import React from 'react'
import moment from 'moment'
import Table, { Cell, Row } from '../../../../components/Table'
import { RenderFirstGrid } from '../../commonMedia/SlotCalendar/index'

export default function PackageCalendar(props) {
  function renderHeaderColumn(packageItem, packageIndex) {
    return (
      <React.Fragment key={packageIndex}>
        {packageIndex === 0 && (<RenderFirstGrid data={packageItem}></RenderFirstGrid>)}
        <Row className='headerColumn'>
          <Cell>
            <div className='name' data-testid={`package_${packageIndex}`} title={packageItem.packageName} onClick={() => { props.togglePackageBox(1, packageItem.packageId) }}>{packageItem.packageName}</div>
          </Cell>
        </Row>
      </React.Fragment>
    )
  }

  function renderCell(packageItem, packageIndex, dateItem, dateIndex) {
    return (
      <React.Fragment key={packageIndex}>
        {packageIndex === 0 && (
          <Row>
            <Cell className='doubleline'>
              <div><small>{dateItem.date}</small></div>
              <small className='text-muted'>{moment(dateItem.date).format('ddd')}</small>
            </Cell>
          </Row>
        )}
        <Row>
          <Cell className={`${packageItem.bookedSlot === packageItem.totalSlot ? 'red' : 'green'} ${packageItem.bookedSlot > 0 && 'booked'}`}>
            <div data-testid={`package_${packageIndex}_date_${dateIndex}`} onClick={()=>{ props.toggleCalendarPopup(1, packageItem.lineItemInfo)}}>
              {packageItem.bookedSlot} / {packageItem.totalSlot}
            </div>
          </Cell>
        </Row>
      </React.Fragment>
    )
  }

  const { packageCalendar } = props
  return (
    <div className='slotWrap'>
      <div className='slot-headercolumn'>
        {packageCalendar.map((dateItem, dateIndex) => (
          <React.Fragment key={dateIndex}>
            {dateIndex === 0 ? (
              <Table>
                {dateItem.packageBookingInfo.map((packageItem, packageIndex) => (
                  renderHeaderColumn(packageItem, packageIndex)
                ))}
              </Table>
            ) : ''}
          </React.Fragment>
        ))}
      </div>
      <div className='slot-innerbox' id='slotScroll'>
        {packageCalendar.map((dateItem, dateIndex) => (
          <Table key={dateIndex}>
            {dateItem.packageBookingInfo.map((packageItem, packageIndex) => (
              renderCell(packageItem, packageIndex, dateItem, dateIndex)
            ))}
          </Table>
        ))}
      </div>
    </div>
  )
}
