import React, { Fragment } from 'react'
import { Upload } from '../../Form'
import { getMessage } from 'lib/translator'

const ImageUrls = ({ _this }) => (
  <Fragment>
    <div className="row mt">
      <div className="col-6">
        <Upload
          name="logo"
          placeholder={getMessage('rewards.logo')}
          label={getMessage('rewards.logo')}
          {..._this.generateStateMappers({
            stateKeys: ['logo'],
            loseEmphasisOnFill: true,
          })}
          required
        />
      </div>
      <div className="col-6">
        <Upload
          name="banner"
          placeholder={getMessage('rewards.banner')}
          label={getMessage('rewards.banner')}
          {..._this.generateStateMappers({
            stateKeys: ['banner'],
            loseEmphasisOnFill: true,
          })}
          required
        />
      </div>
    </div>
  </Fragment>
)

export default ImageUrls
