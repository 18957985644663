import React, { useState, useEffect } from 'react'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import { Popup } from 'components/Popup'
import { Checkbox } from 'components/Form'

const RefundPopup = ({
  rewardDetails,
  handleRefundPopup,
  uid,
  userRewardID,
  type,
}) => {
  const [confirmRefundPopUp, setConfirmRefundPopUp] = useState(false)
  const [refundSuccessMessage, setRefundSuccessMessage] = useState(false)
  const [reason, setReason] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState('')
  const UNSUPPORTED_REWARD_TYPE = ['FP_EVOUCHER', 'DONATION']

  const submitRefundRequest = () => {
    if (reason.trim() === '') {
      setError(getMessage('customer.details.rewards.refund.validationMsg'))
      return
    }
    setError('')
    setConfirmRefundPopUp(true)
  }

  const confirmRefund = async () => {
    try {
      if (!isChecked) {
        setError(getMessage('rewards.confirmation.validationMessage'))
        return
      }

      const url = `/rms/v2/rewards/${userRewardID}/refund`
      const payload = {
        uid,
        reason,
      }

      const api = new API({ url })
      const response = await api.post(payload)

      if (response?.status === 204) {
        setRefundSuccessMessage(true)
      }
    } catch (e) {
      const errorMessage = UNSUPPORTED_REWARD_TYPE.includes(type)
        ? getMessage('customer.details.rewards.errorMsg.unSupportedRewardsType')
        : e.message || getMessage('rewards.defaultError')

      setError(errorMessage)
    }
  }

  useEffect(() => {
    if (refundSuccessMessage) {
      const timeoutId = setTimeout(() => {
        window.location.reload()
      }, 1000)
      return () => clearTimeout(timeoutId)
    }
    return () => {}
  }, [refundSuccessMessage])

  return (
    <Popup
      className="refund-popup rewards"
      show={true}
      heading={
        confirmRefundPopUp
          ? getMessage('customer.details.rewards.refundConfirmation')
          : getMessage('customer.details.rewards.refund')
      }
      close={handleRefundPopup}
    >
      <div className="row">
        <div className="col-12">
          <div className={`${confirmRefundPopUp ? 'styledBox' : ''}`}>
            <p>
              <strong>
                {getMessage('customer.details.rewards.applicableToReward')}
              </strong>
              <span className="content">{rewardDetails}</span>
            </p>
            <p>
              {getMessage('rewards.goodwill.reasonForRequest')}
              {confirmRefundPopUp ? (
                <span className="content">{reason}</span>
              ) : (
                <input
                  placeholder={getMessage(
                    'rewards.goodwill.reasonForRequest.placeholder'
                  )}
                  name="reason"
                  data-testid="reason"
                  value={reason}
                  className="content"
                  readOnly={confirmRefundPopUp}
                  type="text"
                  onChange={(e) => setReason(e.target.value)}
                />
              )}
            </p>
          </div>
          {confirmRefundPopUp && (
            <Checkbox
              label=""
              testid="confirm-checkbox"
              name="confirm"
              inlineLabel={getMessage('customer.details.rewards.refundInfo')}
              onChange={(e) => {
                setIsChecked(e)
                setError('')
              }}
            />
          )}
          {error && <div className="error">{error}</div>}
        </div>
      </div>
      <div className="row form-btns">
        <div className="col-12">
          {confirmRefundPopUp && (
            <button
              type="button"
              className="button"
              data-testid="cancel"
              onClick={() => {
                setIsChecked(false)
                setConfirmRefundPopUp(false)
                setError('')
              }}
            >
              {getMessage('rewards.form.cancel')}
            </button>
          )}
          <button
            type="button"
            className="button primary"
            data-testid={confirmRefundPopUp ? 'confirm' : 'submit'}
            onClick={confirmRefundPopUp ? confirmRefund : submitRefundRequest}
          >
            {confirmRefundPopUp
              ? getMessage('rewards.form.confirm')
              : getMessage('rewards.form.submit')}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {refundSuccessMessage && (
            <div className="success mb">
              {getMessage('customer.details.rewards.refundSuccessMessage')}
            </div>
          )}
        </div>
      </div>
    </Popup>
  )
}

export default RefundPopup
