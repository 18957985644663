import React from 'react'
import moment from 'moment'
import { getMessage } from '../../../../lib/translator'
import { Popup } from '../../../../components/Popup'
import './style.css'

export default function LineItemPreview (props){
  const data = props.data
  return (
    <Popup
      show={props.show}
      className="lineitem-box"
      heading={getMessage('media.adsetdetail.title')}
      close={() => { props.toggleLineitemPopup(2) }}
    >
      <div className='lineitem-wrapper'>
        <div className='lineitem-inner'>
          <div className='lineitem-item description'>
            <div className='title black'>{getMessage('media.adsetdetail.info.h3')}</div>
          </div>
          <div className='lineitem-item description'>
            <div className='text-muted'>{getMessage('media.adset.rowheaders.name')}: <span className='black'>{data.lineItemName}</span></div>
          </div>
          <div className='lineitem-item'>
            <div className='text-muted'>{getMessage('media.adset.rowheaders.id')}: <span className='black'>{data.id}</span></div>
          </div>
          <div className='lineitem-item'>
            <div className='text-muted'>{getMessage('media.adsetdetail.info.time')}: <span className='black'>{data.startDate} ~ {data.endDate}</span></div>
          </div>
          <div className='lineitem-item'>
            <div className='text-muted'>{getMessage('media.adset.rowheaders.package')}: <span className='black'>({data.packageId}) {data.packageName}</span></div>
          </div>
          <div className='lineitem-item'>
            <div className='text-muted'>{getMessage('media.adsetdetail.info.totalbudget')}: <span className='black'>${data.budget}</span></div>
          </div>
          <div className='lineitem-item description'>
            <div className='text-muted'>{getMessage('media.campaigndetail.advertiser.h3')}</div>
            <div className='advertiser-table'>
              {data.lineItemSupplierReservationList?.map((item, index) => (
                <div className='advertiser-row' key={index}>
                  <div className='name'>{item.vendorCode? `${item.supplierName} - ${item.vendorCode}`: item.supplierName}</div>
                  <div className='budget'>$ {item.budget}</div>
                </div>
              ))}
            </div>
          </div>
          <div className='lineitem-item description'>
            <div className='text-muted'>{getMessage('media.public.createdby')}: <span className='black'>{data.createdBy} | {moment(data.createdOn).format('ddd, DD MMM YYYY, h:mm A')}</span></div>
          </div>
          <div className='lineitem-item description'>
            <div className='text-muted'>{getMessage('media.public.updatedby')}: <span className='black'>{data.updatedBy} | {moment(data.updatedOn).format('ddd, DD MMM YYYY, h:mm A')}</span></div>
          </div>
          <div className='lineitem-item description'>
            <div className='text-muted'>{getMessage('media.adsetdetail.info.crmid')}: <span className='black'>cmid_{props.campaignId}_liid_{data.id}</span></div>
          </div>
          <div className='lineitem-item description'>
            <div className='text-muted'>{getMessage('media.adsetdetail.info.buyer')}: <span className='black'>{data.buyer}</span></div>
          </div>
        </div>

        {data.setupDetails && (
          <div className='lineitem-inner setupdetails'>
            <div className='lineitem-item description'>
              <div className='title black'>{getMessage('media.campaigndetail.setup.h3')}</div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.setup.supplierBanner')}: <span className='black'>{data.setupDetails.supplierBanner}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.setup.logoName')}: <span className='black'>{data.setupDetails.logoName}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.setup.brandDetails')}: <span className='black'>{data.setupDetails.brandDetails}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.setup.supplierLandingPageId')}: <span className='black'>{data.setupDetails.supplierLandingPageId}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.setup.supplierLandingPageUrl')}: <span className='black'>{data.setupDetails.supplierLandingPageUrl}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.setup.backendOfficeId')}: <span className='black'>{data.setupDetails.backendOfficeId}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.setup.defaultSearchText')}: <span className='black'>{data.setupDetails.defaultSearchText}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.campaigndetail.campaign.offersverified')}: <span className='black'>{data.offersVerified}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.campaigndetail.setup.categoryPageUrl')}: <span className='black'>{data.setupDetails.categoryPageUrl}</span></div>
            </div>
            <div className='lineitem-item description'>
            <div className='text-muted'>{getMessage('media.campaigndetail.setup.campaignLandingPageUrl')}:</div>
            {data.setupDetails?.campaignLandingPageUrl && (
              <div className='advertiser-table'>
                {data.setupDetails?.campaignLandingPageUrl?.split(';')?.map((item, index) => (
                  <div className='advertiser-row' key={index}>
                    <div className='id'>{`[${index + 1}]`}</div>
                    <div className='content'><a href={item} target='blank'>{item}</a></div>
                  </div>
                ))}
              </div>
            )}
          </div>
          </div>
        )}

        {data.businessMechanic && (
          <div className='lineitem-inner businessMechanic'>
            <div className='lineitem-item description'>
              <div className='title black'>{getMessage('media.campaigndetail.business.h3')}</div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.description')}: <span className='black'>{data.businessMechanic.description}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.edmFeature')}: <span className='black'>{data.businessMechanic.edmFeature}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.includesDbb')}: <span className='black'>{data.businessMechanic.includesDbb}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.defaultSearch')}: <span className='black'>{data.businessMechanic.defaultSearch}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.keywordSearch')}: <span className='black'>{data.businessMechanic.keywordSearch}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.featuredSku')}: <span className='black'>{data.businessMechanic.featuredSku}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.fullDescriptionFoc')}: <span className='black'>{data.businessMechanic.fullDescriptionFoc}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.focWarrantyCard')}: <span className='black'>{data.businessMechanic.focWarrantyCard}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.focWarrantyPeriod')}: <span className='black'>{data.businessMechanic.focWarrantyPeriod}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.focValue')}: <span className='black'>{data.businessMechanic.focValue}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.handling')}: <span className='black'>{data.businessMechanic.handling}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.indicate')}: <span className='black'>{data.businessMechanic.indicate}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.premiumQut')}: <span className='black'>{data.businessMechanic.premiumQut}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.focHandlingFee')}: <span className='black'>{data.businessMechanic.focHandlingFee}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.vendorCode')}: <span className='black'>{data.businessMechanic.vendorCode}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.soac')}: <span className='black'>{data.businessMechanic.soac}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.promoType')}: <span className='black'>{data.businessMechanic.promoType}</span></div>
            </div>
            <div className='lineitem-item'>
              <div className='text-muted'>{getMessage('media.adsetdetail.bm.classGLCode')}: <span className='black'>{data.businessMechanic.classGLCode?.name}</span></div>
            </div>
          </div>
        )}
      </div>
    </Popup>
  )
}
