import * as yup from 'yup'

export const createStoreFormSchema = (action) => {
  return yup.object().shape({
    name: yup
      .string()
      .required('Store name is required')
      .min(4, 'Min must be 4 characters'),
    type: yup.string().nullable().required('Store type is required'),
    clientId:
      action === 'new'
        ? yup.string().nullable().required('Client ID is required')
        : yup.string().nullable().optional(),
    address: yup.string().nullable().required('Store address is required'),
    tierSetupId: yup
      .number()
      .nullable()
      .when('hasPicking', {
        is: true,
        then: (s) => s.required('Delivery fee is required'),
      }),
    pickingStoreId: yup
      .number()
      .nullable()
      .when('hasPicking', {
        is: false,
        then: (s) => s.required('Picking store ID is required'),
      }),
    businessHours: timeHoursSchema,
    meta_isSNG: yup.bool(),
    meta_sngOperationalHours: yup.object().when('meta_isSNG', {
      is: true,
      then: () => timeHoursSchema,
      otherwise: (s) => s.nullable(),
    }),
    meta_sngCheckinRadius: yup.string().when('meta_isSNG', {
      is: true,
      then: (s) => s.nullable().required('Check in Radius is required'),
    }),
    'SAP Code': yup.string().nullable().required('SAP Code is required'),
    'Store Code': yup.string().nullable().required('Store Code is required'),
  })
}

const OPENING_MESSAGE = 'Opening time is required'
const CLOSING_MESSAGE = 'Closing time is required'

export const timeHoursSchema = yup.object().shape({
  Monday: yup.object().shape({
    o: yup.string().required(OPENING_MESSAGE).notOneOf([''], OPENING_MESSAGE),
    c: yup.string().required(CLOSING_MESSAGE).notOneOf([''], CLOSING_MESSAGE),
  }),
  Tuesday: yup.object().shape({
    o: yup.string().required(OPENING_MESSAGE).notOneOf([''], OPENING_MESSAGE),
    c: yup.string().required(CLOSING_MESSAGE).notOneOf([''], CLOSING_MESSAGE),
  }),
  Wednesday: yup.object().shape({
    o: yup.string().required(OPENING_MESSAGE).notOneOf([''], OPENING_MESSAGE),
    c: yup.string().required(CLOSING_MESSAGE).notOneOf([''], CLOSING_MESSAGE),
  }),
  Thursday: yup.object().shape({
    o: yup.string().required(OPENING_MESSAGE).notOneOf([''], OPENING_MESSAGE),
    c: yup.string().required(CLOSING_MESSAGE).notOneOf([''], CLOSING_MESSAGE),
  }),
  Friday: yup.object().shape({
    o: yup.string().required(OPENING_MESSAGE).notOneOf([''], OPENING_MESSAGE),
    c: yup.string().required(CLOSING_MESSAGE).notOneOf([''], CLOSING_MESSAGE),
  }),
  Saturday: yup.object().shape({
    o: yup.string().required(OPENING_MESSAGE).notOneOf([''], OPENING_MESSAGE),
    c: yup.string().required(CLOSING_MESSAGE).notOneOf([''], CLOSING_MESSAGE),
  }),
  Sunday: yup.object().shape({
    o: yup.string().required(OPENING_MESSAGE).notOneOf([''], OPENING_MESSAGE),
    c: yup.string().required(CLOSING_MESSAGE).notOneOf([''], CLOSING_MESSAGE),
  }),
})
