/*
    The purpose of this file is to consolidate all the frontend calculations for order row items
    regardless of the data structure (e.g. packlist response, deliveryOrder response, saleOrder response).
*/
import { getActualSoldByWeightValue } from 'pages/operations/DeliveryOrders/Details/helpers.utils';

/*
    exported getItemData() => To simply extract data from the given source without any calculation
*/
function getItemData(data, dataSource) {
    if (dataSource === 'saleOrder') {
        return {
            image: Array.isArray(data?.images) ? data?.images[0] : null,
            orderedQuantity: data.orderDetails.orderedQuantity,
            deliveredQuantity: data.orderDetails.deliveredQuantity,
            mrp: data.orderDetails.mrp,
            discount: data.orderDetails.discount,
            substitutedItemId: data.orderDetails.substitutedItemId,
            soldByWeight: data.soldByWeight,
            clientItemId: data.clientItemId,
            brand: data.brand,
            name: data.name,
            id: data.id,
            amendmentItemIndex: data.amendmentItemIndex,
            isItemToSub: data.isItemToSub
        }
    } else if (dataSource === 'deliveryOrder') {
        return {
            image: Array.isArray(data.item?.images) ? data.item?.images[0] : null,
            orderedQuantity: data.orderedQuantity,
            deliveredQuantity: data.deliveredQuantity,
            mrp: data.mrp,
            discount: data.discount,
            substitutedItemId: data.substitutedItemId,
            soldByWeight: data.item.soldByWeight,
            clientItemId: data.item.clientItemId,
            brand: data.item.brand,
            name: data.item.name,
            itemId: data.item.itemId,
            amendmentItemIndex: data.amendmentItemIndex
        }
    } else if (dataSource === 'packlist') {
        return {
            image: Array.isArray(data?.images) ? data?.images[0] : null,
            brandName: data.brandName || '',
            mrp: data.mrp,
            discount: data.discount,
            packedQuantity: data.packedQuantity,
            orderedQuantity: data.orderedQuantity,
            soldByWeight: data.soldByWeight, // soldByWeight from packlist response is in true/false
            amendmentItemIndex: data.amendmentItemIndex,
            name: data.name,
            clientItemId: data.clientItemId,
            itemId: data.itemId
        }
    }
    return null;
}

/*
    exported getCalculatedData() => To calculate with the given data from the data source and return the calculated values
*/
function getCalculatedItemData(data, dataSource) {
    switch(dataSource) {
        case 'saleOrder': return calculateItemData({
            orderedQuantity: data.orderDetails.orderedQuantity,
            deliveredQuantity: data.orderDetails.deliveredQuantity || 0,
            mrp: data.orderDetails.mrp,
            discount: data.orderDetails.discount,
            soldByWeight: data.soldByWeight,
            isPendingOrCancelled: data.isPendingOrCancelled
        });
        case 'deliveryOrder': return calculateItemData({
            orderedQuantity: data.orderedQuantity,
            deliveredQuantity: data.deliveredQuantity,
            mrp: data.mrp,
            discount: data.discount,
            soldByWeight: data.item.soldByWeight,
            isPendingOrCancelled: data.isPendingOrCancelled
        });
        case 'packlist': return calculateItemData({
            orderedQuantity: data.orderedQuantity,
            deliveredQuantity: data.packedQuantity,
            mrp: data.mrp,
            discount: data.discount,
            soldByWeight: data.soldByWeight ? 1 : 0, // soldByWeight from packlist response is in true/false
            isPendingOrCancelled: data.isPendingOrCancelled
        });
        default: return null;
    }
}

function calculateItemData({
        orderedQuantity,
        deliveredQuantity,
        mrp,
        discount,
        soldByWeight,
        isPendingOrCancelled
    }) {
    const soldByWeightBool = getActualSoldByWeightValue(soldByWeight)
    const quantity = isPendingOrCancelled ? orderedQuantity : (soldByWeightBool ? Math.min(deliveredQuantity, orderedQuantity) : deliveredQuantity)

    return {
        originalItemAmount: ((mrp - discount) * orderedQuantity).toFixed(2),
        finalItemAmount: ((mrp - discount) * deliveredQuantity).toFixed(2),
        itemAmount: ((mrp - discount) * quantity).toFixed(2),
        fixedDecimalPlace: soldByWeightBool ? 3 : 0
    };
}

export {
    getCalculatedItemData,
    getItemData
}
