import React, { Component } from 'react'
import { Checkbox } from '../../../Form'
import { getMessage } from '../../../../lib/translator'
import './style.css'

export default class UserSegment extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { _this } = this.props

    return (
      <div className="single-column-field fiftypercent-field normal-height-field">
        <div>
          <div className="tab-header">
            {getMessage('rewardPage.userSegment-header')}
          </div>
          <div className="normal-text mb-1">
            {getMessage('luckydraw.userSegment')}
          </div>
        </div>
        <div className="user-segment-checkbox">
          <Checkbox
            className="link-member"
            inlineLabel="Link member"
            name={`link-member`}
            {..._this.generateStateMappers({
              stateKeys: ['userSegment', 'PLUS'],
              loseEmphasisOnFill: true,
            })}
            disabled={_this.state.campaignStarted}
          />
          <Checkbox
            className="union-link-member"
            inlineLabel="Union (NTUC Link) member"
            testid="union-ntuc-link-member"
            name={`union-link-member`}
            {..._this.generateStateMappers({
              stateKeys: ['userSegment', 'UNION'],
              loseEmphasisOnFill: true,
            })}
            disabled={_this.state.campaignStarted}
          />
          <Checkbox
            className="digital-club-member"
            inlineLabel="Digital Club member"
            name={`digital-club-member`}
            {..._this.generateStateMappers({
              stateKeys: ['userSegment', 'FDC'],
              loseEmphasisOnFill: true,
            })}
            disabled={_this.state.campaignStarted}
          />
          <Checkbox
            className="wine-club-member"
            inlineLabel="Just Wine Club member"
            name={`wine-club-member`}
            {..._this.generateStateMappers({
              stateKeys: ['userSegment', 'JWC'],
              loseEmphasisOnFill: true,
            })}
            disabled={_this.state.campaignStarted}
          />
        </div>
      </div>
    )
  }
}
