import React, { Fragment } from 'react'
import { SelectSearch } from '../../Form'
import { getMessage } from '../../../lib/translator'

const PartnerDetails = ({ _this, partnerList, fetchPartnerError }) => {
  return (
    <Fragment>
      {fetchPartnerError && (
        <div className="error" data-testid="error">
          {fetchPartnerError}
        </div>
      )}
      {partnerList?.length > 0 && (
        <div className="row">
          <div className="col-12">
            <SelectSearch
              name="partnerId"
              data-testid="partnerId"
              label={getMessage('rewards.partner')}
              placeholder={getMessage('rewards.partner')}
              options={partnerList}
              searchByName
              required
              {..._this.generateStateMappers({
                stateKeys: ['partnerId'],
              })}
            />
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default PartnerDetails
