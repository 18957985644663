import React, { Component } from 'react'
import { getMessage } from '../../../../../lib/translator'

import './style.css'

class InvoiceSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.togglePricingDetails = this.togglePricingDetails.bind(this)
  }

  togglePricingDetails() {
    this.setState(prevState => {
      return {
        isOpen: !prevState.isOpen,
      }
    })
  }

  render() {
    const { amount: payableAmount, discount, shipping } = this.props.data
    const { isOpen } = this.state
    const total = Number(payableAmount) + Number(shipping)
    return (
      <div className="invoice-summary">
        <div className="flex-around total-amount">
          <div className="text-muted">
            {getMessage('order.details.summary.totalAmount')}:
          </div>
          <div>
            <span>{this.props.currency.symbol} </span>
            <span className="section-value">{total.toFixed(2)}</span>
          </div>
        </div>
        <div className="pricing-details" onClick={this.togglePricingDetails}>
          <div
            className={`pricing-img-wrapper ${
              isOpen ? 'rotate180deg' : 'normal-img'
            }`}
          >
            <small>{'View Pricing Details'}</small>
          </div>
        </div>
        {isOpen && (
          <div className="toggle-pricing">
            <div className="flex-around order-amount">
              <div className="text-muted">
                {getMessage('order.details.summary.orderAmount')}
              </div>
              <div>
                <span>{this.props.currency.symbol} </span>
                <span className="section-value">
                  {Number(payableAmount + discount).toFixed(2)}
                </span>
              </div>
            </div>
            <div className="flex-around shipping-charge">
              <div className="text-muted">
                {getMessage('order.details.summary.shippingCharge')}
              </div>
              <div>
                <span>{this.props.currency.symbol} </span>
                <span className="section-value">
                  {Number(shipping).toFixed(2)}
                </span>
              </div>
            </div>
            <div className="flex-around discount-amount">
              <div className="text-muted">
                {getMessage('order.details.summary.discount')}
              </div>
              <div>
                <span>{this.props.currency.symbol} </span>
                <span className="section-value">
                  {Number(discount).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default InvoiceSummary
