import React, { useContext } from 'react'
import {
  Checkbox,
  Input,
  MultiSelect,
  MultiTextInput,
  SingleDatePicker,
  TimePicker,
} from '../../../../../components/Form'
import { Dialog } from '../../../../../components/Popup'
import SegmentsForm from '../../../../../components/SegmentsForm'
import CategorizedStoreSelector from 'components.new/store-selector/CategorizedStoreSelector'
import StoreGroupingsSelector from 'components.new/store-selector/StoreGroupingsSelector'
import { getMessage } from '../../../../../lib/translator'
import downloadIcon from '../download.svg'
import { digitalCheckoutOrderTypes, orderTypeOptions } from '../settings'
import { handlePaymentType } from './utils'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'

export default function Restrictions({
  isPayViaFpApp,
  generateStateMappers,
  hasOfferStarted,
  stores,
  _this,
  disableCheckbox,
  handleUniquePromocodeValidations,
  values,
  showPromoDownloadPopup,
  method,
  isClonedOfferWithContinuation,
  redeemedCount,
  handleImageClick,
  onClosePopup,
  handleChangePromoCode,
  showMaxRedemption,
  currentTotalRedemption,
  onUpdateStores,
  onUpdateStoreGroupings,
  pressedSubmitWithCurrentData,
}) {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  const enableStoreGroupingsFlag = splits?.[SPLIT_FEATURES.ENABLE_STORE_GROUPINGS_FP_PAY_OFFERS]?.treatment === 'on';

  const timePlaceHolder = getMessage('offer.time.placeholder')
  const showUniqueCouponFields =
    method === 'add' && values && values.hasUniquePromocode
  const hasMinTotalRedemption = method !== 'add' && currentTotalRedemption
  const isDisabledTotalRedemption = method !== 'add' && !currentTotalRedemption

  const handleFocus = e => {
    e.target.addEventListener(
      'wheel',
      function(event) {
        event.preventDefault()
      },
      { passive: false }
    )
  }

  return (
    <div className="form-fields">
      <div className="grid" key="valid-from-div">
        <SingleDatePicker
          enableToday
          name="vaild-from"
          key="vaild-from"
          required
          label={getMessage('offer.validFrom')}
          {...generateStateMappers({
            stateKeys: ['validFrom'],
            loseEmphasisOnFill: true,
          })}
        />
        <TimePicker
          name="validFromTime"
          label={getMessage('offer.time')}
          key="start-time"
          required
          placeholder={timePlaceHolder}
          {...generateStateMappers({
            stateKeys: ['validFromTime'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
      <div className="grid" key="valid-to-div">
        <SingleDatePicker
          enableToday
          name="valid-to"
          key="valid-to"
          label={getMessage('offer.validTill')}
          {...generateStateMappers({
            stateKeys: ['validTill'],
            loseEmphasisOnFill: true,
          })}
        />
        <TimePicker
          name="validToTime"
          key="end-time"
          label={getMessage('offer.validto.time')}
          placeholder={timePlaceHolder}
          {...generateStateMappers({
            stateKeys: ['validTillTime'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>

      {showMaxRedemption && (
        <Input
          readOnly={hasOfferStarted}
          type="number"
          name="max-redemption"
          key="max-redemption"
          onFocus={e => handleFocus(e)}
          min={0}
          label={getMessage('offer.max.redemption')}
          placeholder={getMessage('offer.max.redemption.placeholder')}
          {...generateStateMappers({
            stateKeys: ['rule', 'limit'],
            loseEmphasisOnFill: true,
          })}
        />
      )}

      {
        enableStoreGroupingsFlag ?
        <div>
          {getMessage(!isPayViaFpApp ? 'offer.pay.via.app' : 'offer.applies.to')}
          <StoreGroupingsSelector
            values={values.clientStoreIds}
            onUpdate={onUpdateStoreGroupings}
            validateEmpty={pressedSubmitWithCurrentData}
          />
        </div> :
        <MultiTextInput
          type="text"
          name="clientStoreIds"
          label={getMessage(!isPayViaFpApp ? 'offer.pay.via.app' : 'offer.applies.to')}
          placeholder={getMessage('offer.applies.to.placeholder')}
          {...generateStateMappers({
            stateKeys: ['clientStoreIds'],
            loseEmphasisOnFill: true,
          })}
          onChange={value => handlePaymentType(_this, value, 'clientStoreIds')}
        />
      }

      {!isPayViaFpApp && (
        <div>
         {getMessage('offer.Online.Offline.stores')}
        <CategorizedStoreSelector
          stores={stores}
          values={values.storeId}
          onUpdate={onUpdateStores}
          validateEmpty={pressedSubmitWithCurrentData}
        />
        </div>
      )}

      <div>
        <span className="restiction-order-type">
          {getMessage('offer.ordertype.label')}
          <div className="restriction-order-type-warning">
            Please choose the correct order type to avoid creating offers
            applicable to all order types
          </div>
        </span>
        <MultiSelect
          name="order-types"
          key="order-types"
          label=" "
          placeholder={getMessage('offer.ordertype.placeholder')}
          options={
            isPayViaFpApp
              ? digitalCheckoutOrderTypes
              : [...orderTypeOptions, ...digitalCheckoutOrderTypes]
          }
          {...generateStateMappers({
            stateKeys: ['orderType'],
            loseEmphasisOnFill: true,
          })}
          readOnly={hasOfferStarted}
        />
      </div>
      <div
        className={`restriction-payment-type ${
          showMaxRedemption ? 'margin-t' : ''
        }`}
      >
        <MultiTextInput
          type="text"
          name="payment-type"
          key="payment-type"
          label={getMessage('offer.payment.type')}
          placeholder={getMessage('offer.payment.type.placeholder')}
          {...generateStateMappers({
            stateKeys: ['paymentType'],
            loseEmphasisOnFill: true,
          })}
          data-testid="payment-type"
          onChange={value => handlePaymentType(_this, value, 'paymentType')}
        />
      </div>
      {!isPayViaFpApp && (
        <div>
          <Checkbox
            className="stackable"
            label={getMessage('offer.field.stackable.label')}
            name={`stackable`}
            disabled={disableCheckbox}
            {...generateStateMappers({
              stateKeys: ['stackable'],
              loseEmphasisOnFill: true,
            })}
          />
          <Checkbox
            className="ignoreSegment"
            label={getMessage('offer.field.ignoreSegment.label')}
            name={`ignoreSegment`}
            {...generateStateMappers({
              stateKeys: ['ignoreSegment'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
      )}
      <div className="customer-level-restrictions grid">
        <div className="grid">
          <Checkbox
            className="unique-promocode"
            label={getMessage('offer.field.hasUniquePromocode.label')}
            name={`unique-promocode`}
            disabled={method !== 'add'}
            {...generateStateMappers({
              stateKeys: ['hasUniquePromocode'],
              loseEmphasisOnFill: true,
            })}
            onChange={handleUniquePromocodeValidations}
          />
          {values && values.promoCode && values.promoCode.length > 0 && (
            <Checkbox
              className="add-to-wallet"
              label={getMessage('offer.field.addToWallet.label')}
              name={`add-to-wallet`}
              disabled={method !== 'add'}
              {...generateStateMappers({
                stateKeys: ['addToWallet'],
                loseEmphasisOnFill: true,
              })}
            >
              {method !== 'add' && (
                <span className="">
                  <sup>*</sup>only configurable upon offer creation
                </span>
              )}
            </Checkbox>
          )}
          {method === 'edit' && values && values.hasUniquePromocode && (
            <span className="download-image">
              <img
                src={downloadIcon}
                alt="Download"
                onClick={handleImageClick}
                className="download_icon"
                data-testid="download-unique-promocode"
              />
            </span>
          )}

          {showPromoDownloadPopup && (
            <Dialog
              show={showPromoDownloadPopup}
              className="notification success"
              title={getMessage('offer.dialog.notification.label')}
              information={getMessage('offer.dialog.notification.message')}
              close={onClosePopup}
              closeText={getMessage('offer.dialog.notification.close')}
            />
          )}
          {values && !values.hasUniquePromocode && (
            <MultiTextInput
              className="promocode"
              label={getMessage('offer.promocode.label')}
              placeholder={getMessage('offer.promocode.placeholder')}
              name="promocode"
              type="text"
              {...generateStateMappers({
                stateKeys: ['promoCode'],
                loseEmphasisOnFill: true,
              })}
              onChange={handleChangePromoCode}
              required={isPayViaFpApp}
            />
          )}
          {showUniqueCouponFields && (
            <>
              <Input
                className="coupon-code"
                label={getMessage('Number of coupons')}
                placeholder={getMessage('Enter number of coupons')}
                name="number-of-promos"
                type="number"
                required
                {...generateStateMappers({
                  stateKeys: ['numberOfPromos'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                className="coupon-code"
                label={getMessage('prefix')}
                placeholder={getMessage('Enter prefix')}
                name="prefix"
                required
                {...generateStateMappers({
                  stateKeys: ['prefix'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                className="coupon-code"
                label={getMessage('suffix')}
                placeholder={getMessage('Enter suffix')}
                name="suffix"
                required
                {...generateStateMappers({
                  stateKeys: ['suffix'],
                  loseEmphasisOnFill: true,
                })}
              />
            </>
          )}
        </div>
      </div>
      <div className="customer-level-restrictions">
        <h3>Customer Level</h3>
        <div className="redemptions">
          <Input
            type="number"
            name="customer-redemption"
            key="customer-redemption"
            min={0}
            onFocus={e => handleFocus(e)}
            label={getMessage('offer.customer.redemption')}
            placeholder={getMessage('offer.customer.redemption.placeholder')}
            {...generateStateMappers({
              stateKeys: ['customerRedemptionLimit'],
              loseEmphasisOnFill: true,
              defaultValue: null,
            })}
          />
        </div>
      </div>
      {!isPayViaFpApp && (
        <React.Fragment>
          <h3 className="delivery-date">
            {getMessage('offer.heading.deliveyDate')}
          </h3>
          <div className="grid">
            <SingleDatePicker
              disabled={hasOfferStarted}
              enableToday
              name="delivery-date-start"
              key="delivery-date-start"
              placeholder={getMessage('campaign.startDate')}
              {...generateStateMappers({
                stateKeys: ['startDeliveryDate'],
                loseEmphasisOnFill: true,
              })}
              openDirection="up"
            />
            <TimePicker
              disabled={hasOfferStarted}
              key="delivery-date-start-time"
              placeholder={timePlaceHolder}
              name="delivery-date-start-time"
              {...generateStateMappers({
                stateKeys: ['startDeliveryDateTime'],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="grid" key="valid-to-div">
            <SingleDatePicker
              enableToday
              name="delivery-date-end"
              key="delivery-date-end"
              disabled={hasOfferStarted}
              placeholder={getMessage('campaign.endDate')}
              {...generateStateMappers({
                stateKeys: ['endDeliveryDate'],
                loseEmphasisOnFill: true,
              })}
              openDirection="up"
            />
            <TimePicker
              name="delivery-date-end-time"
              key="delivery-date-end-time"
              disabled={hasOfferStarted}
              placeholder={timePlaceHolder}
              {...generateStateMappers({
                stateKeys: ['endDeliveryDateTime'],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        </React.Fragment>
      )}
      <div className="row">
        <div className="col-6">
          <Input
            type="number"
            name="total-redemption"
            key="total-redemption"
            onFocus={(e) => handleFocus(e)}
            min={hasMinTotalRedemption ? currentTotalRedemption : 0}
            label={getMessage('offer.total.redemption')}
            placeholder={getMessage('offer.total.redemption.placeholder')}
            {...generateStateMappers({
              stateKeys: ['totalRedemption'],
              loseEmphasisOnFill: true,
            })}
            readOnly={isDisabledTotalRedemption}
          >
            {isClonedOfferWithContinuation && (
              <span className="offer-available-redemption">
                {getMessage('offer.redeemedCount.placeholder', {
                  count: redeemedCount,
                })}
              </span>
            )}
          </Input>
        </div>
        {method === 'edit' && (
          <div className="col-6">
            <Input
              type="number"
              name="total-redemption"
              key="total-redemption"
              disabled
              label={getMessage('offer.total.redemption.count')}
              {...generateStateMappers({
                stateKeys: ['redemptionCount'],
                loseEmphasisOnFill: true,
              })}
              readOnly={isDisabledTotalRedemption}
            >
              {isClonedOfferWithContinuation && (
                <span className="offer-available-redemption">
                  {getMessage('offer.redeemedCount.placeholder', {
                    count: values.redemptionCount,
                  })}
                </span>
              )}
            </Input>
          </div>
        )}

      </div>
      <SegmentsForm _this={_this} />
    </div>
  )
}
