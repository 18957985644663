/*
    The purpose of this file is to consolidate all the frontend calculations for order/saleOrder summary
    regardless of the data structure (e.g. deliveryOrder response, saleOrder response).
*/

function getCalculatedSummaryValues(data, dataSource) {
    switch(dataSource) {
        case 'saleOrder': return getSummaryValues(data, dataSource);
        case 'deliveryOrder': return getSummaryValues(data, dataSource);
        default: return null;
    }
}

function getSummaryValues(data, dataSource) {
    let pendingAmount = 0;
    let paidAmount = 0;
    let pendingAmountText = '';

    // for saleOrder, only pending amount need to be calculated. The rest of the summary values are derived from saleOrder response
    if (dataSource === 'saleOrder') {
        paidAmount = data.payments.filter(p => p.status === 'PAYMENT_STATUS_COMPLETED').reduce((previous, current) => {
            return (previous + Number(current.amount));
        }, 0 );
        pendingAmount = Number(data.totalAmount) - Number(paidAmount);

        // check if all payment is Completed
        if (data.payments.every(x => x.status === 'PAYMENT_STATUS_COMPLETED')) {
            pendingAmountText = getPendingAmountText(pendingAmount);
        }

        // for sale order page, no need to return totalAmount as the amount is coming from SO response
        return {
            pendingAmount: Number(pendingAmount).toFixed(2),
            pendingAmountText
        };
    }

    const { discounts, subTotal, deliveryFee, serviceFee, payments, originalSubTotalAmount, finalSubTotalAmount } = data;
    const totalOffersAndDiscounts = discounts.reduce((previous, current) => {
        return previous + current.discount;
    }, 0);

    const totalAmount = Number(subTotal) + deliveryFee + serviceFee - totalOffersAndDiscounts;
    const originalTotalAmount = Number(originalSubTotalAmount) + deliveryFee + serviceFee - totalOffersAndDiscounts;
    const finalTotalAmount = Number(finalSubTotalAmount) + deliveryFee + serviceFee - totalOffersAndDiscounts;

    paidAmount = payments.filter(p => p.status === 'PAYMENT_STATUS_COMPLETED').reduce((previous, current) => {
        return (previous + Number(current.amount));
    }, 0 );
    pendingAmount = Number((finalTotalAmount ? finalTotalAmount : totalAmount) - paidAmount).toFixed(2);

    // check if all payment is Completed
    if (payments.every(x => x.status === 'PAYMENT_STATUS_COMPLETED')) {
        pendingAmountText = getPendingAmountText(pendingAmount);
    }

    return {
        totalAmount,
        pendingAmount,
        pendingAmountText,
        originalTotalAmount,
        finalTotalAmount
    }
}

function getPendingAmountText(pendingAmount) {
    if (pendingAmount > 0) {
        return 'undercharged';
    } else if (pendingAmount < 0) {
        return 'overcharged';
    }
    return '';
}

export {
    getCalculatedSummaryValues
}
