import React, { useEffect } from 'react'
import { FE_GATEWAY_API_URL } from 'config/app'
import { View } from '../ui'
import { useFormikContext } from 'formik'
import API from 'lib/api'
import {
  processBarcode,
  getSlimProductData,
  isProductAdded,
  errorToast,
} from '../helper'
import get from 'lodash.get'

export const ProductFetchHandler = ({ fetchBy, payload, onDone, onFailed }) => {
  const { values, setFieldValue } = useFormikContext()

  const fetchBySKU = () => {
    const apiPath = '/catalogue-service/product'
    return new API({
      url: apiPath,
    })
      .get({
        orderType: 'OFFLINE',
        storeId: values.storeId,
        clientId: payload,
        paginate: 'false',
      })
      .then((res) => get(res, 'data.product[0].barcodes', []).join(','))
  }

  const fetchByBarcode = (barcodeFromSkuReq) => {
    const apiPath = `${FE_GATEWAY_API_URL}/product`
    return new API({
      url: apiPath,
    }).get({
      orderType: 'OFFLINE',
      storeId: values.storeId,
      barcode: processBarcode(barcodeFromSkuReq || payload),
      showFilters: 'false',
    })
  }

  useEffect(() => {
    async function fetchProduct() {
      try {
        let barcodeBySKU = ''
        if (fetchBy !== 'barcode') {
          barcodeBySKU = await fetchBySKU()
        }
        const response = await fetchByBarcode(barcodeBySKU)
        if (
          response.status === 'SUCCESS' &&
          response.data &&
          response.data.product
        ) {
          // should only be one product since it's a scanned result
          const scannedProduct = response.data.product[0]
          // PREORDER-TODO: SKU scan result does not contain storeSpecificData
          // the build is passing now as we mock the data in the test
          const slimProductData = getSlimProductData(scannedProduct)
          const scannedProductSku = slimProductData.sku

          // update local products object
          // contract: [SKU: quantity]
          if (isProductAdded(values.products, scannedProductSku)) {
            // scanned product was already in cart
            // +1 to quantity
            setFieldValue(
              `products.${scannedProductSku}`,
              values.products[scannedProductSku] + 1
            )
          } else {
            // scanned product was not in cart before
            // add product to cart with quantity 1
            setFieldValue(`products.${scannedProductSku}`, 1)
            // push product data to allAvailableProducts
            // because we also need the product ID
            setFieldValue('allAvailableProducts', [
              ...(values.allAvailableProducts ?? []),
              slimProductData,
            ])
            // only update local cart object when the scanned item was not in cart before
            // contract: [SKU: boolean]
            // sometimes values.products was not updated immediately after setFieldValue
            const newCart = {
              ...Object.keys({
                ...values.products,
                ...{ [scannedProductSku]: 1 },
              }).reduce((total, value) => {
                // only check an item if it was not previously added to cart
                // if not, we preserve the checked/unchecked status
                if (values.cart[value] === undefined) {
                  total[value] = true
                }
                return total
              }, {}),
            }
            // after scanning, we will move to order review page
            // so we need to update the entire cart object
            setFieldValue('cart', {
              ...values.cart,
              ...newCart,
            })
          }

          onDone()
        } else {
          throw new Error('failed to retrieve product by barcode...')
        }
      } catch (err) {
        onFailed()
        errorToast('PRODUCT_FETCH_FAILURE', 'Invalid barcode')
      }
    }

    fetchProduct()
  }, [fetchBy, payload])

  return (
    <View
      width={'100%'}
      height={'300px'}
      display="flex"
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      {<p>Please wait...</p>}
    </View>
  )
}
