import React, { useState } from 'react'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import VoucherForm from './Form'

const Actions = ({ setShowForm }) => {
  return (
    <div className="actions-section">
      <button
        className="primary button"
        onClick={() => {
          console.log('show Form')
          setShowForm(true)
        }}
      >
        <span className="text">
          {getMessage('omni-journey.voucher.upload-sequence')}
        </span>
      </button>
    </div>
  )
}

const ManageVouchers = (props) => {
  const [showForm, setShowForm] = useState(false)

  return (
    <AuthenticatedPage menu={props.menu}>
      <div className="omni-journey-voucher-container">
        <h1>{getMessage('omni-journey.voucher.header')}</h1>
        <Actions setShowForm={setShowForm} />
        <Popup
          show={showForm}
          heading={getMessage('omni-journey.voucher.upload-sequence')}
          close={() => setShowForm(false)}
          classname="uploadvoucher-popup"
        >
          <VoucherForm setShowForm={setShowForm} />
        </Popup>
      </div>
    </AuthenticatedPage>
  )
}

export default ManageVouchers
