import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../Loader'
import { getPrintableTime } from '../../lib/datetime'
import EmptyState from '../../components/EmptyState'
import API from '../../lib/api'
import { hasPermissions } from '../../lib/auth'
import { getMessage } from '../../lib/translator'
import '../OrderLog/style.css'
import AuthenticatedPage from '../../containers/AuthenticatedPage'
import emptyIcon from '../OrderLog/order-log-empty.svg'
import LogItem from './LogItem'

export default class DeliveryOrderLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      orderLog: [],
    }
    this.getOrderReferenceNumber = this.getOrderReferenceNumber.bind(this)
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    })

    this.api = new API({ url: '/order-service/order-log' })
    const params = {
      orderReferenceNumber: this.props.match.params.id
    }
    this.api.get(params).then(response => {
      const orderLog = response.data.orderlog
      const log = orderLog.filter(logItem => logItem.action === 'ORDER_DISPATCHED')[0]
      let tripId = false
      if (log && log.action === 'ORDER_DISPATCHED') {
        tripId = log.details && log.details.tripId
      }
      this.clientId = response.data.order && response.data.order.clientId
      if (tripId) {
        this.getTripDetails(tripId)
      }
      this.setState({
        orderLog: response.data.orderlog,
        showLoader: false,
        tripId
      })
    }).catch(error => {
      this.handleError(error)
    })
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
    this.addressApi && this.addressApi.cancel()
  }

  getTripDetails(tripId) {
    const api = new API({ url: `/logistics-service/tripLocations` })
    const tripDetailsApi = new API({ url: `/logistics-service/trip/${tripId}` })
    Promise.all([tripDetailsApi.get(), api.get({ tripId: tripId })])
      .then(([tripDetailsRes, tripLocationResponse]) => {
        const locations = tripLocationResponse.data
        const length = locations.length
        const vehicleCoordinates =
          length > 0
            ? locations.sort(
              (a, b) =>
                new Date(getPrintableTime(a.time)) -
                new Date(getPrintableTime(b.time))
            )
            : []

        const tripDetails = tripDetailsRes.data.trip
        const orders = tripDetails.orders
        const orderLocations = orders.map(({ address }) => ({
          latitude: address.latitude,
          longitude: address.longitude,
        }))
        const storeLocation = orders.length > 0 ? {
          latitude: orders[0].store.latitude,
          longitude: orders[0].store.longitude,
        } : null
        this.setState({
          vehicleCoordinates,
          orderLocations,
          showMap: true,
          storeLocation,
        })
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  handleError(error) {
    if (error.code === 401) {
      throw error
    }
    this.setState({
      error: error.message,
      showLoader: false
    })
  }

  getOrderReferenceNumber() {
    const showLoader = this.state.showLoader
    return <h4>
      {`${getMessage('order.logs.orderRef')} `}
      <span className="text-muted prefix">
        {!showLoader && (this.clientId ? '#' : 'Z')}
      </span>
      {!showLoader && (hasPermissions('order', 'order', 'get') ? (
        <Link to={`/operations/delivery-orders/${this.props.match.params.id}`}>
          {this.clientId || this.props.match.params.id}
        </Link>
      ) : (this.clientId || this.props.match.params.id)
      )}
    </h4>
  }

  validateOrderLog(orderLog) {
    return orderLog &&
      orderLog.length > 0 &&
      orderLog[0] &&
      orderLog[0].details &&
      orderLog[0].details.status &&
      orderLog[0].details.status.newValue
  }

  render() {
    const {
      orderLog,
      showLoader,
      vehicleCoordinates,
      showMap,
      orderLocations,
      storeLocation,
    } = this.state
    const count = orderLog && orderLog.length
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <h1>{getMessage('deliveryOrder.logs.heading')}</h1>
        <div className="order-reference">
          {this.getOrderReferenceNumber()}
          {this.validateOrderLog(orderLog) && (
            <small className="text-muted">{`${getMessage(
              'order.logs.status'
            )}: ${orderLog[0].details.status.newValue.charAt(0) +
            orderLog[0].details.status.newValue
              .slice(1)
              .toLowerCase()}`}</small>
          )}
        </div>
        <div className="order-log">
          {showLoader ?
            (<Loader />) : count > 0 ?
              (
                orderLog.map((entry, index) => (
                  <LogItem
                    data={entry}
                    index={index}
                    key={`logItem-${index}`}
                    vehicleCoordinates={vehicleCoordinates}
                    orderLocations={orderLocations}
                    storeLocation={storeLocation}
                    showMap={showMap}
                  />
                ))
              ) : (
                <EmptyState
                  icon={emptyIcon}
                  message={getMessage('order.logs.emptyMeassage')}
                />
              )}
        </div>
      </AuthenticatedPage>
    )
  }
}
