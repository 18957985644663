import React, { Component } from 'react'
import { Input, Checkbox, Textarea } from '../../Form'
import { getDateInSQL } from '../../../lib/datetime'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'

export default class ConfirmationDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checkedDetails: false,
      onConfirmClick: false,
      submitting: false,
    }

    this.handleFormSubmission = this.handleFormSubmission.bind(this)
  }
  async handleFormSubmission() {
    this.setState({
      onConfirmClick: true,
    })
    const { checkedDetails } = this.state
    const {
      entityName,
      voucherEmails,
      passwordEmails,
      otherEntityName,
      remarks,
      inputList,
      personalisedName,
      title,
      message,
      customerStatus,
      costCenter,
      cannotExtendPast,
    } = this.props
    if (checkedDetails) {
      this.setState({
        submitting: true,
      })
      const entityId = Number(entityName.split('-')[0])
      const existingName = entityName.split('-')[1]
      const expiresAt = new Date()
      expiresAt.setFullYear(expiresAt.getFullYear() + 1)
      const voucherEmailsArr = voucherEmails
        .split(',')
        .map((item) => item.trim())
      const passwordEmailsArr = passwordEmails
        .split(',')
        .map((item) => item.trim())

      const formattedDate = getDateInSQL(expiresAt)
      const vouchers = inputList.map((x) => {
        return {
          quantity: x.noOfEvouchers,
          initialBalance: x.denomination,
          expiresAt: formattedDate,
          cannotExtendPast: cannotExtendPast ? '' : formattedDate,
          metadata: {
            remarks: remarks,
            customerStatus,
          },
        }
      })
      const data = {
        voucherEmails: voucherEmailsArr,
        passwordEmails: passwordEmailsArr,
        entityId,
        costCenter: costCenter,
        invoiceNumber: this.props.invoiceNumber,
        entityName: entityId ? existingName : otherEntityName,
        vouchers: [...vouchers],
        metadata: {
          remarks: remarks,
        },
        personalization: {
          name: personalisedName,
          title,
          message,
        },
      }
      try {
        const api = new API({ url: '/batches' })
        const response = await api.post(data)
        response && this.props.onCancel()
      } catch (error) {
        if (error.code === 401) {
          throw error
        }
        this.setState({
          errorMessage: error.message,
        })
      }
    }
  }

  render() {
    const { props } = this
    const { checkedDetails, onConfirmClick, submitting } = this.state

    return (
      <div className="contain">
        {this.state.errorMessage ? (
          <div className="error">{this.state.errorMessage}</div>
        ) : null}
        <div className="col-6">
          <Input
            label="Entity Name"
            value={props.entityName.split('-')[1] || props.entityName}
            type="text"
            readOnly
          />
        </div>
        <div className="col-6">
          <Input
            className="capitalize"
            label={getMessage('eVoucher.add.customer.status')}
            value={props.customerStatus.replace('_', ' ')}
            type="text"
            readOnly
          />
        </div>
        {props.costCenter && (
          <div className="col-6">
            <Input
              className="capitalize"
              label={getMessage('eVoucher.add.cost.centre')}
              value={props.costCenter}
              type="text"
              readOnly
            />
          </div>
        )}

        {props.otherEntityName && (
          <div className="col-6 mt">
            <Input value={props.otherEntityName} type="text" readOnly />
          </div>
        )}
        <h3>
          Denomination and Number of{' '}
          <span className="non-breaking">E-Vouchers</span>
        </h3>
        <div className="row mb">
          <div className="col-6">
            <label>($) Denomination</label>
          </div>
          <div className="col-6 ml">
            <label>
              Number of <span className="non-breaking">E-Vouchers</span>
            </label>
          </div>
        </div>
        {props.inputList.map((x, i) => (
          <div className="row mb" key={i}>
            <div className="col-6">
              <Input value={x.denomination} type="text" readOnly />
            </div>
            <div className="col-6 ml">
              <Input value={x.noOfEvouchers} type="text" readOnly />
            </div>
          </div>
        ))}
        <h3>Grand Total</h3>
        <div className="row">
          <div className="col-6">
            <Input
              label="Total Amount"
              value={Number(props.totalAmount).toFixed(2)}
              type="text"
              readOnly
            />
          </div>
          <div className="col-6 ml">
            <Input
              label="Total Number of E-Vouchers"
              value={props.totalNoOfVouchers}
              type="text"
              readOnly
            />
          </div>
        </div>
        <h3>Personalization</h3>
        <div className="row">
          <div className="col-6">
            <Input
              label="Name"
              value={props.personalisedName}
              type="text"
              readOnly
            />
          </div>
          <div className="col-6 ml">
            <Input label="Title" value={props.title} type="text" readOnly />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Textarea
              label="Message"
              value={props.message}
              type="text"
              readOnly
              maxLength="150"
            />
          </div>
        </div>
        {!this.props.isVoucherDetails && (
          <>
            <div className="row">
              <label>Emails to receive vouchers</label>
            </div>
            <div className="row">
              <div className="col-12">
                <Input value={props.voucherEmails} type="text" readOnly />
              </div>
            </div>
            <div className="row mt">
              <label>Emails to receive password</label>
            </div>
            <div className="row">
              {props.passwordEmails && (
                <div className="col-12">
                  <Input value={props.passwordEmails} type="text" readOnly />
                </div>
              )}
            </div>
          </>
        )}
        <div className="row mt">
          <label>{getMessage('eVoucher.create.invoice')}</label>
        </div>
        <div className="row">
          {props.invoiceNumber && (
            <div className="col-12">
              <Input value={props.invoiceNumber} type="text" readOnly />
            </div>
          )}
        </div>
        <div className="row mt">
          <label>{getMessage('eVoucher.create.allow')}</label>
        </div>
        <div className="row">
          {props.cannotExtendPast && (
            <div className="col-12">
              <Checkbox
                controlled
                name="cannotExtendPast"
                value={props.cannotExtendPast}
                disabled
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12 mt">
            <Textarea
              label="Remarks"
              value={props.remarks}
              type="text"
              readOnly
              maxLength="150"
            />
          </div>
        </div>
        {!this.props.isVoucherDetails && (
          <>
            <div className="row">
              <Checkbox
                label=""
                testid="confirm-id"
                inlineLabel="I confirm that all information given above is accurate and complete"
                name="checkedDetails"
                onChange={(v) => this.setState({ checkedDetails: v })}
              />
            </div>
            {onConfirmClick && !checkedDetails && (
              <div className="row">
                <div className="input-error-message">
                  This field is required
                </div>
              </div>
            )}
            <div className="row flex-end">
              <div className="form-actions">
                <button
                  type="button"
                  className="primary"
                  disabled={submitting}
                  data-testid="submit"
                  onClick={this.handleFormSubmission}
                >
                  Confirm
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}
