import React, { Fragment } from 'react'
import Table, { Row, Cell } from '../../../../../../components/Table'
import editIcon from '../../../../../../pages/catalogue/Products/Form/edit.svg'
import { Select, Checkbox, Radio } from '../../../../../../components/Form'
import { getMessage } from '../../../../../../lib/translator'
import { toTitleCase, notEmptyArray } from '../../../../../../lib/commonlyused'
import './style.css'
import { STATUSES } from '../../Details.enum'
import SubOrderItems from './SubOrderItems'
import RefundedItems from './RefundedItems'
import StallDiscount from './StallDiscount'
import ContainerFee from './ContainerFee'
import CardSurcharge from './CardSurcharge'
import SubHeader from './SubHeader'
import CutleryOption from './CutleryOption'
import { handleDisabledContainerCheckboxItems } from '../../OrderSummary/OrderSummaryHelpers'

const checkboxSuborderLevel = (suborderidx, state, suborder, handleState) => {
  return (
    <Checkbox
      testid={`selectSingleSubOrder${suborderidx}`}
      name={`selectSingleSubOrder${suborderidx}`}
      value={!!state.refundItems[suborderidx].value}
      onChange={handleSingleSelected.bind(
        this,
        suborder.id,
        handleState,
        state
      )}
      labelClass={
        state.refundOrder &&
        (!state.refundItems[suborderidx].items.every(item => item.value) ||
          !state.refundItems[suborderidx].items.map(a =>
            notEmptyArray(a.customisationOptions)
              ? a.customisationOptions.every(
                  b => b.value || b.finalQuantity === 0
                )
              : true
          )[0]) &&
        'minus'
      }
      disabled={handleCheckboxDisabled(suborder.state, suborder, state)}
    />
  )
}

const subOrderStatusItems = [
  { currentStatus: STATUSES.SUBMITTED, changedStatus: STATUSES.PREPARING },
  {
    currentStatus: STATUSES.PREPARING,
    changedStatus: STATUSES.READY_FOR_COLLECTION,
  },
  {
    currentStatus: STATUSES.READY_FOR_COLLECTION,
    changedStatus: STATUSES.COLLECTED,
  },
]

const subOrderChangedStatuses = currentStatus =>
  subOrderStatusItems
    .filter(item => item.currentStatus === currentStatus)
    .map(item => item.changedStatus)

const tableHeader = () => {
  return [
    getMessage('qc.orderSummary.table.header.StallItem'),
    getMessage('qc.orderSummary.table.header.unit.container.fee'),
    getMessage('qc.orderSummary.table.header.Price'),
    getMessage('qc.orderSummary.table.header.unit.discount'),
    getMessage('qc.orderSummary.table.header.Quantity'),
    getMessage('qc.orderSummary.table.header.Subtotal'),
  ]
}
const checkedSubOrders = (state, e, id) => {
  const arr = [...state.refundItems]
  if (state.refundOrder || state.cancelOrder) {
    arr.find(a => a.id === id).value = e
    arr
      .find(a => a.id === id)
      .items.map(b => {
        b.value = b.quantity === 0 ? false : e
        return b
      })
    arr
      .find(a => a.id === id)
      .items.map(b => {
        if (notEmptyArray(b.customisationOptions)) {
          b.customisationOptions.map(c => {
            c.value = c.finalQuantity === 0 ? false : e
            return c
          })
        }
      })
    return arr
  } else {
    return arr
  }
}
const subOrderStatusGroup = (state, suborderId, e) => {
  let actionParams = ''
  switch (toTitleCase(e)) {
    case 'Rejected':
      actionParams = 'reject'
      break
    case 'Preparing':
      actionParams = 'accept'
      break
    case 'Ready For Collection':
      actionParams = 'ready'
      break
    case 'Collected':
      actionParams = 'collect'
  }
  let arr = [...state.subOrderStatusItems]
  const foundID = arr.some(a => a.id === suborderId)
  if (foundID) {
    arr = arr.filter(a => a.id === suborderId && (a.status = toTitleCase(e)))
    arr = arr.filter(
      a => a.id === suborderId && (a['actionParams'] = actionParams)
    )
    return arr
  } else {
    arr.push({
      id: suborderId,
      status: toTitleCase(e),
      actionParams: actionParams,
    })
    return arr
  }
}
const handleSelectChange = (i, suborderId, handleState, state, e) =>
  handleState({
    ...state,
    subOrderStatusItems: subOrderStatusGroup(state, suborderId, e),
    subOrderStatusItemsContent: {
      ...state.subOrderStatusItemsContent,
      [i]: e,
    },
    submitting: false,
    cancelOrder: false,
    rejectOrder: false,
    refundOrder: false,
    showHideElements: false,
  })
const handleSingleSelected = (id, handleState, state, e) => {
  handleState({
    ...state,
    allSelected: checkedSubOrders(state, e, id).some(
      item => item.value === true
    ),
    refundItems: checkedSubOrders(state, e, id),
  })
}
const handleCheckboxDisabled = (suborder, content, state) => {
  if (state.rejectOrder && suborder === STATUSES.SUBMITTED) {
    return false
  } else if (
    state.cancelOrder &&
    (suborder === STATUSES.PREPARING || suborder === STATUSES.SUBMITTED)
  ) {
    return false
  } else if (refundCheckboxDisabled(state, suborder, content)) {
    return true
  } else if (
    !handleDisabledContainerCheckboxItems(
      state.containerCheckboxItems,
      content.id
    ) &&
    state.refundOrder &&
    suborder === STATUSES.COLLECTED
  ) {
    return false
  } else {
    return true
  }
}

const handleRadioChange = (handleState, state, e) => {
  handleState({
    ...state,
    radioSubOrder: e,
  })
}
const radioInputSuborderLevel = (suborder, state, handleState) => {
  return (
    <Radio
      name="subOrder-type"
      key={'subOrder-type'}
      options={[
        {
          text: ``,
          value: `${suborder.id}`,
        },
      ]}
      value={state.radioSubOrder}
      onChange={handleRadioChange.bind(this, handleState, state)}
      readOnly={handleCheckboxDisabled(suborder.state, suborder, state)}
      disabled={handleCheckboxDisabled(suborder.state, suborder, state)}
      labelClass="oval"
    />
  )
}
const subOrderStatusItemsContent = (
  state,
  suborderidx,
  suborderStateWithUnderscore,
  suborder,
  handleState
) => {
  return (
    <Cell>
      <div className="sub-order-status-container">
        {getMessage('qc.orderSummary.table.header.SubOrderStatus')}:{' '}
        {!state.editSubOrderStatus[suborderidx] ? (
          <div>
            <span className="sub-order-status">
              {suborderStateWithUnderscore}
            </span>
            {suborder.state !== STATUSES.REJECTED &&
              suborder.state !== STATUSES.CANCELLED &&
              suborder.state !== STATUSES.PENDING &&
              suborder.state !== STATUSES.COLLECTED && (
                <img
                  src={editIcon}
                  className={`edit-status ${(state.cancelOrder ||
                    state.rejectOrder ||
                    state.refundOrder) &&
                    'disable-suborder-status'}`}
                  onClick={() =>
                    !(
                      state.cancelOrder ||
                      state.rejectOrder ||
                      state.refundOrder
                    ) &&
                    handleState({
                      ...state,
                      editSubOrderStatus: {
                        ...state.editSubOrderStatus,
                        [suborderidx]: true,
                      },
                    })
                  }
                  alt="edit"
                  data-testid="qc-order-summary-edit-icon"
                />
              )}
          </div>
        ) : (
          <div className="custom-select">
            <Select
              data-testid={`sub-order-status-select${suborderidx}`}
              placeholder={getMessage('qc.orderInfo.SelectSubOrderStatus')}
              options={
                subOrderChangedStatuses(suborder.state) &&
                subOrderChangedStatuses(suborderStateWithUnderscore).map(
                  elm => {
                    return {
                      text: elm,
                      value: elm,
                    }
                  }
                )
              }
              value={state.subOrderStatusItemsContent[suborderidx]}
              onChange={handleSelectChange.bind(
                this,
                suborderidx,
                suborder.id,
                handleState,
                state
              )}
            />
          </div>
        )}
      </div>
    </Cell>
  )
}

const tabularView = (_url, handleState, state) => {
  return {
    headers: tableHeader(),
    row: ({ id, subOrders, location, cutlery, feeDetails }) => {
      const containerFee = feeDetails.filter(a => a.type === 'CONTAINER_FEE')
      return (
        <Fragment>
          {subOrders.map((suborder, suborderidx) => {
            const suborderStateWithUnderscore = suborder.state.includes('_')
              ? suborder.state.split('_').join(' ')
              : suborder.state
            return (
              <Fragment key={`qc-subordr-main-row-${id}-${suborderidx}`}>
                <Table className="suborder-table table-dynamic">
                  <Row
                    className="suborder-heading"
                    key={`qc-subordr-row-${id}-${suborderidx}`}
                  >
                    <Cell>
                      <div className="select-single-subOrder">
                        {!(state.refundOrder || state.cancelOrder) &&
                          state.showHideElements &&
                          radioInputSuborderLevel(suborder, state, handleState)}
                        {(state.refundOrder || state.cancelOrder) &&
                          state.showHideElements &&
                          checkboxSuborderLevel(
                            suborderidx,
                            state,
                            suborder,
                            handleState
                          )}
                        <SubHeader
                          foodSupplier={suborder.foodSupplier}
                          location={location}
                          suborder={suborder}
                        />
                      </div>
                    </Cell>
                    {subOrderStatusItemsContent(
                      state,
                      suborderidx,
                      suborderStateWithUnderscore,
                      suborder,
                      handleState
                    )}
                  </Row>
                </Table>
                <SubOrderItems
                  id={id}
                  suborderidx={suborderidx}
                  suborderId={suborder.id}
                  items={suborder.items}
                  suborder={suborder}
                  state={state}
                  subOrders={subOrders}
                  handleState={handleState}
                  refundSuborder={false}
                />
                {suborder.items.some(
                  item =>
                    item.refundedQuantity ||
                    (notEmptyArray(item.customisationOptions) &&
                      item.customisationOptions.some(
                        customitem => customitem.refundedQuantity
                      ))
                ) && (
                  <Fragment>
                    <RefundedItems />
                    <SubOrderItems
                      id={id}
                      suborderidx={suborderidx}
                      suborderId={suborder.id}
                      items={suborder.items}
                      suborder={suborder}
                      state={state}
                      subOrders={subOrders}
                      handleState={handleState}
                      refundSuborder={true}
                    />
                  </Fragment>
                )}
                {suborder.discountDetails && (
                  <StallDiscount
                    id={id}
                    suborderidx={suborderidx}
                    formattedDiscounts={suborder.formattedDiscounts}
                    discountDetails={suborder.discountDetails}
                  />
                )}
              </Fragment>
            )
          })}
          <CutleryOption id={id} cutlery={cutlery} />
          {state.hideTemp && (
            <ContainerFee containerFee={containerFee && containerFee[0]} />
          )}
          {feeDetails.find(a => a.type === 'CARD_SURCHARGE') &&
            state.hideTemp && (
              <CardSurcharge
                cardSurcharge={feeDetails.find(
                  a => a.type === 'CARD_SURCHARGE'
                )}
              />
            )}
        </Fragment>
      )
    },
  }
}
const refundCheckboxDisabled = (state, suborder, content) => {
  return (
    state.refundOrder &&
    suborder === STATUSES.COLLECTED &&
    content.items.every(
      item =>
        item.refundedQuantity &&
        item.finalQuantity === 0 &&
        (notEmptyArray(item.customisationOptions)
          ? item.customisationOptions.every(customitem =>
              customitem.refundedQuantity
                ? customitem.finalQuantity === 0
                : false
            )
          : true)
    )
  )
}

export {
  subOrderChangedStatuses,
  checkedSubOrders,
  subOrderStatusGroup,
  checkboxSuborderLevel,
  handleRadioChange,
  handleCheckboxDisabled,
}
export default tabularView
