import React, { Component } from 'react'
import moment from 'moment'
import { getMessage } from '../../../../lib/translator'

import './style.css'

class ProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startTime: '',
      estimatedTime: '',
    }
  }

  componentDidMount() {
    const picker = this.props.picker
    this.isComponentMounted = true
    this.setState({
      estimatedTime: picker.currentOrder.estimatedTime,
      startTime: picker.currentOrder.startTime,
    })
    this.currentTime = this.currentTime.bind(this)
    this.currentTime()
  }

  currentTime() {
    if (this.isComponentMounted) {
      this.setState({
        time: moment().format('hh:mm:ss'),
      })
    }
    setTimeout(this.currentTime, 1000)
  }

  componentWillUnmount() {
    this.isComponentMounted = false
  }

  getPointerStyle(startTime, estimatedTime, pixelPerSecond) {
    const width =
      moment.duration(estimatedTime.diff(startTime)).asSeconds() *
      pixelPerSecond
    return { left: `${width - 1}px` }
  }

  calculateEtraTimeProgressWidth(startTime, estimatedTime, pixelPerSecond) {
    const width =
      moment.duration(estimatedTime.diff(startTime)).asSeconds() *
      pixelPerSecond
    return { width: isNaN(width) ? 0 : width, border: '1px solid red' || 0 }
  }

  calculateProgressWidth(startTime, pixelPerSecond) {
    const time = moment(startTime)
    const width =
      moment.duration(moment().diff(time)).asSeconds() * pixelPerSecond
    return { width: isNaN(width) ? 0 : width || 0 }
  }

  getPixelPerSeconds(remainnigTime) {
    return 280 / remainnigTime
  }

  getDuration(startTime, estimatedTime) {
    return moment.duration(estimatedTime.diff(startTime)).asSeconds()
  }

  getExtraTime(startTime) {
    const time = moment(startTime)
    return moment.duration(moment().diff(time)).asSeconds()
  }

  render() {
    const { startTime, estimatedTime } = this.state
    const remainnigTime =
      startTime &&
      estimatedTime &&
      this.getDuration(moment(startTime), moment(estimatedTime))
    const pixel = remainnigTime && this.getPixelPerSeconds(remainnigTime)
    const extraTime = startTime && this.getExtraTime(moment(startTime))

    const pixel2 = extraTime && this.getPixelPerSeconds(extraTime)

    const isOverTime = moment(estimatedTime) >= moment()
    const style = isOverTime
      ? this.calculateProgressWidth(moment(startTime), pixel)
      : this.calculateEtraTimeProgressWidth(
          moment(startTime),
          moment(estimatedTime),
          pixel2
        )
    const pointerStyle = isOverTime
      ? undefined
      : this.getPointerStyle(moment(startTime), moment(estimatedTime), pixel2)

    return (
      <React.Fragment>
        <div
          className="progress-bar"
          style={!isOverTime ? { borderColor: 'red' } : {}}
        >
          <span className="in-progress" style={style} />
          {!isOverTime && (
            <div className="time-pointer" style={pointerStyle}>
              <span className="tooltip-text">
                {getMessage('pickers.today.slot.estimate.time') +
                  moment(estimatedTime).format('hh:mm A')}
              </span>
            </div>
          )}
        </div>
        <div className="pickers-progress-time">
          <span>
            {getMessage('pickers.today.slot.starttime')}
            {moment(startTime).format('hh:mm A')}
          </span>
          <span>
            {!isOverTime
              ? getMessage('pickers.today.slot.current.time') +
                moment().format('hh:mm:ss A')
              : getMessage('pickers.today.slot.estimate.time') +
                moment(estimatedTime).format('hh:mm A')}
          </span>
        </div>
      </React.Fragment>
    )
  }
}

export default ProgressBar
