import React from 'react'
import { BaseForm, Input, Radio, VALIDATION_TYPES } from 'components/Form'
import Loader from 'components/Loader'
import { Dialog } from 'components/Popup'
import { getMessage } from 'lib/translator'
import { formatValidity, STATUS_OPTIONS } from 'components/AppHome/utils'
import Validity from 'components/AppHome/Validity'
import UploadPopup from 'components/AppHome/AppSplash/UploadPopup'
import SplashSection from 'components/AppHome/AppSplash/SplashSection'
import MissingInfoDialog from 'components/AppHome/MissingInfoDialog'
import './style.css'
import {
  getActualDimension,
  getExpectedDimension,
} from 'components/AppHome/AppSplash/utils'

class ManageSplashscreenForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      missingItems: [],
      showErrorDialog: false,
      showUploadPopup: false,
      showMissingInfoDialog: false,
      uploadType: null,
      errorMessage: null,
      errorField: false,
      readOnlyField: false,
      selectedLogo: null,
      values: {
        startTime: '00:00:00',
        endTime: '00:00:00',
        splash: {
          background: null,
          logo: null,
          topAnimation: null,
          bottomAnimation: null,
        },
      },
    }

    this.validateDraft = this.validateDraft.bind(this)
    this.transformFormData = this.transformFormData.bind(this)
    this.checkForMissingItems = this.checkForMissingItems.bind(this)
    this.saveDraftHandler = this.saveDraftHandler.bind(this)
    this.previewButtonHandler = this.previewButtonHandler.bind(this)
    this.uploadPopupHandler = this.uploadPopupHandler.bind(this)
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this)
    this.verifyDimension = this.verifyDimension.bind(this)
  }

  async componentDidMount() {
    const { method } = this.props
    if (method === 'edit') {
      this.setState({ loading: true })
      const { value } = this.props
      value.id === 'splash-Default' && this.setState({ readOnlyField: true })
      const validity = await formatValidity('api', value)
      const values = {
        ...value,
        ...validity,
      }
      this.setState({ values, loading: false })
    }
  }

  async validateDraft() {
    const { method } = this.props
    if (method === 'add' && !this.state.values.name) {
      this.setState({
        errorField: true,
        showErrorDialog: true,
        errorMessage: getMessage('appbanner.errorMessage03'),
      })
    } else {
      this.setState({ errorField: false })
      const { missingItems } = this.checkForMissingItems()
      if (missingItems.length > 0) {
        this.setState({
          showMissingInfoDialog: true,
          missingItems,
        })
      } else {
        await this.saveDraftHandler()
      }
    }
  }

  async transformFormData(isEnable = true) {
    const { values } = this.state
    const validity = await formatValidity('form', values)
    return {
      ...this.state.values,
      ...validity,
      isEnable,
    }
  }

  checkForMissingItems() {
    const missingItems = []
    const { splash, startDate, endDate } = this.state.values
    if (!startDate) {
      missingItems.push('startDate')
    }
    if (!endDate) {
      missingItems.push('endDate')
    }
    if (!splash.background) {
      missingItems.push('background')
    }

    return { missingItems }
  }

  previewButtonHandler(type) {
    if (type === 'reset') {
      const values = Object.assign({}, this.state.values)
      values.splash = {
        background: null,
        logo: null,
        topAnimation: null,
        bottomAnimation: null,
      }
      this.setState({ values, selectedLogo: null })
    } else {
      this.setState({ uploadType: type, showUploadPopup: true })
    }
  }

  async saveDraftHandler() {
    const data = await this.transformFormData(false)
    await this.props.onSubmit(data)
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const { onSubmit } = this.props
    const { missingItems } = this.checkForMissingItems()
    if (missingItems.includes('background') || !this.isFormValid()) {
      this.setState({ missingItems })
    } else {
      this.setState({
        missingItems: [],
      })
      const data = await this.transformFormData()
      await onSubmit(data)
    }
  }

  uploadPopupHandler(show) {
    this.setState({ showUploadPopup: show })
  }

  async verifyDimension(type) {
    const url = this.state.values.splash[type]
    const { maxHeight, maxWidth, errorMessage } = getExpectedDimension(type)
    const { actualWidth, actualHeight } = await getActualDimension(url)
    const values = { ...this.state.values }
    if (actualWidth > maxWidth || actualHeight > maxHeight) {
      values.splash[type] = null
      this.setState({
        showUploadPopup: false,
        showErrorDialog: true,
        errorMessage,
        values,
      })
    }
  }

  checkboxChangeHandler(type, e) {
    const LOGO = {
      colour: {
        src: 'https://preprod-media.nedigital.sg/fairprice/images/fc412aa9-23ac-46e4-b86e-d00ef54da46e/FPG50-Color.png',
      },
      white: {
        src: 'https://preprod-media.nedigital.sg/fairprice/images/ed8a80be-98c5-45be-ba87-5f46a9fd4253/FPG50-White.png',
      },
      custom: { src: e },
    }

    const values = Object.assign({}, this.state.values)
    values.splash.logo = LOGO[type].src
    this.setState({ values, selectedLogo: type })
  }

  render() {
    const { Form } = this.components
    const {
      loading,
      uploadType,
      showUploadPopup,
      showMissingInfoDialog,
      selectedLogo,
      pressedSubmitWithCurrentData,
      readOnlyField,
      missingItems,
    } = this.state
    const { method } = this.props
    const { CancelButton, SubmitButton } = this.buttons
    const { splash, isEnable } = this.state.values

    return (
      <div className="appsplash-form">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <Dialog
              show={this.state.showErrorDialog}
              information={this.state.errorMessage}
              close={() =>
                this.setState({
                  showErrorDialog: false,
                  errorMessage: null,
                })
              }
              closeText={getMessage('apphome.okay')}
            />
            <MissingInfoDialog
              showMissingInfoDialog={showMissingInfoDialog}
              closeMissingInfoDialog={() =>
                this.setState({ showMissingInfoDialog: false })
              }
              missingKeys={missingItems}
              onOkHandler={this.saveDraftHandler}
            />
            <UploadPopup
              selectedLogo={selectedLogo}
              uploadPopupHandler={this.uploadPopupHandler}
              checkboxChangeHandler={this.checkboxChangeHandler}
              uploadType={uploadType}
              showUploadPopup={showUploadPopup}
              generateStateMappers={this.generateStateMappers}
              verifyDimension={this.verifyDimension}
            />
            <SplashSection
              previewButtonHandler={this.previewButtonHandler}
              splash={splash}
              missingItems={missingItems}
              pressedSubmitWithCurrentData={pressedSubmitWithCurrentData}
            />
            <div className="name-status-section">
              <Input
                required
                type="text"
                testid="campaignName"
                className={`campaignName ${
                  this.state.errorField && 'input-error'
                }`}
                label={getMessage('app.campaign.name')}
                placeholder={getMessage('app.campaign.name.placeholder')}
                {...this.generateStateMappers({
                  stateKeys: ['name'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                disabled={method === 'edit'}
              />
              {method === 'edit' && (
                <Radio
                  label="Status"
                  disabled={true}
                  options={STATUS_OPTIONS}
                  value={isEnable}
                  className="campaignStatus"
                />
              )}
            </div>
            <Validity
              readOnlyField={readOnlyField}
              generateStateMappers={this.generateStateMappers}
            />
            <div className="appFormButtons">
              <>
                <CancelButton>{getMessage('apphome.cancel')}</CancelButton>
              </>
              <button
                type="button"
                className="primary"
                data-testid="save-button"
                onClick={this.validateDraft}
              >
                {getMessage('apphome.saveasdraft')}
              </button>
              <SubmitButton>{getMessage('apphome.enable')}</SubmitButton>
            </div>
          </Form>
        )}
      </div>
    )
  }
}

export default ManageSplashscreenForm
