import { Popup } from '../../Popup'
import './style.css'
import React, { Fragment } from 'react'
import SummaryTable from '../../../containers/SummaryTable'
import PendingIcon from './pending.svg'
import { getMessage } from '../../../lib/translator'
import { usePopperTooltip } from 'react-popper-tooltip'
import infoIcon from '../../../components/Form/info-icon.svg'
import Close from '../../../components/FinalTotalAmount/close.svg'
import 'react-popper-tooltip/dist/styles.css'

const tooltipContent = (setControlledVisible, controlledVisible) => {
  return (
    <div className="custom-tooltip-container">
      <div className="custom-tooltop-desc">
        <b>{getMessage('qc.orderSummary.AppPaymentDiscountTooltipLabel')}</b>
        <button onClick={() => setControlledVisible(!controlledVisible)}>
          <img src={Close} alt="Close" />
        </button>
      </div>
      {getMessage('qc.orderSummary.AppPaymentDiscountTooltipDescription')}
    </div>
  )
}

const TablePopup = ({
  show,
  close,
  heading,
  submitButtonText,
  backButtonText,
  handleSubmit,
  data,
  tableProperties,
  testid,
  primaryKey,
  amountText,
  totalAmountText,
  Amount,
  Prepared,
  containerFee,
  cardSurchargeText,
  cardSurcharge,
}) => {
  const [controlledVisible, setControlledVisible] = React.useState(false)
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'click',
    closeOnOutsideClick: false,
    delayHide: 5000,
    interactive: true,
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
  })
  return (
    <Popup className="tablePopup" show={show} close={close} nohide={true}>
      <div className="prepared-order">
        {Prepared && <img src={PendingIcon} alt="Pending icon" />}
        <div>
          <h3>{heading}</h3>
          {Prepared && (
            <h4>
              {getMessage('qc.orderSummary.tablepopup.delivery.cancelled')}
            </h4>
          )}
        </div>
      </div>
      <SummaryTable
        className="scroll-table"
        data={data}
        tableProperties={tableProperties}
        primaryKey={primaryKey}
        testid={testid}
        tableDynamic
      />

      <div className="amount-calculation">
        <h3>{amountText}</h3> <b>{Amount.leftPrice}</b>
      </div>

      {Object.keys(containerFee).length > 0 && (
        <Fragment>
          <Fragment>
            <div className="amount-calculation">
              <h3>{getMessage('qc.orderSummary.containerFee.return')}</h3>
              <b>{containerFee.formattedPrice}</b>
            </div>
          </Fragment>
        </Fragment>
      )}
      {Amount.customAppDiscount !== '$0.00' && (
        <div className="amount-calculation">
          <h3>
            {getMessage('qc.orderSummary.AppPaymentDiscountVoided')} (
            {Amount.customAppDiscount.percentage
              ? Amount.customAppDiscount.percentage
              : 10}
            %)
            <button
              type="button"
              ref={setTriggerRef}
              className="discount-info-btn"
            >
              <img src={infoIcon} alt="infoIcon" />
            </button>
          </h3>
          <b>-{data[0].customPopupAppDiscountPrice}</b>
        </div>
      )}
      {!!cardSurcharge && (
        <div className="amount-calculation">
          <h3>
            {cardSurchargeText}{' '}
            {cardSurcharge.isWaived && (
              <Fragment>
                ({getMessage('qc.orderSummary.CardSurcharge.Waived')})
              </Fragment>
            )}
          </h3>{' '}
          <div
            className={`${(cardSurcharge.formattedPayable === '$0.00' ||
              cardSurcharge.isWaived) &&
              'line-through'}`}
          >
            {cardSurcharge.formattedPrice}
          </div>
        </div>
      )}

      <div className="amount-calculation">
        <h3>{totalAmountText}</h3> <b>{Amount.totalPrice}</b>
      </div>
      <div className="button-container">
        <button
          onClick={handleSubmit}
          className="primary button"
          data-testid="tablepopup-submit"
        >
          {submitButtonText}
        </button>
        <button onClick={close} className="button" id="tablepopup-close">
          {backButtonText}
        </button>
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          data-popper-interactive="true"
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          {tooltipContent(setControlledVisible, controlledVisible)}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </Popup>
  )
}

export default TablePopup
