import React, { useState } from 'react'
import { Popup } from 'components/Popup'
import { Field, useFormikContext } from 'formik'
import { Button, View } from '../ui'
import { FormikInput } from './FormikInput'
import API from 'lib/api'
import {
  errorToast,
  handleMarkOrderResponse,
  refreshedOrderDetail,
} from '../helper'

export const MarkAsCollected = ({ isOpen, toggleModal }) => {
  const { values, setFieldValue } = useFormikContext()
  const [isLoading, setLoading] = useState(false)

  const handleConfirm = () => {
    setLoading(true)
    const orderId = values.orderDetailData.salesOrderReferenceNumber
    new API({
      url: `/order-service/v3/deliveryOrders/${orderId}/complete`,
    })
      .post({
        completeTime: new Date().toISOString(),
        staffId: values.collectedStaffId,
      })
      .then((_) => {
        return refreshedOrderDetail(orderId)
      })
      .then((res) => {
        handleMarkOrderResponse(res?.deliveryOrder, setFieldValue, toggleModal)
      })
      .catch((_) => {
        errorToast('MARK_ORDER_AS_COMPLETED', 'Something went wrong...')
        toggleModal()
      })
  }

  return (
    <Popup
      show={isOpen}
      close={() => !isLoading && toggleModal()}
      heading="Mark as collected"
      className="confirmPreorderPopup"
    >
      <View data-testid="markascollected-modal">
        Verify customer`&lsquo;`s receipt and ensure all items match their
        order. Enter your Staff ID to confirm collection.
      </View>
      <Field
        component={FormikInput}
        name="collectedStaffId"
        label="STAFF ID"
        placeholder="Enter staff ID"
      />

      <View justifyContent="end">
        <Button
          width="50%"
          disabled={!values.collectedStaffId}
          onClick={handleConfirm}
          fontSize={12}
          loading={isLoading}
        >
          Confirm
        </Button>
      </View>
    </Popup>
  )
}
