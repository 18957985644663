import React, { Component } from 'react'
import { Popup } from '../../../Popup'
import API from '../../../../lib/api'
import { compareValues } from '../../../../lib/stateManagement'

import './style.css'
import placeholderIcon from './imageUpload.svg'
import ImageSlider from '../../../../pages/catalogue/Products/Form/ImageSlider'

/* TODOs
  1. If any image is uploading, mark it as invalid. validity.isUploading = true (?)
*/

function getValidationObj(props, value) {
  // Initial value for reduce method
  const initialState = {
    valueMissing: Boolean(props.required),
    isUploading: false,
  }
  initialState.valid = !initialState.valueMissing && !initialState.isUploading
  const individualValidations = value.map((data) => {
    const isUploading = Boolean(data.uploading)
    const valid = !isUploading
    return {
      isUploading,
      valid,
    }
  })
  return individualValidations.reduce((validationRule, acc) => {
    const valueMissing = false // False, because this loop ran because there was an item
    const isUploading = validationRule.isUploading || acc.isUploading
    const valid = !valueMissing && !isUploading
    return {
      valueMissing,
      isUploading,
      valid,
    }
  }, initialState)
}

function upload(file) {
  // Upload file to server
  return new Promise((resolve, reject) => {
    const data = new window.FormData()
    data.append('imageUpload', file)
    data.append('cache', '1y')
    const api = new API({ url: '/media-service/v2/image-upload' })
    api
      .post(data)
      .then((response) => {
        resolve(response.data.imageUpload.url)
      }, reject)
      .catch(reject)
  })
}

class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this.state = this.deriveStateFromProps(props, {
      images: [],
    })
    this.handleFileSelection = this.handleFileSelection.bind(this)
    this.confirmFileSelection = this.confirmFileSelection.bind(this)
    this.resetInputRef = this.resetInputRef.bind(this)
    this.clearFileSelection = this.clearFileSelection.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.uploadFiles = this.uploadFiles.bind(this)
    this.triggerValidation = this.triggerValidation.bind(this)
    this.registerChange = this.registerChange.bind(this)
  }
  triggerValidation() {
    const validation = getValidationObj(this.props, this.state.images)
    this.props.onValidation && this.props.onValidation(validation)
  }
  registerChange() {
    this.props.onChange &&
      this.props.onChange(
        this.state.images.map((data) => data.url).filter(Boolean)
      )
    this.triggerValidation()
  }
  parseFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new window.FileReader()
      reader.onloadend = () => {
        resolve({
          file,
          blob: reader.result,
        })
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }
  handleFileSelection(e) {
    e && e.preventDefault()
    // When user has selected files from file browser
    const files = Array.prototype.slice.call(e.target.files)
    // Parse files as data URLs and show them in a dialog window to confirm
    if (files.length) {
      Promise.all(files.map(this.parseFile)).then((selectedFiles) => {
        this.setState({ selectedFiles })
      })
    }
  }
  resetInputRef() {
    this.inputRef.value = null
  }
  confirmFileSelection(e) {
    e && e.preventDefault()
    // First set these items into current state
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      prevState.selectedFiles.forEach((selectedFile) => {
        newState.images.push(
          Object.assign({}, selectedFile, {
            uploading: false,
            uid: Symbol('uid'),
          })
        )
      })
      newState.selectedFiles = null
      return newState
    }, this.uploadFiles)
    this.resetInputRef()
  }
  uploadFiles() {
    const filesToUpload = this.state.images.filter(
      (image) => !image.url && !image.uploading
    )
    filesToUpload.forEach((image) => {
      this.setState(
        (prevState) => {
          const newState = Object.assign({}, prevState)
          const index = prevState.images.findIndex(
            (data) => data.uid === image.uid
          )
          if (index > -1) {
            newState.images[index].uploading = true
          }
          return newState
        },
        () => {
          this.triggerValidation()
          upload(image.file).then(
            (url) => {
              const { uid } = image
              this.setState((prevState) => {
                const newState = Object.assign({}, prevState)
                const index = prevState.images.findIndex(
                  (img) => img.uid === uid
                )
                if (index > -1) {
                  newState.images[index].url = url
                  newState.images[index].uploading = false
                }
                return newState
              }, this.registerChange)
            },
            (error) => {
              const { uid } = image
              this.setState((prevState) => {
                const newState = Object.assign({}, prevState)
                const index = prevState.images.findIndex(
                  (img) => img.uid === uid
                )
                if (index > -1) {
                  newState.images[index].uploading = false
                  newState.images[index].error = error
                }
                return newState
              }, this.triggerValidation)
            }
          )
        }
      )
    })
  }
  clearFileSelection(e) {
    e && e.preventDefault()
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.selectedFiles = null
      return newState
    })
    this.resetInputRef()
  }
  removeItem(index) {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.images = [
        ...prevState.images.slice(0, index),
        ...prevState.images.slice(index + 1),
      ]
      return newState
    }, this.registerChange)
  }
  deriveStateFromProps(props, oldState) {
    const newState = Object.assign({}, oldState)
    // How to update a given state from an updated set up of props
    if (props.value) {
      let newValues
      if (Array.isArray(props.value)) {
        newValues = props.value
      } else {
        newValues = [props.value]
      }
      if (!props.multiple) {
        newValues = newValues.slice(0, 1)
      }
      newState.images = newValues.map((value) => {
        // If an image in the props exists in the state, merge the data
        // from both sources, else just accept new image URL
        const objInState = oldState.images.find(({ url }) => url === value)
        return Object.assign({}, objInState, {
          url: value,
          uploading: false,
        })
      })
      // If any images are still uploading, keep them in the state
      newState.images = [
        ...newState.images,
        ...oldState.images.filter(({ uploading }) => uploading),
      ]
    } else {
      newState.images = []
    }
    return newState
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    // Update current state depending on change of `props.value`
    if (!compareValues(newProps.value, this.props.value)) {
      // TODO: Use immutable data structures to do this in a better way
      this.setState((prevState) =>
        this.deriveStateFromProps(newProps, prevState)
      )
    }
  }
  componentDidMount() {
    this.triggerValidation()
  }
  render() {
    // TODO: Add support for replacing default messages with localized strings
    return (
      <div className="ImageUpload">
        <div
          className={
            'image-upload-container' +
            (this.state.images.length ? ' with-items' : '')
          }
        >
          <div className="input-area">
            <input
              ref={(inputRef) => {
                this.inputRef = inputRef
              }}
              type="file"
              name="logo"
              required={this.props.required}
              multiple={this.props.multiple}
              onChange={this.handleFileSelection}
            />
            <div className="image-upload-placeholder">
              <div className="image-upload-placeholder-content">
                <img
                  className="image-upload-placeholder-icon"
                  src={placeholderIcon}
                  alt=""
                />
                <div className="image-upload-placeholder-text">
                  {this.props.placeholder}
                </div>
              </div>
            </div>
          </div>
          {this.state.images.length ? (
            !this.props.isProductCreatePage ? (
              <div className="preview-area-container">
                {this.state.images.map((data, index) => (
                  <div key={index} className="image-preview-container">
                    {data.uploading ? (
                      <div className="loading-icon">Uploading...</div>
                    ) : null}
                    {data.error ? (
                      <div className="loading-icon">Error in uploading</div>
                    ) : null}
                    <div
                      className="delete-icon"
                      onClick={() => {
                        this.removeItem(index)
                      }}
                    >
                      &times;
                    </div>
                    <img src={data.url || data.blob} alt="" />
                  </div>
                ))}
              </div>
            ) : (
              <ImageSlider
                images={this.state.images || []}
                removeItem={this.removeItem}
              />
            )
          ) : null}
          {this.state.selectedFiles ? (
            <Popup
              show
              heading={'Confirm selection'}
              close={this.clearFileSelection}
              className="editPopup image-confirmation-popup"
            >
              <div className="image-selection-preview">
                {this.state.selectedFiles.map((image, index) => (
                  <img key={index} src={image.blob} alt="" />
                ))}
              </div>
              <div className="image-selection-buttons">
                <button
                  className="button image-selection-button"
                  onClick={this.confirmFileSelection}
                >
                  Confirm
                </button>
              </div>
            </Popup>
          ) : null}
        </div>
      </div>
    )
  }
}

export default ImageUpload
