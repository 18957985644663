import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getPrintableTime } from 'lib/datetime';
import { Row, Cell } from 'components/Table';
import { getMessage } from 'lib/translator';
import { getCurrency } from 'lib/userDetails';
import { DropDown, DropDownItem, ICONS } from 'components/DropDown';
import { Address as CustomerMapWidget } from 'containers/CustomerWidgets';
import _ from 'lodash';
import pickupImage from 'icons/pickup.svg';
import deliveyImage from 'icons/delivery.svg';
import offlineImage from 'icons/offline.svg';
import B2BImage from 'icons/b2b.svg';
import RbPreorderImage from 'icons/RB_Preorder_icon.svg'
import Image from 'components/Image';
import 'react-popper-tooltip/dist/styles.css';
import './style.scss';


const orderTypeImages = {
  PICKUP: pickupImage,
  DELIVERY: deliveyImage,
  B2B: B2BImage,
  OFFLINE: offlineImage,
  RB_PREORDER: RbPreorderImage
}

const tableHeaders = [
  getMessage('saleOrder.table.header.saleOrderNumber'),
  getMessage('saleOrder.table.header.customer'),
  getMessage('saleOrder.table.header.placedTime'),
  getMessage('saleOrder.table.header.amount'),
  getMessage('saleOrder.table.header.status'),
  getMessage('saleOrder.table.header.completedTime'),
  getMessage('saleOrder.table.header.actions')
]

const SalesOrderTableView = (url) => {
  return {
    headers: tableHeaders,
    row: ({
      createTime,
      referenceNumber,
      status,
      total,
      type,
      deliveryOrders,
      completedAt,
      customer
    }) => {
      const totalItemsCount = deliveryOrders.flatMap(x => x.items).length;
      const getDate = (dateTime) => dateTime && getPrintableTime(dateTime).split(', ')[0];
      const getTime = (dateTime) => dateTime && getPrintableTime(dateTime).split(', ')[1];

      return (
        <Fragment>
          <Row className='sale-order-table-row' key={`row-${referenceNumber}`}>
            <Cell className="sale-order-num">
              <div className="order-type-icons">
                <Image className="order-type-image" src={orderTypeImages[type]} />
              </div>
              <div className='vertical-col'>
                <Link to={`/customer-support/sale-orders/${referenceNumber}`}>
                  <div className='reference-number'>{referenceNumber}</div>
                </Link>
                <div className='text-muted'>{deliveryOrders.length} {getMessage('operations.dashboard.deliveryOrders')}</div>
              </div>
            </Cell>
            <Cell className="customer">
              {customer && (
                  <Link to={`/customer-support/customers/view/${customer.id}`}>
                    <div className='name' style={{wordBreak: 'break-word'}}>{customer.name}</div>
                  </Link>
              )}
              {customer?.address && (
                <div className='address'>
                  <CustomerMapWidget address={`${customer.address.streetAddress}, ${customer.address.city}, ${customer.address.postalCode}`}/>
                </div>
              )}
            </Cell>
            <Cell className="placed-time">
              <div>{getDate(createTime)}</div>
              <div className="time text-muted">{getTime(createTime)}</div>
            </Cell>
            <Cell className="total-amount">
              <div>{getCurrency().symbol}{total.toFixed(2)}</div>
              <div className='items-count text-muted'>{totalItemsCount} {getMessage('pickers.today.picking.item2')}</div>
            </Cell>
            <Cell className="status">{_.capitalize(status)}</Cell>
            <Cell className="completed-time">
              <div>{getDate(completedAt)}</div>
              <div className="time text-muted">{getTime(completedAt)}</div>
            </Cell>
            <Cell className="actions">
              <div>
                <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
                  <DropDownItem>
                    <Link to={`${url}/sale-orders/${referenceNumber}`}>
                      {getMessage('order.action.viewDetails')}
                    </Link>
                  </DropDownItem>
                </DropDown>
              </div>
            </Cell>
          </Row>
        </Fragment>
      )
    }
  }
}

export default SalesOrderTableView;
