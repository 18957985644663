import classNames from 'classnames'
import React from 'react'

export const _PlainInput = ({
  readOnly,
  error,
  color,
  textAlign,
  inputWrapperStyle,
  inputStyle = {},
  ...rest
}) => {
  return (
    <div style={inputWrapperStyle}>
      <input
        className={classNames({ 'focus-none': readOnly })}
        onWheel={() => document.activeElement.blur()}
        style={{ color, textAlign, ...inputStyle }}
        type="text"
        {...rest}
      />
      {error && <div className="input-error-message">{error}</div>}
    </div>
  )
}
