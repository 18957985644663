import React from 'react'
import { Text, View } from '../ui'
import { Field } from 'formik'

export const PreorderTime = ({ timeSlot, slotId, handleTimeChange }) => {
  return (
    <View
      justifyContent="space-between"
      background={timeSlot.available ? '' : '#F2F2F2'}
      padding={16}
      margin="0 0 8px"
      border="1px solid #EAEAEA"
      data-testid={`slot-${slotId}-${timeSlot.available ? 'available' : 'unavailable'}`}
    >
      <Text variant="label" fontSize={12} fontWeight={700}>
        {timeSlot.text}
      </Text>
      <Field
        type="radio"
        value={timeSlot.text}
        name="pickupTime"
        disabled={!timeSlot.available}
        onChange={(e) => {
          handleTimeChange(e, slotId)
        }}
      />
    </View>
  )
}
