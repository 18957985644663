import React from 'react'
import PublicPage from '../../../containers/PublicPage/index'

import AccountCreatedImage from './images/account-created.svg'
import '../Verify/verify.css'

export default function AccountCreated() {
  return (
    <PublicPage className="eazy-page">
      <div className="verify">
        <img src={AccountCreatedImage} alt="Account created" />
        <header className="header">
          <h1 className="heading">We have created an account for you!</h1>
          <h2 className="span subheading">
            A verification email has been sent to your email address.
          </h2>
          <h2 className="subheading">
            Please click the link in your email to verify.{' '}
          </h2>
        </header>
      </div>
    </PublicPage>
  )
}
