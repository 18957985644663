import React, { Component } from 'react'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { getMessage } from '../../../../lib/translator'
import { getSession } from '../../../../lib/auth'
import {
  Input,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import './style.css'

export default class Advertisers extends Component{
  constructor(props){
    super(props)
    this.state = {
      isOpenUploadBox: false,
      selectedAd: {},
      advertiserList: this.props.advertiserList
    }
    this.getAdName = this.getAdName.bind(this)
    this.getTotalBudget = this.getTotalBudget.bind(this)
  }

  getAdName(id){
    let name = ''
    for (let i = 0; i < this.state.advertiserList.length; i++){
      if (this.state.advertiserList[i].id === id){
        name = this.state.advertiserList[i].advertiserName
      }
    }
    return name
  }

  getTotalBudget () {
    let budget = 0
    for (let i = 0; i < this.props.selectedAdvertisers?.length; i++) {
      budget += Number((this.props.values && this.props.values['budget_'+i]) || 0)
    }
    return budget
  }

  render() {
    const { isMobileView, selectedAdvertisers, generateStateMappers } = this.props
    return(
      <div className='advertisers_table'>
        <div className="title">{getMessage('media.campaigndetail.advertiser.title')}</div>
        <Table>
          <Header>
            <Cell>{getMessage('media.adsetdetail.advertisers.rowheaders.name')}</Cell>
            <Cell>{getMessage('media.adsetdetail.info.budget')}</Cell>
          </Header>
          {selectedAdvertisers.map((id, index) => (
            <Row key={index}>
              <Cell className="website-page-name name">
                {isMobileView && (
                  <div className="text-muted">
                    {getMessage('media.adsetdetail.advertisers.rowheaders.name')}
                  </div>
                )}
                {this.getAdName(id)}
              </Cell>
              <Cell className="budget-input website-page-url">
                {isMobileView && (
                  <div className="text-muted">
                    {getMessage('media.adsetdetail.info.budget')}
                  </div>
                )}
                <Input
                  placeholder={getMessage('media.adsetdetail.info.budget.placeholder')}
                  name="budget"
                  type="number"
                  min={0}
                  step="0.01"
                  prefix={getSession().organization?.currency?.symbol}
                  required
                  {...generateStateMappers({
                    stateKeys: [`budget_${index}`],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  validationStrings={{
                    valueMissing: getMessage(
                      'media.adsetdetail.info.budget.requiredMessage'
                    ),
                  }}
                />
              </Cell>
            </Row>
          ))}
        </Table>
        <div className='totalBudget'><small>Total Budget: ${this.getTotalBudget()}</small></div>
      </div>
    )
  }
}
