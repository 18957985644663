import Loader from 'components/Loader'
import _get from 'lodash.get'
import { format, parse } from 'libphonenumber-js'
import React, { useEffect, useState } from 'react'
import Image from '../../../components/Image'
import { Dialog } from '../../../components/Popup'
import Tabs from '../../../components/Tabs'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import { STORE_TECH_BASE_URL } from '../../customer-support/constants'
import styles from './SngActions.module.css'

const tabs = {
  DEVICES: 0,
  LOGS: 1,
}

export const generateAvatarWithName = (name) =>
  name
    ? `https://ui-avatars.com/api/?name=${encodeURIComponent(
        name
      )}&rounded=true&format=svg&background=1557bf&color=ffffff`
    : ''

export const SngActions = (props) => {
  const { customerId } = props.match.params
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState(null)
  const [, setActiveTab] = useState(tabs.DEVICES)

  const resetErrorMessage = () => setMessage('')

  const changeTab = (v) => {
    setActiveTab(v)
  }
  const handleUnblock = () => {
    const api = new API({
      url: `${STORE_TECH_BASE_URL}/store/v0.2/customers/unblock/${customerId}`,
    })

    setLoading(true)
    resetErrorMessage()

    api
      .put()
      .then(() => {
        setMessage(getMessage('sng-actions.text.unblock.success'))
      })
      .catch((e) => {
        setMessage(
          _get(e, 'message', getMessage('sng-actions.text.unblock.fail'))
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleBlock = () => {
    const api = new API({
      url: `${STORE_TECH_BASE_URL}/store/v0.2/customers/block/${customerId}`,
    })

    setLoading(true)
    resetErrorMessage()

    api
      .put()
      .then(() => {
        setMessage(getMessage('sng-actions.text.block.success'))
      })
      .catch((e) => {
        setMessage(
          _get(e, 'message', getMessage('sng-actions.text.block.fail'))
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleBlacklist = () => {
    const api = new API({
      url: `${STORE_TECH_BASE_URL}/store/v0.2/customers/blacklist/${customerId}`,
    })

    setLoading(true)
    resetErrorMessage()

    api
      .put({ isBlackListed: true })
      .then(() => {
        setMessage(getMessage('sng-actions.text.blacklist.success'))
      })
      .catch((e) => {
        setMessage(
          _get(e, 'message', getMessage('sng-actions.text.blacklist.fail'))
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const fetchUserData = async () => {
    const customerAPI = new API({
      url: `/ef-customer-core/profile/${customerId}?fullresponse=true`,
    })

    const sngStatusApi = new API({
      url: `${STORE_TECH_BASE_URL}/store/v0.2/customers/status/${customerId}`,
    })

    let customerData
    let sngData
    try {
      customerData = await customerAPI.get().then((r) => r.data)
      sngData = await sngStatusApi.get().then((r) => r.data)
    } catch (error) {
      sngData = {
        isBlackListed: false,
        isBlocked: false,
      }
    } finally {
      const result = {
        ...customerData,
        ...sngData,
      }
      setCustomer(result)
    }
  }
  useEffect(() => {
    fetchUserData()
  }, [])

  const { name, phone, email, image, uid } = customer || {}

  return (
    <AuthenticatedPage
      menu={props.menu}
      title={getMessage('sng-actions.dashboard.title')}
    >
      <Dialog
        show={!!message}
        close={resetErrorMessage}
        information={message}
        closeText={getMessage('dialog.cancelText')}
        okText={getMessage('dialog.okText')}
      />
      {customer && customerId && (
        <>
          <h1 className="heading">{name}</h1>
          <div className={styles.customerDetails}>
            <Image src={image || generateAvatarWithName(name)} />
            <div className="detail">
              <div>{name}</div>

              <div>{email}</div>
              <div>
                {phone &&
                  phone.length > 0 &&
                  format(parse(phone), 'International')}
              </div>
              <br />
              <div className={styles.uid}>UID: {uid}</div>
              {customer.isBlackListed && (
                <div className="bold">
                  {getMessage('sng-actions.text.blacklist.info')}
                </div>
              )}
              <p>{loading && <Loader size="sm" />}</p>
              <div className={styles.actions}>
                {customer.isBlocked ? (
                  <button
                    disabled={loading}
                    className="button"
                    onClick={handleUnblock}
                  >
                    {getMessage('sng-actions.button.unblock')}
                  </button>
                ) : (
                  <button
                    disabled={loading}
                    className={`${styles.blockButton} button`}
                    onClick={handleBlock}
                  >
                    {getMessage('sng-actions.button.block')}
                  </button>
                )}

                {!customer.isBlackListed && (
                  <button
                    disabled={loading}
                    className={`${styles.blacklistButton} button`}
                    onClick={handleBlacklist}
                  >
                    {getMessage('sng-actions.button.blacklist')}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={styles.tabWrapper}>
            <Tabs
              items={[
                getMessage('sng-actions.tabs.devices'),
                getMessage('sng-actions.tabs.logs'),
              ]}
              default={tabs.DEVICES}
              onClick={changeTab}
            />
          </div>
        </>
      )}
    </AuthenticatedPage>
  )
}
