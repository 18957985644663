import { getMessage } from 'lib/translator'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../Table'
import {
  ColumnDate,
  ColumnName,
  ColumnStatus,
  getStatus,
  TableAction,
} from '../utils'
import ImagePlaceHolder from '../../Image/image-placeholder.svg'
import React from 'react'

const DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

const ANALYTICS_PLACEHOLDER = `Enter analytics in JSON format. Example:\n{\n  "amp": {\n      "event_name": "voucher clicked",\n      "event_properties":  {\n\n      }\n   }\n}`

const ANALYTIC_SCHEMA = {
  id: '/AnalyticSchema',
  type: 'object',
  additionalProperties: true,
  properties: {
    amp: { type: ['object', 'null'] },
  },
}

const linkButtons = () => {
  return (
    <div className="header-wrap">
      <Link to="/app-home/popup/add">
        <button className="primary button">
          <span className="text">{getMessage('app.popup.add')}</span>
        </button>
      </Link>
    </div>
  )
}

const tableProperties = () => {
  return {
    headers: ['Image', 'Title', 'Valid From', 'Valid To', 'Status', 'Actions'],
    row: ({
      pk,
      title,
      imageUrl,
      startTime,
      endTime,
      onAction,
      isDisabled,
      sk,
    }) => {
      const status = isDisabled
        ? getMessage('apphome.disabled')
        : getStatus(startTime, endTime)
      return (
        <Row>
          <Cell className="column-img-url">
            <img
              src={imageUrl ? imageUrl : ImagePlaceHolder}
              alt="popup image"
            />
          </Cell>
          <ColumnName type="popup" id={sk} name={title} />
          <ColumnDate type="start" time={startTime} />
          <ColumnDate type="end" time={endTime} />
          <ColumnStatus status={status} />
          <Cell className="column-actions">
            <TableAction
              id={sk}
              pk={pk}
              onAction={onAction}
              isEnable={!isDisabled}
              type="popup"
            />
          </Cell>
        </Row>
      )
    },
  }
}

export {
  DAYS,
  ANALYTIC_SCHEMA,
  ANALYTICS_PLACEHOLDER,
  linkButtons,
  tableProperties,
}
