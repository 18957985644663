import React from 'react'
import partialRight from 'lodash/partialRight'
import { Link } from 'react-router-dom'
import chunk from 'lodash/chunk'
import {
  BaseForm,
  Checkbox,
  Upload,
  Input,
  Textarea,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import Tooltip from '../../../../components/Tooltip'
import Tabs from '../../../../components/Tabs'
import { SingleDatePicker } from '../../../../components/Form'
import Dialog from '../../../../components/Popup/Dialog'
import { getMessage } from '../../../../lib/translator'
import { WORKING_DAYS, PUBLIC_HOLIDAY_MAP } from '../constants'
import plusGreenSvg from './plus-green.svg'
import deleteSvg from './delete.svg'
import './style.css'
import StoreConfigComponent from './store-config'
import PicklistDownload from './picklist-download'

const tabsList = [
  {
    text: 'Overview',
  },
  {
    text: 'Handling Time',
  },
  {
    text: 'Holiday Calendar',
  },
  {
    text: 'Picklists Download',
    method: 'edit',
  },
]

const validateEmail = (email) => {
  return String(email)
    .trim()
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const HEADERS_WORKING_DAYS = ['Day', 'Open', 'Day', 'Open']
const setWorkingDaysOpen = () =>
  WORKING_DAYS.reduce((acc, day) => {
    return {
      ...acc,
      [day]: true,
    }
  }, {})

class PublicHoliday extends React.Component {
  render() {
    const { onChange, value } = this.props
    return (
      <div className="tag-value" key={value}>
        <span className="tag-value-label">
          <small>{value}</small>
        </span>
        <button
          type="button"
          className="tag-value-icon"
          onClick={() => onChange(null)}
        />
        <Tooltip>{PUBLIC_HOLIDAY_MAP[value]}</Tooltip>
      </div>
    )
  }
}

function isDayBlocked(momentObj, publicHolidays) {
  const date = momentObj.format('YYYY-MM-DD')
  return publicHolidays.includes(date)
}

export default class EditForm extends BaseForm {
  constructor(props) {
    super(props)
    if (props.method === 'add') {
      this.state = {
        values: {
          WorkingDays: setWorkingDaysOpen(),
          BlockedDates: Object.keys(PUBLIC_HOLIDAY_MAP),
        },
      }
    }
    this.state.storeConfigCount = (this.getState(['storeId']) || [0]).length
    this.state.transporterOffDays = []
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleCutOffTimeChange = this.handleCutOffTimeChange.bind(this)
    this.handleHandlingHourChange = this.handleHandlingHourChange.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleDeleteCutOff = this.handleDeleteCutOff.bind(this)
    this.handleAddStoreConfig = this.handleAddStoreConfig.bind(this)
    this.handleDeleteStoreConfig = this.handleDeleteStoreConfig.bind(this)
    this.deleteStoreField = this.deleteStoreField.bind(this)
    this.handleDialogStatus = this.handleDialogStatus.bind(this)
    this.handleInvalidCutoff = this.handleInvalidCutoff.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleWorkingDaysDialogToggle =
      this.handleWorkingDaysDialogToggle.bind(this)
    this.handleStockDistributionDialogToggle =
      this.handleStockDistributionDialogToggle.bind(this)
  }

  handleTabChange(tabIndex) {
    this.setState({
      tabIndex,
    })
  }

  handleCutOffTimeChange(v, storeId, index) {
    const { value, onChange } = this.generateStateMappers({
      stateKeys: [`cutOffTimes.${storeId}`],
      validationType: VALIDATION_TYPES.ONSUBMIT,
    })
    this.deleteKeys([`cutOffTimes.${storeId}`])
    let newValue = value.slice()
    if (value[index] === v) {
      newValue.splice(index, 1)
    } else {
      newValue = newValue.concat(v)
    }
    onChange(newValue)
  }

  handleHandlingHourChange(v, storeId, index) {
    const { value, onChange } = this.generateStateMappers({
      stateKeys: [`handlingHours.${storeId}`],
      validationType: VALIDATION_TYPES.ONSUBMIT,
    })
    this.deleteKeys([`handlingHours.${storeId}`])
    let newValue = value.slice()
    if (value[index] === v) {
      newValue.splice(index, 1)
    } else {
      newValue = newValue.concat(v)
    }
    onChange(newValue)
  }

  handleStatusChange() {
    this.setState({
      status: this.state.status === 'ENABLED' ? 'HIDDEN' : 'ENABLED',
    })
  }

  handleAddStoreConfig() {
    const { storeConfigCount } = this.state
    this.setState({
      storeConfigCount: storeConfigCount + 1,
    })
  }

  handleDeleteStoreConfig() {
    const { storeConfigCount, currentStoreId } = this.state
    this.setState({
      storeConfigCount: storeConfigCount - 1,
    })
    if (!this.props.isTesting) {
      this.handleDialogStatus()
    }
    this.deleteKeys([`cutOffTimes.${currentStoreId}`])
    this.deleteKeys([`handlingHours.${currentStoreId}`])
    const { value: storeIds = [] } = this.generateStateMappers({
      stateKeys: ['storeId'],
    })
    const index = storeIds.indexOf(currentStoreId)

    this.deleteStoreField(index, 'storeId')
    this.deleteStoreField(index, 'status')
    this.deleteStoreField(index, 'reportSignature')
    this.deleteStoreField(index, 'transporterOffDays')
    this.deleteStoreField(index, 'transporters')
  }

  deleteStoreField(index, fieldKey) {
    const { value = [], onChange } = this.generateStateMappers({
      stateKeys: [fieldKey],
    })
    const newValue = value.slice()
    newValue.splice(index, 1)
    onChange(newValue)
  }

  handleDeleteCutOff(cutOffTime, handlingHour, storeId, index) {
    this.handleCutOffTimeChange(cutOffTime, storeId, index)
    this.handleHandlingHourChange(handlingHour, storeId, index)
  }

  handleDialogStatus(storeId = 0) {
    this.setState({
      showDialog: !this.state.showDialog,
      currentStoreId: storeId,
    })
  }

  handleInvalidCutoff() {
    this.setState({
      isInvalid: !this.state.isInvalid,
      information:
        'Cutoff+Handling hour should exceed the previous Cutoff+Handling hour',
    })
  }

  handleStockDistributionDialogToggle() {
    this.setState({
      showStockDistributionWarning: !this.state.showStockDistributionWarning,
    })
  }
  handleWorkingDaysDialogToggle() {
    this.setState({
      showWorkingDaysWarning: !this.state.showWorkingDaysWarning,
    })
  }

  _submitHandler(e) {
    const { values = {}, showErrors, error } = this.state
    const { storeId = [] } = values
    const isStockDistributionAbsent = storeId.every(
      (id) => !values[`stockDistribution.${id}`]
    )

    const WorkingDays = this.getState(['WorkingDays']) || []
    const isAnyWorkingDay = Object.values(WorkingDays).some(Boolean)

    if (!isAnyWorkingDay) {
      e && e.preventDefault()
      e && e.stopPropagation()
      this.handleWorkingDaysDialogToggle()
      return
    }

    if (showErrors && error) {
      e && e.preventDefault()
      e && e.stopPropagation()
    } else if (storeId.length <= 1 || !isStockDistributionAbsent) {
      super._submitHandler(e)
    } else {
      e && e.preventDefault()
      e && e.stopPropagation()
      this.handleStockDistributionDialogToggle()
    }
  }

  handleOnChange(value = '') {
    const { onChange } = this.generateStateMappers({
      stateKeys: ['Emails'],
    })

    const invalidEmails = value
      .split(',')
      .some((email) => !validateEmail(email))

    if (invalidEmails) {
      this.setState({
        showErrors: true,
        error: 'email format is incorrect',
      })
    } else {
      this.setState({
        showErrors: false,
        error: '',
      })
    }

    onChange(value)
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const { props } = this
    const { stores, transporters = [] } = props.options || {}
    const isEditing = props.method === 'edit'
    const { Category } = this.props.value || {}
    const partialOnDayBlock = partialRight(
      isDayBlocked,
      this.getState(['BlockedDates']) || []
    )
    const {
      tabIndex = 0,
      showDialog,
      storeConfigCount: storeConfigCountProp,
      showStockDistributionWarning,
      showWorkingDaysWarning,
    } = this.state
    const storeId = this.getState(['storeId']) || []
    let storeConfigCount = storeConfigCountProp
    if (storeConfigCount === void 0) {
      storeConfigCount = (storeId || []).length
    }

    const tabs = tabsList.filter(
      (tab) => !tab.method || (tab.method && tab.method === props.method)
    )

    return (
      <div className="SellerForm">
        <div className="tabs-offer">
          <Tabs
            items={tabs.map((tab) => `${tab.text}`)}
            default={0}
            onClick={this.handleTabChange}
            active={tabIndex}
          />
          <br />
        </div>
        <Form>
          {tabIndex === 0 && (
            <React.Fragment>
              <Upload
                label={getMessage('brand.form.image.heading')}
                placeholder={getMessage('brand.form.image.placeholder')}
                {...this.generateStateMappers({
                  stateKeys: ['logo'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label="Vendor Code"
                name="VendorCode"
                type="number"
                disabled={isEditing}
                required
                {...this.generateStateMappers({
                  stateKeys: ['VendorCode'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                }}
              />
              {isEditing && (
                <Input
                  label="Seller ID"
                  name="SellerID"
                  type="number"
                  disabled
                  {...this.generateStateMappers({
                    stateKeys: ['SellerID'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              )}
              <Input
                label="Seller Name"
                placeholder="Enter Seller Name"
                name="name"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ['Name'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                }}
              />
              {isEditing && Category && (
                <div className="field">
                  <span className="labelWrap">
                    <label htmlFor="CategoryName">Category Name</label>
                  </span>
                  <div className="input-wrapper">
                    <span className="input">
                      <Link
                        to={`/catalogue/categories/edit/${Category.id}`}
                        className="category-name"
                      >
                        {`${Category.name} (${Category.id})`}
                      </Link>
                    </span>
                  </div>
                </div>
              )}
              {!isEditing && (
                <Input
                  label="Category Name"
                  name="CategoryName"
                  type="text"
                  required
                  {...(!isEditing &&
                    this.generateStateMappers({
                      stateKeys: ['CategoryName'],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                      loseEmphasisOnFill: true,
                    }))}
                  validationStrings={{
                    valueMissing: getMessage('input.requiredMessage'),
                  }}
                />
              )}
              <Input
                label="Handling Time"
                placeholder="Enter Handling Time"
                name="handlingTime"
                type="number"
                {...this.generateStateMappers({
                  stateKeys: ['HandlingDays'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <Checkbox
                label=""
                inlineLabel="Inventory Report"
                name="inventoryReport"
                {...this.generateStateMappers({
                  stateKeys: ['SendInventoryReport'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <br />
              <Textarea
                label="Seller Emails"
                placeholder="Enter Seller Emails"
                className="emails"
                name="emails"
                required
                {...this.generateStateMappers({
                  stateKeys: ['Emails'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                onChange={this.handleOnChange}
                showErrors={this.state.showErrors}
                error={this.state.error}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                  typeMismatch: getMessage('input.requiredMessage'),
                }}
              />
            </React.Fragment>
          )}

          {tabIndex === 2 && (
            <React.Fragment>
              <div>
                <div className="field">
                  <label>Working Days</label>
                  <div className="table-container">
                    <Table tableDynamic={false}>
                      <Header items={HEADERS_WORKING_DAYS} />
                      {chunk(WORKING_DAYS, 2).map((days, index) => (
                        <Row key={index} className="">
                          <Cell>
                            <span className="working-day">{days[0]}</span>
                          </Cell>
                          <Cell>
                            <Checkbox
                              label=""
                              inlineLabel=""
                              name={days[0]}
                              {...this.generateStateMappers({
                                stateKeys: ['WorkingDays', days[0]],
                                validationType: VALIDATION_TYPES.ONSUBMIT,
                                loseEmphasisOnFill: true,
                              })}
                            />
                          </Cell>
                          {days[1] && (
                            <React.Fragment>
                              <Cell>
                                <span className="working-day">{days[1]}</span>
                              </Cell>
                              <Cell>
                                <Checkbox
                                  label=""
                                  inlineLabel=""
                                  name={days[1]}
                                  {...this.generateStateMappers({
                                    stateKeys: ['WorkingDays', days[1]],
                                    validationType: VALIDATION_TYPES.ONSUBMIT,
                                    loseEmphasisOnFill: true,
                                  })}
                                />
                              </Cell>
                            </React.Fragment>
                          )}
                        </Row>
                      ))}
                    </Table>
                  </div>
                </div>
              </div>
              <div className="field Searchable">
                <div>
                  <label>Public Holidays / Non-Working Days</label>
                </div>
                {(this.getState(['BlockedDates']) || []).map((date, index) =>
                  date ? (
                    <PublicHoliday
                      key={date}
                      {...this.generateStateMappers({
                        stateKeys: ['BlockedDates', index],
                      })}
                    />
                  ) : null
                )}
                <div className="calendar-container">
                  <div className="dateTimeSelect">
                    <SingleDatePicker
                      placeholder="Add New Date"
                      enableToday
                      name="scheduleDate"
                      key="scheduleDate"
                      label={''}
                      {...this.generateStateMappers({
                        stateKeys: [
                          'BlockedDates',
                          (this.getState(['BlockedDates']) || []).length,
                        ],
                      })}
                      noBorder
                      isDayBlocked={partialOnDayBlock}
                    />
                  </div>
                </div>
              </div>
              <br />
            </React.Fragment>
          )}
          {tabIndex === 1 &&
            stores &&
            Array(storeConfigCount)
              .fill(1)
              .map((v, index) => (
                <div className="store-config-box" key={storeId[index] || index}>
                  <StoreConfigComponent
                    stores={stores}
                    transporters={transporters}
                    generateStateMappers={this.generateStateMappers}
                    _this={this}
                    onDeleteCutoffTime={this.handleDeleteCutOff}
                    index={index}
                    onInvalidCutoff={this.handleInvalidCutoff}
                    sellerStores={
                      props.value ? props.value.sellerStores || {} : {}
                    }
                  />
                  <div
                    className={`add-store ${
                      storeConfigCount > 1 ? 'two-buttons' : ''
                    }`}
                  >
                    {storeConfigCount > 1 && (
                      <div
                        className="delete-store"
                        onClick={() => {
                          props.isTesting
                            ? this.handleDeleteStoreConfig()
                            : this.handleDialogStatus(
                                this.getState(['storeId', index])
                              )
                        }}
                      >
                        <img
                          className="delete-store"
                          src={deleteSvg}
                          alt="DeleteStore"
                        />
                        <span>Delete</span>
                      </div>
                    )}
                    <img
                      onClick={this.handleAddStoreConfig}
                      className={`${
                        index === storeConfigCount - 1 ? 'show' : 'hide'
                      }`}
                      src={plusGreenSvg}
                      alt="AddStore"
                    />
                  </div>
                </div>
              ))}
          {tabIndex === 3 && props.method !== 'add' && (
            <PicklistDownload
              sellerName={this.props.value && this.props.value.Name}
              vendorCode={this.props.value && this.props.value.VendorCode}
              sellerStores={this.props.value && this.props.value.sellerStores}
            />
          )}
          {tabIndex !== 3 && (
            <div className={`form-actions ${props.method}`}>
              {(!props.method || props.method !== 'edit') && (
                <CancelButton>Cancel</CancelButton>
              )}
              <SubmitButton>Submit</SubmitButton>
            </div>
          )}
          {!this.isFormValid() && this.state.pressedSubmitWithCurrentData && (
            <span className="error">
              Validation errors! Please review all tabs.
            </span>
          )}
          <Dialog
            information={
              'Cutoff+Handling hour should exceed previous Cutoff+Handling hours'
            }
            close={this.handleInvalidCutoff}
            show={this.state.isInvalid}
            closeText="OK"
          />
          <Dialog
            information={'Are you sure you want to delete this store config?'}
            close={this.handleDialogStatus}
            show={showDialog}
            onOk={this.handleDeleteStoreConfig}
            okText="Confirm"
            closeText="Cancel"
          />
          <Dialog
            information={
              'Stock Distribution cannot be empty for more than one stores.'
            }
            close={this.handleStockDistributionDialogToggle}
            show={showStockDistributionWarning}
            closeText="OK"
          />
          <Dialog
            information={'Please select at least one working day.'}
            close={this.handleWorkingDaysDialogToggle}
            show={showWorkingDaysWarning}
            closeText="OK"
          />
        </Form>
      </div>
    )
  }
}
