import React, { Fragment } from 'react'
import {
  BaseForm,
  Upload,
  VALIDATION_TYPES,
  Select,
  Input,
  MultiTextInput,
  CategorySearch,
  BrandSearch,
  Searchable,
  SingleDatePicker,
  Radio,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import moment from 'moment'
import CategorizedStoreSelector from '../../../../components.new/store-selector/CategorizedStoreSelector'

import './style.css'
import API from '../../../../lib/api'

const timeOptions = (interval = 60) => {
  const times = []
  const x = {
    slotInterval: interval,
    openTime: '00:00',
    closeTime: '24:00',
  }
  let startDate = moment(x.openTime, 'HH:mm')
  const endDate = moment(x.closeTime, 'HH:mm')
  while (startDate < endDate) {
    times.push(startDate.format('HH:mm'))
    startDate = startDate.add(x.slotInterval, 'm')
  }
  return times
}
class SearchCampaignsForm extends BaseForm {
  constructor(props) {
    super(props)
    if (!this.props.value) {
      const value = this.state.values
      if (this.props.method === 'add') {
        value.startDate = moment().format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }
  splitSlug(slug) {
    let params = ''
    const url = (slug && slug.split(',')) || []
    if (url.length > 0) {
      url.forEach((each, i, arr) => {
        params += `slug=${each}`
        if (arr.length - 1 > i) {
          params += `&`
        }
      })
    }
    return params
  }
  componentDidMount() {
    const { pageTypes } = this.props.options || {}
    if (this.props.method === 'add') {
      this.setState({ loader: true })
    }
    if (this.state.values && this.props.method !== 'add') {
      const pageType = this.state.values.pageType
      const values = Object.assign({}, this.state.values)
      const { slug } = values
      let params = ''
      this.setState({
        values,
        loader: false,
      })
      params = this.splitSlug(slug)
      if (slug) {
        switch (pageType) {
          case pageTypes.category: {
            const categoryApi = new API({
              url: `/catalogue-service/category?${params}`,
            })
            categoryApi.get().then(response => {
              const category = response.data.category[0]
              values.categoryUrl = category
              this.setState({
                values,
                loader: true,
              })
            })
            break
          }
          case pageTypes.brand: {
            const brandApi = new API({
              url: `/catalogue-service/brand?${params}`,
            })
            brandApi.get().then(response => {
              const brand = response.data.brand[0]
              values.brandUrl = brand
              this.setState({
                values,
                loader: true,
              })
            })
            break
          }
          case pageTypes.productTag: {
            const tagApi = new API({ url: `/catalogue-service/tag?${params}` })
            tagApi.get().then(response => {
              const tag = response.data.tag[0]
              values.productTagUrl = tag
              this.setState({
                values,
                loader: true,
              })
            })
            break
          }
          default:
            break
        }
      }
    }
  }
  componentWillUnmount() {
    this.api && this.api.cancel()
  }
  removeValidations() {
    const { pageType } = this.state.values
    switch (pageType) {
      case 'category':
        delete this.state.validations.brandUrl
        delete this.state.validations.productTagUrl
        break
      case 'brand':
        delete this.state.validations.productTagUrl
        delete this.state.validations.categoryUrl
        break
      case 'tag':
        delete this.state.validations.brandUrl
        delete this.state.validations.categoryUrl
        break
      default:
        break
    }
  }
  renderCategorySearch() {
    return (
      <div className="searched-items">
        <CategorySearch
          label={getMessage('search-campaigns.form.category.heading')}
          placeholder={getMessage('search-campaigns.form.category.placeholder')}
          name="category"
          required
          {...this.generateStateMappers({
            stateKeys: ['categoryUrl'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }
  renderBrandSearch() {
    return (
      <div className="searched-items">
        <BrandSearch
          label={getMessage('search-campaigns.form.brand.heading')}
          placeholder={getMessage('search-campaigns.form.brand.placeholder')}
          name="brand"
          required
          {...this.generateStateMappers({
            stateKeys: ['brandUrl'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }
  renderProductSearch() {
    return (
      <div className="searched-items">
        <Searchable
          label={getMessage('search-campaigns.form.tag.heading')}
          placeholder={getMessage('search-campaigns.form.tag.placeholder')}
          name="tag"
          searchUrl="/catalogue-service/tag"
          valueKey="id"
          responseKey="tag"
          nameKey="name"
          searchKey="name"
          expandMore={true}
          transformResponse={response => response.data.tag}
          required
          {...this.generateStateMappers({
            stateKeys: ['productTagUrl'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }
  pageTypeField() {
    this.removeValidations()
    const { values } = this.state
    const { pageTypes } = this.props.options || {}
    const pageType = values && values.pageType
    switch (pageType) {
      case pageTypes.category:
        return this.renderCategorySearch()
      case pageTypes.brand:
        return this.renderBrandSearch()
      case pageTypes.productTag:
        return this.renderProductSearch()
      default:
        return null
    }
  }
  renderDefault() {
    return (
      <Input
        label={getMessage('search-campaigns.form.defaultText.label')}
        tooltip={
          <Fragment>
            <div>
              <b>Note:</b>
            </div>
            <div>
              Character limit: 36 (if not will be truncated on smallest screen
              size 360px)
            </div>
          </Fragment>
        }
        placeholder={getMessage(
          'search-campaigns.form.defaultText.placeholder'
        )}
        name="default"
        type="text"
        required
        {...this.generateStateMappers({
          stateKeys: ['description'],
          loseEmphasisOnFill: true,
        })}
      />
    )
  }
  renderKeyword() {
    const view = this.props.method === 'view'
    return (
      <MultiTextInput
        readOnly={view}
        label={getMessage('search-campaigns.form.keyword.label')}
        placeholder={getMessage('search-campaigns.form.keyword.placeholder')}
        name="keywords"
        type="text"
        required
        {...this.generateStateMappers({
          stateKeys: ['keywords'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
      />
    )
  }
  campaignTypeField() {
    const { values } = this.state
    const campaignType = values && values.type
    return (
      <div className="form-section block-section">
        <div className="form-field">
          {campaignType && campaignType === 'default' && this.renderDefault()}
          {campaignType && campaignType === 'keyword' && this.renderKeyword()}
        </div>
      </div>
    )
  }
  campaignName() {
    return (
      <div className="form-section block-section">
        <Input
          label={getMessage('search-campaigns.form.campaignName.label')}
          tooltip={
            <Fragment>
              <div>
                <b>Note:</b>
              </div>
              <div>
                Character limit: 46 (if not will be truncated on smallest screen
                size 360px)
              </div>
            </Fragment>
          }
          placeholder={getMessage(
            'search-campaigns.form.campaignName.placeholder'
          )}
          name="name"
          type="text"
          required
          {...this.generateStateMappers({
            stateKeys: ['name'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }
  datePicker() {
    const { startDate, endDate } = this.state.values
    const startDatePlaceholder = moment(startDate).format('HH:mm')
    const endDatePlaceholder = endDate && moment(endDate).format('HH:mm')
    return (
      <Fragment>
        <div className="form-section date-time-section">
          <SingleDatePicker
            label={getMessage('search-campaigns.form.validFrom')}
            placeholder={getMessage(
              'campaign.startDate'
            )}
            displayFormat="YYYY-MM-DD"
            name="startDate"
            className="date-section"
            allowAllDates
            openDirection={'up'}
            {...this.generateStateMappers({
              stateKeys: ['startDate'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('campaign.startTime')}
            name="campaignstartDate"
            type="text"
            className="time-section"
            placeholder={startDatePlaceholder || '00:00'}
            options={timeOptions(30)}
            {...this.generateStateMappers({
              stateKeys: ['campaignStartTime'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div className="form-section date-time-section">
          <SingleDatePicker
            label={getMessage('search-campaigns.form.validTo')}
            placeholder={getMessage(
              'campaign.endDate'
            )}
            displayFormat="YYYY-MM-DD"
            openDirection={'up'}
            allowToday
            enableToday
            name="endDate"
            className="date-section"
            {...this.generateStateMappers({
              stateKeys: ['endDate'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('campaign.endTime')}
            name="campaignendDate"
            type="text"
            className="time-section"
            placeholder={endDatePlaceholder || '00:00'}
            options={timeOptions(30)}
            {...this.generateStateMappers({
              stateKeys: ['campaignEndTime'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
      </Fragment>
    )
  }

  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons
    const view = this.props.method === 'view'
    const { values } = this.state
    const {
      campaignTypes,
      pages,
      stores,
      multiStoreEnabled,
      scopes,
      defaultStatus,
    } = this.props.options || {}
    const pageType = values && values.pageType
    const scope = scopes && values && values.scope === scopes.storeSpecific
    const options = [
      {
        text: getMessage('search-campaigns.form.radio.option.global'),
        value:
          scopes && values && values.scope === scopes.global
            ? scopes.global
            : '',
      },
    ]
    if (multiStoreEnabled) {
      options.push({
        text: getMessage('search-campaigns.form.radio.option.storeSpecific'),
        value: scopes.storeSpecific,
      })
    }
    return (
      <Form className="search-campaigns-form" testid="search-campaigns-form">
        <fieldset disabled={view}>
          <div className="form-sections">
            <div className="form-section logo-upload">
              <Upload
                label={getMessage('search-campaigns.form.image.label')}
                tooltip={
                  <Fragment>
                    <div>
                      <b>Note:</b>
                    </div>
                    <div>
                      Logo should be .png or .jpg and visible in 40px by 40px
                      frame
                    </div>
                  </Fragment>
                }
                name="logo"
                {...this.generateStateMappers({
                  stateKeys: ['logo'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <div className="form-section seachable-component">
              <Select
                testid="campaignType"
                label={getMessage('search-campaigns.form.campaignType.label')}
                placeholder={getMessage(
                  'search-campaigns.form.campaignType.placeholder'
                )}
                name="type"
                required
                options={campaignTypes}
                {...this.generateStateMappers({
                  stateKeys: ['type'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <br></br>
              <Select
                testid="pageType"
                label={getMessage('search-campaigns.form.pageType.label')}
                placeholder={getMessage(
                  'search-campaigns.form.pageType.placeholder'
                )}
                name="pageType"
                required
                options={pages}
                {...this.generateStateMappers({
                  stateKeys: ['pageType'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              {pageType && this.pageTypeField()}
            </div>
            {this.campaignName()}
            {this.campaignTypeField()}
            {this.datePicker()}
            <div className="form-section seachable-component">
              <Radio
                disabled={view}
                label={getMessage('search-campaigns.form.scope.radio.label')}
                name="scope"
                options={options}
                {...this.generateStateMappers({
                  stateKeys: ['scope'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              {multiStoreEnabled && stores && scope && (
                <CategorizedStoreSelector
                  values={this.state.values['storeId'] || []}
                  stores={stores}
                  validateEmpty={this.state.pressedSubmitWithCurrentData}
                  onUpdate={value => {
                    const newValues = {
                      ...this.state.values,
                      storeId: value,
                    }

                    this.setState({ values: newValues })
                  }}
                />
              )}
            </div>
            <div className="form-section seachable-component">
              <Radio
                disabled={view}
                name="status"
                label={getMessage('search-campaigns.form.status')}
                options={[
                  {
                    text: getMessage('search-campaigns.form.enabled'),
                    value: 'ENABLED',
                  },
                  {
                    text: getMessage('search-campaigns.form.disabled'),
                    value: 'DISABLED',
                  },
                ]}
                {...this.generateStateMappers({
                  stateKeys: ['status'],
                  loseEmphasisOnFill: true,
                  defaultValue: defaultStatus,
                })}
              />
            </div>
          </div>
          {!view && (
            <div className="form-actions">
              <CancelButton>
                {'Cancel' ||
                  getMessage('search-campaigns.editForm.button.cancel')}
              </CancelButton>
              <SubmitButton>
                {'Save' || getMessage('search-campaigns.editForm.button.save')}
              </SubmitButton>
            </div>
          )}
        </fieldset>
      </Form>
    )
  }
}

export default SearchCampaignsForm
