/*
  This is a simple file processor which does not handle any api call to upload the selected file.
  Instead, this can be used to read the file and extract the data needed to display the content to user.
  The submission of extracted data need to be handled outside of this component.
  Please use ./FileUpload component if you plan to upload the file as soon as after the file is selected.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { getMessage } from 'lib/translator';
import './style.css';

const FileProcessor = ({
  onFileUpload,
  fileType,
  name,
  title,
  secondaryTitle,
  buttonText,
  dataTestId
}) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onFileUpload(file);
    event.target.value = ''; // if this is not reset, the file will be stuck there and error won't prompt again if same invalid file is uploaded
  };

  return (
    <div className='input FileUpload'>
      <div className='title'>
        <span>{title}</span>
        {secondaryTitle && <span className='secondary-title'><br />{secondaryTitle}</span>}
      </div>
      <div className='file-container'>
        <div className='file-label'>
          {buttonText}
        </div>
        <input
          type="file"
          data-testid={dataTestId}
          id={name}
          accept={fileType}
          onChange={handleFileChange} />
      </div>

    </div>
  );
};

FileProcessor.defaultProps = {
  fileType: '.csv,text/csv',
  buttonText: getMessage('operations.default.fileUploadMessage'),
  dataTestId: ''
}

FileProcessor.propTypes = {
  onFileUpload: PropTypes.func,
  fileType: PropTypes.string,
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  buttonText: PropTypes.string,
  dataTestId: PropTypes.string
}


export default FileProcessor;
