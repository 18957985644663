import React, { useState, useEffect } from 'react'
import AutocompleteInput from '../../../components.new/autocomplete/AutocompleteInput'
import tagIcon from 'icons/tag.svg'
import deleteIcon from 'icons/delete.svg'
import API from 'lib/api'
import { getMessage } from 'lib/translator'
import '../../Rewards/applicable-items.styles.css'

const MerchantsApplicable = ({ onUpdate, initialValues = [] }) => {
  const [merchantList, setMerchantList] = useState([])
  const [selectedMerchants, setSelectedMerchants] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    const excludedMerchantIds = new Set([
      '102200000000', // Cheers
      '101780000000', // Cheers
      '101770000000', // FairPrice Xpress
      'FP', // FairPrice and Unity
    ])

    const fetchMerchants = async () => {
      try {
        const api = new API({ url: '/admin/merchants?limit=1000&offset=0' })
        const response = await api.get()
        const filteredMerchants = response.data.list
          ?.filter(
            (item) =>
              item.name !== '' && !excludedMerchantIds.has(item.clsMerchantID)
          )
          .map((item) => ({ label: item.name, value: item.clsMerchantID }))

        setMerchantList(filteredMerchants)
        setSelectedMerchants(
          filteredMerchants.filter((merchant) =>
            initialValues.includes(merchant.value)
          )
        )
      } catch (e) {
        setError(e.message || getMessage('rewards.defaultError'))
      }
    }
    fetchMerchants()
  }, [])

  useEffect(() => {
    if (selectedMerchants.length !== initialValues.length) {
      onUpdate(selectedMerchants.map((merchant) => merchant.value))
    }
  }, [selectedMerchants, onUpdate])

  const handleSelect = (option) => {
    if (
      !selectedMerchants.some((merchant) => merchant.value === option.value)
    ) {
      setSelectedMerchants((prev) => [...prev, option])
    }
  }

  const handleDelete = (id) => {
    setSelectedMerchants((prev) =>
      prev.filter((merchant) => merchant.value !== id)
    )
  }

  const hasValidationError = selectedMerchants.length === 0

  return (
    <div className="rewards-applicable-container">
      <div className="rewards-applicable-list">
        <button
          className="rewards-applicable-btn"
          onClick={(e) => {
            e.preventDefault()
            setSelectedMerchants(merchantList)
          }}
        >
          {getMessage('rewards.allLinkMerchants')}
        </button>
      </div>
      <AutocompleteInput
        options={merchantList.map((opt) => ({
          label: opt.label,
          value: opt.value,
        }))}
        placeholder={getMessage('rewards.merchantsApplicable.placeholder')}
        onSelect={handleSelect}
        hasError={hasValidationError}
      />
      {hasValidationError && (
        <small style={{ color: 'red', paddingTop: '0.5rem' }}>
          {getMessage('rewards.validationMessage')}
        </small>
      )}
      <SelectedList items={selectedMerchants} onDelete={handleDelete} />
      {selectedMerchants.length > 0 && (
        <div
          role="button"
          className="remove-all-btn"
          onClick={() => setSelectedMerchants([])}
        >
          {getMessage('rewards.removeAll')} ({selectedMerchants.length})&nbsp;
          {getMessage('rewards.merchants')}
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  )
}

const SelectedList = ({ items, onDelete }) => {
  return (
    <ul className="selected-list">
      {items.map((merchant) => (
        <li key={merchant.value} className="selected-list__item">
          <div className="selected-list__label">
            <img src={tagIcon} alt="Tag icon" className="tag-icon" />
            <small>{merchant.label}</small>
          </div>
          <div className="delete-icon__container">
            <img
              src={deleteIcon}
              alt={`Delete ${merchant.label}`}
              className="delete-icon"
              onClick={() => onDelete(merchant.value)}
            />
          </div>
        </li>
      ))}
    </ul>
  )
}

export default MerchantsApplicable
