import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage/index'
// import TopProductsTable from './topProductsTable'
// import CategoriesStats from './categoriesStats'
import SlotOrderTable from './slotOrdertable'
import Loader from '../../../components/Loader'
import StoreSelector, {
  makestoreDependentComponent,
  getDefaultStore,
} from '../../../containers/StoreSelector'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import { isExtensionEnabled } from '../../../lib/auth'
import { get } from '../../../lib/storage'

import './style.css'
import { sortOrderStatus, sortPacklistStatus } from '../../../lib/commonlyused'

// Formats Date object in YYYY-MM-DD format
function formatDate(date) {
  var dd = date.getDate()
  var mm = date.getMonth() + 1 // January is 0!

  var yyyy = date.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return `${yyyy}-${mm}-${dd}`
}

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: true,
      showSmallLoader: true,
      showSlotLoader: true,
      storeId: get('store') || getDefaultStore(this.props.stores).storeId,
      salesStatsData: {},
      customerStatsData: {},
      topProductsData: [],
      categoriesSalesData: [],
      showStore: false,
      selectedDate: formatDate(new Date()),
      storeZone: 'none',
    }
    this.showLoader = this.showLoader.bind(this)
    this.hideLoader = this.hideLoader.bind(this)
    this.changeStore = this.changeStore.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleStoreZoneChange = this.handleStoreZoneChange.bind(this)
  }

  hideLoader() {
    this.setState({
      showLoader: false,
    })
  }

  handleDateChange(date) {
    this.setState({ selectedDate: date }, () => this.updateContent())
  }

  showLoader() {
    this.setState({
      showLoader: true,
    })
  }

  changeStore(storeId) {
    this.setState(
      {
        storeId: storeId,
        storeZone: 'none',
      },
      () => this.updateContent()
    )
  }

  handleStoreZoneChange(storeZone) {
    this.setState(
      {
        storeZone: storeZone,
      },
      () => this.updateContent()
    )
  }

  componentDidMount() {
    this.updateContent()
  }

  updateContent() {
    this.setState({
      showLoader: true,
      showSlotLoader: true,
      showSmallLoader: true,
      showStore: true,
    })
    let storeId =
      this.state.storeId ||
      get('store') ||
      getDefaultStore(this.props.stores).storeId
    const org = JSON.parse(get('organization'))
    storeId = storeId || org.defaultStoreId
    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
    this.slotOrdersApi = new API({ url: '/order-service/order-count' })
    this.storeZoneApi = new API({
      url: '/picking-service/v3/storeZones/' + storeId,
    })
    this.slotPacklistsApi = new API({
      url: '/picking-service/v3/packlists/count',
    })

    const plapis = []
    {
      storeId && plapis.push(this.storeZoneApi.get())
      storeId && plapis.push(this.slotPacklistsApi.get())
    }
    Promise.all(plapis).then(([storeZoneResponse]) => {
      const storeZoneConfig = storeZoneResponse.storeZones
      if (!storeZoneConfig.length) {
        this.setState({
          showZonesDropdown: false,
          storeZoneConfig: {
            0: {
              id: '0',
              tag: 'none',
              displayName: 'All zones',
            },
          },
        })
      } else {
        storeZoneConfig[storeZoneConfig.length + 1] = {
          id: '0',
          tag: 'none',
          displayName: 'All zones',
        }
        this.setState({
          showZonesDropdown: true,
          storeZoneConfig: storeZoneConfig,
        })
        const storeZone = this.state.storeZone
        const storeZoneGetParams =
          storeZone === 'none'
            ? {
                preferredDate: this.state.selectedDate,
                storeId: storeId,
              }
            : {
                preferredDate: this.state.selectedDate,
                storeId: storeId,
                tag: storeZone,
              }
        storeId &&
          storeZone &&
          this.slotPacklistsApi
            .get(storeZoneGetParams)
            .then((slotPacklistsResponse) => {
              const slotPacklistsStats = slotPacklistsResponse.packlistCount
              let totalPacklists = 0
              const packlistFixedSlots = []
              const packlistStatuses = []
              slotPacklistsStats.forEach((packlistCount) => {
                const statusCount = {}
                packlistCount.countByStatus.forEach((countByStatus) => {
                  const key = countByStatus.status
                  const value = countByStatus.count
                  if (packlistStatuses.indexOf(key) === -1) {
                    packlistStatuses.push(key)
                  }
                  totalPacklists += parseInt(value)
                  statusCount[key] = parseInt(value)
                })
                const slot = {}
                slot['endTime'] = packlistCount.slot.slotEndTime
                slot['startTime'] = packlistCount.slot.slotStartTime
                packlistFixedSlots.push({
                  count: statusCount,
                  slot: slot,
                })
              })
              this.setState({
                totalPacklists: totalPacklists,
                packlistStatuses: sortPacklistStatus(packlistStatuses),
                packlistFixedSlots: packlistFixedSlots,
              })
            })
      }
    })

    const apis = []
    if (isExtensionEnabled('DeliverySlots')) {
      storeId &&
        apis.push(
          this.slotOrdersApi.get({
            preferredDate: this.state.selectedDate,
            storeId: storeId,
          })
        )
    } else {
      storeId &&
        apis.push(
          this.slotOrdersApi.get({
            createdAt: this.state.selectedDate,
            storeId: storeId,
          })
        )
    }

    Promise.all(apis).then(([slotOrdersResponse]) => {
      const salesStats = {}
      const customerStats = {}
      const slotOrderStats =
        (slotOrdersResponse && slotOrdersResponse.data) || []
      const statuses = []
      let totalOrders = 0
      slotOrderStats.forEach((slot) => {
        const keys = (slot.count && Object.keys(slot.count)) || []
        keys.forEach((key) => {
          if (statuses.indexOf(key) === -1) {
            statuses.push(key)
          }
          totalOrders += slot.count[key]
        })
      })
      let fixedSlots = null
      let asapSlots = null
      let noSlots = null
      let count = null
      if (isExtensionEnabled('DeliverySlots')) {
        fixedSlots =
          slotOrderStats &&
          slotOrderStats
            .filter((slot) => slot.slot?.type === 'STANDARD')
            .sort((slot1, slot2) => {
              if (slot1.slot.startTime < slot2.slot.startTime) {
                return -1
              } else {
                return 1
              }
            })
        asapSlots =
          slotOrderStats &&
          slotOrderStats
            .filter((slot) => slot.slot && slot.slot.type === 'ASAP')
            .sort((slot1, slot2) => {
              if (slot1.slot.endTime < slot2.slot.endTime) {
                return -1
              } else {
                return 1
              }
            })
      } else {
        count = {}
        slotOrderStats &&
          slotOrderStats.forEach((slot) => {
            const keys = Object.keys(slot.count) || []
            keys.forEach((key) => {
              if (count[key]) {
                count[key] += slot.count[key]
              } else {
                count[key] = slot.count[key]
              }
            })
          })
        noSlots = { count: count }
      }

      this.setState({
        showLoader: false,
        customerStatsData: customerStats,
        salesStatsData: salesStats,
        statuses: sortOrderStatus(statuses),
        totalOrders,
        asapSlots,
        fixedSlots,
        noSlots,
        showSlotLoader: false,
        storeId: storeId,
      })
    })
  }
  componentWillUnmount() {
    this.slotOrdersApi && this.slotOrdersApi.cancel()
    this.storeZoneApi && this.storeZoneApi.cancel()
    this.slotPacklistsApi && this.slotPacklistsApi.cancel()
  }

  render() {
    const { props } = this
    const {
      showSlotLoader,
      statuses,
      asapSlots,
      totalOrders,
      fixedSlots,
      noSlots,
      showStore,
      showZonesDropdown,
      storeZone,
      totalPacklists,
      packlistStatuses,
      packlistFixedSlots,
    } = this.state
    return (
      <div className="operations-dashboard">
        {showStore && isExtensionEnabled('MultiStoreSupport') && (
          <StoreSelector
            value={this.state.storeId}
            onChange={this.changeStore}
            stores={props.stores}
          />
        )}
        <h1 className="title">{getMessage('operations.dashboard.heading')}</h1>
        <div>
          {showSlotLoader ? (
            <Loader />
          ) : showZonesDropdown ? (
            <div>
              <SlotOrderTable
                data={{ totalOrders, asapSlots, fixedSlots, noSlots, statuses }}
                handleDateChange={this.handleDateChange}
                selectedDate={this.state.selectedDate}
                areOrdersScheduled={isExtensionEnabled('DeliverySlots')}
              />
              <SlotOrderTable
                data={{
                  totalPacklists,
                  asapSlots,
                  packlistFixedSlots,
                  noSlots,
                  packlistStatuses,
                }}
                handleDateChange={this.handleDateChange}
                handleStoreZoneChange={this.handleStoreZoneChange}
                showZonesDropdown={this.state.showZonesDropdown}
                storeZoneConfig={this.state.storeZoneConfig}
                storeZone={storeZone}
                selectedDate={this.state.selectedDate}
                areOrdersScheduled={isExtensionEnabled('DeliverySlots')}
              />
            </div>
          ) : (
            <SlotOrderTable
              data={{ totalOrders, asapSlots, fixedSlots, noSlots, statuses }}
              handleDateChange={this.handleDateChange}
              selectedDate={this.state.selectedDate}
              areOrdersScheduled={isExtensionEnabled('DeliverySlots')}
            />
          )}
        </div>
      </div>
    )
  }
}

const DashboardWithStores = makestoreDependentComponent(Dashboard)

export default class OperationsDashboard extends Component {
  render() {
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <DashboardWithStores {...this.props} />
      </AuthenticatedPage>
    )
  }
}
