import React from 'react'
import ChancesEarned from './Draws/ChancesEarned'
import PrizesWon from './Draws/PrizesWon'
import ChallengeProgress from './Challenges/ChallengeProgress'

const RenderListing = ({
  uid,
  selectedCampaign,
  selectedChallenge,
  type,
  handleChallengeSelect,
}) =>
  type === 'lucky-draw' ? (
    <div className="row">
      <div className="col-12">
        <ChancesEarned uid={uid} selectedCampaign={selectedCampaign} />
        <PrizesWon uid={uid} selectedCampaign={selectedCampaign} />
      </div>
    </div>
  ) : (
    <div
      className="m-lr-05"
      data-testid={`challenge-progress-${selectedChallenge}`}
    >
      <ChallengeProgress
        uid={uid}
        selectedChallenge={selectedChallenge}
        handleChallengeSelect={handleChallengeSelect}
      />
    </div>
  )

export default RenderListing
