import React from 'react'
import { BaseForm, SingleDatePicker, Select } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

const LeaveTypes = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Earned',
    value: 'EARNED',
  },
  {
    text: 'Sick',
    value: 'SICK',
  },
  {
    text: 'Halfday',
    value: 'HALFDAY',
  },
  {
    text: 'Maternity',
    value: 'MATERNITY',
  },
  {
    text: 'Paternity',
    value: 'PATERNITY',
  },
]

const LeaveStatus = [
  {
    text: getMessage('leaves.filter.status.all'),
    value: '',
  },
  {
    text: getMessage('leaves.filter.status.pending'),
    value: 'PENDING',
  },
  {
    text: getMessage('leaves.filter.status.approved'),
    value: 'APPROVED',
  },
  {
    text: getMessage('leaves.filter.status.rejected'),
    value: 'REJECTED',
  },
  {
    text: getMessage('leaves.filter.status.cancelled'),
    value: 'CANCELLED',
  },
]

export default class MyLeavesFilter extends BaseForm {
  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components

    return (
      <Form>
        <div className="form-fields">
          <SingleDatePicker
            label={getMessage('leaves.filter.fromdate')}
            placeholder={getMessage('leaves.filter.date')}
            displayFormat="YYYY-MM-DD"
            {...this.generateStateMappers({
              stateKeys: ['fromDate'],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage('leaves.filter.todate')}
            placeholder={getMessage('leaves.filter.date')}
            displayFormat="YYYY-MM-DD"
            {...this.generateStateMappers({
              stateKeys: ['toDate'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div className="form-fields">
          <Select
            label={getMessage('leaves.form.type')}
            placeholder={getMessage('leaves.form.type.placeholder')}
            name="type"
            options={LeaveTypes}
            {...this.generateStateMappers({
              stateKeys: ['type'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('leaves.filter.status.heading')}
            placeholder={getMessage('leaves.filter.status.placeholder')}
            name="status"
            className="status-filter"
            options={LeaveStatus}
            {...this.generateStateMappers({
              stateKeys: ['status'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('leaves.filter.submit')}</SubmitButton>
      </Form>
    )
  }
}
