import React, { Fragment } from 'react'
import { Input, VALIDATION_TYPES } from '../../Form'
import { getMessage } from 'lib/translator'
import { TextDetails } from '../utils'

const PartnerDetails = ({ _this }) => {
  const PARTNER_INPUT_DETAILS = [
    ['linkpointGain', 'linkpointDollarSpend'],
    ['minSpend', 'linkpointDescription'],
  ]
  const PARTNER_TEXT_DETAILS = [['description'], ['tnc']]
  return (
    <Fragment>
      {PARTNER_INPUT_DETAILS.map((inputDetails, i) => {
        return (
          <div className="row" key={i}>
            {inputDetails.map((detail, j) => (
              <div className="col-12" key={j}>
                <Input
                  label={getMessage(`rewards.partner.${detail}`)}
                  placeholder={getMessage(`rewards.partner.${detail}`)}
                  {...(detail === 'linkpointDescription'
                    ? { type: 'text' }
                    : {
                        type: 'number',
                        step: '0.01',
                        min: 0,
                        max: 1000,
                      })}
                  {..._this.generateStateMappers({
                    stateKeys: [`${detail}`],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            ))}
          </div>
        )
      })}
      {PARTNER_TEXT_DETAILS.map((textDetails, i) => {
        return (
          <div className="row" key={i}>
            {textDetails.map((text, j) => (
              <TextDetails _this={_this} key={j} text={text} />
            ))}
          </div>
        )
      })}
    </Fragment>
  )
}

export default PartnerDetails
