const handlePaymentType = (_this, val, stateName) => {
  var values = {
    ..._this.state.values,
    [stateName]: val.toString().split(','),
  }

  if (!val || val?.length === 0) {
    values[stateName] = []
  }

  _this.setState({
    values: values,
  })
}

const handleFocus = (e) => {
  e.target.addEventListener(
    'wheel',
    function (event) {
      event.preventDefault()
    },
    { passive: false }
  )
}

export { handlePaymentType, handleFocus }
