import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ENVIRONMENT } from 'config/app'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage/index'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import Loader from '../../../../components/Loader'
import './style.css'
import { get } from 'lib/storage'

const _defaultFormURL = ENVIRONMENT === 'UAT' ? "https://docs.google.com/forms/d/e/1FAIpQLSffvTNP7s7z0v7vTon_jEHxNfhbSlxFXLXUVZQrrd4uCs0yEQ/viewform" : "https://docs.google.com/forms/d/e/1FAIpQLSeXR0oBqRGMCSaqabeQnhzAR8FbqURd6NB6xwcQZEYdOLlX-g/viewform"
const entrykey = {
  lineItemId: ENVIRONMENT === 'UAT' ? 1415063841 : 338570796,
  emailadd: ENVIRONMENT === 'UAT' ? 1390617321 : 716464194
}

export default class LineItemOffsite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: true,
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
      formURL: "",
      user: null
    }
  }

  componentDidMount() {
    this.getOffsiteDetail();

    this.setState({
      user: JSON.parse(get('user'))
    })

  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  getOffsiteDetail() {
    const id = this.props.match.params.id
    this.api = new API({ url: '/martech-ad-service/lineitemform/' + id })
    this.api.get().then(response => {
      const responseUrl = response.data.formData["Response Url"]
      this.setState({
        showLoader: false,
        formURL: responseUrl ? `${responseUrl}&embedded=true` : `${_defaultFormURL}?embedded=true`
      })
    }).catch(() => {
      this.setState({
        showLoader: false,
        formURL: `${_defaultFormURL}?embedded=true`
      })
    })
  }

  render() {
    const { menu } = this.props
    const lineItemId = this.props.match.params.id
    const { showLoader, formURL, user } = this.state

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className='media-adset-offsite-page website-pages'
        title={getMessage('media.adset.offsite.h3')}
      >
        <div
          className='scrollBody'
          ref={node => {
            this.webHeight = node?.scrollHeight
          }}
        >
          <div className='header-container'>
            <h1 className='title'>{getMessage('media.adset.offsite.h3')}</h1>
            <div className='header-actions-wrapper'>
              <Link to={`/media-monetization/media-allcampaigns/media-adset/${this.props.match.params.campaignId}`}>
                {getMessage('media.action.goBackText')}
              </Link>
            </div>
          </div>

          {showLoader ? (
            <Loader />
          ) : (
            <div>
              {formURL && user &&
                <iframe src={`${formURL}&entry.${entrykey.lineItemId}=${lineItemId}&entry.${entrykey.emailadd}=${user.emails[0].email}`} width="100%" height={this.webHeight} frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
              }
            </div>
          )}
        </div>
      </AuthenticatedPage>
    )
  }
}
