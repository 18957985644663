import React, { Component, useContext } from 'react'
import { Row, Cell } from '../../../components/Table'
import { getMessage } from '../../../lib/translator'
import NewListingPage, {
  TABLE_ACTIONS,
} from '../../../containers/NewListingPage'
import campaignsEmpty from './images/campaigns_empty.svg'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import imagePlaceholder from './images/image-placeholder.svg'
import CampaignLabelsForm from './Forms'
import CampaignLabelsFilter from './Filters'
import './style.css'
import moment from 'moment'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { SplitContext } from 'containers/SplitContext'
import { Link } from 'react-router-dom'

const timeCampaignLabelFormat = 'YYYY-MM-DD HH:mm'
const dateCampaignLabelFormat = 'YYYY-MM-DD'
const emptyCampaignLabelState = {
  icon: campaignsEmpty,
  message: getMessage('campaign-labels.empty.campaigns'),
  actions: /* istanbul ignore next */ ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      + {getMessage('search-campaigns.add.text')}
    </button>
  ),
}

class SearchCampaignActions extends Component {
  render() {
    const { id, onAction, index } = this.props
    return (
      <React.Fragment>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem
            datakey={'delete' + index}
            onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}
          >
            {getMessage('search-campaigns.delete')}
          </DropDownItem>
        </DropDown>
      </React.Fragment>
    )
  }
}

const tableCampaignLabelProperties = () => {
  return {
    headers: [
      getMessage('campaign-labels.list.heading.campaignLabelImage'),
      getMessage('campaign-labels.list.heading.tagId'),
      getMessage('campaign-labels.list.heading.campaignName'),
      getMessage('campaign-labels.list.heading.startDateTime'),
      getMessage('campaign-labels.list.heading.endDateTime'),
      getMessage('campaign-labels.list.heading.action'),
    ],
    row: ({
      campaign_name,
      tag_id,
      id,
      start_datetime,
      end_datetime,
      label_image_url,
      onAction,
      index,
    }) => (
      <Row>
        <Cell className="campaign-image">
          <img
            className="image-size"
            src={label_image_url || imagePlaceholder}
            alt="campaign_image"
            width="50px"
          />
        </Cell>
        <Cell className="search-campaigns-id">{tag_id}</Cell>
        <Cell className="campaign-name">{campaign_name}</Cell>
        <Cell className="search-campaigns-campaignValidFrom">
          {start_datetime &&
            moment(start_datetime).format(timeCampaignLabelFormat)}
        </Cell>
        <Cell className="search-campaigns-campaignValidTill">
          {end_datetime && moment(end_datetime).format(timeCampaignLabelFormat)}
        </Cell>
        <Cell className="search-campaigns-actions">
          <SearchCampaignActions
            onAction={onAction}
            id={id}
            validFrom={start_datetime}
            index={index}
          />
        </Cell>
      </Row>
    ),
  }
}
class CampaignLabelsClass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      campaignLabelsType: [
        { text: 'Online/live', value: 'showActive' },
        { text: 'Upcoming', value: 'showUpcoming' },
        { text: 'Expired', value: 'showExpired' },
      ],
    }
  }
  componentWillUnmount() {
    this.api && this.api.cancel()
  }
  render() {
    const { splits } = this.props
    const isCampaignLabelEnabled =
      splits[SPLIT_FEATURES.BACK_DIS_CAMPAIGN_LABEL]?.treatment === 'on'
    const { props } = this
    const { campaignLabelsType } = this.state

    return (
      <>
        {!isCampaignLabelEnabled ? (
          <div className="campaign-labels">
            <h2>Campaign Labels</h2>
            <Link to="/">Go Back</Link>
          </div>
        ) : (
          <NewListingPage
            testid="search-campaigns-table"
            className="search-campaigns-page"
            menu={props.menu}
            title={getMessage('campaign-labels.title')}
            api={{
              url: '/v1/campaign-labels',
              transform: (response) => {
                return response.data.value
              },
            }}
            modalClassName={'campaigns-label-popup'}
            emptyState={emptyCampaignLabelState}
            addHeading={getMessage('campaign-labels.add.heading')}
            tableProperties={tableCampaignLabelProperties()}
            headerActions={({ onAction }) => (
              <button
                className="primary button vendor-btn"
                onClick={() => {
                  onAction(TABLE_ACTIONS.ADD)
                }}
              >
                +{' '}
                <span className="text" data-testid="add-campaign">
                  {getMessage('search-campaigns.add.text')}
                </span>
              </button>
            )}
            filters={{
              component: CampaignLabelsFilter,
              forceShow: true,
              options: {
                campaignLabelsType,
              },
              transformSubmit: (formData) => {
                let data = Object.assign({}, formData)
                if (data.campaignType) {
                  data = {
                    [data.campaignType]: 1,
                  }
                }
                return data
              },
            }}
            form={{
              component: CampaignLabelsForm,
              transformSubmit: /* istanbul ignore next */ (formData) => {
                const data = Object.assign({}, formData)
                data.label_image_url = data?.logo
                data.tag_id = data?.productTagUrl?.id
                data.campaign_name = data?.name

                data.start_datetime = data?.campaignStartTime
                  ? moment(
                      moment(data?.startDate).format(dateCampaignLabelFormat) +
                        ` ${data?.campaignStartTime}`
                    ).format()
                  : moment(data?.startDate).format()
                if (data?.endDate) {
                  if (data?.campaignEndTime) {
                    data.end_datetime = moment(
                      moment(data?.endDate).format(dateCampaignLabelFormat) +
                        ` ${data?.campaignEndTime}`
                    ).format()
                  } else {
                    data.end_datetime = moment(
                      moment(data?.endDate).format(dateCampaignLabelFormat) +
                        ` 00:00`
                    ).format()
                  }
                }
                const {
                  campaign_name,
                  start_datetime,
                  end_datetime,
                  tag_id,
                  label_image_url,
                } = data

                return {
                  campaign_name,
                  start_datetime,
                  end_datetime,
                  tag_id,
                  label_image_url,
                }
              },
            }}
          />
        )}
      </>
    )
  }
}

const CampaignLabels = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig

  return <CampaignLabelsClass {...props} splits={splits} />
}

export default CampaignLabels
