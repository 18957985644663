import React, { useState } from 'react'
import './AddProductSKU.css'

const AddProductSKU = ({ onChange, values = [] }) => {
  const [sku, setSku] = useState('')

  const handleAddSKU = () => {
    const newSkus = sku.split(',')

    const updatedSkus = [...values]

    newSkus.forEach(_sku => {
      if (_sku.trim() && !updatedSkus.includes(_sku.toUpperCase().trim())) {
        updatedSkus.push(_sku.toUpperCase().trim())
      }
    })

    onChange(updatedSkus)
    setSku('')
  }

  const handleRemoveSKU = value => {
    onChange(values.filter(_sku => _sku !== value))
  }

  return (
    <div>
      <label htmlFor="product-input" className="add-product__label">
        Add Product
      </label>
      <div className="product-input__wrapper">
        <input
          id="product-input"
          value={sku}
          onChange={e => setSku(e.target.value)}
        />
        <button
          type="button"
          className="add-product-btn"
          onClick={handleAddSKU}
        >
          +Add
        </button>
      </div>
      <div className="sku-list">
        {values.map(_sku => (
          <div key={_sku} className="added-sku">
            <span>{_sku}</span>
            <span className="remove-sku" onClick={() => handleRemoveSKU(_sku)}>
              x
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AddProductSKU
