import React, { useEffect, useState } from 'react'

import Loader from '../../../../components/Loader'
import PropTypes from 'prop-types'
import iconSearch from './search.svg'

import { get } from '../../../../lib/storage'
import './StoreGroup.css'
import CategorizedStoreSelector, {
  ALL_OFFLINE_STORES,
  FAIRPRICE_FFS,
  FAIRPRICE_ONLINE,
  NON_SNG_STORES,
  SNG_STORES,
  ALL_PREORDER_STORES
} from '../../../../components.new/store-selector/CategorizedStoreSelector'
export const defaultStoreId = JSON.parse(get('organization') || '{}')
  .defaultStoreId

export function StoreGroup({
  storeType,
  stores = [],
  getInventoryData,
  getStoreDetailsEl,
  allowedCategories = defaultAllowedStores,
}) {
  const [isSearching, setIsSearching] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isStoreOpen, setIsStoreOpen] = useState(true)
  const [filteredStores, setFilteredStores] = useState([])

  const handleStoreGroupStatus = () => {
    setIsStoreOpen(prev => !prev)
  }

  const fetchStoreInventoryData = storesToFetch => {
    setIsLoading(true)
    getInventoryData(storesToFetch, () => setIsLoading(false))
  }

  useEffect(() => {
    if (isStoreOpen) {
      if (stores.length === 1) {
        fetchStoreInventoryData(stores)
      } else {
        if (isSearching) {
          fetchStoreInventoryData(filteredStores)
        }
      }
    }
  }, [filteredStores, isSearching, isStoreOpen])

  return (
    <React.Fragment>
      <div className="toggle-expand" onClick={handleStoreGroupStatus}>
        <div className="flex">
          <span className={`store-type ${isStoreOpen ? 'open' : 'closed'}`}>
            {storeType}
          </span>
          {isLoading ? <Loader size="sm" /> : null}
        </div>
      </div>
      <div
        className={`store-group-box ${isStoreOpen ? 'expanded' : 'contracted'}`}
      >
        {isSearching ? (
          <ResultDisplay
            filteredStores={filteredStores}
            setIsSearching={setIsSearching}
          />
        ) : null}
        {!isSearching ? (
          <SearchingMode
            stores={stores}
            selectedIds={selectedIds}
            getStoreDetailsEl={getStoreDetailsEl}
            allowedCategories={allowedCategories}
            onUpdate={res => {
              const storesToView = stores.filter(store =>
                res.includes(store.id)
              )
              setFilteredStores(storesToView)
              setSelectedIds(res)
            }}
          />
        ) : filteredStores.length ? (
          getStoreDetailsEl(filteredStores)
        ) : null}
        {isSearching || stores.length === 1 ? null : (
          <button
            className="primary button margin-top__small"
            disabled={filteredStores.length === 0}
            onClick={e => {
              e.preventDefault()
              setIsSearching(true)
            }}
          >
            Show store details
            {filteredStores.length ? `(${filteredStores.length})` : ''}
          </button>
        )}
      </div>
    </React.Fragment>
  )
}

const SearchingMode = ({
  stores,
  getStoreDetailsEl,
  allowedCategories,
  selectedIds,
  onUpdate,
}) => {
  return (
    <div>
      {stores.length === 1 ? (
        getStoreDetailsEl(stores)
      ) : stores.length > 1 ? (
        <div className="categorized-selector-container">
          <CategorizedStoreSelector
            allowedCategories={allowedCategories}
            onUpdate={onUpdate}
            stores={stores}
            values={selectedIds}
          />
        </div>
      ) : null}
    </div>
  )
}

const ResultDisplay = ({ filteredStores, setIsSearching }) => {
  return (
    <React.Fragment>
      <button
        className="primary button margin-top__small"
        disabled={filteredStores.length === 0}
        onClick={e => {
          e.preventDefault()
          setIsSearching(false)
        }}
      >
        <img
          src={iconSearch}
          alt="Search Icon"
          style={{
            width: '20px',
            height: '20px',
            marginRight: '10px',
            filter: 'brightness(2.5)',
          }}
        />
        Search Again
      </button>
      <div className="margin-top__small">
        Result ({filteredStores.length} stores)
      </div>
    </React.Fragment>
  )
}

export const STORE_TYPES = {
  PFC: 'pfc',
  FFS: 'ffs',
  SNG: 'sng',
  DEFAULT: 'pfc',
}

const defaultAllowedStores = [
  ALL_OFFLINE_STORES,
  NON_SNG_STORES,
  FAIRPRICE_ONLINE,
  FAIRPRICE_FFS,
  SNG_STORES,
  ALL_PREORDER_STORES
]

StoreGroup.propTypes = {
  storeType: PropTypes.string,
  stores: PropTypes.array.isRequired,
  getInventoryData: PropTypes.func.isRequired,
  getStoreDetailsEl: PropTypes.func.isRequired,
  allowedCategories: PropTypes.array,
}
