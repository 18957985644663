import { isExtensionEnabled } from 'lib/auth'
import { getMessage } from '../../../lib/translator'

export const OfferTypes = {
  BANYGLED:
    'Buy any `n` of the selected variants (X,Y....) and get discount on mrp', // Buy any at Price Z,
  BANYATP:
    'Buy any `n` of the selected variants (X,Y....) and get discount on mrp', // Buy any at Price Z
  BMINXATP: 'Buy n or more of X(or X, Y) and get discount on MRP', // Buy min of X at Price X
  BANYGYD:
    'Buy any `n` of the selected variants (X,Y....) and get Z at discounted price/Free', // Buy Any Get Y at Discount (Z%)
  BXATP: 'Buy X Product At Price (Z)', // Buy X Product At Price (Z)
  BXGYD: 'Buy X and Get Y at discounted price/Free', // Buy X and Get Y at Discount
  SF: 'Shop For `n` Amount and get Y at discounted price/Free', // Shop For Rs.X and GET Y at free/D% discount/X off,
  SFXGSD: 'Shop for X amount and get discount on shipping',
  SFXGSFD: 'Shop on X amount and get discount on Service Fee',
  SFXGCD: 'Shop for X amount and get discount on cart',
  PWP: 'Purchase with purchase',
  BMIN: 'Buy for min amt in entity/non-entity and get discount',
  BFXATP: 'Buy any of the tags at discounted price/Free',
  BMINXGFG: 'Buy for min amt in entity/non-entity and get products at discount',
}

export const OfferMapping = [
  {
    text: OfferTypes.BANYATP,
    value: 'BANYATP',
    isUnityOffer: true,
  },
  {
    text: OfferTypes.BMINXATP,
    value: 'BMINXATP',
  },
  {
    text: OfferTypes.BANYGYD,
    value: 'BANYGYD',
  },
  {
    text: OfferTypes.SF,
    value: 'SF',
  },
  {
    text: OfferTypes.BMIN,
    value: 'BMIN',
  },
  {
    text: OfferTypes.BMINXGFG,
    value: 'BMINXGFG',
  },
  {
    text: OfferTypes.SFXGSD,
    value: 'SFXGSD',
  },
  {
    text: OfferTypes.SFXGSFD,
    value: 'SFXGSFD',
  },
  {
    text: OfferTypes.SFXGCD,
    value: 'SFXGCD',
  },
  {
    text: OfferTypes.BXATP,
    value: 'BXATP',
  },
  {
    text: OfferTypes.BXGYD,
    value: 'BXGYD',
  },
  {
    text: OfferTypes.PWP,
    value: 'PWP',
  },
  {
    text: OfferTypes.BFXATP,
    value: 'BFXATP',
  },
]

export const ProductOnlyOffers = [
  'SFXGSD',
  'SFXGSFD',
  'SFXGCD',
  'BXATP',
  'BXGYD',
  'PWP',
  'BFXATP',
]

export const linkPayOffers = [
  'BANYATP',
  'BMINXATP',
  'BANYGYD',
  'SFXGCD',
  'BMIN',
  'BXATP',
  'BXGYD',
]

export const EntityIds = [
  {
    text: getMessage('offer.product'),
    value: 'PRODUCT',
  },
  {
    text: getMessage('offer.category'),
    value: 'CATEGORY',
  },
]

export const getEntityIds = () => isExtensionEnabled('MultiBrandSupport') ? EntityIds.concat({
  text: getMessage('offer.brand'),
  value: 'BRAND',
}) : EntityIds

export const DiscountTypes = [
  {
    text: getMessage('offer.fixed'),
    value: 'FIXED',
  },
  {
    text: getMessage('offer.percentoff'),
    value: 'PERCENT_OFF',
    isUnityOffer: true,
  },
  {
    text: getMessage('offer.absoluteoff'),
    value: 'ABSOLUTE_OFF',
  },
]

export const StoreOptions = [
  {
    text: getMessage('offer.all.stores'),
    value: 'ALL',
  },
  {
    text: getMessage('offer.selected.stores'),
    value: 'SELECTED',
  },
  {
    text: getMessage('offer.all.except.selected.stores'),
    value: 'ALL_EXCEPT_SELECTED',
  },
]

export const ItemDiscountTypes = [
  {
    text: getMessage('offer.combo.discount'),
    value: 'COMBO_DISCOUNT',
    linkPay:true,
  },
  {
    text: getMessage('offer.individual.discount'),
    value: 'INDIVIDUAL_DISCOUNT',
    linkPay:false,
  },
]

export const OfferValue  = {
  "addToWallet": false,
  "clientId": null,
  "customerRedemptionLimit": null,
  "description": "Buy 2, get free Fancy Feast Cat Food 100g",
  "eligibleItems": [
      {
          "displayUnit": "85G",
          "image": "https://s3-ap-southeast-1.amazonaws.com/www8.fairprice.com.sg/fpol/media/images/product/XL/472580_XL1.jpg",
          "name": "Fancy Feast Classic Cat Food - Tender Liver & Chicken",
          "sku": 472580,
          "slug": "fancy-feast-classic-cat-food-tender-liver-and-chicken-feast-472580"
      }
  ],
  "eligibleItemsLinks": {
      "mobile": null,
      "web": null
  },
  "endDeliveryDate": null,
  "hasUniquePromocode": false,
  "id": 11287200,
  "ignoreSegment": false,
  "imageUrl": "",
  "metaData": {
      "iconType": "free_gift",
      "sapReference": "FREEGIFT Fancy Feast Classic Cat Food for any Kitchen Solution"
  },
  "offerType": "BANYGYD",
  "offerValidFrom": "2023-03-01 05:11:52",
  "offerValidTill": "2023-04-30 10:11:52",
  "orderType": [
      "BULK",
      "DELIVERY",
      "PICKUP",
      "B2B"
  ],
  "paymentType": null,
  "promoCode": null,
  "promoCodeDetail": {
      "howToUseText": "You need to buy 2 product to avail the offer",
      "subTitle": null,
      "title": null,
      "tncUrl": null
  },
  "rule": {
      "entity": {
          "id": 478,
          "type": "CATEGORY"
      },
      "get": {
          "1185355": {
              "q": 1
          }
      },
      "limit": 0,
      "quantity": 1,
      "total": {
          "t": "PERCENT_OFF",
          "v": 100
      },
      "isUnityOffer": false
  },
  "ruleDetail": {
      "buy": {
          "categories": [
              {
                  "clientId": "12989",
                  "createdAt": null,
                  "deletedAt": null,
                  "description": null,
                  "id": 478,
                  "image": "banners/web/category/KitchenSolutions_L2.jpg",
                  "languages": "",
                  "name": "Kitchen Solutions",
                  "parentCategory": {
                      "clientId": "12710",
                      "createdAt": null,
                      "deletedAt": null,
                      "description": null,
                      "id": 722,
                      "image": "banners/web/category/HomeLiving_L1.jpg",
                      "languages": "",
                      "name": "Home & Living",
                      "parentCategory": null,
                      "productsCount": 1,
                      "slug": "home-living",
                      "status": "ENABLED",
                      "updatedAt": null,
                      "updatedBy": ""
                  },
                  "productsCount": 0,
                  "slug": "kitchen-solutions",
                  "status": "ENABLED",
                  "updatedAt": null,
                  "updatedBy": ""
              }
          ],
          "isCombo": false,
          "minCartPrice": null,
          "quantity": 1
      },
      "elementGroup": null,
      "get": {
          "discountType": "PERCENT_OFF",
          "discountValue": 100,
          "isCombo": false,
          "isFree": true,
          "minCartPrice": null,
          "products": [
              {
                  "barcodes": [
                      "50000429042"
                  ],
                  "brand": {
                      "clientId": "FANCY FEAST",
                      "description": null,
                      "id": 31117,
                      "image": null,
                      "languages": "",
                      "logo": null,
                      "name": "Fancy Feast",
                      "productsCount": 125,
                      "slug": "fancy-feast-1",
                      "status": "ENABLED"
                  },
                  "bulkOrderThreshold": 99999,
                  "clientItemId": "472580",
                  "createdAt": "2019-04-27T04:36:41+08:00",
                  "description": "An enticing feast made with liver and chicken feast",
                  "handlingDays": 0,
                  "hasVariants": 0,
                  "id": 1185355,
                  "images": [
                      "https://s3-ap-southeast-1.amazonaws.com/www8.fairprice.com.sg/fpol/media/images/product/XL/472580_XL1.jpg",
                      "https://s3-ap-southeast-1.amazonaws.com/www8.fairprice.com.sg/fpol/media/images/product/XL/472580_LXL1.jpg",
                      "https://s3-ap-southeast-1.amazonaws.com/www8.fairprice.com.sg/fpol/media/images/product/XL/472580_BXL1.jpg"
                  ],
                  "imagesExtra": null,
                  "languages": "",
                  "metaData": {
                      "Country of Origin": "USA",
                      "Dietary Attributes": [],
                      "DisplayUnit": "85G",
                      "Fp Seller Name": "JOO KOON DC",
                      "Ingredients": "Liver, Meat Broth, Meat By-products, Chicken, Artificial and Natural Flavours, Calcium Phosphate, Guar Gum, Potassium Chloride, Taurine, Salt, Mangesium Sulphate, Thiamine Mononitrate, Vitamin E Supplement, Zinc Sulphate, Ferrous Sulphate, Niacin, Calcium Pantothenate, Vitamin A Supplement, Copper Sulphate, Manganese Sulphate, Menadione Sodium Bisulphite Complex (Source of Vitamin K Activity), Pyridoxine Hydrochloride, Riboflavin Supplement, Vitamin B-12 Supplement, Biotin, Folic Acid, Vitamin D-3 Supplement, Potassium Iodide",
                      "Key Information": "Fancy Feast Classic Tender Liver and Chicken Feast offers a smooth pate made from a combination of liver and chicken feast",
                      "LinkPoint Eligible": true,
                      "Nutrigrade": "",
                      "Nutritional Data": "<table border=\"1\">\r\n<tbody>\r\n<tr>\r\n<th>Attributes</th>\r\n<th>Per Serving</th>\r\n</tr>\r\n<tr>\r\n<td>Crude Protein (Min)</td>\r\n<td>11%</td>\r\n</tr>\r\n<tr>\r\n<td>Taurine (Min)</td>\r\n<td>0.05%</td>\r\n</tr>\r\n<tr>\r\n<td>Crude Fat (Min)</td>\r\n<td>5%</td>\r\n</tr>\r\n<tr>\r\n<td>Ash (Max)</td>\r\n<td>3%</td>\r\n</tr>\r\n</tbody>\r\n</table>",
                      "Preparation": "1. Feed an average-sized adult cat 1 can per 1.4 kilograms of body weight daily<br>2. Feed up to twice this amount to kittens<br>3. Pregnant or nursing cats may require two to four times their normal feeding",
                      "SAP Product Name": "FANCY FEAST CAT FOOD - CLASSIC TENDER LIVER & CHICKEN FEAST 85G",
                      "SAP SubClass": 320690202,
                      "Storage Information": "Store in a cool, dry place",
                      "Unit Of Measurement": "EA",
                      "Unit Of Weight": "85G",
                      "Weight": "85 gm"
                  },
                  "name": "Fancy Feast Classic Cat Food - Tender Liver & Chicken",
                  "organizationId": "2",
                  "primaryCategory": {
                      "clientId": "13607",
                      "createdAt": null,
                      "deletedAt": null,
                      "description": null,
                      "id": 220,
                      "image": null,
                      "languages": "",
                      "name": "Cats",
                      "parentCategory": {
                          "clientId": "12996",
                          "createdAt": null,
                          "deletedAt": null,
                          "description": null,
                          "id": 674,
                          "image": "banners/web/category/PetNeeds_L2.jpg",
                          "languages": "",
                          "name": "Pet Needs",
                          "parentCategory": {
                              "clientId": "12710",
                              "createdAt": null,
                              "deletedAt": null,
                              "description": null,
                              "id": 722,
                              "image": "banners/web/category/HomeLiving_L1.jpg",
                              "languages": "",
                              "name": "Home & Living",
                              "parentCategory": null,
                              "productsCount": 29,
                              "slug": "home-living",
                              "status": "ENABLED",
                              "updatedAt": null,
                              "updatedBy": ""
                          },
                          "productsCount": 0,
                          "slug": "pet-needs-12996-1",
                          "status": "ENABLED",
                          "updatedAt": null,
                          "updatedBy": ""
                      },
                      "productsCount": 0,
                      "slug": "cats",
                      "status": "ENABLED",
                      "updatedAt": null,
                      "updatedBy": ""
                  },
                  "quantity": 1,
                  "secondaryCategoryIds": [
                      1502
                  ],
                  "slug": "fancy-feast-classic-cat-food-tender-liver-and-chicken-feast-472580",
                  "soldByWeight": 0,
                  "status": "ENABLED",
                  "stockOverride": {
                      "maxPurchasableStock": null,
                      "stockBuffer": 5,
                      "storeBulkOrderThreshold": null
                  },
                  "tagIds": [
                      4
                  ],
                  "updatedBy": "1547",
                  "variants": null,
                  "vendor_code": 0
              }
          ]
      }
  },
  "stackable": true,
  "startDeliveryDate": null,
  "status": "DISABLED",
  "storeId": [
      4,
      8,
      204,
      358,
      396
  ],
  "totalRedemption": null,
  "updatedAt": "0001-01-01T00:00:00Z",
  "includes": false,
  "entityType": "CATEGORY",
  "itemDiscountType": "COMBO_DISCOUNT",
  "type": "BANYGYD",
  "validFrom": "2023-03-01",
  "validFromTime": "05:11:52",
  "validTill": "2023-04-30",
  "validTillTime": "10:11:52",
  "startDeliveryDateTime": null,
  "endDeliveryDateTime": null,
  "method": "edit"
}
