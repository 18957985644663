import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import UploadWidget from './UploadWidget'
import API from '../../../lib/api'
import { getSession } from '../../../lib/auth'
import Table, { Cell, Header, Row } from '../../../components/Table'
import Loader from '../../../components/Loader'
import Tabs from 'components/Tabs'
import BulkDispatchOrders from './BulkDispatchOrders';

const CLEANUP_API_URL = '/order-service/order-cleanup'

export default class OrderCleanup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isResultsLoading: true,
      JobsLoadErr: '',
      Jobs: [],
      DisableUpload: false,
      FileUploaded: false,
      pages: [
        "Complete Orders",
        "Dispatch Orders"
      ],
      page: 0
    }
  }
  componentDidMount() {
    this.loadJobs()
  }
  tallyStatus = (job) => {
    const interval = setInterval(() => {
      const api = new API({ url: `${CLEANUP_API_URL}?jobid=${job.jobID}` })
      api
        .get()
        .then((resp) => {
          if (typeof resp === 'string') {
            resp = JSON.parse(resp)
          }
          if (resp.data[0].status === 'COMPLETED') {
            clearInterval(interval)
            this.setState({
              DisableUpload: false,
            })
          }
          const Jobs = this.state.Jobs.map((j) => {
            if (j.jobID === job.jobID) {
              return resp.data[0]
            } else {
              return j
            }
          })
          this.setState({
            Jobs: Jobs,
          })
        })
        .catch(() => {
          this.setState({
            DisableUpload: true,
          })
          clearInterval(interval)
        })
    }, 500)
  }

  loadJobs = () => {
    const api = new API({ url: CLEANUP_API_URL })
    api
      .get()
      .then((resp) => {
        if (typeof resp === 'string') {
          resp = JSON.parse(resp)
        }
        const data = resp.data.sort((a, b) => {
          return (
            parseInt(b.jobID.replace('CLEANUP_', ''), 10) -
            parseInt(a.jobID.replace('CLEANUP_', ''), 10)
          )
        })

        const toCheck = data.filter((item) => {
          return item.status === 'IN PROGRESS'
        })

        let disableUpload = false
        if (toCheck.length > 0) {
          this.tallyStatus(toCheck[0])
          disableUpload = true
        }

        this.setState({
          isResultsLoading: false,
          Jobs: data,
          DisableUpload: disableUpload,
        })
      })
      .catch((err) => {
        this.setState({
          isResultsLoading: false,
          Jobs: [],
          JobsLoadErr: err.message,
        })
      })
  }

  handleResults = (file) => {
    const data = new window.FormData()
    data.append('file', file)
    data.append('userID', getSession().user.id)
    const api = new API({ url: CLEANUP_API_URL })
    return api
      .post(data)
      .then((resp) => {
        if (typeof resp === 'string') {
          resp = JSON.parse(resp)
        }
        if (resp.code === 200 && resp.status === 'SUCCESS') {
          this.setState({
            FileUploaded: true,
          })
          this.loadJobs()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  handleDownloadCSVReport = (e) => {
    const jobid = e.currentTarget.getAttribute('data-jobid')
    const result = this.state.Jobs.filter((job) => {
      return job.jobID === jobid
    })
    if (result.length > 0) {
      const csv = result[0].details.map((order) => {
        return `${order.referenceNumber},${order.remarks}`
      })
      e.currentTarget.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,reference number,remarks\n' +
          csv.join('\n')
      )
    }
  }
  handleDownloadSampleCsv = (e) => {
    e.currentTarget.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,referenceNumber,driverId\n94596097,182'
    )
  }

  renderCompleteOrders() {
    return
  }

  render() {
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <h1>Order Cleanup</h1>

        <Tabs
          items={this.state.pages.map(page => `${page}`)}
          default={this.state.page}
          onClick={index => this.setState({ page: index })}/>
        {
          this.state.pages[this.state.page] === "Complete Orders" &&
          <>
            <p>
              Process orders which are already delivered but are still showing in
              PACKED, DISPATCHED, COMPLETED (without payment) status
            </p>

            <div className="batch-upload">
              {this.state.DisableUpload ? (
                <div className="bubble">
                  <div className="processing"></div>
                  Cleanup Job Running
                </div>
              ) : (
                <UploadWidget
                  onUpload={this.handleResults}
                  onDownloadCsv={this.handleDownloadSampleCsv}
                  diabled={this.state.DisableUpload}
                  uploadedFile={this.state.UploadedFile}
                />
              )}
            </div>

            <h3>Cleanup Jobs</h3>
            {this.state.isResultsLoading && <Loader />}

            {this.state.Jobs.length > 0 && this.state.JobsLoadErr === '' && (
              <Table tableDynamic={false}>
                <Header
                  items={[
                    'Job ID',
                    'Status',
                    'Total Orders',
                    'Processed',
                    'Failed',
                    '',
                  ]}
                />

                {this.state.Jobs.map((job) => {
                  return (
                    <Row key={job.jobID}>
                      <Cell>
                        <span>{job.jobID}</span>
                      </Cell>
                      <Cell>
                        <span>{job.status}</span>
                      </Cell>
                      <Cell>
                        <span>{job.count.total}</span>
                      </Cell>
                      <Cell>
                        <span className="product-name">{job.count.processed}</span>
                      </Cell>
                      <Cell>
                        <span className="product-name">{job.count.failed}</span>
                      </Cell>
                      <Cell>
                        <a
                          onClick={this.handleDownloadCSVReport}
                          download={job.jobID + '.csv'}
                          data-jobid={job.jobID}
                        >
                          Download Report
                        </a>
                      </Cell>
                    </Row>
                  )
                })}
              </Table>
            )}
            {this.state.JobsLoadErr !== '' && (
              <div className="form-error">{this.state.JobsLoadErr}</div>
            )}
            {this.state.Jobs.length === 0 && (
              <div className="bubble">No Jobs Yet</div>
            )}
          </>
        }
        {
          this.state.pages[this.state.page] === "Dispatch Orders" &&
          <BulkDispatchOrders />
        }

      </AuthenticatedPage>
    )
  }
}
