import React from 'react'
import { useState } from 'react'
import Tabs from '../../../../components/Tabs'
import UpdatedLayout from './UpdatedLayout'
import './O2OLayout.css'

const O2OLayout = ({
  items,
  onEditClick,
  refetchLayout,
  view,
  setView,
  onRemoveClick,
  showDialog,
  onChangeLayout,
  layoutPicker,
  timeline,
}) => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleChangeIndex = (index) => {
    if (index !== tabIndex) {
      setTabIndex(index)
    }
  }

  return (
    <div
      className="themes-customize o2o-layout"
      style={{
        marginTop: 20,
      }}
    >
      <Tabs
        items={['Home']}
        default={tabIndex}
        onClick={(index) => {
          handleChangeIndex(index)
        }}
      />
      {layoutPicker}
      <UpdatedLayout
        data={items}
        onEditClick={onEditClick}
        tabName="O2O"
        refetchLayout={refetchLayout}
        view={view}
        setView={setView}
        onRemoveClick={onRemoveClick}
        showDialog={showDialog}
        onChangeLayout={onChangeLayout}
        timeline={timeline}
        isLayoutEditAllowed={true}
      />
    </div>
  )
}

export default O2OLayout
