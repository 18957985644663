import React, { Fragment } from 'react'
import { getMessage } from 'lib/translator'
import { Input } from '../../Form'
import PromotionalPrice from './PromotionalPrice'

const LinkpointPrice = ({ _this }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <h3>{getMessage('rewards.catalogue.LinkpointPrice')}</h3>
        </div>
      </div>
      <Fragment>
        <div className="row">
          <div className="col-6 pr">
            <Input
              label={getMessage('rewards.catalogue.regularPrice')}
              placeholder={getMessage('rewards.catalogue.regularPrice')}
              type="number"
              prefix="LP"
              step="0.01"
              min={0.01}
              max={999999}
              {..._this.generateStateMappers({
                stateKeys: ['linkpointPrice', 'regular'],
                loseEmphasisOnFill: true,
              })}
              required
            />
          </div>
        </div>
        <PromotionalPrice _this={_this} />
      </Fragment>
    </Fragment>
  )
}
export default LinkpointPrice
