import React, { useEffect } from 'react'

import './style.css'

const Toast = props => {

  useEffect(() => {
    let timerId;
    if (props.timer) {
      timerId = setTimeout(() => {
        props.onClose();
      }, props.timer);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [props.timer])

  return (
    <div className="toast" style={props.style}>
      <div className="content" style={ props.toastColor ? {backgroundColor: props.toastColor} : {} }>
        <div>{props.content}</div>
        <div className="close-btn" onClick={props.onClose}>
          {props.cancelMessage}
        </div>
      </div>
    </div>
  )
}

export default Toast
