import React, { Fragment } from 'react'
import { Input, VALIDATION_TYPES } from '../../Form'
import { getMessage } from 'lib/translator'

const UrlInfo = ({ _this }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <h3>{getMessage('rewards.urlInfo')}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Input
            label={getMessage('rewards.promotion.urlInfo.externalUrl')}
            placeholder={getMessage('rewards.promotion.urlInfo.externalUrl')}
            type="text"
            {..._this.generateStateMappers({
              stateKeys: ['externalUrl'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default UrlInfo
