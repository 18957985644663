import React from 'react'
import { Input, Upload, Textarea, Select } from '../../../../components/Form'
import { BannerWithMultipleButtons } from '../../../../components/migrated-from-admin-ui-builder'
import { fontSizes } from '../constants.js'

// lower case t is used in Text because it iis dependent on a module whose name is imageWithText with an Upper case T
const BannerWithMultipleButtonsLayout = () => {
  return {
    preview: BannerWithMultipleButtons,
    fields: ({ getState, updateState }) => {
      return (
        <div className="form-sections">
          <div className="form-section">
            <Input
              label="title"
              placeholder="please enter title here"
              value={getState(['title']) || ''}
              onChange={(value) => {
                updateState(['title'], value)
              }}
            />
            <Select
              label="font size (in px)"
              placeholder="font size for title in px"
              options={fontSizes}
              value={getState(['fontSizeForTitle']) || ''}
              onChange={(value) => {
                updateState(['fontSizeForTitle'], value)
              }}
            />
            <Upload
              label="image"
              value={getState(['src']) || ''}
              className="image_bannerbutton"
              onChange={(value) => {
                updateState(['src'], value)
              }}
            />
            <Select
              label="font size (in px)"
              placeholder="font size for description in px"
              options={fontSizes}
              value={getState(['fontSizeForDescription']) || ''}
              onChange={(value) => {
                updateState(['fontSizeForDescription'], value)
              }}
            />
            <Input
              label="Text for Button 1"
              placeholder="text for button 1"
              value={getState(['action1']) || ''}
              onChange={(value) => {
                updateState(['action1'], value)
              }}
            />
            <Input
              label="Text for Button 2"
              placeholder="text for button 2"
              value={getState(['action2']) || ''}
              onChange={(value) => {
                updateState(['action2'], value)
              }}
            />
            <Input
              label="link"
              placeholder="URL to navigate on clicking"
              value={getState(['link']) || ''}
              onChange={(value) => {
                updateState(['link'], value)
              }}
            />
          </div>
          <div className="form-section">
            <Input
              label="subtitle"
              placeholder="Enter subtitle here"
              value={getState(['subtitle']) || ''}
              onChange={(value) => {
                updateState(['subtitle'], value)
              }}
            />
            <Input
              label="text color"
              placeholder="text color for title"
              type="color"
              value={getState(['textColorForTitle']) || ''}
              onChange={(value) => {
                updateState(['textColorForTitle'], value)
              }}
            />
            <Textarea
              label="description"
              placeholder="please enter description"
              value={getState(['details']) || ''}
              onChange={(value) => {
                updateState(['details'], value)
              }}
            />
            <Input
              label="text color"
              placeholder="text color for description"
              type="color"
              value={getState(['textColorForDescription']) || ''}
              onChange={(value) => {
                updateState(['textColorForDescription'], value)
              }}
            />
            <Input
              label="Link for Button 1"
              placeholder="link for Button 1"
              value={getState(['link1']) || ''}
              onChange={(value) => {
                updateState(['link1'], value)
              }}
            />
            <Input
              label="Link for Button 2"
              placeholder="link for Button 2"
              value={getState(['link2']) || ''}
              onChange={(value) => {
                updateState(['link2'], value)
              }}
            />
          </div>
        </div>
      )
    },
  }
}
export default BannerWithMultipleButtonsLayout
