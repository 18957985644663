import React from 'react'

import EmptyState from '../EmptyState'
import HelpCard from '../HelpCard'
import HelpWidget from '../HelpWidget'

import { isEnterprise } from '../../lib/auth'

const HelpItems = ({ emptyState, helpItems }) => (
  <div>
    <EmptyState {...Object.assign({}, emptyState)} />
    {helpItems && !isEnterprise() && (
      <HelpWidget title={helpItems.title}>
        {helpItems.instructions.map((instruction, index) => (
          <HelpCard
            icon={instruction.icon}
            title={instruction.title}
            key={index}
            onAction={instruction.onAction}
          >
            {instruction.description}
          </HelpCard>
        ))}
      </HelpWidget>
    )}
  </div>
)

export default HelpItems
