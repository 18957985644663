const data = {
  'rewards.form.submit': 'Submit',
  'rewards.form.cancel': 'Cancel',
  'rewards.form.confirm': 'Confirm',
  'rewards.form.clear': 'Clear',
  'rewards.defaultError': 'Something went wrong! please try again later.',
  'rewards.empty.message': 'Currently, there are no data available.',
  'rewards.status': 'Status',
  'rewards.active': 'Active',
  'rewards.inActive': 'Inactive',
  'rewards.rank': 'Rank',
  'rewards.type': 'Type',
  'rewards.segment': 'Segment',
  'rewards.customersMappedToSegment': 'Customers Mapped to Segment',
  'rewards.startDate': 'Start Date',
  'rewards.endDate': 'End Date',
  'rewards.startTime': 'Start Time',
  'rewards.endTime': 'End Time',
  'rewards.startAt': 'Start At',
  'rewards.endAt': 'End At',
  'rewards.displayChannel': 'Device Type',
  'rewards.app': 'App',
  'rewards.web': 'Web',
  'rewards.all': 'All',
  'rewards.title': 'Title',
  'rewards.subtitle': 'Subtitle',
  'rewards.cost': 'Cost',
  'rewards.costCenter': 'Cost Center',
  'rewards.publishDate': 'Publish Date',
  'rewards.publishTime': 'Publish Time',
  'rewards.expiryDate': 'Expiry Date',
  'rewards.expiryTime': 'Expiry Time',
  'rewards.date': 'Date',
  'rewards.time': 'Time',
  'rewards.logo': 'Logo',
  'rewards.banner': 'Banner',
  'rewards.description': 'Description',
  'rewards.tnc': 'T&Cs',
  'rewards.address': 'Address',
  'rewards.street': 'Street',
  'rewards.floor': 'Floor',
  'rewards.unit': 'Unit',
  'rewards.postalCode': 'Postal Code',
  'rewards.partner': 'Partner',
  'rewards.name': 'Name',
  'rewards.searchBy': 'Search by',
  'rewards.slug': 'Permalink',
  'rewards.generateSlug': 'Generate link',
  'rewards.metaTitle': 'Meta Title',
  'rewards.metaDescription': 'Meta Description',
  'rewards.story': 'Story',
  'rewards.clear': 'Clear',
  'rewards.partnerName': 'Partner Name',
  'rewards.uploadedCsv.heading':
    'CSV File Uploaded Successfully! Please refresh the page!',
  'rewards.csvDownloadText': 'Download sample CSV',
  'rewards.uploadFileText': 'Click here to upload the file',
  'rewards.createdBy': 'Created By',
  'rewards.createdAt': 'Created At',
  'rewards.updatedBy': 'Last Updated By',
  'rewards.updatedAt': 'Last Updated At',
  'rewards.urlInfo': 'Url Info',
  'rewards.webInfo': 'Web Info',
  'rewards.actions': 'Actions',
  'rewards.id': 'ID',
  'rewards.clsMerchantId': 'CLS Merchant ID',
  'rewards.searchName.placeholder': 'Search by name',
  'rewards.selectType': 'Select type',

  'rewards.channel.Cheers.linkcard': 'Cheers (Link card)',
  'rewards.channel.Cheers.autonomous': 'Cheers Autonomous (Linkpay)',
  'rewards.channel.Cheers.linkpay': 'Cheers (Linkpay)',
  'rewards.channel.Cheers.linkpayLoyalty': 'Cheers (Linkpay Loyalty)',

  'rewards.channel.fairPrice.linkCard': 'Fairprice (Link card)',
  'rewards.channel.fairPrice.linkPay': 'FairPrice (Linkpay)',
  'rewards.channel.fairPrice.linkPayLoyalty': 'FairPrice (Linkpay Loyalty)',
  'rewards.channel.fairPrice.online': 'FairPrice (Online)',
  'rewards.channel.fairPrice.ScanNGo': 'FairPrice (Scan & Go)',

  'rewards.channel.kopitiam.ClickAndCollect': 'Kopitiam (Click and collect)',
  'rewards.channel.kopitiam.linkpay': 'Kopitiam (Linkpay)',

  'rewards.channel.unity.linkcard': 'Unity (Link card)',
  'rewards.channel.unity.linkpay': 'Unity (Linkpay)',
  'rewards.channel.unity.linkpayLoyalty': 'Unity (Linkpay Loyalty)',
  'rewards.channel.others.linkpayLoyalty': 'Others (Linkpay Loyalty)',
  'rewards.channel.preOrderOnline': 'PreOrder (Online)',

  'rewards.category.add': 'Add Category',
  'rewards.category.edit': 'Edit Category',
  'rewards.categories.heading': 'Categories',
  'rewards.category.visible': 'Visible',
  'rewards.category.hidden': 'Hidden',
  'rewards.category.hideIfNoActiveRewards':
    'Hide category from being displayed on app when there are no active rewards',
  'rewards.category.homeDeck': 'Home Deck',
  'rewards.category.homeSwimlane': 'Home Swimlane',
  'rewards.category.catalogueTab': 'Catalogue Tab',
  'rewards.category.rankHeader': 'Category Rank by Placements',
  'rewards.category.rankSubHeader':
    'Leave unchecked if category should not be placed in certain placement',

  'rewards.carousel.add': 'Add Carousel',
  'rewards.carousel.edit': 'Edit Carousel',
  'rewards.carousels.title': 'Carousels',
  'rewards.carousel.webBanner': 'Web banner (2550x1500)',
  'rewards.carousel.appBanner': 'App banner (984 x 360)',
  'rewards.carousel.pageUrl': 'Page URL (CTA)',
  'rewards.carousel.title': 'Title',
  'rewards.carousel.subliner': 'Subtitle',
  'rewards.carousel.promotion': 'Promotion',
  'rewards.carousel.link': 'Link',
  'rewards.carousel.login': 'Login',

  'rewards.maintenance.add': 'Add Maintenance',
  'rewards.maintenance.edit': 'Edit Maintenance',
  'rewards.maintenance.message': 'Message',
  'rewards.maintenance.title': 'Maintenance',

  'rewards.partner.add': 'Add Partner',
  'rewards.partner.edit': 'Edit Partner',
  'rewards.partners.heading': 'Partners',
  'rewards.partner.linkpointGain': 'Issuance Rate Linkpoint',
  'rewards.partner.linkpointDollarSpend': 'Issuance Rate Dollar Value',
  'rewards.partner.minSpend': 'Min Spend',
  'rewards.partner.linkpointDescription': 'Linkpoint Description',
  'rewards.partner.urlInfo.externalUrl': 'App CTA Url',
  'rewards.partner.urlInfo.web': 'Web Url',
  'rewards.partner.urlInfo.facebook': 'Facebook Url',
  'rewards.partner.urlInfo.twitter': 'Twitter Url',
  'rewards.partner.urlInfo.instagram': 'Instagram Url',
  'rewards.partner.categoryInfo.primary': 'Primary Category',
  'rewards.partner.categoryInfo.categories': 'Select Categories',
  'rewards.partner.categoryInfo.category': 'Category',
  'rewards.partner.clsMerchantId': 'CLS Merchant ID',

  'rewards.store.add': 'Add Store',
  'rewards.store.edit': 'Edit Store',
  'rewards.store.period': 'Period',
  'rewards.stores.title': 'Stores',
  'rewards.store.name': 'Store Name',
  'rewards.store.lat': 'Lat',
  'rewards.store.lng': 'Lng',
  'rewards.store.OpeningHours': 'Opening Hours',
  'rewards.store.import': 'Import stores',
  'rewards.store.pinCode': 'Pin Code',

  'rewards.promotion.add': 'Add Promotion',
  'rewards.promotions.edit': 'Edit Promotion',
  'rewards.promotions.heading': 'Promotions',
  'rewards.promotion.name': 'Name',
  'rewards.promotion.description': 'Description',
  'rewards.promotion.webDescription': 'Web Description',
  'rewards.promotion.landscapeBanner': 'Web Banner (640x335)',
  'rewards.promotion.banner': 'App Banner (1080x720)',
  'rewards.promotion.urlInfo.externalUrl': 'App CTA Url',

  'rewards.catalogue.add': 'Add Reward',
  'rewards.catalogue.edit': 'Edit Reward',
  'rewards.catalogue.heading': 'Catalogue',
  'rewards.catalogue.name': 'Name',
  'rewards.catalogue.description': 'Description',
  'rewards.catalogue.banner': 'App Banner (1080x720)',
  'rewards.howToRedeem': 'How To Redeem',
  'rewards.partnerOnlineCodes': 'Partner Online Codes',
  'rewards.partnerOnlineCodesPlaceholder':
    'Please ensure each promo code is separated by a line break.',
  'rewards.partnerOnlineCodes.error':
    'PARTNER_ONLINE reward must have total redemption cap = number of promo codes. Ensure each promo code is separated by a line break.',
  'rewards.partnerOnlineCodes.viewPromocodes': 'View Partner Online Codes',
  'rewards.partnerOnlineCodes.notAvailable': 'Not available for this reward.',
  'rewards.catalogue.redemptionLimit': 'Redemption Limit',
  'rewards.catalogue.redemptionValidity': 'Redemption Validity',
  'rewards.catalogue.LinkpointPrice': 'Linkpoint Price',
  'rewards.catalogue.RedemptionLimit': 'Redemption Limit',
  'rewards.catalogue.usageValidity': 'Usage Validity',
  'rewards.catalogue.useWithinDays': 'Use Within Days',
  'rewards.catalogue.usedByDate': 'Use By Date',
  'rewards.catalogue.fixed': 'Fixed',
  'rewards.catalogue.relative': 'Relative',
  'rewards.catalogue.usedBy': 'Used By',
  'rewards.catalogue.previous': 'Previous Price',
  'rewards.catalogue.current': 'Current Price',
  'rewards.catalogue.regularPrice': 'Regular Price',
  'rewards.catalogue.promotionalPrice': 'Promotional Price',
  'rewards.catalogue.totalRedemptionCap': 'Total Redemption Cap',
  'rewards.catalogue.redemptionCapPerUser': 'Redemption Cap Per User',
  'rewards.catalogue.voucherDenomination': 'Voucher Denomination',
  'rewards.catalogue.faceValue': 'Face Value',
  'rewards.catalogue.categoryInfo': 'Category Info',
  'rewards.catalogue.mechanics': 'Mechanics',
  'rewards.catalogue.mechanics.cashVouchers':
    'Cash vouchers ($ off no min spend)',
  'rewards.catalogue.mechanics.heroProductDeal': 'Hero product deal',
  'rewards.catalogue.mechanics.freeItem': 'Free item / Gift / Service',
  'rewards.catalogue.mechanics.1for1': '1 for 1 / Buy 1 get 1 free',
  'rewards.catalogue.mechanics.off': '% off',
  'rewards.catalogue.mechanics.offWithCriteria': '$ off with criteria',
  'rewards.catalogue.mechanics.lossLeaderDeal': 'Loss leader deal',
  'rewards.catalogue.mechanics.zeroMarginDeals': 'Zero margin deals',
  'rewards.catalogue.mechanics.others': 'Others',
  'rewards.catalogue.addPromotionalPrice': 'Add Promotional Price',
  'rewards.catalogue.membership.union': 'Union (NTUC Link) member',
  'rewards.catalogue.membership.fdc': 'Digital Club member',
  'rewards.catalogue.membership.ptr': 'Premium Tier',
  'rewards.catalogue.userSegment': 'User Segment Condition (optional)',
  'rewards.catalogue.userSegment.subtitle':
    ' Qualifying memberships. Leave blank if reward is available for all users to redeem.',

  'rewards.merchant.add': 'Add Merchant',
  'rewards.merchant.edit': 'Edit Merchant',
  'rewards.merchant.id': 'ID',
  'rewards.merchant.name': 'Name',
  'rewards.merchant.title': 'E&B Merchants',
  'rewards.merchant.operations': 'Operations',
  'rewards.merchant.search': 'Enter cls merchant id / name',

  'rewards.device.add': 'Add Device',
  'rewards.device.edit': 'Edit Device',
  'rewards.device.serialNumber': 'Serial Number',
  'rewards.device.clsTerminalId': 'CLS Terminal ID',
  'rewards.device.clsOperatorId': 'CLS Operator ID',
  'rewards.device.clsBranchNo': 'CLS Branch No',
  'rewards.device.storeName': 'Store Name',
  'rewards.device.storeAddress': 'Store Address',
  'rewards.device.title': 'E&B Devices',
  'rewards.device.operations': 'Operations',
  'rewards.device.import': 'Import Devices',
  'rewards.device.search':
    'Enter serial number / cls merchant id / branch no / store name',

  'rewards.buttonText.applygoodwill': 'Apply Goodwill',
  'rewards.order.newAmount': 'New order amount',
  'rewards.order.newAmount.placeholder':
    'Input new order amount to be counted towards challenge',
  'rewards.goodwill.reasonForRequest': 'Reason for request',
  'rewards.goodwill.reasonForRequest.placeholder':
    'Provide reason (or input customer support ticket url)',
  'customer.details.rewards.goodwill.confirmMsg':
    'This will manually qualify the order towards the challenge task. You will not be able to undo this action.',
  'rewards.confirmation.validationMessage':
    'Please confirm your selection by checking the box.',
  'rewards.goodwill.orderNo': 'Order no.',
  'rewards.goodwill.orderNo.placeholder': 'Input order number',
  'rewards.goodwill.applicableToChallenge': 'Applicable to challenge',
  'rewards.goodwill.manuallyQualifyOrder': 'Manually qualify order:',
  'rewards.confirmation.goodwill': 'Confirmation: Goodwill',
  'rewards.goodwill.apiError404':
    'Order is not applicable for goodwill to this task',
  'rewards.goodwill': 'Goodwill',
  'rewards.goodwill.successMessage': 'Goodwill processed successfully!',
  'rewards.goodwill.validationMsg': 'Please fill all the fields correctly.',
  'rewards.goodwill.channel': 'Channel',
  'rewards.SpendRelatedChannelSelected.error':
    'The following channels can only be selected for solely spend-related mechanics: ‘Link card’ and ‘Linkpay Loyalty’. Additional mechanics cannot be supported.',
  'rewards.allLinkMerchants': '+ All Link Merchants',
  'rewards.merchantsApplicable.placeholder': 'Select the Merchants',
  'rewards.removeAll': 'Remove All',
  'rewards.merchants': 'Merchants',
  'rewards.validationMessage': 'This field is required',
  'rewards.allStores': '+ All Stores',
  'rewards.searchStores': 'Search for Store',
  'rewards.storesApplicable': 'Stores Applicable (for INSTORE type)',
}

export default data
