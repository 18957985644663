import _ from 'lodash';

const padWithZero = (number) => _.padStart(number.toString(), 2, '0');

const calculateDuration = (startTime, currentTime) => {
  const diffInSeconds = currentTime - startTime;
  const seconds = Number(diffInSeconds);
  const d = Math.floor(seconds / (3600*24));
  const h = Math.floor(seconds % (3600*24) / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 60);

  const daysDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  const timeFormat = `${padWithZero(h)}:${padWithZero(m)}:${padWithZero(s)}`
  return daysDisplay + timeFormat;
};

export {
  calculateDuration
}
