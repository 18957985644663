import React, { Component } from 'react'
import './style.css'

export default class extends Component {
  render() {
    const content = this.props.data

    const style = {
      title: {
        color: content.textColorForTitle,
        fontSize: content.fontSizeForTitle,
      },
      description: {
        color: content.textColorForDescription,
        fontSize: content.fontSizeforDescription,
      },
    }

    return (
      <div
        className={`zc-text ${this.props.align === 'right' ? 'tr' : ''}`.trim()}
      >
        {content.title && <h2 style={style.title}>{content.title}</h2>}
        {content.subtitle && <h3>{content.subtitle}</h3>}
        {(content.details || content.text) && (
          <p style={style.description}>{content.details || content.text}</p>
        )}
      </div>
    )
  }
}
