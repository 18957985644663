import { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { clearSession } from '../../../lib/auth'

class Logout extends Component {
  constructor(props) {
    super(props)
    const { history, location = {} } = props
    clearSession()
    history.push('/login', location.state)
  }
  render() {
    return null
  }
}

export default withRouter(Logout)
