import React from 'react'
import BlankImg from '../../../pages/app-home/ManageTiles/blank.jpeg'

const Tiles = ({ type, items }) => {
  return (
    <div className={`grid-${type}-col`}>
      {items.map((item, index) => (
        <div className="tile" key={index}>
          <img
            src={item.image.url ? item.image.url : BlankImg}
            alt="Icon"
            height={50}
          />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  )
}

export default Tiles
