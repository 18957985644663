import React, { Fragment } from 'react'
import { getMessage } from '../../../lib/translator'
import { Select, Searchable } from 'components/Form'
import moment from 'moment'

const UserSegment = ({ _this, method }) => {
  const values = Object.assign({}, _this.state.values)
  const { type } = values.config.segment

  return (
    <Fragment>
      <div className="header-2">
        {getMessage('rewardPage.userSegment-header')}
      </div>
      <div className="mt-1 mb-2">
        <Select
          name="segment-selector"
          testid="segment-selector"
          placeholder={getMessage('Select Segment')}
          options={_this.state.segmentOptions}
          {..._this.generateStateMappers({
            stateKeys: ['config', 'segment', 'type'],
            loseEmphasisOnFill: true,
          })}
          disabled={
            method === 'edit' && moment().isAfter(_this.state.values.startAt)
          }
        />
        {type === 'CUSTOMERS_MAPPED_TO_TAG' && (
          <Searchable
            label={getMessage('product.filters.tag.heading')}
            placeholder={getMessage('product.filters.tag.placeholder')}
            searchUrl="/ef-customer-core/tags"
            valueKey="id"
            nameKey="name"
            searchKey="name"
            className="mt-1"
            multiple={true}
            transformResponse={(response) => response.data.tags}
            readOnly={
              method === 'edit' && moment().isAfter(_this.state.values.startAt)
            }
            {..._this.generateStateMappers({
              stateKeys: ['config', 'segment', 'data', 'tags'],
              loseEmphasisOnFill: true,
            })}
          />
        )}
        {type === 'CUSTOMERS_MAPPED_TO_SEGMENT' && (
          <Searchable
            label={getMessage('rewards.segment')}
            placeholder={getMessage('rewards.segment')}
            searchUrl="/segments"
            valueKey="id"
            nameKey="name"
            searchKey="name"
            className="mt-1"
            multiple={true}
            transformResponse={(response) => response.data}
            readOnly={
              method === 'edit' && moment().isAfter(_this.state.values.startAt)
            }
            {..._this.generateStateMappers({
              stateKeys: ['config', 'segment', 'data', 'segments'],
              loseEmphasisOnFill: true,
            })}
          />
        )}
      </div>
    </Fragment>
  )
}

export default UserSegment
