import React from 'react'
import './Button.css'

const Button = ({ color, filled, ...props }) => {
  return (
    <button
      type="button"
      data-filled={filled}
      data-color={color}
      custom-button=""
      {...props}
    />
  )
}

export default Button
