import React from 'react'
import { Input, Select } from 'components/Form'
import { getMessage } from 'lib/translator'
import { handleFocus } from './FormTabs/utils'

const CommonForm = ({ discountTypes, _this, hasOfferStarted, values }) => {
  return (
    <React.Fragment>
      <Select
        name="discount-type"
        required
        label={getMessage('offer.discounttype')}
        placeholder={getMessage('offer.discounttype.placeholder')}
        options={discountTypes}
        {..._this.generateStateMappers({
          stateKeys: ['rule', 'total', 't'],
          loseEmphasisOnFill: true,
        })}
        onChange={_this.handleDiscountChange}
        readOnly={hasOfferStarted}
      />
      <Input
        name="discount-value"
        type="number"
        onFocus={handleFocus}
        step={0.01}
        min={0}
        max={
          values &&
          values.rule &&
          values.rule.total &&
          values.rule.total.t === 'PERCENT_OFF'
            ? 100
            : Number.MAX_SAFE_INTEGER
        }
        required
        label={getMessage('offer.discountvalue')}
        placeholder={getMessage('offer.discountvalue.placeholder')}
        {..._this.generateStateMappers({
          stateKeys: ['rule', 'total', 'v'],
          loseEmphasisOnFill: true,
        })}
        readOnly={
          hasOfferStarted ||
          (values &&
            values.rule &&
            values.rule.total &&
            values.rule.total.t === 'FREE')
        }
      />
    </React.Fragment>
  )
}

export default CommonForm
