import React, { useContext } from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  Checkbox,
  Select,
} from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'
import { SplitContext } from '../../../../containers/SplitContext'
import SPLIT_FEATURES from '../../../../containers/SplitContext/features'

class StoresFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components

    const isStoreKindFilterEnabled =
      this.props.splits?.[SPLIT_FEATURES.STORE_KIND_FIELD]?.treatment === 'on'

    return (
      <Form className="stores-filter">
        <div className="form-fields">
          <Input
            label={getMessage('store.filters.name.heading')}
            placeholder={getMessage('store.filters.name.placeholder')}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
            }}
          />
          <Input
            label={getMessage('operations.store.filters.clientId.heading')}
            placeholder={getMessage(
              'operations.store.filters.clientId.placeholder'
            )}
            name="clientId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['clientId'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          {isStoreKindFilterEnabled && (
            <Select
              label={'Store kind'}
              placeholder={'Select kind'}
              className={'enum'}
              options={[
                {
                  text: 'Standard',
                  value: 'STANDARD',
                },
                {
                  text: 'Scan & Go',
                  value: 'SNG',
                },
                {
                  text: 'FFS',
                  value: 'FFS',
                },
                {
                  text: 'Search and Browse',
                  value: 'SEARCH_AND_BROWSE',
                },
              ]}
              {...this.generateStateMappers({
                stateKeys: ['type'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          )}
        </div>
        <div className="flex">
          <Checkbox
            className="delivey-hub"
            name="hasPicking"
            label={getMessage('operations.store.form.hasPicking')}
            controlled
            {...this.generateStateMappers({
              stateKeys: ['hasPicking'],
              loseEmphasisOnFill: true,
            })}
          />
          <Checkbox
            className="delivey-hub"
            name="hasDeliveryHub"
            label={getMessage('operations.store.form.hasDeliveryHub')}
            controlled
            {...this.generateStateMappers({
              stateKeys: ['hasDeliveryHub'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('store.filters.submitText')}</SubmitButton>
        <ClearButton>{getMessage('store.clear')}</ClearButton>
      </Form>
    )
  }
}

const StoresFiltersFormWrapper = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return <StoresFiltersForm splits={splits} {...props} />
}

export default StoresFiltersFormWrapper
