import React, { Fragment } from 'react'
import { getMessage } from 'lib/translator'
import { Dialog } from 'components/Popup'

const getInfo = (keys) => {
  return (
    <span className="missingInfoDialog">
      <>
        {keys.length > 1
          ? getMessage('apphome.missingInfo.2')
          : getMessage('apphome.missingInfo.1')}
      </>
      <span className="missingInfo">
        {keys.map((key, index) => (
          <span key={index}>{key}</span>
        ))}
      </span>
      <>{getMessage('apphome.missingInfo.3')}</>
    </span>
  )
}

const MissingInfoDialog = ({
  showMissingInfoDialog,
  closeMissingInfoDialog,
  missingKeys,
  onOkHandler,
}) => {
  const missingInfo = missingKeys.map((keys) =>
    getMessage(`apphome.missingInfo.${keys}`)
  )

  return (
    <Fragment>
      <Dialog
        show={showMissingInfoDialog}
        information={getInfo(missingInfo)}
        close={() => closeMissingInfoDialog(false)}
        closeText={getMessage('apphome.cancel')}
        onOk={() => onOkHandler()}
        okText={getMessage('apphome.saveasdraft')}
        className="appbanner-dialog"
      />
    </Fragment>
  )
}

export default MissingInfoDialog
