import React, { Fragment } from 'react'
import { Input, VALIDATION_TYPES } from '../../Form'
import { postalCodeRegex, postalCodeErrorMsg } from './utils'
import { getMessage } from 'lib/translator'

const ADDRESS_DETAILS = ['street', 'floor', 'unit', 'postalCode']

const AddressDetails = ({ _this }) => (
  <Fragment>
    <div className="row">
      <div className="col-12">
        <h3>{getMessage('rewards.address')}</h3>
      </div>
    </div>
    <div className="row">
      {ADDRESS_DETAILS.map((info, j) => (
        <div className={info === 'street' ? 'col-6' : 'col-3'} key={j}>
          <Input
            label={getMessage(`rewards.${info}`)}
            placeholder={getMessage(`rewards.${info}`)}
            type="text"
            pattern={info === 'postalCode' && postalCodeRegex}
            validationStrings={postalCodeErrorMsg}
            {...(['street', 'postalCode'].includes(info) && {
              required: 'required',
            })}
            {..._this.generateStateMappers({
              stateKeys: [`${info}`],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
      ))}
    </div>
  </Fragment>
)

export default AddressDetails
