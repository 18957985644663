import React, { Component } from 'react'
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'

import 'draft-js/dist/Draft.css'
import './style.css'

export default class RichEditor extends Component {
  constructor(props) {
    super(props)
    this.interceptProps(props)
    this.onChange = props.onChange || (() => {})
    this.handleKeyCommand = this.handleKeyCommand.bind(this)
    // Toolbar click handlers
    this.toggleInlineStyle = this.toggleInlineStyle.bind(this)
  }
  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.props.onChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }
  toggleInlineStyle(style) {
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.value, style))
  }
  interceptProps(props) {
    if (!(props.value instanceof EditorState)) {
      props.onChange(importText(props.value))
    }
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.interceptProps(newProps)
  }
  render() {
    const { props } = this
    if (!(props.value instanceof EditorState)) {
      return null
    }
    return (
      <div className="RichEditor">
        <div className="editor-actions">
          <button
            tabIndex="-1"
            type="button"
            className="editor-action"
            onClick={() => {
              this.toggleInlineStyle('BOLD')
            }}
          >
            <strong>B</strong>
          </button>
          <button
            tabIndex="-1"
            type="button"
            className="editor-action"
            onClick={() => {
              this.toggleInlineStyle('ITALIC')
            }}
          >
            <em>I</em>
          </button>
          <button
            tabIndex="-1"
            type="button"
            className="editor-action"
            onClick={() => {
              this.toggleInlineStyle('UNDERLINE')
            }}
          >
            <u>U</u>
          </button>
          <button
            tabIndex="-1"
            type="button"
            className="editor-action"
            onClick={() => {
              this.toggleInlineStyle('STRIKETHROUGH')
            }}
          >
            <strike>S</strike>
          </button>
        </div>
        <Editor
          placeholder={props.placeholder}
          editorState={props.value}
          onChange={this.onChange}
          handleKeyCommand={this.handleKeyCommand}
          customStyleMap={{
            STRIKETHROUGH: {
              textDecoration: 'line-through',
            },
          }}
        />
      </div>
    )
  }
}

export function importHTML(value) {
  return stateFromHTML(value)
}

export function exportHTML(editorState) {
  return stateToHTML(editorState.getCurrentContent())
}

export function importText(value) {
  if (!value) {
    return EditorState.createEmpty()
  } else if (typeof value === 'string') {
    return EditorState.createWithContent(ContentState.createFromText(value))
  }
  return value
}

export function exportText(editorState) {
  // assuming editorState instanceof EditorState
  return editorState.getCurrentContent().getPlainText()
}
