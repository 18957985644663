import React from 'react'
import {
  BaseForm,
  Select,
  MultiSelect,
  VALIDATION_TYPES,
  Input,
} from '../../../Form'
import './style.css'
import infoIcon from '../../../../components/Form/info-icon.svg'
import { validationMsgs, handleRuleChange } from '../../utils'
import { getMessage } from '../../../../lib/translator'
import SetRuleDuration from './SetRuleDuration'
import { CHANNEL_OPTIONS } from '../../../Rewards/Campaigns/utils'
import AddProduct from './AddProduct'
import HeaderWithDeleteButton from './HeaderWithDeleteButton'

export default class AddRules extends BaseForm {
  constructor(props) {
    super(props)

    this.state.values = {
      rulesList: [
        {
          chanceType: '',
          subType: '',
          productType: '',
          operator: '',
          value: '',
        },
      ],
    }

    this.chanceTypeOptions = [
      {
        text: 'Membership',
        value: 'Membership',
      },
      {
        text: 'Channel',
        value: 'Channel',
      },
      {
        text: 'Product',
        value: 'Product',
      },
      {
        text: 'Card network',
        value: 'Card_type',
      },
    ]

    this.subTypeOptions = {
      Membership: [
        { text: 'Link member', value: 'PLUS' },
        { text: 'Union member', value: 'UNION' },
        { text: 'Digital Club member', value: 'FDC' },
        { text: 'Just Wine Club member', value: 'JWC' },
      ],

      Channel: CHANNEL_OPTIONS,
      Card_type: [
        { text: 'Visa', value: 'VISA' },
        { text: 'Mastercard', value: 'MasterCard' },
        { text: 'Amex', value: 'AMEX' },
      ],
    }

    this.operatorTypeOptions = [
      {
        text: 'Multiply',
        value: 'MULTIPLY',
      },
      {
        text: 'Add',
        value: 'ADD',
      },
    ]

    this.operatorTypeOptions2 = [
      {
        text: 'Add',
        value: 'ADD',
      },
    ]

    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
  }

  handleAddClick() {
    const values = Object.assign({}, this.state.values)
    const data = {
      chanceType: '',
      subType: '',
      productType: '',
      operator: '',
      value: '',
    }
    const rulesList = values.rulesList
    rulesList.push(data)
    this.setState({
      values: values,
    })
  }

  handleRemoveClick(e, index) {
    const parent = this.props._this
    const rulesListValidation = parent.state.validations.rulesList
    const values = Object.assign({}, this.state.values)
    const rulesList = values.rulesList
    rulesList.splice(index, 1)
    rulesListValidation.splice(index, 1)
    this.setState({
      values,
    })
  }

  render() {
    const { _this } = this.props
    const { rulesList } = this.state.values
    Object.assign(this.state.values, _this.state.values)

    return (
      <div className="single-column-field chance-page">
        <div className="label-tooltip">
          <div className="ml-0 mr-1">
            <div className="optional-header">
              <b className="tab-header">
                {getMessage('luckydraw.bonusChance')}
              </b>
              <img className="ml-1 mb-0 icon-2" src={infoIcon} alt="note" />
            </div>
            <div className="label-tooltiptext tooltip-two">
              <div>
                <b>{getMessage('luckydraw.tooltip-note1')} </b>
                {getMessage('luckydraw.bonusChance-1')}
              </div>
              <br />
              <div>
                <b>{getMessage('luckydraw.tooltip-note2')} </b>
                {getMessage('luckydraw.bonusChance-2')}
              </div>
            </div>
          </div>
        </div>
        {rulesList.map((x, i) => (
          <div className="single-column-field" key={i}>
            <div className="rule-popup">
              <HeaderWithDeleteButton
                type="rule"
                index={i}
                handleRemoveClick={this.handleRemoveClick}
                campaignStarted={this.state.campaignStarted}
                getMessage={getMessage}
              />
              <div className="rule-details">
                <Select
                  testid={`rule-type-${i + 1}`}
                  name="chance-type"
                  key="chance-type"
                  label={getMessage('luckydraw.rules-type')}
                  placeholder={getMessage('luckydraw.rules-selecttype')}
                  options={this.chanceTypeOptions}
                  {..._this.generateStateMappers({
                    stateKeys: ['rulesList', i, 'chanceType'],
                    loseEmphasisOnFill: true,
                  })}
                  onBlur={(e) => handleRuleChange(x, e)}
                  disabled={_this.state.campaignStarted}
                />
                {x.chanceType !== 'Product' && (
                  <MultiSelect
                    testid={`rule-subtype-${i + 1}`}
                    name="chance-subtype"
                    key="chance-subtype"
                    label={getMessage('luckydraw.rules-subtype')}
                    placeholder={getMessage('luckydraw.rules-selectsubtype')}
                    options={
                      x.chanceType ? this.subTypeOptions[x.chanceType] : []
                    }
                    {..._this.generateStateMappers({
                      stateKeys: ['rulesList', i, 'subType'],
                      loseEmphasisOnFill: true,
                    })}
                    readOnly={!x.chanceType || _this.state.campaignStarted}
                    {...(x.chanceType && {
                      required: 'required',
                    })}
                  />
                )}
                {x.chanceType === 'Product' && (
                  <AddProduct
                    _this={_this}
                    ruleIndex={i}
                    chanceType={x.chanceType}
                  />
                )}
                <Select
                  testid={`rule-operator-${i + 1}`}
                  name="chance-operator"
                  key="chance-operator"
                  label={getMessage('luckydraw.mechanics-operator')}
                  placeholder={getMessage(
                    'luckydraw.mechanics-select-operator'
                  )}
                  options={
                    x.chanceType === 'Product'
                      ? this.operatorTypeOptions2
                      : this.operatorTypeOptions
                  }
                  {..._this.generateStateMappers({
                    stateKeys: ['rulesList', i, 'operator'],
                    loseEmphasisOnFill: true,
                  })}
                  readOnly={!x.chanceType || _this.state.campaignStarted}
                  {...(x.chanceType && {
                    required: 'required',
                  })}
                />
                <Input
                  testid={`rule-value-${i + 1}`}
                  label={getMessage('luckydraw.rules-value')}
                  value={x.value}
                  type="number"
                  step="0.01"
                  min={0.01}
                  max={1000}
                  {..._this.generateStateMappers({
                    stateKeys: ['rulesList', i, 'value'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  validationStrings={validationMsgs}
                  readOnly={_this.state.campaignStarted}
                  {...(x.chanceType && {
                    required: 'required',
                  })}
                />
              </div>
              {x.chanceType === 'Product' && (
                <SetRuleDuration _this={_this} i={i} />
              )}
            </div>
          </div>
        ))}
        <button
          data-testid="add-rule-btn"
          className="button add-content-block-lucky"
          type="button"
          onClick={this.handleAddClick}
          disabled={_this.state.campaignStarted}
        >
          {getMessage('luckydraw.add-rule')}
        </button>
      </div>
    )
  }
}
