import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ENVIRONMENT } from 'config/app'
import API from '../../lib/api'
import { getMessage } from '../../lib/translator'
import {
  formatCampaignDate,
  getFormattedDate,
  getFormattedTime,
} from 'components/Rewards/utils'

export const CHALLENGE_TYPE = ['FPG', 'CDG', 'PTR']

export const RULE_KEYS = {
  SINGLE_ORDER: ['singleOrder', 'minSpend'],
  MULTIPLE_ORDER: ['multipleOrder', 'minSpend'],
  CUMULATIVE_SPEND: ['cumulativeSpend', 'cumulativeAmount'],
  CDG_MONTHLY_SPEND: ['cdgMonthlySpend', 'targetSpend'],
}

export const CARD_TYPES = ['VISA', 'VISAPLUS', 'MasterCard', 'AMEX']

export const linkButtons = () => {
  return (
    <div className="header-wrap">
      <Link to="/rewards/challenges/add">
        <button className="primary button">
          <span className="text">
            {getMessage('omnichallenge.addChallenge')}
          </span>
        </button>
      </Link>
    </div>
  )
}

export const addTask = (parent) => {
  const task = {
    userAction: '',
    type: 'TRANSACTION',
    awardList: [],
  }
  const values = Object.assign({}, parent.state.values)
  values.taskList?.push(task)
  parent.setState({
    values,
  })
}

export const deleteTask = (parent, e, taskIndex) => {
  const values = Object.assign({}, parent.state.values)
  const validations = Object.assign({}, parent.state.validations)
  values.deletedTaskList = []
  values.deletedTaskList.push(values.taskList[taskIndex])
  values.taskList.splice(taskIndex, 1)
  validations.taskList.splice(taskIndex, 1)
  parent.setState({
    values,
    validations,
  })
}

export const formatTaskList = (tasks) =>
  tasks.map((task) => {
    const {
      id,
      challengeId,
      award = {},
      title,
      userAction,
      awardCap,
      eligibleProductsDeeplink,
      awardList = [],
      ptrTagIds = [],
      ptrSegments = [],
    } = task

    const formattedAward = {
      type: 'LINKPOINT',
      data: {
        amount: award?.data?.amount || 0,
      },
      otherAwards: formatAwardList(awardList, ptrTagIds, ptrSegments),
    }
    return {
      id,
      challengeId,
      title,
      type: userAction,
      awardCap: awardCap || null,
      ...(eligibleProductsDeeplink && { eligibleProductsDeeplink }),
      award: formattedAward,
      rule: task.rule ? formatTaskRule(task) : {},
    }
  })

export const addReward = (parent, taskIndex) => {
  const award = { type: 'OFFER' }
  const values = Object.assign({}, parent.state.values)

  values.taskList[taskIndex]?.awardList?.push(award)

  parent.setState({
    values,
  })
}

export const deleteReward = (parent, taskIndex, awardIndex) => {
  const values = Object.assign({}, parent.state.values)
  values.taskList[taskIndex]?.awardList.splice(awardIndex, 1)

  parent.setState({
    values,
  })
}

export const validateSpendRelatedTaskChannels = (taskList) => {
  if (!taskList) {
    return
  }

  const spendRelatedChannels = new Set([
    'LINK_CARD_CHEERS',
    'LINKPAY_LOYALTY_CHEERS',
    'LINK_CARD_FAIRPRICE',
    'LINKPAY_LOYALTY_FAIRPRICE',
    'LINK_CARD_UNITY',
    'LINKPAY_LOYALTY_UNITY',
  ])

  taskList.forEach((task, taskIndex) => {
    const channels =
      task?.rule?.[RULE_KEYS?.[task.userAction]?.[0]]?.channels || []
    const isSpendRelatedChannelSelected = channels.some((channel) =>
      spendRelatedChannels.has(channel)
    )

    const { userAction = '', rule = '', cardTypes = '' } = task
    const ruleKey = RULE_KEYS[userAction]?.[0]
    const {
      cardBins = '',
      categoryIds = [],
      clientStoreIds = [],
      productBarcodes = [],
    } = rule[ruleKey] || {}

    const hasCardTypes = cardTypes && Object.values(cardTypes).some(Boolean)
    const hasCardBins = Array.isArray(cardBins)
      ? cardBins.length > 0
      : cardBins !== '' && cardBins !== '[object Object]'
    const hasOtherRestrictions = [
      categoryIds,
      clientStoreIds,
      productBarcodes,
    ].some((arr) => arr?.length > 0)

    if (
      isSpendRelatedChannelSelected &&
      (hasCardBins || hasCardTypes || hasOtherRestrictions)
    ) {
      throw new Error(
        `Task ${taskIndex + 1} - ${getMessage(
          'rewards.SpendRelatedChannelSelected.error'
        )}`
      )
    }
  })
}

export const validateAwardList = (taskList) => {
  if (!taskList) {
    return
  }

  taskList.forEach((task, taskIndex) => {
    validateTaskAwards(task.awardList, taskIndex)
  })
}

const validateTaskAwards = (awardList, taskIndex) => {
  const offerIds = new Set()
  const ptrOfferIds = new Set()
  const ptrRcIds = new Set()

  const validationFunctions = {
    OFFER: validateOfferId,
    PTR_OFFER: validatePtrOfferId,
    PTR_RC: validatePtrRcId,
  }

  const getSetForAwardType = (type) => {
    switch (type) {
      case 'OFFER':
        return offerIds
      case 'PTR_OFFER':
        return ptrOfferIds
      case 'PTR_RC':
        return ptrRcIds
      default:
        return null
    }
  }

  awardList?.forEach((award, rewardIndex) => {
    const validateFn = validationFunctions[award.type] || validateAward
    validateFn(award, taskIndex, rewardIndex, getSetForAwardType(award.type))
  })
}

const validateAward = (award, taskIndex, rewardIndex) => {
  if (!award) {
    return
  }

  const {
    type,
    title = '',
    description = '',
    limitation = '',
    startDate = '',
    startTime = '',
    endDate = '',
    endTime = '',
  } = award

  if (type === 'PTR_BENEFIT') {
    const { startAt, endAt } = formatCampaignDate(
      startDate,
      startTime,
      endDate,
      endTime
    )
    const ptrStartAt = new Date(startAt)
    const ptrEndAt = new Date(endAt)
    if (!title) {
      throwValidationError(taskIndex, rewardIndex, 'title cannot be empty')
    }
    if (!limitation) {
      throwValidationError(
        taskIndex,
        rewardIndex,
        'redemption limitation cannot be empty'
      )
    }
    if (!startDate) {
      throwValidationError(taskIndex, rewardIndex, 'start date cannot be empty')
    }
    if (!startTime) {
      throwValidationError(taskIndex, rewardIndex, 'start time cannot be empty')
    }
    if (!endDate) {
      throwValidationError(taskIndex, rewardIndex, 'end date cannot be empty')
    }
    if (!endTime) {
      throwValidationError(taskIndex, rewardIndex, 'end time cannot be empty')
    }
    if (ptrEndAt < ptrStartAt) {
      throwValidationError(
        taskIndex,
        rewardIndex,
        'end date should be greater than start date'
      )
    }
  }
  if (!description) {
    throwValidationError(taskIndex, rewardIndex, 'description cannot be empty')
  }
}

export const validateOfferId = (award, taskIndex, rewardIndex, offerIds) => {
  const { offerID, tagId, tagIds = [] } = award
  const parsedOfferId = parseInt(offerID)

  if (!offerID || !tagId || !tagIds.length) {
    throwValidationError(
      taskIndex,
      rewardIndex,
      !offerID ? 'Offer ID cannot be empty' : 'Tag ID cannot be empty'
    )
  }

  if (offerIds.has(parsedOfferId)) {
    throwValidationError(
      taskIndex,
      rewardIndex,
      `Offer ID ${offerID} already exists`
    )
  }

  offerIds.add(parsedOfferId)
}

export const validatePtrOfferId = (
  award,
  taskIndex,
  rewardIndex,
  ptrOfferIds
) => {
  const { offerID, startAt, endAt } = award
  const parsedOfferId = parseInt(offerID)

  if (!offerID) {
    throwValidationError(taskIndex, rewardIndex, 'Offer ID cannot be empty')
  }

  if (!startAt && !endAt) {
    throwValidationError(
      taskIndex,
      rewardIndex,
      'Invalid Offer ID. Please verify by searching.'
    )
  }

  if (ptrOfferIds.has(parsedOfferId)) {
    throwValidationError(
      taskIndex,
      rewardIndex,
      `Offer ID ${offerID} already exists`
    )
  }

  ptrOfferIds.add(parsedOfferId)
}

export const validatePtrRcId = (award, taskIndex, rewardIndex, ptrRcIds) => {
  const { rcID, startAt, endAt } = award
  const parsedRcId = parseInt(rcID)

  if (!rcID) {
    throwValidationError(
      taskIndex,
      rewardIndex,
      'Rewards Catalogue ID cannot be empty'
    )
  }

  if (!startAt && !endAt) {
    throwValidationError(
      taskIndex,
      rewardIndex,
      'Invalid Rewards Catalogue ID. Please verify by searching.'
    )
  }

  if (ptrRcIds.has(parsedRcId)) {
    throwValidationError(
      taskIndex,
      rewardIndex,
      `Rewards Catalogue ID ${rcID} already exists`
    )
  }

  ptrRcIds.add(parsedRcId)
}

const throwValidationError = (taskIndex, rewardIndex, message) => {
  throw new Error(
    `Task ${taskIndex + 1} - Reward ${rewardIndex + 2}: ${message}`
  )
}

const getProductBarcodes = (userAction, rule) => {
  const productInfo = rule[RULE_KEYS[userAction][0]].productBarcodes
  const productBarcodes = []

  if (productInfo?.length > 0) {
    productInfo.forEach((product) => {
      if (product.barcodes) {
        productBarcodes.push(...product.barcodes)
      }
      if (product.clientItemId) {
        productBarcodes.push(product.clientItemId)
      }
    })
  }
  return productBarcodes
}

const getCategoryIds = (userAction, rule) => {
  const categoryInfo = rule[RULE_KEYS[userAction][0]].categoryIds
  return categoryInfo?.length > 0
    ? categoryInfo.map((category) => category.id)
    : []
}

export const formatTaskRule = (task) => {
  const { userAction, rule } = task
  const [ruleKey] = RULE_KEYS[userAction]
  let { cardBins = '' } = rule[ruleKey]

  if (typeof cardBins === 'string') {
    cardBins =
      cardBins === '[object Object]' || cardBins === ''
        ? []
        : cardBins.split(',').map((item) => item.trim())
  } else if (Array.isArray(cardBins) && cardBins.length === 0) {
    cardBins = []
  }

  rule[ruleKey].cardBins = cardBins

  if (task.cardTypes) {
    const { cardTypes } = task
    const list = Object.keys(cardTypes).filter((card) => cardTypes[card])
    rule[RULE_KEYS[userAction][0]].cardTypes = list.length === 3 ? [] : list
  } else {
    rule[RULE_KEYS[userAction][0]].cardTypes = []
  }

  if (
    ['SINGLE_ORDER', 'CUMULATIVE_SPEND', 'MULTIPLE_ORDER'].includes(
      task.userAction
    )
  ) {
    rule[RULE_KEYS[userAction][0]].productBarcodes = getProductBarcodes(
      userAction,
      rule
    )
    rule[RULE_KEYS[userAction][0]].categoryIds = getCategoryIds(
      userAction,
      rule
    )
  }

  return rule
}

export const fetchCards = (task) => {
  const { type, rule } = task
  const list = rule[RULE_KEYS[type][0]].cardTypes
  const res = {}
  list.forEach((key) => (res[key] = true))
  return res
}

const mapCategoryIds = async (task) => {
  const categoryIdsArray = task.rule[RULE_KEYS[task.type][0]].categoryIds
  if (categoryIdsArray?.length > 0) {
    try {
      let searchCategoryParams = ''

      categoryIdsArray.map((id, index) => {
        if (index === 0) {
          searchCategoryParams += `${Number(id)}`
        } else {
          searchCategoryParams += `&id=${Number(id)}`
        }
      })
      const categoryAPI = new API({
        url: `/catalogue-service/category?id=${searchCategoryParams}`,
      })
      const response = await categoryAPI.get()
      task.rule[RULE_KEYS[task.type][0]].categoryIds = response.data.category
      return task
    } catch (e) {
      throw Error(e)
    }
  }

  return null
}

const mapProductBarcodes = async (task) => {
  const productBarcodesArray =
    task.rule[RULE_KEYS[task.type][0]].productBarcodes
  if (productBarcodesArray?.length > 0) {
    try {
      const productBarcodesString = productBarcodesArray.toString()

      const api = new API({
        url: `/catalogue-service/product?barcode=${productBarcodesString}`,
      })
      const response = await api.get()
      task.rule[RULE_KEYS[task.type][0]].productBarcodes = response.data.product
      return task
    } catch (e) {
      throw Error(e)
    }
  }

  return null
}

export const fetchTasks = async (tasks) => {
  return Promise.all(
    tasks.map(async (task) => {
      const rule = task.type !== 'COMPLETE_CHALLENGE' ? task.rule : undefined
      const cardTypes = !['COMPLETE_CHALLENGE', 'CDG_MONTHLY_SPEND'].includes(
        task.type
      )
        ? fetchCards(task)
        : undefined
      const award = task.award.type !== '' ? task.award : undefined

      if (rule && task.type !== 'CDG_MONTHLY_SPEND') {
        mapCategoryIds(task)
        mapProductBarcodes(task)
        task.rule[RULE_KEYS[task.type][0]].cardBins =
          task.rule[RULE_KEYS[task.type][0]]?.cardBins?.length > 0 &&
          Array.isArray(task.rule[RULE_KEYS[task.type][0]].cardBins)
            ? task.rule[RULE_KEYS[task.type][0]].cardBins.join()
            : ''
      }

      // Await the fetching of awards
      const otherAwards = task.award?.otherAwards || []
      const awardList = (await fetchAwards(otherAwards)) || []
      const extractCustomerTags = (customerTags, key) =>
        customerTags.flatMap((customerTag) => customerTag.ptr?.[key] ?? [])

      const ptrTagIds = extractCustomerTags(otherAwards, 'tagIds')
      const ptrSegments = extractCustomerTags(otherAwards, 'segments')

      return {
        id: task.id,
        challengeId: task.challengeId,
        userAction: task.type,
        type: 'TRANSACTION',
        title: task.title,
        awardCap: task.awardCap,
        eligibleProductsDeeplink: task.eligibleProductsDeeplink,
        ...(cardTypes !== undefined && { cardTypes }),
        ...(rule !== undefined && { rule }),
        ...(award !== undefined && { award }),
        awardList,
        ptrTagIds,
        ptrSegments,
      }
    })
  )
}

export const fetchAwards = async (otherAwards) => {
  if (!Array.isArray(otherAwards)) {
    return []
  }

  const promises = otherAwards.map(async (item) => {
    try {
      if (item.offer) {
        const offerAPIInstance = new API({
          url: `/offer-service/offer/${item.offer.id}`,
        })
        const response = await offerAPIInstance.get()
        const { description } = response.data.offers

        const tagsAPIInstance = new API({
          url: `/ef-customer-core/tags/${item.offer.tagId}`,
        })
        const tagsResponses = await tagsAPIInstance.get()
        const tagsOptions = [
          {
            value: tagsResponses.data.id,
            text: tagsResponses.data.name,
          },
        ]

        return description && tagsOptions
          ? {
              type: item.offer.type,
              offerID: item.offer.id,
              offerDetails: description,
              tagIds: tagsOptions,
              tagId: tagsOptions.length > 0 ? item.offer.tagId : '',
            }
          : null
      }
      if (item.cdg && Array.isArray(item.cdg.awards)) {
        return item.cdg.awards.map(({ type, description }) => ({
          type,
          description,
        }))
      }
      if (item.ptr && Array.isArray(item.ptr.awards)) {
        return await Promise.all(
          item.ptr.awards.map(async (award) => {
            const { type, id } = award

            if (type === 'PTR_OFFER') {
              const {
                offerDetails,
                promoCodeDescription,
                limitation,
                startAt,
                endAt,
              } = (await getTagDetails(id, type)) || {}

              return {
                type,
                offerID: id,
                offerDetails,
                promoCodeDescription,
                limitation,
                startAt,
                endAt,
              }
            }

            if (type === 'PTR_RC') {
              const { promoCodeDescription, limitation, startAt, endAt } =
                (await getRcDetails(id)) || {}

              return {
                type,
                rcID: id,
                promoCodeDescription,
                limitation,
                startAt,
                endAt,
              }
            }

            return {
              type,
              title: award.title,
              description: award.description,
              limitation: award.limitation,
              startDate: getFormattedDate(award.startAt),
              startTime: getFormattedTime(award.startAt),
              endDate: getFormattedDate(award.endAt),
              endTime: getFormattedTime(award.endAt),
            }
          })
        )
      }
    } catch (error) {
      console.error('Error fetching award details:', error)
    }
    return null
  })

  const results = (await Promise.allSettled(promises)).flatMap(
    ({ value }) => value ?? []
  )
  return results.filter(Boolean)
}

export const formatAwardList = (awardList, ptrTagIds, ptrSegments) => {
  if (
    awardList.length === 0 &&
    (ptrTagIds.length > 0 || ptrSegments.length > 0)
  ) {
    return [
      {
        ptr: {
          tagIds: ptrTagIds,
          segments: ptrSegments,
          awards: null,
        },
      },
    ]
  }

  return awardList.reduce((acc, award) => {
    switch (award.type) {
      case 'OFFER': {
        const offerItem = {
          offer: {
            type: award.type,
            id: Number(award.offerID),
            tagId: Number(award.tagId),
          },
        }
        acc.push(offerItem)
        break
      }
      case 'ZIGPOINT':
      case 'CDG_VOUCHER': {
        const cdgObject = acc.find((item) => item.cdg)
        const cdgAward = {
          type: award.type,
          description: award.description,
        }
        if (cdgObject) {
          cdgObject.cdg.awards.push(cdgAward)
        } else {
          acc.push({
            cdg: {
              awards: [cdgAward],
            },
          })
        }
        break
      }
      case 'PTR_OFFER':
      case 'PTR_RC':
      case 'PTR_BENEFIT': {
        const ptrObject = acc.find((item) => item.ptr)
        let ptrAward = ''
        if (award.type === 'PTR_BENEFIT') {
          const { startAt, endAt } = formatCampaignDate(
            award.startDate,
            award.startTime,
            award.endDate,
            award.endTime
          )
          ptrAward = {
            type: award.type,
            title: award.title,
            description: award.description,
            limitation: award.limitation,
            startAt,
            endAt,
          }
        }
        if (award.type === 'PTR_OFFER') {
          ptrAward = {
            type: award.type,
            id: Number(award.offerID),
          }
        }
        if (award.type === 'PTR_RC') {
          ptrAward = {
            type: award.type,
            id: Number(award.rcID),
          }
        }

        if (ptrObject) {
          ptrObject.ptr.awards.push(ptrAward)
        } else {
          acc.push({
            ptr: {
              tagIds: ptrTagIds,
              segments: ptrSegments,
              awards: [ptrAward],
            },
          })
        }
        break
      }
      default:
        break
    }
    return acc
  }, [])
}

export const DEFAULT_CONFIG = {
  segment: {
    type: '',
    data: {},
  },
  isSequential: true,
}

const CUSTOMERS_MAPPED_TO_SEGMENT = 'CUSTOMERS_MAPPED_TO_SEGMENT'

export const formatConfig = (config) => {
  const { type } = config.segment || {}
  const { segments = [], tags = [] } =
    type === CUSTOMERS_MAPPED_TO_SEGMENT
      ? { segments: config.segment.data.segments }
      : { tags: config.segment.data.tags }

  return tags.length > 0
    ? {
        ...config,
        segment: { type, data: { tags: tags.map((tag) => tag.id) } },
      }
    : segments.length > 0
      ? {
          ...config,
          segment: {
            type,
            data: { segments: segments.map((segment) => segment.id) },
          },
        }
      : DEFAULT_CONFIG
}

export const fetchConfig = async (config) => {
  const { type } = config.segment || {}
  const { segments = [], tags = [] } =
    type === CUSTOMERS_MAPPED_TO_SEGMENT
      ? { segments: config.segment.data.segments }
      : { tags: config.segment.data.tags }

  const fetchAndFilter = async (url, items) => {
    const api = new API({ url })
    const response = await api.get({ limit: 10000 })
    const data =
      type === CUSTOMERS_MAPPED_TO_SEGMENT
        ? response.data ?? []
        : response.data.tags ?? []

    return items
      .map((selectedItem) =>
        data.find((item) => item.id === Number(selectedItem))
      )
      .filter(Boolean)
  }

  if (tags?.length > 0) {
    const filteredTags = await fetchAndFilter('/ef-customer-core/tags', tags)
    config = { ...config, segment: { type, data: { tags: filteredTags } } }
  }

  if (segments?.length > 0) {
    const filteredSegments = await fetchAndFilter('/segments', segments)
    config = {
      ...config,
      segment: { type, data: { segments: filteredSegments } },
    }
  }

  return config
}

export const transformAPIData = async (data) => {
  const TIME_FORMAT = 'HH:mm:ss'
  const DATE_FORMAT = 'YYYY-MM-DD'

  const startAt = moment(data.startAt)
  const endAt = moment(data.endAt)

  const config =
    data.config.segment.type === ''
      ? data.config
      : await fetchConfig(data.config)

  const taskList = data.tasks ? await fetchTasks(data.tasks) : []

  const getPtrIds = (tasks, key) => {
    if (!tasks || !Array.isArray(tasks)) {
      return []
    }

    return tasks.reduce((ids, task) => {
      const ptrAwards = task.award?.otherAwards?.filter((award) => award?.ptr)
      if (ptrAwards) {
        const ptrIds = ptrAwards
          .flatMap((award) => award.ptr[key])
          .filter((id) => id !== null)
        ids.push(...ptrIds)
      }
      return ids
    }, [])
  }

  const allPtrTagIds = getPtrIds(data.tasks, 'tagIds')
  const tagsPromises = allPtrTagIds.length
    ? allPtrTagIds.map((tagId) =>
        new API({ url: `/ef-customer-core/tags/${tagId}` }).get()
      )
    : []

  const allPtrSegmentIds = getPtrIds(data.tasks, 'segments')
  const ptrTags = await Promise.all(tagsPromises)
  const customerTags = ptrTags.length > 0 ? ptrTags : allPtrSegmentIds

  return {
    ...data,
    startDate: startAt.format(DATE_FORMAT),
    startTime: startAt.format(TIME_FORMAT),
    endDate: endAt.format(DATE_FORMAT),
    endTime: endAt.format(TIME_FORMAT),
    taskList,
    config,
    customerTags,
  }
}

export const fetchStoresOption = async (_this) => {
  try {
    const storesApi = new API({
      url: `/dp-middleware-connector/stores?groupId=${STORES_GROUP_ID}`,
    })
    const response = await storesApi.get()
    const storesOptions = formatStoresOptions(response.data.stores) || []
    _this.setState({
      storesOptions,
    })
  } catch (error) {
    _this.setState({
      showErrorDialog: true,
      errorMessage: error.message,
    })
  }
}

export const STORES_GROUP_ID =
  ENVIRONMENT === 'UAT'
    ? 'ce663980-b01f-4396-94e5-8cd43d47061c'
    : 'f7f1a835-5a67-452f-8d6b-b76c4b1359f5'

const sortStoreOptions = (storeOptions) => {
  return storeOptions
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      return 1
    })
    .sort((a, b) => {
      if (a.storeType < b.storeType) {
        return -1
      }
      return 1
    })
}

const formatStoresOptions = (storeOptions) => {
  const storeOptionsArray = sortStoreOptions(storeOptions)
  return storeOptionsArray.map((element) => {
    let labelText = `${element.clientStoreId} / ${element.name}`
    if (element.storeType === 'fairprice') {
      labelText = `${element.clientStoreId} / FairPrice - ${element.name}`
    }
    return {
      value: element.clientStoreId,
      text: labelText,
    }
  })
}

const NOT_FOUND_ERROR_CODES = [400, 404]

export const getTagDetails = async (offerID, type) => {
  let offerDetails = ''
  let tagsOptions = []
  let offerError = ''
  let promoCodeDescription = ''
  let limitation = ''
  let startAt = ''
  let endAt = ''

  try {
    const offerAPI = new API({ url: `/offer-service/offer/${offerID}` })
    const response = await offerAPI.get()

    if (response) {
      const {
        description,
        userSet,
        promoCodeDetail,
        customerRedemptionLimit,
        offerValidFrom,
        offerValidTill,
        segmentationIds = [],
      } = response.data.offers

      offerDetails = description
      promoCodeDescription =
        `${promoCodeDetail.title || ''} ${promoCodeDetail.subTitle || ''}`.trim()
      limitation = customerRedemptionLimit
      startAt = `${getFormattedDate(offerValidFrom)} ${getFormattedTime(offerValidFrom)}`
      endAt = `${getFormattedDate(offerValidTill)} ${getFormattedTime(offerValidTill)}`

      const searchString = getTagId(userSet, type, segmentationIds)
      if (!searchString) {
        throw new Error(
          `Please add an offer ${offerID} that is restricted to customer tag segments.`
        )
      }

      const tagIds =
        typeof searchString === 'string'
          ? searchString.split(',').map((id) => id.trim())
          : []

      tagsOptions = await fetchTags(tagIds)
    }
  } catch (error) {
    offerError = handleErrors(error, offerID)
    offerDetails = ''
  }

  return {
    offerError,
    offerDetails,
    tagsOptions,
    promoCodeDescription,
    limitation,
    startAt,
    endAt,
  }
}

const getTagId = (userSet, type, segmentationIds) => {
  const tagId = userSet?.data?.find(
    (segment) => segment.id === 'CustomersMappedToTag'
  )?.tagId
  return type === 'PTR_OFFER' && !tagId && segmentationIds.length > 0
    ? true
    : tagId
}

const fetchTags = async (tagIds) => {
  const tagsPromises = tagIds.map((tagId) => {
    const tagsAPIInstance = new API({ url: `/ef-customer-core/tags/${tagId}` })
    return tagsAPIInstance.get()
  })

  const tagsResponses = await Promise.all(tagsPromises)
  return tagsResponses.map((res) => ({
    value: res?.data.id,
    text: res?.data.name,
  }))
}

const handleErrors = (error, offerID) => {
  if (NOT_FOUND_ERROR_CODES.includes(error.code)) {
    return `This offer ID ${offerID} cannot be found.`
  }
  return error.message
}

export const getRcDetails = async (rcID) => {
  let rcError = ''
  let promoCodeDescription = ''
  let limitation = ''
  let rcStartAt = ''
  let rcEndAt = ''

  try {
    const rcAPI = new API({
      url: `/rms/v2/rewards/${rcID}`,
    })

    const response = await rcAPI.get()

    if (response) {
      const { title, subtitle, startAt, endAt, redemptionCap } = response
      const { capPerUser = 0 } = redemptionCap
      promoCodeDescription = `${title ? title : ''} ${subtitle ? subtitle : ''}`
      limitation = capPerUser
      rcStartAt = startAt
      rcEndAt = endAt
    }
  } catch (error) {
    rcError = error.message
    if (NOT_FOUND_ERROR_CODES.includes(error.code)) {
      rcError = `This catalogue id ${rcID} cannot be found.`
    }
  }
  return {
    rcError,
    promoCodeDescription,
    limitation,
    startAt: `${getFormattedDate(rcStartAt)} ${getFormattedTime(rcStartAt)}`,
    endAt: `${getFormattedDate(rcEndAt)} ${getFormattedTime(rcEndAt)}`,
  }
}

export const transformFilter = (formData) => {
  const { duration, ...data } = formData
  if (duration) {
    const { startDate, endDate } = duration
    const startTime = '00:00:00'
    const endTime = '23:59:59'
    const { startAt, endAt } = formatCampaignDate(
      startDate,
      startTime,
      endDate,
      endTime
    )
    return { ...data, startAt, endAt }
  }
  return data
}

export { formatCampaignDate }

export const calculatePtrModification = (values, ptrModification) => {
  const endAt = moment(values.endAt)
  const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')
  const daysUntilEnd = endAt.diff(currentDate, 'days')

  if (daysUntilEnd <= values.creationLeadDays && values.type === 'PTR') {
    ptrModification = {
      error: true,
      message: getMessage('omnichallenge.rollingType.errorMessage'),
    }
  }

  return ptrModification
}
