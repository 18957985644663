import { Popup } from 'components/Popup'
import React, { useContext, useEffect, useRef } from 'react'
import { Button, Text, View } from '../ui'
import { useFormikContext } from 'formik'
import {
  createClearCanvasHandler,
  createConsentConfirmHandler,
  renderBorderColor,
} from '../helper'
import { SplitContext } from 'containers/SplitContext'
import features from 'containers/SplitContext/features'
import ReactSignatureCanvas from 'react-signature-canvas'
import { useToggle } from '../hooks'

export const ConsentPopup = ({
  isOpen,
  toggleModal,
  onConfirm,
  isLoading,
  error,
  setError,
}) => {
  const { values } = useFormikContext()
  const splitConfig = useContext(SplitContext)
  const [isFocus, , isFocusHandlerCreator] = useToggle()
  const [isCanvasEmpty, , isCanvasEmptyHandlerCreator] = useToggle(true)

  const isEsignatureEnabled =
    splitConfig.splits?.[features.PREORDER_ESIGNATURE]?.treatment === 'on'

  const canvasRef = useRef()

  useEffect(() => {
    if (isEsignatureEnabled && canvasRef.current && error) {
      createClearCanvasHandler(canvasRef, isCanvasEmptyHandlerCreator(true))()
    }
  }, [error])

  useEffect(() => {
    if (isEsignatureEnabled && canvasRef.current && !isCanvasEmpty) {
      setError(false)
    }
  }, [isCanvasEmpty])

  return (
    <Popup
      show={isOpen}
      close={toggleModal}
      heading="Confirm contact details"
      className="confirmPreorderPopup"
    >
      <View direction="column" alignItems="start">
        <Text fontWeight={700}>{values.customerName}</Text>
        <Text fontWeight={700} margin="0 0 16px">
          {values.customerPhone}
        </Text>
        {isEsignatureEnabled ? (
          <>
            <Text textAlign="left" fontSize={14}>
              By using these services, you consent to our use of your data for
              purchasing and delivery.
            </Text>
            <Text textAlign="left" margin="16px 0 0 0" fontSize={14}>
              Please sign in the space below to confirm your consent.
            </Text>
          </>
        ) : (
          <Text textAlign="left">
            By using these services, you agree and consent that all personal
            data collected in this form may be used to facilitate your purchase
            of our goods, or shared with a third party delivery service provider
            for purposes of fulfilling your delivery request.
          </Text>
        )}
      </View>
      {isEsignatureEnabled && (
        <View
          alignItems=""
          justifyContent=""
          direction="column"
          border={renderBorderColor(error, isFocus)}
          padding={0}
          margin="0px 0px 8px 0"
        >
          <ReactSignatureCanvas
            canvasProps={{
              className: 'esignature',
            }}
            ref={canvasRef}
            onBegin={isFocusHandlerCreator(true)}
            onEnd={isCanvasEmptyHandlerCreator(false)}
            penColor="black"
            backgroundColor="white"
          />
        </View>
      )}
      {isEsignatureEnabled && error && (
        <Text fontSize={12} color="#ea353a">
          An error occured while processing the signature.
          <br />
          Please try again.
        </Text>
      )}
      <View justifyContent="end" gap="12px">
        <Button
          onFocus={isFocusHandlerCreator(false)}
          onClick={
            isEsignatureEnabled
              ? createClearCanvasHandler(
                  canvasRef,
                  isCanvasEmptyHandlerCreator(true)
                )
              : toggleModal
          }
          variant="secondary"
          width="30%"
          fontSize={12}
          disabled={isLoading || (isCanvasEmpty && isEsignatureEnabled)}
        >
          {isEsignatureEnabled ? 'Clear' : 'Cancel'}
        </Button>

        <Button
          onFocus={isFocusHandlerCreator(false)}
          onClick={createConsentConfirmHandler(
            canvasRef,
            onConfirm,
            isEsignatureEnabled
          )}
          width="30%"
          fontSize={12}
          loading={isLoading}
          disabled={isCanvasEmpty && isEsignatureEnabled}
        >
          Confirm
        </Button>
      </View>
    </Popup>
  )
}
