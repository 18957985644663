import React from 'react'
import { browseProductHeight } from '../constant'
import { Button, FixedButton, RightArrowIcon, Text, View } from '../ui'
import { AddProduct } from './AddProduct'
import { Select } from 'components/Form'
import { useField, useFormikContext } from 'formik'
import isEmpty from 'lodash.isempty'
import Pagination from '../../../../components/Pagination'

/* listing products per page */
export const BrowseProduct = ({
  selectedCategory,
  toReviewOrder,
  productsPerPage,
  productError,
  productLoading,
  fetchProducts,
  onInputProductQuantity,
}) => {
  const { setFieldValue, values } = useFormikContext()

  const changeProductPage = (page) => {
    fetchProducts({ categoryId: selectedCategory, page })
  }

  const onConfirm = () => {
    // update local cart object
    setFieldValue('cart', {
      ...values.cart,
      ...Object.keys(values.products).reduce((total, value) => {
        if (values.cart[value] === undefined) {
          total[value] = true
        }
        return total
      }, {}),
    })

    toReviewOrder()
  }

  if (productLoading) {
    return <>Loading products...</>
  }

  if (productError) {
    return (
      <>
        <Text color="red">Unable to fetch products:</Text>
        <Text color="red">{productError}</Text>
      </>
    )
  }

  return (
    <>
      <View direction="column" alignItems="start" gap={10}>
        <Text fontWeight={700} fontSize={20}>
          {values.allCategories.find((c) => c.id === selectedCategory)?.name}
        </Text>
        <View
          display="block"
          style={{ height: `calc(${browseProductHeight})` }}
        >
          <View
            direction="column"
            gap={20}
            justifyContent="space-around"
            margin="0 0 12px"
          >
            {productsPerPage.length === 0 ? (
              <>No products</>
            ) : (
              productsPerPage.map((product, i) => (
                <AddProduct
                  onChange={onInputProductQuantity(product.sku)}
                  quantity={values.products[product.sku] || 0}
                  item={product}
                  key={i}
                />
              ))
            )}
          </View>
        </View>
      </View>
      <Pagination
        total={values.totalPages}
        current={values.currentPage}
        onSelect={changeProductPage}
      />
      <FixedButton
        data-testid="toReviewOrder"
        onClick={onConfirm}
        disabled={isEmpty(values.products)}
      >
        Review order
      </FixedButton>
    </>
  )
}

const CategoryList = ({
  selectedCampaign,
  setSelectedCategory,
  toReviewOrder,
  fetchProducts,
  categoryError,
  categoryLoading,
  categories,
}) => {
  const [productsField] = useField('products')

  const onCategoryClick = (categoryId) => () => {
    fetchProducts({ categoryId, page: 1 })
    // This will toggle the current view to show list of products
    setSelectedCategory(categoryId)
  }

  if (categoryLoading) {
    return <>Loading categories...</>
  }

  if (categoryError) {
    return (
      <>
        <Text color="red">Unable to fetch categories:</Text>
        <Text color="red">{categoryError}</Text>
      </>
    )
  }

  return (
    <>
      {selectedCampaign && categories.length > 0 && (
        <>
          <View
            display="block"
            height="calc(100vh - 300px)"
            data-testid="campaign-categories"
          >
            {categories.map((c, i) => (
              <View
                key={i}
                style={{ borderBottom: '1px solid #DADEE0' }}
                margin={i === categories.length - 1 ? '0 0 40px' : null}
                data-testid={`category-${c.id}`}
              >
                <Button
                  variant="text"
                  style={{
                    textAlign: 'left',
                    paddingLeft: 0,
                    margin: '20px 0px',
                  }}
                  onClick={onCategoryClick(c.id)}
                >
                  {c.name}
                </Button>
                <RightArrowIcon />
              </View>
            ))}
          </View>
          <FixedButton
            data-testid="toReviewOrder"
            disabled={isEmpty(productsField.value)}
            onClick={toReviewOrder}
          >
            Review order
          </FixedButton>
        </>
      )}
    </>
  )
}

/* listing pages per campaign */
export const BrowseCategory = ({
  selectedCampaign,
  onCampaignChange,
  setSelectedCategory,
  toReviewOrder,
  fetchProducts,
  campaignLoading,
  campaignError,
  campaigns,
  categoryError,
  categoryLoading,
  categories,
}) => {
  if (campaignLoading) {
    return <>Loading campaigns...</>
  }

  if (campaignError) {
    return (
      <>
        <Text color="red">Unable to fetch campaigns:</Text>
        <Text color="red">{campaignError}</Text>
      </>
    )
  }

  return (
    <>
      <View direction="column" alignItems="start">
        <Text fontSize={20} fontWeight={700} variant="div">
          New pre-order
        </Text>
        <Text fontWeight={700} margin="24px 0 16px" variant="div">
          Browse and add items for pick-up
        </Text>
      </View>
      <Select
        testid="campaign"
        placeholder="Select campaign"
        label="CAMPAIGN"
        value={selectedCampaign}
        onChange={onCampaignChange}
        options={campaigns}
      />
      <CategoryList
        selectedCampaign={selectedCampaign}
        setSelectedCategory={setSelectedCategory}
        toReviewOrder={toReviewOrder}
        fetchProducts={fetchProducts}
        categoryError={categoryError}
        categoryLoading={categoryLoading}
        categories={categories}
      />
    </>
  )
}
