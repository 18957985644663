import React from 'react'
import { BaseForm, Input } from 'components/Form'

import { getMessage } from 'lib/translator'

export default class Filters extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('eVoucher.filter.entityName')}
            placeholder={getMessage('eVoucher.filter.entityName')}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('order.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('order.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
