import React, { Component } from 'react'
import { Upload } from '../../../Form'
import { getMessage } from '../../../../lib/translator'

export default class ImageDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      values: '',
    }
  }

  render() {
    const { _this } = this.props

    return (
      <div className="campaign-assets">
        <Upload
          name="campaign-thumbnail"
          testid="campaign-thumbnail"
          placeholder={getMessage('luckydraw.uploadThumbNail.placeholder')}
          key="image"
          required
          label={getMessage('luckydraw.uploadThumbNail.label')}
          {..._this.generateStateMappers({
            stateKeys: ['imageUrl'],
            loseEmphasisOnFill: true,
          })}
        />
        <Upload
          name="campaign-banner"
          testid="campaign-banner"
          placeholder={getMessage('luckydraw.uploadBanner.placeholder')}
          key="banner"
          required
          label={getMessage('luckydraw.uploadBanner.label')}
          {..._this.generateStateMappers({
            stateKeys: ['bannerUrl'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }
}
