import React, { Fragment } from 'react'
import { TextDetails } from '../utils'
import WebDescription from './WebDescription'

const WebInfo = ({ _this, webInfo }) => {
  const { webDescription } = { ...webInfo }
  const WEB_INFORMATIONS = [
    ['description'],
    ['webDescription'],
    ['tnc'],
    ['metaTitle', 'metaDescription'],
  ]
  return (
    <Fragment>
      {WEB_INFORMATIONS.map((informations, i) => {
        return (
          <div className="row" key={i}>
            {informations.map((info, j) => {
              {
                return info === 'webDescription' ? (
                  <div className="col-12 webDescription" key={j}>
                    <WebDescription
                      _this={_this}
                      webDescription={webDescription}
                    />
                  </div>
                ) : (
                  <TextDetails _this={_this} key={j} text={info} />
                )
              }
            })}
          </div>
        )
      })}
    </Fragment>
  )
}

export default WebInfo
