import React from 'react'
import { View, Text } from '.'

const badgeStyle = {
  success: {
    background: 'rgba(43, 130, 81, 1)',
  },
  danger: {
    background: 'rgba(193, 11, 58, 1)',
  },
  warning: {
    background: 'rgba(219, 88, 0, 1)',
  },
  secondary: {
    background: 'rgba(102, 102, 102, 1)',
  },
  info: {
    background: 'rgba(20, 84, 184, 1)',
  },
}
export const Badge = ({ children, variant, ...rest }) => {
  return (
    <View
      borderRadius="9999px"
      padding="4px 8px"
      width="fit-content"
      {...badgeStyle[variant]}
      {...rest}
    >
      <Text fontSize={12} color="white" fontWeight={700}>
        {children}
      </Text>
    </View>
  )
}
