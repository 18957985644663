import React, { useEffect } from 'react'
import API from 'lib/api'
import chunk from 'lodash/chunk'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { getMessage } from '../../../../lib/translator'
import Loader from 'components/Loader'

export default function BulkSearchOverride({ results = [], onBack, endpoint }) {
  const [resultsd, setResultsd] = React.useState(results)
  const [headers, setHeaders] = React.useState(
    results.length > 0 ? results[0] : []
  )
  const [loading, setLoading] = React.useState(false)
  const [updates, setUpdates] = React.useState([])
  const [failedUpdates, setFailedUpdates] = React.useState([])
  const [showUploadStatus, setShowUploadStatus] = React.useState(false)
  const BATCH_SIZE = 1000

  useEffect(() => {
    const removeFirstRow = results.slice(1)
    const data = removeFirstRow.map(row => {
      return row
        .map((item, index) => {
          const enums = ['type', 'value', 'from', 'to', 'skuID', 'position']
          const obj = {}
          obj[enums[index]] = item || ''
          return obj
        })
        .reduce((val, acc) => {
          return {
            ...val,
            ...acc,
          }
        }, {})
    })
    setResultsd(data)
  }, [])

  const createVoucherDeposits = rows => {
    setLoading(true)
    const searchOverrideAPI = new API({
      url: endpoint,
    })

    const payload = rows.map(item => ({
      organizationId: '2',
      type: item.type.toUpperCase(),
      value: item.value,
      from: item.from,
      to: item.to,
      skuID: Number(item.skuID),
      position: item.position,
    }))

    const chunkedPayload = chunk(payload, BATCH_SIZE)

    chunkedPayload.forEach(chunkPayload => {
      searchOverrideAPI
        .post({ terms: chunkPayload })
        .then(response => {
          if (response.data.failed.length) {
            const heads = Object.keys(response.data.failed[0]).map(item => item)
            setHeaders(heads)
          }
          setLoading(false)
          setUpdates(response.data.succeed)
          setFailedUpdates(response.data.failed)
          setShowUploadStatus(true)
        })
        .finally(() => setLoading(false))
    })
  }

  const onUpload = () => {
    createVoucherDeposits(resultsd)
  }

  return (
    <>
     {loading && <Loader />}
      {!showUploadStatus && resultsd.length
        ? Boolean(resultsd?.length) && (
            <div className="table-container table-responsive">
              <Table tableDynamic={false}>
                <Header items={headers} />
                {resultsd.map((row, index) => {
                  return (
                    <Row key={`${row[index]}-${index}`}>
                      {Object.keys(row).map((val, rowIndex) => {
                        return (
                          <Cell key={rowIndex}>
                            <span>{row[val]}</span>
                          </Cell>
                        )
                      })}
                    </Row>
                  )
                })}
              </Table>
            </div>
          )
        : ''}
      {(showUploadStatus && updates.length) || failedUpdates.length ? (
        <>
          <div className="message-box">
            <div className="update-message">Search Overrides Completed!</div>
            <div className="update-message">
              Number of successful updates:{' '}
              <span className="count">{updates.length}</span>
            </div>
            <div className="update-message">
              Number of failed updates:{' '}
              <span className="count">{failedUpdates.length}</span>
            </div>
          </div>
          {failedUpdates.length > 0 && (
            <div className="invalid-message">
              Below is the list of failed updates
            </div>
          )}
          <div className="table-container">
            {failedUpdates.length > 0 && (
              <Table tableDynamic={false}>
                <Header items={headers} />
                {failedUpdates.filter(Boolean).map((row, rowIndex) => (
                  <Row className={`row`} key={rowIndex}>
                    <Cell>
                      <span>{row.error.Message}</span>
                    </Cell>
                    <Cell>
                      <span>{row.from}</span>
                    </Cell>
                    <Cell>
                      <span>{row.organizationID}</span>
                    </Cell>
                    <Cell>
                      <span>{row.skuID}</span>
                    </Cell>
                    <Cell>
                      <span>{row.to}</span>
                    </Cell>
                    <Cell>
                      <span>{row.type}</span>
                    </Cell>
                    <Cell>
                      <span>{row.value}</span>
                    </Cell>
                  </Row>
                ))}
              </Table>
            )}
          </div>
        </>
      ) : (
        ''
      )}
      <div className="actions">
        <button className="button" onClick={onBack}>
          {showUploadStatus
            ? getMessage('category.form.goBackText')
            : getMessage('category.form.cancelText')}
        </button>
        {!showUploadStatus && (
          <button
            className="primary button"
            onClick={onUpload}
            data-testid="search-override-upload-action"
          >
            {getMessage('category.form.upload')}
          </button>
        )}
      </div>
    </>
  )
}
