import React, { Component } from 'react'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { Popup } from '../../../../components/Popup'
import { getMessage } from '../../../../lib/translator'
import './style.css'

export default class SelectedPackagePopup extends Component {
  render() {
    const { toggleInventoryPopup, handlePackageSelect, inventoryData } = this.props

    return (
      <Popup
        show={inventoryData.inventoryDialog}
        className="selectedPackage-box"
        heading={getMessage('media.packageManagement.inventory.popup.title')}
        close={() => { toggleInventoryPopup(2) }}
      >
        <div>
          <SelectedPackageTable inventoryData={inventoryData} handlePackageSelect={handlePackageSelect}></SelectedPackageTable>
          <div className='btns'>
            <button
              type="button"
              className="button"
              onClick={() => {
                handlePackageSelect()
              }}
            >{getMessage('media.action.clearall')}</button>
          </div>
        </div>
      </Popup>
    )
  }
}

const SelectedPackageTable = (props) => {
  return (
    <div className='SelectedPackage-wrapper'>
      <p className='tip'>Total selected: {props.inventoryData.selectedPackages.size}</p>
      <div className="SelectedPackage-table">
        <Table>
          <Header>
            <Cell>{getMessage('media.packageManagement.header.name')}</Cell>
            <Cell>{getMessage('media.packageManagement.header.ownerBu')}</Cell>
            <Cell>{getMessage('media.packageManagement.header.actions')}</Cell>
          </Header>
          {Array.from(props.inventoryData.selectedPackages).map((item, index) => (
            <Row key={index}>
              <Cell>
                {(item[1].packageName)}
              </Cell>
              <Cell>
                {(item[1].BU)}
              </Cell>
              <Cell>
                <div data-testid="delete-btn" className='btn delete' alt="delete" onClick={()=>{ props.handlePackageSelect([item[1]]) }}></div>
              </Cell>
            </Row>
          ))}
        </Table>
      </div>
    </div>
  )
}
