import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { reorderSubMenuItems } from './orderedSubMenuItems'
import { hiddenSubmenu } from './requiredPermissions'
import { RootDataContext } from './utils'
import lodashGet from 'lodash.get'
import MissingPage from 'pages/others/MissingPage'
import { isEnterprise } from 'lib/auth'

export const PrivateRoute = ({ component: Component, isEnterpriseRoute, ...rest }) => {
  const { displayedMenu, isLoggedIn, redirectToLogin, refresh } =
    useContext(RootDataContext)

  if (isEnterpriseRoute && !isEnterprise()) {
    return <Route {...rest} render={() => <MissingPage />} />
  }

  const menuPath = rest.path.split('/')[1]
  const submenuPath = rest.path.split('/')[2]
  const hasAccessToMenu = Boolean(displayedMenu[menuPath])
  const hasAccessToSubMenu = Boolean(displayedMenu[menuPath]?.some(
    (v) => v === submenuPath
  ))

  if (!hasAccessToMenu || !hasAccessToSubMenu) {
    return <Route {...rest} render={() => <MissingPage />} />
  }

  const filteredMenu = Object.keys(displayedMenu).reduce((result, menu) => {
    result[menu] = displayedMenu[menu].filter((submenu) =>
      !lodashGet(hiddenSubmenu, menu, []).includes(submenu)
    )
    return result
  }, {})

  return (
    !refresh && (
      <Route
        {...rest}
        render={(routeProps) =>
          !redirectToLogin ? (
            <Component
              {...routeProps}
              menu={reorderSubMenuItems(filteredMenu)}
            />
          ) : (
            <Redirect
              to={{
                pathname: isLoggedIn ? '/user/logout' : '/login',
                state: { from: routeProps.location },
              }}
            />
          )
        }
      />
    )
  )
}
