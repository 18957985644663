import React, { useEffect, useState } from 'react';
import { getMessage } from 'lib/translator';
import FileProcessor from 'components/FileUpload/FileProcessor';
import Toast from 'components/Toast';
import UploadIcon from 'icons/trip-upload.svg';
import kmlToGeoJson from '@mapbox/togeojson';
import { Input } from 'components/Form';
import { distinctColors } from '../constants';
import { getLatLongFromPolygon } from '../helpers';
import CommonMapComponent from 'pages/settings/DeliveryArea/CommonMapComponent';
import './style.scss';

export function KmlFileInputStep({
  setZonePayload,
  hasMultipleZone,
  setHasMultipleZone,
  setClusterName,
  clusterName,
  selectedRuleForEdit
}) {
  const [kmlZoneData, setKmlZoneData] = useState(null);
  const [hasError, setHasError] = useState(null);
  const [fileName, setFileName] = useState('');
  const [showToast, setShowToast] = useState(null);

  useEffect(() => {
    if (selectedRuleForEdit) {
      setKmlZoneData([
        {
          area: {
            locations: getLatLongFromPolygon(selectedRuleForEdit.zone.polygon)
          },
          configType: 'POLYGON',
          name: selectedRuleForEdit.zone.name,
          id: selectedRuleForEdit.zone.id
        }
      ])
      // use zone name as file name during edit mode because file name is not stored in db
      setFileName(selectedRuleForEdit.zone.name)
      setClusterName(selectedRuleForEdit.zone.name)
    }
  }, [selectedRuleForEdit])

  function transformZoneToLatLong(zones, selectedFileName) {
    return zones.map((zone, index) => {
      const area = zone.geometry.coordinates[0].map(x => ({ longitude: x[0], latitude: x[1] }));
      return {
        area: { locations: area },
        configType: 'POLYGON',
        name: zone?.properties?.name || (selectedFileName + index),
        id: index + 1
      }
    })
  }

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = () => {
      const xmlDoc = new DOMParser().parseFromString(reader.result, 'application/xml');
      const geoJson = kmlToGeoJson.kml(xmlDoc);
      const selectedFileName = file.name.substring(0, file.name.lastIndexOf('.'));
      if (geoJson?.features.length > 1) {
        const zonesDataFromKml = transformZoneToLatLong(geoJson.features, selectedFileName);
        setKmlZoneData(zonesDataFromKml);
        setHasMultipleZone(true);
        setFileName(selectedFileName);
        setHasError({ text: getMessage('operations.timeSlotBlocking.kmlInput.multipleZoneError')});
        const zoneNames = geoJson.features.map(zone => zone.properties.name).join(', ');
        setClusterName(zoneNames);
      } else if (geoJson?.features.length === 1) {
        const zone = geoJson.features[0];
        const latLongData = zone.geometry.coordinates[0].map(x => ({ longitude: x[0], latitude: x[1] }));
        const zoneData = latLongData.map(x => `${x.longitude} ${x.latitude}`).join(', ');
        const zoneName = zone?.properties?.name || selectedFileName
        const zoneDataFromKml = [{
          area: { locations: latLongData },
          configType: 'POLYGON',
          name: zoneName,
          id: 1
        }]

        setZonePayload({
          name: zoneName,
          polygon: zoneData
        });
        setKmlZoneData(zoneDataFromKml);
        setClusterName(zoneName);
        setFileName(selectedFileName);
        setHasMultipleZone(false);
        setHasError(null);
      } else {
        setShowToast({ text: getMessage('operations.timeSlotBlocking.kmlInput.invalidFile') });
        setClusterName('');
      }
    };

    reader.readAsText(file);
  };

  const removeSelectedFile = () => {
    setFileName('');
    setZonePayload(null);
    setKmlZoneData(null);
  }

  return (
    <div className="kml-input-step">
      {
        showToast &&
        <Toast
          content={showToast.text}
          timer={3000}
          toastColor="#333333"
          style={{
            position: 'fixed',
            fontSize: '14px',
            top: '1.5rem',
            width: '30rem',
            left: '40rem'
          }}
          onClose={() => setShowToast(null)}
        />
      }
      <p>{getMessage('operations.timeSlotBlocking.addRule.kmlInput.label')}</p>
      {
        kmlZoneData ?
        <div>
          {(kmlZoneData && fileName) &&
          <div>
            <Input
              type="text"
              name="uploadedKmlFileName"
              value={`${fileName}.kml`}
              secondaryAction={{
                name: 'Remove',
                onAction: () => removeSelectedFile()
              }}
              disabled
            />
            { hasError?.text && <div className='error-text'>{hasError.text}</div> }
            <div className='delivery-area-content'>
              <div style={{fontSize: '14px', alignSelf: 'flex-start', margin: '0.5rem 0'}}>
                <b>{getMessage('operations.timeSlotBlocking.addRule.zoneName')}:</b>
                <span style={hasMultipleZone ? {color: '#C10B3A'} : {}}>&nbsp;{clusterName || '-'}</span>
              </div>
              <div className='map-area'>
                <CommonMapComponent deliveryArea={kmlZoneData} distinctColors={distinctColors} />
              </div>
            </div>
          </div>
          }
        </div> :
        <div className="file-upload-wrapper">
          <div className="text-center">
            <img src={UploadIcon} width="140" alt="" />
            <FileProcessor
              name="kmlFileUploaderAddRule"
              fileType=".kml"
              dataTestId="kml-file-input"
              title={getMessage('operations.timeSlotBlocking.addRule.dragAndDropKml')}
              secondaryTitle={getMessage('operations.timeSlotBlocking.addRule.kmlOr')}
              buttonText={getMessage('operations.timeSlotBlocking.addRule.chooseFileFromCom')}
              onFileUpload={handleFileUpload} />
          </div>
        </div>
      }
    </div>
  );
}

