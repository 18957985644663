import React from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  DateRangePicker,
  Select,
} from '../../../components/Form'
import { getMessage } from '../../../lib/translator'
import { BUList } from '../MediaAssetManagement/businessUnitList'

//Media Asset Filters Form
export default class Filters extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values.status = ''
    this.state = {}
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components

    return (
      <Form className="package-filter-form">
        <div className="form-fields">
          <div className="section-row">
            <div className="section-row-input">
              <Input
                label={getMessage('media.packageManagement.filters.name')}
                placeholder={getMessage(
                  'media.packageManagement.filters.name.placeholder'
                )}
                name="packageName"
                className="section-row-input"
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ['packageName'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONBLUR,
                })}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                }}
              />
              <Select
                className="section-row-input"
                label={getMessage('media.campaigndetail.business.ownerBu')}
                name="bu"
                placeholder={getMessage(
                  'media.campaigndetail.business.ownerBu.placeholder'
                )}
                options={BUList}
                {...this.generateStateMappers({
                  stateKeys: ['bu'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>
          <div className="section-row">
            <div className="section-row-input">
              <DateRangePicker
                className="section-row-input-auto"
                label={getMessage('media.packageManagement.filters.date')}
                {...this.generateStateMappers({
                  stateKeys: ['duration'],
                  loseEmphasisOnFill: true,
                })}
                displayFormat="YYYY-MM-DD"
                isOutsideRange={() => {
                  return false
                }}
                startDatePlaceholder={getMessage(
                  'media.spotallocation.searchform.time.startDate.placeholder'
                )}
                endDatePlaceholder={getMessage(
                  'media.spotallocation.searchform.time.endDate.placeholder'
                )}
              />
            </div>
          </div>
        </div>
        <SubmitButton>{getMessage('brand.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('brand.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
