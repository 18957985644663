import React, { Component } from 'react'
import { Input } from '../../../../components/Form'
import { Actions } from '../form'
import { getMessage } from '../../../../lib/translator'
import ExotelIcon from './exotel.png'

class Exotel extends Component {
  render() {
    const { parent, type, provider, allowKey } = this.props
    const _this = parent
    const readOnly = _this.getState([allowKey]) === false
    return (
      <React.Fragment>
        <Actions parent={_this} icon={ExotelIcon} allowKey={allowKey} />
        <div className="form-fields">
          <Input
            label={getMessage('communication.exotel.sid')}
            placeholder={getMessage('communication.exotel.sid.placeholder')}
            name="exotel-sid"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'exotelSid'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            type="password"
            label={getMessage('communication.token')}
            placeholder={getMessage('communication.token.placeholder')}
            name="auth-token"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'token'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default Exotel
