import React from 'react'
import { BaseForm, Input } from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import { filterList, multiSelectFilters } from '../Filters'

export default class OrderSearchForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    const filters = JSON.parse(JSON.stringify(this.props.filters))
    delete filters.offset
    delete filters.page

    multiSelectFilters.forEach(filter => {
      if (
        Array.isArray(filters[filter]) &&
        (filters[filter].length === 0 || filters[filter][0] === '')
      ) {
        delete filters[filter]
      }
    })

    const appliedFilters = Object.keys(filters).filter(filterEntity => {
      return filterList.includes(filterEntity) && filters[filterEntity]
    })
    return (
      <Form>
        <div className="search-fields">
          <Input
            label={getMessage('qc.order.filters.search.heading')}
            placeholder={getMessage('qc.order.filters.search.placeholder')}
            className="search-position"
            name="id"
            data-testid="qc-search-filter"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['orderReference'],
              loseEmphasisOnFill: true,
            })}
          />
          <div className="search_n_filter">
            <SubmitButton>
              {getMessage('qc.order.filters.submitText')}
            </SubmitButton>
            <ClearButton>
              {getMessage('qc.order.filters.clearFiltersText')}
            </ClearButton>
            <button
              className={`${
                this.props.isFilterVisible ? 'selected' : 'filter-button'
              }`}
              style={{ marginLeft: '0.625rem' }}
              onClick={e => {
                this.props.filterToggle()
                e.preventDefault()
              }}
            >
              {getMessage('qc.order.filters.filterText')} (
              {appliedFilters.length || 0})
            </button>
          </div>
        </div>
      </Form>
    )
  }
}
