import React from 'react'
import { BaseForm, Select, Radio, Input } from '../../../../../components/Form'

import { getMessage } from '../../../../../lib/translator'

import './style.css'

const slotTimes = () => {
  const timeStrings = []
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      timeStrings.push(`0${i}:00`)
      timeStrings.push(`0${i}:30`)
    } else {
      timeStrings.push(`${i}:00`)
      timeStrings.push(`${i}:30`)
    }
  }
  return timeStrings
}

const slotTypes = [
  {
    text: 'ASAP',
    value: 'ASAP',
  },
  {
    text: 'Time',
    value: 'STANDARD',
  },
]

class SlotForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      formError: '',
      values: {
        type: 'STANDARD',
      },
    }
  }

  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    return (
      <div className="slots-form">
        <Form>
          <div className="form-field">
            <Radio
              name="slot-type"
              {...this.generateStateMappers({
                stateKeys: ['type'],
                loseEmphasisOnFill: true,
              })}
              options={slotTypes}
            />
          </div>
          {this.state.values.type === 'STANDARD' && (
            <div>
              <div className="form-field">
                <Select
                  name="slot-start"
                  label={getMessage('slots.startTime')}
                  placeholder={getMessage('slots.startsAt')}
                  options={slotTimes()}
                  {...this.generateStateMappers({
                    stateKeys: ['startTime'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
              <div className="form-field">
                <Select
                  name="slot-end"
                  label={getMessage('slots.endTime')}
                  placeholder={getMessage('slots.endsAt')}
                  options={slotTimes()}
                  {...this.generateStateMappers({
                    stateKeys: ['endTime'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            </div>
          )}
          {this.state.values.type === 'ASAP' && (
            <div className="form-field">
              <Input
                label={getMessage('slots.asap')}
                name="asap-time"
                placeholder={getMessage('slots.asap.placeholder')}
                {...this.generateStateMappers({
                  stateKeys: ['asap'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          )}
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <div className="form-actions">
            <SubmitButton disabled={this.state.formError}>
              {getMessage('slots.save')}
            </SubmitButton>
          </div>
        </Form>
      </div>
    )
  }
}

export default SlotForm
