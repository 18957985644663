import React, { Fragment } from 'react'
import { Textarea, Upload, Input, VALIDATION_TYPES, Select } from '../../Form'
import CampaignDuration from './CampaignDuration'
import infoIcon from 'components/Form/info-icon.svg'

import { getMessage } from '../../../lib/translator'
import Loader from '../../Loader'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'
import { CHALLENGE_TYPE } from '../utils'

const Details = (props) => {
  const { _this, loading, method } = props
  const { type, customerTags } = _this.state?.values

  return !loading ? (
    <Fragment>
      <div className="grid normal-height-field">
        <Textarea
          type="text"
          required
          name="campaign-name"
          key="campaign-name"
          testid="campaign-name"
          label={getMessage('omnichallenge.campaignName.label')}
          placeholder={getMessage('omnichallenge.campaignName.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['name'],
            loseEmphasisOnFill: true,
          })}
        />
        <Select
          name="type"
          testid="type"
          label={getMessage(`rewards.type`)}
          placeholder={getMessage(`rewards.type`)}
          options={CHALLENGE_TYPE}
          value={type}
          {..._this.generateStateMappers({
            stateKeys: [`type`],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
          required
        />
      </div>
      <CampaignDuration _this={_this} type="start" method={method} />
      <CampaignDuration _this={_this} type="end" method={method} />
      <div className="grid rewards-page">
        <div className="field">
          <div className="label-tooltip">
            <div className="optional-header">
              {getMessage('omnichallenge.awardSummary')}
              <img className="ml-1 mb-0 icon" src={infoIcon} alt="note" />
            </div>
            <div className="label-tooltiptext">
              <div>
                <b>{getMessage('luckydraw.tooltip-note')} </b>
                {getMessage('luckydraw.awardSummary.note')}
              </div>
            </div>
          </div>

          <Input
            type="text"
            {...(type === 'CDG' && { required: 'required' })}
            name="awardSummary"
            testid="awardSummary"
            placeholder={getMessage('omnichallenge.awardSummary.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: ['awardSummary'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        {type === 'PTR' && customerTags.length > 0 && (
          <div className="field">
            <label>{getMessage('omnichallenge.customerTags')}</label>
            <div className="Searchable customer-tags">
              <div className="tag-container">
                {customerTags.map((customerTag) => {
                  const tagId = customerTag.data?.id || customerTag
                  const tagName = customerTag.data?.name || customerTag

                  return (
                    <div className="tag-value" key={tagId}>
                      <span className="tag-value-label">
                        <small>{tagName}</small>
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="grid mt-1">
        <Textarea
          type="text"
          required
          className="challenge-details grid-col-1-span-2 "
          name="challenge-details"
          key="challenge-details"
          testid="challenge-details"
          label={getMessage('omnichallenge.details')}
          placeholder={getMessage('omnichallenge.details.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['participationGuide'],
            loseEmphasisOnFill: true,
          })}
        />
        <Upload
          name="challenge-banner"
          label={getMessage('omnichallenge.uploadBanner.label')}
          placeholder={getMessage('omnichallenge.uploadBanner.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['imgUrls', 'banner'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
      <div className="grid">
        <Input
          label={getMessage('omnichallenge.tncUrl.label')}
          placeholder={getMessage('omnichallenge.tncUrl.placeholder')}
          type="text"
          {..._this.generateStateMappers({
            stateKeys: ['tncUrl'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        {type === 'PTR' && (
          <Textarea
            type="text"
            {...(type === 'PTR' && {
              required: 'required',
            })}
            className="grid-col-1-span-2"
            name="challenge-description"
            key="challenge-description"
            testid="challenge-description"
            label={getMessage('omnichallenge.description')}
            placeholder={getMessage('omnichallenge.description')}
            {..._this.generateStateMappers({
              stateKeys: ['description'],
              loseEmphasisOnFill: true,
            })}
          />
        )}
      </div>

      {_this.props.method === 'edit' && (
        <DisplayCreatorEditorInfo {..._this.state.values} />
      )}
    </Fragment>
  ) : (
    <Loader size="sm" />
  )
}
export default Details
