import React, { Component } from 'react'
import './style.css'

class InvoiceEvochers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      amount: props.amount,
    }
  }

  render() {
    return (
      <div className="invoice-summary" data-testid="InvoiceEvochers">
        <div className="flex-around total-amount">
          <div className="text-muted ">{'Total Amount'}:</div>
          <div>
            <span>$</span>
            {
              <span className="section-value">
                {Number(this.state.amount).toLocaleString()}
              </span>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default InvoiceEvochers
