import React, { Fragment } from 'react'
import { getMessage } from 'lib/translator'
import { Input, Checkbox } from '../../Form'

const RedemptionLimit = ({ _this, isCapTotal, isCapPerUser }) => (
  <Fragment>
    <div className="row mt">
      <div className="col-12">
        <h3>{getMessage('rewards.catalogue.RedemptionLimit')}</h3>
      </div>
    </div>
    <div className="row">
      <div className="col-6 redemption pr-2">
        <Checkbox
          inlineLabel={getMessage('rewards.catalogue.totalRedemptionCap')}
          name="isCapTotal"
          testid="isCapTotal"
          {..._this.generateStateMappers({
            stateKeys: ['isCapTotal'],
            loseEmphasisOnFill: true,
          })}
        />

        <Input
          placeholder={getMessage('rewards.catalogue.totalRedemptionCap')}
          type="number"
          required={isCapTotal}
          disabled={!isCapTotal}
          {..._this.generateStateMappers({
            stateKeys: ['capTotal'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    </div>
    <div className="row mt mb">
      <div className="col-6 redemption pr-2">
        <Checkbox
          inlineLabel={getMessage('rewards.catalogue.redemptionCapPerUser')}
          name="isCapPerUser"
          {..._this.generateStateMappers({
            stateKeys: ['isCapPerUser'],
            loseEmphasisOnFill: true,
          })}
        />

        <Input
          placeholder={getMessage('rewards.catalogue.redemptionCapPerUser')}
          type="number"
          required={isCapPerUser}
          disabled={!isCapPerUser}
          {..._this.generateStateMappers({
            stateKeys: ['capPerUser'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    </div>
  </Fragment>
)

export default RedemptionLimit
