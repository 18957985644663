import React, { Component } from 'react'
import Image from '../../../../components/Image'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { getMessage } from '../../../../lib/translator'
import AddIcon from './icons/add-icon.svg'
import SubtractIcon from './icons/subtract-icon.svg'
import DeleteIcon from './icons/delete-icon.svg'

function formatValue(value) {
  return Math.round(value * 100) / 100
}
function getMobileView() {
  return window.screen.width <= 480
}

class ProductRow extends Component {
  constructor(props) {
    super(props)
    ;['removeProduct', 'decreaseQuantity', 'increaseQuantity'].forEach((fn) => {
      this[fn] = this[fn].bind(this)
    })
    this.state = {
      isMobileView: getMobileView(),
    }
    this.mobileView = this.mobileView.bind(this)
  }

  increaseQuantity() {
    this.props.updateQuantity(this.props.product.id, 1)
  }

  decreaseQuantity() {
    this.props.updateQuantity(this.props.product.id, -1)
  }

  removeProduct() {
    this.props.removeProduct(this.props.product.id)
  }
  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.mobileView, false)
  }
  render() {
    const { product: item, currency } = this.props
    const { mrp: mrpProp, discount: discountProp } = item.storeSpecificData
    let mrp = mrpProp
    let discount = discountProp
    mrp = formatValue(mrp).toFixed(2)
    discount = formatValue(discount).toFixed(2)
    const quantity = formatValue(item.quantity).toFixed(0)
    const amount = ((mrp - discount) * quantity).toFixed(2)
    const { isMobileView } = this.state
    return (
      <Row key={item.id}>
        <Cell className="item-image">
          <Image
            bordered
            size="sm"
            src={
              item.product
                ? item.product.images && item.product.images.length
                  ? item.product.images[0]
                  : null
                : item.images && item.images.length
                  ? item.images[0]
                  : null
            }
          />
        </Cell>
        <Cell className="item-name">
          <small>
            {item.variant ? (
              item.product ? (
                item.product.brand ? (
                  <span>
                    {' '}
                    <div className="product-name">
                      {item.variant.fullName}
                    </div>{' '}
                    {item.product.brand.name}
                  </span>
                ) : (
                  item.variant.fullName
                )
              ) : (
                item.variant.fullName
              )
            ) : item.product ? (
              item.product.brand ? (
                <span>
                  {' '}
                  <div className="product-name"> {item.product.name} </div>
                  {item.product.brand.name}
                </span>
              ) : (
                item.product.name
              )
            ) : item.name ? (
              item.name
            ) : (
              getMessage(
                'order.details.itemsTable.itemsInOrder.unavailabelText'
              )
            )}
          </small>
        </Cell>
        <Cell className="item-quantity">
          <div className="flex">
            <div className="subtract-icon" onClick={this.decreaseQuantity}>
              <img src={SubtractIcon} alt="" />
            </div>
            <div className="quantity">
              <small className="text-muted">{quantity}</small>
            </div>
            <div className="add-icon" onClick={this.increaseQuantity}>
              <img src={AddIcon} alt="" />
            </div>
          </div>
        </Cell>
        <Cell className="item-mrp" align="right">
          {isMobileView ? (
            <div className="item-header">
              {getMessage('order.details.itemsTable.header.mrp')}
            </div>
          ) : (
            ''
          )}
          <small className="text-muted">{`${currency.symbol} ${mrp}`}</small>
        </Cell>
        <Cell className="item-discount" align="right">
          {isMobileView ? (
            <div className="item-header">
              {getMessage('order.details.itemsTable.header.discount')}
            </div>
          ) : (
            ''
          )}
          <small className="text-muted">
            {`${currency.symbol} ${discount}`}
          </small>
        </Cell>
        <Cell className="item-amount" align="right">
          {isMobileView ? (
            <div className="item-header">
              {getMessage('order.details.itemsTable.header.amount')}
            </div>
          ) : (
            ''
          )}
          <div className="remove-button-container">
            <small className="text-muted">
              <strong>{currency.symbol}</strong>
              <strong>{amount}</strong>
            </small>
            <div className="remove-button" onClick={this.removeProduct}>
              <img src={DeleteIcon} alt="" />
            </div>
          </div>
        </Cell>
      </Row>
    )
  }
}

export class ProductsTable extends Component {
  render() {
    const { productsList, updateQuantity, removeProduct, currency, store } =
      this.props
    const ProductRows = []
    for (const key in productsList) {
      const product = productsList[key]
      ProductRows.push(
        <ProductRow
          store={store}
          currency={currency}
          removeProduct={removeProduct}
          updateQuantity={updateQuantity}
          key={product.id}
          product={product}
        />
      )
    }
    return (
      <div className="products-list">
        {productsList && (
          <Table>
            <Header>
              <Cell>{getMessage('order.details.itemsTable.header.image')}</Cell>
              <Cell>{getMessage('order.details.itemsTable.header.name')}</Cell>
              <Cell className="item-quantity">
                {getMessage('order.details.itemsTable.header.quantity')}
              </Cell>
              <Cell align="right">
                {getMessage('order.details.itemsTable.header.mrp')}
              </Cell>
              <Cell align="right">
                {getMessage('order.details.itemsTable.header.discount')}
              </Cell>
              <Cell align="right">
                {getMessage('order.details.itemsTable.header.amount')}
              </Cell>
            </Header>
            {ProductRows}
          </Table>
        )}
      </div>
    )
  }
}
