import React, { Component } from 'react'
import partial from 'lodash/partial'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import chunk from 'lodash/chunk'

import Table, { Header, Row, Cell } from '../../../../components/Table'
import Loader from '../../../../components/Loader'
import API, { UNAUTHORISED_STATUS } from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import { JobConfig, buildProductNameMap } from '../BatchJobsConfig'

const getIndex = (headers, header) => {
  return headers
    .map(h => h.replace(/ /g, '').toLowerCase())
    .indexOf(header.toLowerCase())
}

const isMkpSku = sku => sku >= 90000000
const BATCHING_SIZE = 200

const getPrimarySkuCountMap = list => {
  return list.reduce((acc, sku) => {
    if (acc[sku]) {
      acc[sku] = acc[sku] + 1
    } else {
      acc[sku] = 1
    }
    return acc
  }, {})
}

const getTableData = (results, jobName, productMap) => {
  if (results.length === 0) {
    return []
  }
  const CSV_HEADERS = JobConfig[jobName].csvHeaders
  const fileHeaders = results[0]

  const getIndexPartial = partial(getIndex, fileHeaders)
  const primarySkuIndex = getIndexPartial(CSV_HEADERS.PRIMARY_SKU.header)
  const substituteSkuIndex = getIndexPartial(CSV_HEADERS.SUBSTITUTE_SKU.header)

  const rows = results.slice(1)

  try {
    return rows.map(row => {
      const primaryProduct = productMap[row[primarySkuIndex]] || {}
      const substituteProducts = row[substituteSkuIndex]
        .split(',')
        .map(sku => productMap[sku] || {})
      return {
        primarySku: row[primarySkuIndex],
        productName: primaryProduct.name,
        substituteSku: row[substituteSkuIndex] || '',
        substituteProductNames: substituteProducts.map(product => product.name),
      }
    })
  } catch (e) {
    console.error('Error while generating review data', e)
    return []
  }
}

export default class BulkSubstitutionGroup extends Component {
  constructor() {
    super()
    this.state = {
      productMap: {},
      storeMap: {},
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    this.getProductsBatch(this.props.results)
      .catch(e => {
        this.setState({
          error: e,
        })
        if (e.code === UNAUTHORISED_STATUS) {
          throw e
        }
      })
      .finally(() =>
        this.setState({
          loading: false,
        })
      )
  }

  getProductsBatch(results = []) {
    const primarySkus = results.slice(1).map(row => row[0])
    const substituteSkus = flatten(
      results.slice(1).map(row => row[1].split(','))
    )
    const skus = uniq([...primarySkus, ...substituteSkus])
    if (skus.length) {
      return Promise.all(chunk(skus, BATCHING_SIZE).map(this.getProducts)).then(
        responses => {
          const products = responses.reduce((acc, response) => {
            const prds = response.data ? response.data.product || [] : []
            return [...acc, ...prds]
          }, [])
          this.setState({
            productMap: buildProductNameMap(products) || {},
          })
        }
      )
    }
    return Promise.resolve()
  }

  getProducts(skus) {
    const productApi = new API({
      url: `/catalogue-service/product`,
    })
    if (skus.length) {
      return productApi.get({ paginate: 'false', clientId: skus.slice() })
    }
    return Promise.resolve()
  }

  // getRowErrors :: Array, Object -> Boolean
  getRowErrors(tableData, csvHeaders) {
    return tableData.map(row => {
      const isValidPrimarySku = csvHeaders.PRODUCT_NAME.validate(
        row.productName
      )
      const isValidSubstitutionSkus = row.substituteProductNames.every(
        csvHeaders.PRODUCT_NAME.validate
      )
      return !isValidPrimarySku || !isValidSubstitutionSkus
    })
  }

  render() {
    const { results = [], jobName, actions } = this.props
    const { productMap, error, loading } = this.state
    const tableData = getTableData(results, jobName, productMap)
    const headers = JobConfig[jobName].getTableHeaders()
    const csvHeaders = JobConfig[jobName].csvHeaders
    const validationErrors = this.getRowErrors(tableData, csvHeaders)
    const primarySkuCountMap = getPrimarySkuCountMap(
      tableData.map(row => row.primarySku)
    )
    const isDupicatePrimarySku = Object.values(primarySkuCountMap).some(
      value => value > 1
    )

    const includesMkpItems = tableData.some(row => isMkpSku(row.primarySku))

    return loading ? (
      <Loader />
    ) : (
      <div className="review-table">
        {error && <div className="error">{getMessage('error.server')}</div>}
        {!loading && validationErrors.some(Boolean) && (
          <div className="error">
            {getMessage('category.bulk-upload.error')}
          </div>
        )}
        {!loading && isDupicatePrimarySku && (
          <div className="error">Primary SKU must be unique.</div>
        )}
        {!loading && includesMkpItems && (
          <div className="error">Marketplace items are not allowed.</div>
        )}
        <div className="table-container">
          <Table tableDynamic={false}>
            <Header items={headers} />
            {tableData.map((row, index) => (
              <Row
                key={`${row.sku}-${index}`}
                className={
                  !loading && validationErrors[index] ? 'row-error' : ''
                }
              >
                <Cell>
                  <span
                    className={`primary-sku ${
                      !csvHeaders.PRODUCT_NAME.validate(row.productName) ||
                      primarySkuCountMap[row.primarySku] > 1 ||
                      isMkpSku(row.primarySku)
                        ? 'cell-error'
                        : ''
                    }`}
                  >
                    {row.primarySku}
                  </span>
                </Cell>
                <Cell>
                  <span className="product-name">{row.productName}</span>
                </Cell>
                <Cell>
                  {row.substituteSku.split(',').map((sku, skuIndx) => {
                    const isValidSku = csvHeaders.PRODUCT_NAME.validate(
                      row.substituteProductNames[skuIndx]
                    )
                    return (
                      <span
                        key={sku}
                        className={`substitute-sku ${
                          !isValidSku || isMkpSku(sku) ? 'cell-error' : ''
                        }`}
                      >
                        {sku}
                        {skuIndx !== row.substituteSku.split(',').length - 1 &&
                          ','}
                      </span>
                    )
                  })}
                </Cell>
              </Row>
            ))}
          </Table>
        </div>
        {actions(
          validationErrors.some(Boolean) ||
            isDupicatePrimarySku ||
            includesMkpItems
        )}
      </div>
    )
  }
}
