import React from 'react'
import Table, { Header, Row, Cell } from '../../../../../../components/Table'
import { getMessage } from '../../../../../../lib/translator'
import {
  BaseForm,
  MultiTextInput,
  VALIDATION_TYPES,
} from '../../../../../../components/Form'

class PackingDetailsNoPacklist extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: {},
    }

    this.setPackages = this.setPackages.bind(this)
  }

  updatePackages = (e) => {
    this.setPackages(e)

    this.props.updateOrderPackages(e)
  }

  setPackages = (packages) => {
    const values = this.state.values
    values['packages'] = packages

    this.setState({
      values: values,
    })
  }

  async componentDidMount() {
    const { packages } = this.props
    packages && packages.details && packages.details.crates
      ? this.setPackages(packages.details.crates)
      : this.setPackages([])
  }

  render() {
    return (
      <div className="package-details">
        <h3>{getMessage('order.details.packing.heading')}</h3>
        <Table>
          <Header>
            <Cell>{getMessage('order.packing.type')}</Cell>
            <Cell>{getMessage('order.packing.labels')}</Cell>
          </Header>
          <Row key={`package-item-0`}>
            <Cell>{getMessage(`order.packing.crates`)}</Cell>
            {this.props.itemEditMode ? (
              <Cell>
                <MultiTextInput
                  placeholder={getMessage(
                    'order.details.packages.multi-text.placeholder'
                  )}
                  name={`packages-crates`}
                  classname="packages"
                  value={this.state.values['packages']}
                  {...this.generateStateMappers({
                    stateKeys: ['packages'],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  onChange={(e) => this.updatePackages(e)}
                />
              </Cell>
            ) : (
              <Cell>
                {this.state.values['packages']
                  ? this.state.values['packages'].join(', ')
                  : ''}
              </Cell>
            )}
          </Row>
        </Table>
      </div>
    )
  }
}

export default PackingDetailsNoPacklist
