import React from 'react'
import { getMessage } from '../../../../lib/translator'
import phoneIcon from '../svg/phone.svg'
import { getPrintableTime, formatTime } from '../../../../lib/datetime'
import Loader from '../../../../components/Loader'
import Placeholder from '../svg/customer-image-placeholder.svg'
import { format, parse } from 'libphonenumber-js'
import moment from 'moment'

const IdlePickers = props => {
  const { employees, pickers } = props
  const pickerIds = pickers.map(picker => {
    return picker.pickerId
  })
  let idleEmployees = employees
  if (pickers) {
    idleEmployees = employees.filter(emp => {
      return pickerIds && !pickerIds.includes(emp.id)
    })
  }

  return (
    <React.Fragment>
      {idleEmployees.length ? (
        <div className="pickers-idle-picker-header">
          {getMessage('pickers.idle.header')}
        </div>
      ) : (
        ''
      )}
      <div className="pickers-flex">
        {idleEmployees.length ? (
          idleEmployees.map((employee, index) => {
            let lateTime = false
            if (employee.attendance !== null) {
              lateTime = moment(employee.attendance.expectedIn) <
              moment(employee.attendance.createdAt)
            }
            return (
              <div className="pickers-idle" key={index}>
                <div className="pickers-employee-details-section">
                  {employee.imageUrl ? (
                    <img
                      className="pickers-employee-image"
                      src={employee.imageUrl}
                      alt=""
                    />
                  ) : (
                    <img
                      className="pickers-employee-image-empty"
                      src={Placeholder}
                      alt=""
                    />
                  )}
                  <div className="pickers-employee-name">
                    {employee ? employee.name : ''}
                    <br />
                    <span className="pickers-employee-phone">
                      {employee && !!employee.phones.length && (
                        <img
                          className="pickers-phone-icon"
                          src={phoneIcon}
                          alt=""
                        />
                      )}
                      {employee
                        ? employee.phones.length
                          ? employee.phones[0].phone &&
                            Object.keys(parse(employee.phones[0].phone))
                              .length > 0
                            ? format(
                                parse(employee.phones[0].phone),
                                'International'
                              )
                            : ''
                          : ''
                        : ''}
                    </span>
                  </div>
                </div>
                {employee.shiftStart && employee.shiftEnd && (
                  <div className="pickers-shift-time-1 slot">
                    {getMessage('pickers.today.slot')}
                    {employee.shiftStart ? formatTime(employee.shiftStart) : ''}
                    &nbsp;-&nbsp;
                    {employee.shiftEnd ? formatTime(employee.shiftEnd) : ''}
                  </div>
                )}
                <div>
                  <div className="pickers-shift-time-1">
                    {getMessage('pickers.today.checkin.time')}
                    {
                      getPrintableTime(employee.attendance !== null ? employee.attendance.createdAt : '').split(
                        ','
                      )[1]
                    }
                    {lateTime && (
                      <span className="late-time">
                        {getMessage('pickers.today.late')}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        ) : pickers.length ? null : (
          <Loader />
        )}
      </div>
    </React.Fragment>
  )
}
export default IdlePickers
