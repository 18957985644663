import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Table from './../../operations/Orders/Table'
import Details from '../../operations/Orders/Details'

class OrdersRouter extends Component {
  render() {
    const { router, ...props } = this.props
    const details = { ...props }
    details.url = '/customer-support'
    details.className = 'cx-listing'
    if (router.match.params.id) {
      const newProps = { ...this.props }
      newProps.url = '/customer-support'
      return <Details {...newProps} />
    }
    return <Table {...details} />
  }
}

export default withRouter(({ match, ...props }) => (
  <OrdersRouter router={{ match }} {...props} />
))
