import React, { Fragment } from 'react';
import { formatIsoDate } from 'lib/datetime';
import { getMessage } from 'lib/translator'
import { getStores } from 'lib/auth';
import Table, { Cell, Header, Row } from 'components/Table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './style.scss';

const orderPacklistStatus = {
  PACKLIST_STATUS_UNSPECIFIED: 'Unspecified',
  PACKLIST_STATUS_PENDING: 'Pending',
  PACKLIST_STATUS_RECEIVED: 'Received',
  PACKLIST_STATUS_NOT_RECEIVED: 'Not Received',
  PACKLIST_STATUS_PICKING: 'Picking',
  PACKLIST_STATUS_PACKING: 'Packing',
  PACKLIST_STATUS_PACKED: 'Packed',
  PACKLIST_STATUS_CANCELLED: 'Cancelled',
}

function ZonePickingQueueTable({ zonePickingData, storeID }) {

  return (
<Table tableDynamic={false}>
            <Header
              items={[
                'Delivery Order No.',
                'Placed Time',
                'Order Slot',
                'Quantity',
                'Status',
              ]} />
            {zonePickingData.picklists.map(batch => {
              return (
                <Fragment key={`picking-batch-${batch.id}`}>
                  <div className="sub-heading">
                    <div>
                      <strong> Batch ID :</strong>{' '}
                      <Link
                        to={`/customer-support/orders?batchId=${batch.id}&offset=0&page=1`}
                      >
                        {' '}
                        {batch.id}
                      </Link>
                      <span className="curve-box">{batch.tag}</span>
                      <span> Order Qty : {batch.packlists.length}</span>
                    </div>
                  </div>
                  {batch.packlists.map((packlist, i) => {
                    const rowClasses = []
                    let packlistStatus
                    if (i === 0) {
                      rowClasses.push('offset-top')
                    }
                    for (const key in orderPacklistStatus) {
                      if (key === packlist.status) {
                        packlistStatus = orderPacklistStatus[packlist.status] || ''
                      }
                    }
                    return (
                      <Row
                        className={rowClasses.join(' ')}
                        key={`picking-order-${packlist.deliveryOrder.referenceNumber}`} >
                        <Cell>
                          <div>
                            {getMessage('order.table.referenceNumber.prefix')}
                            <a href={`/operations/delivery-orders/${packlist.deliveryOrder.referenceNumber}`} >
                              {packlist.deliveryOrder.referenceNumber}
                            </a>
                          </div>
                          <small className="text-muted">
                            {
                              getStores().filter(
                                j => j.id === parseInt(storeID, 10)
                              )[0].name
                            }
                          </small>
                        </Cell>
                        <Cell>
                          <div>
                            {formatIsoDate(packlist.deliveryOrder.createTime)}
                          </div>
                          <small className="text-muted">
                            {moment(packlist.deliveryOrder.createTime).format(
                              'LT'
                            )}
                          </small>
                        </Cell>
                        <Cell>
                          <div>
                            {formatIsoDate(
                              packlist.deliveryOrder.slotStartTime
                            )}
                          </div>
                          <small className="text-muted">
                            {moment(
                              packlist.deliveryOrder.slotStartTime
                            ).format('LT')}{' '}
                            -{' '}
                            {moment(packlist.deliveryOrder.slotEndTime).format(
                              'LT'
                            )}
                          </small>
                        </Cell>
                        <Cell>
                          <div>{packlist.itemsQuantity}</div>
                        </Cell>
                        <Cell>
                          <span>{packlistStatus}</span>
                        </Cell>
                      </Row>
                    )
                  })}
                </Fragment>
              )
            })}
          </Table>
  )
}

export default ZonePickingQueueTable;
