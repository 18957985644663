import React from 'react'
import { getMessage } from '../../../../../../lib/translator'
import './style.css'
import Table, { Row, Cell } from '../../../../../../components/Table'

const PaymentDiscount = () => {
  return (
    <Table className="table-dynamic">
      <Row key={`qc-payment-discount-row`}>
        <Cell>
          <div
            data-testid="qc-payment-discount"
            className="qc-payment-discount"
          >
            {getMessage('qc.orderSummary.AppPaymentDiscountApplied')}
          </div>
        </Cell>
      </Row>
    </Table>
  )
}

export default PaymentDiscount
