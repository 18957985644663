import React from 'react'
import { Popup } from 'components/Popup'
import moment from 'moment'
import API from 'lib/api'
import { getMessage } from 'lib/translator'
import Form, {
  BaseForm,
  VALIDATION_TYPES,
  Select,
  Input,
} from 'components/Form'
import Table, { Header, Row, Cell } from 'components/Table'

const DELIVERY_METHOD = [
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'SMS',
    value: 'sms',
  },
]

class EvoucherDialog extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      values: {},
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevProps.resendData.deliveryMethod !== prevState.values.deliveryMethod
  //   ) {
  //     const values = Object.assign({}, this.state.values)
  //     values.deliveryMethod = this.props.resendData.deliveryMethod
  //     this.setState({
  //       values: values,
  //     })
  //   }
  // }

  componentDidMount() {
    const values = Object.assign({}, this.state.values)
    values.deliveryMethod = this.props.resendData.deliveryMethod
    this.setState({
      values: values,
    })
  }

  renderDetails = () => {
    const { resendData } = this.props

    return (
      <div className="resend-details-wrapper mb container">
        <div className="row status">
          <div className="col-6">
            <h3>Status:</h3>
          </div>
          <div className="col-6">
            <h3>{resendData.status}</h3>
          </div>
        </div>
        <div className="row mt">
          <div className="col-6">
            <label>Receiver:</label>
          </div>
          <div className="col-6 word-wrap-resend">
            <label>
              {resendData.email?.replace(/\s/g, '') ||
                resendData.phone?.replace(/\s/g, '')}
            </label>
          </div>
        </div>
        <div className="row mt">
          <div className="col-6">
            <label>Amount:</label>
          </div>
          <div className="col-6">
            <label>{resendData.amount}</label>
          </div>
        </div>
        <div className="row mt">
          <div className="col-6">
            <label>Date Sent:</label>
          </div>
          <div className="col-6">
            <label>
              {moment(resendData.deliveryTime).format('MMMM DD YYYY')}
            </label>
          </div>
        </div>
      </div>
    )
  }

  async submitHandler(e) {
    const {
      resendData: { id },
      setSuccessModal,
      onClose,
    } = this.props
    e && e.preventDefault()
    try {
      const isValid = this.isFormValid()
      if (isValid) {
        const req = {
          deliveryMethod: this.state.values.deliveryMethod,
          email:
            this.state.values.deliveryMethod === 'email'
              ? this.state.values.receiver
              : '',
          phone:
            this.state.values.deliveryMethod === 'sms'
              ? `+65${this.state.values.receiver}`
              : '',
        }
        const api = new API({
          url: `/gifting-admin-service/receiver/${id}/resend`,
        })
        const response = await api.post(req)
        if (response) {
          setSuccessModal(true)
          this.setState({ values: {} })
        }
      }
    } finally {
      onClose()
    }
  }


  render() {
    const { show, onClose, resendHistory = [] } = this.props
    const { SubmitButton, CancelButton } = this.buttons

    return (
      <Popup
        show={show}
        heading={getMessage('resendDialog.heading')}
        close={onClose}
        closeText={getMessage('dialog.cancelText')}
        okText={getMessage('dialog.okText')}
      >
        {this.renderDetails()}
        <Form onSubmit={(e) => this.submitHandler(e)}>
          <div className="resend-details-wrapper mb container">
            <div className="row status">
              <div className="col-12">
                <h3>{getMessage('resendDialog.subheading')}</h3>
              </div>
            </div>
            <div className="row mt">
              <div className="col-6 mt">
                <label>Method:</label>
              </div>
              <div className="col-6">
                <label>
                  <Select
                    required
                    name="method-selector"
                    options={[...DELIVERY_METHOD]}
                    {...this.generateStateMappers({
                      stateKeys: ['deliveryMethod'],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                  />
                </label>
              </div>
            </div>
            <div className="row mt mb">
              <div className="col-6 mt">
                <label>Receiver:</label>
              </div>
              {this.state.values.deliveryMethod === 'email' ? (
                <div className="col-6">
                  <label>
                    <Input
                      type="text"
                      name="receiver"
                      id="receiver"
                      required
                      placeholder={getMessage('resendDialog.emailText')}
                      {...this.generateStateMappers({
                        stateKeys: ['receiver'],
                        validationType: VALIDATION_TYPES.ONSUBMIT,
                      })}
                    />
                  </label>
                </div>
              ) : (
                <div className="col-6">
                  <label>
                    <Input
                      placeholder={getMessage('shifts.phone.placeholder')}
                      name="receiver"
                      pattern="[0-9]+"
                      type="text"
                      required
                      {...this.generateStateMappers({
                        stateKeys: ['receiver'],
                        loseEmphasisOnFill: true,
                        validationType: VALIDATION_TYPES.ONCHANGE,
                      })}
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="resend-details-wrapper mt mb container">
            <div className="row status">
              <div className="col-6">
                <h1>{getMessage('resendDialog.history')}</h1>
              </div>
            </div>

            {resendHistory?.length > 0
              ? resendHistory?.map((resendItem, index) => (
                  <>
                    <Cell>
                      <h4>
                        {moment(resendItem.createTime).format('MMMM DD YYYY')}
                      </h4>
                    </Cell>
                    <Table key={index}>
                      <Row>
                        <Header>
                          <Cell>{getMessage('resendDialog.method')}</Cell>
                          <Cell />
                          <Cell>{getMessage('resendDialog.receiver')}</Cell>
                        </Header>
                      </Row>

                      <Row style={{ display: 'block' }}>
                        <Cell>{resendItem.deliveryMethod}</Cell>
                        {resendItem.deliveryMethod === 'sms' && <Cell />}
                        <Cell className="full-width">
                          {resendItem.deliveryMethod === 'email'
                            ? resendItem.email
                            : resendItem.phone}
                        </Cell>
                      </Row>
                    </Table>
                  </>
                ))
              : null}
          </div>
          <SubmitButton testid="evoucher-dialog-btn">
            {getMessage('category.form.submitText')}
          </SubmitButton>
          <CancelButton>{getMessage('category.form.cancelText')}</CancelButton>
        </Form>
      </Popup>
    )
  }
}

export default EvoucherDialog
