import React from 'react'

const Summary = ({ summaryData }) => {
  return (
    <div className="summary">
      <p className="heading">Summary</p>
      <ul>
        <li>Total: {summaryData.total}</li>
        <li className="succesCount">Success: {summaryData.success}</li>
        <li className="failure">Failures: {summaryData.failure}</li>
        {summaryData?.errors?.length > 0 && (
          <li className="errors">
            Errors:
            {summaryData.errors.map((error, index) => (
              <p key={index} className="error">
                {error}
              </p>
            ))}
          </li>
        )}
      </ul>
    </div>
  )
}

export default Summary
