import React from 'react'
import { Text, View } from '../ui'
import moment from 'moment'
import { Field } from 'formik'

export const PreorderDate = ({ date, slotDate }) => {
  const canBook = Boolean(slotDate && slotDate.some((slot) => slot.available))

  return (
    <View
      justifyContent="space-between"
      background={canBook ? '' : '#F2F2F2'}
      border="1px solid #EAEAEA"
      padding={16}
      margin="0 0 8px"
      style={{ minHeight: 51 }}
      data-testid={`date-${date}-${canBook ? 'available' : 'unavailable'}`}
    >
      <Text variant="label" fontSize={12} fontWeight={700}>
        {moment(date).format('ddd, D MMM')}
      </Text>
      <View width={100} justifyContent="end" gap={8}>
        <Text fontSize={12}>{!canBook && 'Fully booked'}</Text>
        <Field
          type="radio"
          value={date}
          name="pickupDate"
          disabled={!canBook}
        />
      </View>
    </View>
  )
}
