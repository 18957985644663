import React, { Fragment } from 'react'
import { MultiSelect, Input, VALIDATION_TYPES, Checkbox } from '../../Form'
import { getMessage } from '../../../lib/translator'
import TaskReward from './TaskReward'
import { RULE_KEYS, CARD_TYPES } from '../utils'
import moment from 'moment'
import AddConditions from './AddConditions'
import StoresApplicable from './StoresApplicable'
import MerchantsApplicable from './MerchantsApplicable'
import BankNetworkCondition from './BankNetworkCondition'
import { CHANNEL_OPTIONS } from '../../Rewards/Campaigns/utils'

const TaskDetail2 = ({ _this, task, taskIndex, method }) => {
  const isLinkpayLoyaltyOthersIncluded = task?.rule?.[
    RULE_KEYS?.[task.userAction]?.[0]
  ]?.channels?.includes('LINKPAY_LOYALTY_OTHERS')

  const linkpayLoyaltyClsCorporateIds =
    task?.rule?.[RULE_KEYS[task.userAction][0]]?.linkpayLoyaltyClsCorporateIds

  const onUpdateMerchants = (selectedMerchantsIds) => {
    const updatedValues = { ..._this.state.values }
    updatedValues.taskList[taskIndex].rule[
      RULE_KEYS[task.userAction][0]
    ].linkpayLoyaltyClsCorporateIds = selectedMerchantsIds
    _this.setState({ values: updatedValues })
  }
  return (
    <div>
      {!['COMPLETE_CHALLENGE', 'CDG_MONTHLY_SPEND'].includes(
        task.userAction
      ) && (
        <Fragment>
          <div className="task-detail">
            <MultiSelect
              name="task-singleOrder"
              className="task-channel"
              label={getMessage('omnichallenge.task.channels')}
              placeholder={getMessage(
                'omnichallenge.task.channels.placeholder'
              )}
              data-testId={`select-channel-task-${taskIndex}`}
              options={CHANNEL_OPTIONS}
              {..._this.generateStateMappers({
                stateKeys: [
                  'taskList',
                  taskIndex,
                  'rule',
                  RULE_KEYS[task.userAction][0],
                  'channels',
                ],
                loseEmphasisOnFill: true,
              })}
              readOnly={
                method === 'edit' &&
                moment().isAfter(_this.state.values.startAt)
              }
              required
            />
            {isLinkpayLoyaltyOthersIncluded && (
              <MerchantsApplicable
                onUpdate={onUpdateMerchants}
                initialValues={linkpayLoyaltyClsCorporateIds}
              />
            )}
            {task.userAction === 'MULTIPLE_ORDER' && (
              <Input
                className="mobile-768"
                testid={`task-spendamount-${taskIndex + 1}`}
                label={getMessage('omnichallenge.task.minorder')}
                type="number"
                step={1}
                min={1}
                max={1000000}
                {..._this.generateStateMappers({
                  stateKeys: [
                    'taskList',
                    taskIndex,
                    'rule',
                    RULE_KEYS[task.userAction][0],
                    'orderCount',
                  ],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                readOnly={
                  method === 'edit' &&
                  moment().isAfter(_this.state.values.startAt)
                }
                required
              />
            )}
          </div>
          <div className="task-detail-sec3 mobile-768">
            <Input
              className="mobile-768 mr-2"
              testid={`task-spendamount-${taskIndex + 1}`}
              label={
                task.userAction !== 'CUMULATIVE_SPEND'
                  ? getMessage('omnichallenge.task.minspendamount')
                  : getMessage('omnichallenge.task.accumulatedspendamount')
              }
              type="number"
              prefix={'$'}
              step={0.01}
              min={0.01}
              max={1000000}
              {..._this.generateStateMappers({
                stateKeys: [
                  'taskList',
                  taskIndex,
                  'rule',
                  RULE_KEYS[task.userAction][0],
                  RULE_KEYS[task.userAction][1],
                ],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              readOnly={
                method === 'edit' &&
                moment().isAfter(_this.state.values.startAt)
              }
              required
            />
            <StoresApplicable
              taskIndex={taskIndex}
              task={task}
              _this={_this}
              method={method}
            />
          </div>
          {['SINGLE_ORDER', 'CUMULATIVE_SPEND', 'MULTIPLE_ORDER'].includes(
            task.userAction
          ) && (
            <AddConditions
              _this={_this}
              method={method}
              taskIndex={taskIndex}
              userAction={RULE_KEYS[task.userAction][0]}
            />
          )}
          <br />
          <Input
            className="mobile-768"
            label={getMessage('omnichallenge.task.eligibleProductsDeeplink')}
            type="text"
            {..._this.generateStateMappers({
              stateKeys: ['taskList', taskIndex, 'eligibleProductsDeeplink'],
            })}
          />
          <div className="cardtype-section single-column-field mb-1">
            <div className="field-label">
              <strong>
                {getMessage('omnichallenge.task.cardnetwork.label')}
              </strong>
            </div>
            <div className="normal-text mb-1">
              {getMessage('omnichallenge.task.cardnetwork.placeholder')}
            </div>
            {CARD_TYPES.map((card, cardIndex) => (
              <Checkbox
                key={cardIndex}
                inlineLabel={getMessage(
                  `omnichallenge.task.cardnetwork.${card}`
                )}
                name={`task-${taskIndex}-${card}`}
                testid={`task-${taskIndex}-${card}`}
                {..._this.generateStateMappers({
                  stateKeys: ['taskList', taskIndex, 'cardTypes', card],
                  loseEmphasisOnFill: true,
                })}
                disabled={
                  method === 'edit' &&
                  moment().isAfter(_this.state.values.startAt)
                }
              />
            ))}
          </div>
          <BankNetworkCondition
            _this={_this}
            task={task}
            taskIndex={taskIndex}
            method={method}
          />
        </Fragment>
      )}

      {task.userAction === 'CDG_MONTHLY_SPEND' && (
        <div className="task-detail-sec3 mobile-768">
          <Input
            className="mobile-768 mr-2"
            testid={`task-target-spendamount-${taskIndex + 1}`}
            label={getMessage('omnichallenge.task.targetSpend')}
            placeholder={getMessage('omnichallenge.task.targetSpend')}
            type="number"
            prefix={'$'}
            step={0.01}
            min={0.01}
            max={1000000}
            {..._this.generateStateMappers({
              stateKeys: [
                'taskList',
                taskIndex,
                'rule',
                RULE_KEYS[task.userAction][0],
                RULE_KEYS[task.userAction][1],
              ],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            readOnly={
              method === 'edit' && moment().isAfter(_this.state.values.startAt)
            }
            required
          />
        </div>
      )}

      <TaskReward
        _this={_this}
        method={method}
        task={task}
        taskIndex={taskIndex}
        state={{
          keys: [
            ['taskList', taskIndex, 'award', 'type'],
            ['taskList', taskIndex, 'award', 'data', 'amount'],
            ['taskList', taskIndex, 'awardCap'],
          ],
        }}
      />
    </div>
  )
}

export default TaskDetail2
