import React, { Component } from 'react'
import './style.css'
import { Popup as Modal } from '../../../../../components/Popup'
import { getMessage } from '../../../../../lib/translator'
import { hasPermissions } from '../../../../../lib/auth'
import Dialog from '../../../../../components/Popup/Dialog'
import Mapform from './MapForm'

import deleteIcon from './delete-icon.svg'

export default class AddressCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMap: false,
      error: null,
      showDeletePrompt: false,
      showSuccessDialog: false,
      location: props.coordinates,
    }
    ;[
      'showMap',
      'hideMap',
      'showError',
      'showDeletePrompt',
      'handleCloseDialog',
      'onSuccess',
      'showSuccessDialog',
      'handleSuccessClose',
    ].forEach(fn => {
      this[fn] = this[fn].bind(this)
    })
  }

  hideMap() {
    this.setState({
      showMap: false,
      error: null,
    })
  }

  showMap() {
    this.setState({
      showMap: true,
    })
  }

  showError(error) {
    this.setState({
      error,
    })
  }

  showDeletePrompt() {
    this.setState({
      showDeletePrompt: true,
    })
  }
  handleCloseDialog() {
    this.setState({
      showDeletePrompt: false,
    })
  }

  showSuccessDialog() {
    this.setState({
      showSuccessDialog: true,
    })
  }

  handleSuccessClose() {
    this.setState({
      showSuccessDialog: false,
    })
  }

  onSuccess(lat, lng) {
    this.setState({
      location: { lat, lng },
    })
    this.hideMap()
    this.showSuccessDialog()
  }

  render() {
    const {
      deleteMessage,
      title,
      value,
      onDelete,
      id,
      showDeleteOption,
      customerId,
    } = this.props
    const { showMap, showDeletePrompt, showSuccessDialog } = this.state
    const coors = this.state.location
    return (
      <div className="AddressCard">
        <div className="title">{title}</div>
        <div className="value">
          {value.split('\n').map((i, key) => {
            return <div key={key}>{i}</div>
          })}
        </div>
        <div className="card-actions">
          {showDeleteOption && hasPermissions('customer', 'address', 'delete') && (
            <button className="action2" onClick={this.showDeletePrompt}>
              <img src={deleteIcon} alt="delete-icon" />
              {deleteMessage}
            </button>
          )}
        </div>
        {showDeletePrompt && (
          <Dialog
            show={this.state.showDeletePrompt}
            close={this.handleCloseDialog}
            title={getMessage('customer.address.delete.prompt.title')}
            closeText={getMessage('customer.cancel.message')}
            onOk={() => {
              onDelete(id)
            }}
            okText={getMessage('customer.confirm.message')}
          />
        )}
        {showSuccessDialog && (
          <Dialog
            className="success"
            show={this.state.showSuccessDialog}
            close={this.handleSuccessClose}
            title={getMessage('customer.address.success')}
            information={getMessage('customer.address.saved')}
            closeText={getMessage('customer.address.okay')}
          />
        )}
        {showMap && (
          <Modal
            className="editPopup map-address"
            heading={getMessage('customer.address.modal.heading')}
            show={this.state.showMap}
            close={this.hideMap}
          >
            <Mapform
              address={value}
              draggable
              addressId={id}
              onSuccess={this.onSuccess}
              customerId={customerId}
              coordinates={coors}
            />
          </Modal>
        )}
      </div>
    )
  }
}
