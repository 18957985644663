import React, { useContext } from 'react'
import Tabs from '../../../../../components/Tabs'
import {
  BaseForm,
  Input,
  Select,
  VALIDATION_TYPES,
  MultiTextInput,
  Checkbox,
} from '../../../../../components/Form'
import Loader from '../../../../../components/Loader'
import { Dialog } from '../../../../../components/Popup'

import { saveEntityMetaData } from '../../../../../lib/auth'
import API from '../../../../../lib/api'
import { getMessage } from '../../../../../lib/translator'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { SplitContext } from 'containers/SplitContext'

import './style.css'

const TYPE_ENUM_CAMEL_CASE = 'multiValued Enum'
const TYPE_ENUM_LOWER_CASE = 'multivalued enum'

const ConfigTypes = [
  {
    text: 'Number',
    value: 'number',
  },
  {
    text: 'String',
    value: 'string',
  },
  {
    text: 'Text',
    value: 'text',
  },
  {
    text: 'Boolean',
    value: 'boolean',
  },
  {
    text: 'Enum',
    value: 'enum',
  },
  {
    text: 'Country',
    value: 'country',
  },
  {
    text: 'MultiValued Enum',
    value: TYPE_ENUM_CAMEL_CASE,
  },
]

// transform the data from the fetch api to the format required by the form
export function transformFetchMetaData(metaData) {
  const values = {}
  Object.entries(metaData).forEach(([key, value]) => {
    const isProduct = key === 'product'
    values[key] = []
    value &&
      Object.entries(value) &&
      Object.entries(value).forEach(([entity, details]) => {
        // transform data from fetch product meta data api to the format required by the form
        const typeMeta = details.rule ? details.rule : details.typeMeta
        const type =
          isProduct && details.type === TYPE_ENUM_LOWER_CASE
            ? TYPE_ENUM_CAMEL_CASE
            : details.type

        const entityMetadata = {
          name: entity,
          type: type,
          typeMeta: typeMeta && typeMeta.allowedValue,
          indexed: details.indexed,
          mandatory: details.mandatory,
          id: details.id,
          layoutType: details.layoutType,
        }
        if (isProduct) {
          entityMetadata.uiVisibility =
            details.uiVisibility !== undefined ? details.uiVisibility : false
        }
        if (details.type === 'country' && typeMeta && typeMeta.allowedValue) {
          if (typeMeta.allowedValue.length) {
            entityMetadata.typeMeta = typeMeta.allowedValue.map((c) => {
              return { name: c }
            })
          }
        }
        values[key].push(entityMetadata)
      })
  })
  return values
}

// transform the data from form to the format required by the update api
export function transformUpdateMetaData(values, productMetadataMigrateEnabled) {
  const dataToSend = {}
  Object.entries(values).forEach(([key, value]) => {
    const entityMetaData = {}
    value.forEach((obj) => {
      const isProduct = key === 'product'
      entityMetaData[obj.name] = {
        type:
          isProduct &&
          obj.type === TYPE_ENUM_CAMEL_CASE &&
          productMetadataMigrateEnabled
            ? TYPE_ENUM_LOWER_CASE
            : obj.type,
        indexed: obj.indexed,
        mandatory: obj.mandatory,
        layoutType: obj.layoutType,
      }
      if (obj.id) {
        entityMetaData[obj.name].id = obj.id
      }
      if (obj.type === 'enum' || obj.type === TYPE_ENUM_CAMEL_CASE) {
        if (isProduct && productMetadataMigrateEnabled) {
          entityMetaData[obj.name].rule = { allowedValue: obj.typeMeta }
        } else {
          entityMetaData[obj.name].typeMeta = { allowedValue: obj.typeMeta }
        }
      } else if (obj.type === 'country') {
        if (isProduct) {
          delete entityMetaData[obj.name].rule
        } else {
          delete entityMetaData[obj.name].typeMeta
        }
      }
      if (isProduct) {
        entityMetaData[obj.name].uiVisibility =
          obj.uiVisibility !== undefined ? obj.uiVisibility : false
      }
      if (obj.name === '') {
        delete entityMetaData[obj.name]
      }
    })
    dataToSend[key] = entityMetaData
  })
  return dataToSend
}

class EntityMetaData extends BaseForm {
  constructor(props) {
    super(props)

    this.tabHeaders = [
      'order',
      'customer',
      'product',
      'store',
      'address',
      'offer',
    ]
    this.layouts = ['text', 'string', 'number']

    this.state.currentTabIndex = 0
    this.state.showLoader = 0
    this.state.showErrorDialog = false
    this.state.showDeleteDialog = false
    this.state.showSuccessDialog = false

    this.handleOnTabClick = this.handleOnTabClick.bind(this)
    this.addLayout = this.addLayout.bind(this)
    this.deleteKey = this.deleteKey.bind(this)
  }

  componentDidMount() {
    const api = new API({ url: `/config-service/meta-data` })
    this.setState({
      showLoader: true,
    })
    api.get().then(
      (response) => {
        const tranformedResponse = response.data.config.entityMetaData
        const values = transformFetchMetaData(tranformedResponse)
        this.setState({
          values,
          product: response.data.config.entityMetaData.product,
          showLoader: false,
        })
      },
      (error) => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
        this.setState({
          formError: error,
          showLoader: false,
        })
      }
    )
  }

  handleOnTabClick = (index) => {
    this.setState(
      {
        currentTabIndex: index,
      },
      () => {
        const productMetadataMigrateEnabled =
          this.props.splits?.[SPLIT_FEATURES.PRDOUCT_METADATA_MIGRATION_ENABLED]
            ?.treatment === 'on'
        if (!productMetadataMigrateEnabled) {
          return
        }

        // if product fetch product meta data from new api
        const currentTab = this.tabHeaders[this.state.currentTabIndex]
        if (currentTab === 'product') {
          this.setState({
            showTabLoader: currentTab,
          })
          const metaDataApi = new API({ url: `/v1/meta-data` })
          metaDataApi.get().then(
            (response) => {
              const values = transformFetchMetaData(
                response.data.entityMetaData
              )
              this.setState({
                values: {
                  ...this.state.values,
                  product: values.product,
                },
                showTabLoader: '',
              })
            },
            (error) => {
              if (error.code === 401 || error.code === 403) {
                throw error
              }
              this.setState({
                formError: error,
                showTabLoader: '',
              })
            }
          )
        }
      }
    )
  }

  addLayout() {
    const values = Object.assign({}, this.state.values)
    const name = this.tabHeaders[this.state.currentTabIndex]
    const fields = this.state.values[name] || []
    fields.push({
      name: '',
      type: 'string',
    })
    values[name] = fields
    this.setState({
      values,
    })
  }

  deleteKey() {
    const deleteIndex = this.state.deleteIndex
    const name = this.tabHeaders[this.state.currentTabIndex]
    const productMetadataMigrateEnabled =
      this.props.splits?.[SPLIT_FEATURES.PRDOUCT_METADATA_MIGRATION_ENABLED]
        ?.treatment === 'on'
    const isNewProductMetaData =
      !productMetadataMigrateEnabled && name === 'product'
    if (isNewProductMetaData) {
      values = Object.assign({}, { product: this.state.product })
    }
    let values = Object.assign({}, this.state.values)
    const keys = values[name]
    keys.splice(deleteIndex, 1)
    values[name] = keys
    this.setState({
      ...(isNewProductMetaData
        ? { product: values.product }
        : {
            values,
          }),
      deleteIndex: null,
      showDeleteDialog: false,
    })
  }

  onSubmit(_data) {
    this.setState({
      submitting: true,
    })
    const tabName = this.tabHeaders[this.state.currentTabIndex]
    const productMetadataMigrateEnabled =
      this.props.splits?.[SPLIT_FEATURES.PRDOUCT_METADATA_MIGRATION_ENABLED]
        ?.treatment === 'on'
    const isProductTab = tabName === 'product'
    const api = new API({
      url:
        productMetadataMigrateEnabled && isProductTab
          ? `/v1/meta-data`
          : `/config-service/meta-data`,
    })
    const values = Object.assign({}, this.state.values)
    const dataToSend = transformUpdateMetaData(
      values,
      productMetadataMigrateEnabled
    )

    let params = {
      config: {
        entityMetaData: {
          ...dataToSend,
        },
      },
    }
    if (productMetadataMigrateEnabled) {
      if (isProductTab) {
        // send to new api with product data from new api
        params = {
          entityMetaData: {
            product: dataToSend.product,
          },
        }
      } else {
        // send to old api with product data with old api
        params = {
          config: {
            entityMetaData: {
              ...dataToSend,
              product: this.state.product,
            },
          },
        }
      }
    }
    api.put(params).then(
      (response) => {
        this.setState({
          showSuccessDialog: true,
          submitting: false,
        })
        if (!isProductTab) {
          saveEntityMetaData(response.data.config.entityMetaData)
        }
      },
      (error) => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
        this.setState({
          formError: error.message.split(':').slice(1).join(':'),
          showErrorDialog: true,
          submitting: false,
        })
      }
    )
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const name = this.tabHeaders[this.state.currentTabIndex]
    const fields = this.state.values[name]
    const isProduct = name === 'product'
    return this.state.showLoader ? (
      <React.Fragment>
        <h1>{getMessage('entityMetaData.heading')}</h1>
        <Loader />
      </React.Fragment>
    ) : (
      <div className="entity-meta-data-page">
        <h1>{getMessage('entityMetaData.heading')}</h1>
        <React.Fragment>
          <Dialog
            className="success"
            show={this.state.showSuccessDialog}
            title={getMessage('entityMetaData.success')}
            information={getMessage('entityMetaData.success.information')}
            close={() => {
              this.setState({ showSuccessDialog: false })
            }}
            closeText={getMessage('entityMetaData.okay')}
          />
          <Dialog
            show={this.state.showErrorDialog}
            title={getMessage('entityMetaData.error')}
            information={this.state.formError}
            close={() => this.setState({ showErrorDialog: false })}
            closeText={getMessage('entityMetaData.okay')}
          />
          <Dialog
            show={this.state.showDeleteDialog}
            title={getMessage('entityMetaData.are.you.sure')}
            information={getMessage('entityMetaData.this.key.will.be.deleted')}
            close={() =>
              this.setState({ showDeleteDialog: false, deleteIndex: null })
            }
            closeText={getMessage('entityMetaData.cancel')}
            okText={getMessage('entityMetaData.delete')}
            onOk={this.deleteKey}
          />
          <div className="tab-container">
            <Tabs items={this.tabHeaders} onClick={this.handleOnTabClick} />
            {this.state.showTabLoader === name ? (
              <Loader />
            ) : (
              <div className="tab-details">
                <Form>
                  <div
                    className={`${name}-tab ${isProduct ? 'product-custom-class' : ''}`}
                  >
                    {fields &&
                      fields.map((field, index) => {
                        const type = field.type
                        return (
                          <div key={`fragment-${index}`}>
                            <div
                              className={`key-container ${
                                isProduct ? 'product-key-container' : ''
                              }`}
                              data-testid={`field-${field.name}`}
                              key={`input-${index}`}
                            >
                              <Input
                                key={`field-${index}`}
                                type="text"
                                name={`text-${index}`}
                                label={getMessage('entityMetaData.textLabel')}
                                placeholder={getMessage(
                                  'entityMetaData.text.placeholder'
                                )}
                                {...this.generateStateMappers({
                                  stateKeys: [name, index, 'name'],
                                  validationTypes: VALIDATION_TYPES.ONSUBMIT,
                                  loseEmphasisOnFill: true,
                                })}
                              />
                              <Select
                                name={`select-${index}`}
                                placeholder={getMessage(
                                  'entityMetaData.type.placeholder'
                                )}
                                label={getMessage('entityMetaData.type')}
                                key={`select-${index}`}
                                options={ConfigTypes}
                                {...this.generateStateMappers({
                                  stateKeys: [name, index, 'type'],
                                  loseEmphasisOnFill: true,
                                })}
                              />
                              {(type === 'enum' ||
                                type === TYPE_ENUM_CAMEL_CASE ||
                                type === TYPE_ENUM_LOWER_CASE) && (
                                <MultiTextInput
                                  className="enum-fields"
                                  label={getMessage('enitityMetaData.values')}
                                  placeholder={getMessage(
                                    'enitityMetaData.values.placeholder'
                                  )}
                                  required={index === fields.length - 1}
                                  name={`enum-${index}`}
                                  {...this.generateStateMappers({
                                    stateKeys: [name, index, 'typeMeta'],
                                    loseEmphasisOnFill: true,
                                    validationType: VALIDATION_TYPES.ONSUBMIT,
                                  })}
                                  validationStrings={{
                                    valueMissing: getMessage(
                                      'input.requiredMessage'
                                    ),
                                  }}
                                />
                              )}
                              {isProduct && (
                                <>
                                  <Checkbox
                                    label={getMessage(
                                      'entityMetaData.checkboxLabel'
                                    )}
                                    className="checkbox"
                                    name={`filter-checkbox-${index}`}
                                    {...this.generateStateMappers({
                                      stateKeys: [name, index, 'indexed'],
                                      loseEmphasisOnFill: true,
                                    })}
                                  />
                                  <Checkbox
                                    label={getMessage(
                                      'entityMetaData.uiVisibilityLabel'
                                    )}
                                    className="checkbox"
                                    name={`ui-visbility-checkbox-${index}`}
                                    {...this.generateStateMappers({
                                      stateKeys: [name, index, 'uiVisibility'],
                                      loseEmphasisOnFill: true,
                                    })}
                                  />
                                </>
                              )}
                              <button
                                type="button"
                                className="delete-button"
                                onClick={() =>
                                  this.setState({
                                    showDeleteDialog: true,
                                    deleteIndex: index,
                                  })
                                }
                              >
                                {getMessage('entityMetadata.delete')}
                              </button>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                  <div className="add-button">
                    <button
                      type="button"
                      className="button"
                      onClick={this.addLayout}
                    >
                      {getMessage('entityMetadata.add')}
                    </button>
                  </div>
                  <div className="form-actions">
                    <SubmitButton disabled={this.state.submitting}>
                      {getMessage('entityMetaData.submitText')}
                    </SubmitButton>
                    <button
                      className="button"
                      type="button"
                      onClick={() => this.props.history.goBack()}
                    >
                      {getMessage('entityMetaData.cancel')}
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </React.Fragment>
      </div>
    )
  }
}

export default function EntityMetaDataWrapper(props) {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig

  return <EntityMetaData {...props} splits={splits} />
}
