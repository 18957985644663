const data = {
  'sng-actions.dashboard.title': 'S&G Actions',
  'sng-actions.button.block': 'Block Scan & Go',
  'sng-actions.text.block.success': 'Block user successfully',
  'sng-actions.text.unblock.success': 'Unblock user successfully',
  'sng-actions.text.blacklist.success': 'Blacklist user successfully',
  'sng-actions.text.block.fail': 'Unable to block customer',
  'sng-actions.text.unblock.fail': 'Unable to unblock customer',
  'sng-actions.text.blacklist.fail': 'Unable to blacklist customer',
  'sng-actions.text.blacklist.info': 'This user has been blacklisted',
  'sng-actions.button.blacklist': 'Blacklist',
  'sng-actions.button.unblock': 'Unblock Scan & Go',
  'sng-actions.tabs.devices': 'Devices',
  'sng-actions.tabs.logs': 'Logs',
}

export default data
