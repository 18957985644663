import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Select } from '../../../../../../components/Form'
import RenderListing from '../RenderListing'
import Loader from '../../../../../../components/Loader'
import API from '../../../../../../lib/api'
import { getMessage } from '../../../../../../lib/translator'
import '../style.css'

const Challenges = ({ uid }) => {
  const [challengeList, setChallengeList] = useState([])
  const [selectedChallenge, setSelectedChallenge] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    function fetchChallenges() {
      const chendolAPI = new API({
        url: `/chendol/challenges?limit=1000&offset=0&isEnabled=true&uid=${uid}`,
      })

      return chendolAPI
        .get()
        .then((response) => {
          const challengeListArr = response.data.list?.map((item) => {
            const startAt = moment(item.startAt).format('DD MMM YYYY')
            const endAt = moment(item.endAt).format('DD MMM YYYY')
            return {
              value: item.id,
              text: `${item.id} - ${item.name} - ${startAt} to ${endAt}`,
            }
          })
          setChallengeList(challengeListArr)
        })
        .catch((e) => {
          setError(
            e.message || getMessage('customer.details.rewards.serverError')
          )
        })
    }
    fetchChallenges()
  }, [])

  const handleChallengeSelect = (e) => {
    setSelectedChallenge(e)
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <h2>{getMessage('customer.details.challenge')}</h2>
        </div>
      </div>
      {!challengeList.length && <Loader size="sm" />}
      {error && (
        <div className="error" data-testid="error">
          {error}
        </div>
      )}
      {!error && challengeList.length > 0 && (
        <div className="mt">
          <Select
            testid="challenge-campaign-list"
            label={getMessage('customer.details.rewards.campaignLabel')}
            placeholder={getMessage(
              'customer.details.rewards.campaignPlaceholder'
            )}
            options={challengeList}
            value={selectedChallenge}
            onChange={handleChallengeSelect}
            className="auto-width col-6"
          />
          <RenderListing
            uid={uid}
            selectedChallenge={selectedChallenge}
            type="challenges"
            handleChallengeSelect={handleChallengeSelect}
          />
        </div>
      )}
    </div>
  )
}

export default Challenges
