import React, { Component } from 'react'
import MetaDataFormFields from '../../../../../components/MetaDataFormFields'
import API from '../../../../../lib/api'
import {
  getEntityMetaData,
  saveEntityMetaData,
  isExtensionEnabled,
} from '../../../../../lib/auth'

import './style.css'

class OrderMetaData extends Component {
  constructor(props) {
    super(props)
    this.metaDataWithType = {}
    this.getOrderMetaData = this.getOrderMetaData.bind(this)
  }

  setValuesToEmpty(obj) {
    const object = Object.assign({}, obj)
    obj && Object.keys(obj).forEach(key => (object[key] = ''))
    return object
  }

  getOrderMetaData() {
    const metaData = getEntityMetaData().order
    this.props.initializeMetaData(this.setValuesToEmpty(metaData))
    this.metaDataWithType = Object.assign({}, metaData)
    this.metaData = Object.assign({}, this.props.metaData)
    if (!this.metaData) {
      Object.entries(metaData).forEach(([key, _type]) => {
        metaData[key] = ''
      })
      this.metaData = metaData
    }
    this.setState({
      values: metaData,
    })
  }

  componentDidMount() {
    const orderKeys = getEntityMetaData() && getEntityMetaData().order
    if (
      (!orderKeys ||
        (Array.isArray(orderKeys) && !orderKeys.length) ||
        (orderKeys.constructor === Object && !Object.keys(orderKeys).length)) &&
      isExtensionEnabled('EntityMetaData')
    ) {
      const api = new API({ url: `/config-service/meta-data` })
      api.get().then(response => {
        const details = response.data.config.entityMetaData
        saveEntityMetaData(details)
        this.getOrderMetaData()
      })
    } else {
      if (isExtensionEnabled('EntityMetaData')) {
        this.getOrderMetaData()
      }
    }
  }

  render() {
    const { metaData, onChange } = this.props
    return (
      Object.keys(this.metaDataWithType).length > 0 && (
        <React.Fragment>
          <MetaDataFormFields
            className="order-meta-data"
            metaDataWithType={this.metaDataWithType}
            page="create-order-form"
            metaData={metaData}
            onChange={onChange}
            _this={this}
          />
        </React.Fragment>
      )
    )
  }
}

export default OrderMetaData
