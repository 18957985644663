import React from 'react'
import SmartStoreHeader from '../../components/SmartstoreHeader'
import { Provider } from '../Context'

import vehicle from './vehicle.svg'
import './style.css'

export default function PublicPage(props) {
  const enterprise = true
  return (
    <Provider value={enterprise}>
      <div className={'fullWidthPage eazy-page ' + props.className}>
        {props.showHeader && (
          <header className="global-header">
            <SmartStoreHeader />
          </header>
        )}
        <main>{props.children}</main>
        <footer className="page-footer">
          <img className="vehicle" src={vehicle} alt="FPG-vehicle" />
        </footer>
      </div>
    </Provider>
  )
}
PublicPage.defaultProps = {
  showHeader: true,
}
