import React, { Component } from 'react'
import moment from 'moment'

import Upload from '../../../../components/FileUpload'
import {
  Checkbox,
  SingleDatePicker,
  TimePicker,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import { getSession } from '../../../../lib/auth'
import { Link } from 'react-router-dom'
import csvIcon from './csv-icon.svg'
import uploadIcon from './upload-products.svg'

import './style.css'

export default class ProductUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isScheduled: false,
    }
    this.transformSubmit = this.transformSubmit.bind(this)
  }

  transformSubmit(data) {
    const userId = getSession().user.id
    if (
      this.state.isScheduled &&
      this.state.scheduleDate &&
      this.state.scheduleTime
    ) {
      let dateTime = moment().add(5, 'minutes')
      const selectedDateTime = moment(
        this.state.scheduleDate + ' ' + this.state.scheduleTime
      )
      dateTime = selectedDateTime > dateTime ? selectedDateTime : dateTime
      data.append('scheduledAt', dateTime.format('YYYY-MM-DD HH:mm:ss'))
    }
    data.append('jobName', 'SKU_STORE_VISIBILITY')
    data.append('userID', userId)
    return data
  }

  render() {
    const { hideAddButton } = this.props
    return (
      <div className="product-upload">
        {!hideAddButton ? (
          <div className="text-center">
            <Link
              to="/catalogue/products/add"
              className="button primary add-product-link"
            >
              + {getMessage('product.add.text')}
            </Link>
          </div>
        ) : null}
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          {!hideAddButton ? (
            <span className="border-text">
              {getMessage('productUpload.orText')}
            </span>
          ) : null}
          <Upload
            name="bulkProductUpload"
            accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
            placeholder={getMessage('productUpload.uploadFileText')}
            uploadButtonText="Upload"
            cancelText="Cancel"
            uploadUrl="/catalogue-service/product-attribute-upload"
            uploadKey="uploadfile"
            validationStrings={{
              invalidFileType: getMessage('input.invalidFileType'),
            }}
            transformSubmit={this.transformSubmit}
            strings={{
              defaultMessage: getMessage('fileUpload.importCsv.heading'),
              progressMessage: getMessage('fileUpload.importedCsv.heading'),
              completionMessage: getMessage('fileUpload.uploadedCsv.success'),
            }}
            icon={csvIcon}
          />
        </div>
        <div className="text-center schedule-checkbox">
          <Checkbox
            className="entity-checkbox"
            inlineLabel={`Schedule later`}
            name={`isScheduled`}
            value={this.state.isScheduled}
            onChange={() => {
              this.setState({ isScheduled: !this.state.isScheduled })
            }}
          />
        </div>
        {this.state.isScheduled && (
          <div className="dateTimeSelect">
            <SingleDatePicker
              enableToday
              name="scheduleDate"
              key="scheduleDate"
              label={getMessage('Select Date')}
              value={this.state.scheduleDate}
              onChange={v => this.setState({ scheduleDate: v })}
            />
            <TimePicker
              name="scheduleTime"
              key="scheduleTime"
              label={getMessage('Select Time')}
              placeholder={getMessage('offer.time.placeholder')}
              value={this.state.scheduleTime}
              onChange={v => this.setState({ scheduleTime: v })}
              showSecond={false}
              minuteStep={15}
            />
          </div>
        )}
      </div>
    )
  }
}
