import React, { Fragment } from 'react'
import AddRules from './AddRules'
import AddConditions from './AddConditions'
import { getMessage } from '../../../../lib/translator'

const Mechanics = (props) => {
  const { _this, method } = props

  return (
    <Fragment>
      <div className="single-column-field">
        <div className="tab-header">
          {getMessage('luckydraw.mechanicsTab.header')}
        </div>
        <div className="normal-text mb-1">
          {getMessage('luckydraw.mechanicsTab.text')}
        </div>
      </div>
      <AddRules _this={_this} method={method} />
      <AddConditions _this={_this} method={method} />
    </Fragment>
  )
}

export default Mechanics
