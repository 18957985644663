import React from 'react'
import { Cell, Row } from '../../../../components/Table'
import leftArrow from '../../../../components/Pagination/icon-left-arrow.svg'
import rightArrow from '../../../../components/Pagination/icon-right-arrow.svg'

function RenderFirstGrid(props){
  const handleDay = (direction) => {
    const slopBox = document.getElementById('slotScroll')
    const slopItemWidth = slopBox.scrollWidth / (props.data?.length || 1)
    const scrollLeft = slopBox.scrollLeft

    if (slopBox.scrollTo) {
      direction === 'right' ? slopBox.scrollTo(scrollLeft + slopItemWidth, 0) : slopBox.scrollTo(scrollLeft - slopItemWidth, 0)
    }
  }

  return(
    <Row className='headerColumn'>
      <Cell>
        <div className='navigation'>
          <a
            className='navigation-button left'
            onClick={() => handleDay('left')}
          >
            <img className='navigation-icon' src={leftArrow} alt='Previous' />
          </a>
          <span>Days</span>
          <a
            className='navigation-button right'
            onClick={() => handleDay('right')}
          >
            <img className='navigation-icon' src={rightArrow} alt='Next' />
          </a>
        </div>
      </Cell>
    </Row>
  )
}

export {
  RenderFirstGrid
}
