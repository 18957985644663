import React from 'react'
import {
  BaseForm,
  Input,
  Select,
  SingleDatePicker,
} from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import { getSaleOrderStatusOptions } from '../../../../../lib/commonlyused'

// this is also called vendorType
const deliveryOrderTypes = [
  { text: 'Fairprice', value: 'NULL' },
  { text: 'Marketplace', value: 'NOT NULL' },
  { text: 'All', value: 'all' }
]

export default class SaleOrdersFilterForm extends BaseForm {

  render() {
    const { Form } = this.components
    const { SubmitButton, ClearButton } = this.buttons
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('saleOrder.filters.saleOrder.label')}
            placeholder={getMessage('saleOrder.filters.saleOrder.placeholder')}
            name="so-referenceNumber"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['referenceNumber'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.status.heading')}
            placeholder={getMessage('order.filters.status.placeholder')}
            name="so-status"
            options={getSaleOrderStatusOptions()}
            {...this.generateStateMappers({
              stateKeys: ['status'],
              loseEmphasisOnFill: true,
            })}
          />

          <SingleDatePicker
            label={getMessage('order.filters.placedOn')}
            placeholder={getMessage('order.filter.placedOn.placeholder')}
            name="so-placed-on"
            isOutsideRange
            {...this.generateStateMappers({
              stateKeys: ['placedOn'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.deliveryOrderType')}
            placeholder={getMessage('order.filters.deliveryOrderType.placeholder')}
            name="so-delivery-order-type"
            options={deliveryOrderTypes}
            {...this.generateStateMappers({
              stateKeys: ['vendorCode'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('order.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('order.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
