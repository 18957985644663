import React from 'react'
import Select from '../Select'
import { isInclusivelyBeforeDay, isInclusivelyAfterDay } from 'react-dates'
import moment from 'moment'

import { Input, DateRangePicker } from '../index'

import './style.css'

import {
  PAYMENT_SEARCH_CATEGORIES,
  PAYMENT_SEARCH_CATEGORIES_ADV,
  PAYMENT_REF_PREFIX,
  PAYMENT_REF_OTHER_PREFIX,
} from '../../../../pages/customer-support/constants'

const { PAYMENT_REF, CUSTOMER_EMAIL, CUSTOMER_PHONE } =
  PAYMENT_SEARCH_CATEGORIES

const { POS_ID, CASHIER_ID, STORE_TXN, TRANSACTION_DATE } =
  PAYMENT_SEARCH_CATEGORIES_ADV

const placeHolders = {
  [PAYMENT_REF]: 'A-01234567 or B-12345678',
  [CUSTOMER_EMAIL]: 'Enter customer email',
  [CUSTOMER_PHONE]: 'Enter 8 digit phone number',
  [POS_ID]: 'Enter POS ID number',
  [CASHIER_ID]: 'Enter Cashier ID number',
  [STORE_TXN]: 'Enter txn number from the receipt',
}

const inputTypes = {
  [PAYMENT_REF]: 'text',
  [CUSTOMER_EMAIL]: 'email',
  [CUSTOMER_PHONE]: 'text',
  [POS_ID]: 'text',
  [CASHIER_ID]: 'text',
  [STORE_TXN]: 'text',
}

const otherAttributes = {
  [PAYMENT_REF]: {
    elementAttr: {
      pattern: '[A-Z]-\\d{8}',
    },
    validationStrings: {
      patternMismatch: 'Please enter valid payment reference.',
    },
  },
  [CUSTOMER_EMAIL]: {
    validationStrings: {
      typeMismatch: 'Please enter valid email.',
    },
  },
  [CUSTOMER_PHONE]: {
    elementAttr: {
      pattern: '\\d{8}',
    },
    validationStrings: {
      patternMismatch: 'Please enter 8 digit number only.',
    },
  },
  [CASHIER_ID]: {
    elementAttr: {
      pattern: '\\d+',
    },
    validationStrings: {
      patternMismatch: 'Please enter number only.',
    },
  },
  [STORE_TXN]: {
    elementAttr: {
      pattern: '^\\d+$',
    },
    validationStrings: {
      patternMismatch: 'Please enter number only.',
    },
  },
}

export const paymentSearchIsDateOutOfRange = (day) => {
  return (
    isInclusivelyBeforeDay(day, moment('2020-12-31')) ||
    isInclusivelyAfterDay(day, moment().add(1, 'day'))
  )
}

export default class PaymentSearch extends React.Component {
  constructor(props) {
    super(props)
    this.selectOptions = [
      {
        text: 'Payment ref.',
        value: PAYMENT_REF,
      },
      {
        text: 'Customer email',
        value: CUSTOMER_EMAIL,
      },
      {
        text: 'Customer phone',
        value: CUSTOMER_PHONE,
      },
    ]
    this.prefixOptions = [
      {
        text: PAYMENT_REF_OTHER_PREFIX,
        value: PAYMENT_REF_OTHER_PREFIX,
      },
      {
        text: PAYMENT_REF_PREFIX,
        value: PAYMENT_REF_PREFIX,
      },
    ]
    this.selectOptionsAdvanced = [
      {
        text: 'POS ID',
        value: POS_ID,
      },
      {
        text: 'Cashier ID',
        value: CASHIER_ID,
      },
      {
        text: 'Store Txn',
        value: STORE_TXN,
      },
      {
        text: 'Transaction date',
        value: TRANSACTION_DATE,
      },
    ]

    this.handleCategoryChange = this.handleCategoryChange.bind(this)
  }

  handleCategoryChange(value) {
    const updatedValidations = { ...this.props.BaseForm.state.validations }

    if (value === TRANSACTION_DATE) {
      delete updatedValidations.paymentSearchInputAdvanced
      this.props.BaseForm.setState({
        // need to clear validation of advanced search input
        // as its already there in validation obj
        validations: updatedValidations,
      })
    }
  }

  render() {
    const { readOnly, BaseForm, mode } = this.props
    const { values } = BaseForm.state
    const { paymentSearchSelect, paymentSearchSelectAdvanced } = values

    const isAdvancedMode = mode === 'advanced'

    const selectionKeyTarget = isAdvancedMode
      ? paymentSearchSelectAdvanced
      : paymentSearchSelect

    const type = inputTypes[selectionKeyTarget]
    const placeHolder = placeHolders[selectionKeyTarget]

    const otherInputAttributes = otherAttributes[selectionKeyTarget] || {}
    const { elementAttr = {}, validationStrings = {} } = otherInputAttributes

    const paymentInputName = isAdvancedMode
      ? 'paymentSearchInputAdvanced'
      : 'paymentSearchInput'
    const paymentSelectName = isAdvancedMode
      ? 'paymentSearchSelectAdvanced'
      : 'paymentSearchSelect'

    const classNamePrefix = isAdvancedMode ? '-advanced' : ''
    const selectOptions = isAdvancedMode
      ? this.selectOptionsAdvanced
      : this.selectOptions

    const showDateRange = selectionKeyTarget === TRANSACTION_DATE
    const showInput = !showDateRange

    return (
      <div
        className={`Payment-search ${
          isAdvancedMode ? 'Payment-search-advanced' : ''
        }`}
      >
        <React.Fragment>
          <Select
            className={`payment-search-select${classNamePrefix}`}
            name={paymentSelectName}
            testid={paymentSelectName}
            placeholder={placeHolder}
            options={selectOptions}
            disabled={readOnly}
            {...BaseForm.generateStateMappers({
              stateKeys: [paymentSelectName],
              onChange: this.handleCategoryChange,
            })}
          />

          {showInput && (
            <Input
              id={paymentInputName}
              name={paymentInputName}
              testid={paymentInputName}
              type={type}
              placeholder={placeHolder}
              required={true}
              {...elementAttr}
              {...BaseForm.generateStateMappers({
                stateKeys: [paymentInputName],
                loseEmphasisOnFill: true,
              })}
              validationStrings={validationStrings}
            />
          )}
          {showDateRange && (
            <DateRangePicker
              displayFormat="YYYY-MM-DD"
              startDatePlaceholder="State Date"
              endDatePlaceholder="End Date"
              minimumNights={0}
              {...BaseForm.generateStateMappers({
                stateKeys: ['dateRange'],
                loseEmphasisOnFill: true,
              })}
              isOutsideRange={paymentSearchIsDateOutOfRange}
            />
          )}
        </React.Fragment>
      </div>
    )
  }
}
