
import React, { Component } from 'react'
import { getMessage } from '../../../lib/translator'
import { formatTime } from '../../../lib/datetime'
import './assetPreview.css'

/*
Declaring the assetData obj structure.
[{
  "assetOperatingCalendarList": {
    "eventDay": "MONDAY",
    "eventType": "DAY_OF_THE_WEEK",
    "startTime": "",
    "endTime": "",
    "status": "ACTIVE"
  }
}]
*/
export default class AssetPreview extends Component {
  render() {
    let assetData = {
      "id": "",
      "assetTypeName": "",
      "buAssetId": "",
      "ownerBu": "",
      "assetName": "",
      "buStoreLabel": "",
      "slotDuration": "",
      "numberOfSlotsPerPlaylist": "",
      "quantity": "",
      "buAssetLabel1": "",
      "buAssetLabel2": "",
      "buAssetLabel3": "",
      "assetOperatingCalendarList": [],
      campaignList: []
    }
    assetData = Object.assign(assetData, this.props.assetData ? this.props.assetData : {})

    return (
      <div className='asset-summary-wrap'>
        <div className='asset-summary-inner'>
          <div className='asset-summary-item description'>
            <div className='text-muted'>{getMessage('media.assetManagement.header.id')}: <span className='black'>{assetData.buAssetId}</span></div>
          </div>
          <div className='asset-summary-item description'>
            <div className='text-muted'>{getMessage('media.assetManagement.header.name')}: <span className='black'>{assetData.assetName}</span></div>
          </div>
          <div className='asset-summary-item'>
            <div className='text-muted'>{getMessage('media.assetManagement.header.store')}: <span className='black'>{assetData.buStoreLabel}</span></div>
          </div>
          <div className='asset-summary-item'>
            <div className='text-muted'>{getMessage('media.assetManagement.header.ownerBu')}: <span className='black'>{assetData.ownerBu}</span></div>
          </div>
          <div className='asset-summary-item'>
            <div className='text-muted'>{getMessage('media.assetManagement.header.assetType')}: <span className='black'>{assetData.assetTypeName}</span></div>
          </div>
          <div className='asset-summary-item'>
            <div className='text-muted'>{getMessage('media.assetManagement.header.crmAssetFlag')}: <span className='black'>{assetData.crmAssetFlag}</span></div>
          </div>
          <div className='asset-summary-item'>
            <div className='text-muted'>{getMessage('media.assetManagement.header.quantity')}: <span className='black'>{assetData.quantity}</span></div>
          </div>
          <div className='asset-summary-item'>
            <div className='text-muted'>{getMessage('media.assetDetail.slotDuration')}: <span className='black'>{assetData.slotDuration}</span></div>
          </div>
          <div className='asset-summary-item'>
            <div className='text-muted'>{getMessage('media.assetDetail.maxSlots')}: <span className='black'>{assetData.numberOfSlotsPerPlaylist}</span></div>
          </div>
          <div className='asset-summary-item description'>
            <div className='text-muted'>{getMessage('media.assetDetail.operatingHour.h3')}:
              <div className='operatingHour black'>
                {assetData.assetOperatingCalendarList.length === 0 ? (
                  <span>24h</span>) : (
                  assetData.assetOperatingCalendarList.map((item, index) => (
                    <div className='dateBox' key={index}>
                      <div className='asset-time-title'>{item.eventDay}</div>
                      <div className={`asset-time-status ${item.status === "INACTIVE" ?'inactive':''}`}>{item.status}</div>
                      {item.startTime && item.status === 'ACTIVE' ? (
                        <div className='asset-time-time'>{formatTime(item.startTime)}~{formatTime(item.endTime)}</div>
                      ) : (
                        <div>&nbsp;</div>
                      )}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className='asset-summary-item description'>
            <div className='text-muted'>{getMessage('media.assetDetail.campaigns')}:
              <div className='black'>
                {assetData.campaignList.join(', ')}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}