import React from 'react'
import { Searchable } from '../../Inputs'
import API from '../../../../lib/api'
import propTypes from 'prop-types'
export default function CustomerTagSearch(props) {
  const newProps = Object.assign({}, props, {
    onChange: event => {
      if (!props.customerId) {
        return
      }
      if (props.tags.length < event.length) {
        // Tag Added
        const resultingArr = event.filter(item => props.tags.indexOf(item) < 0)
        if (resultingArr.length > 0 && resultingArr[0].id) {
          const postApi = new API({
            url: `/ef-customer-core/profile/${props.customerId}/tags/${resultingArr[0].id}`,
          })
          postApi.post().then(() => {
            props.onChange(event)
          })
        }
      } else {
        // Tag Removed
        const resultingArr = props.tags.filter(item => event.indexOf(item) < 0)
        if (resultingArr.length > 0 && resultingArr[0].id) {
          const api = new API({
            url: `/ef-customer-core/profile/${props.customerId}/tags/${resultingArr[0].id}`,
          })
          api.delete().then(() => {
            props.onChange(event)
          })
        }
      }
    },
    searchUrl: '/ef-customer-core/tags',
    valueKey: 'id',
    nameKey: 'name',
    searchKey: 'name',
    transformRequest: (searchText, paramsDefault) => {
      const params = Object.assign({}, paramsDefault)
      params.name = `${params.name}`
      params.activeOnly = true
      return params
    },
    transformResponse: response => {
      return response.data.tags || []
    },
  })
  return <Searchable {...newProps} />
}
CustomerTagSearch.propTypes = {
  customerId: propTypes.number.isRequired,
}
