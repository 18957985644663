import React from 'react'
import { Upload, Input, VALIDATION_TYPES } from 'components/Form'
import { getMessage } from 'lib/translator'
import deleteIcon from './delete.svg'

const TilesForm = ({ position, items, tiles, handleTiles, generateStateMappers }) => {
  return (
    <div className={`grid-${items.length}-col mb-2`}>
      {items.map((item, index) => {
        return tiles[item]?.target ? (
          <div className="tile" key={index}>
            <div className="tile-label">
              <span>{getMessage(`app.${item}.label`)}</span>
              <button type="button" onClick={() => handleTiles(item, position, 'delete')}>
                <img src={deleteIcon} alt="delete" />
              </button>
            </div>
            <Upload
              data-testid={`${item}-url`}
              className={`${item}-url`}
              placeholder={getMessage('app.tile.image.placeholder')}
              {...generateStateMappers({
                stateKeys: [position, item, 'image', 'url'],
                loseEmphasisOnFill: true,
              })}
              required
            />
            <Input
              label={getMessage('app.tile.title.label')}
              placeholder={getMessage('app.tile.name.placeholder')}
              type="text"
              testid={`${item}-name`}
              className={`${item}-name`}
              {...generateStateMappers({
                stateKeys: [position, item, 'title'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              required
            />
            <Input
              label={getMessage('app.tile.deeplink.label')}
              placeholder={getMessage('app.tile.deeplink.placeholder')}
              type="text"
              testid={`${item}-deepLink`}
              className={`${item}-deepLink`}
              {...generateStateMappers({
                stateKeys: [position, item, 'target', 'deepLink'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              required
            />
            <Input
              label={getMessage('app.tile.service.label')}
              placeholder={getMessage('app.tile.service.placeholder')}
              type="text"
              testid={`${item}-service`}
              className={`${item}-service`}
              {...generateStateMappers({
                stateKeys: [position, item, 'service'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              required
            />
          </div>
        ) : (
          <button
            className="add-tile button"
            key={index}
            onClick={() => handleTiles(item, position, 'add')}
          >
            {getMessage('app.tile.addTile')}
          </button>
        )
      })}
    </div>
  )
}

export default TilesForm
