import React from 'react'

import {
  BaseForm,
  Select,
  Input,
  Upload,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import { getMessage } from 'lib/translator'
import Loader from '../../../../components/Loader'
import {
  Rank,
  Status,
  Type,
  CHANNEL_OPTIONS,
  DatePickerField,
  TimePickerField,
  FormButtons,
  ErrorScreen,
  convertToISOString,
} from '../../../../components/Rewards/utils'
import {
  getApiDetails,
  handleApis,
} from '../../../../components/Rewards/services'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'

class CarouselForm extends BaseForm {
  async componentDidMount() {
    if (this.props.method === 'edit') {
      getApiDetails(this, 'carousels')
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    const {
      id,
      link,
      displayChannel,
      title,
      subliner,
      status,
      type,
      rank,
      imgUrls,
      date,
    } = this.state.values

    const startDate = convertToISOString(date?.startingDate, date?.startingTime)
    const endDate = convertToISOString(date?.endingDate, date?.endingTime)

    const statusValue = status || 1

    if (isValid) {
      const mapData = {
        link,
        displayChannel,
        title,
        subliner,
        startDate,
        endDate,
        status: Number(statusValue),
        type,
        rank,
        imgUrls,
      }
      handleApis(mapData, `/rms/carousels`, this, id)
    }
  }

  render() {
    const { displayChannel, error, loading } = {
      ...this.state.values,
    }
    const { Form } = this.components
    const ALL = ['all']

    return (
      <div className="rewards container">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="row">
              <div className="col-12">
                <Input
                  label={getMessage('rewards.carousel.title')}
                  placeholder={getMessage('rewards.carousel.title')}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['title'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Input
                  label={getMessage('rewards.carousel.subliner')}
                  placeholder={getMessage('rewards.carousel.subliner')}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['subliner'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  label={getMessage('rewards.carousel.pageUrl')}
                  placeholder={getMessage('rewards.carousel.pageUrl')}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['link'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
              <div className="col-6">
                <Select
                  label={getMessage('rewards.displayChannel')}
                  testid="deviceType"
                  placeholder={getMessage('rewards.displayChannel')}
                  options={CHANNEL_OPTIONS}
                  {...this.generateStateMappers({
                    stateKeys: ['displayChannel'],
                  })}
                  required
                />
              </div>
            </div>
            <div className="row">
              <DatePickerField label="startDate" _this={this} />
              <TimePickerField label="startTime" _this={this} />
            </div>
            <div className="row">
              <DatePickerField label="endDate" _this={this} />
              <TimePickerField label="endTime" _this={this} />
            </div>
            <Rank _this={this} />
            <div className="row">
              <Status _this={this} />
              <Type _this={this} type="carousel" />
            </div>
            <div className="row mt">
              <div className="col-6">
                <Upload
                  name="carouselBanner"
                  placeholder={getMessage('rewards.carousel.webBanner')}
                  label={getMessage('rewards.carousel.webBanner')}
                  {...this.generateStateMappers({
                    stateKeys: ['imgUrls', 'carouselBanner'],
                    loseEmphasisOnFill: true,
                  })}
                  {...([...ALL, 'web'].includes(displayChannel) && {
                    required: 'required',
                  })}
                />
              </div>
              <div className="col-6">
                <Upload
                  name="carouselBanner"
                  placeholder={getMessage('rewards.carousel.appBanner')}
                  label={getMessage('rewards.carousel.appBanner')}
                  {...this.generateStateMappers({
                    stateKeys: ['imgUrls', 'appBanner'],
                    loseEmphasisOnFill: true,
                  })}
                  {...([...ALL, 'app'].includes(displayChannel) && {
                    required: 'required',
                  })}
                />
              </div>
            </div>
            {this.props.method === 'edit' && (
              <DisplayCreatorEditorInfo {...this.state.values} />
            )}
            <FormButtons buttons={this.buttons} type="carousels" />
            {error && <ErrorScreen error={error} />}
          </Form>
        )}
      </div>
    )
  }
}
export default CarouselForm
