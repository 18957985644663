import React from 'react';
import './style.scss';

function BulkUploadPLSResultCard({ total, success, failure, errors }) {
  return (
    <div className='bulk-locations-result-card'>
      <h4>Bulk Locations Update Result</h4>
      <div className='field-display-line'>
        <span>Result:</span>
        <span>
          {
            total === success
              ? `All ${total} locations updated successfully`
              : `${success} of ${total} locations updated successfully`
          }
        </span>
      </div>
      <br />
      {
        failure > 0 &&
          <mark style={{width: 'fit-content', background: '#f8d7da'}}>
            Errors encountered while updating {failure} locations below:
          </mark>
      }
      <table className='result-table'>
        <thead>
          <tr>
            <th>Total</th>
            <th>Success</th>
            <th>Failure</th>
            <th>Errors</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='center-aligned'>{total}</td>
            <td className='center-aligned'>{success}</td>
            <td className='center-aligned'>{failure}</td>
            <td>{errors.map((error, index) => (<p key={index}>{error}</p>))}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default BulkUploadPLSResultCard;
