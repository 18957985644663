import React from 'react'
import { Input, StoreSearch, Select } from '../../../../components/Form'
import { TABLE_ACTIONS } from '../../../../containers/ListingPage'
import { BUList } from '../../MediaAssetManagement/businessUnitList'
import { formatAssetOptions } from '../../MediaAssetManagement/AssetTypeList'
import { getMessage } from '../../../../lib/translator'

export default function AssetFilters(props) {
  const { _this } = props
  return (
    <div className="section-wrap">
      <div className="section-row">
        <Select
          className="section-row-input"
          label={getMessage('media.packageDetail.business.ownerBu')}
          placeholder={getMessage(
            'media.packageDetail.business.ownerBu.placeholder'
          )}
          options={BUList}
          name="ownerBu"
          {..._this.generateStateMappers({
            stateKeys: ['ownerBu'],
            loseEmphasisOnFill: true,
            onChange: (value) => {
              _this.setState({
                ownerBu: value,
              })
              _this.actionFun(TABLE_ACTIONS.SET_API_PARAMS, { bu: value })
            },
          })}
        />
        <Select
          className="section-row-input"
          label={getMessage('media.storeList.filters.assettype')}
          placeholder={getMessage(
            'media.storeList.filters.assettype.placeholder'
          )}
          name="assetType"
          options={formatAssetOptions(
            _this.state.assetTypeList,
            _this.state.ownerBu
          )}
          {..._this.generateStateMappers({
            stateKeys: ['assetType'],
            loseEmphasisOnFill: true,
            onChange: (value) => {
              _this.actionFun(TABLE_ACTIONS.SET_API_PARAMS, {
                assetType: value,
              })
            },
          })}
        />
      </div>
      <div className="section-row">
        <Input
          label={getMessage('media.assetManagement.filters.name')}
          placeholder={getMessage(
            'media.assetManagement.filters.name.placeholder'
          )}
          name="assetName"
          className="section-row-input"
          type="text"
          {..._this.generateStateMappers({
            stateKeys: ['assetName'],
            loseEmphasisOnFill: true,
          })}
          onKeyPress={(e) => {
            if (e.charCode === 13) {
              e.preventDefault()
              e.target.blur()
            }
          }}
          onBlur={() => {
            _this.actionFun(TABLE_ACTIONS.SET_API_PARAMS, {
              assetName: _this.state.values.assetName,
            })
          }}
        />
        <StoreSearch
          className="section-row-input"
          label={getMessage('media.storeList.filters.name')}
          placeholder={getMessage('media.storeList.filters.store.placeholder')}
          name="store"
          {..._this.generateStateMappers({
            stateKeys: ['store'],
            onChange: (value) => {
              _this.actionFun(TABLE_ACTIONS.SET_API_PARAMS, {
                storeLabel: value.storeLabel,
              })
            },
          })}
        />
      </div>
    </div>
  )
}
