import React, { Fragment } from 'react'
import { getOnlyDate, getOnlyTime } from '../../lib/datetime'
import { getMessage } from '../../lib/translator'
import { toSentenceCase } from '../../lib/commonlyused'
import Table from './Table'
import './style.css'
import {
  cancelReasons,
  rejectReasons,
  refundReasons,
} from '../../pages/qc/Orders/Details/OrderSummary/Reasons'

const MSG_QC_LOG_PAYMENT_OF = getMessage('qc.log.payment.of')
const MSG_QC_LOG_RIDER = getMessage('qc.log.rider')
const MSG_QC_LOG_ORDER_ACTIONBY = getMessage('qc.log.order.actionby')

const paymentState = {
  AUTHORIZATION_PENDING: 'authorization pending',
  AUTHORIZED: 'authorized',
  AUTHORIZATION_FAILED: 'authorization failed',
  AUTHORIZATION_VOID: 'authorization void',
  CAPTURE_FAILED: 'capture failed',
  CAPTURED: 'captured',
  REFUNDED: 'refunded',
  PARTIAL_REFUND: 'partial refund',
  VOIDED: 'voided',
}

const refundState = {
  PENDING: 'refund pending',
  PARTIAL_REFUNDED: 'partially refunded',
  REFUND_PENDING: 'refund pending',
  REFUNDED: 'refunded',
  PARTIAL_REFUND: 'partial refund',
}

const handleMessage = (message) => {
  return message.toLowerCase().split('_').join(' ')
}
const suborderFrom = getMessage('qc.log.suborder.from')

const renderOrderPaymentCapturedFlow = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_PAYMENT_OF}
          {item.details.formattedTotal}{' '}
          {item.details.paymentState && paymentState[item.details.paymentState]}
          .
        </span>
      </div>
    </Fragment>
  )
}

const renderOrderCancelledFlow = () => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {getMessage('qc.log.master.order.cancelled')}
        </span>
      </div>
    </Fragment>
  )
}
// Sub-order from Mama’s Prawn Noodle auto-rejected.
const renderSubOrderRejectedFlow = (item) => {
  const rejectResDetails =
    rejectReasons.find((reason) => reason.value === item.details.reason) || {}
  return (
    <Fragment>
      <div className="placed-from">
        {item.details.reason === 'AUTO_REJECTED' ? (
          <span className="bold">
            {suborderFrom}
            {item.details.name}
            {getMessage('qc.log.suborder.autorejected')}
          </span>
        ) : (
          <span className="bold">
            {suborderFrom}
            {item.details.name}
            {getMessage('qc.log.suborder.rejected.by')} {item.details.by}.
          </span>
        )}
      </div>
      {item.details.reason && item.details.reason !== 'AUTO_REJECTED' && (
        <div className="placed-from">
          <span>
            Rejection reason:{' '}
            {item.details.reason &&
              (Object.keys(rejectResDetails).length === 0
                ? toSentenceCase(handleMessage(item.details.reason))
                : toSentenceCase(rejectResDetails.text))}
          </span>
        </div>
      )}
    </Fragment>
  )
}

const renderOrderCompletedFlow = () => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {getMessage('qc.log.master.order.completed')}
        </span>
      </div>
    </Fragment>
  )
}
const renderRequestRider = () => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">{getMessage('qc.log.request.rider.order')}</span>
      </div>
    </Fragment>
  )
}

const renderAcceptRider = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_RIDER} {item.details.name}{' '}
          {getMessage('qc.log.accepted.rider.order')}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {getMessage('qc.log.estimated.delivery.time')}:{' '}
          {getOnlyTime(item.createdAt)} - {getOnlyTime(item.createdAt)}
        </span>
      </div>
    </Fragment>
  )
}
const renderRiderReassignment = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">{getMessage('qc.log.new.rider.assigned')}</span>
      </div>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_RIDER} {item.details.name}{' '}
          {getMessage('qc.log.accepted.rider.order')}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {getMessage('qc.log.estimated.delivery.time')}:{' '}
          {getOnlyTime(item.createdAt)} - {getOnlyTime(item.createdAt)}
        </span>
      </div>
    </Fragment>
  )
}
const renderCancelDelivery = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">{getMessage('qc.log.delivery.cancelled')}</span>
      </div>
      <div className="placed-from">
        <span>
          {getMessage('qc.log.cancellation.reason')}: {item.details.reason}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {MSG_QC_LOG_ORDER_ACTIONBY}: {item.details.email}
        </span>
      </div>
    </Fragment>
  )
}
const renderSuborderCancelledNotDelivered = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {getMessage('qc.log.suborder.from')} {`"${item.details.name}"`}
          {getMessage('qc.log.is.cancelled')}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {getMessage('qc.log.cancellation.reason')}: {item.details.reason}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {MSG_QC_LOG_ORDER_ACTIONBY}: {item.details.email}
        </span>
      </div>
    </Fragment>
  )
}
const renderRevisedDeliveryTime = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {getMessage('qc.log.revised.delivery.time')}:{' '}
          {getOnlyTime(item.createdAt)} - {getOnlyTime(item.createdAt)}
        </span>
      </div>
    </Fragment>
  )
}
const renderNearByRider = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_RIDER} {item.details.name}{' '}
          {getMessage('qc.log.nearby.rider')}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {MSG_QC_LOG_ORDER_ACTIONBY}: {item.details.name}
        </span>
      </div>
    </Fragment>
  )
}
const renderArrivedRider = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_RIDER} {item.details.name}{' '}
          {getMessage('qc.log.arrived.rider')}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {MSG_QC_LOG_ORDER_ACTIONBY}: {item.details.name}
        </span>
      </div>
    </Fragment>
  )
}
const renderPickUpRider = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_RIDER} {item.details.name}{' '}
          {getMessage('qc.log.pickup.rider.order')}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {MSG_QC_LOG_ORDER_ACTIONBY}: {item.details.name}
        </span>
      </div>
    </Fragment>
  )
}
const renderNearByDestinationRider = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_RIDER} {item.details.name}{' '}
          {getMessage('qc.log.nearby.rider.destination')}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {MSG_QC_LOG_ORDER_ACTIONBY}: {item.details.name}
        </span>
      </div>
    </Fragment>
  )
}
const renderDeliveredRiderOrder = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_RIDER} {item.details.name}{' '}
          {getMessage('qc.log.delivered.rider.order')}
        </span>
      </div>
      <div className="placed-from">
        <span>
          {MSG_QC_LOG_ORDER_ACTIONBY}: {item.details.name}
        </span>
      </div>
    </Fragment>
  )
}
const renderAllSubordersCancel = () => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {getMessage('qc.log.all.suborders.auto.cancel')}
        </span>
      </div>
    </Fragment>
  )
}
const renderAutoDeliveryCancel = () => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {getMessage('qc.log.auto.delivery.cancel')}
        </span>
      </div>
    </Fragment>
  )
}
const renderSubOrderCancelledFlow = (item) => {
  const cancelResDetails =
    cancelReasons.find((reason) => reason.value === item.details.reason) || {}
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {suborderFrom}
          {item.details.name}
          {item.details.by === 'System' ? (
            <Fragment>
              {getMessage('qc.log.suborder.cancelled.by.system')}.
            </Fragment>
          ) : (
            <Fragment>
              {getMessage('qc.log.suborder.cancelled.by')} {item.details.by}.
            </Fragment>
          )}
        </span>
      </div>
      {item.details.reason && (
        <div className="placed-from">
          <span>
            Cancellation reason:{' '}
            {item.details.reason &&
              (Object.keys(cancelResDetails).length === 0
                ? toSentenceCase(handleMessage(item.details.reason))
                : toSentenceCase(cancelResDetails.text))}
          </span>
        </div>
      )}
    </Fragment>
  )
}

const renderSubOrderAcceptedFlow = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {getMessage('qc.log.order.accepted')}
          {`"${item.details.name}" `}
          {getMessage('qc.log.order.accepted.state')}
          {item.details.subOrderState &&
            handleMessage(item.details.subOrderState)}
          .
        </span>
      </div>
      {item.details.pickupAt && (
        <div className="placed-from">
          <span>
            {getMessage('qc.log.order.pickuptime')}:{' '}
            {getOnlyTime(item.details.pickupAt)}
          </span>
        </div>
      )}
      {item.details.name && (
        <div className="placed-from">
          <span>
            {MSG_QC_LOG_ORDER_ACTIONBY}: {item.details.name}
          </span>
        </div>
      )}
    </Fragment>
  )
}
const renderSubOrderReadyForCollectionFlow = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {getMessage('qc.log.order.ready.start')}
          {`"${item.details.name}"`} {getMessage('qc.log.order.ready.end')}.
        </span>
      </div>
      {item.details.pickupAt && (
        <div className="placed-from">
          <span>Pick-up time: {getOnlyTime(item.details.pickupAt)}</span>
        </div>
      )}
      {item.details.name && (
        <div className="placed-from">
          <span>
            {MSG_QC_LOG_ORDER_ACTIONBY}: {item.details.name}
          </span>
        </div>
      )}
    </Fragment>
  )
}

const renderOrderCreatedFlow = (item) => {
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_PAYMENT_OF}
          {item.details.formattedTotal}{' '}
          {item.details.paymentState && paymentState[item.details.paymentState]}
          .
        </span>
      </div>
      {item.details.paymentState === 'AUTHORIZED' && (
        <div className="placed-from">
          <span className="bold">
            {getMessage('qc.log.master.order.created')}
          </span>
        </div>
      )}
    </Fragment>
  )
}

const renderOrderPaymentRefundFlow = (item) => {
  const refundResDetails =
    refundReasons.find((reason) => reason.value === item.details.reason) || {}
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {MSG_QC_LOG_PAYMENT_OF}
          {item.details.formattedTotal}{' '}
          {item.details.refundState && refundState[item.details.refundState]}{' '}
          {item.details.by && `by ${item.details.by}.`}
        </span>
      </div>
      <div className="placed-from">
        <span>
          Refund reason:{' '}
          {item.details.reason &&
            (Object.keys(refundResDetails).length === 0
              ? toSentenceCase(handleMessage(item.details.reason))
              : toSentenceCase(refundResDetails.text))}
        </span>
      </div>
    </Fragment>
  )
}

const renderOrderPaymentFlow = (item) => {
  const refundResDetails =
    refundReasons.find((reason) => reason.value === item.details.reason) || {}
  const voidedPayment =
    item.details.paymentState && item.details.paymentState === 'VOIDED'
      ? getMessage('qc.log.linkpoints.payment.of')
      : MSG_QC_LOG_PAYMENT_OF
  return (
    <Fragment>
      <div className="placed-from">
        <span className="bold">
          {item.details.paymentState &&
          item.details.paymentState === 'AUTHORIZATION_VOID'
            ? getMessage('qc.log.card.payment.of')
            : voidedPayment}
          {item.details.formattedTotal}{' '}
          {item.details.paymentState && paymentState[item.details.paymentState]}{' '}
          {item.details.by && `by ${item.details.by}.`}
        </span>
      </div>
      {item.details.paymentState === 'REFUNDED' && (
        <div className="placed-from">
          <span>
            Refund reason:{' '}
            {item.details.reason && Object.keys(refundResDetails).length === 0
              ? toSentenceCase(handleMessage(item.details.reason))
              : toSentenceCase(refundResDetails.text)}
          </span>
        </div>
      )}
    </Fragment>
  )
}

const LogItem = (props) => {
  const item = props.data || {}
  const action = props.data.action
  return (
    !!Object.keys(item).length && (
      <div className="log-box" key={`log-${props.index}`}>
        <div className="log-date-time">
          <div className="log-date">{getOnlyDate(props.data.createdAt)},</div>
          <div className="log-time">{getOnlyTime(props.data.createdAt)}</div>
        </div>
        <div className="log-details">
          <div className="log-details-wrapper">
            {props.data.action === 'ORDER_RETURN' && (
              <div className="bold order-return">
                {getMessage('order.log.return')}
              </div>
            )}
            {props.data.action === 'STATUS_CHANGE' &&
              props.data.details.status &&
              props.data.details.status.newValue === 'COMPLETED' &&
              props.data.details.leftAtDoorStep && (
                <div className="bold">
                  {getMessage('order.log.leftAtDoorStep')}
                </div>
              )}
            {(action === 'ORDER_PAYMENT_REFUNDED' ||
              action === 'ORDER_PAYMENT_FAILED' ||
              action === 'ORDER_PAYMENT_VOIDED') &&
              renderOrderPaymentFlow(item)}
            {(action === 'ORDER_REFUND_PENDING' ||
              action === 'ORDER_REFUNDED') &&
              renderOrderPaymentRefundFlow(item)}
            {action === 'ORDER_CREATED' && renderOrderCreatedFlow(item)}
            {action === 'SUBORDER_ACCEPTED' && renderSubOrderAcceptedFlow(item)}
            {action === 'SUBORDER_CANCELLED' &&
              renderSubOrderCancelledFlow(item)}
            {action === 'SUBORDER_REJECTED' && renderSubOrderRejectedFlow(item)}
            {action === 'ORDER_CANCELLED' && renderOrderCancelledFlow()}
            {action === 'SUBORDER_COLLECTED' &&
              renderSubOrderAcceptedFlow(item)}
            {action === 'ORDER_PAYMENT_CAPTURE_FAILED' &&
              renderOrderPaymentCapturedFlow(item)}
            {action === 'ORDER_COMPLETED' && renderOrderCompletedFlow()}
            {action === 'ORDER_PAYMENT_CAPTURED' &&
              renderOrderPaymentCapturedFlow(item)}
            {action === 'SUBORDER_READY_FOR_COLLECTION' &&
              renderSubOrderReadyForCollectionFlow(item)}
            {action === 'REQUEST_RIDER' && renderRequestRider()}
            {action === 'ACCEPT_RIDER' && renderAcceptRider(item)}
            {action === 'NEAR_BY_RIDER' && renderNearByRider(item)}
            {action === 'RIDER_ARRIVED' && renderArrivedRider(item)}
            {action === 'RIDER_PICKED' && renderPickUpRider(item)}
            {action === 'NEAR_BY_RIDER_DESTINATION' &&
              renderNearByDestinationRider(item)}
            {action === 'DELIVERED_RIDER_ORDER' &&
              renderDeliveredRiderOrder(item)}
            {action === 'RIDER_REASSIGNMENT' && renderRiderReassignment(item)}
            {action === 'REVISED_DELIVERY_TIME' &&
              renderRevisedDeliveryTime(item)}
            {action === 'CANCEL_DELIVERY' && renderCancelDelivery(item)}
            {action === 'CANCELLED_NOT_DELIVERED' &&
              renderSuborderCancelledNotDelivered(item)}
            {action === 'ALL_SUBORDERS_CANCEL' && renderAllSubordersCancel()}
            {action === 'AUTO_DELIVERY_CANCEL' && renderAutoDeliveryCancel()}

            {!!item.details.subOrders &&
              item.details.subOrders.length > 0 &&
              item.details.subOrders[0].items &&
              item.details.subOrders[0].items.length > 0 && (
                <div>
                  <Table
                    data={{
                      tableData: item.details.subOrders,
                      properties: ['name', 'original', 'final'],
                      index: props.index,
                      itemChanges: true,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    )
  )
}

export default LogItem
