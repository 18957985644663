import React, { useState, useEffect } from 'react'
import Tabs from '../../Tabs'
import { tableProperties, emptyState } from './participantsUtils'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import './style.css'
import DownloadWinnersList from './DownloadWinnersList'

const DrawTabs = (props) => {
  const { drawsList, selectedValue, drawWinnerModal } = props
  const selectedDetails = drawsList.find(
    (item) => item.value === Number(selectedValue)
  )
  const selectedIndex = selectedDetails ? Number(selectedDetails.index) - 1 : 0
  const [activeIndex, setActiveIndex] = useState(selectedIndex)
  const changeTab = (i) => {
    setActiveIndex(i)
  }
  useEffect(() => {
    setActiveIndex(selectedIndex)
  }, [selectedIndex, drawWinnerModal])

  return (
    <div className="col-12 tab-list mt-2">
      <Tabs
        items={drawsList.map((draw) => `Draw ${draw.index}`)}
        default={activeIndex}
        onClick={changeTab}
        active={activeIndex}
      />
      {drawsList?.map(
        (draw, i) =>
          activeIndex === i && (
            <ListingPageWithRoutes
              testid="winners-listing"
              key={i}
              menu={props.menu}
              headerActions={() => <DownloadWinnersList drawId={draw.value} />}
              api={{
                url: `/duck/winners?drawId=${draw.value}&limit=1000`,
                transform: (response) => response && response.data.list,
              }}
              form={{}}
              emptyState={emptyState}
              tableProperties={tableProperties(draw.drawAt)}
              tableDynamic
            />
          )
      )}
    </div>
  )
}

export default DrawTabs
