import React, { Component } from 'react'
import { Select, Searchable } from '../../../Form'
import Image from '../../../Image'
import API from '../../../../lib/api'
import { getSession } from '../../../../lib/auth'
import { getDefaultStore } from '../../../../containers/StoreSelector'
import { getProductImage } from '../../../../lib/commonlyused'

import './style.css'

class ProductSearch extends Component {
  constructor(props) {
    super(props)
    this.selectOptions = [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Barcode',
        value: 'barcode',
      },
      {
        text: 'Client Id',
        value: 'clientId',
      },
    ]
    this.state = {
      selectionKey: 'name',
    }
    this.handleChange = this.handleChange.bind(this)
    this.runValidation = this.runValidation.bind(this)
    this.getInventoryDetails = this.getInventoryDetails.bind(this)
    this.renderSearchableList = this.renderSearchableList.bind(this)
    this.generateProductsFromVariants =
      this.generateProductsFromVariants.bind(this)
    this.getDetails = this.getDetails.bind(this)
  }

  runValidation(value) {
    this.props.onValidation && this.props.onValidation(value)
  }

  handleChange(e) {
    this.setState({
      selectionKey: e,
    })
  }

  renderSearchableList(item, valueKey, nameKey, onSelect) {
    const includeInventory = this.props.includeInventory ? '' : 'no-inventory'
    const currency = getSession().organization.currency.symbol
    return (
      <li
        className="select-option"
        key={item[valueKey]}
        onClick={() => onSelect(item)}
        data-testid="product-search-item"
      >
        <Image bordered size="sm" src={getProductImage(item)} />
        <div
          className={`searchable-product-details ${includeInventory}`.trim()}
        >
          <div className="product-name">
            {item.brand && <span className="bold">{item.brand.name} </span>}
            {item.fullName ? item.fullName : item.name}
          </div>
          {this.props.includeInventory && (
            <div className="price-holder">
              <span className="price">
                {currency} {item.amount.toFixed(2)}{' '}
              </span>
              {item.discount > 0 && (
                <s className="mrp text-muted">{item.mrp.toFixed(2)}</s>
              )}
            </div>
          )}
        </div>
      </li>
    )
  }

  getInventoryDetails(products, _callback) {
    let params = ''
    if (!products || products.length <= 0) {
      return []
    }
    products.forEach((pdt) => {
      params += `${pdt.id},`
    })
    const api = new API({
      url: `/inventory-service/item?storeId=${
        Number(this.props.storeId) || getDefaultStore().storeId
      }&productId=${params}`,
    })
    return api.get().then((response) => {
      const inventoryData = response.data
      return products
        .map((pdt, index) => {
          const pdtInventory = inventoryData[index]
          if (
            pdtInventory &&
            (Boolean(pdtInventory.stock) || pdtInventory.unlimitedStock) &&
            pdtInventory.mrp > 0
          ) {
            pdt.storeSpecificData = {
              discount: pdtInventory.discount || 0,
              mrp: pdtInventory.mrp,
              amount: pdtInventory.mrp - (pdtInventory.discount || 0),
            }
            pdt.discount = pdtInventory.discount || 0
            pdt.mrp = pdtInventory.mrp
            pdt.amount = pdtInventory.mrp - pdt.discount
            return pdt
          }
          return false
        })
        .filter(Boolean)
    })
  }

  generateProductsFromVariants(product) {
    return (
      product.variants &&
      product.variants.map((variant) => {
        const pdt = Object.assign({}, product)
        pdt.name = variant.fullName
        pdt.brand = product.brand
        pdt.images = variant.images
        pdt.id = variant.id
        pdt.soldByWeight = product.soldByWeight
        pdt.status = variant.status
        return pdt
      })
    )
  }

  getDetails(pdtWithVariants) {
    pdtWithVariants = pdtWithVariants
      .map((product) => {
        const variantCount = (product.variants && product.variants.length) || 0
        let productWithVariants = null
        if (variantCount > 0) {
          productWithVariants = this.generateProductsFromVariants(product)
          return productWithVariants.filter(Boolean)
        }
        return false
      })
      .filter(Boolean)
    pdtWithVariants = (pdtWithVariants && [].concat(...pdtWithVariants)) || []
    return pdtWithVariants
  }

  render() {
    const {
      multiple,
      value,
      onChange,
      getVariantData,
      includeInventory,
      placeholder,
      name,
      readOnly,
      required,
      selectOnEnter,
      withoutDropDown,
    } = this.props
    const { selectionKey } = this.state
    return (
      <div
        className={`Product-search ${multiple ? 'multiple' : ''} ${
          includeInventory ? 'withInventory' : ''
        }`.trim()}
      >
        {!withoutDropDown && (
          <Select
            className="product-select"
            name={`${name}-product-search-select`}
            placeholder={'Search by'}
            options={this.selectOptions}
            value={selectionKey}
            onChange={this.handleChange}
            disabled={readOnly}
          />
        )}
        <Searchable
          required={required}
          selectOnEnter={selectOnEnter}
          name={`${name}-product-search-searchable`}
          className="product-search"
          readOnly={readOnly}
          placeholder={`${placeholder || 'Search Product'}`}
          searchKey={selectionKey}
          searchUrl="/catalogue-service/product"
          valueKey="id"
          nameKey="fullName"
          renderListElement={this.renderSearchableList}
          asyncTransform
          displaySelectedValue={(pdt) => {
            return pdt.brand
              ? `${pdt.brand.name} ${pdt.fullName || pdt.name}`
              : pdt.fullName || pdt.name
          }}
          transformResponse={(response) => {
            let products = response.data.product || []
            if (!getVariantData) {
              return new Promise((resolve) => {
                resolve(products)
              })
            }
            let pdtWithVariants = products.filter(
              (pdt) => Boolean(pdt.hasVariants) === true
            )
            const pdtWithoutVariants = products.filter(
              (pdt) => Boolean(pdt.hasVariants) === false
            )
            if (includeInventory) {
              pdtWithVariants = this.getDetails(pdtWithVariants) || []
              products = pdtWithVariants.concat(pdtWithoutVariants)
              const productsWithInventory = this.getInventoryDetails(products)
              return new Promise((resolve) => {
                resolve(productsWithInventory)
              })
            } else if (pdtWithVariants.length > 0 && getVariantData) {
              pdtWithVariants = this.getDetails(pdtWithVariants)
              return new Promise((resolve) => {
                resolve(pdtWithVariants.concat(pdtWithoutVariants))
              })
            } else {
              return new Promise((resolve) => {
                resolve(products)
              })
            }
          }}
          multiple={multiple}
          value={value}
          onChange={onChange}
          onValidation={this.runValidation}
        />
      </div>
    )
  }
}

ProductSearch.defaultProps = {
  selectionKey: 'name',
  getVariantData: true,
}

export default ProductSearch
