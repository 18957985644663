import React from 'react';
import { Input } from 'components/Form';
import { getMessage } from 'lib/translator';
import { AddRuleOptionsEnum } from '../constants';
import './style.scss'

export function RuleNameInputStep({ ruleName, handleRuleNameInput, selectedRuleType }) {

  const transformRuleName = (value) => {
    const newValue = value.replace(/\s+/g, '_'); // replace whitespace with _
    handleRuleNameInput(newValue);
  }

  return (
    <div className='rule-name-input-step'>
      <Input
        type="text"
        placeholder="e.g.Sentosa"
        name="ruleName"
        disabled={selectedRuleType === AddRuleOptionsEnum.BY_STORE}
        label={getMessage('operations.timeSlotBlocking.addRule.ruleName.label')}
        value={ruleName}
        onChange={transformRuleName}
      />
      <ul className='hint-text-container'>
        {
          selectedRuleType === AddRuleOptionsEnum.BY_STORE ?
          <>
            <li>{getMessage('operations.timeSlotBlocking.addRule.ruleName.byStore.hint1')}</li>
            <li>{getMessage('operations.timeSlotBlocking.addRule.ruleName.byStore.hint2')}</li>
            <li>{getMessage('operations.timeSlotBlocking.addRule.ruleName.byStore.hint3')}</li>
          </> :
          <>
            <li>{getMessage('operations.timeSlotBlocking.addRule.ruleName.others.hint1')}</li>
            <li>{getMessage('operations.timeSlotBlocking.addRule.ruleName.others.hint2')}</li>
          </>
        }
      </ul>
    </div>
  )
}
