import React from 'react'

import Upload from '../../FileUpload'
import { getMessage } from 'lib/translator'
import uploadIcon from './uploadIcon.svg'
import csvIcon from './csvIcon.svg'
import { handleDownloadClick } from '../utils'

const CSVUpload = ({ uploadUrl = '', csv, fileName, onUpload = '' }) => {
  return (
    <div className="rewards product-upload">
      <div className="bordered-box">
        <div className="text-center">
          <img src={uploadIcon} width="140" alt="" />
        </div>
        <Upload
          onUpload={onUpload}
          name="bulkUpload"
          accept=".csv,.txt, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
          placeholder={getMessage('rewards.uploadFileText')}
          uploadButtonText="Upload"
          uploadKey="file"
          cancelText="Cancel"
          uploadUrl={uploadUrl}
          validationStrings={{
            invalidFileType: getMessage('input.invalidFileType'),
          }}
          strings={{
            defaultMessage: getMessage('fileUpload.importCsv.heading'),
            progressMessage: getMessage('fileUpload.importedCsv.heading'),
            completionMessage: getMessage('rewards.uploadedCsv.heading'),
          }}
          icon={csvIcon}
        />
      </div>
      <div className="text-center mt">
        <a
          className="download-link"
          data-testid="download-link"
          onClick={() => handleDownloadClick(csv, fileName)}
        >
          {getMessage('rewards.csvDownloadText')}
        </a>
      </div>
    </div>
  )
}
export default CSVUpload
