import React from 'react'
import { SingleDatePicker, Input, BaseForm } from '../../../components/Form'
import { getMessage } from '../../../lib/translator'

class Filters extends BaseForm {
  constructor(props) {
    super(props)
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components

    return (
      <Form>
        <div className="form-fields">
          <Input
            id="entityName"
            label={getMessage('eVoucher.filter.entityName')}
            placeholder={getMessage('eVoucher.filter.entityName')}
            name="entityName"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['entityName'],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            allowAllDates
            id="datePicker"
            className="full-width-mobile"
            label={getMessage('eVoucher.filter.ordercreatedon')}
            placeholder={getMessage('order.filter.placedOn.placeholder')}
            name="date"
            displayFormat="YYYY MM DD"
            {...this.generateStateMappers({
              stateKeys: ['date'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton testid="submit-filter">
          {getMessage('offer.submit')}
        </SubmitButton>
        <ClearButton testid="clear-filter">
          {getMessage('offer.clear')}
        </ClearButton>
      </Form>
    )
  }
}

export default function EvoucherFilters(props) {
  return <Filters {...props} />
}
