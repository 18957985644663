import React from 'react'
import { BaseForm, Select, CategorySearch, ProductSearch } from '../../../Form'
import './style.css'
import { handleConditionChange } from 'components/LuckyDraw/utils'
import { getMessage } from '../../../../lib/translator'
import ConditionHeader from './ConditionHeader'
import HeaderWithDeleteButton from './HeaderWithDeleteButton'

export default class AddConditions extends BaseForm {
  constructor(props) {
    super(props)

    this.state.values = {
      conditionsList: [
        {
          entityName: '',
          operator: '',
          productCategory: '',
          productInfo: '',
        },
      ],
    }

    this.entityOptions = [
      {
        text: 'Category',
        value: 'Category',
      },
      {
        text: 'Product',
        value: 'Product',
      },
    ]

    this.operatorTypeOptions = [
      {
        text: 'Include ONLY',
        value: 'INCLUDE_ONLY',
      },
      {
        text: 'Include',
        value: 'INCLUDE',
      },
      {
        text: 'Exclude',
        value: 'EXCLUDE',
      },
    ]

    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
  }

  handleAddClick() {
    const values = Object.assign({}, this.state.values)
    const data = {
      entityName: '',
      operator: '',
      productCategory: '',
      productInfo: '',
    }
    const conditionsList = values.conditionsList
    conditionsList.push(data)
    this.setState({
      values: values,
    })
  }

  handleRemoveClick(e, index) {
    const parent = this.props._this
    const conditionsListValidation = parent.state.validations.conditionsList
    const values = Object.assign({}, this.state.values)
    const conditionsList = values.conditionsList
    conditionsList.splice(index, 1)
    conditionsListValidation.splice(index, 1)
    this.setState({
      values,
    })
  }

  render() {
    const { _this } = this.props
    const { conditionsList } = this.state.values

    Object.assign(this.state.values, _this.state.values)

    return (
      <div className="single-column-field chance-page">
        <ConditionHeader />
        {conditionsList.map((x, i) => (
          <div className="single-column-field" key={i}>
            <div className="condition-popup">
              <HeaderWithDeleteButton
                type="condition"
                index={i}
                handleRemoveClick={this.handleRemoveClick}
                campaignStarted={this.state.campaignStarted}
                getMessage={getMessage}
              />
              <div className="condition-details">
                <Select
                  testid={`condition-entityname-${i + 1}`}
                  name="entityname"
                  key="condition-entityname"
                  label={getMessage('luckydraw.condition-entity')}
                  placeholder={getMessage('luckydraw.condition-selectentity')}
                  options={this.entityOptions}
                  {..._this.generateStateMappers({
                    stateKeys: ['conditionsList', i, 'entityName'],
                    loseEmphasisOnFill: true,
                  })}
                  onBlur={(e) => handleConditionChange(x, e)}
                  disabled={_this.state.campaignStarted}
                />
                {x.entityName === 'Category' && (
                  <CategorySearch
                    name="category-search"
                    label={getMessage('luckydraw.condition-category')}
                    placeholder={getMessage(
                      'luckydraw.condition-searchcategory'
                    )}
                    className="category-search"
                    {..._this.generateStateMappers({
                      stateKeys: ['conditionsList', i, 'productCategory'],
                      loseEmphasisOnFill: true,
                    })}
                    multiple
                    readOnly={_this.state.campaignStarted}
                    {...(x.entityName && {
                      required: 'required',
                    })}
                  />
                )}
                {x.entityName === 'Product' && (
                  <ProductSearch
                    label={getMessage('luckydraw.condition-product')}
                    placeholder={getMessage(
                      'luckydraw.condition-searchproduct'
                    )}
                    name="product"
                    key="condition-product"
                    className="product-searchable"
                    {..._this.generateStateMappers({
                      stateKeys: ['conditionsList', i, 'productInfo'],
                      loseEmphasisOnFill: true,
                    })}
                    multiple
                    readOnly={_this.state.campaignStarted}
                    {...(x.entityName && {
                      required: 'required',
                    })}
                  />
                )}
                <Select
                  testid={`condition-operator-${i + 1}`}
                  name="chance-operator"
                  key="chance-operator"
                  label={getMessage('luckydraw.mechanics-operator')}
                  placeholder={getMessage(
                    'luckydraw.mechanics-select-operator'
                  )}
                  options={this.operatorTypeOptions}
                  disabled={_this.state.campaignStarted}
                  {..._this.generateStateMappers({
                    stateKeys: ['conditionsList', i, 'operator'],
                    loseEmphasisOnFill: true,
                  })}
                  {...(x.entityName && {
                    required: 'required',
                  })}
                />
              </div>
            </div>
          </div>
        ))}
        <button
          data-testid="add-condition-btn"
          className="button add-content-block-lucky"
          type="button"
          onClick={this.handleAddClick}
          disabled={_this.state.campaignStarted}
        >
          {getMessage('luckydraw.add-condition')}
        </button>
      </div>
    )
  }
}
