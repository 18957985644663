import React from 'react'

import {
  BaseForm,
  Select,
  Input,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import Loader from '../../../../components/Loader'
import {
  Status,
  FormButtons,
  ErrorScreen,
  CHANNEL_OPTIONS,
  DatePickerField,
  TimePickerField,
  getCategoryDetails,
  getPartnerDetails,
  getDateValues,
  convertToISOString,
} from '../../../../components/Rewards/utils'
import {
  handleApis,
  handleError,
} from '../../../../components/Rewards/services'
import API from '../../../../lib/api'
import WebInfo from '../../../../components/Rewards/Promotions/WebInfo'
import UrlInfo from 'components/Rewards/Promotions/UrlInfo'
import CategoryInfo from '../../../../components/Rewards/CategoryInfo'
import ImageUrls from '../../../../components/Rewards/Promotions/ImageUrls'
import PartnerDetails from 'components/Rewards/Promotions/PartnerDetails'
import Permalink from 'components/Rewards/Permalink'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'

class PromotionForm extends BaseForm {
  async componentDidMount() {
    const values = Object.assign({}, this.state.values)
    values.loading = true
    this.setState({
      values,
    })
    let getPromotionDetails = ''
    const { id } = { ...this.props.value }
    if (id) {
      const api = new API({ url: `/rms/promotions/${id}` })
      getPromotionDetails = api.get()
    }

    try {
      const catDetailsApi = new API({
        url: `/rms/categories?status=active&offset=0&limit=1000`,
      })
      const partnerDetailsApi = new API({
        url: `/rms/partners?offset=0&limit=1000`,
      })
      const [catDetailsRes, partnerDetailsRes, promotionDetailsRes] =
        await Promise.allSettled([
          catDetailsApi.get(),
          partnerDetailsApi.get(),
          getPromotionDetails,
        ])
      getCategoryDetails(catDetailsRes, values)
      getPartnerDetails(partnerDetailsRes, values)

      if (
        promotionDetailsRes.status === 'fulfilled' &&
        promotionDetailsRes.value
      ) {
        const promotionResValue = promotionDetailsRes.value
        const { metaTitle, metaDescription, webDescription } = {
          ...promotionResValue.webInfo,
        }
        const startDateTime = promotionResValue.startDate
        const endDateTime = promotionResValue.endDate
        const dateValues = getDateValues(startDateTime, endDateTime)
        values.date = { ...dateValues }
        values.banner = promotionResValue.imgUrls?.banner
        values.landscapeBanner = promotionResValue.imgUrls?.landscapeBanner
        values.metaTitle = metaTitle
        values.metaDescription = metaDescription
        values.webDescription = webDescription
        values.name = promotionResValue.name
        values.slug = promotionResValue.slug
        values.displayChannel = promotionResValue.displayChannel
        values.partnerId = promotionResValue.partnerId
        values.externalUrl = promotionResValue.externalUrl
      }

      if (promotionDetailsRes.status === 'rejected') {
        throw new Error(promotionDetailsRes.reason.message)
      }

      values.loading = false
      this.setState({
        values,
      })
    } catch (err) {
      handleError(err, this)
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    const {
      id,
      name,
      slug,
      status,
      displayChannel,
      landscapeBanner,
      banner,
      date,
      tnc,
      description,
      categoryIds,
      partnerId,
      externalUrl,
      metaTitle,
      metaDescription,
      webDescription,
    } = this.state.values

    const startDate = convertToISOString(date?.startingDate, date?.startingTime)
    const endDate = convertToISOString(date?.endingDate, date?.endingTime)

    const statusValue = status || 1

    if (isValid) {
      const mapData = {
        name,
        slug,
        tnc,
        description,
        categoryIds,
        displayChannel,
        partnerId: Number(partnerId),
        startDate,
        endDate,
        status: Number(statusValue),
        imgUrls: { banner, landscapeBanner },
        externalUrl,
        webInfo: {
          metaTitle,
          metaDescription,
          webDescription,
        },
      }

      handleApis(mapData, `/rms/promotions`, this, id)
    }
  }

  render() {
    const {
      webInfo,
      categoryList,
      fetchCategoryError,
      partnerList,
      fetchPartnerError,
      error,
      loading,
    } = {
      ...this.state.values,
    }
    const { Form } = this.components

    return (
      <div className="rewards container promotion-form">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="row">
              <div className="col-6">
                <Input
                  label={getMessage('rewards.name')}
                  placeholder={getMessage('rewards.name')}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['name'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
              <Permalink _this={this} />
            </div>
            {(partnerList || fetchPartnerError) && (
              <PartnerDetails
                _this={this}
                partnerList={partnerList}
                fetchPartnerError={fetchPartnerError}
              />
            )}
            <hr />
            {(categoryList || fetchCategoryError) && (
              <CategoryInfo
                _this={this}
                categoryList={categoryList}
                fetchCategoryError={fetchCategoryError}
                type="promotions"
              />
            )}
            <hr />
            <div className="row">
              <div className="col-12">
                <Select
                  label={getMessage('rewards.displayChannel')}
                  testid="deviceType"
                  placeholder={getMessage('rewards.displayChannel')}
                  options={CHANNEL_OPTIONS}
                  {...this.generateStateMappers({
                    stateKeys: ['displayChannel'],
                  })}
                  required
                />
              </div>
              <Status _this={this} />
            </div>
            <div className="row">
              <DatePickerField label="startDate" _this={this} />
              <TimePickerField label="startTime" _this={this} />
            </div>
            <div className="row">
              <DatePickerField label="endDate" _this={this} />
              <TimePickerField label="endTime" _this={this} />
            </div>
            <WebInfo webInfo={webInfo} _this={this} />
            <hr />
            <ImageUrls _this={this} />
            <hr />
            <UrlInfo _this={this} />
            {this.props.method === 'edit' && (
              <DisplayCreatorEditorInfo {...this.state.values} />
            )}
            <FormButtons buttons={this.buttons} type="promotions" />
            {error && <ErrorScreen error={error} />}
          </Form>
        )}
      </div>
    )
  }
}
export default PromotionForm
