import React, { Component } from 'react'
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/uniqBy'
import chunk from 'lodash/chunk'
import padStart from 'lodash/padStart'
import API from '../../../../lib/api'
import { get } from '../../../../lib/storage'
import Loader from '../../../../components/Loader'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { JobConfig } from '../BatchJobsConfig'

const BATCH_SIZE = 800

const storeTypes = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
}

const PFC_CLIENT_ID = '004'

const getRows = results => {
  const skuIdx = results[0].indexOf('sku')
  const storeClientIDIdx = results[0].indexOf('store_id')
  const offerIdIdx = results[0].indexOf('offer_id')
  return results.slice(1).map(result => ({
    id: result[offerIdIdx] ? Number([result[offerIdIdx]]) : '',
    clientStoreId: result[storeClientIDIdx],
    sku: result[skuIdx],
  }))
}

export default class BulkDeactivationOffer extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      storesByClientId: {},
    }
  }

  componentDidMount() {
    const { results = [] } = this.props
    const rows = getRows(results)
    const stores = this.getStores()
    const storesByClientId = stores.reduce((obj, store) => {
      return {
        ...obj,
        [store.clientId]: store,
      }
    }, {})

    const offers = rows.map(row => {
      const sku = row.sku
      if (row.id) {
        return {
          id: row.id,
          status: 'DISABLED',
        }
      } else if (sku && row.clientStoreId) {
        const clientStoreId = padStart(row.clientStoreId, 3, '0')
        return this.getStorePayload(
          storesByClientId[clientStoreId] || { clientId: clientStoreId },
          sku
        )
      } else if (sku) {
        const storeOffers = stores.map(store => {
          return this.getStorePayload(store, sku)
        })
        return uniqBy(storeOffers, 'clientID')
      }
    })

    this.setState({
      offers: flatten(offers),
      storesByClientId: storesByClientId,
    })

    this.updateOfferStatus(offers)
  }

  updateOfferStatus(offers) {
    const allOffers = flatten(offers)
    const batchedOffers = chunk(allOffers, BATCH_SIZE)

    batchedOffers.forEach(batchedPayload => {
      const offerApi = new API({
        url: this.props.endpoint,
      })
      this.setState({
        loading: true,
      })
      offerApi
        .patch({
          offers: batchedPayload,
        })
        .then(response => {
          this.setState({
            offersByClientIdAndId: {
              ...this.state.offersByClientIdAndId,
              ...this.getOffersByClientId(response.data),
            },
          })
        })
        .finally(() =>
          this.setState({
            loading: false,
          })
        )
    })
  }

  getOffersByClientId(updatedOffers = []) {
    return updatedOffers.reduce((offersByClientId, offer) => {
      if (offer.id) {
        offersByClientId[offer.id] = true
      }
      if (offer.clientId) {
        offersByClientId[
          // store_sku
          offer.clientId
            .split('_')
            .shift()
            .split('-')
            .slice(1, 3)
            .join('-')
        ] = true
        offersByClientId[
          // sku
          offer.clientId
            .split('_')
            .shift()
            .split('-')
            .slice(2)
            .join('-')
        ] = true
      }
      return offersByClientId
    }, {})
  }

  getStorePayload(store, sku) {
    const isPFC = store.clientId === PFC_CLIENT_ID
    return {
      clientID: `${isPFC ? storeTypes.ONLINE : storeTypes.OFFLINE}-${
        store.clientId
      }-${sku}`,
      status: 'DISABLED',
    }
  }

  getStores() {
    const stores = JSON.parse(get('stores'))
    return stores.map(store => ({
      ...store,
      isOnline: store.hasPicking && !store.hasSelfCheckout,
      isOffline: store.hasSelfCheckout,
    }))
  }

  render() {
    const { jobName, onBack, results = [] } = this.props
    const { loading } = this.state
    const headers = JobConfig[jobName].getTableHeaders()
    const { offersByClientIdAndId = {} } = this.state
    const rows = getRows(results)
    const invalidRows = rows.filter(row => {
      if (row.id) {
        return !offersByClientIdAndId[`${row.id}`]
      }
      if (row.sku && row.clientStoreId) {
        return !offersByClientIdAndId[[row.clientStoreId, row.sku].join('-')]
      }
      return !offersByClientIdAndId[`${row.sku}`]
    })

    return (
      <div>
        {loading && <Loader />}
        <div className="message-box">
          <div className="update-message">
            Offers have been disabled successfully!
          </div>
          <div className="update-message">
            Number of successful updates:{' '}
            <span className="count">{rows.length - invalidRows.length}</span>
          </div>
          <div className="update-message">
            Number of invalid offers:{' '}
            <span className="count">{invalidRows.length}</span>
          </div>
        </div>
        {invalidRows.length > 0 && (
          <div className="invalid-message">
            Below is the list of already disabled/invalid offer details
          </div>
        )}
        <div className="table-container">
          {invalidRows.length > 0 && (
            <Table tableDynamic={false}>
              <Header items={headers} />
              {invalidRows.map((row, rowIndex) => (
                <Row className={`row`} key={rowIndex}>
                  <Cell>
                    <span>{row.id}</span>
                  </Cell>
                  <Cell>
                    <span>{row.sku}</span>
                  </Cell>
                  <Cell>
                    <span>{padStart(row.clientStoreId, 3, '0')}</span>
                  </Cell>
                </Row>
              ))}
            </Table>
          )}
          <div className="actions">
            <button className="button" onClick={onBack}>
              Ok
            </button>
          </div>
        </div>
      </div>
    )
  }
}
