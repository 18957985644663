import React, { Component } from 'react'
import { Textarea } from '../../../Form'
import { getMessage } from '../../../../lib/translator'

export default class DrawTerms extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { _this } = this.props

    return (
      <div className="single-column-field fiftypercent-field normal-height-field">
        <Textarea
          type="text"
          name="campaign-terms"
          key="campaign-terms"
          testid="campaign-terms"
          label={getMessage('luckydraw.tncsUrl.label')}
          placeholder={getMessage('luckydraw.tncsUrl.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['campaignTermsURL'],
            loseEmphasisOnFill: true,
          })}
        />
        <Textarea
          type="text"
          name="campaign-winners"
          key="campaign-winners"
          testid="campaign-winners"
          label={getMessage('luckydraw.winnersListUrl.label')}
          placeholder={getMessage('luckydraw.winnersListUrl.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['campaignWinnersURL'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }
}
