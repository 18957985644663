import BulkScheduleFeatureRecipe from './ReviewTables/BulkScheduleFeatureRecipe'

export const JobConfig = {
  SCHEDULE_RECIPE: {
    name: 'Bulk Schedule Feature Recipe',
    id: 'bulk-schedule-feature-recipe',
    sampleCsv: 'sample-bulk-schedule-feature-recipe.csv',
    isValidFilename: () => true,
    csvHeaders: {
      DATE_FROM: { header: 'date_from', validate: true, mandatory: true, },
      DATE_TO: { header: 'date_to', validate: true, mandatory: true, },
      ISIN: { header: 'isin', validate: true, mandatory: true },
      ACTION: { header: 'action', validate: true, mandatory: true },
      LABEL: { header: 'label', validate: true },
      ID: { header: 'id', validate: true },
    },
    getTableHeaders: () => ['Date from', 'Date to', 'ISIN', 'Action','Label','Id'],
    endpoint: '/recipe-service/recipe/upload',
    ReviewComponent: BulkScheduleFeatureRecipe,
  },
}
