import {
  convertToISOString,
  getFormattedDate,
  getFormattedTime,
} from 'components/Rewards/utils'

export const formatPromotionalPrice = (promotionalPrice) => {
  if (promotionalPrice?.length > 0 && promotionalPrice[0].price) {
    return promotionalPrice.map((item) => {
      const startAt = convertToISOString(item.startDate, item.startTime)
      const endAt = convertToISOString(item.endDate, item.endTime)
      return { price: item.price, startAt, endAt }
    })
  }
  return null
}

export const getFormattedPromotionalPrice = (promotionalPrice) => {
  if (promotionalPrice?.length > 0 && promotionalPrice[0].price) {
    return promotionalPrice.map((item) => ({
      price: item.price,
      startDate: getFormattedDate(item.startAt),
      startTime: getFormattedTime(item.startAt),
      endDate: getFormattedDate(item.endAt),
      endTime: getFormattedTime(item.endAt),
    }))
  }
  return []
}

export const formatUsuageValidityData = (mapData, stateValues) => {
  const { usageType, usedByDate, usedByTime, useWithinDays } = stateValues
  if (usageType === 'Fixed') {
    const useByDate = convertToISOString(usedByDate, usedByTime)
    mapData.useByDate = useByDate
    mapData.useWithinDays = null
  }

  if (usageType === 'Relative') {
    mapData.useWithinDays = useWithinDays
    mapData.useByDate = null
  }
  return mapData
}

export const formatMetaData = (mapData, metadata, type) => {
  mapData.metadata = {
    cost: Number(metadata.cost),
    costCenter: metadata.costCenter,
    faceValue: Number(metadata.faceValue),
    ...(type === 'FP_EVOUCHER' && {
      voucher: { denomination: Number(metadata.voucher.denomination) },
    }),
    mechanics: metadata.mechanics,
  }
  return mapData
}
