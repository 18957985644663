import React from 'react';
import { Link } from 'react-router-dom';
import PlacementDetails from '../PlacementDetails'
import OrdersListViewNew from '../OrdersListView';
import PaymentDetails from '../PaymentDetails';
import { getMessage } from 'lib/translator';
import './style.scss'

function ShowSaleOrderDetails({ saleOrderData, url, currency } ) {
  if (!saleOrderData) {
    return null;
  }

  return (
    <div className="sale-order-summary-wrapper">
      <div className="user-details">
        <PlacementDetails
          status={saleOrderData.status}
          creationTime={saleOrderData.createTime}
          device={saleOrderData.placedFrom}
        />
        <div className="address-details">
          <h3 className="customer-heading">{getMessage('Customer')}</h3>
          { saleOrderData.customer && (
              <React.Fragment>
                <Link
                  className="customer-name"
                  to={`${url}/customers/view/${saleOrderData.customer.id}`}>
                  {saleOrderData.customer.name}
                </Link>
              </React.Fragment>
            )
          }
        </div>
      </div>
      <OrdersListViewNew
        orders={saleOrderData.orders}
        status={saleOrderData.status}
        discounts={saleOrderData.discounts}
        standardServiceFee={saleOrderData.standardServiceFee}
        payments={saleOrderData.payments}
        currency={currency}
        totalAmount={saleOrderData.total}
      />
      <PaymentDetails
        payments={saleOrderData.payments}
        currency={currency}
      />
    </div>
  )
}

export default ShowSaleOrderDetails;

