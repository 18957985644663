import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../Table'
import { DropDown, DropDownItem, ICONS } from '../DropDown'
import moment from 'moment'
import { getMessage } from '../../lib/translator'
import { DATE_TIME_FORMAT, getStatusText } from './utils'

const tableProperties = section => {
  return {
    headers: [
      'ID',
      'Title',
      'Partner',
      section === 'promotions' ? 'Device Type' : 'Type',
      'Start Date',
      'End Date',
      'Status',
      'Actions',
    ],
    row: ({
      id,
      name,
      partner,
      displayChannel,
      type,
      startDate,
      endDate,
      startAt,
      endAt,
      status,
    }) => (
      <Row>
        <Cell className="rewards-page-actions column-id">
          <Link to={`/rewards/${section}/edit/${id}`}>{id}</Link>
        </Cell>
        <Cell className="rewards-page-actions column-title">
          <Link to={`/rewards/${section}/edit/${id}`}>{name}</Link>
        </Cell>
        <Cell className="rewards-page-actions column-partner">
          <Link to={`/rewards/${section}/edit/${id}`}>{partner?.name}</Link>
        </Cell>
        <Cell className="rewards-page-actions column-type">
          {section === 'promotions' ? displayChannel : type}
        </Cell>
        <Cell className="rewards-page-actions column-startdate">
          {moment(section === 'promotions' ? startDate : startAt).format(
            DATE_TIME_FORMAT
          )}
        </Cell>
        <Cell className="rewards-page-actions column-enddate">
          {moment(section === 'promotions' ? endDate : endAt).format(
            DATE_TIME_FORMAT
          )}
        </Cell>
        <Cell className="rewards-page-actions column-status">
          {getStatusText(status)}
        </Cell>
        <Cell className="rewards-page-actions column-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <Link to={`/rewards/${section}/edit/${id}`}>
              <DropDownItem>
                {getMessage(`rewards.${section}.edit`)}
              </DropDownItem>
            </Link>
          </DropDown>
        </Cell>
      </Row>
    ),
  }
}

export { tableProperties }
