import React from 'react'
import { BaseForm } from 'components/Form'
import SelectStoresSearch from 'components/SelectStoresSearch'
import isEmpty from 'lodash.isempty'
import API from '../../../../lib/api'

export class ManageStorePopupForm extends BaseForm {
  constructor(props) {
    super(props)
    this.handleEnabling = this.handleEnabling.bind(this)
    this.handleDisabling = this.handleDisabling.bind(this)

    this.state = {
      ...this.state,
      organizationId: null,
    }

    /**
     * Gettting organisation ID and def ID from auth/DBP is
     * if the account is not involved or associate with the input, please skip the checks
     */
  }

  async _submitHandler(e) {
    e.preventDefault()
  }

  handleEnabling() {
    const payload = {
      storeIds: [...this.state.values.stores],
      organizationId: this.props.orgId,
    }
    const api = new API({ url: '/account-service/stores/sng-enable' })
    api
      .put(payload)
      .then((_) => {
        this.props.onSubmit()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  handleDisabling() {
    const api = new API({
      url: '/account-service/stores/sng-disable',
    })
    const payload = {
      storeIds: [...this.state.values.stores],
      organizationId: this.props.orgId,
    }

    api
      .put(payload)
      .then((resp) => {
        if (resp.code === 200 || resp.code === 201) {
          this.props.onSubmit()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    const { orgId } = this.props
    const haveOrgId = orgId !== undefined && orgId !== null

    return (
      <div data-testid="manage-store-popup-form" className="manage-store-form">
        <form onSubmit={this._submitHandler} role="form">
          <SelectStoresSearch
            label="Stores"
            placeholder="Search Multiple Stores"
            name="stores"
            stores={this.props.stores}
            _this={this}
            stateKey="stores"
          />
          {!haveOrgId && (
            <p className="error-message" data-testid="no-org-id">
              No Organization ID found for this account
            </p>
          )}
          <div className="form-control">
            <button
              data-testid="enabling-sng"
              disabled={!haveOrgId || isEmpty(this.state.values.stores)}
              className="primary button"
              type="button"
              onClick={this.handleEnabling}
            >
              Enabling S&G
            </button>
            <button
              data-testid="disabling-sng"
              disabled={!haveOrgId || isEmpty(this.state.values.stores)}
              className="button"
              type="button"
              onClick={this.handleDisabling}
            >
              Disabling S&G
            </button>
          </div>
        </form>
      </div>
    )
  }
}
