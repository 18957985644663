import React, { Component } from 'react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './style.css'
import calenderIcon from './calender-icon.svg'

export default class DateRangePickerWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focusedInput: null,
    }
  }

  render() {
    const { focusedInput } = this.state
    const {
      startDate: startDateProp,
      endDate: endDateProp,
      displayFormat,
      transForm,
      required,
      onDatesChange,
      startDatePlaceholder,
      endDatePlaceholder,
      minimumNights,
      isOutsideRange,
      isDayBlocked,
      openDirection,
    } = this.props
    const smallDevice = window.matchMedia('(max-width: 600px)')?.matches
    const orientation = smallDevice ? 'vertical' : 'horizontal'

    let startDate = startDateProp
    let endDate = endDateProp
    startDate = (startDate && moment(startDate, transForm)) || null
    endDate = (endDate && moment(endDate, transForm)) || null

    return (
      <div className="date-picker">
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDatesChange={({ startDate: start, endDate: end }) =>
            onDatesChange(
              start && moment(start).format(transForm),
              end && moment(end).format(transForm)
            )
          }
          focusedInput={focusedInput}
          onFocusChange={(focusedElem) => {
            this.setState({ focusedInput: focusedElem })
          }}
          startDateId="startDate"
          endDateId="endDate"
          displayFormat={displayFormat}
          required={required}
          startDatePlaceholderText={startDatePlaceholder}
          endDatePlaceholderText={endDatePlaceholder}
          customInputIcon={<img src={calenderIcon} alt="" />}
          orientation={orientation}
          minimumNights={minimumNights}
          isOutsideRange={isOutsideRange}
          isDayBlocked={isDayBlocked}
          openDirection={openDirection}
        />
      </div>
    )
  }
}

DateRangePickerWrapper.defaultProps = {
  displayFormat: 'YYYY-MM-DD',
  className: '',
  transForm: 'YYYY-MM-DD',
  minimumNights: 1,
}
