import React, { Fragment } from 'react'
import { getMessage } from '../../../../../lib/translator'
const parameterCheck = linkPoint => {
  return linkPoint ? linkPoint : '___'
}

const checkorderCompletion = orderCompleted => {
  return orderCompleted ? (
    <div className="no-delivery-map">
      {getMessage('qc.deliveryDetails.NoMapOrderCompleted')}
    </div>
  ) : (
    <iframe
      border="0"
      scrolling="no"
      src="https://track.staging.zeek.one/sg/en/FD-J-00683/47a343?b=SG.NTUC"
      className="delivery-details-iframe"
    ></iframe>
  )
}
const DeliveryDetails = () => {
  const noMap = false
  const orderCompleted = false
  return (
    <div
      className="qc-container delivery-details-container"
      data-testid="delivery-details-container"
    >
      <div className="flex-1">
        <div className="qc-header border-bottom">
          <h2>{getMessage('qc.deliveryDetails.Heading')}</h2>
        </div>
        <div className="delivery-details">
          {noMap ? (
            <div className="other-section">
              {getMessage('qc.deliveryDetails.NoRider')}
            </div>
          ) : (
            <Fragment>
              <div>Andrius Lim</div>
              <div>+65 8262 7891</div>
              <div className="other-section">SMM 1234B</div>
            </Fragment>
          )}
          <div>
            {getMessage('qc.deliveryDetails.FinalPickupTime')}:{' '}
            {noMap ? '-' : <b>Sun, 5 Sep 2021, 10:05AM</b>}
          </div>
          <div className="other-section">
            {getMessage('qc.deliveryDetails.FinalDeliveryTime')}:{' '}
            {noMap ? '-' : <b>Sun, 5 Sep 2021, 11:00AM</b>}
          </div>
          <div>
            {getMessage('qc.deliveryDetails.StartAddress')}:{' '}
            {noMap ? (
              '-'
            ) : (
              <b>Parkway Parade, 80 Marine Parade Rd, Singapore 449269</b>
            )}
          </div>
          <div className="other-section">
            {getMessage('qc.deliveryDetails.DeliveryAddress')}:{' '}
            {noMap ? (
              '-'
            ) : (
              <b>
                22 Marine Parade Central 09-06 Parkway Centre, Singapore 449408
              </b>
            )}
          </div>{' '}
          <div>
            {getMessage('qc.deliveryDetails.DeliveryInstructions')}:{' '}
            {noMap ? (
              '-'
            ) : (
              <i className="qc-order-summary-grey">
                {getMessage('qc.deliveryDetails.PressDoorbell')}
              </i>
            )}
          </div>
        </div>
      </div>
      {noMap ? (
        <div className="no-delivery-map">
          {getMessage('qc.deliveryDetails.NoMap')}
        </div>
      ) : (
        checkorderCompletion(orderCompleted)
      )}
    </div>
  )
}

export { parameterCheck }

export default DeliveryDetails
