import React from 'react'
import ListingPage from '../../../../containers/ListingPage'

import { getMessage } from '../../../../lib/translator'
import { GIFTING_PAGINATION_SIZE } from '../../constants'
import EGiftOrderFiltersForm from './Filters'
import tablePropertie, { transform } from './tableProperties'

const EmptyState = {
  message: getMessage('customerSupport.egifting.empty'),
}

export default function List(props) {
  return (
    <ListingPage
      testid="e-gifting-orders"
      menu={props.menu}
      className="egifting-orders-page"
      title={getMessage('customerSupport.egifting.heading')}
      api={{
        url: '/gifting-admin-service/orders',
        params: { ...props.params, page_size: GIFTING_PAGINATION_SIZE },
        transform,
      }}
      primaryKey="id"
      tableProperties={tablePropertie()}
      filters={{
        component: EGiftOrderFiltersForm,
      }}
      onClear={() => props.history.push({ search: 'filters=all' })}
      emptyState={EmptyState}
      statusUpdateOnDelete
    />
  )
}
