import React, { Component } from 'react'
import ListingPage from '../../../containers/ListingPage'
import { desktopView } from './Tables'
import {
  saveSession,
  disableExtension,
  enableExtension,
} from '../../../lib/auth'
import API from '../../../lib/api'
import './style.css'

const ExtensionTypes = {
  All: '',
  Operations: [
    'OrderReturns',
    'DeliverySlots',
    'LogisticsSupport',
    'DeliveryAreaSupport',
    'MultiStoreSupport',
    'InStoreProcessing',
    'SlotCharges',
    'PreOrderSupport',
    'BulkOrderSupport',
    'CapacityPlanning',
  ],
  Catalogue: [
    'StockManagement',
    'SoldByWeightSupport',
    'SubstitutionGroups',
    'ProductTagSupport',
    'MultiVariantSupport',
    'MultiBrandSupport',
    'StockOverride',
    'SearchOverride',
  ],
  Marketing: ['Marketing', 'Campaigns', 'LayoutSchedulingSupport'],
  CustomerSupport: ['CustomerSupport', 'TawkToLiveChat', 'CustomerTags'],
  Analytics: ['Seo', 'Analytics'],
  HumanResource: ['HrManagement', 'MultiUserSupport'],
}

const emptyState = {
  // icon: emptyIcon,
  message: 'There are no extensions', // TODO: Add translation
}

const tableProperties = desktopView

function filterExtensions(extensions, type) {
  if (!extensions) {
    return []
  }
  if (!type) {
    return extensions
  }
  const newExtensions = ExtensionTypes[type] || []
  return extensions
    .filter((extn) => {
      if (newExtensions.indexOf(extn.slug) > -1) {
        return extn
      }
      return null
    })
    .filter(Boolean)
}

class ExtnTypeFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 'All',
    }
    this.extensions = Object.keys(ExtensionTypes)
  }
  render() {
    const { data, updateView } = this.props
    const { selected } = this.state
    return (
      <div className="extension-filter-buttons">
        {this.extensions &&
          this.extensions.map((extn, i) => {
            return (
              <button
                key={`extension-filter-button-${i}`}
                className={selected === extn ? 'selected' : ''}
                onClick={() => {
                  this.setState({
                    selected: extn,
                  })
                  updateView(data, extn)
                }}
              >
                <span>
                  {extn
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, (str) => str.toUpperCase())}
                </span>
              </button>
            )
          })}
      </div>
    )
  }
}

export default class Extensions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowSize: window.innerWidth,
    }
    this.registerResize = this.registerResize.bind(this)
  }
  registerResize(e) {
    this.setState({
      windowSize: e.target.innerWidth,
    })
  }
  componentDidMount() {
    window.addEventListener('resize', this.registerResize, true)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.registerResize, true)
    this.api && this.api.cancel()
  }

  render() {
    const { props } = this
    return (
      <ListingPage
        menu={props.menu}
        className="extensions-page"
        title="Extensions"
        api={{
          url: '/account-service/extension',
          params: { paginated: 'false' },
          transform: (response) => response.data.extension,
          afterSubmit: (response) => {
            response.data.extension.status === 'ENABLED'
              ? enableExtension(response.data.extension)
              : disableExtension(response.data.extension)
            this.api = new API({ url: '/account-service/me' })
            this.api.get().then((res) => {
              saveSession(res.data)
              this.props.rerender && this.props.rerender()
            })
          },
        }}
        emptyState={emptyState}
        additionalViews={[ExtnTypeFilters]}
        updateView={(data, key) => {
          return filterExtensions(data, key)
        }}
        tableProperties={tableProperties}
      />
    )
  }
}
