import React from 'react'
import { getMessage } from 'lib/translator'
import { PAGE } from './utils'
import './style.css'

const Navigator = ({
  view,
  cancelDraft,
  updateModulePosition,
  moduleValues,
}) => {
  const isNewDraft = Object.keys(moduleValues).length === 0

  const buttonsConfig = {
    [PAGE[0]]: [],
    [PAGE[1]]: isNewDraft
      ? [
          {
            type: 'button',
            label: getMessage('apphome.cancel.draft'),
            onClick: cancelDraft,
            testId: 'cancel-button',
          },
        ]
      : [],
    [PAGE[2]]: [
      {
        type: 'primary',
        label: getMessage('apphome.enable'),
        onClick: updateModulePosition,
        testId: 'publish-button',
      },
      {
        type: 'button',
        label: getMessage('apphome.cancel.draft'),
        onClick: cancelDraft,
        testId: 'cancel-button',
      },
    ],
  }

  return (
    <div className="buttons-section">
      {buttonsConfig[view].map((button, index) => (
        <button
          key={index}
          type="button"
          className={button.type}
          onClick={button.onClick}
          data-testid={button.testId}
        >
          {button.label}
        </button>
      ))}
    </div>
  )
}

export default Navigator
