import React from 'react'
import { withRouter } from 'react-router-dom'
import Loader from '../../../../components/Loader'
import { getMessage } from '../../../../lib/translator'
import { BaseForm, Input } from '../../../../components/Form'
import API from '../../../../lib/api'
import { Dialog } from '../../../../components/Popup'

class OrderManagementForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state.showLoader = false
    this.handleClose = this.handleClose.bind(this)
    this.closeDialogs = this.closeDialogs.bind(this)
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    })

    const logisticApi = new API({ url: `/config-service/config/logistics` })
    const orderApi = new API({ url: `/account-service/config/order` })
    Promise.all([orderApi.get(), logisticApi.get()])
      .then(([orderApiResponse, logisticApiResponse]) => {
        const values = JSON.parse(JSON.stringify(this.state.values))
        values.minimumOrderValue =
          (orderApiResponse &&
            orderApiResponse.data &&
            orderApiResponse.data.config &&
            orderApiResponse.data.config.order &&
            orderApiResponse.data.config.order.minimumOrderValue) ||
          0
        values.deliveryFee =
          (logisticApiResponse &&
            logisticApiResponse.data &&
            logisticApiResponse.data.logistics &&
            logisticApiResponse.data.logistics.deliveryFee) ||
          0

        this.setState({ values, showLoader: false })
      })
      .catch(error => {
        this.setState({ showLoader: false })
        if (error.code === 401) {
          throw error
        }
      })
  }
  handleSubmit(data) {
    const order = {}
    if (data && data.hasOwnProperty('minimumOrderValue')) {
      order.minimumOrderValue = data['minimumOrderValue']
    } else {
      order.minimumOrderValue = 0
    }

    const logistics = {
      deliveryFee: data['deliveryFee'] || 0
    }
    const logisticApi = new API({ url: `/config-service/config` })
    const api = new API({ url: `/account-service/config/order` })

    return Promise.all([api.put({ order }), logisticApi.post({ logistics })])
      .then(() => {
        this.setState({ formError: '', showSuccessDialog: true })
      })
      .catch(error => {
        this.setState({ formError: error.message, showErrorDialog: true })

        if (error.code === 401) {
          throw error
        }
      })
  }
  onSubmit(data) {
    this.setState({
      submitting: true,
    })
    this.handleSubmit(data).then(() => {
      this.setState({ submitting: false })
    })
  }
  handleClose() {
    this.props.history.goBack()
  }
  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    })
  }
  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    return (
      <div>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage('basicInfo.save.success.title')}
          close={this.closeDialogs}
          closeText={getMessage('basicInfo.save.success.closeText')}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage('basicInfo.save.error.title')}
          close={this.closeDialogs}
          closeText={getMessage('basicInfo.save.error.closeText')}
        />
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form className="order-management-form">
            <Input
              label={getMessage('orderConfig.form.minimumOrderValue.heading')}
              placeholder={getMessage(
                'orderConfig.form.minimumOrderValue.placeholder'
              )}
              name="minimumOrderValue"
              type="number"
              min={0}
              {...this.generateStateMappers({
                stateKeys: ['minimumOrderValue'],
                loseEmphasisOnFill: true,
              })}
            />
            <Input
              label={getMessage('orderConfig.form.deliveryFees.heading')}
              placeholder={getMessage(
                'orderConfig.form.deliveryFees.placeholder'
              )}
              name="deliveryFee"
              type="number"
              min={0}
              {...this.generateStateMappers({
                stateKeys: ['deliveryFee'],
                loseEmphasisOnFill: true,
              })}
            />
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage('basicInfo.submit.text')}
              </SubmitButton>
              <button
                className="button"
                type="button"
                onClick={this.handleClose}
                disabled={this.state.submitting}
              >
                {getMessage('basicInfo.cancel.text')}
              </button>
            </div>
          </Form>
        )}
      </div>
    )
  }
}

export default withRouter(OrderManagementForm)
