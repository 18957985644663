import React, { Component } from 'react'
import MarkerIcon from './icon-marker.svg'
import { Marker, InfoWindow } from '../../../../components/GoogleMaps'

export class MarkerWithInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.closeInfoWindow = this.closeInfoWindow.bind(this)
    this.openInfoWindow = this.openInfoWindow.bind(this)
  }

  closeInfoWindow() {
    this.setState({
      isOpen: false,
    })
  }

  openInfoWindow() {
    this.setState({
      isOpen: true,
    })
  }

  render() {
    const { location } = this.props
    return (
      <Marker
        icon={MarkerIcon}
        position={{
          lat: location.location.lat(),
          lng: location.location.lng(),
        }}
        onMouseOver={this.openInfoWindow}
        onMouseOut={this.closeInfoWindow}
      >
        {this.state.isOpen && (
          <InfoWindow
            options={{ disableAutoPan: true }}
            onCloseClick={this.closeInfoWindow}
          >
            <div>
              <strong>{location.pincode}</strong>
            </div>
          </InfoWindow>
        )}
      </Marker>
    )
  }
}

class PinCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locations: [],
    }
    this.geocodeAddress = this.geocodeAddress.bind(this)
  }
  geocodeAddress(props) {
    const { pincodes } = props
    const geocoder = new window.google.maps.Geocoder()
    const locations = []
    const promises = []
    pincodes.forEach((pincode) => {
      const promise = new Promise((resolve) => {
        geocoder.geocode(
          { address: pincode.pincode.toString() },
          (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              locations.push({
                location: results[0].geometry.location,
                pincode: pincode.pincode,
              })
            }
            resolve()
          }
        )
      })
      promises.push(promise)
    })
    Promise.all(promises).then(() => {
      this.setState({
        locations,
      })
    })
  }
  componentDidMount() {
    const { pincodes } = this.props
    if (pincodes) {
      this.geocodeAddress(pincodes)
    }
  }
  render() {
    const { locations } = this.state.locations.length && this.state
    const { index } = this.props
    if (locations) {
      return (
        locations.length &&
        locations.map((location, pincodeIndex) => {
          return (
            <MarkerWithInfo
              key={`markerwithinfo${index}${pincodeIndex}`}
              location={location}
            />
          )
        })
      )
    } else {
      return null
    }
  }
}

export default PinCode
