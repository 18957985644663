import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import MobileAppsForm from './form'
import { getMessage } from '../../../lib/translator'

import './style.css'

export default class MobileApps extends Component {
  render() {
    return (
      <AuthenticatedPage
        className="mobile-apps"
        menu={this.props.menu}
        title={getMessage('mobileApps.heading')}
        from={this.props.location && this.props.location.pathname}
      >
        ><h1 className="title">{getMessage('mobileApps.heading')}</h1>
        <MobileAppsForm />
      </AuthenticatedPage>
    )
  }
}
