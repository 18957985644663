import React from 'react'
import {
  BaseForm,
  VALIDATION_TYPES,
  Input,
  Checkbox,
  Select,
} from '../../../../components/Form'
import DeleteIcon from './DeleteIcon'
import { getMessage } from '../../../../lib/translator'
import Loader from '../../../../components/Loader'
import API from '../../../../lib/api'

export const USER_SEGMENTS = {
  B2C: 'B2C',
  B2B: 'B2B',
}

export default class TierForm extends BaseForm {
  constructor(props) {
    super(props)
    const { method } = this.props
    this.state = {
      tierGroup: [1, 2],
      stores: [],
      isDisabled: method === 'edit',
      isShownTierDefaultOption: true,
    }
    this.handleTierView = this.handleTierView.bind(this)
  }
  componentDidMount() {
    const { method, value } = this.props
    if (method === 'edit' && value.id) {
      // Enable loader to fetch stores.
      this.setState({
        loading: true,
      })

      // API Call to fetch list of stores for a given tier-setup
      const api = new API({ url: `/account-service/shipping-tier/${value.id}` })
      api.get().then((response) => {
        if (response.status === 'SUCCESS') {
          const data = response.data
          this.setState({
            loading: false,
            stores: data.stores || [],
          })
        }
      })
      const keyValue = {}
      keyValue['name'] = value.name
      keyValue['isDefault'] = value.isDefault
      keyValue['description'] = value.description
      keyValue['userSegment'] = value.userSegment
      keyValue['id'] = value.id
      if (value.tiers && value.tiers.length > 0) {
        const tierSize = value.tiers.length
        const tierGroup = value.tiers.map((item, idx) => {
          const key = idx + 1
          if (key !== tierSize) {
            keyValue[`end-${key}`] = item.endAmount
          }
          keyValue[`id-${key}`] = item.id
          keyValue[`delivery-${key}`] = item.fee
          return key
        })
        this.setState({ tierGroup })
      }
      this.addKeys(keyValue)
      this.setState({
        isShownTierDefaultOption: value.userSegment !== USER_SEGMENTS.B2B,
      })
    }
  }
  handleTierView(value, idx) {
    const { tierGroup } = this.state
    let startKey = `start-${tierGroup[idx]}`
    if (idx > 0) {
      startKey = `end-${tierGroup[idx - 1]}`
    }
    return (
      <div className="tier">
        <Input
          name={`start-${value}`}
          type="number"
          disabled
          placeholder={idx === 0 ? 0 : ''}
          key={`start-${value}`}
          {...this.generateStateMappers({
            stateKeys: [startKey],
            loseEmphasisOnFill: true,
          })}
        />
        <Input
          name={`end-${value}`}
          type="number"
          disabled={idx === tierGroup.length - 1}
          placeholder={idx === tierGroup.length - 1 ? '∞' : ''}
          required
          key={`end-${value}`}
          {...this.generateStateMappers({
            stateKeys: [`end-${value}`],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
            beforeChange: (v) => {
              if (idx > 0) {
                const state = this.getCurrentState()
                const preValue = state.values[startKey]
                if (v <= preValue) {
                  return preValue + 1
                }
              }
              return v
            },
          })}
        />
        <Input
          name={`delivery-${value}`}
          type="number"
          required
          key={`delivery-${value}`}
          {...this.generateStateMappers({
            stateKeys: [`delivery-${value}`],
            loseEmphasisOnFill: true,
            beforeChange: (v) => {
              return v < 0 ? 0 : v
            },
          })}
        />
      </div>
    )
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const { tierGroup, isDisabled, stores, isShownTierDefaultOption } =
      this.state

    return (
      <Form className="instore-config-form logistics-config-form">
        {this.state.loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="input-flex">
              <Input
                name="name"
                required
                label={getMessage('logistics.tier.tierName')}
                placeholder={getMessage('logistics.tier.tierName.placeholder')}
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ['name'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('logistics.tier.Desc')}
                placeholder={getMessage('logistics.tier.Desc.placeholder')}
                className="product-description"
                name="description"
                {...this.generateStateMappers({
                  stateKeys: ['description'],
                  default: '',
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <Select
              name="userSegment"
              label={getMessage('logistics.tier.userSegment')}
              options={Object.values(USER_SEGMENTS)}
              {...this.generateStateMappers({
                stateKeys: ['userSegment'],
                defaultValue: USER_SEGMENTS.B2C,
                loseEmphasisOnFill: true,
                onChange: (value) => {
                  this.setState({
                    isShownTierDefaultOption: value !== USER_SEGMENTS.B2B,
                  })
                },
              })}
            />
            {isShownTierDefaultOption && (
              <Checkbox
                label={getMessage('')}
                inlineLabel={getMessage('logistics.tier.defaultStore')}
                name="default"
                {...this.generateStateMappers({
                  stateKeys: ['isDefault'],
                  default: true,
                  loseEmphasisOnFill: true,
                })}
              />
            )}

            <div className="tier-group">
              <span className="labelWrap">
                <label>TIER</label>
              </span>
              <div className="tier-setup">
                <div className="tier">
                  <span className="labelWrap">
                    <label>{getMessage('logistics.tier.add.column.1')}</label>
                  </span>
                  <span className="labelWrap">
                    <label>{getMessage('logistics.tier.add.column.2')}</label>
                  </span>
                  <span className="labelWrap">
                    <label>{getMessage('logistics.tier.add.column.3')}</label>
                  </span>
                </div>
                <div className="button_align"></div>
              </div>
              {tierGroup.map((value, index) => (
                <div className="tier-setup" key={index}>
                  {this.handleTierView(value, index)}
                  <div className="button_align">
                    {index > 0 ? (
                      <DeleteIcon
                        className="delete-icon"
                        key={`delete-${value}`}
                        height="18"
                        width="18"
                        title="Delete"
                        onClick={() => {
                          const oldTier = tierGroup
                          this.deleteKeys([`delivery-${value}`, `end-${value}`])
                          oldTier.splice(index, 1)
                          this.setState({ tierGroup: oldTier })
                        }}
                      />
                    ) : (
                      false
                    )}
                    {tierGroup.length === index + 1 ? (
                      <button
                        type="button"
                        className="primary button"
                        onClick={() => {
                          const newValue = tierGroup.slice(-1).pop() + 1
                          const updatedTier = tierGroup
                          updatedTier.push(newValue)
                          this.setState({ tierGroup: updatedTier })
                        }}
                      >
                        +{' '}
                        <span className="text">
                          {getMessage('logistics.tier.add.heading')}
                        </span>
                      </button>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              ))}
            </div>
            {this.props.method === 'edit' && this.state.stores.length > 0 && (
              <React.Fragment>
                <div className="tier-group">
                  <span className="labelWrap">
                    <label>STORE</label>
                  </span>
                </div>
                <div className="multi-tags">
                  {this.state.stores.map((item, idx) => (
                    <div key={`tag-${idx}`} className="tag-value">
                      <span key={`label-${idx}`} className="tag-value-label">
                        <small>{item.name}</small>
                      </span>
                    </div>
                  ))}
                </div>
                <div className="tier-group">
                  <Checkbox
                    className="entity-checkbox"
                    inlineLabel={getMessage('tier.confirmation.text')}
                    name={`isDisabled`}
                    value={!isDisabled}
                    onChange={() => {
                      this.setState({ isDisabled: !isDisabled })
                    }}
                  />
                </div>
              </React.Fragment>
            )}
            <div className="form-action top-space">
              <SubmitButton disabled={isDisabled && stores.length > 0}>
                {getMessage('logistics.save')}
              </SubmitButton>
              {this.props.method === 'edit' && (
                <CancelButton>{getMessage('logistics.cancel')}</CancelButton>
              )}
            </div>
          </React.Fragment>
        )}
      </Form>
    )
  }
}
