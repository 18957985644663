import React from 'react'
import {
  BaseForm,
  Checkbox,
  Radio,
  VALIDATION_TYPES,
} from '../../../../../../components/Form'
import Loader from '../../../../../../components/Loader'
import { getMessage } from '../../../../../../lib/translator'
import API from '../../../../../../lib/api'

export default class PackOrderForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: {
        notifyCustomer: true,
      },
      data: this.props.data,
      packlistData: this.props.packlistData,
      deliverytrackingDummy: {
        partnerName: 'Ninjavan',
        trackingId: 'NTUC123',
        trackingUrl: 'https://www.ninjavan.co/en-sg/tracking?id=NTUC1223',
      },
    }
  }

  onSubmit(formData) {
    this.setState({
      loading: true,
    })
    const pickType = formData && formData.pickType ? formData.pickType : null
    if (pickType) {
      let params = {}
      if (pickType === 'Zero Pick') {
        params = {}
      } else if (pickType === 'Full Pick') {
        const packedItems = []
        const items = this.state.data.items
        const freeItems = this.state.data.freeItems
          ? this.state.data.freeItems
          : []
        ;[...items, ...freeItems].forEach((item) => {
          packedItems.push({
            orderItemId: item.item.id,
            itemId: item.item.itemId,
            packedQuantity: item.orderedQuantity,
          })
        })
        params = {
          packedItems: packedItems,
        }
      }
      if (this.isDfOrder()) {
        params = {
          ...params,
          notifyCustomer: formData.notifyCustomer,
          deliveryTracking: [this.state.deliverytrackingDummy],
        }
      }
      params = {
        ...params,
        notifyCustomer: formData.notifyCustomer,
      }
      this.packOrderAction(params, {}).then(() => {
        this.props.updateCompleted()
      })
    }
    this.setState({
      loading: false,
    })
  }

  packOrderAction(params, headers) {
    const referenceNumber = this.state.data.referenceNumber
    const api = new API({
      url: `/order-service/v3/deliveryOrders/${referenceNumber}/pack`,
    })
    return api.post(params, headers).then(() => {
      this.setState({
        loading: false,
      })
    })
  }

  isDfOrder() {
    return this.props.data.seller?.type === 'SELLER_TYPE_VENDOR'
  }

  processingStatusCheck() {
    if (this.state.data.status !== 'DELIVERY_ORDER_STATUS_PROCESSING') {
      return true
    }

    for (let i = 0; i < this.state.packlistData.length; i++) {
      if (
        this.state.packlistData[i].status === 'PACKLIST_STATUS_PACKING' ||
        this.state.packlistData[i].status === 'PACKLIST_STATUS_PICKING'
      ) {
        return false
      }
    }

    return true
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    return this.state.loading ||
      !this.props.data ||
      !this.props.packlistData ? (
      <Loader />
    ) : this.processingStatusCheck() ? (
      <Form className="pack-order-form" onCancel={this.props.onCancel}>
        <Radio
          label={getMessage('deliveryOrder.details.packOrder.title')}
          name="pickType"
          required
          options={[
            {
              text: getMessage('deliveryOrder.details.packOrder.zeroPick'),
              value: 'Zero Pick',
            },
            {
              text: getMessage('deliveryOrder.details.packOrder.fullPick'),
              value: 'Full Pick',
            },
          ]}
          {...this.generateStateMappers({
            stateKeys: ['pickType'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <Checkbox
          name="notify-customer"
          label={getMessage(
            'deliveryOrder.details.action.notifyCustomer.title'
          )}
          inlineLabel={getMessage(
            'deliveryOrder.details.action.notifyCustomer.text'
          )}
          {...this.generateStateMappers({
            stateKeys: ['notifyCustomer'],
            loseEmphasisOnFill: true,
          })}
        />
        <div className={`form-actions`}>
          <CancelButton>
            {getMessage('deliveryOrder.details.action.cancel')}
          </CancelButton>
          <SubmitButton testid="pack-order-submit">
            {getMessage('deliveryOrder.details.action.save')}
          </SubmitButton>
        </div>
      </Form>
    ) : (
      <div>{getMessage('deliveryOrder.details.packOrder.cannotPack')}</div>
    )
  }
}
