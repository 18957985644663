import React from 'react'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import { getMessage } from '../../../../lib/translator'
import { getSession, isExtensionEnabled } from '../../../../lib/auth'
import PlacementDetails from '../Details/PlacementDetails'
import ItemsView from './ItemsView'
import API from '../../../../lib/api'
import Loader from '../../../../components/Loader'
import './style.css'
import { Dialog } from '../../../../components/Popup'
import {
  BaseForm,
  MultiTextInput,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import Table, { Header, Row, Cell } from '../../../../components/Table'

class EditPackedOrder extends BaseForm {
  constructor(props) {
    super(props)
    this.organization = getSession().organization
    this.currency = this.organization.currency
    this.state = {
      packingDetails: [],
    }

    this.getData = this.getData.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.hideError = this.hideError.bind(this)
  }

  hideError() {
    this.setState(
      {
        showError: false,
      },
      () => {
        const referenceNumber = this.props.match.params.id
        this.props.history.push(`/operations/orders/${referenceNumber}`)
      }
    )
  }

  onChange(items) {
    this.postItems =
      items &&
      items.map((item) => {
        const isSoldByWeight = item.soldByWeight
        const ob = {}
        const orderedQuantity =
          item &&
          item.orderDetails &&
          item.orderDetails.orderedQuantity &&
          Number(item.orderDetails.orderedQuantity)
        const deliveredQuantity =
          (item &&
            item.orderDetails &&
            item.orderDetails.deliveredQuantity &&
            Number(item.orderDetails.deliveredQuantity)) ||
          0
        if (deliveredQuantity > orderedQuantity && !isSoldByWeight) {
          ob['quantity'] = orderedQuantity
        } else {
          ob['quantity'] = deliveredQuantity
        }
        ob['orderItemId'] =
          item && item.orderDetails && item.orderDetails.orderItemId
        return ob
      })
  }

  onSubmit(formdata) {
    this.setState({
      loading: true,
    })
    const data = JSON.parse(JSON.stringify(this.state.data))
    const postParams = {}
    if (!this.postItems) {
      this.postItems =
        data &&
        data.items &&
        data.items.map((item) => {
          const ob = {}
          ob['orderItemId'] =
            item && item.orderDetails && item.orderDetails.orderItemId
          ob['quantity'] =
            (item &&
              item.orderDetails &&
              item.orderDetails.deliveredQuantity &&
              Number(item.orderDetails.deliveredQuantity)) ||
            0
          return ob
        })
    }
    let isOverThreshold = false
    this.postItems.map((pItem) => {
      data.items.map((item) => {
        if (
          pItem.orderItemId === item.orderDetails.orderItemId &&
          item.storeSpecificData &&
          item.soldByWeight
        ) {
          if (
            item.storeSpecificData[0].overPickingThreshold &&
            item.storeSpecificData[0].overPickingThreshold *
              item.orderDetails.orderedQuantity <
              pItem.quantity
          ) {
            isOverThreshold = true
          }
        }
        return item
      })
      return pItem
    })
    if (isOverThreshold) {
      this.setState({
        showError: true,
        loading: false,
        error: 'quantity is more than overpick threshold',
      })
      return
    }
    postParams['items'] = [...this.postItems]
    if (
      formdata &&
      formdata.packages &&
      formdata.packages.crates &&
      formdata.packages.crates.length > 0
    ) {
      postParams['details'] = formdata && formdata.packages
    }

    const referenceNumber = this.props.match.params.id
    const api = new API({ url: `/order-service/order/${referenceNumber}/pack` })
    api
      .put(postParams)
      .then(() => {
        this.setState(
          {
            loading: false,
            error: null,
            showError: false,
          },
          () => {
            const refNum = this.props.match.params.id
            this.props.history.push(`/operations/orders/${refNum}`)
          }
        )
      })
      .catch((err) => {
        if (err.code === 401 || err.code === 403) {
          throw err
        } else {
          this.setState({
            showError: true,
            loading: false,
            error: err.message,
          })
        }
      })
  }

  handleCancel() {
    const referenceNumber = this.props.match.params.id
    this.props.history.push(`/operations/orders/${referenceNumber}`)
  }

  getData() {
    const referenceNumber = this.props.match.params.id
    this.orderDetailsApi = new API({
      url: `/order-service/order/${referenceNumber}`,
    })
    this.setState(
      {
        loading: true,
      },
      () => {
        this.orderDetailsApi
          .get({
            'include[0]': 'cancelledItems',
            rawData: true,
            includeThreshold: true,
          })
          .then(
            (response) =>
              this.setState({
                data: response.data.order,
                packingDetails: (response.data &&
                  response.data.order &&
                  response.data.order.packingDetails &&
                  response.data.order.packingDetails.length > 0 &&
                  response.data.order.packingDetails) || [
                  { details: { crates: [] } },
                ],
              }),
            (error) => {
              if (error.code === 401) {
                throw error
              }
            }
          )
          .then(() => {
            this.setState({ loading: false })
          })
      }
    )
  }

  async componentDidMount() {
    if (isExtensionEnabled('InStoreProcessing')) {
      this.api = new API({
        url: `/config-service/config/inStoreProcessing.packedOrderEditAllowed`,
      })
      await this.api.get().then((res) => {
        if (res.code === 200 && res.status === 'SUCCESS') {
          const isAuthorized =
            res &&
            res.data &&
            res.data.inStoreProcessing &&
            res.data.inStoreProcessing.packedOrderEditAllowed
          this.setState({ isAuthorized })

          if (isAuthorized) {
            this.getData()
          } else {
            this.setState({
              data: {},
              error: 'Not Authorized',
              showError: true,
            })
          }
        }
      })
    } else {
      this.setState({
        data: {},
        error: 'Not Authorized',
        showError: true,
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.packingDetails !== this.state.packingDetails) {
      const values = {}
      values.packages =
        (Array.isArray(this.state.packingDetails) &&
          this.state.packingDetails.length > 0 &&
          this.state.packingDetails[0] &&
          this.state.packingDetails[0].details) ||
        {}
      this.setState({
        values,
      })
    }

    if (prevState.data !== this.state.data) {
      const isStatusPacked =
        (this.state.data && this.state.data.status === 'PACKED') || false
      const packedOrderEdit = this.state.isAuthorized
      if (!packedOrderEdit || !isStatusPacked) {
        const referenceNumber = this.props.match.params.id
        this.props.history.push(`/operations/orders/${referenceNumber}`)
      }
    }
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const packingDetails =
      this.state.packingDetails && this.state.packingDetails.length > 0
    const packages = this.state.values && this.state.values.packages
    let content = <Loader />
    if (this.state.data) {
      const referenceNumber = this.props.match.params.id
      content = (
        <div>
          <h1 className="title heading">
            {getMessage('order.details.heading')}{' '}
            {this.state.data &&
              (this.state.data.clientId
                ? getMessage('order.table.clientId.prefix')
                : getMessage('order.table.referenceNumber.prefix'))}
            <strong className="reference-number">
              {this.state.data && (this.state.data.clientId || referenceNumber)}
            </strong>
          </h1>
          {this.state.loading && (
            <div className="loader-wrapper">
              <Loader />
            </div>
          )}
          {this.state.showError && (
            <Dialog
              title={getMessage('order.details.edit-packed.error.dialog.label')}
              show={this.state.showError}
              information={this.state.error}
              close={this.hideError}
              closeText={getMessage(
                'order.details.edit-packed.error.closeDialog'
              )}
            />
          )}
          <PlacementDetails
            data={{
              preorder: this.state.data.preorder,
              status: this.state.data.status,
              verificationStatus: this.state.data.verificationStatus,
              creationTime: this.state.data.createdAt,
              completionTime: this.state.data.completedAt,
              device: this.state.data.placedFrom,
              items: this.state.data.items,
              metaData: this.state.data.metaData,
              storeId: this.state.data.store.id,
              batchIds: this.state.data.batchIDs,
            }}
          />
          <Form>
            <ItemsView
              data={{
                items: this.state.data.items || [],
              }}
              editable
              currency={this.currency}
              onChange={this.onChange}
              status={this.state.data.status}
              storeId={this.state.data.store && this.state.data.store.id}
              editPackedOrder
            />
            {packingDetails && (
              <div className="packing-details">
                <h3>{getMessage('order.details.packing.heading')}</h3>
                <Table>
                  <Header>
                    <Cell>{getMessage('order.packing.type')}</Cell>
                    <Cell>{getMessage('order.packing.labels')}</Cell>
                  </Header>
                  {packages &&
                    Object.keys(packages).map((type, index) => (
                      <Row key={`packing-item-${index}`}>
                        <Cell>{getMessage(`order.packing.${type}`)}</Cell>
                        <Cell>
                          <MultiTextInput
                            placeholder={getMessage(
                              'order.details.packages.multi-text.placeholder'
                            )}
                            name={`packages-${type}`}
                            classname="packages"
                            value={packages && packages[type]}
                            {...this.generateStateMappers({
                              stateKeys: ['packages', type],
                              loseEmphasisOnFill: true,
                              validationType: VALIDATION_TYPES.ONSUBMIT,
                            })}
                          />
                        </Cell>
                      </Row>
                    ))}
                </Table>
              </div>
            )}
            <div className="foot-buttons">
              <button
                className="button"
                type="button"
                onClick={this.handleCancel}
              >
                {getMessage('order.details.itemsTable.actions.cancelText')}
              </button>
              <SubmitButton>
                {getMessage('order.details.itemsTable.actions.confirmText')}
              </SubmitButton>
            </div>
          </Form>
        </div>
      )
    }
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="edit-packed-order"
        storeDependent
        onChange={this.onStoreChange}
      >
        <h1>{getMessage('order.details.edit-packed.heading')}</h1>
        {content}
      </AuthenticatedPage>
    )
  }
}

export default EditPackedOrder
