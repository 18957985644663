import React, { useState } from 'react'
import Loader from 'components/Loader'
import Table, { Header, Cell, Row } from 'components/Table'
import Image from 'components/Image'

import { getMessage } from 'lib/translator'
import { Input } from 'components/Form'
import { RenderRow } from 'pages/customer-support/EGiftingOrders/Details/EvouchersDetails'

import './styles.css'

const currencyFormat = (data) => {
  return new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
  }).format(data)
}

const renderAmountRow = (data, handleState, assignmentCode, isError) => {
  return (
    <Row>
      <Cell className="input-key">{data.reference}</Cell>
      <Cell className="input-key">
        <Input
          placeholder={getMessage(
            'customerSupport.egifting.header.keyin.assignment'
          )}
          name="reference"
          className="input-key"
          type="text"
          testid="assignment-code"
          error={isError}
          maxLength={18}
          minLength={0}
          showErrors={isError}
          value={assignmentCode}
          onChange={(value) => {
            handleState(value)
          }}
        />
      </Cell>
      <Cell>{currencyFormat(Number(data.amount))}</Cell>
    </Row>
  )
}

const businessHeaderListing = [
  getMessage('category.header.image'),
  getMessage('order.filters.batch.heading'),
  getMessage('customerSupport.egifting.denomination'),
  getMessage('offer.quantity'),
  getMessage('operations.dashboard.total'),
]

const cellHeader = [
  getMessage('customerSupport.egifting.header.orderno'),
  getMessage('customerSupport.egifting.header.assignment'),
  getMessage('customerSupport.payments.header.amount'),
]

const businessHeader = [
  getMessage('customerSupport.egifting.header.orderno'),
  getMessage('customerSupport.egifting.header.assignment'),
  getMessage('customerSupport.payments.header.amount'),
]

const RenderHeader = (data) => {
  return (
    <div className="flex-around section-title">
      <h3>
        {`Items in order`}
        <span className="text-muted"> ({data?.extendedGifts?.length})</span>
      </h3>
    </div>
  )
}

const RenderCell = (items) => {
  return items.map((item, ind) => <Cell key={ind}>{item}</Cell>)
}

const PendingApprovalDetailsPage = ({
  data,
  handleState,
  assignmentCode,
  isError,
}) => {
  const [isLoading] = useState(false)
  return (
    <div className="order-items-view" data-testid="EvoucherDetails">
      {RenderHeader(data)}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Table>
            <Header>{RenderCell(businessHeaderListing)}</Header>
            {data.extendedGifts.length > 0 &&
              data.extendedGifts.map((item, index) => {
                return (
                  <Row key={item.id || `_${index}`}>
                    <Cell>
                      <Image bordered size="sm" src={item.metadata.image} />
                    </Cell>

                    <Cell>
                      <small className="text-muted">
                        {item.batchId || 'N/A'}
                      </small>
                    </Cell>

                    {RenderRow(item)}
                  </Row>
                )
              })}
          </Table>
          <div className="spacing-top">
            <Table>
              <Header>
                {RenderCell(data.isB2B ? businessHeader : cellHeader)}
              </Header>
              {renderAmountRow(data, handleState, assignmentCode, isError)}
            </Table>
          </div>
        </>
      )}
    </div>
  )
}

export default PendingApprovalDetailsPage
