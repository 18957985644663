import React from 'react'
import PropTypes from 'prop-types'
import tagIcon from '../../icons/tag.svg'
import deleteIcon from '../../icons/delete.svg'
import AutocompleInput from '../autocomplete/AutocompleteInput'
import { ENVIRONMENT } from 'config/app'
import prodStoresList from './stores_list.json'
import uatStoresList from './stores_list_uat.json'
import './CategorizedStoreSelector.css'

const KOPITIAM = 'kopitiam';
const FAIRPRICE = 'fairprice';
const UNITY = 'unity';
const CHEERS = 'cheers';

export default function StoreGroupingsSelector({
  onUpdate,
  values = [],
}) {
  const storesList = ENVIRONMENT === 'UAT' ? uatStoresList : prodStoresList;


  const isKopitiamDrinkStall = (clientStoreId) => clientStoreId.endsWith('3DF');

  const categorizedStores = [
    {
      category: 'Fairprice',
      label: 'fairprice',
      stores: storesList.filter(store => store.store_type === FAIRPRICE).map(store => store.client_store_id)
    },
    {
      category: 'Kopitiam tenant stalls',
      label: 'kopitiam tenant stalls',
      stores: storesList.filter(store => store.store_type === KOPITIAM && !isKopitiamDrinkStall(store.client_store_id)).map(store => store.client_store_id)
    },
    {
      category: 'Kopitiam drink stalls',
      label: 'kopitiam drink stalls',
      stores: storesList.filter(store => store.store_type === KOPITIAM && isKopitiamDrinkStall(store.client_store_id)).map(store => store.client_store_id)
    },
    {
      category: 'Unity',
      label: 'unity',
      stores: storesList.filter(store => store.store_type === UNITY).map(store => store.client_store_id)

    },
    {
      category: 'Cheers',
      label: 'cheers',
      stores: storesList.filter(store => store.store_type === CHEERS).map(store => store.client_store_id)
    }
  ]

  const selectedStores = storesList.filter(store => values.some(value => value === store.client_store_id))

  const onAutoCompleteSelect = option => {
    if (!values.some(v => v === option.value)) {
      onUpdate([...values, option.value])
    }
  }

  const addStoresFromCategory = (e, category) => {
    e.preventDefault()
    const newStoresToAdd = category.stores.filter(store => !values.some(existing => existing === store))
    onUpdate([...values, ...newStoresToAdd])
  }

  const getDisplayName = ({ display_name, store_type, client_store_id}) => {
    if (store_type !== KOPITIAM) {
      return display_name;
    }

    if (isKopitiamDrinkStall(client_store_id)) {
      return `${display_name} (drink stalls)`;
    }

    return `${display_name} (tenant stalls)`;
  }

  return (
    <div className="store-grouping-container">
      <div className="store-grouping-list">
        {categorizedStores.map(store => {
          return (
            <button
              className="store-grouping-btn"
              key={store.category}
              onClick={e => addStoresFromCategory(e, store)}>
              + {store.label}
            </button>
          )
        })}
      </div>
      <AutocompleInput
        options={storesList.map(opt => ({
          label: getDisplayName(opt),
          value: opt.client_store_id,
        }))}
        placeholder="Select the store"
        onSelect={onAutoCompleteSelect}
      />
      {selectedStores.length ? (
        <SelectedList
          selectedStores={selectedStores}
          isKopitiamDrinkStall={isKopitiamDrinkStall}
          onDelete={id => {
            onUpdate(values.filter(value => value !== id))
          }}
        />
      ) : null}
      {values.length > 0 ? (
        <div
          role="button"
          className="remove-all-btn"
          onClick={() => onUpdate([])}
        >
          Remove All ({selectedStores.length}) stores
        </div>
      ) : null}
    </div>
  )
}

function SelectedList({ selectedStores, onDelete, isKopitiamDrinkStall }) {

  const getStoreType = ({ store_type, client_store_id }) => {
    if (store_type !== KOPITIAM) {
      return store_type
    }
    if (isKopitiamDrinkStall(client_store_id)) {
      return 'kopitiam drink stalls'
    }
    return 'kopitiam tenant stalls'
  }

  return (
    <ul
      style={{ display: 'flex', flexDirection: 'column' }}
      className="selected-list"
    >
      {selectedStores.map(store => {
        return (
          <li key={store.client_store_id} className="selected-list__item">
            <div className="selected-list__label">
              <img src={tagIcon} alt="store-type-icon" className="tag-icon" />
              <small>{store.display_name}</small>
              <span className="store-grouping-tag">
                  {getStoreType(store)}
              </span>
            </div>
            <div className="delete-icon__container">
              <img
                src={deleteIcon}
                alt={`Delete ${store.display_name} icon`}
                className="delete-icon"
                onClick={() => onDelete(store.client_store_id)}
              />
            </div>
          </li>
        )
      })}
    </ul>
  )
}

SelectedList.propTypes = {
  items: PropTypes.array,
  onDelete: PropTypes.func,
}

StoreGroupingsSelector.propTypes = {
  onUpdate: PropTypes.func,
  values: PropTypes.array,
}
