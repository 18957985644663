import { STATUSES } from '../../Details.enum'
import { handleRefundItems } from '../OrderSummaryHelpers'
import API from '../../../../../../lib/api'
import { QC_BE_GATEWAY } from '../../../../../../config/app'
import { notEmptyArray } from '../../../../../../lib/commonlyused'

const handleCancelOrder = props => {
  props.handleState({
    ...props.state,
    cancelOrder: true,
    rejectOrder: false,
    refundOrder: false,
    showHideElements: true,
  })
}

const openTablePopup = props => {
  const wholeOrderCheck = [...props.state.refundItems]
  if (props.state.refundOrder) {
    wholeOrderCheck.forEach(a =>
      a.items.map(
        b =>
          b.value &&
          notEmptyArray(b.customisationOptions) &&
          b.customisationOptions.map(
            c => c.finalQuantity === 0 && (c.value = true)
          )
      )
    )
  }
  props.handleState({
    ...props.state,
    showHideTablePopup: true,
    wholeOrder: props.state.refundOrder
      ? JSON.stringify(wholeOrderCheck) ===
        JSON.stringify(handleRefundItems(props.data.subOrders, true))
      : false,
  })
}
const handleBack = props => {
  props.handleState({
    ...props.state,
    cancelOrder: false,
    rejectOrder: false,
    refundOrder: false,
    showHideElements: false,
    allSelected: false,
    radioSubOrder: '',
    refundItems: handleRefundItems(props.data.subOrders, false),
  })
}
const handleSelectAll = props => {
  props.handleState({
    ...props.state,
    allSelected: !props.state.allSelected,
    refundItems: handleRefundItems(
      props.data.subOrders,
      !props.state.allSelected,
      !props.state.allSelected,
      props.state
    ),
  })
}

const handleRefundOrder = props => {
  props.handleState({
    ...props.state,
    refundOrder: true,
    cancelOrder: false,
    rejectOrder: false,
    showHideElements: true,
  })
}
const handleRejectOrder = props => {
  props.handleState({
    ...props.state,
    rejectOrder: true,
    refundOrder: false,
    cancelOrder: false,
    showHideElements: true,
  })
}
const closeRefreshPopup = (handleState, state, history, location) => {
  handleState({
    ...state,
    cancelOrder: false,
    rejectOrder: false,
    refundOrder: false,
    showHideRefreshPopup: false,
    showHideElements: false,
    allSelected: false,
  })
  history.push(location.pathname)
}
const closeAutoRejectedPopup = (handleState, state, history, location) => {
  handleState({
    ...state,
    cancelOrder: false,
    rejectOrder: false,
    refundOrder: false,
    showHideAutoRejectedPopup: false,
    showHideRefreshPopup: false,
    showHideElements: false,
    allSelected: false,
  })
  history.push(location.pathname)
}
const handleSaveSubOrderStatus = props => {
  if (props.state.subOrderStatusItems) {
    const allRequests = props.state.subOrderStatusItems.map(
      subOrderStatusItem => {
        const api = new API({
          url: `${QC_BE_GATEWAY}/suborders/${subOrderStatusItem.id}/${subOrderStatusItem.actionParams}`,
        })
        return api.post()
      }
    )

    Promise.all(allRequests).then(
      () => {
        props.handleState({
          ...props.state,
          subOrderStatusItemsContent: props.state.subOrderStatusItemsContent,
          editSubOrderStatus:
            props.state.editSubOrderStatus &&
            Object.values(props.state.editSubOrderStatus).fill(false),
          showHideRefreshPopup: true,
        })
      },
      error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
      }
    )
  }
}
const handleCancelSubOrderStatus = props =>
  props.handleState({
    ...props.state,
    subOrderStatusItems: [],
    editSubOrderStatus:
      props.state.editSubOrderStatus &&
      Object.values(props.state.editSubOrderStatus).fill(false),
  })

const handleLabelClassSelectAll = props => {
  if (props.state.refundOrder) {
    return !props.state.refundItems.every(a => a.value) && 'minus'
  } else {
    return props.state.reasonSelectValue && 'minus'
  }
}

const refundButtonVisibilityCheck = props => {
  return (
    props.refundDetails &&
    props.refundDetails.refund &&
    props.data.state === STATUSES.COMPLETED &&
    props.state.allSubOrdersStatus.indexOf(STATUSES.COLLECTED) !== -1 &&
    !props.data.subOrders
      .filter(c => c.state === STATUSES.COLLECTED)
      .every(suborder =>
        suborder.items.every(
          item =>
            item.refundedQuantity &&
            item.finalQuantity === 0 &&
            (notEmptyArray(item.customisationOptions)
              ? item.customisationOptions.every(customitem =>
                  customitem.refundedQuantity
                    ? customitem.finalQuantity === 0
                    : false
                )
              : true)
        )
      )
  )
}

const handleDisabledSelectAll = props => {
  return props.state.containerCheckboxItems.every(obj => obj.value)
}

export {
  handleCancelOrder,
  openTablePopup,
  handleBack,
  handleSelectAll,
  handleRefundOrder,
  handleRejectOrder,
  handleSaveSubOrderStatus,
  handleCancelSubOrderStatus,
  handleLabelClassSelectAll,
  handleDisabledSelectAll,
  closeRefreshPopup,
  closeAutoRejectedPopup,
  refundButtonVisibilityCheck,
}
