import React from 'react'
import {
  BaseForm,
  VALIDATION_TYPES,
  Phone,
} from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import './style.css'

class AddPhoneNumberForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: {
        phone: '',
      },
      submitting: false,
      formError: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(_data) {}

  onSubmit(data) {
    this.setState(
      {
        submitting: true,
      },
      () => {
        this.handleSubmit(data).then(() => {
          this.setState({
            submitting: false,
          })
          if (this.state.formError === '') {
            this.props.onSuccess('addedPhone', this.response)
          }
        })
      }
    )
  }

  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    return (
      <div className="AddPhoneNumberForm">
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <Phone
            country={this.props.countryIso}
            label={getMessage('customer.details.phone.phoneNumber')}
            name="phone"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ['phone'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage('customer.details.submitText.submit')}
            </SubmitButton>
          </div>
        </Form>
      </div>
    )
  }
}
export default AddPhoneNumberForm
