import { get } from 'lib/storage'
import _get from 'lodash.get'
import _uniq from 'lodash.uniq'
import { createContext } from 'react'
import API from '../../lib/api'
import { getPermissions, isExtensionEnabled, saveSession } from '../../lib/auth'
import { checkForFeatureFlags } from './checksForFeatureFlag'
import {
  newRouterPermissions,
  requiredPermissions,
} from './requiredPermissions'

export const RootDataContext = createContext()

export const getNewSessionOnRefresh = ({ url, callback }) => {
  if (url.searchParams.get('guid')) {
    const api = new API({ url: '/order-service/me' })
    const extnApi = new API({ url: '/order-service/extension' })
    Promise.all([api.get(), extnApi.get()])
      .then(([response, extnResponse]) => {
        saveSession({
          user: response.data.user,
          organization: {
            extension: extnResponse.data.extension,
            currency: { symbol: '' },
          },
        })
        callback()
      })
      .catch(console.log)
  }
}

export function hasAccess({ endpoints, extensions = [] }) {
  const hasExtensionsEnabled = extensions
    .map(isExtensionEnabled)
    .reduce((acc, value) => acc && value, true)
  const hasApiPermissions =
    !endpoints || !endpoints.length
      ? true
      : Object.values(getPermissions(endpoints)).reduce((acc, value) => {
          if (value instanceof Object) {
            return (
              acc &&
              Object.values(value).reduce((total, cur) => total || cur, false)
            )
          } else {
            return acc && value
          }
        }, true)
  return hasApiPermissions && hasExtensionsEnabled
}

export const submenuRedirect = (mainMenuItem, submenu) => {
  return `/${mainMenuItem}/${submenu}`
}

export const getDisplayedMenuFromRole = (menu, splits) => {
  let temp = { ...menu }
  if (get('user') && JSON.parse(get('user'))) {
    const user = JSON.parse(get('user'))
    let roles = _get(user, 'designation.roles', [])
    if (roles.length === 1 && roles[0].name === 'Transport Coordinator') {
      delete menu.hr
      return temp
    }

    const roleBasedMenu = {}
    roles = roles.filter((role) => role.backofficeUrl)
    for (const role of roles) {
      const roleUrl = role.backofficeUrl
      const isRoleGroup = role.isRoleGroup

      if (isRoleGroup) {
        const mainMenu = menu[roleUrl]
        roleBasedMenu[roleUrl] = [...mainMenu]
        continue
      }

      const [mainItem, subItem] = roleUrl.split('/')

      if (!subItem) {
        continue
      }
      const uniqMenu = _get(roleBasedMenu, mainItem, [])

      roleBasedMenu[mainItem] = _uniq(uniqMenu.concat(subItem))
    }
    if (Object.keys(roleBasedMenu).length) {
      temp = { ...roleBasedMenu, user: [...menu.user] }
      temp = checkForFeatureFlags(splits, temp)
    }
  }

  return temp
}

export const getMenuFromPermission = () => {
  const temp = Object.assign(
    {},
    ...Object.keys(requiredPermissions).map((key) => {
      const permissions = requiredPermissions[key].concat(
        newRouterPermissions[key] || []
      )
      return {
        [key]: permissions.filter(hasAccess).map(({ slug }) => slug),
      }
    })
  )
  for (const section in temp) {
    if (!temp[section].length) {
      delete temp[section]
    }
  }

  return temp
}

/*
Find 'operations/dashboard' in current user role,
  If found => return '/operations/dashboard'
  Else     => return first page
*/
export const getDefaultLink = (displayedMenu) => {
  let defaultRoute = '/user/change-password'
  const dashboardPageIndex = displayedMenu?.operations
    ? displayedMenu.operations.indexOf('dashboard')
    : -1
  if (dashboardPageIndex !== -1) {
    defaultRoute = '/operations/dashboard'
  } else if (
    Object.keys(displayedMenu).length > 1 &&
    displayedMenu[Object.keys(displayedMenu)[0]].length &&
    displayedMenu[Object.keys(displayedMenu)[0]][0]
  ) {
    const submenu = displayedMenu[Object.keys(displayedMenu)[0]][0]
    const mainMenu = Object.keys(displayedMenu)[0]
    defaultRoute = submenuRedirect(mainMenu, submenu)
  }
  return defaultRoute
}
