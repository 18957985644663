import React from 'react'
import { get } from '../../../../lib/storage'
import { getMessage } from '../../../../lib/translator'
import { Checkbox, Input, VALIDATION_TYPES } from '../../../../components/Form'
import { SelectSearch } from '../../../../components/Form'
import Toggle from '../../../../components/Form/Inputs/Toggle'
import HandlingTimeComponent from './handling-time'
import { MultiSelect, Select } from '../../../../components/Form/Inputs'
import Dialog from '../../../../components/Popup/Dialog'
import deleteSvg from './delete.svg'

const getDefaultStoreId = () =>
  JSON.parse(get('organization') || '{}').defaultStoreId
const isPFC = storeId => storeId === getDefaultStoreId()

const transporterOffDays = [
  {
    text: 'Sunday',
    value: 1,
  },
  {
    text: 'Monday',
    value: 2,
  },
  {
    text: 'Tuesday',
    value: 3,
  },
  {
    text: 'Wednesday',
    value: 4,
  },
  {
    text: 'Thursday',
    value: 5,
  },
  {
    text: 'Friday',
    value: 6,
  },
  {
    text: 'Saturday',
    value: 7,
  },
]

const packingTypes = [
  {
    text: 'Order by Label',
    value: 'ORDER',
  },
  {
    text: 'SKU',
    value: 'SKU',
  },
  {
    text: 'Order by Ref. No.',
    value: 'ORDER-NO-LABEL',
  },
]

const StoreConfigComponent = ({
  stores,
  transporters,
  generateStateMappers,
  _this,
  index,
  onDeleteCutoffTime,
  onInvalidCutoff,
}) => {
  const [showSplitReportWarning, setSplitReportWarning] = React.useState(false)
  const storeId = _this.getState(['storeId', index])
  const initialCutOffTimesCount =
    _this.getState([`cutOffTimesCount.${storeId}`, index]) || 1
  const cutOffTimes = _this.getState([`cutOffTimes.${storeId}`]) || []
  const handlingHours = _this.getState([`handlingHours.${storeId}`]) || []
  const [cutOffTimeCount, setCutOffTimeCount] = React.useState(
    initialCutOffTimesCount
  )

  React.useEffect(() => {
    const packingTypePrimary = _this.getState([`packingTypePrimary.${storeId}`])
    if (!packingTypePrimary) {
      _this.updateState([`packingTypePrimary.${storeId}`], storeId ? 'SKU' : '')
    }
  }, [storeId])

  const formattedStores = stores.map(store => {
    return {
      text: store.name,
      value: store.id,
    }
  })

  const formattedTransporters = transporters.map(transporter => {
    return {
      text: transporter.Name,
      value: transporter.TransporterID,
    }
  })
  const isStockDistributionDisabled = storeId === getDefaultStoreId()

  const onAddCutOffTime = () => {
    if (cutOffTimeCount >= 1) {
      _this.deleteKeys([`splitReportFlag.${storeId}`])
    }
    setCutOffTimeCount(cutOffTimeCount + 1)
  }
  const handleDeleteCutOffTime = (cutOffTime, handlingHour, storeID, index) => {
    setCutOffTimeCount(cutOffTimeCount - 1)
    onDeleteCutoffTime &&
      onDeleteCutoffTime(cutOffTime, handlingHour, storeID, index)
  }
  const splitReportMappers = storeId
    ? _this.generateStateMappers({
        stateKeys: [`splitReportFlag.${storeId}`],
        loseEmphasisOnFill: true,
      })
    : {}

  const { onChange: onSplitReportChange } = splitReportMappers

  const onSplitReportSelect = value => {
    if (handlingHours[index] >= 8) {
      onSplitReportChange(value)
    } else {
      _this.updateState([`splitReportFlag.${storeId}`], false)
      onSplitReportChange(false)
      setSplitReportWarning(true)
    }
  }
  const storeStateMappers = _this.generateStateMappers({
    stateKeys: ['storeId', index],
    validationType: VALIDATION_TYPES.ONSUBMIT,
  })
  const handleStoreChange = value => {
    storeStateMappers.onChange(value)
    if (value && storeId && value !== storeId) {
      _this.deleteKeys([`cutOffTimes.${storeId}`])
      _this.deleteKeys([`handlingHours.${storeId}`])
      _this.updateState(['reportSignature', index], false)
    }
    _this.updateState([`splitReportFlag.${value}`], isPFC(value))
  }
  return (
    <div className="">
      <div className="seller-store-box">
        <div className="store-config flex">
          <SelectSearch
            name="search-store"
            required
            label={getMessage('shifts.stores')}
            placeholder={getMessage('offer.applies.to.placeholder')}
            multiple={false}
            options={formattedStores}
            nameKey="text"
            valueKey="value"
            searchByName
            className="handling-time-store"
            {...storeStateMappers}
            onChange={handleStoreChange}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
            }}
            // readOnly={sellerStores[storeId] && sellerStores[storeId].storeID}
          />
          <Toggle
            name="status"
            icons={false}
            {..._this.generateStateMappers({
              stateKeys: ['status', index],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        {Array(cutOffTimeCount)
          .fill(1)
          .map((v, index) => (
            <div
              key={`${storeId}-${index}`}
              className={`handling-time-container ${
                cutOffTimeCount > 1 ? 'delete' : ''
              }`}
            >
              <HandlingTimeComponent
                generateStateMappers={generateStateMappers}
                index={index}
                storeId={storeId}
                cutOffTime={cutOffTimes[index]}
                handlingHour={handlingHours[index]}
                onInvalidCutoff={onInvalidCutoff}
                cutOffTimes={cutOffTimes}
                handlingHours={handlingHours}
                updateState={_this.updateState.bind(_this)}
              />
              {cutOffTimeCount > 1 && (
                <img
                  className="delete-cutoff"
                  src={deleteSvg}
                  onClick={() =>
                    handleDeleteCutOffTime(
                      cutOffTimes[index],
                      handlingHours[index],
                      storeId,
                      index
                    )
                  }
                  alt="DeleteStore"
                />
              )}

              <button
                type="button"
                className={`${
                  index === cutOffTimeCount - 1 ? 'show' : 'hide'
                } button`}
                onClick={onAddCutOffTime}
              >
                + Add Cut-Off Timing
              </button>
            </div>
          ))}
        <div
          className={`config-options-container flex ${
            isPFC(storeId) ? '' : 'full'
          }`}
        >
          <Input
            name="stockDistribution"
            label="Stock Distribution"
            type="number"
            min={0}
            max={100}
            placeholder=""
            {..._this.generateStateMappers({
              stateKeys: [`stockDistribution.${storeId}`],
              loseEmphasisOnFill: isStockDistributionDisabled,
            })}
            disabled={!storeId || isStockDistributionDisabled}
            className="stock-distribution"
          />
          <div className="field picklist-type">
            <span className="labelWrap">
              <label htmlFor="picklist-type-dropdown">
                Packing Type
              </label>
            </span>
            <Select
              testid="packingTypePrimary"
              name="packingTypePrimary"
              label="packing-type-dropdown"
              options={packingTypes}
              {..._this.generateStateMappers({
                stateKeys: [`packingTypePrimary.${storeId}`],
                loseEmphasisOnFill: true,
              })}
              onChange={val => {
                _this.updateState([`packingTypePrimary.${storeId}`], val)
                _this.updateState([`packingTypeOrderNoLabel.${storeId}`], false)
                _this.updateState([`packingTypeSKU.${storeId}`], false)
              }}
            />
          </div>
          {cutOffTimes.length <= 1 && (
            <React.Fragment>
              <Checkbox
                label=""
                inlineLabel="Split Reports"
                name={`splitReports.${storeId}`}
                {...splitReportMappers}
                onChange={onSplitReportSelect}
                disabled={!storeId}
                controlled
              />
              <Dialog
                information={
                  'Handling hours has to be at least 8 hours for split reports.'
                }
                close={() => setSplitReportWarning(false)}
                show={showSplitReportWarning}
                closeText="OK"
              />
            </React.Fragment>
          )}
          {!isPFC(storeId) && (
            <Checkbox
              label=""
              inlineLabel="Report Signature"
              name={`reportSignature.${storeId}`}
              {..._this.generateStateMappers({
                stateKeys: ['reportSignature', index],
                loseEmphasisOnFill: true,
              })}
              disabled={!storeId}
            />
          )}
        </div>
        <div className="flex transporter-container">
          <SelectSearch
            name="search-transporter"
            label="Transporter"
            placeholder="Transporter"
            multiple={false}
            options={formattedTransporters}
            nameKey="text"
            valueKey="value"
            searchByName
            className="search-transporter"
            {..._this.generateStateMappers({
              stateKeys: ['transporters', index],
            })}
            readOnly={!storeId}
          />
          <div className="field transporter-off-days">
            <span className="labelWrap">
              <label
                htmlFor="transporter-offdays-dropdown"
                className={!storeId ? 'thin' : ''}
              >
                Transporter Off-days
              </label>
            </span>
            <MultiSelect
              name="search-transporter"
              label="transporter-offdays-dropdown"
              placeholder="Days"
              options={transporterOffDays}
              nameKey="text"
              valueKey="value"
              searchByName
              className="transporter-offdays"
              {..._this.generateStateMappers({
                stateKeys: ['transporterOffDays', index],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
              readOnly={!storeId}
            />
          </div>
          <div className="field additional-packlist-generated">
            <span className="labelWrap">
              <label htmlFor="additional-packlist-generated">
                Additional Packlist Generated
              </label>
            </span>
            {_this.getState([`packingTypePrimary.${storeId}`]) !== 'ORDER-NO-LABEL' && (
              <Checkbox
                label=""
                testid="packingTypeOrderNoLabel"
                inlineLabel="Order by Ref. No."
                name={`packingTypeOrderNoLabel.${storeId}`}
                {..._this.generateStateMappers({
                  stateKeys: [`packingTypeOrderNoLabel.${storeId}`],
                  loseEmphasisOnFill: true,
                })}
                disabled={!storeId}
                controlled
              />
            )}
            {_this.getState([`packingTypePrimary.${storeId}`]) !== 'SKU' && (
              <Checkbox
                label=""
                testid="packingTypeSKU"
                inlineLabel="SKU"
                name={`packingTypeSKU.${storeId}`}
                {..._this.generateStateMappers({
                  stateKeys: [`packingTypeSKU.${storeId}`],
                  loseEmphasisOnFill: true,
                })}
                disabled={!storeId}
                controlled
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreConfigComponent
