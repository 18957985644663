import React from 'react'
import Table, { Cell, Row } from '../../../../components/Table'
import { RenderFirstGrid } from '../../commonMedia/SlotCalendar/index'

export default function SlotTable(props) {

  function renderHeaderColumn(slotItem, slotIndex) {
    return (
      <React.Fragment key={slotIndex}>
        {slotIndex === 0 && ( <RenderFirstGrid data={slotData}></RenderFirstGrid>)}
        <Row className='headerColumn'>
          <Cell className='doubleline'>
            <div><small>{`SPOT ${slotIndex + 1}`}</small></div>
            <small className='text-muted'>
              {`${slotData[0]?.spots[slotIndex]?.start} ~ ${slotData[0]?.spots[slotIndex]?.end}`}
            </small>
          </Cell>
        </Row>
      </React.Fragment>
    )
  }

  function renderCell(slotItem, slotIndex, day) {
    return (
      <React.Fragment key={slotIndex}>
        {slotIndex === 0 && (
          <Row>
            <Cell>
              {day.date}
            </Cell>
          </Row>
        )}
        <Row>
          <Cell className={slotItem.bookedSpots === slotItem.totalSpots ? 'red' : 'green'}>
            {slotItem.bookedSpots} / {slotItem.totalSpots}
          </Cell>
        </Row>
      </React.Fragment>
    )
  }

  const { slotData } = props
  return (
    <div className='slotWrap'>
      <div className='slot-headercolumn'>
        {slotData.map((day, dayIndex) => (
          <React.Fragment key={dayIndex}>
            {dayIndex === 0 ? (
              <Table>
                {day.spots.map((slotItem, slotIndex) => (
                  renderHeaderColumn(slotItem, slotIndex)
                ))}
              </Table>
            ) : ''}
          </React.Fragment>
        ))}
      </div>
      <div className='slot-innerbox' id='slotScroll'>
        {slotData.map((day, dayIndex) => (
          <Table key={dayIndex}>
            {day.spots.map((slotItem, slotIndex) => (
              renderCell(slotItem, slotIndex, day)
            ))}
          </Table>
        ))}
      </div>
    </div>
  )
}
