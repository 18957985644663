import React, { Fragment } from 'react'
import { getMessage } from 'lib/translator'
import { DatePickerField, TimePickerField } from '../utils'

const RedemptionValidity = ({ _this }) => (
  <Fragment>
    <div className="row">
      <div className="col-12">
        <h3>{getMessage('rewards.catalogue.redemptionValidity')}</h3>
      </div>
    </div>
    <div className="row mb">
      <DatePickerField label="startDate" _this={_this} />
      <TimePickerField label="startTime" _this={_this} />
      <DatePickerField label="endDate" _this={_this} />
      <TimePickerField label="endTime" _this={_this} />
    </div>
  </Fragment>
)

export default RedemptionValidity
