import axios from 'axios'

import { getAuthToken } from '../auth'
import { REACT_APP_MEDIA_SERVICE_API_URL } from '../../config/app.js'

const REFRESH_TOKEN_BEFORE_AGE_IN_MS = 2 * 60 * 1000

export default {
  getUrlSign() {
    const accessToken = getAuthToken()

    if (window.sessionStorage.getItem(accessToken)) {
      const sessionSignedUrl = JSON.parse(
        window.sessionStorage.getItem(accessToken)
      )
      const { expires } = sessionSignedUrl
      if (
        new Date(expires).getTime() - new Date().getTime() >
        REFRESH_TOKEN_BEFORE_AGE_IN_MS
      ) {
        return Promise.resolve(sessionSignedUrl)
      }
    }

    return axios({
      url: `${REACT_APP_MEDIA_SERVICE_API_URL}/sign/url`,
      method: 'post',
      data: {
        access_token: accessToken,
        project: 'fairprice',
      },
      validateStatus: status => {
        return status === 200
      },
      responseType: 'json',
    })
      .then(response => {
        window.sessionStorage.setItem(
          accessToken,
          JSON.stringify(response.data)
        )
        return Promise.resolve(response.data)
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject({
            message: 'cancelled',
          })
        }
        return Promise.reject({
          code:
            error.response && error.response.data && error.response.data.code,
          message:
            error.response &&
            error.response.data &&
            error.response.data.message,
        })
      })
  },
}
