import React from 'react'
import { BaseForm, Input } from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import API from '../../../../../lib/api'
import { isExtensionEnabled } from '../../../../../lib/auth'
import Loader from '../../../../../components/Loader'

class AddAddressForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      landmark: '',
      pincode: '',
      city: '',
      submitting: false,
      formError: '',
    }
    this.sequence = null
    if (
      isExtensionEnabled('EntityMetaData') &&
      props.sequence &&
      props.sequence.length
    ) {
      this.sequence = props.sequence
    }
    this.response = ''
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    if (isExtensionEnabled('EntityMetaData')) {
      // let id = getExtensionDetails('EntityMetaData').id
      this.api = new API({ url: `/config-service/meta-data` })
      this.sequenceApi = new API({ url: '/config-service/config/customers' })
      Promise.all([
        this.api.get(),
        !this.sequence && this.sequenceApi.get(),
      ]).then(([extnResponse, sequenceResponse]) => {
        const config = extnResponse.data.config.entityMetaData.address
        this.sequence =
          (sequenceResponse &&
            sequenceResponse.data.customers.addressSequence) ||
          this.sequence
        if (config && !config.length && this.sequence) {
          const sequenceConfig = {}
          this.sequence.forEach((key) => {
            sequenceConfig[key] = config[key]
          })
          this.customerConfig = sequenceConfig
        } else {
          this.customerConfig = config
        }
        this.setState({
          loading: false,
        })
      })
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  handleSubmit(data) {
    const api = new API({
      url: `/ef-customer-core/profile/${this.props.customerId}/addresses`,
    })
    const params = { ...data.address }

    return api.post(params).then(
      (response) => {
        this.setState({ formError: '' })
        this.response = response.data
      },
      (error) => {
        this.setState({ formError: error.message.split(':')[1] })
      }
    )
  }

  onSubmit(data) {
    this.setState(
      {
        submitting: true,
      },
      () => {
        this.handleSubmit(data).then(() => {
          this.setState({
            submitting: false,
          })
          if (this.state.formError === '') {
            this.props.onSuccess &&
              this.props.onSuccess('addedAddress', this.response)
          }
        })
      }
    )
  }

  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    const { loading } = this.state
    const { _this } = this.props
    const Wrapper = _this ? 'div' : Form
    const form = _this || this
    return (
      <div className="addAddressForm">
        <Wrapper>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Input
                label={getMessage(
                  'customer.details.pages.address.addresseeName'
                )}
                placeholder={getMessage(
                  'customer.details.page.addresseeName.placeholder'
                )}
                name="addresseeName"
                required
                {...form.generateStateMappers({
                  stateKeys: ['address', 'addresseeName'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('customer.details.pages.address.phoneNumber')}
                placeholder={getMessage(
                  'customer.details.page.phoneNumber.placeholder'
                )}
                name="phoneNumber"
                required
                {...form.generateStateMappers({
                  stateKeys: ['address', 'phoneNumber'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('customer.details.pages.address')}
                placeholder={getMessage(
                  'customer.details.page.address.placeholder'
                )}
                name="streetAddress"
                required
                {...form.generateStateMappers({
                  stateKeys: ['address', 'streetAddress'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('customer.details.pages.address.unitNumber')}
                placeholder={getMessage(
                  'customer.details.address.unitNumber.placeholder'
                )}
                name="unitNumber"
                {...form.generateStateMappers({
                  stateKeys: ['address', 'unitNumber'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('customer.details.pages.address.floorNumber')}
                placeholder={getMessage(
                  'customer.details.address.floorNumber.placeholder'
                )}
                name="floorNumber"
                {...form.generateStateMappers({
                  stateKeys: ['address', 'floorNumber'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('customer.details.address.buildingName')}
                placeholder={getMessage(
                  'customer.details.address.buildingName.placeholder'
                )}
                name="buildingName"
                {...form.generateStateMappers({
                  stateKeys: ['address', 'buildingName'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('customer.details.address.postalCode')}
                placeholder={getMessage(
                  'customer.details.address.postalCode.placeholder'
                )}
                name="postalCode"
                {...form.generateStateMappers({
                  stateKeys: ['address', 'postalCode'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('customer.details.address.city')}
                placeholder={getMessage(
                  'customer.details.address.city.placeholder'
                )}
                name="city"
                {...form.generateStateMappers({
                  stateKeys: ['address', 'city'],
                  loseEmphasisOnFill: true,
                })}
              />
            </React.Fragment>
          )}
          {!_this && (
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage('customer.details.submitText.submit')}
              </SubmitButton>
            </div>
          )}
        </Wrapper>
      </div>
    )
  }
}
export default AddAddressForm
