export const defaultContract = `
{
    "user": [
      {
        "key": "persona#og_b2c-u2i",
        "key_template": "persona#og_b2c-u2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "USER"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "contains items based on the user b2c personas based on this behaviorial and transactional profile"
      },
      {
        "key": "persona#og_b2b-u2i",
        "key_template": "persona#og_b2b-u2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "USER"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "contains items based on the user b2b personas based on this behaviorial and transactional profile"
      },
      {
        "key": "persona#o2o_b2c-u2i",
        "key_template": "persona#og_b2c-u2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "USER"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "contains items based on the user o2o personas based on this behaviorial and transactional profile"
      },
      {
        "key": "behave#atc-u2i",
        "key_template": "behave#atc-u2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "USER"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "user atc 1 year"
      },
      {
        "key": "behave#clk-u2i",
        "key_template": "behave#clk-u2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "USER"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "user clk 1 year"
      },
      {
        "key": "behave#pp-u2i",
        "key_template": "behave#pp-u2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "USER"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "user past purchase"
      },
      {
        "key": "behave#pdv-u2i",
        "key_template": "behave#pdv-u2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "USER"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "user pdv 1 year"
      },
      {
        "key": "behave#spl-u2i",
        "key_template": "behave#spl-u2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "USER"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "user shopping list click 1 year"
      },
      {
        "key": "trans#ppc-u2i",
        "key_template": "trans#ppc-u2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "USER"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "user past purchase score by item count"
      }
    ],
    "model": [
      {
        "key": "r4u#swing-i2i",
        "key_template": "r4u#swing-i2i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "ITEM"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "item to item based recommender for you via swing model"
      },
      {
        "key": "r4u#swing-l32i",
        "key_template": "r4u#swing-l32i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "L3"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "l3 category to item based recommender for you via swing model"
      },
      {
        "key": "ts-l32i",
        "key_template": "ts-l32i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "L3"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "top selling per l3 category in the last 2 months"
      },
      {
        "key": "ts-l22i",
        "key_template": "ts-l22i#<qualifer:1>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "L2"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "top selling per l2 category in the last 2 months"
      }
    ],
    "filter": [
      {
        "key": "l32i",
        "key_template": "l3#<qualifer:1>-item#<qualifer:2>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "L3",
          "ITEM"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "l3 filter slug"
      },
      {
        "key": "l22i",
        "key_template": "l3#<qualifer:1>-item#<qualifer:2>",
        "value_template": "<qualifer:1>",
        "score": "1",
        "key_type": [
          "L2",
          "ITEM"
        ],
        "value_type": [
          "ITEM"
        ],
        "description": "l2 filter slug"
      }
    ]
  }
`
