const creativeReqTypeList = [
  {
    text: 'Play List',
    value: 'PLAYLIST',
  },
  {
    text: 'Single Image Ad',
    value: 'SINGLE_IMAGE_AD',
  },
  {
    text: 'HTML 5',
    value: 'HTML5',
  }
]

const creativeReqMediaTypeList = [
  {
    text: 'PNG',
    value: 'PNG',
  },
  {
    text: 'JPG',
    value: 'JPG',
  },
  {
    text: 'GIF',
    value: 'GIF',
  },
  {
    text: 'AVI',
    value: 'AVI',
  },
  {
    text: 'MP4',
    value: 'MP4',
  }
]

export {
  creativeReqTypeList,
  creativeReqMediaTypeList,
}
