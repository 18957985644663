import React from 'react'
import { BaseForm, Input, StoreSearch, Select } from '../../../components/Form'
import { getMessage } from '../../../lib/translator'
import { BUList } from '../MediaAssetManagement/businessUnitList'
import { formatAssetOptions } from './AssetTypeList'

// Media Store Filters Form
export default class Filters extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    const { assetTypeList } = this.props.options

    return (
      <Form className="store-filter-form">
        <div className="form-fields">
          <Input
            label={getMessage('media.assetManagement.filters.name')}
            placeholder={getMessage(
              'media.assetManagement.filters.name.placeholder'
            )}
            name="assetName"
            className="section-row-input"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['assetName'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('media.storeList.filters.assettype')}
            placeholder={getMessage(
              'media.storeList.filters.assettype.placeholder'
            )}
            name="assetType"
            options={formatAssetOptions(assetTypeList)}
            {...this.generateStateMappers({
              stateKeys: ['assetType'],
              loseEmphasisOnFill: true,
            })}
          />

          <Select
            className="section-row-input"
            label={getMessage('media.campaigndetail.business.ownerBu')}
            placeholder={getMessage(
              'media.campaigndetail.business.ownerBu.placeholder'
            )}
            options={BUList}
            {...this.generateStateMappers({
              stateKeys: ['bu'],
              loseEmphasisOnFill: true,
            })}
          />
          <StoreSearch
            label={getMessage('media.storeList.filters.name')}
            placeholder={getMessage(
              'media.storeList.filters.store.placeholder'
            )}
            name="store"
            {...this.generateStateMappers({
              stateKeys: ['store'],
            })}
          />
        </div>
        <SubmitButton>{getMessage('brand.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('brand.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
