import React, { Component } from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../../containers/ListingPage'
import { Row, Cell } from '../../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../../components/DropDown'
import { getMessage } from '../../../../lib/translator'
import TagForm from './Form'
import TagFiltersForm from './Filter'
import { Toggle } from '../../../../components/Form'
import { Popup } from '../../../../components/Popup'
import './style.css'
import emptyIcon from '../tags-empty.svg'
import TagsImport from '../../../catalogue/Tags/TagsImport'

const customerServiceTagButton = 'customer-service-tags.button'

const emptyState = {
  icon: emptyIcon,
  message: getMessage('customer-service-tags.noTags'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      + <span className="text">{getMessage(customerServiceTagButton)}</span>
    </button>
  ),
}
const tableProperties = {
  headers: [
    getMessage('customer-service-tags.table.name.header'),
    getMessage('customer-service-tags.table.status.header'),
    getMessage('customer-service-tags.table.description.header'),
    getMessage('customer-service-tags.table.id.header'),
    getMessage('customer-service-tags.table.actions.header'),
  ],
  row: ({ id, name, status, description, systemGenerated, onAction }) => {
    return (
      <Row>
        <Cell
          className={`${
            !systemGenerated
              ? 'customer-service-tag-name'
              : 'system-generated-tag-name'
          }`}
        >
          {!systemGenerated ? (
            <span
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT, { id })
              }}
            >
              {name}
            </span>
          ) : (
            <span>{name}</span>
          )}
        </Cell>
        <Cell className={`customer-service-tag-status ${status} `}>
          {!systemGenerated ? (
            <React.Fragment>
              {' '}
              <span>
                {getMessage(`customer.service.tags.status.${status}`)}
              </span>
              <Toggle
                className={status}
                name={status}
                value={status === 'ENABLED'}
                icons={false}
                onChange={() => {
                  onAction(
                    TABLE_ACTIONS.UPDATE,
                    { id },
                    { status: status === 'ENABLED' ? 'DISABLED' : 'ENABLED' }
                  )
                }}
              />{' '}
            </React.Fragment>
          ) : (
            <span>{getMessage(`customer.service.tags.status.${status}`)}</span>
          )}
        </Cell>
        <Cell className="customer-service-tag-description">{description}</Cell>
        <Cell className="customer-service-tag-description">{id}</Cell>
        <Cell className="customer-service-tag-actions">
          {!systemGenerated && (
            <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
              <DropDownItem
                onClick={() => {
                  onAction(TABLE_ACTIONS.EDIT, { id })
                }}
              >
                {getMessage('customer-service-tags.table.actions.edit')}
              </DropDownItem>
              <DropDownItem
                onClick={() => {
                  onAction(TABLE_ACTIONS.TAGGING, { id })
                }}
              >
                {getMessage('customer-service-tags.table.actions.tagging')}
              </DropDownItem>
              <DropDownItem
                onClick={() => {
                  onAction(TABLE_ACTIONS.DELETE, { id })
                }}
              >
                {getMessage('customer-service-tags.table.actions.delete')}
              </DropDownItem>
            </DropDown>
          )}
        </Cell>
      </Row>
    )
  },
}

class ImportAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShown: false,
    }
    this.hideModal = this.hideModal.bind(this)
  }

  hideModal() {
    this.setState({
      modalShown: false,
    })
  }

  render() {
    return (
      <div className="import-tags-button">
        <Popup
          heading={getMessage('Import Tags')}
          show={this.state.modalShown}
          close={this.hideModal}
          className="import-popup"
        >
          <TagsImport uploadUrl="/customer-service/customer-tag-file" />
        </Popup>
        <button
          className="primary button"
          onClick={() => {
            this.setState({
              modalShown: true,
            })
          }}
        >
          + <span className="text">{getMessage('Import Tags')}</span>
        </button>
      </div>
    )
  }
}

export default class CustomerServiceTags extends Component {
  render() {
    const { props } = this
    return (
      <ListingPage
        menu={props.menu}
        className="customer-tags"
        title={getMessage('customer-service-tags.heading')}
        modalClassName="customer-tags-form"
        api={{
          url: '/ef-customer-core/tags',
          transform: (response) => {
            if (response.data.tags) {
              return response.data.tags
            }
            return response.data
          },
        }}
        emptyState={emptyState}
        form={{
          component: TagForm,
        }}
        tableProperties={tableProperties}
        addHeading={getMessage(customerServiceTagButton)}
        editHeading={getMessage('customer-service-tags.form.edit.heading')}
        headerActions={({ onAction }) => (
          <React.Fragment>
            <ImportAction />
            <button
              className="primary button"
              onClick={() => {
                onAction(TABLE_ACTIONS.ADD)
              }}
            >
              +{' '}
              <span className="text">
                {getMessage(customerServiceTagButton)}
              </span>
            </button>
          </React.Fragment>
        )}
        filters={{
          component: TagFiltersForm,
          transformSubmit: (formData) => {
            /* istanbul ignore next */
            return Object.assign({}, formData)
          },
        }}
      />
    )
  }
}
