import React, { Component } from 'react'
import { BaseForm, Select, Toggle } from '../../../components/Form'
import { getMessage } from '../../../lib/translator'
import API from '../../../lib/api'
import Loader from '../../../components/Loader'
import Image from '../../../components/Image'
import Tabs from '../../../components/Tabs'
import Twilio from './Configs/twilio'
import Alfa from './Configs/alfa'
import Exotel from './Configs/exotel'
import Mgage from './Configs/mgage'
import Whispir from './Configs/whispir'
import Email from './Configs/email'

import * as FLAGS from '../../../config/flags'

const noop = () => null

class Actions extends Component {
  render() {
    const { parent, icon, allowKey, className } = this.props
    const targetStatus = !parent.getState([allowKey])
    return (
      <div className={`actions ${className}`}>
        <Image src={icon} />
        <Toggle
          name="status"
          icons={false}
          value={parent.getState([allowKey])}
          onChange={() => parent.updateState([allowKey], targetStatus)}
        />
      </div>
    )
  }
}

class CommunicationForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state.loading = false
    this.changeTab = this.changeTab.bind(this)
    this.pages = []
    let tabIndex = 0

    if (FLAGS.ENABLE_SETTINGS_COM_CALL) {
      this.pages.push('call')
      this.tabIndexCall = tabIndex
      tabIndex++
      this.callProviders = [
        {
          text: getMessage('communication.twilio'),
          value: 'TWILIO',
        },
        {
          text: getMessage('communication.exotel'),
          value: 'EXOTEL',
        },
      ]
      this.callServiceProvider = {
        TWILIO: Twilio,
        EXOTEL: Exotel,
      }
    }

    if (FLAGS.ENABLE_SETTINGS_COM_SMS) {
      this.pages.push('SMS')
      this.tabIndexSms = tabIndex
      tabIndex++
      this.smsProviders = [
        {
          text: getMessage('communication.twilio'),
          value: 'TWILIO',
        },
        {
          text: getMessage('communication.mgage'),
          value: 'MGAGE',
        },
        {
          text: getMessage('communication.whispir'),
          value: 'WHISPIR',
        },
        {
          text: getMessage('communication.alfa'),
          value: 'ALFA',
        },
      ]
      this.smsServiceProvider = {
        TWILIO: Twilio,
        MGAGE: Mgage,
        WHISPIR: Whispir,
        ALFA: Alfa,
      }
    }

    this.pages.push('EMAIL')
    this.tabIndexEmail = tabIndex
    tabIndex++
    this.emailServiceProvider = {
      EMAIL: Email,
    }
  }

  componentDidMount() {
    this.setState({
      loading: true,
      index: 0,
    })
    this.api = new API({ url: '/config-service/config/communication' })
    this.api.get().then((response) => {
      const data = response.data.communication
      const callServiceProvider = data.callServiceProvider
      const callServiceProviderCredentials = {}
      callServiceProviderCredentials[callServiceProvider] = Object.assign(
        {},
        data.callServiceProviderCredentials
      )
      data.callServiceProviderCredentials = callServiceProviderCredentials

      const smsServiceProvider = data.smsServiceProvider
      const smsServiceProviderCredentials = {}
      smsServiceProviderCredentials[smsServiceProvider] = Object.assign(
        {},
        data.smsServiceProviderCredentials
      )
      data.smsServiceProviderCredentials = smsServiceProviderCredentials

      const emailServiceProvider = 'EMAIL'
      const emailServiceProviderCredentials = {}
      emailServiceProviderCredentials[emailServiceProvider] = Object.assign(
        {},
        data.email
      )
      data.emailServiceProviderCredentials = emailServiceProviderCredentials
      this.setState({
        values: data,
        loading: false,
      })
    })
  }

  componentWillUnmount() {
    this.api && this.api.cancel && this.api.cancel()
  }

  changeTab(index) {
    this.setState({
      index: index,
    })
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    })
    const formData = Object.assign({}, data)
    this.api = new API({ url: '/config-service/config' })
    const callServiceProvider = formData.callServiceProvider
    const smsServiceProvider = formData.smsServiceProvider
    formData.callServiceProviderCredentials =
      formData.callServiceProviderCredentials[callServiceProvider]
    formData.smsServiceProviderCredentials =
      formData.smsServiceProviderCredentials[smsServiceProvider]
    formData.email = formData.emailServiceProviderCredentials['EMAIL']
    delete formData.emailServiceProviderCredentials
    this.api.post({ communication: formData }).then(() => {
      this.setState({
        submitting: false,
      })
    })
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const { loading, values, index } = this.state

    let nameCallProvider,
      nameSmsProvider,
      CallServiceProvider,
      SmsServiceProvider

    if (FLAGS.ENABLE_SETTINGS_COM_CALL) {
      // eslint-disable-next-line no-extra-semi
      ;({ callServiceProvider: nameCallProvider } = values)
      CallServiceProvider = values.callServiceProvider
        ? this.callServiceProvider[values.callServiceProvider]
        : noop
    }

    if (FLAGS.ENABLE_SETTINGS_COM_SMS) {
      // eslint-disable-next-line no-extra-semi
      ;({ smsServiceProvider: nameSmsProvider } = values)
      SmsServiceProvider = values.smsServiceProvider
        ? this.smsServiceProvider[values.smsServiceProvider]
        : noop
    }

    const nameEmailProvider = 'EMAIL'
    const EmailServiceProvider = nameEmailProvider
      ? this.emailServiceProvider[nameEmailProvider]
      : noop

    return loading ? (
      <Loader />
    ) : (
      <Form>
        <Tabs items={this.pages} onClick={this.changeTab} />

        {index !== this.tabIndexEmail && (
          <div className="selection">
            <Select
              name="provider"
              label={getMessage('communication.service.provider')}
              placeholder={getMessage(
                'communication.service.provider.placeholder'
              )}
              options={
                index === this.tabIndexCall
                  ? this.callProviders
                  : this.smsProviders
              }
              {...this.generateStateMappers({
                stateKeys:
                  index === this.tabIndexCall
                    ? ['callServiceProvider']
                    : ['smsServiceProvider'],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        )}

        {FLAGS.ENABLE_SETTINGS_COM_CALL && index === this.tabIndexCall && (
          <CallServiceProvider
            parent={this}
            type="callServiceProviderCredentials"
            provider={nameCallProvider}
            allowKey="allowCall"
          />
        )}
        {FLAGS.ENABLE_SETTINGS_COM_SMS && index === this.tabIndexSms && (
          <SmsServiceProvider
            parent={this}
            type="smsServiceProviderCredentials"
            provider={nameSmsProvider}
            allowKey="allowSms"
          />
        )}
        {index === this.tabIndexEmail && (
          <EmailServiceProvider
            parent={this}
            type="emailServiceProviderCredentials"
            provider={nameEmailProvider}
            allowKey="allowEmail"
          />
        )}
        {(nameCallProvider || nameSmsProvider || nameEmailProvider) && (
          <React.Fragment>
            <SubmitButton disabled={this.state.submitting}>
              {getMessage('save')}
            </SubmitButton>
            <button className="button" type="button" onClick={this.props.back}>
              {getMessage('cancel')}
            </button>
          </React.Fragment>
        )}
      </Form>
    )
  }
}

export default CommunicationForm
export { Actions }
