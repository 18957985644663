import React from 'react'
import { Radio } from 'components/Form'
import { getMessage } from 'lib/translator'

const OfferRadioForm = ({ _this, itemDiscountTypes, hasOfferStarted }) => {
  return (
    <Radio
      name="discount-type-main"
      label={getMessage('offer.main.discount.type')}
      placeholder={getMessage('offer.main.discouont.type.placeholder')}
      options={itemDiscountTypes}
      {..._this.generateStateMappers({
        stateKeys: ['itemDiscountType'],
        loseEmphasisOnFill: true,
      })}
      readOnly={hasOfferStarted}
    />
  )
}

export default OfferRadioForm
