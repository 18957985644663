import React from 'react'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import API from '../../../../lib/api'
import Table, { Cell } from '../../../../components/Table'
import Header from '../../../../components/Table/Header'
import Row from '../../../../components/Table/Row'
import ProgressBar from './ProgressBar'
import './style.scss'
import moment from 'moment'
import ArrowRight from './Arrow-right.svg'
import ArrowLeft from './Arrow-left.svg'
import PickupIcon from './pickup.svg'
import PreorderIcon from 'icons/RB_Preorder_icon.svg'
import VehicleIcon from '../assets/vehicle-icon.svg'
import { formatTime } from '../../../../lib/datetime'
import { getAsapDuration } from '../../../../lib/commonlyused'
import { cutoffTimeOptionsGenerator } from '../Form'
import { Select, SingleDatePicker } from '../../../../components/Form'
import EditIcon from '../../Orders/Details/edit-icon.svg'
import { get } from '../../../../lib/storage'
import { getMessage } from '../../../../lib/translator'
import { Dialog } from '../../../../components/Popup'
import Loader from '../../../../components/Loader'
import { Link } from 'react-router-dom'
import {
  orderTypeOptions,
  generateTabularDataSkeleton,
  generateTabularData,
  getSortedSlots,
  slotsAndZones,
  isEquals,
} from './helpers'

const DATE_FORMAT = 'YYYY-MM-DD'

const ZoneRowTypeDelivery = ({
  capacityItem: item,
  value,
  onChange,
  onKeyDown,
}) => (
  <div className="delivery edit">
    <span className="delivery-count">{item.delivery.orderCount || 0}</span>
    <ProgressBar
      curr={item.delivery.orderCount || 0}
      total={item.delivery.capacity || 0}
    />
    {item.delivery.slotId ? (
      <input
        name="deliveryTotal"
        type="number"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    ) : (
      item.delivery.capacity || 0
    )}
    <input type="submit" />
  </div>
)

const ZoneRowTypePickup = ({
  capacityItem: item,
  value,
  onChange,
  onKeyDown,
}) => (
  <div className="pickup edit">
    <span className="pickup-count">{item.pickup.orderCount || 0}</span>
    <ProgressBar
      curr={item.pickup.orderCount || 0}
      total={item.pickup.capacity || 0}
    />
    {item.pickup.slotId ? (
      <input
        name="pickUpTotal"
        type="number"
        min="0"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    ) : (
      item.pickup.capacity || 0
    )}
  </div>
)
class Today extends React.Component {
  constructor(props) {
    super(props)

    this.tableRef = null
    this.table = {}

    this.state = {
      edit: [],
      sortedSlots: [],
      currentStore: get('store'),
      selectedOption: '00:00:00',
      selectedOrderType: 'DELIVERY',
      errorMessage: null,
      showErrorDialog: false,
      date:
        (this.props.location && this.props.location.date) ||
        moment().format(DATE_FORMAT),
      loading: true,
    }

    this.includes = this.includes.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.arrowClickRight = this.arrowClickRight.bind(this)
    this.arrowClickLeft = this.arrowClickLeft.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.disableEdit = this.disableEdit.bind(this)
    this.handleChangeCutOffTime = this.handleChangeCutOffTime.bind(this)
    this.handleChangeOrderType = this.handleChangeOrderType.bind(this)
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.onTodayButtonClick = this.onTodayButtonClick.bind(this)
    this.onStoreChange = this.onStoreChange.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  handleClickOutside(event) {
    if (this.tableRef && !this.tableRef.contains(event.target)) {
      this.setState({
        edit: [],
      })
    }
  }

  onStoreChange() {
    const currentStore = get('store')
    this.setState({
      currentStore,
      loading: true,
    })
  }

  onTodayButtonClick() {
    this.setState((prevState) => {
      return {
        loading: prevState.date !== moment().format(DATE_FORMAT),
        date: moment().format(DATE_FORMAT),
      }
    })
  }

  onDateChange(date) {
    this.setState((prevState) => {
      return {
        date,
        loading: prevState.date !== date,
      }
    })
  }
  closePopup() {
    this.setState({
      showErrorDialog: false,
      errorMessage: null,
    })
  }

  handleOnKeyDown(e, index, i) {
    if (e.key === 'Escape') {
      this.disableEdit(index, i)
    }
  }

  handleChangeCutOffTime(val) {
    this.setState({
      loading: true,
      selectedOption: val,
    })
  }

  handleChangeOrderType(val) {
    this.setState({ loading: true, selectedOrderType: val })
  }

  includes(x, y) {
    return this.state.edit.find((item) => item.x === x && item.y === y)
  }

  handleEdit(index, i, pickupCapacity, deliveryCapacity) {
    const newob = { x: index, y: i }
    const uniqueState = `${index},${i}`
    this.setState((prevState) => {
      return {
        edit: prevState.edit.concat(newob),
        indices: {
          ...prevState.indices,
          [uniqueState]: {
            pickUpTotal: pickupCapacity ? Number(pickupCapacity) : null,
            deliveryTotal: deliveryCapacity ? Number(deliveryCapacity) : null,
          },
        },
      }
    })
  }

  onChange(e, type, index, i) {
    const val = e.target.value
    if (type === 'delivery') {
      this.setState((prevState) => {
        return {
          indices: {
            ...prevState.indices,
            [`${index},${i}`]: {
              ...prevState.indices[`${index},${i}`],
              deliveryTotal: val,
            },
          },
        }
      })
    }
    if (type === 'pickup') {
      this.setState((prevState) => {
        return {
          indices: {
            ...prevState.indices,
            [`${index},${i}`]: {
              ...prevState.indices[`${index},${i}`],
              pickUpTotal: val,
            },
          },
        }
      })
    }
  }

  disableEdit(index, i) {
    const newEditState = this.state.edit.filter(
      (item) => item.x !== index || item.y !== i
    )
    this.setState({
      edit: newEditState,
    })
  }

  arrowClickLeft() {
    if (!this.once) {
      this.timer = setInterval(() => {
        if (this.tableRef) {
          this.once = true
          this.tableRef.scrollLeft -= 5
        }
      }, 5)
    }

    setTimeout(() => {
      this.once = undefined
      clearInterval(this.timer)
    }, 240)
  }
  arrowClickRight() {
    if (!this.once) {
      this.timer = setInterval(() => {
        if (this.tableRef) {
          this.once = true
          this.tableRef.scrollLeft += 5
        }
      }, 5)
    }

    setTimeout(() => {
      this.once = undefined
      clearInterval(this.timer)
    }, 240)
  }

  onSubmit(e, index, i, slot, zoneId) {
    e.preventDefault()
    if (this.state.selectedOption) {
      Object.keys(this.state.indices).map(async (key) => {
        if (!isEquals(this.state.indices[key], this.table[key])) {
          const deliveryOb = JSON.parse(JSON.stringify(this.state.indices[key]))
          const pickupOb = JSON.parse(JSON.stringify(this.state.indices[key]))
          this.putApi = new API({ url: `/logistics-service/zone-capacity` })
          const promises = []
          if (
            slot.delivery.slotId &&
            deliveryOb.deliveryTotal &&
            Number(deliveryOb.deliveryTotal) !==
              Number(this.table[key].deliveryTotal)
          ) {
            deliveryOb.slotId = Number(slot.delivery.slotId)
            const capacity = deliveryOb.deliveryTotal
            deliveryOb.capacity = capacity
            deliveryOb.orderType = 'DELIVERY'
            deliveryOb.zoneId = Number(zoneId)
            deliveryOb.date = `${this.state.date}T00:00:00+00:00`
            deliveryOb.storeId = Number(this.state.currentStore)
            deliveryOb.cutOffTime = this.state.selectedOption
            delete deliveryOb.deliveryTotal
            delete deliveryOb.pickUpTotal
            promises.push(this.putApi.put(deliveryOb))
          }

          if (
            slot.pickup.slotId &&
            pickupOb.pickUpTotal &&
            Number(pickupOb.pickUpTotal) !== Number(this.table[key].pickUpTotal)
          ) {
            pickupOb.slotId = Number(slot.pickup.slotId)
            const capacity = pickupOb.pickUpTotal
            pickupOb.capacity = capacity
            pickupOb.orderType = 'PICKUP'
            pickupOb.date = `${this.state.date}T00:00:00+00:00`
            pickupOb.storeId = Number(this.state.currentStore)
            pickupOb.cutOffTime = this.state.selectedOption
            delete pickupOb.deliveryTotal
            delete pickupOb.pickUpTotal
            promises.push(this.putApi.put(pickupOb))
          }

          Promise.all(promises).then((res) => {
            const newZoneCapacity = JSON.parse(JSON.stringify(this.state.data))
            res.forEach((response) => {
              if (response.code === 200 && response.status === 'SUCCESS') {
                const zoneCapacity =
                  (response.data && response.data.capacityPlanning) || {}
                const newSlot = zoneCapacity.slot.startTime
                  ? `${zoneCapacity.slot.startTime}-${zoneCapacity.slot.endTime}`
                  : zoneCapacity.slot.endTime
                const type =
                  zoneCapacity.orderType || zoneCapacity.slot.orderType
                const found = newZoneCapacity[newSlot].find(
                  (item) => item.zone.zoneId === zoneId
                )
                if (type === 'PICKUP') {
                  newZoneCapacity[newSlot] = newZoneCapacity[newSlot].map(
                    (item) => {
                      item.pickup.capacity = zoneCapacity.capacity
                      return item
                    }
                  )
                } else {
                  if (found) {
                    found.delivery.capacity = zoneCapacity.capacity
                  }
                }
              }
            })
            this.setState({
              data: newZoneCapacity,
            })
          })
        }
        return null
      })
    } else {
      this.setState({
        errorMessage: 'CutOff Time is not selected.',
        showErrorDialog: true,
      })
    }

    this.disableEdit(index, i)
    this.setState({
      indices: undefined,
    })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.api = new API({ url: '/logistics-service/slot-capacity' })
    const params = {
      date: this.state.date
        ? `${this.state.date}/${this.state.date}`
        : `${moment().format(DATE_FORMAT)}/${moment().format(DATE_FORMAT)}`,
      storeId: Number(this.state.currentStore || get('store')),
      cutOffTime: this.state.selectedOption,
      orderType: this.state.selectedOrderType,
    }
    this.api
      .get(params)
      .then((res) => {
        const filteredData = res.data.slotCapacity
        const filteredTabularData =
          (filteredData &&
            filteredData.length > 0 &&
            filteredData[0].capacity) ||
          []
        const tableProperties = slotsAndZones(filteredTabularData)
        const tabularSkeleton = generateTabularDataSkeleton(tableProperties)
        const data = generateTabularData(tabularSkeleton, filteredTabularData)
        const sortedSlots = getSortedSlots(tableProperties.totalSlots)
        this.setState({
          loading: false,
          data,
          tableProperties,
          sortedSlots,
        })
      })
      .catch((err) => {
        if (err.code === 401 || err.code === 403) {
          throw err
        }
        this.setState({
          loading: false,
          sortedSlots: [],
        })
      })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    this.api && this.api.cancel()
    this.putApi && this.putApi.cancel()
  }

  componentDidUpdate(prevProps, prevState) {
    const prevDate = prevState.date
    const currentDate = this.state.date

    if (
      this.state.currentStore !== prevState.currentStore ||
      prevState.selectedOption !== this.state.selectedOption ||
      prevState.selectedOrderType !== this.state.selectedOrderType ||
      (currentDate && currentDate !== prevDate)
    ) {
      this.api = new API({ url: '/logistics-service/slot-capacity' })
      const params = {
        date: this.state.date
          ? `${this.state.date}/${this.state.date}`
          : `${moment().format(DATE_FORMAT)}/${moment().format(DATE_FORMAT)}`,
        storeId: Number(this.state.currentStore),
        cutOffTime: this.state.selectedOption,
        orderType: this.state.selectedOrderType,
      }
      this.api
        .get(params)
        .then((res) => {
          if (res.code === 200 && res.status === 'SUCCESS') {
            const filteredData = res.data.slotCapacity
            const filteredTabularData =
              (filteredData &&
                filteredData.length > 0 &&
                filteredData[0].capacity) ||
              []
            const tableProperties = slotsAndZones(filteredTabularData)
            const tabularSkeleton = generateTabularDataSkeleton(tableProperties)
            const data = generateTabularData(
              tabularSkeleton,
              filteredTabularData
            )
            const sortedSlots = getSortedSlots(tableProperties.totalSlots)
            this.setState({
              loading: false,
              data,
              tableProperties,
              sortedSlots,
            })
          } else {
            this.setState({
              loading: false,
              sortSlots: [],
            })
          }
        })
        .catch((err) => {
          if (err.code === 400) {
            this.setState({
              loading: false,
              sortedSlots: [],
            })
            return err
          }
          throw err
        })
    }
  }

  renderTableContentsForPreorder() {
    return (
      <React.Fragment>
        <Header />
        {this.state.sortedSlots.map((slot, index) => {
          const preorderSlot = this.state.data[slot][0]
          return (
            <Row key={index}>
              <Cell>
                <div className="delivery">
                  <span className="delivery-count">
                    {preorderSlot.orderCount}
                  </span>
                  <ProgressBar
                    curr={preorderSlot.orderCount}
                    total={preorderSlot.capacity}
                  />
                  <span className="delivery-count">
                    {preorderSlot.capacity}
                  </span>
                </div>
              </Cell>
            </Row>
          )
        })}
      </React.Fragment>
    )
  }

  render() {
    let content = null
    if (this.state.loading) {
      content = <Loader />
    } else {
      if (this.state.sortedSlots.length > 0) {
        content = (
          <div>
            <div className="table-wrapper">
              <div className="outer-table">
                <div>
                  <div className="slot-table">
                    <div className="slot-table-heading">
                      {getMessage('cp.today.table.slot')}
                    </div>
                    {this.state.sortedSlots.map((data, index) => {
                      const slot = data.split('-')
                      const slotHeading =
                        slot.length > 1
                          ? `${formatTime(slot[0])} - ${formatTime(slot[1])}`
                          : getAsapDuration('12:00 AM', slot[0])
                      const slotMapToOrderType =
                        this.state.tableProperties.slotMapToOrderType
                      const orderType = slotMapToOrderType[data]
                      return (
                        <Cell key={index}>
                          <span className="slot-duration">{slotHeading}</span>
                          {orderType === 'BOTH' && (
                            <span className="slot-icons">
                              <span className="pickup-icon">
                                <img src={PickupIcon} alt="PickupIcon" />
                              </span>
                              <span className="delivery-icon">
                                <img src={VehicleIcon} alt="VehicleIcon" />
                              </span>
                            </span>
                          )}
                          {orderType === 'DELIVERY' && (
                            <span className="slot-icons DELIVERY">
                              <span className="delivery-icon">
                                <img src={VehicleIcon} alt="VehicleIcon" />
                              </span>
                            </span>
                          )}
                          {orderType === 'PICKUP' && (
                            <span className="slot-icons PICKUP">
                              <span className="pickup-icon">
                                <img src={PickupIcon} alt="PickupIcon" />
                              </span>
                            </span>
                          )}
                          {orderType === 'RB_PREORDER' && (
                            <span className="slot-icons RB_PREORDER">
                              <span className="preorder-icon">
                                <img src={PreorderIcon} alt="PreorderIcon" />
                              </span>
                            </span>
                          )}
                        </Cell>
                      )
                    })}
                  </div>
                </div>
                <div>
                  <Table
                    tableRef={(el) => {
                      this.tableRef = el
                    }}
                  >
                    {this.state.selectedOrderType === 'RB_PREORDER' ? (
                      this.renderTableContentsForPreorder()
                    ) : (
                      <React.Fragment>
                        <Header
                          items={this.state.tableProperties.totalZones.map(
                            (zone) => zone.name
                          )}
                        />
                        {this.state.sortedSlots.map((slot, index) => {
                          const slotMapToOrderType =
                            this.state.tableProperties.slotMapToOrderType
                          const orderType = slotMapToOrderType[slot]
                          const zoneCapacity = this.state.data[slot]
                          return zoneCapacity.length > 0 ? (
                            <Row key={index}>
                              {zoneCapacity &&
                                zoneCapacity.map((item, i) => {
                                  this.table[`${index},${i}`] = {
                                    deliveryTotal:
                                      item.delivery.capacity || null,
                                    pickUpTotal: item.pickup.capacity || null,
                                  }
                                  if (this.includes(index, i)) {
                                    return (
                                      <Cell key={i}>
                                        <form
                                          onSubmit={(e) =>
                                            this.onSubmit(
                                              e,
                                              index,
                                              i,
                                              {
                                                delivery: {
                                                  slotId: item.delivery.slotId,
                                                },
                                                pickup: {
                                                  slotId: item.pickup.slotId,
                                                },
                                              },
                                              item.zone.zoneId
                                            )
                                          }
                                        >
                                          {orderType === 'BOTH' && (
                                            <div className="both">
                                              <ZoneRowTypePickup
                                                capacityItem={item}
                                                value={
                                                  this.state.indices[
                                                    `${index},${i}`
                                                  ].pickUpTotal || ''
                                                }
                                                onChange={(e) =>
                                                  this.onChange(
                                                    e,
                                                    'pickup',
                                                    index,
                                                    i
                                                  )
                                                }
                                                onKeyDown={(e) =>
                                                  this.handleOnKeyDown(
                                                    e,
                                                    index,
                                                    i
                                                  )
                                                }
                                              />
                                              <ZoneRowTypeDelivery
                                                capacityItem={item}
                                                value={
                                                  this.state.indices[
                                                    `${index},${i}`
                                                  ].deliveryTotal || ''
                                                }
                                                onChange={(e) =>
                                                  this.onChange(
                                                    e,
                                                    'delivery',
                                                    index,
                                                    i
                                                  )
                                                }
                                                onKeyDown={(e) =>
                                                  this.handleOnKeyDown(
                                                    e,
                                                    index,
                                                    i
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                          {orderType === 'PICKUP' && (
                                            <ZoneRowTypePickup
                                              capacityItem={item}
                                              value={
                                                this.state.indices[
                                                  `${index}',${i}`
                                                ].pickUpTotal || ''
                                              }
                                              onChange={(e) =>
                                                this.onChange(
                                                  e,
                                                  'pickup',
                                                  index,
                                                  i
                                                )
                                              }
                                              onKeyDown={(e) =>
                                                this.handleOnKeyDown(
                                                  e,
                                                  index,
                                                  i
                                                )
                                              }
                                            />
                                          )}
                                          {orderType === 'DELIVERY' && (
                                            <ZoneRowTypeDelivery
                                              capacityItem={item}
                                              value={
                                                this.state.indices[
                                                  `${index},${i}`
                                                ].deliveryTotal || ''
                                              }
                                              onChange={(e) =>
                                                this.onChange(
                                                  e,
                                                  'delivery',
                                                  index,
                                                  i
                                                )
                                              }
                                              onKeyDown={(e) =>
                                                this.handleOnKeyDown(
                                                  e,
                                                  index,
                                                  i
                                                )
                                              }
                                            />
                                          )}
                                        </form>
                                      </Cell>
                                    )
                                  }
                                  return (
                                    <Cell key={i}>
                                      {orderType === 'BOTH' && (
                                        <div className="both">
                                          <div className="pickup">
                                            <span className="pickup-count">
                                              {item.pickup.orderCount || 0}
                                            </span>
                                            <ProgressBar
                                              curr={item.pickup.orderCount || 0}
                                              total={item.pickup.capacity || 0}
                                            />
                                            <span className="pickup-capacity">
                                              {item.pickup.capacity || 0}
                                            </span>
                                          </div>
                                          <div className="delivery">
                                            <span className="delivery-count">
                                              {item.delivery.orderCount || 0}
                                            </span>
                                            <ProgressBar
                                              curr={
                                                item.delivery.orderCount || 0
                                              }
                                              total={
                                                item.delivery.capacity || 0
                                              }
                                            />
                                            <span className="delivery-capacity">
                                              {item.delivery.capacity || 0}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      {orderType === 'DELIVERY' && (
                                        <div>
                                          <div className="delivery">
                                            <span className="delivery-count">
                                              {item.delivery.orderCount || 0}
                                            </span>
                                            <ProgressBar
                                              curr={
                                                item.delivery.orderCount || 0
                                              }
                                              total={
                                                item.delivery.capacity || 0
                                              }
                                            />
                                            <span className="delivery-capacity">
                                              {item.delivery.capacity || 0}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      {orderType === 'PICKUP' && (
                                        <div>
                                          <div className="pickup">
                                            <span className="pickup-count">
                                              {item.pickup.orderCount || 0}
                                            </span>
                                            <ProgressBar
                                              curr={item.pickup.orderCount || 0}
                                              total={item.pickup.capacity || 0}
                                            />
                                            <span className="pickup-capacity">
                                              {item.pickup.capacity || 0}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      {this.state.edit.length === 0 ? (
                                        <img
                                          src={EditIcon}
                                          alt="Edit-icon"
                                          onClick={() =>
                                            this.handleEdit(
                                              index,
                                              i,
                                              item.pickup.capacity,
                                              item.delivery.capacity
                                            )
                                          }
                                        />
                                      ) : null}
                                    </Cell>
                                  )
                                })}
                            </Row>
                          ) : (
                            <Row>
                              {' '}
                              <Cell>No Zones to display</Cell>{' '}
                            </Row>
                          )
                        })}
                      </React.Fragment>
                    )}
                  </Table>
                  {this.state.selectedOrderType !== 'RB_PREORDER' && (
                    <React.Fragment>
                      <span
                        className="arrow-right"
                        onClick={this.arrowClickRight}
                      >
                        <img src={ArrowRight} alt="arrow-right" />
                      </span>
                      <span
                        className="arrow-left"
                        onClick={this.arrowClickLeft}
                      >
                        <img src={ArrowLeft} alt="arrow-right" />
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="bottom-info">
              <p>
                <span>
                  <img src={VehicleIcon} alt="VehicleIcon" />
                  {getMessage('cp.today.delivery')}
                </span>
                <span>
                  <img src={PickupIcon} alt="VehicleIcon" />
                  {getMessage('cp.today.pickup')}
                </span>
                <span>
                  <img src={PreorderIcon} alt="VehicleIcon" />
                  {getMessage('cp.today.preorder')}
                </span>
              </p>
            </div>
          </div>
        )
      } else {
        content = <p>No Data Available</p>
      }
    }
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="today"
        storeDependent
        onChange={this.onStoreChange}
      >
        <header className="today-capacity-plan">
          <span className="today-heading">{getMessage('cp.today')}</span>
          <span className="header-icons">
            <SingleDatePicker
              allowAllDates
              displayFormat="YYYY-MM-DD"
              onChange={this.onDateChange}
              value={this.state.date}
            />
            <span
              onClick={this.onTodayButtonClick}
              className={`today-button ${
                this.state.date === moment().format(DATE_FORMAT)
                  ? 'green'
                  : 'black'
              }`}
            >
              {getMessage('cp.today.button')}
            </span>
            <Link to="/operations/capacity-planning">
              <span className="button primary">{getMessage('cp.title')}</span>
            </Link>
          </span>
        </header>
        <div className="selection-container">
          <Select
            className="cut-off-time-select"
            name="cut-off-time-select"
            placeholder={getMessage('cp.today.cutofftime')}
            options={cutoffTimeOptionsGenerator()}
            value={this.state.selectedOption}
            onChange={this.handleChangeCutOffTime}
          />
          <Select
            className="order-type-select"
            name="order-type-select"
            placeholder={getMessage('cp.today.orderTypes')}
            options={orderTypeOptions}
            value={this.state.selectedOrderType}
            onChange={this.handleChangeOrderType}
            testid="order-type-select"
          />
        </div>
        {content}
        {this.state.showErrorDialog && (
          <Dialog
            show={this.state.showErrorDialog}
            information={this.state.errorMessage}
            close={this.closePopup}
            closeText={getMessage('vehiclePlanner.error.dialog.okay')}
          />
        )}
      </AuthenticatedPage>
    )
  }
}

export default Today
