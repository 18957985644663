import React from 'react'
import { Checkbox } from '../../Form'
import { getMessage } from 'lib/translator'

const MEMBERSHIP_TYPES = ['UNION', 'FDC', 'PTR']

const UserSegment = ({ _this }) => {
  const { memberships } = _this.state.values
  const membershipsCheckedValue = memberships?.length > 0 && memberships[0]

  const handleChange = (value, type) => {
    const values = Object.assign({}, _this.state.values)
    values.memberships = []
    if (value) {
      values.memberships = [type]
    }
    _this.setState({
      values,
    })
  }

  return (
    <div className="row user-segment">
      <div className="col-12 single-column-field mb-1">
        <div className="field-label">
          {getMessage('rewards.catalogue.userSegment')}
        </div>
        <div className="normal-text mb-1">
          {getMessage('rewards.catalogue.userSegment.subtitle')}
        </div>
        {MEMBERSHIP_TYPES.map((type, typeIndex) => (
          <Checkbox
            key={typeIndex}
            testid={`membership-${type.toLowerCase()}`}
            name={`membership${type}`}
            value={membershipsCheckedValue === type}
            controlled="checked"
            inlineLabel={getMessage(
              `rewards.catalogue.membership.${type.toLowerCase()}`
            )}
            onChange={(value) => handleChange(value, type)}
          />
        ))}
      </div>
    </div>
  )
}

export default UserSegment
