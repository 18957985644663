import React, { useContext, useEffect, useRef } from 'react'
import { arrayMove } from 'react-sortable-hoc'

import { useState } from 'react'
import API from '../../../../lib/api'

import './UpdatedLayout.css'
import './ActionsToolbar.css'

import { SortableList } from './SortableList'
import ActionsToolbar from './ActionsToolbar'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { getLayoutUrl } from './ConfirmationDialog'

const UpdatedLayout = ({
  data = [],
  tabName = 'Online',
  onEditClick,
  refetchLayout,
  onRemoveClick,
  view,
  setView,
  showDialog,
  onChangeLayout,
  timeline,
  currentSubTab,
  urlValueForSearch,
  isCustomizeEnabled,
  isLayoutEditAllowed,
}) => {
  const targetTimeline = timeline || 'default'
  const items = data
  const [selectedItems, setSelectedItems] = useState(new Set())

  const [isLoading, setIsLoading] = useState(false)

  const hasMounted = useRef(false)

  const urlId = isCustomizeEnabled ? currentSubTab : 'home'

  const splitConfig = useContext(SplitContext)

  const isNewLayoutAPIEnabled =
    splitConfig?.splits?.[SPLIT_FEATURES.BACK_DIS_WEBSITE_HOME_LAYOUT]
      ?.treatment === 'on' && urlId === 'home'

  const platform =
    tabName === 'O2O' ? (isNewLayoutAPIEnabled ? 'offline' : 'o2o') : undefined

  useEffect(() => {
    hasMounted.current = true

    return () => {
      hasMounted.current = false
    }
  }, [])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const _data = arrayMove(items, oldIndex, newIndex)

    onChangeLayout(_data.map(({ id: _, ...rest }) => rest))
  }

  const onAddSelected = (value) => {
    if (selectedItems.has(value)) {
      selectedItems.delete(value)
      setSelectedItems(new Set(selectedItems))
    } else {
      setSelectedItems(new Set(selectedItems.add(value)))
    }
  }

  const handleUpdateTimeline = async ({
    validFrom,
    validTill,
    layouts,
    targetPlatform,
  }) => {
    setIsLoading(true)

    const url = isNewLayoutAPIEnabled
      ? getLayoutUrl('NEW_PAGE', urlId)
      : getLayoutUrl('OLD_PAGE', urlId)

    try {
      await new API({
        url: url,
      })
        .put({
          layouts,
          platform: targetPlatform,
          validFrom,
          validTill,
          ...(isCustomizeEnabled && { url: urlValueForSearch }),
        })
        .then(() => {
          refetchLayout()
          showDialog()
        })
    } catch (error) {
      console.error(error)
    }

    hasMounted.current && setIsLoading(false)
  }

  const handleDuplicate = async ({ status }) => {
    const layoutsToDuplicate = items
      .filter((item) => selectedItems.has(item.id))
      .map(({ id: _, ...item }) => ({
        ...item,
        status: status,
        data: item.data.title
          ? {
              ...item.data,
              title: `Copy of ${item.data.title}`,
            }
          : item.data,
      }))

    const validFrom =
      targetTimeline === 'default' ? undefined : targetTimeline.split('/')[0]
    const validTill =
      targetTimeline === 'default' ? undefined : targetTimeline.split('/')[1]

    const url = isNewLayoutAPIEnabled
      ? getLayoutUrl('NEW_PAGE', urlId)
      : getLayoutUrl('OLD_URL')
    let param = {}
    if (isNewLayoutAPIEnabled) {
      param = {
        platform,
        validFrom,
        validTill,
        ...(isCustomizeEnabled && { url: urlValueForSearch }),
      }
    } else {
      param = {
        id: urlId,
        platform,
        validFrom,
        validTill,
        ...(isCustomizeEnabled && { url: urlValueForSearch }),
      }
    }
    const layoutResponse = await new API({
      url: url,
    }).get(param)

    const swimlanes = layoutResponse.data.page[0].layouts

    await handleUpdateTimeline({
      layouts: [...swimlanes, ...layoutsToDuplicate],
      targetPlatform: platform,
      validFrom,
      validTill,
    })
  }

  const handleReplicatetoO2O = async ({ timeLine }) => {
    const validFrom =
      timeLine === 'default' ? undefined : timeLine.split('/')[0]
    const validTill =
      timeLine === 'default' ? undefined : timeLine.split('/')[1]

    const layoutsToReplicate = items
      .filter((item) => selectedItems.has(item.id))
      .map(({ id: _, ...item }) => item)
    const url = isNewLayoutAPIEnabled
      ? getLayoutUrl('NEW_PAGE', urlId)
      : getLayoutUrl('OLD_URL')
    let param = {}
    if (isNewLayoutAPIEnabled) {
      param = {
        platform: 'offline',
      }
    } else {
      param = {
        id: 'home',
        platform: 'o2o',
      }
    }
    const o2oResponse = await new API({
      url: url,
    }).get(param)

    const o2oSwimlanes = o2oResponse.data.page[0].layouts

    await handleUpdateTimeline({
      validFrom,
      validTill,
      targetPlatform: isNewLayoutAPIEnabled ? 'offline' : 'o2o',
      layouts: [...o2oSwimlanes, ...layoutsToReplicate],
    })
  }

  const updateStatus = async (status) => {
    const layoutsToActivate = items.map(({ id, ...item }) => ({
      ...item,
      status: selectedItems.has(id) ? status : item.status,
    }))

    const validFrom =
      targetTimeline === 'default' ? undefined : targetTimeline.split('/')[0]
    const validTill =
      targetTimeline === 'default' ? undefined : targetTimeline.split('/')[1]

    await handleUpdateTimeline({
      layouts: layoutsToActivate,
      targetPlatform: platform,
      validFrom,
      validTill,
    })
  }

  const handleEditClick = (index) => {
    onEditClick(index)
  }

  return (
    <>
      {data && data.length > 0 && (
        <ActionsToolbar
          selectedItems={selectedItems}
          items={items}
          tabName={tabName}
          handleDuplicate={handleDuplicate}
          handleReplicatetoO2O={handleReplicatetoO2O}
          view={view}
          setView={setView}
          updateStatus={updateStatus}
          currentSubTab={currentSubTab}
          setSelectedItems={setSelectedItems}
        />
      )}

      {isLoading && <div>Loading</div>}

      <div>
        <SortableList
          items={items}
          pressDelay={130}
          selectedItems={selectedItems}
          onAddSelected={onAddSelected}
          onRemoveClick={onRemoveClick}
          onSortEnd={onSortEnd}
          lockAxis="y"
          onEditClick={handleEditClick}
          helperClass="is-dragging"
          view={view}
          isLayoutEditAllowed={isLayoutEditAllowed}
        />
      </div>
    </>
  )
}

export default UpdatedLayout
