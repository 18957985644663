import React from 'react'
import {
  BaseForm,
  Input,
  DateRangePicker,
  Select,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

const leaveType = [
  {
    text: 'Earned',
    value: 'EARNED',
  },
  {
    text: 'Sick',
    value: 'SICK',
  },
  {
    text: 'Halfday',
    value: 'HALFDAY',
  },
  {
    text: 'Maternity',
    value: 'MATERNITY',
  },
  {
    text: 'Paternity',
    value: 'PATERNITY',
  },
]

export default class LeaveForm extends BaseForm {
  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    return (
      <Form className="my-leaves-form">
        <div className="form-fields">
          <DateRangePicker
            label={getMessage('leaves.form.duration')}
            displayFormat="YYYY-MM-DD"
            required
            minimumNights={0}
            startDatePlaceholder={getMessage('leaves.filters.from')}
            endDatePlaceholder={getMessage('leaves.filters.to')}
            isOutsideRange={() => false}
            {...this.generateStateMappers({
              stateKeys: ['dateRange'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div className="form-fields">
          <Input
            label={getMessage('leaves.form.reason')}
            placeholder={getMessage('leaves.form.reason.placeholder')}
            required
            {...this.generateStateMappers({
              stateKeys: ['reason'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Select
            label={getMessage('leaves.form.type')}
            placeholder={getMessage('leaves.form.type.placeholder')}
            name="type"
            required
            options={leaveType}
            {...this.generateStateMappers({
              stateKeys: ['type'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className="form-actions">
          <CancelButton>{getMessage('leaves.form.cancel')}</CancelButton>
          <SubmitButton>{getMessage('leaves.apply')}</SubmitButton>
        </div>
      </Form>
    )
  }
}
