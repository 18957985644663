import React, { Component } from 'react'
import { Input, Phone } from '../../../../components/Form'
import { Actions } from '../form'
import { getMessage } from '../../../../lib/translator'
import TwilioIcon from './twilio.png'

class Twilio extends Component {
  render() {
    const { parent, type, provider, allowKey } = this.props
    const _this = parent
    const readOnly = _this.getState([allowKey]) === false
    return (
      <React.Fragment>
        <Actions parent={_this} icon={TwilioIcon} allowKey={allowKey} />
        <div className="form-fields">
          <Input
            label={getMessage('communication.account.sid')}
            placeholder={getMessage('communication.account.placeholder')}
            name="account-sid"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'accountSid'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            label={getMessage('communication.token')}
            type="password"
            placeholder={getMessage('communication.token.placeholder')}
            name="auth-token"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'authToken'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Phone
            label={getMessage('communication.callnumber')}
            placeholder={getMessage('communication.callnumber.placeholder')}
            name="call-number"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'from'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default Twilio
