import React from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import TierForm, { USER_SEGMENTS } from './Form'
import { getMessage } from '../../../lib/translator'

const emptyState = {
  message: getMessage('logistics.tier.helpItems.message'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      + {getMessage('logistics.tier.add.text')}
    </button>
  ),
}

const tableProperties = () => {
  return {
    headers: [
      'Name',
      'Fee Configuration',
      'Segment',
      'Default Tier',
      getMessage('brand.header.actions'),
    ],
    row: ({
      id,
      name,
      userSegment,
      tiers,
      isDefault,
      onAction,
      description,
    }) => (
      <Row>
        <Cell>
          <span
            className="category-name"
            onClick={() => {
              onAction(TABLE_ACTIONS.EDIT, { id })
            }}
          >
            {name}
          </span>
        </Cell>
        <Cell>
          {tiers &&
            tiers.map((tier) => (
              <div
                key={`tier-${tier.id}`}
              >{`$${tier.startAmount} - $${tier.endAmount} : $${tier.fee}`}</div>
            ))}
        </Cell>
        <Cell>
          <span className="user-segment">
            {userSegment || USER_SEGMENTS.B2C}
          </span>
        </Cell>
        <Cell>
          <span className={isDefault ? 'default' : undefined}>
            {isDefault ? 'Default' : ''}
          </span>
        </Cell>
        <Cell className="brand-actions">
          <DropDown
            data-testid="tierDropDown"
            icon={<img src={ICONS.VELLIP} alt="⋮" />}
          >
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT, { id })
              }}
            >
              {getMessage('tier.action.edit')}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.DELETE, { id })
              }}
            >
              {getMessage('tier.action.delete')}
            </DropDownItem>
            {userSegment !== USER_SEGMENTS.B2B && (
              <DropDownItem
                onClick={() => {
                  onAction(
                    TABLE_ACTIONS.UPDATE_CUSTOM,
                    { id },
                    {
                      isDefault: true,
                      name: name,
                      tiers: tiers,
                      description: description,
                    }
                  )
                }}
              >
                {getMessage('tier.action.default')}
              </DropDownItem>
            )}
          </DropDown>
        </Cell>
      </Row>
    ),
  }
}

const MultiTier = () => {
  return (
    <ListingPage
      className="brands-page"
      addHeading={getMessage('logistics.tier.add.head')}
      editHeading={getMessage('logistics.tier.add.head')}
      title={getMessage('logistics.tier.heading')}
      api={{
        url: '/account-service/shipping-tier',
        transform: (response) => response.data,
      }}
      showLanguageDropDown
      emptyState={emptyState}
      tableProperties={tableProperties()}
      headerActions={({ onAction }) => (
        <button
          className="primary button"
          onClick={() => {
            onAction(TABLE_ACTIONS.ADD)
          }}
        >
          +{' '}
          <span className="text">{getMessage('logistics.tier.add.text')}</span>
        </button>
      )}
      modalClassName="customized-modal"
      form={{
        component: TierForm,
        transformSubmit: (formData) => {
          const arr = Object.keys(formData)
          const deliveryList = arr
            .filter((item) => item.includes('delivery'))
            .sort()
          let tiers = []
          if (deliveryList.length > 1) {
            tiers = deliveryList.map((item, idx) => {
              const key = item.split('-')[1]
              const obj = {
                fee: formData[`delivery-${key}`],
              }
              if (formData[`id-${key}`]) {
                obj['id'] = formData[`id-${key}`]
              }
              if (idx === 0) {
                return {
                  startAmount: 0,
                  endAmount: formData[`end-${key}`],
                  ...obj,
                }
              } else if (idx === deliveryList.length - 1) {
                const oldKey = deliveryList[idx - 1].split('-')[1]
                return {
                  startAmount: formData[`end-${oldKey}`],
                  endAmount: 999999,
                  ...obj,
                }
              } else {
                const oldKey = deliveryList[idx - 1].split('-')[1]
                return {
                  startAmount: formData[`end-${oldKey}`],
                  endAmount: formData[`end-${key}`],
                  ...obj,
                }
              }
            })
          } else {
            tiers.push({
              startAmount: 0,
              endAmount: 999999,
              fee: formData[deliveryList[0]],
            })
          }
          if (formData.id) {
            return {
              id: formData.id,
              name: formData['name'],
              description: formData['description']
                ? formData['description']
                : '',
              isDefault: formData['isDefault'] ? formData['isDefault'] : false,
              userSegment: formData['userSegment'] || USER_SEGMENTS.B2B,
              tiers: tiers,
            }
          }
          return {
            name: formData['name'],
            description: formData['description'] ? formData['description'] : '',
            isDefault: formData['isDefault'] ? formData['isDefault'] : false,
            userSegment: formData['userSegment'] || USER_SEGMENTS.B2B,
            tiers: tiers,
          }
        },
        allowDelete: true,
        overwriteWithApiParams: false,
      }}
    />
  )
}

export default MultiTier
