import React from 'react'

import { BaseForm, VALIDATION_TYPES, Input } from '../../../../components/Form'
import { getMessage } from 'lib/translator'
import Loader from '../../../../components/Loader'
import { FormButtons, ErrorScreen } from '../../../../components/Rewards/utils'
import { Checkbox } from 'components/Form/Inputs'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'

import {
  handleApis,
  handleError,
  fetchDetailsById,
} from 'components/Rewards/services'

const operationsAllowedOptions = [
  {
    text: 'Award Only',
    value: 'awardOnly',
  },
  {
    text: 'Award and Redeem',
    value: 'awardAndRedeem',
  },
  {
    text: 'Vouchers',
    value: 'vouchers',
  },
  {
    text: 'Void',
    value: 'void',
  },
  {
    text: 'Print Balance',
    value: 'printBalance',
  },
]
class MerchantForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      values: {
        operationsAllowed: {
          awardOnly: true,
          awardAndRedeem: true,
          vouchers: false,
          void: true,
          printBalance: true,
        },
      },
    }
  }
  async componentDidMount() {
    if (this.props.method === 'edit') {
      this.setState((prevState) => ({
        values: {
          ...prevState.values,
          loading: true,
        },
      }))

      const { clsMerchantID: id } = this.props.value
      try {
        const response = await fetchDetailsById(id, 'merchants')
        this.setState((prevState) => ({
          values: {
            ...prevState.values,
            loading: false,
            ...response,
          },
        }))
      } catch (err) {
        handleError(err, this)
      }
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    if (this.isFormValid()) {
      this.setState((prevState) => ({
        values: {
          ...prevState.values,
          loading: true,
        },
      }))

      const { clsMerchantID, operationsAllowed, name } = this.state.values
      const mapData = { clsMerchantID, operationsAllowed, name }
      const id = this.props.value?.clsMerchantID
      handleApis(mapData, '/admin/merchants', this, id)
    }
  }
  render() {
    const { Form } = this.components
    const { loading, error } = { ...this.state.values }

    return (
      <div className="rewards container merchant">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="row">
              {error && <div className="error">{error}</div>}
              <div className="col-6">
                <Input
                  label={getMessage(`rewards.clsMerchantId`)}
                  placeholder={getMessage(`rewards.clsMerchantId`)}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['clsMerchantID'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
              <div className="col-6">
                <Input
                  label={getMessage(`rewards.merchant.name`)}
                  placeholder={getMessage(`rewards.merchant.name`)}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['name'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label>{getMessage(`rewards.merchant.operations`)}</label>
              </div>
            </div>
            <div className="row">
              <div className="col-6 pr">
                <div className="box">
                  {operationsAllowedOptions.map((option, i) => {
                    const checkedValues = this.state.values?.operationsAllowed
                    const defaultValue = checkedValues?.[option.value]

                    return (
                      <Checkbox
                        inlineLabel={option.text}
                        key={i}
                        name={option.text}
                        {...this.generateStateMappers({
                          stateKeys: ['operationsAllowed', option.value],
                          loseEmphasisOnFill: true,
                        })}
                        value={defaultValue}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
            <br />
            {this.props.method === 'edit' && (
              <DisplayCreatorEditorInfo {...this.state.values} />
            )}
            <FormButtons buttons={this.buttons} type="merchant" />
            {error && <ErrorScreen error={error} />}
          </Form>
        )}
      </div>
    )
  }
}
export default MerchantForm
