import React, { Fragment } from 'react'
import moment from 'moment'

import { Select } from '../../Form'
import { getMessage } from '../../../lib/translator'
import { RULE_KEYS } from '../utils'

const CARD_BINS_OPTIONS = [
  {
    text: 'Select Bank',
    value: '',
  },
  {
    text: 'Trust Bank (Card Bin(s): 417971)',
    value: '417971',
  },
  {
    text: 'Standard Chartered Bank (Card Bin(s): 540275, 542113, 553402, 514916, 549834, 430092, 486419, 486418, 450873, 450934, 423179)',
    value:
      '540275,542113,553402,514916,549834,430092,486419,486418,450873,450934,423179',
  },
]

const BankNetworkCondition = ({ _this, task, taskIndex, method }) => (
  <Fragment>
    <br />
    <div className="cardbins single-column-field mb-1">
      <div className="field-label">
        <strong>{getMessage('omnichallenge.task.banknetwork.label')}</strong>
      </div>
      <div className="normal-text mb-1">
        {getMessage('omnichallenge.task.banknetwork.placeholder')}
      </div>
      <Select
        options={CARD_BINS_OPTIONS}
        {..._this.generateStateMappers({
          stateKeys: [
            'taskList',
            taskIndex,
            'rule',
            RULE_KEYS[task.userAction][0],
            'cardBins',
          ],
          loseEmphasisOnFill: true,
        })}
        readOnly={
          method === 'edit' && moment().isAfter(_this.state.values.startAt)
        }
        testid={`cardbins-${taskIndex}`}
        name={`cardbins-${taskIndex}`}
        key={`cardbins-${taskIndex}`}
      />
    </div>
    <br />
  </Fragment>
)

export default BankNetworkCondition
