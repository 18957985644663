import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import { formatIsoDate } from 'lib/datetime'
import Button from 'components.new/button/Button'
import ShieldIcon from 'icons/shield-icon.svg'
import Loader from 'components/Loader'
import API from 'lib/api'
import { STORE_TECH_HOST } from 'config/app'
import moment from 'moment'
import preorder from './preorder.svg'
import RbPreorderIcon from 'icons/RB_Preorder_icon.svg'
import _ from 'lodash'
import './style.scss'

const deliveryOrderStatus = {
  COMPLETED: 'order.status.completed',
  CANCELLED: 'order.status.cancelled',
  PENDING: 'order.status.pending',
  PICKED: 'order.status.picked',
  CHECKED: 'order.status.checked',
  PACKED: 'order.status.packed',
  PICKING: 'order.status.picking',
  'PARTIALLY-PICKED': 'order.status.partiallypicked',
  CHECKING: 'order.status.checking',
  PACKING: 'order.status.packing',
  DISPATCHED: 'order.status.dispatched',
  RETURNED: 'order.status.returned',
  PROCESSING: 'order.status.processing',
  READY_FOR_PICKUP: 'order.status.readyToPickup',
}

const deviceNames = {
  MOBILE_ANDROID_WEB: 'Android',
  DESKTOP_NA_WEB: 'Desktop',
  MOBILE_IOS_WEB: 'iOS',
}

class PlacementDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verifyError: null,
      verifyInProgress: false,
    }
    this.verifyOrder = this.verifyOrder.bind(this)
  }

  async verifyOrder() {
    try {
      const verifyOrderApi = new API({
        url: `${STORE_TECH_HOST}/staff-feedback/v0.3/feedbacks`,
      })
      this.setState({ verifyInProgress: true })
      const data = {
        params: {
          sessionID: this.props.data.sngSessionId,
          good: true,
          optionIDs: [],
        },
      }
      const res = await verifyOrderApi.post(data)
      if (res.code === 401) {
        this.setState({ verifyError: 'Unable to verify' })
      } else {
        window.location.reload()
      }
    } catch (e) {
      this.setState({ verifyError: 'Unable to verify' })
    } finally {
      this.setState({ verifyInProgress: false })
    }
  }

  renderOrderStatus(orderType, status) {
    let orderStatus = deliveryOrderStatus[status]
      ? getMessage(deliveryOrderStatus[status])
      : ''
    // If it's RB preorder and status is dispatched, display ready to pick up
    if (
      orderType === 'SALES_ORDER_TYPE_RB_PREORDER' &&
      status === 'DISPATCHED'
    ) {
      orderStatus = getMessage(deliveryOrderStatus['READY_FOR_PICKUP'])
    }
    return (
      <div className="section-pre-order">
        <h3 className="section-title">
          Status:{' '}
          <span className={`order-status-${status.toLowerCase()}`}>
            {orderStatus}
          </span>
        </h3>
        {(this.props.data.preorder ||
          orderType === 'SALES_ORDER_TYPE_RB_PREORDER') && (
          <div className="pre-order">
            <img
              src={
                orderType === 'SALES_ORDER_TYPE_RB_PREORDER'
                  ? RbPreorderIcon
                  : preorder
              }
              alt="pre-order-icon"
            />
            <span>
              {orderType === 'SALES_ORDER_TYPE_RB_PREORDER'
                ? getMessage('order.details.rbpreorder')
                : getMessage('order.details.preorder')}
            </span>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      status,
      device,
      verificationStatus,
      isScanAndGoOrder,
      orderType,
      storeName,
    } = this.props.data

    const isPfcStore = storeName && storeName.includes('PFC')
    const batchInfo =
      this.props.data.batchIds === null
        ? null
        : this.props.data.batchIds.map((batchId, index) => {
            let separator = ''
            if (index !== this.props.data.batchIds.length - 1) {
              separator = ', '
            }
            if (index !== 0) {
              return (
                <span className="batch-info-reassigned" key="{batchId}">
                  <s>
                    <Link to={`../orders?batchId=${batchId}`}>#{batchId}</Link>
                  </s>
                  (reassigned){separator}
                </span>
              )
            } else {
              return (
                <span className="batch-info" key="{batchId}">
                  <Link to={`../orders?batchId=${batchId}`}>#{batchId}</Link>
                  {separator}
                </span>
              )
            }
          })
    return (
      <div className="delivery-order-placement-details">
        {this.renderOrderStatus(orderType, status)}
        {verificationStatus && (
          <div className="order-placement-info-item verification-status-container">
            <img
              className="verification-status-icon"
              src={ShieldIcon}
              alt="verificaiton-status"
            />
            <small className="text-muted">
              {getMessage('deliveryOrder.details.verificationStatus')}:&nbsp;
              <span className="creation-time">
                {_.capitalize(verificationStatus)}
              </span>
            </small>
            <div className="verify-btn-container">
              {verificationStatus === 'UNVERIFIED' &&
                (this.state.verifyInProgress ? (
                  <Loader size="sm" />
                ) : (
                  <Button
                    className="verify-button primary"
                    onClick={() => this.verifyOrder()}
                  >
                    Verify
                  </Button>
                ))}
              <div className="noti-container">
                {this.state.verifyError && (
                  <div className="error-txt">{this.state.verifyError}</div>
                )}
              </div>
            </div>
          </div>
        )}

        {this.props.data.salesOrderReferenceNumber && (
          <div className="order-placement-info-item">
            <span className="order-placement-info-icon icon-sales-order" />
            <small className="text-muted">
              Sales Order:
              <span className="device-name">
                <Link
                  to={`/customer-support/sale-orders/${this.props.data.salesOrderReferenceNumber}`}
                >
                  &nbsp;{this.props.data.salesOrderReferenceNumber}
                </Link>
              </span>
            </small>
          </div>
        )}

        <div className="order-placement-info-item">
          <span className="order-placement-info-icon icon-store" />
          <small className="text-muted">
            Store:
            <span className="device-name">&nbsp;{storeName}</span>
          </small>
        </div>

        {this.props.data.batchIds && (
          <div className="order-placement-info-item">
            <span className="order-placement-info-icon batch-icon" />
            <small className="text-muted">Batch ID: {batchInfo}</small>
          </div>
        )}
        <div className="order-placement-info-item">
          <span className="order-placement-info-icon icon-clock" />
          <small className="text-muted">
            Placed Time:{' '}
            <span className="creation-time">
              {formatIsoDate(this.props.data.creationTime)}
              {', '}
              {moment(this.props.data.creationTime).format('LT')}
            </span>
          </small>
        </div>
        {this.props.data.completionTime ? (
          <div className="order-placement-info-item">
            <span className="order-placement-info-icon icon-clock" />
            <small className="text-muted">
              Completion Time:{' '}
              <span className="completion-time">
                {formatIsoDate(this.props.data.completionTime)}
                {', '}
                {moment(this.props.data.completionTime).format('LT')}
              </span>
            </small>
          </div>
        ) : null}
        {!isScanAndGoOrder && (
          <div className="order-placement-info-item">
            <span className="order-placement-info-icon icon-mobile" />
            <small className="text-muted">
              Placed From:{' '}
              <span className="device-name">
                {device ? deviceNames[device] : deviceNames['DESKTOP_NA_WEB']}
              </span>
            </small>
          </div>
        )}
        <div className="order-placement-info-item">
          <div className={`text-muted ${isPfcStore ? 'note' : ''}`}>
            Note:
            <b>
              {isPfcStore
                ? getMessage('order.log.sapNote')
                : getMessage(`order.log.fponNote`)}
            </b>
          </div>
        </div>
      </div>
    )
  }
}

export default PlacementDetails
