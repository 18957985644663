import React from 'react'
import {
  BaseForm,
  Input,
  Checkbox,
  Phone,
  VALIDATION_TYPES,
} from '../../../components/Form'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import Loader from '../../../components/Loader'
import { withRouter } from 'react-router-dom'
import { Dialog } from '../../../components/Popup'
import Table, { Row } from '../../../components/Table'
import addIcon from './add-button.svg'
import deleteIcon from './delete.svg'

const AccountList = props => {
  const { accounts, toggleAccountAction } = props
  return (
    <Table>
      {(accounts || []).map((account, index) => {
        const actionIcon = account.isAdd ? (
          <img src={addIcon} alt="add" />
        ) : (
          <img src={deleteIcon} alt="delete" />
        )
        return (
          <Row key={'account' + index}>
            <div className="flex">
              <span className={`icon icon-${account.name}`}>
                <span>{getMessage(`socialMedia.select.${account.name}`)}</span>
              </span>
              <button
                type="button"
                className="select-actions"
                onClick={() => toggleAccountAction(account.name)}
              >
                {actionIcon}
              </button>
            </div>
          </Row>
        )
      })}
    </Table>
  )
}

class SocialMediaForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values = {
      facebookHandle: '',
      twitterHandle: '',
      hasFacebookLogin: false,
      hasTwitterLogin: false,
    }
    this.state.formError = ''
    this.state.showLoader = false
    this.state.showSuccessDialog = false
    this.state.showErrorDialog = false
    this.state.showAccountList = false

    this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.closeDialogs = this.closeDialogs.bind(this)
    this.getSocialMediaAccounts = this.getSocialMediaAccounts.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.toggleAccountAction = this.toggleAccountAction.bind(this)
  }

  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    })
  }

  handleClose() {
    this.props.history.goBack()
  }

  handleClickOutside(event) {
    if (event.target.id !== 'select') {
      this.setState({ showAccountList: false })
    }
  }

  toggleAccountAction(name) {
    const accounts = this.state.accounts
    const accountsArray =
      accounts &&
      accounts.map(account => {
        if (account.name === name) {
          account.isAdd = !account.isAdd
        }
        return account
      })
    this.setState({ accounts: accountsArray })
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    })
    this.socialApi = new API({ url: '/account-service/config/social' })
    this.basicInfoApi = new API({ url: '/account-service/config/basic' })

    Promise.all([this.socialApi.get(), this.basicInfoApi.get()]).then(
      ([socialResponse, basicInfoResponse]) => {
        if (
          socialResponse.status === 'SUCCESS' &&
          basicInfoResponse.status === 'SUCCESS'
        ) {
          const social = socialResponse.data.config.social
          const basic = basicInfoResponse.data.config.basic

          const values = {
            facebookHandle: social.facebookHandle,
            twitterHandle: social.twitterHandle,
            hasFacebookLogin: social.hasFacebookLogin
              ? social.hasFacebookLogin
              : false,
            hasTwitterLogin: social.hasTwitterLogin
              ? social.hasTwitterLogin
              : false,
            supportEmail: basic.supportEmail,
            supportPhone: basic.supportPhone && basic.supportPhone.toString(),
          }
          this.setState({ values, showLoader: false }, () =>
            this.getSocialMediaAccounts()
          )
        }
      }
    )
    document.addEventListener('click', this.handleClickOutside, false)
  }

  componentWillUnmount() {
    this.socialApi && this.socialApi.cancel()
    document.removeEventListener('click', this.handleClickOutside, false)
  }

  handleSubmit(data) {
    const socialApi = new API({ url: '/account-service/config/social' })
    const basicInfoApi = new API({ url: '/account-service/config/basic' })

    const socialParams = {}
    const basicParams = {}
    const basicParamsArray = ['supportEmail', 'supportPhone']
    socialParams['social'] = {}
    basicParams['basic'] = {}

    for (const field in data) {
      if (!basicParamsArray.includes(field)) {
        socialParams['social'][field] = data[field]
        this.state.accounts.map(account => {
          if (field.toLowerCase().includes(account.name) && account.isAdd) {
            if (typeof data[field] === 'boolean') {
              socialParams['social'][field] = false
            } else {
              socialParams['social'][field] = ''
            }
          }
          return null
        })
      } else {
        basicParams['basic'][field] = data[field]
      }
    }
    return Promise.all([
      socialApi.put(socialParams),
      basicInfoApi.put(basicParams),
    ]).then(
      () => {
        this.setState({ formError: '', showSuccessDialog: true })
      },
      error => {
        this.setState({ formError: error.message, showErrorDialog: true })
      }
    )
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    })
    this.handleSubmit(data).then(() => {
      this.setState({ submitting: false })
    })
  }

  toggleCheckboxChange(e) {
    const stateName = e.target.value
    var values = { ...this.state.values }
    values[stateName] = !values[stateName]
    this.setState({ values })
  }

  getSocialMediaAccounts() {
    const facebook = {
      name: 'facebook',
      isAdd: !this.getState(['facebookHandle']),
    }
    const twitter = {
      name: 'twitter',
      isAdd: !this.getState(['twitterHandle']),
    }
    const accounts = [facebook, twitter]
    this.setState({ accounts })
  }

  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    const { accounts, showAccountList } = this.state
    return (
      <div>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage('socialMedia.save.success.title')}
          close={this.closeDialogs}
          closeText={getMessage('socialMedia.save.success.closeText')}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage('socialMedia.save.error.title')}
          close={this.closeDialogs}
          closeText={getMessage('socialMedia.save.error.closeText')}
        />
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form>
            <Input
              label={getMessage('basicInfo.storename.support.email')}
              placeholder={getMessage(
                'basicInfo.storename.support.email.placeholder'
              )}
              name="supportEmail"
              type="email"
              {...this.generateStateMappers({
                stateKeys: ['supportEmail'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
            />

            <Phone
              label={getMessage('basicInfo.storename.support.phone')}
              placeholder={getMessage(
                'basicInfo.storename.support.phone.placeholder'
              )}
              name="supportPhone"
              value={this.state.values.supportPhone}
              {...this.generateStateMappers({
                stateKeys: ['supportPhone'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <label className="select-social-media-header">
              {getMessage('socialMedia.accounts.label')}
            </label>
            <div
              className="select-social-media"
              id="select"
              onClick={event => {
                this.setState({ showAccountList: true })
                event.stopPropagation()
                event.preventDefault()
              }}
            >
              {getMessage('socialMedia.accounts.placeholder')}
              {showAccountList && (
                <AccountList
                  accounts={this.state.accounts}
                  toggleAccountAction={this.toggleAccountAction}
                />
              )}
            </div>
            {accounts && !accounts.find(o => o.name === 'facebook').isAdd && (
              <Input
                className="facebook-field"
                label={getMessage('socialMedia.facebook.heading')}
                name="facebook"
                type="text"
                placeholder={getMessage('socialMedia.facebook.placeholder')}
                prefix="https://www.facebook.com/"
                icon="facebook"
                secondaryLabel={() => (
                  <Checkbox
                    inlineLabel={getMessage('socialMedia.facebook.allowlogin')}
                    name="hasFacebookLogin"
                    value={this.getState(['hasFacebookLogin'])}
                    onChange={value => {
                      this.updateState(['hasFacebookLogin'], value)
                    }}
                    className="checkLabel"
                  />
                )}
                value={this.getState(['facebookHandle'])}
                onChange={value => {
                  this.updateState(['facebookHandle'], value)
                }}
              >
                {getMessage('socialMedia.facebook.description')}
              </Input>
            )}
            {accounts && !accounts.find(o => o.name === 'twitter').isAdd && (
              <Input
                className="twitter-field"
                label={getMessage('socialMedia.twitter.heading')}
                name="twitter"
                placeholder={getMessage('socialMedia.twitter.placeholder')}
                prefix="https://www.twitter.com/"
                icon="twitter"
                secondaryLabel={() => (
                  <Checkbox
                    inlineLabel={getMessage('socialMedia.twitter.allowlogin')}
                    name="hasTwitterLogin"
                    value={this.getState(['hasTwitterLogin'])}
                    onChange={value => {
                      this.updateState(['hasTwitterLogin'], value)
                    }}
                    className="checkLabel"
                  />
                )}
                value={this.getState(['twitterHandle'])}
                onChange={value => {
                  this.updateState(['twitterHandle'], value)
                }}
              >
                {getMessage('socialMedia.twitter.description')}
              </Input>
            )}
            <div className="form-buttons-container">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage('socialMedia.submit.text')}
              </SubmitButton>
              <button
                className="button"
                type="button"
                onClick={this.handleClose}
                disabled={this.state.submitting}
              >
                {getMessage('socialMedia.cancel.text')}
              </button>
            </div>
          </Form>
        )}
      </div>
    )
  }
}

export default withRouter(SocialMediaForm)
