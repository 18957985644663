import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getMessage } from '../../../lib/translator'
import API from '../../../lib/api'
import AuthenticatedPage from '../../../containers/AuthenticatedPage/index'
import { Dialog } from '../../../components/Popup'
import Loader from '../../../components/Loader'
import { throwError, hideErrorDialog } from '../commonMedia'
import CampaignView from './campaignView'
import LineItemTable from './LineItemTable'
import './style.css'

function getMobileView () {
  return window.screen.width <= 480
}

export default class LineItemList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showLoader: true,
      isMobileView: getMobileView(),
      selectedLineItem: '',
      campaignData: {},
      lineItemData: [],
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
    }
    this.mobileView = this.mobileView.bind(this)
    this.getCampaignDetail = this.getCampaignDetail.bind(this)
  }
  mobileView () {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount () {
    window.addEventListener('resize', this.mobileView, false)
    this.getCampaignDetail();
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.mobileView, false)
    this.api && this.api.cancel()
  }

  getCampaignDetail(){
    const campaignId = this.props.match.params.id
    this.api = new API({ url: '/martech-ad-service/campaigns/' + campaignId })
    this.api.get().then(response => {
      this.setState({
        showLoader: false,
        lineItemData: response?.data?.lineItemReservationList || [],
        campaignData: response?.data,
      })
      window.sessionStorage.setItem('mediaAdvertiserClients_onecampaign', JSON.stringify(response?.data?.advertiserClientList))
    }).catch(error => {
      throwError(this, error)
    })
  }

  render () {
    const { menu } = this.props
    const { showLoader, isMobileView, lineItemData } = this.state
    const toLink = '/media-monetization/media-allcampaigns/media-adset-detail/add/' + this.props.match.params.id

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className='media-adset-list-page website-pages'
        title={getMessage('media.adset.title')}
      >
        <h1 className='title'>{getMessage('media.adset.title')}</h1>

        {showLoader ? (
          <Loader />
        ) : (
          <React.Fragment>
            <CampaignView data={this.state.campaignData}></CampaignView>

            <div className='adset-table-title'>
              <h3>{getMessage('media.adset.table.h3')}</h3>
              <Link to={toLink} className='primary button'>
                + <span className='text'>{getMessage('media.adset.create.text')}</span>
              </Link>
            </div>

            <LineItemTable
              campaignId={this.props.match.params.id}
              isMobileView={isMobileView}
              lineItemData={lineItemData}
              throwError={(error, message)=>(throwError(this, error, message))}
              getCampaignDetail={this.getCampaignDetail}
            ></LineItemTable>
          </React.Fragment>
        )}

        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={this.state.errorDialog.message}
            close={()=>(hideErrorDialog(this))}
            closeText={getMessage('dialog.okText')}
          />
        )}
      </AuthenticatedPage>
    )
  }
}

