import React, { useState } from 'react'
import { Button, FixedButton, Text, View } from '../ui'
import { useFormikContext } from 'formik'
import { get } from 'lib/storage'
import { Timeslot } from './Timeslot'
import { ConfirmOrderModal } from './ConfirmOrderModal'
import { useConfigMetaData } from 'pages/settings/Store/StoreConfiguration'
import Loader from 'components/Loader'

const SelectSlotButton = ({ slot, setShowPickup }) =>
  slot ? (
    <Button width={88} onClick={() => setShowPickup(true)}>
      <Text color="white" fontSize={12} fontWeight={700}>
        Select
      </Text>
    </Button>
  ) : (
    <>No slot available</>
  )

export const PickupSettingsTab = ({
  slot,
  cart,
  setSelectedCampaign,
  onDone,
}) => {
  const [showPickup, setShowPickup] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const { values } = useFormikContext()
  const { loading, data: metaData } = useConfigMetaData()
  const storeDetail = JSON.parse(get('stores')).find(
    (store) => Number(store.id) === Number(values.storeId)
  )
  const parsedMetaData = Object.keys(storeDetail.metaData).reduce((obj, id) => {
    const foundedMetadata = Object.keys(metaData || {}).find(
      (key) => metaData[key].id === Number(id)
    )
    obj[foundedMetadata] = storeDetail.metaData[id]
    return obj
  }, {})

  return loading ? (
    <Loader />
  ) : (
    <>
      <View
        direction="column"
        justifyContent="space-between"
        style={{ height: 'calc(100vh - 25px - 37px - 40px - 20px)' }}
      >
        <View direction="column" alignItems="start">
          <View direction="column" alignItems="start" margin="0 0 16px">
            <Text fontWeight={700} margin="0 0 8px">
              Pick-up location
            </Text>
            <Text display="block" fontSize={14} fontWeight={700}>
              {storeDetail.name}
            </Text>
            <Text textAlign="left">{storeDetail.address}</Text>
          </View>
          <View direction="column" alignItems="start">
            <Text fontWeight={700} margin="0 0 16px">
              Pick-up time slot
            </Text>
            {values.pickupDate && values.pickupTime ? (
              <View justifyContent="space-between">
                <Text
                  fontSize={14}
                >{`${values.pickupDate}, ${values.pickupTime}`}</Text>
                {/* If no available slot, not allow to change */}
                {slot && (
                  <Button variant="text" width={60}>
                    <Text
                      color="#1454B8"
                      fontWeight={700}
                      fontSize={14}
                      onClick={() => setShowPickup(true)}
                    >
                      Change
                    </Text>
                  </Button>
                )}
              </View>
            ) : (
              <SelectSlotButton slot={slot} setShowPickup={setShowPickup} />
            )}
          </View>
        </View>
        <View></View>
        <FixedButton
          disabled={!values.pickupDate || !values.pickupTime}
          onClick={() => setShowConfirm(true)}
        >
          Confirm & print QR code
        </FixedButton>
      </View>
      {showPickup && (
        <Timeslot onClose={() => setShowPickup(false)} slot={slot} />
      )}
      {showConfirm && (
        <ConfirmOrderModal
          isOpen={showConfirm}
          toggleModal={() => setShowConfirm((state) => !state)}
          onDone={onDone}
          storeName={storeDetail.name}
          storeAddress={storeDetail.address}
          storePhone={parsedMetaData.Phone}
          cart={cart}
          setSelectedCampaign={setSelectedCampaign}
        />
      )}
    </>
  )
}
