import React from 'react'
import { IS_OKTA_ENABLED } from '../../../config/app'
import LoginPage from './LoginPage'
import RedirectPage from './RedirectPage'

export default class Login extends React.Component {
  render() {
    const { loading } = this.props
    if (IS_OKTA_ENABLED) {
      return <RedirectPage loading={loading} />
    } else {
      return <LoginPage />
    }
  }
}
