const data = {
  'qc.order.filters.submitText': 'Search',
  'qc.order.filters.clearFiltersText': 'Clear All',
  'qc.order.filters.search.heading': 'Search',
  'qc.order.filters.applyText': 'Apply',
  'qc.order.filters.filterText': 'Filters',
  'qc.order.filters.search.placeholder': 'Search for an Order ID',
  'qc.order.filters.dateFrom': 'Date Range',
  'qc.order.filters.dateFrom.placeholder': 'Start Date',
  'qc.order.filters.dateTo': 'End Date',
  'qc.order.filters.dateTo.placeholder': 'End Date',
  'qc.order.filters.platform': 'Platform',
  'qc.order.filters.platform.placeholder': 'Select platform',
  'qc.order.filters.fulfillmentMethod': 'Fulfillment',
  'qc.order.filters.fulfillmentMethod.placeholder': 'Select fulfillment',
  'qc.order.filters.orderState': 'Master Status',
  'qc.order.filters.orderState.placeholder': 'Select Rider Status',
  'qc.order.filters.paymentState': 'Payment Status',
  'qc.order.filters.paymentState.placeholder': 'Select payment status',
  'qc.order.filters.brands': 'Brand',
  'qc.order.filters.brands.placeholder': 'Enter brand name',
  'qc.order.filters.locations': 'Outlet',
  'qc.order.filters.locations.placeholder': 'Enter outlet name',
  'qc.order.filters.foodSuppliers': 'Stall',
  'qc.order.filters.foodSuppliers.placeholder': 'Enter stall name',
  'qc.order.table.header.referenceNumber': 'Order No. / Placed Date & Time',
  'qc.order.table.header.brand': 'Brand',
  'qc.order.table.header.outlet': 'Outlet',
  'qc.order.table.header.stall': 'Stall',
  'qc.order.table.header.amount': 'Total Amount',
  'qc.order.table.header.type': 'Fulfillment / Platform ID',
  'qc.order.table.header.masterStatus': 'Master Status',
  'qc.order.table.header.subStatus': 'Suborder Status',
  'qc.orderInfo.Order': 'Order',
  'qc.orderInfo.MasterStatus': 'Master status',
  'qc.orderInfo.RiderStatus': 'Rider status',
  'qc.orderInfo.riderPopup.heading': 'The rider status has been updated.',
  'qc.orderInfo.riderPopup.description':
    'Please refresh the page to receive new updates.',
  'qc.orderInfo.cancelDeliveryPopup.heading': 'Delivery cancelled!',
  'qc.orderInfo.cancelDeliveryPopup.description':
    'This delivery has been successfully cancelled.',
  'qc.orderInfo.cancelDeliveryPopup.button': 'Back to orders',
  'qc.orderInfo.confirmDeliveryPopup.heading':
    'Are you sure you want to cancel delivery?',
  'qc.orderInfo.confirmDeliveryPopup.description':
    'All sub-orders will be cancelled.',
  'qc.orderInfo.confirmDeliveryPopup.button': 'Yes, cancel delivery',
  'qc.orderInfo.confirmDeliveryPopup.dismiss.button': 'Dismiss',
  'qc.orderInfo.cancelDeliveryPopup.reasonPopup.heading':
    'Please select a reason for cancelling this delivery.',
  'qc.orderInfo.cancelDeliveryPopup.reasonPopup.question':
    'What is the reason for cancelling this delivery?',
  'qc.orderInfo.takeaway': 'Takeaway',
  'qc.orderInfo.SelectMasterStatus': 'Select Master Status',
  'qc.orderInfo.PlacedTime': 'Placed time',
  'qc.orderInfo.Fulfillment': 'Fulfillment',
  'qc.orderInfo.Platform': 'Platform',
  'qc.orderInfo.OriginalDeliveryTime': 'Original delivery time',
  'qc.orderInfo.OriginalPickupTime': 'Original pickup time',
  'qc.orderInfo.NotEstimated': 'Not estimated',
  'qc.orderInfo.Save': 'Save',
  'qc.orderInfo.CancelDelivery': 'Cancel Delivery',
  'qc.orderInfo.Cancel': 'Cancel',
  'qc.orderInfo.Z': 'Z',
  'qc.orderInfo.CustomerDetails': 'Customer Details',
  'qc.orderSummary.Heading': 'Order Summary',
  'qc.orderSummary.BulkActions': 'Bulk Actions',
  'qc.orderSummary.CancelOrder': 'Cancel Order',
  'qc.orderSummary.CancelSelected': 'Cancel Selected',
  'qc.orderSummary.RefundOrder': 'Refund Order',
  'qc.orderSummary.RefundSelected': 'Refund Selected',
  'qc.orderSummary.RejectOrder': 'Reject Order',
  'qc.orderSummary.RejectSelected': 'Reject Selected',
  'qc.orderSummary.Save': 'Save',
  'qc.orderSummary.Cancel': 'Cancel',
  'qc.orderSummary.Back': 'Back',
  'qc.orderSummary.SelectAll': 'Select All',
  'qc.orderSummary.Subtotal': 'Subtotal (excl. container fee)',
  'qc.orderSummary.AppPaymentDiscountVoided': 'App payment discount voided',
  'qc.orderSummary.AppPaymentDiscountTooltipLabel': 'App payment discount',
  'qc.orderSummary.AppPaymentDiscountTooltipDescription':
    'Applies to items, container/ delivery/small order fee',
  'qc.orderSummary.AppPaymentDiscount': 'App payment discount (10%)',
  'qc.orderSummary.AppPaymentDiscountApplied':
    'App Payment Discount (10%) applies to items, container/delivery/small order fee',
  'qc.orderSummary.Fees': 'Fees',
  'qc.orderSummary.tablePopup.Fees':
    'Fees (inclusive of discount & card surcharge)',
  'qc.orderSummary.tablePopup.FeesReturned': 'Fees Returned',
  'qc.orderSummary.containerFee': 'Container fee',
  'qc.orderSummary.containerFee.return': 'Container fee returned',
  'qc.orderSummary.containerFee.returned': 'Returned',
  'qc.orderSummary.FeesReturned': 'Fees Returned',
  'qc.orderSummary.CardSurcharge': 'Card Surcharge',
  'qc.orderSummary.CardSurcharge.Waived': 'Waived',
  'qc.orderSummary.DiscountAmount': 'Discount Amount',
  'qc.orderSummary.OriginalTotalAmount': 'Original total amount paid',
  'qc.orderSummary.AmountCancelled': 'Items cancelled / rejected',
  'qc.orderSummary.AmountRefunded': 'Items refunded',
  'qc.orderSummary.AmountRejected': 'Items cancelled / rejected',
  'qc.orderSummary.TotalAmountCancelledRejected':
    'Total amount cancelled / rejected',
  'qc.orderSummary.TotalAmountRefunded': 'Total amount refunded',
  'qc.orderSummary.2OFF': '2 OFF',
  'qc.orderSummary.FinalTotalAmount': 'Final total amount paid',
  'qc.orderSummary.table.header.StallItem': 'Stall / Item',
  'qc.orderSummary.table.header.Name': 'Name',
  'qc.orderSummary.table.header.unit.container.fee': 'Unit Container Fee',
  'qc.orderSummary.table.header.unit.discount': 'Unit Discount',
  'qc.orderSummary.table.header.Quantity': 'Quantity',
  'qc.orderSummary.table.header.Price': 'Unit Price',
  'qc.orderSummary.table.header.Subtotal': 'Subtotal (excl. container fee)',
  'qc.orderSummary.table.header.SubOrderStatus': 'Suborder Status',
  'qc.orderSummary.table.ContainerFee': 'Container fee',
  'qc.orderSummary.table.StallDiscount': 'Stall discount',
  'qc.orderSummary.table.Kdiscount': '10% K discount',
  'qc.orderSummary.table.CutleryOption': 'Cutlery option',
  'qc.orderSummary.table.CutleryOption.Yes': 'Yes',
  'qc.orderSummary.table.CutleryOption.No': 'No',
  'qc.orderSummary.successpopup.heading': 'Order cancelled!',
  'qc.orderSummary.successpopup.description':
    'This order has been successfully cancelled.',
  'qc.orderSummary.successpopup.masterdescription':
    'This order has been successfully cancelled. The master order (all suborders) has been refunded/cancelled/rejected and the card surcharge will be returned.',
  'qc.orderSummary.successpopup.button': 'Back to orders',
  'qc.orderInfo.SelectSubOrderStatus': 'Select Suborder Status',
  'qc.orderSummary.reasonpopup.title':
    'Please select a reason for cancelling this order.',
  'qc.orderSummary.reasonpopup.multiple.title':
    'Please select the reason(s) for cancelling the following order(s).',
  'qc.orderSummary.reasonpopup.question':
    'What is the reason for cancelling this order?',
  'qc.orderSummary.reasonpopup.multiple.question':
    'Reason for cancelling order from ',
  'qc.orderSummary.reasonpopup.SelectReason': 'Select reason',
  'qc.orderSummary.reasonpopup.ApplyReason':
    'Apply this reason to all sub orders.',
  'qc.orderSummary.reasonpopup.reasons.ItemUnavailable': 'Item unavailable',
  'qc.orderSummary.reasonpopup.reasons.TooBusy': 'Too busy',
  'qc.orderSummary.reasonpopup.reasons.ExceptionalCustomerRequest':
    'Exceptional Customer Request',
  'qc.orderSummary.reasonpopup.reasons.NoShow': 'No Show',
  'qc.orderSummary.reasonpopup.reasons.StallClosed': 'Stall closed',
  'qc.orderSummary.reasonpopup.reasons.InternalTesting': 'Internal Testing',
  'qc.orderSummary.reasonpopup.reasons.Others': 'Others',
  'qc.orderSummary.reasonpopup.reasons.DeliveryNoShow': 'Delivery no show',
  'qc.orderSummary.reasonpopup.reasons.AppDiscountNotReflected':
    'App Payment Discount Not Reflected',
  'qc.orderSummary.reasonpopup.reasons.KDiscountNotReflected':
    'K-card discount not reflected',
  'qc.orderSummary.reasonpopup.reasons.QualityOfFood': 'Quality of Food',
  'qc.orderSummary.reasonpopup.reasons.FoodPoisoning': 'Food Poisoning',
  'qc.orderSummary.reasonpopup.reasons.MissingItems':
    'Missing Items/Ingredients',
  'qc.orderSummary.reasonpopup.reasons.DamagedItem':
    'Damaged item/Packaging Spillage',
  'qc.orderSummary.reasonpopup.reasons.WrongItems': 'Wrong/additional Items',
  'qc.orderSummary.reasonpopup.reasons.ServingPortion': 'Serving Portion',
  'qc.orderSummary.reasonpopup.reasons.LateDelivery': 'Late delivery',
  'qc.orderSummary.reasonpopup.button.submit': 'Submit',
  'qc.orderSummary.reasonpopup.button.back': 'Back',
  'qc.orderSummary.tablepopup.heading':
    'Are you sure you want to cancel this order?',
  'qc.orderSummary.tablepopup.preparedheading':
    'Your order(s) is being prepared, are you sure you want to cancel this order?',
  'qc.orderSummary.tablepopup.delivery.cancelled':
    'Delivery will be cancelled when all sub-orders are cancelled.',
  'qc.orderSummary.tablepopup.button.submit': 'Yes, cancel order',
  'qc.orderSummary.tablepopup.button.dismiss': 'Dismiss',
  'qc.orderSummary.tablepopup.CancelledAmount': 'Items cancelled / rejected',
  'qc.orderSummary.tablepopup.RejectedAmount': 'Amount Rejected',
  'qc.orderSummary.tablepopup.total.amount.cancelled':
    'Total amount cancelled / rejected',
  'qc.orderSummary.tablepopup.total.amount.rejected': 'Total Amount Rejected',
  'qc.orderSummary.tablepopup.total.amount.refunded': 'Total amount refunded',
  'qc.orderSummary.tablepopup.RefundAmount': 'Items refunded',
  'qc.orderSummary.tablepopup.rejectOrder.button.submit': 'Yes, reject order',
  'qc.orderSummary.tablepopup.rejectOrder.heading':
    'Are you sure you want to reject this order?',
  'qc.orderSummary.reasonpopup.rejectOrder.title':
    'Please select a reason for rejecting this order.',
  'qc.orderSummary.reasonpopup.rejectOrder.question':
    'What is the reason for rejecting this order?',
  'qc.orderSummary.successpopup.rejectOrder.heading': 'Order rejected!',
  'qc.orderSummary.successpopup.rejectOrder.description':
    'This order has been successfully rejected.',
  'qc.orderSummary.successpopup.rejectOrder.masterdescription':
    'This order has been successfully rejected. The master order (all suborders) has been refunded/cancelled/rejected and the card surcharge will be returned.',
  'qc.orderSummary.tablepopup.refundOrder.button.submit': 'Yes, refund order',
  'qc.orderSummary.tablepopup.refundOrder.heading':
    'Are you sure you want to refund this order?',
  'qc.orderSummary.reasonpopup.refundOrder.title':
    'Please select a reason for refunding this order.',
  'qc.orderSummary.reasonpopup.refundOrder.question':
    'What is the reason for refunding this order?',
  'qc.orderSummary.successpopup.refundOrder.heading': 'Order refunded!',
  'qc.orderSummary.successpopup.refundOrder.description':
    'This order has been successfully refunded.',
  'qc.orderSummary.successpopup.refundOrder.masterdescription':
    'This order has been successfully refunded. The master order (all suborders) has been refunded/cancelled/rejected and the card surcharge will be returned.',
  'qc.paymentDetails.Heading': 'Payment Details',
  'qc.paymentDetails.AmountPaid': 'Final Total Amount Paid',
  'qc.paymentDetails.LinkPoints': 'LinkPoints',
  'qc.paymentDetails.Card': 'Card',
  'qc.paymentDetails.table.header.Amount': 'Amount',
  'qc.paymentDetails.table.header.PaymentMode': 'Payment Mode',
  'qc.paymentDetails.table.header.Card': 'Card',
  'qc.paymentDetails.table.header.Status': 'Status',
  'qc.paymentDetails.table.Credit': 'Credit',
  'qc.paymentDetails.table.Debit': 'Debit',
  'qc.linkPointDetails.Heading': 'LinkPoints Details',
  'qc.linkPointDetails.table.header.Amount': 'Amount',
  'qc.linkPointDetails.table.header.VirtualCard': 'Virtual Card',
  'qc.linkPointDetails.table.header.AwardedLinkPoints': 'Awarded LinkPoints',
  'qc.linkPointDetails.table.header.LinkPointsRedeemed': 'LinkPoints Redeemed',
  'qc.orderSummary.refreshpopup.heading': 'The order status has been updated.',
  'qc.orderSummary.refreshpopup.autoRejected.heading':
    'The order status has been auto rejected.',
  'qc.orderSummary.refreshpopup.description':
    'Please refresh the page to receive new updates.',
  'qc.orderSummary.refreshpopup.button': 'Refresh Page',
  'qc.orderSummary.no.special.instruction': 'No special instruction',
  'qc.orderSummary.discounts': 'Discounts',
  'qc.orderSummary.delivery.fee': 'Delivery Fee',
  'qc.orderSummary.service.fee': 'Service fee',
  'qc.orderSummary.small.order.fee': 'Small Order Fee',
  'qc.orderSummary.delivery.fee.voided': 'Delivery Fee Voided',
  'qc.orderSummary.small.order.fee.voided': 'Small Order Fee Voided',
  'qc.orderSummary.discounts.voided': 'Discounts Voided',
  'qc.order.logs.heading': 'Order Log ',
  'qc.log.master.order.created': 'Master order created',
  'qc.log.payment.of': 'Payment of ',
  'qc.log.linkpoints.payment.of': 'LinkPoints Payment of ',
  'qc.log.card.payment.of': 'Card payment of ',
  'qc.log.order.accepted': 'Sub-order from ',
  'qc.log.order.accepted.state': ' is ',
  'qc.log.order.pickuptime': 'Pick-up time',
  'qc.log.order.actionby': 'Action by',
  'qc.log.order.ready.start': 'Sub-order from ',
  'qc.log.order.ready.end': ' is ready for collection',
  'qc.log.suborder.from': 'Suborder from ',
  'qc.log.suborder.cancelled.by': ' cancellation by User ',
  'qc.log.suborder.cancelled.by.system': ' cancellation by System',
  'qc.log.order.from': 'Suborder from ',
  'qc.log.master.order.completed': 'Master order completed',
  'qc.log.suborder.autorejected': ' auto-rejected.',
  'qc.log.suborder.rejected.by': ' rejected by User ',
  'qc.log.master.order.cancelled': 'Master order cancelled by system',
  'qc.log.request.rider.order': 'Requesting rider for order.',
  'qc.log.rider': 'Rider ',
  'qc.log.accepted.rider.order': ' accepted order.',
  'qc.log.pickup.rider.order':
    ' has picked up order. Delivery of order in progress.',
  'qc.log.arrived.rider': ' arrived at pick-up location.',
  'qc.log.nearby.rider': ' is nearby pick-up location.',
  'qc.log.nearby.rider.destination': ' is nearby destination.',
  'qc.log.delivered.rider.order': ' has delivered order.',
  'qc.log.new.rider.assigned': 'New rider assigned.',
  'qc.log.estimated.delivery.time': 'Estimated delivery time',
  'qc.log.revised.delivery.time': 'Revised delivery time',
  'qc.log.delivery.cancelled': 'Delivery was cancelled.',
  'qc.log.cancellation.reason': 'Cancellation reason',
  'qc.log.is.cancelled': ' is cancelled.',
  'qc.log.all.suborders.auto.cancel':
    'All sub-orders have been auto-cancelled due to delivery cancellation.',
  'qc.log.auto.delivery.cancel':
    'Delivery has been auto-cancelled due to cancellation of all suborders.',
  'qc.refundedItems.heading': 'Refunded items',
  'qc.order.log.name': 'Stall / Item',
  'qc.order.log.original': 'Original Qty',
  'qc.order.log.final': 'Final Qty',
  'qc.deliveryDetails.Heading': 'Delivery Details',
  'qc.deliveryDetails.NoMap':
    'No map available as rider has not arrived at the pick-up point.',
  'qc.deliveryDetails.NoMapOrderCompleted':
    'No map available as master order is completed.',
  'qc.deliveryDetails.NoRider': 'No rider assigned yet.',
  'qc.deliveryDetails.FinalPickupTime': 'Final Pick-up Time',
  'qc.deliveryDetails.FinalDeliveryTime': 'Final Delivery Time',
  'qc.deliveryDetails.StartAddress': 'Start Address',
  'qc.deliveryDetails.DeliveryAddress': 'Delivery Address',
  'qc.deliveryDetails.DeliveryInstructions': 'Delivery Instructions',
  'qc.deliveryDetails.PressDoorbell': '“Press the doorbell when you arrive.”',
}

export default data
