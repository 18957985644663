import { get } from '../storage'
import SPLIT_FEATURES from '../../containers/SplitContext/features'
import { ServiceHostMapping } from '../api'
import { APG_GATEWAY_HOST } from '../../config/app.js'

const getApigeeFlag = (service) => {
  let flags
  try {
    // Attempt to parse the SPLIT_FEATURE_FLAGS from the get function
    flags = JSON.parse(get('SPLIT_FEATURE_FLAGS'))
  } catch (error) {
    // Log an error if parsing fails and return the original service host
    console.error('Error parsing SPLIT_FEATURE_FLAGS:', error)
    return ServiceHostMapping[service]
  }

  // Check if the APIGEE_MIGRATION feature flag is turned on
  if (flags?.[SPLIT_FEATURES.APIGEE_MIGRATION]?.treatment === 'on') {
    let config
    try {
      // Attempt to parse the config array from the feature flag
      config = JSON.parse(
        flags?.[SPLIT_FEATURES.APIGEE_MIGRATION]?.config || '[]'
      )
    } catch (error) {
      // Log an error if parsing fails and set config to an empty array
      console.error('Error parsing APIGEE_MIGRATION config:', error)
      config = []
    }

    // Convert the config array to a set for faster lookup O(1)
    const configSet = new Set(config)

    // Check if the service is in the config set
    if (configSet.has(service)) {
      // If the service is in the config set, return APG_GATEWAY_HOST
      return APG_GATEWAY_HOST
    } else {
      // If the service is not in the config set, return the original service host from ServiceHostMapping
      return ServiceHostMapping[service]
    }
  } else {
    // If the feature flag is off or split is unavailable, return the original service host from ServiceHostMapping
    return ServiceHostMapping[service]
  }
}

const apigeeMigration = (service) => {
  return getApigeeFlag(service)
}

export default apigeeMigration
