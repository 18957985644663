import React from 'react'

export default function Row(props) {
  return (
    <div
      className={'table-row ' + (props.className || '')}
      style={props.style || {}}
      data-testid={props.testId || ''}
    >
      {props.children}
    </div>
  )
}
