import React from 'react'
import { Link } from 'react-router-dom'
import {
  BaseForm,
  Input,
  Upload,
  VALIDATION_TYPES,
  CategorySearch,
} from '../../../../components/Form'
import { extensionRoutes } from '../../../../lib/auth'
import { getMessage } from '../../../../lib/translator'

import deleteIcon from '../delete.svg'

export default class CategoryForm extends BaseForm {
  constructor(props) {
    super(props)
    this.delete = this.delete.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
  }

  delete() {
    this.props.onDelete()
  }

  changeStatus(status) {
    const { value } = this.props
    if (value && value.id) {
      this.props.onSubmit({ id: value.id, status: status })
    }
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const { props } = this

    return (
      <div className="CategoriesForm">
        <Form>
          <Input
            label={getMessage('category.form.name.heading')}
            placeholder={getMessage('category.form.name.placeholder')}
            name="category-name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ['name'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
            }}
          />
          <Input
            label={getMessage('category.form.tagline.heading')}
            placeholder={getMessage('category.form.tagline.placeholder')}
            name="tagline"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['tagLine'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <CategorySearch
            label={getMessage('category.form.parentCategory.heading')}
            placeholder={getMessage('category.form.parentCategory.placeholder')}
            name="parentCategory"
            {...this.generateStateMappers({
              stateKeys: ['parentCategory'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          {props.value && props.value.slug && 
            <div className="field" >
              <Link
                to={`${extensionRoutes.Seo}?type=category&url=${props.value.slug}`}
              >
                {getMessage('seo.category.configure')}
              </Link>
            </div>
          }
          <Upload
            label={getMessage('brand.form.image.heading')}
            placeholder={getMessage('brand.form.image.placeholder')}
            {...this.generateStateMappers({
              stateKeys: ['image'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <div className={`actions ${props.method}`}>
            {props.method &&
              props.method === 'edit' &&
              !this.props.addFromTree && (
                <button
                  className="delete-button"
                  type="button"
                  onClick={this.delete}
                >
                  <img src={deleteIcon} alt="delete" />
                  <span>{getMessage('category.form.deleteText')}</span>
                </button>
              )}
            {props.method && props.method === 'edit' && (
              <div className="hide-delete-buttons flex">
                {props.value && props.value.status === 'ENABLED' ? (
                  <button
                    className="button"
                    type="button"
                    onClick={() => this.changeStatus('HIDDEN')}
                  >
                    {getMessage('category.form.hideText')}
                  </button>
                ) : (
                  <button
                    className="button"
                    type="button"
                    onClick={() => this.changeStatus('ENABLED')}
                  >
                    {getMessage('category.form.enableText')}
                  </button>
                )}
              </div>
            )}
            {(!props.method || props.method !== 'edit') && (
              <CancelButton>
                {getMessage('category.form.cancelText')}
              </CancelButton>
            )}
            <SubmitButton>
              {getMessage('category.form.submitText')}
            </SubmitButton>
          </div>
        </Form>
      </div>
    )
  }
}
