import dataset from './dataset/index.js'
import MessageFormat from 'messageformat'

function getMessage(key, params = {}) {
  const lang = getLocale()['language']
  let msg = key
  if (lang in dataset && key in dataset[lang].strings) {
    msg = dataset[lang]['strings'][key]
  } else if (key in dataset['en'].strings) {
    msg = dataset['en']['strings'][key]
  }
  return new MessageFormat(lang).compile(msg)(params)
}

function setLocale({ language }) {
  if (language) {
    window.localStorage.setItem('language', language)
  }
}

function getLocale() {
  if (typeof window === 'undefined') {
    return {
      language: 'en',
    }
  }
  return {
    language: window.localStorage ? window.localStorage.getItem('language') : 'en',
  }
}

function getSupportedLanguages() {
  return Object.keys(dataset).map(isoCode => ({
    isoCode,
    language: dataset[isoCode].language,
  }))
}

export { getMessage, setLocale, getLocale, getSupportedLanguages }
