import React from 'react'
import { BaseForm } from '../../../../../components/Form'
import MetaDataFormFields from '../../../../../components/MetaDataFormFields'
import { Dialog } from '../../../../../components/Popup'

import API from '../../../../../lib/api'
import {
  getEntityMetaData,
  isExtensionEnabled,
  getExtensionDetails,
  saveEntityMetaData,
  hasPermissions,
} from '../../../../../lib/auth'
import { getMessage } from '../../../../../lib/translator'
import UserTags from './../UserTags'
import SegmentIoTags from './../SegmentIoTags'
import './style.css'

class MetaDataForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      segmentIoTags: null
    }
    this.metaDataWithType = {}
    this.getCustomerMetaData = this.getCustomerMetaData.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.showView = this.showView.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  getCustomerMetaData() {
    const metaData = getEntityMetaData().customer
    this.metaDataWithType = Object.assign({}, metaData)
    const customer = Object.assign({}, this.props.customer)
    this.metaData = customer.metaData
    if (!customer.metaData) {
      metaData &&
        Object.entries(metaData).forEach(([key, _type]) => {
          metaData[key] = ''
        })
      this.metaData = customer.metaData = metaData
    }
    this.setState({
      values: customer,
      tempState: customer.metaData,
      prevState: customer.metaData,
    })
  }

  async getCustomerSegmentIoTags() {
    try {
      const segmentIoApi = new API({ url: `/customers`})
      const results = await segmentIoApi.get({ customerId: this.props.customer?.id });
      this.setState({ segmentIoTags: results.data })
    } catch (error) {
      console.log(error)
      this.setState({ segmentIoTags: { hasError: true } })
    }
  }

  componentDidMount() {
    const customerKeys = getEntityMetaData() && getEntityMetaData().customer
    if (
      (!customerKeys ||
        (Array.isArray(customerKeys) && !customerKeys.length) ||
        (customerKeys.constructor === Object &&
          !Object.keys(customerKeys).length)) &&
      isExtensionEnabled('EntityMetaData')
    ) {
      const extentionDetails = getExtensionDetails('EntityMetaData')
      if (extentionDetails) {
        this.api = new API({ url: `/config-service/meta-data` })
        this.api.get().then(response => {
          const details = response.data.config.entityMetaData
          saveEntityMetaData(details)
          this.getCustomerMetaData()
        })
      }
    } else {
      if (isExtensionEnabled('EntityMetaData')) {
        this.getCustomerMetaData()
      }
    }

    this.getCustomerSegmentIoTags()
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
    this.metaDataAPI && this.metaDataAPI.cancel()
  }

  handleEdit() {
    this.setState({
      editing: true,
    })
  }

  onSubmit(formDataRaw) {
    const formData = { ...formDataRaw, metaData: this.state.tempState }
    this.setState({
      submitting: true,
      editing: false,
    })
    this.metaDataAPI = new API({
      url: `/ef-customer-core/profile/${this.props.customer.id}/metadata`,
    })
    const params = formData.metaData
    const configureableKeys = Object.keys(this.metaDataWithType)
    Object.keys(formData.metaData).forEach(key => {
      if (configureableKeys.indexOf(key) < 0) {
        delete formData.metaData[key]
      }
    })
    this.metaDataAPI
      .patch(params)
      .then(
        () => {
          this.setState({
            submitting: false,
            formError: '',
            showSuccessDialog: true,
          })
        },
        error => {
          this.setState({
            submitting: false,
            formError: error.message
              .split(':')
              .slice(1)
              .join(':'),
            showErrorDialog: true,
            tempState: this.state.prevState,
          })
        }
      )
      .then(() => {
        if (this.state.tempState) {
          Object.keys(this.state.tempState).forEach(key => {
            this.updateState(['metaData', key], this.state.tempState[key])
          })
        }
      })
  }

  handleChange(value, key) {
    this.setState({
      tempState: {
        ...this.state.tempState,
        [key]: value,
      },
    })
  }

  showView() {
    const temp = []
    let count = 0
    if (
      this.state.values &&
      this.state.values.metaData &&
      this.metaDataWithType
    ) {
      for (const key in this.metaDataWithType) {
        count++
        temp.push(
          <div key={count} className="customer-metadata-show-view">
            <div className="customer-metadata-key">{key}</div>
            <div className="customer-metadata-value">
              {this.state.values.metaData[key]}
            </div>
          </div>
        )
      }
    }
    return (
      <div className="customer-metadta-show-view-container">{[...temp]}</div>
    )
  }

  render() {
    const editPermission = hasPermissions(
      'ef-customer-core',
      'profile',
      'patch',
      true
    )
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    return (
      <React.Fragment>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          title={getMessage('customer.entityMetaData.successTitle')}
          information={getMessage('customer.entityMetaData.successInformation')}
          close={() => this.setState({ showSuccessDialog: false })}
          closeText={getMessage('customer.entityMetaData.ok')}
        />
        <Dialog
          show={this.state.showErrorDialog}
          title={getMessage('customer.entityMetaData.errorTitle')}
          information={this.state.formError}
          close={() => this.setState({ showErrorDialog: false })}
          closeText={getMessage('customer.entityMetaData.ok')}
        />
        {isExtensionEnabled('CustomerTags') && <UserTags {...this.props} />}
        {
          this.state.segmentIoTags?.hasError &&
          <>
            <div className="horizontal-line" style={{ marginBottom: '1rem' }}>
              <hr/>
            </div>
            <div style={{ color: 'red', marginLeft: '1.25rem', fontSize: '0.75rem' }}>
              {getMessage('customer.entityMetaData.segmentIo.error')}
            </div>
          </>
        }
        {this.state.segmentIoTags?.length > 0 &&
          <>
            <div className="horizontal-line">
              <hr/>
            </div>
            <SegmentIoTags segmentIoTags={this.state.segmentIoTags} />
          </>
        }
        {isExtensionEnabled('CustomerTags') &&
          Object.keys(this.metaDataWithType).length > 0 && (
            <div className="horizontal-line">
              <hr />
            </div>
          )}
        {Object.keys(this.metaDataWithType).length > 0 && (
          <div className="customer-metadata-form">
            {
              <Form>
                <MetaDataFormFields
                  className="form-fields"
                  metaDataWithType={this.metaDataWithType}
                  page="customer-details-metadata"
                  _this={this}
                />
                <div className="form-actions">
                  <span>
                    {editPermission && (
                      <SubmitButton>
                        {getMessage('customer.entityMetaData.submit')}
                      </SubmitButton>
                    )}
                  </span>
                </div>
              </Form>
            }
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default MetaDataForm
