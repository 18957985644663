import React, { Fragment, useState } from 'react'
import { getMessage } from 'lib/translator'

import { Popup } from 'components/Popup'
import { Textarea, VALIDATION_TYPES } from 'components/Form'

const PartnerOnlineType = ({ _this, isInvalidPartnerOnlineReward }) => {
  const isTypePartnerOnline = _this.state.values?.type === 'PARTNER_ONLINE'
  const { method } = _this.props

  const [showPartnerOnlineCodes, setShowPartnerOnlineCodes] = useState(false)

  const values = Object.assign({}, _this.state.values)
  const { partnerOnlineCodes } = values

  return (
    <Fragment>
      <div
        className={
          isTypePartnerOnline && method === 'add' ? 'col-12' : `hideInputField`
        }
      >
        <Textarea
          label={getMessage(`rewards.partnerOnlineCodes`)}
          placeholder={getMessage(`rewards.partnerOnlineCodesPlaceholder`)}
          type="text"
          {...(isTypePartnerOnline &&
            method === 'add' && {
              required: 'required',
            })}
          {..._this.generateStateMappers({
            stateKeys: ['partnerOnlineCodes'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
      </div>
      {isInvalidPartnerOnlineReward && isTypePartnerOnline && (
        <div className="col-12 error mb">
          {getMessage('rewards.partnerOnlineCodes.error')}
        </div>
      )}
      <div
        className={
          isTypePartnerOnline && method === 'edit' ? 'col-6' : `hideInputField`
        }
      >
        <div className="form-actions">
          <button
            type="button"
            className="primary viewBtn"
            data-testid="viewPartnerOnlineCodes"
            onClick={() => setShowPartnerOnlineCodes(true)}
          >
            {getMessage('rewards.partnerOnlineCodes.viewPromocodes')}
          </button>
        </div>
      </div>
      {showPartnerOnlineCodes && (
        <Popup
          show={showPartnerOnlineCodes}
          heading={getMessage('rewards.partnerOnlineCodes')}
          close={() => setShowPartnerOnlineCodes(false)}
          className="partnerOnlineCodes"
        >
          {partnerOnlineCodes?.length > 0 ? (
            <ol>
              {partnerOnlineCodes.map((details) => (
                <li key={details.id}>{details.code}</li>
              ))}
            </ol>
          ) : (
            <p>{getMessage('rewards.partnerOnlineCodes.notAvailable')}</p>
          )}
        </Popup>
      )}
    </Fragment>
  )
}

export default PartnerOnlineType
