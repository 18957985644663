import React from 'react';
import MultiSelect from '../../../components/Form/Inputs/MultiSelect';

const ZonesMulti = props => {
  const { onChange, storeZoneConfig, value, testId } = props

  const storeZones = storeZoneConfig ? Object.keys(storeZoneConfig).map(key => ({
    text: storeZoneConfig[key].displayName,
    value: storeZoneConfig[key].tag,
  })) : {}

  return (
    <div className="multi-wrapper">
      <span className="labelWrap">
        <label htmlFor="ZonesMulti">Zones</label>
      </span>
      <MultiSelect
      id="zones-multi"
      testId={testId}
      name="ZonesMulti"
      placeholder="Select a zone"
      value={value}
      options={storeZones}
      onChange={onChange}
      disabled={false}
      />
    </div>
  )
}

export default ZonesMulti;
