import React, { Component } from 'react'
import previousIcon from './previous.svg'
import nextIcon from './next.svg'
import { getMessage } from '../../../../lib/translator'
import debounce from 'lodash/debounce'

const IMAGE_SIDES = {
  _XL1: 'FRONT',
  _BXL1: 'BACK',
  _LXL1: 'LEFT',
  _RXL1: 'RIGHT',
}

/***
 * Given an url: https://s3-ap-southeast-1.amazonaws.com/www8.fairprice.com.sg/fpol/media/images/product/XL/13089006_XL1.jpg
 * Output one of [FRONT, BACK, LEFT, RIGHT]
 ***/
const getImageSide = (url = '') => {
  //get fileName: 13089006_XL1.jpg ==> group (id)_(side).(extension) ==> extract "side"
  const key = url.replace(/.*\//, '').replace(/(.*)_(.*)\.(.*)/g, '_$2')
  return IMAGE_SIDES[key]
}

class ImageSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      images: this.props.images || [],
      currentIndex: 0,
      scrollWidth: 0,
    }
    this.goToImage = this.goToImage.bind(this)
    this.setWidth = this.setWidth.bind(this)
    this.resizeWidth = debounce(this.resizeWidth.bind(this), 350)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
  }
  handleTouchEnd() {
    const { start, end } = this.state
    if (start && end) {
      // to avoid nextslide call on single touch
      if (start - end < 0) {
        this.goToImage('previous')
      } else {
        this.goToImage('next')
      }
    }
    this.setState({
      start: 0,
      end: 0,
    })
  }

  handleTouchStart(event) {
    this.setState({ start: event.touches[0].clientX })
  }

  handleTouchMove(event) {
    this.setState({ end: event.touches[event.touches.length - 1].clientX })
  }
  goToImage(button) {
    const sliderContainer = this.sliderRef
    const containerOffsetWidth = sliderContainer.offsetWidth
    const containerScrollWidth = sliderContainer.scrollWidth
    if (containerScrollWidth < 254) {
      return
    }
    if (button === 'next') {
      if (
        containerOffsetWidth + Math.abs(this.state.scrollWidth) <=
        containerScrollWidth
      ) {
        this.setState(
          {
            scrollWidth: this.state.scrollWidth - 254,
            currentIndex: this.state.currentIndex + 1,
          },
          () => {
            sliderContainer.style.transform = `translateX(${this.state.scrollWidth}px)`
          }
        )
      }
    } else {
      if (this.state.scrollWidth < 0) {
        this.setState(
          {
            scrollWidth: this.state.scrollWidth + 254,
            currentIndex: this.state.currentIndex - 1,
          },
          () => {
            sliderContainer.style.transform = `translateX(${this.state.scrollWidth}px)`
          }
        )
      }
    }
  }

  setWidth() {
    const sliderContainer = this.sliderRef
    const containerOffsetWidth = sliderContainer.offsetWidth
    const containerScrollWidth = sliderContainer.scrollWidth
    this.setState({
      containerOffsetWidth: containerOffsetWidth,
      containerScrollWidth: containerScrollWidth,
    })
  }

  resizeWidth() {
    this.setWidth()
  }

  componentDidMount() {
    this.setWidth()
    window.addEventListener('resize', this.resizeWidth, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWidth, false)
  }

  UNSAFE_componentWillReceiveProps({ images }) {
    this.setWidth()
    this.setState({ images: images })
  }

  render() {
    const {
      containerOffsetWidth,
      containerScrollWidth,
      scrollWidth,
      currentIndex,
    } = this.state
    return (
      <div className="product-image-container">
        {containerOffsetWidth < containerScrollWidth ||
        this.state.images.length > 2 ? (
          <button
            type="button"
            onClick={() => this.goToImage('previous')}
            disabled={scrollWidth === 0}
          >
            <img src={previousIcon} alt="previous" />
          </button>
        ) : (
          <span />
        )}
        <div className="preview-slider-container">
          <div
            className="preview-area-container"
            ref={node => {
              this.sliderRef = node
            }}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
          >
            {this.state.images &&
              this.state.images.map((data, index, arr) => (
                <div key={index} className="image-preview-container">
                  {data.uploading ? (
                    <div className="loading-icon">
                      {getMessage('product.form.imageUpload.uploading')}
                    </div>
                  ) : null}
                  {data.error ? (
                    <div className="loading-icon">
                      {getMessage('product.form.imageUpload.error')}
                    </div>
                  ) : null}
                  <div
                    className="delete-icon"
                    onClick={() => {
                      if (
                        arr.length - 1 === index ||
                        arr.length - 2 === index
                      ) {
                        this.setState(
                          { scrollWidth: this.state.scrollWidth + 254 },
                          this.goToImage()
                        )
                      }
                      this.props.removeItem(index)
                    }}
                  >
                    &times;
                  </div>
                  <div className="image-preview">
                    <img src={data.url || data.blob} alt="product-preview" />
                    <label>{getImageSide(data.url)}</label>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {containerOffsetWidth < containerScrollWidth ||
        this.state.images.length > 2 ? (
          <button
            type="button"
            onClick={() => this.goToImage('next')}
            disabled={
              (containerOffsetWidth + Math.abs(scrollWidth) >=
                containerScrollWidth &&
                this.state.images.length < 2) ||
              (currentIndex === this.state.images.length - 2 &&
                (containerOffsetWidth === 492 || containerOffsetWidth === 0))
            }
          >
            <img src={nextIcon} alt="next" />
          </button>
        ) : (
          <span />
        )}
      </div>
    )
  }
}

export default ImageSlider
