import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../Table'
import { DropDown, DropDownItem, ICONS } from '../../DropDown'
import moment from 'moment'
import { getMessage } from 'lib/translator'
import { DATE_TIME_FORMAT } from '../utils'

export const tableProperties = {
  headers: ['ID', 'Start Date', 'End Date', 'Message', 'Actions'],
  row: ({ id, startAt, endAt, message }) => (
    <Row>
      <Cell className="rewards-page-actions column-id">
        <Link to={`/rewards/maintenance/edit/${id}`}>{id}</Link>
      </Cell>
      <Cell className="rewards-page-actions column-startdate">
        {moment(startAt).format(DATE_TIME_FORMAT)}
      </Cell>
      <Cell className="rewards-page-actions column-enddate">
        {moment(endAt).format(DATE_TIME_FORMAT)}
      </Cell>
      <Cell className="rewards-page-actions column-period">{message}</Cell>

      <Cell className="rewards-page-actions column-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <Link to={`/rewards/maintenance/edit/${id}`}>
            <DropDownItem>
              {getMessage('rewards.maintenance.edit')}
            </DropDownItem>
          </Link>
        </DropDown>
      </Cell>
    </Row>
  ),
}
