import React, { Fragment } from 'react'
import {
  BaseForm,
  Select,
  Input,
  Textarea,
  SingleDatePicker,
  VALIDATION_TYPES,
  Checkbox,
} from '../../../../components/Form'
import { Dialog, Popup } from '../../../../components/Popup'
import API from '../../../../lib/api'
import './style.css'
import deleteIcon from './delete.svg'
import GrandTotal from '../../../../components/Evoucher/Create/GrandTotal'
import DenominationAndNoOfVouchers from '../../../../components/Evoucher/Create/DenominationAndNoOfVouchers'
import ConfirmationDetails from '../../../../components/Evoucher/Create/ConfirmationDetails'
import ResendDetails from '../../../../components/Evoucher/ResendDetails'
import {
  multipleEmailErrorMsg,
  validationMsgs,
  emailRegex,
} from '../../../../components/Evoucher/utils'
import { getMessage } from '../../../../lib/translator'

const CUSTOMER_STATUS = [
  {
    value: 'fully_paid',
    text: 'Fully Paid',
  },
  {
    value: 'drawdown',
    text: 'Drawdown',
  },
  {
    value: 'credit',
    text: 'Credit',
  },
]

const errorMsg = 'Something went wrong! please try again later.'

const UpdateComponent = ({ view, generateStateMappers }) => {
  return (
    <div className="resend-details-wrapper mb" data-testid="update-details">
      <div className="row mt">
        <div className="col-6 mt">
          <label>{getMessage('eVoucher.resend.email')}</label>
        </div>
        <div className="col-6">
          <Input
            placeholder="Comma separated list of emails"
            testid="voucherEmails"
            type="text"
            pattern={emailRegex}
            required
            {...generateStateMappers({
              stateKeys: ['voucherEmails'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={multipleEmailErrorMsg}
          />
        </div>
      </div>
      <div className="row mt mb">
        <div className="col-6 mt">
          <label>{getMessage('eVoucher.resend.password')}</label>
        </div>
        <div className="col-6">
          <Input
            placeholder="Comma separated list of emails"
            testid="passwordEmails"
            type="text"
            pattern={emailRegex}
            required
            {...generateStateMappers({
              stateKeys: ['passwordEmails'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={multipleEmailErrorMsg}
          />
        </div>
      </div>
      {!view && (
        <>
          <div className="row mt mb">
            <div className="col-6 mt">
              <label>{getMessage('eVoucher.create.invoice')}</label>
            </div>
            <div className="col-6">
              <Input
                placeholder={getMessage('eVoucher.create.invoice')}
                testid="invoiceNumber"
                required
                type="text"
                {...generateStateMappers({
                  stateKeys: ['invoiceNumber'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 mt mb">
              <label>{getMessage('eVoucher.create.allow')}</label>
            </div>
            <div className="col-6 mb">
              <Checkbox
                controlled
                name="cannotExtendPast"
                {...generateStateMappers({
                  stateKeys: ['cannotExtendPast'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const AddComponentTextArea = ({ generateStateMappers }) => {
  return (
    <div className="row">
      <div className="col-12 my">
        <Textarea
          label="Remarks"
          placeholder="Remarks"
          testid="remarks"
          required
          type="text-area"
          {...generateStateMappers({
            stateKeys: ['remarks'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    </div>
  )
}

const AddListComponent = ({
  inputList,
  generateStateMappers,
  handleAddClick,
  handleRemoveClick,
}) => {
  return inputList.map((x, i) => (
    <div key={`list + ${x}`}>
      <div className="row" key={`list + ${x}`}>
        <div className="col-4">
          <Input
            placeholder="Denomination"
            value={x.denomination}
            testid={`denomination-${i}`}
            type="number"
            prefix="$"
            step="0.01"
            min={0.01}
            max={1000}
            {...generateStateMappers({
              stateKeys: ['inputList', i, 'denomination'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            required
            validationStrings={validationMsgs}
          />
        </div>
        <div className="col-4 ml">
          <Input
            placeholder="Number of E-Vouchers"
            testid={`noOfEvouchers-${i}`}
            value={x.noOfEvouchers}
            type="number"
            min={1}
            {...generateStateMappers({
              stateKeys: ['inputList', i, 'noOfEvouchers'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            required
          />
        </div>
        <div className="col-1 ml">
          {inputList.length !== 1 && (
            <button
              className="mr10"
              type="button"
              data-testid={`delete-btn-${i}`}
              onClick={(e) => handleRemoveClick(e, i)}
            >
              <img src={deleteIcon} alt="delete" />
            </button>
          )}
        </div>
      </div>
      {inputList.length - 1 === i && (
        <div className="add-more">
          <button
            data-testid="add-more-btn"
            type="button"
            onClick={handleAddClick}
          />
          Add More
        </div>
      )}
    </div>
  ))
}
class EvoucherForm extends BaseForm {
  constructor(props) {
    super(props)

    const inputList =
      this.state.values.inputList && this.state.values.inputList.filter(Boolean)
    if (!inputList) {
      this.state.values = {
        inputList: [{ denomination: '', noOfEvouchers: '' }],
        showPopup: false,
        updateData: JSON.parse(localStorage.getItem('updateExpiry')),
        updatePopup: false,
        checkedDetails: false,
        onConfirmClick: false,
        entityNames: [],
        error: '',
        success: false,
      }
    }

    this.handleRemoveClick = this.handleRemoveClick.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleConfirmation = this.handleConfirmation.bind(this)
    this.hidePopup = this.hidePopup.bind(this)
    this.handleResendEmailsSubmission =
      this.handleResendEmailsSubmission.bind(this)
  }
  async handleResendEmailsSubmission() {
    const { voucherEmails, passwordEmails } = this.state.values

    try {
      const voucherEmailsArr = voucherEmails
        .split(',')
        .map((item) => item.trim())
      const passwordEmailsArr = passwordEmails
        .split(',')
        .map((item) => item.trim())
      const data = {
        voucherEmails: voucherEmailsArr,
        passwordEmails: passwordEmailsArr,
      }
      const batchId = this.props.value.id
      const api = new API({ url: `/batches/${batchId}/issue` })
      const response = await api.post(data)
      response && this.props.onCancel()
    } catch (error) {
      const values = Object.assign({}, this.state.values)
      values.error = error.message || errorMsg
      this.setState({
        values: values,
      })
    }
  }

  handleCloseSuccessMessage() {
    const values = Object.assign({}, this.state.values)
    values.success = false
    this.setState({
      values: values,
    })
  }

  handleUpdateExpiryState = (dataObject) => {
    const values = Object.assign({}, this.state.values)
    values.updateData.expiresAt = dataObject.expiresAt
    this.setState({
      values: values,
    })
  }

  async handleUpdateExpiry() {
    const { expiryDate } = this.state.values

    try {
      const data = {
        expiresAt: expiryDate,
      }
      const batchId = this.props.value
        ? this.props.value.id
        : this.state.values.updateData.id
      const api = new API({ url: `/batches/${batchId}` })
      const response = await api.patch(data)
      if (response) {
        this.handleUpdateExpiryState(data)
        const values = Object.assign({}, this.state.values)
        values.updatePopup = false
        values.success = true
        this.setState({
          values: values,
        })
        const objStore = JSON.parse(localStorage.getItem('updateExpiry'))
        objStore.expiresAt = expiryDate
        localStorage.setItem('updateExpiry', JSON.stringify(objStore))
      }
    } catch (error) {
      const values = Object.assign({}, this.state.values)
      values.error = error.message || errorMsg
      this.setState({
        values: values,
      })
    }
  }

  handleConfirmation() {
    const values = Object.assign({}, this.state.values)
    values.showPopup = true
    this.setState({
      values: values,
    })
  }
  hidePopup() {
    const values = Object.assign({}, this.state.values)
    values.showPopup = false
    this.setState({
      values: values,
    })
  }
  // handle click event of the Remove button
  handleRemoveClick(e, index) {
    const values = Object.assign({}, this.state.values)
    const validations = Object.assign({}, this.state.validations)
    const inputList = values.inputList
    inputList.splice(index, 1)
    this.setState({
      values,
      validations,
    })
  }

  // handle click event of the Add button
  handleAddClick() {
    const values = Object.assign({}, this.state.values)
    const data = { denomination: '', noOfEvouchers: '' }
    const inputList = values.inputList
    inputList.push(data)
    this.setState({
      values: values,
    })
  }

  _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    if (isValid && this.props.method === 'add') {
      this.handleConfirmation()
    }
    if (isValid && this.props.method === 'view') {
      this.handleResendEmailsSubmission()
    }
    if (isValid && this.props.method === 'update_expiry') {
      const values = Object.assign({}, this.state.values)
      values.updatePopup = true
      this.setState({
        values: values,
      })
    }
  }
  async componentDidMount() {
    if (this.props.method === 'add') {
      const api = new API({ url: '/entities' })
      const values = Object.assign({}, this.state.values)
      try {
        const response = await api.get()

        const raw = response || []
        let entityNames = raw.map((element) => {
          return {
            value: `${element.id}-${element.name}`,
            text: element.name,
          }
        })
        const otherEntity = {
          value: '0-Other',
          text: 'Other',
        }

        entityNames = [...entityNames, otherEntity]
        values.entityNames = entityNames

        this.setState({
          values: values,
        })
      } catch (error) {
        values.error = error.message || errorMsg
        this.setState({
          values: values,
        })
      }
    }
  }

  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons
    const { entityName, entityNames, inputList, error, success } =
      this.state.values
    const totalAmountStep1 = inputList.map(function (element) {
      return element.denomination * element.noOfEvouchers
    })
    const totalAmount = totalAmountStep1.reduce(
      (a, v) => Number(a) + Number(v),
      0
    )
    const totalNoOfVouchers = inputList.reduce(
      (a, x) => Number(a) + Number(x.noOfEvouchers),
      0
    )

    const { method } = this.props
    const add = method === 'add'
    const view = method === 'view'
    const updateExpiry = method === 'update_expiry'
    const resendData = this.props.value || this.state.values.updateData

    return (
      <Form className={`evoucher-wrapper contain ${method}`}>
        {success ? (
          <div className="col-4 success" id="succcess-dialog">
            {getMessage('eVoucher.update.message')}
            <strong
              data-testid="ok-strng-btn"
              onClick={() => this.handleCloseSuccessMessage()}
              className="strong-btn"
            >
              {getMessage('rewardPage.dialogs.disable.okText')}
            </strong>
          </div>
        ) : (
          ''
        )}
        {add && (
          <>
            <div className="col-6">
              <Select
                label="Entity Name"
                testid="entity-names"
                placeholder="Entity Name"
                options={entityNames}
                {...this.generateStateMappers({
                  stateKeys: ['entityName'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                required
              />
            </div>
            {/* const entityId = Number(entityName.split('-')[0]) */}
            <div className="col-6">
              <Select
                label={getMessage('eVoucher.add.customer.status')}
                testid="customer-status"
                placeholder={getMessage('eVoucher.add.customer.status')}
                options={CUSTOMER_STATUS}
                {...this.generateStateMappers({
                  stateKeys: ['customerStatus'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                required
              />
            </div>
            {['1250-Fairprice', '4-FairPrice'].includes(entityName) ? (
              <div className="col-6">
                <Input
                  label={getMessage('eVoucher.add.cost.centre')}
                  testid="cost-centre"
                  placeholder={getMessage('eVoucher.add.cost.centre')}
                  {...this.generateStateMappers({
                    stateKeys: ['costCenter'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
            ) : null}
          </>
        )}
        {entityName === '0-Other' ? (
          <div className="col-6 mt">
            <Input
              placeholder="Input Entity Name"
              testid="other-entity"
              type="text"
              required
              {...this.generateStateMappers({
                stateKeys: ['otherEntityName'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        ) : (
          ''
        )}
        <DenominationAndNoOfVouchers method={add} />
        {add ? (
          <AddListComponent
            generateStateMappers={this.generateStateMappers}
            inputList={inputList}
            handleAddClick={this.handleAddClick}
            handleRemoveClick={this.handleRemoveClick}
          />
        ) : (
          ''
        )}
        <GrandTotal
          totalAmount={totalAmount}
          totalNoOfVouchers={totalNoOfVouchers}
          method={add}
        />
        {add ? (
          <Fragment>
            <hr />
            <div className="row">
              <h3 className="mt-0">Personalization</h3>
            </div>
            <div className="row">
              <div className="col-4">
                <Input
                  label="Name"
                  placeholder="Name"
                  name="personalisedName"
                  testid="personalisedName"
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['personalisedName'],
                  })}
                />
              </div>
              <div className="col-4 ml">
                <Input
                  label="Title"
                  placeholder="Title"
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['title'],
                  })}
                  testid="title"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Textarea
                  label="Message"
                  placeholder="Message"
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['message'],
                  })}
                  testid="message"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {view ? <ResendDetails method={method} values={resendData} /> : ''}
        {updateExpiry ? (
          <ResendDetails method={method} values={resendData} />
        ) : (
          ''
        )}
        {updateExpiry ? (
          <div
            className="resend-details-wrapper mb"
            data-testid="update-details"
          >
            <div className="row mt mb">
              <div className="col-6 mt">
                <label>{getMessage('eVoucher.update.newexpiry')}</label>
              </div>
              <div className="col-6">
                <SingleDatePicker
                  openDirection="up"
                  enableToday={false}
                  id="expiryPicker"
                  name="expiryPicker"
                  key="start-date"
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['expiryDate'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {!updateExpiry ? (
          <UpdateComponent
            view={view}
            generateStateMappers={this.generateStateMappers}
          />
        ) : (
          ''
        )}
        {add && (
          <AddComponentTextArea
            generateStateMappers={this.generateStateMappers}
          />
        )}
        <div className="row">
          <div className="form-actions">
            <SubmitButton testid="confirm-submit">Submit</SubmitButton>
            <CancelButton testid="cancel-submit">Cancel</CancelButton>
          </div>
        </div>
        {error ? <div className="col-6 error">{error}</div> : ''}
        <Dialog
          show={this.state.values.updatePopup}
          title={getMessage('deleteDialog.title')}
          information={getMessage('eVoucher.update.content')}
          onOk={() => this.handleUpdateExpiry()}
          close={() => {
            const values = Object.assign({}, this.state.values)
            values.updatePopup = false
            this.setState({
              values: values,
            })
          }}
          closeText={getMessage('dialog.cancelText')}
          okText={getMessage('dialog.okText')}
        />
        <Popup
          show={this.state.values.showPopup}
          heading={'Confirm E-Voucher Details'}
          close={this.hidePopup}
          className="confirmPopup"
          data-testid="confirm-popup"
        >
          <ConfirmationDetails
            entityName={entityName}
            otherEntityName={this.state.values.otherEntityName}
            inputList={inputList}
            totalAmount={totalAmount}
            totalNoOfVouchers={totalNoOfVouchers}
            voucherEmails={this.state.values.voucherEmails}
            passwordEmails={this.state.values.passwordEmails}
            costCenter={this.state.values.costCenter}
            invoiceNumber={this.state.values.invoiceNumber}
            remarks={this.state.values.remarks}
            personalisedName={this.state.values.personalisedName}
            message={this.state.values.message}
            customerStatus={this.state.values.customerStatus}
            title={this.state.values.title}
            onCancel={this.props.onCancel}
            cannotExtendPast={this.state.values.cannotExtendPast}
          />
        </Popup>
      </Form>
    )
  }
}
export default EvoucherForm
