import { ACTION_TRAIL_API } from 'config/app'

/**
 *This method checks if this error contains anything related to the action trail
 * POST call or GET call
 * @param {*} data
 */
export const checkforActionTrailErrors = (error, callMethod) => {
  const reason = error.reason
  if (reason) {
    const url = reason.url

    if (
      url &&
      (url.includes(ACTION_TRAIL_API) || url.includes('actions-trail'))
    ) {
      const method = reason.method
      if (method && method.toUpperCase() === callMethod) {
        return true
      }
    }
  }
  return false
}
