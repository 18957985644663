import React from 'react'
import API from '../../lib/api'
import GoogleMaps, { Polyline, Marker, MarkerWithLabel } from '../GoogleMaps'
import { FPGP_MAPS_URL } from '../../config/app'
import Loader from '../Loader'

import MarkerIcon from './map-marker.svg'
import StoreMarker from './store-marker.svg'
import VehicleMarker from './vehicle-marker.svg'

import './style.css'

const lineSymbol = {
  path: 'M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z',
  fillColor: '#0000FF',
  fillOpacity: 0.6,
  strokeWeight: 1,
  scale: 1,
}

class MapWithPath extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: [],
      addresses: this.getAddressString(),
      markers: [],
    }
    this.mapMounted = this.mapMounted.bind(this)
    this.getCenter = this.getCenter.bind(this)
    this.getAddressString = this.getAddressString.bind(this)
    this.getMarkers = this.getMarkers.bind(this)
    this.getPath = this.getPath.bind(this)
  }

  getAddressString() {
    const { coordinates, vehiclePath } = this.props
    return !vehiclePath
      ? coordinates &&
          coordinates
            .map(
              (location) =>
                location.latitude &&
                location.longitude &&
                `&point=${location.latitude},${location.longitude}`
            )
            .filter(Boolean)
      : vehiclePath
          .map(
            (location) =>
              location.latitude &&
              location.longitude &&
              `&point=${location.latitude},${location.longitude}`
          )
          .filter(Boolean)
  }

  getMarkers() {
    const { coordinates } = this.props
    const locationMarkers =
      coordinates.length &&
      coordinates.map((location) => {
        if (!location.latitude || !location.longitude) {
          return null
        }
        return {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
        }
      })
    return locationMarkers ? locationMarkers.filter(Boolean) : []
  }

  mapMounted(ref) {
    this.setState({ map: ref })
  }

  getCenter() {
    let locations = JSON.parse(JSON.stringify(this.state.markers))
    if (this.props.showStore) {
      locations.push({
        lat:
          this.props.showStore.latitude &&
          parseFloat(this.props.showStore.latitude),
        lng:
          this.props.showStore.longitude &&
          parseFloat(this.props.showStore.longitude),
      })
    }
    if (this.props.showVehicle) {
      locations.push({
        lat:
          this.props.showVehicle.latitude &&
          parseFloat(this.props.showVehicle.latitude),
        lng:
          this.props.showVehicle.longitude &&
          parseFloat(this.props.showVehicle.longitude),
      })
    }
    if (this.state.points) {
      locations = locations.concat(this.state.points)
    }
    const points = locations
    if (points && points.length > 0 && window.google) {
      const bounds = new window.google.maps.LatLngBounds()
      for (let i = 0; i < points.length; i++) {
        bounds.extend(points[i])
      }
      this.state.map && this.state.map.fitBounds(bounds)
      return bounds.getCenter()
    }
    if ('geolocation' in window.navigator) {
      window.navigator.geolocation.getCurrentPosition(({ coords }) => {
        return { lat: coords.latitude, lng: coords.longitude }
      })
    }
    return {}
  }

  getPath() {
    this.setState({ markers: this.getMarkers() })
    const api = new API({
      url: `${FPGP_MAPS_URL}/route/?points_encoded=false${this.state.addresses}&locale=en-US&vehicle=car&weighting=fastest&elevation=false&layer=OpenStreetMap`,
    })
    if (this.state.addresses.length > 1 && this.props.callMaps !== false) {
      this.setState({ dataLoading: true })
      api.get().then(
        (response) => {
          const apiPoints = response.paths[0].points.coordinates
          const points = apiPoints.map((point) => {
            return {
              lat: point[1],
              lng: point[0],
            }
          })
          this.setState({
            points: points,
            dataLoading: false,
          })
        },
        (error) => {
          this.setState({
            error: error,
            dataLoading: false,
          })
        }
      )
    } else if (!this.props.callMaps) {
      const { vehiclePath } = this.props
      const points =
        vehiclePath &&
        vehiclePath.map((point) => ({
          lat: parseFloat(point.latitude),
          lng: parseFloat(point.longitude),
        }))
      let vehiclePoint = null
      if (points && points.length > 0) {
        const point = points.slice(-1)[0]
        vehiclePoint = {
          latitude: point.lat,
          longitude: point.lng,
        }
      }
      this.setState({
        points: points,
        dataLoading: false,
        vehiclePoint,
      })
    }
  }

  compareArrays(prevProps, currentProps) {
    if (prevProps.length !== currentProps.length) {
      return false
    } else {
      for (let i = 0; i < prevProps.length; i++) {
        if (prevProps[i] !== currentProps[i]) {
          return false
        }
      }
    }
    return true
  }

  componentDidUpdate() {
    // If props are changed, fetch the route again
    if (
      !this.compareArrays(
        this.state.addresses,
        this.getAddressString(this.props.coordinates)
      )
    ) {
      this.setState(
        { addresses: this.getAddressString(this.props.coordinates) },
        () => {
          this.getPath()
        }
      )
    }
  }

  componentDidMount() {
    this.getPath()
  }

  render() {
    const vehiclePoint = this.props.showVehicle || this.state.vehiclePoint
    return (
      <div className="map-with-path">
        <GoogleMaps
          onMapMounted={this.mapMounted}
          zoom={15}
          containerClassName="address-map"
          getCenter={this.getCenter}
        >
          {this.state.dataLoading && <Loader />}
          {this.state.markers &&
            window.google &&
            this.state.markers.map((marker, i) => (
              <MarkerWithLabel
                key={`marker-${i}`}
                labelAnchor={new window.google.maps.Point(9, 43)}
                position={marker}
                icon={MarkerIcon}
                labelStyle={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#fff',
                  padding: '5px',
                }}
              >
                <div>{i + 1}</div>
              </MarkerWithLabel>
            ))}
          {this.props.showStore && (
            <Marker
              position={{
                lat:
                  this.props.showStore.latitude &&
                  parseFloat(this.props.showStore.latitude),
                lng:
                  this.props.showStore.longitude &&
                  parseFloat(this.props.showStore.longitude),
              }}
              icon={StoreMarker}
            />
          )}
          {vehiclePoint && (
            <Marker
              position={{
                lat: vehiclePoint.latitude && parseFloat(vehiclePoint.latitude),
                lng:
                  vehiclePoint.longitude && parseFloat(vehiclePoint.longitude),
              }}
              icon={VehicleMarker}
            />
          )}
          {this.state.points && (
            <Polyline
              defaultPath={this.state.points}
              path={this.state.points}
              options={{
                fillColor: '#ff0000',
                fillOpacity: 0.4,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                strokeColor: '#ff0000',
                editable: false,
              }}
              icons={[
                {
                  icon: lineSymbol,
                  repeat: '50px',
                },
              ]}
            />
          )}
        </GoogleMaps>
      </div>
    )
  }
}

export default MapWithPath
