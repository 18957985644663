import React from 'react'
import Image from '../Image'
import './style.scss'

export default function InfoCard(props) {
  return (
    <div className={`info-card-container ${props.className}`}>
      <Image src={props.src} />
      <div className="info-card-details">
        <div className="title">{props.title}</div>
        <div className="description">{props.description}</div>
      </div>
    </div>
  )
}
