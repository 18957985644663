/*
    If you want your sub menu item to appear in specific order, add it in orderedSubMenuItems below.
    The order of items that you see here are the orders that will be displayed in sub menu.
    If your menu item is not in below list, it will be added to the last index of the menu.
*/

const orderedSubMenuItems = require('./orderedSubMenuItems.json')

function reorderSubMenuItems(items) {
  const newOrderedItemsList = {}
  for (const [mainMenu, subMenuItems] of Object.entries(items)) {
    if (mainMenu in orderedSubMenuItems) {
      const newItems = []
      if (!Array.isArray(newOrderedItemsList[mainMenu])) {
        newOrderedItemsList[mainMenu] = []
      }

      /* iterate subMenuItems and check if a subMenuItem exists in orderedSubMenuItems[mainMenu] array */
      subMenuItems.forEach((item) => {
        const index = orderedSubMenuItems[mainMenu].findIndex((x) => x === item)
        if (index !== -1) {
          /* if item is found, add it to the index of the array */
          newOrderedItemsList[mainMenu][index] = item
        } else {
          /* if item is not found, add it to new items to be appended later */
          newItems.push(item)
        }
      })

      /* remove empty indexes(if any) and concat with new items */
      newOrderedItemsList[mainMenu] = newOrderedItemsList[mainMenu]
        .filter((x) => x)
        .concat(newItems)
    }
  }
  return newOrderedItemsList
}

export { reorderSubMenuItems, orderedSubMenuItems }
