import React from 'react'
import classNames from '../../lib/utils/classNames'

const NoCell = (props) => (
  <div
    className={classNames([
      'no-row',
      'table-cell',
      props.className || '',
      props.align === 'right' ? 'align-right' : '',
    ])}
    data-abbreviation={props.abbreviation}
  >
    {props.children}
  </div>
)

export default NoCell
