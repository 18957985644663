import React from 'react'
import CardContent from 'components/AppHome/AppCard/CardContent'
import CardMetadata from 'components/AppHome/AppCard/CardMetadata'
import { formatValidity } from 'components/AppHome/utils'
import Card from 'components/AppHome/AppCard/Card'
import Validity from 'components/AppHome/Validity'
import { getMessage } from 'lib/translator'
import { BaseForm } from 'components/Form'
import { Dialog } from 'components/Popup'
import Loader from 'components/Loader'
import API from 'lib/api'
import './style.css'

class ManageCardForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      categories: [],
      loading: false,
      previewCard: false,
      showErrorDialog: false,
      errorMessage: null,
      readOnlyField: false,
      isPublishing: false,
      values: {
        startTime: '00:00:00',
        endTime: '00:00:00',
        texts: {
          text1: '',
        },
      },
    }
  }

  async componentDidMount() {
    const { method } = this.props
    this.setState({ loading: true })
    if (method === 'add') {
      const cardsCategoriesAPI = new API({
        url: `/genie/lms/content?contentType=category&key=cards`,
      })
      cardsCategoriesAPI.get().then(
        (res) => {
          if (!res.data.items) {
            return
          }
          const categories = res.data.items.map((item) => item.name)
          this.setState({ categories, loading: false })
        },
        (error) => {
          this.setState({
            showErrorDialog: true,
            errorMessage: `${error.message} while retrieving categories.`,
            loading: false,
          })
        }
      )
    }
    if (method === 'edit') {
      const apiData = this.props.apiData.data.items[0]
      const validity = await formatValidity('api', apiData)
      const values = {
        ...apiData,
        ...validity,
        category: apiData.category.replace('cards#', ''),
      }
      this.setState({ values, loading: false })
    }
  }

  async _submitHandler(e, saveAsDraft = false) {
    e && e.preventDefault()
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const { method, onSubmit } = this.props
    const { categories, values } = this.state
    if (this.isFormValid()) {
      this.setState({ loading: true })
      const validity = await formatValidity('form', values)
      const dataToSubmit = {
        ...values,
        ...validity,
        isDisabled: saveAsDraft,
      }
      if (method === 'add' && !categories.includes(values.category)) {
        new API({ url: '/genie/lms/content' })
          .post({
            contentType: 'category',
            key: 'cards',
            data: {
              startTime: '0000-01-01T00:00:00+08:00',
              endTime: '9999-01-01T00:00:00+08:00',
              isDisabled: false,
              name: values.category,
            },
          })
          .then(
            () => onSubmit(dataToSubmit),
            (error) => {
              this.setState({
                showErrorDialog: true,
                errorMessage: `${error.message} while creating new category.`,
                loading: false,
              })
            }
          )
      } else {
        await onSubmit(dataToSubmit)
      }
    }
  }

  render() {
    const { Form } = this.components
    const { CancelButton, SubmitButton } = this.buttons
    const { showErrorDialog, loading, previewCard } = this.state

    return (
      <div className="appcard-form">
        {showErrorDialog && (
          <Dialog
            show={this.state.showErrorDialog}
            information={this.state.errorMessage}
            close={() =>
              this.setState({
                showErrorDialog: false,
                errorMessage: null,
              })
            }
            closeText={getMessage('apphome.okay')}
          />
        )}
        {loading && <Loader size="sm" />}
        {!loading && (
          <Form className="grid-2-col">
            {previewCard && (
              <Card
                src={this.state.values.image}
                category={this.state.values.category}
                texts={this.state.values.texts}
              />
            )}
            {!previewCard && (
              <CardContent generateStateMappers={this.generateStateMappers} />
            )}
            <button
              type="button"
              className="button preview-button"
              onClick={() =>
                this.setState({ previewCard: !this.state.previewCard })
              }
            >
              {!previewCard
                ? getMessage('app.card.preview')
                : getMessage('apphome.back')}
            </button>
            <CardMetadata
              method={this.props.method}
              generateStateMappers={this.generateStateMappers}
            />
            <h3>{getMessage('apphome.validity')}</h3>
            <Validity
              readOnlyField={false}
              generateStateMappers={this.generateStateMappers}
            />
            <div className="appFormButtons">
              <>
                <CancelButton>{getMessage('apphome.cancel')}</CancelButton>
              </>
              <button
                type="button"
                className="primary"
                data-testid="save-button"
                onClick={(e) => this._submitHandler(e, true)}
              >
                {getMessage('apphome.saveasdraft')}
              </button>
              <SubmitButton>{getMessage('apphome.enable')}</SubmitButton>
            </div>
          </Form>
        )}
      </div>
    )
  }
}

export default ManageCardForm
