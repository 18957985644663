import { Popup } from '../../Popup'
import './style.css'
import { Select, Checkbox } from '../../Form'
import React, { Fragment } from 'react'
import { getMessage } from '../../../lib/translator'

const ReasonPopup = ({
  show,
  close,
  heading,
  question,
  selectReason,
  submitButtonText,
  backButtonText,
  reasons,
  handleSubmit,
  handleBack,
  state,
  data,
  value,
  multiValue,
  changeReason,
  changeMultipleReason,
  selectAllReasons,
}) => {
  return (
    <Popup className="reasonPopup" show={show} close={close} nohide={true}>
      <h3>{heading}</h3>
      {state.cancelOrder ? (
        <div className="scroll-reasons">
          {data[0].subOrders.map((item, itemIndex) => (
            <Fragment key={itemIndex}>
              {state.refundItems.filter(itemA => itemA.value).length > 1 ? (
                <div
                  className={`question ${itemIndex > 0 && 'multiple-reason'}`}
                >
                  {question} {item.foodSupplier.name} ({item.id}){' '}
                </div>
              ) : (
                <div className="question">{question} </div>
              )}
              <Select
                data-testid={`sub-order-status-multiple-select${itemIndex}`}
                name={`sub-order-status-multiple-select${itemIndex}`}
                placeholder={selectReason}
                options={
                  reasons &&
                  reasons.map(option => {
                    return {
                      text: option.text,
                      value: option.value,
                    }
                  })
                }
                value={multiValue.find(val => val.id === item.id).cancelReason}
                onChange={e => changeMultipleReason(e, item.id)}
                disabled={itemIndex === 0 ? false : !multiValue[0].cancelReason}
              />
              {itemIndex === 0 &&
                state.refundItems.filter(itemA => itemA.value).length > 1 && (
                  <Checkbox
                    testid="selectAllReasons"
                    name="selectAllReasons"
                    id="selectAllReasons"
                    value={state.selectAllReasons}
                    inlineLabel={getMessage(
                      'qc.orderSummary.reasonpopup.ApplyReason'
                    )}
                    labelClass={'top-gap'}
                    onChange={selectAllReasons}
                    disabled={!multiValue[0].cancelReason}
                  />
                )}
            </Fragment>
          ))}
        </div>
      ) : (
        <Fragment>
          <div className="question">{question} </div>
          <Select
            data-testid="sub-order-status-select"
            placeholder={selectReason}
            options={
              reasons &&
              reasons.map(elm => {
                return {
                  text: elm.text,
                  value: elm.value,
                }
              })
            }
            value={value}
            onChange={changeReason}
          />
        </Fragment>
      )}

      <div className="button-container">
        <button
          onClick={handleSubmit}
          disabled={
            state.cancelOrder &&
            state.refundItems.filter(item => item.value).length > 1
              ? !multiValue.every(item => item.cancelReason)
              : state.changeReasonSubmitted
          }
          className="primary button"
          data-testid="reasonpopup-submit"
        >
          {submitButtonText}
        </button>
        <button onClick={handleBack} className="button" id="reasonpopup-back">
          {backButtonText}
        </button>
      </div>
    </Popup>
  )
}

export default ReasonPopup
