import React, { Component } from 'react'

import Upload from '../../../../components/FileUpload'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import { Link } from 'react-router-dom'
import csvIcon from './csv-icon.svg'
import uploadIcon from './upload-products.svg'

import './style.css'

function downloadCsv(data) {
  let csv = ''
  csv += data.join(',')
  csv += '\n'
  var hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  hiddenElement.target = '_blank'
  hiddenElement.download = 'product.csv'
  hiddenElement.click()
}

export default class ProductUpload extends Component {
  constructor(props) {
    super(props)
    this.getSampleCsv = this.getSampleCsv.bind(this)
  }

  getSampleCsv(e) {
    e.preventDefault()
    const api = new API({ url: '/catalogue-service/product-upload' })
    api.get().then(
      response => {
        downloadCsv(response.data.fileUpload || [])
      },
      error => {
        this.setState({ uploadError: error.message })
      }
    )
  }

  render() {
    const { hideAddButton } = this.props
    return (
      <div className="product-upload">
        {!hideAddButton ? (
          <div className="text-center">
            <Link
              to="/catalogue/products/add"
              className="button primary add-product-link"
            >
              + {getMessage('product.add.text')}
            </Link>
          </div>
        ) : null}
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          {!hideAddButton ? (
            <span className="border-text">
              {getMessage('productUpload.orText')}
            </span>
          ) : null}
          <Upload
            name="bulkProductUpload"
            accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
            placeholder={getMessage('productUpload.uploadFileText')}
            uploadButtonText="Upload"
            cancelText="Cancel"
            uploadUrl="/catalogue-service/product-upload"
            validationStrings={{
              invalidFileType: getMessage('input.invalidFileType'),
            }}
            strings={{
              defaultMessage: getMessage('fileUpload.importCsv.heading'),
              progressMessage: getMessage('fileUpload.importedCsv.heading'),
              completionMessage: getMessage('fileUpload.uploadedCsv.heading'),
            }}
            icon={csvIcon}
          />
        </div>
        <div className="text-center">
          <a className="download-link" onClick={this.getSampleCsv}>
            {getMessage('productUpload.csvDownloadText')}
          </a>
        </div>
      </div>
    )
  }
}
