import React, {useEffect, useState, useRef, useCallback} from 'react'
import { Row, Cell } from 'components/Table'
import { getMessage } from 'lib/translator'
import { Link } from 'react-router-dom'
import phoneIcon from '../svg/phone.svg'
import moment from 'moment';
import Placeholder from '../svg/customer-image-placeholder.svg'
import { format, parse } from 'libphonenumber-js';
import Loader from 'components/Loader';
import { calculateDuration } from './helpers';
import './style.scss';

const ActiveZonePickers = ({ employees, activeZonePickers, onUnassignZonePicker }) => {
  const [disable, setDisable] = useState(false);

  const handleUnassign = e => {
    setDisable(true);
    const filteredZonePickers = activeZonePickers.filter(p => {
      return parseInt(p.pickerId,10) === parseInt(e.target.value, 10)
    })
    onUnassignZonePicker(filteredZonePickers);
  }

  return (
    <React.Fragment>
      <div className="pickers-active-picker-header">
        {getMessage('pickers.active.zone.header')}
      </div>
      <div className="pickers-table">
        {
          activeZonePickers.map(picker => {
            const employee = employees.find(x => x.id === parseInt(picker.pickerId));
            if (employee) {
              return <ZonePickerCard
                key={employee.id}
                employee={employee}
                picker={picker}
                handleUnassign={handleUnassign}
                disable={disable} />
            }
            return null;
          })
        }
      </div>
    </React.Fragment>
  )
}

const ZonePickerCard = ({ employee, picker, disable, handleUnassign }) => {
  const startTime = moment(picker.startTime).toDate() / 1000;

  const timerRef = useRef(0);
  const [currentDuration, setCurrentDuration] = useState(null);

  const timerCallback = useCallback(() => {
    const currentTime = (Math.floor(Date.now() / 1000));
    const newDuration = calculateDuration(startTime, currentTime);
    setCurrentDuration(newDuration);
  }, [picker])

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, 1000);

    return () => {
      clearInterval(timerRef.current);
    }
  }, [])

  return (
    <Row className="pickers-table-row">
      <Cell className="pickers-table-cell">
        <div className="active-picker-section">
          <div className="pickers-active-employee-details-section">
            <div className="pickers-emp-name-image">
              {employee.imageUrl ? (
                <img
                  className="pickers-employee-image"
                  src={employee.imageUrl}
                  alt=""
                />
              ) : (
                <img
                  className="pickers-employee-image-empty"
                  src={Placeholder}
                  alt=""
                />
              )}
              <div className="pickers-employee-name">
                {employee ? employee.name : ''}
                <br />
                <span className="pickers-employee-phone">
                  {employee && !!employee.phones.length && (
                    <img
                      className="pickers-phone-icon"
                      src={phoneIcon}
                      alt=""
                    />
                  )}
                  {employee
                    ? employee.phones.length
                      ? employee.phones[0].phone &&
                        Object.keys(parse(employee.phones[0].phone))
                          .length > 0
                        ? format(
                            parse(employee.phones[0].phone),
                            'International'
                          )
                        : employee.phones[0].phone
                      : ''
                    : ''}
                </span>
              </div>
            </div>
          </div>
          <div className="pickers-order-details">
            <span className="pickers-employee-name">
              {getMessage('picker.today.picklistId')}&nbsp;
              <Link to={`/operations/delivery-orders?picklistId=${picker.picklistId}`}>
                {picker.picklistId}
              </Link>
            </span>

            {/* Need to add condition to check whether picklist Id exists*/}
            <div  className="pickers-picking-item" style={{marginTop: '4px', font: '14px'}}> {getMessage('order.log.picklist.quantity')} : {picker.itemsQuantity}</div>
            <span className="curve-box" style={{marginTop: '4px'}}>{getMessage('picker.today.batch')} - {picker.zone}</span>

              <div className='start-time-text'>
                <span>{getMessage('picker.today.startTime')}:</span>
                <div>
                  {moment(picker.startTime).format('D MMM YYYY')},
                  &nbsp;{moment(picker.startTime).format('h:mm A')}
                </div>
              </div>

            { currentDuration ? (
              <div className='elapsed-time-container'>
                <div className='sub-heading'>{getMessage('picker.today.elapsedTime')}</div>
                <div className='duration-text'>
                  {currentDuration}
                </div>
              </div>
            ) : <Loader size='sm' />}
            <button
              value={picker.pickerId}
              className="button primary btnUnassign"
              onClick={e => handleUnassign(e)}
              disabled={disable}>
              {getMessage('picker.today.unassignZonePicker')}
            </button>
          </div>
        </div>
      </Cell>
    </Row>
  )
}

export default ActiveZonePickers
