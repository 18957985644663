import React, { Fragment } from 'react'
import { Textarea, VALIDATION_TYPES } from '../../Form'
import { getMessage } from '../../../lib/translator'
import StoryInfo from './StoryInfo'

const WebInfo = ({ _this, webInfo }) => {
  const { story } = { ...webInfo }
  const WEB_INFORMATIONS = [['metaTitle', 'metaDescription'], ['story']]
  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <h3>{getMessage('rewards.webInfo')}</h3>
        </div>
      </div>
      {WEB_INFORMATIONS.map((informations, i) => {
        return (
          <div className="row" key={i}>
            {informations.map((info, j) => (
              <div className="col-12" key={j}>
                {info === 'story' ? (
                  <StoryInfo _this={_this} story={story} />
                ) : (
                  <Textarea
                    label={getMessage(`rewards.${info}`)}
                    placeholder={getMessage(`rewards.${info}`)}
                    type="text"
                    {..._this.generateStateMappers({
                      stateKeys: [`${info}`],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                      loseEmphasisOnFill: true,
                    })}
                  />
                )}
              </div>
            ))}
          </div>
        )
      })}
    </Fragment>
  )
}

export default WebInfo
