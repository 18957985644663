import React from 'react'
import { getMessage } from 'lib/translator'
import 'pages/marketing/Evouchers/Form/style.css'

const Toaster = (props) => {
  return (
    <div className="col-4 success" id="succcess-dialog">
      {props.message}
      <strong
        data-testid="ok-strng-btn"
        onClick={() => props.onClose()}
        className="strong-btn"
      >
        {getMessage('rewardPage.dialogs.disable.okText')}
      </strong>
    </div>
  )
}

export default Toaster
