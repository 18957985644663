import React from 'react'
import deleteIcon from 'icons/delete.svg'
import { Input, VALIDATION_TYPES } from 'components/Form'
import { getMessage } from 'lib/translator'

const Recommender = ({
  urlParams,
  addUrlParams,
  deleteUrlParams,
  generateStateMappers,
}) => {
  return (
    <div className="r4u-section">
      {Object.values(urlParams).map(({ id }, index) => (
        <div key={id} className="r4u-params-section">
          <Input
            required
            type="text"
            label={getMessage(
              'app.layout.module.datasource.config.key.input.label'
            )}
            placeholder={getMessage(
              'app.layout.module.datasource.config.key.input.placeholder'
            )}
            {...generateStateMappers({
              stateKeys: [
                'mConfig',
                'default',
                'apiConfig',
                'urlParams',
                index,
                'key',
              ],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            required
            type="text"
            label={getMessage(
              'app.layout.module.datasource.config.value.input.label'
            )}
            placeholder={getMessage(
              'app.layout.module.datasource.config.value.input.placeholder'
            )}
            {...generateStateMappers({
              stateKeys: [
                'mConfig',
                'default',
                'apiConfig',
                'urlParams',
                index,
                'val',
              ],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <div className="delete-icon">
            <img
              src={deleteIcon}
              alt={`delete icon ${index}`}
              onClick={() => deleteUrlParams(id, index)}
            />
          </div>
        </div>
      ))}
      <button onClick={addUrlParams} className="button secondary mb-1">
        {getMessage('app.layout.module.datasource.config.button.add')}
      </button>
    </div>
  )
}

export default Recommender
