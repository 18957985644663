import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListingPage  from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { getMessage } from '../../../lib/translator'
import {
  toggleDeleteDialog,
  DeleteAndErrorDialog
} from '../commonMedia'
import Loader from '../../../components/Loader'
import CampaignFilter from './Filters'
import './style.css'
import emptyIcon from './pages-empty.svg'
import CampaingUploadPopup from './CampaignUpload/CampaingUploadPopup'
import { downloadTemplateFromApi } from '../DownloadUtil'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { SplitContext } from 'containers/SplitContext'


function AddButton() {
  return (
    <Link to="/media-monetization/media-allcampaigns/media-campaign-detail" className="primary button">
      + <span className="text">{getMessage('media.add.text')}</span>
    </Link>
  )
}

function BulkAddButton(props) {
  return (
    <button
      className='button bulk-btn'
      onClick={() => {
        props.toggleUploadBox()
      }}
    >
      + {getMessage('media.bulk.add.text')}
    </button>
  )
}

function DownloadAllButton(props) {
  return (
    <button
      className='button bulk-btn'
      onClick={(e) => {
        props.downloadAll(e)
      }}
    >
      {getMessage('media.bulk.export.text')}
    </button>
  )
}

const emptyState = {
  icon: emptyIcon,
  message: getMessage('media.allcampaigns.emptyMessage'),
  submessage: getMessage('media.allcampaigns.emptySubmessage'),
  additionalViews: [AddButton, BulkAddButton, DownloadAllButton],
}

const tableProperties = (isMobileView, _this) => {
  return {
    headers: [
      getMessage('media.allcampaigns.rowheaders.id'),
      getMessage('media.allcampaigns.rowheaders.name'),
      getMessage('media.allcampaigns.rowheaders.budget'),
      getMessage('media.allcampaigns.rowheaders.time'),
      getMessage('media.allcampaigns.rowheaders.offersverified'),
      getMessage('media.allcampaigns.rowheaders.status'),
      getMessage('media.allcampaigns.rowheaders.actions'),
    ],
    row: props => (
      <Row>
        <Cell className="website-page-name">
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.allcampaigns.rowheaders.id')}
            </div>
          )}
          {props.id}
        </Cell>
        <Cell className="website-page-url">
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.allcampaigns.rowheaders.name')}
            </div>
          )}
          <Link className="page-name" to={`/media-monetization/media-allcampaigns/media-adset/${props.id}`}>
            {props.campaignName}
          </Link>
        </Cell>
        <Cell className="website-page-status">
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.allcampaigns.rowheaders.budget')}
            </div>
          )}
          $ {props.campaignBudget}
        </Cell>
        <Cell>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.allcampaigns.rowheaders.time')}
            </div>
          )}
          <div>{props.campaignStartDate} ~ {props.campaignEndDate}</div>
          <small>{props.campaignDuration} days</small>
        </Cell>
        <Cell className="website-page-status">
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.allcampaigns.rowheaders.offersverified')}
            </div>
          )}
         {props.offersVerified}
        </Cell>
        <Cell className="website-page-status">
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.allcampaigns.rowheaders.status')}
            </div>
          )}
         {props.status}
        </Cell>
        <Cell className="website-page-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem>
              <Link to={`/media-monetization/media-allcampaigns/media-adset/${props.id}`}>
                {getMessage('media.action.view')}
              </Link>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                toggleDeleteDialog(_this, 1, props.id, props.onAction)
              }}
            >
              {getMessage('media.action.delete')}
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    ),
  }
}

function getMobileView() {
  return window.screen.width <= 480
}

const BulkOpeartion = (props) => {
  const splitConfig = React.useContext(SplitContext)
  const { splits } = splitConfig
  const isShowBulk = splits?.[SPLIT_FEATURES.MEDIA_CAMPAIGN_BULKOPERATION]?.treatment === 'on'
  return (
    <React.Fragment>
      { isShowBulk && (
        <div>
          <BulkAddButton toggleUploadBox={() => props.toggleUploadBox()} />
          <DownloadAllButton downloadAll={(e) => props.downloadAll(e)} />
        </div>
      )}
    </React.Fragment>
  )
}
export default class MediaAllCampaigns extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileView: getMobileView(),
      loading: true,
      deleteDialog: {
        shown: false,
        id: null,
      },
      errorDialog: {
        shown: false,
        title: '',
        message: ''
      },
      showUploadDialog: false
    }
    this.mobileView = this.mobileView.bind(this)
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
    this.setState({
      loading: false
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.mobileView, false)
    this.api && this.api.cancel()
  }

  toggleUploadBox () {
    this.setState(prevState => ({showUploadDialog: !prevState.showUploadDialog}))
  }

  downloadAll(e){
    e.preventDefault()
    e.stopPropagation()
    downloadTemplateFromApi('/martech-ad-service/campaigns/download_all' ,'campaign-data-export.csv')
  }


  render() {
    return (
      <React.Fragment>
        {this.state.loading ? <Loader /> : (
          <ListingPage
            menu={this.props.menu}
            className="campaign-list-page website-pages"
            title={getMessage('media.allcampaigns.title')}
            api={{
              url: '/martech-ad-service/search/campaigns',
              transform: response => response.data.content,
            }}
            emptyState={emptyState}
            headerActions={() => (
              <React.Fragment>
                <AddButton />
                <BulkOpeartion
                  toggleUploadBox={() => this.toggleUploadBox()}
                  downloadAll={(e) => this.downloadAll(e)}
                ></BulkOpeartion>
              </React.Fragment>
            )}
            filters={{
              component: CampaignFilter,
              transformSubmit: formData => {
                const data = Object.assign({}, formData)
                if (data.duration) {
                  data.startDate = data.duration.startDate ? data.duration.startDate : ''
                  data.endDate = data.duration.endDate ? data.duration.endDate : ''
                  delete data.duration
                } else {
                  delete data.duration
                  delete data.startDate
                  delete data.endDate
                }
                return data
              },
            }}
            tableProperties={tableProperties(this.state.isMobileView, this)}
          />
        )}

        {this.state.showUploadDialog && (
          <CampaingUploadPopup
            isOpenUploadBox={this.state.showUploadDialog}
            toggleUploadBox={() => this.toggleUploadBox()}
          ></CampaingUploadPopup>
        )}

        <DeleteAndErrorDialog
          _this={this}
          deleteDialog={this.state.deleteDialog}
          errorDialog={this.state.errorDialog}
          deleteApi='/martech-ad-service/campaigns/'
          deleteClass='campaign-list-page website-pages'
          refreshApi='/martech-ad-service/search/campaigns'
        ></DeleteAndErrorDialog>

      </React.Fragment>
    )
  }
}
