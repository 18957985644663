import React from 'react'
import { BaseForm, Input, SingleDatePicker } from '../../../components/Form'

export default class FiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <Input
            label='Recipe ISIN'
            placeholder='enter isin'
            name="isin"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['isin'],
            })}
          />
        </div>
        <div className="form-fields">
          <SingleDatePicker
            allowAllDates
            label='from'
            placeholder='date'
            displayFormat="YYYY-MM-DD"
            {...this.generateStateMappers({
              stateKeys: ['date_from'],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            allowAllDates
            label='to'
            placeholder='date'
            displayFormat="YYYY-MM-DD"
            {...this.generateStateMappers({
              stateKeys: ['date_to'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>Submit</SubmitButton>
        <ClearButton>
          Clear
        </ClearButton>
      </Form>
    )
  }
}
