import React from 'react';
import './style.scss';

function BulkDispatchResultCard({ total, success, failure, errors, csvFileName }) {
  return (
    <div className='bulk-dispatch-result-card'>
      <h4>Bulk Dispatch Result</h4>
      <div className='field-display-line'>
        <span>File:</span>
        <span>{csvFileName}</span>
      </div>
      <div className='field-display-line'>
        <span>Result:</span>
        <span>
          {total === success
          ? `All ${total} orders dispatched successfully`
          : `${success} of ${total} orders dispatched successfully`
          }
        </span>
      </div>
      <br />
      {
        failure > 0 &&
          <mark style={{width: 'fit-content', background: '#f8d7da'}}>
            Errors encountered while dispatching {failure} orders below:
          </mark>
      }
      <table className='styled-table'>
        <thead>
          <tr>
            <th>Total</th>
            <th>Success</th>
            <th>Failure</th>
            <th>Errors</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='align-center'>{total}</td>
            <td className='align-center'>{success}</td>
            <td className='align-center'>{failure}</td>
            <td>
              {errors.map((error, index) => (
                <p key={index} style={{ color: 'red' }}>{error}</p>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default BulkDispatchResultCard;
