import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import EmptyState from '../../../../components/EmptyState'
import { Dialog } from '../../../../components/Popup'
import Loader from '../../../../components/Loader'
import {
  hideErrorDialog,
  throwError,
  toggleDeleteDialog,
} from '../../commonMedia'
import emptyIcon from '../../commonMedia/images/pages-empty.svg'
import AdvertForm from './AdvertForm'
import './style.css'
import CreativeRequirementSummary from './CreativeRequirementSummary'

const emptyState = {
  icon: emptyIcon,
  message: 'Please create your first ad!',
}

function getMobileView() {
  return window.screen.width <= 480
}

const blankAd = {
  isEditing: true,
  isLoading: false,
  ad: { name: '', seq: null, url: '' }
}

export default class MediaCreativeAdList extends Component {
  constructor(props) {
    super(props)

    this.api = null
    this.state = {
      showLoader: true,
      isMobileView: getMobileView(),
      pageAction: 'view',
      data: [
      ],
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
      deleteDialog: {
        shown: false,
        id: null,
      }
    }
    this.mobileView = this.mobileView.bind(this)
    this.getAdvertDetail = this.getAdvertDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.addBlankAd = this.addBlankAd.bind(this)
    this.toggleAdStatus = this.toggleAdStatus.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
    this.getAdvertDetail()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.mobileView, false)
  }

  addBlankAd() {
    const _blankAd = JSON.parse(JSON.stringify(blankAd))
    this.setState({
      pageAction: 'edit',
      data: [
        ...this.state.data,
        _blankAd
      ]
    })
  }

  getAdvertDetail() {
    this.api = new API({ url: '/martech-creative-service/creative-group/' + this.props.match.params.groupId })

    function getAd(groupAd) {
      return {
        ad_name: groupAd.ad.ad_name,
        seq: groupAd.seq,
        ad_url: groupAd.ad.ad_url,
        link_to_storage: groupAd.ad.link_to_storage,
        id: groupAd.ad.id,
        scope: groupAd.ad.scope,
        storeId: groupAd.ad.storeId
      };
    }
    function mapAds(creativeGroupAd) {
      const list = []
      for (const groupAd of creativeGroupAd) {
        const ad = {
          isEditing: false,
          isLoading: false,
          ...getAd(groupAd)
        }
        list.push(ad)
      }
      return list
    }

    this.api.get().then(response => {
      this.setState({
        showLoader: false,
        data: mapAds(response.data.creative_group_ad),
        creativeRequirement: response.data.creative_requirement
      })
    }).catch(error => {
      throwError(this, error)
    })
  }

  handleSubmit(formData, adIndex) {
    const creativeId = this.props.match.params.creativeId
    const groupId = this.props.match.params.groupId
    this.addOrUpdateAdvert(creativeId, groupId, formData, adIndex)
  }

  addOrUpdateAdvert(creativeId, groupId, formData, adIndex) {
    const _data = JSON.parse(JSON.stringify(this.state.data))

    const handleAdResponse = (response) => {
      _data[adIndex] = {
        isEditing: false,
        isLoading: false,
        ...response.data
      }
      this.setState({
        data: _data,
        pageAction: 'view'
      })
      this.getAdvertDetail()
    }

    const payload = {
      ad_name: formData.ad_name,
      ad_url: formData.ad_url,
      link_to_storage: formData.link_to_storage,
      scope: formData.scope,
      storeId: formData.storeId
    }
    if (!formData?.id) {
      this.api = new API({
        url: `/martech-creative-service/ad?creativeGroupId=${groupId}&seqId=${formData.seq}`
      })
      this.api.post(payload).then(response => {
        handleAdResponse(response)
      }).catch(error => {
        throwError(this, error)
      })
    } else {
      this.api = new API({
        url: `/martech-creative-service/ad/${formData.id}?creativeGroupId=${groupId}&seqId=${formData.seq}`
      })
      payload.id = formData.id
      this.api.put(payload).then(response => {
        handleAdResponse(response)
      }).catch(error => {
        throwError(this, error)
      })
    }
  }

  toggleAdStatus(status, adIndex) {
    const _data = JSON.parse(JSON.stringify(this.state.data))
    _data[adIndex].isEditing = status === 'edit'
    _data[adIndex].isLoading = false

    if (status === 'view' && !_data[adIndex].id) {
      _data.splice(adIndex, 1)
    }

    let pageAction = 'view'
    _data.forEach(ad => {
      pageAction = ad.isEditing ? 'edit' : pageAction
    })

    this.setState({
      data: _data,
      pageAction,
    })
  }

  confirmDelete() {
    const _id = this.state.deleteDialog.id
    this.setState({
      showLoader: true,
      deleteDialog: {
        shown: false,
        id: null,
      },
    })
    this.api = new API({ url: '/martech-creative-service/ad/' + _id })
    this.api.delete().then(
      () => {
        this.getAdvertDetail()
      },
      error => {
        toggleDeleteDialog(this)
        throwError(this, error)
      },
    )
  }

  toggleEitDisabledDialog(){
    this.setState({
      showEitDisabledDialog: !this.state.showEitDisabledDialog
    })
  }

  render() {
    const { menu } = this.props
    const {
      showLoader,
      isMobileView,
      pageAction,
    } = this.state

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className="media-advert-detail website-pages"
        title={getMessage('media.creative.adlist.title')}
      >
        <div className="header-container">
          <h1 className="title">{getMessage('media.creative.adlist.title')}</h1>
          <div className="header-actions-wrapper">
            <Link to={`/media-monetization/media-creativemanagement/media-creative-group-list/${this.props.match.params.creativeId}`}>
              {getMessage('media.action.goBackText')}
            </Link>
          </div>
        </div>

        {showLoader ? (
          <Loader />
        ) : (
          <React.Fragment>
            {
              <div>
                <CreativeRequirementSummary creative_requirement={this.state?.creativeRequirement}></CreativeRequirementSummary>
              </div>
            }
            {this.state.data?.length === 0 ? (
              <EmptyState
                icon={emptyState.icon}
                message={emptyState.message}
              />
            ) : (
              <div className="ads">
                {this.state.data.map((adItem, adIndex) => (
                  <React.Fragment key={adIndex}>
                    {adItem.isLoading ? (
                      <Loader />
                    ) : (
                      <AdvertForm
                        pageAction={pageAction}
                        value={adItem}
                        adIndex={adIndex}
                        isMobileView={isMobileView}
                        adListData={this.state.data}
                        creativeId={this.props.match.params.creativeId}
                        imageFormat={this.state?.creativeRequirement?.format}
                        imageSize={this.state?.creativeRequirement?.size}
                        imageWidth={this.state?.creativeRequirement?.width}
                        imageHeight={this.state?.creativeRequirement?.height}
                        toggleAdStatus={this.toggleAdStatus}
                        isNotEditable={this.isNotEditable() }
                        toggleEitDisabledDialog={()=>this.toggleEitDisabledDialog()}
                        toggleDeleteDialog={(id) => {
                          toggleDeleteDialog(this, 1, id)
                        }}
                        onSubmit={(data) => {
                          this.handleSubmit(data, adIndex)
                        }}
                        onCancel={() => {
                          this.toggleAdStatus('view', adIndex)
                          this.getAdvertDetail()
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
            {
              <div className="btns-wrap">
                <div className="add-btn" onClick={() => {
                  this.isNotEditable() ? this.toggleEitDisabledDialog() :
                    this.addBlankAd('add')
                }}>
                  {getMessage('media.creative.adlist.add')}
                </div>
              </div>
            }
          </React.Fragment>
        )}

        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={this.state.errorDialog.message}
            close={() => (hideErrorDialog(this))}
            closeText={getMessage('dialog.okText')}
          />
        )}

        {this.state.deleteDialog.shown && (
          <Dialog
            show={this.state.deleteDialog.shown}
            title={getMessage('deleteDialog.title')}
            information={getMessage('deleteDialog.information')}
            onOk={this.confirmDelete}
            close={() => toggleDeleteDialog(this)}
            closeText={getMessage('dialog.cancelText')}
            okText={getMessage('dialog.okText')}
          />
        )}
        {this.state.showEitDisabledDialog && (
          <Dialog
            show={this.state.showEitDisabledDialog}
            title={getMessage('media.creativeManagement.editDisabledPopup.title')}
            information={getMessage('media.creativeManagement.editDisabledPopup.message')}
            close={() => (this.toggleEitDisabledDialog())}
            closeText={getMessage('dialog.okText')}
          />
        )}

      </AuthenticatedPage>
    )
  }

  isNotEditable () {
    return this.props.match.params?.editable === 'disabled'
  }
}
