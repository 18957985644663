import React, { Fragment, useState } from 'react'

import { Checkbox } from '../../Form'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import './style.css'

const DrawWinnersPopUpDetails = ({
  drawsList,
  selectedValue,
  hidePopup,
  previewEmail = '',
}) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isError, setIsError] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const drawDetails = drawsList.find(
    (item) => item.value === Number(selectedValue)
  )
  const handleDrawWinnersSubmit = async () => {
    if (isChecked) {
      setSubmitting(true)
      try {
        let url = `/duck/draws/${drawDetails.value}/winners`
        let payload = ''
        if (previewEmail) {
          url = `/duck/draws/${drawDetails.value}/preview`
          payload = { email: previewEmail }
        }
        const api = new API({ url })
        await api.post(payload)
        setTimeout(function () {
          window.location.reload()
        }, 5000)
      } catch (e) {
        setIsError(e.message || getMessage('luckydraw.server.error'))
      }
    } else {
      setIsError(getMessage('luckydraw.requiredField.error'))
    }
  }
  return (
    <div className="row flex-direction">
      <h4 style={{ marginTop: '0' }}>
        {previewEmail
          ? getMessage('luckydraw.previewDraw')
          : getMessage('luckydraw.actualDraw')}
      </h4>
      <div className="col-12 styledBox">
        <p>{getMessage('luckydraw.drawWinnersPopUp.MainText')}</p>
        <ul>
          <li>
            {drawDetails.winnersCount}
            {getMessage('luckydraw.drawWinnersPopUp.winners')}
          </li>
          <li>
            {drawDetails.reservedWinnersCount}
            {getMessage('luckydraw.drawWinnersPopUp.reserveWinners')}
          </li>
        </ul>
        <p>{getMessage('luckydraw.drawWinnersPopUp.SubText')}</p>
        <ul>
          <li>{drawDetails.cutOffAt}</li>
        </ul>
        {previewEmail && (
          <Fragment>
            <p>{getMessage('luckydraw.drawWinnersPopUp.drawResults.email')}</p>
            <ul>
              <li>{previewEmail}</li>
            </ul>
          </Fragment>
        )}
      </div>
      <div className="col-12">
        <Checkbox
          label=""
          testid="confirm-id"
          name="confirm"
          inlineLabel={
            previewEmail
              ? getMessage('luckydraw.drawWinnersPopUp.preview.checkbox')
              : getMessage('luckydraw.drawWinnersPopUp.checkbox')
          }
          onChange={(e) => {
            setIsChecked(e)
            setIsError('')
          }}
        />
        {isError && <div className="error">{isError}</div>}
        {isSubmitting && (
          <div className="loading-msg">
            {getMessage('luckydraw.drawWinnersPopUp.LoadingMsg')}
          </div>
        )}
      </div>
      <div className="col-12">
        <div className="form-actions">
          <button
            className="primary"
            onClick={handleDrawWinnersSubmit}
            disabled={isSubmitting}
            data-testid="drawWinners-submit"
          >
            {getMessage('luckydraw.submit')}
          </button>
          <button
            className="button"
            data-testid="cancel-btn"
            onClick={hidePopup}
          >
            {getMessage('luckydraw.cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}
export default DrawWinnersPopUpDetails
