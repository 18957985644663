const PROPERTIES = ['permissions', 'formattedPermissions', 'user', 'organization', 'tierSetups', 'stores']

class InMemoryStore {
  constructor() {
    this.store = {}
    this.toBeRemoved = []
  }

  isFeatureEnabled(key) {
    // force to use local storage to fetch only feature flags
    // only when key is provided with a string value, will then check on PROPERTIES FLAGS
    // Passing the ENVIRONMENT to bypass this checks when running test
    if (key && typeof key === 'string') {
      return PROPERTIES.includes(key)
    }
    return true
  }

  getItem(key, bypassSplitFlag = false) {
    if (bypassSplitFlag || this.isFeatureEnabled(key)) {
      return this.store[key] || window.localStorage.getItem(key) || null
    }
    return window.localStorage.getItem(key)
    // return get(key)
  }

  setItem(key, value, bypassSplitFlag = false) {
    // always write to in-memory store
    this.store[key] = value
    if (!bypassSplitFlag && this.isFeatureEnabled(key)) {
      return
    }

    // remember to remove the key from local storage
    // when flag was turned on
    this.toBeRemoved.push(key)
    window.localStorage.setItem(key, value)
    // set(key, value)
  }

  clearLocalStorage() {
    this.toBeRemoved.forEach((item) => {
      if (this.isFeatureEnabled(item)) {
        window.localStorage.removeItem(item)
      }
    })
  }

  removeItem(key) {
    if (this.isFeatureEnabled(key)) {
      delete this.store[key]
      return
    }
    window.localStorage.removeItem(key)
  }
}

export const inMemoryStore = new InMemoryStore()
