import React from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage/index'

import OrchidRecommender from './recommender'
import './style.css'

const Recommender = props => {
  return (
    <AuthenticatedPage
      menu={props.menu}
      showLanguageDropDown
      className="recommender-dashboard"
      title="Recommender"
    >
      <OrchidRecommender />
    </AuthenticatedPage>
  )
}

export default Recommender
