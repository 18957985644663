import React from 'react';
import crossIcon from 'icons/cross-icon.svg';
import { slotRuleTypes } from 'pages/operations/TimeslotBlocking/constants';

function InfoBox({ closeInfoBox, infoBoxData }) {
  const {
    name,
    postalCodes,
    zoneName,
    blockedSlots,
    slotRuleType
  } = infoBoxData;
  return (
    <div className='info-box' key={name}>
      <img src={crossIcon} onClick={closeInfoBox} alt="cross icon" data-testid='close-info-box' />
      <div className='title'>Rule Name</div>
      <div className='content'>{name}</div>
      {
        slotRuleType === slotRuleTypes.BY_POSTAL_CODE ?
        <>
          <div className='title'>Postal Code(s)</div>
          <div className='content'>{postalCodes.join(', ')}</div>
        </> : slotRuleType === slotRuleTypes.IS_CLUSTER ?
        <>
          <div className='title'>Zone Name</div>
          <div className='content'>{zoneName}</div>
        </> : <></>
      }

      <div className='title'>Blocked Timeslots</div>
      <div className='content'>
        {
          blockedSlots.length > 0 &&
          blockedSlots.map(slot => (
            <React.Fragment key={slot.day}>
              <span><b>{slot.day}</b> {slot.slots}</span>
              <br />
            </React.Fragment>
          ))
        }
      </div>
    </div>
  )
}

export default InfoBox;
