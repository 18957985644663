import { useFormik } from 'formik'
import { useEffect } from 'react'
import { createStoreFormSchema } from './form-validator'
import _get from 'lodash/get'

export const DEFAULT_EMPTY_HOURS = {
  Monday: { o: '', c: '' },
  Tuesday: { o: '', c: '' },
  Wednesday: { o: '', c: '' },
  Thursday: { o: '', c: '' },
  Friday: { o: '', c: '' },
  Saturday: { o: '', c: '' },
  Sunday: { o: '', c: '' },
}
export const HOURS_STRING_PATTERN = '^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$'
export const HOURS_PLACEHOLDER = 'HH:MM:SS'

export const metadataKeys = {
  IS_SNG_METADATA_KEY: 'Is SnG Store',
  SNG_OPS_HOUR_METADATA_KEY: 'SnG Operational Hours',
  SNG_CHECKIN_RADIUS_METADATA_KEY: 'SnG Geo Checkin Radius',
  SNG_CHECKIN_BLOCKED_METADATA_KEY: 'SnG Check-in Blocked',
  SEARCH_BROWSE_METADATA_KEY: 'Search And Browse Enabled',
}

export const DYNAMIC_FIELD = ['pickingStoreId', 'meta_sngOperationalHours']

export const STORE_TYPES = [
  {
    text: 'Standard',
    value: 'STANDARD',
  },
  {
    text: 'Scan & Go',
    value: 'SNG',
  },
  {
    text: 'FFS',
    value: 'FFS',
  },
  {
    text: 'Search and Browse',
    value: 'SEARCH_AND_BROWS',
  },
  {
    text: 'PFC',
    value: 'PFC',
  },
]

export const parseEmptyValue = (str) => {
  return str === undefined || str === null || str === ''
    ? 'empty'
    : str.toString()
}

export const flatDiffObject = (obj, str, prevResult) => {
  const result = prevResult ? prevResult : {}
  Object.keys(obj).forEach((key) => {
    const queryKey = str ? `${str}.${key}` : key
    if (Object.keys(obj[key]).includes('__old')) {
      result[queryKey] = _get(obj, key)
    } else if (key.includes('__added')) {
      const newQueryKey = queryKey.replace('__added', '')
      result[newQueryKey] = { __new: _get(obj, key), __old: '' }
    } else {
      flatDiffObject(obj[key], queryKey, result)
    }
  })
  return result
}

/**
 * This is to count total number of input that have errors
 * in store edit page durig submitting based on the count of 
 * key in formik.errors, except that for businessHours and meta_sngOperationalHours should
 * be counted based on the "o" key and "c" instead of the "day", as 
 * formik.errors register those error as hours object like below sample:
 * 
 * Monday: { o: '', c: '' },
  Tuesday: { o: '', c: '' },
  Wednesday: { o: '', c: '' },
  Thursday: { o: '', c: '' },
  Friday: { o: '', c: '' },
  Saturday: { o: '', c: '' },
  Sunday: { o: '', c: '' },
 * 
 * @param {*} obj 
 * @returns 
 */
export const countErrors = (obj) => {
  let count = 0
  function countKey(errors) {
    for (const key in errors) {
      if (key === 'businessHours' || key === 'meta_sngOperationalHours') {
        const objHours = errors[key]
        for (const day in objHours) {
          countKey(objHours[day])
        }
      } else {
        count++
        if (typeof errors[key] === 'object') {
          countKey(errors[key])
        }
      }
    }
  }
  countKey(obj)
  return count
}

export const getSngOperationalHours = (metaData) => {
  let hoursObject

  try {
    hoursObject = JSON.parse(metaData[metadataKeys.SNG_OPS_HOUR_METADATA_KEY])

    /** Validate if the shape of the object passed when checking with DEFAULT_EMPTY_HOURS */
    let errorFound = 0

    Object.keys(DEFAULT_EMPTY_HOURS).forEach((day) => {
      if (!hoursObject[day]) {
        errorFound++
      } else {
        if (!hoursObject[day].o || !hoursObject[day].c) {
          errorFound++
        }
      }
    })
    if (errorFound > 0) {
      /**
       * At this point, object validation failed, due to incorrect JSON string values
       */
      hoursObject = { ...DEFAULT_EMPTY_HOURS }
    }
  } catch (err) {
    // if failed to parse the hours string to the defined object shape,
    // we considered it has no value set.
    // fallback to default hours value
    // console.error(err)
    hoursObject = { ...DEFAULT_EMPTY_HOURS }
  }
  return hoursObject
}

export const useAutoScrollToTop = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [])
}

/**
 * auto scroll to the bottom of the form when form is submitting, to make the form errror visible
 * after validation failed
 */
export const useScrollToFormError = (isSubmitting) => {
  useEffect(() => {
    if (isSubmitting) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [isSubmitting])
}

/**
 * Hooks to help setup the form default values and object shape
 * to avoid from unexpected undefined value during rendering fields
 * @param {*} store
 * @param {*} formik
 */
export const useSetupForm = (store, formik) => {
  useEffect(() => {
    if (store !== null) {
      /**
       * Logic for editing existing store configuration
       */
      const metaData = store.metaData
      delete store.metaData

      const formValues = Object.assign(
        store,
        {},
        {
          hasPicking: store.hasPicking ?? false,
          businessHours: store.businessHours ?? { ...DEFAULT_EMPTY_HOURS },
          meta_sngOperationalHours: getSngOperationalHours(metaData),
          meta_isSNG: metaData && metaData[metadataKeys.IS_SNG_METADATA_KEY],
          meta_sngCheckinRadius:
            metaData && metaData[metadataKeys.SNG_CHECKIN_RADIUS_METADATA_KEY],
          meta_sngCheckinBlocked:
            metaData && metaData[metadataKeys.SNG_CHECKIN_BLOCKED_METADATA_KEY],
          meta_searchNBrowseEnabled:
            metaData && metaData[metadataKeys.SEARCH_BROWSE_METADATA_KEY],
          ...metaData,
        }
      )

      formik.setValues({
        ...formValues,
        currentStoreData: { ...formValues },
        attemptSubmit: false,
      })
      return
    }

    /**
     * Logic for creating new store configuration
     * prepare and populate default values as needed
     */
    formik.setValues({
      meta_sngOperationalHours: { ...DEFAULT_EMPTY_HOURS },
      meta_isSNG: false,
      /** Set default values for new store (only) */
      businessHours: { ...DEFAULT_EMPTY_HOURS },
      hasPicking: false,
      attemptSubmit: false,
    })
  }, [store])
}

/**
 * Hook wrapper for formik creation with dynamic validation schema setup
 * @param {*} store
 */
export const useCreateForm = (action, setAttemptSubmit) => {
  return useFormik({
    initialValues: {},
    validationSchema: createStoreFormSchema(action),
    validateOnChange: true,
    onSubmit: () => {
      setAttemptSubmit(true)
    },
  })
}

export const transformSubmit = (formValues, metadataWithType, action) => {
  /** metaFormValues only hold the metadata that do have values in the
   * form, so its not the completed object of store metadata */
  const metaFormValues = {}

  /**
   * all metadata properties will be remove from the form object,
   * as we going to handle the metadata separatedly at a later stage
   *
   */
  const form = { ...formValues }
  Object.keys(metadataWithType).forEach((key) => {
    if (form[key]) {
      metaFormValues[key] = form[key]
    }
    delete form[key]
  })

  setMetadataWithDefaultValues(metadataWithType, metaFormValues)
  transferFormMetaValues(form, metaFormValues)
  injectAutomationFlag(form, action)

  return form
}

const DEFAULT_DATA = {
  number: 0,
  string: '',
  boolean: false,
  enum: '',
  'multiValued Enum': '',
}

export const setMetadataWithDefaultValues = (
  metadataMapping,
  existingValues
) => {
  Object.keys(metadataMapping || {}).forEach((key) => {
    if (existingValues && !existingValues[key]) {
      const defaultValue = DEFAULT_DATA[metadataMapping[key]['type']]
      existingValues[key] = defaultValue ?? ''
    }
  })
}

export const transferFormMetaValues = (form, metadata) => {
  /**
   * meta_isSNG,
   * meta_searchNBrowseEnabled,
   * meta_sngCheckinBlocked,
   * meta_sngCheckinRadius
   * meta_sngOperationalHours
   */
  metadata[metadataKeys.SEARCH_BROWSE_METADATA_KEY] =
    form.meta_searchNBrowseEnabled ?? false
  metadata[metadataKeys.IS_SNG_METADATA_KEY] = form.meta_isSNG ?? false
  metadata[metadataKeys.SNG_CHECKIN_BLOCKED_METADATA_KEY] =
    form.meta_sngCheckinBlocked ?? false

  metadata[metadataKeys.SNG_CHECKIN_RADIUS_METADATA_KEY] =
    form.meta_sngCheckinRadius ?? ''

  metadata[metadataKeys.SNG_OPS_HOUR_METADATA_KEY] =
    form.meta_sngOperationalHours
      ? JSON.stringify(form.meta_sngOperationalHours)
      : ''

  form.metadata = metadata

  /**
   * Clean up Form Values after transferring
   */
  delete form.meta_isSNG
  delete form.meta_searchNBrowseEnabled
  delete form.meta_sngCheckinBlocked
  delete form.meta_sngCheckinRadius
  delete form.meta_sngOperationalHours
  /**
   * currentStoreData is the properties that hold initial value
   * of store temporarily before any input changes, need to be remove
   * so it wont be sent to backend
   */
  delete form.currentStoreData
}

/**
 * @NAPI need to think of a way to turn off automation for UAT
 * @param {*} form
 * @param {*} action
 */
export const injectAutomationFlag = (form, action) => {
  if (action === 'new') {
    form['isAutomation'] = true
  } else {
    delete form['isAutomation']
  }
}
