import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import NotificationsForm from './form'
import { getMessage } from '../../../lib/translator'

import './style.css'

export default class Notifications extends Component {
  render() {
    return (
      <AuthenticatedPage
        className="notifications"
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
        title={getMessage('notifications.heading')}
      >
        <h1 className="title">{getMessage('notifications.heading')}</h1>
        <NotificationsForm />
      </AuthenticatedPage>
    )
  }
}
