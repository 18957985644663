import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PublicPage from '../../../containers/PublicPage/index'
import LoginForm from './form'
import { Consumer } from '../../../containers/Context'
import { ENVIRONMENT } from '../../../config/app'
import { getMessage } from '../../../lib/translator'

import './style.css'

export default class LoginPage extends React.Component {
  render() {
    const url =
      ENVIRONMENT === 'UAT'
        ? 'https://admin-uat.fairprice.com.sg'
        : 'https://admin.fairprice.com.sg'
    const endDate = ENVIRONMENT === 'UAT' ? '23rd Dec' : '8th Feb'
    return (
      <PublicPage className="login">
        <Consumer>
          {isEnterprise => (
            <Fragment>
              <div className="notify">
                <p className="migration">
                  This legacy login will be decomissioned on {ENVIRONMENT} by{' '}
                  {endDate} (EOD). Use this <a href={url}>link</a> to login via
                  OKTA. If you have trouble logging in, please email us at
                  dbp-backoffice@fairprice.com.sg
                </p>
              </div>
              <header className="header">
                <h1 className="heading">{getMessage('login.heading')}</h1>
                <h2 className="subheading">{getMessage('login.subheading')}</h2>
              </header>
              <div className="box">
                <LoginForm enterprise={isEnterprise} />
                {!isEnterprise && (
                  <p className="alt-action">
                    {getMessage('login.newUser.message')}{' '}
                    <Link to="/signUp">
                      {getMessage('login.newUser.signupText')}
                    </Link>
                  </p>
                )}
              </div>
            </Fragment>
          )}
        </Consumer>
      </PublicPage>
    )
  }
}
