import React from 'react'
import { getMessage } from 'lib/translator'
import { TimePicker } from 'components/Form'
import Table, { Cell, Header, Row } from 'components/Table'
import { DAYS } from './utils'

const DailySchedule = ({ schedule, generateStateMappers }) => {
  return (
    <div className="daily-schedule two-col">
      <h3>{getMessage('app.popup.dailySchedule')}</h3>
      <Table>
        <Header>
          <Cell>{getMessage('apphome.day')}</Cell>
          <Cell>{getMessage('apphome.startTime')}</Cell>
          <Cell>{getMessage('apphome.endTime')}</Cell>
        </Header>
        {DAYS.map((day) => (
          <Row key={day}>
            <Cell>{getMessage(`apphome.day.${day}`)}</Cell>
            <Cell>
              <TimePicker
                required={schedule[day]?.endTime}
                name="select-start-time"
                placeholder={getMessage('apphome.time.placeholder')}
                {...generateStateMappers({
                  stateKeys: ['displaySchedule', day, 'startTime'],
                  loseEmphasisOnFill: true,
                })}
              />
            </Cell>
            <Cell>
              <TimePicker
                required={schedule[day]?.startTime}
                name="select-end-time"
                placeholder={getMessage('apphome.time.placeholder')}
                {...generateStateMappers({
                  stateKeys: ['displaySchedule', day, 'endTime'],
                  loseEmphasisOnFill: true,
                })}
              />
            </Cell>
          </Row>
        ))}
      </Table>
    </div>
  )
}

export default DailySchedule
