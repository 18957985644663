import React, { Component } from 'react'
import { Input, Phone } from '../../../../components/Form'
import { Actions } from '../form'
import { getMessage } from '../../../../lib/translator'
import AlfaIcon from './alfa.png'

class Alfa extends Component {
  render() {
    const { parent, type, provider, allowKey } = this.props
    const _this = parent
    const readOnly = _this.getState([allowKey]) === false
    return (
      <React.Fragment>
        <Actions
          parent={_this}
          icon={AlfaIcon}
          allowKey={allowKey}
          className={'alfa'}
        />
        <div className="form-fields">
          <Input
            label={getMessage('communication.alfa.applicationType')}
            placeholder={getMessage(
              'communication.alfa.applicationType.placeholder'
            )}
            name="application-type"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'applicationType'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Phone
            label={getMessage('communication.alfa.smsNumber')}
            placeholder={getMessage('communication.alfa.smsNumber.placeholder')}
            name="sms-number"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'mobile'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            label={getMessage('communication.alfa.password')}
            type="password"
            placeholder={getMessage('communication.alfa.password.placeholder')}
            name="password"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'password'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            label={getMessage('communication.alfa.sender')}
            type="text"
            placeholder={getMessage('communication.alfa.sender.placeholder')}
            name="sender"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'sender'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default Alfa
