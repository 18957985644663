import React from 'react'
import Table, { Header, Cell, Row } from '../../../../components/Table'
import { Popup } from '../../../../components/Popup'
import { getMessage } from '../../../../lib/translator'
import './style.css'

export default function CalendarPopup(props) {
  const { toggleCalendarPopup, calendarPopupDialog } = props

  return(
    <Popup
        show={calendarPopupDialog.isShow}
        className="calendar-box"
        heading={getMessage('media.adset.table.h3')}
        close={() => { toggleCalendarPopup(2) }}
      >
        <Table>
          <Header>
            <Cell>{getMessage('media.adset.rowheaders.id')}</Cell>
            <Cell>{getMessage('media.adsetdetail.info.name.placeholder')}</Cell>
          </Header>
          {calendarPopupDialog.lineItemInfo?.map((item, index) => (
            <Row className='headerColumn' key={index}>
              <Cell>
                <div>{item.lineItemId}</div>
              </Cell>
              <Cell>
                <div data-testid={`lineitem_${index}`} className='clickable' onClick={()=> { props.toggleLineitemPopup(1, item.lineItemId) }}>{item.lineItemName}</div>
              </Cell>
            </Row>
          ))}
        </Table>
      </Popup>
  )
}
