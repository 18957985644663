import React, { Component } from 'react'
import { Input, Select, ProductSearch } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import { handleFocus } from './FormTabs/utils'

class PWP extends Component {
  constructor(props) {
    super(props)
    this.validationHandler = this.validationHandler.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
  }
  validationHandler(parent) {
    const validations = JSON.parse(JSON.stringify(parent.state.validations))
    parent.setState({
      validations,
    })
  }

  submitHandler(formData) {
    const data = Object.assign({}, formData)
    const total = data.rule.total
    total.variantId = total.variantId && total.variantId.id
    const newRule = {
      PWPTagId:
        formData.pwpTagId ||
        (formData.rule && (formData.rule.PWPTagId || formData.rule.PWPTagID)),
      cartPrice: data.rule.cartPrice,
      total: total,
      limit: Number(data.rule.limit),
    }
    data.rule = newRule
    delete data.pwpTagId
    delete data.ruleDetail
    delete data.product
    delete data.category
    delete data.brand
    delete data.total
    delete data.entity
    return data
  }

  render() {
    const { discountTypes, _this, hasOfferStarted } = this.props
    const { values } = _this.state
    const maxDiscount =
      _this.getState(['rule', 'total', 't']) === `PERCENT_OFF`
        ? 100
        : _this.getState(['rule', 'cartPrice'])
    return (
      <div className="offers-form">
        <div className="offer-fields">
          <ProductSearch
            name="pwp-pdt"
            required
            label={getMessage('Product')}
            placeholder={getMessage('Enter product')}
            className="product-searchable-max"
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'total', 'variantId'],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
          <Input
            name="cart-price"
            className="shipping-cart"
            type="number"
            onFocus={handleFocus}
            step="0.01"
            required
            readOnly={hasOfferStarted}
            label={getMessage('offer.cartprice')}
            placeholder={getMessage('offer.cartprice.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'cartPrice'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            name="discount-type"
            required
            label={getMessage('offer.discounttype')}
            placeholder={getMessage('offer.discounttype.placeholder')}
            options={discountTypes}
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'total', 't'],
              loseEmphasisOnFill: true,
            })}
            onChange={_this.handleDiscountChange}
            readOnly={hasOfferStarted}
          />
          <Input
            name="discount-value"
            type="number"
            onFocus={handleFocus}
            step={0.01}
            min={0}
            max={maxDiscount}
            required
            label={getMessage('offer.discountvalue')}
            placeholder={getMessage('offer.discountvalue.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'total', 'v'],
              loseEmphasisOnFill: true,
            })}
            readOnly={
              hasOfferStarted ||
              (values &&
                values.rule &&
                values.rule.total &&
                values.rule.total.t === `FREE`)
            }
          />
        </div>
      </div>
    )
  }
}

export default PWP
