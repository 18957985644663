import React, { Fragment } from 'react'
import { VALIDATION_TYPES, Input, Select, Status } from '../utils'
import { getMessage } from 'lib/translator'

const Details = ({ merchantList, _this: form }) => {
  const DEVICE_DETAIL_GROUPS = [
    ['clsMerchantId'],
    ['clsBranchNo', 'clsTerminalId', 'clsOperatorId'],
    ['storeName', 'storeAddress'],
  ]

  const generateInputField = (type, key, label, placeholder) => {
    return type === 'select' ? (
      <Select
        label={label}
        placeholder={placeholder}
        testid={key}
        options={merchantList}
        {...form.generateStateMappers({
          stateKeys: [key],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
        required
      />
    ) : (
      <Input
        label={label}
        placeholder={placeholder}
        type="text"
        {...form.generateStateMappers({
          stateKeys: [key],
        })}
      />
    )
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <Input
            label={getMessage('rewards.device.serialNumber')}
            placeholder={getMessage('rewards.device.serialNumber')}
            type="text"
            {...form.generateStateMappers({
              stateKeys: ['serialNumber'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            required
          />
        </div>
        <Status _this={form} />
      </div>
      {DEVICE_DETAIL_GROUPS.map((detailGroup, groupIndex) => (
        <div className="row" key={`group-${groupIndex}`}>
          {detailGroup.map((key, detailIndex) => (
            <div className="col" key={`detail-${groupIndex}-${detailIndex}`}>
              {key === 'clsMerchantId'
                ? generateInputField(
                    'select',
                    key,
                    getMessage(`rewards.${key}`),
                    getMessage(`rewards.${key}`)
                  )
                : generateInputField(
                    'input',
                    key,
                    getMessage(`rewards.device.${key}`),
                    getMessage(`rewards.device.${key}`)
                  )}
            </div>
          ))}
        </div>
      ))}
    </Fragment>
  )
}

export default Details
