import React, { useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import './style.css'

const TextEditor = (props) => {
  const { label, html = '' } = props
  const [value, setValue] = useState(html)
  useEffect(() => setValue(html), [html])

  return (
    <div>
      <label>{label}</label>
      <Editor
        initialValue={html}
        value={value}
        onEditorChange={(newValue) => {
          setValue(newValue)
          props.onChange(newValue)
        }}
        init={{
          base_url: '/tinymce', // Root for TinyMCE assets
          suffix: '.min', // Suffix to use when loading assets,
          menubar: true,
          plugins:
            'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
          toolbar:
            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        }}
      />
    </div>
  )
}

export default TextEditor
