import React from 'react'
import { BaseForm, Searchable } from 'components/Form'
import { getMessage } from 'lib/translator'

export default class FilterForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      moduleOptions: [],
    }
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields date-fields">
          <Searchable
            label={getMessage('designations.form.name')}
            placeholder={getMessage('designations.form.name.placeholder')}
            name="name"
            type="text"
            searchUrl="/account-service/designation"
            valueKey="id"
            searchKey="name"
            transformResponse={(response) => response.data.designation}
            {...this.generateStateMappers({
              stateKeys: ['name'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>
          {getMessage('designation-roles.filter.submit.buttontext')}
        </SubmitButton>
        <ClearButton>
          {getMessage('designation-roles.filter.clear.buttontext')}
        </ClearButton>
      </Form>
    )
  }
}
