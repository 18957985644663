import React from 'react'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import Upload from 'components/Form/Inputs/Upload'
import UploadLogo from './UploadLogo'
import './style.css'

const UploadPopup = ({
  uploadType,
  selectedLogo,
  showUploadPopup,
  uploadPopupHandler,
  checkboxChangeHandler,
  generateStateMappers,
  verifyDimension,
}) => {
  return (
    <Popup
      show={showUploadPopup}
      heading={getMessage(`app.splash.upload.${uploadType}`)}
      close={() => uploadPopupHandler(false)}
      className="uploadPopup"
    >
      <>
        {uploadType !== 'logo' && (
          <Upload
            name="uploadImage"
            accept={'image/jpeg,image/jpg,image/png,application/json'}
            placeholder={getMessage(
              `app.splash.upload.${uploadType}.placeholder`
            )}
            verifyDimension={() => verifyDimension(uploadType)}
            {...generateStateMappers({
              stateKeys: ['splash', uploadType],
              loseEmphasisOnFill: true,
            })}
          />
        )}
        {uploadType === 'logo' && (
          <UploadLogo
            uploadType={uploadType}
            selectedLogo={selectedLogo}
            checkboxChangeHandler={checkboxChangeHandler}
          />
        )}
      </>
      <button
        className="button"
        type="button"
        onClick={() => uploadPopupHandler(false)}
      >
        {getMessage('apphome.done')}
      </button>
    </Popup>
  )
}

export default UploadPopup
