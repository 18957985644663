import React from 'react'
import {
  BaseForm,
  Input,
  Select,
  SingleDatePicker,
  Checkbox,
} from '../../../../../components/Form'
import { isExtensionEnabled } from '../../../../../lib/auth'
import { getMessage } from '../../../../../lib/translator'
import API from '../../../../../lib/api'
import { formatTime, getMinutes } from '../../../../../lib/datetime'
import {
  sortSlots,
  getSelectOrderStatus,
} from '../../../../../lib/commonlyused'

const paymentModes = [
  {
    text: getMessage('order.payment.cod'),
    value: 'COD',
  },
  {
    text: getMessage('order.payment.online'),
    value: 'ONLINE',
  },
]

const paymentStatuses = [
  {
    text: getMessage('order.payment.pending'),
    value: 'PENDING',
  },
  {
    text: getMessage('order.payment.paid'),
    value: 'PAID',
  },
  {
    text: getMessage('order.payment.refund'),
    value: 'REFUND',
  },
]

const productTypes = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'MarketPlace',
    value: 'marketplace',
  },
]

export default class OrderFiltersForm extends BaseForm {
  componentDidMount() {
    this.configApi = new API({ url: '/account-service/config/order' })
    this.configApi.get().then(response => {
      let orderTypes = response.data.config.order.orderTypes
      orderTypes = (orderTypes || []).map(orderType => {
        return {
          text:
            orderType.split('')[0] +
            orderType
              .toLowerCase()
              .split('')
              .splice(1, orderType.length)
              .join(''),
          value: orderType,
        }
      })
      this.setState({ orderTypes })
    })
    if (isExtensionEnabled('DeliverySlots')) {
      this.slotApi = new API({ url: `/order-service/slot` })
      this.slotApi.get({ paginated: 'false' }).then(response => {
        let slots = response.data.slot
        slots = sortSlots(slots)
        const slotOptions = []
        slots.forEach(slot => {
          if (slot.type === 'STANDARD') {
            slotOptions.push({
              text: `${formatTime(slot.startTime)} - ${formatTime(
                slot.endTime
              )}`,
              value: JSON.stringify({
                startTime: slot.startTime,
                endTime: slot.endTime,
                type: slot.type,
              }),
            })
          } else if (slot.type === 'ASAP') {
            slotOptions.push({
              text:
                getMessage('slots.asap') +
                ' ' +
                getMinutes(slot.endTime) +
                ' ' +
                getMessage('slots.asap.minute'),
              value: JSON.stringify({ endTime: slot.endTime, type: slot.type }),
            })
          }
        })
        this.setState({
          slotOptions,
        })
      })
    }
  }
  componentWillUnmount() {
    this.slotApi && this.slotApi.cancel()
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    const { slotOptions, orderTypes } = this.state
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('order.filters.referenceNumber.heading')}
            placeholder={getMessage(
              'order.filters.referenceNumber.placeholder'
            )}
            name="referenceNumber"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['referenceNumber'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage('order.filters.clientId.heading')}
            placeholder={getMessage('order.filters.clientId.placeholder')}
            name="clientId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['clientId'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.status.heading')}
            placeholder={getMessage('order.filters.status.placeholder')}
            name="status"
            options={getSelectOrderStatus()}
            {...this.generateStateMappers({
              stateKeys: ['status'],
              loseEmphasisOnFill: true,
            })}
          />
          {isExtensionEnabled('OnlinePaymentSupport') && (
            <Select
              label={getMessage('order.filters.payment.heading')}
              placeholder={getMessage('order.filters.payment.placeholder')}
              name="paymentMode"
              options={paymentModes}
              {...this.generateStateMappers({
                stateKeys: ['paymentMode'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Select
            label={getMessage('order.filters.paymentStatus')}
            placeholder={getMessage('order.filters.paymentStatus.placeholder')}
            name="paymentStatus"
            options={paymentStatuses}
            {...this.generateStateMappers({
              stateKeys: ['paymentStatus'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.orderType')}
            placeholder={getMessage('order.filters.orderType.placeholder')}
            name="orderType"
            options={orderTypes}
            {...this.generateStateMappers({
              stateKeys: ['orderType'],
              loseEmphasisOnFill: true,
            })}
          />
          {slotOptions && (
            <Select
              label={getMessage('order.filters.slot')}
              placeholder={getMessage('order.filters.slot.placeholder')}
              name="orderSlot"
              options={slotOptions}
              {...this.generateStateMappers({
                stateKeys: ['slot'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <SingleDatePicker
            label={getMessage('order.filters.placedOn')}
            placeholder={getMessage('order.filter.placedOn.placeholder')}
            name="placed-on"
            isOutsideRange
            {...this.generateStateMappers({
              stateKeys: ['placedOn'],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage('order.filters.preferredDate')}
            placeholder={getMessage('order.filter.placedOn.placeholder')}
            name="preferred-date"
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ['preferredDate'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.productType')}
            placeholder={getMessage('order.filters.productType.placeholder')}
            name="productType"
            options={productTypes}
            {...this.generateStateMappers({
              stateKeys: ['productType'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage('order.filters.batch.heading')}
            placeholder={getMessage('order.filters.batch.placeholder')}
            name="batchId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['batchId'],
              loseEmphasisOnFill: true,
            })}
          />
          {isExtensionEnabled('PreOrderSupport') && (
            <Checkbox
              label={getMessage('order.filters.preorder')}
              name={`preorder`}
              className="pre-order"
              {...this.generateStateMappers({
                stateKeys: ['preorder'],
                loseEmphasisOnFill: true,
              })}
              controlled
            />
          )}
        </div>
        <SubmitButton>{getMessage('order.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('order.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
