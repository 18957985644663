import React from 'react'
import { TimePickerField, DatePickerField } from './utils'

const Validity = ({ type, readOnlyField, generateStateMappers }) => {
  return (
    <>
      <div className="validFrom">
        <DatePickerField
          placeholder="startDate"
          label="validFrom"
          readOnlyField={readOnlyField}
          generateStateMappers={generateStateMappers}
          type={type}
        />
        <TimePickerField
          label="startTime"
          readOnlyField={readOnlyField}
          generateStateMappers={generateStateMappers}
        />
      </div>
      <div className="validTo">
        <DatePickerField
          placeholder="endDate"
          label="validTo"
          readOnlyField={readOnlyField}
          generateStateMappers={generateStateMappers}
          type={type}
        />
        <TimePickerField
          label="endTime"
          readOnlyField={readOnlyField}
          generateStateMappers={generateStateMappers}
        />
      </div>
    </>
  )
}
export default Validity
