import React, { Fragment } from 'react'
import { Input, VALIDATION_TYPES } from '../../Form'
import { getMessage } from '../../../lib/translator'

const UrlInfo = ({ _this }) => {
  const URLS = ['web', 'facebook', 'instagram', 'externalUrl']
  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <h3>{getMessage('rewards.urlInfo')}</h3>
        </div>
      </div>
      <div className="row flex-wrap mb">
        {URLS.map((url, j) => (
          <div className="urlInfo" key={j}>
            <Input
              label={getMessage(`rewards.partner.urlInfo.${url}`)}
              placeholder={getMessage(`rewards.partner.urlInfo.${url}`)}
              type="text"
              {..._this.generateStateMappers({
                stateKeys: [`${url}`],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default UrlInfo
