import React from 'react'
import {
  BaseForm,
  Radio,
  VALIDATION_TYPES,
  CampaignSearch,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

export default class CampaignFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <CampaignSearch
            label={getMessage('preorder.filters.name.heading')}
            className="campaignSearchInput"
            placeholder={getMessage('preorder.filters.name.placeholder')}
            name="campaigns"
            {...this.generateStateMappers({
              stateKeys: ['campaigns'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            showChain
            campaignSearch={true}
          />
          <Radio
            label={getMessage('preorder.filters.status.heading')}
            name="status"
            className="campaignStatus"
            options={[
              {
                text: getMessage('preorder.filters.status.label.all'),
                value: '',
              },
              {
                text: getMessage('preorder.filters.status.label.active'),
                value: 'Active',
              },
              {
                text: getMessage('preorder.filters.status.label.scheduled'),
                value: 'Scheduled',
              },
              {
                text: getMessage('preorder.filters.status.label.inactive'),
                value: 'InActive',
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ['status'],
              defaultValue: '',
            })}
          />
        </div>
        <SubmitButton className="searchButton">{getMessage('preorder.filters.submitText')}</SubmitButton>
        <ClearButton testid="clearSearch"
        className="clearButton">
          {getMessage('preorder.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
