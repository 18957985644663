import React from 'react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import Banner from '../../../../components/Layout/Banner'
import ImageSlideshow from '../../../../components/Layout/ImageSlideshow'
import ImageWithText from '../../../../components/Layout/ImageWithText'
import ProductCollection from '../../../../components/Layout/ProductCollection'
import {
  BannerWithText,
  BannerWithMultipleButtons,
  VideoCardCarousel,
} from '../../../../components/migrated-from-admin-ui-builder'
import checkboxChecked from '../../../../icons/checkbox-checked.svg'
import checkboxUnchecked from '../../../../icons/checkbox-unchecked.svg'
import './SortableList.css'

const availableComponents = {
  Images: {
    ImageSlideShow: ImageSlideshow,
    ImageWithText: ImageWithText,
  },
  'E-commerce': {
    ProductCollection: ProductCollection,
    CategoryCollection: ProductCollection,
    BrandCollection: ProductCollection,
    SearchResults: ProductCollection,
  },
  Banners: {
    MarketingBanners: Banner,
    BannerWithText: BannerWithText,
    BannerWithButton: BannerWithText,
    BannerWithMultipleButtons: BannerWithMultipleButtons,
  },
  Videos: {
    VideoCardCarousel: VideoCardCarousel,
  },
}

export const SortableList = sortableContainer((props) => {
  const {
    view,
    selectedItems,
    onAddSelected,
    onEditClick,
    onRemoveClick,
    items,
  } = props

  const SortableComponent =
    view === 'expanded' ? SortableItem : SortableCollapsedItem

  return (
    <div data-testid={`${view}-list-container`}>
      {items.map((value, index) => (
        <div
          key={value.id}
          style={{
            position: 'relative',
          }}
        >
          <SortableComponent
            index={index}
            value={value}
            layoutIndex={index} // for some reason index was not being sent as props
            {...props}
            onEditClick={() => {
              onEditClick(index)
            }}
            onRemoveClick={() => {
              onRemoveClick(index)
            }}
            isSelected={selectedItems.has(value.id)}
            onAddSelected={() => onAddSelected(value.id)}
          />
        </div>
      ))}
    </div>
  )
})

const SortableCollapsedItem = sortableElement((props) => {
  const { value, onEditClick, onRemoveClick, isSelected, onAddSelected } = props

  return (
    <div className="sortable-collapsed-item">
      <SortableItemTitle
        value={value}
        isSelected={isSelected}
        onAddSelected={onAddSelected}
      />
      {value.data && (
        <div className="item-info">
          <div className="item-title">{value.data.title}</div>
          <div className="item-subtitle">{value.data.subtitle}</div>
        </div>
      )}
      <div className="swimlane-buttons">
        {value.name !== 'MarketingBanners' && (
          <button
            data-testid={`edit-${value.name}`}
            className="button primary"
            onClick={onEditClick}
          >
            Edit
          </button>
        )}
        <button
          className="button"
          aria-label="Remove Swimlane"
          onClick={onRemoveClick}
        >
          Remove
        </button>
      </div>
    </div>
  )
})

const SortableItemTitle = ({ isSelected, onAddSelected, value }) => {
  return (
    <h3 className="sortable-item-title">
      <img
        id={`item-${value.id}`}
        src={isSelected ? checkboxChecked : checkboxUnchecked}
        role="checkbox"
        aria-checked={isSelected}
        onClick={onAddSelected}
        style={{ maxWidth: 30, marginRight: 15 }}
      />

      <label
        htmlFor={`item-${value.id}`}
        style={{
          fontSize: 'inherit',
          fontWeight: 'inherit',
          margin: 0,
        }}
      >
        {value.name.replace(/([A-Z])/g, ' $1').trim()}
      </label>
    </h3>
  )
}

const SortableItem = sortableElement((props) => {
  const {
    value,
    onEditClick,
    onRemoveClick,
    isSelected,
    onAddSelected,
    isLayoutEditAllowed,
  } = props
  let LayoutComponent = ''
  Object.keys(availableComponents).forEach(function (component) {
    if (availableComponents[component][value.name]) {
      LayoutComponent = availableComponents[component][value.name]
    }
  })
  const textToShow = isLayoutEditAllowed ? 'Edit' : 'View'
  return (
    <div className="layoutItem">
      <SortableItemTitle
        value={value}
        isSelected={isSelected}
        onAddSelected={onAddSelected}
      />

      <div className={'editable layout'}>
        {LayoutComponent && <LayoutComponent data={value.data} />}
        <div className="toolbar">
          {value.name !== 'MarketingBanners' && (
            <button className="primary button" onClick={onEditClick}>
              {textToShow}
            </button>
          )}
          {isLayoutEditAllowed && (
            <button className="button" onClick={onRemoveClick}>
              Remove
            </button>
          )}
        </div>
      </div>
    </div>
  )
})
