import React, { Component } from 'react'
import { Input, Select } from '../../../../components/Form'
import { Actions } from '../form'
import { getMessage } from '../../../../lib/translator'
import WhispirIcon from './whispir.png'

class Whispir extends Component {
  constructor(props) {
    super(props)
    this.regions = [
      {
        text: 'AU',
        value: 'AU',
      },
      {
        text: 'AP',
        value: 'AP',
      },
      {
        text: 'AP1',
        value: 'AP1',
      },
      {
        text: 'NZ',
        value: 'NZ',
      },
      {
        text: 'US',
        value: 'US',
      },
      {
        text: 'IT',
        value: 'IT',
      },
      {
        text: 'EDU',
        value: 'EDU',
      },
    ]
  }
  render() {
    const { parent, type, provider, allowKey } = this.props
    const _this = parent
    const readOnly = _this.getState([allowKey]) === false
    return (
      <React.Fragment>
        <Actions parent={_this} icon={WhispirIcon} allowKey={allowKey} />
        <div className="form-fields">
          <Select
            label={getMessage('communication.region')}
            placeholder={getMessage('communicaition.region.placeholder')}
            options={this.regions}
            name="region"
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'region'],
              loseEmphasisOnFill: true,
            })}
            disabled={readOnly}
          />
          <Input
            name="api-key"
            type="password"
            label={getMessage('communication.apiKey')}
            placeholder={getMessage('communication.apiKey.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'apiKey'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            label={getMessage('communication.username')}
            placeholder={getMessage('communication.username.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'username'],
              loseEmphasisOnFill: true,
            })}
            name="username"
            readOnly={readOnly}
          />
          <Input
            name="password"
            type="password"
            label={getMessage('communication.password')}
            placeholder={getMessage('communication.password.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'password'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default Whispir
