import React, { useState, useEffect } from 'react'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import { Popup } from 'components/Popup'
import { Checkbox } from 'components/Form'

const GoodwillPopup = ({
  challengeDetails,
  handleGoodwillPopup,
  togglePopUp,
  uid,
  taskId,
  handleChallengeSelect,
  type = '',
}) => {
  const [confirmGoodwillPopUp, setConfirmGoodwillPopUp] = useState(false)
  const [confirmGoodwillSucessMsg, setConfirmGoodwillSucessMsg] =
    useState(false)
  const [reason, setReason] = useState('')
  const [orderNewAmount, setOrderNewAmount] = useState('')
  const [orderNo, setOrderNo] = useState('')
  const [error, setError] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const challengeId = Number(challengeDetails.split('-')[0].trim())

  const isFormValid = () => {
    const isReasonValid = reason.trim() !== ''
    const isOrderNewAmountValid =
      !isNaN(Number(orderNewAmount)) && Number(orderNewAmount) > 0
    const isOrderNoValid = !isNaN(Number(orderNo)) && Number(orderNo) > 0

    if (type === 'CUMULATIVE_SPEND') {
      return isReasonValid && isOrderNewAmountValid && isOrderNoValid
    }

    return isReasonValid && isOrderNoValid
  }

  const submitGoodwillRequest = () => {
    if (!isFormValid()) {
      setError(getMessage('rewards.goodwill.validationMsg'))
      return
    }
    setError('')
    setConfirmGoodwillPopUp(true)
  }

  const confirmGoodwillApproval = async () => {
    try {
      if (!isChecked) {
        setError(getMessage('rewards.confirmation.validationMessage'))
        return
      }
      const url = `/chendol/user-tasks/${taskId}/user-orders/${orderNo}/goodwill`
      const payload = {
        uid,
        challengeId,
        reason,
        channel: 'FPON',
        orderNewAmount: Number(orderNewAmount),
      }

      const api = new API({ url })
      const response = await api.post(payload)
      if (response?.status === 204) {
        setConfirmGoodwillSucessMsg(true)
      }
    } catch (e) {
      if (e.code === 404) {
        setError(getMessage('rewards.goodwill.apiError404'))
      } else {
        setError(e.message || getMessage('rewards.defaultError'))
      }
    }
  }

  useEffect(() => {
    if (confirmGoodwillSucessMsg) {
      const timeoutId = setTimeout(() => {
        setConfirmGoodwillPopUp(false)
        handleGoodwillPopup()
        togglePopUp(false)
        handleChallengeSelect()
        handleChallengeSelect(challengeId)
      }, 1000)
      return () => clearTimeout(timeoutId)
    }
    return () => {}
  }, [confirmGoodwillSucessMsg])

  return (
    <Popup
      className={`rewards goodwill-popup ${
        confirmGoodwillPopUp ? 'goodwill-confirm-popup' : ''
      }`}
      show={true}
      heading={
        confirmGoodwillPopUp
          ? getMessage('rewards.confirmation.goodwill')
          : getMessage('rewards.goodwill')
      }
      close={handleGoodwillPopup}
    >
      <div className="row">
        <div className="col-12">
          <p>
            <strong>
              {getMessage('rewards.goodwill.manuallyQualifyOrder')}
            </strong>
          </p>
          <div className={`${confirmGoodwillPopUp ? 'styledBox' : ''}`}>
            <p>
              <strong>
                {getMessage('rewards.goodwill.applicableToChallenge')}
              </strong>
              <span className="content">{challengeDetails}</span>
            </p>
            <div className="field">
              <label>{getMessage('rewards.goodwill.channel')}</label>
              <span className="Select">
                <select data-testid={'channel'}>
                  <option value={'FPON'} className="selected">
                    {'FPON'}
                  </option>
                </select>
              </span>
            </div>
            <div className="field">
              <label>{getMessage('rewards.goodwill.orderNo')}</label>
              <span className="input">
                <input
                  name="orderNo"
                  placeholder={getMessage(
                    'rewards.goodwill.orderNo.placeholder'
                  )}
                  value={orderNo}
                  readOnly={confirmGoodwillPopUp}
                  type="number"
                  min={0}
                  max={999999}
                  onChange={(e) => setOrderNo(e.target.value)}
                />
              </span>
            </div>
            {type === 'CUMULATIVE_SPEND' && (
              <div className="field">
                <label>{getMessage('rewards.order.newAmount')}</label>
                <span className="input orderNewAmount">
                  <span className={'input-addon'}>$</span>
                  <input
                    name="orderNewAmount"
                    placeholder={getMessage(
                      'rewards.order.newAmount.placeholder'
                    )}
                    value={orderNewAmount}
                    readOnly={confirmGoodwillPopUp}
                    type="number"
                    prefix="$"
                    min={0}
                    max={999999}
                    onChange={(e) => setOrderNewAmount(e.target.value)}
                  />
                </span>
              </div>
            )}
            <div className="field">
              <label>{getMessage('rewards.goodwill.reasonForRequest')}</label>
              <input
                placeholder={getMessage(
                  'rewards.goodwill.reasonForRequest.placeholder'
                )}
                name="reason"
                data-testid="reason"
                value={reason}
                readOnly={confirmGoodwillPopUp}
                type="text"
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </div>
          {confirmGoodwillPopUp && (
            <Checkbox
              label=""
              testid="confirm-checkbox"
              name="confirm"
              inlineLabel={getMessage(
                'customer.details.rewards.goodwill.confirmMsg'
              )}
              onChange={(e) => {
                setIsChecked(e)
                setError('')
              }}
            />
          )}
          {error && <div className="error">{error}</div>}
        </div>
      </div>
      <div className="row form-btns">
        <div className="col-12">
          {confirmGoodwillPopUp && (
            <button
              type="button"
              className="button"
              data-testid="cancel"
              onClick={() => {
                setConfirmGoodwillPopUp(false)
                setIsChecked(false)
                setError('')
              }}
            >
              {getMessage('rewards.form.cancel')}
            </button>
          )}
          <button
            type="button"
            className="button primary"
            data-testid={confirmGoodwillPopUp ? 'confirm' : 'submit'}
            onClick={
              confirmGoodwillPopUp
                ? confirmGoodwillApproval
                : submitGoodwillRequest
            }
          >
            {confirmGoodwillPopUp
              ? getMessage('rewards.form.confirm')
              : getMessage('rewards.form.submit')}
          </button>
        </div>
      </div>
      {confirmGoodwillSucessMsg && (
        <div className="row mt">
          <div className="col-12">
            <div className="success mb">
              {getMessage('rewards.goodwill.successMessage')}
            </div>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default GoodwillPopup
