import React from 'react'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { Checkbox } from '../../../../components/Form'

const DisableLinkCheckbox = props => {
  const { value, onChange } = props

  const splitConfig = React.useContext(SplitContext)
  const { splits } = splitConfig
  const isDisableBannerLinkEnabled =
    splits?.[SPLIT_FEATURES.DISCOVERY_DISABLE_BANNER_LINK_ENABLED]
      ?.treatment === 'on'

  if (!isDisableBannerLinkEnabled) {
    return null
  }

  return (
    <Checkbox
      label="Disable link"
      testid="disable-link"
      inlineLabel="Make banner link non-clickable"
      name="linkDisabled"
      onChange={onChange}
      value={value}
    />
  )
}

export default DisableLinkCheckbox
