import React from 'react';
import { Link } from 'react-router-dom'
import { Row, Cell } from 'components/Table';
import Image from 'components/Image';
import { getMessage } from 'lib/translator';
import SubstitutionIcon from 'icons/substitution-icon.svg';
import { getItemData, getCalculatedItemData } from 'lib/commonlyused/orderCalculations';

const isMarketplaceSku = (clientItemId) => clientItemId >= 90000000;

function SingleOrderItemRow({ rowClassName, item, currency, isPendingOrCancelled }) {

  const {
    orderedQuantity,
    deliveredQuantity,
    mrp,
    discount,
    substitutedItemId,
    soldByWeight,
    clientItemId,
    brand,
    name,
    isItemToSub,
    id,
    amendmentItemIndex,
    image
  } = getItemData(item, 'saleOrder');

  const {
    itemAmount,
    fixedDecimalPlace,
  } = getCalculatedItemData({ ...item, isPendingOrCancelled }, 'saleOrder');

  const getAmendmentText = (index) => {
    switch(index) {
        case 0: return getMessage('saleOrder.details.firstAmendment');
        case 1: return getMessage('saleOrder.details.secondAmendment');
        default: return '';
    }
  }

  return (
    <Row className={rowClassName}>
      <Cell className="item-image">
        { !substitutedItemId && <Image bordered size="sm" src={image} /> }
        { isItemToSub && <img className="sub-icon-img" src={SubstitutionIcon} alt="Substitution Icon" /> }
      </Cell>
      <Cell className="item-name">
        <Link to={`/catalogue/products/edit/${id}`} className="item-link">
          { substitutedItemId && <Image bordered size="sm" src={image} /> }
          <small className="text-muted">
            <div className="product-name">
              <span className="brand-name">{brand?.name}</span> {name}
            </div>
            {
              (amendmentItemIndex !== undefined && amendmentItemIndex !== -1) &&
              <div className='amendment-item-text-order'>
                  {getAmendmentText(amendmentItemIndex)}
              </div>
            }
          </small>
        </Link>
      </Cell>
      <Cell className="client-item-id">
        <Link to={`/catalogue/products/edit/${id}`}>
          {clientItemId}
          {isMarketplaceSku(clientItemId) && <span style={{fontWeight: 'bold'}}>&nbsp;({getMessage('saleOrder.details.mkpLabel')})</span>}
        </Link>
      </Cell>
      <Cell className="text-right">
        <small className='text-muted'>
          {Number(orderedQuantity).toFixed(fixedDecimalPlace)}
          {(soldByWeight === 1) && 'kg'}
        </small>
      </Cell>
      <Cell className="text-right">
        <small className='text-muted'>
          {deliveredQuantity ? Number(deliveredQuantity).toFixed(fixedDecimalPlace) : '-'}
          {(deliveredQuantity && soldByWeight === 1) && 'kg'}
        </small>
      </Cell>
      <Cell className="text-right">
        <small className='text-muted'>
          {currency.symbol}
          {Number(mrp).toFixed(2)}
        </small>
      </Cell>
      <Cell className="text-right">
        <small className='text-muted'>
          {currency.symbol} {Number(discount).toFixed(2)}
        </small>
      </Cell>
      <Cell className="text-right">
        <small>
          {currency.symbol} {itemAmount}
        </small>
      </Cell>
    </Row>
  )
}

export default SingleOrderItemRow;
