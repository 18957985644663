import React from 'react'
import { getMessage } from '../../../../../lib/translator'
import API from '../../../../../lib/api'
import { Popup } from '../../../../../components/Popup'
import { BaseForm } from '../../../../../components/Form'
import './style.css'

export default function GroupPopup(props) {
  return (
    <Popup
      show={props.GroupDialog.shown}
      className="PackageReq-box"
      heading={`${props.GroupDialog.status} ${getMessage('media.creativedetail.creative.group.header.name')}`}
      close={() => { props.toggleGroupDialog(2) }}
    >
      <PackageReqForm
        creativeId={props.creativeId}
        group_no={props.group_no}
        GroupDialog={props.GroupDialog}
        afterSubmit={props.handleSubmitGroup}
        onCancel={() => { props.toggleGroupDialog(2) }}
      />
    </Popup>
  )
}

class PackageReqForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: {},
      packageReqList: [],
      popupStatus: this.props.GroupDialog.status,
      selectedRequirementId: this.props.GroupDialog.selectedRequirementId,
      selectedCreativeGroupId: this.props.GroupDialog.selectedCreativeGroupId
    }

    this.initPackageReqList = this.initPackageReqList.bind(this)
    this.toggleGroupData = this.toggleGroupData.bind(this)
    this.checkIsGroupValid = this.checkIsGroupValid.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    this.initPackageReqList()
  }

  initPackageReqList() {
    const packageReqList = JSON.parse(JSON.stringify(this.props.GroupDialog.packageReqList))
    if (this.state.popupStatus === 'Edit') {
      packageReqList.forEach((item) => {
        if (item.creative_requirement?.id === this.state.selectedRequirementId) {
          item.isGroupValid = true

          item.assets.forEach((asset) => {
            asset.selected = asset.is_assigned
          })
        } else {
          item.isGroupValid = false
        }
      })
    }
    this.setState({
      packageReqList
    })
  }

  toggleGroupData(crIndex, crAssetIndex) {
    const packageReqList = this.state.packageReqList
    let isAnySelected = false
    packageReqList.forEach((item, index) => {
      item.assets.forEach((asset, assetIndex) => {
        if (index === crIndex && assetIndex === crAssetIndex) {
          asset.selected = !asset.selected
        }
        if (asset.selected) {
          isAnySelected = true
        }
      })
    })

    this.setState({
      packageReqList: this.checkIsGroupValid(packageReqList, isAnySelected, crIndex)
    })
  }

  checkIsGroupValid(packageReqList, isAnySelected, crIndex) {
    if (this.state.popupStatus === "Create") {
      packageReqList.forEach((item, index) => {
        if (!isAnySelected) {
          item.isGroupValid = true
        } else {
          item.isGroupValid = index === crIndex ? true : false
        }
      })
    }
    return packageReqList
  }

  onSubmit() {
    const creativeGroupAsset = []
    this.state.packageReqList.forEach((item) => {
      if (item.isGroupValid) {
        item.assets.forEach((assetItem) => {
          if (assetItem.selected) {
            creativeGroupAsset.push(assetItem.asset.id)
          }
        })
      }
    })

    if (creativeGroupAsset.length > 0) {

      const payload = {
        'group_no': this.props.group_no,
        'creative_id': Number(this.props.creativeId),
        'creative_group_asset': creativeGroupAsset
      }
      const groupURL = '/martech-creative-service/creative-group/'
      if (this.state.popupStatus === "Create") {
        this.api = new API({ url: groupURL })
        this.api.post(payload).then(() => {
          this.props.afterSubmit()
        }).catch(error => {
          this.props.afterSubmit('error', error)
        })
      } else {
        payload.id = this.state.selectedCreativeGroupId
        this.api = new API({ url: groupURL + this.state.selectedCreativeGroupId })
        this.api.put(payload).then(() => {
          this.props.afterSubmit()
        }).catch(error => {
          this.props.afterSubmit('error', error)
        })
      }
    } else {
      this.props.afterSubmit('error', { message: getMessage('media.creativedetail.creative.group.emptyAsset') })
    }
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    return (
      <Form className="PackageReq-wrap">
        <div className="PackageReq-set-scroll">
          {this.state.packageReqList?.map((item, index) => (
            <div className="PackageReq-set" key={index}>

              <RequirementTitle
                index={index}
                creative_requirement={item.creative_requirement}
                packageReqList={this.state.packageReqList}
              ></RequirementTitle>

              <div className="PackageReq-set-assets">
                {item.assets?.map((assetItem, assetIndex) => (
                  <div key={assetIndex}
                    className={`PackageReq-set-assets-item ${!item.isGroupValid || (this.props.GroupDialog.status === 'Create' && assetItem.is_assigned) ? 'disabled' : ''}`}
                  >
                    {item.creative_requirement && (
                      <AssetSelectButton
                        index={index}
                        assetIndex={assetIndex}
                        item={item}
                        popupStatus={this.state.popupStatus}
                        assetItem={assetItem}
                        selectedCreativeGroupId={this.state.selectedCreativeGroupId}
                        toggleGroupData={this.toggleGroupData}
                      ></AssetSelectButton>
                    )}
                    <span className='checkbox_label'>{assetItem.asset.asset_name}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="form-buttons">
          <SubmitButton>
            {getMessage('websitePage.form.submitText')}
          </SubmitButton>
          <CancelButton>
            {getMessage('websitePage.form.cancelText')}
          </CancelButton>
        </div>
      </Form>
    )
  }
}

function RequirementTitle(props) {
  const { index, creative_requirement, packageReqList } = props
  let hasNoRequirement = false
  packageReqList.forEach(reqItem => {
    if (!reqItem.creative_requirement) {
      hasNoRequirement = true
    }
  })
  return (
    <div>
      {creative_requirement ? (
        <div className="PackageReq-set-index">{`${getMessage('media.creativedetail.creative.group.header.cr')} ${hasNoRequirement ? index : (index + 1)}`}</div>
      ) : (
        <div className="PackageReq-set-index">{`${getMessage('media.creativedetail.creative.group.header.withoutcr')} `}</div>
      )}
    </div>
  )
}

function AssetSelectButton(props) {
  const { index, assetIndex, item, assetItem, popupStatus, selectedCreativeGroupId, toggleGroupData } = props
  return (
    <button
      type="button"
      data-testid={`cr_${index}_asset_${assetIndex}`}
      disabled={!item.isGroupValid || (popupStatus === 'Create' && assetItem.is_assigned) || (popupStatus === 'Edit' && assetItem.is_assigned && selectedCreativeGroupId !== assetItem.assigned_creative_group_id)}
      className={`checkbox_btn ${(popupStatus === 'Create' ? (assetItem.is_assigned || assetItem.selected) : (item.isGroupValid ? assetItem.selected : assetItem.is_assigned)) ? 'button-checked' : 'button-unchecked'}`}
      onClick={() => {
        toggleGroupData(index, assetIndex)
      }}
    />
  )
}
