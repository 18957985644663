export const WORKING_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]
export const ROTATED_WORKING_DAYS = [
  ...WORKING_DAYS.slice(-1),
  ...WORKING_DAYS.slice(0, -1),
]

export const PUBLIC_HOLIDAY_MAP = {
  '2021-01-01': 'New Year’s Day',
  '2021-02-12': 'Chinese New Year',
  '2021-02-13': 'Chinese New Year',
  '2021-04-02': 'Good Friday',
  '2021-05-01': 'Labour Day',
  '2021-05-26': 'Vesak Day',
  '2021-05-13': 'Hari Raya Puasa',
  '2021-07-09': 'Hari Raya Haji',
  '2021-08-09': 'National Day',
  '2021-11-04': 'Deepavali',
  '2021-12-25': 'Christmas Day',
}
