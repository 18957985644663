import React, { Component } from 'react'
import moment from 'moment'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import Loader from '../../../../components/Loader'
import {
  throwError,
  DeleteAndErrorDialog
} from '../../commonMedia'
import MediaCreativeForm from './CreativeForm'
import './style.css'


const APIurl = '/martech-creative-service/creative/'

export default class MediaCreativeDetail extends Component {
  constructor(props) {
    super(props)

    this.api = null
    this.state = {
      showLoader: true,
      pageAction: 'add',
      data: {},
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
    }
    this.getCreativeDetail = this.getCreativeDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.getCreativeDetail()
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  getCreativeDetail(){
    if (this.props.match?.params?.id) {
      this.setState({
        pageAction: 'edit',
      })

      this.api = new API({ url: APIurl + this.props.match.params.id })
      this.api.get().then(response => {
        this.setState({
          data: {
            ...response.data,

          },
          showLoader: false,
        })
      }).catch(error => {
        throwError(this, error)
      })
    } else {
      this.setState({
        showLoader: false,
      })
    }
  }

  handleSubmit(formData) {

    this.setState({
      showLoader: true,
    })

    if (this.state.pageAction === 'edit') {
      this.api = new API({ url: APIurl + this.props.match.params.id })
      const updateCreative = {
        id: formData.id,
        creative_name: formData.creative_name,
        creative_type: formData.creative_type,
      }
      this.api.put(updateCreative).then(() => {
        this.props.history?.goBack()
      }).catch(error => {
        throwError(this, error)
      })
    } else {
      this.api = new API({ url: APIurl })
      this.api.post(formData).then((res) => {
        this.props.history.push(`/media-monetization/media-creativemanagement/media-creative-group-list/${res?.data.id}`)
      }).catch(error => {
        throwError(this, error)
      })
    }
  }

  render() {

    return (
      <AuthenticatedPage
        menu={this.props.menu}
        showLanguageDropDown
        className='creative-detail-page'
        title={getMessage('media.creativedetail.title.' + this.state.pageAction)}
      >
        <div className='header-container'>
          <h1 className='title'>{getMessage('media.creativedetail.title.' + this.state.pageAction)}</h1>
          {this.state.pageAction === 'edit' && (
            <div className='timestamp'>
              <div>{getMessage('media.public.createdby')}: {this.state.data?.created_by} 丨 {moment(this.state.data?.created_time).format('ddd, DD MMM YYYY, h:mm A')}</div>
              <div>{getMessage('media.public.updatedby')}: {this.state.data?.updated_by} 丨 {moment(this.state.data?.updated_time).format('ddd, DD MMM YYYY, h:mm A')}</div>
            </div>
          )}
        </div>

        {this.state.showLoader ? <Loader /> : (
          <MediaCreativeForm
            value={this.state.data}
            pageAction={this.state.pageAction}
            onSubmit={this.handleSubmit}
            onCancel={() => { this.props.history?.goBack() }}
          />
        )}

        <DeleteAndErrorDialog
          _this={this}
          errorDialog={this.state.errorDialog}
        ></DeleteAndErrorDialog>
      </AuthenticatedPage>
    )
  }
}
