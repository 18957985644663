const data = {
  'user.accountBalance.heading': 'Account Balance',
  'user.accountBalance.loader.text': 'Fetching your balance. Please wait...',
  'user.accountBalance.availableBalance.label': 'Available balance',
  'user.accountBalance.input.placeholder': 'Enter your amount',
  'user.accountBalance.rechargeButton.text': 'Recharge',
  'user.accountBalance.rechargeButton.processing': 'Processing...',
  'user.transactions.emptyList.text': 'You have no transactions yet',
  'user.transactions.header.billingInfo': 'Billing info',
  'user.transactions.header.status': 'Status',
  'user.transactions.header.payment': 'Payment',
  'user.transactions.status.pending': 'Processing',
  'user.transactions.status.completed': 'Completed',
  'user.transactions.status.failed': 'Failed',
  'user.payment.loader.text': 'Verifying payment, please wait...',
  'user.payment.success.title': 'Payment successful',
  'user.payment.fail.title': 'Payment failed',
  'user.payment.redirect.link': 'Click here to view account balance',
  'user.transactions.header.type': 'Type',

  'leaves.team.employee': 'Employee',
  'leaves.team.startson': 'Starts On',
  'leaves.team.endson': 'Ends On',
  'leaves.team.appliedon': 'Applied On',
  'leaves.team.reason': 'Reason',
  'leaves.team.type': 'Type',
  'leaves.team.status': 'Status',
  'leaves.team.actions': 'Actions',
  'leaves.team.empty.message': 'No leaves',
  'leaves.team.approveable': 'Leaves applied by team members',
  'leaves.team.title': 'Team leaves',
  'leaves.team.are.you.sure': 'Are you sure?',
  'leaves.team.approve': 'Approve',
  'leaves.team.approve.leave': 'The leave will be approved',
  'leaves.team.cancel': 'Cancel',
  'leaves.team.reject.leave': 'This leave will be rejected',
  'leaves.team.reject': 'Reject',
  'leaves.team.cancel.title': 'Approved leaves that can be cancelled',
  'leaves.team.cancel.leave': 'This leave will be cancelled',
  'leaves.team.okay': 'Okay',

  'my.leaves.title': 'My leaves',
  'leaves.header.status': 'Status',
  'leaves.management.header': 'Leave Management',
  'leaves.header.startsOn': 'Starts On',
  'leaves.header.endsOn': 'Ends On',
  'leaves.headers.appliedOn': 'Applied On',
  'leaves.header.reason': 'Reason',
  'leaves.header.type': 'Type',
  'leaves.header.actions': 'Actions',
  'leaves.dialog.title': 'Are you sure?',
  'leaves.dialog.information': 'Your leave will be cancelled',
  'leaves.dialog.cancel': 'Cancel',
  'leaves.dialog.okText': 'Okay',
  'leaves.cancel': 'Cancel',
  'leaves.filter.username': 'Name',
  'leaves.filter.username.placeholder': 'Enter name',
  'leaves.filter.fromdate': 'From',
  'leaves.filter.date': 'Date',
  'leaves.filter.todate': 'To',
  'leaves.filter.status.heading': 'Status',
  'leaves.filter.status.all': 'All',
  'leaves.filter.status.pending': 'Pending',
  'leaves.filter.status.approved': 'Approved',
  'leaves.filter.status.rejected': 'Rejected',
  'leaves.filter.status.approvedcancelleble': 'Cancellable',
  'leaves.filter.submit': 'Submit',
  'leaves.filter.clearFiltersText': 'Clear All',
  'leaves.form.duration': 'Duration',
  'leaves.filters.from': 'From',
  'leaves.filters.to': 'To',
  'leaves.form.reason': 'Reason',
  'leaves.form.reason.placeholder': 'Enter reason',
  'leaves.form.type': 'Type',
  'leaves.form.type.placeholder': 'Choose type',
  'leaves.form.cancel': 'Cancel',
  'leaves.apply': 'Apply',
  'leaves.apply.new': 'Apply for leave',
  'leaves.emptyMessage': 'There are no leaves available',
  'leaves.filter.status.cancelled': 'Cancelled',
  'leaves.filter.status.placeholder': 'Choose Status',

  'user.leave-summary.heading': 'Leave Summary',
  'user.leave-summary.button.today': 'Today',
  'user.leave-summary.status.heading': 'Status',
  'user.leave-summary.status.All': 'All',
  'user.leave-summary.status.Earned': 'Earned',
  'user.leave-summary.status.Paternity': 'Paternity',
  'user.leave-summary.status.Maternity': 'Maternity',
  'user.leave-summary.status.Sick': 'Sick',
  'user.leave-summary.status.Halfday': 'Halfday',
}

export default data
