import React, { Component } from 'react'
import Table from '../Table'
import { getMessage } from '../../../../lib/translator'
import { getSession } from '../../../../lib/auth'

const PaymentActionsMap = {
    PAYMENT_INITIATION: 'order.log.payment.initiated',
    PAYMENT_CANCELLATION: 'order.log.payment.cancelled',
    PAYMENT_COMPLETION: 'order.log.payment.completed'
}

export default class PaymentLog extends Component {

    getPaymentStatusLog(item, data, paymentStatus) {
        return (
            <div className="placed-from">
                <span className="bold">
                    {paymentStatus}
                </span>
                {' for '}
                {`${getSession().organization.currency.symbol}${item.amount}`}
                {data.action === 'PAYMENT_INITIATION' && item.paymentService ? ' via ' + item.paymentService : ''}
                {item.paymentMode && (
                    <span className="user-name">
                        <span className="by"> Mode:</span> {item.paymentMode}
                    </span>
                )}
            </div>
        )
    }

    render() {
        const {
            index,
            data,
            item
        } = this.props
        const isPaymentStatusAction = PaymentActionsMap.hasOwnProperty(data.action)
        return (
            <div>
                {item.payment && (
                    <Table
                        data={{
                            header: 'Payment',
                            tableData: item.payment.newValue,
                            properties: ['mode', 'amount', 'status', 'transactionId'],
                            index: index,
                        }}
                    />
                )}
                {isPaymentStatusAction && this.getPaymentStatusLog(item, data, getMessage(PaymentActionsMap[data.action]))}
                {data.action === 'REFUND_INITIATION' && (
                    <div className="placed-from">
                        <span className="bold">
                            {getMessage('order.log.refund.initiated')}
                        </span>
                        {' for '}
                        {`${getSession().organization.currency.symbol}${item.amount}`}
                        {item.paymentMethod && (
                            <span className="user-name">
                                <span className="by"> Mode:</span> {item.paymentMethod}
                            </span>
                        )}
                    </div>
                )}
            </div>
        )
    }
}
