import React from 'react'
import { isExtensionEnabled } from '../../../../lib/auth'
import { getMessage } from '../../../../lib/translator'
import { get } from '../../../../lib/storage'
import {
  BaseForm,
  Input,
  Radio,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import CategorizedStoreSelector from '../../../../components.new/store-selector/CategorizedStoreSelector'
import editIcon from '../../commonMedia/images/edit-icon.svg'

export default class AdvertForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: this.props.value,
      image_format_Error: '',
      multiStoreEnabled: false,
      showSeqError: false,
      seqError: '',
      stores: [],
      scopes: {
        global: 'GLOBAL',
        storeSpecific: 'STORE',
      },
    }
    this.setStateOfParent = this.setStateOfParent.bind(this)
  }

  componentDidMount() {
    const multiStoreEnabled = isExtensionEnabled('MultiStoreSupport')
    if (multiStoreEnabled) {
      this.setState({
        stores: JSON.parse(get('stores')),
      })
    }
    this.setState({
      selectedAdvertisers: this.props.value.advertiser,
      multiStoreEnabled,
      values: {
        ...this.state.values,
        scope: this.state.values?.scope || this.state.scopes.global,
      },
    })
  }

  componentWillReceiveProps(newProps) {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.values = newProps.value
      return newState
    })
  }

  setStateOfParent = (cloudinaryUrl, format, bytes, width, height) => {
    let imageSizeOrFormatError = ''
    if (
      this.props.imageFormat &&
      (this.props.imageFormat.toLowerCase().indexOf(format) < 0 ||
        bytes > this.props.imageSize * 1000 ||
        width !== Number(this.props.imageWidth) ||
        height !== Number(this.props.imageHeight))
    ) {
      imageSizeOrFormatError = 'Image format or size are invalid.'
    }

    this.setState({
      values: {
        ...this.state.values,
        link_to_storage: cloudinaryUrl,
      },
      image_format_Error: imageSizeOrFormatError,
    })
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    const { scopes, stores, values, multiStoreEnabled } = this.state
    const scope = !(values && values.scope !== scopes.storeSpecific)

    const options = [
      {
        text: getMessage('banners.editForm.radio.option.global'),
        value: scopes.global,
      },
    ]
    if (multiStoreEnabled) {
      options.push({
        text: getMessage('banners.editForm.radio.option.storeSpecific'),
        value: scopes.storeSpecific,
      })
    }

    return (
      <div
        className={`website-page-edit drawer ${values.isEditing ? 'active' : ''}`}
      >
        <Form className="website-page-form">
          <div className="drawer-header">
            <div className="title">Ad {values.isEditing && '- editing'}</div>
            <div className="icons">
              {!values.isEditing && this.props.pageAction === 'view' && (
                <div
                  className="icon edit-icon"
                  onClick={() => {
                    this.props.isNotEditable
                      ? this.props.toggleEitDisabledDialog()
                      : this.props.toggleAdStatus('edit', this.props.adIndex)
                  }}
                >
                  <img src={editIcon} alt="edit" />
                </div>
              )}
            </div>
          </div>

          <div className="section-row drawer-details">
            <Input
              className="section-row-input"
              label={getMessage('media.creative.adlist.form.sequence')}
              placeholder={getMessage('media.creative.adlist.form.sequence')}
              name="sequence"
              type="number"
              min={1}
              error={this.state?.seqError}
              showErrors={this.state?.showSeqError}
              disabled={!values.isEditing}
              {...this.generateStateMappers({
                stateKeys: ['seq'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONCHANGE,
                onChange: (val) => {
                  if (
                    this.props.adListData?.filter((x) => val === x.seq).length >
                    0
                  ) {
                    this.setState({
                      showSeqError: true,
                      seqError: getMessage(
                        'media.creative.adlist.form.sequence.existsMessage'
                      ),
                    })
                    return
                  }
                  this.setState({
                    showSeqError: false,
                    seqError: ``,
                  })
                },
              })}
              validationStrings={{
                valueMissing: getMessage(
                  'media.creative.adlist.form.sequence.requiredMessage'
                ),
              }}
            />
            <Input
              className="section-row-input"
              label={getMessage('media.creative.adlist.form.name')}
              placeholder={getMessage(
                'media.creative.adlist.form.name.placeholder'
              )}
              name="ad_name"
              type="text"
              required
              disabled={!values.isEditing}
              {...this.generateStateMappers({
                stateKeys: ['ad_name'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage(
                  'media.creative.adlist.form.name.requiredMessage'
                ),
              }}
            />
            <Input
              className="section-row-input"
              label={getMessage('media.creative.adlist.form.url')}
              placeholder={getMessage(
                'media.creative.adlist.form.url.placeholder'
              )}
              name="ad_url"
              type="text"
              disabled={!values.isEditing}
              {...this.generateStateMappers({
                stateKeys: ['ad_url'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage(
                  'media.creative.adlist.form.url.requiredMessage'
                ),
              }}
            />
            <div className="section-row-input">
              <Radio
                className={`field-no-margin-btm ${!values.isEditing && 'readonly'}`}
                label={getMessage('banners.editForm.radio.label')}
                name="scope"
                options={options}
                readOnly={!values.isEditing}
                {...this.generateStateMappers({
                  stateKeys: ['scope'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              {values.isEditing && multiStoreEnabled && stores && scope && (
                <CategorizedStoreSelector
                  values={values['storeId'] || []}
                  stores={stores}
                  validateEmpty={true}
                  onUpdate={(value) => {
                    const newValues = {
                      ...values,
                      storeId: value,
                    }
                    this.setState({ values: newValues })
                  }}
                />
              )}
            </div>

            <Input
              className="section-row-input-full input-hidden"
              label={getMessage('media.creative.adlist.form.link')}
              name="link_to_storage"
              type="text"
              value={values.link_to_storage}
              disabled={!values.isEditing}
              {...this.generateStateMappers({
                stateKeys: ['link_to_storage'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
            {values.isEditing && <div>*Image Upload Is Not Available</div>}
            <div className="img_preview section-row-input-full">
              {values.link_to_storage && <img src={values.link_to_storage} />}
              <div className="input-error-message">
                {this.state.image_format_Error}
              </div>
            </div>
          </div>
          {values.isEditing && (
            <div className="btns">
              <SubmitButton disabled={this.state.image_format_Error}>
                {getMessage('layouts.save')}
              </SubmitButton>
              <CancelButton>
                {getMessage('websitePage.form.cancelText')}
              </CancelButton>
              {values?.id && (
                <button
                  type="button"
                  className="button delete"
                  onClick={() => {
                    this.props.toggleDeleteDialog(values.id)
                  }}
                >
                  {getMessage('media.action.delete')}
                </button>
              )}
            </div>
          )}
        </Form>
      </div>
    )
  }
}
