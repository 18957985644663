import React from 'react'
import {
  DrawingManager, GoogleMap, InfoWindow, Marker,
  Polygon, Polyline, StandaloneSearchBox
} from '@react-google-maps/api'
class GoogleMaps extends React.Component {
  render() {
    const { props } = this
    const defaultOptions = props.defaultOptions || null
    return (
      <GoogleMap
        mapContainerClassName={props.containerClassName}
        onLoad={props.onMapMounted}
        zoom={props.zoom}
        onClick={props.onMapClick || null}
        center={props.center || (props.getCenter && props.getCenter())}
        onBoundsChanged={props.onBoundsChanged}
        options={defaultOptions}
      >
        {props.children}
      </GoogleMap>
    )
  }
}

GoogleMaps.defaultProps = {
  zoom: 10,
  containerClassName: ''
}

export default GoogleMaps

export {
  Marker,
  Marker as MarkerWithLabel,
  StandaloneSearchBox as SearchBox,
  Polygon,
  InfoWindow,
  DrawingManager,
  Polyline,
  GoogleMaps
}
