import React from 'react'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  Upload,
} from '../../../../components/Form'
import Image from '../../../../components/Image'
import RichEditor, { importText } from '../../../../components/RichEditor'
import { Popup, Dialog } from '../../../../components/Popup'
import Layout, { getLayout } from './layout'
import { getMessage } from '../../../../lib/translator'
import { getSession } from '../../../../lib/auth'

import './style.css'

function getSlugFromText(text) {
  if (!text) {
    return ''
  }
  return text.toLowerCase().replace(/\s+/g, '-')
}

const SortableItem = SortableElement(props => {
  const {
    content,
    index,
    getState,
    updateState,
    onImageChange,
    showDelete,
    layoutIndex,
  } = props
  if (content.Image) {
    return (
      <div className="layout-box" key={`layout-box-${index}`}>
        <div
          className="image-layout layout-container website-form"
          key={`image-${index}`}
        >
          <div className="layout-editable">
            <Image src={content.Image.imageUrl} />
          </div>
        </div>
        <div className="layout-actions">
          <div className="add-image-container">
            <label
              htmlFor={`add-image-action-${layoutIndex}`}
              className="add-image-label layout-edit"
            />
            <Upload
              name={`add-image-action-${layoutIndex}`}
              onChange={value => onImageChange(value, layoutIndex)}
            />
          </div>
          <button
            type="button"
            className="layout-delete"
            onClick={() => showDelete(layoutIndex)}
          />
        </div>
      </div>
    )
  } // else if (content.ImageWithText) {
  //   const layoutInfo = getLayout('ImageWithText') || {}
  //   return (
  //     <div className='layout-box' key={`layout-box-${index}`} >
  //       <div className='image-with-text-layout layout-container' key={`image-with-text-${index}`}>
  //         <div className='layout-editable' >
  //           <ImageWithTextView
  //             data={content.ImageWithText}
  //           />
  //         </div>
  //         <div className='layout-actions'>
  //           <button type='button' className='button primary' onClick={(e) => this.showImageWithTextEdit(index, layoutInfo, content.ImageWithText)}>{getMessage('websitepage.edit')}</button>
  //           <button type='button' className='button' onClick={(e) => this.showDelete(index)} >{getMessage('websitePage.delete')}</button>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  else {
    return (
      <div className="editor-conatiner" key={`editor-${index}`}>
        <RichEditor
          key={`content-${index}`}
          placeholder={getMessage('websitePage.form.content.placeholder')}
          value={getState(['content', layoutIndex])}
          onChange={value => updateState(['content', layoutIndex], value)}
        />
        <div className="editor-buttons">
          <button
            type="button"
            className="button"
            onClick={() => showDelete(layoutIndex)}
          />
        </div>
      </div>
    )
  }
})

const SortableList = SortableContainer(props => {
  return (
    <div className="sortable-container">
      {props.content &&
        props.content.map((value, index) => {
          const { ...other } = props
          const additionalKey = value.Image
            ? value.Image.imageUrl
            : importText(value)
          return (
            <SortableItem
              key={`item-${index}-${additionalKey}`}
              index={index}
              content={value}
              layoutIndex={index}
              {...other}
            />
          )
        })}
    </div>
  )
})

export default class WebsitePageForm extends BaseForm {
  constructor(props) {
    super(props)
    this.domain = getSession().organization.domain

    this.state.showImageEdit = false
    this.state.editingIndex = null
    this.state.showImageWithTextEdit = false
    this.state.showAdd = false
    this.state.showDialog = false

    this.handleClose = this.handleClose.bind(this)
    this.handleImageSubmit = this.handleImageSubmit.bind(this)
    this.handleImageWithTextSubmit = this.handleImageWithTextSubmit.bind(this)
    this.showImageWithTextEdit = this.showImageWithTextEdit.bind(this)
    this.addLayout = this.addLayout.bind(this)
    this.showDelete = this.showDelete.bind(this)
    this.deletelayout = this.deletelayout.bind(this)
    this.handleUploadChange = this.handleUploadChange.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
    const content =
      this.state.values.content && this.state.values.content.filter(Boolean)
    if (!content) {
      this.state.values = {
        content: [],
      }
    }
  }

  showImageWithTextEdit(index, layoutInfo, data) {
    this.setState({
      editingIndex: index,
      showImageEdit: false,
      showImageWithTextEdit: true,
      layoutInfo: layoutInfo,
      data: data,
    })
  }

  handleClose() {
    this.setState({
      showImageWithTextEdit: false,
      showDialog: false,
      showAdd: false,
      layoutInfo: null,
      editingIndex: null,
      showDelete: false,
      deleteIndex: null,
      data: null,
    })
  }

  handleImageSubmit(formData) {
    let editingIndex = this.editingIndex
    if (editingIndex === undefined || editingIndex === null) {
      editingIndex = this.state.values.content.length
    }
    const values = Object.assign({}, this.state.values)
    const content = values.content
    content[editingIndex] = { Image: { imageUrl: formData } }
    this.editingIndex = null
    this.setState({
      editingIndex: null,
      layoutInfo: null,
      data: null,
      showImageEdit: false,
      values: values,
      showAdd: false,
    })
  }

  handleImageWithTextSubmit(formData) {
    let editingIndex = this.state.editingIndex
    if (!editingIndex) {
      editingIndex = this.state.values.content.length
    }
    const values = Object.assign({}, this.state.values)
    const content = values.content
    content[editingIndex] = { ImageWithText: formData }
    this.setState({
      editingIndex: null,
      layoutInfo: null,
      data: null,
      showImageWithTextEdit: false,
      values: values,
    })
  }

  addLayout(layout) {
    if (layout === 'ContentBlock') {
      const data = importText('')
      const values = Object.assign({}, this.state.values)
      const content = values.content
      content.push(data)
      this.setState({
        values: values,
      })
    } else if (layout === 'ImageWithText') {
      const layoutInfo = getLayout(layout)
      this.setState({
        editingIndex: null,
        showImageWithTextEdit: true,
        layoutInfo: layoutInfo,
        data: {},
      })
    }
    this.setState({
      showAdd: false,
    })
  }

  showDelete(index) {
    this.setState({
      showDelete: true,
      deleteIndex: index,
    })
  }

  deletelayout() {
    const values = Object.assign({}, this.state.values)
    const content = values.content
    const deleteIndex = this.state.deleteIndex
    content.splice(deleteIndex, 1)
    this.setState({
      values: values,
      showDelete: false,
      deleteIndex: null,
    })
  }

  onSortEnd({ oldIndex, newIndex }) {
    const values = Object.assign({}, this.state.values)
    const content = values.content
    const newContent = arrayMove(content, oldIndex, newIndex)
    values.content = newContent
    this.setState({
      values,
    })
  }

  handleUploadChange(value, index) {
    this.editingIndex = index
    this.handleImageSubmit(value)
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    return (
      <div className="website-page-edit">
        <Popup
          show={this.state.showImageWithTextEdit}
          heading={getMessage('websitePage.popup.imagewithtext.heading')}
          close={this.handleClose}
        >
          <Layout
            data={Object.assign({}, this.state.data)}
            preview={this.state.layoutInfo && this.state.layoutInfo.preview}
            fields={this.state.layoutInfo && this.state.layoutInfo.fields}
            onSubmit={this.handleImageWithTextSubmit}
          />
        </Popup>
        <Dialog
          show={this.state.showDelete}
          title={getMessage('websitePage.dialog.title')}
          information={getMessage('websitePage.dialog.information')}
          onOk={this.deletelayout}
          close={this.handleClose}
          okText={getMessage('websitePage.delete')}
          closeText={getMessage('websitePage.form.cancelText')}
        />
        <Form className="website-page-form">
          <Input
            className="title-input"
            label={getMessage('websitePage.form.title.heading')}
            placeholder={getMessage('websitePage.form.title.placeholder')}
            name="title"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ['title'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            onBlur={() => {
              if (!this.getState(['url'])) {
                this.updateState(
                  ['url'],
                  getSlugFromText(this.getState(['title']))
                )
              }
            }}
            validationStrings={{
              valueMissing: getMessage(
                'websitePage.form.title.requiredMessage'
              ),
            }}
          />
          <Input
            className="url-input"
            label={getMessage('websitePage.form.url.heading')}
            placeholder={getMessage('websitePage.form.url.placeholder')}
            name="url"
            type="text"
            required
            prefix={`${this.domain}/pages/`}
            {...this.generateStateMappers({
              stateKeys: ['url'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
            }}
          />
          <SortableList
            content={
              this.state.values && this.state.values.content.filter(Boolean)
            }
            showImageEdit={this.showImageEdit}
            helperClass="is-dragging"
            showDelete={this.showDelete}
            showImageWithTextEdit={this.showImageWithTextEdit}
            getState={this.getState.bind(this)}
            updateState={this.updateState.bind(this)}
            onSortEnd={this.onSortEnd}
            hideSortableGhost
            axis="y"
            transitionDuration={300}
            onImageChange={this.handleUploadChange}
            pressDelay={200}
            lockAxis="y"
            lockOffset="50%"
            pressThreshold="5"
          />
          <div className="add-layout">
            <div
              className={`add-layout ${
                this.state.showAdd ? 'expanded' : ''
              }`.trim()}
            >
              <button
                type="button"
                className={`add ${this.state.showAdd ? 'expanded' : ''}`.trim()}
                onClick={() => {
                  const showAdd = this.state.showAdd
                  this.setState({
                    showAdd: !showAdd,
                  })
                }}
              />
              {this.state.showAdd && (
                <div
                  className={`add-actions ${
                    this.state.showAdd ? 'expanded' : ''
                  }`.trim()}
                >
                  <div className="add-image-container">
                    <label
                      htmlFor="add-image-action"
                      className="add-image-label button"
                    >
                      Add Image
                    </label>
                    <Upload
                      name="add-image-action"
                      onChange={this.handleImageSubmit}
                    />
                  </div>
                  <button
                    type="button"
                    className="button add-content-block"
                    onClick={() => {
                      this.addLayout('ContentBlock')
                    }}
                  >
                    Add Text
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="form-buttons">
            <SubmitButton>
              {getMessage('websitePage.form.submitText')}
            </SubmitButton>
            <CancelButton>
              {getMessage('websitePage.form.cancelText')}
            </CancelButton>
          </div>
        </Form>
      </div>
    )
  }
}
