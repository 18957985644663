import React, { Component } from 'react'

export default class DenominationAndNoOfVouchers extends Component {
  render() {
    return (
      this.props.method && (
        <div data-testid="denomination-header">
          <hr />
          <h3>
            Denomination and Number of{' '}
            <span className="non-breaking">E-Vouchers</span>
          </h3>
          <div className="row denomination voucher-values">
            <div className="col-4">
              <label>Denomination</label>
            </div>
            <div className="col-4 ml">
              <label>
                Number of <span className="non-breaking">E-Vouchers</span>
              </label>
            </div>
          </div>
        </div>
      )
    )
  }
}
