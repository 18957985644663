import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import RackWithStores from './Form'
import { getMessage } from '../../../lib/translator'

export default class RackManagement extends Component {
  render() {
    return (
      <AuthenticatedPage
        className="rack-management"
        menu={this.props.menu}
        title={getMessage('rackmanagement.heading')}
        from={this.props.location && this.props.location.pathname}
      >
        <RackWithStores goBack={this.props.history.goBack} />
      </AuthenticatedPage>
    )
  }
}
