import React from 'react'

const MAX_PERCENT = 50;

const getProductNames = (products = []) => {
  return products.map((product) => product.name).join(', ')
}

const getProductDiscountSummary = (
  product,
  discountValue,
  isFixedOff,
  isFree,
  isPercentOff,
  itemDiscountType
) => {
  if (itemDiscountType === 'INDIVIDUAL_DISCOUNT') {
    isFree = product.t === 'FREE'
    isFixedOff = product.t === 'FIXED'
    isPercentOff = product.t === 'PERCENT_OFF'
    discountValue = isPercentOff ? `${product?.v}%` : `$${product?.v}`
  }
  if (isFree || isFixedOff) {
    return `${product.name} ${isFree ? 'for Free' : 'at ' + discountValue}`
  }
  return `${discountValue} off on ${product.name}`
}

const getOfferSummaryForBANYGYD = (
  values,
  quantity,
  discountValue,
  isFixedOff,
  isFree,
  isPercentOff
) => {
  const { buyproduct, itemDiscountType, product: products = [] } = values || {}
  if (values.type === 'BXGYD') {
    quantity = (buyproduct?.length > 0 && buyproduct[0]?.q) || 0
  }
  let offerString= `Buy ${quantity} of ${getProductNames(
    buyproduct
  )} and get`

  if(values.entityType === 'CATEGORY' || values.entityType ==='BRAND'){
    offerString = `Buy ${quantity} from ${values?.category?.name || values?.brand?.name} ${values.entityType.toLowerCase()} and get`
  }
  let productOffString = `${discountValue} off on ${getProductNames(products)}`
  if (isFree || isFixedOff) {
    productOffString = `${getProductNames(products)} ${
      isFree ? 'for Free' : 'at ' + discountValue
    }`
  }
  if (itemDiscountType === 'INDIVIDUAL_DISCOUNT') {
    productOffString = products
      .map((product) => {
        return getProductDiscountSummary(
          product,
          discountValue,
          isFixedOff,
          isFree,
          isPercentOff,
          itemDiscountType
        )
      })
      .join(', ')
  }
  return `${offerString} ${productOffString}`
}
const getOfferSummaryForBXATP = (
  products,
  discountValue,
  isFixedOff,
  isFree,
  isPercentOff,
  itemDiscountType
) => {
  const productOffString = (products || [])
    .map((product) => {
      return getProductDiscountSummary(
        product,
        discountValue,
        isFixedOff,
        isFree,
        isPercentOff,
        itemDiscountType
      )
    })
    .join(', ')
  return `Get ${productOffString}`
}

const getOfferSummaryForBMIN = (products, rules, includes) => {
  const { elementGroups = [] } = rules || {}
  return elementGroups.map((rule, index) => {
    const isFree = rule?.total?.t === 'FREE'
    const isFixedOff = rule?.total?.t === 'FIXED'
    const isPercentOff = rule?.total?.t === 'PERCENT_OFF'
    const discountValue = isPercentOff
      ? `${rule?.total?.v}%`
      : `$${rule?.total?.v}`
    let productOffString = ` ${discountValue} off on all product except ${getProductNames(
      products
    )}`
    if (isFree || isFixedOff) {
      productOffString = `all products ${
        isFree ? 'for Free' : 'at ' + discountValue
      } except ${getProductNames(products)}`
    }
    if (includes) {
      if (isFree || isFixedOff) {
        productOffString = `${getProductNames(products)} ${
          isFree ? 'for Free' : 'at ' + discountValue
        }`
      } else {
        productOffString = `${discountValue} off on ${getProductNames(
          products
        )}`
      }
    }
    return (
      <span className="summary-list" key={index}>
        Spent amount is ${rule?.minAmount} then get {productOffString}
      </span>
    )
  })
}
const getOfferSummaryForBMINXGFG = (
  buyproduct,
  rules,
  itemDiscountType,
  includes,
  values,
  setIsShowDiscountWarning
) => {
  const { elementGroups = [] } = rules || {}
  return elementGroups.map((rule, index) => {
    const isFree = rule?.total?.t === 'FREE'
    const isFixedOff = rule?.total?.t === 'FIXED'
    const isPercentOff = rule?.total?.t === 'PERCENT_OFF'
    if(isPercentOff && rule?.total?.v >= MAX_PERCENT && setIsShowDiscountWarning){
      setIsShowDiscountWarning(true)
    }
    const discountValue = isPercentOff
      ? `${rule?.total?.v}%`
      : `$${rule?.total?.v}`
    const products = rule?.product || []
    const hasCategoryorBrandEntity = values?.entityType === 'CATEGORY' || values?.entityType ==='BRAND';
    let includesString = `if you ${includes ? 'buy' : 'do not buy'} with ${getProductNames(buyproduct)}`;
    let categoryBrandString = ''
    if(hasCategoryorBrandEntity){
      includesString=''
      categoryBrandString = `on ${includes ?'' : 'any category except'} ${getElementName(values?.category || values?.brand)} ${values?.entityType.toLowerCase()}`
    }
    const productOffString = products
      .map((product) => {
        return getProductDiscountSummary(
          product,
          discountValue,
          isFixedOff,
          isFree,
          isPercentOff,
          itemDiscountType
        )
      })
      .join(', ')
    return (
      <span className="summary-list" key={index}>
        Spent amount is ${rule?.minAmount} {categoryBrandString} then get {productOffString} {includesString}
      </span>
    )
  })
}

const getOfferSummaryForBMINXATP = (products, rules) => {
  const { elementGroups = [] } = rules || {}
  return elementGroups.map((rule, index) => {
    const isFree = rule?.total?.t === 'FREE'
    const isFixedOff = rule?.total?.t === 'FIXED'
    const isPercentOff = rule?.total?.t === 'PERCENT_OFF'
    const discountValue = isPercentOff
      ? `${rule?.total?.v}%`
      : `$${rule?.total?.v}`
    let productOffString = `${discountValue} off on ${getProductNames(
      products
    )}`
    if (isFree || isFixedOff) {
      productOffString = `${getProductNames(products)} ${
        isFree ? 'for Free' : 'at ' + discountValue
      }`
    }
    return (
      <span className="summary-list" key={index}>
        Buy minimum quantity {rule?.minQuantity} then get {productOffString}
      </span>
    )
  })
}

const getOfferSummaryForBANYAT = (
  isDirectDiscount,
  isFree,
  discountValue,
  quantity,
  products = []
) => {
  const productNames = getProductNames(products)
  if (products.length > 0 && !isDirectDiscount) {
    return `Buy ${quantity} and get ${discountValue} off on ${productNames}`
  }
  return `Buy ${quantity} and get ${productNames} ${
    isFree ? 'for Free' : 'at ' + discountValue
  } `
}
const getOfferSummaryForPWP = (
  discountValue,
  isDirectDiscount,
  isFree,
  rule
) => {
  if (isDirectDiscount) {
    return `Spend amount is $${rule?.cartPrice} then Get ${
      rule?.total?.variantId?.name || ''
    }  ${isFree ? 'for Free' : 'at ' + discountValue}`
  }
  return `spend amount is $${
    rule?.cartPrice
  } then get ${discountValue} discount on ${
    rule?.total?.variantId?.name || ''
  }`
}

const getDiscountValue = (isPercentOff, value = 0) => {
  return isPercentOff ? `${value}%` : `$${value}`
}

export const getOffersSummary = (values, setIsShowDiscountWarning) => {
  const isPercentOff = values?.rule?.total?.t === 'PERCENT_OFF'
  const isFixedOff = values?.rule?.total?.t === 'FIXED'
  const isFree = values?.rule?.total?.t === 'FREE'
  const quantity = values?.rule?.quantity
  const isDirectDiscount = isFixedOff || isFree
  const value = values?.rule?.total?.v
  const discountValue = getDiscountValue(isPercentOff, value)

  let summaryString = ''
  if (['BANYGYD', 'BXGYD'].includes(values.type)) {
    if(isPercentOff && value >=MAX_PERCENT && setIsShowDiscountWarning){
      setIsShowDiscountWarning(true)
    }
    summaryString = getOfferSummaryForBANYGYD(
      values,
      quantity,
      discountValue,
      isFixedOff,
      isFree,
      isPercentOff,
    )
  } else if (values.type === 'BXATP') {
    summaryString = getOfferSummaryForBXATP(
      values?.product,
      discountValue,
      isFixedOff,
      isFree,
      isPercentOff,
      values?.itemDiscountType
    )
  } else if (values.type === 'PWP') {
    summaryString = getOfferSummaryForPWP(
      discountValue,
      isDirectDiscount,
      isFree,
      values?.rule
    )
  } else if (values.type === 'BMIN') {
    summaryString = getOfferSummaryForBMIN(
      values?.product,
      values?.rule,
      values?.includes
    )
  } else if (values.type === 'BMINXGFG') {
    summaryString = getOfferSummaryForBMINXGFG(
      values?.buyproduct,
      values?.rule,
      values?.itemDiscountType,
      values?.includes,
      values,
      setIsShowDiscountWarning
    )
  } else if (values.type === 'BMINXATP') {
    summaryString = getOfferSummaryForBMINXATP(values?.product, values?.rule)
  } else {
    summaryString = getOfferSummaryForBANYAT(
      isDirectDiscount,
      isFree,
      discountValue,
      quantity,
      values?.product
    )
  }
  return summaryString
}

export const getOffersSummaryForCategoryBrandOrTags = (values,setIsShowDiscountWarning)=>{
  if(values.type === 'BANYGYD'){
    return getOffersSummary(values, setIsShowDiscountWarning)
  }
  if(values.type === 'BMINXGFG'){
    return getOffersSummary(values, setIsShowDiscountWarning)
  }
  let includes = true
  if(values.type === 'BMIN'){
    includes = !!values?.includes;
  }
  if (['BMIN', 'BMINXATP'].indexOf(values.type) > -1) {
   const rules =  values?.rule?.elementGroups ||[]
   const summaryString = rules.map(rule=>getOffersSummaryForCategoryBrandOrTagsByRule(rule,values,values.entityType,includes,setIsShowDiscountWarning))
   return summaryString.join(', ')
  }
  return getOffersSummaryForCategoryBrandOrTagsByRule(values.rule,values,values.entityType,includes,setIsShowDiscountWarning)
}

const getElementName = (element) =>{
  const isElementArray = Array.isArray(element)
  if(isElementArray){
    return element.map(item=>(item?.name || '')).join(', ')
  }
  return element?.name || ''
}

export const getOffersSummaryForCategoryBrandOrTagsByRule = (rule, values,entityType, includes, setIsShowDiscountWarning) => {
  const quantity = rule?.minQuantity || rule?.quantity || rule?.minAmount
  const isPercentOff = rule?.total?.t === 'PERCENT_OFF'
  const isFixedOff = rule?.total?.t === 'FIXED'
  const value = rule?.total?.v || 0
  const discountValue = `${isPercentOff ? value + '%' : '$' + value}`
  const primaryString = values.type ==='BMIN' ? 'Spent amount is' : 'Buy'
  const includesString = includes ? '' : `any ${entityType.toLowerCase()} except`
  let summaryString = ''
  if (entityType === 'CATEGORY') {
    summaryString = `${primaryString} ${quantity} in ${includesString} ${
      getElementName(values?.category)
    } category and get ${discountValue} off`
    if(isFixedOff){
      summaryString = `${primaryString} ${quantity} in ${includesString}  ${
        getElementName(values?.category)
      } category and get at ${discountValue}`
    }
  } else if (entityType === 'BRAND') {

    summaryString = `${primaryString} ${quantity} in ${includesString} ${
      getElementName(values?.brand)
    } brand and get ${discountValue} off`
    if(isFixedOff){
      summaryString = `${primaryString} ${quantity} in ${includesString}  ${
        getElementName(values?.brand)
      } brand and get at ${discountValue}`
    }

  } else if (values.type === 'BFXATP') {
    const tags = values?.tags?.map((tag) => tag.name).join(', ')
    summaryString = `${discountValue} off on ${tags} tags`
  }
  if(values.type === 'BMINXGFG'){
    summaryString += ` on ${getProductNames(rule.product)}`
  }
  if(isPercentOff && value >= MAX_PERCENT){
    setIsShowDiscountWarning(true)
  }
  return summaryString
}

export const getCartLevelOfferSummary = (rules, type) => {
  let offerTypeString = type === 'SFXGCD' ? 'cart value' : 'shiping'
  if (type === 'SFXGSFD') {
    offerTypeString = 'service fee'
  }

  return (rules || [])
    .map((rule) => {
      const isFree = rule?.total?.t === 'FREE'
      const isFixedOff = rule?.total?.t === 'FIXED'
      const isPercentOff = rule?.total?.t === 'PERCENT_OFF'
      const discountValue = isPercentOff
        ? `${rule?.total?.v}%`
        : `$${rule?.total?.v}`
      if (isFree || isFixedOff) {
        return `Spent amount is $${
          rule?.cartPrice
        } then get ${offerTypeString} ${
          isFree ? 'for free' : 'at ' + discountValue
        }`
      }
      return `Spent amount is $${rule?.cartPrice} then get ${discountValue} off on ${offerTypeString}`
    })
    .join(', ')
}
