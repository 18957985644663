import React from 'react'
import { BaseForm } from '../../../components/Form'
import API from '../../../lib/api'
import Loader from '../../../components/Loader'
import { isExtensionEnabled, getSession } from '../../../lib/auth'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'

class LayoutWrap extends BaseForm {
  constructor(props) {
    super(props)
    const user = getSession().user
    const layoutEditSplit =
      this.props.splits?.[SPLIT_FEATURES.DISCOVERY_LAYOUT_EDIT] || {}
    let isLayoutEditAllowed = true
    if (layoutEditSplit.treatment === 'on') {
      const allowedUsers = JSON.parse(layoutEditSplit.config)
      isLayoutEditAllowed = allowedUsers.includes(user.id)
    }
    this.state = { ...this.state, isLayoutEditAllowed }
    this.preview =
      this.props.preview ||
      (() => {
        return null
      })
    this.fields =
      this.props.fields ||
      (() => {
        return null
      })

    this.state.values = {
      ...this.props.data,
      status: this.props.status,
    }
    this.getValueLayouts = {
      CategoryCollection: {
        name: 'category',
        key: 'categoryIds',
      },
      BrandCollection: {
        name: 'brand',
        key: 'brandIds',
      },
    }
  }
  componentDidMount() {
    const name = this.props.name
    const data = this.props.data
    const layout = this.getValueLayouts[name]
    if (layout && data[layout.key] && data[layout.key].length > 0) {
      this.setState({
        loading: true,
      })
      let param = ''
      let cparam = ''
      let tparam = ''
      data[layout.key] &&
        data[layout.key].forEach((id) => {
          if (layout.name === 'category') {
            if (Number(id)) {
              cparam += `id=${id}&`
            } else {
              const type = id && id[0] && id[0].toString()
              if (type === 'C') {
                cparam += `id=${id && id.toString().slice(1)}&`
              } else if (type === 'T') {
                tparam += `id=${id && id.toString().slice(1)}&`
              }
            }
          } else {
            param += `id=${id}&`
          }
        })
      if (layout.name === 'category') {
        cparam += 'paginate=false'
        tparam += 'paginate=false'
        const categoryApi = cparam
          ? new API({ url: `/catalogue-service/category?${cparam}` })
          : null
        const tagApi =
          tparam && isExtensionEnabled('ProductTagSupport')
            ? new API({ url: `/catalogue-service/tag?${tparam}` })
            : null
        Promise.all([
          categoryApi && categoryApi.get(),
          tagApi && tagApi.get(),
        ]).then(([categoryResponse, tagResponse]) => {
          let categories =
            (categoryResponse &&
              categoryResponse.data &&
              categoryResponse.data.category) ||
            []
          categories = (categories || []).map((category) => {
            category.id = `C${category.id}`
            return category
          })
          let tags =
            (tagResponse && tagResponse.data && tagResponse.data.tag) || []
          tags = (tags || []).map((tag) => {
            tag.name = `T ${tag.name}`
            tag.id = `T${tag.id}`
            return tag
          })
          categories = [...categories, ...tags]
          const categoryData = categories
          const sortedData = []
          // We are sorting the get incoming data based on the props data categoryIds
          if (this.props.sortable) {
            this.props.data &&
              this.props.data[layout.key] &&
              this.props.data[layout.key].map((id) => {
                categoryData.map((item) => {
                  if (item.id === id) {
                    sortedData.push(item)
                  }
                  return null
                })
                return null
              })
          }
          const values = Object.assign({}, this.state.values)
          values[layout.name] = this.props.sortable ? sortedData : categoryData
          this.setState({
            values,
            loading: false,
          })
        })
      } else {
        const api = new API({
          url: `/catalogue-service/${layout.name}?${param}`,
        })
        api.get().then((response) => {
          const resData = response.data[layout.name]
          const values = Object.assign({}, this.state.values)
          values[layout.name] = resData
          this.setState({
            values,
            loading: false,
          })
        })
      }
    }

    if (
      name === 'ProductCollection' ||
      name === 'BrandCollection' ||
      name === 'CategoryCollection' ||
      name === 'VoucherSwimlane' ||
      name === 'ProductCollectionWithBanner'
    ) {
      new API({ url: '/account-service/store' })
        .get({ paginate: 'false' })
        .then((e) => {
          this.setState({
            stores: e.data.store,
          })
        })
    }

    if (
      name === 'ProductCollection' ||
      name === 'ProductCollectionWithBanner'
    ) {
      const entityObj = {}
      const promises = []
      if (data.category) {
        const api = new API({
          url: `/catalogue-service/category?slug=${data.category}`,
        })
        if (data.category === 'CURRENT') {
          entityObj.category = {
            id: new Date().getTime(),
            name: 'current',
            displayName: 'current',
            slug: 'CURRENT',
          }
        } else {
          promises.push(api.get())
        }
      }
      if (data.brand && isExtensionEnabled('MultiBrandSupport')) {
        const api = new API({
          url: `/catalogue-service/brand?slug=${data.brand}`,
        })
        if (data.brand === 'CURRENT') {
          entityObj.brand = {
            id: new Date().getTime(),
            name: 'current',
            displayName: 'current',
            slug: 'CURRENT',
          }
        } else {
          promises.push(api.get())
        }
      }

      if (data.tag && isExtensionEnabled('ProductTagSupport')) {
        const api = new API({ url: `/catalogue-service/tag?slug=${data.tag}` })
        if (data.tag === 'CURRENT') {
          entityObj.tag = {
            id: new Date().getTime(),
            name: 'current',
            displayName: 'current',
            slug: 'CURRENT',
          }
        } else {
          promises.push(api.get())
        }
      }
      Promise.all(promises).then((responseArr) => {
        let values = JSON.parse(JSON.stringify(this.state.values))
        responseArr.map((response) => {
          if (response.data.category) {
            entityObj.category = response.data.category[0]
          }
          if (response.data.brand) {
            entityObj.brand = response.data.brand[0]
          }
          if (response.data.tag) {
            entityObj.tag = response.data.tag[0]
          }

          return null
        })
        values = { ...values, ...entityObj }

        if (!values.loadMoreType) {
          values.loadMoreType =
            values.layoutType === 'GRID' ? 'INFINITE' : 'SEEALL'
        }

        if (!values.layoutType) {
          values.layoutType = 'GRID'
        }

        this.setState({ values })
      })
    }
  }

  render() {
    const { Form } = this.components
    const { isTagBrandCategoryPage, isDefaultView } = this.props
    /* istanbul ignore next */
    const isEditPermitted =
      !isTagBrandCategoryPage || (isTagBrandCategoryPage && !isDefaultView)
    const { SubmitButton, CancelButton } = this.buttons
    const Preview = this.preview
    const styles = {
      transform: this.props.top
        ? `translate3d(0, ${this.props.top - 65}px, 0)` // 65px to accommodate for the page heading
        : '',
    }
    const loading = this.state.loading
    return loading ? (
      <Loader />
    ) : (
      <Form>
        <h1>{this.props.name.replace(/([A-Z])/g, ' $1').trim()}</h1>
        <div
          ref={(el) => {
            this.layoutElement = el
          }}
          className="layout chosenAnimation"
          style={styles}
        >
          <Preview data={this.state.values} />
        </div>
        <div className={`editLayout revealAnimation ${this.props.name}`}>
          {this.fields({
            getState: this.getState.bind(this),
            updateState: this.updateState.bind(this),
            parent: this,
            page: this.props.page,
            stores: this.state.stores,
          })}
        </div>
        {(this.state.isLayoutEditAllowed || isEditPermitted) && (
          <div className="layout-footer revealAnimation">
            <CancelButton className="button">Cancel</CancelButton>
            <SubmitButton>Save</SubmitButton>
          </div>
        )}
      </Form>
    )
  }
}

const Layout = (props) => {
  const splitConfig = React.useContext(SplitContext)
  return <LayoutWrap {...props} splits={splitConfig?.splits} />
}

export default Layout
