import React, { Fragment, useState, useEffect } from 'react'
import { Select } from '../../../../../../components/Form'
import Loader from '../../../../../../components/Loader'
import API from '../../../../../../lib/api'
import '../style.css'
import OptIn from './OptIn'
import RenderListing from '../RenderListing'
import { getMessage } from '../../../../../../lib/translator'

const Draws = ({ uid }) => {
  const [campaignList, setCampaignList] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    function fetchDraws() {
      const duckAPI = new API({
        url: `/duck/campaigns?limit=1000&offset=0&isEnabled=true`,
      })

      return duckAPI
        .get()
        .then(response => {
          const campaignListArr = response.data.list?.map(item => {
            return {
              value: item.id,
              text: item.name,
            }
          })
          setCampaignList(campaignListArr)
        })
        .catch(e => {
          setError(
            e.message || getMessage('customer.details.rewards.serverError')
          )
        })
    }
    fetchDraws()
  }, [])

  const handleCampaignSelect = e => {
    setSelectedCampaign(e)
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <h2>{getMessage('customer.details.rewards.luckyDraws')}</h2>
        </div>
      </div>
      <OptIn uid={uid} />
      <div>
        {campaignList.length < 1 && <Loader size="sm" />}
        {error && (
          <div className="error" data-testid="error">
            {error}
          </div>
        )}
        {!error && campaignList.length > 0 && (
          <Fragment>
            <div className="row flex-direction">
              <div className="col-6 auto-width">
                <Select
                  testid="draw-campaign-list"
                  label={getMessage('customer.details.rewards.campaignLabel')}
                  placeholder={getMessage(
                    'customer.details.rewards.campaignPlaceholder'
                  )}
                  options={campaignList}
                  value={selectedCampaign}
                  onChange={handleCampaignSelect}
                  required
                  className="mt"
                />
              </div>
            </div>
            <RenderListing
              uid={uid}
              selectedCampaign={selectedCampaign}
              type="lucky-draw"
            />
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default Draws
