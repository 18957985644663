import { getMessage } from '../../../../../lib/translator'
import {
  removeDollars,
  addDollarSign,
  notEmptyArray,
} from '../../../../../lib/commonlyused'
import { STATUSES } from '../Details.enum'
import { cancelReasons, rejectReasons, refundReasons } from './Reasons'

const tablePopupHeading = state => {
  if (state.refundOrder) {
    return getMessage('qc.orderSummary.tablepopup.refundOrder.heading')
  } else if (state.rejectOrder) {
    return getMessage('qc.orderSummary.tablepopup.rejectOrder.heading')
  } else {
    return getMessage('qc.orderSummary.tablepopup.heading')
  }
}
const tablePopupSubmitButtonText = state => {
  if (state.refundOrder) {
    return getMessage('qc.orderSummary.tablepopup.refundOrder.button.submit')
  } else if (state.rejectOrder) {
    return getMessage('qc.orderSummary.tablepopup.rejectOrder.button.submit')
  } else {
    return getMessage('qc.orderSummary.tablepopup.button.submit')
  }
}
const tablePopupTestID = state => {
  if (state.refundOrder) {
    return 'refund-order'
  } else if (state.rejectOrder) {
    return 'reject-order'
  } else {
    return 'cancel-order'
  }
}
const tablePopupAmountText = state => {
  if (state.refundOrder) {
    return getMessage('qc.orderSummary.tablepopup.RefundAmount')
  } else if (state.rejectOrder) {
    return getMessage('qc.orderSummary.tablepopup.RejectedAmount')
  } else {
    return getMessage('qc.orderSummary.tablepopup.CancelledAmount')
  }
}
const tablePopupTotalAmountText = state => {
  if (state.refundOrder) {
    return getMessage('qc.orderSummary.tablepopup.total.amount.refunded')
  } else if (state.rejectOrder) {
    return getMessage('qc.orderSummary.tablepopup.total.amount.rejected')
  } else {
    return getMessage('qc.orderSummary.tablepopup.total.amount.cancelled')
  }
}
const successPopupHeading = state => {
  if (state.refundOrder) {
    return getMessage('qc.orderSummary.successpopup.refundOrder.heading')
  } else if (state.rejectOrder) {
    return getMessage('qc.orderSummary.successpopup.rejectOrder.heading')
  } else {
    return getMessage('qc.orderSummary.successpopup.heading')
  }
}
const successPopupDescription = state => {
  if (state.wholeOrder && state.refundOrder && state.hideTemp) {
    return getMessage(
      'qc.orderSummary.successpopup.refundOrder.masterdescription'
    )
  } else if (state.refundOrder) {
    return getMessage('qc.orderSummary.successpopup.refundOrder.description')
  } else if (state.orderCancelled && state.rejectOrder && state.hideTemp) {
    return getMessage(
      'qc.orderSummary.successpopup.rejectOrder.masterdescription'
    )
  } else if (state.rejectOrder) {
    return getMessage('qc.orderSummary.successpopup.rejectOrder.description')
  } else if (state.orderCancelled && state.cancelOrder && state.hideTemp) {
    return getMessage('qc.orderSummary.successpopup.masterdescription')
  } else {
    return getMessage('qc.orderSummary.successpopup.description')
  }
}
const reasonPopupHeading = state => {
  if (state.refundOrder) {
    return getMessage('qc.orderSummary.reasonpopup.refundOrder.title')
  } else if (state.rejectOrder) {
    return getMessage('qc.orderSummary.reasonpopup.rejectOrder.title')
  } else if (
    state.cancelOrder &&
    state.refundItems.filter(item => item.value).length > 1
  ) {
    return getMessage('qc.orderSummary.reasonpopup.multiple.title')
  } else {
    return getMessage('qc.orderSummary.reasonpopup.title')
  }
}
const reasonPopupQuestion = state => {
  if (state.refundOrder) {
    return getMessage('qc.orderSummary.reasonpopup.refundOrder.question')
  } else if (state.rejectOrder) {
    return getMessage('qc.orderSummary.reasonpopup.rejectOrder.question')
  } else if (
    state.cancelOrder &&
    state.refundItems.filter(item => item.value).length > 1
  ) {
    return getMessage('qc.orderSummary.reasonpopup.multiple.question')
  } else {
    return getMessage('qc.orderSummary.reasonpopup.question')
  }
}

const subOrderArray = props => {
  return Array.from({ length: props.data.subOrders.length }, () => false)
}

const handleReasons = (state, fulfillmentMethod) => {
  if (
    state.refundOrder &&
    (fulfillmentMethod === 'having_here' ||
      fulfillmentMethod === 'self_collect')
  ) {
    return refundReasons.filter(
      item => item.value !== 'NO_SHOW' && item.value !== 'LATE_DELIVERY'
    )
  } else if (state.refundOrder) {
    return refundReasons
  } else if (state.rejectOrder) {
    return rejectReasons
  } else if (
    state.cancelOrder &&
    (fulfillmentMethod === 'having_here' ||
      fulfillmentMethod === 'self_collect')
  ) {
    return cancelReasons.filter(item => item.value !== 'NO_SHOW')
  } else {
    return cancelReasons
  }
}

const customizationRefundPopData = (
  b,
  d,
  customPopupAppDiscount,
  stallDiscountArray,
  itemQuantity
) => {
  b.finalQuantity = itemQuantity ? d.finalQuantity : 0
  const itemPrice =
    b.finalQuantity *
    (removeDollars(b.formattedPrice) - removeDollars(b.formattedUnitDiscounts))
  const popupDiscountedPrice = !b.promotional
    ? b.finalQuantity * removeDollars(b.formattedUnitAppPaymentDiscount)
    : 0
  customPopupAppDiscount.push(popupDiscountedPrice)

  stallDiscountArray.push(itemPrice)
  b.formattedItemTotal =
    b.formattedPrice === '$0.00' ? '$0.00' : addDollarSign(itemPrice)
  b.formattedItemDiscountedTotal =
    b.formattedPrice === '$0.00' ? '$0.00' : addDollarSign(itemPrice)
  if (b.finalQuantity === 0) {
    b.formattedDiscountedPrice = '$0.00'
  }
  if (b.feeDetails.length) {
    const discountedContainerFee = b.feeDetails.find(
      item => item.type === 'CONTAINER_FEE'
    ).unitAmount
    const customPrice = discountedContainerFee * b.finalQuantity
    b.feeDetails.filter(
      item => item.type === 'CONTAINER_FEE'
    )[0].customPrice = customPrice
  } else {
    b.feeDetails.push({
      customPrice: 0,
      type: 'CONTAINER_FEE',
    })
  }
  if (notEmptyArray(b.customisationOptions)) {
    b.customisationOptions.filter(c => {
      customizationItemPopupData(
        d,
        c,
        customPopupAppDiscount,
        stallDiscountArray
      )
    })
  }
  if (notEmptyArray(b.customisationOptions)) {
    b.customisationOptions = b.customisationOptions.filter(
      f => f.finalQuantity > 0
    )
  }
  return b
}

const refundPopupdata = (temp1, subOrdersArray, customPopupAppDiscount) => {
  const subOrders = JSON.parse(JSON.stringify(subOrdersArray))
  const refundItemArray = []
  for (const j in temp1) {
    for (const i in subOrders) {
      refundPopupItemData(
        subOrders,
        i,
        temp1,
        j,
        refundItemArray,
        customPopupAppDiscount
      )
    }
  }
  return refundItemArray
}

const handleTablePopupData = (state, props) => {
  var allData = [{ ...props.data }]
  const suborderData = []
  const customPopupAppDiscount = []
  const payloadData = handleRefundItemsWithContainerFee(props, state)
  const containerCheckboxItems = payloadData.filter(a =>
    a.hasOwnProperty('fees')
  )
  const refundItemsState = payloadData.filter(a => !a.hasOwnProperty('fees'))

  if (state.refundOrder && containerCheckboxItems.length > 0) {
    const refundContainerFeeItemsData = handleRefundContainerFeeItemsData(
      allData[0],
      containerCheckboxItems,
      customPopupAppDiscount
    )
    suborderData.push(...refundContainerFeeItemsData)
  }
  if ((state.refundOrder || state.cancelOrder) && refundItemsState.length > 0) {
    const refundStatePopupdata = refundPopupdata(
      refundItemsState,
      allData[0]['subOrders'],
      customPopupAppDiscount
    )
    suborderData.push(...refundStatePopupdata)
  } else if (!state.refundOrder && containerCheckboxItems.length === 0) {
    suborderData.push(
      ...allData[0].subOrders.filter(
        item => item.id === Number(state.radioSubOrder)
      )
    )
  }
  const customPopupAppDiscountPrice = customPopupAppDiscount
    .reduce((acc, b) => acc + b, 0)
    .toFixed(2)
  allData[0]['subOrders'] = []
  allData[0].customPopupAppDiscountPrice = addDollarSign(
    customPopupAppDiscountPrice
  )
  allData[0].subOrders.push(...suborderData)
  return allData
}

const handlereasonMultipleSelectValue = (subOrders, val) => {
  const arr = []
  subOrders.map(item => arr.push({ id: item.id, value: val, cancelReason: '' }))
  return arr
}
const handleMultipleCancelReason = reasonMultipleSelectValue => {
  const arr = reasonMultipleSelectValue.filter(item => item.value)
  arr.map(b => delete b.value)
  return arr
}

const closeTablePopup = (handleState, state) => {
  handleState({
    ...state,
    showHideTablePopup: false,
  })
}
const handleReasonPopupBack = (handleState, state) => {
  handleState({
    ...state,
    showReasonPopup: false,
    showHideTablePopup: true,
  })
}
const handleTablePopupSubmit = (handleState, state) => {
  handleState({
    ...state,
    showReasonPopup: true,
    showHideTablePopup: false,
  })
}
const handleReasonPopupChange = (handleState, state, e) => {
  handleState({
    ...state,
    reasonSelectValue: e,
    changeReasonSubmitted: false,
  })
}
const handleMultipleReasonPopupChange = (handleState, state, e, id) => {
  const arr = [...state.reasonMultipleSelectValue]
  arr.find(a => a.id === id).cancelReason = e
  arr.find(a => a.id === id).value = true
  handleState({
    ...state,
    reasonMultipleSelectValue: arr,
    changeReasonSubmitted: false,
    selectAllReasons: false,
  })
  if (state.refundItems.filter(itemA => itemA.value).length > 1) {
    document.getElementById('selectAllReasons').checked = false
  }
}
const handleApplyReasons = (handleState, state, e) => {
  if (e) {
    const arr = [...state.reasonMultipleSelectValue]
    arr.map(item => {
      item.cancelReason = arr[0].cancelReason
      item.value = true
      return item
    })
    handleState({
      ...state,
      reasonMultipleSelectValue: arr,
      selectAllReasons: true,
    })
    if (state.refundItems.filter(itemA => itemA.value).length > 1) {
      document.getElementById('selectAllReasons').checked = true
    }
  } else {
    handleState({
      ...state,
      selectAllReasons: false,
    })
    if (state.refundItems.filter(itemA => itemA.value).length > 1) {
      document.getElementById('selectAllReasons').checked = false
    }
  }
}

const closeSuccessPopup = (handleState, state, history, location) => {
  handleState({
    ...state,
    cancelOrder: false,
    rejectOrder: false,
    refundOrder: false,
    showHideSuccessPopup: false,
  })
  history.push(location.pathname)
}

const checkedValueChange = (arr, state, id) => {
  const val =
    !state.refundItems
      .find(a => a.id === id)
      .items.every(item => !item.value) ||
    !state.refundItems
      .find(a => a.id === id)
      .items.map(a =>
        notEmptyArray(a.customisationOptions)
          ? a.customisationOptions.every(b => !b.value)
          : true
      )
      .every(a => a)
  arr.find(a => a.id === id).value = val
}

const checkedItemSubOrders = (state, id, itemIndex, e) => {
  const arr = [...state.refundItems]
  if (state.refundOrder) {
    arr.find(a => a.id === id).items[itemIndex].value = e
    checkedValueChange(arr, state, id)
  }
  return arr
}
const checkedCustomizationSubOrders = (
  state,
  id,
  itemIndex,
  customisationIndex,
  e
) => {
  const arr = [...state.refundItems]
  if (state.refundOrder) {
    arr.find(a => a.id === id).items[itemIndex].customisationOptions[
      customisationIndex
    ].value = e
    checkedValueChange(arr, state, id)
  }
  return arr
}

const handleRefundItems = (subOrders, val, solidTrue, state) => {
  const refundItemArray = []
  for (const i in subOrders) {
    const obj = {}
    const currentState = subOrders[i].state
    const currentVal = checkRefundItems(currentState, subOrders, i, val, state)
    obj['id'] = subOrders[i].id
    obj['value'] = currentVal
    obj['items'] = subOrders[i].items.map(b => {
      return suborderItems(b, solidTrue, currentVal)
    })
    refundItemArray.push(obj)
  }
  return refundItemArray
}

const handleContainerCheckboxItems = (feeDetails, val) => {
  if (feeDetails.length > 0) {
    const subOrderContainerFee = feeDetails.find(
      feeType => feeType.type === 'CONTAINER_FEE'
    ).breakdown
    return subOrderContainerFee.map(item => {
      return {
        subOrderId: item.subOrderId,
        value: val,
      }
    })
  } else return []
}
const handleDisabledContainerCheckboxItems = (
  containerCheckboxItems,
  suborderId
) => {
  return containerCheckboxItems
    ? containerCheckboxItems.find(item => item.subOrderId === suborderId).value
    : false
}

const refundPayloadHelper = (b, itemQuantity) => {
  const obj2 = {}
  obj2['id'] = b.id
  obj2['quantity'] = itemQuantity ? b.finalQuantity : 0
  obj2['finalQuantity'] = itemQuantity ? b.finalQuantity : 0
  const filterCustomisationOptions = notEmptyArray(b.customisationOptions)
    ? b.customisationOptions.filter(item => item.value)
    : []
  if (filterCustomisationOptions.length > 0) {
    obj2['customisationOptions'] = filterCustomisationOptions.map(c => {
      const obj3 = {}
      obj3['id'] = c.id
      obj3['quantity'] = c.finalQuantity
      obj3['finalQuantity'] = c.finalQuantity
      return obj3
    })
  } else if (notEmptyArray(b.customisationOptions)) {
    obj2['customisationOptions'] = b.customisationOptions.map(c => {
      const obj3 = {}
      obj3['id'] = c.id
      obj3['quantity'] = 0
      obj3['finalQuantity'] = 0

      return obj3
    })
  }
  return obj2
}

const handleRefundContainerFeeItemsPayload = (
  suborders,
  containerCheckboxItems
) => {
  const containerItemPayload = []
  const filterContainerItems = containerCheckboxItems.filter(item => item.value)
  filterContainerItems.length > 0 &&
    filterContainerItems.map(item => {
      const singleSuborder = suborders.find(a => a.id === item.subOrderId)
      const obj = {
        id: singleSuborder.id,
        fees: [],
        items: singleSuborder.items
          .filter(fee => fee.feeDetails && fee.feeDetails.length > 0)
          .map(suborderItem => ({
            id: suborderItem.id,
            fees: [
              {
                id: suborderItem.feeDetails.find(
                  feeType => feeType.type === 'CONTAINER_FEE'
                ).id,
              },
            ],
            customisationOptions:
              suborderItem.customisationOptions &&
              suborderItem.customisationOptions.length > 0
                ? suborderItem.customisationOptions
                    .filter(fee => fee.feeDetails && fee.feeDetails.length > 0)
                    .map(itemCustomisation => ({
                      id: itemCustomisation.id,
                      fees: [
                        {
                          id: itemCustomisation.feeDetails.find(
                            feeType => feeType.type === 'CONTAINER_FEE'
                          ).id,
                        },
                      ],
                    }))
                : [],
          })),
      }
      containerItemPayload.push(obj)
    })

  return containerItemPayload
}

const handleRefundContainerFeeItemsData = (
  allData,
  containerCheckboxItems,
  customPopupAppDiscount
) => {
  const containerItemsData = []
  containerCheckboxItems.map(item => {
    const singleSuborder = allData.subOrders.find(a => a.id === item.id)
    singleSuborder.containerFeeSuborderData = true
    const discountContainerFee = allData.feeDetails
      .find(a => a.type === 'CONTAINER_FEE')
      .breakdown.find(obj => obj.subOrderId === item.id)
    customPopupAppDiscount.push(
      removeDollars(discountContainerFee.formattedItemPrice) -
        removeDollars(discountContainerFee.formattedItemDiscountedPrice)
    )
    containerItemsData.push(singleSuborder)
  })

  return containerItemsData
}

const handleRefundItemsPayload = (initialState, refundItems) => {
  const refundItemPayload = []
  const filterRefundItems = refundItems.filter(item => item.value)
  for (const i in filterRefundItems) {
    const obj = {}
    obj['id'] = filterRefundItems[i].id
    const allSuborderSelected =
      filterRefundItems[i].items &&
      filterRefundItems[i].items.every(item =>
        item.value && notEmptyArray(item.customisationOptions)
          ? item.customisationOptions.every(
              customitem => customitem.value && customitem.finalQuantity !== 0
            )
          : true
      ) &&
      JSON.stringify(
        initialState.filter(a => a.id === filterRefundItems[i].id)
      ) ===
        JSON.stringify(
          refundItems.filter(a => a.id === filterRefundItems[i].id)
        )
    if (!allSuborderSelected) {
      obj['items'] =
        filterRefundItems[i].items &&
        filterRefundItems[i].items.map(b => {
          return refundPayloadHelper(b, b.value)
        })
    }
    refundItemPayload.push(obj)
  }
  return refundItemPayload
}

const tablePopupAmount = (subOrders, hideTemp = false) => {
  const amountArray = []
  for (const i in subOrders) {
    subOrders[i].discountDetails &&
      hideTemp &&
      amountArray.push(-removeDollars(subOrders[i].formattedDiscounts))
    subOrders[i].items &&
      subOrders[i].items.length > 0 &&
      !subOrders[i].containerFeeSuborderData &&
      subOrders[i].items.filter(b => {
        amountArray.push(
          b.finalQuantity *
            (removeDollars(b.formattedPrice) -
              removeDollars(b.formattedUnitDiscounts))
        )
        if (notEmptyArray(b.customisationOptions)) {
          b.customisationOptions.filter(c => {
            amountArray.push(c.finalQuantity * removeDollars(c.formattedPrice))
          })
        }
      })
  }
  return amountArray
}

const handleContainerFee = (state, props) => {
  const popupData = handleTablePopupData(state, props)
  let containerFeeArray = {}
  const refundItems = handleRefundItemsWithContainerFee(props, state)

  if (
    (state.refundOrder || state.cancelOrder) &&
    popupData[0].subOrders.length > 0 &&
    !!refundItems.filter(
      a => a.items && a.items.every(b => b.finalQuantity === 0)
    ).length &&
    !!refundItems.filter(({ items }) => {
      if (!items) return false

      return items.some(customItem =>
        customItem.customisationOptions
          ? customItem.customisationOptions.every(a => a.finalQuantity === 0)
          : []
      )
    }).length
  ) {
    containerFeeArray['formattedPrice'] = '$0.00'
    containerFeeArray['customPrice'] = 0
    containerFeeArray['breakdown'] = []
  } else if (popupData[0].subOrders.length > 0 && state.rejectOrder) {
    containerFeeArray = {
      ...popupData[0].feeDetails.find(a => a.type === 'CONTAINER_FEE'),
    }
    const breakdownData = popupData[0].feeDetails
      .find(a => a.type === 'CONTAINER_FEE')
      .breakdown.filter(
        a =>
          a.displayName ===
          popupData[0].subOrders.find(b => b.id === Number(state.radioSubOrder))
            .foodSupplier.name
      )
    if (breakdownData.length > 0) {
      containerFeeArray['formattedPrice'] =
        breakdownData[0].formattedDiscountedPrice
      containerFeeArray['breakdown'] = breakdownData
      containerFeeArray['customPrice'] = removeDollars(
        breakdownData[0].formattedDiscountedPrice
      )
    }
  } else if (popupData[0].subOrders.length > 0) {
    const breakdownData = []
    refundItems.forEach(item =>
      popupData[0].feeDetails
        .find(a => a.type === 'CONTAINER_FEE')
        .breakdown.filter(a => {
          handleBreakdownArrayData(a, popupData, item, breakdownData)
        })
    )
    const customPrice =
      breakdownData.length > 0
        ? breakdownData.reduce((acc, b) => acc + b.customPrice, 0)
        : 0
    containerFeeArray['formattedPrice'] = addDollarSign(customPrice)
    containerFeeArray['customPrice'] = customPrice
    containerFeeArray['breakdown'] = breakdownData
  }
  return containerFeeArray
}

const handleTablePopupAmount = (state, props) => {
  const popupData = handleTablePopupData(state, props)
  let price = ''
  const refundItems = handleRefundItemsPayload(
    handleRefundItems(props.data.subOrders, true, true),
    state.refundItems
  )
  const suborderItemsPrice = tablePopupAmount(popupData[0].subOrders).reduce(
    (acc, b) => acc + b,
    0
  )
  if (!!state.wholeOrder && state.refundOrder) {
    const containerFeePrice = handleContainerFee(state, props).customPrice
    const cardSurcharge = props.data.feeDetails.find(
      a => a.type === 'CARD_SURCHARGE'
    )
    const cardSurcargeFeePrice = removeDollars(
      !cardSurcharge ||
        cardSurcharge.formattedPayable === '$0.00' ||
        cardSurcharge.isWaived
        ? '$0.00'
        : cardSurcharge.formattedPrice
    )
    price = addDollarSign(
      suborderItemsPrice + containerFeePrice + cardSurcargeFeePrice
    )
  } else if (
    state.refundOrder &&
    popupData[0].subOrders.length > 0 &&
    !!refundItems.filter(
      a => a.items && a.items.every(b => b.finalQuantity === 0)
    ).length
  ) {
    price = addDollarSign(suborderItemsPrice)
  } else if (popupData[0].subOrders.length > 0) {
    let containerFeePrice = handleContainerFee(state, props).customPrice
    containerFeePrice = containerFeePrice ? containerFeePrice : 0
    price = addDollarSign(suborderItemsPrice + containerFeePrice)
  }
  const customAppDiscount =
    price &&
    popupData[0].discountDetails &&
    popupData[0].discountDetails.find(a => a.reason === 'APP_PAYMENT_DISCOUNT')
      ? {
          formattedAmount: addDollarSign(
            (removeDollars(price) *
              popupData[0].discountDetails.find(
                a => a.reason === 'APP_PAYMENT_DISCOUNT'
              ).percentage) /
              100
          ),
          percentage: popupData[0].discountDetails.find(
            a => a.reason === 'APP_PAYMENT_DISCOUNT'
          ).percentage,
        }
      : { formattedAmount: '$0.00', percentage: 10 }

  const combinedtotalPrice = addDollarSign(
    removeDollars(price) -
      removeDollars(popupData[0].customPopupAppDiscountPrice)
  )

  return {
    totalPrice: combinedtotalPrice,
    leftPrice: addDollarSign(suborderItemsPrice),
    customAppDiscount: customAppDiscount,
  }
}

const refundPopupItemData = (
  subOrders,
  i,
  temp1,
  j,
  refundItemArray,
  customPopupAppDiscount
) => {
  subOrders[i].containerFeeSuborderData = false
  if (subOrders[i].id === temp1[j].id) {
    const stallDiscountArray = []
    if (notEmptyArray(temp1[j].items)) {
      subOrders[i].items.filter(b => {
        return temp1[j].items.filter(d => {
          if (d.id === b.id) {
            return customizationRefundPopData(
              b,
              d,
              customPopupAppDiscount,
              stallDiscountArray,
              true
            )
          }
        })
      })
    }
    if (subOrders[i].discountDetails && stallDiscountArray.length > 0) {
      const formattedDiscounts = addDollarSign(
        Number(
          (stallDiscountArray.reduce((acc, b) => acc + b, 0) * 0.1).toFixed(2)
        )
      )
      subOrders[i].formattedDiscounts = formattedDiscounts
      if (
        subOrders[i].discountDetails &&
        subOrders[i].discountDetails.length > 0 &&
        subOrders[i].discountDetails.find(
          discountItem => discountItem.reason === 'K_DISCOUNT'
        )
      ) {
        subOrders[i].discountDetails.find(
          discountItem => discountItem.reason === 'K_DISCOUNT'
        ).formattedAmount = formattedDiscounts
      }
    }
    refundItemArray.push(subOrders[i])
  }
  return refundItemArray
}
const checkRefundItems = (currentState, subOrders, i, val, state) => {
  return (state &&
    state.cancelOrder &&
    (currentState === STATUSES.SUBMITTED ||
      currentState === STATUSES.PREPARING)) ||
    (state &&
      state.refundOrder &&
      currentState === STATUSES.COLLECTED &&
      !state.containerCheckboxItems.find(a => a.subOrderId === subOrders[i].id)
        .value &&
      !subOrders[i].items.every(
        item =>
          item.finalQuantity === 0 &&
          (notEmptyArray(item.customisationOptions)
            ? item.customisationOptions.every(customitem =>
                customitem.refundedQuantity
                  ? customitem.finalQuantity === 0
                  : false
              )
            : true)
      ))
    ? val
    : false
}
const suborderItems = (b, solidTrue, currentVal) => {
  const obj2 = {
    id: b.id,
    finalQuantity: solidTrue ? b.quantity : b.finalQuantity,
    quantity: solidTrue ? b.quantity : b.finalQuantity,
    value: b.finalQuantity === 0 && !solidTrue ? false : currentVal,
  }
  if (notEmptyArray(b.customisationOptions)) {
    obj2['customisationOptions'] = b.customisationOptions.map(c => {
      const FinalQuantity = c.finalQuantity ? c.finalQuantity : 0
      return {
        id: c.id,
        finalQuantity: solidTrue ? c.actualQuantity : FinalQuantity,
        value: c.finalQuantity === 0 && !solidTrue ? false : currentVal,
      }
    })
  }
  return obj2
}

const customizationItemPopupData = (
  refundItems,
  subOrderItem,
  customPopupAppDiscount,
  stallDiscountArray
) => {
  const customisationOptions =
    !!refundItems.customisationOptions &&
    refundItems.customisationOptions.find(a => a.id === subOrderItem.id)
  if (customisationOptions && customisationOptions.id === subOrderItem.id) {
    const customatizationPrice =
      customisationOptions.finalQuantity *
      removeDollars(subOrderItem.formattedPrice)
    const discountedCustomatizationPrice =
      customisationOptions.finalQuantity *
      removeDollars(subOrderItem.formattedDiscountedPrice)
    subOrderItem.finalQuantity = customisationOptions.finalQuantity
    const popupDiscountedPrice =
      customisationOptions.finalQuantity *
      removeDollars(subOrderItem.formattedUnitAppPaymentDiscount)
    customPopupAppDiscount.push(popupDiscountedPrice)
    stallDiscountArray.push(customatizationPrice)
    subOrderItem.formattedTotal =
      subOrderItem.formattedPrice === '$0.00'
        ? '$0.00'
        : addDollarSign(customatizationPrice)
    subOrderItem.formattedCustomisationTotal =
      subOrderItem.formattedPrice === '$0.00'
        ? '$0.00'
        : addDollarSign(customatizationPrice)
    if (customisationOptions.finalQuantity === '$0.00') {
      subOrderItem.formattedDiscountedPrice = '$0.00'
    }
    subOrderItem.formattedCustomisationDiscountedTotal =
      subOrderItem.formattedDiscountedPrice === '$0.00'
        ? '$0.00'
        : addDollarSign(discountedCustomatizationPrice)
    if (subOrderItem?.feeDetails?.length) {
      const discountedContainerFee = subOrderItem.feeDetails.find(
        item => item.type === 'CONTAINER_FEE'
      ).unitAmount
      const customPrice = discountedContainerFee * subOrderItem.finalQuantity
      subOrderItem.feeDetails.filter(
        item => item.type === 'CONTAINER_FEE'
      )[0].customPrice = customPrice
    } else {
      subOrderItem?.feeDetails?.push({
        customPrice: 0,
        type: 'CONTAINER_FEE',
      })
    }
  } else {
    customPopupAppDiscount.push(0)
    stallDiscountArray.push(0)
    subOrderItem.finalQuantity = 0
    subOrderItem.formattedTotal = '$0.00'
    subOrderItem.formattedCustomisationDiscountedTotal = '$0.00'
    subOrderItem?.feeDetails?.push({
      customPrice: 0,
      type: 'CONTAINER_FEE',
    })
  }
}
const handleAutoRejectionPopup = (state, createdAt, handleState) => {
  const submittedStatus = state.allSubOrdersStatus.every(
    suborderStatus =>
      suborderStatus === STATUSES.SUBMITTED ||
      suborderStatus === STATUSES.PENDING
  )
  const fiveMintData = new Date(createdAt)
  fiveMintData.setMinutes(fiveMintData.getMinutes() + 5)
  let currentDate = new Date()
  if (submittedStatus && fiveMintData.getTime() >= currentDate.getTime()) {
    setInterval(() => {
      currentDate = new Date()
      if (fiveMintData.getTime() < currentDate.getTime()) {
        handleState({
          ...state,
          cancelOrder: false,
          rejectOrder: false,
          refundOrder: false,
          showHideAutoRejectedPopup: true,
          showHideRefreshPopup: false,
          showHideElements: false,
          allSelected: false,
        })
      }
    }, 5000)
  }
}
const handleBreakdownArrayData = (
  containerItem,
  popupData,
  item,
  breakdownData
) => {
  const foodSupplierName = popupData[0].subOrders.find(b => b.id === item.id)
    ? popupData[0].subOrders.find(b => b.id === item.id).foodSupplier.name
    : ''
  if (
    containerItem.displayName === foodSupplierName &&
    !containerItem.isRefunded
  ) {
    containerItem.customPrice =
      popupData[0].subOrders
        .find(b => b.id === item.id)
        .items.reduce((acc, b) => {
          const feeItems = b.feeDetails.length
            ? b.feeDetails.find(feeItem => feeItem.type === 'CONTAINER_FEE')
            : {
                customPrice: 0,
                type: 'CONTAINER_FEE',
              }
          const addFee =
            feeItems.customPrice !== undefined
              ? feeItems.customPrice
              : feeItems.unitAmount

          const customisationFeeItems = handleCustomisationFeeItems(b)

          const totalFee = addFee + customisationFeeItems
          return acc + totalFee
        }, 0) / 100
    containerItem.formattedDiscountedPrice = addDollarSign(
      containerItem.customPrice
    )
    breakdownData.push(containerItem)
  }
}
const handleCustomisationFeeItems = b => {
  return b?.customisationOptions?.reduce((customAcc, c) => {
    const customFeeItems = c?.feeDetails?.length
      ? c.feeDetails.find(feeItem => feeItem.type === 'CONTAINER_FEE')
      : {
          customPrice: 0,
          type: 'CONTAINER_FEE',
        }
    const customisationaddFee =
      customFeeItems.customPrice !== undefined
        ? customFeeItems.customPrice
        : customFeeItems.discountedAmount
    return customAcc + customisationaddFee
  }, 0)
}

const handleDisabledItemLevel = props => {
  return (
    props.suborder.state !== STATUSES.COLLECTED ||
    handleDisabledContainerCheckboxItems(
      props.state.containerCheckboxItems,
      props.suborder.id
    )
  )
}

const handleRefundItemsWithContainerFee = (props, state) => {
  const containerFeePayload = handleRefundContainerFeeItemsPayload(
    props.data.subOrders,
    state.containerCheckboxItems
  )
  const refundItemsPayload = handleRefundItemsPayload(
    handleRefundItems(props.data.subOrders, true, true),
    state.refundItems
  )
  return [...containerFeePayload, ...refundItemsPayload]
}

export {
  tablePopupHeading,
  tablePopupSubmitButtonText,
  tablePopupTestID,
  tablePopupAmountText,
  tablePopupTotalAmountText,
  successPopupHeading,
  successPopupDescription,
  reasonPopupHeading,
  reasonPopupQuestion,
  subOrderArray,
  handleReasons,
  handleTablePopupData,
  closeTablePopup,
  handleReasonPopupBack,
  handleTablePopupSubmit,
  handleReasonPopupChange,
  handleMultipleReasonPopupChange,
  handleApplyReasons,
  handlereasonMultipleSelectValue,
  closeSuccessPopup,
  handleRefundItems,
  checkedItemSubOrders,
  checkedCustomizationSubOrders,
  handleRefundItemsPayload,
  handleRefundContainerFeeItemsPayload,
  refundPopupdata,
  customizationRefundPopData,
  refundPayloadHelper,
  handleTablePopupAmount,
  tablePopupAmount,
  refundPopupItemData,
  handleContainerFee,
  checkRefundItems,
  handleContainerCheckboxItems,
  handleAutoRejectionPopup,
  handleBreakdownArrayData,
  handleDisabledContainerCheckboxItems,
  handleMultipleCancelReason,
  handleCustomisationFeeItems,
  handleDisabledItemLevel,
  handleRefundItemsWithContainerFee,
}
