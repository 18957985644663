import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { tableProperties } from '../../../components/Rewards/Categories/utils'
import { LinkButtons, emptyState } from '../../../components/Rewards/utils'
import '../style.css'
import './categories.css'
import CategoryForm from './Form'
import { getMessage } from 'lib/translator'

const Categories = props => {
  return (
    <ListingPageWithRoutes
      testid="categories-listing"
      title={getMessage('rewards.categories.heading')}
      addHeading={getMessage('rewards.category.add')}
      editHeading={getMessage('rewards.category.edit')}
      menu={props.menu}
      className="rewards-page categories"
      api={{
        url: '/rms/categories',
        transform: response => response && response.data.list,
      }}
      headerActions={() => <LinkButtons type="category" />}
      form={{
        component: CategoryForm,
      }}
      emptyState={emptyState('category')}
      tableProperties={tableProperties}
      tableDynamic
    />
  )
}

export default Categories
