const searchCampaignsList = {
  code: 200,
  status: 'SUCCESS',
  data: {
    campaigns: [
      {
        id: 6,
        status: 'ENABLED',
        pageType: 'category',
        slug: 'free-food-steamer-testing',
        scope: 'GLOBAL',
        storeId: '',
        name:
          '$3 off $30 or $6 off  $38 Colgate Palmolive participating products',
        logo:
          'https://media.nedigital.sg/fairprice/images/03f81627-1e7e-4f88-bc31-0ca769ef9662/Colgate.png',
        description: 'Discounts off Colgate Palmolive products',
        keywords: '',
        startDate: '2021-08-21 13:36:40',
        endDate: '2022-08-25 14:35:10',
      },
      {
        id: 21,
        status: 'ENABLED',
        pageType: 'tag',
        slug: '$3-off-colgate-2',
        storeId: '8',
        name:
          '$2 off $20 or $6 off  $38 Colgate Palmolive participating products',
        logo:
          'https://media.nedigital.sg/fairprice/images/03f81627-1e7e-4f88-bc31-0ca769ef9662/Colgate.png',
        description: 'Discounts off Colgate Palmolive products',
        keywords: 'colgate,palmolive',
        startDate: '2021-08-25 13:36:40',
        endDate: null,
      },
    ],
    limit: 25,
    offset: 0,
  },
}
const categoryResp = {
  code: 200,
  status: 'SUCCESS',
  data: {
    category: [
      {
        clientId: null,
        description: null,
        id: 3666,
        image: null,
        languages: '',
        name: 'Free Food Steamer Testing',
        parentCategory: {
          clientId: '12701',
          description: null,
          id: 102,
          image: null,
          languages: '',
          name: 'Deals & Promotions',
          parentCategory: null,
          productsCount: 0,
          slug: 'deals-promotions',
          status: 'HIDDEN',
          updatedBy: '',
        },
        productsCount: 0,
        slug: 'free-food-steamer-testing',
        status: 'ENABLED',
        updatedBy: '1',
      },
    ],
    limit: 25,
    offset: 0,
  },
}
const tagResp = {
  code: 200,
  data: {
    tag: [
      {
        description: null,
        filterable: true,
        id: 321,
        image: null,
        languages: '',
        name: 'DBFreeGiftTest',
        productsCount: 0,
        slug: 'dbfreegifttest',
        type: 'PUBLIC',
        updatedBy: '1',
      },
    ],
  },
  count: 1,
  limit: 20,
  offset: 0,
  status: 'SUCCESS',
}
const brandResp = {
  code: 200,
  data: {
    brand: [
      {
        clientId: 'TEST',
        description: 'TEST',
        id: 40761,
        image: null,
        languages: '',
        logo: null,
        name: 'TEST',
        productsCount: 0,
        slug: 'test',
        status: 'ENABLED',
      },
    ],
  },
  count: 1,
  limit: 20,
  offset: 0,
  status: 'SUCCESS',
}
const storeList = [
  {
    address: 'Dummy road',
    businessHours: null,
    clientId: '002',
    hasClickCollect: false,
    hasDeliveryHub: false,
    hasPicking: false,
    hasSelfCheckout: false,
    id: 2,
    languages: '',
    latitude: 1,
    longitude: 103,
    name: 'Dummy store',
    pickingStoreId: null,
    status: 'DISABLED',
    tierSetupId: 30,
  },
]
const localStorageOrganization = {
  config: { inStoreProcessing: { packedOrderEditAllowed: true } },
  currency: { id: 106, name: 'SGD', symbol: '$' },
  defaultStoreId: 4,
  domain: 'zs-uat.fairprice.com.sg',
  extension: [
    {
      description: 'Allows you to manage brands for your items.',
      disableHelperText:
        'Disables brand management interfaces | Brand Association with products will be lost | Brand filters for products on website will be disabled | Brand specific pages would be removed from website and SEO | Brand specific analytics would not be available',
      enableHelperText:
        'Provides interfaces to manage brands | Allows products to be associated with brands | Website can use brands to filter their products | Brand specific pages can be seen on the website | Brand details will be added to SEO processing | Analytics based on sales of products belonging to a brand will be available',
      icon:
        'https://s3.ap-south-1.amazonaws.com/zopnow-uploads/multi-brand-support%403x-20180223-095255.png',
      id: 1,
      isPrivate: 0,
      isStoreConfigurable: false,
      name: 'Multi Brand Support',
      pricing: 'FREE',
      slug: 'MultiBrandSupport',
      status: 'ENABLED',
    },
    {
      description:
        'Facilitates the organization to support multiple store for their organization',
      disableHelperText:
        'Store interface will be disabled | All the store specific data will be considered from the first store, so products not present in the first store are no longer shown in website| Store specific delivery areas will not be supported  | Store specific website pages cannot be designed  | All types of analytics will show data for the single store | Store Specific Campaigns or Coupons are not supported',
      enableHelperText:
        'Provides an interface to manage Stores | Products can have different stock, mrp, discount etc. across stores | Customers can be served based on the delivery areas configured for each store| Store Specific Pages can be designed on the website | Analytics based on sales and products behaviour can be obvserved across store, which will help targeting audience location-wise | Campaigns and Coupons can be added store wise',
      icon:
        'https://s3.ap-south-1.amazonaws.com/zopnow-uploads/multi-store-support%403x-20180320-061704.png',
      id: 3,
      isPrivate: 0,
      isStoreConfigurable: false,
      name: 'Multi Store Support',
      pricing:
        'FREE for 1 store and ₹ 1500 per month for every store after that',
      slug: 'MultiStoreSupport',
      status: 'ENABLED',
    },
    {
      description:
        'Facilitates the organization to support tags for their products',
      disableHelperText:
        "Products interface will not allow you to add product tags | There won't be an interface to view product tags and corresponding products | Website will not support tag pages | Website will not support tag-based filters on product collection",
      enableHelperText:
        'Products interface will allow user to add product tags to their products | Interface to view product tags and corresponding products | Website will support tag pages with list of products | Website will now support filter on product collection based on tags',
      icon:
        'https://s3.ap-south-1.amazonaws.com/zopnow-uploads/product-tag-support%403x-20180320-063139.png',
      id: 6,
      isPrivate: 0,
      isStoreConfigurable: false,
      name: 'Product Tag Support',
      pricing: 'FREE',
      slug: 'ProductTagSupport',
      status: 'ENABLED',
    },
  ],
  httpsEnabled: 0,
  id: 2,
  isEnterprise: 1,
  logo: 'https://storage.googleapis.com/corporate-content/logo.png',
  name: 'Thor',
  status: 'ENABLED',
  theme: {
    author: null,
    description: 'This is the default theme',
    id: 1,
    image: {
      desktop:
        'https://s3.ap-south-1.amazonaws.com/zopnow-uploads/blue-20171213-070321.png',
      mobile: 'http://via.placeholder.com/414x736',
      tablet: 'http://via.placeholder.com/1024x768',
    },
    name: 'Default Theme',
    slug: 'theme-default',
  },
}

module.exports = {
  searchCampaignsList,
  storeList,
  categoryResp,
  tagResp,
  brandResp,
  localStorageOrganization,
}
