import React, { useEffect, useState } from 'react'
import { PlainInput } from 'components/Form'
import { Text, View } from '../ui'
import { AddIcon, MinusIcon } from '../ui/icon'

export const AddProduct = ({ item, onChange, quantity }) => {
  const [showControls, setShowControls] = useState()

  useEffect(() => {
    if (quantity > 0) {
      setShowControls(true)
    }
  }, [quantity])

  const onAddClick = () => {
    if (!showControls) {
      setShowControls(true)
    }
    onChange({ target: { value: quantity + 1 } })
  }

  const onBlur = (e) => {
    if (e.target.value === '0') {
      setShowControls(false)
    }
  }
  return (
    <View
      direction="column"
      style={{
        border: '1px solid #DADEE0',
        borderRadius: '4px',
        padding: '12px',
        position: 'relative',
        display: 'block',
        gap: '12px',
      }}
    >
      <Text display="block" fontWeight={700}>
        {item.name}
      </Text>
      <Text display="block">SKU: {item.sku}</Text>
      <View justifyContent="space-between">
        <Text
          display="block"
          fontWeight={600}
          style={{ margin: '12px 0 24px 0' }}
        >
          $
          {
            Number(
              quantity === 0
                ? item.price // show unit price
                : item.price * quantity
            ).toFixed(2) // show total price
          }
        </Text>

        <View
          style={{ gap: '10px' }}
          width={140}
          justifyContent={showControls ? 'space-between' : 'flex-end'}
        >
          {showControls && (
            <>
              <MinusIcon
                data-testid={`remove-${item.sku}`}
                onClick={() => onChange({ target: { value: quantity - 1 } })}
              />
              <PlainInput
                type="text"
                color="blue"
                textAlign="center"
                data-testid={`quantity-${item.sku}`}
                value={quantity}
                onChange={onChange}
                onBlur={onBlur}
                inputWrapperStyle={{ width: '56px' }}
              />
            </>
          )}

          <AddIcon data-testid={`add-${item.sku}`} onClick={onAddClick} />
        </View>
      </View>
    </View>
  )
}
