import React, { useEffect, useState } from 'react'
import { Text, View } from '../ui'
import { OrderDetailCard } from '../components/OrderDetailCard'
import { useField, useFormikContext } from 'formik'
import API from 'lib/api'
import { uniqueId } from 'lodash'
import { errorToast, getOrderSummaryFromOrderListItem } from '../helper'
/**
 *
 * @param {c,} param0
 * @returns
 */
export const ViewMultipleOrder = ({
  setSelectedOrder,
  toViewOrder,
  toHome,
}) => {
  const [field] = useField('orderPhoneNumber')
  const { setFieldValue } = useFormikContext()
  const [isFetching, setFetching] = useState(true)
  const [orders, setOrders] = useState([])

  useEffect(() => {
    function fetchCustomerId(mobileNumber) {
      new API({
        url: `/ef-customer-core/offline/profile/phone-number`,
      })
        .post({
          country_code: '65',
          phone_number: mobileNumber,
        })
        .then((resp) => {
          const custId = resp.data.id
          return new API({
            url: `/order-service/v3/search/deliveryOrders`,
          }).get({
            customerId: custId,
            type: 'RB_PREORDER',
          })
        })
        .then((orderResp) => {
          setOrders(orderResp?.deliveryOrders)
          setFetching(false)
        })
        .catch((_) => {
          errorToast('VIEW_MULTIPLE_ORDER', 'Something went wrong...')
          toHome()
          setFetching(false)
        })
    }

    fetchCustomerId(field.value)
  }, [field.value])

  if (isFetching) {
    return (
      <View direction="column" alignItems="center" justifyContent="center">
        Loading...
      </View>
    )
  } else {
    return (
      <View direction="column" alignItems="start">
        <Text fontWeight={700} fontSize={20}>
          Order summary
        </Text>
        {orders.map((item) => (
          <OrderDetailCard
            order={getOrderSummaryFromOrderListItem(item)}
            key={uniqueId()}
            onClick={() => {
              setSelectedOrder(item)
              setFieldValue('scanOrderId', item.referenceNumber)
              toViewOrder()
            }}
          />
        ))}
      </View>
    )
  }
}
