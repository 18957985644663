import React, { useState } from 'react'
import iconSearch from '../../icons/search.svg'
import PropTypes from 'prop-types'
import './AutocompleteInput.css'

const defaultFilterPredicate = (option, searchValue) => {
  return (
    option.label.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
    option.value === Number(searchValue)
  )
}

export default function AutocompleteInput({
  options,
  onSelect,
  hasError,
  value = '',
  placeholder,
  searchPredicate = defaultFilterPredicate,
}) {
  const [searchValue, setSearchValue] = useState(value)

  const handleSelection = option => {
    onSelect(option)
    setSearchValue('')
  }

  return (
    <div className={`search-wrapper ${hasError ? 'has-error' : ''}`}>
      <input
        className="search-input"
        value={searchValue}
        placeholder={placeholder}
        onChange={e => setSearchValue(e.target.value)}
      />
      <img src={iconSearch} alt="Search Icon" />
      {searchValue.trim() ? (
        <div className="search-results">
          <ul className="search-results__wrapper">
            {options.length > 0 ? (
              options
                .filter(option => searchPredicate(option, searchValue))
                .map(option => {
                  return (
                    <li
                      className="search-results__option"
                      key={option.value}
                      role="button"
                      onClick={() => handleSelection(option)}
                    >
                      {option.label}
                    </li>
                  )
                })
            ) : (
              <small style={{ cursor: 'not-allowed' }}>
                No options provided
              </small>
            )}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

AutocompleteInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      onChange: PropTypes.func,
    })
  ),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  searchPredicate: PropTypes.func,
}
