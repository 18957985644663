import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { linkButtons, readOnly } from '../../../components/LuckyDraw/utils'
import { tableProperties } from '../../../components/Rewards/Campaigns/utils'
import LuckydrawForm from './Form'
import './style.css'

const LuckyDraw = (props) => {
  return (
    <ListingPageWithRoutes
      testid="luckydraw-listing"
      title="Lucky Draw"
      addHeading="Add Lucky Draw"
      editHeading="Edit Lucky Draw"
      menu={props.menu}
      className="rewards-page"
      api={{
        url: '/duck/campaigns',
        transform: (response) => response && response.data.list,
      }}
      headerActions={!readOnly() && linkButtons}
      form={{
        component: LuckydrawForm,
      }}
      tableProperties={tableProperties('LuckyDraws')}
      viewHeading="Lucky Draw Details"
      modalClassName="resend-popup"
      tableDynamic
      filters={{
        forceShow: true,
      }}
    />
  )
}

export default LuckyDraw
