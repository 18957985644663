import React, { Component } from 'react'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { Row, Cell } from '../../../components/Table'
import Image from '../../../components/Image'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import CategoryForm from './Form'
import { getMessage } from '../../../lib/translator'
import { getSession } from '../../../lib/auth'
import { get } from '../../../lib/storage'
import { IS_STAGING } from '../../../config/app'
import { Link } from 'react-router-dom'
import CategoryFilters from './Filters'
import { Popup } from '../../../components/Popup'
import CategoryBulkUpload from './CategoryBulkUpload'

import emptyIcon from './categories-empty.svg'
import helpCard1Icon from './helpcard1.svg'
import helpCard2Icon from './helpcard2.svg'
import helpCard3Icon from './helpcard3.svg'
import tableActive from './table-active.svg'
import treeViewInactive from './tree-view-inactive.svg'

import './style.css'

const emptyState = {
  icon: emptyIcon,
  message: getMessage('category.helpItems.message'),
  submessage: getMessage('category.helpItems.submessage'),
  actions: function CategoryAction({ onAction }) {
    return (
      <button
        className="primary button"
        onClick={() => {
          onAction(TABLE_ACTIONS.ADD)
        }}
      >
        + {getMessage('category.add.text')}
      </button>
    )
  },
}

const tableProperties = (_, websiteUrl) => {
  return {
    headers: [
      getMessage('category.header.image'),
      getMessage('category.header.id'),
      getMessage('category.header.name'),
      getMessage('category.header.parentCategory'),
      getMessage('category.header.productCount'),
      getMessage('category.header.status'),
      getMessage('category.header.actions'),
    ],
    row: function CategoryRow({
      id,
      name,
      parentCategory,
      productsCount,
      status,
      image,
      onAction,
      slug,
    }) {
      return (
        <Row>
          <Cell>
            <Image size="sm" src={image} bordered />
          </Cell>
          <Cell>
            <span size="sm" className="category-id">
              {id}
            </span>
          </Cell>
          <Cell>
            <Link
              to={`/catalogue/categories/edit/${id}`}
              className="category-name"
            >
              {name}
            </Link>
          </Cell>
          <Cell className="parent-category">
            <span className="text-muted">
              <span className="category-header-mobileview">{`${getMessage(
                'category.header.parentCategory'
              )}: `}</span>
              <small className="text-category-name">
                {parentCategory ? parentCategory.name : null}
              </small>
            </span>
          </Cell>
          <Cell className="product-count text-right">
            <span className="text-muted">
              <span className="category-productcount-mobileview">{`${getMessage(
                'category.header.productCount'
              )}: `}</span>
              <small className="text-product-count">{`${productsCount}`}</small>
            </span>
          </Cell>
          <Cell className="category-status">
            <small className="text-muted">{status}</small>
          </Cell>
          <Cell className="category-actions">
            <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
              <DropDownItem
                onClick={() => {
                  onAction(TABLE_ACTIONS.DELETE, { id })
                }}
              >
                {getMessage('category.action.delete')}
              </DropDownItem>
              <DropDownItem>
                <Link
                  to={`/catalogue/products?slugCategory=${slug}`}
                  onClick={() => {
                    window.localStorage.removeItem('products-page')
                  }}
                >
                  {' '}
                  {getMessage('category.action.seeProducts')}
                </Link>
              </DropDownItem>
              <DropDownItem>
                <a href={`${websiteUrl}/category/${slug}`} target={'_blank'}>
                  {getMessage('tag.action.viewOnWebsite')}
                </a>
              </DropDownItem>
            </DropDown>
          </Cell>
        </Row>
      )
    },
  }
}
const helpItems = {
  title: getMessage('category.helpItems.title'),
  instructions: [
    {
      icon: helpCard1Icon,
      title: getMessage('category.helpItems.ppo.title'),
      description: getMessage('category.helpItems.ppo.description'),
    },
    {
      icon: helpCard2Icon,
      title: getMessage('category.helpItems.poc.title'),
      description: getMessage('category.helpItems.poc.description'),
    },
    {
      icon: helpCard3Icon,
      title: getMessage('category.helpItems.seo.title'),
      description: getMessage('category.helpItems.seo.description'),
    },
  ],
}

const readBasicConfig = () => {
  const config = window.localStorage.getItem('config-basic')
  if (config) {
    return JSON.parse(config)
  } else {
    return null
  }
}

export default class Categories extends Component {
  constructor() {
    super()
    this.state = {
      modalShown: false,
    }
    this.handleBulkModalStatus = this.handleBulkModalStatus.bind(this)
    this.domain = getSession().organization.domain
    this.config = readBasicConfig()
    this.isHttpsEnabled = JSON.parse(get('organization')).httpsEnabled
    this.isEnterprise = JSON.parse(get('organization')).isEnterprise
    this.websiteUrl = `${
      IS_STAGING
        ? 'http://'
        : this.isEnterprise
        ? 'https://'
        : this.isHttpsEnabled
        ? 'https://'
        : 'http://'
    }${(this.config && this.config.frontendUrl) || this.domain}`
  }

  handleBulkModalStatus() {
    this.setState({
      modalShown: !this.state.modalShown,
    })
  }

  render() {
    const props = this.props
    return (
      <React.Fragment>
        {this.state.modalShown && (
          <Popup
            heading={getMessage('category.bulk-upload')}
            show={this.state.modalShown}
            close={this.handleBulkModalStatus}
            className="bulk-upload"
          >
            <CategoryBulkUpload hideAddButton />
          </Popup>
        )}
        <ListingPageWithRoutes
          menu={props.menu}
          className="categories-page"
          addHeading={getMessage('category.form.add.heading')}
          editHeading={getMessage('category.form.edit.heading')}
          title={getMessage('category.heading')}
          api={{
            url: '/catalogue-service/category',
            transform: response => response.data.category,
          }}
          showLanguageDropDown
          emptyState={emptyState}
          helpItems={helpItems}
          headerActions={({ onAction }) => (
            <div className="category-header">
              <img
                src={tableActive}
                alt="table view"
                title={getMessage('category.icons.table-title')}
              />
              <Link to="/catalogue/categories/tree-view">
                <img
                  src={treeViewInactive}
                  alt="tree view"
                  title={getMessage('category.icons.tree-title')}
                />
              </Link>
              <DropDown
                icon={
                  <button className="primary button">
                    <span className="text">Manage</span>
                  </button>
                }
              >
                <DropDownItem onClick={() => onAction(TABLE_ACTIONS.ADD)}>
                  {getMessage('category.add.text')}
                </DropDownItem>
                <DropDownItem onClick={this.handleBulkModalStatus}>
                  {getMessage('category.bulk.text')}
                </DropDownItem>
              </DropDown>
            </div>
          )}
          form={{
            component: CategoryForm,
            transformResponse: (response) => response.data.category,
            transformSubmit: (formData) => {
              const result = Object.assign({}, formData)
              if (formData.parentCategory) {
                result.parentCategoryId = formData.parentCategory.id
                delete result.parentCategory
              }
              if (!formData.parentCategory) {
                delete result.parentCategoryId
              }
              return result
            },
            allowDelete: true,
          }}
          tableProperties={tableProperties(
            this.state && this.state.isMobileView,
            this.websiteUrl
          )}
          filters={{
            component: CategoryFilters,
            transformSubmit: formData => {
              const data = Object.assign({}, formData)
              if (data.category) {
                data.name = data.category.name
                delete data.category
              }
              return data
            },
          }}
        />
      </React.Fragment>
    )
  }
}
