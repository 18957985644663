import React, { useMemo } from 'react'
import { metadataKeys } from '../hook-utils'
import {
  Checkbox,
  Input,
  MultiSelect,
  Select,
} from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'

export const StoreMetadata = ({
  metaDataWithType,
  hide = {},
  noContainer,
  formik,
  preserveSequence,
  showFieldErrors,
}) => {
  const fieldLabel = getMessage('product.form.enter')
  const metadataFields = useMemo(() => {
    const fields = {}

    const listOfExceptionFields = Object.values(metadataKeys)
    for (const key in metaDataWithType) {
      if (listOfExceptionFields.includes(key)) {
        /**
         * remove fields that found in metadatKeys as its field is rendered
         * outside from StoreMetadata section
         */
        continue
      }
      fields[key] = metaDataWithType[key]
    }
    return fields
  }, [metaDataWithType])

  const uiFields = useMemo(() => {
    let _fieldArray = []
    const tempArr = []

    Object.entries(metadataFields).forEach(([key, details], i) => {
      if (details && hide[key] === undefined) {
        const isSugarLevel = key === 'Sugar Level'
        if (details.type === 'number' || isSugarLevel) {
          const data = (
            <Input
              key={`metaData-${i}`}
              type="number"
              className={'number'}
              label={key}
              readOnly={false}
              placeholder={`${fieldLabel} ${key}`}
              value={formik.values[key]}
              onChange={(value) => formik.setFieldValue(key, value)}
            />
          )
          preserveSequence ? _fieldArray.push(data) : _fieldArray.unshift(data)
        } else if (details.type === 'string') {
          const data = (
            <Input
              key={`metaData-${i}`}
              type="text"
              className={'string'}
              label={key}
              readOnly={false}
              placeholder={`${fieldLabel} ${key}`}
              value={formik.values[key]}
              onChange={(value) => formik.setFieldValue(key, value)}
              error={formik.errors[key]}
              showErrors={showFieldErrors(key)}
            />
          )
          preserveSequence ? _fieldArray.push(data) : _fieldArray.unshift(data)
        } else if (details.type === 'boolean') {
          const data = (
            <div className="field field-checkbox" key={`metadata-${i}`}>
              <Checkbox
                inlineLabel={key}
                name={`metaData-${i}`}
                readOnly={false}
                placeholder={`${fieldLabel} ${key}`}
                value={formik.values[key]}
                onChange={(value) => formik.setFieldValue(key, value)}
                controlled
              />
            </div>
          )
          preserveSequence ? _fieldArray.push(data) : _fieldArray.unshift(data)
        } else if (
          details.type === 'enum' ||
          details.type === 'multiValued Enum' ||
          details.type === 'multiValued enum'
        ) {
          const options =
            details.typeMeta && details.typeMeta.allowedValue
              ? details.typeMeta.allowedValue
              : []

          const Component = details.type === 'enum' ? Select : MultiSelect
          const data = (
            <Component
              label={key}
              name={`metaData-${i}`}
              readOnly={false}
              placeholder={`${getMessage('product.form.enter')} ${key}`}
              key={`metaData-${i}`}
              className="enum"
              options={options.map((opt) => {
                return {
                  text: opt,
                  value: opt,
                }
              })}
              value={formik.values[key]}
              onChange={(value) => formik.setFieldValue(key, value)}
            />
          )
          preserveSequence ? _fieldArray.push(data) : _fieldArray.unshift(data)
        }
      }
    })
    _fieldArray = _fieldArray.concat(tempArr)
    return _fieldArray
  }, [metaDataWithType, preserveSequence, formik])

  return !noContainer ? (
    <div>{uiFields}</div>
  ) : (
    <React.Fragment>{uiFields}</React.Fragment>
  )
}
