import React from 'react'
import {
  BaseForm,
  Input,
  Select,
} from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import './style.css'

export default class ProductFiltersForm extends BaseForm {
  componentDidMount() {
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form className="product-filter-form">
        <div className="form-fields">
          <Input
            label={getMessage('product.filters.name.heading')}
            placeholder={getMessage('product.filters.name.placeholder')}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['search[name]'],
            })}
          />
          <Input
            label={getMessage('File name')}
            placeholder={getMessage('Enter File name')}
            name="File name"
            {...this.generateStateMappers({
              stateKeys: ['search[file_name]'],
            })}
          />
           <Input
            label={getMessage('User id')}
            placeholder={getMessage('Enter User ID')}
            name="User id"
            {...this.generateStateMappers({
              stateKeys: ['search[user_id]'],
            })}
          />
          <Select
            name="SortBy"
            label={getMessage(`Sort By`)}
            placeholder={getMessage(`Sort By`)}
            options={
              ['name:asc', 
              'name:desc',
              'id:asc',
              'id:desc',
              'execution_time:asc',
              'execution_time:desc'

            ]}
            {...this.generateStateMappers({
              stateKeys: ['sort[]'],
            })}
          />
          <Select
            name="Filter by status"
            label={getMessage(`Filter by status`)}
            placeholder={getMessage(`select status`)}
            options={
              ['SUBMITTED', 
              'PENDING',
              'PROCESSING',
              'PARTIALLY_SUCCEEDED',
              'SUCCEEDED',
              'SUSPENDED',
              'FAILED',
              'ABORTED'

            ]}
            {...this.generateStateMappers({
              stateKeys: ['filters[status]'],
            })}
          />
          <Select
            name="Type"
            label={getMessage(`Filter by type`)}
            placeholder={getMessage(`select type`)}
            options={
              ['SKU-PRODUCT-METADATA-UPDATE-JOB', 
              'SKU-STOCK-OVERRIDE-UPDATE-JOB'
            ]}
            {...this.generateStateMappers({
              stateKeys: ['filters[type]'],
            })}
          />
        </div>
        <SubmitButton>{getMessage('product.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('product.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
