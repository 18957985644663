import FlexBox from 'components.new/box/FlexBox'
import { Input, Select } from 'components/Form'
import { Popup } from 'components/Popup'
import API from 'lib/api'
import { getMessage } from 'lib/translator'
import React, { useState } from 'react'
import sortBy from 'lodash/sortBy'

export const CloneDesignationForm = ({
  designations,
  toggleModal,
  isOpen,
  refreshPage,
}) => {
  const options = sortBy(designations, (d) => d.name).map((designation) => ({
    text: designation.name,
    value: designation.id,
  }))

  const [selectedId, setSelectedId] = useState(null)
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)

  const onSubmit = async (e) => {
    e.preventDefault()

    const selectedDesignation = designations.find(
      (designation) => designation.id === selectedId
    )

    if (!selectedDesignation) {
      return
    }

    setErrorMsg(null)
    setIsLoading(true)

    const cloneApi = new API({ url: '/account-service/designation' })

    try {
      await cloneApi.post({
        name,
        roleIds: selectedDesignation.roles.map((r) => r.id),
        timingType: selectedDesignation.timingType,
        designationLevel: null,
        manager: selectedDesignation.manager?.id,
      })
      refreshPage()
      toggleModal()
    } catch (error) {
      console.log(error)
      setErrorMsg('Failed to clone designation')
    }

    setIsLoading(false)
  }

  return (
    <>
      <Popup className="cloneDesignation" show={isOpen} close={toggleModal}>
        <form onChange={() => setErrorMsg(null)} onSubmit={onSubmit}>
          <Select
            label={getMessage('shifts.designation')}
            placeholder={getMessage('shifts.designation.placeholder')}
            name="designation"
            options={options}
            value={selectedId}
            onChange={(id) => setSelectedId(Number(id))}
            required
          />
          <Input
            label={getMessage('designations.form.name')}
            placeholder={getMessage('designations.clone.placeholder.newName')}
            name="name"
            value={name}
            onChange={setName}
            required
          />
          <FlexBox justifyContent="center" flexDirection="column">
            {errorMsg && (
              <div data-testid="errorMsg" className="red">
                {errorMsg}
              </div>
            )}
            <input
              disabled={isLoading}
              className="primary"
              type="submit"
              data-testid="submit-clone"
            />
          </FlexBox>
        </form>
      </Popup>
      <button className="button clone-btn" onClick={toggleModal}>
        {getMessage('designations.clone')}
      </button>
    </>
  )
}
