import React from 'react'
import { withRouter } from 'react-router-dom'
import Table from './Table'
import Details from './Details'

const OrdersRouter = props => {
  const { router } = props
  const details = { ...props, url: '/qc' }
  return router.match.params.reference ? (
    <Details {...details} />
  ) : (
    <Table {...details} />
  )
}

export default withRouter(({ match, ...props }) => (
  <OrdersRouter router={{ match }} {...props} />
))
