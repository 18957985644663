import _ from 'lodash';
import { getMessage } from 'lib/translator'

const getAddressTypeFromSalesOrder = (order) => {
  let addressType = ''
  if (order?.type) {
    switch (order.type) {
      case 'SALES_ORDER_TYPE_BUSINESS_TO_BUSINESS': addressType = 'ADDRESS_TYPE_BIZ'
        break
      case 'SALES_ORDER_TYPE_ONLINE_GROCERY': addressType = 'ADDRESS_TYPE_PRS'
        break
      default: break;
    }
  }
  return addressType
}
const filterAddressByOrderType = ({salesOrder, deliveryOrder }) => {
  const clonedOrder = _.cloneDeep(deliveryOrder)
  if (clonedOrder.customer && clonedOrder.customer.addresses) {
    const addresses = clonedOrder.customer.addresses;
    const addressType = getAddressTypeFromSalesOrder(salesOrder);
    if (addresses.length === 0 || addressType === '') {
      return clonedOrder
    }
    const filteredAddress = addresses.filter(address => address.addressType === addressType)
    clonedOrder.customer.addresses = filteredAddress
  }
  return clonedOrder
}

function getTitleBasedOnOrderStatusAndType(orderStatus, orderType) {
  let title = getMessage('order.details.location.delivery.notSpecified.title');
  if (orderStatus === 'DELIVERY_ORDER_STATUS_COMPLETED') {
    if (orderType === 'SALES_ORDER_TYPE_ONLINE_GROCERY') {
      title = getMessage('order.details.location.delivery.completed.title')
    } else if (orderType === 'SALES_ORDER_TYPE_BUSINESS_TO_BUSINESS') {
      title = getMessage('order.details.location.b2b.completed.title')
    } else if (orderType === 'SALES_ORDER_TYPE_SCAN_AND_GO') {
      title = getMessage('order.details.location.offline.completed.title')
    } else if (orderType === 'SALES_ORDER_TYPE_RB_PREORDER') {
      title = getMessage('order.details.location.rbPreorder')
    }
  } else {
    if (orderType === 'SALES_ORDER_TYPE_ONLINE_GROCERY') {
      title = getMessage('order.details.location.delivery.pending.title')
    } else if (orderType === 'SALES_ORDER_TYPE_BUSINESS_TO_BUSINESS') {
      title = getMessage('order.details.location.b2b.pending.title')
    } else if (orderType === 'SALES_ORDER_TYPE_SCAN_AND_GO') {
      title = getMessage('order.details.location.offline.pending.title')
    } else if (orderType === 'SALES_ORDER_TYPE_RB_PREORDER') {
      title = getMessage('order.details.location.rbPreorder');
    }
  }
  return title;
}

function getStartTimeAndEndTimeFromInterval(interval) {
  if (!interval || interval.split('/').length !== 2 || interval.split('T').length !== 3 ) {
    return null;
  }
  const startTime = interval.split('/')[0]
                            .split('T')[1]
                            .substring(0, 8)

  const endTime = interval.split('/')[1]
                          .split('T')[1]
                          .substring(0, 8)
  return { startTime, endTime };
}

function getAmendmentItemsFromPacklists(oldPacklists) {
  return oldPacklists.map(packlist => {
    // sort orderAmendments by id, so we know which is the first amendment and which is the second amendment
    const sortedAmendmentItems = _.sortBy(packlist.orderAmendments, ['id']).map(x => x.id);
    const items = packlist.items.map(item => {
      // check if item is a sub item because orderamendmentId is in originalItem, not item
      const amendmentItemIndex = item.originalItem
        ? sortedAmendmentItems.findIndex(x => x === item.originalItem.orderAmendmentId)
        : sortedAmendmentItems.findIndex(x => x === item.orderAmendmentId)
      return {
        ...item,
        amendmentItemIndex
      }
    })
    return {
      ...packlist,
      items: items
    }
  })
}

function getAmendmentItemsFromDeliveryOrder(deliveryOrder) {
  // sort orderAmendments by id, so we know which is the first amendment and which is the second amendment
  const sortedAmendmentItems = _.sortBy(deliveryOrder.orderAmendments, ['id']);
  const amendments = sortedAmendmentItems.map(amendment => {
    return amendment.orderAmendmentDetail.orderItems.flatMap(x => x.orderItemId);
  })
  const itemsWithAmendments = deliveryOrder.items.map(item => {
    return {
      ...item,
      amendmentItemIndex: amendments.findIndex(x => x.includes(item.item.id))
    };
  })
  return {
    ...deliveryOrder,
    items: itemsWithAmendments
  };
}

function isOperationPage(url) {
  return url.startsWith('/operations')
}

function isOrderPendingOrCancelled(status) {
  return status === 'DELIVERY_ORDER_STATUS_PENDING' || status === 'DELIVERY_ORDER_STATUS_CANCELLED' || status === 'DELIVERY_ORDER_STATUS_PROCESSING';
}

// null => return "-"
// 0    => return 0
// num  => return num
function getQuantity(quantity, decimalPlace) {
  if (quantity === null) {
    return '-';
  }
  return Number(quantity).toFixed(decimalPlace);
}

// in SaleOrder response, soldByWeight is 0 (false) or 1(true). In DeliveryOrder response, soldByWeight is true or false.
function getActualSoldByWeightValue(soldByWeight) {
  if (typeof soldByWeight === 'number') {
      return soldByWeight === 1;
  }
  return soldByWeight
}

function isBeforePackedOrCancelled(status) {
  const statuses = [
    'DELIVERY_ORDER_STATUS_PENDING',
    'DELIVERY_ORDER_STATUS_PROCESSING',
    'DELIVERY_ORDER_STATUS_PACKING',
    'DELIVERY_ORDER_STATUS_CANCELLED'
  ]
  return statuses.includes(status);
}

export {
  filterAddressByOrderType,
  getTitleBasedOnOrderStatusAndType,
  getStartTimeAndEndTimeFromInterval,
  getAmendmentItemsFromPacklists,
  getAmendmentItemsFromDeliveryOrder,
  isOrderPendingOrCancelled,
  isOperationPage,
  getQuantity,
  getActualSoldByWeightValue,
  isBeforePackedOrCancelled
}

