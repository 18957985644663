import React, { Component } from 'react'
import { ProductSearch } from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'
import ItemTable from './ItemTable'
import CommonForm from './CommonForm'
import OfferRadioForm from './OfferRadioForm'

class BXATP extends Component {
  constructor(props) {
    super(props)
    this.submitHandler = this.submitHandler.bind(this)
    this.validationHandler = this.validationHandler.bind(this)
    this.selectOnEnter = this.selectOnEnter.bind(this)
  }

  selectOnEnter(e) {
    e && e.preventDefault()
  }
  validationHandler(parent) {
    const validations = JSON.parse(JSON.stringify(parent.state.validations))
    const formData = JSON.parse(JSON.stringify(parent.state.values))
    const comboDiscount = formData.itemDiscountType === 'COMBO_DISCOUNT'
    const entityType = formData.entityType.toLowerCase()
    const types = validations[entityType] || []
    if (comboDiscount) {
      types.forEach((validation) => {
        delete validation.t
        delete validation.v
      })
    } else {
      delete validations.rule
    }
    parent.setState({
      validations,
    })
  }

  submitHandler(data) {
    const formData = Object.assign({}, data)
    const comboDiscount = formData.itemDiscountType === 'COMBO_DISCOUNT'
    const entityType = formData.entityType.toLowerCase()
    const buy = {}
    data.offerType = data.type
    formData[entityType].forEach((item) => {
      const res = { q: Number(item.q) }
      if (!comboDiscount) {
        res.t = item.t
        res.v = Number(item.v)
      }
      buy[item.id] = res
    })
    const total = comboDiscount && formData.rule.total
    formData.rule = {
      buy: buy,
      // entity: {
      //   type: formData.entityType === 'PRODUCT' ? 'VARIANT' : formData.entityType,
      //   id: formData.entityType === 'PRODUCT' ? null : 0
      // },
      limit: formData.rule ? Number(formData.rule.limit) : null,
    }
    if (comboDiscount) {
      total.v = Number(total.v)
      formData.rule.total = total
    }
    delete formData.product
    delete formData.brand
    delete formData.category
    return formData
  }
  render() {
    const {
      entityType: entityTypeProp,
      discountTypes,
      itemDiscountTypes,
      _this,
      hasOfferStarted,
    } = this.props
    const { values } = _this.state
    const { itemDiscountType } = _this.state.values
    let entityType = entityTypeProp
    entityType = this.props.entityTypeMap[entityType]
    const comboDiscount = itemDiscountType === 'COMBO_DISCOUNT'
    const items = values[entityType]
    const itemsLength = items ? items.length : 0
    return (
      <div className="offers-form">
        <div className="offer-fields">
          <OfferRadioForm
            _this={_this}
            itemDiscountTypes={itemDiscountTypes}
            hasOfferStarted={hasOfferStarted}
          />

          {comboDiscount && (
            <CommonForm
              discountTypes={discountTypes}
              _this={_this}
              hasOfferStarted={hasOfferStarted}
              values={values}
            />
          )}
        </div>
        {entityType === 'product' && (
          <ProductSearch
            required
            className="product-searchable-max"
            key={`item-searchable-pdt`}
            name={`item-searchable-pdt`}
            label={getMessage(`offer.${entityType}`)}
            placeholder={getMessage(`offer.${entityType}.placeholder`)}
            onChange={_this.addItem}
            readOnly={hasOfferStarted}
            selectOnEnter={this.selectOnEnter}
          />
        )}
        <div>
          {itemsLength ? (
            <ItemTable
              comboDiscount={comboDiscount}
              entityType={entityType}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
              hasOfferStarted={hasOfferStarted}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

export default BXATP
