import React, { Fragment } from 'react'
import { getMessage } from 'lib/translator'
import { Input, SingleDatePicker, TimePicker, Radio } from '../../Form'

const UsageDate = ({ _this, isFixed, isUseByDateNull }) => {
  return (
    <Fragment>
      <div className={isFixed ? 'row mt' : `hideInputField`}>
        <div className="col-6">
          <SingleDatePicker
            enableToday
            label={getMessage(`rewards.catalogue.usedByDate`)}
            placeholder={getMessage(`rewards.date`)}
            {..._this.generateStateMappers({
              stateKeys: ['usedByDate'],
              loseEmphasisOnFill: true,
            })}
            data-testid="usedByDate"
          />
        </div>
        <div className="col-6 usedByTime">
          <TimePicker
            enableToday
            placeholder={getMessage(`rewards.time`)}
            {..._this.generateStateMappers({
              stateKeys: ['usedByTime'],
              loseEmphasisOnFill: true,
            })}
            data-testid="usedByTime"
          />
        </div>
      </div>
      {isUseByDateNull && isFixed && (
        <div className="row">
          <div className="col-12 error">This field is required</div>
        </div>
      )}
    </Fragment>
  )
}
const UsageValidity = ({ _this, usageType, isUseByDateNull }) => {
  const isFixed = usageType !== 'Relative'
  const isRelative = usageType === 'Relative'

  return (
    <Fragment>
      <div className="row mt">
        <div className="col-12">
          <h3>{getMessage('rewards.catalogue.usageValidity')}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Radio
            options={[
              {
                text: getMessage('rewards.catalogue.fixed'),
                value: getMessage('rewards.catalogue.fixed'),
              },
              {
                text: getMessage('rewards.catalogue.relative'),
                value: getMessage('rewards.catalogue.relative'),
              },
            ]}
            {..._this.generateStateMappers({
              stateKeys: ['usageType'],
              loseEmphasisOnFill: true,
              defaultValue: 'Fixed',
            })}
          />
        </div>
      </div>
      <UsageDate
        isFixed={isFixed}
        _this={_this}
        isUseByDateNull={isUseByDateNull}
      />
      <div className={isRelative ? 'row mt' : `hideInputField`}>
        <div className="col-12">
          <Input
            label={getMessage('rewards.catalogue.useWithinDays')}
            placeholder={getMessage('rewards.catalogue.useWithinDays')}
            type="number"
            {..._this.generateStateMappers({
              stateKeys: ['useWithinDays'],
            })}
            {...(isRelative && { required: 'required' })}
            disabled={isFixed}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default UsageValidity
