import React from 'react'

import { BaseForm } from 'components/Form'
import Loader from 'components/Loader'
import { FormButtons, ErrorScreen } from 'components/Rewards/utils'
import { createMapData } from 'components/Rewards/Devices/utils'
import Details from 'components/Rewards/Devices/Details'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'

import {
  handleApis,
  handleError,
  fetchDetailsById,
  fetchAllMerchants,
} from 'components/Rewards/services'

class DeviceForm extends BaseForm {
  async componentDidMount() {
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        loading: true,
      },
    }))

    try {
      const [merchantsResponse, deviceResponse] = await Promise.all([
        fetchAllMerchants(),
        this.props.method === 'edit'
          ? fetchDetailsById(this.props.value.id, 'devices')
          : undefined,
      ])

      const merchantList = (merchantsResponse?.data?.list ?? []).map(
        (merchant) => ({
          value: merchant.clsMerchantID,
          text: `${merchant.clsMerchantID} - ${merchant.name}`,
        })
      )

      this.setState((prevState) => ({
        values: {
          ...prevState.values,
          loading: false,
          merchantList,
          ...(this.props.method === 'edit' && {
            ...deviceResponse,
            status: deviceResponse.status === 'ACTIVE' ? 1 : 0,
          }),
        },
      }))
    } catch (error) {
      handleError(error, this)
    }
  }

  async _submitHandler(e) {
    e?.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })

    if (this.isFormValid()) {
      const mapData = createMapData(this.state.values)

      const id = this.props.value?.id
      await handleApis(mapData, '/admin/devices', this, id)
    }
  }

  render() {
    const { Form } = this.components
    const { loading, merchantList, error } = { ...this.state.values }

    return (
      <div className="rewards container device">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <Details merchantList={merchantList} _this={this} />
            <br />
            {this.props.method === 'edit' && (
              <DisplayCreatorEditorInfo {...this.state.values} />
            )}
            <FormButtons buttons={this.buttons} type="device" />
            {error && <ErrorScreen error={error} />}
          </Form>
        )}
      </div>
    )
  }
}
export default DeviceForm
