import { Popup } from 'components/Popup'
import { FE_GATEWAY_API_URL } from 'config/app'
import { useFormikContext } from 'formik'
import API from 'lib/api'
import React, { useEffect, useState } from 'react'
import { Button, Text, View } from '../ui'
import { PreorderInvoice } from './PreorderInvoice'
import {
  errorToast,
  generateQRFromPLU,
  htmlToPreorderPdf,
  xUserHeaders,
} from '../helper'

const removeFocAndUnchecked = ({ serverCart, localCart }) =>
  serverCart.reduce((acc, item) => {
    if (!item.isFree && localCart[item.product.clientItemId]) {
      acc.push(item)
    }
    return acc
  }, [])

const removeUncheckedItems = ({ serverCart }) =>
  // server cart contains unchecked item and FOC (checked)
  serverCart.reduce((acc, item) => {
    if (item.isChecked === true) {
      acc.push(item)
    }
    return acc
  }, [])

export const ConfirmOrderModal = ({
  isOpen,
  toggleModal,
  onDone,
  storeName,
  storeAddress,
  storePhone,
  cart,
  setSelectedCampaign,
}) => {
  const { values } = useFormikContext()
  const [qrImages, setQRImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [createdOrder, setCreatedOrder] = useState(null)
  const checkedItemsWithoutFoc = removeFocAndUnchecked({
    serverCart: cart,
    localCart: values.cart,
  })
  const checkedItemsWithFoc = removeUncheckedItems({
    serverCart: cart,
  })

  const generateQR = async (orderId) => {
    const allPLU = checkedItemsWithoutFoc.map(
      ({ product, q }) => `${q}x${product.clientItemId}`
    )
    const result = await generateQRFromPLU(orderId, allPLU)
    setQRImages(result)
  }

  const createOrder = () => {
    const createOrderPayload = {
      addressId: 0,
      campaignId: Number(values.selectedCampaign), // must be number, not string
      metaData: {
        staffId: values.staffId,
      },
      cart: checkedItemsWithoutFoc.map(({ id, q }) => ({ id, q })),
      customerId: values.userData.userId,
      isCheckerEnabled: true,
      isDirectFulfilmentEnabled: true,
      isOutOfStockEnabled: true,
      isSellerCheckerEnabled: true,
      isSellerTimeSlotEnabled: true,
      orderType: 'RB_PREORDER',
      paymentMethod: 'OFFLINE',
      sellerTimeSlots: [
        {
          preferredDate: values.pickupDate,
          preferredSlotId: values.slotId,
        },
      ],
      storeId: Number(values.storeId),
      type: 'RB_PREORDER',
    }
    setLoading(true)

    new API({ url: `${FE_GATEWAY_API_URL}/order` })
      .post(
        createOrderPayload,
        xUserHeaders({
          userId: values.userData.userId,
          userUid: values.userData.userUid,
        })
      )
      .then((res) => {
        const orderId = res.data?.order?.referenceNumber
        generateQR(orderId)
        setCreatedOrder(res.data?.order)
      })
      .catch((e) => {
        errorToast('CREATE_ORDER_FAILURE', e.message)
        toggleModal()
        setLoading(false)
      })
  }

  useEffect(() => {
    if (qrImages.length) {
      preparePrint()
    }
  }, [qrImages])

  const preparePrint = () => {
    const input = document.getElementById('preorderInvoice')
    const totalItemHeight = cart.reduce((total, item) => {
      const lineSpan = Math.ceil(item.product.name.length / 16) // 1 line can have 16 characters
      return total + lineSpan * 18 // 1 line is 18px tall
    }, 0)

    htmlToPreorderPdf({
      input,
      orderId: createdOrder.referenceNumber,
      height: 1340 + totalItemHeight + qrImages.length * 300,
    }).then(() => {
      setQRImages([])
      /**
       * Commented out this - if receipt printing succes, dont
       * close the modal unless we wanna show `SUCCESS` dialog or something.
       */
      // toggleModal()
      // onDone()
      setCreatedOrder(null)
      /**
       * As per the latest fixed, this code wont clear cart, instead
       * will be calling onDone() to clear cart instead
       */
      setSelectedCampaign(null)
      /**
       * Immediately complete the flow and go back to
       * Home page + clear Cart. This will prevent user from getting stuck at the
       * confirm order or the confirm modal for too long.
       */
      onDone()
    })
  }

  return (
    <>
      <Popup
        show={isOpen}
        close={() => !loading && toggleModal()}
        heading="Confirm order?"
        className="confirmPreorderPopup"
      >
        <View
          direction="column"
          alignItems="start"
          style={{ fontFamily: 'Roboto' }}
        >
          Review order details and select ‘Print QR code’ to confirm order.
          <Text fontWeight={700} margin="16px 0 0">
            Order summary:
          </Text>
          {Object.entries(values.products).reduce(
            (t, [sku, q]) => t + (values.cart[sku] ? q : 0),
            0
          )}{' '}
          items
          <Text fontWeight={700} margin="16px 0 0">
            Customer details:
          </Text>
          <Text>Name: {values.customerName}</Text>
          <Text>Mobile: {values.customerPhone}</Text>
          <Text fontWeight={700} margin="16px 0 0">
            Pick-up location:
          </Text>
          <View direction="column" alignItems="start">
            <Text>{storeName}</Text>
            <Text textAlign="left">{storeAddress}</Text>
          </View>
          <Text fontWeight={700} margin="16px 0 0">
            Pick-up time slot:
          </Text>
          <Text>
            {values.pickupDate}, {values.pickupTime}
          </Text>
          <View gap={8} margin="16px 0 0">
            <Button
              fontSize={12}
              variant="cancel"
              onClick={toggleModal}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button fontSize={12} onClick={createOrder} loading={loading}>
              Print QR code
            </Button>
          </View>
        </View>
      </Popup>
      {qrImages.length > 0 ? (
        <PreorderInvoice
          qrSources={qrImages}
          order={createdOrder}
          cartItems={checkedItemsWithFoc}
          otherCartDetails={{
            customerName: values.customerName,
            customerPhone: values.customerPhone,
            pickupDate: values.pickupDate,
            pickupTime: values.pickupTime,
            staffId: values.staffId,
            storePhone,
            storeName,
            storeAddress,
          }}
        />
      ) : null}
    </>
  )
}
