import React from 'react'
import { Input } from '../../../components/Form'
import SpecificProductDetails from '../../../pages/settings/Themes/components/SpecificProductDetails'
import { getMessage } from '../../../lib/translator'
import './style.scss'

export default function VoucherSwimLane() {
  return (
    <>
      <div className="voucher-container">
        <div className="form-section">
          <Input
            label={getMessage('themes.title')}
            placeholder={getMessage('themes.layout.homepage.voucher.title')}
            onChange={() => {}}
          />
          <SpecificProductDetails
            getState={() => {}}
            updateState={() => {}}
            stores={[]}
            isVoucher={true}
          />
        </div>
      </div>
    </>
  )
}
