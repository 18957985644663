import { Input, VALIDATION_TYPES } from 'components/Form'
import { getMessage } from 'lib/translator'
import React from 'react'

const ModuleMetadata = ({ values, generateStateMappers }) => {
  return (
    <div className="metadata-section">
      <div className="id-name-section">
        {values.id && (
          <Input
            type="text"
            label={getMessage('app.layout.module.id')}
            value={values.id}
            readOnly
          />
        )}
        <Input
          type="text"
          label={getMessage('app.layout.module.name')}
          placeholder={getMessage('app.layout.module.name.placeholder')}
          {...generateStateMappers({
            stateKeys: ['mName'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
      </div>
      <div className="title-text-section">
        {['title', 'text'].map((item) => (
          <Input
            key={item}
            type="text"
            label={getMessage(`app.layout.module.${item}`)}
            placeholder={getMessage(`app.layout.module.${item}.placeholder`)}
            {...generateStateMappers({
              stateKeys: ['mConfig', 'default', item],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        ))}
      </div>
      <div className="deeplink-section">
        <Input
          type="text"
          label={getMessage('app.layout.module.deeplink')}
          placeholder={getMessage('app.layout.module.deeplink.placeholder')}
          required={values.mConfig.default.cta?.text}
          {...generateStateMappers({
            stateKeys: ['mConfig', 'default', 'cta', 'deeplink'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
          validationStrings={{
            valueMissing: getMessage(
              'app.layout.missingField.errorMsg.cta-deeplink'
            ),
          }}
        />
        <Input
          type="text"
          label={getMessage('app.layout.module.text')}
          placeholder={getMessage('app.layout.module.cta.text.placeholder')}
          required={values.mConfig.default.cta?.deeplink}
          {...generateStateMappers({
            stateKeys: ['mConfig', 'default', 'cta', 'text'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
          validationStrings={{
            valueMissing: getMessage(
              'app.layout.missingField.errorMsg.cta-text'
            ),
          }}
        />
      </div>
    </div>
  )
}

export default ModuleMetadata
