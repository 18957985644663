import React from 'react'
import { Link } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import { Cell } from 'components/Table'
import Row from 'components/Table/Row'
import { getPrintableTime } from 'lib/datetime'
import { returnFunction } from 'pages/customer-support/EGiftingOrders/List/tableProperties'

export default function tableProperties() {
  return {
    headers: [
      getMessage('eVoucher.filter.entityName'),
      getMessage('eVoucher.entities.UEN'),
      getMessage('eVoucher.entities.date.created'),
    ],
    row: ({ id, name, uen, createTime }) => {
      return (
        <Row>
          <Cell>
            <Link
              className="order-number"
              to={`/customer-support/gifting-entities/view/${id}`}
            >
              {name || ''}
            </Link>
          </Cell>
          <Cell>
            <div className="name">
              <span>{uen || ''}</span>
            </div>
          </Cell>
          <Cell>
            {createTime ? getPrintableTime(createTime).split(', ')[0] : null}
          </Cell>
        </Row>
      )
    },
  }
}

export const transform = (response) => {
  const transactions = response.data.entities || []

  returnFunction(response)

  return transactions
}
