import React from 'react'
import { BaseForm, Input, VALIDATION_TYPES } from '../../../../components/Form'
import RichEditor from '../../../../components/RichEditor'
import { cloneMutables } from '../../../../lib/stateManagement/'
import { getMessage } from '../../../../lib/translator'
import { getSession } from '../../../../lib/auth'

import './style.css'

export default class BlogForm extends BaseForm {
  constructor(props) {
    super(props)
    this.domain = getSession().organization.domain
  }
  onSubmit(values) {
    this.setState(
      prevState => Object.assign({}, prevState, { values }),
      this._submitHandler
    )
  }
  render() {
    const { CancelButton } = this.buttons
    const { Form } = this.components
    return (
      <div>
        <Form className="blog-form">
          <Input
            className="title-input"
            label={getMessage('marketing.blog.form.title.heading')}
            placeholder={getMessage('marketing.blog.form.title.placeholder')}
            name="title"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ['title'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage(
                'marketing.blog.form.title.requiredMessage'
              ),
            }}
            siblings={{
              after: (
                <RichEditor
                  placeholder={getMessage(
                    'marketing.blog.form.content.placeholder'
                  )}
                  {...this.generateStateMappers({
                    stateKeys: ['content'],
                  })}
                />
              ),
            }}
          />
          <div className="form-buttons">
            <button
              type="button"
              className="primary button"
              onClick={e => {
                e.preventDefault()
                const updates = {
                  isDraft: false,
                }
                if (!this.state.values.publishTime) {
                  const now = new Date()
                  updates.publishTime = `${now.getFullYear()}-${String(
                    now.getMonth() + 1
                  ).padStart(2, '0')}-${String(now.getDate()).padStart(
                    2,
                    '0'
                  )} ${now.toTimeString().split(' ')[0]}`
                }
                this.onSubmit(
                  Object.assign({}, cloneMutables(this.state.values), updates)
                )
              }}
            >
              {getMessage('marketing.blog.form.submitText')}
            </button>
            {/* <button type='button' className='button' onClick={e => {
              e.preventDefault()
              this.onSubmit(Object.assign({}, cloneMutables(this.state.values), {
                isDraft: true
              }))
            }}>
              Save as draft
            </button> */}
            <CancelButton>
              {getMessage('marketing.blog.form.cancelText')}
            </CancelButton>
          </div>
        </Form>
      </div>
    )
  }
}
