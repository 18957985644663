import React, { Component } from 'react'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import API from '../../../../lib/api'
import StoreSelector, {
  getDefaultStore,
  makestoreDependentComponent,
} from '../../../../containers/StoreSelector'
import Loader from '../../../../components/Loader'
import EmptyState from '../../../../components/EmptyState'
import { getMessage } from '../../../../lib/translator'
import { hasDuplicates } from '../../../../lib/commonlyused'
import AddRackForm from '../AddRackForm'

import '../style.css'
import { Popup, Dialog } from '../../../../components/Popup'

import emptyIcon from './rackEmpty.svg'

const SortableItem = SortableElement(({ value, rackIndex, onRemove }) => (
  <div className="grid-styling-item">
    {value}
    <button onClick={() => onRemove(rackIndex)} className="remove-button">
      ×
    </button>
  </div>
))
const SortableList = SortableContainer(({ items, onRemove }) => {
  return (
    <div className="grid-styling">
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          rackIndex={index}
          value={value}
          onRemove={onRemove}
        />
      ))}
    </div>
  )
})

class InterfaceContainer extends Component {
  constructor(props) {
    super(props)
    this.state = Object.assign(
      {
        items: null,
        addRackPopup: false,
        submitting: false,
        showSuccessDialog: false,
        showRackDeleteDialog: false,
        clearSequenceState: false,
      },
      getDefaultStore(this.props.stores)
    )

    this.tempItems = []
    this.getRacks = this.getRacks.bind(this)
    this.changeStore = this.changeStore.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.onRemove = this.onRemove.bind(this)
    this.handleRackClose = this.handleRackClose.bind(this)
    this.addRack = this.addRack.bind(this)
    this.saveRacks = this.saveRacks.bind(this)
    this.closeDialogs = this.closeDialogs.bind(this)
    this.showRackDeleteDialog = this.showRackDeleteDialog.bind(this)
    this.hideRackDeleteDialog = this.hideRackDeleteDialog.bind(this)
    this.clearSequence = this.clearSequence.bind(this)
    this.undoClear = this.undoClear.bind(this)
  }

  getRacks() {
    this.api = new API({ url: '/picking-service/v3/pickingSequence' })
    this.setState({
      loading: true,
    })
    const params = {}
    if (this.state.storeId) {
      params.storeId = this.state.storeId
    }
    this.api.get(params).then(
      (response) => {
        let sequence =
          response.pickingSequence && response.pickingSequence.length
            ? response.pickingSequence[0].sequence
            : []
        sequence = sequence || []
        const empty = !(
          response.pickingSequence &&
          response.pickingSequence.length > 0
        )
        const id =
          response.pickingSequence && response.pickingSequence[0]
            ? response.pickingSequence[0].id
            : null
        this.setState({
          empty: empty,
          items: sequence,
          id: id,
          loading: false,
        })
      },
      (error) => {
        if (error.code === 5 && error.message === 'no picking sequence configured') {
          this.setState({ items: []})
        }
        if (error.message === 'cancelled') {
          return
        }
        if (error.code === 401 || error.code === 403) {
          throw error
        }
        this.setState({
          error: error.message,
        })
      }
    )
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }
  clearSequence() {
    const currState = JSON.parse(JSON.stringify(this.state))
    const currItems = currState.items
    this.tempItems = currItems
    this.setState({ items: [], clearSequenceState: true })
  }
  undoClear() {
    this.setState({ items: this.tempItems, clearSequenceState: false }, () => {
      this.tempItems = []
    })
  }
  saveRacks() {
    this.setState({
      submitting: true,
      formError: null,
    })
    if (
      this.state.items &&
      Array.isArray(this.state.items) &&
      this.state.items.length &&
      hasDuplicates(this.state.items)
    ) {
      this.setState({
        formError: 'Duplicate entries are not allowed',
        submitting: false,
      })
      return null
    }
    if (
      this.state.empty ||
      this.state.id === undefined ||
      this.state.id === null
    ) {
      const pickingSequenceApi = new API({ url: '/picking-service/v3/pickingSequence' })
      const urlParams = {}
      urlParams['sequence'] = this.state.items
      urlParams['storeId'] = parseInt(this.state.storeId, 10)
      return pickingSequenceApi.post(urlParams).then(
        () => {
          this.setState(
            {
              showSuccessDialog: true,
              submitting: false,
              clearSequenceState: false,
            },
            () => {
              this.tempItems = []
            }
          )
        },
        (error) => {
          this.setState(
            {
              formError: error.message,
              submitting: false,
              clearSequenceState: false,
            },
            () => {
              this.tempItems = []
            }
          )
        }
      )
    }
    const id = this.state.id
    const pickingSequencePutApi = new API({ url: `/picking-service/v3/pickingSequence/${id}` })
    const params = {}
    params['sequence'] = this.state.items
    return pickingSequencePutApi.put(params).then(
      () => {
        this.setState(
          {
            showSuccessDialog: true,
            submitting: false,
            clearSequenceState: false,
          },
          () => {
            this.tempItems = []
          }
        )
      },
      (error) => {
        this.setState(
          {
            formError: error.message,
            submitting: false,
            clearSequenceState: false,
          },
          () => {
            this.tempItems = []
          }
        )
      }
    )
  }

  componentDidMount() {
    this.getRacks()
  }

  changeStore(storeId) {
    this.setState({ storeId }, this.getRacks)
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    })
  }

  onRemove() {
    const index = this.state.index
    const items = this.state.items
    items.splice(index, 1)

    this.setState(
      {
        items: items,
        showRackDeleteDialog: false,
      },
      () => this.saveRacks()
    )
  }

  handleRackClose() {
    this.setState({
      addRackPopup: false,
    })
  }

  addRack(formData) {
    let items = this.state.items
    const newItem = formData.rack.trim().split(',').filter(Boolean)
    items = items.concat(newItem)
    this.setState(
      {
        items: items,
        addRackPopup: false,
        clearSequenceState: false,
      },
      () => {
        this.tempItems = []
      }
    )
  }

  closeDialogs() {
    this.setState(
      {
        showSuccessDialog: false,
      },
      () => this.getRacks()
    )
  }

  showRackDeleteDialog(index) {
    this.setState({
      index: index,
      showRackDeleteDialog: true,
    })
  }

  hideRackDeleteDialog() {
    this.setState({
      showRackDeleteDialog: false,
    })
  }
  render() {
    if (!this.state.items) {
      return <Loader />
    }
    const { items, formError } = this.state
    return (
      <div>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage('rackManagement.save.success.title')}
          close={this.closeDialogs}
          closeText={getMessage('rackManagement.save.success.closeText')}
        />
        <Dialog
          show={this.state.showRackDeleteDialog}
          title={getMessage('rackManagement.rack.delete.title')}
          information={getMessage('rackManagement.rack.delete.message')}
          onOk={() => this.onRemove()}
          close={this.hideRackDeleteDialog}
          closeText={getMessage('rackManagement.rack.delete.cancelText')}
          okText={getMessage('rackManagement.rack.delete.confirmText')}
        />
        <div className="header-container">
          <h1 className="title">{getMessage('rackmanagement.heading')}</h1>
          <div className="header-actions-wrapper">
            <StoreSelector
              value={this.state.storeId}
              onChange={this.changeStore}
              stores={this.props.stores}
            />
            <button
              onClick={() => this.setState({ addRackPopup: true })}
              className="add-more-rack button primary"
            >
              +{' '}
              <span className="text">
                {getMessage('rackManagement.add.more.racks')}
              </span>
            </button>
          </div>
        </div>
        {!items.length && !this.state.clearSequenceState && (
          <EmptyState
            icon={emptyIcon}
            message={getMessage('rackmanagement.empty.message')}
          />
        )}
        {formError && <div className="form-error">{formError}</div>}
        <div className="grid-box">
          <SortableList
            items={items}
            onSortEnd={this.onSortEnd}
            axis="xy"
            onRemove={this.showRackDeleteDialog}
            transitionDuration={800}
            pressDelay={100}
          />
        </div>
        {(items.length > 0 || this.tempItems.length > 0) && (
          <div className="form-buttons">
            <button
              className="primary button"
              disabled={this.state.submitting}
              onClick={() => {
                this.saveRacks()
              }}
            >
              {this.state.submitting
                ? '...'
                : getMessage('rackManagement.save.text')}
            </button>
            {this.state.clearSequenceState && (
              <button type="button" className="button" onClick={this.undoClear}>
                {getMessage('rackManagement.cancel.text')}
              </button>
            )}
            {!this.state.clearSequenceState && (
              <button
                type="button"
                onClick={() => this.clearSequence()}
                className="button"
              >
                <span className="text">
                  {getMessage('rackManagement.clear.sequence')}
                </span>
              </button>
            )}
          </div>
        )}
        <div>
          <Popup
            heading={getMessage('rackManagement.popup.heading')}
            show={this.state.addRackPopup}
            close={this.handleRackClose}
          >
            <AddRackForm onSubmit={this.addRack} items={items} />
          </Popup>
        </div>
      </div>
    )
  }
}

const InterfaceWithStores = makestoreDependentComponent(InterfaceContainer)

export default class RackContainer extends Component {
  render() {
    return <InterfaceWithStores goBack={this.props.goBack} />
  }
}
