import React from 'react'
import {
  BaseForm,
  Select,
  VALIDATION_TYPES,
  Input,
} from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'

export default class UserShiftFilter extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    const { stores, multiStoreEnabled, designations } = this.props.options
    return (
      <Form>
        <div className="form-fields">
          <Select
            label={getMessage('shifts.designation')}
            placeholder={getMessage('shifts.designation.placeholder')}
            name="designation"
            options={(designations || []).map(desig => {
              return {
                text: desig.name,
                value: desig.id,
              }
            })}
            {...this.generateStateMappers({
              stateKeys: ['designationId'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage('shifts.employee')}
            placeholder={getMessage('shifts.employee.placeholder')}
            name="employee"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className="form-fields">
          <Input
            label={getMessage('shifts.employee.enterEmail')}
            placeholder={getMessage('shifts.employee.searchByEmail')}
            name="email"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['email'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            label={getMessage('shifts.employee.enterPhone')}
            placeholder={getMessage('shifts.employee.searchByPhone')}
            name="phone"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['phone'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>

        {multiStoreEnabled && stores && (
          <div className="form-fields">
            <Select
              label={getMessage('shifts.stores')}
              placeholder={getMessage('shifts.stores.placeholder')}
              name="store"
              options={(stores || []).map(store => {
                return {
                  text: store.name,
                  value: store.id,
                }
              })}
              {...this.generateStateMappers({
                stateKeys: ['storeId'],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        )}
        <SubmitButton>{getMessage('shifts.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('shifts.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
