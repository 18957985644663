import { ACTION_TRAIL_STREAM_NAME } from 'config/app'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { isActionTrailEnabled } from 'lib/actiontrail/actiontrail'
import { ACTION_TRAIL_HEADER } from 'lib/actiontrail/utils'
import API from 'lib/api'
import { getSession } from 'lib/auth'
import moment from 'moment'
import React, { useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import PublicPage from '../../../containers/PublicPage/index'

export default function MissingPage() {
  useEffect(() => {
    const actionTrailAPI = new API({
      url: '/actions-trail',
    })

    const sendCustomHeader = isActionTrailEnabled(
      SPLIT_FEATURES.ACTION_TRAIL_CUSTOM_HEADER
    )

    const { user } = getSession()

    const actionPayload = {
      id: uuid(),
      systemId: 1, // <system ID> for action-trail system
      userId: user.id,
      actionType: 'MISSING_PAGE',
      endpoint: window.location.href,
      email: user.emails[0].email,
      timestamp: moment().toISOString(),
      method: 'GET',
      actionPayload: {},
    }

    const apiPayload = {
      DeliveryStreamName: ACTION_TRAIL_STREAM_NAME,
      Records: [
        {
          Data: Buffer.from(JSON.stringify(actionPayload)).toString('base64'),
        },
      ],
    }

    actionTrailAPI
      .post(apiPayload, sendCustomHeader && ACTION_TRAIL_HEADER)
      .catch(console.log)
  }, [])

  return (
    <PublicPage>
      <div className="box">
        <h1 className="heading">Missing Page</h1>
        <h2 className="subheading">Are you lost?</h2>
      </div>
    </PublicPage>
  )
}
