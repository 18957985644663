import React from 'react'

import { Radio, PaymentSearch } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

import { PAYMENT_SEARCH_CATEGORIES } from '../../../../pages/customer-support/constants'
const { CUSTOMER_EMAIL, CUSTOMER_PHONE } = PAYMENT_SEARCH_CATEGORIES

const FormQuickSearch = ({ BaseForm, data = {} }) => {
  const { values } = data
  const { paymentSearchSelect, searchCategory } = values

  const showQuickSearchPaymentStatus =
    paymentSearchSelect === CUSTOMER_EMAIL ||
    paymentSearchSelect === CUSTOMER_PHONE

  return (
    <React.Fragment>
      <PaymentSearch value={searchCategory} BaseForm={BaseForm} />
      {showQuickSearchPaymentStatus && (
        <Radio
          className="payment-status"
          label={getMessage('customerSupport.payments.paymentStatus.heading')}
          name="paymentStatus"
          data-testid="paymentStatus"
          options={[
            {
              text: getMessage(
                'customerSupport.payments.paymentStatus.label.all'
              ),
              value: 'ALL',
            },
            {
              text: getMessage(
                'customerSupport.payments.paymentStatus.label.refunded'
              ),
              value: 'REFUNDED',
            },
            {
              text: getMessage(
                'customerSupport.payments.paymentStatus.label.failed'
              ),
              value: 'FAILURE',
            },
            {
              text: getMessage(
                'customerSupport.payments.paymentStatus.label.completed'
              ),
              value: 'COMPLETED',
            },
          ]}
          {...BaseForm.generateStateMappers({
            stateKeys: ['paymentStatus'],
            defaultValue: 'ALL',
          })}
        />
      )}
    </React.Fragment>
  )
}

FormQuickSearch.propTypes = {}

export default FormQuickSearch
