import { ACTION_TRAIL_CUSTOM_HEADER } from 'config/app'
import { SplitContext } from 'containers/SplitContext'
import { useContext } from 'react'

export const isAtRoute = (url) => {
  return window.location.pathname.includes(url)
}

export const isRouteMatch = (...urls) => {
  return urls.some((url) => window.location.pathname.includes(url))
}

/**
 * Commons hooks for action trail to check for split feature flag
 */
export const useActionTrailEnabled = (flagName) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return splits?.[flagName]?.treatment === 'on'
}


/**
 *
 */
export const ACTION_TRAIL_HEADER = {
  'x-action-trail-agent': ACTION_TRAIL_CUSTOM_HEADER
}
