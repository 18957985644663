import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import {
  isNoResult,
  isFilterApplied,
} from '../../../components/ListingPage/utils'
import Loader from '../../../components/Loader'
import { Popup as Modal, Dialog } from '../../../components/Popup'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import { get } from '../../../lib/storage'
import { getDefaultStore } from '../../../containers/StoreSelector'
import { isExtensionEnabled } from '../../../lib/auth'
import { arrayMove } from 'react-sortable-hoc'

import '../../../containers/ListingPage/style.css'
import './style.css'

import DraggableListingPageView from './DraggableListingPageView'

export const TABLE_ACTIONS = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  DELETE: 'DELETE',
  FILTER: 'FILTER',
  REFRESH: 'REFRESH',
  UPDATE: 'UPDATE',
  SET_API_PARAMS: 'SET_API_PARAMS',
}

const NullComponent = () => null

class DraggableListingPage extends React.Component {
  constructor(props) {
    super(props)
    const searchParams = Object.assign(
      {},
      ...this.props.router.location.search
        .slice(1)
        .split('&')
        .filter(Boolean)
        .map((keystr) => {
          keystr = keystr.split('=')
          return {
            [keystr[0]]: decodeURIComponent(keystr[1]),
          }
        })
    )
    if (Object.keys(searchParams).length > 0) {
      window.localStorage.setItem(this.props.className, 1)
    }
    let apiParams = {}
    if (props.api) {
      if (props.api.params) {
        apiParams = props.api.params
      }
      if (props.storeDependent && isExtensionEnabled('MultiStoreSupport')) {
        apiParams.storeId = get('store') || getDefaultStore().storeId
      }
    }
    this.state = {
      data: {
        items: null,
        paging: null,
        filters: searchParams || {}, // Corresponds to the filters applied to the API
        viewItems: null,
      },
      filters: {
        // Corresponds to the state of the 'filters' view
        shown: Object.keys(searchParams).length > 0,
      },
      apiParams: apiParams,
      loaders: {
        data: false,
      },
      form: {
        shown: false,
        rowIndex: -1, // The row being edited
        data: null,
      },
      deleteDialog: {
        shown: false,
        data: {},
      },
      errorDialog: {
        shown: false,
        message: '',
        title: '',
      },
      saveBanner: false,
      submitting: false,
      firstLoadDone: false, // Indicate that we have gotten results from API at least once
      isGridView: false, // For Enabling Grid View
    }

    this.primaryKey = this.props.primaryKey || 'id'

    if (props.api && props.api.url) {
      this.api = new API({ url: props.api.url })
    }

    /* Default hooks */
    // Method to extract data from the API call made
    this._transformResponse = (response) => response
    this._transformSubmit = (form) => form
    /* Hook overrides */
    if (props.api && props.api.transform) {
      this._transformResponse = props.api.transform
    }
    if (props.form && props.form.transformSubmit) {
      this._transformSubmit = props.form.transformSubmit
    }
    if (props.updateView) {
      this.updateView = this.updateView.bind(this)
    }

    this._showDataLoader = this._showDataLoader.bind(this)
    this._hideDataLoader = this._hideDataLoader.bind(this)
    this.performAction = this.performAction.bind(this)
    this._showForm = this._showForm.bind(this)
    this._hideForm = this._hideForm.bind(this)
    this._showFilters = this._showFilters.bind(this)
    this._hideFilters = this._hideFilters.bind(this)
    this._toggleFilters = this._toggleFilters.bind(this)
    this.createResource = this.createResource.bind(this)
    this.modifyResource = this.modifyResource.bind(this)
    this.getResource = this.getResource.bind(this)
    this.applyFilters = this.applyFilters.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.throwError = this.throwError.bind(this)
    this._showDeleteDialog = this._showDeleteDialog.bind(this)
    this._hideDeleteDialog = this._hideDeleteDialog.bind(this)
    this._showErrorDialog = this._showErrorDialog.bind(this)
    this._hideErrorDialog = this._hideErrorDialog.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.onDraggableSubmit = this.onDraggableSubmit.bind(this)
    this.onAddBanner = this.onAddBanner.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.closeDragDialog = this.closeDragDialog.bind(this)
    this.handleChangeView = this.handleChangeView.bind(this)
  }
  // Some data lookup methods
  _getRow(data) {
    const result = {
      data: null,
      index: -1,
    }
    const { items } = this.state.data
    for (let i = 0, len = items.length; i < len; i++) {
      const item = items[i]
      let matched = true
      for (const key in data) {
        if (!(key in item) || item[key] !== data[key]) {
          matched = false
          break
        }
      }
      if (matched) {
        result.data = item
        result.index = i
        break
      }
    }
    return result
  }
  // Some utility methods to manage states
  _showDataLoader() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.loaders.data = true
      return newState
    })
  }
  _hideDataLoader() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.loaders.data = false
      return newState
    })
  }
  _showForm(rowIndex, data) {
    // If rowIndex is specified, then a particular row is being edited
    if (this.props.form && this.props.form.component) {
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        newState.form.shown = true
        newState.form.rowIndex = isFinite(rowIndex) ? rowIndex : -1
        if (data) {
          newState.form.data = data
        }
        return newState
      })
    }
  }
  _hideForm() {
    // Clear the form and hide it
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.form.shown = false
      newState.form.rowIndex = -1
      newState.form.data = null
      return newState
    })
  }
  _showFilters() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.filters.shown = true
      return newState
    })
  }
  _hideFilters() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.filters.shown = false
      return newState
    })
  }
  _toggleFilters() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.filters.shown = !prevState.filters.shown
      return newState
    })
  }
  _hideDeleteDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.deleteDialog.shown = false
      return newState
    })
  }
  _showDeleteDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.deleteDialog.shown = true
      return newState
    })
  }
  _hideErrorDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.errorDialog.shown = false
      newState.errorDialog.message = ''
      newState.errorDialog.title = ''
      return newState
    })
  }
  _showErrorDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.errorDialog.shown = true
      return newState
    })
  }
  // Methods to manage data via API
  /**
   * Makes API call to create the resource
   * @param {object} data - Data to be created in DB
   * @return {Oject} newState - updated state [Immutated]
   */
  createResource(dataToSubmit) {
    const api = new API({
      url: this.props.api.url,
    })
    const meta = { itemCount: this.state.data.items?.length || 0 }
    const params =
      this.props.form && this.props.form.overwriteWithApiParams === false
        ? Object.assign(
            {},
            this.state.apiParams,
            this._transformSubmit(dataToSubmit, meta)
          )
        : Object.assign(
            {},
            this._transformSubmit(dataToSubmit),
            this.state.apiParams
          )
    return api.post(params).then(
      (response) => {
        let data = this._transformResponse(response)
        this.setState({ pageType: data && data.pageType, id: data && data.id })
        /* Show new entry at top of the table */
        if (this.props.form && this.props.form.filterBeforeAdding) {
          const filterBeforeAdding = this.props.form.filterBeforeAdding
          data = filterBeforeAdding(data, this)
        }
        if (data) {
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            const updatedItems = newState.data.items
            this.props.addNewItemToLast
              ? updatedItems.push(data)
              : updatedItems.unshift(data)
            newState.data.items = updatedItems
            const updatedPaging = newState.data.paging
            updatedPaging.count = (updatedPaging.count || 0) + 1
            newState.data.paging = updatedPaging
            return newState
          })
        }
        this.viewData = this.props.updateView
          ? this.state.data.viewItems && this.state.data.viewItems.length > 0
            ? this.state.data.viewItems
            : this.state.data.items
          : this.state.data.items
        if (this.viewData) {
          this.onAddBanner(data?.pageType)
        }
        this._hideForm()
      },
      (error) => {
        this.throwError(error)
      }
    )
  }
  modifyResource(data) {
    // Make an API call to update the resource
    const api = new API({
      url: `${this.props.api.url}/${data[this.primaryKey]}`,
    })
    const params =
      this.props.form && this.props.form.overwriteWithApiParams === false
        ? Object.assign({}, this.state.apiParams, this._transformSubmit(data))
        : Object.assign({}, this._transformSubmit(data), this.state.apiParams)
    return api.put(params).then(
      (response) => {
        /* Update the table row */
        if (this.props.form && this.props.form.filterBeforeAdding) {
          const filterBeforeAdding = this.props.form.filterBeforeAdding
          data = filterBeforeAdding(data, this)
        }
        const updatedRow = this._transformResponse(response)
        const row = this._getRow({
          [this.primaryKey]: updatedRow[this.primaryKey],
        })
        if (data) {
          if (row.index > -1) {
            this.setState((prevState) => {
              const newState = Object.assign({}, prevState)
              const updatedItems = newState.data.items
              updatedItems.splice(row.index, 1, updatedRow)
              newState.data.items = updatedItems
              return newState
            })
          }
        } else {
          if (row.index > -1) {
            this.setState((prevState) => {
              const newState = Object.assign({}, prevState)
              const updatedItems = newState.data.items
              updatedItems.splice(row.index, 1)
              newState.data.items = updatedItems
              const updatedPaging = newState.data.paging
              updatedPaging.count = updatedPaging.count - 1
              newState.data.paging = updatedPaging
              return newState
            })
          }
        }
        this.props.api &&
          this.props.api.afterSubmit &&
          this.props.api.afterSubmit(response)
        this.viewData = this.props.updateView
          ? this.state.data.viewItems && this.state.data.viewItems.length > 0
            ? this.state.data.viewItems
            : this.state.data.items
          : this.state.data.items
        this._hideForm()
      },
      (error) => {
        this.throwError(error)
      }
    )
  }

  /**
   * Makes API call to delete the resource
   * @param {object} data - Data to be deleted from DB
   * @return {Oject} newState - updated state [Immutated]
   */
  deleteResource(data) {
    const api = new API({
      url: `${this.props.api.url}/${data[this.primaryKey]}`,
    })
    const params = Object.assign({}, data, this.state.apiParams)
    api
      .delete(params)
      .then(
        () => {
          /* Update the table row */
          const row = this._getRow({
            [this.primaryKey]: data[this.primaryKey],
          })
          if (row.index > -1) {
            this.setState((prevState) => {
              const newState = Object.assign({}, prevState)
              const updatedItems = newState.data.items
              updatedItems.splice(row.index, 1)
              newState.data.items = updatedItems
              const updatedPaging = newState.data.paging
              updatedPaging.count = updatedPaging.count - 1
              newState.data.paging = updatedPaging
              return newState
            })
          }
          this.viewData = this.props.updateView
            ? this.state.data.viewItems && this.state.data.viewItems.length > 0
              ? this.state.data.viewItems
              : this.state.data.items
            : this.state.data.items
        },
        (error) => {
          this.throwError(error, getMessage('errorDialog.delete.error.title'))
        }
      )
      .then(this._hideForm)
  }

  getResource(data) {
    const api = new API({
      url: `${this.props.api.url}/${data[this.primaryKey]}`,
    })
    const params = Object.assign(
      {},
      this._transformSubmit(data),
      this.state.apiParams
    )
    api.get(params).then(
      (response) => {
        /* Update the table row */
        const updatedRow = this._transformResponse(response)
        const row = this._getRow({
          [this.primaryKey]: updatedRow[this.primaryKey],
        })
        if (row.index > -1) {
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            const updatedItems = newState.data.items
            updatedItems.splice(row.index, 1, updatedRow)
            newState.data.items = updatedItems
            return newState
          })
        }
        this.props.api &&
          this.props.api.afterSubmit &&
          this.props.api.afterSubmit(response)

        this._hideForm()
      },
      (error) => {
        this.throwError(error)
      }
    )
  }
  fetchTableData(params = {}) {
    if (!this.api) {
      return null
    }
    this._showDataLoader()
    if (Object.keys(params).length > 0) {
      params = Object.assign({}, this.state.apiParams, params)
    } else {
      params = Object.assign({}, params, this.state.apiParams)
    }
    return this.api
      .get(params)
      .then((response) => {
        this.setState((prevState) => {
          const newState = Object.assign({}, prevState)
          newState.data.items = this._transformResponse(response)
          if (this.props.updateView) {
            newState.data.viewItems = this._transformResponse(response)
          }

          newState.data.paging = (({ count, limit, offset }) => ({
            count,
            limit,
            offset,
          }))(response.data)
          newState.firstLoadDone = true
          this.viewData = this.props.updateView
            ? this.state.data.viewItems && this.state.data.viewItems.length > 0
              ? this.state.data.viewItems
              : this.state.data.items
            : this.state.data.items
          return newState
        })
      })
      .then(this._hideDataLoader)
      .catch((error) => {
        if (error.code === 404) {
          this.setState(
            (prevState) => {
              const newState = Object.assign({}, prevState)
              newState.data.items = []
              return newState
            },
            () => {
              this._hideDataLoader()
            }
          )
        } else if (error.code === 401) {
          throw error
        }
      })
  }

  applyFilters(filters, page = 1) {
    // To add default pageType filter for FPOB-4235
    if (!('pageType' in filters)) {
      filters = {
        ...filters,
        pageType: 'Home',
      }
    }
    let defaultFilters = filters
    if (Object.keys(filters).length < 1) {
      defaultFilters = {
        ...filters,
        pageType: 'Home',
      }
    }

    this.setState({ saveBanner: false })
    window.localStorage.setItem(this.props.className, page) // necessary for pagination
    var transformedFilters = defaultFilters
    if (this.props.filters && this.props.filters.transformSubmit) {
      transformedFilters = this.props.filters.transformSubmit(defaultFilters)
    }
    const params = Object.assign(
      {
        offset: 0, // Reset page to 1 if applying filters
        page: page,
      },
      transformedFilters
    )
    this.setState(
      (prevState) => {
        const newState = Object.assign({}, prevState)
        newState.data.filters = defaultFilters
        return newState
      },
      () => {
        this.fetchTableData(params)
      }
    )
  }
  clearFilters() {
    window.localStorage.setItem(this.props.className, 1)
    this.setState(
      (prevState) => {
        const newState = Object.assign({}, prevState)
        newState.data.filters = {}
        return newState
      },
      () => {
        this.fetchTableData({
          pageType: 'Home',
        })
      }
    )
  }
  confirmDelete() {
    this.deleteResource(this.state.deleteDialog.data)
    this._hideDeleteDialog()
  }
  throwError(error, title) {
    if (error.code === 400) {
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        newState.errorDialog.message =
          error.message.split(':').slice(1).join(':') || error.message // if string does not have a colon
        newState.errorDialog.title = title || ''
        return newState
      }, this._showErrorDialog())
    } else {
      throw error
    }
  }
  performAction(action, data, updates, deleteRow = false) {
    // action: (string) Action to perform
    // data: The data needed to search a specific a row
    // updates: The data to update the row with
    // deleteRow: delete the row from view without making api call
    if (action in TABLE_ACTIONS) {
      switch (action) {
        case TABLE_ACTIONS.ADD:
          if (this.props.form) {
            this._showForm(-1, data)
          }
          break
        case TABLE_ACTIONS.EDIT:
          if (this.props.form) {
            // Lookup row number for given data and send that row number
            const row = this._getRow(data)
            if (row.index >= 0) {
              if (updates) {
                // If there are updates, just update row instead of showing form
                this.setState((prevState) => {
                  const newState = Object.assign({}, prevState)
                  const updatedItems = newState.data.items
                  updatedItems.splice(
                    row.index,
                    1,
                    Object.assign({}, row.data, updates)
                  )
                  newState.data.items = updatedItems
                  return newState
                })
              } else {
                this._showForm(row.index)
              }
            }
          }
          break
        case TABLE_ACTIONS.UPDATE: {
          const row = this._getRow(data)
          if (row.index >= 0 && updates) {
            const params = Object.assign({}, data, updates)
            return this.modifyResource(params)
          }
          break
        }
        case TABLE_ACTIONS.DELETE: {
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            newState.deleteDialog.data = data
            return newState
          })
          this._showDeleteDialog()
          break
        }
        case TABLE_ACTIONS.FILTER:
          // To the user, search & filters are mutually exclusive, but internally,
          // both are implemented as filters
          this.applyFilters(data)
          break
        case TABLE_ACTIONS.REFRESH: {
          let params = {}
          if (data && deleteRow) {
            // If data and delete row are not empty, simply delete the row from the view
            const row = this._getRow(data)
            this.setState((prevState) => {
              const newState = Object.assign({}, prevState)
              const updatedItems = newState.data.items.slice()
              updatedItems.splice(row.index, 1)
              newState.data.items = updatedItems
              return newState
            })
            return null
          } else if (data && updates) {
            // If 'data' and 'updates' are not empty, simply update the view by pushing 'updates' into the state
            const row = this._getRow(data)
            this.setState((prevState) => {
              const newState = Object.assign({}, prevState)
              const updatedItems = newState.data.items
              updatedItems.splice(
                row.index,
                1,
                Object.assign({}, row.data, updates)
              )
              newState.data.items = updatedItems
              return newState
            })
          } else if (data) {
            // TODO: If 'data' is not empty, fetch details for that row and update the data for that row
            this.getResource(data)
            return null
          } else if (this.state.data && this.state.data.filters) {
            // if filters are applied on the table then refresh the page with filters
            params = Object.assign(
              {
                offset: 0, // Reset page to 1 if applying filters
              },
              this.state.data.filters
            )
          }
          this.fetchTableData(params)
          break
        }
        case TABLE_ACTIONS.SET_API_PARAMS:
          this.setState((prevState) => {
            return {
              apiParams: Object.assign({}, prevState.apiParams, data),
            }
          }, this.fetchTableData)
          break
        default:
          break
      }
    } else if (
      this.props.tableProperties.actions &&
      action in this.props.tableProperties.actions
    ) {
      // Perform custom action
    }
    return null
  }
  componentDidMount() {
    this.fetchTableData({
      ...this.state.data.filters,
      page: window.localStorage.getItem(this.props.className),
      pageType: 'HOME',
    })
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  updateView(data, key) {
    if (this.props.updateView) {
      const newView = this.props.updateView(data, key)
      const newData = Object.assign({}, this.state.data)
      newData.viewItems = newView
      this.setState({
        data: newData,
      })
    }
  }

  closeDragDialog() {
    this.setState({ isDrag: false })
  }

  onSortEnd({ oldIndex, newIndex }) {
    const beforeSwap = JSON.parse(JSON.stringify(this.viewData))
    this.viewData =
      this.viewData && arrayMove(this.viewData, oldIndex, newIndex)
    const afterSwap = JSON.parse(JSON.stringify(this.viewData))

    beforeSwap.map((ele, index) => {
      if (ele.id !== afterSwap[index].id) {
        this.setState({ saveBanner: true })
      }
      return null
    })

    const updatedArray = afterSwap.map((banner, index) =>
      Object.assign({ ...banner }, { sequence: beforeSwap[index].sequence })
    )

    this.viewData = updatedArray
    this.forceUpdate()
  }

  processSequence = () => {
    const info = this.viewData.map((banner, index) => {
      return {
        SequenceID: index + 1,
        BannerID: banner.id,
      }
    })
    const api = new API({
      url: '/banner-service/banners',
    })

    api.put({ Banners: info })
  }

  onAddBanner(pageType) {
    if (pageType !== 'HOME') {
      this.processSequence()
    }
  }

  onDraggableSubmit() {
    this.setState({ isDrag: true })
    this.processSequence()
  }

  handleMove(event) {
    if (window.innerHeight - event.clientY < 50) {
      window.scrollBy(0, 10)
    } else if (event.clientY < 50) {
      window.scrollBy(0, -10)
    }
  }

  handleChangeView() {
    this.setState({ isGridView: !this.state.isGridView })
  }

  render() {
    // TODO: Add support for replacing default messages with localized strings
    const { props } = this
    const data = this.state.data

    const filtersApplied = isFilterApplied({ data })

    let emptyStateShown = false

    const { filters } = this.state.data

    const isPageTypeHome =
      !filters.pageType || filters.pageType.toLowerCase() === 'home'
    const isPageTypeOmniHome =
      !filters.pageType || filters.pageType === 'OMNI_HOMEPAGE'

    if (isNoResult({ api: this.api, data })) {
      if (!filtersApplied) {
        emptyStateShown = true
      }
    }

    let HeaderActions = this.props.headerActions || NullComponent
    const Form = props.form
      ? props.form.component || NullComponent
      : NullComponent
    const Filters = props.filters
      ? props.filters.component || NullComponent
      : NullComponent
    const allowDelete = props.form && props.form.allowDelete // to allow delete action from inside the form
    if (emptyStateShown) {
      HeaderActions =
        props.emptyState && props.emptyState.actions
          ? props.emptyState.actions
          : NullComponent
    }
    const WrapperComponent = this.props.menu
      ? AuthenticatedPage
      : ({ ...subProps }) => <div {...subProps} />
    return (
      <WrapperComponent
        menu={props.menu}
        className={`listing-page ${props.className} ${this.state.isGridView ? 'grid-view-tiles' : ''} ${isPageTypeHome ? 'row-4' : ''}`}
        storeDependent={props.storeDependent}
        onChange={() => {
          this.performAction(TABLE_ACTIONS.SET_API_PARAMS, {
            storeId: get('store'),
          })
          this.props.api &&
            this.props.api.onUpdateStore &&
            this.props.api.onUpdateStore()
        }}
      >
        <div className="header-container">
          <h1 className="title">{props.title}</h1>
          {(!emptyStateShown && this.state.firstLoadDone) ||
          (this.props.filters && this.props.filters.forceShow) ? (
            <div className="header-actions-wrapper">
              {this.props.filters ? (
                <div className="search-button-wrapper">
                  <button
                    className={
                      'search-button' +
                      (this.state.filters.shown ? '' : ' active')
                    }
                    onClick={this._toggleFilters}
                  />
                  {/* <span className='search-button-count'>{filtersCount || null}</span> */}
                </div>
              ) : null}
              <HeaderActions
                apiParams={this.state.apiParams}
                onAction={this.performAction}
                data={this.state.data}
              />
            </div>
          ) : (
            emptyStateShown && (
              <div className="header-actions-wrapper">
                <HeaderActions
                  apiParams={this.state.apiParams}
                  onAction={this.performAction}
                />
              </div>
            )
          )}
        </div>
        {this.props.additionalViews
          ? this.props.additionalViews.map((View, index) => (
              <View
                key={index}
                data={this.props.updateView ? this.state.data.items : null}
                updateView={this.props.updateView ? this.updateView : null}
              />
            ))
          : null}
        <div
          className={
            'filters-wrapper' + (this.state.filters.shown ? ' hidden' : '')
          }
        >
          {this.state.firstLoadDone && (
            <Filters
              value={this.state.data.filters}
              onClear={this.clearFilters}
              onSubmit={this.applyFilters}
              options={props.filters ? props.filters.options : null}
            />
          )}
          {this.state.isGridView && (isPageTypeHome || isPageTypeOmniHome) && (
            <div className="sequencing-disabled-info">
              <p>{getMessage('banners.pageType.sequence.tip1')}</p>
              <p>{getMessage('banners.pageType.sequence.tip2')}</p>
            </div>
          )}
        </div>
        {/* CHANGE VIEW */}
        <div className="change-view">
          <button
            className={!this.state.isGridView ? 'primary' : 'secondary'}
            onClick={this.handleChangeView}
          >
            List View
          </button>
          <button
            className={this.state.isGridView ? 'primary' : 'secondary'}
            onClick={this.handleChangeView}
          >
            Grid View
          </button>
        </div>
        {this.state.form.shown ? (
          <Modal
            heading={
              this.state.form.rowIndex > -1
                ? this.props.editHeading ||
                  (this.props.getEditHeading &&
                    this.props.getEditHeading(
                      this.state.data.items[this.state.form.rowIndex]
                    ))
                : this.props.addHeading ||
                  (this.props.getAddHeading &&
                    this.props.getAddHeading(this.state.form.data))
            }
            className={
              this.props.modalClassName ||
              (this.props.getModalClassName &&
                ((this.state.form.data &&
                  this.props.getModalClassName(this.state.form.data)) ||
                  (this.state.form.rowIndex > -1 &&
                    this.props.getModalClassName(
                      this.state.data.items[this.state.form.rowIndex]
                    )))) ||
              ''
            }
            show={this.state.form.shown}
            close={this._hideForm}
          >
            <Form
              value={
                this.state.form.rowIndex > -1
                  ? this.state.data.items[this.state.form.rowIndex]
                  : this.state.form.data
              }
              onSubmit={
                this.state.form.rowIndex > -1
                  ? this.modifyResource
                  : this.createResource
              }
              onCancel={this._hideForm}
              method={this.state.form.rowIndex > -1 ? 'edit' : 'add'}
              options={this.props.form && this.props.form.options}
              onDelete={
                allowDelete
                  ? () =>
                      this.performAction(
                        'DELETE',
                        this.state.data.items[this.state.form.rowIndex]
                      )
                  : null
              }
            />
          </Modal>
        ) : null}
        {this.state.deleteDialog.shown && (
          <Dialog
            show={this.state.deleteDialog.shown}
            title={getMessage('deleteDialog.title')}
            information={getMessage('deleteDialog.information')}
            onOk={this.confirmDelete}
            close={this._hideDeleteDialog}
            closeText={getMessage('dialog.cancelText')}
            okText={getMessage('dialog.okText')}
          />
        )}
        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={getMessage(this.state.errorDialog.message)}
            close={this._hideErrorDialog}
            closeText={getMessage('dialog.okText')}
          />
        )}
        {this.state.loaders.data ? (
          <Loader />
        ) : (
          <DraggableListingPageView
            data={this.state.data}
            pageType={this.state.pageType}
            apiParams={this.state.apiParams}
            isGridView={this.state.isGridView}
            saveBanner={this.state.saveBanner}
            submitting={this.state.submitting}
            emptyState={props.emptyState}
            helpItems={props.helpItems}
            tableProperties={props.tableProperties}
            tableDynamic={props.tableDynamic}
            addToTable={props.addToTable}
            api={this.api}
            viewData={this.viewData}
            onSortEnd={this.onSortEnd}
            performAction={this.performAction}
            handleMove={this.handleMove}
            onDraggableSubmit={this.onDraggableSubmit}
          />
        )}
        {!emptyStateShown && this.props.additionalViewsBottom
          ? this.props.additionalViewsBottom.map((View, index) => (
              <View key={index} values={this.state.data} />
            ))
          : null}
        <Dialog
          className="success draggable-listing-page"
          show={this.state.isDrag}
          close={this.closeDragDialog}
          message={getMessage('banners.success.dialog.message')}
          closeText="OK"
        />
      </WrapperComponent>
    )
  }
}

DraggableListingPage.propTypes = {
  title: PropTypes.string,
  api: PropTypes.shape({
    url: PropTypes.string.isRequired,
    transform: PropTypes.func,
  }),
}

export default withRouter(({ location, history, match, ...props }) => (
  <DraggableListingPage router={{ location, history, match }} {...props} />
))
