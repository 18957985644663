const data = {

    'media.add.text': 'Create',
    'media.bulk.add.text': 'Bulk Add',
    'media.bulk.export.text': 'Export',
    'media.edit.text': 'Edit',
    'media.action': 'Actions',
    'media.action.view': 'Review',
    'media.action.edit': 'Edit',
    'media.action.delete': 'Delete',
    'media.action.duplicate': 'Duplicate',
    'media.action.goBackText': 'Go Back',
    'media.action.clearall':'Clear All',
    'media.action.selectall':'Select All',
    'media.action.exit': 'Exit',
    'media.public.createdby': 'Created By',
    'media.public.updatedby': 'Last Updated By',
    'media.public.empty': 'Empty',
    'media.action.edit.creative': 'Edit Creative',
    'media.dateselect.error': 'Date count cannot be grater than',
    

    //asset management
    'media.assetManagement.filters.name': 'Asset Name',
    'media.assetManagement.filters.name.placeholder': 'Search by asset name',
    'media.assetManagement.title': 'Asset Management',
    'media.assetManagement.header.id': 'Asset ID',
    'media.assetManagement.header.name': 'Asset Name',
    'media.assetManagement.header.store': 'Store Label',
    'media.assetManagement.header.ownerBu': 'Owner BU',
    'media.assetManagement.header.assetType':'Asset Type',
    'media.assetManagement.header.quantity':'Quantity',
    'media.assetManagement.header.actions': 'Actions',
    'media.assetManagement.header.crmAssetFlag': 'CRM Asset Flag',

    'media.assetDetail.title': 'Asset Detail',
    'media.assetDetail.title.add': 'Create Asset',
    'media.assetDetail.title.edit': 'Edit Asset',
    'media.assetDetail.name': 'Name',
    'media.assetDetail.name.placeholder': 'Asset name',
    'media.assetDetail.name.requiredMessage': 'The asset must have a name',
    'media.assetDetail.ownerBu': 'Business Unit',
    'media.assetDetail.ownerBu.placeholder': 'Please select the business unit',
    'media.assetDetail.ownerBu.requiredMessage': 'Please select the business unit',
    'media.assetDetail.assettype': 'Asset Type',
    'media.assetDetail.assettype.placeholder': 'Please select the asset type',
    'media.assetDetail.assettype.requiredMessage': 'Please select the asset type',
    'media.assetDetail.assettype.btn':'+ Add Asset Type',
    'media.assetDetail.assettype.popupTitle':'Add Asset Type',
    'media.assetDetail.assettype.name':'Name',
    'media.assetDetail.assettype.name.placeholder':'Asset type name',
    'media.assetDetail.assettype.name.requiredMessage':'The ssset type must have a name',
    'media.assetDetail.quantity': 'Quantity',
    'media.assetDetail.quantity.placeholder': 'Asset quantity',
    'media.assetDetail.quantity.tooltip': 'If an asset is booked, all quantity of that asset will be booked.',
    'media.assetDetail.quantity.requiredMessage': 'Please input the quantity',
    'media.assetDetail.slotDuration': 'Slot Duration(for RB)',
    'media.assetDetail.slotDuration.placeholder': 'Duration that ad will play for',
    'media.assetDetail.slotDuration.requiredMessage': 'Please input slot duration',
    'media.assetDetail.slotPerSpot': 'Number of slot per playlist/Spot',
    'media.assetDetail.slotPerSpot.requiredMessage': 'Please input the number of slot per playlist/Spot',
    'media.assetDetail.maxSlots': 'Max Slots',
    'media.assetDetail.maxSlots.requiredMessage': 'Please input the number of max slots',
    'media.assetDetail.maxSlots.tooltip': 'Maximum times an asset can be booked for a time period',
    'media.assetDetail.crmAssetFlag': 'CRM Asset Flag',
    'media.assetDetail.crmAssetFlag.placeholder': 'Please select the CRM Asset Flag if it is EDM or PNS',
    'media.assetDetail.yes': 'Yes',
    'media.assetDetail.no': 'No',
    'media.assetDetail.rb.h3':'Business Mechanic (for RB)',
    'media.assetDetail.rb.PWP': 'Is PWP?',
    'media.assetDetail.rb.MB': 'Is Must Buy?',
    'media.assetDetail.rb.LF': 'Is Large Format?',
    'media.assetDetail.db.OLP_HB': 'Is OLP_HB?',
    'media.assetDetail.operatingHour.h3':'Operating Time',
    'media.assetDetail.operatingHour.tips':'* The default setting for unset days is 24 hours.',
    'media.assetDetail.operatingHour.clearall':'Clear All',
    'media.assetDetail.operatingHour.popupTitle':'Edit Operating Time',
    'media.assetDetail.operatingHour.days': 'Days',
    'media.assetDetail.operatingHour.days.requiredMessage': 'Please select at least one day',
    'media.assetDetail.operatingHour.status': 'Operating Status',
    'media.assetDetail.operatingHour.status.open': 'Active',
    'media.assetDetail.operatingHour.status.close': 'Inactive',
    'media.assetDetail.operatingHour.status.requiredMessage': 'P',
    'media.assetDetail.operatingHour.validFromTime': 'Start Time',
    'media.assetDetail.operatingHour.validToTime': 'End Time',
    'media.assetDetail.operatingHour.timeplaceholder': 'Please select',
    'media.assetDetail.campaigns': 'Campaigns reserving this asset',

    'media.assetDetail.assetList.rowheaders.id':'ID',
    'media.assetDetail.assetList.rowheaders.name':'Name',
    'media.assetDetail.assetList.rowheaders.description':'Description',
    'media.assetDetail.assetList.rowheaders.store':'Store',
    'media.assetDetail.assetList.rowheaders.ownerBu':'Owner BU',
    'media.assetDetail.assetList.rowheaders.assetType':'Asset Type',
    'media.assetDetail.assetList.rowheaders.quantity': 'Quantity',
    'media.assetDetail.assetList.rowheaders.actions':'Actions',


    //package Management
    'media.packageManagement.title' : 'Package Management',
    'media.packageManagement.header.id': 'ID',
    'media.packageManagement.header.name': 'Package Name',
    'media.packageManagement.header.ownerBu': 'Owner BU',
    'media.packageManagement.header.description': 'Description',
    'media.packageManagement.header.duration': 'Duration (d)',
    'media.packageManagement.header.packagetype': 'Package Type',
    'media.packageManagement.header.expire': 'Expire Date',
    'media.packageManagement.header.price': 'Price ($)',
    'media.packageManagement.header.value': 'Value ($)',
    'media.packageManagement.header.maxSlots': 'Total Slots',
    'media.packageManagement.header.availableSpots': 'Available Slots',
    'media.packageManagement.header.campaigns': 'Campaigns',
    'media.packageManagement.header.actions': 'Actions',
    'media.packageManagement.header.assets': 'Assets',
    'media.packageManagement.filters.name': 'Package Name',
    'media.packageManagement.filters.name.placeholder': 'Search by package name',
    'media.packageManagement.filters.date': 'Dates',
    'media.packageManagement.filters.fromTime': 'Start Time',
    'media.packageManagement.filters.toTime': 'End Time',

    'media.packageManagement.inventory.title': 'Package Discovery',
    'media.packageManagement.inventory.btn.selected': 'Selected',
    'media.packageManagement.inventory.btn.search': 'Go →',
    'media.packageManagement.inventory.popup.title': 'Selected Packages',
    'media.packageManagement.inventory.error': 'Exceeds the max No. of selections',
    'media.packageManagement.inventory.empty': 'Please re-select the packages',
    'media.packageManagement.inventory.reselect': 'Reselect Packages',
    'media.packageManagement.inventory.card.total': 'Total Packages',
    'media.packageManagement.inventory.card.booked': 'Booked Packages',
    'media.packageManagement.inventory.card.free': 'Free Packages',

    'media.packageDetail.title': 'Package Detail',
    'media.packageDetail.title.edit': 'Edit Package',
    'media.packageDetail.title.add': 'Create Package',
    'media.packageDetail.h3': 'Basic Information',
    'media.packageDetail.name': 'Name',
    'media.packageDetail.name.placeholder': 'Package name',
    'media.packageDetail.name.requiredMessage': 'The package must have a name',
    'media.packageDetail.packagetype.name': 'Package Type',
    'media.packageDetail.packagetype.placeholder': 'Please choose the package type',
    'media.packageDetail.packagetype.requiredMessage': 'Please choose the package type',
    'media.packageDetail.packagetype.tooltip': 'All assets in Fixed packages will be booked for the same defined duration when added to a campaign. Assets in Flexible packages can be booked for different specified durations.',
    'media.packageDetail.duration': 'Duration',
    'media.packageDetail.duration.placeholder': 'Package time duration (days)',
    'media.packageDetail.duration.requiredMessage': 'The package must have a time duration',
    'media.packageDetail.expiredate': 'Expire Date',
    'media.packageDetail.expiredate.requiredMessage': 'Please select the expire date',
    'media.packageDetail.price': 'Price',
    'media.packageDetail.value': 'Value',
    'media.packageDetail.price.placeholder': 'Package cost',
    'media.packageDetail.value.placeholder': 'Package value',
    'media.packageDetail.price.requiredMessage': 'The package must have a price',
    'media.packageDetail.business.ownerBu': 'Business Unit',
    'media.packageDetail.business.ownerBu.placeholder': 'Please select the business unit',
    'media.packageDetail.description': 'Description',
    'media.packageDetail.description.placeholder': 'Package description',
    'media.packageDetail.asset.h3': 'List of Assets',
    'media.packageDetail.asset.filter.h3': '',
    'media.packageDetail.asset.error': 'Please select assets from one BU',
    'media.packageDetail.asset.error2': 'Please select assets',

    //storeList
    'media.storeList.title':'Stores',
    'media.storeList.filters.name': 'Store',
    'media.storeList.filters.name.placeholder': 'Search by store name',
    'media.storeList.filters.time': 'Date',
    'media.storeList.filters.time.startDate.placeholder': 'Date',
    'media.storeList.filters.assettype': 'Asset Type',
    'media.storeList.filters.assettype.placeholder': 'Please select asset type',
    'media.storeList.filters.store.placeholder': 'Search by store label',
    'media.storeList.filters.store.requiredMessage': 'Please input store',
    'media.storeList.empty.message': 'Stores will be shown here.',
    'media.storeList.header.id': 'Store ID',
    'media.storeList.header.name': 'Store Name',
    'media.storeList.header.status': 'Status',
    'media.storeList.header.availableSpots': 'Available Spots',
    'media.storeList.header.actions': 'Actions',
    'media.storeList.header.actions.view': 'Review',

    //assetList
    'media.assetlist.title': 'Store Assets',
    'media.assetlist.table.h3': 'Asset List',
    'media.assetlist.rowheaders.name':'Asset Name',
    'media.assetlist.rowheaders.time': 'Operation Hour',
    'media.assetlist.rowheaders.type': 'Type',
    'media.assetlist.rowheaders.rateCard': 'Rate Card',
    'media.assetlist.rowheaders.slotsPerHour': 'Slots Per Hour',
    'media.assetlist.rowheaders.availableSpots': 'Available Spots',
    'media.assetlist.rowheaders.status': 'Status',
    'media.assetlist.rowheaders.actions': 'Actions',
    'media.assetlist.rowheaders.actions.view': 'Review',

    //Spot Allocation
    'media.spotallocation.title': 'Spot Allocation',
    'media.spotallocation.searchform.time': 'Select Dates',
    'media.spotallocation.searchform.time.startDate': 'Select Start Date',
    'media.spotallocation.searchform.time.startDate.placeholder': 'Start Date',
    'media.spotallocation.searchform.time.endDate': 'Select End Date',
    'media.spotallocation.searchform.time.endDate.placeholder': 'End Date',
    'media.spotallocation.searchform.validFromTime': 'Start Time',
    'media.spotallocation.searchform.validToTime': 'End Time',
    'media.spotallocation.searchform.submitText': 'Search',
    'media.spotallocation.searchform.name': 'Asset Name',
    'media.spotallocation.searchform.name.placeholder': 'Search by name',

    //All Campaign
    'media.allcampaigns.title' : 'All Campaigns',
    'media.allcampaigns.upload.title': 'Import campaigns from CSV file',
    'media.filters.submitText': 'Search',
    'media.filters.clearFiltersText': 'Clear All',
    'media.filters.name.heading': 'Campaign Name',
    'media.filters.name.placeholder': 'Search by name',
    'media.filters.status.heading': 'Status',
    'media.filters.status.label.all': 'All',
    'media.filters.status.label.active': 'active',
    'media.filters.status.label.inactive': 'inactive',
    'media.filters.createdby.heading': 'Created By',
    'media.filters.createdby.placeholder': 'Search by user email',
    'media.filters.offerstatus.heading': 'Offer Status',
    'media.filters.offerstatus.placeholder': 'Please Select The Offer Status',

    'media.allcampaigns.emptyMessage': 'Empty',
    'media.allcampaigns.emptySubmessage': 'Go create your 1st campaign!',
    'media.allcampaigns.rowheaders.id': 'ID',
    'media.allcampaigns.rowheaders.name': 'Campaign name',
    'media.allcampaigns.rowheaders.time': 'Duration',
    'media.allcampaigns.rowheaders.budget': 'Budget',
    'media.allcampaigns.rowheaders.advertiser': 'Advertiser',
    'media.allcampaigns.rowheaders.offersverified': 'Offer Status',
    'media.allcampaigns.rowheaders.status': 'Campaign Status',
    'media.allcampaigns.rowheaders.actions': 'Actions',
    'media.allcampaigns.add.Heading':'Creat New Campaign',
    'media.allcampaigns.edit.Heading': 'Edit Campaign',

    //campaign detail
    'media.campaigndetail.title.edit': 'Edit Campaign',
    'media.campaigndetail.title.add': 'Create Campaign',
    'media.campaigndetail.campaign.h3':'Campaign Details',
    'media.campaigndetail.campaign.name': 'Name',
    'media.campaigndetail.campaign.name.requiredMessage': 'The campaign must have a name',
    'media.campaigndetail.campaign.name.placeholder': 'Campaign name',
    'media.campaigndetail.campaign.budget': 'Budget',
    'media.campaigndetail.campaign.budget.requiredMessage': 'The campaign must have a budget',
    'media.campaigndetail.campaign.budget.placeholder': 'Campaign budget',
    'media.campaigndetail.campaign.objective': 'Objective',
    'media.campaigndetail.campaign.objective.requiredMessage': 'The campaign must have an objective',
    'media.campaigndetail.campaign.objective.placeholder': 'e.g. Branding',
    'media.campaigndetail.campaign.description': 'Description',
    'media.campaigndetail.campaign.description.placeholder': 'Campaign description',
    'media.campaigndetail.campaign.time': 'Duration',
    'media.campaigndetail.campaign.time.requiredMessage': 'The campaign must have a time duration',
    'media.campaigndetail.campaign.time.startDate.placeholder': 'Start Date',
    'media.campaigndetail.campaign.time.endDate.placeholder': 'End Date',
    'media.campaigndetail.buyer.h3':'Buyer Details',
    'media.campaigndetail.buyer.advertiser': 'Advertiser and Vendor Code',
    'media.campaigndetail.buyer.advertiser.placeholder': 'Please select the advertiser',
    'media.campaigndetail.buyer.advertiser.requiredMessage': 'Please select the advertiser',
    'media.campaigndetail.buyer.resell': 'Resell Agent',
    'media.campaigndetail.buyer.resell.placeholder': 'Please select The resell agent',
    'media.campaigndetail.buyer.resell.requiredMessage': 'Please select the resell agent',
    'media.campaigndetail.buyer.adname': 'Advertiser and Vendor Code',
    'media.campaigndetail.buyer.adname.requiredMessage': 'Please write down The advertiser name',
    'media.campaigndetail.buyer.adname.placeholder': 'advertiser name',
    'media.campaigndetail.buyer.adurl': 'Advertiser URL',
    'media.campaigndetail.buyer.adurl.requiredMessage': 'Please write down the advertiser website domain URL',
    'media.campaigndetail.buyer.adurl.placeholder': 'advertiser website domain URL',
    'media.campaigndetail.buyer.resellname': 'Resell Agent Name',
    'media.campaigndetail.buyer.resellname.requiredMessage': 'Please write down the resell agent name',
    'media.campaigndetail.buyer.resellname.placeholder': 'resell agent name',
    'media.campaigndetail.buyer.resellurl': 'Resell Agent URL',
    'media.campaigndetail.buyer.resellurl.requiredMessage': 'Please write down the resell agent website domain URL',
    'media.campaigndetail.buyer.resellurl.placeholder': 'resell agent website domain URL',
    'media.campaigndetail.advertiser.h3':'Advertiser List',
    'media.campaigndetail.advertiser.title':'BUDGET SETTING',
    'media.campaigndetail.business.h3':'Business Mechanic (for DB)',
    'media.campaigndetail.business.ownerBu': 'Business Unit',
    'media.campaigndetail.business.ownerBu.placeholder': 'Please select the business unit',
    'media.campaigndetail.setup.h3': 'Setup Details (for DB)',
    'media.campaigndetail.campaign.offersverified': 'Offer Status',
    'media.campaigndetail.campaign.status': 'Campaign Status',
    'media.campaigndetail.setup.categoryPageUrl': 'Category Page Url',
    'media.campaigndetail.setup.campaignLandingPageUrl': 'Campaign Landing Page Url',
    'media.campaigndetail.report.required': 'Required for reporting',

    //adsets / line items
    'media.adset.title': 'Campaign Detail',
    'media.adset.emptyMessage': 'Empty',
    'media.adset.emptySubmessage': 'Go create your 1st line item!',
    'media.adset.table.h3': 'Line Item List',
    'media.adset.create.text': 'Create Line Item',
    'media.adset.filter.name': 'Line Item Name or ID',
    'media.adset.filter.name.placeholder': 'Please write down the name or ID',
    'media.adset.rowheaders.id': 'ID',
    'media.adset.rowheaders.name': 'Name',
    'media.adset.rowheaders.time': 'Duration',
    'media.adset.rowheaders.budget': 'Budget',
    'media.adset.rowheaders.package': 'Package',
    'media.adset.rowheaders.offsite': 'Off-site',
    'media.adset.rowheaders.actions': 'Actions',
    'media.adset.rowheaders.sku': 'SKUs',
    'media.adset.rowheaders.editCreative': 'Edit Creative',
    'media.adset.rowheaders.status': 'Status',
    'media.adset.advertiserpopup.sku': 'SKUs Upload / Download',
    'media.adset.upload.title': 'Import products from CSV file',
    'media.adset.upload.tip': 'Any CSV file that was uploaded previously shall be overwritten if a new file is uploaded.',
    'media.adset.upload.fileinput': 'CSV file Upload',
    'media.adset.offsite.h3': 'Offsite Information',
    'media.adset.offsite.edm.h4': 'E-mail',
    'media.adset.offsite.pns.h4': 'PNS',

    //adset detail / line item detail
    'media.adset.add.Heading': 'Add Line Item',
    'media.adset.edit.Heading': 'Edit Line Item',
    'media.adsetdetail.title': 'Line Item Detail',
    'media.adsetdetail.title.add': 'Add Line Item',
    'media.adsetdetail.title.edit': 'Edit Line Item',
    'media.adsetdetail.title.review': 'Review Line Item',
    'media.adsetdetail.info.h3':'Basic Information',
    'media.adsetdetail.info.name': 'Name',
    'media.adsetdetail.info.name.placeholder': 'Line Item Name',
    'media.adsetdetail.info.name.requiredMessage': 'The line item must have a name',
    'media.adsetdetail.info.totalbudget': 'Total Budget',
    'media.adsetdetail.info.budget': 'Budget',
    'media.adsetdetail.info.budget.placeholder': 'Line item budget',
    'media.adsetdetail.info.budget.requiredMessage': 'The line item must have a budget',
    'media.adsetdetail.info.package': 'Package',
    'media.adsetdetail.info.package.placeholder': 'Please select the package',
    'media.adsetdetail.info.package.requiredMessage': 'Please select the package',
    'media.adsetdetail.info.status': 'Status',
    'media.adsetdetail.info.status.placeholder': 'Please select the status',
    'media.adsetdetail.info.status.requiredMessage': 'Please select the status',
    'media.adsetdetail.info.time': 'Duration',
    'media.adsetdetail.info.time.startDate': 'Start Date',
    'media.adsetdetail.info.time.startDate.placeholder': 'Start Date',
    'media.adsetdetail.info.time.startDate.requiredMessage': 'The line item must have a start date',
    'media.adsetdetail.info.time.endDate': 'End Date',
    'media.adsetdetail.info.time.endDate.placeholder': 'End Date',
    'media.adsetdetail.info.time.endDate.requiredMessage': 'The line item must have an end date',
    'media.adsetdetail.info.time.requiredMessage': 'Please enter the date duration',
    'media.adsetdetail.info.validFromTime': 'Start Time',
    'media.adsetdetail.info.validFromTime.requiredMessage': 'Please enter start time',
    'media.adsetdetail.info.validToTime': 'End Time',
    'media.adsetdetail.info.validToTime.requiredMessage': 'Please enter end time',
    'media.adsetdetail.info.target':'Target Audience',
    'media.adsetdetail.info.edm': 'E-mail Name',
    'media.adsetdetail.info.edm.placeholder': 'Please enter the e-mail name',
    'media.adsetdetail.info.edm.requiredMessage': 'The package requires e-mail name',
    'media.adsetdetail.info.pns': 'PNS Name',
    'media.adsetdetail.info.pns.placeholder': 'Please enter the PNS name',
    'media.adsetdetail.info.pns.requiredMessage': 'The package requires PNS name',
    'media.adsetdetail.info.crmid': 'CRM Campaign ID',
    'media.adsetdetail.info.buyer': 'Buyer',
    'media.adsetdetail.info.buyer.placeholder': 'Please enter buyer name',
    'media.adsetdetail.package.h3':'Media Booking',
    'media.adsetdetail.package.rowheaders.id':'ID',
    'media.adsetdetail.package.rowheaders.name':'Name',
    'media.adsetdetail.package.rowheaders.description':'Description',
    'media.adsetdetail.package.rowheaders.store':'Store',
    'media.adsetdetail.package.rowheaders.ownerBu':'Owner BU',
    'media.adsetdetail.package.rowheaders.assetType':'Asset Type',
    'media.adsetdetail.package.rowheaders.quantity': 'Quantity',
    'media.adsetdetail.package.rowheaders.actions':'Actions',
    'media.adsetdetail.package.rowheaders.duration': 'Duration',
    'media.adsetdetail.package.rowheaders.expire': 'Expire Date',
    'media.adsetdetail.package.rowheaders.price': 'Price',
    'media.adsetdetail.advertisers.rowheaders.name': 'Advertiser Name',
    'media.adsetdetail.flexible.defaulttime': 'Default Activation',
    'media.adsetdetail.flexible.defaulttime.tooltip': 'By default, all assets in the package will be booked on the days specified in the Default Activation',
    'media.adsetdetail.flexible.defaulttime.requiredMessage': 'Please select the default activation',
    'media.adsetdetail.flexible.defaulttime.tipmessage': '*To edit an existing activation period, delete the period and add a new period',
    'media.adsetdetail.flexible.asset.h3': 'SPECIAL ACTIVATION SETTING',
    'media.adsetdetail.flexible.asset.rowheaders.name': 'Asset Name',
    'media.adsetdetail.flexible.asset.rowheaders.time': 'Special Activation',
    'media.adsetdetail.flexible.asset.rowheaders.time.requiredMessage': 'special Activation duration cannot be empty',

    'media.adsetdetail.setup.supplierBanner': 'Supplier Banner Identifier',
    'media.adsetdetail.setup.logoName': 'Logo Name',
    'media.adsetdetail.setup.brandDetails': 'Brand Deals Product Placement',
    'media.adsetdetail.setup.supplierLandingPageId': 'Supplier Landing Page ID',
    'media.adsetdetail.setup.supplierLandingPageUrl': 'Supplier Landing Page URL',
    'media.adsetdetail.setup.backendOfficeId': 'Backend Offer ID',
    'media.adsetdetail.setup.defaultSearchText': 'Default Search Campaign Name',
    'media.adsetdetail.setup.keywords': 'Keywords Search',
    'media.adsetdetail.setup.supplierUtm': 'Supplier UTM',
    'media.adsetdetail.setup.campaignPromoText': 'Campaign Promo Text',
    'media.adsetdetail.bm.description': 'Promo Mechaincs / Description',
    'media.adsetdetail.bm.edmFeature': 'eDM Feature',
    'media.adsetdetail.bm.includesDbb': 'Includes DBB',
    'media.adsetdetail.bm.defaultSearch': 'Default Search (1 Day)',
    'media.adsetdetail.bm.keywordSearch': 'keyword search (1 Week)',
    'media.adsetdetail.bm.featuredSku': 'Featured SKU',
    'media.adsetdetail.bm.fullDescriptionFoc': 'Full description of FOC item',
    'media.adsetdetail.bm.focWarrantyCard': 'FOC Warranty Card',
    'media.adsetdetail.bm.focWarrantyPeriod': 'FOC Warranty Period(Years)',
    'media.adsetdetail.bm.focValue': 'FOC Value',
    'media.adsetdetail.bm.handling': 'handling',
    'media.adsetdetail.bm.indicate': 'Indicate:',
    'media.adsetdetail.bm.premiumQut': 'Premium Qty',
    'media.adsetdetail.bm.focHandlingFee': 'FOC Handling Fee',
    'media.adsetdetail.bm.vendorCode': 'Vendor Code',
    'media.adsetdetail.bm.soac': 'SOAC',
    'media.adsetdetail.bm.classGLCode': 'Class/GL Code',
    'media.adsetdetail.bm.classGLCode.placeholder': 'Please select class/GL code',
    'media.adsetdetail.bm.promoType': 'Promo Type',

    //Media Creative Management
    'media.creativeManagement.title': 'Creative Management',
    'media.creativeManagement.filter.name': 'Creative Name',
    'media.creativeManagement.filter.creativetype': 'Creative Type',
    'media.creativeManagement.filter.creativetype.placeholder': 'Please select creative type',
    'media.creativeManagement.filters.name.placeholder': 'Search by creative name',

    'media.creativeManagement.header.id': 'Id',
    'media.creativeManagement.header.name': 'Creative Name',
    'media.creativeManagement.header.package': 'Package',
    'media.creativeManagement.header.bu': 'Business Unit',
    'media.creativeManagement.header.status': 'Status',
    'media.creativeManagement.header.actions': 'Actions',
    'media.creativeManagement.header.actions.pause': 'Pause',
    'media.creativeManagement.header.actions.active': 'Active',

    //Creative Detail
    'media.creativedetail.creative.title': 'Creative',
    'media.creativedetail.title.add': 'Create Creative',
    'media.creativedetail.title.edit': 'Edit Creative',
    'media.creativedetail.creative.name': 'Creative Name',
    'media.creativedetail.creative.name.placeholder': 'Please enter creative name',
    'media.creativedetail.creative.name.requiredMessage': 'Creative name is required',
    'media.creativedetail.creative.type': 'Creative Type',
    'media.creativedetail.creative.type.placeholder': 'Select creative type',
    'media.creativedetail.creative.type.requiredMessage': 'Creative type is required',
    'media.creativedetail.creative.status': 'Status',

    'media.creativedetail.title': 'Creative Detail',
    'media.creativedetail.creative.group.title': 'Groups',
    'media.creativedetail.creative.group.emptyMessage': 'Empty',
    'media.creativedetail.creative.group.emptySubmessage': 'Create a group!',
    'media.creativedetail.creative.group.add': 'Create Group',
    'media.creativedetail.creative.group.header.name': 'Group',
    'media.creativedetail.creative.group.header.asset': 'Assets',
    'media.creativedetail.creative.group.header.adlist': 'Ad List',
    'media.creativedetail.creative.group.header.cr': 'Creative Requirement',
    'media.creativedetail.creative.group.header.withoutcr': 'Assets Without Creative Requirement',
    'media.creativedetail.creative.group.emptyAsset': 'Please choose at least 1 asset!',

    'media.creativeManagement.packageReqPopup.h3':'',
    'media.creativeManagement.packageReqPopup.empty':'Please select a valid package.',

    'media.creativeManagement.editDisabledPopup.title': 'Warning',
    'media.creativeManagement.editDisabledPopup.message': 'Creative has been added to a line item and cannot be edited. Create a new creative',


    //Creative Requirement in ad
    'media.creative.requirement.title': 'Creative Requirement Information',
    'media.creative.requirement.height': 'Height (px) ',
    'media.creative.requirement.width': 'Width (px)',
    'media.creative.requirement.size': 'Max File Size (kb)',
    'media.creative.requirement.duration': 'Duration (s)',
    'media.creative.requirement.format': 'Formats',

    //Creative Requirement Management
    'media.creativeReqManagement.title': 'Creative Requirement Management',
    'media.creativeReqManagement.name': 'Creative Requirement',
    'media.creativeReqManagement.name.placeholder': 'Optional',
    'media.creativeReqManagement.btn': 'Manage Creative Requirements',
    'media.creativeReqManagement.header.id': 'Id',
    'media.creativeReqManagement.header.name': 'Name',
    'media.creativeReqManagement.header.type': 'Type',
    'media.creativeReqManagement.header.height': 'Height (px)',
    'media.creativeReqManagement.header.width': 'Width (px)',
    'media.creativeReqManagement.header.size': 'Size (Kb)',
    'media.creativeReqManagement.header.format': 'Format',
    'media.creativeReqManagement.header.action': 'Actions',

    //Creative Requirement Detail
    'media.creativeReqManagement.detail.title.add': 'Add Creative Requirement',
    'media.creativeReqManagement.detail.title.edit': 'Edit Creative Requirement',
    'media.creativeReqManagement.detail.editip': 'Any changes to creative requirement will not be applied to campaigns with creative(s) assigned.',
    'media.creativeReqManagement.detail.name': 'Name',
    'media.creativeReqManagement.detail.name.placeholder': 'Name of creative requirement',
    'media.creativeReqManagement.detail.name.requiredMessage': 'Name is required',
    'media.creativeReqManagement.detail.type': 'Type',
    'media.creativeReqManagement.detail.type.placeholder': 'Please select type',
    'media.creativeReqManagement.detail.type.requiredMessage': 'Type is required',
    'media.creativeReqManagement.detail.height': 'Height (px)',
    'media.creativeReqManagement.detail.height.placeholder': 'Height of the creative',
    'media.creativeReqManagement.detail.height.requiredMessage': 'Height is required',
    'media.creativeReqManagement.detail.width': 'Width (px)',
    'media.creativeReqManagement.detail.width.placeholder': 'Width of the creative',
    'media.creativeReqManagement.detail.width.requiredMessage': 'Width is required',
    'media.creativeReqManagement.detail.size': 'Size (Kb)',
    'media.creativeReqManagement.detail.size.placeholder': 'Size of the creative',
    'media.creativeReqManagement.detail.size.requiredMessage': 'Size is required',
    'media.creativeReqManagement.detail.format': 'Format',
    'media.creativeReqManagement.detail.format.placeholder': 'Please select format of the creative',
    'media.creativeReqManagement.detail.format.requiredMessage': 'Format is required',
    'media.creativeReqManagement.detail.duration': 'Duration (s)',
    'media.creativeReqManagement.detail.duration.placeholder': 'Duration of the creative',
    'media.creativeReqManagement.detail.duration.requiredMessage': 'Duration is required',

    //Creative Ad List
    'media.creative.adlist.title': 'Ad List Management',
    'media.creative.adlist.table.title': 'Ad List / Playlist',
    'media.creative.adlist.add': '+ Ad',
    'media.creative.adlist.form.name': 'Ad Name',
    'media.creative.adlist.form.name.placeholder': '',
    'media.creative.adlist.form.name.requiredMessage': '',
    'media.creative.adlist.form.sequence': 'Sequence',
    'media.creative.adlist.form.sequence.existsMessage': 'Sequence already assigned',
    'media.creative.adlist.form.sequence.placeholder': '',
    'media.creative.adlist.form.sequence.requiredMessage': '',
    'media.creative.adlist.form.url': 'Ad URL',
    'media.creative.adlist.form.url.placeholder': '',
    'media.creative.adlist.form.url.requiredMessage': '',
    'media.creative.adlist.form.link': 'Cloudinary link',
    'media.creative.adlist.form.cr.title': 'Creative Requirements',

    //Creative Add
    'media.creative.add.title': 'Add Creative',
    'media.creative.add.name': 'Creative Name',
    'media.creative.add.package': 'Package',
    'media.creative.add.search': 'Search',
    'media.creative.add.clear': 'Clear All',
    'media.creative.add.emptyheader': ' ',
    'media.creative.add.empty': 'There is no creative created.',
    'media.creative.add.empty.submessage': 'Please create a Creative.',
    'media.creative.add.info.message': 'Only creatives with completed status can be added to line items.',


    //Advert Details Popup
    'media.creative.adlist.requirement.id': 'Id',
    'media.creative.adlist.requirement.name': 'Name',
    'media.creative.adlist.requirement.type': 'Type',
    'media.creative.adlist.requirement.length': 'Length',
    'media.creative.adlist.requirement.width': 'Width',
    'media.creative.adlist.requirement.duration': 'Duration',
    'media.creative.adlist.requirement.linktostorage': 'Storage Link',

    //Advert Details
    'media.adManagement.title': 'Advertisements'
}

export default data
