import React from 'react'
import { Upload } from '../../Form'
import { getMessage } from 'lib/translator'

const ImageUrl = ({ _this }) => (
  <div className="row mt">
    <div className="col-12">
      <Upload
        name="banner"
        placeholder={getMessage(`rewards.catalogue.banner`)}
        label={getMessage(`rewards.catalogue.banner`)}
        {..._this.generateStateMappers({
          stateKeys: ['banner'],
          loseEmphasisOnFill: true,
        })}
        required
      />
    </div>
  </div>
)

export default ImageUrl
