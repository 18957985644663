import { PlainInput } from 'components/Form'
import { Text, View } from '../ui'
import React, { useEffect, useState } from 'react'
import { AddIcon, MinusIcon } from '../ui/icon'
import { Field } from 'formik'
import { FormikCheckBox } from './FormikInput'

export const ProductLine = ({ item, onChange, quantity }) => {
  const [quantityInput, setQuantityInput] = useState()

  useEffect(() => {
    setQuantityInput(quantity)
  }, [quantity])
  return (
    <View
      direction="column"
      padding="12px 0 0"
      style={{
        borderTop: '1px solid #DADEE0',
        position: 'relative',
        display: 'block',
        gap: '12px',
      }}
    >
      <View alignItems="center" justifyContent="start">
        <Field component={FormikCheckBox} name={`cart.${item.sku}`} />
        <View display="block">
          <Text display="block" textAlign="left" fontSize={14}>
            {item.name}
          </Text>
          <Text display="block" textAlign="left" fontSize={12}>
            SKU: {item.sku}
          </Text>
        </View>
      </View>
      <View justifyContent="space-between">
        <View
          style={{ gap: '10px' }}
          width={140}
          justifyContent="space-between"
        >
          <MinusIcon
            data-testid={`remove-${item.sku}`}
            onClick={() => onChange({ target: { value: quantity - 1 } })}
          />
          <PlainInput
            type="text"
            color="blue"
            textAlign="center"
            data-testid={`quantity-${item.sku}`}
            value={quantityInput}
            onChange={(e) => setQuantityInput(e.target.value)}
            onBlur={onChange}
            inputStyle={{
              height: 32,
              padding: '4px 8px',
              borderWidth: '1px',
              borderRadius: '4px',
            }}
            inputWrapperStyle={{
              width: '56px',
            }}
          />
          <AddIcon
            data-testid={`add-${item.sku}`}
            onClick={() => onChange({ target: { value: quantity + 1 } })}
          />
        </View>
        <Text
          display="block"
          fontWeight={900}
          fontFamily="Lato"
          fontSize={16}
          style={{ margin: '12px 0 24px 0' }}
        >
          $ {parseFloat(item.price * quantity).toFixed(2)}
        </Text>
      </View>
    </View>
  )
}
