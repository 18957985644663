import React from 'react'
import { Input, VALIDATION_TYPES, Upload } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import './style.scss'
import guideDetails from './GuideDetails.svg'
import guide from './Guide.svg'
import listingGuide from './ListingScreenGuide.png'

export const Divider = () => {
  return (
    <div className="divider-container" data-testid="border">
      <div className="divider-border" />
    </div>
  )
}

export default function MobileAppSetup({
  generateStateMappers,
  saveChangesDialogue,
  handleLeavePageCheck
}) {
  const valid = getMessage('input.requiredMessage')
  const imagePlaceholder = getMessage('brand.form.image.placeholder')

  const renderInputForm = (inputData) =>{
    const {
      header,
      titleKey,
      titlePlaceholderKey,
      descKey,
      descPlaceholderKey,
      titleNameKey,
      descNameKey,
      imageTitle,
      logoKey,
      onboardingImageHover,
      img
    } = inputData
    const HeadeTitle="Campaign Listing Screen"
    return(
    <div className="vertical">
      <div className="inputForm">
        <div>
          <h3 className="swimlaneHeader">{getMessage(header)}</h3>
          <Input
            label={getMessage(titleKey)}
            placeholder={getMessage(titlePlaceholderKey)}
            name={titleNameKey}
            type="text"
            required={!saveChangesDialogue && !handleLeavePageCheck}
            {...generateStateMappers({
              stateKeys: [titleNameKey],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: valid,
            }}
            maxLength={header===HeadeTitle ? 84:60}
          />
        </div>
        <br />
        <div>
          <Input
            label={getMessage(descKey)}
            placeholder={getMessage(descPlaceholderKey)}
            name={descNameKey}
            type="text"
            required={!saveChangesDialogue && !handleLeavePageCheck}
            {...generateStateMappers({
              stateKeys: [descNameKey],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: valid,
            }}
            maxLength={130}
          />
        </div>
        <br />
        <Upload
          className="onboardingImage"
          label={getMessage(imageTitle)}
          placeholder={imagePlaceholder}
          required={!saveChangesDialogue && !handleLeavePageCheck}
          {...generateStateMappers({
            stateKeys: [logoKey],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
      </div>
      <div className={onboardingImageHover}>
        <img src={img} alt="logo" className="img" />
      </div>
    </div>
  )
        }

  const campaignListingScreen = renderInputForm({
    header: 'Campaign Listing Screen',
    titleKey: 'preorder.form.campaigntitle',
    titlePlaceholderKey: 'preorder.mobileapp.campaignTitle.placeholder',
    descKey: 'preorder.mobileapp.description',
    descPlaceholderKey: 'preorder.mobileapp.description.placeholder',
    titleNameKey: 'mbSetupTitle',
    descNameKey: 'description',
    imageTitle: 'preorder.campaignlisting.img',
    logoKey: 'listingLogo',
    onboardingImageHover: 'onboardingImageHover2',
    img: guideDetails
  })

  const onboardingScreen = renderInputForm({
    header: 'preorder.mobileapp.onboardingscreen',
    titleKey: 'preorder.mobileapp.title',
    titlePlaceholderKey: 'preorder.mobileapp.campaignTitle.placeholder',
    descKey: 'preorder.mobileapp.description',
    descPlaceholderKey: 'preorder.mobileapp.description.placeholder',
    titleNameKey: 'mbOnboardingtitle',
    descNameKey: 'mbOnboardingdescription',
    imageTitle: 'preorder.mobileapp.onboardingimg',
    logoKey: 'logo',
    onboardingImageHover: 'onboardingImageHover',
    img: guide
  })
  return (
    <div className="preorder-page mobileAppSetup-page">
      <React.Fragment>
        <div className="tab-container">
          <div className="optional">{getMessage('preorder.optional')}</div>
          <div className="tab-details">
            {campaignListingScreen}
            <Divider />
            {onboardingScreen}
            <Divider />
            <h3 className="sectcomponentDidMountion-title">
              Campaign Landing Screen
            </h3>
            <div className="vertical">
              <div className="inputForm">
                <div>
                  <Upload
                    className="onboardingImage"
                    placeholder={imagePlaceholder}
                    label="MARKETING IMAGE (W1080xH600)"
                    required={!saveChangesDialogue && !handleLeavePageCheck}
                    {...generateStateMappers({
                      stateKeys: ['landing_image_path'],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                      loseEmphasisOnFill: true,
                    })}
                  />
                </div>
                <br />
              </div>
              <div className="onboardingImageHover">
                <img src={listingGuide} alt="logo" className="img" />
              </div>
            </div>
            <Divider />
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}
