import React, { useCallback, useState } from 'react'
import { FormikScanPage } from '../components/FormikInput'
import { SearchProduct } from '../components/SearchProduct'
import { ProductFetchHandler } from '../components/ProductFetchHandler'
import { useFormikContext } from 'formik'

export const ScanProduct = ({ toHome, toReviewOrder }) => {
  const { values } = useFormikContext()
  const [fetchProduct, setFetchProduct] = useState(false)
  const [fetchBy, setFetchBy] = useState()
  const [param, setParam] = useState()

  const onSubmitManual = useCallback(() => {
    if (values.inputBarcode) {
      // fetch product by barcode,once done ..add item to the "cart" field (formik)
      setParam(values.inputBarcode)
      setFetchBy('barcode')
      setFetchProduct(true)
    } else if (values.inputSKU) {
      // fetch product by SKU
      // once done ..add item to the "cart" field (formik)
      setParam(values.inputSKU)
      setFetchBy('SKU')
      setFetchProduct(true)
    }
  }, [values])

  const onScanResult = (decodeText) => {
    setParam(decodeText)
    setFetchBy('barcode')
    setFetchProduct(true)
  }

  const onDone = () => {
    toHome()
    toReviewOrder()
  }

  if (fetchProduct) {
    return (
      <ProductFetchHandler
        fetchBy={fetchBy}
        payload={param}
        onDone={onDone}
        onFailed={toHome}
      />
    )
  }
  return (
    <FormikScanPage
      onBack={toHome}
      title="Scan barcode"
      footer="Enter barcode number or SKU"
      name="inputBarcode"
      onNewScanResult={onScanResult}
    >
      <SearchProduct onBack={toHome} onSubmit={onSubmitManual} />
    </FormikScanPage>
  )
}
