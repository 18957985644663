import React, { Component } from 'react'
import Table from '../Table'
import { getMessage } from '../../../../lib/translator'

const pickerStatuses = ["PICKING", "PICKED", "PACKING", "PACKED", "UPDATED"]

export default class StatusLog extends Component {

    constructor(props) {
        super(props)
    }

    isPacklistEvent(item) {
        const isPacklistEventStatusChange = this.props.data.action === 'PACKLISTEVENT_STATUS_CHANGE';
        return (this.props.packlistAction
            && item.status.newValue !== 'PROCESSING'
            && !isPacklistEventStatusChange)
    }

    getStatusValue(value, item) {
        const statusPrefix = this.isPacklistEvent(item) ? 'Packlist ' : ''
        return statusPrefix + value.toLowerCase()
            .split('_')
            .map(text => text==='packlist' ? '' : text[0].toUpperCase() + text.substr(1, text.length))
            .join(' ')
    }

    getPacklistId(item) {
        const isPacklist = this.isPacklistEvent(item)
        return (isPacklist && item.packlistId) ?
        <small style={{color: '#80959d'}}>&nbsp;(Packlist id: {item.packlistId})</small> : null;
    }

    getUserName(data) {
        return <div className="user-name">
            <span className="by">{' '}{getMessage('order.log.user')}</span>
            {(data.user && data.user.name)
							? data.user.isDeleted
								? ` ${data.user.name} (Deleted)`: ` ${data.user.name}`
							: ' - (Deleted)'}
        </div>
    }

    renderStatusWithUserName(item, data) {
        const status = item?.status?.newValue || data.action;
        return (
            <div className={`bold ${status}`}>
                {this.getStatusValue(status, item)}
                {this.getPacklistId(item)}
                {this.getUserName(data)}
            </div>
        )
    }

    render() {
        const {
            data,
            index,
            packlistAction
        } = this.props
        const item = data.action === 'PACKLIST_PACKING' ?
            {
                ...this.props.item,
                status: {newValue: 'PACKING', oldValue: 'PICKING'}
            } : this.props.item;
        return (
            <React.Fragment>
                {(item.status) ? (
                    data.user ? (
                        this.renderStatusWithUserName(item, data)
                    ) : (
                        item.status.newValue &&
                        item.status.oldValue && (
                            packlistAction && pickerStatuses.includes(item.status.newValue)
                            && data.action !== 'PACKLISTEVENT_STATUS_CHANGE' ?
                                this.renderStatusWithUserName(item, data)
                            : (
                                <div className={`status-changed ${item.status.newValue}`}>
                                    {getMessage('order.log.status.changed')}{' '}
                                    {getMessage('order.log.from')}{' '}
                                    <span className="bold">{` ${item.status.oldValue &&
                                        this.getStatusValue(item.status.oldValue, item)}`}</span>{' '}
                                    {getMessage('order.log.to')}{' '}
                                    <span className="bold">{`${item.status.newValue &&
                                        this.getStatusValue(item.status.newValue, item)}`}</span>
                                </div>
                            )
                        )
                    )
                ) :
                    data.user &&
                        <div className="bold updated-by">
                            {getMessage('order.log.editedBy')}
                            {this.getUserName(data)}
                        </div>

                }
                {!packlistAction && item.itemChanges && (
                    <Table
                        data={{
                            header: 'Item Changes',
                            tableData: item.itemChanges,
                            properties: [
                                'itemName',
                                'oldQuantity',
                                'newQuantity',
                                'orderItemId',
                                'reason',
                            ],
                            index: index,
                            itemChanges: true,
                        }}
                    />
                )}
            </React.Fragment>
        )
    }
}
