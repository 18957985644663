import React from 'react'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import MediaPagination from '../../commonMedia/pagination'
import { Link } from 'react-router-dom'
import { getMessage } from '../../../../lib/translator'


export default function AssetTable (props) {

  const { assetData, paging, isMobileView, changePage, toggleAssetBox } = props

  return(
    <div>
      <Table>
        {!isMobileView && (
          <Header>
            <Cell>{getMessage('media.assetlist.rowheaders.name')}</Cell>
            <Cell>{getMessage('media.assetlist.rowheaders.type')}</Cell>
            <Cell>{getMessage('media.assetlist.rowheaders.status')}</Cell>
            <Cell>{getMessage('media.assetlist.rowheaders.slotsPerHour')}</Cell>
            <Cell>{getMessage('media.assetlist.rowheaders.availableSpots')}</Cell>
            <Cell>{getMessage('media.assetlist.rowheaders.actions')}</Cell>
          </Header>
        )}
        {assetData.map((asset, assetIndex) => (
          <Row key={assetIndex}>
            <Cell className='website-page-name'>
              {isMobileView && (
                <div className="text-muted">
                  {getMessage('media.assetlist.rowheaders.name')}
                </div>
              )}
              <span className='link-btn' onClick={()=>{toggleAssetBox(1, asset)}} >{asset?.assetName}</span>
            </Cell>
            <Cell>
              {isMobileView && (
                <div className="text-muted">
                  {getMessage('media.assetlist.rowheaders.type')}
                </div>
              )}
              {asset?.assetType}
            </Cell>
            <Cell>
              {isMobileView && (
                <div className="text-muted">
                  {getMessage('media.assetlist.rowheaders.status')}
                </div>
              )}
              {asset?.assetStatus}
            </Cell>
            <Cell>
              {isMobileView && (
                <div className="text-muted">
                  {getMessage('media.assetlist.rowheaders.slotsPerHour')}
                </div>
              )}
              {asset?.slotsPerHour}
            </Cell>
            <Cell>
              {isMobileView && (
                <div className="text-muted">
                  {getMessage('media.assetlist.rowheaders.availableSpots')}
                </div>
              )}
              {asset?.availableSpots}
            </Cell>
            <Cell className='website-page-actions'>
              {isMobileView && (
                <div className="text-muted">
                  {getMessage('media.assetlist.rowheaders.actions')}
                </div>
              )}
              <Link to={`/media-monetization/media-storelist/media-spotallocation/${asset?.assetId}`}>
                  {getMessage('media.assetlist.rowheaders.actions.view')}
              </Link>
            </Cell>
          </Row>
        ))}
      </Table>

      <MediaPagination
        paging={paging}
        changePage={changePage}
      ></MediaPagination>
    </div>
  )
}
