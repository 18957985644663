import React, { Component } from 'react'
import { getMessage } from '../../../../lib/translator'
import Checkbox from '../../../../components/Form/Inputs/Checkbox'
import './style.css'

class ExtensionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      submitting: false,
      checked: this.props.value.status === 'ENABLED',
    }
  }
  getList(desc) {
    if (!desc) {
      return null
    }
    const descriptions = desc.map(function(description, i) {
      return <li key={i}>{description}</li>
    })
    return <ul>{descriptions}</ul>
  }
  render() {
    const { props } = this
    const data = this.props.value
    // TODO: Get this info from API
    return (
      <div>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        <div className="extension-header">
          {data.icon ? (
            <div className="extension-image">
              <img src={data.icon} alt="extension placeholder" />
            </div>
          ) : (
            ''
          )}
          <div className="extension-name">
            <span>{data.name}</span>
            <div className="payment-details">
              {data.pricing && (
                <div className="payment-amount">
                  <div>{data.pricing.split(' ')[0]}</div>
                  <div className="payment-explanation">
                    {data.pricing
                      .split(' ')
                      .slice(1)
                      .join(' ')}
                  </div>
                </div>
              )}
            </div>
            {data.version ? (
              <div>
                <span className="key">
                  {getMessage('extensions.version')}:{' '}
                </span>
                <span className="value">{data.version}</span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="extension-details">
          <div className="description">
            <div className="description-heading">{data.description}</div>
            {this.props.details && (
              <div className="desc">
                <div className="descheading">
                  {getMessage('extensions.whatwillchange')}
                </div>
                {this.getList(this.props.details.split('|').filter(Boolean))}
              </div>
            )}
            {props.showExtra ? (
              <div className="optional">
                <div className="span-header">
                  <span className="key">
                    {getMessage('extensions.lastUpdated')}:{' '}
                  </span>
                  <span className="value">
                    {data.lastUpdated ? data.lastUpdated : '-'}
                  </span>
                </div>
                <div className="span-header">
                  <span className="key">
                    {getMessage('extensions.activeInstallations')}:{' '}
                  </span>
                  <span className="value">
                    {data.activeInstallations ? data.activeInstallations : 0}
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="form-buttons-container">
          {data.status === 'DISABLED' || props.value.status === 'DISABLED' ? (
            <Checkbox
              inlineLabel={getMessage('extensions.policy')}
              name="policy"
              value={this.state.checked}
              onChange={value => {
                this.setState({ checked: value })
              }}
              className="extension-checkbox"
            />
          ) : (
            ''
          )}
          <div className="form-actions">
            <button
              type="button"
              className="primary"
              onClick={props.onSubmit}
              disabled={this.state.submitting || !this.state.checked}
            >
              {data.status === 'ENABLED'
                ? getMessage('extensions.uninstall')
                : getMessage('extensions.install')}
            </button>
            <button type="button" className="button" onClick={props.onCancel}>
              {getMessage('extensions.cancel')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ExtensionForm
