import React from 'react'
import Searchable from '../Searchable'

export default function BrandSearch(props) {
  const newProps = Object.assign({}, props, {
    searchUrl: '/catalogue-service/brand',
    valueKey: 'id',
    nameKey: 'name',
    searchKey: 'name',
    expandMore: true,
    transformRequest: (searchText, paramsDefault) => {
      const params = Object.assign({}, paramsDefault)
      params.name = `%${params.name}`
      return params
    },
    transformResponse: response => {
      return (
        (props.appendElement ? props.appendElement : []).concat(
          response.data.brand
        ) || []
      )
    },
  })
  return <Searchable {...newProps} />
}
