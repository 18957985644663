import React, { useMemo } from 'react'
import { camelToReading } from 'lib/commonlyused'

const getSegmentDetails = (segmentValue, stores) => {
  const isDateRange = segmentValue['startDate'] && segmentValue['endDate']
  const singleValueSegment = ['date', 'gmv', 'city', 'pincode']
  const singleValueSegmentKey =
    (Object.keys(segmentValue).length === 1 &&
      Object.keys(segmentValue).find(
        (key) => singleValueSegment.indexOf(key) > -1
      )) ||
    null
  if (singleValueSegmentKey) {
    return segmentValue[singleValueSegmentKey]
  }

  if (isDateRange && segmentValue['minAmount'] && segmentValue['maxAmount']) {
    return `${segmentValue['startDate']} to ${segmentValue['endDate']}, $${segmentValue['minAmount']} to $${segmentValue['maxAmount']}`
  }
  if (isDateRange && segmentValue['minCount'] && segmentValue['maxCount']) {
    return `${segmentValue['minCount']} to ${segmentValue['maxCount']}, ${segmentValue['startDate']} to ${segmentValue['endDate']}`
  }
  if (isDateRange) {
    return `${segmentValue['startDate']} to ${segmentValue['endDate']}`
  }
  if (segmentValue['tags']) {
    return segmentValue['tags'].map((tag) => tag.name).join(', ')
  }
  if (segmentValue['storeId']) {
    return segmentValue['storeId']
      .map(
        (storeId) => stores.find((store) => store.id === storeId)?.name || ''
      )
      .join(', ')
  }

  return ''
}

const SegmentsList = ({ segments = [], stores }) => {
  const segmentString = useMemo(() => {
    return segments.map((item) => {
      const { id, ...rest } = item
      const details =
        Object.keys(rest).length > 0 ? getSegmentDetails(rest, stores) : ''
      const segmentName = camelToReading(id)
      return (
        <span className="segment-list" id={id} key={id}>
          <b>{segmentName}</b>: {details}
        </span>
      )
    })
  }, [segments])
  return (
    <span data-testid="offer-confirmation-modal-segment-data">
      {segmentString}
    </span>
  )
}

export default SegmentsList
