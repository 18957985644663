import React from 'react'
import { BaseForm } from '../../../../../components/Form'
import { CheckboxGroup } from '../../../../../components/Form/Inputs'
import { SKINS as CHECKBOX_SKINS } from '../../../../../components/Form/Inputs/CheckboxGroup'
import Loader from '../../../../../components/Loader'
import Dialog from '../../../../../components/Popup/Dialog'
import API from '../../../../../lib/api'
import { getMessage } from '../../../../../lib/translator'

export default class PaymentGatewayForm extends BaseForm {
  constructor(props) {
    super(props)

    const paymentModes = props.paymentModes
    const mapping = paymentModes.reduce((result, pm) => {
      result[pm.parentPaymentModeId] = result[pm.parentPaymentModeId] || []
      result[pm.parentPaymentModeId].push(pm)
      return result
    }, {})
    const pmIds = Object.keys(mapping)
    delete pmIds[pmIds.indexOf('null')]
    const updated =
      mapping['null'] &&
      mapping['null'].filter(pm => {
        return pmIds.indexOf(pm.id) < 0 ? pm : null
      })

    mapping['null'] = updated

    this.state = {
      paymentModes: props.paymentModes,
      mapping: mapping,
      showLoader: false,
      submitting: false,
      showDialog: false,
    }

    const addedPaymentModes = props.addedPaymentModes
    const ids = addedPaymentModes.map(pm => {
      return pm.id
    })
    this.state.values = {
      paymentModes: ids,
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    })
    const { mapping, paymentModes } = this.state
    const result = Object.entries(mapping)
    const map = result.map(parentGw => {
      const parentGwId = parentGw[0]

      if (parentGwId > 0) {
        const parentGwName = paymentModes.filter(pm => pm.id === parentGwId)[0]
          .paymentMode
        const childGws = parentGw[1]

        const options = childGws.map(cpm => {
          return {
            text: cpm.paymentMode,
            value: cpm.id,
            icon:
              cpm.imageUrl ||
              'https://png.icons8.com/ios/1600/ios-application-placeholder.png',
          }
        })
        return {
          key: parentGwId,
          label: parentGwName,
          name: parentGwName,
          options: options,
        }
      } else {
        const gateways = parentGw[1]
        const checkboxes =
          gateways &&
          gateways.map(gw => {
            const option = [
              {
                value: gw.id,
                icon:
                  gw.imageUrl ||
                  'https://png.icons8.com/ios/1600/ios-application-placeholder.png',
              },
            ]
            return {
              key: gw.id,
              label: gw.paymentMode,
              name: gw.paymentMode,
              options: option,
            }
          })
        return checkboxes
      }
    })
    this.setState({
      map: map,
      showLoader: false,
    })
  }

  closeDialog() {
    this.setState({
      showDialog: false,
    })
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    })
    const api = new API({ url: '/account-service/payment-account' })
    const params = {}
    params['type'] = 'DEFAULT'
    params['id'] = this.props.id
    params['paymentModes'] = data.paymentModes.map(pm => {
      return {
        id: pm,
      }
    })
    api.put(params).then(
      () => {
        this.setState({
          formError: '',
          submitting: false,
          showDialog: true,
        })
      },
      error => {
        this.setState({
          formError: error.message,
          submitting: false,
        })
      }
    )
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const paymentModes = this.state.map
    return (
      <div className="default-payment-gateway-form">
        <Dialog
          show={this.state.showDialog}
          className="success"
          message={getMessage('settings.online.payment.success')}
          information={getMessage('settings.online.payment.succesfully.saved')}
          close={this.closeDialog}
          closeText={getMessage('settings.online.payment.ok')}
        />
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <Form>
            {this.state.formError && (
              <div className="form-error">{this.state.formError}</div>
            )}
            {paymentModes &&
              paymentModes.map(group => {
                if (Array.isArray(group)) {
                  const LonerPaymentModes = group.map(pm => (
                    <div
                      className="checkboxgroup-with-label"
                      key={`${pm.key}-div`}
                    >
                      <label>{pm.label}</label>
                      <CheckboxGroup
                        key={pm.key}
                        skin={CHECKBOX_SKINS.WITH_ICONS}
                        label={pm.label}
                        name={pm.name}
                        options={pm.options}
                        {...this.generateStateMappers({
                          stateKeys: ['paymentModes'],
                        })}
                      />
                    </div>
                  ))
                  return (
                    <div className="flex" key="payment-modes-config">
                      {LonerPaymentModes}
                    </div>
                  )
                } else {
                  if (group) {
                    return (
                      <div
                        className="checkboxgroup-with-label"
                        key={`${group.key}-div`}
                      >
                        <label>{group.label}</label>
                        <CheckboxGroup
                          key={group.key}
                          skin={CHECKBOX_SKINS.WITH_ICONS}
                          label={group.label}
                          name={group.name}
                          options={group.options}
                          {...this.generateStateMappers({
                            stateKeys: ['paymentModes'],
                          })}
                        />
                      </div>
                    )
                  }
                  return null
                }
              })}
            <div className="form-actions">
              <SubmitButton disabled={this.state.submitting}>
                {getMessage('settings.online.payments.save')}
              </SubmitButton>
              <button
                className="button"
                type="button"
                onClick={() => this.props.back()}
                disabled={this.state.submitting}
              >
                {getMessage('basicInfo.cancel.text')}
              </button>
            </div>
          </Form>
        )}
      </div>
    )
  }
}
