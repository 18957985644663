import React from 'react'
import { getMessage } from 'lib/translator'
import TextEditor from '../../RichEditor/TextEditor'

const StoryInfo = ({ _this, story }) => {
  const formattedStory = typeof story === 'string' ? story.trim() : ''

  const onEditorStateChange = editorStateValue => {
    const values = Object.assign({}, _this.state.values)
    values.story = editorStateValue
    _this.setState({
      values,
    })
  }

  return (
    <TextEditor
      label={getMessage(`rewards.story`)}
      placeholder={getMessage(`rewards.story`)}
      html={formattedStory}
      onChange={onEditorStateChange}
    />
  )
}

export default StoryInfo
