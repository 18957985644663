import React, { useContext } from 'react'
import {
  BaseForm,
  Input,
  Textarea,
  VALIDATION_TYPES,
  SelectSearch,
  Checkbox,
  Upload,
  Select,
} from '../../../../components/Form'
import Toggle from '../../../../components/Form/Inputs/Toggle'
import { cloneMutables } from '../../../../lib/stateManagement/'
import MetaDataFormFields from '../../../../components/MetaDataFormFields'
import {
  Marker,
  SearchBox,
  GoogleMaps,
} from '../../../../components/GoogleMaps'
import { getMessage } from '../../../../lib/translator'
import { isExtensionEnabled, setTierSetups } from '../../../../lib/auth'
import {
  storeSelection,
  tierSetupSelection,
} from '../../../../lib/commonlyused'
import API from '../../../../lib/api'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { USER_SEGMENTS } from '../../../logistics/LogisticsConfig/Form/index'
import _find from 'lodash.find'
import _cloneDeep from 'lodash/cloneDeep'

import './style.css'
import { SearchCoordForm } from './SearchCoordinate'
import { When } from 'components/When'
import { StoreChangesModal } from './StoreChangesModal'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { SplitContext } from 'containers/SplitContext'
import { diff } from 'json-diff'

const daysOfWeak = {
  Monday: { o: '', c: '' },
  Tuesday: { o: '', c: '' },
  Wednesday: { o: '', c: '' },
  Thursday: { o: '', c: '' },
  Friday: { o: '', c: '' },
  Saturday: { o: '', c: '' },
  Sunday: { o: '', c: '' },
}

const hourStringPattern = '^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$'

const storeTypeOptions = [
  {
    text: 'Standard',
    value: 'STANDARD',
  },
  {
    text: 'Scan & Go',
    value: 'SNG',
  },
  {
    text: 'FFS',
    value: 'FFS',
  },
  {
    text: 'Search and Browse',
    value: 'SEARCH_AND_BROWS',
  },
  {
    text: 'PFC',
    value: 'PFC',
  },
]

/** Little helper to initialize form values, (only for business hours and sng operational hours) */
const getInitialValues = (value) => {
  return getSngHoursObject(getBusinessHoursObject(value))
}

const getBusinessHoursObject = (value) => {
  const values = cloneMutables(value)
  const newValues = Object.assign(values, { hasPicking: !!values.hasPicking })
  if (newValues.businessHours) {
    Object.keys(daysOfWeak).forEach((day) => {
      newValues.businessHours[day] = newValues.businessHours[day] || {
        o: '',
        c: '',
      }
    })
  } else {
    newValues.businessHours = _cloneDeep(daysOfWeak)
  }
  return newValues
}

/**
 * Supporting extraction of hours object only for
 * SNG Operational hours
 * @param {*} value
 * @returns
 */
export const getSngHoursObject = (value) => {
  const values = cloneMutables(value)
  const newValues = Object.assign(values, {})
  if (newValues.metaData && newValues.metaData['SnG Operational Hours']) {
    try {
      const jsonObject = JSON.parse(newValues.metaData['SnG Operational Hours'])
      newValues.sngOperationalHours = {}
      Object.keys(daysOfWeak).forEach((day) => {
        newValues.sngOperationalHours[day] = jsonObject[day] || {
          o: '',
          c: '',
        }
      })
    } catch (err) {
      newValues.sngOperationalHours = _cloneDeep(daysOfWeak)
    }
  } else {
    newValues.sngOperationalHours = _cloneDeep(daysOfWeak)
  }
  return newValues
}

const requiredMetaData = {
  'SAP Code': true,
  'Store Code': true,
}

const wrapRequiredMetadata = (obj) => {
  if (!obj) {
    return {}
  }
  const newObj = _cloneDeep(obj)
  Object.keys(newObj).forEach((key) => {
    if (requiredMetaData[key]) {
      newObj[key] = { ...newObj[key], required: true }
    }
  })
  return newObj
}

const isSngStoreText = 'Is SnG Store'

class StoreForm extends BaseForm {
  constructor(props) {
    super(props)
    const initialTierSetups = tierSetupSelection()

    const values = props.value
      ? getInitialValues(props.value)
      : { businessHours: daysOfWeak, sngOperationalHours: daysOfWeak }

    this.state = {
      isConfirmModalOpen: false,
      initialValues: this.props.apiData
        ? _cloneDeep(getInitialValues(this.props.apiData))
        : {},
      mapMounted: false,
      tierSetups: initialTierSetups.filter(
        (tierSetup) => tierSetup.userSegment !== USER_SEGMENTS.B2B
      ),
      b2bTierSetups: initialTierSetups.filter(
        (tierSetup) => tierSetup.userSegment !== USER_SEGMENTS.B2B
      ),
      values: values,
      defaultTierSetup:
        initialTierSetups && !props.values?.metaData?.[isSngStoreText]
          ? _find(initialTierSetups, (tierSetup) => tierSetup.isDefault)
          : undefined,
    }
    this.handleMapMounted = this.handleMapMounted.bind(this)
    this.handleFindLocation = this.handleFindLocation.bind(this)
    this.tempHoursObject = daysOfWeak
    this.tempSngHoursObject = daysOfWeak
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleHasPickingChange = this.handleHasPickingChange.bind(this)
    this.onAddressSubmit = this.onAddressSubmit.bind(this)
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
    this.handleSngHoursCheckbox = this.handleSngHoursCheckbox.bind(this)
    this.renderSngHoursForm = this.renderSngHoursForm.bind(this)
    this.renderBusinessHoursForm = this.renderBusinessHoursForm.bind(this)
    this.renderPickingAndDeliveryInputs = this.renderPickingAndDeliveryInputs.bind(this)
    this.renderClickNCollectSelfCheckoutInputs = this.renderClickNCollectSelfCheckoutInputs.bind(this)
  }

  handleStatusChange(val) {
    const values = JSON.parse(JSON.stringify(this.state.values || {}))
    values.status = val ? 'ENABLED' : 'DISABLED'
    this.setState({ values })
  }

  handleHasPickingChange(value) {
    const values = JSON.parse(JSON.stringify(this.state.values || {}))
    values.hasPicking = value

    if (!value) {
      this.addKeys(['pickingStoreId'])
      values.pickingStoreId = undefined
      values.tierSetupId = undefined
      this.deleteKeys(['tierSetupId'])
    } else {
      this.addKeys(['tierSetupId'])
      values.tierSetupId =
        this.props.method === 'add' && this.state.defaultTierSetup
          ? this.state.defaultTierSetup.value
          : undefined
      values.pickingStoreId = undefined
      this.deleteKeys(['pickingStoreId'])
    }

    this.setState({ values })
  }

  handleMapMounted(node) {
    this.mapRef = node
    this.setState({
      mapMounted: true,
    })
    if (
      (!this.state.values.latitude || !this.state.values.longitude) &&
      'geolocation' in window.navigator
    ) {
      /* geolocation is available */
      window.navigator.geolocation.getCurrentPosition(({ coords }) => {
        this.setLocation(coords)
      })
    }
  }

  setLocation({ latitude, longitude }) {
    this.updateState(['latitude'], latitude)
    this.updateState(['longitude'], longitude)
  }
  handleFindLocation() {
    if (this.searchBoxRef) {
      const places = this.searchBoxRef.getPlaces()
      const bounds = new window.google.maps.LatLngBounds()
      if (places.length) {
        const place = places[0]
        this.setLocation({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        })
        this.updateState(['address'], place.formatted_address)
      }
      // Fit location area into the map
      places.forEach((place) => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }
      })
      this.mapRef.fitBounds(bounds)
    }
  }
  async componentDidMount() {
    const values = Object.assign({}, this.state.values)
    if (!values.metaData) {
      values.metaData = {}
      this.setState({
        values,
      })
    }

    const tierSetupApi = new API({ url: `/account-service/shipping-tier` })
    const response = await tierSetupApi.get()
    const tierSetupData = response.data
    const sortedTierSetupData = tierSetupData.sort(
      (a, b) => b.isDefault - a.isDefault
    )

    const tierSetups = tierSetupSelection(sortedTierSetupData)

    const defaultTierSetup =
      this.props.method === 'add'
        ? _find(tierSetups, (tierSetup) => tierSetup.isDefault)
        : undefined

    this.setState(
      {
        tierSetups: tierSetups.filter(
          (tierSetup) => tierSetup.userSegment !== USER_SEGMENTS.B2B
        ),
        b2bTierSetups: tierSetups.filter(
          (tierSetup) => tierSetup.userSegment === USER_SEGMENTS.B2B
        ),
        defaultTierSetup: !values?.metaData?.[isSngStoreText]
          ? defaultTierSetup
          : undefined,
      },
      () => setTierSetups(tierSetupData)
    )
  }

  onAddressSubmit(address, coordinates) {
    this.updateState(['latitude'], Number.parseFloat(coordinates.lat))
    this.updateState(['longitude'], Number.parseFloat(coordinates.lng))
    this.updateState(['address'], address)
  }

  toggleModal = () => {
    const diffObj = diff(this.state.initialValues, this.state.values)
    if (!diffObj) {
      this._submitHandler()
      return
    }

    if (this.isFormValid() || this.state.isConfirmModalOpen) {
      this.setState((oldState) => ({
        isConfirmModalOpen: !oldState.isConfirmModalOpen,
      }))
    }
  }

  handleChangeCheckbox(value, day) {
    const form = JSON.parse(
      JSON.stringify(this.state.values)
    )
    if (value) {
      this.tempHoursObject[day]['o'] =
        (form.businessHours[day] &&
          form.businessHours[day]['o']) ||
        ''
      this.tempHoursObject[day]['c'] =
        (form.businessHours[day] &&
          form.businessHours[day]['c']) ||
        ''
      form.businessHours[day]['o'] = '00:00:00'
      form.businessHours[day]['c'] = '23:59:59'
      this.setState({ values: form })
    } else {
      if (!form.businessHours[day]) {
        form.businessHours[day] = {}
      }
      form.businessHours[day]['o'] =
        (this.tempHoursObject[day] &&
          this.tempHoursObject[day]['o']) ||
        ''
      form.businessHours[day]['c'] =
        (this.tempHoursObject[day] &&
          this.tempHoursObject[day]['c']) ||
        ''
      this.setState({ values: form })
    }
  }

  handleSngHoursCheckbox(value, day) {
    const form = JSON.parse(
      JSON.stringify(this.state.values)
    )
    if (value) {
      this.tempSngHoursObject[day]['o'] =
        (form.sngOperationalHours[day] &&
          form.sngOperationalHours[day]['o']) ||
        ''
      this.tempSngHoursObject[day]['c'] =
        (form.sngOperationalHours[day] &&
          form.sngOperationalHours[day]['c']) ||
        ''
      form.sngOperationalHours[day]['o'] = '00:00:00'
      form.sngOperationalHours[day]['c'] = '23:59:59'
      this.setState({ values: form })
    } else {
      if (!form.sngOperationalHours[day]) {
        form.sngOperationalHours[day] = {}
      }
      form.sngOperationalHours[day]['o'] =
        (this.tempSngHoursObject[day] &&
          this.tempSngHoursObject[day]['o']) ||
        ''
      form.sngOperationalHours[day]['c'] =
        (this.tempSngHoursObject[day] &&
          this.tempSngHoursObject[day]['c']) ||
        ''
      this.setState({ values: form })
    }
  }

  renderClickNCollectSelfCheckoutInputs() {
    return (
      <div className="grid">
        <Checkbox
          className="delivey-hub"
          name="isCnC"
          label={getMessage('operations.store.form.hasClickCollect.label')}
          {...this.generateStateMappers({
            stateKeys: ['hasClickCollect'],
            loseEmphasisOnFill: true,
          })}
        />
        <Checkbox
          className="delivey-hub"
          name="hasSelfCheckout"
          label={getMessage('operations.store.form.hasSelfCheckout.label')}
          {...this.generateStateMappers({
            stateKeys: ['hasSelfCheckout'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }

  renderPickingAndDeliveryInputs(status) {
    return (
      <div className="grid">
        <Checkbox
          className="delivey-hub"
          name="hasPicking"
          label={getMessage('operations.store.form.hasPicking.label')}
          {...this.generateStateMappers({
            stateKeys: ['hasPicking'],
            loseEmphasisOnFill: true,
          })}
          onChange={this.handleHasPickingChange}
        />
        <Checkbox
          className="delivey-hub"
          name="hasDeliveryHub"
          label={getMessage('operations.store.form.hasDeliveryHub.label')}
          {...this.generateStateMappers({
            stateKeys: ['hasDeliveryHub'],
            loseEmphasisOnFill: true,
          })}
        />
        <Toggle
          data-testid="store-switch"
          className={status}
          title="enable/disable the store"
          name={status}
          value={status === 'ENABLED'}
          icons={false}
          onChange={this.handleStatusChange}
        />
      </div>
    )
  }

  renderBusinessHoursForm() {
    return (
      <div className="businessTime">
        <h2>{getMessage('operations.store.form.businessHours.title')}</h2>
        <Table>
          <TableHeaders />
          {Object.keys(daysOfWeak).map((day) => (
            <Row className="business-time-row" key={day}>
              <Cell>{day}</Cell>
              <Cell>
                <Checkbox
                  value={
                    this.state.values.businessHours[day]
                      ? this.state.values.businessHours[day]['o'] ===
                          '00:00:00' &&
                        this.state.values.businessHours[day]['c'] ===
                          '23:59:59'
                      : false
                  }
                  name={`always-open-${day}`}
                  onChange={(value) => this.handleChangeCheckbox(value, day)}
                />
              </Cell>
              <Cell>
                <Input
                  type="text"
                  pattern={hourStringPattern}
                  placeholder={'HH:MM:SS'}
                  name={`opening-hour-${day}`}
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['businessHours', day, 'o'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </Cell>
              <Cell>
                <Input
                  type="text"
                  pattern={hourStringPattern}
                  placeholder={'HH:MM:SS'}
                  name={`closing-hour-${day}`}
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['businessHours', day, 'c'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </Cell>
            </Row>
          ))}
        </Table>
      </div>
    )
  }

  renderSngHoursForm() {
    return (
      <div className="businessTime">
        <h2>{getMessage('operations.store.form.sngHours.title')}</h2>
        <Table>
          <TableHeaders />
          {Object.keys(daysOfWeak).map((day) => (
            <Row className="business-time-row" key={`sng-hours-${day}`}>
              <Cell>{day}</Cell>
              <Cell>
                <Checkbox
                  value={
                    this.state.values.sngOperationalHours[day]
                      ? this.state.values.sngOperationalHours[day][
                          'o'
                        ] === '00:00:00' &&
                        this.state.values.sngOperationalHours[day][
                          'c'
                        ] === '23:59:59'
                      : false
                  }
                  name={`sng-always-open-${day}`}
                  onChange={(value) => this.handleSngHoursCheckbox(value, day)}
                />
              </Cell>
              <Cell>
                <Input
                  type="text"
                  pattern={hourStringPattern}
                  placeholder={'HH:MM:SS'}
                  name={`sng-opening-hour-${day}`}
                  {...this.generateStateMappers({
                    stateKeys: ['sngOperationalHours', day, 'o'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </Cell>
              <Cell>
                <Input
                  type="text"
                  pattern={hourStringPattern}
                  placeholder={'HH:MM:SS'}
                  name={`sng-closing-hour-${day}`}
                  {...this.generateStateMappers({
                    stateKeys: ['sngOperationalHours', day, 'c'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </Cell>
            </Row>
          ))}
        </Table>
      </div>
    )
  }

  render() {
    /**@feature_flag ----------------------------------------------------- */
    const { splits } = this.props
    const isStoreKindFieldEnabled =
      splits?.[SPLIT_FEATURES.STORE_KIND_FIELD]?.treatment === 'on'
    const isConfirmModalFlagOn =
      this.props.splits?.[SPLIT_FEATURES.STORE_EDIT_CONFIRMATION_MODAL]
        ?.treatment === 'on'
    const isSngHoursFormEnabled =
      splits?.[SPLIT_FEATURES.STORE_SNG_OPT_HOURS_FORM]?.treatment === 'on'
    /**@feature_flag ----------------------------------------------------- */

    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const { validations, values, tierSetups, b2bTierSetups, defaultTierSetup } =
      this.state
    const hasPicking = values && values.hasPicking
    if (hasPicking) {
      // eslint-disable-next-line no-prototype-builtins
      if (validations && validations.hasOwnProperty('pickingStoreId')) {
        delete this.state.validations.pickingStoreId
      }
    }
    const status =
      this.props.method === 'add' &&
      !(this.state.values && this.state.values.status)
        ? 'ENABLED'
        : this.state.values && this.state.values.status
    const { storeMetaDataWithType } = this.props.options

    const processedMetaData = wrapRequiredMetadata(storeMetaDataWithType)

    const { latitude, longitude } = this.state.values

    return (
      <div>
        <Form className="store-form">
          <div className="store-form-address-name-wrapper">
            <div className="store-form-address-name">
              <Input
                className="store-name"
                label={getMessage('operations.store.form.name.heading')}
                placeholder={getMessage('operations.store.form.name.placeholder')}
                name="name"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ['name'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                }}
                maxLength="45"
              />
              {isStoreKindFieldEnabled && (
                <Select
                  label={'Store Type'}
                  placeholder={'N/A'}
                  className={'enum'}
                  required
                  options={storeTypeOptions}
                  {...this.generateStateMappers({
                    stateKeys: ['type'],
                    loseEmphasisOnFill: true,
                  })}
                  validationStrings={{
                    valueMissing: getMessage('store.form.type.requiredMessage'),
                  }}
                />
              )}

              <SearchCoordForm
                onAddressSubmit={this.onAddressSubmit}
                defaultCoord={{ lat: latitude, lng: longitude }}
              />
              <Textarea
                label={getMessage('operations.store.form.address.heading')}
                placeholder={getMessage(
                  'operations.store.form.address.placeholder'
                )}
                name="address"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ['address'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                }}
              />

              {/* image upload */}
              <Upload
                label="Image"
                placeholder="Upload image"
                {...this.generateStateMappers({
                  stateKeys: ['storeImgUrl'],
                })}
              />
            </div>
            <Input
              className="coordinates-input"
              label={getMessage('operations.store.form.location.heading')}
              name="coordinates"
              type="text"
              required
              value={
                this.getState(['latitude']) && this.getState(['longitude'])
                  ? 'filled'
                  : ''
              }
              onValidation={(error) => {
                this.registerValidation(['coordinates'], error)
              }}
              emphasize={
                !this.getState(['latitude']) || !this.getState(['longitude'])
              }
              showErrors={this.validationScenarios.validateOnSubmit()}
              siblings={{
                after: (
                  <GoogleMaps
                    onMapMounted={this.handleMapMounted}
                    zoom={15}
                    center={{
                      lat: this.getState(['latitude']) || 12.9178614,
                      lng: this.getState(['longitude']) || 77.6449406,
                    }}
                    containerClassName="map-element"
                  >
                    {this.state.mapMounted ? (
                      <SearchBox
                        onLoad={(node) => {
                          this.searchBoxRef = node
                        }}
                        controlPosition={
                          window.google.maps.ControlPosition.TOP_LEFT
                        }
                        onPlacesChanged={this.handleFindLocation}
                      >
                        <input
                          type="text"
                          placeholder={getMessage('operations.store.form.location.placeholder')}
                          className="location-search-box"
                        />
                      </SearchBox>
                    ) : null}
                    <Marker
                      position={{
                        lat: this.getState(['latitude']) || 12.9178614,
                        lng: this.getState(['longitude']) || 77.6449406,
                      }}
                      draggable
                      onDragEnd={(e) => {
                        this.setLocation({
                          latitude: e.latLng.lat(),
                          longitude: e.latLng.lng(),
                        })
                      }}
                    />
                  </GoogleMaps>
                ),
              }}
              validationStrings={{ valueMissing: getMessage('operations.store.form.location.requiredMessage') }}
            />
          </div>
          {this.renderPickingAndDeliveryInputs(status)}
          {this.renderClickNCollectSelfCheckoutInputs()}
          <div className="grid">
            <SelectSearch
              required={!values?.metaData?.[isSngStoreText]}
              name="tierSetupId"
              label={getMessage('operations.store.form.deliveryFee.label')}
              placeholder={getMessage(
                'operations.store.form.deliveryFee.placeholder'
              )}
              options={tierSetups}
              nameKey="text"
              valueKey="value"
              searchByName
              defaultOption={defaultTierSetup}
              isShowingDefaultOptions={true}
              {...this.generateStateMappers({
                stateKeys: ['tierSetupId'],
                loseEmphasisOnFill: true,
              })}
            />
            <SelectSearch
              name="b2bTierSetupId"
              label={getMessage('operations.store.form.b2bDeliveryFee.label')}
              placeholder={getMessage(
                'operations.store.form.b2bDeliveryFee.placeholder'
              )}
              options={b2bTierSetups}
              nameKey="text"
              valueKey="value"
              searchByName
              isShowingDefaultOptions={true}
              {...this.generateStateMappers({
                stateKeys: ['b2bTierSetupId'],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="grid">
            {!hasPicking && (
              <SelectSearch
                name="picking-store"
                required
                label={getMessage('Picking store')}
                placeholder={getMessage('Select store')}
                options={storeSelection()}
                nameKey="text"
                valueKey="value"
                searchByName
                {...this.generateStateMappers({
                  stateKeys: ['pickingStoreId'],
                  loseEmphasisOnFill: true,
                })}
              />
            )}
          </div>
          {(this.props.method === 'add' || this.getState(['clientId'])) && (
            <Input
              label={getMessage('operations.store.form.clientId.heading')}
              placeholder={getMessage(
                'operations.store.form.clientId.placeholder'
              )}
              name="clientId"
              type="text"
              className="client-id"
              {...this.generateStateMappers({
                stateKeys: ['clientId'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              readOnly={this.props.method !== 'add'}
            />
          )}

          {this.renderBusinessHoursForm()}
          {isSngHoursFormEnabled && this.renderSngHoursForm()}
          {isExtensionEnabled('EntityMetaData') &&
            processedMetaData &&
            Object.keys(processedMetaData).length > 0 && (
              <MetaDataSection
                processedMetaData={processedMetaData}
                hasPicking={hasPicking}
                isSngHoursFormEnabled={isSngHoursFormEnabled}
                splits={this.props.splits}
                _this={this} />
            )}
          <div className="form-buttons">
            <When
              condition={isConfirmModalFlagOn && this.props.method === 'edit'}
            >
              <When condition={this.state.isConfirmModalOpen}>
                <StoreChangesModal
                  isOpen={this.state.isConfirmModalOpen}
                  toggleModal={this.toggleModal}
                  oldData={this.state.initialValues}
                  newData={this.state.values}
                  submitForm={this._submitHandler}
                />
              </When>
              <button
                data-testid="submit"
                className="primary button"
                type="button"
                onClick={this.toggleModal}
              >
                {getMessage('operations.store.form.submitText')}
              </button>
            </When>
            <When
              condition={this.props.method === 'add' || !isConfirmModalFlagOn}
            >
              <SubmitButton testid="submit">
                {getMessage('operations.store.form.submitText')}
              </SubmitButton>
            </When>
            <CancelButton>
              {getMessage('operations.store.form.cancelText')}
            </CancelButton>
          </div>
        </Form>
      </div>
    )
  }
}

const TableHeaders = () => (
  <Header>
    <Cell>
      {getMessage('operations.store.form.businessHours.day')}
    </Cell>
    <Cell>
      {getMessage('operations.store.form.businessHours.alwaysOpen')}
    </Cell>
    <Cell>
      {getMessage('operations.store.form.businessHours.openingTime')}
    </Cell>
    <Cell>
      {getMessage('operations.store.form.businessHours.closingTime')}
    </Cell>
  </Header>
)


const MetaDataSection = ({ processedMetaData, hasPicking, isSngHoursFormEnabled, splits, _this }) => (
  <div className="metadata-section">
    <h2>{getMessage('operations.store.form.metaData.heading')}</h2>
    <div className="metadata-section-wrapper">
      <MetaDataFormFields
        metaDataWithType={processedMetaData}
        noContainer
        page="stores-form"
        hide={{
          'Search And Browse Enabled':
            splits?.[
              SPLIT_FEATURES.HIDE_SEARCH_AND_BROWSE
            ]?.treatment === 'on' && hasPicking,
          'SnG Operational Hours': isSngHoursFormEnabled,
        }}
        tooltips={{
          'SnG Check-in Blocked':
            'Checking this field would block GPS and QR code check in for S&G',
          'Search And Browse Enabled':
            'If this is an FFS store listing, DO NOT CHECK THIS FIELD. Checking this field shows a store in the in store grocery tile, swimlanes, and search.',
          'Is SnG Store':
            'Checking this field turns on SnG for this store',
          'SnG Geo Checkin Radius':
            'SnG GPS check in radius in meters',
        }}
        _this={_this}
      />
    </div>
  </div>
)

const StoreFormWrapper = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return <StoreForm splits={splits} {...props} />
}

export default StoreFormWrapper
