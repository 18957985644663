import React, { Component } from 'react'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage/index'
import ListingPage from '../../../../containers/ListingPage'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import { getSession } from '../../../../lib/auth'
import { Popup, Dialog } from '../../../../components/Popup'
import {
  BaseForm,
  Input,
  Textarea,
  Select,
  SingleDatePicker,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import { Row, Cell } from '../../../../components/Table'
import Loader from '../../../../components/Loader'
import AssetPreview from '../../MediaAssetManagement/assetPreview'
import { PackageTypeList } from '../../MediaAssetManagement/businessUnitList'
import { getAssetTypes } from '../../MediaAssetManagement/AssetTypeList'
import { updatePackages } from '../../MediaCampaigns/advertiserList'
import AssetFilters from './assetFilters'
import './style.css'

const APIurl = '/martech-ad-service/packages/'

const emptyState = {
  message: 'No results found',
}

class AssetsSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowAllAssets: false,
    }

    this.toggleAllAssets = this.toggleAllAssets.bind(this)
  }

  toggleAllAssets() {
    const isShowAllAssets = !this.state.isShowAllAssets
    this.setState({
      isShowAllAssets,
    })
  }

  render() {
    const { selectedAssetItemList, selectedPackage, handleAssetsClear } =
      this.props

    return (
      <div className="assets-summary">
        <div className="header">
          <div className="assets-summary-inner">
            <div className="assets-summary-col">
              <div className="assets-summary-col-top">
                <small>Selected Items</small>
              </div>
              <div className="assets-summary-col-btm">
                {selectedAssetItemList.length === 0 ? (
                  <div>-</div>
                ) : (
                  selectedAssetItemList.map(
                    (selectedAssetItem, selectedAssetIndex) => (
                      <span
                        key={selectedAssetItem}
                        className={
                          selectedAssetIndex > 20 && !this.state.isShowAllAssets
                            ? 'hide'
                            : ''
                        }
                      >
                        {selectedAssetItem}
                      </span>
                    )
                  )
                )}
                <div
                  className={`loadbtn down ${!this.state.isShowAllAssets && selectedAssetItemList.length > 20 ? 'show' : ''}`}
                  onClick={this.toggleAllAssets}
                ></div>
                <div
                  className={`loadbtn up ${this.state.isShowAllAssets && selectedAssetItemList.length > 20 ? 'show' : ''}`}
                  onClick={this.toggleAllAssets}
                ></div>
              </div>
            </div>
            <div className="assets-summary-col">
              <div className="assets-summary-col-top">
                <small>Quantity</small>
              </div>
              <div className="assets-summary-col-btm">
                {selectedAssetItemList.length +
                  (selectedPackage && selectedPackage.package_id ? 1 : 0)}
              </div>
            </div>
            <div className="assets-summary-col">
              <button
                type="button"
                className="primary button"
                onClick={handleAssetsClear}
              >
                <span className="text">Clear All</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const tableProperties = (
  isMobileView,
  selectedAssetItemList,
  handleAssetItemAdd,
  handleAssetItemRemove,
  toggleAssetBox,
  _this
) => {
  return {
    headers: [
      getMessage('media.assetDetail.assetList.rowheaders.id'),
      getMessage('media.assetDetail.assetList.rowheaders.name'),
      getMessage('media.assetDetail.assetList.rowheaders.store'),
      getMessage('media.assetDetail.assetList.rowheaders.ownerBu'),
      getMessage('media.assetDetail.assetList.rowheaders.assetType'),
      getMessage('media.assetDetail.assetList.rowheaders.quantity'),
      getMessage('media.assetDetail.assetList.rowheaders.actions'),
    ],
    row: (props) => {
      _this.actionFun = props.onAction
      return (
        <Row>
          <Cell className="website-page-url">
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.assetDetail.assetList.rowheaders.id')}
              </div>
            )}
            {props.buAssetId}
          </Cell>
          <Cell className="website-page-url assetName">
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.assetDetail.assetList.rowheaders.name')}
              </div>
            )}
            <small
              className="link-btn"
              onClick={() => {
                toggleAssetBox(1, props)
              }}
            >
              {props.assetName}
            </small>
          </Cell>
          <Cell className="website-page-url">
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.assetDetail.assetList.rowheaders.store')}
              </div>
            )}
            {props.buStoreLabel}
          </Cell>
          <Cell className="website-page-url">
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.assetDetail.assetList.rowheaders.ownerBu')}
              </div>
            )}
            {props.ownerBu}
          </Cell>
          <Cell className="website-page-url">
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.assetDetail.assetList.rowheaders.assetType')}
              </div>
            )}
            <small>{props.assetTypeName}</small>
          </Cell>
          <Cell className="website-page-url">
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.assetDetail.assetList.rowheaders.quantity')}
              </div>
            )}
            {props.quantity}
          </Cell>
          <Cell className="website-page-actions">
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.adset.rowheaders.name')}
              </div>
            )}
            {selectedAssetItemList.indexOf(props.buAssetId) < 0 ? (
              <div
                className="cell-action add"
                onClick={() => {
                  handleAssetItemAdd(props)
                }}
              >
                +
              </div>
            ) : (
              <span
                className="cell-action remove"
                onClick={() => handleAssetItemRemove(props.buAssetId)}
              >
                remove
              </span>
            )}
          </Cell>
        </Row>
      )
    },
  }
}

class MediaPackageForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      ownerBu: '',
      assetTypeList: [],
      values: this.props.value,
    }
  }

  componentDidMount() {
    window.localStorage.setItem('asset-table-wrap website-pages', 1)
    getAssetTypes(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.values = newProps.value
      if (newProps.value.ownerBu) {
        newState.ownerBu = String(newProps.value.ownerBu)
      }
      return newState
    })
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const {
      isMobileView,
      selectedAssetItemList,
      handleAssetItemAdd,
      handleAssetItemRemove,
      handleAssetsClear,
      toggleAssetBox,
    } = this.props
    return (
      <div className="website-page-edit">
        <Form className="website-page-form">
          <h3 className="sectcomponentDidMountion-title">
            {getMessage('media.packageDetail.h3')}
          </h3>
          <div className="section-wrap">
            <div className="section-row">
              <Input
                className="section-row-input"
                label={getMessage('media.packageDetail.name')}
                placeholder={getMessage('media.packageDetail.name.placeholder')}
                name="packageName"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ['packageName'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    'media.packageDetail.name.requiredMessage'
                  ),
                }}
              />
              <Select
                className="section-row-input"
                label={getMessage('media.packageDetail.packagetype.name')}
                placeholder={getMessage(
                  'media.packageDetail.packagetype.placeholder'
                )}
                options={PackageTypeList}
                name="packageType"
                required
                {...this.generateStateMappers({
                  stateKeys: ['packageType'],
                  loseEmphasisOnFill: true,
                  onChange: () => {
                    this.deleteKeys(['packageDuration', 'packagePrice'])
                  },
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    'media.packageDetail.packagetype.requiredMessage'
                  ),
                }}
                tooltip={
                  <div>
                    {getMessage('media.packageDetail.packagetype.tooltip')}
                  </div>
                }
              />
            </div>
            {this.state.values?.packageType === 'FIXED' && (
              <div className="section-row">
                <Input
                  className="section-row-input"
                  label={getMessage('media.packageDetail.duration')}
                  placeholder={getMessage(
                    'media.packageDetail.duration.placeholder'
                  )}
                  name="packageDuration"
                  type="number"
                  min={0}
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['packageDuration'],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  validationStrings={{
                    valueMissing: getMessage(
                      'media.packageDetail.duration.requiredMessage'
                    ),
                  }}
                />
                <Input
                  className="section-row-input"
                  label={getMessage('media.packageDetail.price')}
                  placeholder={getMessage(
                    'media.packageDetail.price.placeholder'
                  )}
                  name="packagePrice"
                  type="number"
                  min={0}
                  step="0.01"
                  prefix={getSession().organization.currency.symbol}
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['packagePrice'],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  validationStrings={{
                    valueMissing: getMessage(
                      'media.packageDetail.price.requiredMessage'
                    ),
                  }}
                />
              </div>
            )}
            <div className="section-row">
              <SingleDatePicker
                className="section-row-input"
                label={getMessage('media.packageDetail.expiredate')}
                displayFormat="YYYY-MM-DD"
                placeholder={getMessage('media.packageDetail.expiredate')}
                required
                allowAllDates
                {...this.generateStateMappers({
                  stateKeys: ['expireDate'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    'media.packageDetail.expiredate.requiredMessage'
                  ),
                }}
              />
              <Input
                className="section-row-input"
                label={getMessage('media.packageDetail.value')}
                placeholder={getMessage(
                  'media.packageDetail.value.placeholder'
                )}
                name="packageValue"
                type="number"
                min={0}
                step="0.01"
                prefix={getSession().organization.currency.symbol}
                {...this.generateStateMappers({
                  stateKeys: ['packageValue'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
            </div>
            <div className="section-row">
              <Textarea
                label={getMessage('media.packageDetail.description')}
                placeholder={getMessage(
                  'media.packageDetail.description.placeholder'
                )}
                name="packageDescription"
                maxLength={100}
                {...this.generateStateMappers({
                  stateKeys: ['packageDescription'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>

          <h3 className="section-title">
            {getMessage('media.packageDetail.asset.h3')}
          </h3>
          <AssetsSummary
            selectedAssetItemList={selectedAssetItemList}
            handleAssetsClear={handleAssetsClear}
          ></AssetsSummary>

          <h3 className="section-title">
            {getMessage('media.packageDetail.asset.filter.h3')}
          </h3>
          <AssetFilters _this={this}></AssetFilters>

          <ListingPage
            className="asset-table-wrap website-pages"
            title="&nbsp;"
            api={{
              url: '/martech-ad-service/search/assets',
              transform: (response) => response.data.content,
              params: {
                pageSize: 10,
              },
            }}
            emptyState={emptyState}
            tableProperties={tableProperties(
              isMobileView,
              selectedAssetItemList,
              handleAssetItemAdd,
              handleAssetItemRemove,
              toggleAssetBox,
              this
            )}
            primaryKey="id"
          />

          <div className="form-buttons">
            <SubmitButton>
              {getMessage('websitePage.form.submitText')}
            </SubmitButton>
            <CancelButton>
              {getMessage('websitePage.form.cancelText')}
            </CancelButton>
          </div>
        </Form>
      </div>
    )
  }
}

function getMobileView() {
  return window.screen.width <= 480
}
export default class MediaPackageDetail extends Component {
  constructor(props) {
    super(props)

    this.api = null
    this.state = {
      isLoaded: false,
      pageAction: 'add',
      isMobileView: getMobileView(),
      isOpenAssetBox: false,
      selectedAssetItemList: [],
      selectedAssetItemBU: '',
      data: {
        packageDescription: '',
        packageDuration: '',
        packageName: '',
        packageType: 'FIXED',
        packagePrice: '',
        expireDate: '',
        assetList: [],
      },
      selectedAssetData: {},
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.mobileView = this.mobileView.bind(this)
    this.handleAssetItemAdd = this.handleAssetItemAdd.bind(this)
    this.handleAssetItemRemove = this.handleAssetItemRemove.bind(this)
    this.handleAssetsClear = this.handleAssetsClear.bind(this)
    this.toggleAssetBox = this.toggleAssetBox.bind(this)
    this.throwError = this.throwError.bind(this)
    this._hideErrorDialog = this._hideErrorDialog.bind(this)
  }

  handleAssetItemAdd(data) {
    if (
      this.state.selectedAssetItemBU &&
      data.ownerBu !== this.state.selectedAssetItemBU
    ) {
      this.throwError('', getMessage('media.packageDetail.asset.error'))
    } else {
      const _assetList = this.state.data.assetList
      _assetList.push({
        id: data.id,
        buAssetId: data.buAssetId,
        assetName: data.assetName,
      })
      this.setState({
        selectedAssetItemList: [
          ...this.state.selectedAssetItemList,
          data.buAssetId,
        ],
        selectedAssetItemBU: data.ownerBu,
        data: {
          ...this.state.data,
          assetList: _assetList,
        },
      })
    }
  }

  handleAssetItemRemove(id) {
    if (this.state.selectedAssetItemList.length <= 1) {
      this.setState({
        selectedAssetItemBU: '',
      })
    }
    const _assetList = this.state.data.assetList
    _assetList.forEach((item, index) => {
      if (item.buAssetId === id) {
        _assetList.splice(index, 1)
        this.setState({
          data: {
            ...this.state.data,
            assetList: _assetList,
          },
        })
      }
    })
    this.setState({
      selectedAssetItemList: this.state.selectedAssetItemList.filter(
        (item) => item !== id
      ),
    })
  }
  handleAssetsClear() {
    this.setState({
      selectedAssetItemList: [],
      selectedAssetItemBU: '',
      data: {
        ...this.state.data,
        assetList: [],
      },
    })
  }

  toggleAssetBox(status, selectedAssetData) {
    this.setState({
      isOpenAssetBox: status === 1,
      selectedAssetData,
    })
  }

  handleSubmit(formData) {
    if (formData.assetList && formData.assetList.length > 0) {
      this.setState({
        isLoaded: false,
      })
      if (this.state.pageAction === 'edit') {
        this.api = new API({ url: APIurl + this.props.match.params.id })
        this.api
          .put(formData)
          .then(() => {
            updatePackages(this)
            this.props.history.goBack()
          })
          .catch((error) => {
            this.throwError(error)
          })
      } else {
        this.api = new API({ url: APIurl })
        this.api
          .post(formData)
          .then(() => {
            updatePackages(this)
            this.props.history.goBack()
          })
          .catch((error) => {
            this.throwError(error)
          })
      }
    } else {
      this.throwError('', getMessage('media.packageDetail.asset.error2'))
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({
        pageAction: 'edit',
      })

      this.api = new API({ url: APIurl + this.props.match.params.id })
      this.api
        .get()
        .then((response) => {
          const _selectedAssetItemList = []
          for (let i = 0; i < response.data.assetList.length; i++) {
            const _asset = response.data.assetList[i]
            _selectedAssetItemList.push(_asset.buAssetId)
          }

          const firstAsset = response.data.assetList[0]

          this.setState({
            selectedAssetItemBU: firstAsset.buAssetId.slice(0, 2),
            selectedAssetItemList: _selectedAssetItemList,
            isLoaded: true,
            data: {
              ...response.data,
            },
          })
        })
        .catch((error) => {
          this.throwError(error)
        })
    } else {
      this.setState({
        isLoaded: true,
        data: {
          ...this.state.data,
        },
      })
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  throwError(error, title) {
    this.setState({
      isLoaded: true,
      errorDialog: {
        shown: true,
        title: title || '',
        message: error ? error.message : '',
      },
    })
  }
  _hideErrorDialog() {
    this.setState({
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
    })
  }
  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }
  render() {
    const { menu } = this.props
    const { isMobileView, selectedAssetItemList, isOpenAssetBox } = this.state

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className="package-detail-page"
        title={getMessage('media.packageDetail.title.' + this.state.pageAction)}
      >
        <h1 className="title">
          {getMessage('media.packageDetail.title.' + this.state.pageAction)}
        </h1>

        {this.state.isLoaded ? (
          <MediaPackageForm
            isMobileView={isMobileView}
            value={this.state.data}
            selectedAssetItemList={selectedAssetItemList}
            handleAssetItemAdd={this.handleAssetItemAdd}
            handleAssetItemRemove={this.handleAssetItemRemove}
            handleAssetsClear={this.handleAssetsClear}
            toggleAssetBox={this.toggleAssetBox}
            onSubmit={this.handleSubmit}
            onCancel={() => {
              this.props.history.goBack()
            }}
          />
        ) : (
          <Loader />
        )}

        <Popup
          show={isOpenAssetBox}
          className="assetPreview-box"
          heading={getMessage('media.assetDetail.title')}
          close={() => {
            this.toggleAssetBox(2)
          }}
        >
          <AssetPreview assetData={this.state.selectedAssetData}></AssetPreview>
        </Popup>

        {this.state.errorDialog.shown ? (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={this.state.errorDialog.message}
            close={this._hideErrorDialog}
            closeText={getMessage('dialog.okText')}
          />
        ) : (
          ''
        )}
      </AuthenticatedPage>
    )
  }
}
