import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import UploadWidget from './UploadWidget'
import API from '../../../lib/api'
import { getSession } from '../../../lib/auth'
import Dialog from 'components/Popup/Dialog'
import Table, { Cell, Header, Row } from '../../../components/Table'
import Loader from '../../../components/Loader'
import JobsDropdown from './JobsDropdown'
import { getMessage } from 'lib/translator'
import BulkUploadPLSResultCard from './BulkUploadPLSResultCard';

const META_UPDATE_API_URL = '/inventory-service/product-meta-update'
const BULK_PRODUCT_LOCATION_UPDATE_URL = '/pls/v1/admin/locations/bulk'
const COLUMNS = ['Job ID', 'Status', 'Total Orders', 'Processed', 'Failed', '']
const sampleCSV = {
  'daily-preset-sample.csv':
    'data:text/csv;charset=utf-8,SKU,StoreID,DailyPreset,Variance,InventoryType\n' +
    '913807,12,100,100,DAILY_PRESET',
  'packinfo-sample.csv':
    'data:text/csv;charset=utf-8,SKU,PackSize,PackUnit,SAPUnit\n' +
    '913807,100,KG,KG',
  'plu-sample.csv':
    'data:text/csv;charset=utf-8,SKU,PLUNumber,DisplayUnit\n' +
    '913807,1234,100G',
  'picking-threshold-sample.csv':
    'data:text/csv;charset=utf-8,StoreID,SKU,OverPickingThreshold,ShortPickingThreshold\n' +
    '461,913807,1.1,0.9',
  'sku-location-sample.csv':
    'data:text/csv;charset=utf-8,StoreID,SKU,Aisle\n' +
    '461,913807,A1',
  'bulk-routing-threshold-sample.csv':
    'data:text/csv;charset=utf-8,StoreID,SKU,BulkThreshold,DeliveryDays,Status\n'+
    '461,13075707,991,2,ENABLED',
  'bulk-product-location-update-sample.csv':
    'data:text/csv;charset=utf-8,store_id,sku,location_type,location,secondary_location_expiry_date\n' +
    '461,13246713,primary,L26 / Spices,\n' +
    '461,13246713,secondary,Promotion Area 1,2024-03-30'
}

export default class BulkUploadSKUMeta extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isResultsLoading: true,
      jobsLoadErr: '',
      jobs: [],
      disableUpload: false,
      selectedJob: '',
      uploadError: '',
      successDialog: null,
      bulkUploadLocationsResult: null
    }
  }
  componentDidMount() {
    this.loadJobs(true)
  }
  tallyStatus = job => {
    const interval = setInterval(() => {
      const api = new API({ url: META_UPDATE_API_URL + '?jobid=' + job.jobID })
      api
        .get()
        .then(resp => {
          if (typeof resp === 'string') {
            resp = JSON.parse(resp)
          }
          if (resp.data[0].status === 'COMPLETED') {
            clearInterval(interval)
            this.setState({
              disableUpload: false,
            })
          }
          const Jobs = this.state.jobs.map(j => {
            if (j.jobID === job.jobID) {
              return resp.data[0]
            } else {
              return j
            }
          })
          this.setState({
            jobs: Jobs,
          })
        })
        .catch(() => {
          this.setState({
            disableUpload: true,
          })
          clearInterval(interval)
        })
    }, 500)
  }

  loadJobs = (isPageLoad) => {
    const api = new API({ url: META_UPDATE_API_URL })
    api
      .get()
      .then(resp => {
        if (typeof resp === 'string') {
          resp = JSON.parse(resp)
        }
        const data = resp.data.sort((a, b) => {
          return (
            parseInt(b.jobID.replace('SKUMETAUPDATE_', ''), 10) -
            parseInt(a.jobID.replace('SKUMETAUPDATE_', ''), 10)
          )
        })

        const toCheck = data.filter(item => {
          return item.status === 'IN PROGRESS'
        })

        let disableUpload = false
        if (toCheck.length > 0) {
          this.tallyStatus(toCheck[0])
          disableUpload = true
        }else if(!isPageLoad){
          this.tallyStatus(data[0])
          disableUpload = true
        }

        this.setState({
          isResultsLoading: false,
          jobs: data,
          disableUpload: disableUpload,
        })
      })
      .catch(err => {
        this.setState({
          isResultsLoading: false,
          jobs: [],
          jobsLoadErr: err.message,
        })
      })
  }

  handleResults = file => {
    this.setState({
      uploadError: '',
    })

    return new Promise((resolve, reject) => {
      if (this.state.selectedJob === '') {
        reject({ message: 'Select a job' })
        return
      }

      if (this.state.selectedJob === 'UpdateBulkProductLocation') {
        const plsData = new window.FormData();
        plsData.append('file', file)

        const api = new API({ url: BULK_PRODUCT_LOCATION_UPDATE_URL })
        api.post(plsData).then(res => {
          const { total, failure, success, errors } = res.data;
          this.setState({
            uploadedFile: null,
            bulkUploadLocationsResult: {
              total,
              failure,
              success,
              errors
            },
            successDialog: {
              title: 'Request Executed',
              message: failure > 0 ?
              `${success} locations have been updated. ${failure} locations failed, Please refer to error table for more details.`
              : `${success} locations have been updated successfully.`,
              type: failure > 0 ? 'info' : 'success'
            }
          })
          resolve()
        }).catch(e => {
          console.log(e)
          reject({ message: 'Error while submitting job' })
        })
      } else {
        const data = new window.FormData()
        data.append('file', file)
        data.append('userID', getSession().user.id)
        data.append('jobName', this.state.selectedJob)

        const api = new API({ url: META_UPDATE_API_URL })
        api.put(data)
          .then(resp => {
            if (typeof resp === 'string') {
              resp = JSON.parse(resp)
            }
            if (resp.code === 200 && resp.status === 'SUCCESS') {
              this.loadJobs(false)
            }

            this.setState({
              uploadedFile: null,
            })
            resolve()
          })
          .catch(err => {
            console.log(err) // eslint-disable-line
            reject({ message: 'Error while submitting job' })
          })
      }

    })
  }

  handleDownloadCSVReport = e => {
    const jobid = e.currentTarget.getAttribute('data-jobid')
    const result = this.state.jobs.filter(job => {
      return job.jobID === jobid
    })
    if (result.length > 0) {
      const csv = result[0].details.map(order => {
        return order.referenceNumber + ',' + order.remarks
      })
      e.currentTarget.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,reference number,remarks\n' +
          csv.join('\n')
      )
    }
  }

  handleDownload = e => {
    e.currentTarget.setAttribute(
      'href',
      sampleCSV[e.currentTarget.getAttribute('download')]
    )
  }
  handleSelectJob = e => {
    this.setState({
      selectedJob: e,
      validationError: false,
    })
  }

  render = () => {
    const { bulkUploadLocationsResult } = this.state;
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1>Bulk Update SKU Meta</h1>
        {this.state.successDialog && (
          <Dialog
            className={this.state.successDialog.type}
            show={!!this.state.successDialog}
            title={this.state.successDialog.title}
            information={this.state.successDialog.message}
            close={() => this.setState({ successDialog: null })}
            closeText={getMessage('dialog.okText')}
          />
        )}
        <JobsDropdown
          value={this.state.selectedJob}
          onChange={this.handleSelectJob}
        />

        <div className="batch-upload">
          {this.state.disableUpload ? (
            <div className="bubble">
              <div className="processing"></div>
              Update Job Running
            </div>
          ) : (
            <UploadWidget
              onUpload={this.handleResults}
              onDownload={this.handleDownload}
              disabled={this.state.disableUpload}
              uploadedFile={this.state.uploadedFile}
            />
          )}
        </div>

        {
          bulkUploadLocationsResult && bulkUploadLocationsResult.failure > 0 &&
          <BulkUploadPLSResultCard
            total={bulkUploadLocationsResult.total}
            success={bulkUploadLocationsResult.success}
            failure={bulkUploadLocationsResult.failure}
            errors={bulkUploadLocationsResult.errors}
          />
        }

        <h3>Update Jobs</h3>
        {this.state.isResultsLoading && <Loader />}

        {this.state.jobs.length > 0 && this.state.jobsLoadErr === '' && (
          <Table tableDynamic={false}>
            <Header items={COLUMNS} />

            {this.state.jobs.map(job => {
              return (
                <Row key={job.jobID}>
                  <Cell>
                    <span>{job.jobID}</span>
                  </Cell>
                  <Cell>
                    <span>{job.status}</span>
                  </Cell>
                  <Cell>
                    <span>{job.count.total}</span>
                  </Cell>
                  <Cell>
                    <span className="product-name">{job.count.processed}</span>
                  </Cell>
                  <Cell>
                    <span className="product-name">{job.count.failed}</span>
                  </Cell>
                  <Cell>
                    <a
                      onClick={this.handleDownloadCSVReport}
                      download={job.jobID + '.csv'}
                      data-jobid={job.jobID}
                    >
                      Download Report
                    </a>
                  </Cell>
                </Row>
              )
            })}
          </Table>
        )}
        {this.state.jobsLoadErr !== '' && (
          <div className="form-error">{this.state.jobsLoadErr}</div>
        )}
        {this.state.jobs.length === 0 && (
          <div className="bubble">No jobs Yet</div>
        )}
      </AuthenticatedPage>
    )
  }
}
