import API from '../../../lib/api'

function downloadBlob(url, fileName) {
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  link.click()
}

function downloadTemplateFromApi(url, filename) {
  const api = new API({ url: url })
  api.get({}, { Accept: 'text/csv' })
    .then(data => {
      const binaryData = []
      binaryData.push(data)
      const x = window.URL.createObjectURL(new Blob(binaryData, { type: 'text/csv' }))
      downloadBlob(x, filename)
    })
}

export {
  downloadTemplateFromApi
}
