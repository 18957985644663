import React, { Component } from 'react'
import Table, { Header, Row, Cell } from '../../../../../components/Table'
import { getMessage } from '../../../../../lib/translator'

class PackingDetails extends Component {
  render() {
    const details = this.props.packingDetails
    return (
      <div className="packing-details">
        <h3>{getMessage('order.details.packing.heading')}</h3>
        <Table>
          <Header>
            <Cell>{getMessage('order.packing.type')}</Cell>
            <Cell>{getMessage('order.packing.labels')}</Cell>
          </Header>
          {Object.keys(details).map((type, index) => (
            <Row key={`packing-item-${index}`}>
              <Cell>{getMessage(`order.packing.${type}`)}</Cell>
              <Cell>
                {(Array.isArray(details[type]) && details[type].join(', ')) ||
                  details[type]}
              </Cell>
            </Row>
          ))}
        </Table>
      </div>
    )
  }
}

export default PackingDetails
