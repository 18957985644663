import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Image from '../../../../../../components/Image'
import { getStoreName } from '../../../../../../containers/StoreSelector'
import { getMessage } from '../../../../../../lib/translator'

export default class OrderDetailsColumn extends Component {

  render() {
    const {
      orderTypeImages,
      pickupLocation,
      referenceNumber,
      store,
      type,
      url
    } = this.props
    return (
      <div className="container-order-number">
        <div>
          {type && (
            <div className="order-type-icons">
              <Image src={orderTypeImages[type]} />
            </div>
          )}
        </div>
        <div>
          <div>
            <Link
              className="order-number"
              to={`${url}/delivery-orders/${referenceNumber}`}
            >
              <span>
                <span className="text-muted prefix">
                  {getMessage(
                    'order.table.referenceNumber.prefix'
                  )}&nbsp;
                </span>
                {referenceNumber}
              </span>
            </Link>
          </div>
          {type.name === 'PICKUP' ? (
            <small className="text-muted store-name">
              {pickupLocation && pickupLocation.name}
            </small>
          ) : (
            store && (
              <small className="text-muted store-name">
                {getStoreName(store)}
              </small>
            )
          )}
        </div>
      </div>
    )
  }
}
