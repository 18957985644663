import React from 'react'
import { getMessage } from '../../../../../../lib/translator'
import './style.css'

const RefundedItems = () => {
  return (
    <div className="suborder-refunded-items-header">
      {getMessage('qc.refundedItems.heading')}
    </div>
  )
}

export default RefundedItems
