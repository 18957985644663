import React from 'react'
import moment from 'moment'
import { Select } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import { TimePicker, VALIDATION_TYPES } from '../../../../components/Form'
const handlingHoursOptions = Array(1000)
  .fill(0)
  .map((v, i) => ({
    text: '' + i,
    value: i,
  }))

const pickupLeadTimeOptions = Array(11)
  .fill(0)
  .map((v, i) => ({
    text: '' + i,
    value: i,
  }))

function getEarliestDeliverySlot(cutOffTime, handlingHours) {
  if (cutOffTime === void 0 || handlingHours === void 0) {
    return ''
  }
  const [cutOffTimeHour] = cutOffTime.split(':')
  const days = Math.floor((Number(cutOffTimeHour) + Number(handlingHours)) / 24)
  const hours = (Number(cutOffTimeHour) + Number(handlingHours)) % 24

  const hoursIn12Format = moment(`${hours}`, 'hh').format('h A')

  return `D+${days}, ${hoursIn12Format}`
}

const HandlingTimeComponent = ({
  storeId,
  index,
  generateStateMappers,
  cutOffTime,
  handlingHour,
  onInvalidCutoff,
  cutOffTimes,
  handlingHours,
}) => {
  const cutOffMappers = storeId
    ? generateStateMappers({
        stateKeys: [`cutOffTimes.${storeId}`, index],
        validationType: storeId && VALIDATION_TYPES.ONSUBMIT,
      })
    : {}
  const handlingHourMappers = storeId
    ? generateStateMappers({
        stateKeys: [`handlingHours.${storeId}`, index],
        validationType: VALIDATION_TYPES.ONSUBMIT,
        loseEmphasisOnFill: true,
      })
    : {}

  const pickupLeadTimeMappers = storeId
    ? generateStateMappers({
        stateKeys: [`pickupLeadTime.${storeId}`, index],
        validationType: VALIDATION_TYPES.ONSUBMIT,
        loseEmphasisOnFill: true,
        defaultValue: 0,
      })
    : {}

  const { onChange: onHandlingHourChange } = handlingHourMappers

  const handleHandlingHourChange = value => {
    const invalidCutoff = cutOffTimes.slice(0, index).some((cutoff, idx) => {
      if (
        Number(cutOffTimes[index].split(':')[0]) + Number(value) <=
        Number(cutoff.split(':')[0]) + Number(handlingHours[idx])
      ) {
        return true
      }
      return false
    })
    if (invalidCutoff) {
      onInvalidCutoff && onInvalidCutoff(storeId, index)
    } else {
      onHandlingHourChange(value)
    }
  }

  return (
    <div className={`handling-time-box flex`}>
      <TimePicker
        name="cutOffTime"
        key="cutOffTime"
        label={getMessage('Cut-Off Time')}
        placeholder={getMessage('offer.time.placeholder')}
        {...cutOffMappers}
        validationStrings={{
          valueMissing: getMessage('input.requiredMessage'),
        }}
        showSecond={false}
        minuteStep={60}
        required
        disabled={!storeId}
      />
      <div className="field">
        <span className="labelWrap">
          <label htmlFor="handling-hours-dropdown">Handling Hours</label>
        </span>
        <Select
          id="handling-hours-dropdown"
          name="handling-hours-dropdown"
          for="handling-hours-dropdown"
          placeholder="Hours"
          options={handlingHoursOptions}
          {...handlingHourMappers}
          onChange={handleHandlingHourChange}
          validationStrings={{
            valueMissing: getMessage('input.requiredMessage'),
          }}
          disabled={!storeId}
          required
        />
      </div>
      <div className="field delivery-slot">
        <div>
          <span className="labelWrap">
            <label>Earliest Delivery Slot</label>
          </span>
        </div>
        <div data-testid="delivery-slot">
          <label>
            <i>{getEarliestDeliverySlot(cutOffTime, handlingHour)}</i>
          </label>
        </div>
      </div>
      <div className="field">
        <span className="labelWrap">
          <label htmlFor="pickup-lead-time-dropdown">Pick-up Lead Time</label>
        </span>
        <Select
          id="pickup-lead-time-dropdown"
          name="pickup-lead-time-dropdown"
          for="pickup-lead-time-dropdown"
          type="number"
          placeholder="Days"
          options={pickupLeadTimeOptions}
          {...pickupLeadTimeMappers}
          validationStrings={{
            valueMissing: getMessage('input.requiredMessage'),
          }}
          disabled={!storeId}
          required
        />
      </div>
    </div>
  )
}
export default HandlingTimeComponent
