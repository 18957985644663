import React from 'react'
import {
  BaseForm,
  Select,
  SelectSearch,
} from '../../../../../components/Form/index'
import { getMessage } from '../../../../../lib/translator'
import { DateRangePicker } from '../../../../../components/Form'
import { isInclusivelyBeforeDay, isInclusivelyAfterDay } from 'react-dates'
import moment from 'moment'

// If you add a new filter kindly define state here to for applied filter count to work
export const filterList = [
  'orderDuration',
  'platform',
  'fulfillmentMethod',
  'orderState',
  'paymentState',
  'brandNames',
  'locationNames',
  'foodSupplierNames',
]

export const multiSelectFilters = [
  'brandNames',
  'locationNames',
  'foodSupplierNames',
]

const masterOrderStatus = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'PENDING',
    value: 'PENDING',
  },
  {
    text: 'CREATED',
    value: 'CREATED',
  },
  {
    text: 'CANCELLED',
    value: 'CANCELLED',
  },
  {
    text: 'COMPLETED',
    value: 'COMPLETED',
  },
]

const paymentStates = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'AUTHORIZATION PENDING',
    value: 'AUTHORIZATION_PENDING',
  },
  {
    text: 'AUTHORIZED',
    value: 'AUTHORIZED',
  },
  {
    text: 'AUTHORIZATION FAILED',
    value: 'AUTHORIZATION_FAILED',
  },
  {
    text: 'AUTHORIZATION VOID',
    value: 'AUTHORIZATION_VOID',
  },
  {
    text: 'CAPTURE FAILED',
    value: 'CAPTURE_FAILED',
  },
  {
    text: 'CAPTURED',
    value: 'CAPTURED',
  },
  {
    text: 'REFUNDED',
    value: 'REFUNDED',
  },
  {
    text: 'PARTIAL REFUND',
    value: 'PARTIAL_REFUND',
  },
  {
    text: 'REFUND PENDING',
    value: 'REFUND_PENDING',
  },
]

const fulfillmentMethods = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'Having here',
    value: 'having_here',
  },
  {
    text: 'Takeaway',
    value: 'self_collect',
  },
]

const platformType = [
  {
    text: 'FAIRPRICE',
    value: 'FAIRPRICE',
  },
]

export const qcFilterIsDateOutOfRange = day => {
  return (
    isInclusivelyBeforeDay(day, moment('2020-12-31')) ||
    isInclusivelyAfterDay(day, moment().add(1, 'day'))
  )
}

export default class OrderFiltersForm extends BaseForm {
  render() {
    /* eslint-disable */
    const { filterApiData } = this.props
    const { SubmitButton } = this.buttons
    const { Form } = this.components

    const brands = filterApiData['brands'] || []
    const locations = filterApiData['locations'] || []
    const foodsuppliers = filterApiData['foodsuppliers'] || []
    return (
      <Form>
        <div className="form-fields date-fields">
          <DateRangePicker
            className="section-row-input"
            label={getMessage('qc.order.filters.dateFrom')}
            {...this.generateStateMappers({
              stateKeys: ['orderDuration'],
              loseEmphasisOnFill: true,
            })}
            minimumNights={0}
            displayFormat="ddd, DD MMM YYYY"
            isOutsideRange={qcFilterIsDateOutOfRange}
            startDatePlaceholder={getMessage(
              'qc.order.filters.dateFrom.placeholder'
            )}
            endDatePlaceholder={getMessage(
              'qc.order.filters.dateTo.placeholder'
            )}
          />
          <SelectSearch
            name="brand-search"
            key="brand-search"
            autoComplete="off"
            label={getMessage('qc.order.filters.brands')}
            placeholder={getMessage('qc.order.filters.brands.placeholder')}
            multiple={true}
            options={brands}
            nameKey="text"
            valueKey="value"
            searchByName
            {...this.generateStateMappers({
              stateKeys: ['brandNames'],
              loseEmphasisOnFill: true,
            })}
            onChange={selectedValue => {
              selectedValue =
                selectedValue instanceof Array ? selectedValue : [selectedValue]
              let values = JSON.parse(JSON.stringify(this.state.values))
              values = {
                ...values,
                ['brandNames']: selectedValue.map(el => el.value),
              }
              this.setState({ values })
            }}
            value={(this.state.values['brandNames'] || [])
              .map(id => brands.find(opt => opt.value === id))
              .filter(Boolean)}
          />
          <SelectSearch
            name="locations-search"
            key="locations-search"
            autoComplete="off"
            label={getMessage('qc.order.filters.locations')}
            placeholder={getMessage('qc.order.filters.locations.placeholder')}
            multiple={true}
            options={locations}
            nameKey="text"
            valueKey="value"
            searchByName
            {...this.generateStateMappers({
              stateKeys: ['locationNames'],
              loseEmphasisOnFill: true,
            })}
            onChange={selectedValue => {
              selectedValue =
                selectedValue instanceof Array ? selectedValue : [selectedValue]
              let values = JSON.parse(JSON.stringify(this.state.values))
              values = {
                ...values,
                ['locationNames']: selectedValue.map(el => el.value),
              }
              this.setState({ values })
            }}
            value={(this.state.values['locationNames'] || [])
              .map(id => locations.find(opt => opt.value === id))
              .filter(Boolean)}
          />
          <SelectSearch
            name="foodSuppliers-search"
            key="foodSuppliers-search"
            autoComplete="off"
            label={getMessage('qc.order.filters.foodSuppliers')}
            placeholder={getMessage(
              'qc.order.filters.foodSuppliers.placeholder'
            )}
            multiple={true}
            options={foodsuppliers}
            nameKey="text"
            valueKey="value"
            searchByName
            {...this.generateStateMappers({
              stateKeys: ['foodSupplierNames'],
              loseEmphasisOnFill: true,
            })}
            onChange={selectedValue => {
              selectedValue =
                selectedValue instanceof Array ? selectedValue : [selectedValue]
              let values = JSON.parse(JSON.stringify(this.state.values))
              values = {
                ...values,
                ['foodSupplierNames']: selectedValue.map(el => el.value),
              }
              this.setState({ values })
            }}
            value={(this.state.values['foodSupplierNames'] || [])
              .map(id => foodsuppliers.find(opt => opt.value === id))
              .filter(Boolean)}
          />
          <Select
            label={getMessage('qc.order.filters.platform')}
            placeholder={getMessage('qc.order.filters.platform.placeholder')}
            name="Platform"
            options={platformType}
            {...this.generateStateMappers({
              stateKeys: ['platform'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('qc.order.filters.fulfillmentMethod')}
            placeholder={getMessage(
              'qc.order.filters.fulfillmentMethod.placeholder'
            )}
            name="fulfillmentMethod"
            options={fulfillmentMethods}
            {...this.generateStateMappers({
              stateKeys: ['fulfillmentMethod'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('qc.order.filters.orderState')}
            placeholder={getMessage('qc.order.filters.orderState.placeholder')}
            name="orderState"
            options={masterOrderStatus}
            {...this.generateStateMappers({
              stateKeys: ['orderState'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('qc.order.filters.paymentState')}
            placeholder={getMessage(
              'qc.order.filters.paymentState.placeholder'
            )}
            name="paymentStates"
            options={paymentStates}
            {...this.generateStateMappers({
              stateKeys: ['paymentState'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton testid="qc-search-filter-apply">
          {getMessage('qc.order.filters.applyText')}
        </SubmitButton>
      </Form>
    )
  }
}
