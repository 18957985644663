import React from 'react'
import { BaseForm, Input, VALIDATION_TYPES } from 'components/Form'
import Loader from 'components/Loader'
import { Dialog } from 'components/Popup'
import { getMessage } from 'lib/translator'
import { closeErrorMsgPopup, formatValidity } from 'components/AppHome/utils'
import Validity from 'components/AppHome/Validity'
import TilesForm from 'components/AppHome/AppTile/TilesForm'
import { formatTiles } from 'components/AppHome/AppTile/utils'
import './style.css'

const TILES_DEFAULT_VALUE = {
  tile1: {
    title: 'Grocery delivery',
    target: {
      deepLink: 'fairpriceonline://home',
    },
    service: 'grocery-online',
  },
  tile2: {
    title: 'Grocery in-store',
    target: {
      deepLink: 'fairprice://scanandgo/home?storeid=default&skipDelay=true',
    },
    service: 'grocery-instore',
  },
  tile3: {
    title: 'Food takeaway',
    target: {
      deepLink: 'fairprice://food',
    },
    service: 'food-c2c',
  },
  tile4: {
    title: 'Recipes',
    target: {
      deepLink: 'fairprice://recipes',
    },
    service: 'lifestyle',
  },
  tile5: {
    title: 'Rewards',
    target: {
      deepLink: 'fairprice://loyalty/home',
    },
    service: 'rewards',
  },
}

class ManageTilesForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showErrorDialog: false,
      errorMessage: null,
      readOnlyField: false,
      values: {
        startTime: '00:00:00',
        endTime: '00:00:00',
        upperTiles: {
          tile1: {
            title: 'Grocery delivery',
            target: {
              deepLink: 'fairpriceonline://home',
            },
            service: 'grocery-online',
          },
        },
        lowerTiles: {},
      },
    }

    this.handleTiles = this.handleTiles.bind(this)
  }

  async componentDidMount() {
    if (this.props.method === 'edit') {
      this.setState({ loading: true })
      const { value } = this.props
      value.id === 'tiles-Default' && this.setState({ readOnlyField: true })
      const validity = await formatValidity('api', value)
      const tiles = formatTiles('api', value)
      const values = {
        ...value,
        ...tiles,
        ...validity,
      }
      this.setState({ values, loading: false })
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const { onSubmit } = this.props
    const { values } = this.state
    if (this.isFormValid()) {
      const isValidTiles = Object.keys(values.upperTiles).length > 0
      if (isValidTiles) {
        const validity = await formatValidity('form', values)
        const tiles = formatTiles('form', values)
        await onSubmit({ ...values, ...validity, tiles })
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessage: getMessage('app.tile.missing'),
        })
      }
    }
  }

  handleTiles(item, position, action) {
    const values = Object.assign({}, this.state.values)
    if (action === 'add') {
      values[position][item] = JSON.parse(
        JSON.stringify(TILES_DEFAULT_VALUE[item])
      )
    }
    if (action === 'delete') {
      delete values[position][item]
      delete this.state.validations[position][item]
    }
    this.setState({ values, pressedSubmitWithCurrentData: false })
  }

  render() {
    const { Form } = this.components
    const { loading, readOnlyField, values } = this.state
    const { CancelButton, SubmitButton } = this.buttons

    return (
      <div className="apptiles-form">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            {this.state.showErrorDialog && (
              <Dialog
                show={this.state.showErrorDialog}
                information={this.state.errorMessage}
                close={() => closeErrorMsgPopup(this)}
                closeText={getMessage('apphome.okay')}
              />
            )}
            <Input
              label={getMessage('app.campaign.name')}
              placeholder={getMessage('app.campaign.name.placeholder')}
              type="text"
              testid="campaignName"
              className="campaignName"
              {...this.generateStateMappers({
                stateKeys: ['name'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              disabled={this.state.readOnlyField}
              required
            />
            <div className="tiles mb-1">
              <TilesForm
                tiles={values.upperTiles}
                position="upperTiles"
                handleTiles={this.handleTiles}
                generateStateMappers={this.generateStateMappers}
                items={['tile1', 'tile2']}
              />
              <TilesForm
                tiles={values.lowerTiles}
                position="lowerTiles"
                handleTiles={this.handleTiles}
                generateStateMappers={this.generateStateMappers}
                items={['tile3', 'tile4', 'tile5']}
              />
            </div>
            <div className="tileduration">
              <Validity
                readOnlyField={readOnlyField}
                generateStateMappers={this.generateStateMappers}
                type={this.props.value?.id}
              />
            </div>
            <div className="appFormButtons">
              <>
                <CancelButton>{getMessage('apphome.cancel')}</CancelButton>
              </>
              <SubmitButton>{getMessage('apphome.enable')}</SubmitButton>
            </div>
          </Form>
        )}
      </div>
    )
  }
}

export default ManageTilesForm
