import React, { Component } from 'react'
import {
  Polygon,
  DrawingManager,
  Marker,
  InfoWindow,
  GoogleMaps
} from '../../../../components/GoogleMaps'
import editIcon from './edit-icon.svg'
import editIconActive from './edit-icon-active.svg'
import undoIcon from 'icons/undo-icon.svg';
import { getStores } from '../../../../lib/auth'
import { get } from '../../../../lib/storage'
import { isEqual } from 'lodash'
import { getCoordindatesFromPolygon } from 'pages/operations/TimeslotBlocking/helpers.js';
import InfoBox from './InfoBox'
import './style.scss'

// color markers imports
import MarkerBlue from './icon-marker-blue.svg'
import MarkerDarkPurple from './icon-marker-darkPurple.svg'
import MarkerGreen from './icon-marker-green.svg'
import MarkerOliveGreen from './icon-marker-oliveGreen.svg'
import MarkerOrange from './icon-marker-orange.svg'
import MarkerPink from './icon-marker-pink.svg'
import MarkerPurple from './icon-marker-purple.svg'
import MarkerTeal from './icon-marker-teal.svg'
import MarkerYellow from './icon-marker-yellow.svg'
import MarkerRed from './icon-marker-red.svg'


function drawCircle(point, radius, dir, pts) {
  const d2r = Math.PI / 180 // degrees to radians
  const r2d = 180 / Math.PI // radians to degrees
  const earthsradius = 6371000 // 6371000 is the radius of the earth in meters

  const points = pts || 40 // we will get 40 points that lies on circle's circumference

  // find the radius in lat/lng
  const rlat = (radius / earthsradius) * r2d
  const rlng = rlat / Math.cos(point.lat * d2r)

  const extp = []
  let start = points + 1
  let end = 0
  if (dir === 1) {
    start = 0
    end = points + 1
  }
  for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
    const theta = Math.PI * (i / (points / 2))
    const ey = point.lng + rlng * Math.cos(theta) // center a + radius x * cos(theta)
    const ex = point.lat + rlat * Math.sin(theta) // center b + radius y * sin(theta)
    extp.push({ lat: ex, lng: ey })
  }
  return extp
}
const markerColors = [
  MarkerDarkPurple,
  MarkerBlue,
  MarkerGreen,
  MarkerOliveGreen,
  MarkerOrange,
  MarkerPink,
  MarkerPurple,
  MarkerTeal,
  MarkerYellow,
  MarkerRed
]

const markerToColorMappings = {
  darkPurple: MarkerDarkPurple,
  blue: MarkerBlue,
  green: MarkerGreen,
  oliveGreen: MarkerOliveGreen,
  orange: MarkerOrange,
  pink: MarkerPink,
  purple: MarkerPurple,
  teal: MarkerTeal,
  yellow: MarkerYellow,
  red: MarkerRed
}

class MarkerWithInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.infoOpen || false,
    }
    this.closeInfoWindow = this.closeInfoWindow.bind(this)
    this.openInfoWindow = this.openInfoWindow.bind(this)
    this.handlePincodeClick = this.handlePincodeClick.bind(this)
  }

  closeInfoWindow() {
    this.setState({
      isOpen: false,
    })
  }

  openInfoWindow() {
    this.setState({
      isOpen: true,
    })
  }
  handlePincodeClick(groupId) {
    this.props.handlePincodeEdit(groupId)
  }
  render() {
    const { location, MarkerIcon, groupId, editing, radial } = this.props
    return (
      <Marker
        icon={MarkerIcon}
        position={location.location}
        onClick={
          editing && !radial && groupId
            ? () => {
                this.handlePincodeClick(groupId)
              }
            : this.openInfoWindow
        }
      >
        {this.state.isOpen && (
          <InfoWindow onCloseClick={this.closeInfoWindow}>
            <div>
              <strong>{location.pincode}</strong>
            </div>
          </InfoWindow>
        )}
      </Marker>
    )
  }
}

export default class CommonMapComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pincodeLocations: [],
      locationsGroups: [],
      polygonLocationZones: [],
      radialZones: [],
      mapMounted: !!window.google,
      center: null
    }
    this.polygonRefs = {}
    this._isMounted = true
    this.onMapMounted = this.onMapMounted.bind(this)
    this.formatDataForAllZones = this.formatDataForAllZones.bind(this)
    this.getLatLngPoints = this.getLatLngPoints.bind(this)
    this.getDefaultLocation = this.getDefaultLocation.bind(this)
    this.setFitBounds = this.setFitBounds.bind(this)
    this.setCenter = this.setCenter.bind(this)
    this.getCenter = this.getCenter.bind(this)
    this.handlePolygonClicked = this.handlePolygonClicked.bind(this);
    ['onPolygonComplete', 'setPolygonRef', 'updatePolygon'].forEach(fn => {
      this[fn] = this[fn].bind(this)
    })
  }
  componentDidMount() {
    const allZonesLocations = this.formatDataForAllZones()
    this.setState(allZonesLocations);
  }
  formatDataForAllZones(props = this.props) {
    const { deliveryArea } = props
    const pincodeLocations = []
    const polygonLocationZones = []
    const radialZones = []
    deliveryArea &&
      deliveryArea.map(zone => {
        const polygonLocations = []
        const radialZone = zone.configType === 'RADIAL' ? zone.area : null
        zone.configType === 'PINCODE' &&
          pincodeLocations.push({
            pincodes: zone.area.pincodes,
            id: zone.id,
            tempId: zone.tempId,
            metadata: zone?.metadata
          })
        zone.configType === 'POLYGON' &&
          zone.area.locations.forEach((location, index) => {
            polygonLocations[index] = {
              lat: parseFloat(location['latitude']),
              lng: parseFloat(location['longitude']),
            }
          })
        if (radialZone) {
          radialZone.id = zone.id
          radialZone.tempId = zone.tempId
        }
        if (radialZone && radialZone.latitude) {
          radialZone.markerLocation = {
            lat: parseFloat(radialZone.latitude),
            lng: parseFloat(radialZone.longitude),
          }
        }
        if (
          radialZone &&
          (radialZone.startRadius || radialZone.startRadius === 0) &&
          radialZone.endRadius &&
          radialZone.unit
        ) {
          radialZone.formattedStartRadius =
            radialZone.startRadius *
            (radialZone.unit === 'KILOMETRE' ? 1000 : 1609.34)
          radialZone.formattedEndRadius =
            radialZone.endRadius *
            (radialZone.unit === 'KILOMETRE' ? 1000 : 1609.34)
        }
        radialZone && radialZones.push(radialZone)
        polygonLocations.length &&
          polygonLocationZones.push({
            id: zone.id,
            tempId: zone.tempId,
            name: zone.name,
            points: polygonLocations,
            metadata: zone?.metadata
          })
        return null
      })
    return { pincodeLocations, polygonLocationZones, radialZones }
  }
  getDefaultLocation() {
    let defaultCenter = {}
    const stores = getStores()
    if (stores && get('store')) {
      const store = stores.filter(
        s => Number(s.id) === Number(get('store'))
      )[0]
      if (store && store.latitude && store.longitude) {
        defaultCenter = {
          lat: Number(store.latitude),
          lng: Number(store.longitude),
        }
      }
    }
    return defaultCenter
  }
  onMapMounted(ref) {
    this.map = ref
    this.setState({ mapMounted: true })
    if (this._isMounted && window.google) {
      this.setCenter()
      this.setFitBounds()
    }
  }
  // onSearchBoxMounted (ref) {
  //   this.searchBox = ref
  // }
  onPolygonComplete(polygon) {
    const path = polygon.getPath()
    const paths = []
    for (let i = 0; i < path.getLength(); i++) {
      const xy = path.getAt(i)
      paths.push({ lat: xy.lat(), lng: xy.lng() })
    }
    polygon.setMap(null)
    this.props.setNewLocations({ locations: paths })
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevProps = this.props
    const prevArea = prevProps.deliveryArea
    const nextArea = nextProps.deliveryArea
    const editingCancel =
      prevProps.editing === true && nextProps.editing === false
    const prevPincodes = prevProps.deliveryArea.filter(
      zone => zone.configType === 'PINCODE'
    )
    const nextPincodes = nextProps.deliveryArea.filter(
      zone => zone.configType === 'PINCODE'
    )
    const nextPolygons = nextProps.deliveryArea.filter(
      zone => zone.configType === 'POLYGON'
    )
    const prevPolygons = prevProps.deliveryArea.filter(
      zone => zone.configType === 'POLYGON'
    )

    const areaChanged = !isEqual(prevArea, nextArea)
    const pincodesChanged = !isEqual(prevPincodes, nextPincodes)
    const polygonChanged = !isEqual(prevPolygons, nextPolygons)
    const allZonesLocations = this.formatDataForAllZones(nextProps)
    areaChanged &&
      this.setState(allZonesLocations, () => {
        if (window.google && pincodesChanged) {
          this.geocodeAddress(this.state.pincodeLocations)
        }
        // if polygon changes dont re-calculate
        if (!nextProps.selectedRuleId && !polygonChanged || (polygonChanged && !prevPolygons.length)) {
          this.setCenter()
          this.setFitBounds()
        }
      })
    if (editingCancel) {
      this.setCenter()
      this.setFitBounds()
    }
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  componentDidUpdate(prevProps) {
    if (this.props.selectedRuleId !== prevProps.selectedRuleId) {
      const selectedZone = this.props.deliveryArea.find(x => x.id === this.props.selectedRuleId);
      if (selectedZone && selectedZone.metadata.slotRuleType === 'CLUSTER') {
        const coordinates = getCoordindatesFromPolygon(selectedZone.metadata.polygon);
        this.setCenter(coordinates)
        this.setFitBounds(coordinates)
      } else if (selectedZone && selectedZone.metadata.slotRuleType === 'POSTAL_CODE') {
        const coordinates = selectedZone.area.pincodes.flatMap(x => (
          {lat: x.coordinate.lat, lng: x.coordinate.lng}
        ))
        this.setCenter(coordinates)
        this.setFitBounds(coordinates)
      }
    }
  }

  getLatLngPoints() {
    const currentState = Object.assign({}, this.state)
    const { locationsGroups, polygonLocationZones, radialZones } = currentState
    const points = []
    locationsGroups.map(pinCodeZone => {
      pinCodeZone.locations.map(pincode => {
        points.push({
          lat: Number(pincode.location.lat),
          lng: Number(pincode.location.lng),
        })
        return null
      })
      return null
    })
    polygonLocationZones.map(polygonZone => {
      polygonZone.points.map(point => {
        points.push(point)
        return null
      })
      return null
    })
    radialZones.map(zone => {
      points.push(
        ...drawCircle(zone.markerLocation, zone.formattedEndRadius, 1, 4)
      )
      points.push(
        ...drawCircle(zone.markerLocation, zone.formattedEndRadius, -1, 4)
      )
      return null
    })
    return points
  }

  getCenter(coordinates) {
    const points = this.getLatLngPoints()
    if(coordinates) {
      const bounds = new window.google.maps.LatLngBounds()
      for (let i = 0; i < coordinates.length; i++) {
        bounds.extend(coordinates[i])
      }
      return bounds.getCenter()
    } else if (points && points.length > 0 && window.google) {
      const bounds = new window.google.maps.LatLngBounds()
      for (let i = 0; i < points.length; i++) {
        bounds.extend(points[i])
      }
      return bounds.getCenter()
    }
    return this.getDefaultLocation()
  }

  setCenter(coordinates) {
    const center = this.getCenter(coordinates)
    center && this.setState({ center })
  }

  setFitBounds(coordinates) {
    const points = this.getLatLngPoints()
    if (coordinates) {
      const bounds = new window.google.maps.LatLngBounds()
      for (let i = 0; i < coordinates.length; i++) {
        bounds.extend(coordinates[i])
      }
      this.map && this.map.fitBounds(bounds)
    } else if (points && points.length > 0 && window.google) {
      const bounds = new window.google.maps.LatLngBounds()
      for (let i = 0; i < points.length; i++) {
        bounds.extend(points[i])
      }
      this.map && this.map.fitBounds(bounds)
    }
  }

  geocodeAddress(pincodeLocations) {
    const geocoder = new window.google.maps.Geocoder()
    const promises = []
    const locationsGroups = []
    pincodeLocations &&
      pincodeLocations.map(pincodeGroup => {
        const locations = []
        pincodeGroup.pincodes.forEach(pincode => {
        const location = {
          pincode: pincode.pincode,
          id: pincode.id,
        }
        // if there is coordinate (from address-service), use that instead of the coordinate from Google geocoder
        if(pincode.coordinate) {
          locations.push({
            ...location,
            location: pincode.coordinate,
          })
        } else {
            const promise = new Promise(resolve => {
              geocoder.geocode(
                { address: `${pincode.pincode.toString()}+Singapore` },
                (results, status) => {
                  if (status === window.google.maps.GeocoderStatus.OK) {
                    locations.push({
                      ...location,
                      location: {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                      },
                    })
                  }
                  resolve()
                }
              )
            })
            promises.push(promise)
          }
        })

        locationsGroups.push({
          id: pincodeGroup.id,
          tempId: pincodeGroup.tempId,
          locations: locations,
        })
        return null
      })
      Promise.all(promises).then(() => {

      this.setState(
        {
          locationsGroups,
        },
        () => {
          if (!this.props.selectedRuleId) {
            this.setCenter()
            this.setFitBounds()
          }
        }
      )
    })
  }
  updatePolygon(polygonPath, id) {
    const path = polygonPath
    const paths = []
    for (let i = 0; i < path.getLength(); i++) {
      const xy = path.getAt(i)
      paths.push({ lat: xy.lat(), lng: xy.lng() })
    }
    this.props.setNewEditedLocations({ locations: paths, id: id })
  }
  setPolygonRef(polygon, id) {
    if (polygon) {
      this.polygonRefs[id] = polygon.getPath()
      const currentPolygon = polygon.getPath()

      window.google.maps.event.addListener(currentPolygon, 'set_at', () => {
        this.updatePolygon(currentPolygon, id)
      })
      window.google.maps.event.addListener(currentPolygon, 'insert_at', () => {
        this.updatePolygon(currentPolygon, id)
      })
      window.google.maps.event.addListener(currentPolygon, 'remove_at', () => {
        this.updatePolygon(currentPolygon, id)
      })
    }
  }
  handlePolygonClicked(polygonZone) {
    // if there is no metadata, there is no info to display
    if (!polygonZone.metadata) {
      return;
    }
    this.props?.handleMapInfoBoxData({
      ...polygonZone.metadata,
      ruleId: polygonZone.id
    });
  }

  getPolygons() {
    const { polygonLocationZones } = this.state;
    const { editing, distinctColors, colorsMappedToRules } = this.props
    const polygonOptions = {
      fillColor: '#B53146',
      editable: false,
      strokeColor: '#FF0000',
      strokeOpacity: 1,
      strokeWeight: 1,
      fillOpacity: 0.2,
    }
    return polygonLocationZones.map((polygonZone, index) => {
      const isSelected = polygonZone.id === this.props.selectedRuleId;
      const zoneColor = (colorsMappedToRules && polygonZone.id in colorsMappedToRules) ?
        colorsMappedToRules[polygonZone.id].value
        : distinctColors[index % distinctColors.length].value
      return (
        polygonZone.points.length > 0 && (
          <Polygon
            key={`polygonal${polygonZone.id}`}
            data-testid={polygonZone.id}
            path={polygonZone.points}
            onClick={() => this.handlePolygonClicked(polygonZone)}
            onLoad={ref =>
              this.setPolygonRef(
                ref,
                polygonZone.id || polygonZone.tempId
              )
            }
            onUnmount={(polygon) => {
              window.google.maps.event.clearInstanceListeners(polygon.getPath())
            }}

            options={{
              ...polygonOptions,
              editable: editing,
              strokeColor: isSelected ? '#1454B8' : '#000000',
              strokeWeight: isSelected ? 2 : 0.5,
              fillOpacity: isSelected ? 0.4 : 0.2,
              fillColor: zoneColor
            }}
          />
        )
      )
    })
  }

  getRadialPolygons() {
    const { radialZones } = this.state;
    const defaultCenter = this.getDefaultLocation();
    const { handleRadialZoneEdit, distinctColors } = this.props;

    return radialZones.map((radialZone, index) => {
      const center = radialZone.markerLocation || defaultCenter
      const {
        formattedStartRadius: startRadius,
        formattedEndRadius: endRadius,
        id,
        tempId,
      } = radialZone
      return (
        <Polygon
          key={`radial${index}`}
          paths={[
            drawCircle(center, endRadius, 1),
            drawCircle(center, startRadius, -1),
          ]}
          options={{
            strokeColor:
              distinctColors[
                distinctColors.length -
                  1 -
                  (index % distinctColors.length)
              ].value,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor:
              distinctColors[
                distinctColors.length -
                  1 -
                  (index % distinctColors.length)
              ].value,
            fillOpacity: 0.35,
          }}
          onClick={() => {
            handleRadialZoneEdit(id || tempId)
          }}
        />
      )
    })
  }

  render() {
    const { locationsGroups, polygonLocationZones, radialZones } = this.state
    const {
      editing,
      changeEditMode,
      handlePincodeEdit,
      showEditButton,
      showUndoLastDrawnZoneIcon,
      triggerUndoLastDrawnZone,
      mapInfoBoxData,
      setMapInfoBoxData,
      handleMapInfoBoxData,
      colorsMappedToRules
    } = this.props
    const selectedLocationMarker = this.props.selectedLocation && (
      <Marker
        position={this.props.selectedLocation}
        draggable
        onDragEnd={e => {
          this.props.setNewLocation(e.latLng.lat(), e.latLng.lng())
        }}
      />
    )

    let markers = []
    locationsGroups.map((group, i) => {
      group.locations.map((location, index) => {
        let pinColor = markerColors[(i % 6) + 1]
        if (colorsMappedToRules && Object.keys(colorsMappedToRules).length > 0 && this.state.pincodeLocations?.length === 1) {
          const color = colorsMappedToRules[this.state.pincodeLocations[0].id].color;
          pinColor = markerToColorMappings[color];
        }
        markers.push(
          <MarkerWithInfo
            handlePincodeEdit={handlePincodeEdit}
            editing={editing}
            groupId={group.id || group.tempId}
            pincodeId={location.id}
            key={`pincode${index}${i}`}
            MarkerIcon={pinColor}
            location={location}
          />
        )
        return null
      })
      return null
    })
    markers = markers.length ? markers : null

    const polygons = polygonLocationZones.length > 0
        ? this.getPolygons()
        : null

    const radialPolygons = radialZones.length > 0
        ? this.getRadialPolygons()
        : null

    let radialMarkers = []
    radialZones.map((radialZone, index) => {
      radialMarkers.push(
        <MarkerWithInfo
          radial
          key={`radial-marker-${index}`}
          MarkerIcon={markerColors[0]}
          location={{
            location: radialZone.markerLocation,
            pincode: 'radial center',
          }}
        />
      )
      return null
    })
    radialMarkers = radialMarkers.length ? radialMarkers : null
    return (
      <React.Fragment>
        { mapInfoBoxData &&
          <InfoBox
            closeInfoBox={() => {
              setMapInfoBoxData(null);
              handleMapInfoBoxData(null);
            }}
            infoBoxData={mapInfoBoxData}/>
        }
        <GoogleMaps
          onMapMounted={this.onMapMounted}
          zoom={15}
          center={this.state.center || this.getDefaultLocation()}
          containerClassName="map-element"
          defaultOptions={{
            mapTypeControl: false,
            fullscreenControl: true,
            fullscreenControlOptions: {
              position: 1,
            },
          }}
        >
          {editing && (
            <DrawingManager
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  position: 2, // window.google.maps.ControlPosition.TOP_CENTER
                  drawingModes: ['polygon'],
                },
                polygonOptions: {
                  fillColor: '#7ac8ed',
                  fillOpacity: 0.4,
                  strokeOpacity: 0.8,
                  strokeWeight: 1,
                },
              }}
              onPolygonComplete={this.onPolygonComplete}
            />
          )}
          {markers}
          {polygons}
          {radialPolygons}
          {radialMarkers}
          {editing && selectedLocationMarker}
        </GoogleMaps>
        {showEditButton &&
          <button className="edit-icon" title="Edit" onClick={() => (editing ? null : changeEditMode())}>
            <img src={editing ? editIconActive : editIcon} alt="" />
          </button>
        }
        {showUndoLastDrawnZoneIcon &&
          <button className="edit-icon" title="Undo" onClick={() => triggerUndoLastDrawnZone()}>
            <img src={undoIcon} alt="undo" />
          </button>
        }
      </React.Fragment>
    )
  }
}
