import React from 'react'
import { getMessage } from '../../../../lib/translator'
import {
  BaseForm,
  CheckboxGroup,
  Radio,
  TimePicker,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import './style.css'


var DateMap = {
  'MONDAY': {
    rank: 1,
    eventType: "DAY_OF_THE_WEEK",
  },
  'TUESDAY': {
    rank: 2,
    eventType: "DAY_OF_THE_WEEK",
  },
  'WEDNESDAY': {
    rank: 3,
    eventType: "DAY_OF_THE_WEEK",
  },
  'THURSDAY': {
    rank: 4,
    eventType: "DAY_OF_THE_WEEK",
  },
  'FRIDAY': {
    rank: 5,
    eventType: "DAY_OF_THE_WEEK",
  },
  'SATURDAY': {
    rank: 6,
    eventType: "DAY_OF_THE_WEEK",
  },
  'SUNDAY': {
    rank: 7,
    eventType: "DAY_OF_THE_WEEK",
  },
  'PH': {
    rank: 8,
    eventType: "HOLIDAY",
  },
  'PH_EVE': {
    rank: 9,
    eventType: "HOLIDAY",
  },
  'CNY': {
    rank: 10,
    eventType: "HOLIDAY",
  }
}

var assetDayGroups = [
  {
    text: getMessage('Monday'),
    value: 'MONDAY',
    eventType: 'DAY_OF_THE_WEEK',
    rank: 1,
  },
  {
    text: getMessage('Tuesday'),
    value: 'TUESDAY',
    eventType: 'DAY_OF_THE_WEEK',
    rank: 2,
  },
  {
    text: getMessage('Wednesday'),
    value: 'WEDNESDAY',
    eventType: 'DAY_OF_THE_WEEK',
    rank: 3,
  },
  {
    text: getMessage('Thursday'),
    value: 'THURSDAY',
    eventType: 'DAY_OF_THE_WEEK',
    rank: 4,
  },
  {
    text: getMessage('Friday'),
    value: 'FRIDAY',
    eventType: 'DAY_OF_THE_WEEK',
    rank: 5,
  },
  {
    text: getMessage('Saturday'),
    value: 'SATURDAY',
    eventType: 'DAY_OF_THE_WEEK',
    rank: 6,
  },
  {
    text: getMessage('Sunday'),
    value: 'SUNDAY',
    eventType: 'DAY_OF_THE_WEEK',
    rank: 7,
  },
  {
    text: 'PH',
    value: 'PH',
    eventType: "HOLIDAY",
    rank: 8,
  },
  {
    text: 'PH EVE',
    value: 'PH_EVE',
    eventType: "HOLIDAY",
    rank: 9,
  },
  {
    text: 'CNY',
    value: 'CNY',
    eventType: "HOLIDAY",
    rank: 10,
  },
]


function compare(property) {
  return function (a, b) {
    var value1 = a[property];
    var value2 = b[property];
    return value1 - value2;
  }
}

function checkOperatingCalendar(assetOperatingCalendarList) {
  const _list = JSON.parse(JSON.stringify(assetOperatingCalendarList))
  const checklist = []
  _list.forEach(item => {
    checklist.push(item.eventDay)
  })
  assetDayGroups.forEach(day => {
    const isExist = checklist.indexOf(day.value)
    if (isExist < 0) {
      _list.push({
        "eventDay": day.value,
        "eventType": DateMap[day.value].eventType,
        "startTime": "00:00:00",
        "endTime": "23:59:00",
        "status": "ACTIVE",
      })
    }
  })
  _list.forEach((calendar, index) => {
    _list[index].rank = DateMap[calendar.eventDay].rank
  })
  return _list.sort(compare('rank'))
}

function initOperatingCalendarList(_this){
  const _list = JSON.parse(JSON.stringify(assetDayGroups))
  const assetOperatingCalendarList = []
  _list.forEach(item => {
    assetOperatingCalendarList.push({
      "eventDay": item.value,
      "eventType": item.eventType,
      "startTime": "00:00:00",
      "endTime": "23:59:00",
      "status": "ACTIVE"
    })
  })
  _this.setState({
    assetOperatingCalendarList,
  })
}

export {
  DateMap,
  checkOperatingCalendar,
  initOperatingCalendarList
}

export default class AssetTimeForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      values: {
        operatingList: [],
        status: 'ACTIVE',
        startTime: '00:00',
        endTime: '23:59',
      }
    }
    const _assetDayGroupsArr = []
    assetDayGroups.forEach((DayItem) => {
      _assetDayGroupsArr.push(DayItem.value)
    })
    this.state.values.operatingList = _assetDayGroupsArr
  }

  componentDidMount() {

    const _list = assetDayGroups
    const _operatingList = []
    _list.forEach((_ListItem, _ListIndex) => {
      _operatingList.push(_ListItem.value)
    })

    this.setState(prevState => {
      const newState = Object.assign({}, prevState)
      newState.values.operatingList = _operatingList
      return newState
    })

  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    return (
      <Form className="website-page-form">
        <div className="section-wrap">
          <div className="section-row">
            <CheckboxGroup
              label={getMessage('media.assetDetail.operatingHour.days')}
              options={assetDayGroups}
              required
              {...this.generateStateMappers({
                stateKeys: ['operatingList'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage(
                  'media.assetDetail.operatingHour.days.requiredMessage'
                ),
              }}
            />
          </div>
          <div className='section-row'>
            <Radio
              className='section-row-input'
              label={getMessage('media.assetDetail.operatingHour.status')}
              name="status"
              options={[
                {
                  text: getMessage('media.assetDetail.operatingHour.status.close'),
                  value: 'INACTIVE',
                },
                {
                  text: getMessage('media.assetDetail.operatingHour.status.open'),
                  value: 'ACTIVE',
                },
              ]}
              {...this.generateStateMappers({
                stateKeys: ['status'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage(
                  'media.assetDetail.operatingHour.status.requiredMessage'
                ),
              }}
            />

            {this.state.values.status === 'ACTIVE' ? (
              <div className='section-row-input'>
                <TimePicker
                  className='section-row-input-half'
                  name="startTime"
                  label={getMessage('media.assetDetail.operatingHour.validFromTime')}
                  key="start-time"
                  required
                  showSecond={false}
                  minuteStep={1}
                  placeholder={getMessage('media.assetDetail.operatingHour.timeplaceholder')}
                  {...this.generateStateMappers({
                    stateKeys: ['startTime'],
                    loseEmphasisOnFill: true,
                  })}
                />
                <TimePicker
                  className='section-row-input-half'
                  name="endTime"
                  label={getMessage('media.assetDetail.operatingHour.validToTime')}
                  key="end-time"
                  required
                  showSecond={false}
                  minuteStep={15}
                  placeholder={getMessage('media.assetDetail.operatingHour.timeplaceholder')}
                  {...this.generateStateMappers({
                    stateKeys: ['endTime'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            ) : ''}
          </div>


          <div className='buttons'>
            <SubmitButton>
              {getMessage('websitePage.dialogs.disable.okText')}
            </SubmitButton>
            <CancelButton>
              {getMessage('websitePage.form.cancelText')}
            </CancelButton>
          </div>
        </div>
      </Form>
    )
  }
}