import React from 'react'
import { getMessage } from '../../../../lib/translator'
import Loader from '../../../../components/Loader'
import {
  BaseForm,
  Input, Select, MultiSelect,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import { creativeReqMediaTypeList, creativeReqTypeList } from './creativeReqList'


function getMobileView () {
  return window.screen.width <= 480
}

export default class CreativeReqDetail extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: this.props.creativeReqDetailDialog?.data,
      isMobileView: getMobileView(),
    }
    this.mobileView = this.mobileView.bind(this)
  }
  mobileView () {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount () {
    window.addEventListener('resize', this.mobileView, false)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.mobileView, false)
    this.api && this.api.cancel()
  }

  componentWillReceiveProps(newProps) {
    this.setState(prevState => {
      const newState = Object.assign({}, prevState)
      newState.values = newProps.creativeReqDetailDialog?.data
      return newState
    })
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const { creativeReqDetailDialog } = this.props

    return (
      <div className='website-page-edit creative-req-detail-form'>
        {creativeReqDetailDialog?.loading ? (
          <Loader />
        ) : (
          <Form className='website-page-form'>
            <div className='field editip'>{getMessage('media.creativeReqManagement.detail.editip')}</div>
            <div className='section-wrap'>
              <div className='section-row'>
                <Input
                  className='section-row-input'
                  label={getMessage('media.creativeReqManagement.detail.name')}
                  placeholder={getMessage('media.creativeReqManagement.detail.name.placeholder')}
                  name='creativeName'
                  type='text'
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['name'],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  validationStrings={{
                    valueMissing: getMessage(
                      'media.creativeReqManagement.detail.name.requiredMessage',
                    ),
                  }}
                />
                <Select
                  className="section-row-input"
                  name="creativeType"
                  label={getMessage('media.creativeReqManagement.detail.type')}
                  placeholder={getMessage('media.creativeReqManagement.detail.type.placeholder')}
                  options={creativeReqTypeList}
                  {...this.generateStateMappers({
                    stateKeys: ['required_creative_type'],
                    loseEmphasisOnFill: true
                  })}
                  validationStrings={{
                    valueMissing: getMessage(
                      'media.creativeReqManagement.detail.type.requiredMessage',
                    ),
                  }}
                />
              </div>
              <div className='section-row'>
                <Input
                  className='section-row-input'
                  label={getMessage('media.creativeReqManagement.detail.height')}
                  placeholder={getMessage('media.creativeReqManagement.detail.height.placeholder')}
                  name='creativeHeight'
                  type='number'
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['height'],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  validationStrings={{
                    valueMissing: getMessage(
                      'media.creativeReqManagement.detail.height.requiredMessage',
                    ),
                  }}
                />
                <Input
                  className='section-row-input'
                  label={getMessage('media.creativeReqManagement.detail.width')}
                  placeholder={getMessage('media.creativeReqManagement.detail.width.placeholder')}
                  name='creativeWidth'
                  type='number'
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['width'],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  validationStrings={{
                    valueMissing: getMessage(
                      'media.creativeReqManagement.detail.width.requiredMessage',
                    ),
                  }}
                />
              </div>
              <div className='section-row'>
                <Input
                  className='section-row-input'
                  label={getMessage('media.creativeReqManagement.detail.size')}
                  placeholder={getMessage('media.creativeReqManagement.detail.size.placeholder')}
                  name='creativeSize'
                  type='number'
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['size'],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  validationStrings={{
                    valueMissing: getMessage(
                      'media.creativeReqManagement.detail.size.requiredMessage',
                    ),
                  }}
                />
                <MultiSelect
                  className="section-row-input"
                  name="creativeFormat"
                  label={getMessage('media.creativeReqManagement.detail.format')}
                  placeholder={getMessage('media.creativeReqManagement.detail.format.placeholder')}
                  options={creativeReqMediaTypeList}
                  {...this.generateStateMappers({
                    stateKeys: ['supported_formats'],
                  })}
                />
              </div>
              {this.state.values?.required_creative_type !== "SINGLE_IMAGE_AD" &&
                <div className='section-row'>
                  <Input
                    className='section-row-input'
                    label={getMessage('media.creativeReqManagement.detail.duration')}
                    placeholder={getMessage('media.creativeReqManagement.detail.duration.placeholder')}
                    name='creativeDuration'
                    type='number'
                    {...this.generateStateMappers({
                      stateKeys: ['duration'],
                      loseEmphasisOnFill: true,
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                    validationStrings={{
                      valueMissing: getMessage(
                        'media.creativeReqManagement.detail.duration.requiredMessage',
                      ),
                    }}
                  />
                </div>
              }
            </div>

            <div className='form-buttons'>
              <SubmitButton>
                {getMessage('websitePage.form.submitText')}
              </SubmitButton>
              <CancelButton>
                {getMessage('websitePage.form.cancelText')}
              </CancelButton>
            </div>
          </Form>
        )}
      </div>
    )
  }
}
