import React from 'react'
import { Upload } from '../../Form'
import { getMessage } from 'lib/translator'

const IMAGES = ['landscapeBanner', 'banner']

const ImageUrls = ({ _this }) => (
  <div className="row mt">
    {IMAGES.map((image, j) => (
      <div className="col-12" key={j}>
        <Upload
          name={`${image}`}
          placeholder={getMessage(`rewards.promotion.${image}`)}
          label={getMessage(`rewards.promotion.${image}`)}
          {..._this.generateStateMappers({
            stateKeys: [`${image}`],
            loseEmphasisOnFill: true,
          })}
          required
        />
      </div>
    ))}
  </div>
)

export default ImageUrls
