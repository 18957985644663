import React from 'react'
import { Link } from 'react-router-dom'
import { Cell, Row } from '../../../components/Table'
import { getMessage } from '../../../lib/translator'
import { upperCase } from 'lodash'

export const tableProperties = (isStoreKindColEnable) => {
  const headers = [
    getMessage('operations.store.table.header.name'),
    isStoreKindColEnable &&
      getMessage('operations.store.table.header.store_kind'),
    getMessage('operations.store.table.header.address'),
    getMessage('operations.store.table.header.deliveryHub'),
    getMessage('operations.store.table.header.deliveryFee'),
    getMessage('operations.store.table.header.picking'),
    getMessage('operations.store.table.header.clientSAPId'),
    getMessage('operations.store.table.header.status'),
  ]

  return {
    /**
     * filter before rendering to ensure, if some value are "false" or empty values,
     * remove it out from that header list, or else UI will create extra spacing even
     * there should not be any col in that header.
     */
    headers: headers.filter(Boolean),
    row: ({
      id,
      type,
      name,
      address,
      clientId,
      hasDeliveryHub,
      deliveryFee,
      hasPicking,
      status,
    }) => (
      <Row>
        <Cell className="store-name-container">
          <Link
            to={`/settings/stores/configuration/${id}`}
            className="store-name"
          >
            {name || <strong>{getMessage('operations.store.default')}</strong>}
          </Link>
        </Cell>
        {isStoreKindColEnable && (
          <Cell className="store-kind">
            <span>{upperCase(type)}</span>
          </Cell>
        )}
        <Cell className="store-address">{address}</Cell>
        <Cell className="store-has-delivery-hub">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.deliveryHub')}
          </div>
          <span>
            {hasDeliveryHub
              ? getMessage('operations.store.table.row.picking.yes')
              : getMessage('operations.store.table.row.picking.no')}
          </span>
        </Cell>
        <Cell className="store-has-delivery-fee">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.deliveryFee')}
          </div>
          <span>{deliveryFee}</span>
        </Cell>
        <Cell className="store-has-picking">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.picking')}
          </div>
          <span>
            {hasPicking
              ? getMessage('operations.store.table.row.picking.yes')
              : getMessage('operations.store.table.row.picking.no')}
          </span>
        </Cell>
        <Cell className="store-client-id">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.clientId')}
          </div>
          <span>{clientId}</span>
        </Cell>
        <Cell className="store-status">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.status')}
          </div>
          <span>
            {status === 'ENABLED'
              ? getMessage('operations.store.form.status.enable.label')
              : getMessage('operations.store.form.status.disable.label')}
          </span>
        </Cell>
      </Row>
    ),
  }
}
