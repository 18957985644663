import React from 'react'
import QueryString from 'query-string'
import {
  BaseForm,
  Input,
  Textarea,
  CategorySearch,
  BrandSearch,
} from '../../../../components/Form'
import Tabs from '../../../../components/Tabs'
import Dialog from '../../../../components/Popup/Dialog'
import API from '../../../../lib/api'
import { isExtensionEnabled, extensionRoutes } from '../../../../lib/auth'
import { getMessage } from '../../../../lib/translator'
import { withRouter } from 'react-router-dom'
import './style.css'
import Loader from '../../../../components/Loader'

const MSG_EXT_SEO_READMORE = getMessage('extensions.seo.read.more')

// serchable component for brand and category
const searchableComponent = {
  brand: BrandSearch,
  category: CategorySearch,
}

const seoPageIndices = {
  home: 0,
  product: 1,
  category: 2,
  tag: 3,
  brand: 4,
}

class SEOSetting extends BaseForm {
  constructor(props) {
    super(props)

    const qs = this.extractQuery()
    const selectedTab = qs.selectedTab
    this.state = {
      page: seoPageIndices[selectedTab] || 0,
      selectedTab: selectedTab,
      submitting: false,
      showSuccessDialog: false,
      categoryObject: {},
      allTabDataSet: false,
      categoryTabDataSet: false,
      isCategoryDefaultDataSet: false,
      loading: false,
      values: {
        CATEGORY: {},
      },
    }

    this.pages = ['HOME', 'PRODUCT', 'CATEGORY']
    this.selectOptionsData = {
      category: [],
    }

    if (isExtensionEnabled('ProductTagSupport')) {
      this.pages.push('TAG')
    }

    if (isExtensionEnabled('MultiBrandSupport')) {
      this.pages.push('BRAND')
    }

    this.changePage = this.changePage.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getTabsData = this.getTabsData.bind(this)
    this.getAllTabsData = this.getAllTabsData.bind(this)
    this.setCategorySearchData = this.setCategorySearchData.bind(this)
    this.extractQuery = this.extractQuery.bind(this)
    this.onCategoryChange = this.onCategoryChange.bind(this)
  }

  extractQuery() {
    const qs =
      this.props.location &&
      this.props.location.search &&
      QueryString.parseUrl(this.props.location.search)
    const selectedTab = qs.query && qs.query.type ? qs.query.type : 'home'
    const queryUrl = qs && qs.query && qs.query.url ? qs.query.url : ''
    return {
      selectedTab,
      queryUrl,
    }
  }

  onCategoryChange(value) {
    const { selectedTab } = this.state
    this.setState({ [`${selectedTab}Object`]: value, loading: true })
    let windowLocation = `${extensionRoutes.Seo}?type=${selectedTab}`
    windowLocation += value && value.slug ? `&url=${value.slug}` : ''
    window.location.href = windowLocation
  }

  setCategorySearchData({ selectedTab, dataFor, url }) {
    if (url !== '') {
      let apiUrl = `/catalogue-service/${selectedTab}`
      apiUrl += url ? `?slug=${url}` : ''
      const api = new API({
        url: apiUrl,
      })

      api.get().then((response) => {
        if (response.status === 'ERROR') {
          console.error(`Error in fetching details of ${dataFor}`)
          return
        }
        this.selectOptionsData[dataFor] = []
        if (!response.data[selectedTab]) {
          response.data[selectedTab] = []
        }
        this.selectOptionsData[dataFor] = response.data[selectedTab][0]
        this.setState({
          categoryObject: this.selectOptionsData[dataFor],
          categoryTabDataSet: true,
        })
      })
    }
  }

  getTabsData({ selectedTab, dataFor, url = '', exceptTab = 'category' }) {
    if (selectedTab === 'category') {
      this.setCategorySearchData({ selectedTab, dataFor, url })
      this.getCategoryTabData({ url })
    } else {
      this.getAllTabsData({ selectedTab, exceptTab })
    }
  }

  getCategoryTabData({ url = '' }) {
    this.setState({ loading: true })
    let apiUrl = `/website-service/seo/category`
    apiUrl += url ? `?slug=${url}` : ''
    const api = new API({
      url: apiUrl,
    })

    api.get().then(
      (response) => {
        const seoData = response.data.seo || {}
        let values = this.state.values
        if (seoData !== {}) {
          values = {
            ...values,
            CATEGORY: {
              description: seoData.description,
              title: seoData.title,
              keywords: seoData.keywords,
              name: seoData.name,
            },
          }
        }
        this.setState({
          values: values,
          loading: false,
          categoryTabDataSet: true,
          page: seoPageIndices.category,
          isCategoryDefaultDataSet: !!url,
          selectTab: 'category',
        })
      },
      (error) => console.error(error)
    )
  }

  getAllTabsData({ selectedTab, exceptTab }) {
    this.setState({ loading: true })
    this.api = new API({ url: '/account-service/seo' })
    this.api.get().then(
      (response) => {
        const transformedResponse = response.data.seo
        this.seos = response.data.seo || {}
        let values = this.state.values
        transformedResponse.forEach((seo) => {
          const pageName = seo.page.name
          if (pageName !== exceptTab.toUpperCase()) {
            values = {
              ...values,
              [pageName]: {
                description: seo.description,
                title: seo.title,
                keywords: seo.keywords,
              },
            }
          }
          if (
            !this.state.isCategoryDefaultDataSet &&
            pageName === exceptTab.toUpperCase()
          ) {
            values = {
              ...values,
              [pageName]: {},
            }
          }
        })
        const qs = this.extractQuery()
        this.setState({
          values: values,
          allTabDataSet: true,
          categoryObject: {},
          categoryTabDataSet: false,
          loading: false,
          page: seoPageIndices[selectedTab],
          selectedTab: qs.selectedTab,
        })
      },
      (error) => console.error(error)
    )
  }

  componentDidMount() {
    const qs = this.extractQuery()
    const selectedTab = qs.selectedTab
    if (selectedTab === 'category') {
      const categoryToConfigure = qs.queryUrl
      this.getTabsData({
        selectedTab: selectedTab,
        dataFor: 'category',
        url: categoryToConfigure,
      })
    } else if (selectedTab !== 'category') {
      this.getTabsData({
        selectedTab: selectedTab,
        exceptTab: 'category',
      })
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page &&
      !Object.keys(this.state.values.CATEGORY).length &&
      this.state.page === seoPageIndices.category
    ) {
      const qs = this.extractQuery()
      const categoryToConfigure = qs.queryUrl
      this.getTabsData({
        selectedTab: 'category',
        dataFor: 'category',
        url: categoryToConfigure,
      })
    }
  }

  changePage(page) {
    const selectedTab = Object.keys(seoPageIndices).find(
      (seoPageIndex) => seoPageIndices[seoPageIndex] === page
    )
    this.setState({ page: page, selectedTab: selectedTab })
    if (page !== seoPageIndices.category && !this.state.allTabDataSet) {
      this.setState({ loading: true })
      window.location.href = `${extensionRoutes.Seo}?type=${selectedTab}`
    }
  }

  closeDialog() {
    this.setState({
      showSuccessDialog: false,
    })
  }

  handleSubmit(data) {
    const { selectedTab } = this.state
    const pageName = this.pages[this.state.page]
    let api = ''
    let thisSeo = {}
    let params = {}
    if (selectedTab === 'category') {
      thisSeo = data[selectedTab.toUpperCase()] || {}
      const qs = this.extractQuery()
      const categoryToConfigure = qs.queryUrl
      api = new API({ url: `/website-service/seo/category` })
      if (categoryToConfigure !== '') {
        params.slug = categoryToConfigure
        params.isDefault = false
      } else {
        params.isDefault = true
      }
      params.name = thisSeo.name
    } else {
      params = this.seos.filter((seo) => seo.page.name === pageName)[0]
      thisSeo = data[pageName] || {}
      api = new API({ url: `/account-service/seo/${params.id}` })
    }
    params.title = thisSeo.title
    params.description = thisSeo.description
    params.keywords = thisSeo.keywords

    return api.put(params).then(
      () => {
        this.setState({
          formError: '',
          showSuccessDialog: true,
        })
      },
      (error) => {
        this.setState({
          formError: error.message.split(':')[1],
        })
      }
    )
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    })
    this.handleSubmit(data).then(() => {
      this.setState({
        submitting: false,
      })
    })
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const { selectedTab } = this.state
    const SearchableComponent = searchableComponent[selectedTab]
    const showSearchableComponent = selectedTab === 'category'
    const name = selectedTab.toUpperCase()
    return (
      <div className="seo-form">
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          title={getMessage('extensions.seo.success')}
          information={getMessage('extensions.seo.save.successful')}
          close={this.closeDialog}
          closeText={getMessage('extensions.seo.ok')}
        />
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <div className="tab-container">
            <Tabs
              items={this.pages.map((page) => `${page}`)}
              default={this.state.page}
              onClick={(index) => this.changePage(index)}
            />
            <div className="tab-details">
              {this.state.loading ? (
                <Loader size="lg" className="seoLoaderWrapperHeight" />
              ) : (
                <React.Fragment>
                  <div className={`${name}-page`}>
                    {showSearchableComponent && (
                      <SearchableComponent
                        className="seo-category-configure"
                        label={getMessage('seo.searchable.label')}
                        placeholder={getMessage(
                          `themes.${selectedTab}.show.layout`
                        )}
                        value={this.state[`${selectedTab}Object`]}
                        dontShowChain={selectedTab === 'category'}
                        onChange={(value) => this.onCategoryChange(value)}
                      />
                    )}
                    {selectedTab === 'category' && (
                      <Input
                        label={getMessage('extensions.seo.name')}
                        name="name"
                        {...this.generateStateMappers({
                          stateKeys: [name, 'name'],
                          loseEmphasisOnFill: true,
                        })}
                        required
                        validationStrings={{
                          valueMissing: getMessage('input.requiredMessage'),
                        }}
                        maxLength={150}
                      >
                        {getMessage('extensions.seo.name.explanation')}
                        <a
                          href="https://support.google.com/webmasters/answer/7451184?hl=en"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          {MSG_EXT_SEO_READMORE}
                        </a>
                      </Input>
                    )}
                    <Input
                      label={getMessage('extensions.seo.title')}
                      name="title"
                      {...this.generateStateMappers({
                        stateKeys: [name, 'title'],
                        loseEmphasisOnFill: true,
                      })}
                      required
                      validationStrings={{
                        valueMissing: getMessage('input.requiredMessage'),
                      }}
                      maxLength={150}
                    >
                      {getMessage('extensions.seo.title.explanation')}
                      <a
                        href="https://support.google.com/webmasters/answer/7451184?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        {MSG_EXT_SEO_READMORE}
                      </a>
                    </Input>
                    <Textarea
                      label={getMessage('extensions.seo.description')}
                      name="description"
                      {...this.generateStateMappers({
                        stateKeys: [name, 'description'],
                        loseEmphasisOnFill: true,
                      })}
                      required
                      validationStrings={{
                        valueMissing: getMessage('textarea.requiredMessage'),
                      }}
                      maxLength={64000}
                    >
                      {' '}
                      {getMessage('extensions.seo.description.explanation')}
                      <a
                        href="https://support.google.com/webmasters/answer/7451184?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        {MSG_EXT_SEO_READMORE}
                      </a>
                    </Textarea>
                    <Textarea
                      label={getMessage('extensions.seo.keywords')}
                      name="keywords"
                      {...this.generateStateMappers({
                        stateKeys: [name, 'keywords'],
                        loseEmphasisOnFill: true,
                      })}
                      maxLength={64000}
                    >
                      {getMessage('extensions.seo.keywords.explanation')}
                      <a
                        href="https://support.google.com/webmasters/answer/7451184?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        {MSG_EXT_SEO_READMORE}
                      </a>
                    </Textarea>
                  </div>
                  <SubmitButton>
                    {getMessage('extensions.seo.save')}
                  </SubmitButton>
                  <button
                    type="button"
                    className="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    {getMessage('extensions.seo.cancel')}
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export default withRouter(SEOSetting)
