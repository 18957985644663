import React from 'react'
import { BaseForm, Input , VALIDATION_TYPES} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

export default class AddCreativeFilter extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components

    return (
      <Form className="website-page-form">
        <div className="form-fields">
          <div className="section-wrap">
            <div className="section-row">
              <Input
                className="section-row-input"
                label={getMessage('media.creative.add.name')}
                name="creativeName"
                type="text"
                value={this.props.creativeName}
                {...this.generateStateMappers({
                  stateKeys: ['creativeName'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONBLUR,
                })}
                onChange={value => {
                  this.props.creativeNameChanged(value)
                }}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                }}
              />
            </div>
          </div>
        </div>

        <div className="form-buttons">
          <SubmitButton>{getMessage('media.creative.add.search')}</SubmitButton>
          <ClearButton>{getMessage('media.creative.add.clear')}</ClearButton>
        </div>
      </Form>
    )
  }
}
