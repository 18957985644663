import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import WebsitePageForm from './Form'
import { Row, Cell } from '../../../components/Table'
import { importText, exportText } from '../../../components/RichEditor'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { Dialog } from '../../../components/Popup'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import { getSession } from '../../../lib/auth'

import emptyIcon from './pages-empty.svg'

import './style.css'

function getStatusText(status) {
  if (!status) {
    return ''
  }
  status = status.toLowerCase()
  switch (status) {
    case 'enabled':
    case 'disabled':
      return getMessage(`websitePage.status.${status}`)
    default:
      return status
  }
}

class PagesActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statusActionID: null,
    }
    this.showStatusActionDialog = this.showStatusActionDialog.bind(this)
    this.hideStatusActionDialog = this.hideStatusActionDialog.bind(this)
  }
  showStatusActionDialog(statusActionID) {
    this.setState({ statusActionID })
  }
  hideStatusActionDialog() {
    this.setState({ statusActionID: null })
  }
  UNSAFE_componentWillReceiveProps() {
    this.setState({ statusActionID: false })
  }
  modifyStatus(status) {
    const id = this.props.id
    const api = new API({ url: `/account-service/static-page/${id}` })
    return api.put({ id, status }).then(() => {
      this.props.onAction(TABLE_ACTIONS.EDIT, { id }, { status })
    }, console.error)
  }
  render() {
    const { status, id } = this.props
    return (
      <div>
        <Dialog
          show={Number.isFinite(this.state.statusActionID)}
          title={
            status === 'ENABLED'
              ? getMessage('websitePage.dialogs.disable.title')
              : getMessage('websitePage.dialogs.enable.title')
          }
          information={
            status === 'ENABLED'
              ? getMessage('websitePage.dialogs.disable.message')
              : getMessage('websitePage.dialogs.enable.message')
          }
          onOk={() => {
            status === 'ENABLED'
              ? this.modifyStatus('DISABLED')
              : this.modifyStatus('ENABLED')
          }}
          close={this.hideStatusActionDialog}
          closeText={getMessage('websitePage.dialogs.disable.cancelText')}
          okText={getMessage('websitePage.dialogs.disable.okText')}
        />
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/marketing/pages/edit/${id}`}>
              {getMessage('websitePage.table.action.edit')}
            </Link>
          </DropDownItem>
          <DropDownItem>
            <div
              onClick={() => {
                this.showStatusActionDialog(id)
              }}
            >
              {status === 'ENABLED'
                ? getMessage('websitePage.table.action.disable')
                : getMessage('websitePage.table.action.enable')}
            </div>
          </DropDownItem>
        </DropDown>
      </div>
    )
  }
}

function AddButton() {
  return (
    <Link to="/marketing/pages/add" className="primary button">
      + <span className="text">{getMessage('websitePage.add.text')}</span>
    </Link>
  )
}

const emptyState = {
  icon: emptyIcon,
  message: getMessage('websitePage.emptyList.message'),
  submessage: getMessage('websitePage.emptyList.submessage'),
  additionalViews: [AddButton],
}

const tableProperties = (isMobileView) => {
  return {
    headers: [
      getMessage('websitePage.table.title'),
      getMessage('websitePage.table.url'),
      getMessage('websitePage.table.status'),
      getMessage('websitePage.table.actions'),
    ],
    row: ({ id, title, url, status, onAction }) => (
      <Row>
        <Cell className="website-page-name">
          {isMobileView ? (
            <div className="text-muted">
              {getMessage('websitePage.table.title')}
            </div>
          ) : (
            ''
          )}
          <Link className="page-name" to={`/marketing/pages/edit/${id}`}>
            {title}
          </Link>
        </Cell>
        <Cell className="website-page-url">
          {isMobileView ? (
            <div className="text-muted">
              {getMessage('websitePage.table.url')}
            </div>
          ) : (
            ''
          )}
          <a
            target="_blank"
            className="page-url"
            href={`http://${getSession().organization.domain}/pages/${url}`}
          >{`/${url}`}</a>
        </Cell>
        <Cell className="website-page-status">
          <span className="status">{getStatusText(status)}</span>
        </Cell>
        <Cell className="website-page-actions">
          <PagesActions onAction={onAction} id={id} status={status} />
        </Cell>
      </Row>
    ),
  }
}
function getMobileView() {
  return window.screen.width <= 480
}

export default class Pages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileView: getMobileView(),
    }
    this.mobileView = this.mobileView.bind(this)
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.mobileView, false)
  }
  render() {
    return (
      <ListingPageWithRoutes
        menu={this.props.menu}
        className="website-pages"
        title={getMessage('websitePage.table.heading')}
        addHeading={getMessage('websitePage.form.add.heading')}
        editHeading={getMessage('websitePage.form.edit.heading')}
        api={{
          url: '/account-service/static-page',
          transform: (response) => response.data.staticpage,
        }}
        emptyState={emptyState}
        headerActions={AddButton}
        form={{
          component: WebsitePageForm,
          transformSubmit: (formData) => {
            const { title, url, content } = formData
            const layouts = content.map((singleContent) => {
              if (singleContent.Image) {
                return {
                  data: singleContent.Image,
                  name: 'Image',
                }
              } else if (singleContent.ImageWithText) {
                return {
                  data: singleContent.ImageWithText,
                  name: 'ImageWithText',
                }
              } else {
                return {
                  data: {
                    text: exportText(singleContent),
                  },
                  name: 'ContentBlock',
                }
              }
            })
            return {
              layouts: layouts,
              title,
              url,
            }
          },
          transformResponse: (response) => {
            const title = response.data.staticpage.title
            const url = response.data.staticpage.url
            const layouts = response.data.staticpage.layouts
            let content
            if (layouts) {
              content = layouts.map((layout) => {
                if (layout.name === 'ContentBlock') {
                  return importText(layout.data.text)
                } else if (layout.name === 'ImageWithText') {
                  return {
                    ImageWithText: layout.data,
                  }
                } else if (layout.name === 'Image') {
                  return {
                    Image: layout.data,
                  }
                } else {
                  return null
                }
              })
            }
            return { title, url, content }
          },
        }}
        tableProperties={tableProperties(this.state.isMobileView)}
      />
    )
  }
}
