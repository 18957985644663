import React from 'react'

export const _PlainSelect = ({ options, ...rest }) => {
  return (
    <span className="Select">
      <select {...rest}>
        {options.map((option) => {
          if (typeof option === 'object' && !option.text) {
            return null
          }
          const optionValue = option.value ? option.value : option
          return (
            <option
              key={optionValue}
              value={optionValue}
              className={optionValue === rest.value ? 'selected' : undefined}
              selected={rest.value === rest.defaultValue ? true : undefined}
              disabled={option.disabled}
            >
              {option.text ? option.text : option}
            </option>
          )
        })}
      </select>
    </span>
  )
}
