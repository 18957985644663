import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import HelpItems from '../../../components/ListingPage/HelpItems'
import {
  isNoResult,
  isFilterApplied,
} from '../../../components/ListingPage/utils'
import Table from '../../../components/Table'

import '../../../containers/ListingPage/style.css'

import './style.css'

const SortableItem = SortableElement((props) => {
  const { row, Row, onAction, apiParams, rowIndex } = props
  const { pageTypeFilter } = props
  return (
    <Row
      {...row}
      apiParams={apiParams}
      onAction={onAction}
      index={rowIndex}
      pageTypeFilter={pageTypeFilter}
    />
  )
})

const SortableList = SortableContainer((props) => {
  return (
    <div>
      {props.viewData &&
        props.viewData.map((row, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            row={row}
            rowIndex={index}
            {...props}
          />
        ))}
    </div>
  )
})

const DraggableListingPageView = ({
  // this.state.xx from parent
  data,
  pageType,
  apiParams,
  isGridView,
  saveBanner,
  submitting,
  // this.props.xx from parent
  emptyState,
  helpItems,
  tableProperties,
  tableDynamic,
  addToTable,
  // this.xx from parent
  api,
  viewData,
  onSortEnd,
  performAction,
  handleMove,
  onDraggableSubmit,
}) => {
  const filtersApplied = isFilterApplied({ data })

  const { filters } = data

  const isPageTypeHome =
    !filters.pageType || filters.pageType.toLowerCase() === 'home'
  const isPageTypeOmniHome =
    !filters.pageType || filters.pageType === 'OMNI_HOMEPAGE'
  // INFO: Enabled when storeId is not selected (all stores), pageType is not selected or is HOME, and bannerType is not selected or is All
  const sequencingEnabled =
    (!filters.storeId &&
      (isPageTypeHome || isPageTypeOmniHome) &&
      (!filters.bannerType || filters.bannerType === 'All')) ||
    (!isPageTypeHome && !isPageTypeOmniHome)

  if (isNoResult({ api, data })) {
    if (filtersApplied) {
      return <div className="text-muted text-center">No results found</div>
    } else {
      return <HelpItems emptyState={emptyState} helpItems={helpItems} />
    }
  } else if (data.items && tableProperties.row && viewData) {
    const Row = tableProperties.row

    return viewData.length === 0 && pageType !== 'HOME' ? (
      <HelpItems emptyState={emptyState} helpItems={helpItems} />
    ) : (
      <div className="table-container">
        <Table tableDynamic={tableDynamic || false}>
          <SortableList
            viewData={viewData}
            Row={Row}
            pressDelay={200}
            onSortEnd={onSortEnd}
            apiParams={apiParams}
            onAction={performAction}
            {...(isGridView ? { axis: 'xy' } : { lockAxis: 'y' })}
            lockToContainerEdges
            onSortMove={handleMove}
            helperClass="manage-banners-sortable-helper"
            disabled={!sequencingEnabled}
            pageTypeFilter={filters.pageType}
          />

          {addToTable && <addToTable onAction={performAction} />}
        </Table>
        {saveBanner && (
          <div className="draggable-actions">
            <button
              type="button"
              onClick={onDraggableSubmit}
              className="button primary"
            >
              {submitting ? '...' : 'Save'}
            </button>
          </div>
        )}
      </div>
    )
  }
  return null
}

export default DraggableListingPageView
