import React from 'react'
import { BaseForm, Radio, BrandSearch } from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'

export default class BrandFiltersForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values.status = ''
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <BrandSearch
            label={getMessage('product.filters.brand.heading')}
            placeholder={getMessage('product.filters.brand.placeholder')}
            name="brand"
            {...this.generateStateMappers({
              stateKeys: ['brand'],
            })}
          />
          <Radio
            label={getMessage('brand.filters.status.heading')}
            name="status"
            options={[
              {
                text: getMessage('brand.filters.status.label.all'),
                value: '',
              },
              {
                text: getMessage('brand.filters.status.label.enabled'),
                value: 'ENABLED',
              },
              {
                text: getMessage('brand.filters.status.label.hidden'),
                value: 'HIDDEN',
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ['status'],
            })}
          />
        </div>
        <SubmitButton>{getMessage('brand.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('brand.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
