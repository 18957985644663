import React from 'react'
import { BaseForm, Select, Input } from '../../../../components/Form'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import { getStores, sortStores } from '../../../../lib/auth'

class StoreConfigForm extends BaseForm {
  constructor(props) {
    super(props)
    this.getDetails = this.getDetails.bind(this)
  }
  componentDidMount() {
    const api = new API({ url: '/catalogue-service/stock-override' })
    api.get().then(response => {
      const values = response.data.stockOverride
      values.storeId = 'all'
      this.setState({
        values,
      })
    })
  }
  onSubmit(formData) {
    this.setState({
      submitting: true,
    })
    const data = Object.assign({}, formData)
    if (!Number(data.storeId)) {
      data.storeId = null
    }
    if (data.maxPurchasableStock) {
      data.maxPurchasableStock = Number(data.maxPurchasableStock)
    } else {
      delete data.maxPurchasableStock
    }
    if (data.bulkOrderThreshold) {
      data.bulkOrderThreshold = Number(data.bulkOrderThreshold)
    } else {
      delete data.bulkOrderThreshold
    }
    if (data.stockBuffer) {
      data.stockBuffer = Number(data.stockBuffer)
    } else {
      delete data.stockBuffer
    }
    data.storeId = Number(data.storeId)
    const api = new API({ url: '/catalogue-service/stock-override' })
    api.put(data).then(
      () => {
        this.setState({
          submitting: false,
        })
      },
      error => {
        if (error.code === 401) throw error
        this.setState({
          error: error,
          submitting: false,
        })
      }
    )
  }

  getDetails(storeId) {
    if (!Number(storeId)) {
      storeId = null
    }
    this.props.saveSelectedStore(storeId)
    this.setState({
      submitting: true,
    })
    const api = new API({ url: '/catalogue-service/stock-override' })
    api.get({ storeId: storeId }).then(
      response => {
        const values = response.data.stockOverride
        values.storeId = storeId || 'all'
        this.setState({
          values,
          submitting: false,
        })
      },
      error => {
        if (error.code === 401) throw error
        this.setState({
          error: error,
          submitting: false,
        })
      }
    )
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const stores = getStores && getStores()
    let allStores =
      Array.isArray(stores) &&
      stores
        .filter(({ hasPicking }) => hasPicking)
        .map(store => {
          return { text: store.name, value: store.id }
        })
    sortStores(allStores)
    allStores = [{ text: 'All', value: 'all' }].concat(allStores)
    return (
      <Form>
        <div className="config-variables form-fields">
          <Select
            label="Store"
            placeholder="Select store"
            name="store"
            required
            options={allStores}
            value={this.getState(['storeId'])}
            onChange={id => {
              this.getDetails(id)
            }}
          />
          <Input
            label={getMessage('store.min.stock.count')}
            placeholder="00"
            name="minStock"
            min={0}
            type="number"
            {...this.generateStateMappers({
              stateKeys: ['stockBuffer'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage('store.max.purchaseable.qty')}
            placeholder="00"
            name="maxOrder"
            type="number"
            min={0}
            {...this.generateStateMappers({
              stateKeys: ['maxPurchasableStock'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage('store.bulk.orderThreshold')}
            placeholder="00"
            name="storeBulk"
            type="number"
            min={0}
            {...this.generateStateMappers({
              stateKeys: ['bulkOrderThreshold'],
              loseEmphasisOnFill: true,
            })}
          />
          <div className="submit-button">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage('store.submit')}
            </SubmitButton>
          </div>
        </div>
      </Form>
    )
  }
}
export default StoreConfigForm
