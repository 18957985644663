import React from 'react'
import { Row, Cell } from '../../../../components/Table'
import { getMessage } from '../../../../lib/translator'
import {
  getPrintableTime,
  formatTime,
  formatIsoTime,
} from '../../../../lib/datetime'
import { Link } from 'react-router-dom'
import phoneIcon from '../svg/phone.svg'
import Placeholder from '../svg/customer-image-placeholder.svg'
import { format, parse } from 'libphonenumber-js'
import ProgressBar from '../ProgressBar'
import moment from 'moment'

const ActivePickers = props => {
  const {onUnassignPicker} = props
  const { employees, pickers} = props
  let picker = ''
  const pickerIds = pickers.map(p => {
    return p.pickerId
  })
  const activeEmployees = employees.filter(emp => {
    return pickerIds && pickerIds.includes(emp.id)
  })

  return (
    <React.Fragment>
      <div className="pickers-active-picker-header">
        {getMessage('pickers.active.header')}
      </div>
      <div className="pickers-table">
        {activeEmployees.map((employee, index) => {
          for (let i = 0; i < pickers.length; i++) {
            if (pickers[i].pickerId === employee.id) {
              picker = pickers[i]
            }
          }
          let lateTime = false
          if (employee.attendance !== null) {
            lateTime = moment(employee.attendance.expectedIn) <
            moment(employee.attendance.createdAt)
          }
          return (
            <Row className="pickers-table-row" key={index}>
              <Cell className="pickers-table-cell">
                <div className="active-picker-section">
                  <div className="pickers-active-employee-details-section">
                    <div className="pickers-emp-name-image">
                      {employee.imageUrl ? (
                        <img
                          className="pickers-employee-image"
                          src={employee.imageUrl}
                          alt=""
                        />
                      ) : (
                        <img
                          className="pickers-employee-image-empty"
                          src={Placeholder}
                          alt=""
                        />
                      )}
                      <div className="pickers-employee-name">
                        {employee ? employee.name : ''}
                        <br />
                        <span className="pickers-employee-phone">
                          {employee && !!employee.phones.length && (
                            <img
                              className="pickers-phone-icon"
                              src={phoneIcon}
                              alt=""
                            />
                          )}
                          {employee
                            ? employee.phones.length
                              ? employee.phones[0].phone &&
                                Object.keys(parse(employee.phones[0].phone))
                                  .length > 0
                                ? format(
                                    parse(employee.phones[0].phone),
                                    'International'
                                  )
                                : employee.phones[0].phone
                              : ''
                            : ''}
                        </span>
                      </div>
                    </div>
                    {employee.shiftStart && employee.shiftEnd && (
                      <div className="pickers-shift-time-1 slot">
                        {getMessage('pickers.today.slot')}
                        {employee.shiftStart
                          ? formatTime(employee.shiftStart)
                          : ''}
                        &nbsp;-&nbsp;
                        {employee.shiftEnd ? formatTime(employee.shiftEnd) : ''}
                      </div>
                    )}
                    <div className="pickers-shift-time-1">
                      {getMessage('pickers.today.checkin.time')}
                      {
                        getPrintableTime(employee.attendance !== null ? employee.attendance.createdAt : '').split(
                          ','
                        )[1]
                      }
                      {lateTime && (
                        <span className="late-time">
                          {getMessage('pickers.today.late')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="pickers-order-details">
                    {picker.currentOrder.slotStartTime &&
                      picker.currentOrder.slotEndTime && (
                        <div className="pickers-shift-time-1 order-slot">
                          {picker.currentOrder.slotStartTime &&
                            picker.currentOrder.slotEndTime &&
                            getMessage('pickers.today.order.slot')}
                          {picker.currentOrder.slotStartTime &&
                            formatIsoTime(picker.currentOrder.slotStartTime)}
                          &nbsp;-&nbsp;
                          {picker.currentOrder.slotEndTime &&
                            formatIsoTime(picker.currentOrder.slotEndTime)}
                        </div>
                      )}
                    {picker.currentOrder.batchId && (
                      <span className="pickers-employee-name">
                        Batch ID:&nbsp;
                        {picker.currentOrder ? (
                            picker.currentOrder.batchId
                        ) : (
                          ''
                        )}
                      </span>
                    )}

                    {!picker.currentOrder.batchId && (
                      <React.Fragment>
                        <span className="pickers-employee-name">
                          {getMessage('picker.today.order')}
                          {picker.currentOrder ? (
                            <Link
                              to={`/operations/orders/${picker.currentOrder.referenceNumber}`}
                            >
                              {picker.currentOrder.clientId
                                ? `#${picker.currentOrder.clientId}`
                                : `Z${picker.currentOrder.referenceNumber}`}{' '}
                            </Link>
                          ) : (
                            ''
                          )}
                        </span>
                        <div className="pickers-picking-item">
                          {picker.currentOrder.processName === 'PICKING'
                            ? getMessage('pickers.today.picking.item')
                            : picker.currentOrder.processName === 'CHECKING'
                            ? getMessage('pickers.today.checking.item')
                            : picker.currentOrder.processName === 'PACKING'
                            ? getMessage('pickers.today.packing.item')
                            : getMessage('pickers.today.packed.item')}
                          {picker.currentOrder.numOfOrderItems || 0}
                          {getMessage('pickers.today.picking.item2')}
                        </div>
                      </React.Fragment>
                    )}

                    <ProgressBar picker={picker} />

                    <button
                      value={picker.pickerId}
                      className="button primary btnUnassign"
                      onClick={e => {
                        onUnassignPicker(
                          pickers.filter(p => {
                            return p.pickerId === parseInt(e.target.value, 10)
                          })
                        )
                      }}
                    >
                      Unassign Picker
                    </button>
                  </div>
                </div>
              </Cell>
            </Row>
          )
        })}
      </div>
    </React.Fragment>
  )
}

export default ActivePickers
