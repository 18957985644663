import React from 'react'
import {
  BaseForm,
  Input,
  Select,
  SingleDatePicker,
} from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'
import { getSelectGiftingOrderStatus } from '../../../../lib/commonlyused'

const ORDER_TYPE = [
  {
    text: getMessage('order.business'),
    value: 'B2B',
  },
  {
    text: getMessage('order.personal'),
    value: 'B2C',
  },
]

export default class Filters extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('order.filters.referenceNumber.heading')}
            placeholder={getMessage(
              'order.filters.referenceNumber.placeholder'
            )}
            name="reference"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['id'],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage('order.filters.placedOn')}
            placeholder={getMessage('order.filter.placedOn.placeholder')}
            name="placed-on"
            isOutsideRange
            {...this.generateStateMappers({
              stateKeys: ['date'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.status.heading')}
            placeholder={getMessage('order.filters.status.placeholder')}
            name="status"
            options={getSelectGiftingOrderStatus()}
            {...this.generateStateMappers({
              stateKeys: ['status'],
              loseEmphasisOnFill: true,
            })}
          />

          <Input
            label={getMessage('customerSupport.egifting.header.email')}
            placeholder={getMessage('customerSupport.egifting.header.email')}
            name="senderEmail"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['email'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('offer.ordertype.label')}
            placeholder={getMessage('offer.ordertype.label')}
            name="type"
            options={ORDER_TYPE}
            {...this.generateStateMappers({
              stateKeys: ['type'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.payment.paymentmode')}
            placeholder={getMessage(
              'customerSupport.egifting.choose.payment.type'
            )}
            name="paymentType"
            options={[
              {
                text: getMessage('customerSupport.egifting.bank'),
                value: 'DEPOSIT',
              },
              {
                text: getMessage('order.details.payment.card'),
                value: 'CARD',
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ['paymentType'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('order.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('order.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
