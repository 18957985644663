import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import {
  tableProperties,
  storeDataCsvExample,
} from '../../../components/Rewards/Stores/utils'
import { LinkButtons, emptyState } from '../../../components/Rewards/utils'
import '../style.css'
import './stores.css'
import StoresForm from './Form'
import { getMessage } from 'lib/translator'
import Filters from '../../../components/Rewards/Filters'
import CSVUpload from '../../../components/Rewards/CSVUpload'

const Stores = (props) => {
  return (
    <ListingPageWithRoutes
      testid="store-listing"
      title={getMessage('rewards.stores.title')}
      addHeading={getMessage('rewards.store.add')}
      editHeading={getMessage('rewards.store.edit')}
      menu={props.menu}
      className="rewards-page stores"
      api={{
        url: '/rms/stores',
        transform: (response) => response && response.data.list,
      }}
      headerActions={({ onAction }) => (
        <LinkButtons type="store" onAction={onAction} />
      )}
      form={{
        component: StoresForm,
      }}
      filters={{
        component: Filters,
        forceShow: true,
        options: {
          type: 'store',
        },
      }}
      importDialog={{
        component: () => (
          <CSVUpload
            uploadUrl="/rms/stores"
            csv={storeDataCsvExample()}
            fileName="storesData"
          />
        ),
      }}
      modalClassName="csvUpload"
      importHeading={getMessage('rewards.store.import')}
      emptyState={emptyState('store')}
      tableProperties={tableProperties}
      tableDynamic
    />
  )
}

export default Stores
