export const isNoResult = ({ api, data }) =>
  !api ||
  (data.paging &&
    data.paging.count === 0 &&
    !(data.items && data.items.length > 0)) ||
  (data.items && data.items.length === 0)

export const isFilterApplied = ({ data }) => {
  const filtersCount = Object.keys(data.filters).filter((key) =>
    Boolean(data.filters[key])
  ).length
  return filtersCount > 0
}
