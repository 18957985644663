const data = {
    'preorder.header.id': 'ID',
    'preorder.header.campaign': 'Campaign',
    'preorder.header.startdate': 'Start Date',
    'preorder.header.enddate': 'End Date',
    'preorder.header.offerid': 'Offer ID',
    'preorder.header.status': 'Status',
    'preorder.filters.status.label.all': 'All',
    'preorder.filters.status.label.active': 'Active',
    'preorder.filters.status.label.scheduled': 'Scheduled',
    'preorder.filters.status.label.inactive': 'Inactive',
    'preorder.filters.name.heading': 'CAMPAIGN TITLE',
    'preorder.filters.status.heading': 'Campaign Status',
    'preorder.filters.name.placeholder': 'Search by Name',
    'preorder.heading': 'Pre-Order Campaign',
    'preorder.filters.clearFiltersText': 'Clear All',
    'preorder.filters.submitText': 'Search',
    'preorder.definefestivecampaign.heading': 'Define Festive Campaign',
    'preorder.definefestivecampaign.campaignstatus': 'Campaign Status',
    'preorder.vector': 'Vector',
    'preorder.savecampaign': 'Save Campaign',
    'preorder.publish': 'Publish',
    'preorder.details': 'Details',
    'preorder.form.type': 'TYPE',
    'preorder.form.campaigntitle': 'CAMPAIGN TITLE',
    'preorder.form.store': 'STORE',
    'preorder.optional': `To customise free gift details, go to Marketing menu > Offers`,
    'preorder.form.campaignTitle.placeholder': 'Enter Campaign Title',
    'preorder.form.type.placeholder': 'Select A PreOrder Type',
    'preorder.form.store.placeholder': 'Select Store',
    'preorder.form.dateAndTime.header': 'Date and Time',
    'preorder.form.dateAndTime.validOrderDate': 'VALID ORDER DATE',
    'preorder.form.dateAndTime.validCollectionDate': 'VALID COLLECTION DATE',
    'preorder.form.dateAndTime.validOrderDateto': 'To',
    'preorder.form.dateAndTime.placeholder': 'Select Date',
    'preorder.mobileapp.details': 'Swimlane Information Details',
    'preorder.mobileapp.onboardingscreen': 'Onboarding Screen',
    'preorder.mobileapp.campaignTitle.placeholder':
      'Title will appear on the app Screen',
    'preorder.mobileapp.description': 'DESCRIPTION',
    'preorder.mobileapp.description.placeholder':
      'Write a short description about your campaign',
    'preorder.mobileapp.title': 'TITLE',
    'preorder.campaignlisting.img': 'CAMPAIGN LISTING IMAGE (W1080xH600)',
    'preorder.mobileapp.onboardingimg': 'ONBOARDING IMAGE (W1080xH600)',
    'preorder.exportconsolidatedorders.heading': 'Export Consolidated Orders',
    'preorder.exportconsolidatedorders.orderref': 'ORDER REF.',
    'preorder.exportconsolidatedorders.campaignselection': 'CAMPAIGN SELECTION',
    'preorder.exportconsolidatedorders.customer': 'CUSTOMER',
    'preorder.exportconsolidatedorders.handphone': 'HANDPHONE',
    'preorder.exportconsolidatedorders.status': 'STATUS',
    'preorder.exportconsolidatedorders.orderdate': 'ORDER DATE',
    'preorder.exportconsolidatedorders.collectiondate': 'COLLECTION DATE',
    'preorder.exportconsolidatedorders.to': 'TO',
    'preorder.exportconsolidatedorders.orderplaced': 'Order placed',
    'preorder.exportconsolidatedorders.readytopickup': 'Ready to pick up',
    'preorder.exportconsolidatedorders.collected': 'Collected',
    'preorder.exportconsolidatedorders.no': 'No',
    'preorder.exportconsolidatedorders.campaignid': 'Campaign ID',
    'preorder.exportconsolidatedorders.storeid': 'Store ID',
    'preorder.exportconsolidatedorders.orderamount': 'Order Amount',
    'preorder.exportconsolidatedorders.orderquantity': 'Order Quantity',
    'preorder.exportconsolidatedorders.date': 'Collection Date',
    'preorder.exportconsolidatedorders.collectiontime': 'Collection Time',
    'preorder.exportconsolidatedorders.ref': 'Order Ref.',
    'preorder.exportconsolidatedorders.stat': 'Status',
    'preorder.exportconsolidatedorders.deli': 'Deli Classic Combo',
    'preorder.exportconsolidatedorders.jordon': 'Jordon Honey Duet',
    'preorder.exportconsolidatedorders.orderref.placeholder': 'Enter ID number',
    'preorder.exportconsolidatedorders.campaignselection.placeholder':
      'Enter ID number',
    'preorder.exportconsolidatedorders.customer.placeholer': 'Text label',
    'preorder.exportconsolidatedorders.handphone.placeholder': 'Text label',
    'preorder.exportconsolidatedorders.orderdate.placeholder': 'Select Date',
    'preorder.exportconsolidatedorders.collectiondate.placeholder': 'Select Date',
    'preorder.exportconsolidatedorders.to.placeholder': 'Select Time',
    'preorders.form.itemList.header': 'Item',
    'preorders.form.itemList.products-title': 'PRODUCTS',
    'preorders.form.itemList.products-title.placeholder':
      'Enter any number of SKU separated by comma',
    'preorders.form.itemList.selectProducts.placeholder': 'Select Products',
    'preorder.export.status.heading': 'STATUS',
    'preorder.export.button': 'Export',
    'timeslot.button': 'Add daily time slot',
    'timeslot.adddate.button': 'Add Specific Date',
    'preorder.timeSlot.heading': 'Time Slot Maintainance',
  }
  export default data