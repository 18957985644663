import React, { Component } from 'react'
import { ProductSearch } from '../../../../components/Form'
import ItemTable from './ItemTable'
import { getMessage } from '../../../../lib/translator'
import CommonForm from './CommonForm'
import OfferRadioForm from './OfferRadioForm'

class BXGYD extends Component {
  constructor(props) {
    super(props)
    this.validationHandler = this.validationHandler.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
  }

  validationHandler(parent) {
    const formData = JSON.parse(JSON.stringify(parent.state.values))
    const validations = JSON.parse(JSON.stringify(parent.state.validations))
    const comboDiscount = formData.itemDiscountType === 'COMBO_DISCOUNT'
    const entityType = formData.entityType.toLowerCase()
    if (comboDiscount) {
      const types = validations[entityType] || []
      types.forEach((validation) => {
        delete validation.t
        delete validation.v
      })
    } else {
      validations.rule && delete validations.rule
    }
    parent.setState({
      validations,
    })
  }

  submitHandler(formData, buyKey) {
    const data = Object.assign({}, formData)
    const comboDiscount = formData.itemDiscountType === 'COMBO_DISCOUNT'
    const entityType = formData.entityType.toLowerCase()
    data.offerType = data.type
    const get = {}
    if (!comboDiscount) {
      data.rule && delete data.rule.total
    }
    const rule = {}
    if (comboDiscount) {
      rule.total = data.rule.total
      rule.total.v = Number(rule.total.v)
    }
    rule.entity = {
      type: data.entityType === 'PRODUCT' ? 'VARIANT' : formData.entityType,
      id: data.entityType === 'PRODUCT' ? null : 0,
    }
    rule.limit = data.rule ? Number(data.rule.limit) : null
    const getItems = data[entityType]
    getItems.forEach((item) => {
      const res = { q: Number(item.q) }
      if (!comboDiscount) {
        res.t = item.t
        res.v = Number(item.v)
      }
      get[item.id] = res
    })
    rule.get = get
    rule.buy = {}
    data[`${buyKey}product`] &&
      data[`${buyKey}product`].forEach((item) => {
        rule.buy[item.id] = Number(item.q)
      })
    data.rule = rule
    delete data.product
    delete data.category
    delete data.brand
    delete data[`${buyKey}product`]
    delete data[`${buyKey}category`]
    delete data[`${buyKey}brand`]
    return data
  }

  render() {
    const {
      entityType: entityTypeProp,
      discountTypes,
      itemDiscountTypes,
      _this,
      buyKey,
      hasOfferStarted,
    } = this.props
    const { values } = _this.state
    const { itemDiscountType } = _this.state.values
    let entityType = entityTypeProp
    entityType = this.props.entityTypeMap[entityType]
    const comboDiscount = itemDiscountType === 'COMBO_DISCOUNT'
    const buyItems = values[`buy${entityType}`]
    const buyItemsLength = buyItems ? buyItems.length : 0
    const items = values[entityType]
    const itemsLength = items ? items.length : 0
    return (
      <div className="offers-form">
        <div className="offer-fields">
          <OfferRadioForm
            _this={_this}
            itemDiscountTypes={itemDiscountTypes}
            hasOfferStarted={hasOfferStarted}
          />
          {comboDiscount && (
            <CommonForm
              discountTypes={discountTypes}
              _this={_this}
              hasOfferStarted={hasOfferStarted}
              values={values}
            />
          )}
        </div>
        <h3 className="top-o">{`Buy`}</h3>
        {buyItemsLength === 0 && entityType === 'product' && (
          <ProductSearch
            required
            label={getMessage(`offer.${entityType}`)}
            placeholder={getMessage(`offer.${entityType}.placeholder`)}
            name={`item-searchable-pdt-buy`}
            key={`item-searchable-pdt-buy`}
            className="product-searchable"
            onChange={_this.addItemBuy}
            readOnly={hasOfferStarted}
          />
        )}
        <div>
          {buyItemsLength ? (
            <ItemTable
              comboDiscount
              entityType={entityType}
              items={buyItems}
              discountTypes={discountTypes}
              _this={_this}
              buy
              buyQty
              buyKey={buyKey}
            />
          ) : null}
        </div>
        <h3>Get</h3>
        {/* {entityType === 'product' && <Searchable
          className='product-searchable'
          key={`item-searchable-pdt-get`}
          name={`item-searchable-pdt-get`}
          label={getMessage(`offer.${entityType}`)}
          placeholder={getMessage(`offer.${entityType}.placeholder`)}
          searchUrl='/catalogue-service/product'
          searchKey='name'
          valueKey='id'
          renderListElement={renderSearchableListElement}
          transformResponse={response => generateProducts(response.data.product)}
          onChange={_this.addItem}
        />} */}
        {entityType === 'product' && (
          <ProductSearch
            required
            label={getMessage(`offer.${entityType}`)}
            placeholder={getMessage(`offer.${entityType}.placeholder`)}
            name={`item-searchable-pdt-get`}
            key={`item-searchable-pdt-get`}
            className="product-searchable"
            onChange={_this.addItem}
            readOnly={hasOfferStarted}
          />
        )}
        <div>
          {itemsLength ? (
            <ItemTable
              comboDiscount={comboDiscount}
              entityType={entityType}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
              buyKey={buyKey}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

export default BXGYD
