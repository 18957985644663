import React, { useState } from 'react'
import { EMPTY_STATE, tableProperties } from '../rewardsUtils'
import ListingPageWithRoutes from '../../../../../../containers/ListingPage/listingRouter'
import '../style.css'
import { Textarea } from '../../../../../../components/Form'
import { getMessage } from '../../../../../../lib/translator'
import moment from 'moment'

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const ChallengeProgress = ({
  uid,
  selectedChallenge,
  handleChallengeSelect,
}) => {
  const [user, setUser] = useState([])
  return (
    selectedChallenge && (
      <div className="col-12 mt">
        <div className="row flex-direction challenge-dates">
          <Textarea
            type="text"
            testid="accepted-at"
            className={!user.userChallenge?.acceptedAt && 'challenge-date'}
            label={getMessage('customer.details.challenge.acceptedat')}
            disabled={true}
            value={
              user.userChallenge?.acceptedAt
                ? moment(user.userChallenge.acceptedAt).format(DATE_TIME_FORMAT)
                : 'null'
            }
          />
          <Textarea
            type="text"
            testid="completed-at"
            className={!user.userChallenge?.completedAt && 'challenge-date'}
            label={getMessage('customer.details.challenge.completedat')}
            disabled={true}
            value={
              user.userChallenge?.completedAt
                ? moment(user.userChallenge.completedAt).format(
                    DATE_TIME_FORMAT
                  )
                : 'null'
            }
          />
        </div>
        <ListingPageWithRoutes
          isRewardsTab={true}
          menu=""
          className="rewards-page challenge-table"
          api={{
            url: `/chendol/challenges`,
            params: { uid, id: selectedChallenge, limit: 20, offset: 0 },
            transform: (response) => {
              if (response) {
                setUser(response.data.list[0])
                return response.data.list[0].tasks
              }
              return null
            },
            updateApiParams: (prevParams, newParams) => {
              if (prevParams.id !== newParams.id) {
                return {
                  shouldUpdate: true,
                  params: {
                    id: newParams.id,
                    uid,
                    limit: 20,
                    offset: 0,
                  },
                }
              }
              return null
            },
          }}
          form={{}}
          emptyState={EMPTY_STATE}
          tableProperties={tableProperties(
            'ChallengeProgress',
            `${selectedChallenge} - ${user.name}`,
            handleChallengeSelect
          )}
          tableDynamic
        />
      </div>
    )
  )
}

export default ChallengeProgress
