import React from 'react'
import {
  BaseForm,
  Input,
  DateRangePicker,
  VALIDATION_TYPES,
  Select,
} from 'components/Form'
import { getMessage } from 'lib/translator'
import { ActivityTypes } from 'lib/actiontrail/action-maps'
import { CSVExport } from '../CSVExport'

const FORM_KEYS = {
  userid: 'userId',
  email: 'email',
  dates: 'dates',
  actionType: 'actionType',
}

export default class ActionTrailFilterForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      moduleOptions: [],
    }
  }

  /**
   * Retrieving all action type values
   * for the Autocomplete select options component
   */
  getActionTypeSelections = () => {
    const options = []

    Object.entries(ActivityTypes).forEach((entry) => {
      Object.entries(entry[1]).forEach((obj) => {
        options.push({
          value: obj[1],
          text: obj[1],
        })
      })
    })
    options.push({
      value: 'MISSING_PAGE',
      text: 'MISSING_PAGE',
    })
    return options
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components

    return (
      <Form>
        <div className="form-fields date-fields">
          <Input
            name={FORM_KEYS.userid}
            label={getMessage('action-trail.filter.userid.label')}
            {...this.generateStateMappers({
              stateKeys: [FORM_KEYS.userid],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            name={'actionType'}
            key={'actionType'}
            options={this.getActionTypeSelections()}
            autoComplete="off"
            label={getMessage('action-trail.filter.type.label')}
            placeholder="Action Type"
            {...this.generateStateMappers({
              stateKeys: [FORM_KEYS.actionType],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <DateRangePicker
            className="section-row-input"
            label={getMessage('action-trail.filter.dates.label')}
            placeholder={getMessage('datetime.date')}
            minimumNights={1}
            isOutsideRange={() => false}
            {...this.generateStateMappers({
              stateKeys: [FORM_KEYS.dates],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            name={FORM_KEYS.email}
            label={getMessage('action-trail.filter.email.label')}
            placeholder={getMessage('action-trail.filter.email.placeholder')}
            {...this.generateStateMappers({
              stateKeys: [FORM_KEYS.email],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>
          {getMessage('action-trail.filter.submit.buttontext')}
        </SubmitButton>
        <ClearButton>
          {getMessage('action-trail.filter.clear.buttontext')}
        </ClearButton>
        <CSVExport />
      </Form>
    )
  }
}
