const requiredPermissions = require('./requiredPermissions.json');

export const newRouterPermissions = {
  operations: [
    {
      slug: 'pickers-today',
      endpoints: [
        'account-service/employee',
        'account-service/store',
        'order-service/picker-activity',
        'account-service/attendance',
      ],
      extensions: ['InStoreProcessing'],
      hideMenuItem: true,
    },
  ],
  logistics: [
    {
      slug: 'vehicle-vendor',
      endpoints: [
        'account-service/store',
        'logistics-service/vehicle',
        'logistics-service/vehicle-vendor',
      ],
      extensions: ['LogisticsSupport'],
      hideMenuItem: true,
    },
  ],
  qc: [
    {
      slug: 'orders',
      hideMenuItem: true,
    },
  ],
  marketing: [
    {
      slug: 'search-evoucher',
      extensions: ['Marketing'],
      hideMenuItem: true,
    },
    {
      slug: 'evoucher-details',
      extensions: ['Marketing'],
      hideMenuItem: true,
    },
  ],
  settings: [
    {
      slug: 'extensions',
      endpoints: ['account-service/store', 'account-service/extension'],
      extensions: ['InStoreProcessing'],
      hideMenuItem: true,
    },
  ],
  admin: [
    {
      slug: 'sng-actions',
      hideMenuItem: true,
    },
  ],
}

export const hiddenSubmenu = Object.keys(newRouterPermissions).reduce(
  (result, menu) => {
    result[menu] = newRouterPermissions[menu]
      .filter((item) => item.hideMenuItem)
      .map((item) => item.slug)
    return result
  },
  {}
)

export { requiredPermissions }
