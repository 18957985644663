import React from 'react'
import { BaseForm, Input } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

class PickerFilters extends BaseForm {
  render() {
    const { Form } = this.components
    const { SubmitButton, ClearButton } = this.buttons
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('picker.filter.name')}
            placeholder={getMessage('picker.filter.name.placeholder')}
            {...this.generateStateMappers({
              stateKeys: ['name'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('picker.filter.submit')}</SubmitButton>
        <ClearButton>{getMessage('picker.filter.clear')}</ClearButton>
      </Form>
    )
  }
}

export default PickerFilters
