import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'

const Splashscreen = ({ splash }) => {
  return (
    <div className="splashscreen">
      {Object.keys(splash).map((item, index) => (
        <div className={item} key={index}>
          {splash[item] &&
            (splash[item].slice(-4) === 'json' ? (
              <Player
                autoplay
                loop
                src={splash[item]}
                style={{ width: '10rem' }}
              />
            ) : (
              <img src={splash[item]} alt={item} />
            ))}
        </div>
      ))}
    </div>
  )
}

export default Splashscreen
