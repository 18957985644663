import React from 'react'
import { Popup } from 'components/Popup'
import API from 'lib/api'
import { getMessage } from 'lib/translator'
import Form, { BaseForm, Select, Input } from 'components/Form'

const requiredMessage = 'input.requiredMessage'
const requiredEmail = 'resendDialog.emailText'

const style = {
  marginTop: 32,
}

const DELIVERY_METHOD = [
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'SMS',
    value: 'sms',
  },
]

class ResendDialog extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      formValues: {},
    }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  setInitialState() {
    this.props.resendDetails.map((res) => {
      if (res.entityId) {
        const orgObj = this.state.formValues
        orgObj.voucher = res.addressee.voucher
        orgObj.password = res.addressee.password
        orgObj.batchId = res.batchId
        this.setState({ formValues: orgObj })
      } else {
        const setData = this.handleGroupBy(this.props.resendDetails)
        Object.keys(setData).forEach((data) => {
          setData[data].forEach((k) => {
            if (!this.isSameMethod(data)) {
              k.deliveryMethod = ''
              k.phone = ''
              k.email = ''
              k.isSame = true
            }
            if (k.deliveryMethod === 'sms') {
              k.phone = k?.phone?.toString().substr(3)
            }
            return { ...k }
          })
        })
        this.setState({
          formValues: setData,
        })
      }
    })
  }

  componentDidMount() {
    this.setInitialState()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resendDetails !== this.props.resendDetails) {
      this.setInitialState()
    }
  }

  handleOnChange(e, name) {
    const updatedData = this.state.formValues[name].map((k) => {
      return k.name === name ? { ...k, deliveryMethod: e } : k
    })
    const getOriginalObj = this.state.formValues
    getOriginalObj[name] = updatedData
    this.setState({ formValues: getOriginalObj })
  }

  handleInputChange(e, name, method) {
    const updatedData = this.state.formValues[name].map((k) => {
      return k.name === name ? { ...k, [method]: e } : k
    })
    const getOriginalObj = this.state.formValues
    getOriginalObj[name] = updatedData
    this.setState({ formValues: getOriginalObj })
  }

  isSameMethod(name) {
    const groupBy = this.handleGroupBy(this.props.resendDetails)
    return (
      groupBy[name].every(
        (k) => k.deliveryMethod === groupBy[name][0]?.deliveryMethod
      ) || false
    )
  }

  handleGroupBy(data) {
    return data.reduce((acc, key) => {
      acc[key.name] = acc[key.name] || []
      acc[key.name].push(key)

      return acc
    }, Object.create(null))
  }

  async submitHandler(e) {
    e && e.preventDefault()
    const { onClose } = this.props
    const { password, voucher, batchId } = this.state.formValues
    if (password || voucher) {
      const req = {
        voucherEmails: [voucher],
        passwordEmails: [password],
      }
      const api = new API({
        url: `/batches/${batchId}/issue`,
      })
      const response = await api.post(req)
      if (response) {
        this.setState({ formValues: {} })
        this.props.setSelectReceivers([])
        this.props.successModal(true)
      }
    } else {
      try {
        const isValid = this.isFormValid()
        if (isValid) {
          const mappedValue = Object.values(this.state?.formValues).flatMap(
            (value) => value
          )
          mappedValue.length > 0 &&
            mappedValue.map(async (item) => {
              const req = {
                deliveryMethod: item.deliveryMethod,
                email: item.deliveryMethod === 'email' ? item.email : '',
                phone: item.deliveryMethod === 'sms' ? `+65${item.phone}` : '',
              }
              const api = new API({
                url: `/gifting-admin-service/receiver/${item.id}/resend`,
              })
              const response = await api.post(req)
              if (response) {
                this.setState({ formValues: {} })
                this.props.setSelectReceivers([])
                this.props.successModal(true)
              }
            })
        }
      } finally {
        onClose()
      }
    }
    onClose()
  }

  render() {
    const { show, onClose, resendDetails } = this.props
    const { SubmitButton, CancelButton } = this.buttons
    const groupData = this.handleGroupBy(resendDetails)
    const isGroupDataExist = Object.keys(this.state.formValues).length > 0
    const isB2B = resendDetails.some((item) => item.entityId)

    return (
      <Popup
        show={show}
        heading={getMessage('resendDialog.heading')}
        close={onClose}
        closeText={getMessage('dialog.cancelText')}
        okText={getMessage('dialog.okText')}
      >
        <Form onSubmit={(e) => this.submitHandler(e)}>
          {resendDetails.map((resend, index) => {
            if (resend.entityId) {
              return (
                <div
                  className="resend-details-wrapper mb container"
                  key={index}
                >
                  <div className="row status">
                    <div className="col-12">
                      <h3>
                        {getMessage('evoucher.resend.resend')}{' '}
                        {getMessage('picker.today.batch')} {resend.batchId}
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mt" style={style}>
                      <label>{getMessage('eVoucher.voucher.receiver')}</label>
                    </div>
                    <div className="col-6 mt">
                      <Input
                        testid={`business-receiver-email-${index}`}
                        type="email"
                        name={`business-email-${index}`}
                        id={`business-receiver${index}`}
                        required
                        validationStrings={{
                          valueMissing: getMessage(requiredMessage),
                        }}
                        placeholder={getMessage(requiredEmail)}
                        value={this.state.formValues.voucher}
                      />
                    </div>
                  </div>
                  <div className="row mt">
                    <div className="col-6 mt" style={style}>
                      <label>{getMessage('eVoucher.password.receiver')}</label>
                    </div>
                    <div className="col-6 mt mb">
                      <Input
                        testid={`business-password-receiver-email-${index}`}
                        type="email"
                        name={`business-password-email-${index}`}
                        id={`business-password-receiver${index}`}
                        required
                        validationStrings={{
                          valueMissing: getMessage(requiredMessage),
                        }}
                        placeholder={getMessage(requiredEmail)}
                        value={this.state.formValues.password}
                      />
                    </div>
                  </div>
                </div>
              )
            } else {
              return null
            }
          })}

          {isGroupDataExist &&
            !isB2B &&
            Object.keys(groupData).map((resendDetail, i) => {
              return (
                <div className="resend-details-wrapper mb container" key={i}>
                  <div className="row status">
                    <div className="col-12">
                      <h3>
                        {getMessage('evoucher.resend.resend')}{' '}
                        {groupData[resendDetail].length}{' '}
                        {getMessage('resendDialog.receiver.egift')} for{' '}
                        {resendDetail}
                      </h3>
                    </div>
                  </div>

                  {this.state.formValues[resendDetail][0]?.isSame && (
                    <>
                      <div className="row mt">
                        <div className="col-12">
                          <span className="span-error" data-testid="span-error">
                            {getMessage(
                              'customerSupport.egifting.resend.error'
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="row mt">
                        <div className="col-12">
                          <span
                            className="span-error"
                            data-testid="span-error-2"
                          >
                            {getMessage(
                              'customerSupport.egifting.resend.error_1'
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row mt">
                    <div className="col-6 mt">
                      <label>{getMessage('evoucher.resend.method')}</label>
                    </div>
                    <div className="col-6">
                      <label>
                        <Select
                          testid={`method-${i}`}
                          required
                          id={`delivery-method-${i}`}
                          options={DELIVERY_METHOD}
                          name={`method-${i}`}
                          onChange={(e) => this.handleOnChange(e, resendDetail)}
                          value={
                            isGroupDataExist &&
                            this.state.formValues[resendDetail][0]
                              ?.deliveryMethod
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt mb">
                    <div className="col-6 mt">
                      <label>{getMessage('evoucher.resend.receiver')}</label>
                    </div>
                    {`${
                      isGroupDataExist &&
                      this.state.formValues[resendDetail][0]?.deliveryMethod
                    }` === 'email' ? (
                      <div className="col-6">
                        <label>
                          <Input
                            testid={`receiver-email-${i}`}
                            type="email"
                            name={`email-${i}`}
                            id={`receiver${i}`}
                            required
                            validationStrings={{
                              valueMissing: getMessage(requiredMessage),
                            }}
                            placeholder={getMessage(requiredEmail)}
                            value={
                              isGroupDataExist &&
                              this.state.formValues[resendDetail][0]?.email
                            }
                            onChange={(e) =>
                              this.handleInputChange(e, resendDetail, 'email')
                            }
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="col-6">
                        <label>
                          <Input
                            testid={`receiver-phone-${i}`}
                            name={`phone-${i}`}
                            validationStrings={{
                              valueMissing: getMessage(requiredMessage),
                            }}
                            id={`receiver-phone${i}`}
                            type="number"
                            required
                            value={
                              isGroupDataExist &&
                              this.state.formValues[resendDetail][0]?.phone
                            }
                            onChange={(e) =>
                              this.handleInputChange(e, resendDetail, 'phone')
                            }
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}

          <SubmitButton
            testid="resend-mltple-btn"
            disabled={this.state.submitting}
          >
            {getMessage('category.form.submitText')}
          </SubmitButton>
          <CancelButton
            testid="cancel-mltple-btn"
            disabled={this.state.submitting}
          >
            {getMessage('category.form.cancelText')}
          </CancelButton>
        </Form>
      </Popup>
    )
  }
}

export default ResendDialog
