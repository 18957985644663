import React from 'react'
import { BaseForm, Input } from 'components/Form'
import { Popup } from 'components/Popup'
import { GOOGLE_MAP_JSON_URL } from 'config/app'
import { get } from 'lib/storage'
import iconSearch from '../search.svg'
import { getMessage } from 'lib/translator'
import isEmpty from 'lodash.isempty'

export class SearchCoordForm extends BaseForm {
  constructor(props) {
    super(props)
    this.submitHandler = this.submitHandler.bind(this)
    this.handleAddressClick = this.handleAddressClick.bind(this)
    this._getMapPayload = this._getMapPayload.bind(this)
    this._updateStateAfterSearch = this._updateStateAfterSearch.bind(this)
    this.state = {
      openPopup: false,
      resultAddress: null,
      errorMessage: null,
      lat: null,
      lng: null,
    }
  }

  async submitHandler(e) {
    e.preventDefault()
    e.stopPropagation()

    try {
      const payload = this._getMapPayload(this.state.values['coords'])

      if (payload) {
        const googleKey = get('googleMapAPIkey')
        const response = await fetch(
          GOOGLE_MAP_JSON_URL +
            `?latlng=${payload.lat},${payload.lng}&key=${googleKey}`
        )

        const data = await response.json()

        if (data.status && data.status === 'OK') {
          const formattedAddress = data.results[0].formatted_address
          this._updateStateAfterSearch(false, formattedAddress, payload, null)
        } else {
          this._updateStateAfterSearch(
            true,
            null,
            null,
            getMessage('operations.store.coord-modal.error.address-not-found')
          )
        }

        return
      }

      this._updateStateAfterSearch(
        true,
        null,
        null,
        getMessage('operations.store.coord-modal.error.address-not-found')
      )
    } catch (err) {
      this.setState({
        resultAddress: null,
        errorMessage: getMessage('operations.store.coord-modal.error.default'),
      })
    }
  }

  handleAddressClick = () => {
    const { lat, lng } = this.state
    if (this.props.onAddressSubmit) {
      this.props.onAddressSubmit(this.state.resultAddress, { lat, lng })
      this.setState({
        openPopup: false,
        resultAddress: null,
        lat: null,
        lng: null,
      })
    }
  }

  componentDidMount() {
    const defaultCoordsValue = this.props.defaultCoord
    if (isEmpty(defaultCoordsValue.lat) || isEmpty(defaultCoordsValue.lng)) {
      this.setState({ errorMessage: null })
      return
    }
    this.updateState(['coords'], Object.values(defaultCoordsValue).join(', '))
  }

  /**
   * Update the local state when search fetch response is retrieved
   * @param {*} failed
   * @param {*} address
   * @param {*} coords
   * @param {*} errorMessage
   */
  _updateStateAfterSearch(failed, address, coords, errorMessage) {
    this.setState({
      resultAddress: failed || isEmpty(address) ? null : address,
      errorMessage: errorMessage,
      ...(function () {
        return !isEmpty(coords) ? { ...coords } : undefined
      })(),
    })
  }

  /**
   * Retrieve the lat and lng from the raw input field
   * Default to return null  when  raw strings have invalid patterns with a ","
   * or either the lat or lng have empty value or failed to extract.
   * @param {*} inputValue
   * @returns
   */
  _getMapPayload(inputValue) {
    if (inputValue.length !== 0) {
      const coords = inputValue.split(',')
      if (coords.length < 2) {
        return null
      }

      const lat = coords[0].trim()
      const lng = coords[1].trim()

      if (isEmpty(lat) || isEmpty(lng)) {
        return null
      }
      return {
        lat,
        lng,
      }
    }
    return null
  }

  render() {
    const { resultAddress, errorMessage } = this.state

    return (
      <>
        <button
          type="button"
          className="button primary search-by-coords-btn"
          onClick={() => this.setState({ openPopup: true })}
        >
          {getMessage('operations.store.button.get-by-coordinate')}
        </button>
        <Popup
          show={this.state.openPopup}
          heading={getMessage('operations.store.coord-modal.title')}
          close={() => this.setState({ openPopup: false })}
          size="lg"
          className="search-by-coords-popup"
        >
          <form onSubmit={this.submitHandler} role="form">
            <div className="coord-search-input">
              <Input
                placeholder={getMessage(
                  'operations.store.coord-modal.input.placeholder'
                )}
                label={getMessage('operations.store.coord-modal.input.label')}
                {...this.generateStateMappers({
                  stateKeys: ['coords'],
                })}
              />
              <img src={iconSearch} alt="search icon" className="search-icon" />
            </div>
          </form>

          {resultAddress !== null && (
            <button className="result-link" onClick={this.handleAddressClick}>
              {resultAddress}
            </button>
          )}
          {errorMessage !== null && (
            <button className="error-message">{errorMessage}</button>
          )}
        </Popup>
      </>
    )
  }
}
