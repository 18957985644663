import React, { Component } from 'react'
import { getMessage } from '../../../../lib/translator'
import { getAsapDuration } from '../../../../lib/commonlyused'
import moment from 'moment'

const LOG_OLD_VALUE = 'oldValue'
const LOG_NEW_VALUE = 'newValue'

export default class SlotTime extends Component {

    checkSlotTime(item) {
        return item.delivery_slot_start && item.delivery_slot_end
    }

    checkSlotOldValue(item) {
        return this.checkSlotTime(item) && item.delivery_slot_start.oldValue && item.delivery_slot_end.oldValue
    }

    checkSlotNewValue(item) {
        return this.checkSlotTime(item) && item.delivery_slot_start.newValue && item.delivery_slot_end.newValue
    }

    getAsapSlotTime(slotStartTime, slotEndTime) {
        return getAsapDuration(slotStartTime, slotEndTime)
    }

    getSlotChangedTime(item, logValueType) {
        const slotStartTime = item.delivery_slot_start[logValueType]
        const slotEndTime = item.delivery_slot_end[logValueType]
        return (
            <span className="bold">
                {moment(slotStartTime).format('Do MMM YYYY, ')}
                {moment(slotStartTime).format('hh:mm A')} - {moment(slotEndTime).format('hh:mm A')}
            </span>
        )
    }

    render() {
        const {
            item
        } = this.props

        if (this.checkSlotOldValue(item) && this.checkSlotNewValue(item)) {
            return (
                <div className="order-slot">
                    <span>
                        {getMessage('order.log.slot.changed')}{' '}
                        {item.delivery_slot_start.oldValue && getMessage('order.log.from')}&nbsp;
                    </span>
                    <br />
                    {this.getSlotChangedTime(item, LOG_OLD_VALUE)}
                    <br />
                    <span>
                        &nbsp;{getMessage('order.log.to')}&nbsp;
                    </span>
                    <br />
                    {this.getSlotChangedTime(item, LOG_NEW_VALUE)}
                </div>
            )
        }
        return null;
    }
}
