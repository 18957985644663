import React, { useEffect, useState } from 'react';
import AuthenticatedPage from 'containers/AuthenticatedPage';
import { getMessage } from 'lib/translator';
import API from 'lib/api';
import BulkOperationsFilters from './BulkOperationFilters';
import { sortSlots } from 'lib/commonlyused';
import { formatTime } from 'lib/datetime';
import './style.scss';

function BulkOperations({ menu }) {

  const [slotOptions, setSlotOptions] = useState([])

  useEffect(() => {
    const slotApi = new API({ url: `/order-service/slot` })
    slotApi.get({ paginated: 'false', orderType: 'DELIVERY' })
      .then(response => {
        const slots = sortSlots(response.data.slot)
        const slotOptionsArr = []
        slots.forEach(slot => {
          if (slot.type === 'STANDARD') {
            slotOptionsArr.push({
              text: `${formatTime(slot.startTime)} - ${formatTime(slot.endTime)}`,
              value: JSON.stringify({
                startTime: slot.startTime,
                endTime: slot.endTime,
              }),
            })
          }
        })
        setSlotOptions(slotOptionsArr);
      })
      .catch(error => {
        console.log(error);
      })
    return () => slotApi && slotApi.cancel()
  }, [])


  return (
    <AuthenticatedPage
      className="bulk-operations-page"
      menu={menu}
      storeDependent>
      <div className="header-container">
        <h2>{getMessage('menu.item.bulk-operations')}</h2>
      </div>
      <div>
        <h3>{getMessage('order.filters.deliveryOrders')}</h3>
        <BulkOperationsFilters slotOptions={slotOptions} />
      </div>
    </AuthenticatedPage>
  );
}

export default BulkOperations;
