import { useEffect } from 'react'
import { run } from '../../lib/actiontrail/actiontrail'

/**
 * To run the action trail
 * @returns
 */
export const ActionTrailContext = () => {
  useEffect(() => {
    run()
  }, [])

  return null
}
