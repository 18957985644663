import React, { useContext } from 'react'
import {
  BaseForm,
  Input,
  Select,
  SingleDatePicker,
} from '../../../../../components/Form'
import { isExtensionEnabled } from '../../../../../lib/auth'
import { getMessage } from '../../../../../lib/translator'
import API from '../../../../../lib/api'
import { formatTime, getMinutes } from '../../../../../lib/datetime'
import {
  sortSlots,
  getDeliveryOrderStatus,
  getPacklistStatus,
} from '../../../../../lib/commonlyused'
import { SplitContext } from 'containers/SplitContext';


const productTypes = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'MarketPlace',
    value: 'marketplace',
  },
]

const packlistZones = [
  {
    text: 'General',
    value: 'general',
  },
  {
    text: 'FreshFrozen',
    value: 'freshfrozen',
  },
  {
    text: 'Bulk',
    value: 'bulk',
  },
  {
    text: 'Others',
    value: 'others',
  }
]

export function getOrderTypes(orderTypes) {
  return (orderTypes || []).map(orderType => {
    // e.g. D + elivery
    let orderTypeText = orderType.split('')[0] + orderType.toLowerCase().split('').splice(1, orderType.length).join('');

    if (orderTypeText.includes('_')) {
      orderTypeText = orderTypeText.replace('_',' ');
    }

    if (orderTypeText.startsWith('Rb')) {
      orderTypeText = orderTypeText.replace('Rb','RB');
    }

    return {
      text: orderTypeText,
      value: orderType,
    }
  })
}

export default function DeliveryOrderFiltersFormWrapper(props) {
  // this is a temporary split flag checking.
  // it will be removed once the feature is stable on production
  const splitConfig = useContext(SplitContext);
  const { splits } = splitConfig;

  return <DeliveryOrderFiltersForm {...props} splits={splits} />
}

class DeliveryOrderFiltersForm extends BaseForm {

  componentDidMount() {
    this.configApi = new API({ url: '/account-service/config/order' })
    this.configApi.get().then(response => {
      const orderTypes = response.data.config.order.orderTypes
      this.setState({
        orderTypes: getOrderTypes([...orderTypes, "RB_PREORDER", "B2B"])
      })
    })
    if (isExtensionEnabled('DeliverySlots')) {
      this.slotApi = new API({ url: `/order-service/slot` })
      this.slotApi.get({ paginated: 'false' }).then(response => {
        let slots = response.data.slot
        slots = sortSlots(slots)
        const slotOptions = []
        slots.forEach(slot => {
          if (slot.type === 'STANDARD') {
            slotOptions.push({
              text: `${formatTime(slot.startTime)} - ${formatTime(
                slot.endTime
              )}`,
              value: JSON.stringify({
                startTime: slot.startTime,
                endTime: slot.endTime,
              }),
            })
          } else if (slot.type === 'ASAP') {
            slotOptions.push({
              text: `${getMessage('slots.asap')} ${getMinutes(slot.endTime)} ${getMessage('slots.asap.minute')}`,
              value: JSON.stringify({ endTime: slot.endTime, type: slot.type }),
            })
          }
        })
        this.setState({ slotOptions })
      })
    }
  }

  componentWillUnmount() {
    this.slotApi && this.slotApi.cancel()
  }

  render() {
    const { Form } = this.components
    const { SubmitButton, ClearButton } = this.buttons
    const { slotOptions, orderTypes } = this.state
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('order.filters.referenceNumber.heading')}
            placeholder={getMessage('order.filters.referenceNumber.placeholder')}
            name="do-referenceNumber"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['referenceNumber'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage('order.filters.campaignId.heading')}
            placeholder={getMessage('order.filters.campaignId.placeholder')}
            name="do-campaignId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['campaignId'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.status.heading')}
            placeholder={getMessage('order.filters.status.placeholder')}
            name="do-status"
            options={getDeliveryOrderStatus()}
            {...this.generateStateMappers({
              stateKeys: ['status'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.orderType')}
            placeholder={getMessage('order.filters.orderType.placeholder')}
            name="do-type"
            options={orderTypes}
            {...this.generateStateMappers({
              stateKeys: ['type'],
              loseEmphasisOnFill: true,
            })}
          />
          {slotOptions && (
            <Select
              label={getMessage('order.filters.slot')}
              placeholder={getMessage('order.filters.slot.placeholder')}
              name="do-orderSlot"
              options={slotOptions}
              {...this.generateStateMappers({
                stateKeys: ['slot'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <SingleDatePicker
            label={getMessage('order.filters.placedOn')}
            placeholder={getMessage('order.filter.placedOn.placeholder')}
            name="do-placed-on"
            isOutsideRange
            {...this.generateStateMappers({
              stateKeys: ['placedOn'],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage('order.filters.preferredDate')}
            placeholder={getMessage('order.filter.placedOn.placeholder')}
            name="do-preferred-date"
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ['preferredDate'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.productType')}
            placeholder={getMessage('order.filters.productType.placeholder')}
            name="do-productType"
            options={productTypes}
            {...this.generateStateMappers({
              stateKeys: ['productType'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage('order.filters.picklist.heading')}
            placeholder={getMessage('order.filters.picklist.placeholder')}
            name="picklistId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['picklistId'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.packlistZone.heading')}
            placeholder={getMessage('order.filters.packlistZone.placeholder')}
            name="packlistZone"
            options={packlistZones}
            {...this.generateStateMappers({
              stateKeys: ['packlistZone'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('order.filters.packlistStatus.heading')}
            placeholder={getMessage('order.filters.packlistStatus.placeholder')}
            name="packlistStatus"
            options={getPacklistStatus()}
            {...this.generateStateMappers({
              stateKeys: ['packlistStatus'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('order.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('order.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
