import React, { Fragment } from 'react'
import { parsePhoneNumber } from 'react-phone-number-input'

import moment from 'moment'
import { Link } from 'react-router-dom'
import { getMessage } from '../../lib/translator'
import { hasPermissions } from '../../lib/auth'
import { TABLE_ACTIONS } from '../../containers/ListingPage'

import {
  Select,
  SelectSearch,
  Radio,
  Input,
  SingleDatePicker,
  TimePicker,
  Textarea,
  VALIDATION_TYPES,
} from '../../components/Form'
import { tableProperties } from './tableProperties'

import { TYPE_OPTIONS } from '../../components/Rewards/Carousels/utils'
import emptyIcon from './empty.svg'

const DATE_TIME_FORMAT = 'D MMM YYYY, HH:mm'

const convertToISOString = (date, time) =>
  moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss').toISOString()

const DATE = 'YYYY-MM-DD'
const TIME = 'HH:mm:ss'

export const getFormattedDate = (date) =>
  date ? moment(date).format(DATE) : ''
export const getFormattedTime = (time) =>
  time ? moment(time).format(TIME) : ''

const STATUS_OPTIONS = [
  {
    value: 1,
    text: getMessage('rewards.active'),
  },
  {
    value: 0,
    text: getMessage('rewards.inActive'),
  },
]

export const CATALOGUE_TYPE = [
  'INSTORE',
  'DONATION',
  'FP_EVOUCHER',
  'PARTNER_ONLINE',
  'UNION_FEE',
]

const MECHANICS_VALUES_ARRAY = [
  getMessage('rewards.catalogue.mechanics.cashVouchers'),
  getMessage('rewards.catalogue.mechanics.heroProductDeal'),
  getMessage('rewards.catalogue.mechanics.freeItem'),
  getMessage('rewards.catalogue.mechanics.1for1'),
  getMessage('rewards.catalogue.mechanics.off'),
  getMessage('rewards.catalogue.mechanics.offWithCriteria'),
  getMessage('rewards.catalogue.mechanics.lossLeaderDeal'),
  getMessage('rewards.catalogue.mechanics.zeroMarginDeals'),
  getMessage('rewards.catalogue.mechanics.others'),
]
export const MECHANICS_OPTIONS = MECHANICS_VALUES_ARRAY.map((value) => ({
  value,
  text: value,
}))

const TYPE_CAT_OPTIONS = [
  {
    value: 'main',
    text: getMessage('rewards.category.visible'),
  },
  {
    value: 'none',
    text: getMessage('rewards.category.hidden'),
  },
]

const CHANNEL_OPTIONS = [
  {
    value: 'all',
    text: getMessage('rewards.all'),
  },
  {
    value: 'app',
    text: getMessage('rewards.app'),
  },
  {
    value: 'web',
    text: getMessage('rewards.web'),
  },
]
const LinkButtons = ({ type, onAction }) => {
  let listType = ''
  switch (type) {
    case 'category':
      listType = 'categories'
      break
    case 'maintenance':
      listType = 'maintenance'
      break
    case 'catalogue':
      listType = 'catalogue'
      break
    default:
      listType = `${type}s`
  }

  if (!isReadOnly(listType)) {
    return (
      <div className="header-wrap">
        {['store', 'device'].includes(type) && (
          <button
            className="primary button"
            onClick={() => {
              onAction(TABLE_ACTIONS.IMPORT)
            }}
          >
            <span className="text">{getMessage(`rewards.${type}.import`)}</span>
          </button>
        )}
        <Link to={`/rewards/${listType}/add`}>
          <button className="primary button ml">
            <span className="text">{`+ ${getMessage(
              `rewards.${type}.add`
            )}`}</span>
          </button>
        </Link>
      </div>
    )
  }
  return null
}
const FormButtons = ({ buttons, type }) => {
  const { CancelButton, SubmitButton } = buttons

  return (
    <div className="row form-btns">
      <div className="col-12">
        <CancelButton>{getMessage('rewards.form.cancel')}</CancelButton>
        {!isReadOnly(type) && (
          <SubmitButton>{getMessage('rewards.form.submit')}</SubmitButton>
        )}
      </div>
    </div>
  )
}

export const isReadOnly = (type) => {
  // Determine the appropriate endpoint based on the type.
  // 'rewards' is for categories, carousels, maintenance, partners, stores, promotions, catalogues.
  // 'terminal' is for merchants and devices.
  const endpoint = ['device', 'merchant'].includes(type)
    ? 'terminal'
    : 'rewards'

  const hasEditPermissions = hasPermissions('loyalty', endpoint, 'post', true)

  return !hasEditPermissions
}

export const emptyState = (type) => {
  return {
    icon: emptyIcon,
    message: getMessage('rewards.empty.message'),
    actions: () => <LinkButtons type={type} />,
  }
}

const ErrorScreen = ({ error }) => (
  <div className="row mt">
    <div className="col-6 error">{error}</div>
  </div>
)

const Rank = ({ _this }) => (
  <div className="row mt">
    <div className="col-12">
      <Input
        label={getMessage('rewards.rank')}
        placeholder={getMessage('rewards.rank')}
        type="number"
        {..._this.generateStateMappers({
          stateKeys: ['rank'],
        })}
      />
    </div>
  </div>
)

const Status = ({ _this }) => (
  <div className="col-12 status">
    <Radio
      testid="status"
      label={getMessage('rewards.status')}
      placeholder={getMessage('rewards.status')}
      options={STATUS_OPTIONS}
      {..._this.generateStateMappers({
        stateKeys: ['status'],
        loseEmphasisOnFill: true,
        defaultValue: 1,
      })}
    />
  </div>
)
const Type = ({ _this, type }) => (
  <div className="col-12">
    <Select
      testid="type"
      label={getMessage('rewards.type')}
      placeholder={getMessage('rewards.type')}
      options={type === 'carousel' ? TYPE_OPTIONS : TYPE_CAT_OPTIONS}
      {..._this.generateStateMappers({
        stateKeys: ['type'],
      })}
      required
    />
  </div>
)

const DatePickerField = ({ label, _this, placeholder = label }) => {
  return (
    <div className="col-6">
      <SingleDatePicker
        enableToday
        required
        label={label && getMessage(`rewards.${label}`)}
        placeholder={getMessage(`rewards.${placeholder}`)}
        {..._this.generateStateMappers({
          stateKeys:
            label === 'startDate'
              ? ['date', 'startingDate']
              : ['date', 'endingDate'],
          loseEmphasisOnFill: true,
        })}
        data-testid={label}
      />
    </div>
  )
}

const TimePickerField = ({ label, _this, placeholder = label }) => {
  return (
    <div className="col-6">
      <TimePicker
        label={label && getMessage(`rewards.${label}`)}
        required
        placeholder={getMessage(`rewards.${placeholder}`)}
        {..._this.generateStateMappers({
          stateKeys:
            label === 'startTime'
              ? ['date', 'startingTime']
              : ['date', 'endingTime'],
          loseEmphasisOnFill: true,
        })}
        data-testid={label}
      />
    </div>
  )
}
const PublishAt = ({ _this }) => (
  <Fragment>
    <div className="col-6">
      <SingleDatePicker
        enableToday
        required
        label={getMessage('rewards.publishDate')}
        placeholder={getMessage('rewards.publishDate')}
        {..._this.generateStateMappers({
          stateKeys: ['date'],
          loseEmphasisOnFill: true,
        })}
      />
    </div>
    <div className="col-6">
      <TimePicker
        label={getMessage('rewards.publishTime')}
        required
        placeholder={getMessage('rewards.publishTime')}
        {..._this.generateStateMappers({
          stateKeys: ['time'],
          loseEmphasisOnFill: true,
        })}
      />
    </div>
  </Fragment>
)

const TextDetails = ({ _this, text, isRequired, placeholder = text }) => (
  <div className="col-12">
    <Textarea
      label={getMessage(`rewards.${text}`)}
      placeholder={getMessage(`rewards.${placeholder}`)}
      type="text"
      {...(isRequired && {
        required: 'required',
      })}
      {..._this.generateStateMappers({
        stateKeys: [`${text}`],
        validationType: VALIDATION_TYPES.ONSUBMIT,
        loseEmphasisOnFill: true,
      })}
    />
  </div>
)

const getStatusText = (status) => {
  return status ? getMessage('rewards.active') : getMessage('rewards.inActive')
}

export const getCategoryDetails = (catDetailsRes, values) => {
  if (catDetailsRes.status === 'fulfilled') {
    const categoryListArr = catDetailsRes.value.data.list?.map((item) => {
      const status = getStatusText(item.status)
      const type = item.type === 'main' ? 'Visible' : 'Hidden'
      return {
        value: item.id,
        text: `${item.name}| ${status}| ${type}`,
      }
    })
    values.categoryList = categoryListArr
  } else {
    values.fetchCategoryError = catDetailsRes.reason.message
  }
}

export const getPartnerDetails = (partnerDetailsRes, values) => {
  if (partnerDetailsRes.status === 'fulfilled') {
    const partnerListArr = partnerDetailsRes.value.data.list?.map((item) => {
      const status = getStatusText(item.status)
      return {
        value: item.id,
        text: `${status} | ${item.name}`,
      }
    })
    values.partnerList = partnerListArr
  } else {
    values.fetchPartnerError = partnerDetailsRes.reason.message
  }
}

const REWARDS_API = ['rms', 'chendol', 'genie']

const SearchPartner = ({ _this, partnerList, fetchPartnerError }) => {
  return (
    <Fragment>
      {fetchPartnerError && (
        <div className="col-12 error" data-testid="error">
          {fetchPartnerError}
        </div>
      )}
      {partnerList?.length > 0 && (
        <div className="col-12">
          <SelectSearch
            name="partnerId"
            data-testid="partnerId"
            label={getMessage('rewards.partner')}
            placeholder={getMessage('rewards.partner')}
            options={partnerList}
            searchByName
            required
            {..._this.generateStateMappers({
              stateKeys: ['partnerId'],
            })}
          />
        </div>
      )}
    </Fragment>
  )
}

const getFilteredList = ({ partnerOnlineCodes }) => {
  let filteredList = []
  const partnerOnlineCodesArray = partnerOnlineCodes?.split(/\n/)
  filteredList = partnerOnlineCodesArray?.filter(
    (item, index) =>
      partnerOnlineCodesArray.indexOf(item) === index && item !== ''
  )
  return filteredList
}

const validatePartnerOnlineCodes = (filteredList, values) => {
  if (filteredList?.length !== values.capTotal) {
    values.isInvalidPartnerOnlineReward = true
    return false
  }
  values.isInvalidPartnerOnlineReward = false
  return true
}

const validateUseByDate = (values) => {
  if (!values.usedByDate || !values.usedByTime) {
    values.isUseByDateNull = true
    return false
  }
  values.isUseByDateNull = false
  return true
}

const getFormattedPartnerOnlineCodes = (filteredList) =>
  filteredList?.map((code) => ({
    code: code.trim(),
  }))

const mapPhoneNumber = ({ phone, mapData }) => {
  mapData.contactInfo = {
    countryCode: '',
    phoneNumber: '',
  }
  if (phone && parsePhoneNumber(phone)) {
    const { countryCallingCode, nationalNumber } = parsePhoneNumber(phone)
    mapData.contactInfo = {
      countryCode: `+${countryCallingCode}`,
      phoneNumber: nationalNumber,
    }
  }
}

export const getDateValues = (startDateTime, endDateTime) => {
  return {
    startingDate: getFormattedDate(startDateTime),
    startingTime: getFormattedTime(startDateTime),
    endingDate: getFormattedDate(endDateTime),
    endingTime: getFormattedTime(endDateTime),
  }
}

export const formatCampaignDate = (startDate, startTime, endDate, endTime) => {
  const formattedDate = (date, time) => {
    const isoTimestamp = `${moment(date, 'DD MM YYYY')._i}T${
      moment(time, 'HH:mm:ss')._i
    }`
    return moment(isoTimestamp).format()
  }

  const startAt =
    startDate && startTime ? formattedDate(startDate, startTime) : ''
  const endAt = endDate && endTime ? formattedDate(endDate, endTime) : ''

  return { startAt, endAt }
}

export let PAGE_SIZE = 20

export const isRewardsPage = (className) => {
  const newSize = className?.includes('rewards-tab-catalogue') ? 5 : 20
  PAGE_SIZE = newSize
  return className?.includes('rewards-page') ?? false
}

export const setOffsetForRewardsPage = (page) => {
  const offset = (page - 1) * PAGE_SIZE
  return { offset }
}

export const handleDownloadClick = (csv, fileName) => {
  const csvDataUri = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  const hiddenElement = document.createElement('a')
  hiddenElement.href = csvDataUri
  hiddenElement.target = '_blank'
  hiddenElement.download = `${fileName}.csv`
  document.body.appendChild(hiddenElement)
  hiddenElement.click()
  document.body.removeChild(hiddenElement)
}

export const emailRegex = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$')

export {
  Input,
  Select,
  VALIDATION_TYPES,
  DATE_TIME_FORMAT,
  Rank,
  Status,
  Type,
  getStatusText,
  CHANNEL_OPTIONS,
  REWARDS_API,
  PublishAt,
  DatePickerField,
  TimePickerField,
  LinkButtons,
  FormButtons,
  ErrorScreen,
  TextDetails,
  SearchPartner,
  convertToISOString,
  tableProperties,
  getFilteredList,
  validatePartnerOnlineCodes,
  getFormattedPartnerOnlineCodes,
  validateUseByDate,
  mapPhoneNumber,
  hasPermissions,
}
