import React, { Component } from 'react'
import './style.css'
import API from '../../lib/api'
import Summary from '../Rewards/Devices/Summary'

class FileUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileUploading: false,
      file: props.uploadedFile || '',
      uploadError: '',
      fileUploaded: !!props.uploadedFile,
      summary: '',
    }
    this.readFile = this.readFile.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  checkInvalidFileType(accept, file) {
    // for .kml files, file.type is empty string "". So, check the name instead
    if (accept === '.kml') {
      return !file.name.endsWith('.kml');
    }
    return !accept
      .split(',')
      .map(str => str.trim())
      .includes(file.type)
  }

  readFile(e) {
    e.preventDefault()
    e.stopPropagation()
    /* istanbul ignore next */
    if(this.props.onchangeCallback){
      this.props.onchangeCallback()
    }
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      const state = {
        file: file,
      }

      // file validations
      if (
        this.props.accept !== '*' &&
        this.checkInvalidFileType(this.props.accept, file)
      ) {
        state['uploadError'] =
          this.props.validationStrings.invalidFileType || 'Invalid File Type'
      } else {
        state['uploadError'] = ''
      }
      this.setState(state)
    }
  }

  handleCancel() {
    this.setState({
      file: '',
      uploadError: '',
    })
  }

  handleFileUploadError({ onUpload, error }) {
    const defaultStateObject = {
      file: '',
      uploadError: error.message,
      fileUploading: false,
    }
    const updateStateObject = onUpload ? {...defaultStateObject} : {...defaultStateObject, fileUploaded: false}
    this.setState(
      updateStateObject,
      this.props.errorCallback
        ? _response => this.props.errorCallback(error)
        : null
    )
  }

  handleFileUpload() {
    let data = new window.FormData()
    const file = this.state.file
    const { uploadUrl, onUpload, isBulkTagging } = this.props
    this.setState(
      {
        fileUploading: true,
        fileUploaded: false,
      },
      () => {
        if (onUpload) {
          onUpload(file).then(
            () =>
              this.setState({
                fileUploading: false,
                uploadError: '',
              }),
            error =>
              this.handleFileUploadError({ onUpload, error })
          )
        } else if (isBulkTagging) {
          data.append('file', file)
          const api = new API({ url: uploadUrl })
          api.put(data).then(res => {
            this.setState(
              {
                fileUploading: false,
                fileUploaded: true,
                uploadError: '',
              },
              this.props.successCallback
                ? () => this.props.successCallback(res)
                : null
            )
          }).catch(error => {
            this.handleFileUploadError({ error })
          })
        } else {
          data.append(this.props.uploadKey || 'fileUpload', file)
          data = this.props.transformSubmit
            ? this.props.transformSubmit(data)
            : data
          const api = new API({ url: uploadUrl })
          api.post(data).then(
            response => {
              this.setState(
                {
                  fileUploading: false,
                  fileUploaded: true,
                  uploadError: '',
                  summary: response?.summary ?? '',
                },
                this.props.successCallback
                  ? () => this.props.successCallback(response)
                  : null
              )
            },
            error => {
              this.handleFileUploadError({ error })
            }
          )
        }
      }
    )
  }

  handleClick() {
    if (this.uploadInputRef?.value) {
      this.uploadInputRef.value = null
    }
  }

  render() {
    const {
      name,
      required,
      placeholder,
      accept,
      uploadButtonText,
      cancelText,
      icon,
      secondaryTitle
    } = this.props
    const { file, uploadError, fileUploaded, fileUploading, summary = '' } = this.state

    let title = (this.props.strings && this.props.strings.defaultMessage) || ''
    if (file.name && !uploadError && !fileUploaded) {
      title = (this.props.strings && this.props.strings.progressMessage) || ''
    } else if (fileUploaded) {
      title = (this.props.strings && this.props.strings.completionMessage) || ''
    }

    return (
      <span className="input FileUpload">
        <div className="title">
          {title}
          {(secondaryTitle && !file.name) && <span className='secondary-title'><br />{secondaryTitle}</span>}
        </div>
        {(!file.name || uploadError) && !fileUploaded && (
          <div className="file-container">
            <div className="file-label">
              {placeholder || 'Click here to upload the file'}
            </div>
            <input
              type="file"
              id={name}
              name={name}
              required={required}
              onChange={this.readFile}
              accept={accept}
              ref={node => {
                this.uploadInputRef = node
              }}
              onClick={this.handleClick}
              data-testid="fileDropzone"
            />
          </div>
        )}
        {uploadError ? (
          <div className="form-error">{uploadError}</div>
        ) : (
          file.name && (
            <div>
              <div className="file-name-container">
                {icon && (
                  <div className="file-icon">
                    <img src={icon} alt="" />
                  </div>
                )}
                <div className="upload-file-name">{file.name}</div>
              </div>
              {!fileUploaded && (
                <div className="upload-button-container">
                  <button
                    disabled={fileUploading}
                    type="button"
                    onClick={this.handleCancel}
                  >
                    {cancelText}
                  </button>
                  <button
                    disabled={fileUploading}
                    className="primary"
                    type="button"
                    onClick={this.handleFileUpload}
                  >
                    {fileUploading ? '...' : uploadButtonText}
                  </button>
                </div>
              )}
            </div>
          )
        )}
        {summary && <Summary summaryData={summary} />}
      </span>
    )
  }
}

FileUpload.defaultProps = {
  accept: '.csv,text/csv',
}

export default FileUpload
