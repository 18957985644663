import React, { useState, useEffect } from 'react'
import OrderSummaryHeader from './OrderSummaryHeader'
import SummaryTable from '../../../../../containers/SummaryTable'
import { qcOrderSummaryTabularView, popUpTabularView } from './Views'
import { QC_BE_GATEWAY } from '../../../../../config/app'
import FinalTotalAmount from '../../../../../components/FinalTotalAmount'

import {
  SuccessPopup,
  ReasonPopup,
  TablePopup,
} from '../../../../../components/Popup'
import API from '../../../../../lib/api'
import { getMessage } from '../../../../../lib/translator'
import {
  tablePopupHeading,
  tablePopupSubmitButtonText,
  tablePopupTestID,
  tablePopupAmountText,
  tablePopupTotalAmountText,
  successPopupHeading,
  successPopupDescription,
  reasonPopupHeading,
  reasonPopupQuestion,
  subOrderArray,
  handleReasons,
  handleTablePopupData,
  closeTablePopup,
  handleReasonPopupBack,
  handleTablePopupSubmit,
  handleReasonPopupChange,
  handleMultipleReasonPopupChange,
  handleApplyReasons,
  handlereasonMultipleSelectValue,
  closeSuccessPopup,
  handleRefundItems,
  handleRefundItemsWithContainerFee,
  handleTablePopupAmount,
  handleContainerFee,
  handleAutoRejectionPopup,
  handleContainerCheckboxItems,
  handleMultipleCancelReason,
} from './OrderSummaryHelpers'

const OrderSummary = props => {
  const { history, location } = props

  const [state, setState] = useState({
    subOrderStatusItemsContent: [],
    subOrderStatusItems: [],
    allSubOrdersStatus: props.data.subOrders.map(item => item.state),
    editSubOrderStatus: subOrderArray(props),
    submitting: true,
    cancelOrder: false,
    rejectOrder: false,
    refundOrder: false,
    allSelected: false,
    showHideElements: false,
    reasonSelectValue: '',
    reasonMultipleSelectValue: handlereasonMultipleSelectValue(
      props.data.subOrders,
      false
    ),
    showHideTablePopup: false,
    showHideReasonPopup: false,
    showHideSuccessPopup: false,
    showHideRefreshPopup: false,
    showHideAutoRejectedPopup: false,
    changeReasonSubmitted: true,
    radioSubOrder: '',
    refundItems: handleRefundItems(props.data.subOrders, false),
    wholeOrder: false,
    orderCancelled: false,
    hideTemp: false,
    selectAllReasons: false,
    containerCheckboxItems: handleContainerCheckboxItems(
      props.data.feeDetails,
      false
    ),
  })
  useEffect(() => {
    handleAutoRejectionPopup(state, props.data.createdAt, handleState)
  }, [])

  const handleState = stateVal => {
    setState(prevState => {
      return Object.assign({}, prevState, stateVal)
    })
  }

  const handleReasonPopupSubmit = () => {
    let params = {}
    let apiUrl = ''
    if (state.refundOrder) {
      params = {
        subOrders: handleRefundItemsWithContainerFee(props, state),
        reason: state.reasonSelectValue,
        refundBy: 'CUSTOMER_SERVICE',
      }
      apiUrl = `${QC_BE_GATEWAY}/orders/${props.data.id}/refund`
    } else if (state.rejectOrder) {
      params = {
        rejectBy: 'CUSTOMER_SERVICE',
        reason: state.reasonSelectValue,
      }
      apiUrl = `${QC_BE_GATEWAY}/suborders/${state.radioSubOrder}/reject`
    } else {
      params = {
        cancelBy: 'MERCHANT',
        subOrders: handleMultipleCancelReason(state.reasonMultipleSelectValue),
      }
      apiUrl = `${QC_BE_GATEWAY}/suborders/cancel`
    }
    const api = new API({
      url: apiUrl,
    })
    api.post(params).then(
      response => {
        handleState({
          ...state,
          showReasonPopup: false,
          showHideTablePopup: false,
          showHideSuccessPopup: true,
          orderCancelled: response.data ? response.data.orderCancelled : false,
        })
      },
      error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
      }
    )
  }
  const containerFee = props.data.feeDetails.find(
    a => a.type === 'CONTAINER_FEE'
  )
  const cardSurcharge = props.data.feeDetails.find(
    a => a.type === 'CARD_SURCHARGE'
  )
  const appDiscount =
    props.data.discountDetails &&
    props.data.discountDetails.find(a => a.reason === 'APP_PAYMENT_DISCOUNT')
      ? props.data.discountDetails.find(
          a => a.reason === 'APP_PAYMENT_DISCOUNT'
        )
      : { formattedAmount: '$0.00' }

  return (
    <div className="qc-container" data-testid="order-summary-container">
      <OrderSummaryHeader
        handleState={handleState}
        state={state}
        data={props.data}
        history={history}
        location={location}
        refundDetails={props.refundDetails}
      />

      <SummaryTable
        className={`qc-odp ${props.className || 'ops'}`}
        data={[{ ...props.data }]}
        tableProperties={qcOrderSummaryTabularView(
          props.url,
          handleState,
          state
        )}
        primaryKey={props.data.id}
        testid="order-summary"
        tableDynamic
        summary
      />
      <FinalTotalAmount
        subtotal={props.data.formattedSubTotal}
        fees={props.data.formattedTotalFees}
        cardSurcharge={props.data.feeDetails && cardSurcharge}
        finalAmount={props.data.formattedFinalTotal}
        originalTotalAmount={props.data.formattedTotal}
        containerFee={containerFee}
        feesReturned={props.data.formattedTotalFeesReturned}
        amountCancelled={
          props.data.formattedCancelledTotal &&
          props.data.formattedCancelledTotal !== '$0.00'
            ? props.data.formattedCancelledTotal
            : false
        }
        amountRejected={
          props.data.formattedRejectedTotal &&
          props.data.formattedRejectedTotal !== '$0.00'
            ? props.data.formattedRejectedTotal
            : false
        }
        amountRefunded={
          props.data.formattedRefundedTotal &&
          props.data.formattedRefundedTotal !== '$0.00'
            ? props.data.formattedRefundedTotal
            : false
        }
        appDiscount={appDiscount}
        serviceFee={props.data.feeDetails && cardSurcharge}
        hideTemp={state.hideTemp}
        cancellationBreakdown={props.data.cancellationBreakdown}
        refundBreakdown={props.data.refundBreakdown}
        handleState={handleState}
        state={state}
        subOrders={props.data.subOrders}
      />
      <TablePopup
        show={state.showHideTablePopup}
        close={() => closeTablePopup(handleState, state)}
        heading={tablePopupHeading(state)}
        submitButtonText={tablePopupSubmitButtonText(state)}
        handleSubmit={() => handleTablePopupSubmit(handleState, state)}
        backButtonText={getMessage('qc.orderSummary.tablepopup.button.dismiss')}
        state={state}
        containerFee={handleContainerFee(state, props)}
        cardSurchargeText={getMessage('qc.orderSummary.CardSurcharge')}
        cardSurcharge={state.wholeOrder && cardSurcharge}
        data={handleTablePopupData(state, props)}
        tableProperties={popUpTabularView(props.url, handleState, state)}
        primaryKey={props.data.id}
        testid={tablePopupTestID(state)}
        amountText={tablePopupAmountText(state)}
        totalAmountText={tablePopupTotalAmountText(state)}
        Amount={handleTablePopupAmount(state, props)}
        Prepared={state.cancelOrder}
      />
      <ReasonPopup
        show={state.showReasonPopup}
        close={() => handleReasonPopupBack(handleState, state)}
        heading={reasonPopupHeading(state)}
        question={reasonPopupQuestion(state)}
        selectReason={getMessage('qc.orderSummary.reasonpopup.SelectReason')}
        submitButtonText={getMessage(
          'qc.orderSummary.reasonpopup.button.submit'
        )}
        handleSubmit={handleReasonPopupSubmit}
        backButtonText={getMessage('qc.orderSummary.reasonpopup.button.back')}
        handleBack={() => handleReasonPopupBack(handleState, state)}
        reasons={handleReasons(state, props.data.fulfillmentMethod)}
        state={state}
        data={handleTablePopupData(state, props)}
        value={state.reasonSelectValue}
        multiValue={state.reasonMultipleSelectValue}
        changeReason={e => handleReasonPopupChange(handleState, state, e)}
        changeMultipleReason={(e, id) =>
          handleMultipleReasonPopupChange(handleState, state, e, id)
        }
        selectAllReasons={e => handleApplyReasons(handleState, state, e)}
      />
      <SuccessPopup
        {...props}
        show={state.showHideSuccessPopup}
        close={() => closeSuccessPopup(handleState, state, history, location)}
        heading={successPopupHeading(state)}
        description={successPopupDescription(state)}
        buttonText={getMessage('qc.orderSummary.successpopup.button')}
      />
    </div>
  )
}

export default OrderSummary
