import React from 'react'
import { View } from '.'

export const Progress = ({ progress, ...rest }) => {
  return (
    <View
      display="block"
      background="#EAEAEA"
      borderRadius={4}
      border="1px solid transparent"
      margin="24px 0 0"
      {...rest}
    >
      <View
        background={progress === 100 ? '#3FC078' : '#1454B8'}
        height={8}
        width={`${progress}%`}
      ></View>
    </View>
  )
}
