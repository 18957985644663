import React, { Component } from 'react'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import PermissionsForm from './Form'
import Loader from '../../../../components/Loader'
import API from '../../../../lib/api'
import { getAllPermissions } from '../../../../lib/auth'
import { getMessage } from '../../../../lib/translator'

import './style.css'

function handleException(error) {
  if (error.code === 401) {
    throw error
  }
}

export default class Permissions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      permissions: null,
      loading: false,
      failed: false,
      submitting: false,
    }
    this.allPermissions = this.categorizePermissions(getAllPermissions(), true)
    this.getData = this.getData.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.parsePermissions = this.parsePermissions.bind(this)
  }
  parsePermissions(endpointPermissions) {
    const permissions = this.categorizePermissions(
      Object.assign(
        {},
        ...endpointPermissions.map(({ url, allowedMethods }) => ({
          [url]: allowedMethods,
        }))
      )
    )
    return this.prunePermissions(this.allPermissions, permissions)
  }
  getData() {
    this.setState({ loading: true, failed: false })
    const api = new API({
      url: `/account-service/user/${this.props.router.match.params.id}`,
    })
    api
      .get()
      .then(
        (response) => {
          const permissions = this.parsePermissions(
            response.data.user.endpointPermissions
          )
          delete response.data.endpointPermissions
          this.setState({
            data: response.data.user,
            permissions,
          })
        },
        (err) => {
          this.setState({ failed: true })
          handleException(err)
        }
      )
      .then(() => {
        this.setState({ loading: false })
      })
  }
  categorizePermissions(uncategorized = {}, prune = false) {
    const result = {}
    Object.keys(uncategorized).forEach((endpoint) => {
      const [service, api] = endpoint.split('/').filter(Boolean)
      const permissions = uncategorized[endpoint]
      const allKeysFalse = !Object.values(permissions).reduce(
        (acc, val) => acc || val,
        false
      )
      if (prune ? !allKeysFalse : true) {
        if (!(service in result)) {
          result[service] = {}
        }
        result[service][api] = prune
          ? Object.assign(
              {},
              ...Object.keys(permissions)
                .filter((method) => permissions[method])
                .map((method) => ({
                  [method]: permissions[method],
                }))
            )
          : permissions
      }
    })
    return result
  }
  prunePermissions(allowedPermissions, newPermissions) {
    // Remove all the changes that are not allowed
    const result = {}
    for (const service in allowedPermissions) {
      const apiPermissions = {}
      for (const api in allowedPermissions[service]) {
        const methodPermissions = {}
        for (const method in allowedPermissions[service][api]) {
          if (allowedPermissions[service][api][method]) {
            if (
              newPermissions[service] &&
              newPermissions[service][api] &&
              newPermissions[service][api][method]
            ) {
              methodPermissions[method] = newPermissions[service][api][method]
            }
          }
        }
        if (Object.keys(methodPermissions).length) {
          apiPermissions[api] = methodPermissions
        }
      }
      if (Object.keys(apiPermissions).length) {
        result[service] = apiPermissions
      }
    }
    return result
  }
  handleSubmit(value) {
    const userId = this.props.router.match.params.id
    const permissions = []
    for (const service in value) {
      for (const serviceApi in value[service]) {
        for (const method in value[service][serviceApi]) {
          permissions.push({
            endpoint: `${service}/${serviceApi}`,
            method,
            hasPermission: value[service][serviceApi][method],
          })
        }
      }
    }
    const api = new API({ url: '/account-service/permission' })
    this.setState({ submitting: true, permissions: value })
    return api.put({ userId, permissions }).then(() => {
      this.setState({ submitting: false })
      const { history, location } = this.props
      history.push(location.pathname.replace(/(\/edit-permissions\/.*)$/, ''))
    })
  }
  componentDidMount() {
    this.getData()
  }
  render() {
    const { props } = this
    return (
      <AuthenticatedPage
        menu={props.menu}
        from={props.location && props.location.pathname}
        className="permissions-page"
      >
        <h1 className="title">
          {this.state.data
            ? getMessage('settings.users.permissionsForm.heading', {
                name: this.state.data.name,
              })
            : getMessage('settings.users.permissionsForm.headingWithoutName')}
        </h1>
        {this.state.data ? (
          <PermissionsForm
            allPermissions={this.allPermissions}
            parsePermissions={this.parsePermissions}
            value={this.state.permissions}
            userId={props.router.match.params.id}
            onSubmit={this.handleSubmit}
            submitting={this.state.submitting}
            disabled={this.state.submitting}
            onCancel={() => {
              const { history, location } = this.props
              history.push(
                location.pathname.replace(/(\/edit-permissions\/.*)$/, '')
              )
            }}
          />
        ) : (
          <Loader />
        )}
      </AuthenticatedPage>
    )
  }
}
