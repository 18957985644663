import React from 'react'
import { SingleDatePicker, TimePicker } from '../../Form'
import { getMessage } from '../../../lib/translator'

const Field = ({
  labelKey,
  placeholderKey,
  stateKey,
  isDatePicker,
  _this,
  taskIndex,
  awardIndex,
}) => (
  <div className="col-6">
    {isDatePicker ? (
      <SingleDatePicker
        enableToday
        label={getMessage(labelKey)}
        placeholder={getMessage(placeholderKey)}
        {..._this.generateStateMappers({
          stateKeys: ['taskList', taskIndex, 'awardList', awardIndex, stateKey],
        })}
      />
    ) : (
      <TimePicker
        label={getMessage(labelKey)}
        placeholder={getMessage(placeholderKey)}
        {..._this.generateStateMappers({
          stateKeys: ['taskList', taskIndex, 'awardList', awardIndex, stateKey],
        })}
      />
    )}
  </div>
)

const PtrDateAndTimeFields = ({ _this, taskIndex, awardIndex }) => (
  <div className="row">
    <Field
      labelKey="rewards.startDate"
      placeholderKey="rewards.startDate"
      stateKey="startDate"
      isDatePicker
      _this={_this}
      taskIndex={taskIndex}
      awardIndex={awardIndex}
    />
    <Field
      labelKey="rewards.startTime"
      placeholderKey="rewards.startTime"
      stateKey="startTime"
      _this={_this}
      taskIndex={taskIndex}
      awardIndex={awardIndex}
    />
    <Field
      labelKey="rewards.endDate"
      placeholderKey="rewards.endDate"
      stateKey="endDate"
      isDatePicker
      _this={_this}
      taskIndex={taskIndex}
      awardIndex={awardIndex}
    />
    <Field
      labelKey="rewards.endTime"
      placeholderKey="rewards.endTime"
      stateKey="endTime"
      _this={_this}
      taskIndex={taskIndex}
      awardIndex={awardIndex}
    />
  </div>
)

export default PtrDateAndTimeFields
