import React from 'react';
import { getMessage } from 'lib/translator';
import CommonMapComponent from 'pages/settings/DeliveryArea/CommonMapComponent';
import { distinctColors } from '../constants';
import './style.scss';

export function MapInputStep({ zonePayload, setZonePayload, drawnZone, setDrawnZone }) {

  const handleZoneDrawing = ({ locations }) => {
    const newZone = {
      configType: 'POLYGON',
      name: 'new_polygon',
      tempId: 123,
      area: {
        locations: locations.map(coord => {
          return {
            latitude: coord.lat,
            longitude: coord.lng,
          }
        }),
      },
    }
    setDrawnZone([newZone]);
    setZonePayload({
      name: zonePayload?.name || '',
      polygon: locations.map(x => `${x.lng} ${x.lat}`).join(', ')
    })
  }

  const triggerUndoLastDrawnZone = () => {
    setDrawnZone([]);
    setZonePayload({
      ...zonePayload,
      polygon: null
    });
  }

  return (
    <div className='map-input-step'>
      <p>{getMessage('operations.timeSlotBlocking.addRule.mapInput.label')}</p>
      <div className='delivery-area-content'>
        <div className='map-area'>
          <CommonMapComponent
            editing={drawnZone.length === 0}
            deliveryArea={drawnZone}
            setNewLocations={handleZoneDrawing}
            showUndoLastDrawnZoneIcon={drawnZone.length === 1}
            triggerUndoLastDrawnZone={triggerUndoLastDrawnZone}
            distinctColors={distinctColors} />
        </div>
      </div>
    </div>
  )
}

