import React, { Component } from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { Dialog } from '../../../components/Popup'
import DetailsCard from '../../../components/DetailsCard'

import { getMessage } from '../../../lib/translator'
import { formatDate, getPrintableTime } from '../../../lib/datetime'

import EmptyIcon from './cancelledLeave.svg'
import './style.css'

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage('leaves.team.empty.message'),
}

class LeaveActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
      showRejectDialog: false,
    }
    this.showDialog = this.showDialog.bind(this)
    this.hideDialog = this.hideDialog.bind(this)
    this.showRejectDialog = this.showRejectDialog.bind(this)
    this.approveLeave = this.approveLeave.bind(this)
    this.rejectLeave = this.rejectLeave.bind(this)
  }

  showDialog() {
    this.setState({
      showDialog: true,
    })
  }

  showRejectDialog() {
    this.setState({
      showRejectDialog: true,
    })
  }

  hideDialog() {
    this.setState({
      showDialog: false,
      showRejectDialog: false,
    })
  }

  approveLeave(id) {
    const { onAction } = this.props
    onAction(TABLE_ACTIONS.UPDATE, { id }, { status: 'APPROVED' })
  }

  rejectLeave(id) {
    const { onAction } = this.props
    onAction(TABLE_ACTIONS.UPDATE, { id }, { status: 'REJECTED' })
  }

  render() {
    const { id, status } = this.props
    return (
      <div>
        {status === 'PENDING' && (
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem onClick={this.showDialog}>
              {getMessage('leaves.team.approve')}
            </DropDownItem>
            <DropDownItem onClick={this.showRejectDialog}>
              {getMessage('leaves.team.reject')}
            </DropDownItem>
          </DropDown>
        )}
        <Dialog
          show={this.state.showDialog}
          title={getMessage('leaves.team.are.you.sure')}
          information={getMessage('leaves.team.approve.leave')}
          onOk={() => this.approveLeave(id)}
          close={this.hideDialog}
          okText={getMessage('leaves.team.approve')}
          closeText={getMessage('leaves.team.cancel')}
        />
        <Dialog
          show={this.state.showRejectDialog}
          title={getMessage('leaves.team.are.you.sure')}
          information={getMessage('leaves.team.reject.leave')}
          onOk={() => this.rejectLeave(id)}
          close={this.hideDialog}
          okText={getMessage('leaves.team.reject')}
          closeText={getMessage('leaves.team.cancel')}
        />
      </div>
    )
  }
}

const tableProperties = {
  headers: [
    getMessage('leaves.team.employee'),
    getMessage('leaves.team.startson'),
    getMessage('leaves.team.endson'),
    getMessage('leaves.team.appliedon'),
    getMessage('leaves.team.reason'),
    getMessage('leaves.team.type'),
    getMessage('leaves.team.status'),
    getMessage('leaves.team.actions'),
  ],
  row: ({
    id,
    fromDate,
    toDate,
    appliedOn,
    reason,
    type,
    status,
    user,
    onAction,
  }) => (
    <Row>
      <Cell>{user.name}</Cell>
      <Cell>{formatDate(fromDate)}</Cell>
      <Cell>{formatDate(toDate)}</Cell>
      <Cell>{getPrintableTime(appliedOn)}</Cell>
      <Cell>{reason}</Cell>
      <Cell>{type}</Cell>
      <Cell>{status}</Cell>
      <Cell>
        {<LeaveActions id={id} onAction={onAction} status={status} />}
      </Cell>
    </Row>
  ),
}

const gridView = {
  row: ({ id, fromDate, toDate, reason, type, status, onAction }) => (
    <div className="spacer">
      <DetailsCard
        leftTitle={type}
        leftValue={reason}
        rightTitle={getMessage('leaves.header.status')}
        rightValue={status}
        bottomValue={`${formatDate(fromDate)} - ${formatDate(toDate)}`}
      >
        {<LeaveActions id={id} onAction={onAction} status={status} />}
      </DetailsCard>
    </div>
  ),
}

export default class TeamLeaves extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowSize: window.innerWidth,
    }
    this.registerResize = this.registerResize.bind(this)
  }

  registerResize(e) {
    this.setState({
      windowSize: e.target.innerWidth,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.registerResize, true)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.registerResize, true)
  }

  render() {
    let tableProps = tableProperties
    if (this.state.windowSize <= 768) {
      tableProps = gridView
    }
    return (
      <ListingPage
        className="team-leaves-approve-page"
        api={{
          url: '/account-service/user-leave',
          params: { leaveType: 'PENDING' },
          transform: (response) => response.data.userleave,
        }}
        emptyState={EmptyState}
        tableProperties={tableProps}
      />
    )
  }
}
