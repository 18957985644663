import React, { useState, useEffect, useCallback, useMemo } from 'react'
import AutocompleteInput from '../../../components.new/autocomplete/AutocompleteInput'
import tagIcon from 'icons/tag.svg'
import deleteIcon from 'icons/delete.svg'
import API from 'lib/api'
import { getMessage } from 'lib/translator'
import '../applicable-items.styles.css'

const StoresApplicable = ({ onUpdate, initialValues = {} }) => {
  const [storeList, setStoreList] = useState([])
  const [selectedStores, setSelectedStores] = useState([])
  const [error, setError] = useState('')

  const fetchStores = useCallback(async (partnerId, storeIds) => {
    try {
      const response = await new API({
        url: `/rms/partners/${partnerId}`,
      }).get()
      const filteredStores = response.stores
        ?.filter((item) => item.name !== '')
        .map((item) => ({ label: item.name, value: item.id }))

      setSelectedStores(
        filteredStores?.filter((store) => storeIds?.includes(store.value)) || []
      )
      setStoreList(filteredStores || [])
    } catch (e) {
      setError(e.message || getMessage('rewards.defaultError'))
    }
  }, [])

  useEffect(() => {
    if (initialValues?.partnerId) {
      fetchStores(initialValues.partnerId, initialValues.storeIds)
    } else {
      setSelectedStores([])
      setStoreList([])
    }
  }, [initialValues.partnerId])

  useEffect(() => {
    if (selectedStores?.length !== initialValues?.storeIds?.length) {
      onUpdate(selectedStores.map((store) => store.value))
    }
  }, [selectedStores, onUpdate])

  const handleSelect = useCallback(
    (option) => {
      if (!selectedStores.some((store) => store.value === option.value)) {
        setSelectedStores((prev) => [...prev, option])
      }
    },
    [selectedStores]
  )

  const handleDelete = useCallback((value) => {
    setSelectedStores((prev) => prev.filter((store) => store.value !== value))
  }, [])

  const storeOptions = useMemo(
    () =>
      storeList.map((opt) => ({
        label: opt.label,
        value: opt.value,
      })),
    [storeList]
  )

  return (
    <div className="rewards-applicable-container stores">
      <div className="rewards-applicable-list">
        <label>{getMessage('rewards.storesApplicable')}</label>
      </div>
      <div className="rewards-applicable-list">
        <button
          className="rewards-applicable-btn"
          onClick={(e) => {
            e.preventDefault()
            setSelectedStores(storeList)
          }}
        >
          {getMessage('rewards.allStores')}
        </button>
      </div>

      <AutocompleteInput
        options={storeOptions}
        placeholder={getMessage('rewards.searchStores')}
        onSelect={handleSelect}
      />

      <SelectedList items={selectedStores} onDelete={handleDelete} />
      {selectedStores.length > 0 && (
        <div
          role="button"
          className="remove-all-btn"
          onClick={() => setSelectedStores([])}
        >
          {getMessage('rewards.removeAll')}
          {selectedStores.length > 1 && ` (${selectedStores.length})`}&nbsp;
          {getMessage('rewards.stores.title')}
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  )
}

const SelectedList = ({ items, onDelete }) => (
  <ul className="selected-list">
    {items.map((store) => (
      <li key={store.value} className="selected-list__item">
        <div className="selected-list__label">
          <img src={tagIcon} alt="Tag icon" className="tag-icon" />
          <small>{store.label}</small>
        </div>
        <div className="delete-icon__container">
          <img
            src={deleteIcon}
            alt={`Delete ${store.label}`}
            className="delete-icon"
            onClick={() => onDelete(store.value)}
          />
        </div>
      </li>
    ))}
  </ul>
)

export default StoresApplicable
