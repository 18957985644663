import React from 'react'

import AppHomePagination from '../../components/AppHome/AppHomePagination'
import HelpItems from '../../components/ListingPage/HelpItems'
import { isNoResult, isFilterApplied } from '../../components/ListingPage/utils'
import Pagination from '../../components/Pagination'
import Table, { Header } from '../../components/Table'

import { APP_HOME_PAGES } from './constants'

const ListingPageView = ({
  // this.state.xx from parent
  data,
  updatingApiParams,
  apiParams,
  // this.props.xx from parent
  emptyState,
  helpItems,
  tableProperties,
  className,
  updateView,
  testid,
  tableDynamic,
  addToTable,
  nextPrevButtonsEnabled,
  // this.xx from parent
  api,
  renderToast,
  primaryKey,
  performAction,
  changePage,
  changeAppHomePage,
  showPaginationCounts,
}) => {
  const filtersApplied = isFilterApplied({ data })
  const noResult = isNoResult({
    api,
    data,
  })

  if (noResult) {
    if (filtersApplied) {
      return <div className="text-muted text-center">No results found</div>
    } else {
      return <HelpItems emptyState={emptyState} helpItems={helpItems} />
    }
  } else if (data.items && tableProperties.row && !updatingApiParams) {
    const Row = tableProperties.row
    const {
      count: countProp,
      offset: offsetProp,
      limit: limitProp,
    } = data.paging

    let count = countProp
    let offset = offsetProp
    let limit = limitProp
    count = Number(count) || Number(data.items.length)
    offset = Number(offset)
    limit = Number(limit)
    const totalPages = Math.ceil(count / limit)
    const page = window.localStorage.getItem(className)
    const isAppHomePage = APP_HOME_PAGES.includes(className)
    const viewData = updateView
      ? data.viewItems && data.viewItems.length > 0
        ? data.viewItems
        : data.items
      : data.items

    return (
      <div className="table-container" data-testid={testid}>
        <div className="table-responsive">
          {renderToast()}
          <Table tableDynamic={tableDynamic || false}>
            {tableProperties.headers ? (
              <Header items={tableProperties.headers} />
            ) : null}
            {viewData.map((row, index) => (
              <Row
                {...row}
                key={row[primaryKey]}
                apiParams={apiParams}
                onAction={performAction}
                index={index}
              />
            ))}
            {addToTable && <addToTable onAction={performAction} />}
          </Table>
        </div>
        {!isAppHomePage && (
          <div className="pagination-container">
            <div className="pagination-count">
              {showPaginationCounts(count, limit, offset)}
            </div>
            {(totalPages > 1 || nextPrevButtonsEnabled) && (
              <Pagination
                total={totalPages}
                current={Number(page) || Math.floor(offset / limit + 1)}
                onSelect={changePage}
                nextPrevButtonsEnabled={nextPrevButtonsEnabled}
                offset={offset}
                // pageNumberNotEditable
              />
            )}
          </div>
        )}
        {isAppHomePage && (
          <AppHomePagination
            data={data}
            onSelect={changeAppHomePage}
            page={page}
          />
        )}
      </div>
    )
  }
  return null
}

export default ListingPageView
