import { getMessage } from '../../../../lib/translator'

export const orderTypeOptions = [
  {
    text: getMessage('offer.ordertype.options.bulk'),
    value: 'BULK',
  },
  {
    text: getMessage('offer.ordertype.options.delivery'),
    value: 'DELIVERY',
  },
  {
    text: getMessage('offer.ordertype.options.pickup'),
    value: 'PICKUP',
  },
  {
    text: getMessage('offer.ordertype.options.offline'),
    value: 'OFFLINE',
  },
  {
    text: getMessage('offer.ordertype.options.b2b'),
    value: 'B2B',
  },
  {
    text: getMessage('offer.ordertype.options.instore'),
    value: 'INSTORE',
  },
  {
    text: getMessage('offer.ordertype.options.preorder'),
    value: 'RB_PREORDER',
  },
]

export const digitalCheckoutOrderTypes = [
  {
    text: getMessage('offer.ordertype.options.dp.fairprice'),
    value: 'DP_FAIRPRICE',
  },
  {
    text: getMessage('offer.ordertype.options.dp.cheers'),
    value: 'DP_CHEERS',
  },
  {
    text: getMessage('offer.ordertype.options.dp.kopitiam'),
    value: 'DP_KOPITIAM',
  },
]

export const digitalPayViaFpApp = [
  {
    text: getMessage('order.details.location.option.delivery'),
    value: 'DELIVERY',
  },
  {
    text: getMessage('offer.ordertype.options.offline'),
    value: 'OFFLINE',
  },
  {
    text: getMessage('order.filters.preorder'),
    value: 'RB_PREORDER',
  },
]

export const tabsList = [
  {
    text: 'Promotion',
  },
  {
    text: 'Restrictions',
  },
  {
    text: 'Override Content',
  },
]

export const iconTypes = [
  {
    text: 'QR code',
    value: 'qr_code',
  },
  {
    text: 'Bar-Code',
    value: 'barcode',
  },
  {
    text: '$ Value',
    value: 'dollar_off',
  },
  {
    text: '% Off',
    value: 'percent_off',
  },
  {
    text: 'Free Gift ',
    value: 'free_gift',
  },
]

export const treatmentTypes = [
  {
    text: 'Partial Payments',
    value: 'PARTIAL_PAYMENTS',
  },
  {
    text: 'Discounts',
    value: 'DISCOUNTS',
  },
]
