import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Table from './Table'
import DeliveryOrderDetails from './Details'

class DeliveryOrdersRouter extends Component {
  render() {
    const { router } = this.props
    // url can be /operations/delivery-orders OR /customer-support/delivery-orders depending on which route is rendering the component
    const url = this.props.location.pathname.split('/')[1];
    const details = { ...this.props, url: url.padStart(url.length+1, '/') }
    if (router.match.params.id) {
      return <DeliveryOrderDetails {...details} />
    }
    return <Table {...details} />
  }
}

export default withRouter(({ match, ...props }) => (
  <DeliveryOrdersRouter router={{ match }} {...props} />
))
