import React from 'react'
import {
  Select,
  VALIDATION_TYPES,
  SingleDatePicker,
  BaseForm,
  Input,
} from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'
import './style.css'

const getTimes = () => {
  const times = []
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      times.push(`0${i}:00`)
      times.push(`0${i}:30`)
    } else {
      times.push(`${i}:00`)
      times.push(`${i}:30`)
    }
  }
  return times
}

export default class DateTimeSearchFilter extends BaseForm {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClear = this.handleClear.bind(this)
  }

  handleSubmit() {
    this.props.handleSearchTerm(this.state.values)
  }

  handleClear() {
    this.setState({ values: {} })
    this.props.handleSearchTerm({})
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <div onSubmit={this.handleSubmit}>
        <Form>
          <div className="filter-main">
            <div className="date-time-section">
              <div className="date-time-filter">
                <SingleDatePicker
                  label={getMessage('banners.editForm.validity.from')}
                  placeholder={getMessage('banners.startTime.placeholder')}
                  displayFormat="YYYY-MM-DD"
                  name="startDate"
                  className="date-section"
                  allowAllDates
                  openDirection={'down'}
                  {...this.generateStateMappers({
                    stateKeys: ['startDate'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
                <Select
                  label={getMessage('campaign.startTime')}
                  name="startTime"
                  type="text"
                  className="time-section"
                  placeholder={'00:00'}
                  options={getTimes()}
                  {...this.generateStateMappers({
                    stateKeys: ['startTime'],
                    defaultValue: '00:00',
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
              <div className="date-time-filter">
                <SingleDatePicker
                  label={getMessage('banners.editForm.validity.to')}
                  placeholder={getMessage('banners.endTime.placeholder')}
                  displayFormat="YYYY-MM-DD"
                  openDirection={'down'}
                  allowAllDates
                  name="endDate"
                  className="date-section"
                  {...this.generateStateMappers({
                    stateKeys: ['endDate'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
                <Select
                  label={getMessage('campaign.endTime')}
                  name="endTime"
                  type="text"
                  className="time-section"
                  placeholder={'00:00'}
                  options={getTimes()}
                  {...this.generateStateMappers({
                    stateKeys: ['endTime'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            </div>
            <div>
              <Input
                label={getMessage('campaign.searchByName')}
                placeholder={getMessage('campaign.enterTheName')}
                name="name"
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ['name'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <div className="actions">
              <ClearButton onClear={this.handleClear} testid="clearSearch">
                {getMessage('search-configuration.clear.button.label')}
              </ClearButton>
              <SubmitButton testid="submitSearch">
                {getMessage('search-configuration.search.button.label')}
              </SubmitButton>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}
