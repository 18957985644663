/*
  This file exists in src/ folder due to limitations set by create-react-app
*/
import { getOktaState, handleDBPRedirect } from '../lib/utils'

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

const GO_HOST =
  process.env.REACT_APP_API_URL || 'https://api.zs-uat.fairprice.com.sg'
const GO_HOST_PUBLIC =
  process.env.REACT_APP_API_PUBLIC_URL ||
  'https://public-api.zs-uat.fairprice.com.sg'
const GTM_ID = process.env.REACT_APP_GTM_ID
const PREORDER_API =
  process.env.REACT_APP_PREORDER_URL ||
  'https://gt-campaign-service-hgzmusx7fq-as.a.run.app'
const CAMPAIGN_LABELS_API =
  process.env.REACT_APP_CAMPAIGN_LABELS_URL ||
  'https://gt-layout-service-hgzmusx7fq-as.a.run.app'
const CONFIG_API =
  process.env.REACT_APP_CONFIG_URL ||
  'https://gt-catalogue-service-hgzmusx7fq-as.a.run.app'
const FULFILMENT_API =
  process.env.REACT_APP_FULFILMENT_URL ||
  'https://api.fulfilment.preprod.fairprice.com.sg'
const IS_STAGING = GO_HOST.includes('staging')
const GOOGLE_MAP_DEFAULT_KEY = process.env.GOOGLE_MAP_KEY
const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places,drawing&key=`
const GOOGLE_MAP_JSON_URL =
  process.env.GOOLE_MAP_JSON_URL ||
  'https://maps.googleapis.com/maps/api/geocode/json'
// API_URL
const BUGSNAG_KEY = ''
const FPGP_MAPS_URL =
  process.env.REACT_APP_MAPS_URL || 'http://maps.thor.zopsmart.com'
const GIT_HASH = process.env.REACT_APP_GIT_HASH
const GIT_TAG = process.env.REACT_APP_GIT_TAG

// RUM integration for backoffice.
const RUM_APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID
const RUM_CLIENT_TOKEN = process.env.REACT_APP_CLIENT_TOKEN

const REACT_APP_MEDIA_SERVICE_API_URL =
  process.env.REACT_APP_MEDIA_SERVICE_API_URL ||
  'https://preprod-media.nedigital.sg/api'

const STORE_TECH_HOST =
  process.env.REACT_APP_STORE_TECH_API_URL ||
  'http://storetech-uat.fairprice.com.sg'

const MARTECH_AD_HOST =
  process.env.REACT_APP_MARTECH_AD_API_URL ||
  'https://martech-ad-server-uat.nedigital.sg'

const LINK_HOST =
  process.env.REACT_APP_LINK_API || 'https://api.preprod.link.sg'
const CUSTOMER_SEGMENTATION_SERVICE =
  process.env.REACT_APP_CUSTOMER_SEGMENTATION_SERVICE ||
  'https://customer-segmentation-service-esp-m6qxzyeuta-as.a.run.app'

const TERMINAL_LINK_HOST = process.env.REACT_APP_TERMINAL_LINK_API

const PAY_URL = process.env.REACT_APP_PAY_URL

const P13N_HOST = process.env.REACT_APP_P13N_API

const PICKERS_TODAY_ROLE_IDS =
  process.env.REACT_APP_PICKERS_TODAY_ROLE_IDS || ''

const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER
const OKTA_CLIENTID = process.env.REACT_APP_OKTA_CLIENTID
const QC_BE_GATEWAY =
  process.env.REACT_APP_QC_BE_GATEWAY ||
  'https://backoffice-9fgcialh.an.gateway.dev/v1'

const SPLIT_KEY = process.env.REACT_APP_SPLIT_KEY

const FE_GATEWAY_API_URL =
  process.env.REACT_APP_FE_GATEWAY_API_URL ||
  'https://website-api.zs-uat.fairprice.com.sg/api'

const IS_OKTA_ENABLED = getOktaState()

/**
 *@actiontrail configs
 */
const ACTION_TRAIL_API = process.env.REACT_APP_ACTION_TRAIL_API || ''

const ACTION_TRAIL_STREAM_NAME =
  process.env.REACT_APP_ACTION_TRAIL_STREAM_NAME ||
  'actions-trail-firehose-stream'

const ACTION_TRAIL_CYCLE_PERIOD =
  process.env.REACT_APP_ACTION_TRAIL_CYCLE_PERIOD || 60000

const ACTION_TRAIL_CUSTOM_HEADER =
  process.env.REACT_APP_ACTION_TRAIL_CUSTOM_HEADER || ''

const RELEASE_VERSION = process.env.REACT_APP_RELEASE_VERSION

const DD_SERVICE_NAME = process.env.REACT_APP_DD_SERVICE_NAME

const LINKPASS_ADMIN_URL =
  process.env.REACT_APP_LINKPASS_API || 'https://api.pass.preprod.link.sg'

const APG_GATEWAY_HOST = process.env.REACT_APP_APG_GATEWAY_HOST || ''

// To handle redirect from UAT and PROD to DBP Backoffice
handleDBPRedirect()

export {
  GO_HOST,
  GO_HOST_PUBLIC,
  STORE_TECH_HOST,
  LINK_HOST,
  CUSTOMER_SEGMENTATION_SERVICE,
  TERMINAL_LINK_HOST,
  GOOGLE_MAPS_URL,
  GOOGLE_MAP_JSON_URL,
  BUGSNAG_KEY,
  FPGP_MAPS_URL,
  GIT_HASH,
  GIT_TAG,
  IS_STAGING,
  GOOGLE_MAP_DEFAULT_KEY,
  REACT_APP_MEDIA_SERVICE_API_URL,
  GTM_ID,
  RUM_APPLICATION_ID,
  RUM_CLIENT_TOKEN,
  PICKERS_TODAY_ROLE_IDS,
  ENVIRONMENT,
  OKTA_ISSUER,
  OKTA_CLIENTID,
  IS_OKTA_ENABLED,
  QC_BE_GATEWAY,
  PAY_URL,
  SPLIT_KEY,
  MARTECH_AD_HOST,
  ACTION_TRAIL_API,
  ACTION_TRAIL_STREAM_NAME,
  ACTION_TRAIL_CYCLE_PERIOD,
  ACTION_TRAIL_CUSTOM_HEADER,
  PREORDER_API,
  CONFIG_API,
  FULFILMENT_API,
  P13N_HOST,
  RELEASE_VERSION,
  DD_SERVICE_NAME,
  FE_GATEWAY_API_URL,
  CAMPAIGN_LABELS_API,
  LINKPASS_ADMIN_URL,
  APG_GATEWAY_HOST,
}
