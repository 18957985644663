import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListingPage from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { Popup } from '../../../components/Popup'
import { getMessage } from '../../../lib/translator'
import assetFilters from './filters.js'
import AssetPreview from './assetPreview'
import { getAssetTypes } from './AssetTypeList'
import {
  toggleDeleteDialog,
  DeleteAndErrorDialog
} from '../commonMedia'
import './style.css'

function getMobileView() {
  return window.screen.width <= 480
}

function AddButton() {
  return (
    <Link to="/media-monetization/media-assetmanagement/media-asset-detail" className="primary button">
      + <span className="text">{getMessage('media.add.text')}</span>
    </Link>
  )
}

const emptyState = {
  message: 'No results found'
}

const tableProperties = (isMobileView, toggleAssetBox, _this) => {
  return {
    headers: [
      getMessage('media.assetManagement.header.id'),
      getMessage('media.assetManagement.header.name'),
      getMessage('media.assetManagement.header.assetType'),
      getMessage('media.assetManagement.header.ownerBu'),
      getMessage('media.assetManagement.header.store'),
      getMessage('media.assetManagement.header.actions'),
    ],
    row: props => (
      <Row>
        <Cell className='website-page-name'>
          {isMobileView ? (
            <div className="text-muted">
              {getMessage('media.assetManagement.header.id')}
            </div>
          ) : (
            ''
          )}
          {props.buAssetId}
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView ? (
            <div className="text-muted">
              {getMessage('media.assetManagement.header.name')}
            </div>
          ) : (
            ''
          )}
          <small
            className='link-btn'
            onClick={() => {
              toggleAssetBox(1, { ...props })
            }}
          >{props.assetName}</small>
        </Cell>
        <Cell className='website-page-url assetType-cell'>
          {isMobileView ? (
            <div className="text-muted">
              {getMessage('media.assetManagement.header.assetType')}
            </div>
          ) : (
            ''
          )}
          <small>{props.assetTypeName}</small>
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView ? (
            <div className="text-muted">
              {getMessage('media.assetManagement.header.ownerBu')}
            </div>
          ) : (
            ''
          )}
          {props.ownerBu}
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView ? (
            <div className="text-muted">
              {getMessage('media.assetManagement.header.store')}
            </div>
          ) : (
            ''
          )}
          {props.buStoreLabel}
        </Cell>
        <Cell className='website-page-actions'>
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() => {
                toggleAssetBox(1, { ...props })
              }}
            >
              {getMessage('media.action.view')}
            </DropDownItem>
            <DropDownItem>
              <Link to={`/media-monetization/media-assetmanagement/media-asset-detail/${props.id}`}>
                {getMessage('media.action.edit')}
              </Link>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                toggleDeleteDialog(_this, 1, props.id, props.onAction)
              }}
            >
              {getMessage('media.action.delete')}
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    )
  }
}

export default class MediaAssetList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileView: getMobileView(),
      isOpenAssetBox: false,
      selectedAssetData: {},
      deleteDialog: {
        shown: false,
        id: null,
      },
      errorDialog: {
        shown: false,
        title: '',
        message: ''
      }
    }
    this.mobileView = this.mobileView.bind(this)
    this.toggleAssetBox = this.toggleAssetBox.bind(this)
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  toggleAssetBox(status, selectedAssetData) {
    this.setState({
      isOpenAssetBox: status === 1,
      selectedAssetData,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)

    getAssetTypes(this)
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
    window.removeEventListener('resize', this.mobileView, false)
  }

  render() {
    return (
      <React.Fragment>
        <ListingPage
          data-testid="search-assets-table"
          className="media-asset-management website-pages"
          title={getMessage('media.assetManagement.title')}
          menu={this.props.menu}
          showLanguageDropDown
          api={{
            url: '/martech-ad-service/search/assets',
            transform: response => response.data.content,
          }}
          emptyState={emptyState}
          headerActions={AddButton}
          tableProperties={tableProperties(this.state.isMobileView, this.toggleAssetBox, this)}
          primaryKey='id'
          filters={{
            component: assetFilters,
            forceShow: true,
            options: {
              assetTypeList: this.state.assetTypeList || [],
            },
            transformSubmit: formData => {
              const data = Object.assign({}, formData)
              if (data.store) {
                data.storeLabel = data.store.storeLabel ? data.store.storeLabel : ''
                delete data.store
              } else {
                delete data.store
                delete data.storeLabel
              }
              return data
            },
          }}
        />
        <Popup
          show={this.state.isOpenAssetBox}
          className="assetPreview-box"
          heading={getMessage('media.assetDetail.title')}
          close={() => { this.toggleAssetBox(2) }}
        >
          <AssetPreview assetData={this.state.selectedAssetData}></AssetPreview>
        </Popup>

        <DeleteAndErrorDialog
          _this={this}
          deleteDialog={this.state.deleteDialog}
          errorDialog={this.state.errorDialog}
          deleteApi='/martech-ad-service/assets/'
          deleteClass='media-asset-management website-pages'
          refreshApi='/martech-ad-service/search/assets'
        ></DeleteAndErrorDialog>

      </React.Fragment>
    )
  }
}
