import React, { useEffect, useState } from 'react'
import FileProcessor from 'components/FileUpload/FileProcessor'
import { getMessage } from 'lib/translator'
import Dialog from 'components/Popup/Dialog'
import API from 'lib/api'
import UploadIcon from 'icons/upload-container.svg'
import BulkDispatchResultCard from './BulkDispatchResultCard'
import Loader from 'components/Loader'
import CsvParser from 'papaparse'
import './style.scss'
import { hasDuplicates } from 'lib/commonlyused'

function BulkDispatchOrders() {
  const [ordersFromCsv, setOrdersFromCsv] = useState(null)
  const [errorDialog, setErrorDialog] = useState(null)
  const [successDialog, setSuccessDialog] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [bulkDispatchResult, setBulkDispatchResult] = useState(null);
  const [csvFileName, setCsvFileName] = useState(null);
  const [orderRefNumbers, setOrderRefNumbers] = useState(null);
  const [invalidCsvMsg, setInvalidCsvMsg] = useState('');

  const invalidCsvText = getMessage('customer.orderCleanup.bulkDispatch.invalidCsv');

  useEffect(() => {
    if (ordersFromCsv) {
      const orderReferenceNumbers = ordersFromCsv.map(order => order.reference_number)
      const hasDuplicateOrderRef = hasDuplicates(orderReferenceNumbers);
      if (hasDuplicateOrderRef) {
        const invalidMsg = 'Uploaded CSV has duplicate order reference numbers.'
        setInvalidCsvMsg(invalidMsg);
        setErrorDialog({
          title: invalidCsvText,
          message: invalidMsg,
        })
      } else if (orderReferenceNumbers.length === 0 || orderReferenceNumbers.some(x => !x)) {
        // below invalid condition will be true when csv do not have the reference_number header
        const invalidMsg = 'Uploaded CSV is invalid.'
        setInvalidCsvMsg(invalidMsg);
        setErrorDialog({
          title: invalidCsvText,
          message: invalidMsg,
        })
      } else {
        // verify order reference numbers before calling bulk dispatch
        const verifiedResults = orderReferenceNumbers.map(
          (orderNumber, index) => {
            const hasNonDigit = /\D/.test(orderNumber)
            const hasLessThanOneChar = orderNumber.length < 1
            return (hasNonDigit || hasLessThanOneChar) ? index + 2 : -1
          }
        )
        if (verifiedResults.some((x) => x !== -1)) {
          const invalidRows = verifiedResults.filter((x) => x !== -1).join(', ')
          const invalidMsg = `Uploaded CSV has invalid cells at row(s): ${invalidRows}`
          setInvalidCsvMsg(invalidMsg);
          setErrorDialog({
            title: invalidCsvText,
            message: invalidMsg,
          })
        } else {
          setInvalidCsvMsg('');
          setOrderRefNumbers(orderReferenceNumbers);
        }
      }
    }
  }, [ordersFromCsv])

  async function dispatchOrders() {
    try {
      setIsLoading(true);
      const bulkDispatchApi = new API({
        url: '/order-service/v3/bulkPatchDeliveryOrders',
      })
      const res = await bulkDispatchApi.patch({
        referenceNumbers: orderRefNumbers,
        status: 'DISPATCHED',
        communication: false // not to send sms and pns to customers
      })
      if (res) {
        setBulkDispatchResult(res);
        setInvalidCsvMsg('');
        setSuccessDialog({
          title: 'Request Executed',
          message: res.failure > 0 ?
          `${res.success} orders have succeeded. ${res.failure} Orders failed to dispatched, Please refer to error data for more details.`
          : `${res.success} orders have been dispatched successfully.`,
          type: res.failure > 0 ? 'info' : 'success'
        })
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e.message) {
        setErrorDialog({
          title: 'Error',
          message: e.message,
        })
      }
    }
  }

  // clear existing csv if any
  const clearExistingCsv = () => {
    setOrdersFromCsv(null)
    setBulkDispatchResult(null)
    setCsvFileName(null)
    setOrderRefNumbers(null)
    setInvalidCsvMsg('')
  }

  const handleFileUpload = async (file) => {
    // if file type is not csv. Show error and do not process it.
    if (file.type === 'text/csv') {
      clearExistingCsv()
      await csvFileToJSON(file)
    } else {
      setErrorDialog({
        title: 'Wrong file format',
        message: 'Uploaded file is not in CSV format.',
      })
    }
  }

  // Method to convert csv file to JSON
  const csvFileToJSON = (file) => {
    try {
      const reader = new FileReader()
      reader.readAsBinaryString(file)

      reader.onload = function (e) {
        const data = e.target.result;
        CsvParser.parse(data, {
          header: true,
          skipEmptyLines: true,
          newline: '\n',
          complete: function(results) {
            const rowsInJson = results.data;
            setCsvFileName(file.name)
            setOrdersFromCsv(rowsInJson)
          }
        });
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="bulk-dispatch-orders-tab">
      <p>Process multiple orders to dispatch</p>

      {/* File Processor */}
      <div className="file-upload-wrapper">
        <div className="text-center">
          <img src={UploadIcon} width="140" alt="" />
          <FileProcessor
            name="csvFileUploaderBulkDispatchOrders"
            fileType=".csv"
            dataTestId="csv-file-input"
            title={getMessage(
              'customer.orderCleanup.bulkDispatch.csvUploadLabel'
            )}
            secondaryTitle={getMessage(
              'customer.orderCleanup.bulkDispatch.csvUploadLabel.or'
            )}
            buttonText={getMessage(
              'customer.orderCleanup.bulkDispatch.csvUploadLabel.chooseFileFromPC'
            )}
            onFileUpload={handleFileUpload}
          />
        </div>
      </div>

      {/* Dropped file details */}
      {
        (csvFileName && !bulkDispatchResult) &&
        <div>
          <div className='dropped-file-details'>
            <span className='file-name'>
              <span style={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>File name:&nbsp;</span>
              {csvFileName}
            </span>
            <span className={`status ${invalidCsvMsg ? 'error-status' : 'success-status'}`}>
              {invalidCsvMsg ? 'Invalid': 'Valid'}
            </span>
          </div>
          {invalidCsvMsg && <div className='invalid-csv-error'>{invalidCsvMsg}</div>}
          <button className='button primary dispatch-button' disabled={invalidCsvMsg} onClick={dispatchOrders}>Dispatch</button>
        </div>
      }

      { isLoading && <Loader /> }
      {
        bulkDispatchResult &&
        <BulkDispatchResultCard
          total={bulkDispatchResult.total}
          success={bulkDispatchResult.success}
          failure={bulkDispatchResult.failure}
          errors={bulkDispatchResult.errors}
          csvFileName={csvFileName}
        />
      }
      {errorDialog && (
        <Dialog
          className='error'
          show={!!errorDialog}
          title={errorDialog.title}
          information={errorDialog.message}
          close={() => setErrorDialog(null)}
          closeText={getMessage('dialog.okText')}
        />
      )}
      {successDialog && (
        <Dialog
          className={successDialog.type}
          show={!!successDialog}
          title={successDialog.title}
          information={successDialog.message}
          close={() => setSuccessDialog(null)}
          closeText={getMessage('dialog.okText')}
        />
      )}
    </div>
  )
}

export default BulkDispatchOrders
