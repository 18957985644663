import React from 'react'
import { SelectSearch } from '../Form'

export default function SelectAdvertiserSearch(props) {
  const {
    _this,
    advertisers,
    stateKey,
    className,
    label,
    placeholder,
    requiredMessage,
    secondaryLabel,
    readOnly,
    dontDisplaySelected,
    required,
    multiple = true,
    searchById = false,
  } = props
  const formattedAdvertisers = advertisers.map(advertiser => {
    let text = {}
    if (advertiser.vendorCode){
      text = `${advertiser.advertiserName} - ${advertiser.vendorCode}`
    } else {
      text = advertiser.advertiserName
    }
    return {
      text: text,
      value: advertiser.id,
    }
  })

  return (
    <SelectSearch
      name="search-advertisers"
      required={required}
      readOnly={readOnly}
      dontDisplaySelected={dontDisplaySelected}
      label={label}
      placeholder={placeholder}
      multiple={multiple}
      options={formattedAdvertisers}
      nameKey="text"
      valueKey="value"
      searchByName
      searchById={searchById}
      className={className}
      secondaryLabel={secondaryLabel}
      {..._this.generateStateMappers({
        stateKeys: [stateKey],
        loseEmphasisOnFill: true,
      })}
      validationStrings={{
        valueMissing: requiredMessage,
      }}
      onChange={selectedValue => {
        selectedValue =
          selectedValue instanceof Array ? selectedValue : [selectedValue]
        let values = JSON.parse(JSON.stringify(_this.state.values))
        values = {
          ...values,
          [stateKey]: selectedValue.map(el => el.value),
        }
        _this.setState({ values })
      }}
      value={(_this.state.values[stateKey] || [])
        .map(id => formattedAdvertisers.find(opt => opt.value === id))
        .filter(Boolean)}
    />
  )
}
