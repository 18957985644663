import React, { Component } from 'react'
import moment from 'moment'

import AuthenticatedPage from '../../../containers/AuthenticatedPage'

import ListingPage from '../../../containers/ListingPage'
import { getMessage } from '../../../lib/translator'
import { Row, Cell } from '../../../components/Table'

import {
  PAYMENT_SEARCH_CATEGORIES,
  PAYMENT_SEARCH_CATEGORIES_ADV,
  PAYMENT_SEARCH_TYPES,
  PAYMENT_PAGINATION_SIZE,
  CURRENCY,
} from '../../../pages/customer-support/constants'
import EmptyIcon from './empty.svg'
import PaymentsForm from './Form'
import PaymentsContextProvider, { PaymentsContext } from './PaymentsContext'

import './style.css'

const {
  PAYMENT_REF,
  CUSTOMER_EMAIL,
  CUSTOMER_PHONE,
} = PAYMENT_SEARCH_CATEGORIES
const {
  POS_ID,
  CASHIER_ID,
  STORE_TXN,
  TRANSACTION_DATE,
} = PAYMENT_SEARCH_CATEGORIES_ADV

const headerPaymentRef = getMessage(
  'customerSupport.payments.header.paymentRef'
)
const headerStoreIdName = getMessage(
  'customerSupport.payments.header.storeIdName'
)
const headerTransactionDate = getMessage(
  'customerSupport.payments.header.transactionDate'
)
const headerAmount = getMessage('customerSupport.payments.header.amount')
const headerStatus = getMessage('customerSupport.payments.header.status')
const headerAction = getMessage('customerSupport.payments.header.action')

const tableProperties = {
  headers: [
    headerPaymentRef,
    headerStoreIdName,
    headerTransactionDate,
    headerAmount,
    headerStatus,
    headerAction,
  ],
  row: ({
    paymentRef,
    store,
    issuedTime,
    amount,
    transactionStatus,
    invoiceUrl,
  }) => {
    return (
      <Row>
        <Cell className="cell-payment-ref">
          <span className="cell-mobile-header">{headerPaymentRef}</span>
          {paymentRef}
        </Cell>
        <Cell className="cell-store-id-name">
          <span className="cell-mobile-header">{headerStoreIdName}</span>
          {store.id}/{store.name}
        </Cell>
        <Cell className="cell-issued-time">
          <span className="cell-mobile-header">{headerTransactionDate}</span>
          <div>
            {issuedTime ? moment.unix(issuedTime).format('DD MMM YYYY') : null}
          </div>
          <small className="text-muted">
            {issuedTime ? moment.unix(issuedTime).format('LT') : null}
          </small>
        </Cell>
        <Cell className="cell-amount">
          <span className="cell-mobile-header">{headerAmount}</span>
          {amount && `${CURRENCY}${amount}`}
        </Cell>
        <Cell className="cell-status">
          <span className="cell-mobile-header">{headerStatus}</span>
          {transactionStatus}
        </Cell>
        <Cell className="cell-action">
          <span className="cell-mobile-header">{headerAction}</span>
          <a
            className="primary button"
            type="button"
            href={invoiceUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            Receipt
          </a>
        </Cell>
      </Row>
    )
  },
}

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage('customerSupport.payments.empty'),
}

export class PaymentsTransactionsListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
    }

    this.handleOnSubmitFilters = this.handleOnSubmitFilters.bind(this)
  }

  afterFiltersUpdate() {
    const { resultsFetched } = this.context.paymentsContext
    if (!resultsFetched) {
      // Updating "resultsFetched" to true
      // so changing service from dropdown will trigger submit only after results are fetched once.
      this.context.updatePaymentsContext({ resultsFetched: true })
      // Need to set "forceRefresh" to false otherwise updating context will trigger another fetch
      this.setState({
        params: {
          ...this.state.params,
          forceRefresh: false,
        },
      })
    }
  }

  handleOnSubmitFilters(searchValues) {
    const { filterType, values } = searchValues
    this.setState(
      {
        params: {
          filterType,
          ...values,
          forceRefresh: true,
        },
      },
      this.afterFiltersUpdate
    )
  }

  prepareApiParams() {
    const { params } = this.state
    const {
      filterType,
      paymentSearchSelect,
      paymentSearchInput,
      paymentStatus,
      storeIds,
      paymentSearchSelectMerchant,
      paymentSearchSelectAdvanced,
      paymentSearchInputAdvanced,
      dateRange,
      forceRefresh,
    } = params

    let apiParams = {}

    if (filterType === PAYMENT_SEARCH_TYPES.QUICK_SEARCH) {
      const selectCat = paymentSearchSelect
      apiParams = {
        paymentRef: selectCat === PAYMENT_REF ? paymentSearchInput : '',
        email: selectCat === CUSTOMER_EMAIL ? paymentSearchInput : '',
        phone: selectCat === CUSTOMER_PHONE ? `65${paymentSearchInput}` : '',
        status: selectCat !== PAYMENT_REF ? paymentStatus : '',

        store: '',
        posId: '',
        cashierId: '',
        posTxnId: '',
        startDate: '',
        endDate: '',
      }
    } else {
      const selectCat = paymentSearchSelectAdvanced
      apiParams = {
        paymentRef: '',
        email: '',
        phone: '',
        status: '',

        store: storeIds,
        posId: selectCat === POS_ID ? paymentSearchInputAdvanced : '',
        cashierId: selectCat === CASHIER_ID ? paymentSearchInputAdvanced : '',
        posTxnId: selectCat === STORE_TXN ? paymentSearchInputAdvanced : '',
        startDate:
          selectCat === TRANSACTION_DATE && dateRange.startDate
            ? moment(dateRange.startDate).unix()
            : '',
        endDate:
          selectCat === TRANSACTION_DATE && dateRange.endDate
            ? moment(dateRange.endDate)
                .endOf('day')
                .unix()
            : '',
      }
    }

    apiParams.forceRefresh = forceRefresh
    apiParams.groupId = paymentSearchSelectMerchant

    return apiParams
  }

  render() {
    const { params } = this.state

    const { forceRefresh } = params

    const apiParams = this.prepareApiParams()

    return (
      <React.Fragment>
        <PaymentsForm onSubmitFilters={this.handleOnSubmitFilters} />
        <ListingPage
          className="payments-list"
          dontFetchOnMount
          dontSavePaginations
          api={{
            url: '/dp-middleware-connector/transactions',
            params: apiParams,
            overWriteDeleteParams: false,
            transform: response => {
              const transactions = response.data.payments || []

              response.data.count = response.data.totalSize
              response.data.limit = PAYMENT_PAGINATION_SIZE

              if (response.data.totalSize < PAYMENT_PAGINATION_SIZE) {
                // only one page data
                response.data.offset = 0
              } else if (response.data.nextPageToken > 0) {
                // For data from page=1 to page=last-1
                // For first page nextPageToken will be 2
                response.data.offset =
                  (response.data.nextPageToken - 2) * PAYMENT_PAGINATION_SIZE
              } else if (response.data.totalSize > 0) {
                // For last page, nextPageToken= 0;
                response.data.offset =
                  (Math.ceil(
                    response.data.totalSize / PAYMENT_PAGINATION_SIZE
                  ) -
                    1) *
                  PAYMENT_PAGINATION_SIZE
              } else {
                response.data.offset = 0
              }

              transactions.forEach((t, i) => (t.id = `${i}${t.issuedTime}`))

              return transactions
            },
            updatePageParams: ({ page }) => {
              return {
                pageToken: page,
              }
            },
            updateApiParams: (prevParams, newParams) => {
              const {
                pageToken,
                pageSize,
                forceRefresh: newParamForceRefresh,
              } = newParams

              delete newParams.pageToken
              delete newParams.pageSize
              delete newParams.forceRefresh
              delete prevParams.pageToken
              delete prevParams.pageSize
              delete prevParams.forceRefresh

              const shouldUpdate =
                newParamForceRefresh ||
                JSON.stringify(prevParams) !== JSON.stringify(newParams)

              // pass only params which will be required to compare next time
              newParams.pageToken = pageToken || 1
              newParams.pageSize = pageSize || PAYMENT_PAGINATION_SIZE

              return {
                shouldUpdate,
                clearPagination: forceRefresh,
                params: {
                  ...newParams,
                },
              }
            },
          }}
          emptyState={EmptyState}
          noId
          primaryKey="id"
          tableProperties={tableProperties}
        />
      </React.Fragment>
    )
  }
}

PaymentsTransactionsListing.contextType = PaymentsContext

class CsPayments extends Component {
  render() {
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
        title="Pay via FairPrice app"
        className="payments-page"
      >
        <h1 className="title">Pay via FairPrice app</h1>
        <PaymentsContextProvider>
          <PaymentsTransactionsListing />
        </PaymentsContextProvider>
      </AuthenticatedPage>
    )
  }
}

export default CsPayments
