import React from 'react'
import {
  BaseForm,
  VALIDATION_TYPES,
  Phone,
} from '../../../../../components/Form'
import API from '../../../../../lib/api'
import { getMessage } from '../../../../../lib/translator'
import './style.css'
import Loader from '../../../../../components/Loader'

export default class PhoneNumberForm extends BaseForm {
  onSubmit(formData) {
    const api = new API({
      url: `/ef-customer-core/customers?phone=${formData.phone}`,
    })
    api.get(formData).then(
      response => {
        let customerDetails = null
        if (response.data.customers.count > 0) {
          customerDetails = response.data.customers[0]
          this.customerDetailsApi = new API({
            url: `/ef-customer-core/profile/${customerDetails.id}`,
          })
          this.customerDetailsApi.get().then(response => {
            this.props.setCustomerDetails(response.data, formData['phone'])
          })
        } else {
          this.props.setCustomerDetails(customerDetails, formData['phone'])
        }
      },
      error => {
        if (error.code === 401) {
          throw error
        }
      }
    )
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    const api = new API({ url: '/account-service/country' })
    api.get().then(response => {
      const country = response.data.country.isoCode
      this.setState({
        loading: false,
        countryCode: country,
      })
    })
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    return this.state.loading ? (
      <Loader />
    ) : (
      <Form className="phone-form">
        <div className="flex phone-wrapper">
          <Phone
            country={this.state.countryCode}
            label={getMessage('order.form.customer.phone.heading')}
            placeholder={getMessage('order.form.customer.phone.placeholder')}
            name="phone"
            className="phone-number"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ['phone'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <SubmitButton>{getMessage('order.form.search.text')}</SubmitButton>
        </div>
      </Form>
    )
  }
}
