import React, { useState } from 'react'
import { tableProperties, EMPTY_STATE } from '../rewardsUtils'
import ListingPage from '../../../../../../containers/ListingPage'
import { getMessage } from '../../../../../../lib/translator'
import '../style.css'

const ChancesEarned = ({ uid, selectedCampaign }) => {
  const [chancesCount, setChancesCount] = useState(0)
  return (
    <div className="tab-list">
      <h3 className="title">
        {getMessage('customer.details.luckyDraws.chancesEarned')}
        <span>({chancesCount})</span>
      </h3>
      {selectedCampaign && (
        <ListingPage
          isRewardsTab={true}
          testid="chances-earned-listing"
          menu=""
          className="rewards-page chances"
          api={{
            url: `/duck/chances`,
            params: {
              campaignId: selectedCampaign,
              uid,
              limit: 20,
              offset: 0,
              wantFullData: true,
            },
            transform: (response) => {
              if (response) {
                const totalChancesCount = response.data.list.reduce(
                  (total, currentValue) => total + currentValue.chances,
                  0
                )

                setChancesCount(totalChancesCount)
                return response.data.list
              }
              return response
            },
            updateApiParams: (prevParams, newParams) => {
              if (prevParams.campaignId !== newParams.campaignId) {
                return {
                  shouldUpdate: true,
                  params: {
                    campaignId: newParams.campaignId,
                    uid,
                    limit: 20,
                    offset: 0,
                    wantFullData: true,
                  },
                }
              }
              return null
            },
          }}
          form={{}}
          emptyState={EMPTY_STATE}
          tableProperties={tableProperties('ChancesEarned')}
          tableDynamic
        />
      )}
    </div>
  )
}

export default ChancesEarned
