import React from 'react';
import { getMessage } from 'lib/translator';
import Checkbox from 'components.new/checkbox/CheckBox';
import { ReactComponent as PartialCheckboxIcon } from 'icons/checkbox-partial.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'icons/checkbox-selected-blue.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'icons/checkbox-unchecked.svg';
import './style.scss';

export function TimeSlotInputStep({
  days,
  slots,
  blockedTimeSlots,
  handleTimeSlotCheck,
  isAllSlotsChecked,
  isSomeSlotsChecked,
  isAllSlotsUnChecked,
  handleClearAllTimeSlots,
  handleSelectAllTimeSlots
}) {

  /*
    if all slots unchecked => the icon is unchecked icon => click => select all checkboxes
    if some slots checked/unchecked => the icon is minus icon => click => clear all checkboxes
    if all slots checked => the icon is tick icon => click => clear all checboxes
  */
  const handleClearAllOrSelectAll = () => {
    isSomeSlotsChecked ? handleClearAllTimeSlots() : handleSelectAllTimeSlots();
  }

  return (
    <div className='time-slot-input-step'>
      <div className='title-container'>
        <p>{getMessage('operations.timeSlotBlocking.addRule.selectBlockedTimeSlots')}</p>
        <div className='select-all-text'>
          { isAllSlotsUnChecked ?
            <CheckboxUncheckedIcon data-testid='select-all-icon' onClick={handleClearAllOrSelectAll} /> :
              isAllSlotsChecked ?
              <CheckboxCheckedIcon data-testid='select-all-icon' onClick={handleClearAllOrSelectAll} />
              : <PartialCheckboxIcon data-testid='select-all-icon' onClick={handleClearAllOrSelectAll}/>
          }
          &nbsp;Select all
        </div>
      </div>
      <table className='styled-table'>
        <thead>
          <tr>
            <th></th>
            {days.map(day => <th key={day}>{day}</th>)}
          </tr>
        </thead>
        <tbody>
          {
            slots.map(slot =>
            <tr key={slot}>
              <td>{slot}</td>
              {
                days.map(day =>
                  <td key={`${day}-${slot}`}>
                    <Checkbox
                      name="checkedDetails"
                      data-testid={`${day}-${slot}`}
                      checked={blockedTimeSlots.get(`${day}|${slot}`)}
                      onChange={() => handleTimeSlotCheck(`${day}|${slot}`)}
                      isBlue
                    />
                  </td>)
              }
            </tr>
            )
          }
        </tbody>
      </table>
    </div>
  )
}
