import React from 'react'
import { getMessage } from 'lib/translator'
import './style.css'

const TYPES = [
  {
    type: 'banner',
    text: 'app.layout.module.type.banner',
  },
  {
    type: 'product-swimlane',
    text: 'app.layout.module.type.productSwimlane',
  },
  {
    type: 'card-swimlane',
    text: 'app.layout.module.type.cardSwimlane',
  },
  {
    type: 'placeholder',
    text: 'app.layout.module.type.placeholder',
  },
]

const ModuleTypeForm = ({ setPopUp, setModuleType }) => {
  const selectModuleType = (type) => {
    setPopUp('module-form')
    setModuleType(type)
  }

  return (
    <div className="module-type-section">
      {TYPES.map(({ type, text }, index) => (
        <button
          className="button applayout-hover-button"
          onClick={() => selectModuleType(type)}
          type="button"
          key={index}
        >
          {getMessage(text)}
        </button>
      ))}
    </div>
  )
}

export default ModuleTypeForm
