import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getMessage } from '../../lib/translator'
import { getSession } from '../../lib/auth'
import API from '../../lib/api'
import { formatCampaignDate } from 'components/Rewards/utils'

const dateTimeFormat2 = 'YYYY-MM-DD,HH:mm:ss'

export const linkButtons = () => {
  return (
    <div className="header-wrap">
      <Link to="/marketing/lucky-draws/add">
        <button className="primary button">
          <span className="text">{getMessage('luckydraw.addLuckyDraw')}</span>
        </button>
      </Link>
    </div>
  )
}

export const readOnly = () => {
  const { user } = getSession()
  const roles = user.designation
    ? user.designation.roles
      ? user.designation.roles
      : []
    : []
  if (roles.length) {
    for (const role of roles) {
      if (role.name === 'Marketing / Lucky Draw (Read-only)') {
        return true
      }
    }
  }
  return false
}

export const validationMsgs = {
  rangeUnderflow: 'Minimum number of winners is 1',
}

export const handleBaseChanceRule = (channels, spendPerChannels) => {
  const qualifiedChannels = Object.keys(channels).filter((key) => channels[key])
  const qualifiedSpendPerChannels = Object.entries(spendPerChannels).filter(
    ([key]) => qualifiedChannels.includes(key)
  )

  const qualifiedSpendPerChannelsObj = Object.fromEntries(
    qualifiedSpendPerChannels
  )

  return qualifiedSpendPerChannels.length > 0
    ? {
        target: 'CHANNEL',
        operator: 'AMOUNT_PER_CHANCE',
        config: {
          configAmountPerChance: {
            channelInfoMap: qualifiedSpendPerChannelsObj,
          },
        },
      }
    : {}
}

export const fetchChannels = (baseChanceRule) => {
  const { config } = baseChanceRule
  const list = config.configAmountPerChance
    ? Object.keys(config.configAmountPerChance.channelInfoMap)
    : []
  const res = {}
  list.forEach((key) => (res[key] = true))
  return res
}

export const fetchChannelInfoMap = (baseChanceRule) => {
  const { config } = baseChanceRule
  const list = config.configAmountPerChance
    ? Object.entries(config.configAmountPerChance.channelInfoMap)
    : []
  const res = {}
  list.forEach((key) => (res[key[0]] = key[1]))
  return res
}

export const handleRuleList = (rulesList, campaignStartAt, campaignEndAt) => {
  if (rulesList?.length > 0 && rulesList[0]?.chanceType !== '') {
    return rulesList.map((rule, i) => {
      const {
        productType,
        date,
        config,
        chanceType,
        operator,
        value,
        subType,
      } = rule
      const list = productType ? extractProductTypeList(productType) : []
      const configData = handleRuleConfig(
        date,
        config,
        campaignStartAt,
        campaignEndAt,
        i
      )
      const target = chanceType ? chanceType.toUpperCase() : ''
      const defaultOperator = ''
      const defaultValue = ''
      const defaultSubType = []

      return {
        target,
        operator: operator || defaultOperator,
        value: value || defaultValue,
        config: configData || {},
        list: chanceType === 'Product' ? list : subType || defaultSubType,
      }
    })
  }
  return []
}

const extractProductTypeList = (productType) => {
  const list = []
  productType?.forEach((x) => {
    if (x.barcodes) {
      list.push(...x.barcodes)
    }
    if (x.clientItemId) {
      list.push(x.clientItemId)
    }
  })
  return list
}

const handleRuleConfig = (
  date,
  config,
  campaignStartAt,
  campaignEndAt,
  index
) => {
  if (date) {
    const { startDate, startTime, endDate, endTime } = date

    const { startAt, endAt } = formatCampaignDate(
      startDate,
      startTime,
      endDate,
      endTime
    )

    if (startAt && endAt) {
      if (
        !areDatesWithinCampaign(startAt, endAt, campaignStartAt, campaignEndAt)
      ) {
        throw new Error(
          `RULE ${index + 1}: Duration date is not within the campaign period.`
        )
      }
      return { ...config, configProductAdd: { startAt, endAt } }
    } else {
      delete config.configProductAdd
    }
  }

  return config
}

export const handleConditionList = (conditionsList) => {
  if (!isValidConditionsList(conditionsList)) {
    return []
  }

  return conditionsList.map((condition) => {
    const target = condition.entityName?.toUpperCase() || ''
    const config = condition.config || {}
    const operator = condition.operator || ''
    const list = getList(condition)

    return { target, operator, config, value: null, list }
  })
}

const isValidConditionsList = (conditionsList) => {
  return conditionsList?.length > 0 && conditionsList[0]?.entityName !== ''
}

const getList = (condition) => {
  if (condition.entityName === 'Category') {
    return getProductCategoryList(condition)
  } else {
    return getProductInfoList(condition)
  }
}

const getProductCategoryList = (condition) => {
  return condition.productCategory?.map((x) => x.id.toString()) || []
}

const getProductInfoList = (condition) => {
  return condition.productInfo
    ? condition.productInfo.flatMap((x) => [
        ...(x?.barcodes ?? []),
        ...(x.clientItemId ? [x.clientItemId] : []),
      ])
    : []
}

export const handleAdmissionRules = (userSegment, cardSegment) => {
  const filteredUserSegments = userSegment
    ? Object.keys(userSegment).filter((key) => userSegment[key])
    : []

  const filteredCardSegments = cardSegment
    ? Object.keys(cardSegment).filter((key) => cardSegment[key])
    : []

  const membershipRules = filteredUserSegments.length
    ? [
        {
          target: 'MEMBERSHIP',
          operator: 'INCLUDE_ONLY',
          list: filteredUserSegments,
        },
      ]
    : []

  const cardTypeRules = filteredCardSegments.length
    ? [
        {
          target: 'CARD_TYPE',
          operator: 'INCLUDE_ONLY',
          list: filteredCardSegments,
        },
      ]
    : []

  return [...membershipRules, ...cardTypeRules]
}

export const fetchConditionData = async (rulesList) => {
  let rulesArr = []
  for (const rule of rulesList) {
    const { target, operator, list, config } = rule
    const value = {
      entityName: target.charAt(0) + target.slice(1).toLowerCase(),
      config,
      operator,
      productCategory: '',
      productInfo: '',
    }
    if (target === 'CATEGORY') {
      let searchCategoryParams = ''
      list.length > 0 &&
        list.map((id, index) => {
          if (index === 0) {
            searchCategoryParams += `${Number(id)}`
          } else {
            searchCategoryParams += `&id=${Number(id)}`
          }
          return null
        })

      const categoryAPI = new API({
        url: `/catalogue-service/category?id=${searchCategoryParams}`,
      })
      categoryAPI.get().then((response) => {
        value.productCategory = response.data.category
      })
      value.productInfo = []
      rulesArr = [...rulesArr, value]
    } else {
      const searchProductBarcodes = list.toString()
      const api = new API({
        url: `/catalogue-service/product?barcode=${searchProductBarcodes}`,
      })
      const response = await api.get()
      if (response) {
        value.productInfo = response.data.product
      }

      value.productCategory = []
      rulesArr = [...rulesArr, value]
    }
  }
  return rulesArr
}

export const fetchAdmissionRules = (rules, target) => {
  const rule = rules.find((r) => r.target === target)
  if (rule?.list && Array.isArray(rule.list)) {
    const admissionRules = {}

    rule.list.forEach((item) => {
      admissionRules[item] = true
    })

    return admissionRules
  }

  return null
}

export const fetchChanceData = async (rulesList) => {
  let rulesArr = []
  for (const rules of rulesList) {
    const { target, operator, list, config } = rules
    const { configProductAdd } = { ...config }
    const date = []
    if (configProductAdd) {
      const formattedStartAt = moment(configProductAdd?.startAt).format(
        dateTimeFormat2
      )
      const formattedEndAt = moment(configProductAdd?.endAt).format(
        dateTimeFormat2
      )
      date.startTime = formattedStartAt?.split(',')[1]
      date.endTime = formattedEndAt?.split(',')[1]
      date.startDate = formattedStartAt?.split(',')[0]
      date.endDate = formattedEndAt?.split(',')[0]
    }

    const value = {
      chanceType: target.charAt(0) + target.slice(1).toLowerCase(),
      operator,
      config,
      date,
      value: rules.value,
    }
    if (target === 'PRODUCT') {
      const searchProductBarcodes = list.toString()
      const api = new API({
        url: `/catalogue-service/product?barcode=${searchProductBarcodes}`,
      })
      const response = await api.get()
      if (response) {
        value.productType = response.data.product
      }
    } else {
      value.subType = list
    }
    rulesArr = [...rulesArr, value]
  }
  return rulesArr
}

export const transformAPIData = async (data) => {
  const campaign = data
  campaign.campaignName = campaign.name
  const formattedStartAt = moment(campaign.startAt).format(dateTimeFormat2)
  const formattedEndAt = moment(campaign.endAt).format(dateTimeFormat2)
  campaign.startTime = formattedStartAt.split(',')[1]
  campaign.endTime = formattedEndAt.split(',')[1]
  campaign.startDate = formattedStartAt.split(',')[0]
  campaign.endDate = formattedEndAt.split(',')[0]
  campaign.prizes = data.prizes
  campaign.participate = campaign.participationGuide
  campaign.campaignURL = campaign.campaignPageUrl
  campaign.campaignTermsURL = campaign.tncUrl
  campaign.campaignWinnersURL = campaign.winnerListUrl
  campaign.imageUrl = campaign.imgUrls.logo
  campaign.bannerUrl = campaign.imgUrls.banner
  campaign.inputList = campaign.draws.map((draw) => {
    const formattedCutOffAt = moment(draw.cutOffAt)
      .format(dateTimeFormat2)
      .split(',')[0]
    return {
      cutOffDate: formattedCutOffAt,
      numberOfWinners: draw.winnersCount,
      numberOfReserveWinners: draw.reservedWinnersCount,
    }
  })
  campaign.rulesList =
    campaign?.chanceRules?.length > 0
      ? await fetchChanceData(campaign.chanceRules)
      : [
          {
            chanceType: '',
            subType: '',
            productType: '',
            operator: '',
            value: '',
          },
        ]
  campaign.amountPerChance = data.amountPerChance
  campaign.channels = campaign.baseChanceRule
    ? fetchChannels(campaign.baseChanceRule)
    : []
  campaign.channelInfoMap = campaign.baseChanceRule
    ? fetchChannelInfoMap(campaign.baseChanceRule)
    : []
  campaign.conditionsList =
    campaign?.costRules?.length > 0
      ? await fetchConditionData(campaign.costRules)
      : [
          {
            entityName: '',
            operator: '',
            productCategory: '',
            productInfo: '',
          },
        ]
  campaign.userSegment = campaign.admissionRules.length
    ? fetchAdmissionRules(campaign.admissionRules, 'MEMBERSHIP')
    : []
  campaign.cardSegment = campaign.admissionRules.length
    ? fetchAdmissionRules(campaign.admissionRules, 'CARD_TYPE')
    : []
  return campaign
}

const tabItem = {
  text: 'Participants',
}

export const getEditTabs = (tabsList) => {
  tabsList = [...tabsList, tabItem]
  return tabsList.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.text === value.text)
  )
}

export const handleRuleChange = (x) => {
  if (x.chanceType === 'Product') {
    x.subType && delete x.subType
    x.productType && x.productType.splice(0)
  } else if (x.chanceType !== 'Product') {
    x.productType && delete x.productType
    x.subType && x.subType.splice(0)
  }

  x.operator && delete x.operator
  x.value && delete x.value
  x.config && delete x.config
}

export const handleConditionChange = (x) => {
  if (x.entityName === 'Category') {
    x.productInfo = []
  } else {
    x.productCategory = []
  }

  x.config && delete x.config
}

export const areDatesWithinCampaign = (
  startDate,
  endDate,
  campaignStartDate,
  campaignEndDate
) => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const campaignStart = new Date(campaignStartDate)
  const campaignEnd = new Date(campaignEndDate)

  return (
    start >= campaignStart && end <= campaignEnd && start <= end && end >= start
  )
}

export const isCutOffDateWithinCampaign = (
  cutOffAt,
  campaignStartDate,
  campaignEndDate
) => {
  const cutOffDate = new Date(cutOffAt)
  const campaignStart = new Date(campaignStartDate)
  const campaignEnd = new Date(campaignEndDate)

  return cutOffDate >= campaignStart && cutOffDate <= campaignEnd
}

export { formatCampaignDate }
