const data = {
  'deleteDialog.title': 'Are you sure?',
  'deleteDialog.information': 'This action cannot be reverted',

  'dialog.okText': 'Ok',
  'dialog.cancelText': 'Cancel',

  'errorDialog.title': 'Oops!',
  'errorDialog.delete.error.title': 'Can not delete',

  'pagination.text': 'Showing',
  'pagination.helperText': 'of',
  'pagination.jumpToPage': 'Jump to page',

  'updateDialog.title': 'Are you sure?',
  'updateDialog.information':
    'This will affect all existing stores with default tier',
  'resendDialog.heading': 'E-Gift Voucher Details',
  'resendDialog.subheading': 'Resend E-Gift Voucher',
  'resendDialog.emailText': 'Enter email address',
  'resendDialog.history': 'Resend History',
  'resendDialog.method': 'Method',
  'resendDialog.receiver': 'Receiver',
  'resendDialog.receiver.egift': 'E-Gift Voucher',
}

export default data
