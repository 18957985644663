import React from 'react'
import Searchable from '../Searchable'
import Select from '../Select'

import './style.css'

export const getCampaignChain = (campaign) => {
  let chain = []
  let reference = campaign
  while (reference) {
    const name = reference.name ? reference.name : ''
    const id = reference.id ? ` (${reference.id}) ` : ''
    const result = name + id
    chain.push(result)
    reference = reference.parentCategory
  }
  chain = chain.reverse()
  return chain.join(' > ')
}

class CampaignSearch extends React.Component {
  constructor(props) {
    super(props)
    this.options = [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Id',
        value: 'id',
      },
    ]
    this.state = {
      selectedValue: 'name',
    }
    this.handleValueChange = this.handleValueChange.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
  }

  handleValidation(value) {
    const { onValidation } = this.props
    if (onValidation) {
      onValidation(value)
    }
  }

  handleValueChange(e) {
    this.setState({
      selectedValue: e,
    })
  }


  render() {
    const { selectedValue } = this.state
    const { name, readOnly, withOptions, dontShowChain, appendElement } = this.props
    const newProps = Object.assign({}, this.props, {
      searchUrl: '/campaigns',
      valueKey: 'id',
      nameKey: 'displayName',
      searchKey: selectedValue,
      expandMore: true,
      displaySelectedValue: (campaign) => {
        return dontShowChain ? campaign.name : getCampaignChain(campaign)
      },
      transformRequest: (searchText, paramsDefault) => {
        const params = Object.assign({}, paramsDefault )
        params.name&&(params.name = `%${params.name}`)
        return params
      },
      transformResponse: (response) => {
        return (appendElement || []).concat(
          response.data.campaigns.map((campaign) => ({
            ...campaign,
            displayName: getCampaignChain(campaign),
          }))
        )
      },
    })
    return (
      <div className="CampaignSearch">
        {withOptions && (
          <Select
            className="category-select"
            name={`${name}-campaign-search-select`}
            placeholder={'Search by'}
            options={this.selectOptions}
            value={selectedValue}
            onChange={this.handleChange}
            disabled={readOnly}
          />
        )}
        <Searchable {...newProps} />
      </div>
    )
  }
}

export default CampaignSearch
