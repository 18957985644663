import React from 'react'
import {
  BaseForm,
  Searchable,
  Radio,
  Input,
  Select,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

import deleteIcon from './delete.svg'

export default class DesignationForm extends BaseForm {
  constructor(props) {
    super(props)
    this.delete = this.delete.bind(this)
  }
  delete() {
    this.props.onDelete()
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const designationId = this.state.values.id
    return (
      <Form className="designation-form">
        <Input
          type="text"
          label={getMessage('designations.form.name')}
          placeholder={getMessage('designations.form.name.placeholder')}
          name="name"
          required
          {...this.generateStateMappers({
            stateKeys: ['name'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <Searchable
          label={getMessage('designations.form.manager')}
          placeholder={getMessage('designations.form.manager.placeholder')}
          name="manager"
          type="text"
          searchUrl="/account-service/designation"
          valueKey="id"
          searchKey="name"
          transformResponse={response =>
            response.data.designation.filter(
              designation => designation.id !== designationId
            )
          }
          {...this.generateStateMappers({
            stateKeys: ['manager'],
            loseEmphasisOnFill: true,
          })}
        />
        <Searchable
          label={getMessage('designations.form.roles')}
          placeholder={getMessage('designations.form.roles.placeholder')}
          name="roles"
          type="text"
          searchUrl="/account-service/role"
          valueKey="id"
          searchKey="name"
          multiple
          transformResponse={response => response.data.role}
          {...this.generateStateMappers({
            stateKeys: ['roles'],
            loseEmphasisOnFill: true,
          })}
        />
        <Select
          label={getMessage('designations.form.designationlevel')}
          placeholder={getMessage(
            'designations.form.designationlevel.placeholder'
          )}
          type="number"
          options={[
            {
              text: 'High',
              value: 3,
            },
            {
              text: 'Medium',
              value: 2,
            },
            {
              text: 'Low',
              value: 1,
            },
          ]}
          {...this.generateStateMappers({
            stateKeys: ['designationLevel'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <Radio
          label={getMessage('designations.form.timingtype')}
          name="timingType"
          options={[
            {
              text: getMessage('designations.form.timingtype.fixed'),
              value: 'FIXED',
            },
            {
              text: getMessage('designations.form.timingtype.flexible'),
              value: 'FLEXI',
            },
          ]}
          {...this.generateStateMappers({
            stateKeys: ['timingType'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <div className={`form-actions ${this.props.method}`}>
          {this.props.method && this.props.method === 'edit' && (
            <button
              className="delete-button"
              type="button"
              onClick={this.delete}
              data-testid="designation-form-delete-button"
            >
              <img src={deleteIcon} alt="delete" />
              <span>{getMessage('brand.form.deleteText')}</span>
            </button>
          )}
          <CancelButton>{getMessage('designations.form.cancel')}</CancelButton>
          <SubmitButton>{getMessage('designations.form.save')}</SubmitButton>
        </div>
      </Form>
    )
  }
}
