import { Input, VALIDATION_TYPES } from 'components/Form'
import { getMessage } from 'lib/translator'
import React from 'react'

const CardMetadata = ({ method, generateStateMappers }) => {
  return (
    <>
      <h3>{getMessage('apphome.metadata')}</h3>
      <Input
        type="text"
        label={getMessage('app.card.name')}
        placeholder={getMessage('app.card.name.placeholder')}
        {...generateStateMappers({
          stateKeys: ['name'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
        required
      />
      <Input
        required
        type="text"
        disabled={method === 'edit'}
        label={getMessage('app.card.category')}
        placeholder={getMessage('app.card.category.placeholder')}
        pattern="^\S+$"
        {...generateStateMappers({
          stateKeys: ['category'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
        validationStrings={{
          patternMismatch: getMessage('app.card.category.validation'),
        }}
      />
      <Input
        type="text"
        label={getMessage('app.card.deeplink')}
        placeholder={getMessage('app.card.deeplink.placeholder')}
        {...generateStateMappers({
          stateKeys: ['cta', 'deeplink'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
        required
      />
    </>
  )
}

export default CardMetadata
