import React, { Component } from 'react'
import moment from 'moment'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage/index'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import { Dialog } from '../../../../components/Popup'
import Loader from '../../../../components/Loader'
import { hideErrorDialog, throwError } from '../../commonMedia'
import { calculateDates, concatDateArr } from '../../commonMedia/time'
import MediaAdsetForm from './AdsetForm'
import './style.css'

function getMobileView () {
  return window.screen.width <= 480
}

export default class MediaAdsetDetail extends Component {
  constructor (props) {
    super(props)

    this.api = null
    this.state = {
      showLoader: true,
      isMobileView: getMobileView(),
      pageAction: 'add',
      lineItemId: null,
      data: {
        campaignLandingPageUrl: ['']
      },
      packageType: null,
      assetReservationList: [],
      flexibleDefaultTimeList: [],
      offsite: null,
      advertiserList: [],
      duration: {
        startDate: null,
        endDate: null,
        day: null
      },
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
    }
    this.mobileView = this.mobileView.bind(this)
    this.getLineItemDetail = this.getLineItemDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.addLineItem = this.addLineItem.bind(this)
    this.updateLineItem = this.updateLineItem.bind(this)
    this.handleOffsite = this.handleOffsite.bind(this)
    this.handleCampaignUrl = this.handleCampaignUrl.bind(this)
    this.initCampaignUrl = this.initCampaignUrl.bind(this)
    this.handlePackageChange = this.handlePackageChange.bind(this)
    this.getPackageDetail = this.getPackageDetail.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.formatAttributeValues = this.formatAttributeValues.bind(this)
    this.flexibleTimeAdd = this.flexibleTimeAdd.bind(this)
    this.flexibleTimeDelete = this.flexibleTimeDelete.bind(this)
    this.regroupAssetReservation = this.regroupAssetReservation.bind(this)
    this.getClassGLCode = this.getClassGLCode.bind(this)
  }

  mobileView () {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount () {
    window.addEventListener('resize', this.mobileView, false)

    if (this.props.match.params.action === 'edit') {
      this.setState({
        pageAction: 'edit',
        lineItemId: this.props.match.params.id
      })
      this.getLineItemDetail()
    }else{
      this.setState({
        pageAction: 'add'
      })

      if (this.props.match.params.id) {
        this.getLineItemDetail()
      }
      else{
        this.setState({
          showLoader: false
        })
      }
    }
    this.getClassGLCode();
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.mobileView, false)
  }

  getLineItemDetail(){
    this.api = new API({ url: '/martech-ad-service/reservations/lineItem/'+this.props.match.params.id })
    this.api.get().then(response => {
      let offsite = {}
      const attrData = {}
      for (let i = 0; i < response.data.attributeValues?.length; i++) {
        const attr = response.data.attributeValues[i]
        offsite = Object.assign(offsite, {
          [attr.name]: {
            'id': attr.id,
            'list': attr.values.map((item) => {
              return {
                'id': item.id,
                'name': attr.name+'Name'
              }
            })
          }
        })
        const attrName = attr.name + 'Name';
        attrData[String(attrName)] = attr.values.map((item)=>{
          return item.value
        })
      }
      let budgetData = {}
      for (let i = 0; i < response.data.lineItemSupplierReservationList?.length; i++) {
        const key = 'budget_' + i
        const value = response.data.lineItemSupplierReservationList[i].budget
        budgetData = { ...budgetData, [key]: value }
      }
      const startDate = response.data.startDate
      const endDate = response.data.endDate
      const day = moment.duration(moment(endDate).diff(moment(startDate))).asDays() + 1

      //flexible Default Time
      const flexibleDefaultTimeList = []
      response.data.defaultReserveDates?.forEach(item => {
        flexibleDefaultTimeList.push({
          startDate: item,
          endDate: item
        })
      })
      //flexible special time
      response.data.assetReservationList?.forEach(item => {
        const _reserveDates = []
        item.reserveDates.forEach(dateItem => {
          _reserveDates.push({
            startDate: dateItem,
            endDate: dateItem
          })
        })
        item.reserveDates = calculateDates(_reserveDates)
      })

      //campaign Landing Page Url Arr
      if(response.data.setupDetails){
        response.data.setupDetails.campaignLandingPageUrl = response.data.setupDetails.campaignLandingPageUrl?.split(";").map((url) => url.trim())
        response.data.setupDetails.campaignLandingPageUrl?.forEach((item, index) => {
          const attrName = 'campaignLandingPageUrlList_' + index;
          response.data[String(attrName)] = item
        })
      }

      this.setState({
        showLoader: false,
        offsite,
        duration: {
          startDate,
          endDate,
          day
        },
        flexibleDefaultTimeList: calculateDates(flexibleDefaultTimeList),
        assetReservationList: response.data.assetReservationList,
        data: {
          ...response.data,
          ...this.state.data,
          ...attrData,
          'selectedPackage': response.data.packageId,
          'advertiser': response.data.lineItemSupplierReservationList.map((item)=>{
            return item.supplierId
          }),
          'selectedAdvertisers': response.data.lineItemSupplierReservationList.map((item)=>{
            return item.supplierId
          }),
          ...response.data.setupDetails,
          ...response.data.businessMechanic,
          ...budgetData
        },
      })
      this.getPackageDetail(response.data.packageId, 'auto')
    })
  }

  getClassGLCode(){
    this.api = new API({ url: '/martech-ad-service/class-gl-code'})
    this.api.get().then(response => {
      this.setState({
        classGLCodeList: response.data
      })
    })
  }

  handlePackageChange(packageId){
    if(packageId){
      this.getPackageDetail(packageId, 'manual')
    }
  }

  getPackageDetail(packageId, mode){
    this.api = new API({ url: '/martech-ad-service/packages/' + packageId })
    this.api.get().then(response => {
      const _packageDuration = response.data.packageDuration
      const packageType = response.data.packageType
      this.setState({
        packageType,
      })
      if (this.state.pageAction === 'add' || mode === 'manual'){
        this.setState({
          assetReservationList: response.data.assetList
        })
      }
      this.handleDateChange(null, _packageDuration)
    })
  }

  flexibleTimeAdd(startDate, endDate, targetList, targetIndex){
    if(startDate && endDate){

      if(targetList === 'default'){
        let flexibleDefaultTimeList = this.state.flexibleDefaultTimeList
        flexibleDefaultTimeList.push({
          'startDate': startDate,
          'endDate': endDate
        })
        flexibleDefaultTimeList = calculateDates(flexibleDefaultTimeList)

        const assetReservationList = JSON.parse(JSON.stringify(this.state.assetReservationList))
        assetReservationList.forEach((item)=>{
          item.reserveDates = flexibleDefaultTimeList
        })

        this.setState({
          flexibleDefaultTimeList,
          assetReservationList,
          data: {
            ...this.state.data,
            flexible_duration: null
          }
        })
      }
      else {
        const assetReservationList = JSON.parse(JSON.stringify(this.state.assetReservationList))
        let reserveDates = assetReservationList[targetIndex].reserveDates
        if (reserveDates){
          reserveDates.push({
            'startDate': startDate,
            'endDate': endDate
          })
        }else{
          reserveDates = [{
            'startDate': startDate,
            'endDate': endDate
          }]
        }
        assetReservationList[targetIndex].reserveDates = calculateDates(reserveDates, assetReservationList[targetIndex].operatingHour)
        this.setState({
          assetReservationList,
          data: {
            ...this.state.data,
            special_duration: null
          }
        })
      }

    }
  }

  flexibleTimeDelete(targetList, targetIndex, assetIndex){
    if(targetList === 'default'){
      this.setState({
        flexibleDefaultTimeList: this.state.flexibleDefaultTimeList.filter((item, index) => index !== targetIndex),
      })
    }
    else {
      const assetReservationList = JSON.parse(JSON.stringify(this.state.assetReservationList))
      if(targetIndex !== undefined && assetIndex !== undefined){
        assetReservationList[assetIndex]?.reserveDates?.splice(targetIndex, 1)
      }else{
        assetReservationList.forEach(item=>{
          item.reserveDates = this.state.flexibleDefaultTimeList
        })
      }
      this.setState({
        assetReservationList
      })
    }
  }

  handleDateChange(_startDate, _packageDuration){

    const startDate = _startDate || this.state.duration.startDate
    const day = _packageDuration || this.state.duration.day
    const endDate = startDate && day && (moment(startDate).add(day-1, 'day').format('YYYY-MM-DD'))

    this.setState({
      duration: {
        startDate,
        endDate,
        day
      }
    })
  }

  handleOffsite(name, option, index) {
    const offsite = Object.assign({}, this.state.offsite)
    const list = this.state.offsite[name]['list']

    if (option === 'add') {
      list.push({
        name: name + 'Name',
        type: 'text'
      })
    } else {
      list.splice(index, 1)
    }

    offsite[name]['list'] = list
    this.setState({
      offsite,
    })
  }
  initCampaignUrl(){
    const data = Object.assign({}, this.state.data)
    const _urls = this.state.data.campaignLandingPageUrl || ['']
    _urls.forEach((item, listIndex) => {
      const _urlItem = data['campaignLandingPageUrlList_' + listIndex] || ''
      if(data.campaignLandingPageUrl){
        data.campaignLandingPageUrl[listIndex] = _urlItem
      }else{
        data.campaignLandingPageUrl = [_urlItem]
      }
    })
    return data
  }
  handleCampaignUrl(option, index){
    const data = this.initCampaignUrl()
    const list = Object.assign([], data.campaignLandingPageUrl)

    if (option === 'add') {
      list.push('')
    } else {
      list.splice(index, 1)
      delete data['campaignLandingPageUrlList_' + index]
    }
    list.forEach((item, listIndex) => {
      data[`campaignLandingPageUrlList_${listIndex}`] = item;
    })
    data['campaignLandingPageUrl'] = list
    this.setState({
      data
    })
  }

  formatAttributeValues(formData){
    const attributeValues = []
    for (const key in this.state.offsite){
      const inputs = formData[key+'Name']

      let _values = []
      for(let i=0; i<inputs.length; i++){
        if(inputs[i].name === key){
          _values = inputs[i].values
        }
      }

      const _list = []
      inputs?.forEach((item, index)=>{
        if(index < this.state.offsite[key].list.length){
          _list.push({
            'id': _values[index]?.id || null,
            'value': item
          })
        }
      })

      attributeValues.push({
        'id': this.state.offsite[key].id,
        'values': _list
      })
    }
    return attributeValues
  }

  handleSubmit(formData) {
    if(this.state.packageType === 'FLEXIBLE'){
      if(this.state.flexibleDefaultTimeList.length === 0){
        this.setState({
          errorDialog: {
            shown: true,
            title:  'Alert',
            message: getMessage('media.adsetdetail.flexible.defaulttime.requiredMessage'),
          },
        })
        return
      }else{
        for (let i = 0; i < this.state.assetReservationList.length; i++) {
          const reserveDates = this.state.assetReservationList[i].reserveDates;
          if (reserveDates == null || (Array.isArray(reserveDates) && reserveDates.length === 0)) {
            const assetName = this.state.assetReservationList[i].assetName
            this.setState({
              errorDialog: {
                shown: true,
                title:  'Alert',
                message: `Asset(${assetName}) ${getMessage('media.adsetdetail.flexible.asset.rowheaders.time.requiredMessage')}`,
              },
            })
            return
          }
        }
      }
    }
    const _assetReservationList = this.regroupAssetReservation()
    this.setState({
      showLoader: true
    })
    if(!this.state.lineItemId) {
      const campaignId = this.props.match.params.campaignId;
      this.addLineItem(campaignId, formData, _assetReservationList)
    }
    else {
      const lineItemId = this.state.lineItemId
      this.updateLineItem(lineItemId, formData, _assetReservationList)
    }
  }

  regroupAssetReservation(){
    const _assetReservationList = []
    if (this.state.packageType === 'FLEXIBLE'){
      this.state.assetReservationList.forEach((item) => {
        if(item.reserveDates?.length > 0){
          _assetReservationList.push({
            id: item.id,
            reserveDates: concatDateArr(item.reserveDates)
          })
        }
      })
    }
    return _assetReservationList
  }

  addLineItem(campaignId, formData, _assetReservationList){
    this.api = new API({ url: '/martech-ad-service/reservations/addLineItem/'+campaignId })
    const requestBody = {
      'lineItemName': formData.lineItemName,
      'startDate': formData.startDate,
      'status': null,
      'packageId': formData.selectedPackage,
      'lineItemSupplierReservationList': this.getSupplierListWithBudget(formData),
      'attributeValues': this.formatAttributeValues(formData),
      'buyer': formData.buyer,
    }
    if(this.state.packageType === 'FLEXIBLE'){
      requestBody['defaultReserveDates'] = concatDateArr(this.state.flexibleDefaultTimeList)
      requestBody['assetReservationList'] = _assetReservationList
    }

    if (formData.classGLCode){
      formData.classGLCode = this.getClassGLCodeFromId(parseInt(formData.classGLCode))
    }

    this.api.post(requestBody).then(response => {
      this.setState({
        lineItemId: response.data.id
      })
      this.SetupDetailsForLineItems(response.data.id, formData).then(
        () => {
          this.SetupMechanicDetailsForLineItems(response.data.id, formData)
        },
        error => {
          throwError(this, error)
        }
      )
    }).catch(error => {
      throwError(this, error)
    })
  }

  updateLineItem(lineItemId, formData, _assetReservationList){
    this.api = new API({ url: '/martech-ad-service/reservations/updateLineItem' })
    const requestBodyPut = {
      'id': Number(lineItemId),
      'lineItemName': formData.lineItemName,
      'startDate': formData.startDate,
      'status': null,
      'packageId': formData.selectedPackage,
      'lineItemSupplierReservationList': this.getSupplierListWithBudget(formData),
      'attributeValues': this.formatAttributeValues(formData),
      'buyer': formData.buyer,
    }
    if(this.state.packageType === 'FLEXIBLE'){
      requestBodyPut['defaultReserveDates'] = concatDateArr(this.state.flexibleDefaultTimeList)
      requestBodyPut['assetReservationList'] = _assetReservationList
    }

    if (formData.classGLCode){
      formData.classGLCode = this.getClassGLCodeFromId(parseInt(formData.classGLCode))
    }

    this.api.put(requestBodyPut).then(() => {
      this.SetupDetailsForLineItems(lineItemId, formData).then(
        () => {
          this.SetupMechanicDetailsForLineItems(lineItemId, formData)
        },
        error => {
          throwError(this, error)
        }
      )
    }).catch(error => {
      throwError(this, error)
    })
  }

  SetupDetailsForLineItems (lineItemId, formData) {
    return new Promise((resolve, reject) => {
      this.initCampaignUrl()
      const setupBuBody = {
        "lineItemReservationId": lineItemId,
        "supplierBanner": formData.supplierBanner,
        "logoName": formData.logoName,
        "brandDetails": formData.brandDetails,
        "supplierLandingPageId": formData.supplierLandingPageId,
        "supplierLandingPageUrl": formData.supplierLandingPageUrl,
        "backendOfficeId": formData.backendOfficeId,
        "defaultSearchText": formData.defaultSearchText,
        "keywords": formData.keywords,
        "supplierUtm": formData.supplierUtm,
        "campaignPromoText": formData.campaignPromoText,
        "categoryPageUrl": formData.categoryPageUrl,
        "campaignLandingPageUrl": this.state.data.campaignLandingPageUrl?.filter(url => url.trim()).join(';'),
      }

      this.api = new API({ url: '/martech-ad-service/setupDetails' })
      const id = Number(this.state.data.setupDetails?.id)
      if (!id) {
        this.api.post(setupBuBody).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      } else {
        this.api = new API({ url: '/martech-ad-service/setupDetails/' + id })
        setupBuBody['id'] = id
        this.api.put(setupBuBody).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      }
    })
  }

  SetupMechanicDetailsForLineItems(lineItemId, formData) {

    const mechanicBuBody = {
      lineItemReservationId: lineItemId,
      description: formData.description,
      edmFeature: formData.edmFeature,
      includesDbb: formData.includesDbb,
      defaultSearch: formData.defaultSearch,
      keywordSearch: formData.keywordSearch,
      featuredSku: formData.featuredSku,
      fullDescriptionFoc: formData.fullDescriptionFoc,
      focWarrantyCard: formData.focWarrantyCard,
      focWarrantyPeriod: formData.focWarrantyPeriod,
      focValue: formData.focValue,
      handling: formData.handling,
      indicate: formData.indicate,
      premiumQut: formData.premiumQut,
      focHandlingFee: formData.focHandlingFee,
      vendorCode: formData.vendorCode,
      soac: formData.soac,
      promoType: formData.promoType,
      classGLCode: formData.classGLCode
    }
    const id = Number(this.state.data.businessMechanic?.id)
    if (!id) {
      this.api = new API({ url: '/martech-ad-service/businessMechanic' })
      this.api.post(mechanicBuBody).then(() => {
        this.props.history?.goBack()
      }).catch(error => {
        throwError(this, error)
      })
    } else {
      this.api = new API({ url: '/martech-ad-service/businessMechanic/' + id })
      this.api.put(mechanicBuBody).then(() => {
        this.props.history?.goBack()
      }).catch(error => {
        throwError(this, error)
      })
    }
  }

  getSupplierListWithBudget (formData) {
    const suppliers = []
    for (let i = 0; i < formData?.advertiser?.length; i++) {
      const item ={
        supplierId: formData.advertiser[i],
        budget: formData['budget_'+i]
      }
      suppliers.push(item)
    }
    return suppliers
  }

  getClassGLCodeFromId(id){
    return this.state.classGLCodeList.find(item => item.id === id)
  }

  render () {
    const { menu } = this.props
    const {
      showLoader,
      isMobileView,
      pageAction,
    } = this.state

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className='media-adset-detail website-pages'
        title={getMessage('media.adsetdetail.title.' + pageAction)}
      >
        <div className="header-container">
          <h1 className='title'>{getMessage('media.adsetdetail.title.' + pageAction)}</h1>
          {this.state.pageAction === 'edit' && (
            <div className='timestamp'>
              <div>{getMessage('media.public.createdby')}: {this.state.data?.createdBy} 丨 {moment(this.state.data?.createdOn).format('ddd, DD MMM YYYY, h:mm A')}</div>
              <div>{getMessage('media.public.updatedby')}: {this.state.data?.updatedBy} 丨 {moment(this.state.data?.updatedOn).format('ddd, DD MMM YYYY, h:mm A')}</div>
            </div>
          )}
        </div>

        {showLoader ? (
          <Loader />
        ) : (
          <MediaAdsetForm
            pageAction={pageAction}
            value={this.state.data}
            isMobileView={isMobileView}
            campaignId={this.props.match.params.campaignId}
            advertiserList={this.state.advertiserList}
            offsite={this.state.offsite}
            handleOffsite={this.handleOffsite}
            handleCampaignUrl={this.handleCampaignUrl}
            handlePackageChange={this.handlePackageChange}
            packageType={this.state.packageType}
            assetReservationList={this.state.assetReservationList}
            flexibleTimeAdd={this.flexibleTimeAdd}
            flexibleTimeDelete={this.flexibleTimeDelete}
            flexibleDefaultTimeList={this.state.flexibleDefaultTimeList}
            handleDateChange={this.handleDateChange}
            duration={this.state.duration}
            classGLCodeList={this.state.classGLCodeList}
            onSubmit={(data) => {
              this.handleSubmit(data);
            }}
            onCancel={() => {
              this.props.history.goBack()
            }}
          />
        )}

        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={this.state.errorDialog.message}
            close={()=>(hideErrorDialog(this))}
            closeText={getMessage('dialog.okText')}
          />
        )}
      </AuthenticatedPage>
    )
  }
}
