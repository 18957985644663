import React, { Component } from 'react'
import Loader from '../Loader'
import EmptyState from '../../components/EmptyState'
import API from '../../lib/api'
import { getMessage } from '../../lib/translator'
import './style.css'
import emptyIcon from './order-log-empty.svg'
import LogItem from './LogItem'

export default class QCOrderLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      orderLog: [],
    }
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    })
    const { orderId } = this.props

    this.api = new API({ url: `/orders/${orderId}/logs` })
    this.api.get().then((response) => {
      this.setState({
        orderLog: response.data.orderlog,
        showLoader: false,
      })
    })
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  render() {
    const { orderLog, showLoader } = this.state
    const count = orderLog && orderLog.length
    return (
      <div className="order-log-component">
        <span className="order-log-title">
          {getMessage('qc.order.logs.heading')}
        </span>
        <div className="qc-order-log">
          {showLoader ? (
            <Loader />
          ) : count > 0 ? (
            orderLog
              .toReversed()
              .map((logItem, index) => (
                <LogItem data={logItem} index={index} key={index} />
              ))
          ) : (
            <div>
              <EmptyState
                icon={emptyIcon}
                message={getMessage('order.logs.emptyMeassage')}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
