import React from 'react'
import Tree from '../../../../components/Tree'
import Loader from '../../../../components/Loader'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import { DropDown, DropDownItem, ICONS } from '../../../../components/DropDown'
import AddForm from './AddForm'
import './style.css'
import { Popup } from '../../../../components/Popup'
import Image from '../../../../components/Image'
import TagIcon from './Tag-icon.svg'
import LinkIcon from './Link-icon.svg'

export const generateNodeProps =
  ({ showPopupMethod, removeItemMethod  }) =>
  ({ node, path }) => ({
    buttons: [
      node.hasOwnProperty('c') ? null : node.hasOwnProperty('t') ? (
        <Image src={TagIcon} alt="tag-icon" />
      ) : (
        <Image src={LinkIcon} alt="link-icon" />
      ),
      <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />} key={path}>
        <DropDownItem
          onClick={() => {
            showPopupMethod(path, 'Tag')
          }}
        >
          {getMessage('navigation.dropdown.item.addTag')}
        </DropDownItem>
        <DropDownItem
          onClick={() => {
            showPopupMethod(path, 'Link')
          }}
        >
          {getMessage('navigation.dropdown.item.addLink')}
        </DropDownItem>
      </DropDown>,
      !node.hasOwnProperty('c') ? (
        <button className="remove-button" onClick={() => removeItemMethod(path)}>
          Delete Category
        </button>
      ) : null,
    ],
  })
export function recursivelyTraversing(data, path, index) {
  if (path.length <= index) {
    return data
  } else {
    const key = path[index]
    return data.filter(item => {
      if (item.index === key) {
        if (item.hasOwnProperty('children')) {
          if (path.length > index + 1) {
            item.children = recursivelyTraversing(item.children, path, ++index)
            item.menu = item.children
            return item
          }
        }
        return null
      } else {
        return item
      }
    })
  }
}

export function generateSubMenu(data, submit = false, counter = 0) {
  data.map((item, index) => {
    item.index = index.toString() + ',' + counter.toString()
    if (!item.menu) {
      if (item.id) {
        // Either category or tag
        if (item.url && item.url.includes('category')) {
          item['c'] = item.id
        }
        if (item.url && item.url.includes('tag')) {
          item['t'] = item.id
        }
      }
    } else {
      if (item.id) {
        // Either category or tag
        if (item.url && item.url.includes('category')) {
          item['c'] = item.id
        }
        if (item.url && item.url.includes('tag')) {
          item['t'] = item.id
        }
      }
      item[submit ? 'menu' : 'children'] = generateSubMenu(
        submit ? item.children : item.menu,
        submit,
        item.index
      )
    }
    if (!submit) {
      item.title = item.name
      item.link = item.url
      delete item.name
      delete item.url
    } else {
      item.name = item.title
      if (item.hasOwnProperty('c')) {
        item.c = item.id
        delete item.name
        delete item.link
      }

      if (item.hasOwnProperty('t')) {
        item.t = item.id
        delete item.name
        delete item.link
      }

      delete item.children
      delete item.id
      delete item.url
      delete item.title
      delete item.index
    }

    return item
  })

  return data
}

export function findingNode(data, path, index) {
  if (path.length <= index) {
    return data
  } else {
    if (index !== 0) {
      data = data.children
    }
  }
  const key = path[index]
  let returnedObject = null
  data.map(item => {
    if (item.index === key) {
      returnedObject = findingNode(item, path, ++index)
    }
    return null
  })
  return returnedObject
}
export const deepIterator = (info) => {
  if (info.menu) {
    info.menu.forEach(deepIterator)
  } else {
    if (info.c && info.c === info.t) {
      delete info.t
    }
  }
}
class CategorySequencing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      treeData: [],
      showPopup: false,
    }

    this.removeItem = this.removeItem.bind(this)
    this.showPopup = this.showPopup.bind(this)
    this.hidePopup = this.hidePopup.bind(this)
    this.onAddClick = this.onAddClick.bind(this)
    this.onConfirmClick = this.onConfirmClick.bind(this)
    this.onAppendMethod = this.onAppendMethod.bind(this)
    this.onAppendClick = this.onAppendClick.bind(this)
  }

  onAppendClick(type) {
    this.setState({
      showPopup: true,
      append: true,
      val: type,
    })
  }

  onAppendMethod() {
    const treeData = JSON.parse(JSON.stringify(this.state.treeData))
    if (this.state.val === 'Link') {
      treeData.push({
        title: this.state.data.linkText,
        link: this.state.data.href,
        index: this.state.treeData.length,
      })
    } else {
      const tagId = (this.state.data.tag && this.state.data.tag.id) || null
      const tagName = (this.state.data.tag && this.state.data.tag.name) || null
      treeData.push({
        t: tagId,
        id: tagId,
        title: tagName,
        index: this.state.treeData.length,
      })
    }
    this.setState({
      treeData,
      onSubmit: true,
      append: false,
    })
  }

  removeItem(path) {
    const treeData = JSON.parse(JSON.stringify(this.state.treeData))
    const data = recursivelyTraversing(treeData, path, 0)
    this.setState({
      treeData: data,
      onSubmit: true,
    })
  }

  async onConfirmClick(data) {
    await this.setState({
      data,
    })

    this.state.append ? await this.onAppendMethod() : await this.onAddClick()
    this.hidePopup()
  }

  showPopup(path, type) {
    this.setState({
      showPopup: true,
      path,
      val: type,
    })
  }

  hidePopup() {
    this.setState({
      showPopup: false,
    })
  }

  onAddClick() {
    const treeData = JSON.parse(JSON.stringify(this.state.treeData))
    const item = findingNode(treeData, this.state.path, 0)
    if (item) {
      if (this.state.val === 'Link') {
        if (item.hasOwnProperty('children')) {
          item.children.push({
            title: this.state.data.linkText,
            link: this.state.data.href,
            index: item.children.length + ',' + item.index,
          })
          item.menu = item.children
        } else {
          item.children = []
          item.children.push({
            title: this.state.data.linkText,
            link: this.state.data.href,
            index: item.children.length + ',' + item.index,
          })
          item.menu = item.children
        }
      } else {
        const tagId = (this.state.data.tag && this.state.data.tag.id) || null
        const tagName = (this.state.data.tag && this.state.data.tag.name) || null
        if (this.state.val === 'Tag' && tagId && tagName) {
          if (item.hasOwnProperty('children')) {
            item.children.push({
              t: tagId,
              id: tagId,
              title: tagName,
              index: item.children.length + ',' + item.index,
            })
            item.menu = item.children
          } else {
            item.children = []
            item.children.push({
              t: tagId,
              id: tagId,
              title: tagName,
              index: item.children.length + ',' + item.index,
            })
            item.menu = item.children
          }
        }
      }
      this.setState({
        treeData,
        onSubmit: true,
      })
    }
  }

  componentDidMount() {
    this.menuapi = new API({ url: '/website-service/nav' })
    this.menuapi
      .get()
      .then(res => {
        const menu = res.data || []
        if (menu && menu.length > 0) {
          const generateTree = generateSubMenu(menu)
          this.setState({
            loading: false,
            treeData: generateTree,
          })
        } else {
          this.setState({
            loading: false,
          })
        }
      })
      .catch(error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        } else {
          this.setState({
            error: error.message,
            loading: false,
          })
        }
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.onSubmit && this.state.onSubmit !== prevState.onSubmit) {
      const tree = JSON.parse(JSON.stringify(this.state.treeData))
      const data = generateSubMenu(tree, true)
      data.forEach(item => deepIterator(item));
      this.api = new API({ url: '/website-service/nav' })
      this.api.post({ menu: data }).then(
        () => {
          this.setState({
            success: true,
            onSubmit: false,
          })
        },
        error => {
          if (error.code === 401 || error.code === 403) {
            throw error
          } else {
            this.setState({
              error: error.message,
              onSubmit: false,
            })
          }
        }
      )
    }
  }
  componentWillUnmount() {
    this.api && this.api.cancel()
    this.menuapi && this.menuapi.cancel()
  }

  render() {
    const { loading, treeData, error, showPopup, val } = this.state

    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="category-tree category-sequence"
      >
        <div className="heading">
          <h1>{getMessage('navigation.sequencing.heading')}</h1>
          <DropDown
            icon={
              <button className="primary button">
                {getMessage('navigation.add.button')}
              </button>
            }
          >
            <DropDownItem
              onClick={() => {
                this.onAppendClick('Tag')
              }}
            >
              {getMessage('navigation.dropdown.item.addTag')}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                this.onAppendClick('Link')
              }}
            >
              {getMessage('navigation.dropdown.item.addLink')}
            </DropDownItem>
          </DropDown>
        </div>
        <Popup
          show={showPopup}
          close={this.hidePopup}
          heading={`${getMessage('category.add')} ${val}`}
        >
          <AddForm val={val} onSubmit={this.onConfirmClick} />
        </Popup>
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {error && <div className="form-error">{error}</div>}
            {treeData.length > 0 ? (
              <Tree
                data={treeData}
                className="category-tree"
                data-testid="tree"
                onDragStateChanged={({ isDragging }) => {
                  if (isDragging) {
                    this.treeData = this.state.treeData
                  }
                }}
                getNodeKey={({ node }) => node.index}
                onVisibilityToggle={() => {
                  this.setState({
                    onSubmit: false,
                  })
                }}
                onChange={e => this.setState({ treeData: e, onSubmit: true })}
                canDrop={({ node, nextParent, prevParent }) => {
                  const nextParentId = nextParent && nextParent.id
                  const prevParentId = prevParent && prevParent.id
                  if (node.hasOwnProperty('c')) {
                    if (nextParentId === prevParentId) {
                      return true
                    }
                    return false
                  }
                  return true
                }}
                generateNodeProps={generateNodeProps({
                  showPopupMethod: this.showPopup,
                  removeItemMethod: this.removeItem,
                })}
              />
            ) : (
              <p className="no-items">
                {getMessage('navigation.nosequence.display')}
              </p>
            )}
          </React.Fragment>
        )}
      </AuthenticatedPage>
    )
  }
}

export default CategorySequencing
