import { BackButton, Button, FixedButton, Text, View } from '../ui'
import React, { useCallback, useEffect } from 'react'
import { useFormikContext, Field } from 'formik'
import { FormikInput } from './FormikInput'

const COMMON_ERROR = 'Either Barcode or SKU is required'

export const SearchProduct = ({ onBack, onSubmit }) => {
  const { setFieldValue, values, setFieldError } = useFormikContext()

  useEffect(() => {
    return () => {
      setFieldValue('inputBarcode', '')
      setFieldValue('inputSKU', '')
    }
  }, [])

  const handleContinue = useCallback(() => {
    /**
     * Validate the fields to ensure either barcode or SKU is having values
     */
    if (!values.inputBarcode && !values.inputSKU) {
      if (!values.inputBarcode) {
        setFieldError('inputBarcode', COMMON_ERROR)
      } else {
        setFieldError('inputSKU', COMMON_ERROR)
      }
    } else {
      onSubmit?.()
    }
  }, [values])

  return (
    <>
      <BackButton width={40} onClick={onBack} margin="0 0 16px" />
      <View direction="column" alignItems="start">
        <Text fontSize={14} margin="0 0 16px" textAlign="left" display="block">
          Enter the complete barcode or SKU number below.
        </Text>
        <Field
          component={FormikInput}
          label="BARCODE NUMBER"
          name="inputBarcode"
          placeholder="Enter barcode"
        />
        <Field
          component={FormikInput}
          label="SKU Number"
          placeholder="Enter sku number"
          name="inputSKU"
        />
      </View>
      <FixedButton>
        <Button onClick={handleContinue}>Continue</Button>
      </FixedButton>
    </>
  )
}
