import React, { useState, useEffect } from 'react'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import { tableProperties } from 'components/AppHome/AppCard/utils'
import PreviewCard from 'components/AppHome/AppCard/PreviewCard'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Filters from 'components/AppHome/Filters'
import ManageGenericSwimlaneForm from './Form'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import { DndProvider } from 'react-dnd'
import { Link } from 'react-router-dom'
import API from 'lib/api'
import './style.css'

export const CategoriesContext = React.createContext()

const Actions = ({ isDisabled, setIsPreview }) => {
  return (
    <div className="actions-section">
      <button
        disabled={isDisabled}
        className="primary button"
        onClick={() => {
          setIsPreview(true)
        }}
      >
        <span className="text">{getMessage('app.card.preview.all')}</span>
      </button>
      <Link to="/app-home/card-content/add">
        <button className="primary button">
          <span className="text">{getMessage('app.card.create')}</span>
        </button>
      </Link>
    </div>
  )
}

const ManageCard = (props) => {
  const [categories, setCategories] = useState([])
  const [isPreview, setIsPreview] = useState(false)

  const closePopup = () => {
    setIsPreview(false)
  }

  useEffect(() => {
    const cardsCategoriesAPI = new API({
      url: `/genie/lms/content?contentType=category&key=cards`,
    })
    cardsCategoriesAPI.get().then((res) => {
      if (!res.data.items) {
        return
      }
      const list = res.data.items.map((item) => {
        return { text: item.name, value: `cards#${item.name}` }
      })
      setCategories(list)
    })
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <CategoriesContext.Provider value={{ categories }}>
        <ListingPageWithRoutes
          testid="appcard-listingpage"
          className="appcard-listingpage"
          title="Manage Card"
          addHeading="Create Card"
          editHeading="Edit Card"
          menu={props.menu}
          api={{
            url: `/genie/lms/content`,
            transform: (res) => {
              if (res.data.items.length > 0) {
                return res.data.items
              }
              return null
            },
            params: {
              contentType: 'content',
              key: props.match.params.key
                ? `cards#${props.match.params.key}`
                : null,
              sk: props.match.params.sk ? Number(props.match.params.sk) : null,
            },
          }}
          headerActions={() => (
            <Actions
              setIsPreview={setIsPreview}
              isDisabled={!props.location.search}
            />
          )}
          form={{
            component: ManageGenericSwimlaneForm,
            transformSubmit: (data) => {
              if (props.match.params.action === 'add') {
                return {
                  data,
                  contentType: 'content',
                  key: `cards#${data.category}`,
                  sk: null,
                }
              }
              return data
            },
            overwriteWithApiParams: false,
          }}
          filters={{
            component: Filters,
            forceShow: true,
            showFiltersOnLoad: true,
            transformFilter: () => {
              return {
                contentType: 'content',
                key: 'cards#ContentHub',
              }
            },
            options: { type: 'cards' },
          }}
          tableProperties={tableProperties()}
          viewHeading="Card Details"
          modalClassName="appcard-popup"
          dontSavePagination
          primaryKey="sk"
          tableDynamic
        />
        <Popup
          show={isPreview}
          heading={getMessage('app.card.preview.all')}
          close={closePopup}
          className="previewcard-popup"
        >
          <PreviewCard
            closePopup={closePopup}
            category={props.location.search.split('%23')[1]}
          />
        </Popup>
      </CategoriesContext.Provider>
    </DndProvider>
  )
}

export default ManageCard
