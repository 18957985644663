import React from 'react'
import QCOrderLog from '../../../../../components/QCOrderLog'

const OrderLog = props => {
  return (
    <div className="qc-container" data-testid="order-summary-container">
      <QCOrderLog orderId={props.id} />
    </div>
  )
}

export default OrderLog
