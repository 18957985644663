import React from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
} from '../../../../../components/Form'

import { getMessage } from '../../../../../lib/translator'

export default class TagFiltersForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values.status = ''
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage(
              'customer-service-tags.form.search.tag.name.label'
            )}
            placeholder={getMessage(
              'customer-service-tags.form.tag.name.placeholder'
            )}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
            }}
          />
        </div>
        <SubmitButton>
          {getMessage('customer-service-tags.form.search.submitText.label')}
        </SubmitButton>
        <ClearButton>
          {getMessage('customer-service-tags.form.search.clearAll.label')}
        </ClearButton>
      </Form>
    )
  }
}
