import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Image from 'components/Image'
import InfoIcon from 'icons/info-icon-filled.svg'
import Table, { Header, Row, Cell } from 'components/Table'
import { getMessage } from 'lib/translator'
import { formatCardNumber, getPaymentLogo } from 'lib/payment'
import API from '../../../../../lib/api'

export default function PaymentDetails({
  payments,
  currency,
  isSplitOrder,
  salesOrderReferenceNumber,
}) {
  const getCardNumber = ({ chargeType, cardNumber }) => {
    const type = chargeType ? chargeType.split('_').pop() : ''
    return formatCardNumber(cardNumber, type)
  }

  const [salesPayments, setSalesPayments] = useState([])

  useEffect(() => {
    const getSaleOrderApi = new API({
      url: `/order-service/v3/salesOrders/${salesOrderReferenceNumber}`,
    })
    getSaleOrderApi
      .get()
      .then((res) => {
        setSalesPayments(res.salesOrder.payments)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [isSplitOrder])

  return (
    <div className="payment-details">
      <div className="section-title">
        <h3>{getMessage('order.details.payment.heading')}</h3>
        {isSplitOrder ? (
          <>
            <div style={{ display: 'flex' }}>
              <img src={InfoIcon} style={{ width: '1rem' }} alt="" />
              <Link
                to={`/customer-support/sale-orders/${salesOrderReferenceNumber}`}
                style={{ marginLeft: '0.5rem' }}
              >
                {getMessage('deliveryOrder.details.redirectToSaleOrder')}
              </Link>
            </div>
            {salesPayments && salesPayments.length !== 0 && (
              <Table>
                <Header>
                  <Cell>{getMessage('saleOrder.details.payment.amount')}</Cell>
                  <Cell>{getMessage('saleOrder.details.payment.mode')}</Cell>
                  <Cell>{getMessage('saleOrder.details.payment.card')}</Cell>
                  <Cell>{getMessage('saleOrder.details.payment.status')}</Cell>
                  <Cell>{getMessage('saleOrder.details.payment.details')}</Cell>
                </Header>
                {salesPayments.map((paymentItem) => (
                  <Row key={paymentItem.id}>
                    <Cell>
                      {currency.symbol} {Number(paymentItem.amount).toFixed(2)}
                    </Cell>

                    <Cell>
                      <div>{paymentItem.mode}</div>
                    </Cell>

                    <Cell testId="payment-card-number">
                      {paymentItem.metaData.cardNumber ||
                      paymentItem.metaData.cardType ? (
                        <React.Fragment>
                          <Image
                            className="payment-card"
                            size="sm"
                            src={getPaymentLogo(paymentItem.metaData.cardType)}
                          />
                          <span className="formatted-card-number">
                            {formatCardNumber(
                              paymentItem.metaData.cardNumber,
                              paymentItem.metaData.type
                            )}
                          </span>
                        </React.Fragment>
                      ) : (
                        '-'
                      )}
                    </Cell>

                    <Cell className="payment-status">
                      {paymentItem.status.split('PAYMENT_STATUS_').pop()}
                    </Cell>

                    <Cell className="payment-transaction-id">
                      {getMessage('order.details.payment.transactionId')}-
                      <span className="transaction-id">
                        {paymentItem.transactionId}
                      </span>
                    </Cell>
                  </Row>
                ))}
              </Table>
            )}
          </>
        ) : (
          <Table>
            <Header>
              <Cell>{getMessage('order.details.payment.amount')}</Cell>
              <Cell>{getMessage('order.details.payment.mode')}</Cell>
              <Cell>{getMessage('order.details.payment.card')}</Cell>
              <Cell>{getMessage('order.details.payment.status')}</Cell>
              <Cell>{getMessage('order.details.payment.details')}</Cell>
            </Header>
            {payments.map((paymentItem) => (
              <Row key={paymentItem.transactionId}>
                <Cell className="payment-amount">
                  {currency.symbol} {Number(paymentItem.amount).toFixed(2)}
                </Cell>

                <Cell className="payment-mode">
                  <div>{paymentItem.method}</div>
                </Cell>

                <Cell
                  className="payment-card-number"
                  testId="payment-card-number"
                >
                  {paymentItem.cardNumber || paymentItem.cardType ? (
                    <React.Fragment>
                      <Image
                        className="payment-card"
                        size="sm"
                        src={getPaymentLogo(paymentItem.cardType)}
                      />
                      <span className="formatted-card-number">
                        {getCardNumber(paymentItem)}
                      </span>
                    </React.Fragment>
                  ) : (
                    '-'
                  )}
                </Cell>

                <Cell className="payment-status">
                  {paymentItem.status.split('PAYMENT_STATUS_').pop()}
                </Cell>

                <Cell className="payment-transaction-id">
                  <span className="transactionId">
                    {getMessage('order.details.payment.transactionId')} &ndash;{' '}
                    {paymentItem.transactionId}
                  </span>
                </Cell>
              </Row>
            ))}
          </Table>
        )}
      </div>
    </div>
  )
}
