import React, { Fragment } from 'react'
import UserSegment from './UserSegment'
import Channels from './Channels'
import CardSegment from './CardSegment'
import './style.css'

const Qualifiers = (props) => {
  const { _this, method } = props

  return (
    <Fragment>
      <UserSegment _this={_this} method={method} />
      <CardSegment _this={_this} />
      <Channels _this={_this} method={method} />
    </Fragment>
  )
}

export default Qualifiers
