import React, { Component } from 'react'
import API from '../../../../lib/api'
import Loader from '../../../../components/Loader'
import EmptyState from '../../../../components/EmptyState'
import { getMessage } from '../../../../lib/translator'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import InstoreSettings from './InstoreSettings'
import AnalyticsForm from './AnalyticsForm'
import TawkChatSettings from './TawkChatSetting'
import EntityMetaData from './EntityMetaData'
import LanguageSelect from './LanguageConfig'
import { getExtensionDetails } from '../../../../lib/auth'

class MissingDetails extends Component {
  render() {
    const emptyState = {
      message: getMessage('extension.details.notfound'),
    }

    return <EmptyState {...emptyState} />
  }
}

export default class ExtensionDetailsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: true,
      data: null,
    }
    this.state.showLoader = false
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.props.history.goBack()
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    })
    const metaDataExtension = getExtensionDetails('EntityMetaData')

    const id = this.props.match.params.id
    const api = new API({ url: `/account-service/extension/${id}` })
    if (
      metaDataExtension &&
      Number(metaDataExtension.id) === Number(this.props.match.params.id)
    ) {
      this.setState(
        {
          data: {
            id: Number(this.props.match.params.id),
            slug: metaDataExtension.slug,
          },
        },
        () => {
          this.setState({ showLoader: false })
        }
      )
      return
    }
    api.get().then(
      (response) => {
        this.setState({
          data: response.data.extension,
          showLoader: false,
        })
      },
      () => {
        this.setState({ showLoader: false })
      }
    )
  }

  render() {
    const { data, showLoader } = this.state
    let Comp = MissingDetails
    const slug = data ? data.slug : null
    const value = data ? data.config : null
    switch (slug) {
      case 'InStoreProcessing':
        Comp = InstoreSettings
        break
      case 'Analytics':
        Comp = AnalyticsForm
        break
      case 'TawkToLiveChat':
        Comp = TawkChatSettings
        break
      case 'EntityMetaData':
        Comp = EntityMetaData
        break
      case 'MultiLingualSupport':
        Comp = LanguageSelect
        break
      default:
        break
    }
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
        className="ExtensionForms"
      >
        {showLoader ? (
          <Loader />
        ) : (
          <Comp
            data={data}
            value={value}
            {...this.props}
            onCancel={this.handleClose}
          />
        )}
      </AuthenticatedPage>
    )
  }
}
