import React, { Component } from 'react'
import { Input, Select, Searchable } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import Loader from '../../../../components/Loader'
import { handleFocus } from './FormTabs/utils'

class BFXATP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      selectedVal: [],
    }
    this.validationHandler = this.validationHandler.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
  }
  validationHandler(parent) {
    const validations = JSON.parse(JSON.stringify(parent.state.validations))
    parent.setState({
      validations,
    })
  }

  submitHandler(formData) {
    const data = Object.assign({}, formData)
    const tagIds = data.tagIds && data.tagIds.map(tag => tag.id)
    const newRule = {
      tagIds: tagIds,
      total: data?.rule?.total,
    }
    data.rule = newRule
    delete data.ruleDetail
    delete data.product
    delete data.category
    delete data.brand
    delete data.total
    delete data.entity
    return data
  }

  render() {
    const { discountTypes, _this, hasOfferStarted, isNewOffer } = this.props
    const { values, tagsLoaded } = _this.state
    return (
      <div className="offers-form tag">
        <div className="offer-fields tag">
          {isNewOffer || (!isNewOffer && tagsLoaded) ? (
            <Searchable
              label={getMessage('themes.cc.tags')}
              placeholder={getMessage('themes.cc.tags')}
              name="tag"
              className="tag-search"
              searchUrl="/catalogue-service/tag"
              valueKey="id"
              responseKey="tag"
              nameKey="name"
              searchKey="name"
              transformResponse={response => {
                let tags = response.data.tag
                tags = (tags || []).map(tag => {
                  tag.name = `${tag.name}`
                  tag.id = `${tag.id}`
                  return tag
                })
                return tags
              }}
              multiple
              {..._this.generateStateMappers({
                stateKeys: ['tags'],
                loseEmphasisOnFill: true,
              })}
              readOnly={hasOfferStarted}
            />
          ) : (
            <Loader size="sm" />
          )}
          <Select
            name="discount-type"
            required
            label={getMessage('offer.discounttype')}
            placeholder={getMessage('offer.discounttype.placeholder')}
            options={discountTypes}
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'total', 't'],
              loseEmphasisOnFill: true,
            })}
            onChange={_this.handleDiscountChange}
            readOnly={hasOfferStarted}
          />
          <Input
            name="discount-value"
            type="number"
            onFocus={handleFocus}
            step={0.01}
            min={0}
            max={
              values &&
              values.rule &&
              values.rule.get &&
              values.rule.get.t === `PERCENT_OFF`
                ? 100
                : values && values.rule && values.rule.cartPrice
            }
            required
            label={getMessage('offer.discountvalue')}
            placeholder={getMessage('offer.discountvalue.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'total', 'v'],
              loseEmphasisOnFill: true,
            })}
            readOnly={
              hasOfferStarted ||
              (values &&
                values.rule &&
                values.rule.total &&
                values.rule.total.t === `FREE`)
            }
          />
        </div>
      </div>
    )
  }
}

export default BFXATP
