import React, { Component } from 'react'
import moment from 'moment'
import API from '../../../../lib/api'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage/index'
import Loader from '../../../../components/Loader'
import { getMessage } from '../../../../lib/translator'
import './style.css'
import Filters from './Filters'
import StoreBlock from './storeBlock'
import AssetTable from './assetTable'
import AssetPreview from '../../MediaAssetManagement/assetPreview'
import { Popup } from '../../../../components/Popup'


function getMobileView() {
  return window.screen.width <= 480
}

export default class AssetList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobileView: getMobileView(),
      showLoader: true,
      assetData:[],
      paging: {},
      filterData: {
        filter_duration: {
          startDate: moment().add(90, 'day').format('YYYY-MM-DD'),
          endDate: moment().add(111, 'day').format('YYYY-MM-DD'),
        },
        assetName:'',
        filterStartTime: '00:00',
        filterEndTime: '00:00'
      },
      AssetPopup: {
        shown: false,
        data: null
      },
    }
    this.mobileView = this.mobileView.bind(this)
    this.changePage = this.changePage.bind(this)
    this.toggleAssetBox = this.toggleAssetBox.bind(this)
  }

  onChange (dates) {
    const filterData = JSON.parse(JSON.stringify(this.state.filterData))
    filterData.filter_duration.startDate = dates && dates.startDate
    filterData.filter_duration.endDate = dates && dates.endDate

    this.setState({
      filterData,
    })
  }

  assetNameChanged (value) {
    const filterData = JSON.parse(JSON.stringify(this.state.filterData))
    filterData.assetName = value

    this.setState({
      filterData,
    })
  }

  changePage(_page){
    this.getAssetData(_page)
  }

  getAssetData(_page){
    this.setState({
      showLoader: true
    })
    const storeIdParam = this.props.match.params.id
    const startDateParam = this.state.filterData?.filter_duration?.startDate;
    const endDateParam = this.state.filterData?.filter_duration?.endDate;
    const assetNameParam = this.state.filterData?.assetName;

    this.assetApi = new API({ url: `/martech-ad-service/reservations/asset/inventory` })
    this.assetApi
      .get({
        startDate: startDateParam,
        endDate: endDateParam,
        storeId: storeIdParam,
        assetName: assetNameParam,
        page: _page || 1
      })
      .then(response => {
        this.setState({
          assetData: response?.data?.content,
          paging: {
            count: response?.data?.count,
            offset: response?.data?.offset,
            limit: response?.data?.limit
          },
          showLoader: false
        })
      })
      .catch(error => {
        throw error
      })
  }

  getStoreData () {
    const storeId = this.props.match.params.id
    this.storeDetailsApi = new API({
      url: `/martech-ad-service/stores/${storeId}`,
    })
    this.storeDetailsApi.get().then(
      response => {
        this.setState({
          storeInfo: response?.data,
        })
      }).catch(error => {
      throw error
    })
  }

  toggleAssetBox(status, data) {
    if (status === 1){
      this.api = new API({ url: '/martech-ad-service/assets/'+ data.assetId })
      this.api.get().then(response => {
        this.setState({
          AssetPopup: {
            shown: true,
            data: response.data,
          },
        })
      })
    } else {
      this.setState({
        AssetPopup: {
          shown: false,
          data: null,
        },
      })
    }
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
    this.getStoreData();
    this.getAssetData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.mobileView, false)
    this.storeDetailsApi && this.storeDetailsApi.cancel()
    this.assetApi && this.assetApi.cancel()
  }

  render() {
    const { menu } = this.props
    const { isMobileView, showLoader, storeInfo, assetData, paging, filterData } = this.state

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className="media-asset-list website-pages"
        title={getMessage('media.assetlist.title')}
      >
        <h1 className="title">{getMessage('media.assetlist.title')}</h1>
        <React.Fragment>
          <StoreBlock storeInfo={storeInfo}></StoreBlock>
          <div className='asset-table'>
            <h3 className='asset-table-title'>{getMessage('media.assetlist.table.h3')}</h3>
            <Filters
              value={filterData}
              onChange={(dates) => this.onChange(dates)}
              assetNameChanged={(value)=> this.assetNameChanged(value)}
              onSubmit={() => {
                this.getAssetData()
              }}
            > </Filters>
            {showLoader ? (
              <Loader />
            ) : (
              <AssetTable
                assetData={assetData}
                paging={paging}
                isMobileView={isMobileView}
                changePage={this.changePage}
                toggleAssetBox={this.toggleAssetBox}
              ></AssetTable>
            )}
          </div>
        </React.Fragment>
        <Popup
          show={this.state.AssetPopup.shown}
          className="assetPreview-box"
          heading={getMessage('media.assetDetail.title')}
          close={() => { this.toggleAssetBox(2) }}
        >
          <AssetPreview assetData={this.state.AssetPopup.data}></AssetPreview>
        </Popup>
      </AuthenticatedPage>
    )
  }
}



