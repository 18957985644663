import React from 'react'
import { Select } from 'components/Form'
import { CategoriesContext } from 'pages/app-home/ManageCard'
import { getMessage } from 'lib/translator'

const FilterCard = ({ generateStateMappers }) => {
  const { categories } = React.useContext(CategoriesContext)

  return (
    <Select
      name="select-card-category"
      style={{ marginBottom: '10px' }}
      className="select-card-category"
      label={getMessage('app.card.filter.category')}
      placeholder={getMessage('app.card.filter.category.placeholder')}
      options={categories}
      {...generateStateMappers({
        stateKeys: ['key'],
        loseEmphasisOnFill: true,
      })}
    />
  )
}

export default FilterCard
