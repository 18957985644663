import React, { Component } from 'react'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import { getSession } from 'lib/auth'
import { withRouter } from 'react-router-dom'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import Loader from 'components/Loader'
import PlacementDetails from '../../EGiftingOrders/Details/PlacementDetails'
import PendingApprovalDetailsPage from './PendingApprovalDetailsPage'
import Button from 'components.new/button/Button'

// Modal Component
import RejectModal from './RejectModal'

import './styles.css'

class Details extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: false,
      failed: false,
      invoiceShown: false,
      assignmentCode: props.assignmentCode || '',
      isError: false,
      rejectModalOpen: false,
      error: false,
      buttonLoading: false,
    }
    this.getDataBankToBank = this.getDataBankToBank.bind(this)
    this.getDetailsApi = this.getDetailsApi.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.handleState = this.handleState.bind(this)
  }

  getDetailsApi() {
    return this.evoucherDetailsApi.get().then(
      (response) => {
        const { data } = response
        this.setState({
          failed: false,
          data: data,
        })
      },
      (error) => {
        this.setState({ failed: true })
        if (error.code === 401) {
          throw error
        }
      }
    )
  }

  getDataBankToBank() {
    const referenceNumber = this.props.router.match.params.id
    this.evoucherDetailsApi = new API({
      url: `/gifting-admin-service/orders/${referenceNumber}`,
    })
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        this.getDetailsApi().then(() => {
          this.setState({ loading: false })
        })
      }
    )
  }

  handleState(value) {
    this.setState({ assignmentCode: value })
  }

  onConfirm() {
    const patchAPI = new API({
      url: `/gifting-admin-service/orders/${this.state.data.id}`,
    })
    const { user } = getSession()
    this.setState({ buttonLoading: true, error: false })
    if (!this.state.assignmentCode) {
      this.setState({ isError: true, buttonLoading: false })
    } else {
      this.setState({ isError: false })
      patchAPI
        .patch({
          assignmentCode: this.state.assignmentCode,
          status: 'CHARGED',
          customer: {
            id: user?.id,
            email: user?.emails[0]?.email,
            name: user?.name,
          },
        })
        .then(() => {
          this.setState({ error: false, buttonLoading: false })
          this.props.history.push({
            pathname: '/customer-support/egifting-orders-pending-bank-approval',
            state: {
              action: 'approved',
              reference: this.state.data.reference,
            },
          })
        })
        .catch(() => {
          this.setState({ error: true, buttonLoading: false })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }
  }

  async componentDidMount() {
    this.getDataBankToBank()
  }

  render() {
    const contentLoaded =
      !this.state.loading && !this.state.failed && this.state.data
    const isBusiness = this.state.data?.isB2B || 0

    return (
      <>
        <AuthenticatedPage
          storeDependent
          className="evoucher-details"
          menu={this.props.menu}
        >
          {this.state.rejectModalOpen && (
            <RejectModal
              show={this.state.rejectModalOpen}
              data={this.state.data}
              onClose={() => this.setState({ rejectModalOpen: false })}
            />
          )}
          <div className="flex-around">
            <h1 className="title heading">
              {getMessage('customerSupport.egifting.details.heading')}
              <strong className="reference-number">
                {this.state.data && ` ${this.state.data.reference}`}
              </strong>
            </h1>
          </div>

          {this.state.loading ? <Loader /> : null}
          {this.state.failed ? (
            <div className="retry-message text-muted" onClick={this.getData}>
              Something went wrong. Click here to try again.
            </div>
          ) : null}

          {contentLoaded ? (
            <div>
              <div className="evoucher-summary-wrapper">
                <div className="user-details">
                  {this.state.error && (
                    <div className="changes-saved error flex-end margin-top-16">
                      {getMessage('customerSupport.egifting.header.save.fail')}
                    </div>
                  )}
                  <PlacementDetails
                    isBankToBank
                    data={{
                      status: this.state.data.status,
                      creationTime: this.state.data.createTime,
                      completionTime: this.state.data.updateTime,
                      paymentMethod: this.state.data.paymentType,
                      isBusiness: isBusiness,
                      url: this.state.data.methods?.deposit?.uploads
                        ? this.state.data.methods?.deposit?.uploads[0]?.url
                        : '',
                    }}
                  />
                </div>
                <PendingApprovalDetailsPage
                  handleState={this.handleState}
                  data={this.state.data}
                  assignmentCode={this.state.assignmentCode}
                  isError={this.state.isError}
                />
                <div className="user-details-button">
                  <div className="flex-end">
                    <Button
                      className="verify-button primary margin-right-8"
                      type="button"
                      filled
                      data-testid="on-confirm"
                      disabled={this.state.buttonLoading}
                      onClick={() => this.onConfirm()}
                    >
                      {getMessage(
                        'customerSupport.egifting.header.proof.confirm'
                      )}
                    </Button>
                    <Button
                      type="button"
                      filled
                      data-testid="reject-click"
                      onClick={() =>
                        this.setState({ rejectModalOpen: true, error: false })
                      }
                    >
                      {getMessage(
                        'customerSupport.egifting.header.proof.reject'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </AuthenticatedPage>
      </>
    )
  }
}

export default withRouter(Details)
