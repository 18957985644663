import React from 'react'
import { Select } from '../../Form'
import { getMessage } from '../../../lib/translator'

const RewardTypeSelect = ({ _this, taskIndex, awardIndex }) => (
  <div className="col-6">
    <Select
      name="reward-type"
      label={getMessage('omnichallenge.task.type')}
      placeholder={getMessage('omnichallenge.task.type')}
      options={[
        { text: 'OFFER', value: 'OFFER' },
        { text: 'ZIGPOINT', value: 'ZIGPOINT' },
        { text: 'CDG_VOUCHER', value: 'CDG_VOUCHER' },
        { text: 'PTR_BENEFIT', value: 'PTR_BENEFIT' },
        { text: 'PTR_OFFER', value: 'PTR_OFFER' },
        { text: 'PTR_RC', value: 'PTR_RC' },
      ]}
      {..._this.generateStateMappers({
        stateKeys: ['taskList', taskIndex, 'awardList', awardIndex, 'type'],
      })}
    />
  </div>
)

export default RewardTypeSelect
