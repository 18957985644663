import React from 'react'
import { BaseForm, ProductSearch } from '../../../../components/Form'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'

import './style.css'

function handleError(err) {
  if (err.code === 401) {
    throw err
  }
}

export default class ProductLinkingForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = Object.assign(this.state, {
      products: [],
    })
    this.linkProductToTag = this.linkProductToTag.bind(this)
    this.unlinkProductFromTag = this.unlinkProductFromTag.bind(this)
  }
  linkProductToTag(product) {
    this.setState(
      (prevState) => {
        let index = prevState.products.findIndex(({ id }) => id === product.id)
        if (index === -1) {
          index = prevState.products.length
        }
        const products = [...prevState.products]
        products.splice(
          index,
          1,
          Object.assign({}, product, { _status: 'adding' })
        )
        return { products }
      },
      () => {
        const api = new API({ url: `/catalogue-service/product/${product.id}` })
        api
          .get()
          .then((response) => Promise.resolve(response.data.product))
          .then((product) => {
            const tags = product.tags || []
            delete product.tags
            const tagIds = tags.map(({ id }) => id)
            let promise = null
            const index = tagIds.indexOf(this.props.tagId)
            if (index === -1) {
              tagIds.push(this.props.tagId)
              promise = api.put(Object.assign({}, product, { tagIds }))
            } else {
              promise = Promise.resolve()
            }
            promise.then(() => {
              this.setState((prevState) => {
                const products = [...prevState.products]
                const i = products.findIndex(({ id }) => id === product.id)
                const selectedProduct = Object.assign({}, products[i], {
                  _status: 'added',
                })
                products.splice(i, 1, selectedProduct)
                return { products }
              })
            })
          })
          .catch((err) => {
            handleError(err)
            this.setState((prevState) => {
              const products = [...prevState.products]
              const index = products.findIndex(({ id }) => id === product.id)
              const selectedProduct = Object.assign({}, products[index], {
                _status: 'failed',
              })
              products.splice(index, 1, selectedProduct)
              return { products }
            })
          })
      }
    )
  }
  unlinkProductFromTag(product) {
    this.setState(
      (prevState) => {
        let index = prevState.products.findIndex(({ id }) => id === product.id)
        if (index === -1) {
          index = prevState.products.length
        }
        const products = [...prevState.products]
        products.splice(
          index,
          1,
          Object.assign({}, product, { _status: 'removing' })
        )
        return { products }
      },
      () => {
        const api = new API({ url: `/catalogue-service/product/${product.id}` })
        api
          .get()
          .then((response) => Promise.resolve(response.data.product))
          .then((product) => {
            const tags = product.tags || []
            delete product.tags
            const tagIds = tags.map(({ id }) => id)
            let promise = null
            const index = tagIds.indexOf(this.props.tagId)
            if (index > -1) {
              tagIds.splice(index, 1)
              promise = api.put(Object.assign({}, product, { tagIds }))
            } else {
              promise = Promise.resolve()
            }
            promise.then(() => {
              this.setState((prevState) => {
                const products = [...prevState.products]
                const i = products.findIndex(({ id }) => id === product.id)
                products.splice(i, 1)
                return { products }
              })
            })
          })
      }
    )
  }
  render() {
    const { Form } = this.components
    const statusButtonTexts = {
      added: getMessage('tag.linkToProduct.product.action.remove'),
      failed: getMessage('tag.linkToProduct.product.action.retry'),
    }
    return (
      <Form className="product-linking-form">
        <ProductSearch
          label="Product"
          value={this.getState(['products'])}
          getVariantData={false}
          onChange={this.linkProductToTag}
        />
        <div>
          {this.state.products.map((product) => (
            <div
              className={`product-row status-${product._status}`}
              onClick={() => {
                switch (product._status) {
                  case 'added':
                    this.unlinkProductFromTag(product)
                    break
                  case 'failed':
                    this.linkProductToTag(product)
                    break
                  default:
                    break
                }
              }}
              key={product.id}
            >
              <div className="product-name">{product.name}</div>
              <div className="action-text">
                {statusButtonTexts[product._status] || ''}
              </div>
            </div>
          ))}
        </div>
      </Form>
    )
  }
}
