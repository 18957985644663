import React from 'react'
import { BaseForm, Select } from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'

class CampaignLabelsFilter extends BaseForm {
  render() {
    const { Form } = this.components
    const { SubmitButton, ClearButton } = this.buttons
    const { campaignLabelsType } = this.props.options || {}
    return (
      <Form
        className="search-campaigns-filters"
        testid="search-campaigns-filter"
      >
        <div className="form-fields">
          <Select
            testid="filter-campaignType"
            name="campaignType"
            label={getMessage('campaign-labels.filter.type')}
            placeholder={getMessage('campaign-labels.filter.type.placeholder')}
            options={campaignLabelsType}
            {...this.generateStateMappers({
              stateKeys: ['campaignType'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>
          {getMessage('search-campaigns.filter.submit')}
        </SubmitButton>
        <ClearButton>{getMessage('search-campaigns.filter.clear')}</ClearButton>
      </Form>
    )
  }
}

export default CampaignLabelsFilter
