import React, { Component } from 'react'

import './style.css'
import infoIcon from '../../info-icon.svg'

class Checkbox extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.props.onChange && this.props.onChange(e.target.checked)
  }

  render() {
    const {
      name,
      value,
      inlineLabel,
      className = '',
      controlled = false,
      disabled,
      testid,
      labelClass = '',
      tooltip,
    } = this.props
    return (
      <span className={`Checkbox ${className}`}>
        <input
          data-testid={testid}
          type="checkbox"
          id={name}
          name={name}
          value={!!value}
          disabled={disabled}
          onChange={this.handleChange}
          {...{
            [controlled ? 'checked' : 'defaultChecked']: !!value,
          }}
        />
        <label className={`inline-label ${labelClass}`} htmlFor={name}>
          {!tooltip && inlineLabel}
          {tooltip && (
            <div className="label-tooltip">
              {inlineLabel}
              <img src={infoIcon} alt="note" />
              <div className="label-tooltiptext">{tooltip}</div>
            </div>
          )}
        </label>
      </span>
    )
  }
}

export default Checkbox
