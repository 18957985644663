import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MapWithPath from '../../MapWithPath'
import PaymentLog from './PaymentLog'
import PacklistLog from './PacklistLog'
import StatusLog from './StatusLog'
import SlotTime from './SlotTime'
import { getPrintableTime, formatDate } from '../../../lib/datetime'
import { getMessage } from '../../../lib/translator'
import { getSession, hasPermissions } from '../../../lib/auth'
import { getNewDisplayAddressWithCapKeys } from '../../../lib/commonlyused'
import { get } from 'lib/storage'

const msgLogFrom = getMessage('order.log.from')
const msgLogTo = getMessage('order.log.to')

export default class LogItem extends Component {
  updateStoreLocation(storeLocation) {
    if (
      !storeLocation ||
      !(storeLocation.latitude || storeLocation.longitude)
    ) {
      const storeId = Number(get('store'))
      storeLocation = JSON.parse(get('stores')).filter(
        (store) => store.id === storeId
      )[0]
      delete storeLocation.address
      delete storeLocation.clientStoreId
      delete storeLocation.id
      delete storeLocation.name
    }
    return storeLocation
  }

  getPlacedFrom(item) {
    return (
      item.placedFrom && (
        <div className="placed-from">
          <span className="bold">{getMessage('order.log.placedfrom')}</span>:{' '}
          {item.placedFrom}
        </div>
      )
    )
  }

  getPaymentStatusValue(paymentStatus) {
    return (
      <span className="bold">
        {paymentStatus &&
          paymentStatus
            .toLowerCase()
            .split('_')
            .map((t) => t[0].toUpperCase() + t.substr(1, t.length))
            .join(' ')
            .toString()}
      </span>
    )
  }

  getPaymentStatus(item) {
    return (
      item.paymentStatus && (
        <div className="payment-changed">
          {getMessage('order.log.payment.changed')}: {msgLogFrom}{' '}
          {this.getPaymentStatusValue(item.paymentStatus.oldValue)} {msgLogTo}{' '}
          {this.getPaymentStatusValue(item.paymentStatus.newValue)}
        </div>
      )
    )
  }

  getAddress(item) {
    return (
      item?.address?.newValue &&
      item.address.newValue.ID !== item.address.oldValue.ID && (
        <div>
          {getMessage('order.log.address.changed')}:{' '}
          <span>
            <br />
            {msgLogFrom}:
            {getNewDisplayAddressWithCapKeys(item.address.oldValue)
              .split('\n')
              .map((value, index) => (
                <div
                  key={index}
                  style={{ fontSize: '0.8rem', margin: '0', color: '#80959d' }}
                >
                  {value}
                </div>
              ))}
            {msgLogTo}:
            {getNewDisplayAddressWithCapKeys(item.address.newValue)
              .split('\n')
              .map((value, index) => (
                <div
                  key={index}
                  style={{ fontSize: '0.8rem', margin: '0', color: '#80959d' }}
                >
                  {value}
                </div>
              ))}
          </span>
        </div>
      )
    )
  }

  getPreferredDate(item) {
    return (
      item.preferredDate && (
        <div className="order-date">
          {getMessage('order.log.date.changed')}{' '}
          {item.preferredDate.oldValue && msgLogFrom}{' '}
          <span className="bold">
            {item.preferredDate.oldValue &&
              formatDate(item.preferredDate.oldValue)}
          </span>{' '}
          {msgLogTo}
          <span className="bold">
            {' '}
            {formatDate(item.preferredDate.newValue)}
          </span>
        </div>
      )
    )
  }

  getSlotTime(item) {
    return <SlotTime item={item} />
  }

  getShipping(item) {
    return (
      item.shipping && (
        <div>
          {getMessage('order.log.shipping.charges')}:{' '}
          <span className="bold">{item.shipping.newValue}</span>
        </div>
      )
    )
  }

  getItemType(item) {
    return (
      item.type && (
        <div style={{ textTransform: 'capitalize' }}>
          {getMessage('order.log.type.changed')}: {msgLogFrom}{' '}
          <span className="bold">{item.type.oldValue.name.toLowerCase()}</span>{' '}
          {msgLogTo}{' '}
          <span className="bold">{item.type.newValue.name.toLowerCase()}</span>
        </div>
      )
    )
  }

  getMetaData(item, index) {
    return (
      item.metaData &&
      Object.keys(item.metaData.newValue).map((metadata, i) => (
        <div
          key={`${index}-metadata-${i}`}
        >{`${metadata}: ${item.metaData.newValue[metadata]}`}</div>
      ))
    )
  }

  getAmounts(item) {
    return (
      item.amount &&
      item.amount.oldValue &&
      item.amount.newValues !== null && (
        <div className="amount-changed">
          {getMessage('order.log.amount.changed')}: {msgLogFrom}{' '}
          <span className="bold">{` ${
            getSession().organization.currency.symbol
          }${item.amount.oldValue}`}</span>{' '}
          {msgLogTo}{' '}
          <span className="bold">{`${
            getSession().organization.currency.symbol
          }${item.amount.newValue}`}</span>
        </div>
      )
    )
  }

  getPaymentTransactions(data, item, index) {
    return <PaymentLog index={index} data={data} item={item} />
  }

  getInvoice(item) {
    const invoiceNumber = item?.invoice_number
    return invoiceNumber &&
      invoiceNumber?.oldValue.String !== invoiceNumber?.newValue.String ? (
      <div>
        {getMessage('order.log.invoiceNumber.generated')}:{' '}
        <span className="bold">
          &quot;{invoiceNumber.newValue.String}&quot;
        </span>
      </div>
    ) : null
  }

  isPacklistAction(data) {
    return data.action && data.action.includes('PACKLIST')
  }

  render() {
    const {
      data,
      index,
      orderLocations,
      showMap,
      vehicleCoordinates,
      storeLocation: storeLocationProp,
    } = this.props
    const item = data.details || {}

    let storeLocation = storeLocationProp
    storeLocation = this.updateStoreLocation(storeLocation)

    return (
      ((Object.keys(data.details).length !== 0 &&
        data.action !== 'RECALCULATE_PAYMENT_STATUS') ||
        (data.action === 'RECALCULATE_PAYMENT_STATUS' &&
          !!Object.keys(item).length)) && (
        <div className="log-box" key={`log-${index}`}>
          <div className="log-date-time">
            <div className="log-date">
              {getPrintableTime(data.createdAt).split(',')[0]}
            </div>
            <small className="text-muted">
              {getPrintableTime(data.createdAt).split(',')[1]}
            </small>
          </div>
          <div className="log-details">
            <div className="log-details-wrapper">
              {data.action === 'ORDER_RETURN' && (
                <div className="bold order-return">
                  {getMessage('order.log.return')}
                </div>
              )}

              {data.action === 'STATUS_CHANGE' &&
                data.details.status &&
                data.details.status.newValue === 'COMPLETED' &&
                data.details.leftAtDoorStep && (
                  <div className="bold">
                    {getMessage('order.log.leftAtDoorStep')}
                  </div>
                )}

              {data.action === 'CUSTOMER_NOT_AVAILABLE' && (
                <div className="bold">{getMessage('order.log.customerNA')}</div>
              )}

              <StatusLog
                data={data}
                item={item}
                index={index}
                packlistAction={this.isPacklistAction(data)}
              />

              <PacklistLog data={data} item={item} index={index} />

              {this.getPlacedFrom(item)}

              {this.getPaymentStatus(item)}

              {this.getAddress(item)}

              {this.getPreferredDate(item)}

              {this.getSlotTime(item)}

              {this.getShipping(item)}

              {this.getItemType(item)}

              {this.getMetaData(item, index)}

              {this.getInvoice(item)}

              {data.action === 'ORDER_DISPATCHED' && showMap && (
                <MapWithPath
                  coordinates={orderLocations}
                  vehiclePath={vehicleCoordinates}
                  callMaps={false}
                  showStore={storeLocation}
                />
              )}
              {data.action === 'ORDER_DISPATCHED' && item.tripId && (
                <React.Fragment>
                  <div className="bold">
                    {getMessage('order.log.tripId')}
                    {hasPermissions('logistics', 'trips', 'get') ? (
                      <Link to={`/logistics/trips/${item.tripId}`}>
                        #{item.tripId}
                      </Link>
                    ) : (
                      item.tripId
                    )}
                  </div>
                </React.Fragment>
              )}

              {this.getAmounts(item)}

              {this.getPaymentTransactions(data, item, index)}
            </div>
          </div>
        </div>
      )
    )
  }
}
