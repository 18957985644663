import { DropDown, DropDownItem, ICONS } from 'components/DropDown'
import { Popup } from 'components/Popup'
import ReactJson from 'react-json-view'
import React, { useState } from 'react'

export const PayloadViewPopup = (props) => {
  const { actionPayload, actionType } = props
  const [showPopup, setShowUp] = useState(false)

  const handlePopupClose = () => {
    setShowUp(false)
  }
  if (showPopup) {
    return (
      <Popup
        show={showPopup}
        heading={`Action Payload:  ${actionType}`}
        close={handlePopupClose}
        className={'action-trail-popup'}
      >
        <ReactJson
          name="actionPayload"
          displayObjectSize={false}
          displayDataTypes={false}
          src={actionPayload}
          enableClipboard={false}
        />
      </Popup>
    )
  }
  return (
    <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
      <DropDownItem
        onClick={() => {
          setShowUp(true)
        }}
      >
        View Payload
      </DropDownItem>
    </DropDown>
  )
}
