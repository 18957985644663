/*
    The purpose of this file is to consolidate all the frontend calculations for order subtotals
    regardless of the data structure (e.g. deliveryOrder response, saleOrder response).
*/

function getCalculatedSubtotalOrderValues(order, dataSource) {
    switch(dataSource) {
        case 'saleOrder': {
            const orderItems = order.items.filter(x => !x.orderDetails.substitutedItemId).flatMap(item => {
                return {
                    ...item.orderDetails,
                    soldByWeight: item.soldByWeight === 1
                }
            });
            return getSubtotalValues({
                items: orderItems,
                isPendingOrCancelled: order.isPendingOrCancelled,
                dataSource,
                shipping: order.shipping
            });
        }
        case 'deliveryOrder': return getSubtotalValues({
            items: order.items,
            isPendingOrCancelled: order.isPendingOrCancelled,
            subTotal: order.subTotal,
            dataSource
        });
        default: return null;
    }
}

function getSubtotalValues({ items, isPendingOrCancelled, dataSource, shipping }) {
    const isSaleOrder = dataSource === 'saleOrder';

    // total mrp
    const subTotalPrice = items.reduce((previous, current) => {
        const soldByWeight = 'soldByWeight' in current ? current.soldByWeight : current.item.soldByWeight;
        const quantity = getQuantity(current.deliveredQuantity, current.orderedQuantity, isPendingOrCancelled, soldByWeight)
        return previous + (current.mrp * quantity);
    }, 0)

    // total discount
    const subTotalDiscount = items.reduce((previous, current) => {
        return previous + (current.discount * current.orderedQuantity);
    }, 0)

    // subtotal (Temp: remove subtotal from API response as it is wrong)
    const subTotalAmount = items.reduce((previous, current) => {
        const soldByWeight = 'soldByWeight' in current ? current.soldByWeight : current.item.soldByWeight;
        const quantity = getQuantity(current.deliveredQuantity, current.orderedQuantity, isPendingOrCancelled, soldByWeight)
        const itemAmount = (current.mrp - current.discount) * quantity;
        return previous + itemAmount
    }, isSaleOrder ? Number(shipping) : 0)

    // original subtotal amount
    const originalSubTotalAmount = items.reduce((previous, current) => {
        const itemAmount = (current.mrp - current.discount) * current.orderedQuantity;
        return previous + itemAmount;
    }, isSaleOrder ? Number(shipping) : 0)

    // final subtotal amount
    const finalSubTotalAmount = items.reduce((previous, current) => {
        const soldByWeight = 'soldByWeight' in current ? current.soldByWeight : current.item.soldByWeight;
        const quantity = getQuantity(current.deliveredQuantity, current.orderedQuantity, isPendingOrCancelled, soldByWeight)
        const itemAmount = (current.mrp - current.discount) * quantity;
        return previous + itemAmount;
    }, isSaleOrder ? Number(shipping) : 0)

    return {
        subTotalPrice,
        subTotalDiscount,
        subTotalAmount,
        originalSubTotalAmount,
        finalSubTotalAmount
    }
}

function getQuantity(fulfiledQuantity, orderedQuantity, isPendingOrCancelled, soldByWeight) {
    return isPendingOrCancelled ? orderedQuantity
        : soldByWeight ? Math.min(fulfiledQuantity, orderedQuantity) : fulfiledQuantity;
}

export {
    getCalculatedSubtotalOrderValues
}
