import React, { useMemo } from 'react'
import { parseEmptyValue } from '../hook-utils'
import { diff } from 'json-diff'
import { Popup } from '../../../../../components/Popup'
import { uniqueId } from 'lodash'

export const parseValueToDisplay = (changeObj) => {
  const oldValue = parseEmptyValue(changeObj.__old)
  const newValue = parseEmptyValue(changeObj.__new)
  return (
    <>
      <span
        className={`line-through ${
          oldValue.length + newValue.length > 30 ? 'block' : ''
        }`}
      >
        {oldValue}
      </span>
      <span>{newValue}</span>
    </>
  )
}

export const StoreChangesModal = (props) => {
  const {
    isOpen,
    currentData,
    updatedData,
    onConfirmSubmit,
    onCancel,
    isSubmitting,
    action,
  } = props

  const parsedUpdatedData = { ...updatedData }

  delete parsedUpdatedData.currentStoreData
  delete parsedUpdatedData.attemptSubmit

  const diffObject = useMemo(() => {
    if (isOpen && action !== 'new') {
      // only run diff when modal is open to save resource and improve performance
      const differences = diff(currentData, parsedUpdatedData)
      const fieldsArray = []
      if (differences) {
        Object.keys(differences).forEach((key) => {
          if (key === 'latitude' || key === 'longitude') {
            return
          }
          if (key === 'businessHours' || key === 'meta_sngOperationalHours') {
            Object.keys(differences[key]).forEach((day) => {
              Object.keys(differences[key][day]).forEach((hourKey) => {
                let fieldTitle =
                  key === 'businessHours'
                    ? 'Business Hours'
                    : 'SNG Operation Hours'
                if (hourKey === 'o') {
                  fieldTitle = fieldTitle + ` : ${day} : Opening`
                } else {
                  fieldTitle = fieldTitle + ` : ${day} : Closing`
                }
                const field = (
                  <React.Fragment key={uniqueId()}>
                    <div className="changes__field">{fieldTitle}</div>
                    <div className="changes__field">
                      {parseValueToDisplay(differences[key][day][hourKey])}
                    </div>
                  </React.Fragment>
                )
                fieldsArray.push(field)
              })
            })
          } else if (key === 'meta_isSNG') {
            const field = (
              <React.Fragment key={uniqueId()}>
                <div className="changes__field">{'Is SnG Store'}</div>
                <div className="changes__field">
                  {parseValueToDisplay(differences[key])}
                </div>
              </React.Fragment>
            )
            fieldsArray.push(field)
          } else if (key === 'meta_sngCheckinRadius') {
            const field = (
              <React.Fragment key={uniqueId()}>
                <div className="changes__field">{'SnG Geo Checkin Radius'}</div>
                <div className="changes__field">
                  {parseValueToDisplay(differences[key])}
                </div>
              </React.Fragment>
            )
            fieldsArray.push(field)
          } else if (key === 'meta_sngCheckinBlocked') {
            const field = (
              <React.Fragment key={uniqueId()}>
                <div className="changes__field">{'SnG Check-in Blocked'}</div>
                <div className="changes__field">
                  {parseValueToDisplay(differences[key])}
                </div>
              </React.Fragment>
            )
            fieldsArray.push(field)
          } else if (key === 'meta_searchNBrowseEnabled') {
            const field = (
              <React.Fragment key={uniqueId()}>
                <div className="changes__field">
                  {'Search And Browse Enabled'}
                </div>
                <div className="changes__field">
                  {parseValueToDisplay(differences[key])}
                </div>
              </React.Fragment>
            )
            fieldsArray.push(field)
          } else {
            const field = (
              <React.Fragment key={uniqueId()}>
                <div className="changes__field">{key}</div>
                <div className="changes__field">
                  {parseValueToDisplay(differences[key])}
                </div>
              </React.Fragment>
            )
            fieldsArray.push(field)
          }
        })
      }
      return fieldsArray
    }
    return []
  }, [isOpen, action])

  return (
    <Popup
      className="preview__popup"
      show={isOpen}
      close={onCancel}
      heading={action === 'new' ? 'New Store' : 'Preview store changes'}
    >
      {action !== 'new' ? (
        <div id="store-diffing-table">
          <div className="changes__wrapper">
            {diffObject.length === 0 ? (
              <div className="changes_empty">
                <h5>No Changes to the store configuration</h5>
              </div>
            ) : (
              <>
                <div className="changes__header">Field</div>
                <div className="changes__header">Changes</div>
                {diffObject}
              </>
            )}
          </div>
          <div className="flex">
            {diffObject.length === 0 ? (
              <button type="button" className="button" onClick={onCancel}>
                Close
              </button>
            ) : (
              <button
                type="button"
                className="primary changes__submit button"
                onClick={onConfirmSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? '...' : 'Submit'}
              </button>
            )}
          </div>
        </div>
      ) : (
        <div id="new-store-confirm-dialog" className="changes__wrapper">
          <div className="changes_empty">
            <h5>
              Please review the form and ensure all input are filled correctly.
            </h5>
            <h5> Are you sure ?</h5>
          </div>
          <div className="form-footer ">
            <button
              type="button"
              className="primary button"
              onClick={onConfirmSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? '...' : 'Confirm Submit'}
            </button>
            <button type="button" className="button" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </Popup>
  )
}
