import React from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
} from '../../../../../components/Form'

import { getMessage } from '../../../../../lib/translator'

export default class CustomerFilters extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('customer.enterCustomerEmail')}
            placeholder={getMessage('customer.searchByEmail')}
            name="email"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['email'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisonFill: true,
            })}
          />
          <Input
            label={getMessage('customer.enterCustomerPhone')}
            placeholder={getMessage('customer.searchByPhone')}
            name="phone"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['phone'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisonFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('customer.filters.submittext')}</SubmitButton>
        <ClearButton>
          {getMessage('customer.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
