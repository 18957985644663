import React from 'react'
import { LeftArrowIcon, View } from '.'
import Loader from 'components/Loader'

const ButtonDisableStyle = {
  primary: {
    backgroundColor: 'rgba(102, 102, 102, 0.15)',
    color: 'rgba(153, 153, 153, 1)',
  },
  secondary: {
    backgroundColor: 'rgba(102, 102, 102, 0.15)',
    color: 'rgba(153, 153, 153, 1)',
  },
}

const ButtonStyle = {
  primary: {
    backgroundColor: '#1454B8',
    color: '#FFFFFF',
    fontWeight: 700,
  },
  outline: {
    backgroundColor: 'transparent',
    border: '1px solid #DADEE0',
    color: '#000000',
  },
  cancel: {
    backgroundColor: '#F3F5F6',
    color: '#000000',
  },
  danger: {
    backgroundColor: '#C10B3A',
    color: '#FFFFFF',
  },
  text: {
    padding: 0,
  },
  secondary: {
    backgroundColor: '#E8F0FD',
    color: '#1454B8',
    fontWeight: 700,
  },
}

export const Button = ({
  variant = 'primary',
  children,
  onClick,
  style = {},
  icon,
  alignIcon = 'right',
  width = '100%',
  height,
  fontSize = '18px',
  margin,
  disabled,
  loading,
  ...rest
}) =>
  loading ? (
    <View width={width}>
      <Loader size="sm" />
    </View>
  ) : (
    <button
      style={{
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize,
        width,
        height,
        margin,
        ...ButtonStyle[variant],
        ...(disabled ? ButtonDisableStyle[variant] : {}),
        ...(icon
          ? {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }
          : {}),
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {alignIcon === 'left' && icon}
      {children}
      {alignIcon === 'right' && icon}
    </button>
  )

export const BackButton = (props) => {
  return (
    <Button
      variant="text"
      icon={<LeftArrowIcon />}
      alignIcon="left"
      fontSize="14px"
      {...props}
    >
      Back
    </Button>
  )
}
export const FixedButton = ({ children, ...rest }) => {
  return (
    <View
      direction="column"
      position="fixed"
      data-name="fixedButton"
      style={{
        bottom: '12px',
        left: 0,
        background: 'white',
      }}
    >
      <Button {...rest}>{children}</Button>
    </View>
  )
}
