import React from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
} from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import API from '../../../../../lib/api'
import './style.css'

class CallForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      reason: getMessage('customer.details.call.option.orderEdit'),
      submitting: '',
      formError: '',
      otherOptionValue: '',
    }
    this.options = [
      getMessage('customer.details.call.option.lateDelivery'),
      getMessage('customer.details.call.option.orderEdit'),
      getMessage('customer.details.call.option.paymentReminder'),
      getMessage('customer.details.call.option.rescheduling'),
      getMessage('customer.details.call.option.orderConfirmation'),
      getMessage('customer.details.call.option.addressClarification'),
      getMessage('customer.details.call.option.callToInternalEmployees'),
      getMessage('customer.details.call.option.tripEnquiry'),
      getMessage('customer.details.call.option.surveyOnceShopped'),
      getMessage('customer.details.call.option.retention'),
      getMessage('customer.details.call.option.other'),
    ]
    this.handleSubmit = this.handleSubmit.bind(this)
    this.changeReason = this.changeReason.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  handleSubmit(_data) {
    const api = new API({ url: `/communication-service/call` })
    const params = {}
    params['number'] = this.props.number
    params['reason'] = this.state.reason
    params['transactional'] = false
    params['template'] = 'no-template'
    return api.post(params).then(
      () => {
        this.setState({ formError: '' })
      },
      error => {
        this.setState({ formError: error.message.split(':')[1] })
      }
    )
  }

  submitForm(data) {
    this.setState({
      submitting: true,
    })
    this.handleSubmit(data).then(() => {
      this.setState({
        submitting: false,
      })
      if (this.state.formError === '') {
        this.props.onSuccess('call')
      }
    })
  }

  changeReason(event) {
    this.setState({
      reason: event.target.value,
    })
  }

  render() {
    const { Form } = this.components
    return (
      <div className="CallForm">
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <select value={this.state.reason} onChange={this.changeReason}>
            {this.options.map(option => (
              <option key={option} value={option} onClick={this.changeReason}>
                {option}
              </option>
            ))}
          </select>

          {this.state.reason ===
          getMessage('customer.details.call.option.other') ? (
            <Input
              label="Enter reason"
              name="other"
              required
              {...this.generateStateMappers({
                stateKeys: ['otherOptionValue'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
            />
          ) : (
            ''
          )}

          <div className="form-buttons-container">
            <button
              type="button"
              className="primary button"
              disabled={this.state.submitting}
              onClick={this.submitForm}
            >
              {getMessage('customer.details.call.submitText.call')}
            </button>
          </div>
        </Form>
      </div>
    )
  }
}
export default CallForm
