import React, { Component } from 'react'
import InstoreConfigForm from './form'
import { getMessage } from '../../../../../lib/translator'
import { isExtensionEnabled, getStores } from '../../../../../lib/auth'
import Loader from '../../../../../components/Loader'

function modifyData(response) {
  const config = {}
  config['globalConfig'] = { ...response.globalConfig }
  if (response.storeSpecific) {
    config['storeSpecific'] = {}
    response['storeSpecific'].forEach(obj => {
      config['storeSpecific'][obj.storeId] = obj
    })
  }
  return config
}

class StoreSelect extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.props.onChange(e.target.value)
  }

  render() {
    const { stores, value } = this.props
    return (
      <select value={value || ''} onChange={this.onChange}>
        <option value="">
          {getMessage('orderprocessing.config.allStores')}
        </option>
        {stores.map(option => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    )
  }
}

export default class InstoreSettings extends Component {
  constructor(props) {
    super(props)
    let modifiedResponse = props.data.config
    if (modifiedResponse['storeSpecific']) {
      modifiedResponse = modifyData(props.data.config)
    }
    this.state = {
      storeId: null,
      stores: [],
      config: modifiedResponse,
      showLoader: false,
    }
    this.updateStoreId = this.updateStoreId.bind(this)
    this.updateConfig = this.updateConfig.bind(this)
  }

  updateStoreId(storeId) {
    this.setState({
      storeId: storeId,
    })
  }

  componentDidMount() {
    const multiStoresEnabled = isExtensionEnabled('MultiStoreSupport')
    // If multi store extension is enabled, get the stores data
    if (multiStoresEnabled) {
      this.setState({
        showLoader: true,
      })
      let stores = getStores()
      if (stores) {
        stores = stores
          .filter(store => store.hasPicking)
          .map(store => {
            return {
              text: store.name,
              value: store.id,
            }
          })
      }
      this.setState({
        stores: stores,
        showLoader: false,
      })
    }
  }

  updateConfig(updatedConfig) {
    this.setState({
      config: modifyData(updatedConfig),
    })
  }

  render() {
    const { data } = this.props
    const { storeId, stores, config, showLoader } = this.state
    return showLoader ? (
      <Loader />
    ) : (
      <div className="instore-settings">
        <div className="header-container">
          <h1 className="title">
            {getMessage('orderprocessing.config.heading')}
          </h1>
          <div className="header-actions-wrapper">
            {data.isStoreConfigurable && stores.length > 0 && (
              <StoreSelect
                onChange={this.updateStoreId}
                value={storeId}
                stores={stores}
              />
            )}
          </div>
        </div>
        <InstoreConfigForm
          storeId={storeId || null}
          value={
            storeId && config.storeSpecific && config.storeSpecific[storeId]
              ? config.storeSpecific[storeId]
              : config.globalConfig
          }
          data={data}
          config={this.state}
          updateConfig={this.updateConfig}
        />
      </div>
    )
  }
}
