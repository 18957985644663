import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const Draggable = ({ axis, index, style, moveCard, children }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const { top, bottom, left, right } = hoverBoundingRect
      const hoverMiddle =
        axis === 'vertical' ? (bottom - top) / 2 : (right - left) / 2
      const clientOffset = monitor.getClientOffset()
      const { x, y } = clientOffset
      const hoverClient = axis === 'vertical' ? y - top : x - left
      if (dragIndex < hoverIndex && hoverClient < hoverMiddle) {
        return
      }
      if (dragIndex > hoverIndex && hoverClient > hoverMiddle) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const opacity = isDragging ? 0 : 1

  return (
    <div data-handler-id={handlerId} style={{ ...style, opacity }} ref={ref}>
      {children}
    </div>
  )
}

export default Draggable
