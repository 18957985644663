import _ from 'lodash'
import React, { Component } from 'react'
import API from '../../../../lib/api'
import './style.css'
import { get } from 'lib/storage'
export default class CapacityPlanning extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileUploaded: false,
      uploadedFile: null,
      UpdateList: [],
      CreateList: [],
      SlotData: null,
      completedCount: 0,
      failedCount: 0,
      errors: [],
      jobCompleted: false,
    }
  }

  componentDidMount() {
    const store = this.getSelectedStore()
    this.setState({
      selectedStore: store.name,
    })
  }

  getSelectedStore() {
    const store = JSON.parse(get('stores'))
      .filter(s => s.id === parseInt(get('store'), 10))
      .map(({ id, name }) => ({ id, name }))
    if (store.length > 0) {
      return store[0]
    }
    return null
  }

  handleFileUpload = e => {
    const file = e.target.files[0]
    if (!file || !(file.type === 'text/csv' || file.name.match(/.+\.csv$/i))) {
      this.setState({
        fileUploadError: 'Only CSV file is allowed',
        UpdateList: null,
        CreateList: null,
        fileUploaded: false,
        SlotData: null,
      })
      return
    }
    const reader = new FileReader()
    reader.onload = this.onFileRead
    reader.readAsText(file)
    this.setState({
      uploadedFile: file,
      fileUploadError: null,
    })
  }
  onFileRead = e => {
    const store = this.getSelectedStore()
    const { result, errors } = this.processCSV(e.target.result)
    if (errors.length) {
      this.setState({ errors })
      return
    }
    const { pickupSlots, deliverySlots } = this.processRequiredSlot(result)
    if (pickupSlots.length === 0 && deliverySlots.length === 0) {
      this.setState({
        errors: this.state.errors.push(
          `can't process slots from uploaded file`
        ),
      })
    }
    const zones = this.getRequiredDeliveryZones(result)
    this.validateSlotsForStore({ store, pickupSlots, deliverySlots, zones })
      .then(data => {
        const updateList = this.markForUpdation(data.oldCapacity, result)
        const createList = this.markForCreation(data.oldCapacity, result)
        this.setState({
          UpdateList: updateList,
          CreateList: createList,
          fileUploaded: true,
          SlotData: data,
          errors: [],
        })
      })
      .catch(err => {
        if (err.length) {
          this.setState({
            errors: err,
            UpdateList: null,
            CreateList: null,
            fileUploaded: false,
            SlotData: null,
          })
        } else {
          this.setState({
            errors: ['Invalid file contents'],
            UpdateList: null,
            CreateList: null,
            fileUploaded: false,
            SlotData: null,
          })
        }
      })
  }

  getCapacityKey = item => {
    const dateOrDay = item.date || item.day
    if (item.zone) {
      return `${item.orderType}_${item.zone?.name ? item.zone.name : item.zone}_${this.slotToStartTimeString(item.slot)}_${item.cutOffTime}_${dateOrDay.toUpperCase()}`
    } else {
      return `${item.orderType}_${this.slotToStartTimeString(item.slot)}_${item.cutOffTime}_${dateOrDay.toUpperCase()}`
    }
  }

  markForUpdation = (oldCapacity, newCapacity) => {
    return newCapacity.reduce((acc, item) => {
      const key = this.getCapacityKey(item)
      if (oldCapacity[key]) {
        const toUpdate = oldCapacity[key]
        if (toUpdate.capacity === item.capacity) {
          return acc
        }
        toUpdate.capacity = item.capacity
        acc.push(toUpdate)
        return acc
      }
      return acc
    }, [])
  }

  markForCreation = (oldCapacity, newCapacity) => {
    return newCapacity.filter(item => {
      return !oldCapacity[this.getCapacityKey(item)]
    })
  }

  updateCapacity = updateObj => {
    const body = {
      capacity: updateObj.capacity,
      cutOffTime: updateObj.cutOffTime,
      orderType: updateObj.orderType,
      organizationId: updateObj.organizationId,
      slotId: updateObj.slotId,
      storeId: updateObj.storeId,
      zoneId: updateObj.zoneId,
    }
    if (updateObj.day) {
      body.day = updateObj.day
    }
    if (updateObj.date) {
      body.date = updateObj.date
    }
    return new API({
      url: `/logistics-service/zone-capacity/${updateObj.id}`,
    }).put(body)
  }

  addCapacity = createObj => {
    const slots =
      createObj.orderType === 'DELIVERY'
        ? this.state.SlotData.deliverySlots
        : this.state.SlotData.pickupSlots
    let slot = null
    for (const key in slots) {
      if (slots[key].startTime === this.slotToStartTimeString(createObj.slot)) {
        slot = slots[key]
        break
      }
    }
    const opts = {
      storeId: this.getSelectedStore().id,
      orderType: createObj.orderType,
      slotId: slot.id,
      cutOffTime: createObj.cutOffTime,
      capacity: createObj.capacity,
    }

    if (createObj.date) {
      opts.date = createObj.date
    }

    if (createObj.day) {
      opts.day = createObj.day
    }

    if (createObj.orderType === 'DELIVERY') {
      for (const key in this.state.SlotData.zones) {
        if (
          (this.state.SlotData.zones[key].name || '').toLowerCase() ===
          createObj.zone.toLowerCase()
        ) {
          opts.zoneId = this.state.SlotData.zones[key].id
        }
      }
    }
    return new API({ url: '/logistics-service/zone-capacity' }).post(opts)
  }

  validateSlotsForStore = ({ store, pickupSlots, deliverySlots }) => {
    const result = {}
    const errors = []

    return this.fetchSlotsForStore(store.id, 'PICKUP')
      .then(({ data, code }) => {
        if (code !== 200) {
          errors.push('failed to fetch pickup slots for store')
          return
        }

        const availableSlots = data.slot.map(sl => sl.startTime)
        const missingSlots = pickupSlots.filter(
          requiredSlot => !availableSlots.includes(requiredSlot)
        )

        if (missingSlots.length) {
          errors.push(`Missing Pickup slots ${missingSlots.join(',')}`)
        } else {
          result.pickupSlots = data.slot.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        }
      })
      .then(() => this.fetchSlotsForStore(store.id, 'DELIVERY'))
      .then(({ data, code }) => {
        if (code !== 200) {
          errors.push('failed to fetch delivery slots for store')
          return
        }
        const availableSlots = data.slot.map(sl => sl.startTime)
        const missingSlots = deliverySlots.filter(
          requiredSlot => !availableSlots.includes(requiredSlot)
        )
        if (missingSlots.length) {
          errors.push('Missing delivery slots')
        } else {
          result.deliverySlots = data.slot.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        }
      })
      .then(() => this.fetchDeliveryZones(store.id))
      .then(({ data, code }) => {
        if (code !== 200) {
          errors.push('failed to fetch delivery zones')
          return
        }
        result.zones = data.deliveryarea.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
      })
      .then(() => this.fetchStoreCapacity(store.id))
      .then(({ data, code }) => {
        if (code !== 200) {
          errors.push('can not fetch old slot capacity')
        }

        result.oldCapacity = data.capacityPlanning
          .map(item => {
            item.zone = result.zones[item.zoneId]
            if (item.orderType === 'DELIVERY') {
              item.slot = result.deliverySlots[item.slotId]
            } else if (item.orderType === 'PICKUP') {
              item.slot = result.pickupSlots[item.slotId]
            }
            return item
          })
          .reduce((acc, item) => {
            const key = this.getCapacityKey(item)
            acc[key] = item
            return acc
          }, {})
      })
      .then(() => {
        if (errors.length) {
          return Promise.reject(errors)
        }
        return result
      })
  }

  processCSV = file => {
    const errors = []
    const daysMap = {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday',
    }
    const lines = file.split('\n').filter(Boolean)
    const header = lines[0]
      .toLowerCase()
      .split(',')
      .map(cell => {
        if (cell.includes('cutoff')) { return 'cutOffTime' }
        if (cell.includes('type')) { return 'orderType' }
        if (cell.includes('capacity')) { return 'capacity' }
        if (cell.includes('slot')) { return 'slot' }
        if (cell.includes('day') || cell.includes('date')) { return 'dayOrDate' }
        if (cell.includes('area') || cell.includes('zone')) { return 'zone' }

        return cell
      })
    const body = lines.slice(1).map(line => line.split(','))
    const result = body.map(line => {
      return line.reduce((acc, cell, i) => {
        if (header[i] === 'orderType') {
          acc[header[i]] = cell.toUpperCase()
        } else if (header[i] === 'dayOrDate') {
          if (cell.includes('any') || cell.includes('all')) {
            acc['day'] = 'ALLDAYS'
          } else if (Object.keys(daysMap).includes(cell.toLowerCase())) {
            acc['day'] = daysMap[cell.toLowerCase()]
          } else if (/\d{2}\/\d{2}\/\d{4}/.test(cell)) {
            const [day, month, year] = cell.split('/')
            const date = new Date(`${year}-${month}-${day}`)

            if (isFinite(date.getTime())) {
              acc['date'] = date
                .toISOString()
                .replace('T00:00:00.000Z', 'T00:00:00Z')
            } else {
              acc[header[i]] = cell
              errors.push(`"Date" is invalid: "${cell}" (row ${i + 2})`)
            }
          } else {
            acc[header[i]] = cell
            errors.push(`"Date" or "Day" is invalid: "${cell}" (row ${i + 2})`)
          }
        } else if (header[i] === 'capacity') {
          if (isFinite(parseInt(cell, 10))) {
            acc[header[i]] = parseInt(cell, 10)
          } else {
            acc[header[i]] = cell
            errors.push(
              `"Capacity" expected to be integer, got "${cell}" instead (row ${i +
              2})`
            )
          }
        } else if (header[i] === 'cutOffTime') {
          if (isFinite(parseInt(cell, 10))) {
            acc[header[i]] = `${parseInt(cell, 10)
              .toString()
              .padStart(2, '0')}:00:00`
          } else {
            acc[header[i]] = cell
            errors.push(
              `"Cutoff Time" expected to be integer, got "${cell}" instead (row ${i +
              2})`
            )
          }
        } else {
          acc[header[i]] = cell
        }
        return acc
      }, {})
    })
    result.reduce((accumulator, eachResult) => {
      if (accumulator.find(object => _.isEqual(_.omit(object, ['capacity']), _.omit(eachResult, ['capacity'])))) {
        errors.push("DUPLICATE capacity rules found in csv")
      } else {
        accumulator.push(eachResult);
      }
      return accumulator;
    }, []);

    return { result, errors }
  }

  slotToStartTimeString = slot => {
    if (typeof slot === 'object') {
      return slot.startTime
    }
    const hours = slot.replace('T', '').padStart(2, '0')
    return `${hours}:00:00`
  }

  processRequiredSlot = jsonResult => {
    const pickupSlots = jsonResult
      .filter(row => row.orderType === 'PICKUP')
      .map(row => row.slot)
      .filter((slot, i, array) => array.indexOf(slot) === i)
      .sort()
      .map(slot => this.slotToStartTimeString(slot))

    const deliverySlots = jsonResult
      .filter(row => row.orderType === 'DELIVERY')
      .map(row => row.slot)
      .filter((slot, i, array) => array.indexOf(slot) === i)
      .sort()
      .map(slot => this.slotToStartTimeString(slot))

    return {
      pickupSlots,
      deliverySlots,
    }
  }

  fetchSlotsForStore = (storeId, type) => {
    return new API({ url: '/order-service/slot' }).get({
      paginated: 'false',
      storeId: storeId,
      orderType: type,
    })
  }

  fetchStoreCapacity = storeId => {
    return new API({ url: `/logistics-service/zone-capacity` }).get({
      paginated: 'false',
      storeId: storeId,
    })
  }

  fetchDeliveryZones = storeId => {
    return new API({ url: `/logistics-service/delivery-area` }).get({
      paginated: 'false',
      storeId: storeId,
    })
  }

  getRequiredDeliveryZones = jsonResult => {
    return jsonResult
      .map(row => {
        return (row.zone || '').toLowerCase().replace(/\s+/g, ' ')
      })
      .filter(zone => !!zone.trim())
      .filter((zone, i, array) => array.indexOf(zone) === i)
      .sort()
  }

  handleApplyButtonClick = () => {
    this.setState({
      showProcessingLabel: true,
    })
    const allPromise = []
    this.state.UpdateList.forEach(item => {
      const prom = this.updateCapacity(item)
        .then(() => {
          this.setState(state => {
            return { completedCount: state.completedCount + 1 }
          })
        })
        .catch(() => {
          this.setState(state => {
            return { failedCount: state.failedCount + 1 }
          })
        })
      allPromise.push(prom)
    })

    this.state.CreateList.forEach(item => {
      const prom = this.addCapacity(item)
        .then(() => {
          this.setState(state => {
            return { completedCount: state.completedCount + 1 }
          })
        })
        .catch(() => {
          this.setState(state => {
            return { failedCount: state.failedCount + 1 }
          })
        })
      allPromise.push(prom)
    })

    Promise.all(allPromise)
      .then(() => {
        this.setState({
          showProcessingLabel: false,
          jobCompleted: true,
          sucessMessage: 'Capacities Updated',
        })
      })
      .catch(err => {
        this.setState({
          showProcessingLabel: false,
          errors: ['failed to update capacities', err],
        })
      })
  }

  reset = () => {
    this.setState({
      showProcessingLabel: false,
      errors: [],
      fileUploaded: false,
      uploadedFile: null,
      UpdateList: [],
      CreateList: [],
      SlotData: null,
      completedCount: 0,
      failedCount: 0,
      jobCompleted: false,
    })
  }

  render() {
    const { selectedStore, fileUploadError, errors } = this.state

    return (
      <div className="CapacityModal">
        <div
          className="modal__container"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-1-title"
        >
          <div className="modal__content" id="modal-1-content">
            <h3>Import Capacity for {selectedStore}</h3>
            <input
              data-testid="upInput"
              type="file"
              onChange={this.handleFileUpload}
              name="fileupload"
            />
            {fileUploadError && (
              <div className="error-panel">{fileUploadError}</div>
            )}
            {errors.length > 0 && (
              <div className="error-panel">
                {errors.map((error, i) => (
                  <div className="error-msg" key={`error_${i}`}>
                    {error}
                  </div>
                ))}
              </div>
            )}
            {this.state.fileUploaded && (
              <div>
                <div>
                  <strong>Total Records to Update</strong> :{' '}
                  {this.state.UpdateList.length}{' '}
                </div>
                <div>
                  <strong>Total Records to Create</strong> :{' '}
                  {this.state.CreateList.length}
                </div>
                <div className="col-lg-5">
                  {!this.state.showProcessingLabel &&
                    !this.state.jobCompleted && (
                      <input
                        type="button"
                        className="primary button"
                        value="Apply Changes"
                        onClick={this.handleApplyButtonClick}
                      />
                    )}
                </div>
              </div>
            )}
            {(this.state.showProcessingLabel || this.state.jobCompleted) && (
              <div>
                <div>
                  <strong>Total Records completed</strong> :{' '}
                  {this.state.completedCount}{' '}
                </div>
                <div>
                  <strong>Total Records failed </strong> :{' '}
                  {this.state.failedCount}
                </div>
              </div>
            )}
            {this.state.jobCompleted && (
              <div>
                <p>{'completed'}</p>
                <input
                  type="button"
                  onClick={this.reset}
                  value={'New Request'}
                  style={{ width: '150px' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
