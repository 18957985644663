import React from 'react'
import { Button, View } from '../ui'

export const Home = ({ onNewOrder, onScanOrder }) => {
  return (
    <View direction="column" style={{ gap: '24px' }}>
      <Button onClick={onNewOrder}>+ New pre-order</Button>
      <Button onClick={onScanOrder}>Scan/Search for order</Button>
    </View>
  )
}
