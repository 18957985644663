import React from 'react'
import {
  BaseForm,
  Input,
  ProductSearch,
  CategorySearch,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import Image from '../../../../components/Image'

export default class ProductForm extends BaseForm {
  constructor(props) {
    super(props)
    this.delete = this.delete.bind(this)
    this.store = 0
  }

  delete() {
    this.props.onDelete()
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const { props } = this
    const { options, value } = props
    const product = value && value.product
    const category = value && value.category
    const entity = options && options.entityName
    return (
      <div className="BrandForm stockForm">
        <Form>
          {props.method === 'add' ? (
            entity === 'product' ? (
              <ProductSearch
                name="name"
                required
                {...this.generateStateMappers({
                  stateKeys: ['product'],
                  loseEmphasisOnFill: true,
                })}
              />
            ) : (
              <CategorySearch
                label={options.entityName}
                placeholder={`Search a ${options.entityName} to add`}
                name="name"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ['category'],
                  loseEmphasisOnFill: true,
                })}
                withOptions
              />
            )
          ) : null}
          <div className="flex">
            {product ? (
              <Image size="md" src={product.images && product.images[0]} />
            ) : category ? (
              <Image size="md" src={category.image} />
            ) : null}
            {product ? (
              <h4>
                {product.brand ? (
                  <React.Fragment>
                    <span className="bold">{product.brand.name}</span>{' '}
                    {product.name}
                  </React.Fragment>
                ) : (
                  product.name
                )}
              </h4>
            ) : category ? (
              <h4>{category.name}</h4>
            ) : null}
          </div>
          <Input
            label={getMessage('store.stock.overrides.storeRouting')}
            placeholder="00"
            min={1}
            name="bulkOrderThreshold"
            type="number"
            {...this.generateStateMappers({
              stateKeys: ['bulkOrderThreshold'],
              loseEmphasisOnFill: true,
            })}
          />
          <div className="form-fields">
            <Input
              label={getMessage('store.min.stock.count')}
              placeholder="00"
              name="stockBuffer"
              type="number"
              min={1}
              {...this.generateStateMappers({
                stateKeys: ['stockBuffer'],
                loseEmphasisOnFill: true,
              })}
              transformValidationHook={(DomNode, validationObj) => {
                const value = DomNode.value
                if (value === '') {
                  Object.keys(validationObj).map(key => {
                    validationObj[key] = key === 'valid'
                    return null
                  })
                }
                return validationObj
              }}
            />
            <Input
              label={getMessage('store.max.purchaseable.qty')}
              placeholder="00"
              min={1}
              name="maxPurchasableStock"
              type="number"
              {...this.generateStateMappers({
                stateKeys: ['maxPurchasableStock'],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className={`form-actions ${props.method}`}>
            <SubmitButton>{getMessage('brand.form.submitText')}</SubmitButton>
            <CancelButton>{getMessage('brand.form.cancelText')}</CancelButton>
          </div>
        </Form>
      </div>
    )
  }
}
