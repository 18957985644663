import { PlainInput, PlainSelect } from 'components/Form'
import React, { useState } from 'react'
import { Text, View, LeftArrowIcon, KeyboardIcon, FullScreen } from '../ui'
import { CustomScanner } from '../components/Scanner'
import Checkbox from 'components/Form/Inputs/Checkbox'

export const FormikScanPage = ({
  onBack,
  children,
  title,
  footer,
  onNewScanResult,
}) => {
  const [showManualInput, setShowManualInput] = useState(false)

  return showManualInput ? (
    children
  ) : (
    <FullScreen
      direction="column"
      justifyContent="start"
      position="fixed"
      background="black"
    >
      <View direction="column" justifyContent="spac">
        <View padding="16px">
          <View position="fixed" display="block">
            <LeftArrowIcon
              onClick={onBack}
              fill="white"
              rotate={180}
              data-testid="back"
            />
          </View>
          <Text color="white">{title}</Text>
        </View>
        <CustomScanner
          fps={5}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
        <View gap="12px" onClick={() => setShowManualInput(true)} padding={16}>
          <Text color="white">{footer}</Text>
          <KeyboardIcon />
        </View>
      </View>
    </FullScreen>
  )
}

export const FormikInput = ({ field, meta, form, ...rest }) => {
  return (
    <PlainInput
      type="text"
      data-testid={field.name}
      style={{ width: '100%', ...rest.style }}
      error={form.errors[field.name]}
      {...field}
      {...meta}
      {...rest}
    />
  )
}

export const FormikCustomerInput = ({ field, meta, form, ...rest }) => {
  return (
    <PlainInput
      type="text"
      data-testid={field.name}
      style={{ width: '100%', ...rest.style }}
      error={form.touched[field.name] && form.errors[field.name]}
      {...field}
      {...meta}
      {...rest}
    />
  )
}

export const FormikSelect = ({ field, meta, form, ...rest }) => {
  return (
    <PlainSelect
      data-testid={field.name}
      style={{ width: '100%', ...rest.style }}
      error={form.errors[field.name]}
      {...field}
      {...meta}
      {...rest}
    />
  )
}

export const FormikCheckBox = ({ field, form, ...rest }) => {
  return (
    <Checkbox
      testid={field.name}
      error={form.errors[field.name]}
      controlled
      name={field.name}
      value={field.value}
      onChange={() => form.setFieldValue(field.name, !field.value)}
      {...rest}
    />
  )
}
