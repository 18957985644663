import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthenticatedPage from 'containers/AuthenticatedPage';
import { getMessage } from 'lib/translator';
import { BaseForm } from 'components/Form';
import Dialog from 'components/Popup/Dialog'
import API from 'lib/api';
import PrimaryInputs from './PrimaryInputs';
import OfferSchedulesInput from './OfferSchedulesInput';
import './style.scss';

class OfferCampaignForm extends BaseForm {

  constructor(props) {
    super(props);
    this.state = {
      errorDialog: null
    }
    this.addOfferSlot = this.addOfferSlot.bind(this);
    this.removeOfferSlotByIndex = this.removeOfferSlotByIndex.bind(this);
  }

  componentDidMount() {
    if (this.props.router.match.params?.id) {
      /* assign existing campaign values to the form */
    } else {
      const offerSchedule = {
        id: 1,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        offers: []
      };
      this.setState(prev => ({
        values: {
          ...prev.values,
          schedules: [offerSchedule]
        }
      }));
    }
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
    })
    const validFromDate = new Date(formData.validFrom);
    const validTillDate = new Date(formData.validTill);

    // Destructure 'id' to exclude it, spread the rest
    // eslint-disable-next-line
    const offerSchedules = formData.schedules.map(({id, ...scheduleWithoutId}) => {
      const offerIds = scheduleWithoutId.offers.split(',').map(offerId => ({offerID: Number(offerId)}));
      return {
        ...scheduleWithoutId,
        offers: offerIds
      }
    });

    const data = Object.assign({}, {
      ...formData,
      validFrom: validFromDate.toISOString().split('.')[0] + "Z",
      validTill: validTillDate.toISOString().split('.')[0] + "Z",
      schedules: offerSchedules,
      status: "ENABLED"
    })
    const api = new API({ url: '/offer-service/campaigns' })
    api.post(data)
      .then((res) => {
        if (res) {
          this.props.history.push(`/marketing/offer-campaigns`)
        }
      })
      .catch(err => {
        if (err.code === 400 && err.message) {
          this.setState({ errorDialog: err.message });
        }
      })
      .finally(() => this.setState({ submitting: false }))
  }

  addOfferSlot(e) {
    e.preventDefault()
    this.setState(prev => ({
      values: {
        ...prev.values,
        schedules: [
          ...prev.values.schedules,
          {
            id: prev.values.schedules.length + 1,
            offers: null,
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null
          }
        ]
      }
    }))
  }

  removeOfferSlotByIndex(index) {
    this.setState(prev => ({
      values: {
        ...prev.values,
        schedules: prev.values.schedules.filter((_, i) => i !== index)
      }
    }))
  }

  render() {
    const { menu } = this.props;
    const { SubmitButton } = this.buttons
    const { Form } = this.components

    return (
      <AuthenticatedPage className="offer-campaign-form-page" menu={menu}>
        <div className="flex-around">
          <h1 className="title heading">
            <strong>
              {getMessage('offer.campaigns.listing.add.offerCampaign')}
            </strong>
          </h1>
        </div>
        <Form className='offer-campaign-form'>

          <PrimaryInputs generateStateMappers={this.generateStateMappers}/>

          <OfferSchedulesInput
            generateStateMappers={this.generateStateMappers}
            formValues={this.state.values}
            addOfferSlot={this.addOfferSlot}
            removeOfferSlotByIndex={this.removeOfferSlotByIndex}
          />

          <div className='form-buttons'>
            <SubmitButton>{getMessage('offer.submit')}</SubmitButton>
            <button className="button secondary" onClick={() => this.props.history.push(`/marketing/offer-campaigns`)}>
              {getMessage('offer.cancel')}
            </button>
          </div>
        </Form>

        {
          this.state.errorDialog && (
            <Dialog
              className="error-dialog"
              show={!!this.state.errorDialog}
              title="Server Error"
              information={this.state.errorDialog}
              close={() => this.setState({ errorDialog: null })}
              closeText={getMessage('dialog.okText')}
            />
          )
        }

      </AuthenticatedPage>
    )
  }
}

export default withRouter(({ match, ...props }) => (
  <OfferCampaignForm router={{ match }} {...props} />
))
