import React, { Component } from 'react'
import { getMessage } from '../../../../lib/translator'
import API from '../../../../lib/api'
import Table, { Header, Cell, Row } from '../../../../components/Table'
import { BaseForm, Input } from '../../../../components/Form'
import { Popup, Dialog } from '../../../../components/Popup'
import Loader from '../../../../components/Loader'
import MediaPagination from '../../commonMedia/pagination'
import { throwError, hideErrorDialog } from '../../commonMedia'
import CreativeReqDetail from './creativeReqDetail'
import './style.css'

const APIurl = '/martech-creative-service/creative-requirement/'

function getMobileView() {
  return window.screen.width <= 480
}

class CreativeReqFilters extends BaseForm {
  constructor(props) {
    super(props)
  }

  toggleCreativeReqDetailBox = (status) => {
    this.props.toggleCreativeReqDetailBox(status)
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components

    return (
      <Form className='creative-req-filter-form'>
        <div className="form-fields">
          <Input
            label={getMessage('media.creativeReqManagement.header.name')}
            name="name"
            className="section-row-input"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>

        <div className='creativeReq-btns'>
          <SubmitButton>{getMessage('brand.filters.submitText')}</SubmitButton>
          <ClearButton>
            {getMessage('brand.filters.clearFiltersText')}
          </ClearButton>
          <button className="primary button add-creativereq" onClick={() => {
            this.toggleCreativeReqDetailBox(1)
          }}>
            <span className="text">+ {getMessage('media.add.text')}</span>
          </button>
        </div>
      </Form>
    )
  }
}

function CreativeReqTable(props){
  const { creativeReqList, creativeReqListDialog, selectCreativeReq, toggleCreativeReqDetailBox } = props
  return (
    <Table>
      <Header>
        <Cell></Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.id')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.name')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.type')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.height')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.width')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.size')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.format')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.action')}</Cell>
      </Header>
      {creativeReqList.map((creativeReqItem, creativeReqIndex) => (
        <Row key={creativeReqIndex} >
          <Cell>
            <button
              data-testid = {'button_' + creativeReqIndex}
              id = {'button_' + creativeReqIndex}
              name = {'button_' + creativeReqIndex}
              onClick = {() => {
                selectCreativeReq(creativeReqItem)
              }}
              className={`checkbox_btn ${creativeReqListDialog?.id === creativeReqItem?.id ? "button-checked" : "button-unchecked"}`}
            />
          </Cell>
          <Cell>
            <small>{creativeReqItem.id}</small>
          </Cell>
          <Cell>
            <small>{creativeReqItem.name}</small>
          </Cell>
          <Cell>
            <small>{creativeReqItem.required_creative_type}</small>
          </Cell>
          <Cell>
            <small>{creativeReqItem.height}</small>
          </Cell>
          <Cell>
            <small>{creativeReqItem.width}</small>
          </Cell>
          <Cell>
            <small>{creativeReqItem.size}</small>
          </Cell>
          <Cell>
            <small>{creativeReqItem.supported_formats.join(" / ")}</small>
          </Cell>
          <Cell>
            <small><a data-testid = {'edit_button_' + creativeReqIndex} onClick={() => { toggleCreativeReqDetailBox(1, creativeReqItem) }} >{getMessage('media.edit.text')}</a></small>
          </Cell>
        </Row>
      ))}
    </Table>
  )
}

export default class MediaCreativeReqList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileView: getMobileView(),
      showLoader: false,
      paging: {},
      creativeReqList: [],
      creativeReqDetailDialog: {
        shown: false,
        loading: false,
        data: null
      },
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      }
    }
    this.mobileView = this.mobileView.bind(this)
    this.toggleCreativeReqDetailBox = this.toggleCreativeReqDetailBox.bind(this)
    this.filterName = this.filterName.bind(this)
    this.changePage = this.changePage.bind(this)
    this.handleCreativeReqDetailSubmit = this.handleCreativeReqDetailSubmit.bind(this)
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
    this.getCreativeReqList()
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
    window.removeEventListener('resize', this.mobileView, false)
  }

  getCreativeReqList(_page, filterData) {
    this.setState({
      showLoader: true
    })
    this.api = new API({ url: APIurl})
    this.api.get({
      page: _page || 0,
      pageSize: 10,
      name: filterData?.name
    }).then(response => {
      this.setState({
        creativeReqList: response?.data?.content,
        paging: {
          count: response?.data?.count,
          offset: response?.data?.offset,
          limit: response?.data?.limit
        },
        showLoader: false
      })
    }).catch(error => {
      throwError(this, error)
    })
  }

  filterName(filterData) {
    this.getCreativeReqList(0, filterData)
  }

  changePage(_page) {
    this.getCreativeReqList(_page)
  }

  toggleCreativeReqDetailBox(status, data) {
    this.setState({
      creativeReqDetailDialog: {
        ...this.state.creativeReqDetailDialog,
        loading: false,
        shown: status === 1,
        data
      }
    })
  }

  handleCreativeReqDetailSubmit(formData){
    this.setState({
      creativeReqDetailDialog: {
        ...this.state.creativeReqDetailDialog,
        loading: true
      }
    })

    if(!formData?.id){
      this.api = new API({ url: APIurl })
      this.api.post(formData).then(() => {
        this.setState({
          creativeReqDetailDialog: {
            ...this.state.creativeReqDetailDialog,
            loading: false
          }
        })
        this.toggleCreativeReqDetailBox(2)
        this.getCreativeReqList()
      }).catch(error => {
        this.setState({
          creativeReqDetailDialog: {
            ...this.state.creativeReqDetailDialog,
            loading: false
          }
        })
        throwError(this, error)
      })
    }else{
      this.api = new API({ url: APIurl + formData.id})
      this.api.put(formData).then(() => {
        this.setState({
          creativeReqDetailDialog: {
            ...this.state.creativeReqDetailDialog,
            loading: false
          }
        })
        this.toggleCreativeReqDetailBox(2)
        this.getCreativeReqList()
      }).catch(error => {
        this.setState({
          creativeReqDetailDialog: {
            ...this.state.creativeReqDetailDialog,
            loading: false
          }
        })
        throwError(this, error)
      })
    }
  }

  render() {
    const { showLoader, paging, creativeReqList, creativeReqDetailDialog } = this.state
    const { creativeReqListDialog } = this.props
    return (
      <div>
        <CreativeReqFilters
          toggleCreativeReqDetailBox={this.toggleCreativeReqDetailBox}
          onSubmit={this.filterName}
          onClear={() => { this.getCreativeReqList() }}
        ></CreativeReqFilters>

        {showLoader ? (
          <Loader />
        ) : (
          <div>
            <CreativeReqTable
              creativeReqList={creativeReqList}
              creativeReqListDialog={creativeReqListDialog}
              selectCreativeReq={this.props.selectCreativeReq}
              toggleCreativeReqDetailBox={this.toggleCreativeReqDetailBox}
            ></CreativeReqTable>
            <MediaPagination
              paging={paging}
              changePage={this.changePage}
            ></MediaPagination>
          </div>
        )}

        <Popup
          show={creativeReqDetailDialog?.shown}
          className="creativeReq-box"
          heading={getMessage(`media.creativeReqManagement.detail.title.${creativeReqDetailDialog?.data ? 'edit':'add'}`)}
          close={() => { this.toggleCreativeReqDetailBox(2) }}
        >
          <CreativeReqDetail
            creativeReqDetailDialog={creativeReqDetailDialog}
            onSubmit={this.handleCreativeReqDetailSubmit}
            onCancel={() => { this.toggleCreativeReqDetailBox(2) }}
          ></CreativeReqDetail>
        </Popup>

        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={this.state.errorDialog.message}
            close={() => (hideErrorDialog(this))}
            closeText={getMessage('dialog.okText')}
          />
        )}
      </div>
    )
  }
}
