import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { DropDown, DropDownItem, ICONS } from 'components/DropDown'
import Loader from 'components/Loader'
import { Checkbox } from 'components/Form'
import API from 'lib/api'
import Table, { Header, Cell, Row } from '../../../../../components/Table'
import './style.css'
import InvoiceEvochers from '../InvoiceEvouchers'
import Image from '../../../../../../src/components/Image/index'
import { getOnlyTime, getPrintableTime } from '../../../../../lib/datetime'
import { getMessage } from 'lib/translator'
import { Dialog } from 'components/Popup'
import EvoucherDialog from './EvoucherDialog'
import ResendDialog from './ResendDialog'

export const RenderRow = (item) => {
  return (
    <>
      <Cell>
        {item.denominations.map((denomination, indexDenomination) => (
          <p key={indexDenomination}>
            <small className="text-muted">
              {currencyFormat(denomination.amount)}{' '}
            </small>
          </p>
        ))}
      </Cell>
      <Cell>
        {item.denominations.map((denomination, i) => (
          <p key={i}>
            <small className="text-muted">{denomination.quantity} </small>
          </p>
        ))}
      </Cell>

      <Cell key={item.amount}>
        <small className="text-muted">{currencyFormat(item.amount)}</small>
      </Cell>
    </>
  )
}

const resendLabel = 'customerSupport.egifting.resend'
const currencyFormat = (data) => {
  return new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
  }).format(data)
}

const renderAccessTime = (item) => (
  <>
    <small className="text-muted">
      {(item.accessedTime &&
        getPrintableTime(item.accessedTime).split(', ')[0]) ||
        'N/A'}
    </small>
    <small className="text-muted">
      {(item.accessedTime && getOnlyTime(item.accessedTime)) || ''}
    </small>
  </>
)

const renderAmountRow = (selectReceivers, setResendDialog, data) => {
  return (
    <Row>
      {data.isB2B ? (
        <>
          <Cell>
            <button
              disabled={selectReceivers.length === 0}
              data-testid="resend-multiple-btn"
              className="primary button"
              type="button"
              onClick={() => setResendDialog(true)}
            >
              {getMessage(resendLabel)}
            </button>
          </Cell>
          {RenderCell(['', '', '', 'Total'])}
          <Cell>{currencyFormat(Number(data.amount))}</Cell>
        </>
      ) : (
        <>
          <Cell>
            <button
              disabled={selectReceivers.length === 0}
              data-testid="resend-multiple-btn"
              className="primary button"
              type="button"
              onClick={() => setResendDialog(true)}
            >
              {getMessage(resendLabel)}
            </button>
          </Cell>
          {RenderCell(['', '', '', '', '', '', '', '', 'Total'])}
          <Cell>{currencyFormat(Number(data.amount))}</Cell>
        </>
      )}
    </Row>
  )
}

const cellHeader = [
  'Image',
  getMessage('customerSupport.egifting.details.receiver.name'),
  'Delivery Method',
  'Delivery Schedule',
  'Date Sent',
  'Access Time',
  'Delivery Status',
  'Status',
  'Amount',
  getMessage('customerSupport.payments.header.action'),
]

const businessHeader = [
  getMessage('category.header.image'),
  getMessage('order.filters.batch.heading'),
  getMessage('customerSupport.egifting.denomination'),
  getMessage('offer.quantity'),
  getMessage('operations.dashboard.total'),
]

const RenderHeader = (data, successModal, setSuccessModal) => {
  return (
    <div className="flex-around section-title">
      <h3>
        {`Items in order`}
        <span className="text-muted"> ({data?.extendedGifts?.length})</span>
      </h3>
      <Dialog
        data-testid="resend-success"
        className="success no-padding"
        show={successModal}
        close={() => setSuccessModal(false)}
        message={getMessage('customerSupport.egifting.resend.success')}
        closeText="OK"
      />
    </div>
  )
}

const RenderCell = (items) => {
  return items.map((item, ind) => <Cell key={ind}>{item}</Cell>)
}

const getResendDetails = async (item, setResendHistory) => {
  try {
    const api = new API({
      url: `/gifting-admin-service/receiver/${item.id}/resend`,
    })

    const response = await api.get()
    setResendHistory(response?.data)
  } catch (e) {
    throw new Error(e)
  }
}

const renderDeliveryMethod = (item, index) => (
  <>
    <Cell>
      <div data-testid={`resend-popup-${index}`}>
        <small>{item.name}</small>
      </div>
    </Cell>
    <Cell>
      <div>
        <small>{item.deliveryMethod}</small>
      </div>
    </Cell>
  </>
)

const EvoucherDeatils = ({ data }) => {
  const [successModal, setSuccessModal] = useState(false)
  const [showResendModal, setShowResendModal] = useState({
    shown: false,
    data: {},
  })
  const [resendHistory, setResendHistory] = useState([])
  const [selectReceivers, setSelectReceivers] = useState([])
  const [isLoading] = useState(false)
  const [resendDialog, setResendDialog] = useState(false)
  const haveReceivers = data.isB2B
    ? data.extendedGifts
    : data?.extendedGifts?.reduce(
        (acc, curr) =>
          Array.isArray(curr.receivers) ? curr.receivers.concat(acc) : [],
        []
      )
  const noOfReceivers = haveReceivers.length

  const keepEnabled = (filterData) =>
    ['CHARGED', 'SENT', 'GIFT_STATUS_COMPLETED'].includes(filterData.status)

  return (
    <div className="order-items-view" data-testid="EvoucherDetails">
      {RenderHeader(data, successModal, setSuccessModal)}
      {isLoading ? (
        <Loader />
      ) : (
        <Table>
          <Header>
            <Cell className="align-center">
              <Checkbox
                controlled
                testid="resend-check-all"
                name="receiver-checkbox"
                value={noOfReceivers === selectReceivers.length}
                onChange={(value) => {
                  if (value) {
                    const receiverData = data.isB2B
                      ? data?.extendedGifts.filter((filteredData) =>
                          keepEnabled(filteredData)
                        )
                      : data?.extendedGifts
                          ?.reduce(
                            (acc, curr) => curr.receivers.concat(acc),
                            []
                          )
                          .filter((filteredData) => keepEnabled(filteredData))
                          .reverse()

                    setSelectReceivers(receiverData)
                  } else {
                    setSelectReceivers([])
                  }
                }}
              />
            </Cell>
            {RenderCell(data.isB2B ? businessHeader : cellHeader)}
          </Header>
          {haveReceivers.length > 0 &&
            haveReceivers.map((item, index) => {
              const isStatusDisable = ![
                'CHARGED',
                'SENT',
                'GIFT_STATUS_COMPLETED',
              ].includes(item.status)
              return (
                <Row key={item.id || `_${index}`}>
                  <Cell className="align-center">
                    <Checkbox
                      disabled={isStatusDisable}
                      controlled
                      name={`receiver-checkbox-${index}`}
                      testid={`resend-check-${index}`}
                      value={selectReceivers.find(
                        (selectReceiver) => selectReceiver.id === item.id
                      )}
                      onChange={(value) => {
                        if (value) {
                          setSelectReceivers([
                            ...selectReceivers,
                            {
                              id: item.id,
                              name: item.name,
                              deliveryMethod: item.deliveryMethod,
                              deliveryTime: item.deliveryTime,
                              updateTime: item.updateTime,
                              status: item.status,
                              amount: item.amount,
                              phone: item.phone,
                              entityId: item?.entityId || '',
                              addressee: item?.addressee || {},
                              batchId: item?.batchId || '',
                              email: item.email,
                            },
                          ])
                        } else {
                          setSelectReceivers(
                            selectReceivers.filter(
                              (select) => select.id !== item.id
                            )
                          )
                        }
                      }}
                    />
                  </Cell>
                  <Cell>
                    <Image bordered size="sm" src={item.metadata.image} />
                  </Cell>
                  {data.isB2B ? (
                    <>
                      <Cell key={item.batchId}>
                        <Link
                          to={`/marketing/evoucher-details/${item.batchId}`}
                        >
                          <small className="text-muted">
                            {item.batchId || 'N/A'}
                          </small>
                        </Link>
                      </Cell>
                      {RenderRow(item)}
                    </>
                  ) : (
                    <>
                      {renderDeliveryMethod(item, index)}

                      <Cell>
                        <div key={index}>
                          <div>
                            <small className="text-muted">
                              {item.deliveryTime &&
                                getPrintableTime(item.deliveryTime).split(
                                  ', '
                                )[0]}
                            </small>
                          </div>
                        </div>
                      </Cell>
                      <Cell>
                        <div key={index}>
                          <div>
                            <small className="text-muted">
                              {item.updateTime &&
                                getPrintableTime(item.updateTime).split(
                                  ', '
                                )[0]}
                            </small>{' '}
                            <small className="text-muted">
                              {item.updateTime && getOnlyTime(item.updateTime)}
                            </small>
                          </div>
                        </div>
                      </Cell>
                      <Cell key={item.accessedTime}>
                        {renderAccessTime(item)}
                      </Cell>
                      <Cell key={item.deliveryStatus}>
                        <small className="text-muted">
                          {item.deliveryStatus || 'N/A'}
                        </small>
                      </Cell>
                      <Cell key={item.status}>
                        <small>{getMessage(item.status)}</small>
                      </Cell>
                      <Cell key={item.amount}>
                        <small className="text-muted">
                          {currencyFormat(Number(item.amount))}
                        </small>
                      </Cell>
                      <Cell>
                        <DropDown
                          data-testid={`resend-action-${index}`}
                          isDisable={isStatusDisable}
                          icon={
                            <img
                              data-testid="egifting-row-dropdown"
                              src={ICONS.VELLIP}
                              alt="⋮"
                            />
                          }
                        >
                          <DropDownItem
                            dataTestid={`resend-action-item-${index}`}
                            key={index}
                            onClick={() => {
                              setShowResendModal({
                                shown: true,
                                data: item,
                              })
                              getResendDetails(item, setResendHistory)
                            }}
                          >
                            {getMessage(resendLabel)}
                          </DropDownItem>
                        </DropDown>
                      </Cell>
                    </>
                  )}
                </Row>
              )
            })}
          {renderAmountRow(selectReceivers, setResendDialog, data)}
        </Table>
      )}
      <div className="invoiceEvochers">
        <InvoiceEvochers amount={data.amount} />
      </div>
      {resendDialog && (
        <ResendDialog
          show={resendDialog}
          onClose={() => {
            setResendDialog(false)
            setSelectReceivers([])
          }}
          onCancel={() => {
            setResendDialog(false)
            setSelectReceivers([])
          }}
          successModal={setSuccessModal}
          resendDetails={selectReceivers}
          setSelectReceivers={setSelectReceivers}
        />
      )}

      <EvoucherDialog
        resendHistory={resendHistory}
        show={showResendModal.shown}
        resendData={showResendModal.data}
        setSuccessModal={setSuccessModal}
        onCancel={() => setShowResendModal({ shown: false, data: {} })}
        onClose={() => setShowResendModal({ shown: false, data: {} })}
      />
    </div>
  )
}

export default EvoucherDeatils
