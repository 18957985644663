import React from 'react'
import { BaseForm, Radio, Select } from '../../../../../components/Form'
import Loader from '../../../../../components/Loader'
import Table, { Header, Row, Cell } from '../../../../../components/Table'

import API from '../../../../../lib/api'
import { getMessage } from '../../../../../lib/translator'

import './style.css'

const getWastageReasons = wastageReasons => {
  return wastageReasons.map(reason => ({
    text: reason.name.toLowerCase(),
    value: reason.id,
  }))
}

const resolutionOptions = [
  {
    text: getMessage('return.toStore'),
    value: 'STORE',
  },
  {
    text: getMessage('return.markasWastage'),
    value: 'WASTAGE',
  },
]

class ReturnsFrom extends BaseForm {
  constructor(props) {
    super(props)
    this.state.wastageReasons = getWastageReasons(props.wastageReasons)
    this.state.loading = false
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    const api = new API({
      url: `/order-service/order/${this.props.referenceNumber}/returns`,
    })
    // const api = new API({ url: 'https://api.myjson.com/bins/obvhy' })
    api.get().then(
      response => {
        const returns = response.data.returns
        this.setState({
          values: {
            returns,
          },
          loading: false,
        })
      },
      error => {
        this.setState({
          formError: error,
          loading: false,
        })
      }
    )
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
    })
    const data = Object.assign({}, formData)
    const returns = data.returns
    const returnToStoreItems = []
    const wastageItems = []
    returns.forEach(item => {
      if (item.resolution === 'WASTAGE') {
        wastageItems.push({
          orderReturnId: item.id,
          quantity: item.quantity,
          wastageReasonId:
            item.wastageReasonId ||
            (this.state.wastageReasons && this.state.wastageReasons[0].value),
        })
      } else if (item.resolution === 'STORE') {
        returnToStoreItems.push({
          orderReturnId: item.id,
          quantity: item.quantity,
        })
      }
    })
    if (returnToStoreItems.length > 0) {
      const storeApi = new API({
        url: `/order-service/order/${this.props.referenceNumber}/return-to-store`,
      })
      const params = {
        items: returnToStoreItems,
        storeId: this.props.storeId
      }
      storeApi.put(params).then(
        () => {
          this.props.onSuccess()
        },
        error => {
          this.setState({
            formError: error,
            submitting: false,
          })
        }
      )
    }
    if (wastageItems.length > 0) {
      const wastageApi = new API({
        url: `/order-service/order/${this.props.referenceNumber}/wastage`,
      })
      const params = {
        items: wastageItems,
        storeId: this.props.storeId
      }
      wastageApi.put(params).then(
        () => {
          this.props.onSuccess()
        },
        error => {
          this.setState({
            formError: error,
            submitting: false,
          })
        }
      )
    }
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const { returns } = this.state.values
    const defaultWastageValue =
      this.state.wastageReasons && this.state.wastageReasons[0].value
    return this.state.loading ? (
      <Loader />
    ) : (
      <div className="return-resolution">
        <Form>
          <Table>
            <Header>
              <Cell>{getMessage('return.name')}</Cell>
              <Cell>{getMessage('return.resolution')}</Cell>
              <Cell>{getMessage('return.wastagereason')}</Cell>
              <Cell>{getMessage('return.quantity')}</Cell>
              <Cell>{getMessage('return.settledQuantity')}</Cell>
            </Header>
            {returns &&
              returns.map((item, index) => {
                const entityType = item.orderItem.entityType.toLowerCase()
                const name = item.orderItem[entityType].name
                return (
                  <Row key={`return-row-${index}`}>
                    <Cell>
                      <p>{name}</p>
                    </Cell>
                    <Cell>
                      <Radio
                        name={`resolution-${index}`}
                        options={resolutionOptions}
                        {...this.generateStateMappers({
                          stateKeys: ['returns', index, 'resolution'],
                          loseEmphasisOnFill: true,
                        })}
                      />
                    </Cell>
                    <Cell>
                      {returns[index].resolution === 'WASTAGE' && (
                        <Select
                          name={`wastage-reason-${index}`}
                          placeholder={getMessage(
                            'return.wastagereason.placeholder'
                          )}
                          options={this.state.wastageReasons}
                          {...this.generateStateMappers({
                            stateKeys: ['returns', index, 'wastageReasonId'],
                            loseEmphasisOnFill: true,
                            defaultValue: defaultWastageValue,
                          })}
                        />
                      )}
                    </Cell>
                    <Cell>{item.quantity}</Cell>
                    <Cell>{item.settledQuantity}</Cell>
                  </Row>
                )
              })}
          </Table>
          <div className="form-action">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage('return.submit')}
            </SubmitButton>
            <button
              type="button"
              className="button"
              onClick={() => this.props.closeReturnsPopup()}
            >
              {getMessage('return.cancel')}
            </button>
          </div>
        </Form>
      </div>
    )
  }
}

export default ReturnsFrom
