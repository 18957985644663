import React, { cloneElement, useEffect, useState } from 'react'
import {
  CloseIcon,
  FixedButton,
  FullScreen,
  LeftArrowIcon,
  RightArrowIcon,
  Text,
  View,
} from '../ui'
import { useField, useFormikContext } from 'formik'
import { useLockScroll } from '../hooks'
import moment from 'moment'
import { PreorderDate } from './PreorderDate'
import { PreorderTime } from './PreorderTime'

const AccordionItem = ({
  children,
  label,
  index,
  setActiveIndex,
  isActive,
}) => {
  return (
    <View
      direction="column"
      background="white"
      padding="16px"
      overflow="hidden"
    >
      {cloneElement(label, { onClick: () => setActiveIndex(index) })}
      {isActive && children}
    </View>
  )
}

const AccordionContainer = ({ children, activeIndex, setActiveIndex }) => {
  return children.map((element, index) =>
    cloneElement(element, {
      index,
      isActive: index === activeIndex,
      setActiveIndex,
    })
  )
}

export const Timeslot = ({ onClose, slot }) => {
  const { setFieldValue, handleChange } = useFormikContext()

  const [activeIndex, setActiveIndex] = useState(0)
  const [weekIndex, setWeekIndex] = useState(0)
  const [pickupDate] = useField('pickupDate')
  const [pickupTime] = useField('pickupTime')

  useLockScroll('body', () => {
    document.querySelector('body').removeAttribute('style')
  })

  useEffect(() => {
    if (pickupDate.value) {
      setActiveIndex(1)
    }
  }, [pickupDate.value])

  const slotDateAsc = Object.keys(slot).sort(
    (a, b) => new Date(a) - new Date(b)
  )
  const startDate = slotDateAsc[0]
  const endDate = slotDateAsc[slotDateAsc.length - 1]

  const firstMonday = moment(startDate, 'YYYY-MM_DD').isoWeekday(1)
  const lastSunday = moment(endDate, 'YYYY-MM-DD').isoWeekday(7)

  const datesInSlotRange = Array(lastSunday.diff(firstMonday, 'days') + 1)
    .fill(1)
    .map((_, i) => firstMonday.clone().add(i, 'd').format('YYYY-MM-DD'))

  const maxWeekIndex = lastSunday.diff(firstMonday, 'weeks')

  const prevWeek = () => {
    setWeekIndex((w) => Math.max(0, w - 1))
  }

  const nextWeek = () => {
    setWeekIndex((w) => Math.min(w + 1, maxWeekIndex))
  }

  const displayedDate = datesInSlotRange.slice(7 * weekIndex, 7 * weekIndex + 7)

  const handleTimeChange = (e, slotId) => {
    setFieldValue('slotId', slotId)
    handleChange(e)
  }

  return (
    <FullScreen background="#F2F2F2" justifyContent="start">
      <View background="white" padding={16} margin="0 0 16px">
        <View display="inline" width={30} onClick={onClose}>
          <CloseIcon />
        </View>
        <View width="100%">
          <Text fontWeight={700}>Select pick-up options</Text>
        </View>
      </View>
      <View
        direction="column"
        gap={16}
        height="calc(100vh - 100px)"
        justifyContent="start"
      >
        <AccordionContainer
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <AccordionItem
            key="selectDate"
            label={
              <View justifyContent="space-between" overflow="hidden">
                <Text fontWeight={700} fontSize={20}>
                  Select a date
                </Text>
                <Text color="blue">{pickupDate.value}</Text>
              </View>
            }
          >
            <>
              <View justifyContent="space-between">
                <Text fontWeight={700} fontSize={18}>
                  {`${moment(displayedDate[0]).format('DD MMM')} - ${moment(displayedDate.at(-1)).format('DD MMM')}`}
                </Text>
                <LeftArrowIcon
                  fill={weekIndex !== 0 ? 'blue' : 'grey'}
                  onClick={prevWeek}
                  data-testid="prevWeek"
                />{' '}
                <RightArrowIcon
                  fill={weekIndex !== maxWeekIndex ? 'blue' : 'grey'}
                  onClick={nextWeek}
                  data-testid="nextWeek"
                />
              </View>
              <View display="block" height={350} minHeight={300}>
                {displayedDate.map((day) => (
                  <PreorderDate key={day} date={day} slotDate={slot[day]} />
                ))}
              </View>
            </>
          </AccordionItem>
          <AccordionItem
            key="selectTime"
            label={
              <Text
                textAlign="left"
                display="block"
                fontWeight={700}
                fontSize={20}
                width="100%"
              >
                Select a time slot
              </Text>
            }
          >
            <View direction="column">
              {slot[pickupDate.value]?.map((time) => (
                <PreorderTime
                  key={time.id}
                  timeSlot={time}
                  slotId={time.id}
                  handleTimeChange={handleTimeChange}
                />
              ))}
            </View>
          </AccordionItem>
        </AccordionContainer>
      </View>
      <FixedButton disabled={!pickupDate || !pickupTime} onClick={onClose}>
        Confirm date and time slot
      </FixedButton>
    </FullScreen>
  )
}
