import React, { Component } from 'react'

import { getMessage } from '../../../../lib/translator'
import API from '../../../../lib/api'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage/index'
import { Dialog } from '../../../../components/Popup'
import Loader from '../../../../components/Loader'
import { hideErrorDialog, throwError } from '../../commonMedia'
import CreativeSummary from './CreativeSummary'
import CreativeGroupTable from './CreativeGroupTable'
import GroupPopup from './GroupPopup'
import './style.css'

function getMobileView() {
  return window.screen.width <= 480
}

export default class MediaCreativeGroupList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: true,
      isMobileView: getMobileView(),
      data: {},
      creativeGroupsData: [],
      GroupDialog: {
        shown: false,
        status: 'Create',
        selectedRequirementId: '',
        selectedCreativeGroupId: '',
        packageReqList: []
      },
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
      showEitDisabledDialog: false,
    }
    this.mobileView = this.mobileView.bind(this)
    this.getCreativeDetail = this.getCreativeDetail.bind(this)
    this.toggleGroupDialog = this.toggleGroupDialog.bind(this)
    this.toggleEitDisabledDialog = this.toggleEitDisabledDialog.bind(this)
    this.handleSubmitGroup = this.handleSubmitGroup.bind(this)
  }
  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
    this.getCreativeDetail();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.mobileView, false)
    this.api && this.api.cancel()
  }

   toggleEitDisabledDialog(){
    this.setState({
      showEitDisabledDialog: !this.state.showEitDisabledDialog
    })
  }


  getCreativeDetail() {
    const creativeId = this.props.match.params.id
    this.creativeAPI = new API({ url: `/martech-creative-service/creative/${creativeId}` })
    this.assetsAPI = new API({ url: `/martech-creative-service/creative/${creativeId}/assets`})

    this.setState({
      showLoader: true
    })

    Promise.all([
      this.creativeAPI.get(),
      this.assetsAPI.get(),
    ]).then(([creativeRES, assetsRES]) => {

      const packageReqList = assetsRES.data
      packageReqList.forEach( group => {
        group.isGroupValid = true
      })

      this.setState({
        showLoader: false,
        data: creativeRES?.data,
        creativeGroupsData: creativeRES.data.creative_group,
        GroupDialog: {
          ...this.state.GroupDialog,
          packageReqList,
        }
      })
    }).catch(error => {
      throwError(this, error)
    })
  }

  toggleGroupDialog(shown, status, selectedRequirementId, selectedCreativeGroupId) {
    this.setState({
      GroupDialog: {
        ...this.state.GroupDialog,
        shown: shown === 1,
        status: status === 1 ? 'Create' : 'Edit',
        selectedRequirementId,
        selectedCreativeGroupId,
      }
    })
  }

  handleSubmitGroup(error, errObj) {
    if(error){
      throwError(this, errObj)
    }
    this.getCreativeDetail()
    this.toggleGroupDialog(2)
  }

  isCreativeNotEditable () {
    return this.state.data?.creative_assigment?.some(assign => assign.line_item.status === 'Scheduled')
  }


  render() {
    const { menu } = this.props
    const { showLoader, isMobileView, creativeGroupsData } = this.state

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className='media-creative-list-page website-pages'
        title={getMessage('media.creativedetail.title')}
      >
        <h1 className='title'>{getMessage('media.creativedetail.title')}</h1>

        {showLoader ? (
          <Loader />
        ) : (
          <React.Fragment>
            <CreativeSummary
              data={this.state.data}
              isNotEditable={ this.isCreativeNotEditable()}
              toggleEitDisabledDialog={()=> this.toggleEitDisabledDialog()}
            ></CreativeSummary>

            <div className='creative-table-title'>
              <h3>{getMessage('media.creativedetail.creative.group.title')}</h3>
              <div className='section-btn'
                onClick={() => { this.state.data?.creative_assigment?.some(assign=> assign.line_item.status==='Complete')
                  ?
                  this.toggleEitDisabledDialog()
                  :this.toggleGroupDialog(1, 1) }}
              >
                + <span className='text'>{getMessage('media.creativedetail.creative.group.add')}</span>
              </div>
            </div>

            <CreativeGroupTable
              creativeId={this.props.match.params.id}
              isMobileView={isMobileView}
              creativeGroupsData={creativeGroupsData}
              throwError={(error, message) => (throwError(this, error, message))}
              getCreativeDetail={this.getCreativeDetail}
              toggleGroupDialog={this.toggleGroupDialog}
              toggleEitDisabledDialog={()=> this.toggleEitDisabledDialog()}
              isNotEditable={this.isCreativeNotEditable()}
            ></CreativeGroupTable>
          </React.Fragment>
        )}

        {this.state.GroupDialog.shown && (
          <GroupPopup
              creativeId={this.props.match.params.id}
              group_no={creativeGroupsData.length+1}
              GroupDialog={this.state.GroupDialog}
              toggleGroupDialog={() => { this.toggleGroupDialog(2) }}
              handleSubmitGroup={this.handleSubmitGroup}
          ></GroupPopup>
        )}

        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={this.state.errorDialog.message}
            close={() => (hideErrorDialog(this))}
            closeText={getMessage('dialog.okText')}
          />
        )}
        {this.state.showEitDisabledDialog && (
          <Dialog
            show={this.state.showEitDisabledDialog}
            title={getMessage('media.creativeManagement.editDisabledPopup.title')}
            information={getMessage('media.creativeManagement.editDisabledPopup.message')}
            close={() => (this.toggleEitDisabledDialog())}
            closeText={getMessage('dialog.okText')}
          />
        )}
      </AuthenticatedPage>
    )
  }
}

