import React, { Component, Fragment } from 'react'
import PublicPage from '../../../containers/PublicPage/index'
import ResetPasswordForm from './form'
import { Link, Redirect } from 'react-router-dom'
import { Consumer } from '../../../containers/Context'

import { getMessage } from '../../../lib/translator'

export default class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      success: false,
    }
    this.onReset = this.onReset.bind(this)
  }
  onReset() {
    this.setState({
      success: true,
    })
  }
  render() {
    return (
      <PublicPage className="forgot-password eazy-page">
        <Consumer>
          {isEnterprise =>
            isEnterprise ? (
              <Redirect to="/login" />
            ) : (
              <Fragment>
                {!this.state.success ? (
                  <header className="header">
                    <h1 className="heading">
                      {getMessage('resetPassword.heading')}
                    </h1>
                    <h2 className="subheading">
                      {getMessage('resetPassword.subheading')}
                    </h2>
                  </header>
                ) : null}
                <div className="box">
                  {!this.state.success ? (
                    <div>
                      <ResetPasswordForm onSuccess={this.onReset} />
                      <p className="alt-action">
                        {getMessage('resetPassword.oldUserText')}{' '}
                        <Link to={'/login'}>
                          {getMessage('resetPassword.login')}
                        </Link>
                      </p>
                    </div>
                  ) : (
                    <div className="text-center">
                      {getMessage('resetPassword.successMessage')}
                      <div>
                        <Link to="/login">
                          {getMessage('resetPassword.loginLinkText')}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </Fragment>
            )
          }
        </Consumer>
      </PublicPage>
    )
  }
}
