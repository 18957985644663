import React, { Fragment } from 'react'
import { TextDetails } from '../utils'

const TextInfo = ({ _this }) => {
  const requiredFields = [['howToRedeem'], ['tnc']]
  const WEB_INFORMATIONS = [['description'], ...requiredFields]
  return (
    <Fragment>
      {WEB_INFORMATIONS.map((informations, i) => {
        const isRequired = requiredFields.includes(informations)
        return (
          <div className="row" key={i}>
            {informations.map((info, j) => {
              return (
                <TextDetails
                  _this={_this}
                  key={j}
                  text={info}
                  isRequired={isRequired}
                />
              )
            })}
          </div>
        )
      })}
    </Fragment>
  )
}

export default TextInfo
