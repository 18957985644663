import React, { useState } from 'react'

const ActionTrailContext = React.createContext()

export const useActionTrailContext = () => {
  return React.useContext(ActionTrailContext)
}

export const ActionTrailContextProvider = ({ children }) => {
  const [tableRecords, setTableRecords] = useState(null)
  const [totalRecord, setTotalRecord] = useState(0)
  const [filterForm, setFilterForm] = useState({})

  return (
    <ActionTrailContext.Provider
      value={{
        tableRecords,
        setTableRecords,
        totalRecord,
        setTotalRecord,
        filterForm,
        setFilterForm,
      }}
    >
      {children}
    </ActionTrailContext.Provider>
  )
}
