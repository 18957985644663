import React, { Fragment } from 'react'
import { MultiSelect, Select } from '../Form'
import { getMessage } from 'lib/translator'

const CategoryInfo = ({
  _this,
  categoryList,
  fetchCategoryError,
  type,
  isRewardsCatalogue = '',
}) => {
  const values = Object.assign({}, _this.state.values)
  values.categoryIds = []
  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <h3>{getMessage('rewards.catalogue.categoryInfo')}</h3>
        </div>
      </div>
      {fetchCategoryError && (
        <div className="row mb">
          <div className="col-12 error" data-testid="error">
            {fetchCategoryError}
          </div>
        </div>
      )}
      {categoryList?.length > 0 && (
        <div className="row">
          {type === 'partners' && (
            <div className="col-12">
              <Select
                testid="primaryCategoryId"
                options={categoryList}
                label={getMessage('rewards.partner.categoryInfo.primary')}
                placeholder={getMessage('rewards.partner.categoryInfo.primary')}
                {..._this.generateStateMappers({
                  stateKeys: ['primaryCategoryId'],
                })}
              />
            </div>
          )}

          <div className="col-12">
            <MultiSelect
              name="categoryIds"
              className="section-row-input select-categories"
              label={getMessage(
                `rewards.partner.categoryInfo.${
                  isRewardsCatalogue ? `category` : `categories`
                }`
              )}
              placeholder={getMessage(
                `rewards.partner.categoryInfo.${
                  isRewardsCatalogue ? `category` : `categories`
                }`
              )}
              options={categoryList}
              searchByName
              {..._this.generateStateMappers({
                stateKeys: ['categoryIds'],
              })}
              {...(isRewardsCatalogue && { required: 'required' })}
            />
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default CategoryInfo
