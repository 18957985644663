import React from 'react'
import ListingPage from 'containers/ListingPage'
import PendingApprovalFilter from './Filters'

import { getMessage } from 'lib/translator'
import tablePropertie from './tableProperties'
import { transform } from '../../EGiftingOrders/List/tableProperties'

const EmptyState = {
  message: getMessage('customerSupport.egifting.empty'),
}

export default function List(props) {
  return (
    <ListingPage
      testid="e-gifting-orders-pending-approval"
      menu={props.menu}
      className="egifting-orders-page-pending-approval"
      title={getMessage('customerSupport.egifting.pendingApproval.heading')}
      api={{
        url: '/gifting-admin-service/orders',
        params: { paymentType: 'DEPOSIT', type: 'B2B', status: 'DEPOSITED' },
        transform,
      }}
      filters={{
        component: PendingApprovalFilter,
        forceShow: true,
      }}
      haveToast={props.history?.location?.state?.action}
      reference={props.history?.location?.state?.reference}
      primaryKey="id"
      tableProperties={tablePropertie()}
      emptyState={EmptyState}
      statusUpdateOnDelete
    />
  )
}
