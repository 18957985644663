import React from 'react'
import NewListingPage from 'containers/NewListingPage'
import { getMessage } from '../../../lib/translator'
import FilterForm from './FilterForm'
import { tableProperties } from './tableProperties'
import './style.css'

const DesignationRole = (props) => {
  return (
    <NewListingPage
      title={getMessage('designation-roles.dashboard.title')}
      className="dr"
      menu={props.menu}
      primaryKey="id"
      skipArrows={true}
      api={{
        url: '/account-service/designation',
        params: { organizationId: 2, limit: 20 },
        isPageDisabled: true,
        transform: (response) => {
          return response.data.designation
        },
      }}
      filters={{
        forceShow: true,
        component: FilterForm,
        transformSubmit: (formData) => {
          const result = Object.assign({}, formData)
          if (formData.name) {
            result.name = formData.name.name
          }
          return result
        },
      }}
      tableProperties={tableProperties()}
    />
  )
}
export default DesignationRole
