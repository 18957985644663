import React, { useCallback, useState } from 'react'
import { useActionTrailContext } from '../ActionTrailContext'
import API from 'lib/api'
import Loader from 'components/Loader'
import { CSVLink } from 'react-csv'

export const CSVButton = () => {
  const actiontrailContext = useActionTrailContext()
  const [_recordsToDownload, setRecordsToDownload] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [isDownloadReady, setDownloadReady] = useState(false)

  const _handleClick = useCallback(async () => {
    setIsFetching(true)
    const apis = new API({ url: '/actions-trail' })

    const filterForm = actiontrailContext.filterForm

    const result = await apis.get({
      ...filterForm,
      limit: actiontrailContext.totalRecord,
    })

    const records = result.data.actionsTrail
    setRecordsToDownload(preprocessDataForCSV(records))
    setIsFetching(false)
    setDownloadReady(true)
  }, [actiontrailContext])

  const preprocessDataForCSV = (data) => {
    return data.map((item) => ({
      ...item,
      // Enclose the JSON string in double quotes
      actionPayload: `'${item.actionPayload.replace(/"/g, "'")}'`,
    }))
  }

  if (isDownloadReady) {
    return (
      <CSVLink
        data={_recordsToDownload}
        filename={'my-file.csv'}
        className="primary button csv-export-button"
        target="_blank"
      >
        Download CSV
      </CSVLink>
    )
  }

  return (
    <button
      className="primary button csv-export-button"
      type="button"
      onClick={_handleClick}
      disabled={isFetching}
    >
      Export Records to CSV
      {isFetching && <Loader size="sm" className="csv-button-loader" />}
    </button>
  )
}
