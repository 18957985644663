import React from 'react'
import {
  SelectSearch,
  Input,
  Textarea,
  Select,
  MultiSelect,
  Checkbox,
  SingleDatePicker,
  DateTime,
} from '../Form'
import { getMessage } from '../../lib/translator'
import { getCountryList } from '../../lib/commonlyused'

const INPUT_PLACEHOLDER = getMessage('product.form.enter')

function commonGenerateStateMappers(
  _this,
  key,
  stateVar,
  loseEmphasisOnFill = false
) {
  let stateKeys = stateVar || ['metaData']
  stateKeys = stateKeys.slice()
  stateKeys.push(key)
  return _this.generateStateMappers
    ? _this.generateStateMappers({
        stateKeys,
        loseEmphasisOnFill,
      })
    : {}
}

const MetaDataInputNumber = ({
  i,
  keyName: key,
  readOnly,
  details,
  tooltips,
  isSugarLevel,
  _this,
  stateKeys,
  metaData,
  onChange,
}) => (
  <Input
    type="number"
    className="number"
    key={`metaData-${i}`}
    label={key}
    readOnly={readOnly}
    required={details.required}
    tooltip={tooltips?.[key]}
    placeholder={`${INPUT_PLACEHOLDER} ${key}`}
    {...commonGenerateStateMappers(_this, key, stateKeys, true)}
    value={
      metaData
        ? metaData[key]
        : commonGenerateStateMappers(_this, key, stateKeys).value
    }
    onChange={
      onChange
        ? (e) => onChange(key, e)
        : commonGenerateStateMappers(_this, key, stateKeys).onChange
    }
    {...(isSugarLevel && {
      min: 0,
      max: 100,
      ..._this.generateStateMappers({
        stateKeys: ['metaData', 'Sugar Level'],
        loseEmphasisOnFill: true,
      }),
      validationStrings: {
        rangeUnderflow: 'Sugar Level should be integer from 0 - 100',
        rangeOverflow: 'Sugar Level should be integer from 0 - 100',
      },
    })}
  />
)

const MetaDataInputString = ({
  i,
  keyName: key,
  readOnly,
  details,
  tooltips,
  _this,
  stateKeys,
  metaData,
  onChange,
}) => (
  <Input
    key={`metaData-${i}`}
    className="string"
    type="text"
    label={key}
    readOnly={readOnly}
    required={details.required}
    tooltip={tooltips?.[key]}
    placeholder={`${INPUT_PLACEHOLDER} ${key}`}
    {...commonGenerateStateMappers(_this, key, stateKeys, true)}
    value={
      metaData
        ? metaData[key]
        : commonGenerateStateMappers(_this, key, stateKeys).value
    }
    onChange={
      onChange
        ? (e) => onChange(key, e)
        : commonGenerateStateMappers(_this, key, stateKeys).onChange
    }
  />
)

const MetaDataInputBoolean = ({
  i,
  keyName: key,
  readOnly,
  details,
  tooltips,
  _this,
  stateKeys,
  metaData,
  onChange,
}) => (
  <div className="field field-checkbox" key={`metadata-${i}`}>
    <Checkbox
      inlineLabel={key}
      name={`metaData-${i}`}
      readOnly={readOnly}
      required={details.required}
      tooltip={tooltips?.[key]}
      placeholder={`${INPUT_PLACEHOLDER} ${key}`}
      {...commonGenerateStateMappers(_this, key, stateKeys)}
      value={
        metaData
          ? metaData[key]
          : commonGenerateStateMappers(_this, key, stateKeys).value
      }
      onChange={
        onChange
          ? (e) => onChange(key, e)
          : commonGenerateStateMappers(_this, key, stateKeys).onChange
      }
    />
  </div>
)

const MetaDataInputEnum = ({
  details,
  productMetadataMigrateEnabled,
  keyName: key,
  i,
  readOnly,
  _this,
  stateKeys,
  metaData,
  onChange,
}) => {
  const options = productMetadataMigrateEnabled
    ? details.rule && details.rule.allowedValue
    : details.typeMeta && details.typeMeta.allowedValue
      ? details.typeMeta.allowedValue
      : []
  const Comp = details.type === 'enum' ? Select : MultiSelect
  return options === null ? null : (
    <Comp
      label={key}
      name={`metaData-${i}`}
      readOnly={readOnly}
      placeholder={`${INPUT_PLACEHOLDER} ${key}`}
      key={`metaData-${i}`}
      className="enum"
      required={details.required}
      options={options.map((opt) => {
        return {
          text: opt,
          value: opt,
        }
      })}
      {...commonGenerateStateMappers(_this, key, stateKeys, true)}
      value={
        metaData
          ? metaData[key]
          : commonGenerateStateMappers(_this, key, stateKeys).value
      }
      onChange={
        onChange
          ? (e) => onChange(key, e)
          : commonGenerateStateMappers(_this, key, stateKeys).onChange
      }
    />
  )
}

const MetaDataInputCountry = ({
  i,
  keyName: key,
  readOnly,
  details,
  _this,
  stateKeys,
  metaData,
  onChange,
}) => (
  <SelectSearch
    name={`metaData-${i}`}
    label={key}
    readOnly={readOnly}
    className="country"
    nameKey="name"
    valueKey="name"
    key={`metaData-${i}`}
    required={details.required}
    options={getCountryList()}
    placeholder={`${INPUT_PLACEHOLDER} ${key}`}
    {...commonGenerateStateMappers(_this, key, stateKeys, true)}
    value={
      metaData
        ? metaData[key]
        : commonGenerateStateMappers(_this, key, stateKeys).value
    }
    onChange={
      onChange
        ? (e) => onChange(key, e)
        : commonGenerateStateMappers(_this, key, stateKeys).onChange
    }
  />
)

const MetaDataInputDate = ({
  keyName: key,
  i,
  readOnly,
  details,
  _this,
  metaData,
  stateKeys,
  onChange,
}) => (
  <SingleDatePicker
    key={`metadata-${key}-${i}`}
    name={`metadata-${key}-${i}`}
    readOnly={readOnly}
    label={key}
    required={details.required}
    placeholder={`${getMessage('order.select')} ${key}`}
    {...commonGenerateStateMappers(_this, key)}
    value={
      metaData
        ? metaData[key]
        : commonGenerateStateMappers(_this, key, stateKeys).value
    }
    onChange={
      onChange
        ? (e) => onChange(key, e)
        : commonGenerateStateMappers(_this, key, stateKeys).onChange
    }
  />
)

const MetaDataInputDateTime = ({
  keyName: key,
  i,
  readOnly,
  details,
  _this,
  metaData,
  stateKeys,
  onChange,
}) => (
  <DateTime
    key={`metadata-${key}-${i}`}
    name={`metadata-${key}-${i}`}
    label={key}
    readOnly={readOnly}
    required={details.required}
    placeholder={`${getMessage('order.select')} ${key}`}
    {...commonGenerateStateMappers(_this, key, stateKeys)}
    value={
      metaData
        ? metaData[key]
        : commonGenerateStateMappers(_this, key, stateKeys).value
    }
    onChange={
      onChange
        ? (e) => onChange(key, e)
        : commonGenerateStateMappers(_this, key, stateKeys).onChange
    }
  />
)

const MetaDataInputText = ({
  keyName: key,
  i,
  readOnly,
  _this,
  metaData,
  stateKeys,
  onChange,
}) => (
  <Textarea
    key={`metaData-${i}`}
    label={key}
    readOnly={readOnly}
    className="text"
    type="text"
    placeholder={`${INPUT_PLACEHOLDER} ${key}`}
    {...commonGenerateStateMappers(_this, key, stateKeys)}
    value={
      metaData
        ? metaData[key]
        : commonGenerateStateMappers(_this, key, stateKeys).value
    }
    onChange={
      onChange
        ? (e) => onChange(key, e)
        : commonGenerateStateMappers(_this, key, stateKeys).onChange
    }
  />
)

const MetaDataFormFields = (props) => {
  const {
    metaDataWithType,
    title,
    className,
    _this,
    readOnly,
    metaData,
    onChange,
    noContainer,
    preserveSequence: preserveSequenceProp,
    stateKeys,
    productMetadataMigrateEnabled,
    tooltips,
    hide = {},
  } = props
  let arr = []
  let preserveSequence = preserveSequenceProp
  preserveSequence = productMetadataMigrateEnabled ? true : preserveSequence
  const tempArr = []
  if (metaDataWithType) {
    const filteredMetaDataWithType = Object.entries(metaDataWithType).filter(
      ([key, details]) => details && !hide[key]
    )
    filteredMetaDataWithType.map(([key, details], i) => {
      if (details.type === 'number') {
        const data = (
          <MetaDataInputNumber
            i={i}
            keyName={key}
            readOnly={readOnly}
            details={details}
            tooltips={tooltips}
            isSugarLevel={key === 'Sugar Level'}
            _this={_this}
            stateKeys={stateKeys}
            metaData={metaData}
            onChange={onChange}
          />
        )
        preserveSequence ? arr.push(data) : arr.unshift(data)
      } else if (details.type === 'string') {
        const data = (
          <MetaDataInputString
            i={i}
            keyName={key}
            readOnly={readOnly}
            details={details}
            tooltips={tooltips}
            _this={_this}
            stateKeys={stateKeys}
            metaData={metaData}
            onChange={onChange}
          />
        )
        preserveSequence ? arr.push(data) : tempArr.unshift(data)
      } else if (details.type === 'boolean') {
        const data = (
          <MetaDataInputBoolean
            i={i}
            keyName={key}
            readOnly={readOnly}
            details={details}
            tooltips={tooltips}
            _this={_this}
            stateKeys={stateKeys}
            metaData={metaData}
            onChange={onChange}
          />
        )
        preserveSequence ? arr.push(data) : arr.unshift(data)
      } else if (
        details.type === 'enum' ||
        details.type === 'multiValued Enum' ||
        details.type === 'multivalued enum'
      ) {
        const data = (
          <MetaDataInputEnum
            details={details}
            productMetadataMigrateEnabled={productMetadataMigrateEnabled}
            keyName={key}
            i={i}
            readOnly={readOnly}
            _this={_this}
            stateKeys={stateKeys}
            metaData={metaData}
            onChange={onChange}
          />
        )
        preserveSequence ? arr.push(data) : arr.unshift(data)
      } else if (details.type === 'country') {
        const data = (
          <MetaDataInputCountry
            i={i}
            keyName={key}
            readOnly={readOnly}
            details={details}
            _this={_this}
            stateKeys={stateKeys}
            metaData={metaData}
            onChange={onChange}
          />
        )
        preserveSequence ? arr.push(data) : arr.unshift(data)
      } else if (details.type === 'date') {
        const data = (
          <MetaDataInputDate
            keyName={key}
            i={i}
            readOnly={readOnly}
            details={details}
            _this={_this}
            metaData={metaData}
            stateKeys={stateKeys}
            onChange={onChange}
          />
        )
        arr.push(data)
      } else if (details.type === 'dateTime') {
        const data = (
          <MetaDataInputDateTime
            keyName={key}
            i={i}
            readOnly={readOnly}
            details={details}
            _this={_this}
            metaData={metaData}
            stateKeys={stateKeys}
            onChange={onChange}
          />
        )
        arr.push(data)
      } else if (details.type === 'text') {
        const data = (
          <MetaDataInputText
            keyName={key}
            i={i}
            readOnly={readOnly}
            _this={_this}
            metaData={metaData}
            stateKeys={stateKeys}
            onChange={onChange}
          />
        )
        preserveSequence ? arr.push(data) : tempArr.push(data)
      }
      return null
    })
  }
  arr = arr.concat(tempArr)
  return !noContainer ? (
    <div className={className}>
      {arr.length > 0 && title && <title />}
      {arr}
    </div>
  ) : (
    <React.Fragment>
      {arr.length > 0 && title && <title />}
      {arr}
    </React.Fragment>
  )
}

MetaDataFormFields.defaultProps = {
  preserveSequence: false,
}

export default MetaDataFormFields
