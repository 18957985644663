import React from 'react'
import { fieldNameMapping, flatDiffObject, parseEmptyValue, parseSngHours } from './utils'
import { diff } from 'json-diff'
import { Popup } from 'components/Popup'

export const parseValueToDisplay = (changeObj) => {
  const oldValue = parseEmptyValue(changeObj.__old)
  const newValue = parseEmptyValue(changeObj.__new)
  return (
    <>
      <span
        className={`line-through ${
          oldValue.length + newValue.length > 30 ? 'block' : ''
        }`}
      >
        {oldValue}
      </span>
      <span>{newValue}</span>
    </>
  )
}
export const StoreChangesModal = ({
  isOpen,
  toggleModal,
  oldData,
  newData,
  submitForm,
}) => {
  if (!isOpen) {
    return null
  }

  const diffObj = diff(parseSngHours(oldData), parseSngHours(newData))
  const parsedDiffObj = flatDiffObject(diffObj, '')

  return (
    <Popup
      className="preview__popup"
      show={isOpen}
      close={toggleModal}
      heading="Preview store changes"
    >
      <div className="changes__wrapper">
        <div className="changes__header">Field</div>
        <div className="changes__header">Changes</div>
        {Object.keys(parsedDiffObj).map((key) => (
          <>
            <div className="changes__field">{fieldNameMapping[key] || key}</div>
            <div className="changes__field">
              {parseValueToDisplay(parsedDiffObj[key])}
            </div>
          </>
        ))}
      </div>
      <div className="flex">
        <button
          type="button"
          className="primary changes__submit button"
          onClick={submitForm}
        >
          Submit
        </button>
      </div>
    </Popup>
  )
}
