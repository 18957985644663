import React from 'react'
import NewListingPage from 'containers/NewListingPage'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import {
  ACTION_TRAIL_HEADER,
  useActionTrailEnabled,
} from 'lib/actiontrail/utils'
import { getMessage } from 'lib/translator'
import ActionTrailFilterForm from './Filter'
import { transformFilter } from './transformFilter'
import { tableProperties } from './tableProperties'
import { useActionTrailContext } from './ActionTrailContext'

export const ActionTrailTable = (props) => {
  const _customHeaderEnabled = useActionTrailEnabled(
    SPLIT_FEATURES.ACTION_TRAIL_CUSTOM_HEADER
  )
  const { setFilterForm, setTotalRecord } = useActionTrailContext()

  return (
    <NewListingPage
      title={getMessage('action-trail.dashboard.title')}
      className={'user-activities'}
      api={{
        url: '/actions-trail',
        params: { ...props.params, limit: 20 },
        isPageDisabled: true,
        transform: (response) => {
          setTotalRecord(response.data.count)
          return response.data.actionsTrail
        },
        customHeaders: _customHeaderEnabled && {
          headers: ACTION_TRAIL_HEADER,
          methodToInjectHeader: ['GET'],
        },
      }}
      menu={props.menu}
      primaryKey="id"
      skipArrows={true}
      onClear={() => {
        setFilterForm({})
        setTotalRecord(0)
      }}
      filters={{
        forceShow: true,
        component: ActionTrailFilterForm,
        transformSubmit: (formData) => {
          const filterData = transformFilter(formData)
          setFilterForm(filterData)
          setTotalRecord(0) // clear the cache first to get latest value from pending api fetch
          return filterData
        },
      }}
      tableProperties={tableProperties()}
    />
  )
}
