import React, { Component } from 'react'
import { Input } from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import './style.css'
import deleteIcon from '../icon-dustbin.svg'
import PlacesWithStandaloneSearchBox from '../../../../../components/StandaloneSearchBox'

export default class RadialForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startRadius: '',
      endRadius: '',
      name: '',
      showLocationError: false,
      location: null,
      mode: 'add',
      radialZoneEditId: null,
    }
    this.onKeyPress = this.onKeyPress.bind(this)
    this.onPlacesSearched = this.onPlacesSearched.bind(this)
    this.clearTempData = this.clearTempData.bind(this)
    this.setStartEndRadius = this.setStartEndRadius.bind(this)
    this.handleSaveData = this.handleSaveData.bind(this)
    this.changeMode = this.changeMode.bind(this)
    this.changeRadialZoneEditId = this.changeRadialZoneEditId.bind(this)
    this.setZoneName = this.setZoneName.bind(this)
    this.handleZoneDelete = this.handleZoneDelete.bind(this)
  }

  onKeyPress(e) {
    e.key === 'Enter' && this.handleSaveData()
  }
  handleSaveData() {
    if (
      (this.state.startRadius && Number(this.state.startRadius) < 0) ||
      (this.state.endRadius && Number(this.state.endRadius) < 1)
    ) {
      return
    }
    const { selectedLocation: selectedLocationProp } = this.props
    let selectedLocation = selectedLocationProp
    const randomId = new Date().getTime()
    const { parent } = this.props
    const mode = this.state.mode
    const editId = this.state.radialZoneEditId
    if (!selectedLocation) {
      this.setState({ showLocationError: true })
      return
    }
    selectedLocation = {
      lat: Number(selectedLocation.lat),
      lng: Number(selectedLocation.lng),
    }
    if (
      (this.state.startRadius || this.state.startRadius === '0') &&
      this.state.endRadius
    ) {
      const currentState = Object.assign({}, parent.state)
      const { tempDeliveryArea, deliveryarea } = currentState
      if (mode === 'add') {
        tempDeliveryArea.push({
          configType: 'RADIAL',
          tempId: randomId,
          name: this.state.name,
          area: {
            startRadius: this.state.startRadius,
            endRadius: this.state.endRadius,
            unit: 'KILOMETRE',
            latitude: selectedLocation.lat,
            longitude: selectedLocation.lng,
          },
        })
      } else {
        const radialZone = tempDeliveryArea.find(
          (zone) => zone.id === editId || zone.tempId === editId
        )
        if (radialZone) {
          const newTempDeliveryArea = tempDeliveryArea.filter(
            (zone) => zone.id === editId || zone.tempId === editId
          )
          const newRadialArea = radialZone.area
          newRadialArea.startRadius = this.state.startRadius
          newRadialArea.endRadius = this.state.endRadius
          newRadialArea.latitude = selectedLocation.lat
          newRadialArea.longitude = selectedLocation.lng
          radialZone.area = newRadialArea
          radialZone.name = this.state.name
          newTempDeliveryArea.push(radialZone)
        } else {
          const newRadialZone = deliveryarea.find((zone) => zone.id === editId)
          const newRadialArea = newRadialZone.area
          newRadialArea.startRadius = this.state.startRadius
          newRadialArea.endRadius = this.state.endRadius
          newRadialArea.latitude = selectedLocation.lat
          newRadialArea.longitude = selectedLocation.lng
          newRadialZone.area = newRadialArea
          newRadialZone.name = this.state.name
          tempDeliveryArea.push(newRadialZone)
        }
      }
      parent.setState({ tempDeliveryArea })
      this.clearTempData()
    }
  }
  onPlacesSearched(data) {
    if (data && data[0] && data[0].geometry && data[0].geometry.location) {
      const location = data[0].geometry.location
      this.props.setNewLocation(location.lat(), location.lng())
    }
  }
  clearTempData() {
    this.inputLocation.value = ''
    this.setState({
      startRadius: '',
      endRadius: '',
      name: '',
      mode: 'add',
      radialZoneEditId: null,
    })
    this.props.deleteSelectedLocation()
  }
  setStartEndRadius(startRadius, endRadius) {
    this.setState({ startRadius, endRadius })
  }
  setZoneName(name) {
    this.setState({ name })
  }
  changeMode(mode) {
    this.setState({ mode })
  }
  changeRadialZoneEditId(id) {
    this.setState({ radialZoneEditId: id })
  }
  handleZoneDelete(id) {
    if (id === this.state.radialZoneEditId) {
      this.clearTempData()
    }
    this.props.handleZoneDelete(id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedLocation } = nextProps
    selectedLocation && this.setState({ showLocationError: false })
  }

  render() {
    const { active, radialArea, editing, distinctColors } = this.props
    return (
      <div
        className={`radial-form zones-subsection ${
          active ? '' : 'hidden-section'
        }`}
      >
        <span
          onClick={() => this.props.handleOpenClose('radial')}
          className="section-title"
        >
          {getMessage('extensions.deliveryArea.radial.title')}
        </span>
        <span
          onClick={() => this.props.handleOpenClose('radial')}
          className="open-close-icon"
        >
          {active ? '\u2013' : '+'}
        </span>
        <div className="zones-content">
          {editing && (
            <div className="location-search">
              <Input
                placeholder="Enter zone name"
                name="zone-name"
                type="text"
                value={this.state.name}
                onChange={(name) => this.setState({ name })}
              />
              <PlacesWithStandaloneSearchBox
                element={
                  <input
                    placeholder="Search location"
                    name="landmark"
                    type="text"
                    ref={(el) => {
                      this.inputLocation = el
                    }}
                  />
                }
                onPlacesSearched={(data) => this.onPlacesSearched(data)}
              />
              <div className="flex">
                <Input
                  placeholder="0"
                  className="distance-value"
                  name="startRadius"
                  type="number"
                  onKeyPress={this.onKeyPress}
                  value={this.state.startRadius}
                  onChange={(value) => this.setState({ startRadius: value })}
                />
                <div className="distance-middle-text">
                  {getMessage('radial.form.to')}
                </div>
                <Input
                  placeholder="0"
                  className="distance-value"
                  name="endRadius"
                  type="number"
                  onKeyPress={this.onKeyPress}
                  value={this.state.endRadius}
                  onChange={(value) => this.setState({ endRadius: value })}
                />
              </div>
              <div className="distance-description">
                {getMessage('radial.form.distance.description')}
              </div>
              {this.state.showLocationError && (
                <div className="text-muted map-description">
                  <small>
                    {getMessage(
                      'extensions.deliveryArea.radial.location.error'
                    )}
                  </small>
                </div>
              )}

              {this.state &&
                (this.state.startRadius || this.state.startRadius === '0') &&
                (this.state.endRadius || this.state.endRadius === '0') &&
                this.props.selectedLocation && (
                  <div className="flex">
                    <button
                      onClick={this.handleSaveData}
                      className="button primary update-group"
                    >
                      {this.state.mode === 'add' ? 'Add' : 'Update'}
                    </button>
                    <button
                      onClick={this.clearTempData}
                      className="button cancel-update"
                    >
                      {this.state.mode === 'add' ? 'Clear' : 'Cancel'}
                    </button>
                  </div>
                )}
            </div>
          )}
          {/* <label htmlFor='deliveryFeeRadial'>{getMessage('radial.form.deliveryCharge.heading')}</label>
          <Input
            placeholder={`${getCurrency().symbol}0`}
            className='delivery-fee'
            name='deliveryFeeRadial'
            type='number'
            // error={negDeliveryFeeErr}
            // {...parent.generateStateMappers({
            //   stateKeys: ['deliveryFee'],
            //   validationType: VALIDATION_TYPES.ONCHANGE
            // })}
          /> */}
          <div className="radial-zones-list">
            {radialArea &&
              radialArea.map((zone, index) => {
                return (
                  <div key={`radial-zone-item-${index}`} className="list-item">
                    <span
                      style={{
                        backgroundColor:
                          distinctColors[
                            distinctColors.length -
                              1 -
                              (index % distinctColors.length)
                          ].value,
                      }}
                    />
                    {zone.name && `${zone.name} (`}
                    {typeof zone.area.startRadius === 'string'
                      ? zone.area.startRadius.replace(/\.00$/, '')
                      : zone.area.startRadius}{' '}
                    -{' '}
                    {typeof zone.area.endRadius === 'string'
                      ? zone.area.endRadius.replace(/\.00$/, '')
                      : zone.area.endRadius}{' '}
                    KM{`${zone.name ? ')' : ' from the search location'}`}
                    {editing && (
                      <span className="delete-radial">
                        <img
                          src={deleteIcon}
                          onClick={() => {
                            this.handleZoneDelete(zone.id || zone.tempId)
                          }}
                          alt="delete"
                        />
                      </span>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}
