import React from 'react'
import { SingleDatePicker, TimePicker } from '../../Form'
import { getMessage } from '../../../lib/translator'
import moment from 'moment'

const CampaignDuration = props => {
  const { _this, type, method } = props
  return (
    <div className="campaign-duration">
      <div className="grid">
        <SingleDatePicker
          enableToday
          name="campaign-date"
          required
          label={
            type === 'start'
              ? getMessage('campaign.startDate')
              : getMessage('campaign.endDate')
          }
          {..._this.generateStateMappers({
            stateKeys: type === 'start' ? ['startDate'] : ['endDate'],
            loseEmphasisOnFill: true,
          })}
          disabled={
            method === 'edit' &&
            (type === 'start'
              ? moment().isAfter(_this.state.values.startAt)
              : moment().isAfter(_this.state.values.endAt))
          }
        />
        <TimePicker
          name="campaign-time"
          label={
            type === 'start'
              ? getMessage('campaign.startTime')
              : getMessage('campaign.endTime')
          }
          required
          placeholder={getMessage('omnichallenge.time.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: type === 'start' ? ['startTime'] : ['endTime'],
            loseEmphasisOnFill: true,
          })}
          disabled={
            method === 'edit' &&
            (type === 'start'
              ? moment().isAfter(_this.state.values.startAt)
              : moment().isAfter(_this.state.values.endAt))
          }
        />
      </div>
    </div>
  )
}
export default CampaignDuration
