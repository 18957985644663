import API from '../../../lib/api'
import moment from 'moment'

function getAdvertiserClient(_this, campaignId){
  let advertiserList = []
  if(campaignId){
    advertiserList = JSON.parse(window.sessionStorage.getItem('mediaAdvertiserClients_onecampaign'))
  }else{
    advertiserList = JSON.parse(window.sessionStorage.getItem('mediaAdvertiserClients'))
  }

  if (advertiserList && advertiserList.length > 0) {
    _this.setState({
      advertiserList,
    })
  } else {
    _this.api = new API({ url: '/martech-ad-service/advertiserClient' })
    _this.api.get().then(
      response => {
        advertiserList = response.data
        window.sessionStorage.setItem('mediaAdvertiserClients', JSON.stringify(advertiserList))
        _this.setState({
          advertiserList,
        })
      }
    )
  }
}

function formattedadvertiserList(_adList){
  return _adList ? _adList.map(advertiser => {
    let text = {}

    if (advertiser.vendorCode){
      text = `${advertiser.advertiserName} - ${advertiser.vendorCode}`
    } else {
      text = advertiser.advertiserName
    }
    return {
      text: text,
      value: advertiser.id,
    }
  }) : []
}

function getAgents(_this) {
  let resellAgentList = JSON.parse(window.sessionStorage.getItem('mediaResellAgents'))
  if (resellAgentList && resellAgentList.length > 0) {
    _this.setState({
      resellAgentList,
    })
  } else {
    _this.api = new API({ url: '/martech-ad-service/agent' })
    _this.api.get().then(
      response => {
        resellAgentList = response?.data
        window.sessionStorage.setItem('mediaResellAgents', JSON.stringify(resellAgentList))
        _this.setState({
          resellAgentList,
        })
      }
    )
  }
}

function formattedresellAgentList(_List){
  return _List.map(item => {
    return {
      text: item.agentName,
      value: item.id,
    }
  })
}


function getPackages(_this) {
  const _packagesObj = JSON.parse(window.sessionStorage.getItem('mediaPackages'))
  if (_packagesObj) {
    const packageList = _packagesObj.packageList
    const _timediff = moment.duration(moment().diff(moment(_packagesObj.time))).asSeconds()
    if(packageList.length > 0 && _timediff <= 60){
      _this.setState({
        packageList,
      })
    }else{
      updatePackages(_this)
    }
  } else {
    updatePackages(_this)
  }
}

function updatePackages(_this) {
  _this.packageApi = new API({ url: '/martech-ad-service/packages' })
  _this.packageApi.get().then(
    response => {
      const packageList = response.data
      _this.setState({
        packageList,
      })
      const _packagesObj = {
        packageList,
        time: moment().format('YYYY-MM-DD HH:mm:ss')
      }
      window.sessionStorage.setItem('mediaPackages', JSON.stringify(_packagesObj))
    }
  )
}

function formattedMediaPackageList(_List){
  return _List ? _List.map(item => {
    return {
      text: item.packageName,
      value: item.id,
    }
  }) : []
}

function formattedMediaPackageList_toString(_List){
  return _List ? _List.map(item => {
    return {
      text: item.packageName,
      value: String(item.id),
    }
  }) : []
}

function getClassGLCodeList(_this){
  _this.api = new API({ url: '/martech-ad-service/class-gl-code'})
  _this.api.get().then(response => {
    const formattedClassGLCodeList = formatClassGLCodeList(response.data)
    _this.setState({
      formattedClassGLCodeList: formattedClassGLCodeList,
    })
  })
}

function formatClassGLCodeList(_List){
  return _List ? _List.map(item => {
    return {
      text: item.name,
      value: item.id,
    }
  }) : []
}

export {
  formattedadvertiserList,
  getAdvertiserClient,
  getAgents,
  formattedresellAgentList,
  getPackages,
  updatePackages,
  formattedMediaPackageList,
  formattedMediaPackageList_toString,
  getClassGLCodeList,
}
