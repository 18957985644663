import React from 'react'
import { Radio, Select } from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'

export default function Promotion({
  entityIds,
  entityType,
  handleEntityTypeOnchange,
  isNewOffer,
  applicableOffers,
  generateStateMappers,
  handleOfferTypeOnchange,
}) {
  return (
    <div className="form-fields">
        <Radio
          name="entity-type"
          key={'entity-type'}
          label={getMessage('offer.entity')}
          options={entityIds}
          value={entityType}
          onChange={handleEntityTypeOnchange}
          readOnly={!isNewOffer}
        />
      <Select
        name="offer-type"
        key="type"
        testid="select-offer-type"
        label={getMessage('offer.type')}
        placeholder={getMessage('offer.type.placeholder')}
        options={applicableOffers}
        {...generateStateMappers({
          stateKeys: ['type'],
          loseEmphasisOnFill: true,
        })}
        onChange={handleOfferTypeOnchange}
        disabled={!isNewOffer}
      />
    </div>
  )
}
