import React from 'react'
import Loader from 'components/Loader'
import API from 'lib/api'
import { BaseForm } from '../../../../components/Form'
import AddCreativeFilter from './addCreativeFilter'
import EmptyCreative from './emptyCreative'
import CreativeTable from './creativeTable'
import { getMessage } from 'lib/translator'
import { throwError} from '../../commonMedia'

function getMobileView() {
  return window.screen.width <= 480
}

export default class AddCreativeForm extends BaseForm {
  constructor(props) {
    super(props)

    const filterData = {
      pageSize: 10,
      creativeName: '',
      status: 'Complete',
      packageId: this.props.packageId,
    }

    this.state = {
      creativeList: [],
      isMobileView: getMobileView(),
      optionSelected: undefined,
      filterData: filterData,
      emptyState: true,
      paging: {},
      creativeAssignment: undefined,
    }
    this.mobileView = this.mobileView.bind(this)
    this.changePage = this.changePage.bind(this)
    this.assignLineItem = this.assignLineItem.bind(this)
    this.unassignLineItem = this.unassignLineItem.bind(this)
    this.getCreativeAssignment = this.getCreativeAssignment.bind(this)
    this.updateCreativeAssignment = this.updateCreativeAssignment.bind(this)
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
    this.getCreativeList()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.mobileView, false)
    this.api && this.api.cancel()
  }

  toggleCheckbox(e) {
    if (this.state.optionSelected === e.id) {
      this.setState({
        optionSelected: undefined,
      })
      this.unassignLineItem()
    } else {

      this.setState({ optionSelected: e.id })
      if (this.state.creativeAssignment) {
        this.updateCreativeAssignment(e.id)
      } else {
        this.assignLineItem(e.id)
      }
    }
  }

  creativeNameChanged(value) {
    const filterData = {
      pageSize: 10,
      creativeName: value,
    }

    this.setState({
      filterData,
    })
  }

  changePage(_page) {
    this.getCreativeList(_page)
  }

  clearAll() {

    const filterData = {
      pageSize: 10,
      creativeName: '',
      page: 1,
      packageId: this.props?.packageId,
    }
    this.setState(
      {
        filterData,
      },
      () => {
        this.getCreativeList()
      }
    )
  }

  getCreativeList(_page) {
    this.setState({
      showLoader: true,
    })

    const creativeNameParam = this.state.filterData?.creativeName
    const pageSize = this.state.filterData?.pageSize
    const status = this.state.filterData?.status
    const packageId = this.state.filterData?.packageId

    this.creativeAPI = new API({
      url: `/martech-creative-service/creative`,
    })
    this.creativeAPI
      .get({
        pageSize: pageSize,
        creativeName: creativeNameParam,
        page: _page || 1,
        status: status,
        packageId: packageId,
      })
      .then(response => {
        this.setState(
          {
            creativeList: response?.data?.content,
            paging: {
              count: response?.data?.count,
              offset: response?.data?.offset,
              limit: response?.data?.limit,
            },
            showLoader: false,
          },
          () => {
            if (this.state.creativeList.length > 0) {
              this.setState({
                emptyState: false,
              })
            }
          }
        )
        this.getCreativeAssignment()
      })
      .catch(error => {
        throwError(this, error)
      })
  }

  assignLineItem(creativeId) {

    this.setState(
      { showLoader: true }
    )

    this.creativeAPI = new API({
      url: `/martech-creative-service/creative/${creativeId}/line-item/${this.props.lineItemId}`,
    })

    this.creativeAPI.post({}).then(response =>{
      this.setState({
        creativeAssignment: response?.data,
        showLoader: false,
      })

    }).catch(error => {
      this.setState({
        showLoader: false,
      },() => {throw error})
    })
  }

  getCreativeAssignment() {
    this.creativeAPI = new API({
      url:
        `/martech-creative-service/creative-assignment/line-item/` +
        this.props.lineItemId,
    })
    this.creativeAPI
      .get({})
      .then(response => {
        this.setState({
          creativeAssignment: response?.data,
          optionSelected: response?.data?.creative_id,
        })
      })
      .catch(error => {
        throwError(this, error)
      })
  }

  updateCreativeAssignment(creativeId) {

    this.setState({
      showLoader: true,
    })

    if (this.state.creativeAssignment) {
      const body = {
        id: this.state.creativeAssignment.id,
        creative_id: creativeId,
        assigned_line_item_id: Number(this.props.lineItemId),
      }

      this.creativeAPI = new API({
        url: `/martech-creative-service/creative-assignment/`+ this.state.creativeAssignment.id,
      })
      this.creativeAPI
        .put(body)
        .then(response => {
           this.setState({
             creativeAssignment: response?.data,
             showLoader: false,
           })
         })
         .catch(error => {
           this.setState({
            showLoader: false,
           },()=>{throw error})
         })
    }
  }

  unassignLineItem() {
    this.setState({
      showLoader: true,
    })

    this.creativeAPI = new API({
      url: `/martech-creative-service/creative-assignment/`+ this.state.creativeAssignment.id,
    })
    this.creativeAPI.delete({}).then(() =>{
      this.setState({
        creativeAssignment: undefined,
        showLoader: false,
      })
    }).catch(error => {
      this.setState({
        showLoader: false,
      },() => {throw error})
    })
  }

  render() {
    const {
      creativeList,
      paging,
      isMobileView,
      emptyState,
      filterData,
    } = this.state

    return (
      <div className="website-page-edit">
        {!emptyState ? (
          <React.Fragment>
            <div>
              <p>
                <small>
                  &#9432; <b>{getMessage('media.creative.add.info.message')}</b>
                </small>
              </p>
            </div>
            <AddCreativeFilter
              value={filterData}
              creativeNameChanged={value => this.creativeNameChanged(value)}
              onSubmit={() => {
                this.getCreativeList()
              }}
              onClear={() => {
                this.clearAll()
              }}
            />

            {this.state.showLoader ? (
              <Loader />
            ) : (
              <CreativeTable
                creativeList={creativeList}
                paging={paging}
                isMobileView={isMobileView}
                changePage={this.changePage}
                _this={this}
              />
            )}
          </React.Fragment>
        ) : (
          <EmptyCreative />
        )}
      </div>
    )
  }
}
