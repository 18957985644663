import React from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  ProductSearch,
} from '../../../../components/Form'
import API, { UNAUTHORISED_STATUS } from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'

import './style.css'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { getProductImage, getProductName } from '../../../../lib/commonlyused'
import Image from '../../../../components/Image'
import DeleteIcon from './delete.svg'

const ProductList = props => {
  const { products, onDelete, readOnly } = props
  return (
    <Table>
      {(products || []).length > 0 && (
        <Header>
          <Cell>{getMessage('Image')}</Cell>
          <Cell>{getMessage('Name')}</Cell>
          <Cell />
        </Header>
      )}
      {(products || []).map((product, index) => (
        <Row className="pdt-row" key={product.id}>
          <Cell>
            <Image size="sm" src={getProductImage(product)} />
          </Cell>
          <Cell>{getProductName(product)}</Cell>
          <Cell>
            <button
              className={`del-button ${readOnly ? 'hide' : 'show'}`}
              type="button"
              onClick={() => onDelete(index)}
            >
              <img src={DeleteIcon} alt="delete" />
            </button>
          </Cell>
        </Row>
      ))}
    </Table>
  )
}

export default class SubstitutionGroupForm extends BaseForm {
  constructor(props) {
    super(props)
    this.delete = this.delete.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    this.addProduct = this.addProduct.bind(this)
    this.addSubstitutedProduct = this.addSubstitutedProduct.bind(this)
    this.onSubstitutedDelete = this.onSubstitutedDelete.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  componentDidMount() {
    const { id } = this.state.values
    this.getProducts(id)
      .catch(e => {
        this.setState({
          error: e,
        })
        if (e.code === UNAUTHORISED_STATUS) {
          throw e
        }
      })
      .finally(() =>
        this.setState({
          loading: false,
        })
      )
  }

  delete() {
    this.props.onDelete()
  }

  changeStatus(status) {
    const { value } = this.props
    if (value && value.id) {
      this.props.onSubmit({ id: value.id, status: status })
    }
  }

  addProduct(product) {
    const values = JSON.parse(JSON.stringify(this.state.values))
    const pdts = (values.products || []).slice()
    if (pdts.filter(p => p.id === product[0].id).length > 0) {
      return null
    }
    pdts.push(product[0])
    values.products = pdts
    this.setState({
      values,
    })
  }

  addSubstitutedProduct(product) {
    const values = JSON.parse(JSON.stringify(this.state.values))
    values.substitutedProduct = product
    this.setState({
      values,
    })
  }

  onDelete(index) {
    const values = JSON.parse(JSON.stringify(this.state.values))
    const products = values.products
    products.splice(index, 1)
    values.products = products
    this.setState({
      values,
    })
  }
  onSubstitutedDelete() {
    const values = JSON.parse(JSON.stringify(this.state.values))
    delete values.substitutedProduct
    this.setState({
      values,
    })
  }

  getProducts(id) {
    const productApi = new API({
      url: `/catalogue-service/product`,
    })
    if (id) {
      const values = JSON.parse(JSON.stringify(this.state.values))
      return productApi.get({ paginate: 'false', id: id }).then(response => {
        values.substitutedProduct = response.data.product || []
        this.setState({
          values,
        })
      })
    }
    return Promise.resolve()
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const { props } = this
    const { products, substitutedProduct } = this.state.values

    return (
      <div className="substitution-groups-form">
        <Form>
          <Input
            label={getMessage('substitutionGroup.form.name.heading')}
            placeholder={getMessage('substitutionGroup.form.name.placeholder')}
            name="name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ['name'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
              rangeOverflow: getMessage('input.rangeOverflow'),
              rangeUnderflow: getMessage('input.rangeUnderflow'),
            }}
          />
          <ProductSearch
            label={'Original Product'}
            getVariantData={false}
            name="substitutedProduct"
            required={
              !(Array.isArray(substitutedProduct) && substitutedProduct.length)
            }
            readOnly={
              Array.isArray(substitutedProduct) && substitutedProduct.length
            }
            {...this.generateStateMappers({
              stateKeys: ['substitutedProduct'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
            }}
            onChange={this.addSubstitutedProduct}
          />
          <ProductList
            products={
              substitutedProduct instanceof Array
                ? substitutedProduct
                : substitutedProduct
                ? [substitutedProduct]
                : undefined
            }
            onDelete={this.onSubstitutedDelete}
            readOnly={
              substitutedProduct instanceof Array && substitutedProduct.length
            }
          />
          <Input
            label={getMessage(
              'substitutionGroup.form.percentageDeviation.heading'
            )}
            placeholder={getMessage(
              'substitutionGroup.form.percentageDeviation.placeholder'
            )}
            name="percentage-deviation"
            type="number"
            required
            multiple
            min={0}
            max={100}
            {...this.generateStateMappers({
              stateKeys: ['percentageDeviation'],
              formatChange: Number,
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
            }}
          />
          <ProductSearch
            label={'Substitution Products'}
            getVariantData={false}
            multiple
            name="products"
            onChange={this.addProduct}
          />
          <ProductList products={products} onDelete={this.onDelete} />
          <div className={`form-actions ${props.method}`}>
            <div className="form-buttons">
              <SubmitButton>
                {getMessage('substitutionGroup.form.submitText')}
              </SubmitButton>
              <CancelButton>
                {getMessage('substitutionGroup.form.cancelText')}
              </CancelButton>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}
