import React from 'react'
import { getMessage } from '../../../../lib/translator'
import {
  BaseForm,
  Input,
  Select,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import { BUList } from '../../MediaAssetManagement/businessUnitList'
import './style.css'

export default class AssetTypeForm extends BaseForm {
  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    return (
      <Form className="website-page-form">
        <div className="section-wrap">
          <div className="section-row">
            <Input
              className="section-row-input"
              label={getMessage('media.assetDetail.assettype.name')}
              placeholder={getMessage(
                'media.assetDetail.assettype.name.placeholder'
              )}
              name="assetType_name"
              type="text"
              required
              {...this.generateStateMappers({
                stateKeys: ['assetType_name'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage(
                  'media.assetDetail.assettype.name.requiredMessage'
                ),
              }}
            />
          </div>
          <div className="section-row">
            <Select
              className="section-row-input"
              label={getMessage('media.assetDetail.ownerBu')}
              placeholder={getMessage('media.assetDetail.ownerBu.placeholder')}
              options={BUList}
              name="assetType_bu"
              {...this.generateStateMappers({
                stateKeys: ['assetType_bu'],
                loseEmphasisOnFill: true,
              })}
              required
              validationStrings={{
                valueMissing: getMessage(
                  'media.assetDetail.ownerBu.requiredMessage'
                ),
              }}
            />
          </div>
        </div>

        <div className="form-buttons">
          <SubmitButton>
            {getMessage('websitePage.form.submitText')}
          </SubmitButton>
          <CancelButton>
            {getMessage('websitePage.form.cancelText')}
          </CancelButton>
        </div>
      </Form>
    )
  }
}
