import React from 'react'
import Searchable from '../Searchable'
import Select from '../Select'

import './style.css'

const getChain = (category) => {
  const chain = []
  let reference = category
  while (reference) {
    const name = reference.name ? reference.name : ''
    const id = reference.id ? ` (${reference.id}) ` : ''
    const result = name + id
    chain.push(result)
    reference = reference.parentCategory
  }
  return chain.toReversed().join(' > ')
}

export default class CategorySearch extends React.Component {
  constructor(props) {
    super(props)
    this.selectOptions = [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Id',
        value: 'id',
      },
    ]
    this.state = {
      selectionKey: 'name',
    }
    this.handleChange = this.handleChange.bind(this)
    this.runValidation = this.runValidation.bind(this)
  }

  runValidation(value) {
    this.props.onValidation && this.props.onValidation(value)
  }

  handleChange(e) {
    this.setState({
      selectionKey: e,
    })
  }

  render() {
    const { selectionKey } = this.state
    const { name, readOnly, withOptions } = this.props
    const newProps = Object.assign({}, this.props, {
      searchUrl: '/catalogue-service/category',
      valueKey: 'id',
      nameKey: 'displayName',
      searchKey: selectionKey,
      expandMore: true,
      displaySelectedValue: (category) => {
        return this.props.dontShowChain ? category.name : getChain(category)
      },
      transformRequest: (searchText, paramsDefault) => {
        const params = Object.assign({}, paramsDefault)
        if (params.name) {
          params.name = `%${params.name}`
        }
        return params
      },
      transformResponse: (response) => {
        return (
          this.props.appendElement ? this.props.appendElement : []
        ).concat(
          response.data.category.map((category) => {
            category.displayName = getChain(category)
            return category
          })
        )
      },
    })
    return (
      <div className="Category-search">
        {withOptions && (
          <Select
            className="category-select"
            name={`${name}-category-search-select`}
            placeholder={'Search by'}
            options={this.selectOptions}
            value={selectionKey}
            onChange={this.handleChange}
            disabled={readOnly}
          />
        )}
        <Searchable {...newProps} />
      </div>
    )
  }
}
