import React, { useMemo } from 'react'
import { getCustomPrintableTime } from 'lib/datetime'
import { getMessage } from 'lib/translator'
import { Row, Cell } from '../../../components/Table'
import { PayloadViewPopup } from './PayloadViewPopup'
import { upperCase } from 'lodash'
export const TableRow = (props) => {
  const {
    userid,
    email,
    endpoint,
    method,
    actionType,
    timestamp,
    actionPayload,
  } = props

  const timeStampDisplay = useMemo(() => {
    const d = getCustomPrintableTime(timestamp).split(',')

    return {
      date: d[1].trim(),
      time: d[2].trim(),
    }
  }, [timestamp])

  /**
   * `actionPayload` is a stringified object,
   *  if failed to parsed or malformd json object, return empty object
   */
  const payload = useMemo(() => {
    let data = {}
    try {
      data = JSON.parse(actionPayload)
    } catch (err) {
      data = {}
    }
    return data
  }, [actionPayload])

  return (
    <Row>
      <Cell>{userid}</Cell>
      <Cell>
        <div className="wrap-cell">{email}</div>
      </Cell>
      <Cell>{actionType}</Cell>
      <Cell className="column-timestamp">
        <div> {timeStampDisplay.date}</div>
        <span> {timeStampDisplay.time}</span>
      </Cell>
      <Cell>
        <div className="wrap-cell">{endpoint}</div>
      </Cell>
      <Cell>{upperCase(method)}</Cell>
      <Cell>
        <PayloadViewPopup actionPayload={payload} actionType={actionType} />
      </Cell>
    </Row>
  )
}

export const tableProperties = () => {
  return {
    headers: [
      getMessage('action-trail.table.header.userId'),
      getMessage('action-trail.table.header.email'),
      getMessage('action-trail.table.header.actionType'),
      getMessage('action-trail.table.header.dateTime'),
      getMessage('action-trail.table.header.endpoint'),
      getMessage('action-trail.table.header.method'),
      getMessage('action-trail.table.header.actions'),
    ],
    row: (props) => <TableRow {...props} />,
  }
}
