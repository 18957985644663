import React from 'react'
import {
  BaseForm,
  Radio,
  MultiTextInput,
  ProductSearch,
  CategorySearch,
  BrandSearch,
  Searchable,
  SingleDatePicker,
  Select,
} from '../../../../components/Form'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import Table, { Row, Header, Cell } from '../../../../components/Table'
import { getMessage } from '../../../../lib/translator'
import Image from '../../../../components/Image'
import { getProductName, getProductImage } from '../../../../lib/commonlyused'

import DeleteIcon from '../../../hr/UserShifts/Form/delete.svg'
import { isExtensionEnabled } from '../../../../lib/auth'
import { getTimes } from '../../../../lib/datetime'

const SortableItem = SortableElement((props) => {
  const { product, ind, onDelete } = props
  return (
    <Row className="pdt-row">
      <Cell>
        <Image size="sm" src={getProductImage(product)} />
      </Cell>
      <Cell>{getProductName(product)}</Cell>
      <Cell>{ind + 1}</Cell>
      <Cell>
        <button
          className="del-button"
          type="button"
          onClick={() => onDelete(ind)}
        >
          <img src={DeleteIcon} alt="delete" />
        </button>
      </Cell>
    </Row>
  )
})

const SortableList = SortableContainer((props) => {
  const products = props.products || []
  return (
    <Table>
      {products.length > 0 && (
        <Header>
          <Cell>{getMessage('Image')}</Cell>
          <Cell>{getMessage('Name')}</Cell>
          <Cell>{getMessage('Position')}</Cell>
          <Cell />
        </Header>
      )}
      {products.map((pdt, i) => (
        <SortableItem
          key={`product-${i}`}
          product={pdt}
          index={i}
          ind={i}
          onDelete={props.onDelete}
        />
      ))}
    </Table>
  )
})

class SearchConfigForm extends BaseForm {
  constructor(props) {
    super(props)
    this.addProduct = this.addProduct.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)

    this.options = [
      {
        text: 'Search Term',
        value: 'SEARCH',
      },
      {
        text: 'Category',
        value: 'CATEGORY',
      },
    ]
    if (isExtensionEnabled('MultiBrandSupport')) {
      this.options.push({
        text: 'Brand',
        value: 'BRAND',
      })
    }
    if (isExtensionEnabled('ProductTagSupport')) {
      this.options.push({
        text: 'Tag',
        value: 'TAG',
      })
    }
  }

  beforeSubmit() {
    const validations = Object.assign({}, this.state.validations)
    const values = Object.assign({}, this.state.values)
    if (values.pageType === 'SEARCH') {
      delete validations.category
    } else {
      delete validations.url
    }
    this.setState({
      validations,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageType !== this.state.pageType) {
      const validations = Object.assign({}, this.state.validations)
      const values = Object.assign({}, this.state.values)
      if (values.pageType === 'SEARCH') {
        delete validations.category
      } else {
        delete validations.url
      }
      this.setState({
        validations,
      })
    }
  }

  componentDidMount() {
    if (!this.state.values.pageType) {
      const values = Object.assign({}, this.state.values)
      values.pageType = 'SEARCH'
      this.setState({
        values,
      })
    }
  }

  addProduct(product) {
    const values = Object.assign({}, this.state.values)
    const pdts = (values.products || []).slice()
    if (!pdts.find((p) => p.id === product.id)) {
      pdts.push(product)
      values.products = pdts
      this.setState({
        values,
      })
    }
  }

  onSortEnd({ oldIndex, newIndex }) {
    const values = Object.assign({}, this.state.values)
    const products = values.products
    const newProducts = arrayMove(products, oldIndex, newIndex)
    values.products = newProducts
    this.setState({
      values,
    })
  }

  onDelete(index) {
    const values = Object.assign({}, this.state.values)
    const products = values.products
    products.splice(index, 1)
    values.products = products
    this.setState({
      values,
    })
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const { pageType, products } = this.state.values
    const searchPage = pageType === 'SEARCH'
    const categoryPage = pageType === 'CATEGORY'
    const brandPage = pageType === 'BRAND'
    const tagPage = pageType === 'TAG'
    const readonly = this.props.method !== 'add'
    return (
      <div className="search-config-form">
        <Form>
          {this.props.method === 'add' && (
            <Radio
              label={getMessage('search-configuration.form.label.select.type')}
              name="type"
              options={this.options}
              readOnly={readonly}
              {...this.generateStateMappers({
                stateKeys: ['pageType'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <div className="flex">
            <div className="flex no-margin">
              <SingleDatePicker
                name="validFromDate"
                label={getMessage('valid from')}
                placeholder={getMessage('Date')}
                enableToday
                {...this.generateStateMappers({
                  stateKeys: ['validFromDate'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Select
                name="validFromTime"
                label={getMessage('time')}
                placeholder={getMessage('Select time')}
                options={getTimes()}
                {...this.generateStateMappers({
                  stateKeys: ['validFromTime'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <div className="flex no-margin">
              <SingleDatePicker
                name="validToDate"
                enableToday
                label={getMessage('valid to')}
                placeholder={getMessage('Date')}
                {...this.generateStateMappers({
                  stateKeys: ['validToDate'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Select
                name="validToTime"
                label={getMessage('time')}
                placeholder={getMessage('Select time')}
                options={getTimes()}
                {...this.generateStateMappers({
                  stateKeys: ['validToTime'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>
          {searchPage && (
            <MultiTextInput
              name="search-urls"
              label={getMessage('search-config.searchurls')}
              placeholder={getMessage('search-config.searchurls.placeholder')}
              {...this.generateStateMappers({
                stateKeys: ['url'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {categoryPage && (
            <CategorySearch
              label={getMessage('sc.form.category.label')}
              placeholder={getMessage('sc.form.cat.placeholder')}
              name="category"
              multiple
              {...this.generateStateMappers({
                stateKeys: ['category'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {brandPage && (
            <BrandSearch
              name="brand"
              multiple
              label={getMessage('brand')}
              placeholder={getMessage('Enter brands')}
              {...this.generateStateMappers({
                stateKeys: ['brand'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {tagPage && (
            <Searchable
              name="tag"
              multiple
              expandMore={true}
              searchUrl="/catalogue-service/tag"
              transformResponse={(response) => response.data.tag}
              searchKey="name"
              valueKey="id"
              nameKey="name"
              label={getMessage('tag')}
              placeholder={getMessage('Enter tags')}
              {...this.generateStateMappers({
                stateKeys: ['tag'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {
            <ProductSearch
              className="search-product"
              label="Product"
              getVariantData={false}
              onChange={this.addProduct}
            />
          }
          <SortableList
            products={products}
            onDelete={this.onDelete}
            onSortEnd={this.onSortEnd}
            pressDelay={200}
            helperClass="sortableSearchPageHelper"
            axis="y"
          />
          <div className="form-action actions">
            <CancelButton>
              {getMessage('search-configuration.form.cancel')}
            </CancelButton>
            <SubmitButton>{getMessage('Save')}</SubmitButton>
          </div>
        </Form>
      </div>
    )
  }
}

export default SearchConfigForm
