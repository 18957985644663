import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../Table'
import { DropDown, DropDownItem, ICONS } from '../../DropDown'
import moment from 'moment'
import { getMessage } from '../../../lib/translator'
import { DATE_TIME_FORMAT, getStatusText } from '../utils'

export const tableProperties = {
  headers: [
    'ID',
    'Title',
    'Home Deck Rank',
    'Home Swimlane Rank',
    'Category Tab Rank',
    'Publish Date',
    'Status',
    'Actions',
  ],
  row: ({ id, name, publishAt, status, metadata: { placementRanking } }) => (
    <Row>
      <Cell className="rewards-page-actions column-id">
        <Link to={`/rewards/categories/edit/${id}`}>{id}</Link>
      </Cell>
      <Cell className="rewards-page-actions column-name">
        <Link to={`/rewards/categories/edit/${id}`}>{name}</Link>
      </Cell>
      <Cell className="rewards-page-actions column-rank homeDeck">
        {placementRanking?.homeDeck}
      </Cell>
      <Cell className="rewards-page-actions column-rank homeSwimlane">
        {placementRanking?.homeSwimlane}
      </Cell>
      <Cell className="rewards-page-actions column-rank catalogueTab">
        {placementRanking?.catalogueTab}
      </Cell>
      <Cell className="rewards-page-actions column-date">
        {moment(publishAt).format(DATE_TIME_FORMAT)}
      </Cell>
      <Cell className="rewards-page-actions column-status">
        {getStatusText(status)}
      </Cell>
      <Cell className="rewards-page-actions column-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <Link to={`/rewards/categories/edit/${id}`}>
            <DropDownItem>{getMessage('rewards.category.edit')}</DropDownItem>
          </Link>
        </DropDown>
      </Cell>
    </Row>
  ),
}
