import React, { Fragment } from 'react'
import { Input, BaseForm } from '../../Form'
import { getMessage } from 'lib/translator'
import SearchChallenges from 'components/OmniChallenge/SearchChallenges'

class Filters extends BaseForm {
  constructor(props) {
    super(props)
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    const { type } = this.props.options
    const name = getMessage('rewards.name')
    return (
      <Form>
        <div className="form-fields">
          {['device', 'merchant'].includes(type) && (
            <Input
              id="q"
              label={getMessage('rewards.searchBy')}
              placeholder={getMessage(`rewards.${type}.search`)}
              name="q"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['q'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {type === 'partner' && (
            <Input
              id="name"
              label={name}
              placeholder={name}
              name="name"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['name'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {['promotion', 'store', 'catalogue'].includes(type) && (
            <Fragment>
              <Input
                id="name"
                label={name}
                placeholder={name}
                name="name"
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ['name'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                id="name"
                label={getMessage('rewards.partnerName')}
                placeholder={getMessage('rewards.partnerName')}
                name="name"
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ['partnerName'],
                  loseEmphasisOnFill: true,
                })}
              />
            </Fragment>
          )}
          {type === 'challenges' && (
            <SearchChallenges
              generateStateMappers={this.generateStateMappers}
            />
          )}
        </div>
        <SubmitButton testid="submit-filter">
          {getMessage('rewards.form.submit')}
        </SubmitButton>
        <ClearButton testid="clear-filter">
          {getMessage('rewards.form.clear')}
        </ClearButton>
      </Form>
    )
  }
}

export default Filters
