import React, { useState, Fragment } from 'react'
import CsvParser from 'papaparse'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import { ProductSearch } from 'components/Form'
import CSVUpload from 'components/Rewards/CSVUpload'
import { clientIDsCsvSample } from 'components/Rewards/Stores/utils'
import API from 'lib/api'

const AddProduct = ({ _this, ruleIndex, chanceType }) => {
  const [isBulkClientIdsModalVisible, setBulkClientIdsModalVisibility] =
    useState(false)
  const [uploadError, setUploadError] = useState(false)

  const handleCsvUpload = async ({ results }) => {
    try {
      const nonBlankClientIds = results.data
        .slice(1)
        .filter((row) => !row.every((el) => !el.trim()))

      await Promise.all(
        nonBlankClientIds.map(async (clientId) => {
          try {
            const productApi = new API({
              url: `/catalogue-service/product?clientId=${clientId}`,
            })

            const productApiResponse = await productApi.get()

            if (productApiResponse && _this?.setState) {
              _this.setState((prevState) => {
                const { values } = prevState
                const { rulesList } = values

                const updatedRule = { ...rulesList[ruleIndex] }

                const currentProductTypes = updatedRule.productType || []
                const newProductTypes = productApiResponse.data.product

                const uniqueProductTypes = [
                  ...currentProductTypes,
                  ...newProductTypes.filter(
                    (newType) =>
                      !currentProductTypes.some(
                        (currentType) => currentType.id === newType.id
                      )
                  ),
                ]

                updatedRule.productType = uniqueProductTypes

                const updatedRulesList = [...rulesList]
                updatedRulesList[ruleIndex] = updatedRule

                return {
                  ...prevState,
                  values: {
                    ...values,
                    rulesList: updatedRulesList,
                  },
                }
              })
            }

            setBulkClientIdsModalVisibility(false)
          } catch (error) {
            setUploadError(`Error fetching product: ${error}`)
          }
        })
      )
    } catch (error) {
      setUploadError(`Error processing CSV upload: ${error}`)
    }
  }

  const handleFileUpload = (file) => {
    return new Promise((resolve, reject) => {
      CsvParser.parse(file, {
        skipEmptyLines: true,
        delimiter: ',',
        complete: (results) => {
          resolve()
          handleCsvUpload({ results })
        },
        error: (error) => {
          reject(error)
        },
      })
    })
  }

  return (
    <Fragment>
      <div className="mb">
        <div className="col-12">
          <ProductSearch
            label={getMessage('luckydraw.condition-product')}
            placeholder={getMessage('luckydraw.condition-searchproduct')}
            name="chance-product"
            key={`chance-product-${ruleIndex}`}
            className="product-searchable"
            {..._this.generateStateMappers({
              stateKeys: ['rulesList', ruleIndex, 'productType'],
              loseEmphasisOnFill: true,
            })}
            multiple
            readOnly={_this.state.campaignStarted}
            {...(chanceType && {
              required: 'required',
            })}
          />
        </div>
        <div className="col-12">
          <button
            className="button"
            type="button"
            data-testid="bulk-upload-btn"
            onClick={() => setBulkClientIdsModalVisibility(true)}
          >
            {getMessage('luckydraw.bulkUploadOfClientIDs')}
          </button>
        </div>
      </div>
      {isBulkClientIdsModalVisible && (
        <Popup
          show
          close={() => setBulkClientIdsModalVisibility(false)}
          heading={getMessage('luckydraw.bulkUploadOfClientIDs')}
        >
          <CSVUpload
            onUpload={(params) => handleFileUpload(params)}
            csv={clientIDsCsvSample()}
            fileName="clientIDs"
          />
          {uploadError && <div className="error mt-0">{uploadError}</div>}
        </Popup>
      )}
    </Fragment>
  )
}

export default AddProduct
