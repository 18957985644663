import React from 'react'
import { BaseForm, Input } from '../../../../../components/Form'
import { Dialog } from '../../../../../components/Popup'

import API from '../../../../../lib/api'
import { getMessage } from '../../../../../lib/translator'
import {
  getExtensionDetails,
  isExtensionEnabled,
} from '../../../../../lib/auth'

class SlotConfig extends BaseForm {
  constructor(props) {
    super(props)
    this.state.showSuccessDialog = false
    this.state.showErrorDialog = false
    this.state.submitting = false
    this.state.formError = ''

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(data) {
    const id = this.state.data.id
    const api = new API({ url: `/account-service/extension/${id}` })

    const params = {}
    params['status'] = this.state.data.status
    params['config'] = {}
    for (const field in data) {
      params['config'][field] = data[field]
    }

    return api.put(params).then(
      () => {
        this.setState({
          formError: '',
          showSuccessDialog: true,
        })
      },
      error => {
        this.setState({
          formError: error.message.split(':')[1],
          showErrorDialog: true,
        })
      }
    )
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    })
    this.handleSubmit(data).then(() => this.setState({ submitting: false }))
  }

  componentDidMount() {
    const id =
      isExtensionEnabled('DeliverySlots') &&
      getExtensionDetails('DeliverySlots').id
    const api = new API({ url: `/account-service/extension/${id}` })
    id &&
      api.get().then(response => {
        this.setState({
          data: response.data.extension,
          values: response.data.extension.config,
        })
      })
  }
  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    return (
      <div className="slot-config-form">
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          title={getMessage('extensions.slot.success')}
          information={getMessage('extension.slots.saved')}
          close={() => this.setState({ showSuccessDialog: false })}
          closeText={getMessage('extensions.slot.okay')}
        />
        <Dialog
          show={this.state.showErrorDialog}
          title={getMessage('extensions.slot.error')}
          information={this.state.formError}
          close={() => this.setState({ showErrorDialog: false, formError: '' })}
          closeText={getMessage('extensions.slot.okay')}
        />
        <Form>
          <Input
            type="number"
            name="noOfDays"
            min={1}
            max={10}
            label={getMessage('extensions.slot.numOfDays')}
            placeholder={getMessage('extensions.slot.numOfDays.placeholder')}
            {...this.generateStateMappers({
              stateKeys: ['globalConfig', 'visibleDaysForSlot'],
              loseEmphasisOnFill: true,
            })}
          />
          <SubmitButton disabled={this.state.submitting}>
            {getMessage('extensions.slot.save')}
          </SubmitButton>
        </Form>
      </div>
    )
  }
}

export default SlotConfig
