import React, { Fragment } from 'react'
import { Select, Input, MultiSelect, VALIDATION_TYPES } from '../../Form'
import { getMessage } from 'lib/translator'
import {
  Status,
  SearchPartner,
  CATALOGUE_TYPE,
  MECHANICS_OPTIONS,
} from '../utils'
import Permalink from '../Permalink'
import PartnerOnlineType from './PartnerOnlineType'
import UserSegment from './UserSegment'
import StoresApplicable from './StoresApplicable'

const FIELD_TITLES = ['title', 'subtitle']
const FIELD_COSTS = ['cost', 'costCenter']

const Details = ({
  _this,
  partnerList,
  fetchPartnerError,
  isInvalidPartnerOnlineReward,
}) => {
  const { values } = _this.state
  const isTypeFPVoucher = values?.type === 'FP_EVOUCHER'
  const isTypeInStore = values?.type === 'INSTORE'
  const partnerId = values?.partnerId
  const storeIds = values?.storeIds

  const onUpdateStores = (selectedStoreIds) => {
    const updatedValues = {
      ...values,
      storeIds: selectedStoreIds || [],
    }
    _this.setState({ values: updatedValues })
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-6">
          <Input
            label={getMessage('rewards.name')}
            placeholder={getMessage('rewards.name')}
            type="text"
            {..._this.generateStateMappers({
              stateKeys: ['name'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            required
          />
        </div>
        <Permalink _this={_this} />
      </div>

      <div className="row">
        {FIELD_TITLES.map((title, index) => (
          <div className="col-6" key={index}>
            <Input
              label={getMessage(`rewards.${title}`)}
              placeholder={getMessage(`rewards.${title}`)}
              type="text"
              {..._this.generateStateMappers({
                stateKeys: [title],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              required={title === 'title' && 'required'}
            />
          </div>
        ))}
      </div>

      <div className="row">
        <div className="col-6">
          <Select
            name="type"
            testid="type"
            label={getMessage(`rewards.type`)}
            placeholder={getMessage(`rewards.type`)}
            options={CATALOGUE_TYPE}
            {..._this.generateStateMappers({
              stateKeys: [`type`],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            required
          />
        </div>
        <div className="col-6">
          <Status _this={_this} />
        </div>
      </div>

      <div className="row flex-wrap">
        {(partnerList || fetchPartnerError) && (
          <SearchPartner
            _this={_this}
            partnerList={partnerList}
            fetchPartnerError={fetchPartnerError}
          />
        )}
        {isTypeInStore && (
          <StoresApplicable
            onUpdate={onUpdateStores}
            initialValues={{ partnerId, storeIds }}
          />
        )}
        {isTypeFPVoucher && (
          <div className="col-6">
            <Input
              label={getMessage(`rewards.catalogue.voucherDenomination`)}
              placeholder={getMessage(`rewards.catalogue.voucherDenomination`)}
              type="number"
              prefix="$"
              {...{
                required: 'required',
                step: '0.01',
                min: 0.01,
                max: 1000,
              }}
              {..._this.generateStateMappers({
                stateKeys: ['metadata', 'voucher', 'denomination'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        )}
        <PartnerOnlineType
          _this={_this}
          isInvalidPartnerOnlineReward={isInvalidPartnerOnlineReward}
        />
      </div>

      <div className="row">
        {FIELD_COSTS.map((cost, index) => (
          <div className="col-6" key={index}>
            <Input
              label={getMessage(`rewards.${cost}`)}
              placeholder={getMessage(`rewards.${cost}`)}
              type={cost === 'cost' ? 'number' : 'text'}
              step={0.001}
              min={0}
              max={1000000}
              prefix={cost === 'cost' && '$'}
              required
              {..._this.generateStateMappers({
                stateKeys: ['metadata', cost],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        ))}
      </div>

      <div className="row">
        <div className="col-6" style={{ alignSelf: 'baseline' }}>
          <Input
            label={getMessage(`rewards.catalogue.faceValue`)}
            placeholder={getMessage(`rewards.catalogue.faceValue`)}
            type="number"
            prefix="$"
            step={0.001}
            min={0}
            max={1000000}
            {..._this.generateStateMappers({
              stateKeys: ['metadata', 'faceValue'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div className="col-6">
          <MultiSelect
            label={getMessage('rewards.catalogue.mechanics')}
            placeholder={getMessage('rewards.catalogue.mechanics')}
            options={MECHANICS_OPTIONS}
            {..._this.generateStateMappers({
              stateKeys: ['metadata', 'mechanics'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
      </div>
      <br />
      <UserSegment _this={_this} />
    </Fragment>
  )
}

export default Details
