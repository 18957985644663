import React, { Component } from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../../containers/ListingPage'
import StoreSelector from '../../../../containers/StoreSelector'
import { getMessage } from '../../../../lib/translator'
import DeliveryOrderFiltersForm from './Filters'
import { tabularView } from './Views'
import API from '../../../../lib/api'
import { isExtensionEnabled } from '../../../../lib/auth'
import emptyIcon from '../../Orders/Table/no-orders.svg'
import '../../Orders/Table/style.css'

const emptyState = props => {
  return {
    icon: emptyIcon,
    message: getMessage('order.emptyList.message'),
    actions: ({ onAction, apiParams }) => (
      <React.Fragment>
        {isExtensionEnabled('MultiStoreSupport') && (
          <StoreSelector
            value={apiParams.storeId}
            stores={props.stores}
            onChange={storeId => {
              onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId })
            }}
          />
        )}
      </React.Fragment>
    ),
  }
}

const { Provider, Consumer } = React.createContext('commConfig')

const OrdersContainer = class Orders extends Component {

  getCommunicationConfig() {
    this.communicationApi = new API({
      url: `/account-service/config/communication`,
    })
    this.communicationApi
      .get()
      .then(response => {
        this.setState({ communicationConfig: response.data.config })
      })
      .catch(error => {
        throw error
      })
  }

  editAllowed(res) {
    return res && res.data
      && res.data.inStoreProcessing && res.data.inStoreProcessing.packedOrderEditAllowed
  }

  componentWillUnmount() {
    this.communicationApi && this.communicationApi.cancel()
  }

  componentDidMount() {

    this.getCommunicationConfig()

    if (isExtensionEnabled('InStoreProcessing')) {
      this.api = new API({
        url: `/config-service/config/inStoreProcessing.packedOrderEditAllowed`,
      })
      this.api
        .get()
        .then(res => {
          if (res.code === 200 && res.status === 'SUCCESS') {
            this.setState({
              packedOrderEdit: this.editAllowed(res)
            })
          }
        })
        .catch(err => {
          if (err.code === 401 || err.code === 403) {
            throw err
          }
        })
    }
  }

  processFormDataSubmit(formData) {
    const result = Object.assign({}, formData)
    if (result.customer) {
      result.customerId = result.customer.id
      delete result.customer
    }
    if (result.slot) {
      const slotDetails = JSON.parse(result.slot)
      if (slotDetails.startTime) {
        result.slotStartTime = slotDetails.startTime
      }
      result.slotEndTime = slotDetails.endTime
      result.type = slotDetails.type
      delete result.slot
    }
    return result
  }

  getSearchParams(formData) {
    let searchParams = ''
    Object.keys(formData)
      .filter(
        key =>
          key !== 'filters' && (formData[key] === 0 || formData[key])
      )
      .forEach((key, index) => {
        if (index === 0) {
          searchParams += `?${key}=${formData[key]}`
        } else {
          searchParams += `&${key}=${formData[key]}`
        }
      })
    return searchParams
  }

  getUpdateApiParams(props) {
    return props.updateApiParams ? props.updateApiParams : null
  }

  processFormDataFilter(formData) {
    const result = this.processFormDataSubmit(formData)
    if (result.productType === 'all') {
      delete result.productType
    }
    return result
  }

  render() {
    const { props } = this
    // If url is present in the props, then we need to show customer support related interfaces
    return (
      <Provider value={this.state && this.state.communicationConfig}>
        <ListingPage
          menu={props.menu}
          className={`orders-page ${this.props.className || 'ops'}`}
          title={getMessage('operations.dashboard.deliveryOrders')}
          api={{
            url: '/order-service/v3/search/deliveryOrders',
            params: props.params,
            updateApiParams: this.getUpdateApiParams(props),
            transform: response => response.deliveryOrders,
          }}
          primaryKey="referenceNumber"
          emptyState={props.emptyState ? props.emptyState() : emptyState(props)}
          filters={{
            component: DeliveryOrderFiltersForm,
            transformFilter: formData => {
              return this.processFormDataFilter(formData)
            },
            transformSubmit: formData => {
              const searchParams = this.getSearchParams(formData)
              if (searchParams !== '') {
                this.props.history.push({ search: searchParams })
              }
              return this.processFormDataSubmit(formData)
            },
          }}
          onClear={() => this.props.history.push({ search: '' })}
          tableProperties={tabularView(
            this.props.url,
            this.state && this.state.packedOrderEdit
          )}
          tableDynamic
          storeDependent={this.props.url === '/operations'}
        />
      </Provider>
    )
  }
}

export default OrdersContainer

export { Provider, Consumer }
