import React, { Component } from 'react'
import moment from 'moment'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import { Dialog } from '../../../../components/Popup'
import Loader from '../../../../components/Loader'
import { throwError, hideErrorDialog } from '../../commonMedia'
import { getAdvertiserClient, getAgents } from '../advertiserList'
import MediaCampaignForm from './CampaignForm'
import './style.css'

const APIurl = "/martech-ad-service/campaigns/"

export default class MediaCampaignDetail extends Component {
  constructor(props) {
    super(props)

    this.api = null
    this.state = {
      showLoader: true,
      pageAction: 'add',
      data: {
        campaignObjective: '',
      },
      advertiserList: [],
      resellAgentList: [],
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    getAdvertiserClient(this)
    getAgents(this)

    if(this.props.match.params.id){
      this.setState({
        pageAction: 'edit',
      })

      this.api = new API({ url: APIurl + this.props.match.params.id })
      this.api.get().then(response => {
        this.setState({
          data: {
            ...response.data,
            campaign_duration: {
              startDate: response.data.campaignStartDate,
              endDate: response.data.campaignEndDate,
            },
            advertiserClientIdList: response.data.advertiserClientList.map((item)=>{
              return item.id
            }),
            agentId: response.data.agent?.id
          },
          showLoader: false,
        })
      }).catch(error => {
        throwError(this, error)
      })
    }else{
      this.setState({
        showLoader: false,
      })
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  handleSubmit(formData) {
    formData.campaignStartDate = formData.campaign_duration?.startDate
    formData.campaignEndDate = formData.campaign_duration?.endDate

    this.setState({
      showLoader: true,
    })

    if (this.state.pageAction === 'edit') {
      this.api = new API({ url: APIurl + this.props.match.params.id })
      const updateCampaign = {
        id : formData.id,
        campaignName : formData.campaignName,
        campaignStartDate : formData.campaignStartDate,
        campaignEndDate : formData.campaignEndDate,
        campaignDuration : formData.campaignDuration,
        campaignBudget : formData.campaignBudget,
        campaignObjective : formData.campaignObjective,
        totalBookingUnits : formData.totalBookingUnits,
        advertiserClientIdList : formData.advertiserClientIdList,
        agentId: formData.agentId,
        description : formData.description
      }
      this.api.put(updateCampaign).then(() => {
        this.props.history?.goBack()
      }).catch(error => {
        throwError(this, error)
      })
    }else{
      this.api = new API({ url: APIurl })
      this.api.post(formData).then(() => {
        this.props.history?.goBack()
      }).catch(error => {
        throwError(this, error)
      })
    }
  }

  render() {

    return (
      <AuthenticatedPage
        menu={this.props.menu}
        showLanguageDropDown
        className="campaign-detail-page"
        title={getMessage('media.campaigndetail.title.'+this.state.pageAction)}
      >
        <div className="header-container">
          <h1 className="title">{getMessage('media.campaigndetail.title.'+this.state.pageAction)}</h1>
          {this.state.pageAction === 'edit' && (
            <div className='timestamp'>
              <div>{getMessage('media.public.createdby')}: {this.state.data?.createdBy} 丨 {moment(this.state.data?.createdOn).format('ddd, DD MMM YYYY, h:mm A')}</div>
              <div>{getMessage('media.public.updatedby')}: {this.state.data?.updatedBy} 丨 {moment(this.state.data?.updatedOn).format('ddd, DD MMM YYYY, h:mm A')}</div>
            </div>
          )}
        </div>

        {this.state.showLoader ? <Loader /> : (
          <MediaCampaignForm
            value={this.state.data}
            resellAgentList={this.state.resellAgentList}
            advertiserList={this.state.advertiserList}
            onSubmit={this.handleSubmit}
            onCancel={() => {
              this.props.history?.goBack()
            }}
          />
        )}

        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={this.state.errorDialog.message}
            close={()=>(hideErrorDialog(this))}
            closeText={getMessage('dialog.okText')}
          />
        )}
      </AuthenticatedPage>
    )
  }
}
