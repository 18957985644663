import React, { Fragment } from 'react'
import Table, { Row, Cell } from '../../../../../../components/Table'
import { getMessage } from '../../../../../../lib/translator'
import './style.css'
import SubHeader from './SubHeader'
import SubOrderItems from './SubOrderItems'
import StallDiscount from './StallDiscount'
import PaymentDiscount from './PaymentDiscount'
const tableHeader = () => {
  return [
    getMessage('qc.orderSummary.table.header.Name'),
    getMessage('qc.orderSummary.table.header.unit.container.fee'),
    getMessage('qc.orderSummary.table.header.Price'),
    getMessage('qc.orderSummary.table.header.unit.discount'),
    getMessage('qc.orderSummary.table.header.Quantity'),
    getMessage('qc.orderSummary.table.header.Subtotal'),
  ]
}
const popUpTabularView = (_url, handleState, state) => {
  return {
    headers: tableHeader(),
    row: ({ id, subOrders, location }) => (
      <Fragment>
        {subOrders.map((suborder, suborderidx) => {
          return (
            <Fragment key={`qc-subordr-main-row-${id}-${suborderidx}`}>
              <Table className="suborder-table table-dynamic">
                <Row
                  className="suborder-heading"
                  key={`qc-subordr-row-${id}-${suborderidx}`}
                >
                  <Cell>
                    <div className="select-single-subOrder">
                      <SubHeader
                        foodSupplier={suborder.foodSupplier}
                        location={location}
                        suborder={suborder}
                      />
                    </div>
                  </Cell>
                </Row>
              </Table>
              {!suborder.containerFeeSuborderData && (
                <SubOrderItems
                  id={id}
                  suborderidx={suborderidx}
                  suborderId={suborder.id}
                  items={suborder.items}
                  suborder={suborder}
                  state={state}
                  subOrders={subOrders}
                  handleState={handleState}
                  refundSuborder={false}
                />
              )}
              {suborder.discountDetails && state.hideTemp && (
                <StallDiscount
                  id={id}
                  suborderidx={suborderidx}
                  formattedDiscounts={suborder.formattedDiscounts}
                  discountDetails={suborder.discountDetails}
                />
              )}
            </Fragment>
          )
        })}
        <PaymentDiscount />
      </Fragment>
    ),
  }
}
export default popUpTabularView
