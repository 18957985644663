import React, { Component } from 'react'
import { Row, Cell } from '../../../../components/Table'
import Image from '../../../../components/Image'
import { DropDown, DropDownItem, ICONS } from '../../../../components/DropDown'
import { Link } from 'react-router-dom'
import { getMessage } from '../../../../lib/translator'
import customerImagePlaceholder from './customer-image-placeholder.svg'
import { getReadableDateFromISO8601 } from '../../../../lib/datetime'
import { hasPermissions } from '../../../../lib/auth/index'
import { Dialog } from '../../../../components/Popup'
import API from '../../../../lib/api'
import { parse, format } from 'libphonenumber-js'
import { TABLE_ACTIONS } from '../../../../containers/ListingPage'
import SPLIT_FEATURES from 'containers/SplitContext/features'

class CustomerEnableDisableDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
      submitting: false,
    }
    this.toggleDialog = this.toggleDialog.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit() {
    const id = this.props.id
    const params = {}
    params['status'] = this.props.status === 'ENABLED' ? 'DISABLED' : 'ENABLED'
    await this.props.onAction(TABLE_ACTIONS.UPDATE, { id }, params)
    this.setState({
      showDialog: false,
    })
    this.props.rerender()
  }

  toggleDialog() {
    this.setState((prevState) => {
      return {
        showDialog: !prevState.showDialog,
      }
    })
  }

  render() {
    return (
      <div>
        <div onClick={this.toggleDialog}>
          {this.props.status === 'ENABLED'
            ? getMessage('customer.table.actions.disable')
            : getMessage('customer.table.actions.enable')}
        </div>
        <Dialog
          show={this.state.showDialog}
          title={getMessage('customer.reset.password.title')}
          information={
            this.props.status === 'ENABLED'
              ? getMessage('customer.table.actions.disable.information')
              : getMessage('customer.table.actions.enable.information')
          }
          onOk={this.onSubmit}
          close={this.toggleDialog}
          closeText={getMessage('customer.reset.password.cancelText')}
          okText={getMessage('customer.reset.password.confirmText')}
        />
      </div>
    )
  }
}
class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessDialog: false,
      showPasswordResetDialog: false,
      submitting: false,
    }
    this.showPasswordReset = this.showPasswordReset.bind(this)
    this.closeDialogs = this.closeDialogs.bind(this)
    this.onSend = this.onSend.bind(this)
  }
  onSend() {
    const api = new API({
      url: `/customer-service/password?username=${this.props.email}`,
    })

    api.get().then(
      () => {
        this.setState({
          showSuccessDialog: true,
          showPasswordResetDialog: false,
        })
      },
      (error) => {
        this.setState({ formError: error.message })
      }
    )
  }
  showPasswordReset() {
    this.setState({
      showPasswordResetDialog: true,
    })
  }
  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
    })
  }
  render() {
    return (
      <div>
        <span onClick={this.showPasswordReset}>
          {getMessage('customer.action.reset.password')}
        </span>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage('customer.save.success.title')}
          close={this.closeDialogs}
          closeText={getMessage('customer.save.success.closeText')}
        />
        <Dialog
          show={this.state.showPasswordResetDialog}
          title={getMessage('customer.reset.password.title')}
          information={getMessage('customer.reset.password.message')}
          onOk={() => this.onSend()}
          close={() => this.setState({ showPasswordResetDialog: false })}
          closeText={getMessage('customer.reset.password.cancelText')}
          okText={getMessage('customer.reset.password.confirmText')}
        />
      </div>
    )
  }
}

const isSnGActionEnable = () => {
  const splits = JSON.parse(localStorage.getItem('SPLIT_FEATURE_FLAGS'))
  return splits[SPLIT_FEATURES.SNG_ACTION]?.treatment === 'on'
}

const TableView = (page, rerender) => {
  const customerTableViewHeaders = [
    getMessage('customer.table.header.image'),
    getMessage('customer.table.header.name'),
    getMessage('customer.table.header.contact'),
    getMessage('customer.table.header.joinDate'),
    getMessage('customer.table.header.status'),
    getMessage('customer.table.header.actions'),
  ]

  const customerTableViewRows = ({
    id,
    image,
    name,
    phone,
    email,
    status,
    joinedOn,
    onAction,
    uidOfNonFpUser,
  }) => {
    const customerDetailsPageBaseUrl = `/${page}/customers/view`
    const urlToCustomerDetailsPage = uidOfNonFpUser
      ? `${customerDetailsPageBaseUrl}/${uidOfNonFpUser}?isNotFpCustomer=true`
      : `${customerDetailsPageBaseUrl}/${id}`
    return (
      <Row>
        <Cell className="customer-table-image">
          <Image
            src={
              image ||
              (name
                ? `https://ui-avatars.com/api/?name=${encodeURIComponent(
                    name
                  )}&rounded=true&format=svg&background=1557bf&color=ffffff`
                : customerImagePlaceholder)
            }
            size="sm"
          />
        </Cell>
        <Cell className="customer-table-name">
          <Link to={urlToCustomerDetailsPage}>
            <span className="customer-name">{name}</span>
          </Link>
        </Cell>
        <Cell className="customer-table-contact">
          <div className="text-nowrap">
            <a
              href={`tel:${
                phone
                  ? Object.keys(parse(`+${phone}`)).length > 0
                    ? format(parse(`+${phone}`), 'International')
                    : `+${phone}`
                  : null
              }`}
            >
              {phone
                ? Object.keys(parse(`+${phone}`)).length > 0
                  ? format(parse(`+${phone}`), 'International')
                  : `+${phone}`
                : null}
            </a>
          </div>
          <small className="text-nowrap text-muted">
            {email ? email : null}
          </small>
        </Cell>
        <Cell className="customer-table-joining">
          <div className="text-nowrap">
            {joinedOn
              ? getReadableDateFromISO8601(joinedOn).split(', ')[0]
              : null}
          </div>
          <small className="text-muted">
            {joinedOn
              ? getReadableDateFromISO8601(joinedOn).split(', ')[1]
              : null}
          </small>
        </Cell>
        <Cell className="customer-table-status">
          <div>{status}</div>
        </Cell>
        <Cell className="customer-table-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            {/* NE Digital ID */}
            {/* we would temporarily hide
              enable/disable customer function
              till we support OTP to verify
              user details */}
            {/* do not remove the code block as
              it will be restored later */}
            {id === 'do_not_show_this' && (
              <DropDownItem>
                <CustomerEnableDisableDialog
                  status={status}
                  id={id}
                  onAction={onAction}
                  rerender={rerender}
                  email={email}
                />
              </DropDownItem>
            )}
            <DropDownItem>
              <Link to={urlToCustomerDetailsPage}>
                {getMessage('product.action.view')}
              </Link>
            </DropDownItem>
            {isSnGActionEnable() && !uidOfNonFpUser && (
              <DropDownItem>
                <Link to={`/admin/sng-actions/${id}`}>View S&G</Link>
              </DropDownItem>
            )}
            {hasPermissions('customer', 'reset-password', 'post') && (
              <DropDownItem>
                <ResetPassword email={email} />
              </DropDownItem>
            )}
          </DropDown>
        </Cell>
      </Row>
    )
  }

  return {
    headers: customerTableViewHeaders,
    row: customerTableViewRows,
  }
}

export default TableView
