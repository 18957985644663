import React from "react";
import {
  Input, VALIDATION_TYPES, Select
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

const reportRequredMsg = getMessage('media.campaigndetail.report.required')

function SetupDetails(props) {
  const { generateStateMappers, handleCampaignUrl, values } = props
  const campaignLandingPageUrl = values.campaignLandingPageUrl

  return (
    <React.Fragment>
      <h3 className='section-title'>{getMessage('media.campaigndetail.setup.h3')}</h3>
      <div className='section-wrap'>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label='Supplier Banner Identifier'
            secondaryLabel={() => (
              <label>
                <i className="small-label text-muted">{reportRequredMsg}</i>
              </label>
            )}
            placeholder='Example: 20240321CP1A'
            name='setup_supplier'
            type='text'
            maxLength="50"
            {...generateStateMappers({
              stateKeys: ['supplierBanner'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label='Logo Name'
            secondaryLabel={() => (
              <label>
                <i className="small-label text-muted">{reportRequredMsg}</i>
              </label>
            )}
            placeholder='Example: P&G-Logo'
            name='logoName'
            type='text'
            maxLength="50"
            {...generateStateMappers({
              stateKeys: ['logoName'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label='Brand Deals Product Placement'
            placeholder='Enter product(s) SKU separated with comma(s)'
            name='brandDetails'
            type='text'
            maxLength="100"
            {...generateStateMappers({
              stateKeys: ['brandDetails'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label='Supplier Landing Page ID'
            placeholder='Enter category ID'
            name='supplierLandingPageId'
            type='number'
            max={99999}
            {...generateStateMappers({
              stateKeys: ['supplierLandingPageId'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label='Backend Offer ID'
            secondaryLabel={() => (
              <label>
                <i className="small-label text-muted">{reportRequredMsg}</i>
              </label>
            )}
            placeholder='Enter offer ID separated with comma(s), Example: 51471517'
            name='backendOfficeId'
            type='text'
            maxLength="100"
            {...generateStateMappers({
              stateKeys: ['backendOfficeId'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label='Default Search Campaign Name'
            secondaryLabel={() => (
              <label>
                <i className="small-label text-muted">{reportRequredMsg}</i>
              </label>
            )}
            placeholder='Example: 2 for $XX - FairPrice Roasted Chicken Mid Wing (UP $10.14)'
            name='defaultSearchText'
            type='text'
            maxLength="100"
            {...generateStateMappers({
              stateKeys: ['defaultSearchText'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label='Supplier Landing Page URL'
            placeholder='Enter category URL'
            name='supplierLandingPageUrl'
            type='text'
            maxLength="300"
            {...generateStateMappers({
              stateKeys: ['supplierLandingPageUrl'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label='Category Page URL'
            placeholder='Enter category page URL'
            name='categoryPageUrl'
            type='text'
            maxLength="300"
            {...generateStateMappers({
              stateKeys: ['categoryPageUrl'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        
        {campaignLandingPageUrl?.map((item, index)=> (
          <CampaignPages
            key={index}
            index={index}
            item={item}
            campaignLandingPageUrl={campaignLandingPageUrl}
            generateStateMappers={generateStateMappers}
            handleCampaignUrl={handleCampaignUrl}
          ></CampaignPages>
        ))}
      </div>
    </React.Fragment>
  )
}

function CampaignPages (props){
  const { generateStateMappers, index, campaignLandingPageUrl} = props
  return (
    <div className='section-row addable-inputs'>
      <Input
        className='section-row-input'
        label={`Campaign Landing Page URL ${campaignLandingPageUrl.length > 1 ? `[${index + 1}]` : ''}`}
        placeholder='Enter campaign landing page URL'
        name={`campaignLandingPageUrlList_${index}`}
        type='text'
        maxLength="300"
        {...generateStateMappers({
          stateKeys: [`campaignLandingPageUrlList_${index}`],
          loseEmphasisOnFill: true,
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
      />
      {index > 0 && (<div className='addable-inputs-btn delete' onClick={()=>{props.handleCampaignUrl('delete', index)}}></div>)}
      <div>{(index < 4 && index === campaignLandingPageUrl.length-1) && (<div className='addable-inputs-btn add' onClick={()=>{props.handleCampaignUrl('add')}}></div>)}</div>
    </div>
  )
}

function BusinessMechanic(props) {
  const {generateStateMappers, classGLCodeList, classGLCode} = props
  return (
    <React.Fragment>
      <h3 className='section-title'>{getMessage('media.campaigndetail.business.h3')}</h3>
      <div className='section-wrap'>
        <div className='section-row'>
          <Input
            className='section-row-input-full'
            label='Promo Mechaincs / Description'
            placeholder=''
            name='description'
            type='text'
            maxLength="500"
            {...generateStateMappers({
              stateKeys: ['description'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input-full'
            label='eDM Feature'
            placeholder=''
            name='edmFeature'
            type='text'
            maxLength="300"
            {...generateStateMappers({
              stateKeys: ['edmFeature'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label='Includes DBB'
            placeholder=''
            name='includesDbb'
            type='text'
            maxLength="50"
            {...generateStateMappers({
              stateKeys: ['includesDbb'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label='Default Search (1 Day) (P)'
            placeholder='(For Platinum Package) Y/N'
            name='defaultSearch'
            type='text'
            maxLength="100"
            {...generateStateMappers({
              stateKeys: ['defaultSearch'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input-full'
            label='Featured SKU'
            secondaryLabel={() => (
              <label>
                <i className="small-label text-muted">{reportRequredMsg}</i>
              </label>
            )}
            placeholder='Example: 13110009, 13137903, 13198904'
            name='featuredSku'
            type='text'
            maxLength="300"
            {...generateStateMappers({
              stateKeys: ['featuredSku'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input-full'
            label={getMessage('media.adsetdetail.bm.fullDescriptionFoc')}
            placeholder='(include demension/colour/model munber)'
            name='fullDescriptionFoc'
            type='text'
            maxLength="300"
            {...generateStateMappers({
              stateKeys: ['fullDescriptionFoc'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label='keyword search (1 Week) (P,G,S) (Up to 3)'
            secondaryLabel={() => (
              <label>
                <i className="small-label text-muted">{reportRequredMsg}</i>
              </label>
            )}
            placeholder='For Plat/Gold/Gold+/Silver, Example: sneezing ,runny nose, allergy'
            name='keywordSearch'
            type='text'
            maxLength="100"
            {...generateStateMappers({
              stateKeys: ['keywordSearch'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label={getMessage('media.adsetdetail.bm.premiumQut')}
            placeholder=''
            name='premiumQut'
            type="number"
            min={0}
            step="1"
            {...generateStateMappers({
              stateKeys: ['premiumQut'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label={getMessage('media.adsetdetail.bm.focWarrantyCard')}
            placeholder='Y/N'
            name='focWarrantyCard'
            type='text'
            maxLength="50"
            {...generateStateMappers({
              stateKeys: ['focWarrantyCard'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label={getMessage('media.adsetdetail.bm.focValue')}
            placeholder=''
            name='focValue'
            type='text'
            maxLength="100"
            {...generateStateMappers({
              stateKeys: ['focValue'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label={getMessage('media.adsetdetail.bm.focHandlingFee')}
            placeholder=''
            name='focHandlingFee'
            type="number"
            min={0}
            step="0.01"
            {...generateStateMappers({
              stateKeys: ['focHandlingFee'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label={getMessage('media.adsetdetail.bm.focWarrantyPeriod')}
            placeholder=''
            name='focWarrantyPeriod'
            type="number"
            min={0}
            step="1"
            {...generateStateMappers({
              stateKeys: ['focWarrantyPeriod'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label='Handling'
            placeholder=''
            name='handling'
            type='text'
            maxLength="50"
            {...generateStateMappers({
              stateKeys: ['handling'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label='Indicate: Limited to N per transcation Or N per Customer'
            placeholder=''
            name='indicate'
            type='text'
            maxLength="100"
            {...generateStateMappers({
              stateKeys: ['indicate'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label='Vendor Code'
            placeholder=''
            name='vendorCode'
            type='text'
            maxLength="50"
            {...generateStateMappers({
              stateKeys: ['vendorCode'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            className='section-row-input'
            label='SOAC'
            placeholder=''
            name='soac'
            type='text'
            maxLength="50"
            {...generateStateMappers({
              stateKeys: ['soac'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className='section-row'>
          <Input
            className='section-row-input'
            label='Promo Type'
            placeholder=''
            name='promoType'
            type='text'
            maxLength='100'
            {...generateStateMappers({
              stateKeys: ['promoType'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT
            })}
          />

          <Select
            className='section-row-input'
            label={getMessage('media.adsetdetail.bm.classGLCode')}
            placeholder={getMessage('media.adsetdetail.bm.classGLCode.placeholder')}
            name='classGLCode'
            options={classGLCodeList}
            value={classGLCode}
            {...generateStateMappers({
              stateKeys: ['classGLCode'],
              loseEmphasisOnFill: true
            })}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export {
  SetupDetails,
  BusinessMechanic,
}
