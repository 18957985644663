import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Table from './Table'
import Details from './Details'

class OrdersRouter extends Component {
  render() {
    const { router, ...props } = this.props
    const details = { ...props }
    details.url = '/operations'
    if (router.match.params.id) {
      const data = { ...this.props }
      data.url = '/operations'
      return <Details {...data} />
    }
    return <Table {...details} />
  }
}

export default withRouter(({ match, ...props }) => (
  <OrdersRouter router={{ match }} {...props} />
))
