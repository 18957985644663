import React from 'react'

import {
  BaseForm,
  VALIDATION_TYPES,
  Input,
  SelectSearch,
  Phone,
} from '../../../../components/Form'
import { getMessage } from 'lib/translator'
import Loader from '../../../../components/Loader'
import {
  Status,
  FormButtons,
  ErrorScreen,
  mapPhoneNumber,
} from '../../../../components/Rewards/utils'
import API from '../../../../lib/api'
import {
  handleApis,
  handleError,
} from '../../../../components/Rewards/services'
import OpeningHours from 'components/Rewards/Stores/OpeningHours'
import GeoInfo from 'components/Rewards/Stores/GeoInfo'
import AddressDetails from 'components/Rewards/Stores/AddressDetails'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'

class StoreForm extends BaseForm {
  async componentDidMount() {
    const values = Object.assign({}, this.state.values)
    values.loading = true
    this.setState({
      values,
    })
    let getStoreDetails = ''
    const { id } = { ...this.props.value }
    if (id) {
      const api = new API({ url: `/rms/stores/${id}` })
      getStoreDetails = api.get()
    }

    try {
      const partnertDetailsApi = new API({
        url: `/rms/partners?offset=0&limit=1000`,
      })
      const [partnerDetailsRes, storeDetailsRes] = await Promise.allSettled([
        partnertDetailsApi.get(),
        getStoreDetails,
      ])

      if (partnerDetailsRes.status === 'fulfilled') {
        const partnersListArr = partnerDetailsRes.value.data.list?.map(
          (item) => {
            return {
              value: Number(item.id),
              text: item.name,
            }
          }
        )
        values.partnersList = partnersListArr
      } else {
        values.error = partnerDetailsRes.reason.message
      }

      if (storeDetailsRes.status === 'fulfilled' && storeDetailsRes.value) {
        const storeDetailsResValue = storeDetailsRes.value
        const { street, floor, unit, postalCode } = {
          ...storeDetailsResValue.address,
        }
        const { countryCode, phoneNumber } = {
          ...storeDetailsResValue.contactInfo,
        }

        const { legacyOpeningHours } = { ...storeDetailsResValue.openingHours }

        values.name = storeDetailsResValue.name
        values.street = street
        values.floor = floor
        values.unit = unit
        values.postalCode = postalCode
        values.phone = `${countryCode}${phoneNumber}`
        values.legacyOpeningHours = legacyOpeningHours
      }

      if (storeDetailsRes.status === 'rejected') {
        throw new Error(storeDetailsRes.reason.message)
      }

      values.loading = false
      this.setState({
        values,
      })
    } catch (err) {
      handleError(err, this)
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    const {
      id,
      partnerId,
      name,
      status,
      lat,
      lng,
      phone,
      street,
      floor,
      unit,
      postalCode,
      legacyOpeningHours,
      pinCode,
    } = this.state.values
    const statusValue = status || 1

    if (isValid) {
      const mapData = {
        id,
        partnerId: Number(partnerId),
        name,
        status: Number(statusValue),
        lat,
        lng,
        address: {
          street,
          floor,
          unit,
          postalCode,
        },
        pinCode: pinCode || '',
      }
      mapPhoneNumber({ mapData, phone })
      if (legacyOpeningHours) {
        mapData.openingHours = {
          legacyOpeningHours,
        }
      }

      handleApis(mapData, `/rms/stores`, this, id)
    }
  }

  render() {
    const { Form } = this.components
    const { partnersList, loading, error } = { ...this.state.values }
    const { method } = this.props
    return (
      <div className="rewards container store-form">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="row">
              <div className="col-12">
                {partnersList?.length > 0 && (
                  <SelectSearch
                    label={getMessage('rewards.partnerName')}
                    placeholder={getMessage('rewards.partnerName')}
                    options={partnersList}
                    searchByName
                    {...this.generateStateMappers({
                      stateKeys: ['partnerId'],
                    })}
                    required
                  />
                )}
              </div>
              <div className="col-12">
                <Input
                  label={getMessage('rewards.store.name')}
                  placeholder={getMessage('rewards.store.name')}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['name'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Phone
                  label={getMessage('customer.details.phone.phoneNumber')}
                  name="phone"
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['phone'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  defaultCountry="SG"
                  limitMaxLength
                  international
                  countryCallingCodeEditable={false}
                />
              </div>
              <Status _this={this} />
            </div>
            <div className="row">
              <div className="col-6 pr">
                <Input
                  label={getMessage('rewards.store.pinCode')}
                  placeholder={getMessage('rewards.store.pinCode')}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['pinCode'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  readOnly={method === 'add'}
                />
              </div>
            </div>
            <hr />
            <AddressDetails _this={this} />
            <GeoInfo _this={this} />
            <OpeningHours _this={this} />
            {this.props.method === 'edit' && (
              <DisplayCreatorEditorInfo {...this.state.values} />
            )}
            <FormButtons buttons={this.buttons} type="stores" />
            {error && <ErrorScreen error={error} />}
          </Form>
        )}
      </div>
    )
  }
}
export default StoreForm
