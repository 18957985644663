const data = {
  'vehicles.action.delete': 'Delete',
  'vehicles.action.edit': 'Edit',
  'vehicles.emptyState.helperText':
    'Here you will see a list of delivery vehicles available to you.',
  'vehicles.header.addText': 'Add Vehicle',
  'vehicles.header.editText': 'Edit Vehicle',
  'vehicles.header.number': 'Number',
  'vehicles.header.onAction': 'Actions',
  'vehicles.header.status': 'Status',
  'vehicles.header.vendor': 'Vendor',
  'vehicles.header.type': 'Type',
  'vehicles.heading': 'Manage Vehicles',
  'vehicles.type.2w': '2W',
  'vehicles.type.4w': '4W',
  'vehicles.status.disabled': 'Disabled',
  'vehicles.status.enabled': 'Enabled',
  'vehicles.enable.text': 'Enable',
  'vehicles.disable.text': 'Disable',
  'vehicles.filters.byNumber.title': 'Number',
  'vehicles.filters.byNumber.placeholder': 'Search by number',
  'vehicles.filters.byStatus.title': 'Status',
  'vehicles.filters.byStatus.label.all': 'All',
  'vehicles.filters.byStatus.label.enabled': 'Enabled',
  'vehicles.filters.byStatus.label.disabled': 'Disabled',
  'vehicles.filters.byVendor.title': 'Vendor',
  'vehicles.filters.byVendor.placeholder': 'Search by vendor',
  'vehicles.filters.submitText': 'Search',
  'vehicles.filters.clearFiltersText': 'Clear All',
  'vehicles.form.number.heading': 'Number',
  'vehicles.form.number.placeholder': 'Enter vehicle number',
  'vehicles.form.status.heading': 'Status',
  'vehicles.form.status.label.enable': 'Enabled',
  'vehicles.form.status.label.disable': 'Disabled',
  'vehicles.form.cancelText': 'Cancel',
  'vehicles.form.submitText': 'Save',
  'vehicles.form.type.heading': 'Type',
  'vehicles.form.type.label.2w': '2W',
  'vehicles.form.type.label.4w': '4W',
  'vehicles.form.vendor.addButton': 'Add new vendor',
  'vehicles.form.vendor.heading': 'Vendor',
  'vehicles.form.vendor.placeholder': 'Enter vendor name',
  'vehicles.vendor.addButton': 'Add Vendor',
  'vehicles.vendor.heading': 'Vehicle Vendors',
  'vehicles.vendor.heading.name': 'Vendor Name',
  'vehicles.vendor.heading.status': 'Status',
  'vehicles.vendor.heading.action': 'Action',

  'vehiclePlanner.filters.clearFiltersText': 'Clear',
  'vehiclePlanner.heading': 'Vehicle Planner',
  'vehiclePlanner.emptyState.message': 'No planned shift found',
  'vehiclePlanner.addShift.heading': 'Add Shift',
  'vehiclePlanner.editShift.heading': 'Edit Shift',
  'vehiclePlanner.addShift.button': 'Add Shift',
  'vehiclePlanner.checkout.button': 'Check Out',
  'vehiclePlanner.checkin.button': 'Add',
  'vehiclePlanner.checkin.form.addbutton': 'Add',
  'vehiclePlanner.checkin.form.cancelbutton': 'Cancel',
  'vehiclePlanner.replaceVehicle.popup.heading': 'Replace Vehicle',
  'vehiclePlanner.error.dialog.okay': 'Okay',
  'vehiclePlanner.action.edit': 'Edit',
  'vehiclePlanner.action.delete': 'Delete',
  'vehiclePlanner.vehicleList.novehicle': 'No {type} vehicle checked in',
  'vehiclePlanner.vehicleList.replace': 'Replace vehicle',
  'vehiclePlanner.checkin.form.select.plceholder': 'Select vehicle',
  'vehiclePlanner.addShift.form.regular.label': 'Regular Vehicle Count',
  'vehiclePlanner.addShift.form.adhoc.label': 'Adhoc Vehicle Count',
  'vehiclePlanner.addShift.form.regular.count': 'Enter vehicle count',
  'vehiclePlanner.addShift.form.adhoc.count': 'Enter vehicle count',
  'vehiclePlanner.replaceVehicle.form.replaced.label': 'Vehicle to be replaced',
  'vehiclePlanner.replaceVehicle.form.replacedBy.label':
    'Vehicle to be replaced by',
  'vehiclePlanner.replaceVehicle.form.replaced.placeHolder':
    'Select the vehicle',
  'vehiclePlanner.replaceVehicle.form.replacedBy.placeHolder':
    'Select the vehicle',
  'vehiclePlanner.vehicleShift.vehilceCount': '{type} Vehicle',
  'vehiclePlanner.vehicleShift.startTime': 'Start Time: {time}',
  'vehiclePlanner.vehicleShift.endTime': 'End Time: {time}',

  'manage-runner-executives.title': 'Manage Runner Executives',
  'manage-runner-executives-emptyState.emptyMessage':
    'Currently there are no runner executives ',
  'manage-runner-executives.editHeading': 'Edit Runner Executive ',

  'trips.heading': 'Trips',
  'trips.action.close': 'End Trip',
  'trips.action.fin_close': 'Register Payments',
  'trips.action.viewDetails': 'View Details',
  'trips.dialog.closeTrip.title': 'Are you sure?',
  'trips.dialog.closeTrip.message': 'This action will end the trip.',
  'trips.dialog.closeTrip.cancelButton': 'Cancel',
  'trips.dialog.closeTrip.confirmButton': 'Yes',
  'trips.action.vehicleOdometer': 'Show Odometer Image',
  'trips.emptyState.helperText':
    'There are no trips {count, plural, one{from this store} other{}}',
  'trips.filter.byDeliveryAgent.heading': 'Delivery Agent',
  'trips.filter.byDeliveryAgent.placeholder': 'Search by delivery agent',
  'trips.filter.byVehicleNumber.heading': 'Vehicle Number',
  'trips.filter.byVehicleNumber.placeholder': 'Search by vehicle number',
  'trips.filter.byOrderNumber.heading': 'Order Number',
  'trips.filter.byOrderNumber.placeholder': 'Search by order number',
  'trips.filter.byRunnerExecutive.heading': 'Runner Executive',
  'trips.filter.byRunnerExecutive.placeholder': 'Search by runner executive',
  'trips.filter.byStatus.heading': 'Status',
  'trips.filter.byStatus.all': 'All',
  'trips.filter.byStatus.open': 'Open',
  'trips.filter.byStatus.close': 'Closed',
  'trips.filter.byStatus.completed': 'Financially Closed',
  'trips.filter.byDates.heading': 'Date',
  'trips.filters.submitText': 'Search',
  'trips.filters.clearFiltersText': 'Clear All',
  'trips.header.deliveryAgent': 'Delivery Agent',
  'trips.header.vehicle': 'Vehicle',
  'trips.header.distance': 'Distance',
  'trips.header.startTime': 'Start Time',
  'trips.header.endTime': 'End Time',
  'trips.header.status': 'Status',
  'trips.header.actions': 'Actions',
  'trips.header.id': 'ID',
  'trips.status.open': 'Open',
  'trips.status.close': 'Closed',
  'trips.status.fin_close': 'Financially closed',
  'trips.paymentsForm.pendingAmount': '{mode} {currency}{amount}',
  'trips.paymentsForm.amount.label': 'Order #{referenceNumber}',
  'trips.payment.orders': 'Orders',
  'trips.payment.order.id': 'Order #',
  'trips.paymentsForm.amount.placeholder': 'Amount paid by the customer',
  'trips.paymentsForm.submitText': 'Submit',
  'trips.details.heading': 'Trip #{id}',
  'trip.details.startedAt': 'Started at',
  'trip.details.endedAt': 'Ended at',
  'trip.details.tripIsActive': 'Trip is active',
  'trips.pickuppending': 'Pickup pending items',
  'trip.details.orderList.heading': 'Orders',
  'trip.details.orderList.singleOrder.heading': 'Order',
  'trip.details.orderList.withthistrip': 'with this trip',
  'trips.details.orderList.serialNumber': 'S.N',
  'trips.details.orderList.completedTime': 'Completed Time',
  'trips.details.orderList.referenceNumber': 'Reference No',
  'trips.details.orderList.orderTime': 'Order Time',
  'trips.details.orderList.amount': 'Amount',
  'trips.details.orderList.preferredSlot': 'Preferred Slot',
  'trips.details.orderList.location': 'Location',
  'trips.details.orderList.transactionId': 'Transaction Id',
  'trips.details.orderList.transactionId.na': 'NA',
  'trips.details.orderList.view.on.map': 'View On Map',
  'trips.payment.order': 'Pending payments for Order',
  'trips.details.customerImage': 'Customer signature',
  'trips.details.invalid-id': 'Invalid Trip Id',
  'trips.startOdometer': 'Trip start odometer image',
  'trip.endOdometer': 'Trip end odometer image',

  'tripPlanner.heading': 'Trip Planner',
  'tripPlanner.emptyState.helperText':
    'There are no orders to be delivered {count, plural, one{from this store} other{}}',
  'tripPlanner.orderCount':
    '{count, plural, =0{No Orders} one{1 Order} other{# Orders}}',
  'tripPlanner.unassignedOrders.placeholder':
    'Drop orders here that are not ready for a trip',
  'tripPlanner.unassignedOrders.title': 'Orders not ready for trips',
  'tripPlanner.start': 'Start',
  'tripPlanner.assign': 'Assign To',
  'tripPlanner.runner.executive': 'Runner Executives',
  'tripPlanner.runner.vehicle': 'Vehicles',
  'tripPlanner.trip.started': 'Trip started successfully',
  'tripPlanner.trip.storeLocation.error':
    'Store must have proper latitude-longitude.',
  'tripPlanner.table.heading': 'Trip Summary',
  'tripPlanner.orderNumber': 'Order Number',
  'tripPlanner.items': 'Items count',
  'tripPlanner.orderPlacedTime': 'Placed at',
  'tripPlanner.orderPlacedDate': 'Created At',
  'tripPlanner.slots.startTime': 'Slot Start',
  'tripPlanner.slot.endTime': 'Slot End',
  'tripPlanner.order.lat': 'Latitude',
  'tripPlanner.order.lng': 'Longitude',
  'tripPlanner.order.distance': 'Distance(km)',
  'tripPlanner.picker.name': 'Picker',
  'tripPlanner.picker.startTime': 'Picking start',
  'tripPlanner.picker.endTime': 'Picking end',
  'tripPlanner.orderSeq': 'Sequence',
  'tripPlanner.arrival': 'ETA',
  'tripPlanner.waiting': 'Wait',
  'tripPlanner.complete': 'Completion',
  'tripPlanner.vehicle': 'Vehicle',
  'tripPlanner.vehicle.shiftStart': 'Vehicle Shift Start',
  'tripPlanner.vehicle.shiftEnd': 'Vehicle Shift End',
  'tripPlanner.tripNo': 'Trip Number',
  'tripPlanner.tripStart': 'Trip Start',
  'tripPlanner.tripEnd': 'Trip End',
  'tripPlanner.tableView': 'Trip Summary',
  'tripPlanner.status': 'Status',
  'tripPlanner.download.summary.text': 'Download CSV',

  'logistics.config.heading': 'Logistics Settings',
  'logistics.config.reseesOrders': 'How does the RE see the orders?',
  'logistics.config.resees.placeholder': 'Select one option',
  'logistics.config.oneByone': 'One by one',
  'logistics.config.allOrders': 'All orders at a time',
  'logistics.config.leaveAtDoorstep': 'Can RE leave the order at doorstep?',
  'logistics.config.yes': 'Yes',
  'logistics.config.no': 'No',
  'logistics.config.deliveryFee': 'Delivery Fees',
  'logistics.config.deliveryFee.placeholder': 'Enter delivery fees',
  'logistics.save': 'Save',
  'logistics.cancel': 'Cancel',
  'logistics.config.shouldCaptureOdometer':
    'Should the RE capture the odometer image?',
  'logistics.tier.heading': 'Delivery Fees',
  'logistics.tier.add.heading': 'Add tier',
  'logistics.tier.add.column.1': 'Equal or more than',
  'logistics.tier.add.column.2': 'Less than',
  'logistics.tier.add.column.3': 'Fee',
  'logistics.tier.tierName.placeholder': 'Enter name',
  'logistics.tier.tierName': 'Name',
  'logistics.tier.Desc': 'Description',
  'logistics.tier.yes': 'Yes',
  'logistics.tier.Desc.placeholder': 'Enter Description',
  'logistics.tier.no': 'No',
  'logistics.tier.defaultStore': 'Set as default tier for all stores?',
  'logistics.tier.add.text': 'Add Configuration',
  'logistics.tier.add.head': 'Delivery Fee Configuration',
  'logistics.tier.helpItems.message':
    'Your tier configuration will be shown here.',
  'logistics.tier.userSegment': 'Segment',
  'tier.header.status': 'Status',
  'tier.header.actions': 'Actions',
  'tier.status.enabled': 'Enabled',
  'tier.status.disabled': 'Disabled',
  'tier.status.hidden': 'Hidden',
  'tier.action.edit': 'Edit',
  'tier.action.delete': 'Delete',
  'tier.action.default': 'Make default',
  'tier.confirmation.text': 'Confirm to save',
}
export default data
