import { Input, Upload, VALIDATION_TYPES } from 'components/Form'
import { getMessage } from 'lib/translator'
import React from 'react'

const CardContent = ({ generateStateMappers }) => {
  return (
    <>
      <h3>{getMessage('app.card.content')}</h3>
      <div className="card-content">
        <Upload
          placeholder={getMessage('app.card.image.placeholder')}
          {...generateStateMappers({
            stateKeys: ['image'],
            loseEmphasisOnFill: true,
          })}
          required
        />
        <Input
          type="text"
          placeholder={getMessage(`app.card.title`)}
          {...generateStateMappers({
            stateKeys: ['texts', `text1`],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
          required
        />
      </div>
    </>
  )
}

export default CardContent
