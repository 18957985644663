import { inMemoryStore } from '../../store'

const set = (key, data) => {
  if (key && data) {
    // special case only for feature flag, will
    // force to use localstorage
    if (key === 'SPLIT_FEATURE_FLAGS') {
      window.localStorage.setItem(key, data)
      return true
    }

    var dataString = data
    // Just to make sure that for object, need to
    // stringify it before storing in memory store
    if (typeof data === 'object') {
      dataString = JSON.stringify(data)
    }

    inMemoryStore.setItem(key, dataString)
    return true
  }
  console.error('Parameter Error - Please check set method')
  return false
}

const get = (key) => {
  if (key) {
    //special case only for feature flag, will
    // force to use localstorage
    if (key === 'SPLIT_FEATURE_FLAGS') {
      return window.localStorage.getItem(key)
    }

    return inMemoryStore.getItem(key)
  }
  console.error('Parameter Error -  Please check get method.')
  return false
}

const unset = (key) => {
  if (key) {
    //special case only for feature flag, will
    // force to use localstorage
    if (key === 'SPLIT_FEATURE_FLAGS') {
      return window.localStorage.removeItem(key)
    }
    inMemoryStore.removeItem(key)
    return true
  }
  console.error('Parameter Error -  Please check unset method.')
  return false
}

export { set, get, unset }
