import React from 'react'
import moment from 'moment'

import {
  BaseForm,
  Input,
  Upload,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import API from '../../../../lib/api'
import { getMessage } from 'lib/translator'
import Loader from '../../../../components/Loader'
import {
  Status,
  FormButtons,
  ErrorScreen,
  PublishAt,
} from '../../../../components/Rewards/utils'
import {
  handleError,
  handleApis,
} from '../../../../components/Rewards/services'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'
import RankSettings from 'components/Rewards/Categories/RankSettings'

const InputField = ({ label, _this }) => {
  return (
    <div className="col-6">
      <Input
        label={getMessage(`rewards.${label}`)}
        placeholder={getMessage(`rewards.${label}`)}
        type="text"
        {..._this.generateStateMappers({
          stateKeys: label === 'title' ? ['name'] : ['description'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
        {...(label === 'title' && { required: 'required' })}
      />
    </div>
  )
}
class CategoryForm extends BaseForm {
  async componentDidMount() {
    if (this.props.method === 'edit') {
      const values = Object.assign({}, this.state.values)
      values.loading = true
      this.setState({
        values,
      })
      const { id } = this.props.value
      try {
        const api = new API({ url: `/rms/categories/${id}` })
        const response = await api.get()
        values.date = moment(response.publishAt).format('YYYY-MM-DD')
        values.time = moment(response.publishAt).format('HH:mm:ss')
        values.logo = response.imgUrls?.logo
        const { placementRanking } = response.metadata
        values.homeDeckVisibility = placementRanking?.homeDeck
        values.homeSwimlaneVisibility = placementRanking?.homeSwimlane
        values.catalogueTabVisibility = placementRanking?.catalogueTab
        values.name = response.name
        values.loading = false

        this.setState({
          values,
        })
      } catch (err) {
        handleError(err, this)
      }
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    const {
      id,
      name,
      description,
      status,
      logo,
      date,
      time,
      homeDeckVisibility,
      homeSwimlaneVisibility,
      catalogueTabVisibility,
      hideIfNoActiveRewards,
      metadata,
    } = this.state.values

    const {
      homeDeck = null,
      homeSwimlane = null,
      catalogueTab = null,
    } = metadata?.placementRanking ?? {}

    const publishAt = moment(
      `${date} ${time}`,
      'YYYY-MM-DD HH:mm:ss'
    ).toISOString()
    const statusValue = status || 1

    if (isValid) {
      const mapData = {
        name,
        description,
        publishAt,
        status: Number(statusValue),
        imgUrls: { logo },
        metadata: {
          placementRanking: {
            homeDeck: homeDeckVisibility ? homeDeck : null,
            homeSwimlane: homeSwimlaneVisibility ? homeSwimlane : null,
            catalogueTab: catalogueTabVisibility ? catalogueTab : null,
          },
        },
        hideIfNoActiveRewards,
      }
      handleApis(mapData, `/rms/categories`, this, id)
    }
  }

  render() {
    const { Form } = this.components
    const { loading, error } = { ...this.state.values }

    return (
      <div className="rewards container">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="row">
              <InputField label="title" _this={this} />
              <InputField label="subtitle" _this={this} />
            </div>
            <div className="row">
              <PublishAt _this={this} />
            </div>
            <div className="row">
              <Status _this={this} />
            </div>
            <div className="row mt">
              <div className="col-12">
                <Upload
                  name="logo"
                  placeholder={getMessage('rewards.logo')}
                  key="image"
                  label={getMessage('rewards.logo')}
                  {...this.generateStateMappers({
                    stateKeys: ['logo'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            </div>
            <RankSettings _this={this} />
            {this.props.method === 'edit' && (
              <DisplayCreatorEditorInfo {...this.state.values} />
            )}
            <FormButtons buttons={this.buttons} type="categories" />
            {error && <ErrorScreen error={error} />}
          </Form>
        )}
      </div>
    )
  }
}
export default CategoryForm
