import React, { useEffect, useState } from 'react'
import { Popup } from 'components/Popup'
import { Field, useFormikContext } from 'formik'
import { FormikInput } from './FormikInput'
import { Button, Text, View } from '../ui'
import API from 'lib/api'
import {
  errorToast,
  handleMarkOrderResponse,
  refreshedOrderDetail,
} from '../helper'

export const MarkAsPaid = ({ isOpen, toggleModal }) => {
  const {
    values,
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
    setFieldError,
  } = useFormikContext()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setFieldTouched('transactionAmount', false)
  }, [])

  useEffect(() => {
    if ('transactionAmount' in touched && !touched['transactionAmount']) {
      setFieldError('transactionAmount', undefined)
    }
  }, [touched])
  const handleConfirm = () => {
    setLoading(true)
    const { transactionId, transactionAmount, orderDetailData, staffId } =
      values
    const payment = {
      amount: transactionAmount,
      transactionId: transactionId,
    }
    const orderId = orderDetailData.salesOrderReferenceNumber
    new API({
      url: `/order-service/v3/preOrders/${orderId}/confirm`,
    })
      .put({ payment, staffId })
      .then((_) => {
        return refreshedOrderDetail(orderId)
      })
      .then((res) => {
        /**
         * clearing the mark-as-paid form dialog after success
         */
        setFieldValue('transactionAmount', undefined)
        setFieldValue('transactionId', undefined)
        handleMarkOrderResponse(res?.deliveryOrder, setFieldValue, toggleModal)
      })
      .catch((_) => {
        errorToast('MARK_ORDER_AS_PAID', 'Something went wrong...')
        toggleModal()
      })
  }

  return (
    <Popup
      show={isOpen}
      close={() => !isLoading && toggleModal()}
      heading="Mark as paid"
      className="confirmPreorderPopup"
    >
      <View data-testid="markaspaid-modal">
        <Text textAlign="left">
          Ensure transaction ID and total amount matches customer’s receipt.
          <Text fontWeight={700}>Action is final and cannot be changed.</Text>
        </Text>
      </View>
      <Field
        component={FormikInput}
        name="transactionId"
        label="TRANSACTION ID"
        placeholder="Enter transaction ID from receipt"
      />
      <Field
        component={FormikInput}
        type="number"
        name="transactionAmount"
        label="TOTAL AMOUNT"
        placeholder="Enter total amount from receipt"
      />
      <View justifyContent="end">
        <Button
          width="50%"
          disabled={
            !values.transactionId ||
            !values.transactionAmount ||
            errors['transactionAmount']
          }
          onClick={handleConfirm}
          fontSize={12}
          loading={isLoading}
        >
          Confirm
        </Button>
      </View>
    </Popup>
  )
}
