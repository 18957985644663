import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getMessage } from '../../../../lib/translator'
import editIcon from '../../commonMedia/images/edit-icon.svg'

export default function CreativeSummary (props) {
  return (
    <div className='creative-summary-wrap'>
      <div className='creative-summary-header'>
        <h3>Creative Information</h3>
        <div className='editIcon'>
          {props.isNotEditable ?
            (
              <img alt="edit" src={editIcon} onClick={() => props.toggleEitDisabledDialog()}/>
            ) : (
              <Link to={`/media-monetization/media-creativemanagement/media-creative-detail/${props.data?.id}`}>
                <img alt="edit" src={editIcon}/>
              </Link>
            )
          }
        </div>
      </div>
      <div className='creative-summary-inner'>
        <div className='creative-summary-item'>
          <small className='text-muted'>{getMessage('media.creativedetail.creative.name')}: <span
            className='black'>{props.data?.creative_name}</span></small>
        </div>
        <div className='creative-summary-item'>
          <small className='text-muted'>{getMessage('media.adsetdetail.info.package')}: <span
            className='black'>({props.data?.media_package?.id}) {props.data?.media_package?.package_name}</span></small>
        </div>

        <div className='creative-summary-item description'>
          <small className='text-muted'>{getMessage('media.creativedetail.creative.status')}: <span
            className='black'>{props.data?.status}</span></small>
        </div>

        <div className='creative-summary-item'>
          <small className='text-muted'>{getMessage('media.public.createdby')}: <span
            className='black'>{props.data?.created_by} | {moment(props.data?.created_time).format('ddd, DD MMM YYYY, h:mm A')}</span></small>
        </div>
        <div className='creative-summary-item'>
          <small className='text-muted'>{getMessage('media.public.updatedby')}: <span
            className='black'>{props.data?.updated_by} | {moment(props.data?.updated_time).format('ddd, DD MMM YYYY, h:mm A')}</span></small>
        </div>

      </div>
    </div>
  )
}
