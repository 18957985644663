import { useEffect, useState } from 'react'

export const useLockScroll = (query, callback) => {
  useEffect(() => {
    const element = document.querySelector(query)
    element.classList.add('lockScroll')
    callback()
    return () => {
      const cleanupElement = document.querySelector(query)
      cleanupElement.classList.remove('lockScroll')
    }
  }, [])
}

export const useToggle = (defaultToggle = false) => {
  const [toggleState, setToggleState] = useState(defaultToggle)

  const toggle = () => {
    setToggleState((s) => !s)
  }

  const toggleHandleCreator = (inputState) => () => {
    setToggleState(inputState)
  }

  return [toggleState, toggle, toggleHandleCreator]
}
