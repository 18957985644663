import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell, NoCell } from '../../../../../components/Table'
import { getMessage } from '../../../../../lib/translator'
import { getReadableDateFromQC } from '../../../../../lib/datetime'
import Fplogo from '../../../../../icons/fairprice_logo.png'
import './style.css'

const orderStatus = {
  PENDING: 'Pending',
  CREATED: 'Created',
  CANCELLED: 'Cancelled',
  NEEDS_REVIEW: 'Needs Review',
  COMPLETED: 'Completed',
}

const orderFulfilment = {
  having_here: 'Having here',
  self_collect: 'Takeaway',
  delivery: 'Delivery',
}

const subOrderStatus = {
  SUBMITTED: 'Submitted',
  DECLINED: 'Declined',
  CANCELLED: 'Cancelled',
  READY_FOR_COLLECTION: 'Ready for collection',
  COLLECTED: 'Collected',
  PENDING: 'Pending',
  PREPARING: 'Preparing',
  REQUESTING_DRIVER: 'Requesting Driver',
  DRIVER_ACCEPTED: 'Driver Accepted',
  DRIVER_CANCELLED: 'Cancelled',
  ARRIVED_AT_MERCHANT: 'Arrived at Merchant',
  DELIVERY_IN_PROGRESS: 'Delivery in progress',
  TIMED_OUT: 'Timed out',
  NEARBY_DESTINATION: 'Nearby Destination',
  DELIVERED: 'Delivered',
  REJECTED: 'Rejected',
}

const PaymentSubOrderStatus = (subOrder, id) => {
  return (
    <div key={`suborder-status-${id}`} className="other-payment-status">
      {subOrderStatus[subOrder.state]}
    </div>
  )
}

const getFulfilmentLogo = platfrom => {
  if (platfrom === 'FAIRPRICE') {
    return <img src={Fplogo} alt="Fairprice Logo" />
  } else {
    return
  }
}

const tableHeader = () => {
  const headers = [
    getMessage('qc.order.table.header.referenceNumber'),
    getMessage('qc.order.table.header.brand'),
    getMessage('qc.order.table.header.outlet'),
    getMessage('qc.order.table.header.stall'),
    getMessage('qc.order.table.header.amount'),
    getMessage('qc.order.table.header.type'),
    getMessage('qc.order.table.header.masterStatus'),
    getMessage('qc.order.table.header.subStatus'),
  ]
  return headers
}
const tableProperties = url => {
  return {
    headers: tableHeader(),
    row: ({
      createdAt,
      fulfillmentMethod,
      id,
      location,
      platform,
      state,
      subOrders,
      formattedTotal,
      reference,
      customer,
    }) => (
      <Fragment>
        {subOrders.map((order, idx) => {
          if (idx === 0) {
            return (
              <Row key={`qc-ordr-row-${id}-${idx}`}>
                <Cell className="column-order-number">
                  <div className="container-order-number">
                    <div>
                      <div>
                        <Link
                          className="order-number"
                          to={
                            url && url.includes('/qc/')
                              ? `${url}/orders/${customer.customerId}/${reference}`
                              : `/qc/orders/${customer.customerId}/${reference}`
                          }
                        >
                          {id && reference && <span>{reference}</span>}
                        </Link>
                      </div>
                      {createdAt && (
                        <small className="text-muted store-name">
                          {getReadableDateFromQC(createdAt)}
                        </small>
                      )}
                    </div>
                  </div>
                </Cell>
                <Cell className="column-brand-name">
                  <div className="cx-name-text">
                    {order && order.foodSupplier && order.foodSupplier.brand}
                  </div>
                </Cell>
                <Cell className="column-customer-name">
                  <div className="cx-name-text">
                    {location && location.name && location.name}
                  </div>
                </Cell>
                <Cell className="sub-order-name">
                  <ul>
                    <li key={`food-supplier-${idx}`}>
                      {order.foodSupplier.name}
                    </li>
                  </ul>
                </Cell>
                <Cell className="column-order-amount">
                  <div>{formattedTotal}</div>
                </Cell>
                <Cell className="column-order-master-status">
                  <span className="fulfilment-text">
                    {orderFulfilment[fulfillmentMethod]}
                  </span>
                  {getFulfilmentLogo(platform)}
                </Cell>
                <Cell className="column-order-status">
                  <div className="other-payment-status">
                    {orderStatus[state]}
                  </div>
                </Cell>
                <Cell className="column-order-sub-status">
                  {PaymentSubOrderStatus(order, idx)}
                </Cell>
              </Row>
            )
          } else {
            return (
              <Row key={`qc-ordr-row-${id}-${idx}`}>
                <NoCell className="no-row column-order-number"></NoCell>
                <NoCell className="no-row column-brand-name">
                  {order && order.foodSupplier && order.foodSupplier.brand}
                </NoCell>
                <NoCell className="no-row column-customer-name"></NoCell>
                <NoCell className="no-row sub-order-name">
                  <ul>
                    <li key={`food-supplier-${idx}`}>
                      {order.foodSupplier.name}
                    </li>
                  </ul>
                </NoCell>
                <NoCell className="no-row column-order-amount"></NoCell>
                <NoCell className="no-row column-order-status"></NoCell>
                <NoCell className="no-row column-order-status"></NoCell>
                <NoCell className="no-row column-order-sub-status">
                  {PaymentSubOrderStatus(order, idx)}
                </NoCell>
              </Row>
            )
          }
        })}
      </Fragment>
    ),
  }
}

export default tableProperties
