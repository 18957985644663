import React, { Component } from 'react'
import debounce from '../../../../lib/debounce'
import _find from 'lodash.find'
import searchIcon from './icon-search.svg'

function getValidationObj(props, value) {
  let valueMissing = props.required && !value
  if (props.required && props.multiple) {
    valueMissing = valueMissing || !value || !value.length
  }
  return {
    valueMissing,
    valid: !valueMissing,
  }
}

class SelectSearch extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.runValidation = this.runValidation.bind(this)
    const options = props.options || []
    const selectedOption = _find(
      options,
      (opt) => opt[props.valueKey] === props.value
    )
    const searchText = selectedOption ? selectedOption[props.nameKey] : ''
    this.state = {
      options: options,
      searchText: searchText,
      selectedValue: props.value,
      loading: false,
      selectableOptions: props.defaultSelectableOptions ? props.options : [],
    }

    this.selectSearch = debounce(this.selectSearch.bind(this), 300)
    this.search = this.selectSearch
    this.onSelect = this.onSelect.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  setDefaultValue = () => {
    const { selectedValue } = this.state
    const { defaultOption, nameKey, valueKey } = this.props

    //if we have a default option but have not selected any options yet, we should set default value instead
    if (!selectedValue && defaultOption) {
      const defaultName = defaultOption[nameKey]
      const defaultValue = defaultOption[valueKey]
      this.setState({
        searchText: defaultName,
        selectedValue: defaultValue,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      Array.isArray(nextProps.value) &&
      Array.isArray(prevState.selectedValue) &&
      nextProps.value.length !== prevState.selectedValue.length
    ) {
      if (nextProps.onValidation) {
        nextProps.onValidation(getValidationObj(nextProps, nextProps.value))
      }

      return {
        selectedValue: nextProps.value,
      }
    }
    return null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.required !== this.props.required) {
      this.runValidation(this.props.value)
    }
    if (
      prevProps.options !== this.props.options ||
      prevProps.value !== this.props.value
    ) {
      const selectedOption = _find(
        this.props.options,
        (opt) => opt[this.props.valueKey] === this.props.value
      )
      const searchText = selectedOption
        ? selectedOption[this.props.nameKey]
        : ''

      if (!searchText) {
        this.setDefaultValue()
        this.setState({ options: this.props.options })
        return
      }

      this.setState({
        searchText,
        selectedValue: this.props.value,
        options: this.props.options,
      })
    }
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({
      searchText: e.target.value,
    })
    this.search(e.target.value)
  }

  runValidation(value) {
    this.props.onValidation &&
      this.props.onValidation(getValidationObj(this.props, value))
  }

  selectSearch(value) {
    const options = this.state.options
    let searchKey = this.props.valueKey
    if (this.props.searchByName) {
      searchKey = this.props.nameKey
    }

    const searchOptionsByName = options.filter((opt) =>
      opt[searchKey].toLowerCase().includes(value.toLowerCase())
    )

    let searchOptionsById = []
    if (this.props.searchById) {
      searchKey = this.props.valueKey
      searchOptionsById = options.filter((opt) => opt[searchKey] === value)
    }

    this.setState({
      selectableOptions: [...searchOptionsByName, ...searchOptionsById],
      lastSearch: value,
    })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)

    if (!this.props.value && this.props.defaultOption) {
      this.setDefaultValue()
    }

    this.runValidation(this.state.selectedValue)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.onBlur()
    }
  }

  onSelect(option) {
    let selectedValue = option
    if (this.props.multiple) {
      selectedValue = [...(this.state.selectedValue || [])]
      const index = selectedValue.findIndex(
        (listOption) =>
          listOption[this.props.valueKey] === option[this.props.valueKey]
      )
      if (index > -1) {
        selectedValue.splice(index, 1)
      } else {
        selectedValue = [...selectedValue, option]
      }
    }

    this.setState(
      {
        selectableOptions: [],
        searchText:
          option && !this.props.multiple ? option[this.props.nameKey] : '',
        lastSearch: '',
        selectedValue,
      },
      () => {
        this.props.onChange(
          this.props.multiple
            ? selectedValue
            : selectedValue[this.props.valueKey]
        )
        this.runValidation(selectedValue)
      }
    )
  }

  handleKeyDown(e) {
    if (!this.props.multiple && (e.keyCode === 8 || e.keyCode === 46)) {
      if (this.state.selectedValue) {
        this.onSelect('')
      }
    }
  }

  handleFocus = () => {
    this.setState({ isFocused: true })
  }

  onBlur = () => {
    this.setState({ isFocused: false })
  }

  renderOptions = (suggestion) => {
    const { nameKey, valueKey, renderListElement } = this.props
    const { selectedValue } = this.state
    let suggestionAllowed
    if (this.props.multiple) {
      suggestionAllowed =
        selectedValue && selectedValue.length
          ? selectedValue.findIndex(
              (option) => suggestion[valueKey] === option[valueKey]
            ) === -1
          : true
    } else {
      suggestionAllowed = selectedValue
        ? selectedValue[valueKey] !== suggestion[valueKey]
        : true
    }
    return suggestionAllowed ? (
      renderListElement ? (
        renderListElement(suggestion, this.onSelect)
      ) : (
        <li
          className="select-option"
          key={suggestion[valueKey]}
          onClick={() => this.onSelect(suggestion)}
        >
          {suggestion[nameKey]}
        </li>
      )
    ) : (
      ''
    )
  }

  render() {
    const {
      name,
      placeholder,
      required,
      type,
      nameKey,
      readOnly,
      autoFocus,
      isShowingDefaultOptions,
      dontDisplaySelected,
      autoComplete,
    } = this.props
    const { selectableOptions, searchText, error, lastSearch, isFocused } =
      this.state
    return (
      <div className={`Searchable`}>
        <div className="input">
          <input
            data-testid={`Searchable-${name}`}
            id={name}
            name={name}
            type={type || 'text'}
            value={searchText}
            placeholder={placeholder}
            required={required}
            readOnly={readOnly}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            autoFocus={autoFocus}
            onFocus={this.handleFocus}
            autoComplete={autoComplete}
          />
          <img src={searchIcon} className="select-field-icon" alt="" />
        </div>
        {error && <div className="error-message">{error}</div>}
        {searchText && selectableOptions && (
          <div
            className="select-field-dropdown-container"
            ref={(node) => (this.dropdownRef = node)}
          >
            <ul
              className={`select-field-dropdown ${
                lastSearch !== searchText ? 'hidden' : ''
              }`.trim()}
            >
              {selectableOptions.map(this.renderOptions)}
            </ul>
          </div>
        )}
        {!searchText && isShowingDefaultOptions && isFocused && (
          <div className="select-field-dropdown-container">
            <ul
              className={`select-field-dropdown`.trim()}
              ref={(node) => (this.dropdownRef = node)}
            >
              {this.state.options.map(this.renderOptions)}
            </ul>
          </div>
        )}
        {!dontDisplaySelected && this.props.multiple ? (
          <div className="selected-items">
            {(this.state.selectedValue || []).map((value) => (
              <div className="tag-value" key={value[nameKey]}>
                <span className="tag-value-label">
                  <small>{value[nameKey]}</small>
                </span>
                <button
                  disabled={readOnly}
                  type="button"
                  className="tag-value-icon"
                  onClick={() => {
                    this.onSelect(value)
                  }}
                />
              </div>
            ))}
            {(this.state.selectedValue || []).length > 0 && (
              <button
                disabled={readOnly}
                type="button"
                className="remove-all"
                onClick={() => {
                  this.setState({ selectedValue: [] }, () => {
                    this.props.onChange([])
                  })
                }}
              >
                Remove all
              </button>
            )}
          </div>
        ) : null}
      </div>
    )
  }
}
SelectSearch.defaultProps = {
  nameKey: 'text',
  valueKey: 'value',
  isShowingDefaultOptions: false,
}
export default SelectSearch
