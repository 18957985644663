import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { Cell, Row } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { getMessage } from '../../../lib/translator'
import Filters from './filters.js'
import emptyIcon from '../commonMedia/images/pages-empty.svg'
import './style.css'
import { Dialog } from '../../../components/Popup'

function getMobileView() {
  return window.screen.width <= 480
}

function AddButton() {
  return (
    <Link to="/media-monetization/media-creativemanagement/media-creative-detail" className="primary button">
      + <span className="text">{getMessage('media.add.text')}</span>
    </Link>
  )
}

const emptyState = {
  icon: emptyIcon,
  message: 'No results found',
  additionalViews: [AddButton],
}

const tableProperties = (isMobileView, toggleEitDisabledDialog, _this) => {
  function isNotEditable (poperties) {
    return poperties?.creative_assigment?.some(assign => assign.line_item.status === 'Scheduled')
  }

  return {
    headers: [
      getMessage('media.creativeManagement.header.id'),
      getMessage('media.creativeManagement.header.name'),
      getMessage('media.creativeManagement.header.package'),
      getMessage('media.creativeManagement.header.bu'),
      getMessage('media.creativeManagement.header.status'),
      getMessage('media.creativeManagement.header.actions'),
    ],
    row: props => (
      <Row>
        <Cell className='website-page-name'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.creativeManagement.header.id')}
            </div>
          )}
          {props.id}
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.creativeManagement.header.name')}
            </div>
          )}
          <Link className="page-name" to={`/media-monetization/media-creativemanagement/media-creative-group-list/${props.id}`}>
            {props.creative_name}
          </Link>
        </Cell>
        <Cell className='website-page-url creativeType-cell'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.creativeManagement.header.package')}
            </div>
          )}
          <small>({props.media_package?.id}) {props.media_package?.package_name}</small>
        </Cell>
        <Cell className='website-page-url creativeType-cell'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.creativeManagement.header.bu')}
            </div>
          )}
          <small> {props.owner_bu}</small>
        </Cell>
        <Cell className='website-page-url creativeType-cell'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.creativeManagement.header.status')}
            </div>
          )}
          <small>{props.status}</small>
        </Cell>
        <Cell className='website-page-actions'>
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem>
              <Link to={`/media-monetization/media-creativemanagement/media-creative-group-list/${props.id}`}>
                {getMessage('media.action.view')}
              </Link>
            </DropDownItem>
            {isNotEditable(props)
              ? (
                <DropDownItem onClick={toggleEitDisabledDialog}>
                  {getMessage('media.action.edit')}
                </DropDownItem>)
              : (
                <DropDownItem><Link
                  to={`/media-monetization/media-creativemanagement/media-creative-detail/${props.id}`}>
                  {getMessage('media.action.edit')}
                </Link>
                </DropDownItem>)
            }

            <DropDownItem onClick={() =>
              isNotEditable(props)? toggleEitDisabledDialog() :
              props.onAction(TABLE_ACTIONS.DELETE, { id: props.id })
            }>
              {getMessage('media.action.delete')}
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    )
  }
}

export default class MediaCreativeList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileView: getMobileView(),
      showEitDisabledDialog: false,
    }
    this.mobileView = this.mobileView.bind(this)
  }

  toggleEitDisabledDialog(){
    this.setState({
      showEitDisabledDialog: !this.state.showEitDisabledDialog
    })
  }
  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
    window.removeEventListener('resize', this.mobileView, false)
  }

  render () {
    return (
      <React.Fragment>
        {this.state.showEitDisabledDialog && (
          <Dialog
            show={this.state.showEitDisabledDialog}
            title={getMessage('media.creativeManagement.editDisabledPopup.title')}
            information={getMessage('media.creativeManagement.editDisabledPopup.message')}
            close={() => (this.toggleEitDisabledDialog())}
            closeText={getMessage('dialog.okText')}
          />
        )}
        <ListingPageWithRoutes
          className="media-creative-management website-pages"
          title={getMessage('media.creativeManagement.title')}
          menu={this.props.menu}
          api={{
            url: '/martech-creative-service/creative',
            transform: response => response.data.content,
          }}
          emptyState={emptyState}
          addHeading={getMessage('offer.add.heading')}
          editHeading={getMessage('offer.edit.heading')}
          tableProperties={tableProperties(this.state.isMobileView, ()=>this.toggleEitDisabledDialog(), this)}
          headerActions={AddButton}
          filters={{
            component: Filters,
            transformSubmit: formData => {
              return Object.assign({}, formData)
            },
          }}
          form={{}}
        />
      </React.Fragment>
    )
  }
}
