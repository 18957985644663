import React, { Component } from 'react'
import QueryString from 'query-string'
import { Link } from 'react-router-dom'
import moment from 'moment'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage/index'
import Loader from '../../../../components/Loader'
import { Dialog, Popup } from '../../../../components/Popup'
import NameValueCard from '../../../../components/NameValueCard'
import EmptyState from '../../../../components/EmptyState'
import { throwError, hideErrorDialog } from '../../commonMedia'
import Filters from '../../commonMedia/SlotCalendar/DateSelectFilter'
import PackageCalendar from './PackageCalendar'
import PackagePreview from '../PackagePreview'
import CalendarPopup from './CalendarPopup'
import LineItemPreview from '../../MediaLineItem/LineItemPreview'
import emptyIcon from '../../commonMedia/images/pages-empty.svg'
import './style.css'

const dateFormat = 'YYYY-MM-DD'

const emptyState = {
  icon: emptyIcon,
  message: getMessage('media.public.empty'),
  submessage: getMessage('media.packageManagement.inventory.empty'),
}

function AdditionalBtns() {
  return (
    <Link className='button goback' to={`/media-monetization/media-packagemanagement`}>
      {getMessage('media.packageManagement.inventory.reselect')}
    </Link>
  )
}

export default class PackageInventory extends Component {
  constructor(props) {
    super(props)

    this.api = null
    this.state = {
      showLoader: false,
      isEmptyCalender: false,
      summaryCardData: [],
      values: {
        filter_duration: {
          startDate: null,
          endDate: null,
        }
      },
      packageCalendar: [],
      PackagePreviewDialog: {
        isShow: false,
        selectedPackageData: {},
      },
      calendarPopupDialog: {
        isShow: false,
        lineItemInfo: [],
      },
      LineitemPopup: {
        shown: false,
        lineItemId: null,
        data: null,
      },
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
    }

    this.toggleCalendarPopup = this.toggleCalendarPopup.bind(this)
    this.togglePackageBox = this.togglePackageBox.bind(this)
    this.searchPackageInventory = this.searchPackageInventory.bind(this)
    this.toggleLineitemPopup = this.toggleLineitemPopup.bind(this)
    this.setSearchParam = this.setSearchParam.bind(this)
    this.onSearch = this.onSearch.bind(this)
  }

  componentDidMount() {
    const query = QueryString.parseUrl(this.props.location.search).query
    const packageIds = query?.packageIds ? [...JSON.parse(query.packageIds)] : []
    const startDate = query?.startDate || moment().format(dateFormat)
    const endDate = query?.endDate || moment().add(14, 'day').format(dateFormat)
    this.setState({
      packageIds,
      values: {
        filter_duration: {
          startDate,
          endDate
        }
      }
    }, () => this.searchPackageInventory())
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  setSearchParam(startDate, endDate){
    window.sessionStorage.setItem('mediaPackageInventoryDuration', JSON.stringify({
      startDate,
      endDate
    }))
    const query = QueryString.parseUrl(this.props.location.search).query
    const searchParams = `?packageIds=${query.packageIds}&startDate=${startDate}&endDate=${endDate}`
    this.props.history.push({ search: searchParams })
  }

  onSearch(){
    this.setSearchParam(this.state.values.filter_duration.startDate, this.state.values.filter_duration.endDate)
  }

  filterDurationOnChange(dates) {
    const values = JSON.parse(JSON.stringify(this.state.values))
    values.filter_duration.startDate = dates && dates.startDate
    values.filter_duration.endDate = dates && dates.endDate
    this.setState({
      values,
    })
  }

  searchPackageInventory() {
    const packageIds = this.state.packageIds
    if(packageIds && packageIds.length > 0){
      this.setState({
        showLoader: true
      })
      this.api = new API({ url: '/martech-ad-service/reservations/package/inventory' })
      const payload = {
        startDate: this.state.values?.filter_duration?.startDate,
        endDate: this.state.values?.filter_duration?.endDate,
        packageIds
      }
      this.api.get(payload).then(response => {
        const summaryCardsData = this.handleSummaryCards(response?.data)
        this.setState({
          showLoader: false,
          summaryCardData: summaryCardsData,
          packageCalendar: response.data?.packageCalendar,
        })
      }).catch(error => {
        throwError(this, error)
      })
    }else{
      this.setState({
        isEmptyCalender: true
      })
    }
  }

  handleSummaryCards(data) {
    return [
      { name: getMessage('media.packageManagement.inventory.card.total'), value: this.getValueOrDefault(data?.totalPackages, 0), color: 'blue' },
      { name: getMessage('media.packageManagement.inventory.card.booked'), value: this.getValueOrDefault(data?.bookedPackages, 0), color: 'red' },
      { name: getMessage('media.packageManagement.inventory.card.free'), value: this.getValueOrDefault(data?.availablePackages, 0), color: 'green' },
    ]
  }

  getValueOrDefault(value, defaultValue) {
    return value || defaultValue
  }

  toggleCalendarPopup(status, lineItemInfo){
    this.setState({
      calendarPopupDialog: {
        isShow: lineItemInfo?.length > 0 && status === 1,
        lineItemInfo,
      }
    })
  }

  togglePackageBox(status, packageId) {
    if (status === 1) {
      this.getPackageDetail(packageId)
    } else {
      this.setState({
        PackagePreviewDialog: {
          isShow: false,
          selectedPackageData: {},
        }
      })
    }
  }

  getPackageDetail(packageId) {
    this.api = new API({ url: '/martech-ad-service/packages/' + packageId })
    this.api.get().then(response => {
      this.setState({
        PackagePreviewDialog: {
          isShow: true,
          selectedPackageData: response.data,
        }
      })
    }).catch(error => {
      throwError(this, error)
    })
  }

  toggleLineitemPopup(status, lineItemId) {
    if (status === 1 && lineItemId !== this.state.LineitemPopup.lineItemId){
      this.getLineItemDetail(lineItemId)
    }
    else if(status === 1){
      this.setState({
        LineitemPopup: {
          ...this.state.LineitemPopup,
          shown: true
        },
      })
    }
    else{
      this.setState({
        LineitemPopup: {
          ...this.state.LineitemPopup,
          shown: false
        },
      })
    }
  }

  getLineItemDetail(lineItemId){
    this.api = new API({ url: '/martech-ad-service/reservations/lineItem/'+lineItemId })
    this.api.get().then(response => {
      const data = response.data
      data?.lineItemSupplierReservationList?.forEach(supplierItem => {
        data.budget += supplierItem.budget
      })
      this.setState({
        LineitemPopup: {
          shown: true,
          lineItemId,
          data,
        }
      })
    }).catch(error => {
      throwError(this, error)
    })
  }

  render() {
    const { menu } = this.props
    const { showLoader, summaryCardData, packageCalendar } = this.state

    const SummaryCards = summaryCardData.map(({ name, ...data }, index) => (
      <NameValueCard
        key={`item-${index}`}
        name={name}
        {...data}
      />
    ))

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className='media-package-discovery'
        title={getMessage('media.packageManagement.inventory.title')}
      >
        <h1 className='title'>{getMessage('media.packageManagement.inventory.title')}</h1>
        {showLoader ? (
          <Loader />
        ) : (
          <div>
            <Filters
              maxDays={35}
              value={this.state.values}
              additionalBtns={AdditionalBtns}
              onChange={(dates) => this.filterDurationOnChange(dates)}
              onSubmit={this.onSearch}
            />
            <div className='summary-info'>{SummaryCards}</div>
            <PackageCalendar
              packageCalendar={packageCalendar}
              togglePackageBox={this.togglePackageBox}
              toggleCalendarPopup={this.toggleCalendarPopup}
            ></PackageCalendar>
          </div>
        )}

        {this.state.isEmptyCalender && <EmptyState {...emptyState} />}

        <Popup
          show={this.state.PackagePreviewDialog.isShow}
          className="packagePreview-box"
          heading={getMessage('media.packageDetail.title')}
          close={() => { this.togglePackageBox(2) }}
        >
          <PackagePreview
            packageData={this.state.PackagePreviewDialog.selectedPackageData}
            showCampaigns={false}
            showAssets={true}
          ></PackagePreview>
        </Popup>

        <CalendarPopup
          toggleCalendarPopup={this.toggleCalendarPopup}
          calendarPopupDialog={this.state.calendarPopupDialog}
          toggleLineitemPopup={this.toggleLineitemPopup}
          LineitemPopup={this.state.LineitemPopup}
        ></CalendarPopup>

        {this.state.LineitemPopup.shown && (
          <LineItemPreview
            show={this.state.LineitemPopup.shown}
            toggleLineitemPopup={this.toggleLineitemPopup}
            data={this.state.LineitemPopup.data}
            campaignId={this.state.LineitemPopup.data?.campaignId}
          ></LineItemPreview>
        )}

        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={this.state.errorDialog.message}
            close={() => (hideErrorDialog(this))}
            closeText={getMessage('dialog.okText')}
          />
        )}
      </AuthenticatedPage>
    )
  }

}
