import React, { useState, useEffect, useCallback, Fragment } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import API from '../../../lib/api'
import Pagination from '../../../components/Pagination'
import { getMessage } from '../../../lib/translator'
import EmptyState from '../../../components/EmptyState'
import emptyIcon from 'icons/picking-queue.svg'
import Loader from '../../../components/Loader'
import './style.scss'
import ZonesMulti from './ZonesMulti'
import { getTagsParam, isZonePickingStore } from './helper'
import ZonePickingQueueTable from './ZonePickingQueueTable';
import { get } from 'lib/storage'

const ZonePickingQueue = ({ menu, location }) => {
  const [zonePickingData, setZonePickingData] = useState(null)
  const [storeID, setStoreID] = useState(get('store'))
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(0)
  const [count, setCount] = useState(0)
  const [isFailed, setIsFailed] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [storeZoneConfig, setStoreZoneConfig] = useState({})
  const [storeZoneSelected, setStoreZoneSelected] = useState([])
  const pageSize = 20
  const handleStoreChange = storeId => {
    setStoreID(storeId)
    setStoreZoneSelected([])
  }
  const handleMultiChange = useCallback((value) => {
    if (value.length > 0) {
      setStoreZoneSelected(value)
      loadData()
    }
  },[storeZoneSelected])

  useEffect(() => {
    loadData()
  }, [page, storeID, storeZoneSelected])

  const changePage = (pageNumber) => {
    setPage(pageNumber)
    setOffset((pageNumber - 1) * pageSize)
  }

  // get storeZones and get picklists
  const loadData = () => {
    // get store zones
    const storeZonesApi = new API({ url: `/picking-service/v3/storeZones/` + storeID })
    storeZonesApi.get().then(response => {
      setStoreZoneConfig(response.storeZones)
    })

    // do not get picklists if there is no storeZoneSelected
    if (storeZoneSelected.length === 0) {
      return;
    }

    // get picklists
    const api = new API({ url: `/picking-service/v3/picklists?seller.type=SELLER_TYPE_UNSPECIFIED&seller.code=${storeID}&picklist.statuses=PICKLIST_STATUS_PENDING${getTagsParam(storeZoneSelected)}&page=${page}&pageSize=${pageSize}` })
    api.get().then(response => {
        setPageStatus(false, false, response);
        setTotalPages(Math.ceil(response.paging.count / response.paging.limit));
        setLimit(Number(response.paging.limit))
        setCount(response.paging.count);
      }).catch((e) => {
        console.log('error retrieving picklists: ', e)
        setPageStatus(false, true, null)
      }).finally(() => {
        setIsLoading(false)
    })
  }
  const setPageStatus = (loading, failed, data) => {
    setIsLoading(loading)
    setIsFailed(failed)
    setZonePickingData(data)
  }
  const showResults = !isLoading && zonePickingData && zonePickingData?.picklists
  return (
    <AuthenticatedPage
      menu={menu}
      from={location && location.pathname}
      className="zone-picking"
      storeDependent
      onChange={handleStoreChange} >
      <div className="page-header">
        <h1 className="title">Zone Picking Queue</h1>
      </div>
      {isLoading && (
        <Fragment>
          <Loader />
        </Fragment>
      )}
      {showResults && zonePickingData?.picklists.length === 0 && (
        <EmptyState
          icon={emptyIcon}
          message={getMessage('operations.pickingQueue.emptyList.message')} />
      )}
      {/* initial state or a state with no picklists for selected zones */}
      {(!zonePickingData || isFailed) && (
        <Fragment>
          {isZonePickingStore(storeZoneConfig) ? <Fragment>
            <ZonesMulti
              onChange={handleMultiChange}
              testId="store-zone-select"
              storeZoneConfig={storeZoneConfig}
              value={storeZoneSelected} />
            <EmptyState
              icon={emptyIcon}
              message={getMessage('operations.pickingQueue.emptyList.message')} />
          </Fragment> : <EmptyState message={getMessage('order.details.errors.onLoad')} /> }
        </Fragment>
      )}
      {showResults && zonePickingData.picklists.length !== 0 && (
        <Fragment>
          {isZonePickingStore(storeZoneConfig) ? <Fragment>
            <ZonesMulti
              testId="store-zone-select"
              onChange={handleMultiChange}
              storeZoneConfig={storeZoneConfig}
              value={storeZoneSelected} />
          </Fragment> : null}
          <ZonePickingQueueTable zonePickingData={zonePickingData} storeID={storeID} />
          <div className="pagination-container">
            <div className="pagination-count">
              {`${getMessage('pagination.text')} ${offset + 1} - ${
              limit > 0 && offset >= 0 ? Math.min(offset + limit, count) : count
            } ${getMessage('pagination.helperText')} ${count}`}</div>
            {totalPages > 1 && (
              <Pagination
                total={totalPages}
                current={Number(page) || Math.floor(offset / limit + 1)}
                onSelect={changePage}
              />
            )}
          </div>
        </Fragment>
      )}
    </AuthenticatedPage>
  )
}

export default ZonePickingQueue
