import React, { Fragment } from 'react'
import { Row, Cell } from '../../../../../../components/Table'
import { Checkbox } from '../../../../../../components/Form'
import {
  checkedCustomizationSubOrders,
  handleDisabledItemLevel,
} from '../OrderSummaryHelpers'
import './style.css'
import { STATUSES } from '../../Details.enum'

const refundCustomizationQuantity = (
  state,
  suborder,
  orderId,
  suborderId,
  customisationIndex,
  customisationOptions
) => {
  return state.refundOrder &&
    suborder.state === STATUSES.COLLECTED &&
    !state.showHideTablePopup
    ? state.refundItems
        .find(a => a.id === suborderId)
        .items.find(b => b.id === orderId).customisationOptions[
        customisationIndex
      ].finalQuantity
    : customisationOptions.finalQuantity
}
const handleCustomisationItemSingleSelected = (
  idx,
  suborderId,
  customisationIndex,
  handleState,
  state,
  e
) => {
  handleState({
    ...state,
    allSelected: checkedCustomizationSubOrders(
      state,
      suborderId,
      idx,
      customisationIndex,
      e
    ).some(item => item.value),
    refundItems: checkedCustomizationSubOrders(
      state,
      suborderId,
      idx,
      customisationIndex,
      e
    ),
  })
}
const increaseCustomisationQuantity = (
  orderId,
  suborderId,
  customisationIndex,
  handleState,
  state
) => {
  ++state.refundItems
    .find(a => a.id === suborderId)
    .items.find(b => b.id === orderId).customisationOptions[customisationIndex]
    .finalQuantity
  handleState({
    ...state,
    refundItems: state.refundItems,
  })
}
const decreaseCustomisationQuantity = (
  orderId,
  suborderId,
  customisationIndex,
  handleState,
  state
) => {
  --state.refundItems
    .find(a => a.id === suborderId)
    .items.find(b => b.id === orderId).customisationOptions[customisationIndex]
    .finalQuantity
  handleState({
    ...state,
    refundItems: state.refundItems,
  })
}
const customizedFormattedTotal = (customisationOptions, refundSuborder) => {
  return (
    <Cell>
      {refundSuborder
        ? customisationOptions.formattedRefundedCustomisationTotal
        : customisationOptions.formattedCustomisationTotal}
    </Cell>
  )
}
const customizedFormattedPrice = (customisationOptions, refundSuborder) => {
  return (
    <Cell>
      {refundSuborder
        ? customisationOptions.formattedDiscountedPrice
        : customisationOptions.formattedPrice}
    </Cell>
  )
}
const customizationQuantityButton = str => (
  <button className={`primary button quantity hide-quantity`}>{str}</button>
)
const CustomizationName = customisationOptions => {
  return (
    <div className="qc-order-summary-grey">{customisationOptions.name}</div>
  )
}

const CustomizationUnitContainerFee = customisationOptions => {
  const feeDetails = customisationOptions?.feeDetails
  return feeDetails?.length &&
    Object.keys(feeDetails.find(feeItem => feeItem.type === 'CONTAINER_FEE'))
      .length
    ? feeDetails.find(feeItem => feeItem.type === 'CONTAINER_FEE')
        .formattedUnitAmount
    : '$0.00'
}
const refundedCustomisationOptions = (
  props,
  customisationIndex,
  customisationOptions
) => {
  return (
    <Row
      className={`suborder-customisation-refunded-items ${props.order
        .customisationOptions.length -
        1 ===
        customisationIndex && 'last-customisation-items'}  ${props.order
        .refundedQuantity === 0 &&
        customisationIndex === 0 &&
        'no-order-item'} ${!props.order.refundedQuantity &&
        'customisation-top-space'}`}
      key={customisationOptions.id}
    >
      <Cell>
        <div className={`customisation-options-container`}>
          {CustomizationName(customisationOptions)}
        </div>
      </Cell>
      <Cell>{CustomizationUnitContainerFee(customisationOptions)}</Cell>
      {customizedFormattedPrice(customisationOptions, props.refundSuborder)}
      <Cell>
        <div>{'$0.00'}</div>
      </Cell>
      <Cell>
        {props.state.refundOrder &&
          !props.state.showHideTablePopup &&
          customizationQuantityButton('-')}
        {customisationOptions.refundedQuantity}
        {props.state.refundOrder &&
          !props.state.showHideTablePopup &&
          customizationQuantityButton('+')}
      </Cell>

      {customizedFormattedTotal(customisationOptions, props.refundSuborder)}
    </Row>
  )
}

const CustomisationOptions = props => {
  return (
    <Fragment>
      {props.order.customisationOptions.map(
        (customisationOptions, customisationIndex) => (
          <Fragment key={customisationOptions.id}>
            {!props.refundSuborder && customisationOptions.finalQuantity !== 0 && (
              <Row
                className={`suborder-customisation-items 
                ${(props.order.customisationOptions.length - 1 ===
                  customisationIndex ||
                  props.order.customisationOptions.filter(a => a.finalQuantity)
                    .length -
                    1 ===
                    customisationIndex) &&
                  'last-customisation-items'} 
                  ${props.order.finalQuantity === 0 &&
                    customisationIndex === 0 &&
                    'no-order-item'}  
                  ${!props.order.finalQuantity && 'customisation-top-space'}`}
                key={customisationOptions.id}
              >
                <Cell>
                  <div
                    className={`customisation-options-container ${props.state
                      .refundOrder &&
                      !props.state.showHideTablePopup &&
                      'customisation-checkbox'}`}
                  >
                    {props.state.refundOrder &&
                      !props.state.showHideTablePopup && (
                        <Checkbox
                          testid={`customisationOptions${props.order.id}${customisationIndex}`}
                          name={`customisationOptions${props.order.id}${customisationIndex}`}
                          value={
                            props.state.refundItems[props.suborderidx].items[
                              props.idx
                            ].customisationOptions[customisationIndex].value
                          }
                          onChange={handleCustomisationItemSingleSelected.bind(
                            this,
                            props.idx,
                            props.suborderId,
                            customisationIndex,
                            props.handleState,
                            props.state
                          )}
                          disabled={handleDisabledItemLevel(props)}
                        />
                      )}
                    {CustomizationName(customisationOptions)}
                  </div>
                </Cell>
                <Cell>
                  {CustomizationUnitContainerFee(customisationOptions)}
                </Cell>
                {customizedFormattedPrice(
                  customisationOptions,
                  props.refundSuborder
                )}
                <Cell>
                  <div>{'$0.00'}</div>
                </Cell>
                <Cell>
                  {props.state.refundOrder && !props.state.showHideTablePopup && (
                    <button
                      data-testid={`decreaseCustomisationQuantity${customisationIndex}${props.idx}${props.suborderId}${props.suborderidx}`}
                      onClick={decreaseCustomisationQuantity.bind(
                        this,
                        props.order.id,
                        props.suborderId,
                        customisationIndex,
                        props.handleState,
                        props.state
                      )}
                      className={`primary button quantity ${props.suborder
                        .state !== STATUSES.COLLECTED && 'hide-quantity'}`}
                      disabled={
                        !(
                          props.state.refundItems[props.suborderidx].items[
                            props.idx
                          ].customisationOptions[customisationIndex].value &&
                          props.state.refundItems[props.suborderidx].items[
                            props.idx
                          ].customisationOptions[customisationIndex]
                            .finalQuantity > 1
                        )
                      }
                    >
                      -
                    </button>
                  )}
                  {refundCustomizationQuantity(
                    props.state,
                    props.suborder,
                    props.order.id,
                    props.suborderId,
                    customisationIndex,
                    customisationOptions
                  )}
                  {props.state.refundOrder && !props.state.showHideTablePopup && (
                    <button
                      data-testid={`increaseCustomisationQuantity${customisationIndex}${props.idx}${props.suborderId}${props.suborderidx}`}
                      onClick={increaseCustomisationQuantity.bind(
                        this,
                        props.order.id,
                        props.suborderId,
                        customisationIndex,
                        props.handleState,
                        props.state
                      )}
                      className={`primary button quantity ${props.suborder
                        .state !== STATUSES.COLLECTED && 'hide-quantity'}`}
                      disabled={
                        !(
                          props.state.refundItems[props.suborderidx].items[
                            props.idx
                          ].customisationOptions[customisationIndex].value &&
                          props.state.refundItems[props.suborderidx].items[
                            props.idx
                          ].customisationOptions[customisationIndex]
                            .finalQuantity !==
                            customisationOptions.finalQuantity
                        )
                      }
                    >
                      +
                    </button>
                  )}
                </Cell>

                {customizedFormattedTotal(
                  customisationOptions,
                  props.refundSuborder
                )}
              </Row>
            )}
            {!!customisationOptions.refundedQuantity &&
              customisationOptions.refundedQuantity !== 0 &&
              props.refundSuborder &&
              refundedCustomisationOptions(
                props,
                customisationIndex,
                customisationOptions
              )}
          </Fragment>
        )
      )}
    </Fragment>
  )
}
export {
  refundCustomizationQuantity,
  customizationQuantityButton,
  CustomizationUnitContainerFee,
}
export default CustomisationOptions
