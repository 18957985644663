import moment from 'moment'

function createTransformSubmit({ pageTypes, scopes }) {
  return (formData, meta = {}) => {
    const params = Object.assign({}, formData)
    let url = []
    params.url = url
    if(params.pageType === 'OMNI_HOMEPAGE'){
      //for omni_homepage, doesnt require appending prefix /
      params.linkUrl = `${params.linkUrl ? params.linkUrl : ''}`
    } else {
      // To prevent appending the word "undefined"
      params.linkUrl = `/${params.linkUrl ? params.linkUrl : ''}`
    }
    if (params.pageType && params.pageType === pageTypes.category) {
      url =
        params.categoryUrl &&
        params.categoryUrl.map((obj) => {
          const value = obj.slug || obj
          return value
        })
      delete params.categoryUrl
    } else if (params.pageType && params.pageType === pageTypes.brand) {
      url =
        params.brandUrl &&
        params.brandUrl.map((obj) => {
          const value = obj.slug || obj
          return value
        })
      delete params.brandUrl
    } else if (params.pageType && params.pageType === pageTypes.productTag) {
      url =
        params.productTagUrl &&
        params.productTagUrl.map((obj) => {
          const value = obj.slug || obj
          return value
        })
      delete params.productTagUrl
    } else if (params.pageType && params.pageType === pageTypes.search) {
      url =
        params.searchUrl &&
        params.searchUrl.map((value) => {
          return value
        })
      delete params.searchUrl
    }
    params.url = (url && [...url]) || []

    if (
      params.pageType &&
      params.pageType === pageTypes.search &&
      params.searchTerm &&
      params.url
    ) {
      url = [...params.url]
      params.url.push(...url)
    }
    if (params.pageType === pageTypes.home || params.pageType === undefined) {
      delete params.url
    }
    if (moment(params.startTime).isSame(Date.now(), 'day')) {
      if (params.startTime) {
        const startDate = params.startTime.split(' ') || params.startTime
        if (startDate[1]) {
          params.startTime = startDate[0] + ' '
          params.startTime += params.bannerStartTime
            ? params.bannerStartTime + ':00'
            : startDate[1]
        } else {
          params.startTime = params.startTime + ' '
          params.startTime += params.bannerStartTime
            ? params.bannerStartTime + ':00'
            : '00:00:00'
        }
      }
      const isBefore = new Date() > new Date(params.startTime)
      if (isBefore) {
        params.startTime =
          params.startTime || moment().format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      if (params.startTime) {
        const startDate = params.startTime.split(' ') || params.startTime
        if (startDate[1]) {
          params.startTime = startDate[0] + ' '
          params.startTime += params.bannerStartTime
            ? params.bannerStartTime + ':00'
            : startDate[1]
        } else {
          params.startTime = params.startTime + ' '
          params.startTime += params.bannerStartTime
            ? params.bannerStartTime + ':00'
            : '00:00:00'
        }
      }
    }

    if (params.endTime) {
      const endDate = params.endTime.split(' ') || params.endTime
      if (endDate[1]) {
        params.endTime = endDate[0] + ' '
        params.endTime += params.bannerEndTime
          ? params.bannerEndTime + ':00'
          : endDate[1]
      } else {
        params.endTime = params.endTime + ' '
        params.endTime += params.bannerEndTime
          ? params.bannerEndTime + ':00'
          : '00:00:00'
      }
    }

    if (params.bannerStartTime) {
      delete params.bannerStartTime
    }

    if (params.bannerEndTime) {
      delete params.bannerEndTime
    }

    if (params.startTime === null) {
      delete params.startTime
    }

    if (params.endTime === null) {
      delete params.endTime
    }
    if (!params.id) {
      // if pageType is home, sequence will be 0
      if (params.pageType === pageTypes.home) {
        params.sequence = 0
      } else {
        params.sequence = meta?.itemCount ? meta.itemCount + 1 : 1
      }
    } else {
      delete params.sequence
    }
    params.scope = params.scope ? params.scope : scopes.global
    if (params.scope === scopes.global) {
      params.storeId = []
    }
    if (!params.bannerType) {
      params.bannerType = 'BIG'
    }
    return params
  }
}

export { createTransformSubmit }
