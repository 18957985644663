import { sortSlots } from 'lib/commonlyused'
import { getMessage } from 'lib/translator'

const orderTypeOptions = [
  {
    text: getMessage('delivery'),
    value: 'DELIVERY'
  },
  {
    text: getMessage('cp.rb_preorder'),
    value: 'RB_PREORDER'
  }
]

// This generates delivery and pickup capacity for each zone.
function generateTabularDataSkeleton(zonesAndSlots) {
  const skeleton = {}
  zonesAndSlots &&
    zonesAndSlots.totalSlots &&
    zonesAndSlots.totalSlots.map(slot => {
      skeleton[slot] = []
      zonesAndSlots &&
        zonesAndSlots.totalZones &&
        zonesAndSlots.totalZones.map(zone => {
          const ob = JSON.parse(
            JSON.stringify({
              delivery: { capacity: null, orderCount: null },
              pickup: { capacity: null, orderCount: null },
              zone: JSON.parse(JSON.stringify(zone)),
            })
          )
          skeleton[slot].push(ob)
          return null
        })
      return null
    })
  return skeleton
}

function generateTabularData(tableSkeleton, inComingData) {
  inComingData.forEach(item => {
    const slot = item.startTime
      ? `${item.startTime}-${item.endTime}`
      : item.endTime
    if (item.orderType === 'DELIVERY') {
      tableSkeleton[slot].forEach((eachZone, i) => {
        eachZone.delivery.slotId = item.id
        eachZone.delivery.capacity = item.zones[i].capacity
        eachZone.delivery.orderCount = item.zones[i].orderCount
      })
    } else if (item.orderType === 'RB_PREORDER') {
      tableSkeleton[slot].push({
        slotId: item.id,
        capacity: item.capacity,
        orderCount: item.totalOrder
      })
    } else {
      tableSkeleton[slot].forEach((eachZone, i) => {
        eachZone.pickup.slotId = item.id
        eachZone.pickup.capacity = item.zones[i].capacity
        eachZone.pickup.orderCount = item.zones[i].orderCount
      })
    }
  })
  return tableSkeleton
}
function getSortedSlots(slots) {
  const formattedSlots = slots.map(item => {
    const data = item.split('-');
    return data.length > 1
      ? { type: 'STANDARD', endTime: data[1], startTime: data[0] }
      : { type: 'ASAP', endTime: data[0] }
  })
  const sortedFormattedSlots = sortSlots(formattedSlots)
  return sortedFormattedSlots
    .filter(item => item.type !== 'ASAP')
    .map(item => {
      return item.startTime
        ? `${item.startTime}-${item.endTime}`
        : item.endTime
    })
}

function slotsAndZones(slots) {
  const uniqueSlots = {}
  const slotMapToOrderType = {}
  slots.map(slot => {
    uniqueSlots[
      slot.startTime ? `${slot.startTime}-${slot.endTime}` : slot.endTime
    ] = slot
    if (
      slotMapToOrderType[
        slot.startTime ? `${slot.startTime}-${slot.endTime}` : slot.endTime
      ]
    ) {
      slotMapToOrderType[
        slot.startTime ? `${slot.startTime}-${slot.endTime}` : slot.endTime
      ] = 'BOTH'
    } else {
      slotMapToOrderType[
        slot.startTime ? `${slot.startTime}-${slot.endTime}` : slot.endTime
      ] = slot.orderType
    }
    return null
  })
  return {
    totalSlots: (uniqueSlots && Object.keys(uniqueSlots)) || [],
    totalZones:
      (uniqueSlots &&
        Object.values(uniqueSlots).length > 0 &&
        Object.values(uniqueSlots)[0] &&
        Object.values(uniqueSlots)[0].zones) ||
      [],
    slotMapToOrderType,
  }
}

function isEquals(data, tableIndex) {
  return (data.deliveryTotal && Number(data.deliveryTotal) === Number(tableIndex.deliveryTotal))
    || (data.pickUpTotal && Number(data.pickUpTotal) === Number(tableIndex.pickUpTotal))
}

export {
  orderTypeOptions,
  generateTabularDataSkeleton,
  generateTabularData,
  getSortedSlots,
  slotsAndZones,
  isEquals
}
