import React from 'react'
import Challenges from './Challenges/Challenges'
import Draws from './Draws/Draws'
import RewardsCatalogue from './RewardsCatalogue/RewardsCatalogue'
import './style.css'

const Rewards = ({ uid }) => (
  <div className="container rewards-tab mt">
    <Challenges uid={uid} />
    <hr />
    <Draws uid={uid} />
    <hr />
    <RewardsCatalogue uid={uid} />
  </div>
)

export default Rewards
