import React, { useEffect } from 'react'
import { Field, useField } from 'formik'
import { get, set } from 'lib/storage'
import { FormikInput, FormikSelect } from '../components/FormikInput'
import { contentHeightWithBackBtn } from '../constant'
import { View, FixedButton } from '../ui'

export const SelectStore = ({ onConfirm }) => {
  const [staffIdField, meta] = useField('staffId')
  const [storeIdField] = useField('storeId')

  const stores = JSON.parse(get('stores'))

  const handleConfirm = async () => {
    if (staffIdField.value && storeIdField.value && !meta.error) {
      onConfirm()
    }
  }
  useEffect(() => {
    set('store', storeIdField.value)
  }, [storeIdField.value])

  return (
    <>
      <View
        direction="column"
        style={{ height: `calc(${contentHeightWithBackBtn})` }}
        justifyContent="space-between"
      >
        <View direction="column" alignItems="start">
          <Field
            name="storeId"
            component={FormikSelect}
            label="STORE"
            options={stores.map((s) => ({ text: s.name, value: s.id }))}
          />
          <Field
            name="staffId"
            label="STAFF ID"
            placeholder="Enter staff ID"
            component={FormikInput}
          />
        </View>
      </View>
      <FixedButton onClick={handleConfirm}>Confirm</FixedButton>
    </>
  )
}
