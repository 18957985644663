import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AuthenticatedPage from '../../../containers/AuthenticatedPage/index'
import { getMessage } from '../../../lib/translator'
import HelpWidget from '../../../components/HelpWidget'
import HelpCard from '../../../components/HelpCard'
import Warning from '../../../components/Warning'
import { isEnterprise } from '../../../lib/auth'

import productsIcon from './products.svg'
import uploadingImagesIcon from './uploading-images.svg'
import contentMarketingIcon from './content-marketing.svg'

import API from '../../../lib/api'
import Loader from '../../../components/Loader'
import NameValueCard from '../../../components/NameValueCard'

import './style.css'

const helpItems = {
  title: getMessage('catalogue.dashboard.helpItems.title'),
  instructions: [
    {
      icon: productsIcon,
      title: getMessage('catalogue.dashboard.helpItems.gp.title'),
      description: getMessage('catalogue.dashboard.helpItems.gp.description'),
    },
    {
      icon: uploadingImagesIcon,
      title: getMessage('catalogue.dashboard.helpItems.uI.title'),
      description: getMessage('catalogue.dashboard.helpItems.uI.description'),
    },
    {
      icon: contentMarketingIcon,
      title: getMessage('catalogue.dashboard.helpItems.cm.title'),
      description: getMessage('catalogue.dashboard.helpItems.cm.description'),
    },
  ],
}

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.api = null
    this.state = {
      showLoader: false,
      catalogueCountData: [],
      productsData: {},
      apiError: '',
    }
    this.colors = ['green', 'gray', 'blue']
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    })
    this.api = new API({ url: '/catalogue-service/stats' })
    this.api.get().then(
      response => {
        const catalogueCountData = []
        const productsData = response.data['products'] || {}
        let index = 0
        for (var key in response.data) {
          if (response.data[key]['count'] >= 0) {
            catalogueCountData.push({
              name: key,
              value: response.data[key]['count'],
              color: this.colors[index++],
            })
          }
        }
        this.setState({
          catalogueCountData,
          productsData,
          showLoader: false,
        })
      },
      error => {
        if (error.message === 'cancelled') {
          return
        }
        if (error.code === 401) {
          throw error
        }
        this.setState({
          apiError: error.message,
          showLoader: false,
        })
      }
    )
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  render() {
    const { menu } = this.props
    const { showLoader, catalogueCountData, productsData } = this.state
    const cards = catalogueCountData.map(({ name, ...data }, index) => (
      <NameValueCard
        key={`item-${index}`}
        name={
          <Link className="pages-link" to={`/catalogue/${name}`}>
            {getMessage('catalogue.dashboard.' + name, {
              count: this.state.catalogueCountData[index]['value'],
            })}
          </Link>
        }
        {...data}
      />
    ))

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className="catalogue-dashboard"
        title={getMessage('catalogue.dashboard.heading')}
      >
        <h1 className="title">{getMessage('catalogue.dashboard.heading')}</h1>
        {showLoader ? (
          <Loader />
        ) : (
          <div>
            {this.state.apiError && (
              <div className="api-error">{getMessage('error.generic')}</div>
            )}
            <div className="count-info">{cards}</div>

            <div className="products-info">
              {productsData.withoutImagesCount > 0 && (
                <Warning
                  textToBold={productsData.withoutImagesCount}
                  sentence={getMessage(
                    'catalogue.dashboard.productsWithoutImages',
                    { count: productsData.withoutImagesCount }
                  )}
                  tooltipText={getMessage(
                    'catalogue.dashboard.helpItems.uI.description'
                  )}
                />
              )}
              {productsData.withoutDescriptionCount > 0 && (
                <Warning
                  textToBold={productsData.withoutDescriptionCount}
                  sentence={getMessage(
                    'catalogue.dashboard.productsWithoutDescription',
                    { count: productsData.withoutDescriptionCount }
                  )}
                  tooltipText={getMessage(
                    'catalogue.dashboard.helpItems.gp.description'
                  )}
                />
              )}
            </div>
          </div>
        )}
        {!isEnterprise() && (
          <HelpWidget title={getMessage('catalogue.dashboard.helpItems.title')}>
            {helpItems.instructions.map((instruction, index) => (
              <HelpCard
                icon={instruction.icon}
                title={instruction.title}
                key={index}
              >
                {instruction.description}
              </HelpCard>
            ))}
          </HelpWidget>
        )}
      </AuthenticatedPage>
    )
  }
}
