import React, { Fragment } from 'react'
import Table, { Row, Cell } from '../../../../../../components/Table'
import { getMessage } from '../../../../../../lib/translator'
import './style.css'

const ContainerFee = ({ containerFee }) => {
  return (
    <Fragment>
      {containerFee && (
        <Table className="container-fee-table table-dynamic">
          <Row className={`container-fee-main-row`} key={`qc-container-row`}>
            <Cell>
              <b>{getMessage('qc.orderSummary.containerFee')}</b>
            </Cell>
            <Cell>
              <b>{containerFee.formattedPrice}</b>
            </Cell>
          </Row>
          <Fragment>
            {containerFee.breakdown.map((item, itemIndex) => (
              <Fragment key={`containerFeeKey${itemIndex}`}>
                {item.formattedPrice && (
                  <Row
                    className={`container-fee-items-row ${item.formattedPayable ===
                      '$0.00' &&
                      !(item.isCancelled || item.isRefunded) &&
                      'line-through'}`}
                    key={`containerFee${itemIndex}`}
                  >
                    <Cell>
                      {item.displayName}{' '}
                      {(item.isCancelled || item.isRefunded) && (
                        <Fragment>
                          ({getMessage('qc.orderSummary.containerFee.returned')}
                          )
                        </Fragment>
                      )}
                    </Cell>
                    <Cell>{item.formattedPrice}</Cell>
                  </Row>
                )}
              </Fragment>
            ))}
          </Fragment>
          {containerFee.breakdown && !containerFee.breakdown[0].displayName && (
            <div className="container-fee-items-row-spacer"></div>
          )}
        </Table>
      )}
    </Fragment>
  )
}

export default ContainerFee
