import React from 'react'
import { Link } from 'react-router-dom'
import { formatIsoDate } from '../../lib/datetime'
import { Row, Cell } from '../Table'
import { DropDown, DropDownItem, ICONS } from '../DropDown'
import { TABLE_ACTIONS } from '../../containers/ListingPage'
import { getMessage } from '../../lib/translator'

export const linkButtons = () => {
  return (
    <div className="header-wrap">
      <DropDown
        icon={
          <button className="primary button">
            <span className="text">Manage E-Voucher</span>
          </button>
        }
      >
        <DropDownItem>
          <Link to="/marketing/evouchers/add">
            + New <span>E-Voucher</span>
          </Link>
        </DropDownItem>
        <DropDownItem>
          <Link to="/marketing/search-evoucher">
            Search <span>E-Voucher</span>
          </Link>
        </DropDownItem>
      </DropDown>
    </div>
  )
}

export const tableProperties = () => {
  return {
    headers: [
      'Batch ID',
      'Entity Name',
      'Initial Amount',
      'Total Voucher Count',
      'Batch Status',
      'Created At',
      'Actions',
    ],
    row: ({
      id,
      entityName,
      totalAmount,
      totalVoucherCount,
      voucherStatus,
      status,
      createdAt,
      onAction,
    }) => (
      <Row>
        <Cell className="rewards-page-actions column-batch-id">
          <Link
            data-testid={`batch-click-${id}`}
            to={`/marketing/evoucher-details/${id}`}
          >
            {id}
          </Link>
        </Cell>
        <Cell className="rewards-page-actions column-entity-name">
          {entityName}
        </Cell>
        <Cell className="rewards-page-actions column-total-amount">
          ${totalAmount.toLocaleString()}
        </Cell>
        <Cell className="rewards-page-actions column-total-voucher-count">
          {totalVoucherCount}
        </Cell>
        <Cell className="rewards-page-actions column-status">{status}</Cell>
        <Cell className="rewards-page-actions column-creation-time">
          {formatIsoDate(createdAt)}
        </Cell>
        <Cell className="rewards-page-actions column-actions">
          <DropDown
            data-testid={`column-action-${id}`}
            icon={<img src={ICONS.VELLIP} alt="⋮" />}
            isDisable={voucherStatus === 'DISABLED'}
          >
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.VIEW, { id })
              }}
            >
              Resend
            </DropDownItem>
            <DropDownItem
              dataTestid={`disable-action-${id}`}
              onClick={() => {
                onAction(TABLE_ACTIONS.DISABLE, { id })
              }}
            >
              Disable
            </DropDownItem>
            <DropDownItem
              dataTestid={`update-action-${id}`}
              onClick={() => {
                onAction(TABLE_ACTIONS.UPDATE_EXPIRY, { id })
              }}
            >
              {getMessage('eVoucher.update.expiry')}
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    ),
  }
}

export const validationMsgs = {
  rangeOverflow: 'E-Voucher can only support Amount <= $1000.00',
  stepMismatch: 'E-Voucher can only support amount >= $0.01',
  rangeUnderflow: 'E-Voucher can only support amount >= $0.01',
}

export const emailRegex =
  '(([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}(;|,|$)s?))*'

export const multipleEmailErrorMsg = {
  patternMismatch: 'Incorrect value',
}
