import React from 'react'

import { PaymentSearch } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

import SelectStoresSearch from '../../../../components/SelectStoresSearch'

const FormAdvancedSearch = ({ BaseForm, data = {} }) => {
  const { values, stores } = data
  const { searchCategory } = values

  return (
    <React.Fragment>
      <div className="Payment-store-search">
        <SelectStoresSearch
          label={getMessage('customerSupport.payments.selectStore.label')}
          placeholder="Select store"
          _this={BaseForm}
          stores={stores}
          searchById
          required
          stateKey="storeIds"
        />
      </div>
      <PaymentSearch
        value={searchCategory}
        BaseForm={BaseForm}
        mode="advanced"
      />
    </React.Fragment>
  )
}

FormAdvancedSearch.propTypes = {}

export default FormAdvancedSearch
