import { useState, useEffect } from 'react';
import API from 'lib/api';
import { filterRules } from './helpers';
import { get } from 'lib/storage';

const useRules = (pageNum = 1) => {
  const [rules, setRules] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [totalRuleCount, setTotalRuleCount] = useState(null);

  /*
    When does this hook fetch rules?
    * upon component mounted: useEffect triggered
    * load more: button click => setPageNum(prev => Math.abs(prev) + 1) => useEffect triggered
    * after rule added/ after rule deleted/ search field cleared/ store changed:
       refetchRules() => if pageNum is abs(1), negate. Otherwise, set to 1 => useEffect triggered
  */
  const fetchRules = () => {
    setIsLoading(true);
    const absPageNum = Math.abs(pageNum);
    const isPageOne = absPageNum === 1;

    // if we dont set the rules to empty for refetch all. Map will render last viewed zone
    isPageOne && setRules([]);

    const params = {
      page: absPageNum,
      pageSize: 20,
      orderType: 'DELIVERY',
      storeId: get('store')
    }
    const ruleApi = new API({ url: `/logistics-management-service/v1/rules` });

    ruleApi.get(params)
      .then(res => {
        const { filteredRules, numberOfFilteredOutRules } = filterRules(res.data.items);
        setTotalRuleCount(res.data.count - numberOfFilteredOutRules);
        if (isPageOne) {
          const hasNextPageToLoad = res.data.items.length < res.data.count;
          setHasNextPage(hasNextPageToLoad)
          setRules(filteredRules);
        } else {
          const hasNextPageToLoad = (rules.length + res.data.items.length) < res.data.count;
          setHasNextPage(hasNextPageToLoad)
          setRules(prev => [...prev, ...filteredRules])
        }
        setIsError(false)
      })
      .catch(e => {
        console.log(e)
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    fetchRules();
  }, [pageNum])

  return { isLoading, isError, rules, totalRuleCount, hasNextPage, setRules, refetchRules: fetchRules }
}

export default useRules;
