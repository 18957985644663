import React, { Component } from 'react'
import ListingPage, { TABLE_ACTIONS }  from '../../../../containers/ListingPage'
import API from 'lib/api'
import { Link } from 'react-router-dom'
import { DropDown, DropDownItem, ICONS } from '../../../../components/DropDown'
import { Row, Cell } from '../../../../components/Table'
import { getMessage } from '../../../../lib/translator'
import './style.css'
import emptyIcon from './empty.svg'
import ProductFilters from './Filters'
import Dialog from '../../../../components/Popup/Dialog'

const emptyState = {
  icon: emptyIcon,
  message: 'There are no scheduled upload jobs',
}

const tableProperties = (onEventAction) => {
  return {
    headers: [
      getMessage('scheduled.batch.jobs.column.jobId'),
      getMessage('scheduled.batch.jobs.column.status'),
      getMessage('scheduled.batch.jobs.column.jobType'),
      getMessage('scheduled.batch.jobs.column.jobName'),
      getMessage('scheduled.batch.jobs.column.total'),
      getMessage('scheduled.batch.jobs.column.success'),
      getMessage('scheduled.batch.jobs.column.failure'),
      getMessage('scheduled.batch.jobs.column.pending'),
      getMessage('scheduled.batch.jobs.column.createdBy'),
      getMessage('scheduled.batch.jobs.column.createdAt'),
      getMessage('scheduled.batch.jobs.column.scheduledAt'),
      getMessage('scheduled.batch.jobs.column.completedAt'),
      getMessage('scheduled.batch.jobs.column.jobExecutionTime'),
      getMessage('scheduled.batch.jobs.column.avgJobExecutionTime'),
      getMessage('product.header.actions'),
    ],
    row: ({ id, state, type, name, report, scheduledAt, createdAt, completedAt,user, stats, onAction}) => /* istanbul ignore next */(
      <Row>
        <Cell>
          <Link
            to={`/catalogue/batch-uploads-status-tracker/view/${id}`}
            className="job-name"
          >
            <span>{id}</span>
          </Link>
        </Cell>
        <Cell>
          <span>{state}</span>
        </Cell>
        <Cell>
          <span>{type}</span>
        </Cell>
        <Cell>
          <span>{name}</span>
        </Cell>
        <Cell>
          <span>{report.totalTask}</span>
        </Cell>
        <Cell>
          <span>{report.succeededTask}</span>
        </Cell>
        <Cell>
          <span>{report.failedTask}</span>
        </Cell>
        <Cell>
          <span>{report.pendingTask}</span>
        </Cell>
        <Cell className="userID">{user.name}({user.id})</Cell>
        <Cell className="createdAt">{createdAt}</Cell>
        <Cell className="scheduledAt">{scheduledAt}</Cell>
        <Cell className="scheduledAt">{completedAt}</Cell>
        <Cell className="scheduledAt">{stats.jobExecutionTime}</Cell>
        <Cell className="scheduledAt">{stats.averageTaskExecutionTime}</Cell>
        <Cell className="batch-actions">
        { (state=== 'PENDING' || state=== 'SUBMITTED') && <DropDown
            data-testid={`batch-action-dropdown-${id}`}
            className="batch-action-dropdown"
            icon={<img src={ICONS.VELLIP} alt="⋮" />}
          >
            <DropDownItem
                onClick={() => {
                  onEventAction('ABORTED',id,onAction)
                }}
              >
                {getMessage('Abort')}
              </DropDownItem>
            <DropDownItem
                 onClick={() => {
                  onEventAction('SUSPENDED',id,onAction)
                 }}
                className="hide-action"
              >
                {getMessage(
                  `Suspend`
                )}
              </DropDownItem>
          </DropDown>}
        </Cell>
      </Row>
    ),
  }
}

export default class BatchUploadsStatusList extends Component {
  constructor() {
    super()
    this.state = {
      showDialog: false,
      dialogMessage: '',
      status: 'success'
    }
    this.onAction = this.onAction.bind(this)
    this.toggleDialog = this.toggleDialog.bind(this)
  }

  toggleDialog() {
    this.setState({
      showDialog: !this.state.showDialog,
    })
  }
  async onAction(event, id, onAction){
    try {
      const api = new API({
        url: `/gt-catelogue/v1/batch-jobs/${id}`,
      })

      const response = await api.patch({state: event})
      this.setState({
        showDialog: true,
        dialogMessage: response.data,
        status: 'success'
      })
      onAction(TABLE_ACTIONS.REFRESH)
    } catch (error) /* istanbul ignore next */{
      this.setState({
        showDialog: true,
        dialogMessage: error.message,
        status: 'failure'
      })
    }
  }

  render() {
    const props = this.props
    return (
      <div>
        <ListingPage
           menu={props.menu}
           className="scheduled-jobs-status-page"
           addHeading="Batch upload status tracker"
           title="Batch upload status tracker"
           api={{
             url: '/gt-catelogue/v1/batch-jobs',
             transform: response => /* istanbul ignore next */response && response.data ? response.data.jobs : []
           }}
           showLanguageDropDown
           emptyState={emptyState}
           tableProperties={tableProperties(this.onAction)}
           primaryKey="id"
           filters={{
            component: ProductFilters,
            transformSubmit: formData => {
              const filters = Object.assign({}, formData)
              if (formData['sort[]']) {
                const values = formData['sort[]'].split(':')
                filters[`sort[${values[0]}]`] = values[1]
              }
              return filters
            },
          }}
        />
        <Dialog
          className={this.state.status}
          show={this.state.showDialog}
          close={this.toggleDialog}
          message={this.state.dialogMessage}
          closeText="OK"
        />
      </div>
    )
  }
}
