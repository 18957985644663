import React from 'react'
import { getMessage } from '../../../../../../lib/translator'
import './style.css'
import Table, { Row, Cell } from '../../../../../../components/Table'

const CutleryOption = ({ id, cutlery }) => {
  return (
    <Table className="table-dynamic">
      <Row key={`qc-cutlery-option-row-${id}`}>
        <Cell>
          <div data-testid="qc-cutlery-option" className="qc-cutlery-option">
            {getMessage('qc.orderSummary.table.CutleryOption')}:{' '}
            <b>
              {cutlery
                ? getMessage('qc.orderSummary.table.CutleryOption.Yes')
                : getMessage('qc.orderSummary.table.CutleryOption.No')}
            </b>
          </div>
        </Cell>
      </Row>
    </Table>
  )
}

export default CutleryOption
