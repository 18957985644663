import React, { useEffect, useState } from 'react';
import { getMessage } from 'lib/translator';
import API from 'lib/api';

export default function CustomFields({ data }) {

  const [config, setConfig] = useState(null);
  const ignoreKeysInView = ['pickupPersonContact', 'pickupPersonName'];

  useEffect(() => {
    async function extnApi() {
      const extensionApi = new API({ url: '/config-service/meta-data' });
      const res = await extensionApi.get();
      setConfig(res.data.config.entityMetaData.order);
    }

    extnApi();
  }, [])

  const filterByignoredKeys = (key) => {
    return data && data[key] && !ignoreKeysInView.includes(key)
  }

  return (
    <div className='order-metadata-details'>
      <div className="order-metadata-details">
        <div className="flex-around section-title">
          <h3>{getMessage('order.details.customFields.title')}</h3>
        </div>
        {config &&
          Object.entries(config).filter((key) => filterByignoredKeys(key[0])) // index 0 is key, index 1 is value
            .map(([key, _type], index) => (
                  <div key={`metadata-${key}-${index}`}>
                    <small className="text-muted">
                      {key}:&nbsp;
                      <span className="creation-time">
                        {data[key] === true ? 'Yes' : JSON.stringify(data[key])}
                      </span>
                    </small>
                  </div>
                ))
        }
      </div>
    </div>
  )
}
