import React from 'react'
import { Popup } from 'components/Popup'
import { withRouter } from 'react-router-dom'
import API from 'lib/api'
import { getMessage } from 'lib/translator'
import Form, { BaseForm, Textarea, Select, Checkbox } from 'components/Form'

// CSS
import './styles.css'

const REASONS = [
  {
    text: 'No payment received after 5 business days.',
    value: 'No payment received after 5 business days.',
  },
  {
    text: 'Submission is unclear.',
    value: 'Submission is unclear.',
  },
  {
    text: 'Payment amount is incorrect.',
    value: 'Payment amount is incorrect.',
  },
  {
    text: 'Others',
    value: 'others',
  },
]

const currencyFormat = (data) => {
  return new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
  }).format(data)
}

class RejectModal extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      formValues: {
        reason: '',
      },
      error: false,
      isLoading: false,
      checkedDetails: false,
    }
  }

  async submitHandler(e) {
    e && e.preventDefault()
    const { onClose } = this.props
    const { remarks, reason } = this.state.formValues
    const patchAPI = new API({
      url: `/gifting-admin-service/orders/${this.props.data.id}`,
    })
    if (this.state.checkedDetails) {
      this.setState({ error: false, isLoading: true })
      patchAPI
        .patch({
          message: remarks || reason,
          status: 'PENDING',
        })
        .then(() => {
          this.setState({ error: false, isLoading: false })
          onClose()
          this.props.history.push({
            pathname: '/customer-support/egifting-orders-pending-bank-approval',
            state: {
              action: 'rejected',
              reference: this.props.data.reference,
            },
          })
        })
        .catch(() => this.setState({ error: true, isLoading: false }))
    }
  }

  render() {
    const { show, onClose, data } = this.props
    const { SubmitButton } = this.buttons

    const isDisabled =
      this.state.isLoading ||
      !this.state.checkedDetails ||
      (!this.state.formValues.remarks &&
        this.state.formValues.reason === 'others')

    return (
      <Popup
        show={show}
        heading={getMessage('customerSupport.egifting.header.reject.issuance')}
        close={onClose}
        closeText={getMessage('dialog.cancelText')}
        className="reject-popup"
        okText={getMessage('dialog.okText')}
      >
        <Form onSubmit={(e) => this.submitHandler(e)}>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>
                  {getMessage(
                    'customerSupport.egifting.header.order.reference'
                  )}
                  :{' '}
                </h3>
              </div>
              <div className="col-6">
                <h3>{data.reference}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <h3>
                  {getMessage('customerSupport.payments.header.amount')}:{' '}
                </h3>
              </div>
              <div className="col-6">
                <h3>{currencyFormat(Number(data.amount))}</h3>
              </div>
            </div>
            <div className="row mb">
              <div className="col-6">
                <h3>
                  {getMessage('customerSupport.egifting.header.reject.reason')}:{' '}
                </h3>
              </div>
              <div className="col-6 mt">
                <Select
                  required
                  options={REASONS}
                  testid="reasons"
                  placeholder={getMessage(
                    'customerSupport.egifting.placeholder.reason'
                  )}
                  onChange={(e) => {
                    this.setState({ formValues: { reason: e, remarks: '' } })
                  }}
                  value={this.state.formValues.reason}
                />
              </div>
            </div>
            <div className="row mb">
              <div className="col-6">
                <h3>
                  {getMessage('customerSupport.egifting.header.reject.remarks')}
                  :{' '}
                </h3>
              </div>
              <div className="col-6 mt">
                {this.state.formValues.reason?.toLowerCase() === 'others' ? (
                  <>
                    <p className="info small-font">
                      Remarks field is mandatory
                    </p>
                    <Textarea
                      type="text"
                      required
                      testid="text-area-others"
                      onChange={(e) => {
                        const orgObj = this.state.formValues
                        orgObj.remarks = e
                        this.setState({ formValues: orgObj })
                      }}
                      value={this.state.formValues.remarks}
                    />
                  </>
                ) : (
                  <Textarea
                    type="text"
                    required
                    disabled
                    value={this.state.formValues.reason}
                  />
                )}
              </div>
            </div>
            <div className="row mb">
              <div className="col-12 mb">
                <p className="info">
                  {getMessage('customerSupport.egifting.header.reject.info')}
                </p>
              </div>
            </div>
            <div className="row mt">
              <div className="col-12 mt">
                <Checkbox
                  label=""
                  testid="confirm-id"
                  inlineLabel="I confirm that all information given above is accurate and complete"
                  name="checkedDetails"
                  onChange={(v) => this.setState({ checkedDetails: v })}
                />
              </div>
            </div>
          </div>
          <div className="mt">
            <div className="flex-end">
              {this.state.error && (
                <div className="changes-saved error flex-end">
                  {getMessage('customerSupport.egifting.header.save.fail')}
                </div>
              )}
            </div>

            <div className="flex-end">
              <SubmitButton testid="resend-mltple-btn" disabled={isDisabled}>
                {getMessage('category.form.submitText')}
              </SubmitButton>
            </div>
          </div>
        </Form>
      </Popup>
    )
  }
}

export default withRouter(RejectModal)
