import React from 'react';
import NewListingPage from 'containers/NewListingPage';
import { getMessage } from 'lib/translator';
import emptyIcon from 'icons/no-orders.svg';
import SaleOrdersFilterForm from './Filters';
import SalesOrderTableProperties from './Views'

const SalesOrderTable = ({ menu, url }) => {

	const processFormDataFilter = (data) => {
		if (data.vendorCode === 'all') {
			delete data.vendorCode;
		}
		return data;
	}

	return (
		<NewListingPage
			menu={menu}
			primaryKey='referenceNumber'
			title={getMessage('operations.dashboard.saleOrders')}
			className={`sale-orders-page`} // this is used to store pagination in local storage
			tableProperties={SalesOrderTableProperties(url)}
			tableDynamic
			emptyState={{
				icon: emptyIcon,
				message: getMessage('saleOrder.listing.emptyPage')
			}}
			api={{
				url: '/order-service/v3/salesOrders',
				transform: response => response.salesOrders,
				params: { page: 1, pageSize: 20 }, // this is default search params. If any value exists in localStorage, that will overwrite default value.
			}}
			filters={{
				component: SaleOrdersFilterForm,
				forceShow: true,
				transformFilter: formData => {
					return processFormDataFilter(formData)
				},
			}}
		/>
	)
}

export default SalesOrderTable;
