import moment from 'moment'

const dateStr = 'YYYY-MM-DD'

function calculateDates(arr, operatingHour) {
  // According to the start and end dates, fill the time period into the time period of each day
  const dates = arr.map(item => makeDateBetweenArr(item.startDate, item.endDate, operatingHour));
  // push each day into an array
  const allDates = [];
  dates.forEach(item => allDates.push(...item));
  // duplicate removal
  if (allDates.length === 0) {
    return [];
  }
  const onlyDates = Array.from(new Set(allDates));

  // converted to a timestamp and quicksort is performed, with the earliest time placed first
  const times = onlyDates.map(item => new Date(item).getTime());
  const orderTimes = hurryShift(times);
  // The core of the algorithm:
  // Determine whether the date is continuous according to whether the timestamp difference of the adjacent date is greater than the millisecond number of a day. If not, it is the start and end date of the new time period
  const temp = dateRegroup(orderTimes)

  // The resulting array is truncated into a new array every two items, with the first value being the start date of the period and the second the end date
  let result = [];
  if (temp.length > 0) {
    let index = 0;
    let resIndex = 0;
    result = Array.from({length: Math.ceil(temp.length / 2)})
    while (index < temp.length) {
      const _index = index
      index += 2
      result[resIndex] = temp.slice(_index, index)
      resIndex++
    }
  }
  return formatDurationArr(result);
}

function dateRegroup(orderTimes){
  const temp = [];
  if (orderTimes.length === 1) {
    temp.push(...[orderTimes[0], orderTimes[0]]);
  } else {
    orderTimes.forEach((item, index) => {
      if (index === 0) {
        temp.push(moment(item).format(dateStr));
      } else {
        if (item - orderTimes[index - 1] > 86400000) {
          temp.push(orderTimes[index - 1]);
          temp.push(moment(item).format(dateStr));
        }
        if (index === orderTimes.length - 1) {
          temp.push(moment(item).format(dateStr));
        }
      }
    })
  }
  return temp
}

function formatDurationArr(result){
  const _result = []
  result.forEach(item => {
    _result.push({
      'startDate': item[0],
      'endDate': item[1]
    })
  })
  return _result
}

function makeDateBetweenArr(startDate, endDate, operatingHour) {
  let dates = [];
  const theDate = new Date(startDate);
  while (theDate < new Date(endDate)) {
    if(!operatingHour || operatingHour.includes(moment(new Date(theDate)).day())){
      dates = [...dates, moment(new Date(theDate)).format(dateStr)];
    }
    theDate.setDate(theDate.getDate() + 1);
  }
  dates = [...dates, moment(new Date(theDate)).format(dateStr)];
  return dates;
}

function concatDateArr(list){
  let newlist = []
  list.forEach(item => {
    newlist = newlist.concat(makeDateBetweenArr(item.startDate, item.endDate))
  })
  return newlist
}

function hurryShift(arr) {
  if (arr.length === 1 || arr.length === 0) {
    return arr;
  }
  const fix = arr[0];
  const left = [];
  const right = [];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] <= fix) {
      left.push(arr[i]);
    }
    if (arr[i] > fix) {
      right.push(arr[i]);
    }
  }
  return hurryShift(left).concat([fix], hurryShift(right));
}

export {
  calculateDates,
  makeDateBetweenArr,
  concatDateArr
}
