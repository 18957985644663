import React from 'react'
import moment from 'moment'

import {
  BaseForm,
  Select,
  Input,
  SingleDatePicker,
  TimePicker,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import { getMessage } from 'lib/translator'
import Loader from '../../../../components/Loader'
import {
  Status,
  FormButtons,
  ErrorScreen,
  CHANNEL_OPTIONS,
  getStatusText,
} from '../../../../components/Rewards/utils'
import {
  handleApis,
  handleError,
} from '../../../../components/Rewards/services'
import API from '../../../../lib/api'
import WebInfo from '../../../../components/Rewards/Partners/WebInfo'
import UrlInfo from '../../../../components/Rewards/Partners/UrlInfo'
import CategoryInfo from '../../../../components/Rewards/CategoryInfo'
import ImageUrls from '../../../../components/Rewards/Partners/ImageUrls'
import PartnerDetails from '../../../../components/Rewards/Partners/PartnerDetails'
import Permalink from 'components/Rewards/Permalink'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'

class PartnerForm extends BaseForm {
  async componentDidMount() {
    const values = Object.assign({}, this.state.values)
    values.loading = true
    this.setState({
      values,
    })
    let getPartnerDetails = ''
    const { id } = { ...this.props.value }
    if (id) {
      const api = new API({ url: `/rms/partners/${id}` })
      getPartnerDetails = api.get()
    }

    try {
      const catDetailsApi = new API({
        url: `/rms/categories?status=active&offset=0&limit=1000`,
      })
      const [catDetailsRes, partnerDetailsRes] = await Promise.allSettled([
        catDetailsApi.get(),
        getPartnerDetails,
      ])
      if (catDetailsRes.status === 'fulfilled') {
        const categoryListArr = catDetailsRes.value.data.list?.map((item) => {
          const status = getStatusText(item.status)
          const type = item.type === 'main' ? 'Visible' : 'Hidden'
          return {
            value: item.id,
            text: `${item.name}| ${status}| ${type}`,
          }
        })
        values.categoryList = categoryListArr
      } else {
        values.fetchCategoryError = catDetailsRes.reason.message
      }

      if (partnerDetailsRes.status === 'fulfilled' && partnerDetailsRes.value) {
        const partnerDetailsResValue = partnerDetailsRes.value
        const { web, facebook, instagram, externalUrl } = {
          ...partnerDetailsResValue.urlInfo,
        }

        const { metaTitle, metaDescription, story } = {
          ...partnerDetailsResValue.webInfo,
        }
        values.date = moment(partnerDetailsResValue.publishAt).format(
          'YYYY-MM-DD'
        )
        values.time = moment(partnerDetailsResValue.publishAt).format(
          'HH:mm:ss'
        )
        values.expiryDate = moment(partnerDetailsResValue.expiresAt).format(
          'YYYY-MM-DD'
        )
        values.expiryTime = moment(partnerDetailsResValue.expiresAt).format(
          'HH:mm:ss'
        )
        values.logo = partnerDetailsResValue.imgUrls?.logo
        values.banner = partnerDetailsResValue.imgUrls?.banner
        values.web = web
        values.facebook = facebook
        values.instagram = instagram
        values.externalUrl = externalUrl
        values.banner = partnerDetailsResValue.imgUrls?.banner
        values.metaTitle = metaTitle
        values.metaDescription = metaDescription
        values.story = story
        values.name = partnerDetailsResValue.name
        values.slug = partnerDetailsResValue.slug
        values.displayChannel = partnerDetailsResValue.displayChannel
        values.clsMerchantId = partnerDetailsResValue.clsMerchantId
      }

      if (partnerDetailsRes.status === 'rejected') {
        throw new Error(partnerDetailsRes.reason.message)
      }

      values.loading = false
      this.setState({
        values,
      })
    } catch (err) {
      handleError(err, this)
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    const {
      id,
      name,
      clsMerchantId,
      slug,
      status,
      displayChannel,
      logo,
      banner,
      date,
      time,
      expiryDate,
      expiryTime,
      linkpointGain,
      linkpointDescription = '',
      tnc,
      minSpend,
      description,
      linkpointDollarSpend,
      primaryCategoryId,
      categoryIds,
      web,
      facebook,
      instagram,
      externalUrl,
      metaTitle,
      metaDescription,
      story,
    } = this.state.values

    const publishAt = moment(
      `${date} ${time}`,
      'YYYY-MM-DD HH:mm:ss'
    ).toISOString()

    const expiresAt = moment(
      `${expiryDate} ${expiryTime}`,
      'YYYY-MM-DD HH:mm:ss'
    ).toISOString()

    const formattedPrimaryCatId = primaryCategoryId
      ? Number(primaryCategoryId)
      : ''
    const catIds = categoryIds
      ? [...categoryIds, formattedPrimaryCatId]
      : [formattedPrimaryCatId]
    const uniqueCatIds = catIds?.filter(
      (item, index) => catIds.indexOf(item) === index && item !== ''
    )
    const statusValue = status || 1

    if (isValid) {
      const mapData = {
        name,
        slug,
        clsMerchantId,
        linkpointGain,
        linkpointDescription,
        tnc,
        minSpend,
        description,
        linkpointDollarSpend,
        categoryIds: uniqueCatIds,
        displayChannel,
        publishAt,
        expiresAt,
        status: Number(statusValue),
        urlInfo: { web, facebook, instagram, externalUrl },
        imgUrls: { logo, banner },
        webInfo: {
          metaTitle,
          metaDescription,
          story,
        },
      }

      if (formattedPrimaryCatId) {
        mapData.primaryCategoryId = formattedPrimaryCatId
      }

      handleApis(mapData, `/rms/partners`, this, id)
    }
  }

  render() {
    const { webInfo, categoryList, fetchCategoryError, error, loading } = {
      ...this.state.values,
    }
    const { Form } = this.components

    return (
      <div className="rewards container partner-form">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="row">
              <div className="col-6">
                <Input
                  label={getMessage('rewards.name')}
                  placeholder={getMessage('rewards.name')}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['name'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
              <Permalink _this={this} />
            </div>
            <div className="row">
              <div className="col-12">
                <Input
                  label={getMessage('rewards.partner.clsMerchantId')}
                  placeholder={getMessage('rewards.partner.clsMerchantId')}
                  type="text"
                  maxLength={64}
                  {...this.generateStateMappers({
                    stateKeys: ['clsMerchantId'],
                  })}
                />
              </div>
            </div>
            <hr />
            {(categoryList || fetchCategoryError) && (
              <CategoryInfo
                _this={this}
                categoryList={categoryList}
                fetchCategoryError={fetchCategoryError}
                type="partners"
              />
            )}
            <hr />
            <div className="row">
              <div className="col-12">
                <Select
                  label={getMessage('rewards.displayChannel')}
                  testid="deviceType"
                  placeholder={getMessage('rewards.displayChannel')}
                  options={CHANNEL_OPTIONS}
                  {...this.generateStateMappers({
                    stateKeys: ['displayChannel'],
                  })}
                  required
                />
              </div>
              <Status _this={this} />
            </div>
            <div className="row">
              <div className="col-6">
                <SingleDatePicker
                  enableToday
                  required
                  label={getMessage('rewards.publishDate')}
                  placeholder={getMessage('rewards.publishDate')}
                  {...this.generateStateMappers({
                    stateKeys: ['date'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
              <div className="col-6">
                <TimePicker
                  label={getMessage('rewards.publishTime')}
                  required
                  placeholder={getMessage('rewards.publishTime')}
                  {...this.generateStateMappers({
                    stateKeys: ['time'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
              <div className="col-6">
                <SingleDatePicker
                  enableToday
                  required
                  testid="expiryDate"
                  label={getMessage('rewards.expiryDate')}
                  placeholder={getMessage('rewards.expiryDate')}
                  {...this.generateStateMappers({
                    stateKeys: ['expiryDate'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
              <div className="col-6">
                <TimePicker
                  label={getMessage('rewards.expiryTime')}
                  required
                  placeholder={getMessage('rewards.expiryTime')}
                  {...this.generateStateMappers({
                    stateKeys: ['expiryTime'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            </div>
            <ImageUrls _this={this} />
            <PartnerDetails _this={this} />
            <hr />
            <WebInfo webInfo={webInfo} _this={this} />
            <UrlInfo _this={this} />
            {this.props.method === 'edit' && (
              <DisplayCreatorEditorInfo {...this.state.values} />
            )}
            <FormButtons buttons={this.buttons} type="partners" />
            {error && <ErrorScreen error={error} />}
          </Form>
        )}
      </div>
    )
  }
}
export default PartnerForm
