import React, { Component, Fragment } from 'react'
import { Input, Select, MultiSelect } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import API from '../../../../lib/api'
import { getDisplaySlot, isAllRulesValid } from '../../../../lib/commonlyused'
import { isExtensionEnabled } from '../../../../lib/auth'
import { handleFocus } from './FormTabs/utils'

import deleteIcon from './delete.svg'

class SFXGSD extends Component {
  constructor(props) {
    super(props)

    this.state = {
      slotOptions: [],
    }
    this.validationHandler = this.validationHandler.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
    this.handleAddRule = this.handleAddRule.bind(this)
    this.handleDeleteRule = this.handleDeleteRule.bind(this)
    this.initData = this.initData.bind(this)
  }

  initData() {
    const { method, _this, onUpdateValues } = this.props
    if (method === 'add') {
      const { values } = _this && _this.state
      if (!values.rule) {
        onUpdateValues({ ...values, rule: [{ total: {} }] })
      }
    }
  }

  validationHandler(parent) {
    const validations = JSON.parse(JSON.stringify(parent.state.validations))
    parent.setState({
      validations,
    })
  }

  submitHandler(formData) {
    const data = Object.assign({}, formData)

    delete data.ruleDetail
    delete data.product
    delete data.category
    delete data.brand
    delete data.total
    delete data.entity
    return data
  }

  handleAddRule(e) {
    e && e.preventDefault()
    const { _this } = this.props
    const { values } = _this.state
    const { rule: ruleProp } = values || {}

    let rule = ruleProp
    if (!rule) {
      rule = [{ total: {} }]
    }

    rule = [...rule, { total: {} }]

    _this.setState({ pressedSubmitWithCurrentData: false })

    this.props.onUpdateValues({ ...values, rule })
  }

  handleDeleteRule(index) {
    const { _this } = this.props
    const { values } = _this.state
    const { rule: ruleProp } = values || {}

    let rule = ruleProp
    if (rule) {
      rule = rule.filter((_, idx) => idx !== index)
    }

    this.props.onUpdateValues({ ...values, rule })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.initData()
    }
  }

  componentDidMount() {
    this.initData()
    if (isExtensionEnabled('DeliverySlots')) {
      this.api = new API({ url: `/order-service/slot` })
      this.api
        .get({ paginated: 'false' })
        .then((response) => {
          const slots = response.data.slot
          const slotOptions = slots.map((slot) => ({
            text: getDisplaySlot(slot),
            value: slot.id,
          }))
          this.setState({
            slotOptions,
          })
        })
        .catch((err) => {
          if (err.code === 401) {
            throw err
          }
          this.error = err
        })
    }
  }

  render() {
    const {
      discountTypes,
      _this,
      hasOfferStarted,
      type,
      method,
      isPayViaFpApp,
    } = this.props

    const { values } = _this.state
    const { rule } = values || {}

    return (
      <Fragment>
        {rule
          ? rule.map((_, index) => (
              <div
                className={`offers-form ${
                  type === 'SFXGCD' ? 'rule-block' : ''
                }`}
                key={`rule-block-${index}`}
              >
                <div className="offer-rule-header">
                  {Boolean(index) && (
                    <img
                      className="rule-delete-icon"
                      onClick={() => this.handleDeleteRule(index)}
                      src={deleteIcon}
                      alt="delete"
                    />
                  )}
                </div>
                <div className="offer-fields">
                  <Input
                    name="cart-price"
                    className="shipping-cart"
                    type="number"
                    onFocus={handleFocus}
                    step="0.01"
                    required
                    readOnly={hasOfferStarted}
                    label={getMessage('offer.cartprice')}
                    placeholder={getMessage('offer.cartprice.placeholder')}
                    {..._this.generateStateMappers({
                      stateKeys: ['rule', index, 'cartPrice'],
                      loseEmphasisOnFill: true,
                    })}
                  />
                  <Select
                    name="discount-type"
                    required
                    label={getMessage('offer.discounttype')}
                    placeholder={getMessage('offer.discounttype.placeholder')}
                    options={discountTypes}
                    {..._this.generateStateMappers({
                      stateKeys: ['rule', index, 'total', 't'],
                      loseEmphasisOnFill: true,
                    })}
                    onChange={(discountType) =>
                      _this.handleDiscountChange(discountType, [index])
                    }
                    disabled={hasOfferStarted}
                  />
                  <Input
                    name="discount-value"
                    type="number"
                    onFocus={handleFocus}
                    step={0.01}
                    min={0}
                    max={
                      values &&
                      values.rule &&
                      values.rule[index].shippingDiscount &&
                      values.rule[index].shippingDiscount.t === `PERCENT_OFF`
                        ? 100
                        : values &&
                          values.rule &&
                          values.rule[index] &&
                          values.rule.cartPrice
                    }
                    required
                    label={getMessage('offer.discountvalue')}
                    placeholder={getMessage('offer.discountvalue.placeholder')}
                    {..._this.generateStateMappers({
                      stateKeys: ['rule', index, 'total', 'v'],
                      loseEmphasisOnFill: true,
                    })}
                    readOnly={
                      hasOfferStarted ||
                      (values &&
                        values.rule &&
                        values.rule[index] &&
                        values.rule[index].total &&
                        values.rule[index].total.t === `FREE`)
                    }
                  />
                  {values &&
                    values.rule &&
                    values.rule[index] &&
                    values.rule[index].total &&
                    values.rule[index].total.t === `PERCENT_OFF` && (
                      <Input
                        name="max-discount-value"
                        type="number"
                        onFocus={handleFocus}
                        min={0}
                        label={getMessage('offer.maxdiscountvalue')}
                        placeholder={getMessage(
                          'offer.discountvalue.placeholder'
                        )}
                        {..._this.generateStateMappers({
                          stateKeys: ['rule', index, 'maxDiscount'],
                          loseEmphasisOnFill: true,
                        })}
                        readOnly={
                          hasOfferStarted ||
                          (values &&
                            values.rule &&
                            values.rule[index] &&
                            values.rule[index].total &&
                            values.rule[index].total.t === `FREE`)
                        }
                      />
                    )}
                </div>
                {isExtensionEnabled('DeliverySlots') &&
                  type === 'SFXGCD' &&
                  !isPayViaFpApp && (
                    <div className="delivery-slot-multi-select">
                      <MultiSelect
                        label={getMessage('slots.placeholder')}
                        name="slots"
                        placeholder={getMessage('slots.placeholder')}
                        options={this.state.slotOptions}
                        {..._this.generateStateMappers({
                          stateKeys: ['rule', index, 'timeSlots'],
                          loseEmphasisOnFill: true,
                        })}
                        readOnly={hasOfferStarted}
                      />
                    </div>
                  )}
              </div>
            ))
          : null}
        {method === 'add' && type === 'SFXGCD' && (
          <button
            className="primary add-rule-btn"
            onClick={this.handleAddRule}
            disabled={!isAllRulesValid(rule, ['cartPrice', 'total'])}
          >
            +
          </button>
        )}
      </Fragment>
    )
  }
}

export default SFXGSD
