import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListingPage from '../../../containers/ListingPage'
import { Cell, Row } from '../../../components/Table'
import { getMessage } from '../../../lib/translator'
import storeFilters from './storeList/Filters'
import { getAssetTypes } from '../MediaAssetManagement/AssetTypeList'

import emptyIcon from './empty.svg'
import moment from 'moment'

function getMobileView () {
  return window.screen.width <= 480
}

const emptyState = {
  icon: emptyIcon,
  message: getMessage('media.storeList.empty.message'),
}

const tableProperties = (isMobileView) => {
  return {
    headers: [
      getMessage('media.storeList.header.id'),
      getMessage('media.storeList.header.name'),
      getMessage('media.storeList.header.status'),
      getMessage('media.storeList.header.availableSpots'),
      getMessage('media.storeList.header.actions'),
    ],
    row: (props) => (
      <Row>
        <Cell className='website-page-name'>
          {isMobileView && (
            <div className='text-muted'>
              {getMessage('media.storeList.header.id')}
            </div>
          )}
          {props.storeLabel}
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView && (
            <div className='text-muted'>
              {getMessage('media.storeList.header.name')}
            </div>
          )}
          {props.storeName}
        </Cell>
        <Cell className='website-page-status'>
          {isMobileView && (
            <div className='text-muted'>
              {getMessage('media.storeList.header.status')}
            </div>
          )}
          {props.storeStatus}
        </Cell>
        <Cell>
          {isMobileView && (
            <div className='text-muted'>
              {getMessage('media.storeList.header.availableSpots')}
            </div>
          )}
          {props.availableSpots}
        </Cell>
        <Cell className='website-page-actions'>
          <Link to={`/media-monetization/media-storelist/media-assetlist/${props.storeId}`}>
            {getMessage('media.storeList.header.actions.view')}
          </Link>
        </Cell>
      </Row>
    ),
  }
}
const dateFormat = 'YYYY-MM-DD'
export default class StoreList extends Component {
  constructor (props) {
    super(props)
    const defaultStartDate = moment().add(90, 'day').format(dateFormat)
    const defaultEndDate = moment().add(111, 'day').format(dateFormat)
    this.state = {
      isMobileView: getMobileView(),
      storeName: 'haha',
      data: {
        filter_duration: {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
        },
      },
    }

    this.mobileView = this.mobileView.bind(this)
  }

  mobileView () {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount () {
    window.addEventListener('resize', this.mobileView, false)
    getAssetTypes(this)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.mobileView, false)
  }

  render () {

    const defaultStartDate = moment().add(90, 'day').format(dateFormat)
    const defaultEndDate = moment().add(111, 'day').format(dateFormat)
    return (
      <ListingPage
        menu={this.props.menu}
        className='media-store-list website-pages'
        title={getMessage('media.storeList.title')}
        api={{
          url: '/martech-ad-service/reservations/store/inventory',
          params: {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            pageSize: 10,
          },
          transform: response => response?.data?.content || [],
        }}
        showLanguageDropDown
        emptyState={emptyState}
        primaryKey='storeLabel'
        tableProperties={tableProperties(this.state.isMobileView)}
        filters={{
          component: storeFilters,
          forceShow: true,
          options: {
            assetTypeList: this.state.assetTypeList || [],
            defaultStartDate,
            defaultEndDate,
          },
          transformSubmit: formData => {
            return Object.assign(
              formData.filter_duration ? {
                'startDate': formData.filter_duration.startDate,
                'endDate': formData.filter_duration.endDate,
              } : {},
              {
                'storeName': formData?.storeName,
                'assetType': formData?.assetType,
              })
          },
        }}
      />
    )
  }
}
