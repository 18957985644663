import { Input, Radio } from 'components/Form'
import { getMessage } from 'lib/translator'
import React from 'react'

const BANNER_STATUS_OPTIONS = [
  {
    value: 'Live',
    text: getMessage(`apphome.live`),
  },
  {
    value: true,
    text: getMessage(`apphome.enabled`),
  },
  {
    value: false,
    text: getMessage(`apphome.disabled`),
  },
]

const FilterBanner = ({ generateStateMappers }) => {
  return (
    <>
      <Input
        id="name"
        label={getMessage('apphome.filter.searchByName')}
        placeholder={getMessage('app.campaign.name.placeholder')}
        name="name"
        type="text"
        {...generateStateMappers({
          stateKeys: ['name'],
          loseEmphasisOnFill: true,
        })}
      />
      <Radio
        label={getMessage('appbanner.status')}
        options={BANNER_STATUS_OPTIONS}
        {...generateStateMappers({
          stateKeys: ['status'],
          loseEmphasisOnFill: true,
          defaultValue: 1,
        })}
      />
    </>
  )
}

export default FilterBanner
