import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SaleOrderDetails from './Details';
import SalesOrderTable from './Table';

class SaleOrdersRouter extends Component {
  render() {
    const { router } = this.props;
    const details = { ...this.props, url: '/customer-support' }
    if (router.match.params.id) {
      return <SaleOrderDetails {...details} />
    }
    return <SalesOrderTable {...details} />
  }
}

export default withRouter(({ match, ...props }) => (
  <SaleOrdersRouter router={{ match }} {...props} />
))
