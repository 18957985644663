import React, { Component } from 'react'
import {
  Checkbox,
  SingleDatePicker,
  TimePicker,
} from '../../../../components/Form'
import Upload from '../../../../components/FileUpload'
import { getMessage } from '../../../../lib/translator'
import csvIcon from './csv-icon.svg'
import uploadIcon from './import-customers.svg'
import infoIcon from './info-icon.svg'
import sampleTags from './sample-tags.csv'
import moment from 'moment'

import './style.css'

function downloadCsv() {
  var hiddenElement = document.createElement('a')
  hiddenElement.href = sampleTags
  hiddenElement.target = '_blank'
  hiddenElement.download = 'sample-tags.csv'
  hiddenElement.click()
}

export default class TagsImport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isScheduled: false,
    }
    this.getSampleCsv = this.getSampleCsv.bind(this)
    this.transformSubmit = this.transformSubmit.bind(this)
  }

  getSampleCsv(e) {
    e.preventDefault()
    downloadCsv()
  }
  transformSubmit(data) {
    // let data = Object.assign({}, fileData)
    if (
      this.state.isScheduled &&
      this.state.scheduleDate &&
      this.state.scheduleTime
    ) {
      let dateTime = moment().add(5, 'minutes')
      const selectedDateTime = moment(
        this.state.scheduleDate + ' ' + this.state.scheduleTime
      )
      dateTime = selectedDateTime > dateTime ? selectedDateTime : dateTime
      data.append('scheduledDateTime', dateTime.format('YYYY-MM-DD HH:mm'))
    }
    return data
  }

  render() {
    const { isBulkTagging } = this.props;
    return (
      <div className="product-upload">
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          <Upload
            name="tagsImport"
            accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
            placeholder={getMessage('productUpload.uploadFileText')}
            uploadButtonText="Upload"
            cancelText="Cancel"
            uploadKey="uploadfile"
            uploadUrl={this.props.uploadUrl}
            validationStrings={{
              invalidFileType: getMessage('input.invalidFileType'),
            }}
            transformSubmit={this.transformSubmit}
            strings={{
              defaultMessage: getMessage('fileUpload.importCsv.heading'),
              progressMessage: getMessage('fileUpload.importedCsv.heading'),
              completionMessage: getMessage('customer.tags.import.successText'),
            }}
            icon={csvIcon}
            isBulkTagging={isBulkTagging}
          />
          {!isBulkTagging && <div className="cx-import-tooltip">
            <img src={infoIcon} alt="note" />
            NOTE
            <div className="tooltiptext">
              <div className="tooltip-title">Note:</div>
              <div className="instruction">
                * The column 'action' takes the values 'A' or 'D', for
                add/delete{' '}
              </div>
            </div>
          </div>}
        </div>
        {!isBulkTagging &&
        <div className="text-center download-sample-text">
          <a className="download-link" onClick={this.getSampleCsv}>
            {getMessage('productUpload.csvDownloadText')}
          </a>
        </div>}
        {!isBulkTagging && <div className="text-center schedule-checkbox">
          <Checkbox
            className="entity-checkbox"
            inlineLabel={`Schedule later`}
            name={`isScheduled`}
            value={this.state.isScheduled}
            onChange={() => {
              this.setState({ isScheduled: !this.state.isScheduled })
            }}
          />
        </div>}
        {this.state.isScheduled && (
          <div className="dateTimeSelect">
            <SingleDatePicker
               enableToday
              name="scheduleDate"
              key="scheduleDate"
              label={getMessage('Select Date')}
              value={this.state.scheduleDate}
              onChange={v => this.setState({ scheduleDate: v })}
            />
            <TimePicker
              name="scheduleTime"
              key="scheduleTime"
              label={getMessage('Select Time')}
              placeholder={getMessage('offer.time.placeholder')}
              value={this.state.scheduleTime}
              onChange={v => this.setState({ scheduleTime: v })}
              showSecond={false}
              minuteStep={15}
            />
          </div>
        )}
      </div>
    )
  }
}
