import React from 'react'
import PropTypes from 'prop-types'
import { getMessage } from '../../lib/translator'
import './style.css'
import { Link } from 'react-router-dom'

const CustomerDetails = props => {
  const { id, name, mobile, email, address } = props
  return (
    <div className="customer-details">
      <b>{getMessage('qc.orderInfo.CustomerDetails')}</b>
      <div className="customer-name">
        <Link to={`/customer-support/customers/view/${id}`}>{name}</Link>
      </div>
      <div>{mobile}</div>
      <div>{email}</div>
      {address && (
        <div className="address-info">
          {`
          ${address.streetAddress ? address.streetAddress : ''},
          ${address.floorNumber ? address.floorNumber : ''} - ${
            address.unitNumber ? address.unitNumber : ''
          } 
          ${address.buildingName ? address.buildingName : ''}, ${
            address.city ? address.city : ''
          } ${address.postalCode ? address.postalCode : ''}
        `}
        </div>
      )}
    </div>
  )
}

CustomerDetails.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  mobile: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.object,
}

export default CustomerDetails
