import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import API from 'lib/api'
import { BrowseCategory, BrowseProduct } from '../components/BrowseTab'
import { CustomerDetailsTab } from '../components/CustomerDetailsTab'
import { PickupSettingsTab } from '../components/PickupSettingsTab'
import { ReviewOrderTab } from '../components/ReviewOrderTab'
import { NewOrderTabs } from '../constant'
import { BackButton, Button, ScanIcon, Text, View } from '../ui'
import { ScanProduct } from './ScanProduct'
import { FE_GATEWAY_API_URL } from 'config/app'
import {
  getSlimProductData,
  usePrevious,
  xUserHeaders,
  getCategoryNameAndSlug,
  getSlimCampaignData,
} from '../helper'

export const NewOrder = ({ onBackToHome }) => {
  const {
    values,
    setFieldValue,
    resetForm,
    setValues,
    setErrors,
    setFieldTouched,
  } = useFormikContext()

  const [activeIndex, setActiveIndex] = useState(NewOrderTabs.CUSTOMER_DETAIL)
  const [selectedCampaign, setSelectedCampaign] = useState(null) // on page load, do not select campaign
  const [selectedCategory, setSelectedCategory] = useState(null) // on page load, do not select category
  const [showScanner, setShowScanner] = useState(false)
  const [productsPerPage, setProductsPerPage] = useState([])
  const [productLoading, setProductLoading] = useState(false)
  const [productError, setProductError] = useState(false)
  const [campaignLoading, setCampaignLoading] = useState(false)
  const [campaignError, setCampaignError] = useState(false)
  const [campaigns, setCampaigns] = useState([])
  const [categoryLoading, setCategoryLoading] = useState(false)
  const [categoryError, setCategoryError] = useState(false)
  const [cartResponse, setCartResponse] = useState(null)

  const prevCampaign = usePrevious(selectedCampaign)

  const toggleProductScanner = () => {
    setShowScanner(true)
  }

  const closeProductScanner = () => {
    setShowScanner(false)
  }

  const onBack = () => {
    if (activeIndex === NewOrderTabs.BROWSE) {
      if (selectedCategory !== null) {
        // if user is on product listing page
        // going back means unset selected category
        // and view will go back to category listing page
        setSelectedCategory(null)
      } else {
        // if user is on category listing page
        // goint back means unset selected campaign
        setSelectedCampaign(null)
        clearCart()
        setActiveIndex((i) => i - 1)
      }
    } else if (activeIndex === NewOrderTabs.CUSTOMER_DETAIL) {
      setFieldTouched('customerName', false)
      setFieldTouched('customerPhone', false)
      onBackToHome()
    } else if (activeIndex === NewOrderTabs.REVIEW_ORDER) {
      setSelectedCategory(null)
      setActiveIndex((i) => i - 1)
    } else {
      setActiveIndex((i) => i - 1)
    }
  }
  const toReviewOrder = () => {
    setActiveIndex(NewOrderTabs.REVIEW_ORDER)
  }

  const onCampaignChange = async (campaignId) => {
    // clear the previous error registered to allow data to pass through UI
    // when the api call success...
    setCategoryError(false)
    setCategoryLoading(true)

    if (prevCampaign) {
      await clearCart({ preserveCustomerDetail: true })
    }

    setSelectedCampaign(campaignId)
    setFieldValue('selectedCampaign', campaignId)

    const selectedCampaignItem = campaigns.find(
      // cmp.id is number but campaignId is string
      (cmp) => cmp.id + '' === campaignId
    )

    // fetch campaign slug and save as form value
    // to be used for call fetch product API later
    new API({ url: `/catalogue-service/tag/${selectedCampaignItem.tag_id}` })
      .get()
      .then((res) => {
        const campaignSlug = res.data?.tag?.slug
        setFieldValue('selectedCampaignSlug', campaignSlug)
      })
      .catch((err) => {
        setCategoryError(
          err.message // default value: 'An error has occured'
        )
      })
      .finally()

    // fetch categories for the campaign
    new API({
      url: `/catalogue-service/category/${selectedCampaignItem.category_id}`,
    })
      .get()
      .then((res) => {
        const categoriesRaw = res.data?.category?.subCategories
        const categoryNameAndSlug = categoriesRaw.map((category) =>
          getCategoryNameAndSlug(category)
        )
        setFieldValue(
          'allCategories',
          categoryNameAndSlug.sort((a, b) => a.id - b.id)
        )
      })
      .catch((err) => {
        setCategoryError(
          err.message // default value: 'An error has occured'
        )
      })
      .finally(() => {
        setCategoryLoading(false)
      })
  }

  const fetchProducts = ({ categoryId, page = 1 }) => {
    // reset local state
    setProductLoading(true)
    setProductsPerPage([])

    // prepare search params for GET /product/v2
    const slug = values.allCategories.find((c) => c.id === categoryId)?.slug
    const searchParamsObj = {
      experiments: 'algolia-b,algolia-tag,algolia-cat,algolia-product', // no change
      orderType: 'RB_PREORDER', // no change
      pageType: 'tag', // no change
      page, // pagination
      url: values.selectedCampaignSlug, // no change
      storeId: values.storeId,
      categorySlug: slug,
    }
    const searchParams = new URLSearchParams(searchParamsObj).toString()

    new API({ url: `${FE_GATEWAY_API_URL}/product/v2?${searchParams}` })
      .get()
      .then((res) => {
        const products = res.data?.product || []
        const slimProducts = products.map((product) =>
          getSlimProductData(product)
        )
        // pagination
        const pagination = res.data?.pagination || {}
        setFieldValue('totalPages', pagination.total_pages)
        setFieldValue('currentPage', pagination.page)
        setFieldValue('pageSize', pagination.page_size)
        // products
        setProductsPerPage(slimProducts)
        setFieldValue('allAvailableProducts', [
          ...(values.allAvailableProducts ?? []),
          ...slimProducts,
        ])
      })
      .catch((err) => {
        setProductError(err.message)
      })
      .finally(() => {
        setProductLoading(false)
      })
  }

  React.useEffect(() => {
    // fetch campaigns
    const searchParams = {
      status: 'Active',
      storeId: values.storeId,
      limit: 10000, // do not paginate as the UI is a dropdown
    }

    setCampaignLoading(true)
    setCampaignError(false)
    new API({ url: '/campaigns' })
      .get(searchParams)
      .then((res) => {
        const campaignDataRaw = res.data?.campaigns || []
        const slimCampaignData = campaignDataRaw.map((cmp) =>
          getSlimCampaignData(cmp)
        )
        setCampaigns(slimCampaignData)
      })
      .catch((err) => {
        setCampaignError(
          err.message // default value: 'An error has occured'
        )
      })
      .finally(() => {
        setCampaignLoading(false)
      })
  }, [])

  const clearCart = async (options) => {
    const getCartBy = {
      storeId: values.storeId,
      orderType: 'RB_PREORDER',
      campaignId: Number(prevCampaign), // must be number, not string
      isCheckerEnabled: true,
    }

    try {
      const getCartRes = await new API({
        url: `${FE_GATEWAY_API_URL}/cart`,
      }).get(
        getCartBy,
        xUserHeaders({
          userId: values.userData.userId,
          userUid: values.userData.userUid,
        })
      )
      // only clear cart if user has a cart
      const cartToBeCleared = getCartRes.data?.cart?.items
      if (Array.isArray(cartToBeCleared) && cartToBeCleared.length > 0) {
        // only clear chargable items
        const chargableItems = cartToBeCleared.filter(
          (item) => item.isFree === false
        )
        await new API({ url: `${FE_GATEWAY_API_URL}/checkout` }).post(
          {
            campaignId: Number(prevCampaign), // must be number, not string
            cart: {
              isCartMerge: false,
              items: chargableItems.map((item) => ({
                id: item.id,
                isChecked: item.isChecked,
                q: '0',
                t: Number(new Date()),
              })),
            },
            couponCodes: [],
            isCheckerEnabled: true,
            isDirectFulfilmentEnabled: true,
            isSellerCheckerEnabled: true,
            isSellerTimeSlotEnabled: true,
            orderType: 'RB_PREORDER',
            storeId: values.storeId,
          },
          xUserHeaders({
            userId: values.userData.userId,
            userUid: values.userData.userUid,
          })
        )
      }
    } catch (e) {
      // fail silently
    }

    // values to keep
    const storeId = values.storeId
    const staffId = values.staffId
    const cart = {}
    const products = {}
    const userData = values.userData ?? {}
    const customerName = values.customerName
    const customerPhone = values.customerPhone

    /**
     * resetForm will remove all keys in the formik value, which will result
     * in breaking problem due to undefined or null.
     * Only storeId, staffId, and userData should be persisted after the clearCart()..
     * cart and products should be reassigned as {} instead of undefined
     */
    resetForm()

    setValues({
      storeId,
      staffId,
      cart,
      products,
      userData,
      // Only preserve the customer detail when `preserveCustomerDetail` === `TRUE`,
      // This is used for only specific flow. When switching campaign (ONLY), do not clear the customer INFO
      // during clearCart or else the order creation won't include customer info
      ...(options && options.preserveCustomerDetail && { customerName }),
      ...(options && options.preserveCustomerDetail && { customerPhone }),
    })

    /** ensure all existing errors is removed and cleaned */
    setErrors({})

    if (activeIndex === NewOrderTabs.PICKUP) {
      // This means user has completed placing order
      // so we need to go back to home page
      // we can't do it anywhere else
      // because it will unmount this component
      // and cart won't be cleared
      onBackToHome()
    }
  }

  const onInputProductQuantity = (sku) => (event) => {
    const quantity = parseInt(event.target.value)

    // update quantity
    setFieldValue(`products.${sku}`, !quantity || quantity < 0 ? 0 : quantity)
    const newCart = {
      ...Object.keys(values.products).reduce((total, value) => {
        // only check an item if it was not previously added to cart
        // if not, we preserve the checked/unchecked status
        if (values.cart[value] === undefined) {
          total[value] = true
        }
        return total
      }, {}),
    }
    setFieldValue('cart', {
      ...values.cart,
      ...newCart,
    })
  }

  const allowScanner = Boolean(
    (activeIndex === NewOrderTabs.BROWSE ||
      activeIndex === NewOrderTabs.REVIEW_ORDER) &&
      selectedCampaign &&
      campaigns.find((camp) => camp.id === Number(selectedCampaign))
        .hasSkuScanner
  )
  return (
    <>
      {!showScanner ? (
        <View direction="column">
          <View
            margin="0 0 16px"
            justifyContent="space-between"
            style={{ minHeight: 40 }}
          >
            <BackButton width={62} onClick={onBack} />
            {allowScanner && (
              <Button
                width={145}
                variant="blue"
                icon={<ScanIcon />}
                onClick={toggleProductScanner}
                fontSize="14px"
              >
                <Text width={80} color="#1454B8">
                  Scan items
                </Text>
              </Button>
            )}
          </View>

          <View display="block">
            {activeIndex === NewOrderTabs.CUSTOMER_DETAIL && (
              <CustomerDetailsTab setActiveIndex={setActiveIndex} />
            )}
            {activeIndex === NewOrderTabs.BROWSE &&
              (selectedCategory === null ? (
                /* listing categories per campaign */
                <BrowseCategory
                  toReviewOrder={toReviewOrder}
                  selectedCampaign={selectedCampaign}
                  selectedCategory={selectedCategory}
                  onCampaignChange={onCampaignChange}
                  setSelectedCategory={setSelectedCategory}
                  fetchProducts={fetchProducts}
                  campaignLoading={campaignLoading}
                  campaignError={campaignError}
                  campaigns={campaigns}
                  categoryError={categoryError}
                  categoryLoading={categoryLoading}
                  categories={values.allCategories}
                />
              ) : (
                /* listing products per page */
                <BrowseProduct
                  toReviewOrder={toReviewOrder}
                  selectedCampaign={selectedCampaign}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  productError={productError}
                  productsPerPage={productsPerPage}
                  productLoading={productLoading}
                  fetchProducts={fetchProducts}
                  onInputProductQuantity={onInputProductQuantity}
                />
              ))}
            {activeIndex === NewOrderTabs.REVIEW_ORDER && (
              <ReviewOrderTab
                goBack={onBack}
                setActiveIndex={setActiveIndex}
                setCartResponse={setCartResponse}
                cart={cartResponse?.cart}
                selectedCampaign={selectedCampaign}
                onInputProductQuantity={onInputProductQuantity}
              />
            )}
            {activeIndex === NewOrderTabs.PICKUP && (
              <PickupSettingsTab
                cart={cartResponse?.cart?.items}
                slot={cartResponse?.slot}
                onDone={() => {
                  clearCart()
                }}
                setSelectedCampaign={setSelectedCampaign}
              />
            )}
          </View>
        </View>
      ) : (
        <ScanProduct
          toHome={closeProductScanner}
          toReviewOrder={toReviewOrder}
        />
      )}
    </>
  )
}
