import React from 'react'

import { getMessage } from '../../../../../../lib/translator'
import { tableProperties, EMPTY_STATE } from '../rewardsUtils'
import ListingPage from '../../../../../../containers/ListingPage'
import '../style.css'

const REWARDS_CATALOGUE_PAGINATION_SIZE = 5

const RewardsCatalogue = ({ uid }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>{getMessage('customer.details.rewards.rewardsCatalogue')}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="tab-list">
            <ListingPage
              isRewardsTab={true}
              testid="rewards-catalogue-listing"
              className="rewards-page rewards-tab-catalogue"
              api={{
                url: `/rms/v2/user-rewards`,
                params: {
                  status: 'active',
                  uid,
                  limit: REWARDS_CATALOGUE_PAGINATION_SIZE,
                  offset: 0,
                  wantFullData: true,
                },
                transform: (response) => {
                  if (response) {
                    return response.data.list
                  }
                  return {}
                },
              }}
              form={{}}
              emptyState={EMPTY_STATE}
              tableProperties={tableProperties('RewardsCatalogue')}
              tableDynamic
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RewardsCatalogue
