import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import ListingPage from '../../../containers/ListingPage/listingRouter'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import SubstitutionGroupForm from './Form'
import SubstitutionGroupFilters from './Filters'
import { Dialog } from '../../../components/Popup'
import Toggle from '../../../components/Form/Inputs/Toggle'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'

import emptyIcon from './empty.svg'

import './style.css'

const emptyState = {
  icon: emptyIcon,
  message: getMessage('substitutionGroup.helpItems.message'),
  actions: () => (
    <Link className="primary button" to="/catalogue/substitution-groups/add">
      + {getMessage('substitutionGroup.add.text')}
    </Link>
  ),
}

class TableActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statusToggleConfirmation: false,
    }
    this.toggleStatus = this.toggleStatus.bind(this)
  }
  toggleStatus() {
    const { id, status: statusProp } = this.props
    const status = statusProp === 'ENABLED' ? 'DISABLED' : 'ENABLED'
    const api = new API({ url: `/catalogue-service/substitution-group/${id}` })
    return api
      .put({ id, status })
      .then((response) => {
        this.props.onAction(
          TABLE_ACTIONS.UPDATE,
          { id },
          response.data.substitutionGroup
        )
      })
      .then(() => {
        this.setState({ statusToggleConfirmation: false })
      })
  }
  render() {
    const { id, status, onAction } = this.props
    const toggledAction = status === 'ENABLED' ? 'disable' : 'enable'
    return (
      <div>
        <Dialog
          show={this.state.statusToggleConfirmation}
          title={getMessage(`substitutionGroup.dialogs.${toggledAction}.title`)}
          information={getMessage(
            `substitutionGroup.dialogs.${toggledAction}.message`
          )}
          onOk={this.toggleStatus}
          okText={getMessage(`substitutionGroup.dialogs.confirmText`)}
          close={() => {
            this.setState({ statusToggleConfirmation: false })
          }}
          closeText={getMessage(`substitutionGroup.dialogs.cancelText`)}
        />
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/catalogue/substitution-groups/edit/${id}`}>
              {getMessage('substitutionGroup.action.edit')}
            </Link>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              this.setState({ statusToggleConfirmation: true })
            }}
          >
            {getMessage(`substitutionGroup.action.${toggledAction}`)}
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              onAction(TABLE_ACTIONS.DELETE, { id })
            }}
          >
            {getMessage('substitutionGroup.action.delete')}
          </DropDownItem>
        </DropDown>
      </div>
    )
  }
}

const tableProperties = (_isMobileView) => {
  return {
    headers: [
      getMessage('substitutionGroup.header.name'),
      getMessage('substitutionGroup.header.productCount'),
      getMessage('substitutionGroup.header.status'),
      getMessage('substitutionGroup.header.actions'),
    ],
    row: ({ id, name, productsCount, status, onAction }) => (
      <Row>
        <Cell>
          <Link
            className="group-name"
            to={`/catalogue/substitution-groups/edit/${id}`}
          >
            {name}
          </Link>
        </Cell>
        <Cell className="product-count">
          <span className="text-muted">
            <span className="substitution-header-mobileview">{`${getMessage(
              'brand.header.productCount'
            )}: `}</span>
            <small className="substitution-text-muted">{`${productsCount}`}</small>
          </span>
        </Cell>
        <Cell className="substitutionGroup-status">
          <small className="text-muted not-mobile">
            {['ENABLED', 'DISABLED'].indexOf(status) > -1
              ? getMessage(`brand.status.${status.toLowerCase()}`)
              : status}
          </small>
          <Toggle
            className={`${status} only-mobile`}
            name={status}
            value={status === 'ENABLED'}
            icons={false}
            onChange={() => {
              onAction(
                TABLE_ACTIONS.UPDATE,
                { id },
                { status: status === 'ENABLED' ? 'DISABLED' : 'ENABLED' }
              )
            }}
          />
        </Cell>
        <Cell className="substitutionGroup-actions">
          <TableActions id={id} status={status} onAction={onAction} />
        </Cell>
      </Row>
    ),
  }
}

export default class SubstitutionGroups extends Component {
  render() {
    const props = this.props
    return (
      <ListingPage
        menu={props.menu}
        className="substitution-groups-page"
        title={getMessage('substitutionGroup.heading')}
        api={{
          url: '/catalogue-service/substitution-group',
          transform: (response) => response.data.substitutionGroup,
        }}
        showLanguageDropDown
        emptyState={emptyState}
        tableProperties={tableProperties(this.state && this.state.isMobileView)}
        headerActions={() => (
          <button className="button primary">
            <Link to="/catalogue/substitution-groups/add" className="primary">
              +{' '}
              <span className="text">
                {getMessage('substitutionGroup.add.text')}
              </span>
            </Link>
          </button>
        )}
        form={{
          component: SubstitutionGroupForm,
          transformResponse: (response) => response.data.substitutionGroup,
          transformSubmit: ({ products, substitutedProduct, ...formData }) => {
            const request = Object.assign({}, formData, {
              id: substitutedProduct && substitutedProduct.id,
              productIds: products && products.map(({ id }) => id),
            })
            return request
          },
          allowDelete: true,
        }}
        filters={{
          component: SubstitutionGroupFilters,
        }}
      />
    )
  }
}
