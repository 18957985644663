import React from 'react'
import { FormikScanPage } from '../components/FormikInput'
import { SearchOrder } from '../components/SearchOrder'
import { useField } from 'formik'
import { extractOrderIDFromQR } from '../helper'

export const ScanOrder = ({ toHome, toViewOrder, toOrderSummary }) => {
  const [, , form] = useField('scanOrderId')

  const onNewScanResult = async (decodedText) => {
    const orderId = await extractOrderIDFromQR(decodedText)
    form.setValue(orderId)
    toViewOrder()
  }

  return (
    <FormikScanPage
      onBack={toHome}
      title="Scan QR code"
      footer="Enter order ID or mobile number"
      name="scanOrderId"
      onNewScanResult={onNewScanResult}
    >
      <SearchOrder toOrderSummary={toOrderSummary} toViewOrder={toViewOrder} />
    </FormikScanPage>
  )
}
