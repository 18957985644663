import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#2b3238'
const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      backgroundColor: '#8B9FA6',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
      fontSize: 10,
    },
    no: {
      width: '5%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    sku: {
      width: '10%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    productDescription: {
      width: '70%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    qty: {
      width: '5%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    uom: {
      width: '10%',
    }
  });

  const OrderItemsTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.no}>No</Text>
        <Text style={styles.sku}>SKU</Text>
        <Text style={styles.productDescription}>Product Description</Text>
        <Text style={styles.qty}>Qty</Text>
        <Text style={styles.uom}>UOM</Text>
    </View>
  );

  export default OrderItemsTableHeader
