import React from 'react'
import sampleGift from './sample-gift.svg'

export const RightArrowIcon = ({ rotate = 0, fill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    style={{ transform: `rotate(${rotate}deg)` }}
    {...rest}
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill={fill} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
)

export const DownArrowIcon = (props) => (
  <RightArrowIcon rotate={90} {...props} />
)
export const LeftArrowIcon = (props) => (
  <RightArrowIcon rotate={180} {...props} />
)

export const ScanIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1411_34490)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.12676 15.6479C2.74905 15.6479 3.25352 16.1524 3.25352 16.7747V20.1549C3.25352 20.7772 3.75799 21.2817 4.38028 21.2817H7.76056C8.38286 21.2817 8.88732 21.7862 8.88732 22.4085C8.88732 23.0308 8.38286 23.5352 7.76056 23.5352H4.38028C2.5134 23.5352 1 22.0218 1 20.1549V16.7747C1 16.1524 1.50447 15.6479 2.12676 15.6479ZM22.4085 15.6479C22.9863 15.6479 23.4625 16.0829 23.5276 16.6433L23.5352 16.7747V20.1549C23.5352 21.9551 22.128 23.4267 20.3535 23.5295L20.1549 23.5352H16.7746C16.1524 23.5352 15.6479 23.0308 15.6479 22.4085C15.6479 21.8306 16.0829 21.3544 16.6432 21.2893L16.7746 21.2817H20.1549C20.7328 21.2817 21.209 20.8467 21.2741 20.2863L21.2817 20.1549V16.7747C21.2817 16.1524 21.7862 15.6479 22.4085 15.6479ZM7.76056 1C8.38286 1 8.88732 1.50447 8.88732 2.12676C8.88732 2.70461 8.45235 3.18086 7.89197 3.24594L7.76056 3.25352H4.38028C3.80244 3.25352 3.32619 3.6885 3.2611 4.24888L3.25352 4.38028V7.76057C3.25352 8.38285 2.74905 8.88733 2.12676 8.88733C1.54892 8.88733 1.07267 8.45235 1.00758 7.89197L1 7.76057V4.38028C1 2.58008 2.40723 1.10855 4.18166 1.00574L4.38028 1H7.76056ZM20.1549 1C22.0218 1 23.5352 2.51341 23.5352 4.38028V7.76057C23.5352 8.38285 23.0307 8.88733 22.4085 8.88733C21.7862 8.88733 21.2817 8.38285 21.2817 7.76057V4.38028C21.2817 3.758 20.7772 3.25352 20.1549 3.25352H16.7746C16.1524 3.25352 15.6479 2.74905 15.6479 2.12676C15.6479 1.50447 16.1524 1 16.7746 1H20.1549Z"
        fill="#1454B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.12676 13.3941H22.4085C23.0307 13.3941 23.5352 12.8897 23.5352 12.2674C23.5352 11.6451 23.0307 11.1406 22.4085 11.1406H2.12676C1.50447 11.1406 1 11.6451 1 12.2674C1 12.8897 1.50447 13.3941 2.12676 13.3941Z"
        fill="#F43E6D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1411_34490">
        <rect
          width="22.5352"
          height="22.5352"
          fill="white"
          transform="translate(1 1)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const KeyboardIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="5"
      width="18"
      height="14"
      rx="1"
      stroke="white"
      strokeWidth="2"
      fill="black"
    />
    <circle cx="8" cy="9" r="1" fill="white" />
    <circle cx="8" cy="12" r="1" fill="white" />
    <circle cx="12" cy="9" r="1" fill="white" />
    <circle cx="12" cy="12" r="1" fill="white" />
    <circle cx="16" cy="9" r="1" fill="white" />
    <circle cx="16" cy="12" r="1" fill="white" />
    <rect x="7" y="14" width="10" height="2" rx="1" fill="white" />
  </svg>
)

export const AddIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#1454B8"
      />
      <path
        d="M15 23C15 23.5523 15.4477 24 16 24C16.5523 24 17 23.5523 17 23V17L23 17C23.5523 17 24 16.5523 24 16C24 15.4477 23.5523 15 23 15L17 15V9C17 8.44772 16.5523 8 16 8C15.4477 8 15 8.44772 15 9V15L9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17L15 17V23Z"
        fill="white"
      />
    </svg>
  )
}

export const MinusIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#1454B8"
      />
      <path
        d="M8 16C8 15.4477 8.44772 15 9 15H23C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17H9C8.44772 17 8 16.5523 8 16Z"
        fill="white"
      />
    </svg>
  )
}

export const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79541 5.30799C6.38477 4.89734 5.71898 4.89734 5.30834 5.30799C4.89769 5.71864 4.89769 6.38445 5.30834 6.7951L10.5129 11.9998L5.30798 17.2049C4.89734 17.6156 4.89734 18.2814 5.30798 18.692C5.71863 19.1027 6.38441 19.1027 6.79506 18.692L12 13.4869L17.2049 18.692C17.6156 19.1026 18.2814 19.1026 18.692 18.692C19.1027 18.2813 19.1027 17.6155 18.692 17.2049L13.4871 11.9998L18.6917 6.79512C19.1023 6.38446 19.1023 5.71866 18.6917 5.30801C18.281 4.89735 17.6152 4.89735 17.2046 5.30801L12 10.5127L6.79541 5.30799Z"
        fill="#1454B8"
      />
    </svg>
  )
}

export const GiftIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0409 8.24307L5.55022 8.27373C4.83726 8.38394 4.23647 8.77269 3.84147 9.31213C3.44647 9.85157 3.25725 10.5417 3.36746 11.2547L4.57998 19.0991C4.67842 19.7359 5.00169 20.2893 5.46144 20.6834C5.9212 21.0776 6.51745 21.3125 7.16182 21.3125H16.8081C17.4512 21.3125 18.0465 21.0785 18.506 20.6856C18.9654 20.2927 19.2891 19.7409 19.3889 19.1056L20.6534 10.8556C20.6534 10.1342 20.361 9.48103 19.8882 9.00826C19.4154 8.53549 18.7623 8.24307 18.0409 8.24307Z"
        fill="#FFB60F"
        stroke="#EA6100"
        strokeWidth="1.375"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9 7.13196L1.6875 7.54446L1.85815 10.2014L22.3125 10.0222L21.9 7.13196Z"
        fill="#EA6100"
        stroke="#EA6100"
        strokeWidth="1.375"
      />
      <path
        d="M9.8001 6.44444C11.0151 6.44444 12.0001 5.44952 12.0001 4.22222C12.0001 2.99492 11.0151 2 9.8001 2C8.58507 2 7.6001 2.99492 7.6001 4.22222C7.6001 5.44952 8.58507 6.44444 9.8001 6.44444Z"
        stroke="#EA6100"
        strokeWidth="1.1"
      />
      <path
        d="M14.2 6.44444C15.415 6.44444 16.4 5.44952 16.4 4.22222C16.4 2.99492 15.415 2 14.2 2C12.985 2 12 2.99492 12 4.22222C12 5.44952 12.985 6.44444 14.2 6.44444Z"
        stroke="#EA6100"
        strokeWidth="1.1"
      />
      <path
        d="M4.07227 14.7778H19.948"
        stroke="#EA6100"
        strokeWidth="1.1"
        strokeLinecap="square"
      />
      <path
        d="M12 10.8889L11.9878 20.3333"
        stroke="#EA6100"
        strokeWidth="1.1"
        strokeLinecap="square"
      />
    </svg>
  )
}

export const SampleGift = () => {
  return <img src={sampleGift} />
}

export const LockIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM9 6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9V6ZM18 20H6V10H18V20ZM12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17Z"
        fill="#999999"
      />
    </svg>
  )
}
