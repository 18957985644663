import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { PAY_URL } from '../../../../../config/app'
import { Row, Cell } from '../../../../../components/Table'
import { getMessage } from '../../../../../lib/translator'
import { Popup } from '../../../../../components/Popup'
import GoodwillPopup from './Challenges/GoodwillPopup'
import RefundPopup from './RewardsCatalogue/RefundPopup'
import ListingPageWithRoutes from '../../../../../containers/ListingPage/listingRouter'
import { getSession } from '../../../../../lib/auth'
import moment from 'moment'

const DATE_TIME_FORMAT = 'D MMM YYYY, HH:mm A'
const DATE_TIME_FORMAT2 = 'D MMM YYYY, HH:mm'
const CHANNEL_STATUS = ['FPON', 'SCANGO']
const REWARD_TYPE = {
  LINKPOINT: 'Linkpoints',
}

export const EMPTY_STATE = {
  message: getMessage('customer.details.luckyDraws.emptState'),
}

const EMPTY_ORDER_RESPONSE = {
  message: getMessage('customer.details.challenge.noOrders'),
}

const PERMITTED_USER = [
  'Marketing / Lucky Draw (Read-only)',
  'Marketing / Lucky Draw',
  'Rewards / Challenges (Read-only)',
  'Rewards / Challenges',
]

export const isRewardsUser = () => {
  const { user } = getSession()
  if (user.designation === null) {
    return true
  }
  if (user.designation?.roles) {
    for (const role of user.designation.roles) {
      if (PERMITTED_USER.includes(role.name)) {
        return true
      }
    }
  }
  return false
}

export const formatOrderRef = (orderRef, channel) => {
  let formattedOrderRefLink = orderRef
  if (CHANNEL_STATUS.includes(channel) || channel.includes('LINKPAY')) {
    const orderRefUrl = CHANNEL_STATUS.includes(channel)
      ? `/customer-support/orders/`
      : `${PAY_URL}/invoices/admin/index.html?id=`
    formattedOrderRefLink = (
      <a href={`${orderRefUrl}${orderRef}`} rel="noreferrer" target="_blank">
        {orderRef}
      </a>
    )
  }
  return formattedOrderRefLink
}

export const tableRowChancesEarned = ({
  orderRef,
  channel,
  chances,
  createdAt,
}) => {
  const formattedOrderRef = formatOrderRef(orderRef, channel)

  return (
    <Row>
      <Cell className="rewards-page-actions order">{formattedOrderRef}</Cell>
      <Cell className="rewards-page-actions channel">{channel}</Cell>
      <Cell className="rewards-page-actions chances">{chances}</Cell>
      <Cell className="rewards-page-actions date">
        {moment(createdAt).format(DATE_TIME_FORMAT)}
      </Cell>
    </Row>
  )
}
export const tableRowPrizesWon = ({
  orderRef,
  chancesEarned,
  channel,
  drawAt,
  status,
  reason,
}) => {
  const formattedOrderRef = formatOrderRef(orderRef, channel)

  return (
    <Row>
      <Cell className="rewards-page-actions order">{formattedOrderRef}</Cell>
      <Cell className="rewards-page-actions ordertype">{channel}</Cell>
      <Cell className="rewards-page-actions chances">{chancesEarned}</Cell>
      <Cell className="rewards-page-actions date">
        {moment(drawAt).format(DATE_TIME_FORMAT)}
      </Cell>
      <Cell className={`rewards-page-actions status ${status.toLowerCase()}`}>
        {status}
        <br />
        {reason}
      </Cell>
    </Row>
  )
}

export const TableChallengeProgress = (
  task,
  challengeDetails,
  handleChallengeSelect
) => {
  const [seePopUp, setSeePopUp] = useState(false)
  const [taskDetail, setTaskDetail] = useState()
  const { index, id, type, award, title } = task
  const [showGoodwillPopUp, setGoodwillPopUp] = useState(false)

  const togglePopUp = (toggle, taskData) => {
    setSeePopUp(toggle)
    toggle ? setTaskDetail(taskData) : setTaskDetail(null)
  }

  const handleGoodwillPopup = () => {
    setGoodwillPopUp(false)
  }

  const getProgress = (taskType) => {
    let progress, current, target
    switch (taskType) {
      case 'CUMULATIVE_SPEND':
        if (task.userTask && task.userTask.progress?.cumulativeSpend) {
          target = task.rule.cumulativeSpend.cumulativeAmount
          current = task.userTask?.completedAt
            ? target
            : task.userTask.progress.cumulativeSpend.spentAmount
          progress = `$${current} / $${target}`
        } else {
          progress = '-'
        }
        break
      case 'MULTIPLE_ORDER':
        if (task.userTask && task.userTask.progress?.multipleOrder) {
          target = task.rule.multipleOrder.orderCount
          current = task.userTask?.completedAt
            ? target
            : task.userTask.progress.multipleOrder.orderCount
          progress = `${current} / ${target}`
        } else {
          progress = '-'
        }
        break
      default:
        progress = '-'
        break
    }
    return progress
  }

  return (
    <Fragment>
      {taskDetail?.userTask && (
        <Popup
          className="order-popup"
          show={seePopUp}
          heading="Task details"
          close={() => togglePopUp(false)}
        >
          <div className="row form-btns">
            <div className="col-12">
              <button
                type="button"
                className="primary apply-goodwill"
                onClick={() => setGoodwillPopUp(true)}
              >
                {getMessage('rewards.buttonText.applygoodwill')}
              </button>
            </div>
          </div>

          <ListingPageWithRoutes
            tableProperties={tableProperties('OrderDetails')}
            isRewardsTab={true}
            menu=""
            form={{}}
            api={{
              url: `/chendol/user-tasks/${taskDetail.userTask.id}/user-orders`,
              transform: (response) => {
                if (response.data.list.length > 0) {
                  return response.data.list
                }
                return null
              },
            }}
            emptyState={EMPTY_ORDER_RESPONSE}
            primaryKey="OrderRef"
          />
        </Popup>
      )}
      <Row>
        <Cell className="rewards-page-actions taskIndex">{index + 1}</Cell>
        <Cell className="rewards-page-actions taskTitle">{title}</Cell>
        <Cell className="rewards-page-actions taskProgress">
          {getProgress(type)}
        </Cell>
        <Cell className="rewards-page-actions taskCompletedAt">
          {task.userTask?.completedAt
            ? moment(task.userTask.completedAt).format(DATE_TIME_FORMAT2)
            : '-'}
        </Cell>
        <Cell className="rewards-page-actions taskAward">
          {task.userTask?.awardedAt && award.data.type !== ''
            ? `${award.data.amount} ${REWARD_TYPE[award.type]}`
            : '-'}
        </Cell>
        <Cell>
          <button
            type="button"
            className="view-details-button"
            data-testid={`view-details-button-${id}`}
            onClick={() => togglePopUp(true, task)}
            disabled={!task.userTask || task.type === 'COMPLETE_CHALLENGE'}
          >
            {getMessage('customer.details.challenge.orderDetails')}
          </button>
        </Cell>
      </Row>
      {showGoodwillPopUp && (
        <GoodwillPopup
          challengeDetails={challengeDetails}
          handleGoodwillPopup={handleGoodwillPopup}
          togglePopUp={togglePopUp}
          uid={taskDetail?.apiParams.uid}
          taskId={taskDetail?.userTask.id}
          handleChallengeSelect={handleChallengeSelect}
          type={task?.type}
        />
      )}
    </Fragment>
  )
}

export const tableOrderDetails = (order) => {
  const { OrderRef, Amount, Channel, OrderCompletedAt } = order
  return (
    <Row>
      <Cell>{formatOrderRef(OrderRef, Channel)}</Cell>
      <Cell>{Amount}</Cell>
      <Cell>{Channel}</Cell>
      <Cell>{moment(OrderCompletedAt).format(DATE_TIME_FORMAT2)}</Cell>
    </Row>
  )
}

export const TableRewardsCatalogue = (response) => {
  const {
    apiParams,
    storeId,
    code,
    expiredAt,
    usedAt,
    createdAt,
    reward,
    id,
    status,
  } = response
  const { id: rewardId, title, type } = reward
  const [refundPopUp, setRefundPopUp] = useState(false)
  const handleRefundPopup = () => {
    setRefundPopUp(false)
  }
  return (
    <>
      <Row>
        <Cell>
          <Link to={`/rewards/catalogue/edit/${rewardId}`}>{rewardId}</Link>
        </Cell>
        <Cell className="catalogue-name">{reward.name}</Cell>
        <Cell className="reward-code">{code}</Cell>
        <Cell>{moment(createdAt).format(DATE_TIME_FORMAT2)}</Cell>
        <Cell>{moment(expiredAt).format(DATE_TIME_FORMAT2)}</Cell>
        <Cell>{usedAt ? moment(usedAt).format(DATE_TIME_FORMAT2) : '-'}</Cell>
        <Cell>
          <Link to={`/rewards/stores/edit/${storeId}`}>{storeId}</Link>
        </Cell>
        <Cell>{status === 0 ? 'Refunded' : ''}</Cell>
        <Cell>
          <button
            data-testid={`refund${id}`}
            className="primary button"
            onClick={() => setRefundPopUp(true)}
            disabled={status === 0}
          >
            {getMessage('customer.details.rewards.refund')}
          </button>
        </Cell>
      </Row>
      {refundPopUp && (
        <RefundPopup
          rewardDetails={`${rewardId} - ${title} - ${code}`}
          handleRefundPopup={handleRefundPopup}
          uid={apiParams.uid}
          userRewardID={id}
          type={type}
        />
      )}
    </>
  )
}

export const tableProperties = (
  page,
  challengeDetails = '',
  handleChallengeSelect = ''
) => {
  switch (page) {
    case 'ChancesEarned': {
      return {
        headers: ['Reference no.', 'Channel', 'Chances', 'Date'],
        row: (response) => tableRowChancesEarned(response),
      }
    }
    case 'PrizesWon': {
      return {
        headers: [
          'Reference no.',
          'Order Type',
          'Chances',
          'Drawn date & time',
          'Details',
        ],
        row: (response) => tableRowPrizesWon(response),
      }
    }
    case 'ChallengeProgress': {
      return {
        headers: [
          'No.',
          'Task Title',
          'Overall Progress',
          'Completed At',
          'Awarded',
          'Actions',
        ],
        row: (response) =>
          TableChallengeProgress(
            response,
            challengeDetails,
            handleChallengeSelect
          ),
      }
    }
    case 'OrderDetails': {
      return {
        headers: ['Order ref', 'Order amount', 'Channel', 'Order completed at'],
        row: (response) => tableOrderDetails(response),
      }
    }
    case 'RewardsCatalogue': {
      return {
        headers: [
          'Reward ID',
          'Reward Name',
          'Code',
          'Redeemed on',
          'Expires on',
          'Used on',
          'Used at(store)',
          'Refund Status',
          'Actions',
        ],
        row: (response) => TableRewardsCatalogue(response),
      }
    }
    default:
      return null
  }
}
