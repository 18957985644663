import React from 'react'
import {
  Select,
  VALIDATION_TYPES,
  SingleDatePicker,
  BaseForm,
  Input,
} from '../../../../../components/Form'

import { getMessage } from '../../../../../lib/translator'
import './style.css'

const getTimes = () => {
  const times = []
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      times.push(`0${i}:00`)
      times.push(`0${i}:30`)
    } else {
      times.push(`${i}:00`)
      times.push(`${i}:30`)
    }
  }
  return times
}

export default class DateTimeValidityFilter extends BaseForm {
  render() {
    return (
      <div className="banner-filter-main">
        <div className="banner-date-time-section">
          <div className="date-time-filter">
            <SingleDatePicker
              label={getMessage('banners.editForm.validity.from')}
              placeholder={getMessage('banners.startTime.placeholder')}
              displayFormat="YYYY-MM-DD"
              name="startTime"
              className="date-section"
              allowAllDates
              openDirection={'down'}
              {...this.props.mapper({
                stateKeys: ['startTime'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Select
              label={getMessage('campaign.startTime')}
              name="bannerStartTime"
              type="text"
              className="time-section"
              placeholder={'05:00'}
              options={getTimes()}
              {...this.props.mapper({
                stateKeys: ['bannerStartTime'],
                defaultValue: '05:00',
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="date-time-filter">
            <SingleDatePicker
              label={getMessage('banners.editForm.validity.to')}
              placeholder={getMessage('banners.endTime.placeholder')}
              displayFormat="YYYY-MM-DD"
              openDirection={'down'}
              allowAllDates
              name="endTime"
              className="date-section"
              {...this.props.mapper({
                stateKeys: ['endTime'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Select
              label={getMessage('campaign.endTime')}
              name="bannerEndTime"
              type="text"
              className="time-section"
              placeholder={'00:00'}
              options={getTimes()}
              {...this.props.mapper({
                stateKeys: ['bannerEndTime'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        </div>
        <div>
          <Input
            label={getMessage('banners.form.search.name.label')}
            placeholder={getMessage('banners.form.search.name.placeholder')}
            name="bannerName"
            type="text"
            {...this.props.mapper({
              stateKeys: ['bannerName'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
      </div>
    )
  }
}
