import loginStrings from './login.js'
import resetPasswordStrings from './resetPassword.js'
import changePasswordStrings from './changePassword.js'
import menuStrings from './menu.js'
import signupStrings from './signup.js'
import settingsStrings from './settings.js'
import validationStrings from './validations.js'
import error404Strings from './404.js'
import catalogueStrings from './catalogue.js'
import operationsStrings from './operations.js'
import marketingStrings from './marketing.js'
import errorStrings from './errors.js'
import listingPageStrings from './listingPage.js'
import userStrings from './user.js'
import logisticsStrings from './logistics'
import hrStrings from './hr'
import storeStrings from './stores'
import csStrings from './customer-support'
import qcStrings from './qc'
import meidaStrings from './media'
import rewardsStrings from './rewards'
import actionTrailStrings from './action-trail'
import preorderStrings from './preorder'
import apphomeStrings from './apphome'
import omniJourneyStrings from './omni-journey'
import sngActionsStrings from './sng-actions.js'
import designationRolesStrings from './designation-roles.js'

const allStrings = Object.assign(
  {},
  loginStrings,
  resetPasswordStrings,
  validationStrings,
  signupStrings,
  error404Strings,
  settingsStrings,
  changePasswordStrings,
  menuStrings,
  catalogueStrings,
  operationsStrings,
  marketingStrings,
  listingPageStrings,
  userStrings,
  errorStrings,
  logisticsStrings,
  hrStrings,
  storeStrings,
  csStrings,
  qcStrings,
  meidaStrings,
  rewardsStrings,
  actionTrailStrings,
  preorderStrings,
  apphomeStrings,
  omniJourneyStrings,
  sngActionsStrings,
  designationRolesStrings
)

export default allStrings
