import React, { Component } from 'react'
 import ListingPage from '../../../../containers/ListingPage'
 import { Row, Cell } from '../../../../components/Table'
 import { getMessage } from '../../../../lib/translator'
 import './style.css'
 import emptyIcon from './empty.svg'
 import ProductFilters from './Filters'
 import Dialog from '../../../../components/Popup/Dialog'

 const emptyState = {
   icon: emptyIcon,
   message: 'There are no scheduled upload jobs',
 }

 const tableProperties = () => {
   return {
     headers: [
       getMessage('scheduled.batch.jobs.column.taskId'),
       getMessage('scheduled.batch.jobs.column.sku'),
       getMessage('scheduled.batch.jobs.column.payload'),
       getMessage('scheduled.batch.jobs.column.status'),
       getMessage('scheduled.batch.jobs.column.scheduledAt'),
       getMessage('scheduled.batch.jobs.column.completedAt'),
     ],
     row: ({ id, sku, payload,state, createdAt, updatedAt}) => /* istanbul ignore next */(
       <Row>
         <Cell>
           <span>{id}</span>
         </Cell>
         <Cell>
           <span>{sku}</span>
         </Cell>
         <Cell>
           <span>{payload}</span>
         </Cell>
         <Cell>
           <span>{state}</span>
         </Cell>
         <Cell className="createdAt">{createdAt}</Cell>
         <Cell className="updatedAt">{updatedAt}</Cell>
       </Row>
     ),
   }
 }

class BatchJobDetails extends Component {
  constructor() {
    super()
    this.state = {
      showDialog: false,
      dialogMessage: '',
      status: 'success'
    }
  }

  render() {
    const {menu, router} = this.props
    const { id } = router.match.params
    return (
      <div>
        <ListingPage
           menu={menu}
           className="scheduled-jobs-details-page"
           addHeading="Batch job details"
           title="Batch job details"
           api={{
             url: `/gt-catelogue/v1/batch-jobs/${id}/tasks`,
             transform: response => /* istanbul ignore next */ response && response.data ? response.data.jobs : [],
           }}
           showLanguageDropDown
           emptyState={emptyState}
           tableProperties={tableProperties()}
           primaryKey="id"
           filters={{
            component: ProductFilters
          }}
        />
        <Dialog
          className={this.state.status}
          show={this.state.showDialog}
          close={this.toggleDialog}
          message={this.state.dialogMessage}
          closeText="OK"
        />
      </div>
    )
  }
}

export default BatchJobDetails
