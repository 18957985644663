import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ErrorIcon } from 'icons/error-icon.svg';
import './style.scss'

const DeprecatedMessage = ({ url, referenceNumber }) => (
  <div className="deprecated-message-container">
    <ErrorIcon height={18} width={18} />
    <div data-testid="deprecated-message">
      This page will be deprecated on 30 September 2024. Please use the &nbsp;
      <Link to={`${url}/delivery-orders${referenceNumber ? `/${referenceNumber}` : ''}`}>
        Delivery Order page
      </Link>
      &nbsp;instead.
    </div>
  </div>
)

export default DeprecatedMessage
