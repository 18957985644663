import { isUserHaveRole } from 'lib/authorization'
import SPLIT_FEATURES from '../../../containers/SplitContext/features'
import { get } from 'lib/storage'

export const CATALOGUE_PRODUCT_READ_ONLY_ROLE_NAME =
  'Catalogue / Product (Read-only)'

export const isCatalogueProductReadOnly = () => {
  const flags = JSON.parse(get('SPLIT_FEATURE_FLAGS'))
  const isReadOnlyAccessControlEnabled =
    flags?.[SPLIT_FEATURES.READ_ONLY_ACCESS_CONTROL]?.treatment === 'on'
  if (isReadOnlyAccessControlEnabled) {
    return isUserHaveRole(CATALOGUE_PRODUCT_READ_ONLY_ROLE_NAME)
  }

  return false
}
