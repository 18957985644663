import React from 'react'
import { SelectSearch } from '../../../components/Form'
import './style.css'
import icon from '../drop-down-arrow.svg'
import { get } from '../../../lib/storage'

class StoreSelectorForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      defaultStore: this.props.selectedStore,
      selectedValue: '',
      showSelectSearch: false,
      options: this.props.stores || [],
    }

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleShowSelectSearch = this.handleShowSelectSearch.bind(this)
    this.hideSelectSearch = this.hideSelectSearch.bind(this)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleShowSelectSearch() {
    this.setState({
      showSelectSearch: true,
    })
  }

  hideSelectSearch() {
    this.setState({
      selectedValue: '',
      showSelectSearch: false,
    })
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.hideSelectSearch()
    }
  }

  handleChange(val) {
    this.setState({
      selectedValue: val,
    })

    if (val) {
      const selectedStore = this.props.stores.filter(
        store => Number(store.id) === Number(val)
      )
      if (selectedStore && selectedStore.length > 0) {
        if (selectedStore[0].id === Number(get('store'))) {
          this.hideSelectSearch()
          this.setState({
            defaultStore: selectedStore[0].name,
          })
          return
        }
        this.props.updateStore(selectedStore[0].id, this.props.onChange)
        this.setState({
          defaultStore: selectedStore[0].name,
        })
        this.hideSelectSearch()
      }
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    return this.state.showSelectSearch ? (
      <div
        className="store-selector-select-form search"
        ref={this.setWrapperRef}
      >
        <div className="default-store">{this.state.defaultStore}</div>
        <SelectSearch
          name={`store`}
          nameKey="name"
          valueKey="id"
          options={this.state.options}
          value={this.state.selectedValue}
          onChange={this.handleChange}
          autoFocus
          isShowingDefaultOptions={true}
          searchByName
        />
      </div>
    ) : (
      <div
        className="store-selector-select-form"
        onClick={this.handleShowSelectSearch}
      >
        <React.Fragment>
          {this.state.defaultStore}
          <img src={icon} alt="dropdown" />
        </React.Fragment>
      </div>
    )
  }
}

export default StoreSelectorForm
