import React from 'react'

import checkboxCheckedBlue from '../../icons/checkbox-selected-blue.svg'
import checkboxChecked from '../../icons/checkbox-selected.svg'
import checkboxUnchecked from '../../icons/checkbox-unselected.svg'

const CheckBox = ({ checked, onChange, isBlue, ...props }) => {
  const handleChange = () => {
    onChange &&
      onChange({
        target: {
          value: !checked,
        },
      })
  }

  return (
    <div role="checkbox" {...props} onClick={handleChange}>
      {checked ? (
        <img src={isBlue ? checkboxCheckedBlue : checkboxChecked} role="button" aria-label="selected" />
      ) : (
        <img
          src={checkboxUnchecked}
          role="button"
          alt="No Selected"
          aria-label="no-selected"
        />
      )}
    </div>
  )
}

export default CheckBox
