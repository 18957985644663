import React from 'react'
import ActionButton from './ActionButton'
import Splashscreen from './Splashscreen'

const SplashSection = ({
  previewButtonHandler,
  splash,
  missingItems,
  pressedSubmitWithCurrentData,
}) => {

  return (
    <div className="splash-section">
      <div className="column-flex">
        <ActionButton
          type="background"
          isErrorField={
            missingItems.includes('background') &&
            pressedSubmitWithCurrentData
          }
          previewButtonHandler={previewButtonHandler}
        />
        <ActionButton
          type="reset"
          previewButtonHandler={previewButtonHandler}
        />
      </div>
      <Splashscreen
        splash={splash}
      />
      <div className="column-flex">
        <ActionButton
          type="topAnimation"
          previewButtonHandler={previewButtonHandler}
        />
        <ActionButton
          type="logo"
          previewButtonHandler={previewButtonHandler}
        />
        <ActionButton
          type="bottomAnimation"
          previewButtonHandler={previewButtonHandler}
        />
      </div>
    </div>
  )
}

export default SplashSection
