import React from 'react'

import { Popup } from "../../../../../components/Popup";
import { getMessage } from '../../../../../lib/translator'
import API from '../../../../../lib/api'
import TagsImport from '../../../../catalogue/Tags/TagsImport'


const BulkTaggingModal = (props) => {
  const {show, handleClosePopup, value} = props;
  const { name, id } = value.data
  const taggingUrl = new API({
    url: `/ef-customer-core/tags/${id}/batch-assign`,
  })

  return (
    <Popup heading={getMessage('Bulk Tagging')} show={show} close={() => handleClosePopup(false)}>
      <div>
        <div>{getMessage('customer-service-tags.bulktagging.tagid')}</div>
        <div>{id}</div>
      </div>
      <div>
        <div>{getMessage('customer-service-tags.bulktagging.tagname')}</div>
        <div>{name}</div>
      </div>
      <TagsImport uploadUrl={taggingUrl.url} isBulkTagging={true} />
    </Popup>
  )
}

export default BulkTaggingModal;
