import React from 'react'
import deleteIcon from '../delete.svg'
import { getMessage } from '../../../lib/translator'
import TaskDetail from './TaskDetail'
import { addTask, deleteTask } from '../utils'
import { Radio } from '../../Form'
import './style.css'
import moment from 'moment'

const Tasks = (props) => {
  const { _this, method } = props
  const values = _this.state.values
  const {
    taskList,
    config: { isSequential },
  } = { ...values }

  const TASK_TYPE = [
    {
      text: 'Yes',
      value: true,
    },
    {
      text: 'No',
      value: false,
    },
  ]
  const handleTaskSequential = (value) => {
    values.config.isSequential = value === 'true' ? true : false
    _this.setState({
      values,
    })
  }
  return (
    <div className="single-column-field task-section">
      <div className="header-2">
        {getMessage('omnichallenge.mechanics.header1')}
      </div>
      <div className="col-6 mt">
        <Radio
          data-testid="isSequential"
          label={getMessage('omnichallenge.task.isSequential')}
          placeholder={getMessage('omnichallenge.task.isSequential')}
          options={TASK_TYPE}
          {..._this.generateStateMappers({
            stateKeys: ['config', 'isSequential'],
            defaultValue: true,
          })}
          onChange={(e) => handleTaskSequential(e)}
          value={isSequential}
        />
      </div>

      {taskList?.map((task, taskIndex) => (
        <div className="mb-1" key={taskIndex}>
          <div className="task-popup">
            <div className="task-header">
              <div
                className="header-1-lowercase"
                data-testid={`task ${taskIndex + 1}`}
              >
                {getMessage('omnichallenge.task.header')} {taskIndex + 1}
              </div>
              {taskList.length !== 1 && (
                <button
                  className="pb05"
                  type="button"
                  data-testid={`delete-task-btn-${taskIndex + 1}`}
                  onClick={(e) => deleteTask(_this, e, taskIndex)}
                  disabled={
                    method === 'edit' &&
                    moment().isAfter(_this.state.values.startAt)
                  }
                >
                  <img src={deleteIcon} alt="delete" />
                </button>
              )}
            </div>
            <TaskDetail
              _this={_this}
              task={task}
              taskIndex={taskIndex}
              method={method}
            />
          </div>
        </div>
      ))}
      <div className="flex mt-1 mb-1">
        <button
          data-testid="add-task-btn"
          className="button mr-3"
          type="button"
          onClick={() => addTask(_this)}
          disabled={
            method === 'edit' && moment().isAfter(_this.state.values.startAt)
          }
        >
          + {getMessage('omnichallenge.addTasks')}
        </button>
      </div>
    </div>
  )
}

export default Tasks
