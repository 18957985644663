import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { useContext } from 'react'

/**
 * Simple hooks to extract the feature flag for bulk S&G action
 * @returns
 */
export const useIsBulkSngActionEnabled = () => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return splits?.[SPLIT_FEATURES.BULK_ENABLING_SNG_STORE]?.treatment === 'on'
}
