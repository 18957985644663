import React from 'react'
import { Input, Upload, Textarea } from '../../../../components/Form'
import ImageWithText from '../../../../components/Layout/ImageWithText'

// lower case t is used in Text because it iis dependent on a module whose name is imageWithText with an Upper case T
const ImageWithTextLayout = () => {
  return {
    preview: ImageWithText,
    fields: ({ getState, updateState }) => (
      <div className='form-sections'>
        <div className='form-section'>
          <Input
            label='title'
            placeholder='Please enter title here'
            value={getState(['title']) || ''}
            onChange={value => {
              updateState(['title'], value)
            }} />
          <Upload
            label='image'
            value={getState(['imageUrl']) || ''}
            onChange={value => {
              updateState(['imageUrl'], value)
            }} />
          <Input
            label='link'
            placeholder='URL to navigate on clicking'
            value={getState(['link']) || ''}
            onChange={value => {
              updateState(['link'], value)
            }} />
        </div>
        <div className='form-section'>
          <Input
            label='subtitle'
            placeholder='Please enter subtitle here'
            value={getState(['subtitle']) || ''}
            onChange={value => {
              updateState(['subtitle'], value)
            }} />
          <Textarea
            label='description'
            placeholder='Please enter description'
            value={getState(['description']) || ''}
            onChange={value => {
              updateState(['description'], value)
            }} />
        </div>
      </div>
    )
  }
}
export default ImageWithTextLayout
