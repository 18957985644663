import React from 'react'
import CategorizedStoreSelector from '../../../../components.new/store-selector/CategorizedStoreSelector'
import AddProductSKU from './AddProductSKU'

const SpecificProductDetails = ({
  stores,
  updateState,
  getState,
  isVoucher,
}) => {
  return (
    <div>
      {!isVoucher && (
        <div
          className="form-sections"
          style={{
            padding: '0.5rem 1rem',
          }}
        >
          <AddProductSKU
            onChange={value => {
              updateState(['productIds'], value.join(','))
            }}
            values={
              getState(['productIds'])
                ?.split(',')
                .filter(e => !!e) || []
            }
          />
        </div>
      )}

      <div
        className="form-sections"
        style={{
          padding: !isVoucher ? '0.5rem 1rem' : 0,
        }}
      >
        <label htmlFor="specific-store">Specific Store</label>
        <div id="specific-store">
          <CategorizedStoreSelector
            onUpdate={value => {
              updateState(['storeIds'], value.join(','))
            }}
            stores={stores}
            values={
              getState(['storeIds'])
                ?.split(',')
                .filter(e => !!e)
                .map(e => Number(e)) || []
            }
          />
        </div>
      </div>
    </div>
  )
}

export default SpecificProductDetails
