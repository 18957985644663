import React, { useContext } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import { SplitContext } from '../../../containers/SplitContext'
import Form from './form'
import './style.css'

const CommunicationConfig = (props) => {
  const splitConfig = useContext(SplitContext)
  if (splitConfig.loading) {
    return null
  }

  const { splits } = splitConfig
  const color =
    splits?.back_platform_comm_title_color?.treatment === 'on' ? 'red' : 'fff'
  return (
    <AuthenticatedPage
      menu={props.menu}
      from={props.location && props.location.pathname}
      className="communication-config"
    >
      <h1 className="title" style={{ color: color }}>
        Communication Configuration
      </h1>
      <Form back={props.history.goBack} />
    </AuthenticatedPage>
  )
}

export default CommunicationConfig
