import React from 'react'
import {
  BaseForm,
  Input,
  Radio,
  VALIDATION_TYPES,
} from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'

export default class SubstitutionGroupFiltersForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values.status = ''
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('substitutionGroup.filters.name.heading')}
            placeholder={getMessage(
              'substitutionGroup.filters.name.placeholder'
            )}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
            }}
          />
          <Radio
            label={getMessage('substitutionGroup.filters.status.heading')}
            name="status"
            options={[
              {
                text: getMessage('substitutionGroup.filters.status.label.all'),
                value: '',
              },
              {
                text: getMessage(
                  'substitutionGroup.filters.status.label.enabled'
                ),
                value: 'ENABLED',
              },
              {
                text: getMessage(
                  'substitutionGroup.filters.status.label.disabled'
                ),
                value: 'DISABLED',
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ['status'],
            })}
          />
        </div>
        <SubmitButton>
          {getMessage('substitutionGroup.filters.submitText')}
        </SubmitButton>
        <ClearButton>
          {getMessage('substitutionGroup.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
