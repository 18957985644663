import React from 'react'
import {
  BaseForm,
  Input,
  Select,
  SingleDatePicker,
  TimePicker,
  VALIDATION_TYPES,
} from 'components/Form'
import API from 'lib/api'
import FileUpload from 'components/FileUpload'
import { formatDateTime } from 'components/AppHome/utils'
import { getMessage } from 'lib/translator'
import uploadIcon from './uploadIcon.svg'
import Loader from 'components/Loader'
import csvIcon from './csv-icon.svg'
import './style.css'

class VoucherForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      page: 'form',
      error: null,
      values: {
        time: '00:00:00',
      },
    }

    this.dismissErrors = this.dismissErrors.bind(this)
    this.onUploadFile = this.onUploadFile.bind(this)
  }

  dismissErrors() {
    this.setState({
      error: null,
      page: 'form',
    })
  }

  onUploadFile(file) {
    return new Promise((resolve, reject) => {
      this.setState({
        pressedSubmitWithCurrentData: true,
      })

      if (this.isFormValid()) {
        this.setState({ page: 'loading' })
        const data = new window.FormData()
        const startTime = formatDateTime(
          this.state.values.date,
          this.state.values.time
        )
        data.append('file', file)
        data.append('startTime', startTime)
        data.append('checkInCategory', this.state.values.checkInCategory)
        data.append('storeType', this.state.values.storeType)
        data.append('storeId', this.state.values.storeId)

        new API({ url: '/genie/lms/scratchcards' }).post(data).then(
          () => {
            this.setState({
              page: 'success',
            })
            resolve()
          },
          (error) => {
            this.setState({
              page: 'error',
              error: {
                message: getMessage('omni-journey.error-msg.encounter-issue'),
              },
            })
            reject({
              ...error,
              message: getMessage('omni-journey.error-msg.encounter-issue'),
            })
          }
        )
      } else {
        reject({
          message: getMessage('omni-journey.error-msg.incomplete-form'),
        })
      }
    })
  }

  render() {
    const { Form } = this.components
    const { error, page } = this.state

    return (
      <Form className="omni-journey-voucher-form">
        {page === 'loading' && <Loader />}
        {page === 'success' && (
          <div className="status-section">
            <span>{getMessage('omni-journey.success-msg.upload-success')}</span>
            <button
              className="primary button"
              onClick={() => this.props.setShowForm(false)}
            >
              {getMessage('omni-journey.close-button')}
            </button>
          </div>
        )}
        {page === 'error' && (
          <div className="status-section">
            <span>{error.message}</span>
            <button className="primary button" onClick={this.dismissErrors}>
              {getMessage('omni-journey.try-again-button')}
            </button>
          </div>
        )}
        {page === 'form' && (
          <>
            <Input
              required
              type="text"
              label={getMessage('omni-journey.storeId-field.label')}
              placeholder={getMessage('omni-journey.storeId-field.placeholder')}
              {...this.generateStateMappers({
                stateKeys: ['storeId'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Input
              required
              type="text"
              label={getMessage('omni-journey.storeType-field.label')}
              placeholder={getMessage(
                'omni-journey.storeType-field.placeholder'
              )}
              {...this.generateStateMappers({
                stateKeys: ['storeType'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Select
              required
              name="check-in-category"
              className="check-in-category"
              label={getMessage('omni-journey.category-field.label')}
              placeholder={getMessage(
                'omni-journey.category-field.placeholder'
              )}
              options={[
                { value: 'FIRST_TIME', text: 'FIRST_TIME' },
                { value: 'REPEAT', text: 'REPEAT' },
                { value: 'SECOND_TIME_SPECIAL', text: 'SECOND_TIME_SPECIAL' },
                { value: 'NEW_USER_SPECIAL', text: 'NEW_USER_SPECIAL' },
              ]}
              {...this.generateStateMappers({
                stateKeys: ['checkInCategory'],
                loseEmphasisOnFill: true,
              })}
            />
            <SingleDatePicker
              required
              enableToday
              label={getMessage('omni-journey.start-date-field.label')}
              placeholder={getMessage('omni-journey.date-field.placeholder')}
              {...this.generateStateMappers({
                stateKeys: ['date'],
                loseEmphasisOnFill: true,
              })}
            />
            <TimePicker
              required
              label={getMessage('omni-journey.start-time-field.label')}
              placeholder={getMessage('omni-journey.time-field.placeholder')}
              {...this.generateStateMappers({
                stateKeys: ['time'],
                loseEmphasisOnFill: true,
              })}
            />
            <div className="csv-upload-section">
              <div>
                <img src={uploadIcon} width="140" alt="upload icon" />
              </div>
              <FileUpload
                name="voucher-sequence-upload"
                uploadButtonText={getMessage('omni-journey.upload-button')}
                accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
                onUpload={this.onUploadFile}
                cancelText={getMessage('omni-journey.cancel-button')}
                placeholder={getMessage(
                  'omni-journey.fileUpload-field.placeholder'
                )}
                validationStrings={{
                  invalidFileType: getMessage('input.invalidFileType'),
                }}
                strings={{
                  defaultMessage: getMessage('fileUpload.importCsv.heading'),
                  progressMessage: getMessage('fileUpload.importedCsv.heading'),
                  completionMessage: getMessage(
                    'fileUpload.uploadedCsv.success'
                  ),
                }}
                icon={csvIcon}
              />
            </div>
          </>
        )}
      </Form>
    )
  }
}

export default VoucherForm
