import React from 'react';
import { getMessage } from 'lib/translator';
import { formatIsoDate } from 'lib/datetime';
import moment from 'moment';
import _ from 'lodash';
import './style.scss'

const deliveryOrderStatus = {
  COMPLETED: 'order.status.completed',
  CANCELLED: 'order.status.cancelled',
  PENDING: 'order.status.pending',
  PICKED: 'order.status.picked',
  CHECKED: 'order.status.checked',
  PACKED: 'order.status.packed',
  PICKING: 'order.status.picking',
  'PARTIALLY-PICKED': 'order.status.partiallypicked',
  CHECKING: 'order.status.checking',
  PACKING: 'order.status.packing',
  DISPATCHED: 'order.status.dispatched',
  RETURNED: 'order.status.returned',
  PROCESSING: 'order.status.processing',
}

const deviceNames = {
  MOBILE_ANDROID_WEB: 'Android',
  DESKTOP_NA_WEB: 'Desktop',
  MOBILE_IOS_WEB: 'iOS',
}

function PlacementDetails({ status, device, creationTime }) {
  return (
    <div className="order-placement-details">
      <div className="section-pre-order">
        <h3 className="section-title">{getMessage('saleOrder.details.status')}: {' '}
        <span className={`order-status-${status.toLowerCase()}`}>
          {deliveryOrderStatus[status] ? getMessage(deliveryOrderStatus[status]) : _.capitalize(status)}
        </span>
        </h3>
      </div>
      <div className="order-placement-info-item">
        <span className="order-placement-info-icon icon-clock" />
        <small className="text-muted">
          {getMessage('order.details.placedTime')}:{' '}
          <span className="creation-time">
            {formatIsoDate(creationTime)} {', '}
            {moment(creationTime).format('LT')}
          </span>
        </small>
      </div>
      <div className="order-placement-info-item">
        <span className="order-placement-info-icon icon-mobile" />
        <small className="text-muted">
          {getMessage('saleOrder.details.placedFrom')}: <span className="device-name">{device ? deviceNames[device] : deviceNames['DESKTOP_NA_WEB']}</span>
        </small>
      </div>
    </div>
  )
}

export default PlacementDetails
