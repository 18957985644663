import React, { useState } from 'react'
import { tableProperties, EMPTY_STATE } from '../rewardsUtils'
import ListingPageWithRoutes from '../../../../../../containers/ListingPage/listingRouter'
import { getMessage } from '../../../../../../lib/translator'
import '../style.css'

const PrizesWon = ({ uid, selectedCampaign }) => {
  const [prizesWonCount, setPrizesWonCount] = useState(0)

  return (
    <div className="tab-list mt-2">
      <h3 className="title">
        {getMessage('customer.details.luckyDraws.prizesWon')}
        <span>({prizesWonCount})</span>
      </h3>
      {selectedCampaign && (
        <ListingPageWithRoutes
          isRewardsTab={true}
          testid="prizes-won-listing"
          menu=""
          className="rewards-page"
          api={{
            url: `/duck/winners`,
            params: { campaignId: selectedCampaign, uid, limit: 20, offset: 0 },
            transform: (response) => {
              if (response) {
                setPrizesWonCount(response.data.count)
                return response.data.list
              }
              return null
            },
            updateApiParams: (prevParams, newParams) => {
              if (prevParams.campaignId !== newParams.campaignId) {
                return {
                  shouldUpdate: true,
                  params: {
                    campaignId: newParams.campaignId,
                    uid,
                    limit: 20,
                    offset: 0,
                  },
                }
              }
              return null
            },
          }}
          form={{}}
          emptyState={EMPTY_STATE}
          tableProperties={tableProperties('PrizesWon')}
          tableDynamic
        />
      )}
    </div>
  )
}

export default PrizesWon
