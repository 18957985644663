import React from 'react'
import { Link } from 'react-router-dom'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import StoreForm from './Form'
import storesFilters from './Filters'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { getMessage } from '../../../lib/translator'
import { setStores, getStores, isExtensionEnabled } from '../../../lib/auth'
import API from '../../../lib/api'
import { set } from '../../../lib/storage'
import { SplitContext } from 'containers/SplitContext'
import { upperCase } from 'lodash'

import EmptyIcon from './store-empty.svg'
import './style.css'
import { injectAutomationFlag } from './automationFlag'
import { ManageStoreCore } from './ManageStore'
import { STORE_TECH_BASE_URL } from 'pages/customer-support/constants'
import SPLIT_FEATURES from '../../../containers/SplitContext/features'

export const clearStoreCache = (isClearStoreCache = false) => {
  if (!isClearStoreCache) return
  const clearCacheAPI = new API({
    url: `${STORE_TECH_BASE_URL}/store/v0.2/clear-store-configs`,
  })
  clearCacheAPI.get().catch(console.log)
}

export const transformSngOperationHours = (isFeatureEnabled, data) => {
  if (isFeatureEnabled) {
    const sngHoursObject = data.sngOperationalHours

    if (sngHoursObject && data.metaData !== undefined) {
      data.metaData['SnG Operational Hours'] = JSON.stringify(sngHoursObject)
      delete data.sngOperationalHours
    }
    return data
  }
  return data
}

/**
 * New Version to display all actions component at the top of the
 * page
 */
function StorePageActions() {
  return (
    <div>
      <div className="text-center actions-box">
        <ManageStoreCore />{' '}
        {/* Entry point to the Manage Store - S&G Bulk Enablinge */}
        <Link to="/settings/stores/add" className="primary button">
          <span />+{' '}
          <span className="text">
            {getMessage('operations.store.add.text')}
          </span>
        </Link>
      </div>
    </div>
  )
}

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage('operations.store.empty.message'),
  additionalViews: [StorePageActions],
}

export const tableProperties = (isStoreKindColEnable) => {
  const headers = [
    getMessage('operations.store.table.header.name'),
    isStoreKindColEnable &&
      getMessage('operations.store.table.header.store_kind'),
    getMessage('operations.store.table.header.address'),
    getMessage('operations.store.table.header.deliveryHub'),
    getMessage('operations.store.table.header.deliveryFee'),
    getMessage('operations.store.table.header.picking'),
    getMessage('operations.store.table.header.clientSAPId'),
    getMessage('operations.store.table.header.status'),
    getMessage('operations.store.table.header.actions'),
  ]

  return {
    /**
     * filter before rendering to ensure, if some value are "false" or empty values,
     * remove it out from that header list, or else UI will create extra spacing even
     * there should not be any col in that header.
     */
    headers: headers.filter(Boolean),
    // eslint-disable-next-line react/display-name
    row: ({
      id,
      type,
      name,
      address,
      clientId,
      onAction,
      hasDeliveryHub,
      deliveryFee,
      hasPicking,
      status,
      tierSetupId,
      businessHours
    }) => (
      <Row>
        <Cell className="store-name-container">
          <Link to={`/settings/stores/edit/${id}`} className="store-name">
            {name || <strong>{getMessage('operations.store.default')}</strong>}
          </Link>
        </Cell>
        {isStoreKindColEnable && (
          <Cell className="store-kind">
            <span>{upperCase(type)}</span>
          </Cell>
        )}
        <Cell className="store-address">{address}</Cell>
        <Cell className="store-has-delivery-hub">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.deliveryHub')}
          </div>
          <span>
            {hasDeliveryHub
              ? getMessage('operations.store.table.row.picking.yes')
              : getMessage('operations.store.table.row.picking.no')}
          </span>
        </Cell>
        <Cell className="store-has-delivery-fee">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.deliveryFee')}
          </div>
          <span>{deliveryFee}</span>
        </Cell>
        <Cell className="store-has-picking">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.picking')}
          </div>
          <span>
            {hasPicking
              ? getMessage('operations.store.table.row.picking.yes')
              : getMessage('operations.store.table.row.picking.no')}
          </span>
        </Cell>
        <Cell className="store-client-id">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.clientId')}
          </div>
          <span>{clientId}</span>
        </Cell>
        <Cell className="store-status">
          <div className="store-mobile-view-header">
            {getMessage('operations.store.table.header.status')}
          </div>
          <span>
            {status === 'ENABLED'
              ? getMessage('operations.store.form.status.enable.label')
              : getMessage('operations.store.form.status.disable.label')}
          </span>
        </Cell>
        <Cell className="store-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem>
              <Link to={`/settings/stores/edit/${id}`}>
                {getMessage('operations.store.actions.edit')}
              </Link>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.DELETE, { id })
                const allStores = getStores()
                allStores.splice(
                  allStores.findIndex((store) => store.id === Number(id)),
                  1
                )
                setStores(allStores)
                allStores &&
                  allStores.length > 0 &&
                  set('store', allStores[0].id)
              }}
            >
              {getMessage('operations.store.actions.delete')}
            </DropDownItem>
            {status && status === 'ENABLED' && (
              <DropDownItem
                onClick={() =>
                  onAction(TABLE_ACTIONS.UPDATE, { id }, { status: 'DISABLED', tierSetupId, businessHours })
                }
              >
                {getMessage('operations.store.actions.disable')}
              </DropDownItem>
            )}
            {status && status === 'DISABLED' && (
              <DropDownItem
                onClick={() =>
                  onAction(TABLE_ACTIONS.UPDATE, { id }, { status: 'ENABLED', tierSetupId, businessHours })
                }
              >
                {getMessage('operations.store.actions.enable')}
              </DropDownItem>
            )}
          </DropDown>
        </Cell>
      </Row>
    ),
  }
}

const DEFAULT_DATA = {
  number: 0,
  string: '',
  boolean: false,
  enum: '',
  'multiValued Enum': '',
}

export default class Store extends React.Component {
  static contextType = SplitContext
  constructor() {
    super()
    this.api = new API({
      url: '/account-service/shipping-tier',
    })
    this.state = {}
    this.setMetadata = this.setMetadata.bind(this)
  }

  async componentDidMount() {
    const response = await this.api.get()
    if (response && response.data) {
      this.setState({ tierSetupData: response.data })
    }
    const metadataApi = new API({ url: `/config-service/meta-data` })
    const metadataResponse = await metadataApi.get()
    const storeMetaDataWithType =
      (metadataResponse.data &&
        metadataResponse.data.config &&
        metadataResponse.data.config.entityMetaData &&
        metadataResponse.data.config.entityMetaData.store) ||
      {}
    this.setState({
      storeMetaDataWithType,
    })
  }

  setMetadata(metadata) {
    const { storeMetaDataWithType } = this.state
    Object.keys(storeMetaDataWithType || {}).forEach((key) => {
      if (metadata && !metadata[key]) {
        metadata[key] =
          DEFAULT_DATA[storeMetaDataWithType[key]['type']] !== undefined
            ? DEFAULT_DATA[storeMetaDataWithType[key]['type']]
            : ''
      }
    })
  }

  render() {
    const { props } = this
    const { storeMetaDataWithType } = this.state

    const storeKindColEnabled =
      this.context.splits?.[SPLIT_FEATURES.STORE_KIND_FIELD]?.treatment === 'on'

    const sngOperationalHoursFormEnabled =
      this.context.splits?.[SPLIT_FEATURES.STORE_SNG_OPT_HOURS_FORM]
        ?.treatment === 'on'

    return (
      <ListingPageWithRoutes
        menu={props.menu}
        className="stores-page"
        title={getMessage('operations.store.heading')}
        addHeading={getMessage('operations.store.form.add.heading')}
        editHeading={getMessage('operations.store.form.edit.heading')}
        headerActions={StorePageActions}
        showLanguageDropDown
        emptyState={EmptyState}
        api={{
          url: `/account-service/store`,
          transform: (response) => {
            const result =
              response.data && response.data.store
                ? [...response.data.store]
                : []

            result.forEach((store) => {
              //mapping tier setups in stores with tier setup data to get names
              const { tierSetupData } = this.state
              if (tierSetupData) {
                const tierSetup = tierSetupData.find(
                  // codesmell  clean up 'tierSetup' is already declared in the upper scope
                  (ts) => ts.id === store.tierSetupId
                )
                if (tierSetup) {
                  store.deliveryFee = tierSetup.name
                }
              }
            })
            clearStoreCache(this.state.isClearStoreCache)
            this.setState({ isClearStoreCache: false })
            return result
          },
        }}
        form={{
          component: StoreForm,
          options: {
            storeMetaDataWithType,
          },
          transformSubmit: (formData) => {
            this.setState({ isClearStoreCache: true })
            let data = JSON.parse(JSON.stringify(formData))
            const { businessHours } = data
            const newBusinessHours = {}
            businessHours &&
              Object.keys(businessHours).map((day) => {
                if (businessHours[day]['o'] || businessHours[day]['c']) {
                  newBusinessHours[day] = businessHours[day]
                }
                return null
              })
            data.businessHours = newBusinessHours
            if (!Object.keys(data.businessHours).length) {
              data.businessHours = null
            }
            this.setMetadata(data.metaData)
            !isExtensionEnabled('EntityMetaData') && delete data.metaData
            /** Injecting automation flag when on store creation flow  */
            data = injectAutomationFlag(data, this.props, this.context)
            return transformSngOperationHours(
              sngOperationalHoursFormEnabled,
              data
            )
          },
          afterSubmit: (response) => {
            const store = (response.data && response.data.store) || {}
            const storeId = store.id
            const allStores = getStores()
            // codesmell clean up - 'storeIndex' is never reassigned. Use 'const' instead. - 'store' is already declared in the upper scope
            const storeIndex = allStores.findIndex((st) => st.id === storeId)
            if (storeIndex > -1) {
              allStores[storeIndex] = store
            } else {
              if (Object.keys(store).length) {
                allStores.push(store)
              }
            }
            setStores(allStores)
          },
          transformResponse: (response) => {
            const formattedResponse = Object.assign(
              {},
              response.data && response.data.store
            )
            if (formattedResponse.latitude) {
              formattedResponse.latitude = Number(formattedResponse.latitude)
            }
            if (formattedResponse.longitude) {
              formattedResponse.longitude = Number(formattedResponse.longitude)
            }
            if (
              formattedResponse.businessHours &&
              typeof formattedResponse.businessHours === 'object'
            ) {
              if (!Object.keys(formattedResponse.businessHours).length) {
                formattedResponse.businessHours = null
              }
            }

            return formattedResponse
          },
        }}
        filters={{
          component: storesFilters,
        }}
        tableProperties={tableProperties(storeKindColEnabled)}
      />
    )
  }
}
