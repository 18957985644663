import React from 'react'
import ProductCollection from '../../../../components/Layout/ProductCollection'
import { Input, Select, Radio, Searchable } from '../../../../components/Form'
import { isExtensionEnabled } from '../../../../lib/auth'
import { getMessage } from '../../../../lib/translator'
import SpecificProductDetails from '../components/SpecificProductDetails'
const ProductCollectionWithBannerLayout = () => {
  return {
    preview: ProductCollection,
    fields: ({ getState, updateState, page, stores = [] }) => {
      return (
        <div>
          <div className="form-sections">
            <div className="form-section">
              <Input
                label={getMessage('themes.title')}
                placeholder="Type the collection title"
                value={getState(['title']) || ''}
                onChange={e => {
                  updateState(['title'], e)
                }}
              />
            </div>
            <div className="form-section">
              <Input
                label={getMessage('themes.cc.subtitle')}
                placeholder="Enter the  subtitle"
                value={getState(['subtitle']) || ''}
                onChange={e => {
                  updateState(['subtitle'], e)
                }}
              />
            </div>
            {isExtensionEnabled('ProductTagSupport') && (
              <div className="form-section">
                <Searchable
                  label="Tags"
                  placeholder={getMessage('themes.tag.show')}
                  name="tag"
                  searchUrl="/catalogue-service/tag"
                  valueKey="id"
                  nameKey="name"
                  searchKey="name"
                  expandMore={true}
                  transformResponse={response =>
                    (page === 'tag'
                      ? [
                          {
                            id: new Date().getTime(),
                            name: 'current',
                            slug: 'CURRENT',
                          },
                        ]
                      : []
                    ).concat(response.data.tag)
                  }
                  value={getState(['tag']) || updateState(['tag'], 'ALL')}
                  onChange={value => {
                    updateState(['tag'], value)
                  }}
                />
              </div>
            )}
          </div>
          <div className="form-sections">
            <div className="form-section">
              <Select
                label="Sort by"
                placeholder={getMessage('themes.sortinglogic')}
                options={[
                  {
                    text: 'Popularity',
                    value: 'POPULARITY',
                  },
                  {
                    text: 'New First',
                    value: 'NEW_FIRST',
                  },
                  {
                    text: 'New Last',
                    value: 'NEW_LAST',
                  },
                ]}
                value={getState(['sorting']) || ''}
                onChange={value => {
                  updateState(['sorting'], value)
                }}
              />
            </div>
          </div>
          <div className="form-sections">
            <div
              style={{
                padding: '0.5rem 1.75rem 1.5rem',
              }}
            >
              <SpecificProductDetails
                getState={getState}
                updateState={updateState}
                stores={stores}
                isVoucher={true}
              />
            </div>
          </div>

          <div className="form-sections">
            <div className="form-section">
              <Radio
                name="layoutType"
                label={getMessage('themes.layoutType')}
                options={[
                  {
                    text: getMessage('themes.grid'),
                    value: 'GRID',
                  },
                  {
                    text: getMessage('themes.scroller'),
                    value: 'SCROLLER',
                  },
                ]}
                value={getState(['layoutType'])}
                onChange={e => updateState(['layoutType'], e)}
                readOnly={true}
                disabled={true}
              />
            </div>
            <div className="form-section">
              <Radio
                name="pagination"
                label={getMessage('themes.loadMoreType')}
                options={[
                  {
                    text: getMessage('themes.infiniteScroll'),
                    value: 'INFINITE',
                  },
                  {
                    text: getMessage('themes.seeAll'),
                    value: 'SEEALL',
                  },
                ]}
                value={getState(['loadMoreType'])}
                onChange={e => updateState(['loadMoreType'], e)}
                readOnly={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
      )
    },
  }
}

export default ProductCollectionWithBannerLayout
// TODO
// Styling fixes
// Add unit tests
