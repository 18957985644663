import { OktaAuth } from '@okta/okta-auth-js'
import { OKTA_ISSUER, OKTA_CLIENTID } from '../../config/app'

const mockClient = {
  token: {
    getWithRedirect: () => Promise.resolve({}),
    parseFromUrl: () => Promise.resolve({ tokens: { accessToken: 'test' } }),
  },
  start: () => Promise.resolve({}),
  stop: () => Promise.resolve({}),
  revokeAccessToken: () => {},
  signOut: () => {},
  parseFromUrl: () => Promise.resolve({}),
  tokenManager: {
    on: () => {},
    get: () => Promise.resolve(localStorage.getItem('testToken')),
    setTokens: () => {},
    hasExpired: () => false,
  },
}
export const getAccessToken = (key, newToken, currentToken) => {
  if (key === 'accessToken') {
    return newToken.accessToken
  }
  return currentToken
}
class Okta {
  constructor() {
    const config = {
      clientId: OKTA_CLIENTID,
      issuer: OKTA_ISSUER,
      redirectUri: '/user/callback',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      pkce: true,
    }
    if (process.env.NODE_ENV === 'test') {
      this.authClient = mockClient
      return
    }
    this.authClient = new OktaAuth(config)
    this.authClient.start()

    this.authClient.tokenManager.on('renewed', (key, newToken) => {
      this.token = getAccessToken(key, newToken, this.token)
    })
  }

  async oktaLogoutMethod() {
    await this.authClient.revokeAccessToken()
    this.authClient.signOut()
  }

  async reinitRenewService() {
    await this.authClient.stop()
    await this.authClient.start()
  }

  loginMethod() {
    this.authClient.token
      .getWithRedirect({
        responseType: ['token', 'id_token'],
        state: 'defaultrandomstring',
      })
      .catch((err) => {
        console.error('Error OKTA login redirect', err)
      })
  }

  setTokenMethod(callback, errorHandler) {
    const self = this
    this.authClient.token
      .parseFromUrl()
      .then(async (res) => {
        const authToken = res.tokens.accessToken
        this.token = authToken.accessToken
        self.authClient.tokenManager.setTokens(res.tokens)
        callback()
      })
      .catch((err) => {
        console.error('Error OKTA set token: ', err)
        errorHandler && errorHandler(err)
      })
  }

  subscribeTokenExpiryEventMethod() {
    this.authClient.tokenManager.on('expired', () => {
      this.authClient.tokenManager.renew('accessToken').then((token) => {
        this.token = token.accessToken
      })
    })
  }

  async isOktaLoggedInMethod() {
    const token = await this.authClient.tokenManager.get('accessToken')
    if (!token || this.authClient.tokenManager.hasExpired(token)) {
      return false
    }
    this.token = token.accessToken
    return true
  }

  fetchAuthTokenMethod() {
    return this.token
  }
}

const oktaInstance = new Okta()
const onVisibilityChange = () => {
  oktaInstance.reinitRenewService()
  return true
}
document.addEventListener('visibilitychange', onVisibilityChange)

export { oktaInstance, onVisibilityChange }
