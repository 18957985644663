import React from 'react'
import { isPast, parse } from 'date-fns'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import { BaseForm, Input, VALIDATION_TYPES } from '../../../components/Form'
import API from '../../../lib/api'
import './Form/style.css'
import SearchResults from '../../../components/Evoucher/SearchResults'

class SearchEvoucher extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values = {
      response: '',
      error: '',
      code: this.props?.location?.param,
    }
    this.handleReset = this.handleReset.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  handleReset() {
    const values = Object.assign({}, this.state.values)
    values.response = ''
    values.error = ''
    values.code = ''
    values.slug = ''
    this.setState({
      values: values,
    })
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    const values = Object.assign({}, this.state.values)
    values.response = ''
    values.error = ''
    this.setState({
      values: values,
    })
    const { slug, code } = this.state.values
    let url = ''
    if (slug) {
      url = `/${slug}?idType=slug`
    }
    if (code) {
      url = `/${code}?idType=code`
    }

    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    if (isValid && (slug || code)) {
      try {
        const api = new API({ url: `/vouchers${url}` })
        const response = await api.get()
        values.response = response
        this.setState({
          values: values,
        })
      } catch (error) {
        values.error = error.message || 'No results found.'
        this.setState({
          values: values,
        })
        if (error.code === 401) {
          throw error
        }
      }
    }
  }

  onCancel() {
    this.props.history.push('/marketing/evouchers')
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const { response, error } = this.state.values
    const parsedDate = parse(response.expiresAt || '', 'yyyy-MM-dd')
    const hasExpired = isPast(parsedDate)
    let status = 'active'
    if (response.balance === 0) {
      status = 'used'
    }
    if (hasExpired) {
      status = 'expired'
    }

    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <h1 className="title">Search E-Voucher</h1>
        <Form className="evoucher-wrapper contain mt">
          <div className="row flex-direction">
            <div className="col-6">
              <Input
                label="E-Voucher code"
                placeholder="Search by E-Voucher code"
                testid="code"
                type="text"
                pattern="\b[a-zA-Z0-9]{19}\b"
                {...this.generateStateMappers({
                  stateKeys: ['code'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  patternMismatch: 'Code should be 19 characters long ',
                }}
              />
            </div>
            <div className="col-6">
              <Input
                label="E-Voucher slug"
                placeholder="Search by E-Voucher slug"
                testid="slug"
                type="text"
                pattern="\b[a-zA-Z0-9]{64}\b"
                {...this.generateStateMappers({
                  stateKeys: ['slug'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  patternMismatch: 'Slug should be 64 characters long',
                }}
              />
            </div>
          </div>

          <div className="row flex-direction">
            <div className="col-6">
              <SubmitButton testid="search-btn">Search</SubmitButton>
              <button
                className="button"
                type="button"
                onClick={this.handleReset}
                data-testid="reset-btn"
              >
                Reset
              </button>
            </div>
          </div>
        </Form>
        {(error || response) && (
          <SearchResults
            onCancel={this.onCancel}
            history={this.props.history}
            error={error}
            response={response}
            status={status}
          />
        )}
      </AuthenticatedPage>
    )
  }
}

export default SearchEvoucher
