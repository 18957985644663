const data = {
  'action-trail.dashboard.title': 'Action Trail',
  'action-trail.dashboard.dropdown.csvexport.label': 'Export to CSV',
  'action-trail.filter.userid.label': 'User Id',
  'action-trail.filter.userid.placeholder': 'Search for user id',
  'action-trail.filter.email.label': 'Email',
  'action-trail.filter.email.placeholder': 'Email Address',
  'action-trail.filter.dates.label': 'Action Dates',
  'action-trail.filter.type.label': 'Action Type',
  'action-trail.filter.endpoint.label': 'Endpoint',
  'action-trail.filter.endpoint.placeholder': 'Enter endpoint url',
  'action-trail.filter.submit.buttontext': 'Search',
  'action-trail.filter.clear.buttontext': 'Clear',
  'action-trail.table.header.id': 'Log ID',
  'action-trail.table.header.email': 'Email',
  'action-trail.table.header.method': 'Method',
  'action-trail.table.header.endpoint': 'Endpoint',
  'action-trail.table.header.userId': 'User ID',
  'action-trail.table.header.actionType': 'Action Type',
  'action-trail.table.header.dateTime': 'Date Time',
  'action-trail.table.header.locationOrigin': 'Location Origin',
  'action-trail.table.header.actions': 'Actions',
}

export default data
