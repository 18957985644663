import React from 'react'
import { Input } from '../../Form'
import { getMessage } from 'lib/translator'

const GEO_INFO = ['lat', 'lng']

const GeoInfo = ({ _this }) => (
  <div className="row">
    {GEO_INFO.map((info, j) => (
      <div className="col-12" key={j}>
        <Input
          label={getMessage(`rewards.store.${info}`)}
          placeholder={getMessage(`rewards.store.${info}`)}
          type="number"
          step="0.000000001"
          min={0.000000001}
          {..._this.generateStateMappers({
            stateKeys: [`${info}`],
          })}
        />
      </div>
    ))}
  </div>
)

export default GeoInfo
