import React from 'react'
import { getMessage } from 'lib/translator'
import Table, { Header, Row, Cell } from 'components/Table'
import SingleOrderView from './SingleOrderView'
import { ShowAmounts, GroupedDiscounts } from 'pages/operations/DeliveryOrders/Details/OrderItemsView'
import { getCalculatedSummaryValues } from 'lib/commonlyused/orderCalculations'
import './style.scss'

function OrdersListView({
  orders,
  currency,
  standardServiceFee,
  discounts,
  payments,
  totalAmount,
}) {
  const totalItemsCount = orders.flatMap((order) => order.items).length
  const totalShippingCharge = orders
    .flatMap((x) => x.shipping)
    .reduce((prev, current) => prev + Number(current), 0)
  const allOrderItems = orders.flatMap((order) => {
    return order.items.map((x) => ({ ...x, orderStatus: order.status }))
  })

  const isPendingOrCancelled = (orderStatus) =>
    orderStatus === 'PENDING' || orderStatus === 'CANCELLED'

  /* SaleOrder Level Subtotal Values */
  // remove sub items and flatten to orderDetails and add orderStatus
  const ordersItemsDetails = allOrderItems
    .filter((x) => !x.orderDetails.substitutedItemId)
    .flatMap((x) => ({ ...x.orderDetails, orderStatus: x.orderStatus }))

  const subTotalPrice = ordersItemsDetails.reduce(
    (previousValue, currentValue) => {
      const quantity = isPendingOrCancelled(currentValue.orderStatus)
        ? currentValue.orderedQuantity
        : currentValue.deliveredQuantity
      return previousValue + Number(currentValue.mrp) * quantity
    },
    0
  )
  const subTotalDiscount = ordersItemsDetails.reduce(
    (previousValue, currentValue) => {
      const quantity = isPendingOrCancelled(currentValue.orderStatus)
        ? currentValue.orderedQuantity
        : currentValue.deliveredQuantity
      return previousValue + Number(currentValue.discount) * quantity
    },
    0
  )
  const subTotalAmount = ordersItemsDetails.reduce(
    (previousValue, currentValue) => {
      const quantity = isPendingOrCancelled(currentValue.orderStatus)
        ? currentValue.orderedQuantity
        : currentValue.deliveredQuantity
      const itemAmount =
        (Number(currentValue.mrp) - Number(currentValue.discount)) * quantity
      return previousValue + itemAmount
    },
    0
  )

  const vouchers = discounts.filter(
    (item) => item.type === 'DISCOUNT_TYPE_VOUCHER'
  )
  const offers = discounts.filter((item) => item.type === 'DISCOUNT_TYPE_OFFER')

  const { pendingAmount, pendingAmountText } = getCalculatedSummaryValues(
    { payments, totalAmount },
    'saleOrder'
  )

  return (
    <div className="order-list-items-container">
      <div className="section-title flex-around">
        <h3>
          {getMessage('order.details.itemsTable.title')}
          <span className="text-muted" data-testid="total-items-count"> ({totalItemsCount})</span>
        </h3>
      </div>

      <Table>
        <Header>
          <Cell className="item-image">
            {getMessage('order.details.itemsTable.header.image')}
          </Cell>
          <Cell className="item-name">
            {getMessage('order.details.itemsTable.header.name')}
          </Cell>
          <Cell>
            {getMessage('order.details.itemsTable.header.clientItemId')}
          </Cell>
          <Cell className="text-right">
            {getMessage('order.details.itemsTable.header.quantity')}
          </Cell>
          <Cell className="text-right">
            {getMessage('order.details.itemsTable.header.picked')}
          </Cell>
          <Cell className=" text-right">
            {getMessage('order.details.itemsTable.header.mrp')}
          </Cell>
          <Cell className="text-right">
            {getMessage('order.details.itemsTable.header.discount')}
          </Cell>
          <Cell className="text-right">
            {getMessage('order.details.itemsTable.header.amount')}
          </Cell>
        </Header>
        {orders.map((order) => (
          <SingleOrderView
            key={order.id}
            order={order}
            currency={currency}
            isPendingOrCancelled={isPendingOrCancelled}
          />
        ))}
        <Row className="black-text">
          <Cell />
          <Cell />
          <Cell />
          <Cell />
          <Cell className="text-right">
            <small>{getMessage('saleOrder.details.subtotal')}</small>
          </Cell>
          <Cell className="text-right">
            <small>
              {currency.symbol} {subTotalPrice.toFixed(2)}
            </small>
          </Cell>
          <Cell className="text-right">
            <small>
              {currency.symbol} {subTotalDiscount.toFixed(2)}
            </small>
          </Cell>
          <Cell className="text-right">
            <small>
              {currency.symbol} {subTotalAmount.toFixed(2)}
            </small>
          </Cell>
        </Row>
      </Table>
      <div className="invoice-summary-wrapper">
        <div className="invoice-summary">
          {vouchers.length > 0 && (
            <GroupedDiscounts
              items={vouchers}
              text="saleOrder.details.summary.vouchers"
              currency={currency}
            />
          )}
          {offers.length > 0 && (
            <GroupedDiscounts
              items={offers}
              text="saleOrder.details.summary.offers"
              currency={currency}
            />
          )}
          <ShowAmounts
            clName="small-size"
            text="order.details.summary.shippingCharge"
            testId="sale-order-shipping-charge"
            value={totalShippingCharge.toFixed(2)}
            currency={currency}
          />
          <ShowAmounts
            clName="small-size"
            text="saleOrder.details.summary.serviceFee"
            testId="sale-order-service-fee"
            value={standardServiceFee.toFixed(2)}
            currency={currency}
          />
          <ShowAmounts
            text="order.details.summary.totalAmount"
            testId="sale-order-total-amount"
            value={totalAmount.toFixed(2)}
            currency={currency}
          />
          <ShowAmounts
            clName="pending-amount"
            text="order.details.summary.pendingAmount"
            testId="sale-order-pending-amount"
            value={pendingAmount}
            currency={currency}
          />
          {pendingAmountText && (
            <div
              className="text-muted"
              style={{ fontSize: '0.8rem', marginTop: 0, textAlign: 'right' }}
            >
              ({pendingAmountText})
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrdersListView
