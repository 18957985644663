import React, { useState, useEffect } from 'react'
import { Select } from 'components/Form'
import { getMessage } from 'lib/translator'
import ModuleForm from './ModuleForm'
import ModuleTypeForm from './ModuleTypeForm'
import DeleteModule from './DeleteModule'
import ModuleList from './ModuleList'
import Loader from 'components/Loader'
import Navigator from './Navigator'
import { PAGE, POPUP_HEADING } from './utils'
import { Popup } from 'components/Popup'
import API from 'lib/api'

const LAYOUT_OPTIONS = [
  { text: 'Default', value: 'Default' },
  { text: 'ScanFairprice', value: 'ScanFairprice' },
  { text: 'ScanKopitiam', value: 'ScanKopitiam' },
]

const LayoutForm = () => {
  const [popUp, setPopUp] = useState(null)
  const [view, setView] = useState(PAGE[0])
  const [isLoading, setIsLoading] = useState(false)
  const [layout, setLayout] = useState('Default')
  const [moduleType, setModuleType] = useState(null)
  const [cacheValues, setCacheValues] = useState(null)
  const [moduleValues, setModuleValues] = useState({})
  const [activeModule, setActiveModule] = useState(null)

  useEffect(() => {
    setActiveModule(null)
    setModuleType(null)
    if (cacheValues === null) {
      setIsLoading(true)
      new API({ url: `/genie/lms/profile/module?omni_profile=${layout}` })
        .get()
        .then((response) => {
          const values = response.reduce((accumulator, item, index) => {
            accumulator[index] = item
            return accumulator
          }, {})
          setModuleValues(values)
          setCacheValues(values)
          setIsLoading(false)
        })
    }
  }, [cacheValues])

  const cancelDraft = () => {
    if (view === PAGE[2]) {
      setModuleValues(cacheValues)
    }
    setView(PAGE[0])
  }

  const updateModulePosition = () => {
    setIsLoading(true)
    setView(PAGE[0])
    const data = Object.values(moduleValues).map((val) => {
      return {
        ModulePK: val.id,
        'm-Position': val.mPosition,
      }
    })
    new API({ url: `/genie/lms/module/position` }).patch(data).then(() => {
      setCacheValues(null)
    })
  }

  const toggleModuleStatus = (key) => {
    new API({
      url: `/genie/lms/module/enable?enable=${!moduleValues[key]
        .mIsEnable}&module_pk=${moduleValues[key].id}`,
    })
      .patch()
      .then(() => {
        setCacheValues(null)
      })
  }

  const getPopupHeading = () => {
    if (popUp === 'module-form') {
      return activeModule === null
        ? POPUP_HEADING['create-module']
        : POPUP_HEADING['edit-module']
    }
    return POPUP_HEADING[popUp]
  }

  const popUpComponents = {
    'module-form': (
      <ModuleForm
        setPopUp={setPopUp}
        activeModule={activeModule}
        moduleValues={moduleValues}
        setActiveModule={setActiveModule}
        moduleType={moduleType}
        setCacheValues={setCacheValues}
        layout={layout}
        setIsLoading={setIsLoading}
      />
    ),
    'module-type': (
      <ModuleTypeForm setPopUp={setPopUp} setModuleType={setModuleType} />
    ),
  }

  return (
    <div className="layout-wrapper">
      <div className="selector-buttons-section">
        <div className="selector-section">
          <Select
            name="select-layout"
            placeholder={getMessage(
              'app.layout.module.selectLayout.placeholder'
            )}
            options={LAYOUT_OPTIONS}
            value={layout}
            onChange={(e) => {
              setLayout(e)
              setView(PAGE[0])
              setCacheValues(null)
            }}
          />
        </div>
        <Navigator
          view={view}
          cancelDraft={cancelDraft}
          moduleValues={moduleValues}
          updateModulePosition={updateModulePosition}
        />
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <ModuleList
          moduleValues={moduleValues}
          setPopUp={setPopUp}
          setModuleValues={setModuleValues}
          setActiveModule={setActiveModule}
          toggleModuleStatus={toggleModuleStatus}
          setView={setView}
          view={view}
        />
      )}

      {view !== PAGE[2] && !isLoading && (
        <div className="add-module-section">
          <button
            type="button"
            className="button applayout-hover-button"
            data-testid="add-module-button"
            onClick={() => setPopUp('module-type')}
          >
            {getMessage('app.layout.module.add')}
          </button>
        </div>
      )}

      {popUp &&
        (popUp !== 'delete-module' ? (
          <Popup
            show
            heading={getPopupHeading()}
            close={() => {
              setPopUp(null)
              if (popUp === 'module-form') {
                setActiveModule(null)
              }
            }}
            className="module-popup"
          >
            {popUpComponents[popUp]}
          </Popup>
        ) : (
          <DeleteModule
            setPopUp={setPopUp}
            moduleValues={moduleValues}
            setModuleValues={setModuleValues}
            activeModule={activeModule}
            setActiveModule={setActiveModule}
            setCacheValues={setCacheValues}
            setIsLoading={setIsLoading}
          />
        ))}
    </div>
  )
}

export default LayoutForm
