import React, { Fragment } from 'react'
import { MultiSelect } from '../../Form'
import { getMessage } from '../../../lib/translator'
import { RULE_KEYS } from '../utils'
import moment from 'moment'

const StoresApplicable = (props) => {
  const { _this, method, taskIndex, task } = props

  return (
    <Fragment>
      <br />
      <div className="task-stores-applicable mobile-768">
        <MultiSelect
          className="task-channel"
          label={getMessage('omnichallenge.task.storesApplicable')}
          placeholder={getMessage(
            'omnichallenge.task.storesApplicable.placeholder'
          )}
          options={_this.state?.storesOptions}
          {..._this.generateStateMappers({
            stateKeys: [
              'taskList',
              taskIndex,
              'rule',
              RULE_KEYS[task.userAction][0],
              'clientStoreIds',
            ],
            loseEmphasisOnFill: true,
          })}
          readOnly={
            method === 'edit' && moment().isAfter(_this.state.values.startAt)
          }
        />
      </div>
    </Fragment>
  )
}

export default StoresApplicable
