const data = {
  'login.heading': 'Welcome to FPG Backoffice',
  'login.subheading': 'Enter your details below',
  'login.buttonText': 'Login',
  'login.email.heading': 'Email address',
  'login.email.placeholder': 'Enter your email address',
  'login.username.heading': 'Username',
  'login.username.placeholder': 'Username',
  'login.organisation.heading': 'Organisation',
  'login.organisation.placeholder': 'Organisation',
  'login.password.heading': 'Password',
  'login.password.placeholder': 'Password',
  'login.remember': 'Remember me',
  'login.forgotPassword': 'Forgot Password',
  'login.submitText': 'Login',
  'login.newUser.message': "Don't have an account?",
  'login.newUser.signupText': 'Create Account',
}

export default data
