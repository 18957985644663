import React, { Component } from 'react'
import Papa from 'papaparse'
import moment from 'moment'
import partial from 'lodash/partial'

import Table, { Header, Row, Cell } from '../../../../components/Table'
import Upload from '../../../../components/FileUpload'
import {
  Checkbox,
  SingleDatePicker,
  TimePicker,
} from '../../../../components/Form'
import Loader from '../../../../components/Loader'
import API from '../../../../lib/api'
import { getSession } from '../../../../lib/auth'
import { Link } from 'react-router-dom'
import csvIcon from './csv-icon.svg'
import uploadIcon from './upload-products.svg'
import { getMessage } from '../../../../lib/translator'

import './style.css'

const SECONDARY_CATEGORY_ACTIONS = { ADD: 'A', DELETE: 'D', OVERWRITE: 'O' }

const CSV_HEADERS = {
  SKU: 'sku',
  PRIMARY_CATEGORY_ID: 'primary_category_id',
  SECONDARY_CATEGORY_IDS: 'secondary_category_ids',
  SECONDARY_CATEGORY_ACTION: 'secondary_category_action',
}

const getHeaders = () => [
  getMessage('category.bulk-upload.columns.sku'),
  getMessage('category.bulk-upload.columns.productName'),
  getMessage('category.bulk-upload.columns.category.primary'),
  getMessage('category.bulk-upload.columns.category.secondary'),
  getMessage('category.bulk-upload.columns.category.secondary.action'),
]

function downloadTemplate(data) {
  const csv = `${data.join(',')}\n`
  var hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  hiddenElement.target = '_blank'
  hiddenElement.download = 'sample-product-category.csv'
  hiddenElement.click()
}

const isCsvHeadersInvalid = csvHeaders => {
  const trimmedCsvHeaders = csvHeaders
    .map(h => h.replace(/ /g, ''))
    .slice(0, Object.values(CSV_HEADERS).length) // TODO: csv template can have more columns?
  const isHeadersValid = trimmedCsvHeaders.every(
    h => !h || Object.values(CSV_HEADERS).includes(h.toLowerCase())
  )

  return !isHeadersValid
}

const isInvalidCsv = results => {
  const csvHeaders = results[0]
  const isStructureInvalid = results
    .slice(1)
    .some(row => row.length !== csvHeaders.length)

  return isCsvHeadersInvalid(csvHeaders) || isStructureInvalid
}

const getCategoryHierarchy = category => {
  if (!category.parentCategory) {
    return category.name
  }
  return getCategoryHierarchy(category.parentCategory) + ' > ' + category.name
}

const buildCategoryMapping = categories => {
  const categoryMap = {}

  categories.forEach(category => {
    categoryMap[category.id] = getCategoryHierarchy(category)
  })
  return categoryMap
}

const buildProductNameMap = products => {
  const productMap = {}

  products.forEach(product => {
    productMap[product.clientItemId] = {
      name: product.name,
      displayUnit: product.metaData ? product.metaData.DisplayUnit : '',
    }
  })
  return productMap
}

const getIndex = (headers, header) => {
  return headers
    .map(h => h.toUpperCase().replace(/ /g, ''))
    .indexOf(header.toUpperCase())
}

const getTableData = (results, categories, products) => {
  if (results.length === 0) {
    return []
  }
  const csvHeaders = results[0]
  const getIndexPartial = partial(getIndex, csvHeaders)
  const skuIndex = getIndexPartial(CSV_HEADERS.SKU)
  const primaryCategoryIdx = getIndexPartial(CSV_HEADERS.PRIMARY_CATEGORY_ID)
  const secondaryCategoryIdx = getIndexPartial(
    CSV_HEADERS.SECONDARY_CATEGORY_IDS
  )
  const secCategoryActionIdx = getIndexPartial(
    CSV_HEADERS.SECONDARY_CATEGORY_ACTION
  )

  const rows = results.slice(1)
  const categoryMap = buildCategoryMapping(categories)
  const productMap = buildProductNameMap(products)

  try {
    const tableData = rows.map(row => {
      const product = productMap[row[skuIndex]] || {}
      return {
        sku: row[skuIndex],
        productName: product.name,
        displayUnit: product.displayUnit,
        primaryCategory: {
          id: row[primaryCategoryIdx],
          name: categoryMap[row[primaryCategoryIdx]],
        },
        secondaryCategory: row[secondaryCategoryIdx]
          ? row[secondaryCategoryIdx]
              .replace(/ /g, '')
              .split(',')
              .map(id => ({ id, name: categoryMap[id] }))
          : [],
        secCategoryAction: row[secCategoryActionIdx],
      }
    })
    return tableData
  } catch (e) {
    console.error('Error while generating review data', e)
    return []
  }
}

export default class ProductUpload extends Component {
  constructor() {
    super()
    this.state = {
      file: '',
      isScheduled: false,
      isFileParsed: false,
      isFileUploaded: false,
      results: [],
      categories: [],
      products: [],
      error: null,
    }
    this.handleUpload = this.handleUpload.bind(this)
    this.handleResults = this.handleResults.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  componentDidMount() {
    this.getCategories()
  }

  transformSubmit(data) {
    const userId = getSession().user.id
    if (
      this.state.isScheduled &&
      this.state.scheduleDate &&
      this.state.scheduleTime
    ) {
      let dateTime = moment().add(5, 'minutes')
      const selectedDateTime = moment(
        this.state.scheduleDate + ' ' + this.state.scheduleTime
      )
      dateTime = selectedDateTime > dateTime ? selectedDateTime : dateTime
      data.append('scheduledAt', dateTime.format('YYYY-MM-DD HH:mm:ss'))
    }
    data.append('jobName', 'SKU_CATEGORY_MAPPING')
    data.append('userID', userId)
    return data
  }

  getCategories() {
    const categoryApi = new API({ url: '/catalogue-service/category' })
    categoryApi.get({ paginate: 'false', hierarchy: false }).then(
      response => {
        this.setState({
          categories: response.data.category || [],
        })
      },
      error => {
        this.setState({
          error,
        })
      }
    )
  }

  getProducts(skus) {
    const productApi = new API({
      url: `/catalogue-service/product`,
    })
    this.setState({
      loading: true,
    })
    productApi.get({ paginate: 'false', clientId: skus.slice() }).then(
      response => {
        this.setState({
          products: response.data.product || [],
          loading: false,
        })
      },
      error => {
        this.setState({
          error,
          loading: false,
        })
      }
    )
  }

  handleResults(results) {
    const skus = results.slice(1).map(row => row[0])
    this.getProducts(skus)
    this.setState({
      isFileParsed: true,
      fileUploadError: {},
      results: results,
    })
  }

  handleCancel() {
    this.setState({
      isFileParsed: false,
      error: null,
      results: [],
      file: '',
    })
  }

  handleSubmit() {
    const { file } = this.state
    let data = new window.FormData()
    data.append('uploadfile', file)
    data = this.transformSubmit(data)
    const api = new API({ url: '/catalogue-service/product-attribute-upload' })
    this.setState({
      loading: true,
    })
    api.post(data).then(
      () => {
        this.setState({
          fileUploading: false,
          isFileUploaded: true,
          error: null,
          loading: false,
        })
      },
      error => {
        this.setState({
          file: '',
          error: error,
          fileUploading: false,
          isFileUploaded: false,
          loading: false,
        })
      }
    )
  }

  handleUpload(file) {
    const handleResults = this.handleResults
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: results => {
          const filteredResults = results.data.filter(
            row => !row.every(el => !el.trim())
          )
          if (isInvalidCsv(filteredResults)) {
            reject(new Error('Invalid CSV! Columns/rows mismatch.'))
          } else {
            resolve()
            this.setState({
              file,
            })
            setTimeout(() => handleResults(filteredResults), 100)
          }
        },
        error: e => {
          reject(e)
        },
      })
    })
  }

  isCategoriesInvalid(categories) {
    return categories.some(category => category.id && !category.name)
  }

  validateTableData(tableData) {
    return tableData.map(row => {
      const noSku = !row.sku
      const noProductName = !row.productName
      const noCategoryIds =
        !row.primaryCategory.id &&
        (!row.secondaryCategory.length ||
          row.secondaryCategory.some(cat => !cat.id))
      const isInvalidPrimaryCategory = this.isCategoriesInvalid([
        row.primaryCategory,
      ])
      const isInvalidSecondaryCategory = this.isCategoriesInvalid(
        row.secondaryCategory
      )
      const isInvalidAction =
        !!row.secCategoryAction &&
        !Object.values(SECONDARY_CATEGORY_ACTIONS).includes(
          row.secCategoryAction
        )
      return (
        noSku ||
        noProductName ||
        noCategoryIds ||
        isInvalidPrimaryCategory ||
        isInvalidSecondaryCategory ||
        isInvalidAction
      )
    })
  }

  handleSampleDownload(e) {
    e.preventDefault()
    downloadTemplate(Object.values(CSV_HEADERS))
  }

  render() {
    const { hideAddButton } = this.props
    const {
      isFileParsed,
      isFileUploaded,
      results,
      categories,
      products,
      error,
      loading,
      file,
    } = this.state
    const tableData = getTableData(results, categories, products)
    const validationErrors = this.validateTableData(tableData)
    return isFileUploaded || !isFileParsed ? (
      <div className="product-upload">
        {!hideAddButton ? (
          <div className="text-center">
            <Link
              to="/catalogue/products/add"
              className="button primary add-product-link"
            >
              + {getMessage('product.add.text')}
            </Link>
          </div>
        ) : null}
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          {!hideAddButton ? (
            <span className="border-text">
              {getMessage('productUpload.orText')}
            </span>
          ) : null}
          <Upload
            name="bulkProductUpload"
            accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
            placeholder={getMessage('category.bulk-upload')}
            uploadButtonText={getMessage('category.form.review')}
            cancelText={getMessage('category.form.cancelText')}
            onUpload={this.handleUpload}
            validationStrings={{
              invalidFileType: getMessage('input.invalidFileType'),
            }}
            strings={{
              defaultMessage: getMessage('fileUpload.importCsv.heading'),
              progressMessage: getMessage('fileUpload.importedCsv.heading'),
              completionMessage: getMessage('fileUpload.uploadedCsv.success'),
            }}
            icon={csvIcon}
            uploadedFile={file}
          />
        </div>
        <div className="text-center download-sample-text">
          <a className="download-link" onClick={this.handleSampleDownload}>
            {getMessage('productUpload.csvDownloadText')}
          </a>
        </div>
        {!isFileUploaded && (
          <React.Fragment>
            <div className="text-center schedule-checkbox">
              <Checkbox
                className="entity-checkbox"
                inlineLabel={`Schedule later`}
                name={`isScheduled`}
                value={this.state.isScheduled}
                onChange={() => {
                  this.setState({ isScheduled: !this.state.isScheduled })
                }}
              />
            </div>
            {this.state.isScheduled && (
              <div className="dateTimeSelect">
                <SingleDatePicker
                  enableToday
                  name="scheduleDate"
                  key="scheduleDate"
                  label={getMessage('Select Date')}
                  value={this.state.scheduleDate}
                  onChange={v => this.setState({ scheduleDate: v })}
                />
                <TimePicker
                  name="scheduleTime"
                  key="scheduleTime"
                  label={getMessage('Select Time')}
                  placeholder={getMessage('offer.time.placeholder')}
                  value={this.state.scheduleTime}
                  onChange={v => this.setState({ scheduleTime: v })}
                  showSecond={false}
                  minuteStep={15}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    ) : loading ? (
      <div className="loader-box">
        <Loader />
      </div>
    ) : (
      <div>
        {error && <div className="error">{getMessage('error.server')}</div>}
        {!loading && validationErrors.some(Boolean) && (
          <div className="error">
            {getMessage('category.bulk-upload.error')}
          </div>
        )}
        <div className="table-container">
          <Table tableDynamic={false}>
            <Header items={getHeaders()} />
            {tableData.map((row, index) => (
              <Row
                key={`${row.sku}-${index}`}
                className={
                  !loading && validationErrors[index] ? 'row-error' : ''
                }
              >
                <Cell>
                  <span className="sku">{row.sku}</span>
                </Cell>
                <Cell>
                  <span className="product-name">{row.productName}</span>
                  {` `}
                  <span className="display-unit muted">{row.displayUnit}</span>
                </Cell>
                <Cell>
                  <span size="md" className="category-id">
                    {row.primaryCategory.id}
                  </span>
                  {row.primaryCategory.id && ':'}{' '}
                  <span size="md" className="category-name">
                    {row.primaryCategory.name}
                  </span>
                </Cell>
                <Cell>
                  {row.secondaryCategory.map(cat => {
                    return (
                      <React.Fragment key={cat.id}>
                        <span className="category-id">{cat.id}</span>
                        {cat.id && ':'}{' '}
                        <span className="category-name">{cat.name}</span> <br />
                      </React.Fragment>
                    )
                  })}
                </Cell>
                <Cell>
                  <span className={`category-action ${row.secCategoryAction}`}>
                    {row.secCategoryAction}
                  </span>
                </Cell>
              </Row>
            ))}
          </Table>
        </div>
        <div className="actions">
          <button className="button" onClick={this.handleCancel}>
            {getMessage('category.form.cancelText')}
          </button>
          <button
            disabled={validationErrors.some(Boolean)}
            className="primary button"
            onClick={this.handleSubmit}
          >
            {getMessage('category.form.upload')}
          </button>
        </div>
      </div>
    )
  }
}
