import React from 'react'
import { Row, Cell } from '../../Table'
import { formatOrderRef } from '../../../pages/operations/Customers/Details/RewardsTab/rewardsUtils'

export const formatWinnerPositions = winners => {
  return winners.includes(',')
    ? winners.split(',').map(x => Number(x))
    : [Number(winners)]
}

export const verifyInput = winner => {
  return new RegExp(/^\s*\d+(?:\s*,\s*\d+)*\s*$/).test(winner)
}

export const tableRow = (
  drawAt,
  {
    index,
    name,
    orderRef,
    uid,
    customerId,
    chancesEarned,
    status,
    channel,
    cardNumber,
  }
) => {
  const customerName = uid ? (
    <a href={`/customer-support/customers/view/${customerId}`}>{name}</a>
  ) : (
    name
  )
  const formattedOrderRef = formatOrderRef(orderRef, channel)
  return (
    <Row>
      <Cell className="rewards-page-actions id">{index + 1}</Cell>
      <Cell className="rewards-page-actions name">{customerName}</Cell>
      <Cell className="rewards-page-actions channel row">{channel}</Cell>
      <Cell className="rewards-page-actions cardNumber row">{cardNumber}</Cell>
      <Cell className="rewards-page-actions order row">
        {formattedOrderRef}
      </Cell>
      <Cell className="rewards-page-actions total row">{chancesEarned}</Cell>
      <Cell className="rewards-page-actions row date">{drawAt}</Cell>
      <Cell className={`rewards-page-actions status ${status.toLowerCase()}`}>
        {status}
      </Cell>
    </Row>
  )
}
export const tableProperties = drawAt => {
  return {
    headers: [
      'No.',
      'Name',
      'Channel',
      'Card number',
      'Order Reference no.',
      'Total Chances Earned',
      'Drawn Date & Time',
      'Status',
    ],
    row: response => tableRow(drawAt, response),
  }
}

export const emptyState = {
  message: 'No draws yet',
}
