import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import List from './List'
import Details from './Details'

import './style.css'

class EGiftEntitiesRouter extends Component {
  render() {
    const { router, ...props } = this.props
    const details = { ...props }
    details.url = '/customer-support'
    details.className = 'cx-listing'
    if (router.match.params.id && router?.match.params.action === 'view') {
      const detailsOrder = { ...this.props }
      detailsOrder.url = '/customer-support'
      return <Details {...detailsOrder} />
    }
    return <List {...this.props} />
  }
}

export default withRouter(({ match, ...props }) => (
  <EGiftEntitiesRouter router={{ match }} {...props} />
))
