import React, { useCallback, useMemo, useState } from 'react'
import {
  Checkbox,
  Input,
  Select,
  SelectSearch,
  Textarea,
  Toggle,
  Upload,
} from '../../../../components/Form'
import { SearchCoordForm } from './SearchCoordinate'
import { StoreMapLocator } from './StoreMapLocator'
import find from 'lodash.find'
import { storeSelection } from '../../../../lib/commonlyused'
import Table, { Cell, Header, Row } from '../../../../components/Table'
import {
  countErrors,
  DEFAULT_EMPTY_HOURS,
  DYNAMIC_FIELD,
  HOURS_PLACEHOLDER,
  HOURS_STRING_PATTERN,
  STORE_TYPES,
  transformSubmit,
  useAutoScrollToTop,
  useCreateForm,
  useScrollToFormError,
  useSetupForm,
} from './hook-utils'
import isEmpty from 'lodash.isempty'
import { StoreMetadata } from './StoreMetadatas'
import { StoreChangesModal } from './StoreChangesModal'
import API from '../../../../lib/api'

export const StoreConfigurationForm = ({
  store,
  tier,
  metaData,
  action,
  onCancel,
  onSubmitDone,
}) => {
  const [attemptSubmit, setAttemptSubmit] = useState(false)

  /**
   * Similar to fomik isSubmitting but this is use for showing loader
   * when the actual submit API is calling and in progress.
   * Please see comments on _handleSubmit()
   */
  const [isSubmitting, setSubmitting] = useState(false)

  const userTier = useMemo(() => {
    return tier.filter((itemTiersUser) => itemTiersUser.userSegment !== 'B2B')
  }, [tier])

  const b2bTier = useMemo(() => {
    return tier.filter((itemTierB2b) => itemTierB2b.userSegment === 'B2B')
  }, [tier])

  const storeOptions = storeSelection()
  useAutoScrollToTop()

  const formik = useCreateForm(action, setAttemptSubmit)
  useSetupForm(store, formik)

  useScrollToFormError(formik.isSubmitting)

  const showFieldErrors = useCallback(
    (field) => {
      /**
       * To handle address, since its a readonly input,
       * error may still show even after user have set the address,
       * workaround is to manually skip the address error from formik
       * by deleting it
       */
      if (field === 'address') {
        if (formik.values.address) {
          delete formik.errors.address
        }
      }
      /**
       * Only for edit existing store, should show error
       * upon rendering
       */
      if (action === 'edit' && !DYNAMIC_FIELD.includes(field)) {
        return true
      }
      let showError = false
      if (formik.submitCount > 0 || formik.touched[field]) {
        showError = Boolean(formik.errors[field])
      }

      return showError
    },
    [formik, action]
  )

  const getDefaultTier = useCallback(
    (isB2B) => {
      if (store !== null) {
        const target = find(tier, {
          value: isB2B ? store.b2bTierSetupId : store.tierSetupId,
        })
        if (target) {
          return {
            value: target.value,
            text: target.text,
          }
        }
      }
      return undefined
    },
    [tier, store]
  )

  const getDefaultPickingStore = useCallback(() => {
    const target = find(storeOptions, { value: formik.values.pickingStoreId })
    if (target) {
      return {
        value: target.value,
        text: target.text,
      }
    } else {
      /**
       * Remove it by force, to ensure user always pick the only available
       * pickingStore from storeOptions. if unknown pickingStoreId received
       * via API, we reject it and treat as unset input value.
       *
       * manual remove instead of using formik.setFieldValue, to avoid circular
       * invokation (infinite rerendering)
       */
      delete formik.values.pickingStoreId
      return undefined
    }
  }, [formik.values, storeOptions])

  const businessHoursError = useCallback(
    (day, opening) => {
      const { businessHours } = formik.errors
      if (businessHours && businessHours[day]) {
        const error = businessHours[day]?.[opening ? 'o' : 'c']
        if (error) {
          return error
        }
      }
      return undefined
    },
    [formik.errors]
  )

  const sngOperationHoursError = useCallback(
    (day, opening) => {
      const { meta_sngOperationalHours } = formik.errors
      if (meta_sngOperationalHours && meta_sngOperationalHours[day]) {
        const error = meta_sngOperationalHours[day]?.[opening ? 'o' : 'c']
        if (error) {
          return error
        }
      }
      return undefined
    },
    [formik.errors]
  )

  const getAlwaysOpenChecked = useCallback(
    ({ hourSection, day }) => {
      return (
        formik.values[hourSection] &&
        formik.values[hourSection][day] &&
        formik.values[hourSection][day].c === '23:59:59' &&
        formik.values[hourSection][day].o === '00:00:00'
      )
    },
    [formik]
  )

  const onAlwaysOpenCheckedChanged = useCallback(
    ({ hourSection, day, value }) => {
      const objectHours = formik.values[hourSection]
      if (value) {
        objectHours[day] = {
          o: '00:00:00',
          c: '23:59:59',
        }
      } else {
        objectHours[day] = {
          o: '',
          c: '',
        }
      }
      formik.setFieldValue(hourSection, { ...objectHours })
    },
    [formik]
  )

  const handleHoursChange = useCallback(
    ({ hourSection, type, day, value }) => {
      let objHours
      if (hourSection === 'businessHours') {
        objHours = { ...formik.values.businessHours }
      } else {
        objHours = { ...formik.values.meta_sngOperationalHours }
      }

      if (objHours) {
        const dayObject = { ...objHours[day] }
        objHours[day] = {
          ...dayObject,
          [type === 'opening' ? 'o' : 'c']: value,
        }
        formik.setFieldValue(hourSection, objHours)
      }
    },
    [formik.values]
  )

  const getHourValue = useCallback(
    ({ hourSection, type, day }) => {
      let targetHourObject = formik.values[hourSection]

      if (
        targetHourObject === undefined ||
        targetHourObject[day] === undefined
      ) {
        targetHourObject = { ...DEFAULT_EMPTY_HOURS }
      }
      const dayObject = targetHourObject[day]
      return type === 'opening' ? dayObject.o : dayObject.c
    },
    [formik.values]
  )

  const numberOfTotalErrors = useMemo(() => {
    if (isEmpty(formik.errors)) {
      return 0
    }
    return countErrors(formik.errors)
  }, [formik.errors])

  const onAddressSubmit = useCallback(
    (address, coords) => {
      formik.setValues({
        ...formik.values,
        address: address,
        latitude: coords.lat,
        longitude: coords.lng,
      })
    },
    [formik]
  )

  const onPlacedChanged = useCallback(
    (loc) => {
      formik.setValues({
        ...formik.values,
        address: loc.address,
        latitude: loc.location.lat,
        longitude: loc.location.lng,
      })
    },
    [formik]
  )

  const onHasPickingChanged = useCallback(
    (checked) => {
      formik.setValues({
        ...formik.values,
        hasPicking: checked,
      })
    },
    [formik]
  )

  const onSngEnabledChanged = useCallback(
    (checked) => {
      formik.setValues({ ...formik.values, meta_isSNG: checked })
    },
    [formik]
  )

  const getAlwaysOpenDaily = useCallback(
    (hourSection) => {
      const objectHours = formik.values[hourSection]

      let notEqualCount = 0
      if (objectHours) {
        Object.keys(DEFAULT_EMPTY_HOURS).forEach((day) => {
          if (objectHours[day]) {
            if (
              objectHours[day].c !== '23:59:59' ||
              objectHours[day].o !== '00:00:00'
            ) {
              notEqualCount++
            }
          } else {
            notEqualCount++
          }
        })
      }
      return notEqualCount === 0
    },
    [formik]
  )

  const onAlwaysOpenDailyChanged = useCallback(
    ({ hourSection, value }) => {
      if (value) {
        const objHours = {}

        for (const day in DEFAULT_EMPTY_HOURS) {
          objHours[day] = {
            c: '23:59:59',
            o: '00:00:00',
          }
        }

        formik.setFieldValue(hourSection, { ...objHours })
      } else {
        formik.setFieldValue(hourSection, { ...DEFAULT_EMPTY_HOURS })
      }
    },
    [formik]
  )

  /**
   * formik.handleSubmit is not used for actual api call as we use to
   * to only run validation to the form.. the handleSubmit from formik
   * will only update atemptSubmit local state as a flag to open diff popover window.
   *  if passed, will show the diff
   * popover window. The submit button on that popover will then call
   * this function to do the actual api POST | PUT call.
   *
   * This func will only call from the
   * popover component, when user click on submit button.
   */
  const _handleSubmit = useCallback(() => {
    setSubmitting(true)
    const formData = transformSubmit(formik.values, metaData, action)
    const url = '/account-service/store'
    const method = action === 'new' ? 'post' : 'put'
    const api = new API({
      url: url + (action !== 'new' ? '/' + store.id : ''),
    })
    api[method](formData).then(
      (_) => {
        setSubmitting(false)
        onSubmitDone()
      },
      (error) => {
        throw new Error(error)
      }
    )
  }, [formik.values, metaData, action])

  return (
    <form data-testid="store-config-form" onSubmit={formik.handleSubmit}>
      <div className="store-configuration-form">
        <div className="row">
          <div className="field store-name">
            <Input
              label="Store Name"
              name="name"
              placeholder="store name"
              value={formik.values.name}
              onChange={(val) => formik.setFieldValue('name', val)}
              maxLength={45}
              error={formik.errors.name}
              showErrors={showFieldErrors('name')}
            />
          </div>
          <div className="field store-type">
            <Select
              label="Store Type"
              name="type"
              options={STORE_TYPES}
              className={'enum'}
              placeholder="N/A"
              value={formik.values.type}
              onChange={(val) => formik.setFieldValue('type', val)}
              error={formik.errors.type}
              showErrors={showFieldErrors('type')}
            />
          </div>
        </div>
        {(action === 'new' || formik.values.clientId) && (
          <div className="row">
            <div className="field">
              <Input
                label="Client ID / SAP ID"
                readOnly={action !== 'new'}
                name="clientId"
                type="text"
                value={formik.values.clientId}
                onChange={(value) => formik.setFieldValue('clientId', value)}
                error={formik.errors.clientId}
                showErrors={showFieldErrors('clientId')}
              />
            </div>
            <div className="field" />
          </div>
        )}
        <div className="row">
          <div className="field store-address">
            <span className="labelWrap address-label">
              <label htmlFor={'store name'} className="thin">
                Store Address
              </label>
            </span>
            <Textarea
              name="address"
              type="text"
              value={formik.values.address}
              readOnly={true}
              error={formik.errors.address}
              showErrors={showFieldErrors('address')}
            />
          </div>
        </div>
        <div className="row coord-button">
          <SearchCoordForm
            onAddressSubmit={onAddressSubmit}
            defaultCoord={{ lat: 12.9178614, lng: 77.6449406 }}
          />
        </div>

        <div className="row">
          <StoreMapLocator
            initialLocation={{
              lat: formik.values.latitude ?? 0,
              lng: formik.values.longitude ?? 0,
            }}
            onPlaceChanged={onPlacedChanged}
          />
        </div>
        <div className="row upload">
          <Upload
            label="Store Image"
            placeholder="Upload image"
            onChange={(val) => formik.setFieldValue('storeImgUrl', val)}
          />
        </div>

        <h2>Setup</h2>
        <div className="row">
          {action !== 'new' && (
            <div className="field" data-testid="store-enable-toggle">
              <Toggle
                id="store-toggle"
                name="store-toggle"
                className="sng-toggle"
                label="Store Enabled"
                value={formik.values.status === 'ENABLED'}
                onChange={(value) =>
                  formik.setFieldValue('status', value ? 'ENABLED' : 'DISABLED')
                }
                icons={false}
              />
            </div>
          )}

          <div className="field" data-testid="search-n-browse-toggle">
            <Toggle
              name="search-n-browse"
              className="sng-toggle"
              label="Search And Browse Enabled"
              value={formik.values.meta_searchNBrowseEnabled}
              onChange={(value) =>
                formik.setFieldValue('meta_searchNBrowseEnabled', value)
              }
              icons={false}
            />
          </div>
          <div className="field" />
        </div>
        <div className="row">
          <Checkbox
            name="hasPicking"
            label="Has Picking ?"
            controlled
            value={formik.values.hasPicking}
            defaultChecked={true}
            onChange={onHasPickingChanged}
          />
          <Checkbox
            name="deliveryHub"
            label="Has Delivery Hub ?"
            controlled
            value={formik.values.hasDeliveryHub}
            onChange={(val) => formik.setFieldValue('hasDeliveryHub', val)}
          />
          <Checkbox
            name="hasClickCollect"
            label={'Is this a collection center?'}
            controlled
            value={formik.values.hasClickCollect}
            onChange={(val) => formik.setFieldValue('hasClickCollect', val)}
          />
        </div>
        <div className="row">
          <Checkbox
            name="hasSelfCheckout"
            label={'Self Checkout ?'}
            controlled
            value={formik.values.hasSelfCheckout}
            onChange={(val) => formik.setFieldValue('hasSelfCheckout', val)}
          />
        </div>
        <div className="row">
          <div className="field">
            <SelectSearch
              name="deliveryFee"
              label="Delivery Fee"
              nameKey="text"
              valueKey="value"
              options={userTier}
              searchByName
              onChange={(val) => formik.setFieldValue('tierSetupId', val)}
              error={formik.errors.tierSetupId}
              showErrors={showFieldErrors('tierSetupId')}
              defaultOption={getDefaultTier()}
            />
          </div>
          <div className="field">
            <SelectSearch
              name="b2bTierSetupId"
              label="B2B Delivery Fee (optional)"
              nameKey="text"
              valueKey="value"
              searchByName
              options={b2bTier}
              isShowingDefaultOptions={true}
              defaultOption={getDefaultTier(true)}
              onChange={(val) => formik.setFieldValue('b2bTierSetupId', val)}
            />
          </div>
        </div>

        <div className="row">
          <div className="field">
            {!formik.values.hasPicking && (
              <SelectSearch
                name="picking-store"
                label="Picking store"
                nameKey="text"
                valueKey="value"
                searchByName
                options={storeOptions}
                defaultOption={getDefaultPickingStore()}
                onChange={(val) => formik.setFieldValue('pickingStoreId', val)}
                error={formik.errors.pickingStoreId}
                showErrors={showFieldErrors('pickingStoreId')}
              />
            )}
          </div>
          <div className="field" />
        </div>

        <div className="hours-form">
          <h2>Business Hours</h2>

          <Table>
            <Header>
              <Cell>Day</Cell>
              <Cell>
                <div className="row">
                  Always Open
                  <Checkbox
                    name="always-open-daily"
                    className="sng-toggle"
                    value={getAlwaysOpenDaily('businessHours')}
                    onChange={(value) =>
                      onAlwaysOpenDailyChanged({
                        hourSection: 'businessHours',
                        value,
                      })
                    }
                    controlled
                  />
                </div>
              </Cell>
              <Cell>Opening Time</Cell>
              <Cell>Closing Time</Cell>
            </Header>
            {Object.keys(DEFAULT_EMPTY_HOURS).map((day) => (
              <Row key={day}>
                <Cell>{day}</Cell>
                <Cell>
                  <Checkbox
                    name={`always-open-${day}`}
                    controlled
                    value={getAlwaysOpenChecked({
                      hourSection: 'businessHours',
                      day,
                    })}
                    onChange={(value) =>
                      onAlwaysOpenCheckedChanged({
                        hourSection: 'businessHours',
                        day,
                        value,
                      })
                    }
                  />
                </Cell>
                <Cell>
                  <Input
                    type="text"
                    name={`opening-hour-${day}`}
                    pattern={HOURS_STRING_PATTERN}
                    placeholder={HOURS_PLACEHOLDER}
                    onChange={(value) =>
                      handleHoursChange({
                        hourSection: 'businessHours',
                        type: 'opening',
                        day,
                        value,
                      })
                    }
                    value={getHourValue({
                      hourSection: 'businessHours',
                      type: 'opening',
                      day,
                    })}
                    error={businessHoursError(day, true)}
                    showErrors={showFieldErrors('businessHours')}
                  />
                </Cell>
                <Cell>
                  <Input
                    type="text"
                    name={`closing-hour-${day}`}
                    pattern={HOURS_STRING_PATTERN}
                    placeholder={HOURS_PLACEHOLDER}
                    onChange={(value) =>
                      handleHoursChange({
                        hourSection: 'businessHours',
                        type: 'closing',
                        day,
                        value,
                      })
                    }
                    value={getHourValue({
                      hourSection: 'businessHours',
                      type: 'closing',
                      day,
                    })}
                    error={businessHoursError(day, false)}
                    showErrors={showFieldErrors('businessHours')}
                  />
                </Cell>
              </Row>
            ))}
          </Table>
        </div>

        <h2>Scan and Go settings</h2>
        <div className="row">
          <div className="field" data-testid="sng-enabled-toggle">
            <Toggle
              name="is-sng"
              className="sng-toggle"
              label="Enable Scan And Go "
              title="Enabled Scan And Go"
              value={formik.values.meta_isSNG}
              onChange={onSngEnabledChanged}
              icons={false}
            />
          </div>
          {formik.values.meta_isSNG && (
            <>
              <div className="field">
                <Checkbox
                  name="sng-checkin-blocked"
                  label="SNG Check-in Blocked ?"
                  value={formik.values.meta_sngCheckinBlocked}
                  onChange={(value) =>
                    formik.setFieldValue('meta_sngCheckinBlocked', value)
                  }
                />
              </div>
            </>
          )}
        </div>
        {formik.values.meta_isSNG && (
          <div className="row">
            <div className="field">
              <Input
                name="sng-checkin-radius"
                label="SNG Geo Checkin Radius"
                value={formik.values.meta_sngCheckinRadius}
                onChange={(value) =>
                  formik.setFieldValue('meta_sngCheckinRadius', value)
                }
                error={formik.errors.meta_sngCheckinRadius}
                showErrors={showFieldErrors('meta_sngCheckinRadius')}
              />
            </div>
            <div className="field" />
          </div>
        )}

        {formik.values.meta_isSNG && (
          <div className="hours-form">
            <h2>SNG Operational Hours</h2>
            <Table>
              <Header>
                <Cell>Day</Cell>
                <Cell>
                  <div className="row">
                    Always Open
                    <Checkbox
                      name="sng-always-open-daily"
                      className="sng-toggle"
                      value={getAlwaysOpenDaily('meta_sngOperationalHours')}
                      onChange={(value) =>
                        onAlwaysOpenDailyChanged({
                          hourSection: 'meta_sngOperationalHours',
                          value,
                        })
                      }
                      controlled
                    />
                  </div>
                </Cell>
                <Cell>Opening Time</Cell>
                <Cell>Closing Time</Cell>
              </Header>
              {Object.keys(DEFAULT_EMPTY_HOURS).map((day) => (
                <Row key={day}>
                  <Cell>{day}</Cell>
                  <Cell>
                    <Checkbox
                      name={`sng-always-open-${day}`}
                      controlled
                      value={getAlwaysOpenChecked({
                        hourSection: 'meta_sngOperationalHours',
                        day,
                      })}
                      onChange={(value) =>
                        onAlwaysOpenCheckedChanged({
                          hourSection: 'meta_sngOperationalHours',
                          day,
                          value,
                        })
                      }
                    />
                  </Cell>
                  <Cell>
                    <Input
                      name={`sng-opening-hour-${day}`}
                      placeholder={HOURS_PLACEHOLDER}
                      error={sngOperationHoursError(day, true)}
                      showErrors={showFieldErrors('meta_sngOperationalHours')}
                      pattern={HOURS_STRING_PATTERN}
                      value={getHourValue({
                        hourSection: 'meta_sngOperationalHours',
                        type: 'opening',
                        day,
                      })}
                      onChange={(value) => {
                        handleHoursChange({
                          hourSection: 'meta_sngOperationalHours',
                          type: 'opening',
                          day,
                          value,
                        })
                      }}
                    />
                  </Cell>
                  <Cell>
                    <Input
                      name={`sng-closing-hour-${day}`}
                      placeholder={HOURS_PLACEHOLDER}
                      pattern={HOURS_STRING_PATTERN}
                      value={getHourValue({
                        hourSection: 'meta_sngOperationalHours',
                        type: 'closing',
                        day,
                      })}
                      onChange={(value) => {
                        handleHoursChange({
                          hourSection: 'meta_sngOperationalHours',
                          type: 'closing',
                          day,
                          value,
                        })
                      }}
                      error={sngOperationHoursError(day, false)}
                      showErrors={showFieldErrors('meta_sngOperationalHours')}
                    />
                  </Cell>
                </Row>
              ))}
            </Table>
          </div>
        )}
        <div className="metadata-section">
          <h2>Store Metadata</h2>
          <div className="metadata-section-wrapper">
            <StoreMetadata
              metaDataWithType={metaData}
              formik={formik}
              noContainer
              showFieldErrors={showFieldErrors}
            />
          </div>
        </div>
      </div>
      {formik.submitCount > 0 && numberOfTotalErrors > 0 && (
        <div className="form-error">
          <p className="form-error-text">
            There
            {numberOfTotalErrors > 1
              ? ' are more than 1 inputs '
              : ' is 1 input '}
            with errors in the form. Please review and correct them before
            submitting again !
          </p>
        </div>
      )}
      <div className="form-footer">
        <StoreChangesModal
          isOpen={attemptSubmit}
          currentData={formik.values.currentStoreData}
          updatedData={formik.values}
          onConfirmSubmit={_handleSubmit}
          onCancel={() => setAttemptSubmit(false)}
          isSubmitting={isSubmitting}
          action={action}
        />
        <button className="button primary" type="submit" id="store-form-submit">
          {action === 'new' ? 'Submit' : 'Update'}
        </button>
        <button
          className="button"
          type="button"
          onClick={onCancel}
          id="store-form-cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  )
}
