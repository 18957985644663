import React, { Fragment } from 'react'
import { Textarea } from '../../Form'
import { getMessage } from 'lib/translator'

const OpeningHours = ({ _this }) => (
  <Fragment>
    <div className="row">
      <div className="col-12">
        <h3 className="mt">{getMessage(`rewards.store.OpeningHours`)}</h3>
      </div>
    </div>
    <div className="row mb">
      <div className="col-12">
        <Textarea
          placeholder={getMessage(`rewards.store.OpeningHours`)}
          {..._this.generateStateMappers({
            stateKeys: [`legacyOpeningHours`],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    </div>
  </Fragment>
)

export default OpeningHours
