export const paymentErrorMsg = (status, failureReason) => {
  switch(status) {
    case 'COMPLETED':
    case 'CANCELLED':
      return 'None';
    case 'CREATED':
      return "Didn’t complete the payment process. Please try again";
    case 'FAILED':
      return failureReason;
    default:
      return null;
  }
}
