import React from 'react'
import {
  BaseForm,
  Input,
  Select,
} from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import './style.css'

export default class ProductFiltersForm extends BaseForm {
  componentDidMount() {
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form className="product-filter-form">
        <div className="form-fields">
          <Input
            label={getMessage('SKU ID')}
            placeholder={getMessage('Enter SKU')}
            name="SKU ID"
            {...this.generateStateMappers({
              stateKeys: ['search[sku]'],
            })}
          />
          <Select
            name="Filter by status"
            label={getMessage(`Filter by status`)}
            placeholder={getMessage(`select status`)}
            options={
              [
              'PENDING',
              'SUCCEEDED',
              'FAILED'
            ]}
            {...this.generateStateMappers({
              stateKeys: ['filters[state]'],
            })}
          />
        </div>
        <SubmitButton>{getMessage('product.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('product.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
