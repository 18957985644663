import React from 'react'
import { Input } from '../../../../components/Form'
import SpecificProductDetails from '../components/SpecificProductDetails'
import { getMessage } from '../../../../lib/translator'

const VoucherSwimLane = () => {
  return {
    fields: ({ getState, updateState, stores }) => {
      return (
        <>
          <div className="form-sections">
            <div className="form-section">
              <Input
                testid="voucher-title"
                label={getMessage('themes.title')}
                placeholder={getMessage('themes.layout.homepage.voucher.title')}
                value={getState(['title']) || ''}
                onChange={e => {
                  updateState(['title'], e)
                }}
              />
            </div>
          </div>
          <SpecificProductDetails
            getState={getState}
            updateState={updateState}
            stores={stores}
            isVoucher={true}
          />
        </>
      )
    },
  }
}

export default VoucherSwimLane
