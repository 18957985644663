import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import logo from '../../../../icons/fp_logo_pdf.png'

const QRCODE_LABELS = {
  AMBIENT: 'MKP_A01',
  FROZEN: 'MKP_F01',
  CHILLED: 'MKP_C01',
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    fontSize: 12,
  },
  view: {
    width: '100%',
    height: '100%',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  message: {
    fontStyle: 'italic',
    marginBottom: '2px',
    fontSize: 14,
  },
  label: {
    fontWeight: 'bold',
  },
  header: {
    border: '1px',
    borderLeft: 0,
    backgroundColor: 'gray',
    flexGrow: 1,
    padding: '2px',
    fontWeight: 700,
  },
  leftCell: {
    border: '1px',
    borderTop: 0,
    flexGrow: 1,
    overflowWrap: 'break-word',
    padding: '2px',
  },
  cell: {
    border: '1px',
    borderLeft: 0,
    borderTop: 0,
    flexGrow: 1,
    overflowWrap: 'break-word',
    padding: '2px',
  },
  rightCell: {
    border: '1px',
    borderTop: 0,
    borderLeft: 0,
    flexGrow: 1,
    overflowWrap: 'break-word',
    padding: '2px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const getBarcodesString = barcodesJson =>
  barcodesJson.repllaceAll && barcodesJson.replaceAll(/[\]["]/g, '')

// Create Document Component
export default ({ orders = [] }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {orders.map((order, index) => (
        <View key={order.referenceNumber} break={index !== 0}>
          <View style={styles.section}>
            <Text style={styles.message}>
              *Please cross out the QR code types that are not applicable to
              this package.
            </Text>
            <View style={[styles.flex]}>
              {Object.keys(QRCODE_LABELS).map((label, i) => (
                <View
                  key={label}
                  style={[
                    styles.flexColumn,
                    i === 0
                      ? {
                          border: '1px',
                          borderRight: 0,
                          borderStyle: 'dashed',
                          borderTopLeftRadius: '5px',
                          borderBottomLeftRadius: '5px',
                        }
                      : i === Object.keys(QRCODE_LABELS).length - 1
                      ? {
                          border: '1px',
                          borderLeft: 0,
                          borderStyle: 'dashed',
                          borderTopRightRadius: '5px',
                          borderBottomRightRadius: '5px',
                        }
                      : {
                          border: '1px',
                          borderStyle: 'dashed',
                        },
                  ]}
                >
                  <View style={{ alignSelf: 'flex-end' }}>
                    <Text>{order.vendorCode}</Text>
                  </View>
                  <View
                    style={[
                      styles.flexColumn,
                      {
                        padding: '30px 40px',
                      },
                    ]}
                  >
                    <Text style={styles.label}>{label}</Text>
                    <Image
                      src={order[label]}
                      style={{ width: '100px', height: '100px' }}
                    />
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.section}>
            <View style={[styles.flex]}>
              <View
                style={[
                  styles.flexColumn,
                  {
                    border: '1px',
                    borderRight: 0,
                    borderTopLeftRadius: '5px',
                    borderBottomLeftRadius: '5px',
                    width: '50%',
                    padding: '2px',
                    height: '200px',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  },
                ]}
              >
                <Image src={logo} style={{ width: '150px', height: '40px' }} />
                <Text style={[styles.label, { fontSize: 14 }]}>PACKLIST</Text>
                <View>
                  <Text style={[styles.label]}>Store:</Text>
                  <Text>{order.storeName}</Text>
                </View>
                <View>
                  <Text style={[styles.label]}>Seller Name:</Text>
                  <Text>{order.sellerName} Foods</Text>
                </View>
                <View>
                  <Text style={[styles.label]}>Customer Delivery Date:</Text>
                  <Text>{order.deliveryDate}</Text>
                </View>
              </View>
              <View
                style={[
                  styles.flexColumn,
                  {
                    border: '1px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                    width: '50%',
                    padding: '2px',
                    height: '200px',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  },
                ]}
              >
                <View style={[styles.flex, { alignSelf: 'flex-end' }]}>
                  <Text
                    style={[styles.label, { fontSize: 14 }]}
                  >{`T${order.deliveryHour}`}</Text>
                </View>
                <View>
                  <Text style={[styles.label, { fontSize: '14px' }]}>
                    Order No:
                  </Text>
                  <Text>{order.referenceNumber}</Text>
                </View>
                <View>
                  <Text style={[styles.label]}>Pickup Date:</Text>
                  <Text>{order.pickupDate}</Text>
                </View>
                <View>
                  <Text style={[styles.label]}>Customer Delivery Time:</Text>
                  <Text>{order.deliveryTime}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={[styles.flex]}>
              <Text style={[styles.header, { width: '10%' }]}>No</Text>
              <Text style={[styles.header, { width: '15%' }]}>SKU</Text>
              <Text style={[styles.header, { width: '20%' }]}>Barcode</Text>
              <Text style={[styles.header, { width: '15%' }]}>Seller SKU</Text>
              <Text style={[styles.header, { width: '20%' }]}>
                Product Name
              </Text>
              <Text style={[styles.header, { width: '10%' }]}>Unit</Text>
              <Text style={[styles.header, { width: '5%' }]}>Qty</Text>
              <Text style={[styles.header, { width: '5%' }]}>{` `}</Text>
            </View>
            {order.orderItems.map((item, i) => (
              <View style={[styles.flex]} key={item.sku}>
                <Text style={[styles.leftCell, { width: '10%' }]}>{i + 1}</Text>
                <Text style={[styles.cell, { width: '15%' }]}>{item.sku}</Text>
                <Text style={[styles.cell, { width: '20%' }]}>
                  {getBarcodesString(item.barcodes)}
                </Text>
                <Text style={[styles.cell, { width: '15%' }]}>
                  {item.sellerSku}
                </Text>
                <Text style={[styles.cell, { width: '20%' }]}>
                  {item.productName}
                </Text>
                <Text style={[styles.cell, { width: '10%' }]}>
                  {item.displayUnit}
                </Text>
                <Text style={[styles.cell, { width: '5%' }]}>
                  {item.orderedQuantity}
                </Text>
                <Text style={[styles.rightCell, { width: '5%' }]}>{` `}</Text>
              </View>
            ))}
            <View
              style={[
                styles.flex,
                { justifyContent: 'center', marginTop: '20px' },
              ]}
            >
              <Text style={styles.label}>END OF PACKLIST</Text>
            </View>
          </View>
        </View>
      ))}
    </Page>
  </Document>
)
