import React from 'react'
import {
  BaseForm,
  Input,
  Select,
  Upload,
  Radio,
  VALIDATION_TYPES,
} from 'components/Form'
import Loader from 'components/Loader'
import { Dialog } from 'components/Popup'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import {
  PRIORITY_OPTIONS,
  BANNER_OPTIONS,
  REQUIRED_INPUTS,
} from 'components/AppHome/AppBanner/utils'
import { formatValidity, STATUS_OPTIONS } from 'components/AppHome/utils'
import MissingInfoDialog from 'components/AppHome/MissingInfoDialog'
import Validity from 'components/AppHome/Validity'
import './style.css'

class BannerUploadForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      missingKeys: [],
      showMissingInfoDialog: false,
      showErrorDialog: false,
      errorField: false,
      errorMessage: null,
      values: {
        bannerType: 'DEEPLINK',
        startTime: '00:00:00',
        endTime: '00:00:00',
      },
    }

    this.transformFormData = this.transformFormData.bind(this)
    this.checkForMissingData = this.checkForMissingData.bind(this)
    this.validateDraft = this.validateDraft.bind(this)
    this.closeErrorMsgPopup = this.closeErrorMsgPopup.bind(this)
  }

  async componentDidMount() {
    const { apiData, method } = this.props
    if (method === 'edit') {
      this.setState({ loading: true })
      const validity = await formatValidity('api', apiData)
      const values = {
        ...apiData,
        ...validity,
      }
      this.setState({ values, loading: false })
    }
  }

  async validateDraft() {
    const { method, onSubmit } = this.props
    if (method === 'add' && !this.state.values.name) {
      this.setState({
        errorField: true,
        showErrorDialog: true,
        errorMessage: getMessage('appbanner.errorMessage03'),
      })
    } else {
      this.setState({ errorField: false })
      const missingKeys = this.checkForMissingData()
      if (missingKeys.length > 0) {
        this.setState({
          showMissingInfoDialog: true,
          missingKeys,
        })
      } else {
        const data = await this.transformFormData(false)
        await onSubmit(data)
      }
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    if (this.isFormValid()) {
      const { method, onSubmit } = this.props
      if (method === 'add') {
        const name = this.state.values.name.replace(/ /g, '_')
        const getBannerByName = await new API({
          url: `/genie/lms/banners?name=${name}`,
        }).get()

        if (getBannerByName.items.length > 0) {
          this.setState({
            showErrorDialog: true,
            showMissingInfoDialog: false,
            errorMessage: getMessage('appbanner.errorMessage02'),
          })
          return
        }
      }
      const data = await this.transformFormData()
      await onSubmit(data)
    }
  }

  async transformFormData(isEnable = true) {
    const { values } = this.state
    const validity = await formatValidity('form', values)
    return {
      ...this.state.values,
      ...validity,
      isEnable,
    }
  }

  checkForMissingData() {
    const { values: obj } = this.state
    const requiredKeys = REQUIRED_INPUTS[obj.bannerType] || []
    return requiredKeys.filter((key) => !obj[key])
  }

  closeErrorMsgPopup() {
    this.setState({
      showErrorDialog: false,
      errorMessage: null,
    })
  }

  render() {
    const { Form } = this.components
    const { method, onSubmit } = this.props
    const { loading, missingKeys, readOnlyField, showMissingInfoDialog } =
      this.state
    const { CancelButton, SubmitButton } = this.buttons

    return (
      <div className="appbanner-form">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            {this.state.showErrorDialog && (
              <Dialog
                show={this.state.showErrorDialog}
                information={this.state.errorMessage}
                close={this.closeErrorMsgPopup}
                closeText={getMessage('apphome.okay')}
              />
            )}
            <MissingInfoDialog
              showMissingInfoDialog={showMissingInfoDialog}
              closeMissingInfoDialog={() =>
                this.setState({ showMissingInfoDialog: false })
              }
              missingKeys={missingKeys}
              onOkHandler={async () => {
                const data = await this.transformFormData(false)
                await onSubmit(data)
              }}
            />
            <div className="grid-2-col">
              <Radio
                className="bannerType"
                label={getMessage('appbanner.bannerType')}
                options={BANNER_OPTIONS}
                {...this.generateStateMappers({
                  stateKeys: ['bannerType'],
                  loseEmphasisOnFill: true,
                })}
              />
              {method === 'edit' && (
                <Radio
                  label="Status"
                  disabled={true}
                  options={STATUS_OPTIONS}
                  value={this.state.values.isEnable}
                />
              )}
              <Upload
                name="image"
                required
                className="bannerImage"
                placeholder={getMessage('appbanner.image.placeholder')}
                label={getMessage('appbanner.image')}
                {...this.generateStateMappers({
                  stateKeys: ['image'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('appbanner.campaign')}
                placeholder={getMessage('appbanner.campaign.placeholder')}
                type="text"
                testid="name"
                className={`bannerName ${
                  this.state.errorField && 'input-error'
                }`}
                {...this.generateStateMappers({
                  stateKeys: ['name'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                disabled={method === 'edit'}
                onKeyPress={() => this.setState({ errorField: false })}
                required
              />
              <Input
                type="text"
                label={getMessage(`appbanner.deepLink`)}
                placeholder={getMessage(`appbanner.deepLink.placeholder`)}
                className="bannerDeepLink"
                {...this.generateStateMappers({
                  stateKeys: ['deepLink'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                disabled={this.state.values?.bannerType === 'LOGIN'}
                required={this.state.values?.bannerType !== 'LOGIN'}
              />
              <Validity
                readOnlyField={readOnlyField}
                generateStateMappers={this.generateStateMappers}
              />
              <Select
                type="text"
                testid="priority"
                className="bannerPriority"
                label={getMessage('appbanner.priority')}
                placeholder={getMessage('appbanner.priority.placeholder')}
                required
                options={PRIORITY_OPTIONS}
                {...this.generateStateMappers({
                  stateKeys: ['priority'],
                })}
              />
              <div className="appFormButtons">
                <>
                  <CancelButton>{getMessage('apphome.cancel')}</CancelButton>
                </>
                <button
                  type="button"
                  className="primary"
                  data-testid="save-button"
                  onClick={this.validateDraft}
                >
                  {getMessage('apphome.saveasdraft')}
                </button>
                <SubmitButton>{getMessage('apphome.enable')}</SubmitButton>
              </div>
            </div>
          </Form>
        )}
      </div>
    )
  }
}

export default BannerUploadForm
