import React from 'react'
import { BaseForm, CategorySearch, ProductSearch } from '../../Form'
import './style.css'
import { getMessage } from 'lib/translator'

export default class AddConditions extends BaseForm {
  constructor(props) {
    super(props)

    this.entityOptions = [
      {
        text: 'Category',
        value: 'Category',
      },
      {
        text: 'Product',
        value: 'Product',
      },
    ]
  }

  render() {
    const { _this, taskIndex, userAction } = this.props

    return (
      <div className="single-column-field chance-page mt">
        <div className="field-label">
          <strong>{getMessage('omnichallenge.task.condition.label')}</strong>
        </div>
        <div className="normal-text mb-1">
          {getMessage('omnichallenge.task.condition.placeholder')}
        </div>
        <div className="single-column-field">
          <div className="condition-popup">
            <div className="condition-details">
              <CategorySearch
                name="category-search"
                label={getMessage('luckydraw.condition-category')}
                placeholder={getMessage('luckydraw.condition-searchcategory')}
                className="category-search"
                {..._this.generateStateMappers({
                  stateKeys: [
                    'taskList',
                    taskIndex,
                    'rule',
                    userAction,
                    'categoryIds',
                  ],
                  loseEmphasisOnFill: true,
                })}
                multiple
                readOnly={_this.state.challengeStarted}
              />
            </div>
            <div className="condition-details">
              <ProductSearch
                label={getMessage('luckydraw.condition-product')}
                placeholder={getMessage('luckydraw.condition-searchproduct')}
                name="product"
                key="condition-product"
                className="product-searchable"
                {..._this.generateStateMappers({
                  stateKeys: [
                    'taskList',
                    taskIndex,
                    'rule',
                    userAction,
                    'productBarcodes',
                  ],
                  loseEmphasisOnFill: true,
                })}
                multiple
                readOnly={_this.state.challengeStarted}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
