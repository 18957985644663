import React from 'react'
import moment from 'moment'
import { BaseForm, DateRangePicker, VALIDATION_TYPES } from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import './style.css'

export default class Filters extends BaseForm {
  constructor (props) {
    super(props)
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      const values = JSON.parse(JSON.stringify(this.state.values))
      values.filter_duration.startDate = this.props.startDate
      values.filter_duration.endDate = this.props.endDate
      this.setState({
        values,
      })
    }
  }

  onChange (dates) {
    const a = moment(dates.endDate)
    const b = moment(dates.startDate)
    if (a.diff(b, 'days') > this.props.maxDays) {
      this.setState({
        showDateRangeError: true,
        dateRangeError: `${getMessage('media.dateselect.error')} ${this.props.maxDays}`,
      })
      return
    }
    this.setState({
      showDateRangeError: false,
    })
    this.props.onChange(dates)
  }

  render () {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    const AdditionalBtns = this.props.additionalBtns

    return (
      <Form className='slot-summary-form'>
        <div className='section-wrap'>
          <div className='section-row'>
            <DateRangePicker
              className='section-row-input-auto'
              label={getMessage('media.spotallocation.searchform.time')}
              {...this.generateStateMappers({
                stateKeys: ['filter_duration'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              error={this.state.dateRangeError}
              showErrors={this.state.showDateRangeError}
              onChange={(dates) => this.onChange(dates)}
              displayFormat='YYYY-MM-DD'
              isOutsideRange={()=>{return false}}
              required
              startDatePlaceholder={getMessage(
                'media.spotallocation.searchform.time.startDate.placeholder',
              )}
              endDatePlaceholder={getMessage(
                'media.spotallocation.searchform.time.endDate.placeholder',
              )}
            />
          </div>
          <div className='section-row-button'>
            <SubmitButton>
              {getMessage('media.spotallocation.searchform.submitText')}
            </SubmitButton>
            {this.props.additionalBtns && <AdditionalBtns></AdditionalBtns>}
          </div>
        </div>
      </Form>
    )
  }

}
