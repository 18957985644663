import Observable from 'zen-observable'
import parallelLimit from 'async/parallelLimit'
import asyncify from 'async/asyncify'
import { compact, uniq, difference } from 'lodash'
import { useEffect, useState } from 'react'
import API from '../../../../../lib/api'
import { OfferMapping } from '../../../../marketing/Offers/settings'
import { JobConfig } from '../../BatchJobsConfig'
import { ENVIRONMENT } from 'config/app'
import moment from 'moment'
import prodStoresList from 'components.new/store-selector/stores_list.json'
import uatStoresList from 'components.new/store-selector/stores_list_uat.json'
import { iconTypes } from '../../../../marketing/Offers/Form/settings'
import { getStores } from 'lib/auth'
import { ALL_OFFLINE_STORES,SNG_STORES,NON_SNG_STORES,FAIRPRICE, FAIRPRICE_EXTRA,FAIRPRICE_FINEST,FAIRPRICE_HYPER,FAIRPRICE_ONLINE,FAIRPRICE_FFS, IS_SNG_STORE_CODE, defaultCategorizedStores,STORE_FORMAT_CODE } from 'components.new/store-selector/CategorizedStoreSelector'

// For pay via app store groupings
const KOPITIAM_PVA = 'kopitiam';
const FAIRPRICE_PVA = 'fairprice';
const UNITY_PVA = 'unity';
const CHEERS_PVA = 'cheers';

const defaultCategories = defaultCategorizedStores();

const storeCategories = defaultCategories.map(item=>item.label?.toLowerCase());

const CART_LEVEL_OFFERS = ['SFXGSD', 'SFXGSFD', 'SFXGCD']
const DISCOUNT_TYPES = {
  fixed: 'FIXED',
  'percent off': 'PERCENT_OFF',
  'absolute off': 'ABSOLUTE_OFF',
  free: 'FREE',
}

const ALL_CUSTOMER_SEGMENT = { id: 'AllCustomers' }
const NEW_CUSTOMER_SEGMENT = { id: 'NewCustomers' }
const B2B_CUSTOMER_SEGMENT = { id: 'AllB2BCustomers' }
const SNG_CUSTOMER_SEGMENT = { id: 'NewSNGCustomers' }

const getEntityTypeIndex = (results) => {
  return results[0].findIndex((head) => head.trim() === 'Entity Type')
}

const getProductsDataForColumn = (value, products) => {
  let productsData = null
  const productIds = []
  const productNames = []
  const skus = value.replace(/ /g, '').split(',')
  skus.forEach((sku) => {
    const currentProduct = products.find(
      (product) => product?.clientItemId === sku
    )
    if (currentProduct) {
      productNames.push(currentProduct.name)
      productIds.push(currentProduct.id)
      productsData = [
        ...(productsData || []),
        {
          name: currentProduct.name,
          sku: currentProduct.clientItemId,
          slug: currentProduct.slug,
          displayUnit: currentProduct.metaData.DisplayUnit,
          image:
            currentProduct?.images?.length > 0
              ? currentProduct.images[0]
              : null,
        },
      ]
    }
  })
  return {
    productsData,
    productIds,
    productNames: productNames.join(', '),
  }
}

const getCategoryOrBrandData = (value, itemsArray) => {
  const itemIds = []
  const itemNames = []
  const ids = value.replace(/ /g, '').split(',')
  ids.forEach((id) => {
    const currentCategory = itemsArray.find(
      (category) => category.id === Number(id)
    )
    if (currentCategory) {
      itemNames.push(currentCategory.name)
      itemIds.push(currentCategory.id)
    }
  })
  return {
    itemIds,
    itemNames: itemNames.join(', '),
  }
}

const getSegmentColumnData = (value, segmentTags) => {
  let segmentData = null
  const tags = value.replace(/ /g, '').split(',')
  const hasAllCustomerSegment =
    tags.findIndex((tag) => tag?.toLowerCase() === 'allcustomers') > -1
  const hasNewCustomerSegment =
    tags.findIndex((tag) => tag?.toLowerCase() === 'newcustomers') > -1

  const hasB2BCustomerSegment =
    tags.findIndex((tag) => tag?.toLowerCase() === 'allb2bcustomers') > -1

  const hasSNGCustomerSegment =
    tags.findIndex((tag) => tag?.toLowerCase() === 'newsngcustomers') > -1

  if (hasAllCustomerSegment) {
    segmentData = [...(segmentData || []), ALL_CUSTOMER_SEGMENT]
  }
  if (hasNewCustomerSegment) {
    segmentData = [...(segmentData || []), NEW_CUSTOMER_SEGMENT]
  }
  if (hasB2BCustomerSegment) {
    segmentData = [...(segmentData || []), B2B_CUSTOMER_SEGMENT]
  }
  if (hasSNGCustomerSegment) {
    segmentData = [...(segmentData || []), SNG_CUSTOMER_SEGMENT]
  }
  const otherTags = compact(
    tags.map(
      (tag) =>
        segmentTags.find(
          (segment) => segment.name?.toLowerCase() === tag?.toLowerCase()
        )?.id || null
    )
  )
  if (otherTags.length) {
    segmentData = [
      ...(segmentData || []),
      { id: 'CustomersMappedToTag', tagId: otherTags.join(', ') },
    ]
  }
  return segmentData
}


const getSNGStores = (stores)=>{
  return stores.filter((store) => {
    return (
      store.hasSelfCheckout &&
      store.metaData &&
      (store.metaData["Is SnG Store"] || store.metaData[IS_SNG_STORE_CODE])
    );
  });
}

const getNONSNGStores = (stores)=>{
  return stores.filter((store) => {
    return (
      store.hasSelfCheckout &&
      store.metaData &&
      !store.metaData["Is SnG Store"] &&
      !store.metaData[IS_SNG_STORE_CODE]
    );
  });
}


const getFairPriceOnline = (stores)=>{
  return stores.filter((store) => {
    const isFFSStores = store.name?.toLowerCase().includes("(ffs)");
        const isDS = store.name?.toLowerCase().includes("(ds)");
        const isPFC = store.name?.toLowerCase().includes(" pfc");

        return isFFSStores || isDS || isPFC;
  });
}

const getStoreFormatGroupSgtores = (stores,value)=>{
  return stores.filter((store) => {
    const isOfflineStore = store.hasSelfCheckout
    const isStoreHasStoreFormat = isOfflineStore && store.metaData

    return isStoreHasStoreFormat  && (store.metaData['Store Format'] === value ||   (store.metaData[STORE_FORMAT_CODE] && store.metaData[STORE_FORMAT_CODE] === value))
  });
}

const getStoreByCategory = (value,stores)=>{
  const storeFormatGroups = [FAIRPRICE, FAIRPRICE_EXTRA,FAIRPRICE_FINEST,FAIRPRICE_HYPER].map(item=>item?.toLowerCase());
    if (value?.toLowerCase() === ALL_OFFLINE_STORES?.toLowerCase()) {
        return  stores.filter((store) => store.hasSelfCheckout);
      }
      else if (value?.toLowerCase() === SNG_STORES?.toLowerCase()) {
        return  getSNGStores(stores)
      }
      else if (value?.toLowerCase() === NON_SNG_STORES?.toLowerCase()) {
        return getNONSNGStores(stores)
      }
      else if (value?.toLowerCase() === FAIRPRICE_ONLINE?.toLowerCase()) {
        return getFairPriceOnline(stores)
      }
      else if (value?.toLowerCase() === FAIRPRICE_FFS?.toLowerCase()) {
        return stores.filter((store) => {
          return store.name?.toLowerCase().includes("(ffs)");
        });
      }
      else if (storeFormatGroups.indexOf(value?.toLowerCase())){
        return getStoreFormatGroupSgtores(stores, value)
      }
      return []
}
const getStoresData = (value) => {
  const stores = getStores();
  const storeIds = value ? value.split(",") : [];
  const itemIds = []
  const itemNames = []
  storeIds.map(store=>{
    const hasCategorisedStore = storeCategories.includes((''+store)?.toLowerCase());
    if(hasCategorisedStore){
      const currentCategory = defaultCategories.find(item=>item.label?.toLowerCase() === store?.toLowerCase());
      if(currentCategory?.category){
        const selectedStores = getStoreByCategory(currentCategory?.category,stores) || []
        itemIds.push(...(selectedStores.map((item) => item.id)))
        itemNames.push(...(selectedStores.map((item) => item.name)))
      }
    }
    else{
      const selectedStores = stores.filter((item) => storeIds.map(id=>Number(id)).includes(item.id));
      if(selectedStores.length){
        itemIds.push(...(selectedStores.map((selectedStor) => selectedStor.id)))
        itemNames.push(...(selectedStores.map((selectedStor) => selectedStor.name)))
      }
    }
  })
  return {
    itemIds:[...new Set(itemIds)],
    itemNames:[...new Set(itemNames)].join(', ')
  };
};

const isKopitiamDrinkStall = (clientStoreId) => clientStoreId.endsWith('3DF');

const getIdsFromStoreGroupings = (value) => {
  const storesList = ENVIRONMENT === 'UAT' ? uatStoresList : prodStoresList
  const storeIds = value ? value.split(",") : [];

  const fairpriceGrouping = ["fairprice"];
  const cheersGrouping = ["cheers"];
  const unityGrouping = ["unity"];
  const kopitiamTenantGrouping = ["kopitiam", "tenant", "stalls"];
  const kopitiamDrinkStallGrouping = ["kopitiam", "drink", "stalls"];

  const allStoreIdsArr = storeIds.map(storeId => {
    const keywords = storeId.trim().toLowerCase().split(/\s+/); // this regex handle multiple spaces
    const differenceFairprice = difference(keywords, fairpriceGrouping);
    const differenceCheers = difference(keywords, cheersGrouping);
    const differenceUnity = difference(keywords, unityGrouping);
    const differenceKopitiamTenant = difference(keywords, kopitiamTenantGrouping);
    const differenceKopitiamDrink = difference(keywords, kopitiamDrinkStallGrouping);

    if(differenceFairprice.length === 0) {
      return storesList
        .filter(store => store.store_type === FAIRPRICE_PVA)
        .map(store => store.client_store_id);
    }
    if(differenceCheers.length === 0) {
      return storesList
        .filter(store => store.store_type === CHEERS_PVA)
        .map(store => store.client_store_id);
    }
    if(differenceUnity.length === 0) {
      return storesList
        .filter(store => store.store_type === UNITY_PVA)
        .map(store => store.client_store_id);
    }
    if(differenceKopitiamTenant.length === 0) {
      return storesList
        .filter(store => store.store_type === KOPITIAM_PVA && !isKopitiamDrinkStall(store.client_store_id))
        .map(store => store.client_store_id);
    }
    if(differenceKopitiamDrink.length === 0) {
      return storesList
        .filter(store => store.store_type === KOPITIAM_PVA && isKopitiamDrinkStall(store.client_store_id))
        .map(store => store.client_store_id);
    }
    // return the storeId as it is if it is not a match with grouping names
    return storeId.trim();
  })

  const flattenedArray = allStoreIdsArr.flat();
  const uniqueStoreIds = [...new Set(flattenedArray)];
  return {
    itemIds: uniqueStoreIds,
    itemNames: uniqueStoreIds.join(', ')
  }
}

const getTableData = (
  rows,
  headersRow,
  jobName,
  products,
  segmentTags,
  categories,
  brands
) => {
  const CSV_HEADERS = JobConfig[jobName].csvHeaders
  const csvHeaders = Object.values(CSV_HEADERS)
  const headers = headersRow.map((head) =>
    csvHeaders.find((csvHead) => {
      return csvHead.header?.toLowerCase() === head.trim()?.toLowerCase()
    })
  )
  const offerTypeIndex = headers.findIndex(
    (head) => head.valueKey === 'offerType'
  )

  return rows.map((row) => {
    const offerType = getOfferTypeFromString(row[offerTypeIndex])
    const entityType = getEntityType(headers, row)
    const orderType = getOrderType(headers, row)
    return row.map((value, index) => {
      let dataValue = value
      let data = null
      let isValid =  headers[index].validate(value, offerType, entityType);
      const key = headers[index].valueKey
      const isEligible = key === 'eligibleItems'

      switch (key) {
        case 'eligibleItems':
        case 'productId':
        case 'getProduct': {
          const { productsData, productNames, productIds } = getProductsDataForColumn(value, products)
          dataValue = productNames
          data = isEligible ? productsData : productIds
          break
        }
        case 'categoryId': {
          const { itemIds, itemNames } = getCategoryOrBrandData(value, categories)
          dataValue = itemNames
          data = itemIds
          break;
        }
        case 'brandId': {
          const { itemIds, itemNames } = getCategoryOrBrandData(value, brands)
          dataValue = itemNames
          data = itemIds
          break
        }
        case 'storeId': {
          const { itemIds, itemNames } = getStoresData(value)
          dataValue = itemNames
          data = itemIds
          break;
        }
        case 'segments':
          data = getSegmentColumnData(value, segmentTags)
          break;
        case 'orderType': {
          const splitValues = orderType?.split(',')?.map((item) => item.replace(/ /g, '')?.toLowerCase())
          splitValues.map(item => {
            if (item === 'fairprice') {
              dataValue = splitValues.map(
                splitItem => splitItem?.charAt(0).toUpperCase() + splitItem.slice(1))
                .join(', ')
              isValid = true
            }
          })
          break;
        }
        case 'costCenter':
        case 'supplierName':
        case 'supplierCode': {
          const splitValues = orderType?.split(',')?.map((item) => item.replace(/ /g, '')?.toLowerCase())
          splitValues.map(item => {
            if (['fairprice', 'kopitiam', 'cheers'].includes(item)) {
              isValid = value
            } else {
              isValid = true
            }
          })
          break;
        }
        case 'clientStoreIds': {
          const { itemIds, itemNames }  = getIdsFromStoreGroupings(value);
          dataValue = itemNames
          data = itemIds
          break;
        }
      }
      return {
        value: dataValue,
        key,
        isValid,
        data,
      }
    })
  })
}

// download image from url
const downloadImageFromUrl = async (imageUrl) => {
  const response = await fetch(imageUrl)
  return response.blob()
}

// download image from url and upload on media service
const uploadImage = async (image) => {
  const file = await downloadImageFromUrl(image)
  const filename = image.replace(/^.*[\\/]/, '')
  const data = new window.FormData()
  data.append('imageUpload', file, filename)
  data.append('cache', '1y')
  const api = new API({ url: '/media-service/v2/image-upload' })
  const response = await api.post(data)
  return (response?.data?.imageUpload?.url ||  response?.data?.file?.url)+'?w=60%60'
}

const createOffer = async (offer, endpoint) => {
  const offerApi = new API({
    url: endpoint,
  })
  const offerToCreate = JSON.parse(JSON.stringify(offer))
  delete offerToCreate.id
  delete offerToCreate.isValid
  if (offer.image) {
    offerToCreate.imageUrl = await uploadImage(offer.image)
  }
  return offerApi.post(offerToCreate)
}

const upload = (offers, endpoint) =>
  new Observable((observer) => {
    (async () => {
      const result = {
        successful: [],
        errors: [],
      }
      const uploadPromises = []

      // Create all post requests to upload offers
      // and push them into array
      // to upload them parallel with limit using
      // async/parallelLimit
      offers.forEach(async (offer) => {
        const offerPostPromise = async () => {
          try {
            const response = await createOffer(offer, endpoint)
            result.successful.push(response.data.offers)
            observer.next({ done: false, id: offer.id })
            return result
          } catch (err) {
            result.errors.push({ ...err, id: offer.id })
            observer.next({ done: false, id: offer.id })
            return result
          }
        }

        // need to wrap function with asyncify as we are using transpiler
        // as async function will be parsed
        // to an ordinary function that returns a promise
        // reference link https://caolan.github.io/async/v3/global.html
        uploadPromises.push(asyncify(offerPostPromise))
      })

      // limit concurrent request to maximum 4
      parallelLimit(uploadPromises, 4, () => {
        // notify subscriber that operations is completed
        // and all files are uploaded
        observer.next({ done: true, result })
        observer.complete()
      })
    })()
  })

const edit = (offers) =>
  new Observable((observer) => {
    (async () => {
      const result = {
        successful: [],
        errors: [],
      }
      const uploadPromises = []
      offers.forEach(async (offer) => {
        const offerPostPromise = async () => {
          const endpoint = new API({ url: `/offer-service/offer/${offer.offerId}`})
          try {
            const response = await endpoint.put(offer)
            result.successful.push(response.data.offers)
            observer.next({ done: false, id: offer.offerId })
            return result
          } catch (err) {
            result.errors.push({ ...err, id: offer.offerId })
            observer.next({ done: false, id: offer.offerId })
            return result
          }
        }
        uploadPromises.push(asyncify(offerPostPromise))
      })

      // limit concurrent request to maximum 4
      parallelLimit(uploadPromises, 4, () => {
        observer.next({ done: true, result })
        observer.complete()
      })
    })()
  })

const uploadOffers = (offers, setUploadResult, endpoint) =>
  new Promise((resolve) => {
    upload(offers, endpoint).subscribe({
      next: (response) => {
        setUploadResult(response.result)
      },
      complete: () => resolve(),
    })
  })

const editOffers = (offers, setUploadResult) =>
  new Promise((resolve) => {
    edit(offers).subscribe({
      next: (response) => {
        setUploadResult(response.result)
      },
      complete: () => resolve()
    })
  })

const getProducts = async (eligibleItemsSKUs) => {
  let products = []
  if (eligibleItemsSKUs.length) {
    const productApi = new API({
      url: `/catalogue-service/product`,
    })

    const promises = eligibleItemsSKUs.map(async(eligible) => {
      return productApi.get({
        paginate: 'false',
        clientId: eligible,
      }).then((response) => response.data.product).catch(error => console.error(`Error for product API call:`, error))
    })


    products = Promise.all(promises)
        .then(responses => {
            const concatenatedResponse = responses?.filter(response => response !== null);
            return concatenatedResponse?.flatMap(item => item);
        })
  }
  return products
}
const getCategories = async (rows, results) => {
  // get eligible items
  const categoryIdIndex = results[0].findIndex(
    (head) => head.trim() === 'Category (Category ID)'
  )
  // get product items
  const entityTypeIndex = getEntityTypeIndex(results)

  let categorieIds = []

  rows.forEach((row) => {
    if ((row[entityTypeIndex] || '')?.toLowerCase() === 'category') {
      categorieIds = [
        ...categorieIds,
        ...(row[categoryIdIndex] || '').replace(/ /g, '').split(','),
      ]
    }
  })
  categorieIds = compact(uniq(categorieIds))

  let categories = []
  if (categorieIds.length) {
    const categoryApi = new API({
      url: `/catalogue-service/category`,
    })
    const response = await categoryApi.get({
      paginate: 'false',
      id: categorieIds.slice(),
    })
    categories = response.data.category
  }
  return categories
}

const getBrands = async (rows, results) => {
  // get eligible items
  const brandIdIndex = results[0].findIndex(
    (head) => head.trim() === 'Brand (Brand ID)'
  )
  // get product items
  const entityTypeIndex = getEntityTypeIndex(results)

  let brandIds = []

  rows.forEach((row) => {
    if ((row[entityTypeIndex] || '')?.toLowerCase() === 'brand') {
      brandIds = [
        ...brandIds,
        ...(row[brandIdIndex] || '').replace(/ /g, '').split(','),
      ]
    }
  })
  brandIds = compact(uniq(brandIds))

  let brands = []
  if (brandIds.length) {
    const brandApi = new API({
      url: `/catalogue-service/brand`,
    })
    const response = await brandApi.get({
      paginate: 'false',
      id: brandIds.slice(),
    })
    brands = response.data.brand
  }
  return brands
}

const getSegmentTags = async (segments) => {
  let tags = []
  const nonTagsSegments = ['allcustomers', 'newcustomers']
  const filteredSegments = segments.filter(
    (tag) => nonTagsSegments.indexOf(tag?.toLowerCase()) < 0
  )
  if (filteredSegments.length) {
    const productApi = new API({
      url: `/ef-customer-core/tags`,
    })
    const tagsPromises = filteredSegments.map((segment) =>
      productApi.get({
        paginate: 'false',
        name: segment,
      })
    )
    const tagsResponse = await Promise.all(tagsPromises)
    tags = tagsResponse
      .map((response) => response?.data?.tags[0] || null)
      .filter((tag) => Boolean(tag))
  }
  return tags
}

const getOfferTypeFromString = (typeString) => {
  const offer = OfferMapping.find(
    (item) =>
      item.text.replace(/[^a-zA-Z ]/g, '')?.toLowerCase() ===
      typeString.replace(/[^a-zA-Z ]/g, '')?.toLowerCase()
  )
  return offer?.value || typeString
}

const getEntityType = (headers, row) => {
  const entityTypeIndex = headers.findIndex(
    (head) => head.valueKey === 'entityType'
  )
  return row[entityTypeIndex] || ''
}

const getOrderType = (headers, row) => {
  const entityTypeIndex = headers.findIndex(
    (head) => head.valueKey === 'orderType'
  )
  return row[entityTypeIndex] || ''
}

// select offer type
// and if didn't find correct offer type
// set validation to false
const getOfferType = (row) => {
  const currentOffer = row.find((col) => col.key === 'offerType')
  return getOfferTypeFromString(currentOffer.value)
}

const getRuleForBMINXGFG = (
  ruleObj,
  getProduct,
  getProductQuantity,
  entityIds,
  isCombo,
  entityTypeValue,
  entityKey
) => {
  let rule = {
    ...ruleObj,
    get: getProduct.reduce(
      (obj, item) =>
        Object.assign(obj, { [item]: { q: Number(getProductQuantity || 0) } }),
      {}
    ),
  }
  if (!isCombo) {
    rule = {
      minAmount: ruleObj.minAmount,
      get: getProduct.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item]: {
              q: Number(getProductQuantity || 0),
              t: ruleObj.total.t,
              v: ruleObj.total.v,
            },
          }),
        {}
      ),
    }
  }
  return {
    elementGroups: [{ ...rule }],
    entityIds: entityIds,
    limit: 0,
    [entityKey]: {
      type: entityTypeValue,
    },
  }
}
const getRulesForCartLevelOffer = (row, offerType, discountType) => {
  let rule = null
  const cartPrice = row.find((col) => col.key === 'cartPrice')
  const discountValue = row.find((col) => col.key === 'discountValue')
  const orderLevelRedemption = row.find(
    (col) => col.key === 'orderLevelRedemption'
  )
  const ruleKey =
    offerType === 'SFXGSD'
      ? 'shippingDiscount'
      : offerType === 'SFXGCD'
      ? 'cartDiscount'
      : 'serviceFee'
  rule = {
    cartPrice: Number(cartPrice?.value || 0),
    limit: Number(orderLevelRedemption?.value || 0),
    [ruleKey]: {
      t: DISCOUNT_TYPES[discountType.value?.toLowerCase()],
      v: Number(discountValue.value || 0),
    },
  }

  if (offerType === 'SFXGCD') {
    const slot = row.find((col) => col.key === 'slot')
    rule.timeSlots = slot.value === 'NA' ? [] : slot.value || []
    rule = [
      {
        ...rule,
      },
    ]
  }
  return rule
}

const getEntityValue = (
  isCategoryOffer,
  isBrandOffer,
  productIds,
  categoryIds,
  brandIds
) => {
  if (isCategoryOffer) {
    return { entityTypeValue: 'CATEGORY', entityIds: categoryIds }
  }
  if (isBrandOffer) {
    return { entityTypeValue: 'BRAND', entityIds: brandIds }
  }
  return { entityTypeValue: 'VARIANT', entityIds: productIds }
}

const getRulesForSKULevelOffer = (row, offerType, discountType) => {
  let rule = null
  const minimumValue = row.find((col) => col.key === 'minimumValue')
  const productIds = row.find((col) => col.key === 'productId')?.data
  const categoryIds = row.find((col) => col.key === 'categoryId')?.data
  const brandIds = row.find((col) => col.key === 'brandId')?.data
  const discountValue = row.find((col) => col.key === 'discountValue')
  const itemDiscountType = row.find((col) => col.key === 'itemDiscountType')
  const entityType = row.find((col) => col.key === 'entityType')?.value
  const includes = row.find((col) => col.key === 'includes')?.value
  const getProduct = row.find((col) => col.key === 'getProduct')?.data
  const getProductQuantity = row.find(
    (col) => col.key === 'getProductQuantity'
  )?.value
  const isCombo = (itemDiscountType?.value || '')?.toLowerCase() !== 'individual'
  const isCategoryOffer = entityType?.toLowerCase() === 'category'
  const isBrandOffer = entityType?.toLowerCase() === 'brand'
  const isCategoryOrBrand = isCategoryOffer || isBrandOffer
  const entityKey =
    (includes || '')?.toLowerCase() === 'yes' ? 'entity' : 'non-entity'

  const { entityTypeValue, entityIds } = getEntityValue(
    isCategoryOffer,
    isBrandOffer,
    productIds,
    categoryIds,
    brandIds
  )

  let minimumTypeKey = 'quantity'
  if (('BMIN', 'BMINXGFG').includes(offerType)) {
    minimumTypeKey = 'minAmount'
  }
  if (offerType === 'BMINXATP') {
    minimumTypeKey = 'minQuantity'
  }

  const ruleObj = {
    total: {
      t: DISCOUNT_TYPES[discountType.value?.toLowerCase()],
      v: Number(discountValue.value || 0),
    },
    [minimumTypeKey]: Number(minimumValue?.value || 0),
  }
  rule = {
    variants: productIds,
    isCombo,
    limit: 0,
    entity: {
      type: entityTypeValue,
      id: isCategoryOrBrand ? entityIds[0] : null,
    },
  }
  if (offerType === 'BMINXATP') {
    rule.elementGroups = [{ ...ruleObj }]
  } else if (offerType === 'BMINXGFG') {
    rule = getRuleForBMINXGFG(
      ruleObj,
      getProduct,
      getProductQuantity,
      entityIds,
      isCombo,
      entityTypeValue,
      entityKey
    )
  } else if (offerType === 'BMIN') {
    rule = {
      elementGroups: [{ ...ruleObj }],
      entityIds: entityIds,
      isCombo,
      limit: 0,
      [entityKey]: {
        type: entityTypeValue,
      },
    }
  } else {
    rule = { ...rule, ...ruleObj }
  }
  return rule
}

// Define offer rules
const getOfferRules = (row, offerType, discountType) => {
  if (CART_LEVEL_OFFERS.includes(offerType)) {
    return getRulesForCartLevelOffer(row, offerType, discountType)
  }
  return getRulesForSKULevelOffer(row, offerType, discountType)
}

// Define offer meta data
const getOfferMeta = (row) => {
  let metaData = null
  const sapReference = row.find((col) => col.key === 'sapReference')
  const orderType = row.find((col) => col.key === 'orderType')
  const supplierName = row.find((col) => col.key === 'supplierName')
  const supplierCode = row.find((col) => col.key === 'supplierCode')
  const iconType = row.find((col) => col.key === 'iconType')
  const treatment = row.find((col) => col.key === 'treatment')
  const splitedOrderType = orderType?.value?.toString()
    ?.replace(/ /g, '')
    ?.toLowerCase()
    ?.split(',')
  if (sapReference || iconType) {
    metaData = {
      sapReference: sapReference?.value || '',
      iconType:
        iconTypes.find(
          (icon) =>
            icon.text?.toLowerCase() === (iconType?.value || '')?.toLowerCase()
        )?.value || '',
    }
  }
  if (treatment) {
    metaData.treatment = treatment?.value || '';
  }
  if (supplierName) {
    metaData.supplierName = supplierName?.value || '';
  }
  if (supplierCode) {
    metaData.supplierCode = supplierCode?.value || '';
  }
  splitedOrderType.map(splitItem => {
    if (['fairprice', 'kopitiam', 'cheers'].includes(splitItem)) {
      metaData.supplierName = supplierName?.value || '';
      metaData.supplierCode = supplierCode?.value || '';
    }
  })
  return metaData
}

//define eligible items links
const geteligibleItemsLinks = (row) => {
  let eligibleItemsLinks = null
  const webLink = row.find((col) => col.key === 'webLink')
  const mobileLink = row.find((col) => col.key === 'mobileLink')
  if (webLink?.value || mobileLink?.value) {
    eligibleItemsLinks = {
      web: webLink?.value || '',
      mobile: mobileLink?.value || '',
    }
  }
  return eligibleItemsLinks
}

// define eligible items
const getEligibleItems = (row) => {
  const eligibleItems = row.find((col) => col.key === 'eligibleItems')
  return eligibleItems?.data || null
}

// define client store ids
const getClientStoreIds = (row) => {
  const clientIds = row.find((col) => col.key === 'clientStoreIds')
  return clientIds?.value.split(',').map((val) => val.trim().toUpperCase())
}

const getPromoCodeDetail = (row) => {
  const title = row.find((col) => col.key === 'title')
  const subTitle = row.find((col) => col.key === 'subTitle')
  const howToUseText = row.find((col) => col.key === 'howToUseText')
  const tncUrl = row.find((col) => col.key === 'tncUrl')
  return {
    title: title?.value || '',
    subTitle: subTitle?.value || '',
    howToUseText: howToUseText?.value || '',
    tncUrl: tncUrl?.value || '',
  }
}

const generateDataForUpload = (tableData) => {
  const data = tableData.map((row, index) => {
    const isEdit = row.some(i => i.key === 'offerId');

    // Keys
    const title = row.find((col) => col.key === 'title')?.value
    const subTitle = row.find((col) => col.key === 'subTitle')?.value
    const howToUseText = row.find((col) => col.key === 'howToUseText')?.value
    const tncUrl = row.find((col) => col.key === 'tncUrl')?.value
    const webLink = row.find((col) => col.key === 'webLink')?.value
    const mobileLink = row.find((col) => col.key === 'mobileLink')?.value
    const sapReference = row.find((col) => col.key === 'sapReference')?.value
    const orderType = row.find((col) => col.key === 'orderType')?.value
    const supplierName = row.find((col) => col.key === 'supplierName')?.value
    const supplierCode = row.find((col) => col.key === 'supplierCode')?.value
    const iconType = row.find((col) => col.key === 'iconType')?.value
    const treatment = row.find((col) => col.key === 'treatment')?.value
    const offerTypeData = row.find((col) => col.key === 'offerType')?.value
    // Rule Keys
    const minimumValue = row.find((col) => col.key === 'minimumValue')?.value
    const productIds = row.find((col) => col.key === 'productId')?.value
    const categoryIds = row.find((col) => col.key === 'categoryId')?.value
    const brandIds = row.find((col) => col.key === 'brandId')?.value
    const discountValue = row.find((col) => col.key === 'discountValue')?.value
    const itemDiscountType = row.find((col) => col.key === 'itemDiscountType')?.value
    const entityType = row.find((col) => col.key === 'entityType')?.value
    const includes = row.find((col) => col.key === 'includes')?.value
    const getProduct = row.find((col) => col.key === 'getProduct')?.value
    const getProductQuantity = row.find(
      (col) => col.key === 'getProductQuantity'
    )?.value

    const offerToPost = {
      isValid: row.every((col) => col.isValid),
      id: index,
    }
    const discountType = row.find((col) => col.key === 'discountType')
    if (offerTypeData) {
      offerToPost.offerType = getOfferType(row)
    }
    if (
      minimumValue ||
      productIds ||
      categoryIds ||
      brandIds ||
      discountValue ||
      itemDiscountType ||
      entityType ||
      includes ||
      getProduct ||
      getProductQuantity ||
      discountType?.value ||
      offerTypeData
    ) {
      offerToPost.rule = getOfferRules(row, offerToPost.offerType, discountType)
    }

    if (
      sapReference ||
      orderType ||
      supplierName ||
      supplierCode ||
      iconType ||
      treatment
    ) {
      offerToPost.metaData = getOfferMeta(row)
    }
    if (webLink || mobileLink) {
      offerToPost.eligibleItemsLinks = geteligibleItemsLinks(row)
    }
    if (row.find((col) => col.key === 'eligibleItems')?.value) {
      offerToPost.eligibleItems = getEligibleItems(row)
    }
    if (title || subTitle || howToUseText || tncUrl) {
      offerToPost.promoCodeDetail = getPromoCodeDetail(row)
    }
    if (row.find((col) => col.key === 'clientStoreIds')?.value) {
      offerToPost.clientStoreIds = getClientStoreIds(row)
    }

    // define segment user set items
    const segmentItems = row.find((col) => col.key === 'segments')
    if (segmentItems?.data) {
      offerToPost.userSet = { data: segmentItems.data, type: 'SEGMENTS' }
    }
    if (!isEdit) {
      offerToPost.status = 'DISABLED'
    }

    const processedKeys = [
      'offerType',
      'clientStoreIds',
      'cartPrice',
      'discountType',
      'discountValue',
      'orderLevelRedemption',
      'slot',
      'sapReference',
      'iconType',
      'eligibleItems',
      'webLink',
      'mobileLink',
      'segments',
      'id',
      'timeStamp',
      'emailAddress',
      'title',
      'subTitle',
      'discountItem',
      'itemDiscountType',
      'quantity',
      'entityType',
      'minimumType',
      'minimumValue',
      'productId',
      'categoryId',
      'brandId',
      'brandId',
      'reference',
      'getProduct',
      'getProductQuantity',
      'includes',
    ]

    const remainingCols = row.filter(
      (col) => processedKeys.indexOf(col.key) < 0
    )

    // add rest of the fields
    return remainingCols.reduce((current, next) => {
      let columnData = {}

      const numericFields = [
        'numberOfPromos',
        'totalRedemption',
        'customerRedemptionLimit',
      ]
      if (!next.value || next.value === 'NA') {
        return { ...current, ...columnData, id: index }
      }

      switch (next.key) {
        case 'stackable': {
          columnData = {
            [next.key]:
              next.value?.toLowerCase() === 'yes' &&
              discountType.value?.toLowerCase() !== 'fixed',
          }
          break;
        }
        case 'hasUniquePromocode':
        case 'addToWallet': {
          columnData = {
            [next.key]: next.value?.toLowerCase() === 'yes',
          }
          break;
        }
        case 'orderType':
        case 'paymentType':
        case 'promoCode': {
          const splitedValues = next.value
          ?.toString()
          ?.replace(/ /g, '')
          ?.toLowerCase()
          ?.split(',')
          next.value = splitedValues.map((item) => {
            return ['fairprice', 'kopitiam', 'cheers'].includes(item) ? `DP_${item}`.replace(/ /g, '') : item
          }).join(',')
          columnData = {
            [next.key]: next.value
              .replace(/ /g, '')
              .split(',')
              .map((val) => val.toUpperCase()),
          }
          break;
        }
        case 'storeId': {
          columnData = {
            [next.key]: (next.data).join(','),
          }
          break;
        }
        case 'offerValidFrom':
        case 'offerValidTill':
        case 'startDeliveryDate':
        case 'endDeliveryDate': {
          columnData = {
            [next.key]: moment(next.value, 'DD/MM/YYYY hh:mm:ss').format(
              'YYYY-MM-DD HH:mm:ss'
            ),
          }
          break;
        }
        default:
          columnData = {
            [next.key]: numericFields.includes(next.key)
              ? Number(next.value)
              : next.value,
          }
      }

      return { ...current, ...columnData, id: index }
    },  offerToPost)
  })
  if (data?.some(item => item.hasOwnProperty('offerId'))) {
    return data;
  } else {
    return data.filter((item) => item.isValid)
  }
}

const getProductsSKUs = (rows, results) => {
  // get eligible items
  const eligibleItemsIndex = results[0].findIndex(
    (head) => head === 'Eligible Items'
  )
  // get product items
  const entityTypeIndex = getEntityTypeIndex(results)
  const productSKUindex = results[0].findIndex(
    (head) => head.trim() === 'Product (SKU ID)'
  )
  const getProductIndex = results[0].findIndex(
    (head) => head.trim() === '(Get) Product'
  )
  let eligibleItemsSKUs = []
  let productsSKUs = []

  rows.forEach((row) => {
    eligibleItemsSKUs = [
      ...eligibleItemsSKUs,
      ...(row[eligibleItemsIndex] || '').replace(/ /g, '').split(','),
    ]
    if (getProductIndex > -1) {
      productsSKUs = [
        ...productsSKUs,
        ...(row[getProductIndex] || '').replace(/ /g, '').split(','),
      ]
    }
    if ((row[entityTypeIndex] || '')?.toLowerCase() === 'product') {
      productsSKUs = [
        ...productsSKUs,
        ...(row[productSKUindex] || '').replace(/ /g, '').split(','),
      ]
    }
  })
  return compact(uniq([...eligibleItemsSKUs, ...productsSKUs]))
}

const useUploadOffer = (results, jobName) => {
  const [isLoading, setIsloading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [uploadResult, setUploadResult] = useState({})
  const [isUploading, setIsUploading] = useState(false)
  const [invalidSegmentIoData, setInvalidSegmentIoData] = useState([])
  const headersRow = results[0]
  const rows = results.slice(1)

  const generateRowsData = async () => {
    setIsloading(true)

    const productSkus = getProductsSKUs(rows, results)
    // get segments
    const segmentsIndex = results[0].findIndex((head) => head === 'Segments')
    let segments = []
    rows.forEach((row) => {
      segments = [
        ...segments,
        ...(row[segmentsIndex] || '').replace(/ /g, '').split(','),
      ]
    })
    segments = compact(uniq(segments))

    const products = await getProducts(productSkus);
    const segmentTags = await getSegmentTags(segments)
    const categories = await getCategories(rows, results)
    const brands = await getBrands(rows, results)
    const rowsData = getTableData(
      rows,
      headersRow,
      jobName,
      products,
      segmentTags,
      categories,
      brands
    )
    setIsloading(false)
    let returnData = rowsData;
    if (returnData[0]?.some((k) => k.key.includes('offerId'))) {
      returnData = rowsData?.map((item) => item?.map(k => ({ ...k, isValid: true })))
    }
    setTableData(returnData)
  }

  const fetchSegmentIoByName = async(name) => {
    try {
      const fetchApi = new API({ url: `/segments` });
      return fetchApi.get({ name });
    } catch(e) {
      return {
        data: { name: name, error: e }
      }
    }
  }

  const onUpload = async () => {
    setIsUploading(true)
    const endpoint = JobConfig[jobName].endpoint
    const dataToUpload = generateDataForUpload(tableData)

    if (dataToUpload.some(data => data?.segmentationIds)) {
      // split each string by comma and flatten the arrays
      const allTagNames = dataToUpload
        .map(x => x.segmentationIds)
        .flatMap(item => item.split(',').map(str => str.trim()));

      // remove duplicates by creating a Set
      const uniqueTagNames = [...new Set(allTagNames)];

      // store only matching values from API in this form [{ id: 1, name: "test" }]
      const segmentIoArr = [];
      if (uniqueTagNames.length > 0) {
        // API call to retrieve ID of all unique tag names
        const responses = await Promise.all(uniqueTagNames.map(name => fetchSegmentIoByName(name)))
        responses.forEach((result, index) => {
          segmentIoArr.push({
            // API returns best matching results, not exactly matching. So, need to check with uniqueTagNames
            id: (result?.data?.length > 0 && result.data[0].name === uniqueTagNames[index]) ?
              result.data[0].id : -1,
            name: uniqueTagNames[index]
          });
        })
      }

      // set invalid segmentIo names, to be displayed to user
      setInvalidSegmentIoData(segmentIoArr.filter(item => item.id === -1))

      // reassign ids instead of tag names to segmentationIds in payload
      dataToUpload.forEach(data => {
        if (data?.segmentationIds) {
          const segmentNames = data?.segmentationIds.split(',').map(str => str.trim());

          // filter to select only valid segment names and get ids of them
          data.segmentationIds = segmentNames
            .filter(segmentName => segmentIoArr.some(segment => segment.name === segmentName))
            .map(name => {
              const index = segmentIoArr.findIndex(x => x.name === name);
              return segmentIoArr[index].id;
            })
        }
      })
    }

    if (
      dataToUpload?.some(
        (item) => item.key === 'offerId' || item.hasOwnProperty('offerId')
      )
    ) {
      await editOffers(dataToUpload, setUploadResult)
    } else if(dataToUpload.length > 0) {
      await uploadOffers(dataToUpload, setUploadResult, endpoint)
    }
    setIsUploading(false)
  }

  useEffect(() => {
    generateRowsData()
  }, [])

  return {
    tableData,
    isLoading,
    onUpload,
    uploadResult,
    isUploading,
    invalidSegmentIoData
  }
}

export default useUploadOffer
