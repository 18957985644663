import API from 'lib/api'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import {
  ACTION_TRAIL_CYCLE_PERIOD,
  ACTION_TRAIL_STREAM_NAME,
} from '../../config/app'
import SPLIT_FEATURES from '../../containers/SplitContext/features'
import { getSession } from '../auth'
import { ActivityMaps, ActivityTypes } from './action-maps'
import { ACTION_TRAIL_HEADER, isAtRoute, isRouteMatch } from './utils'
import { chunk } from 'lodash'

export class ActionTrail {
  constructor() {
    this.PERIOD = ACTION_TRAIL_CYCLE_PERIOD
    this.split = {}
    this.ACTION_TRAIL_KEY = 'action-logs'
    this.actionTrailInterval = null
    this.run = this.run.bind(this)
    this.setSplit = this.setSplit.bind(this)
    this.isActionTrailEnabled = this.isActionTrailEnabled.bind(this)
    this.captureActionViaAxios = this.captureActionViaAxios.bind(this)
    this.addAction = this.addAction.bind(this)
    this.sendActionLogs = this.sendActionLogs.bind(this)
    this.cacheRecordIntoLocalStorage =
      this.cacheRecordIntoLocalStorage.bind(this)
    this.actionRecords = this.prepopulateLeftOverRecords()
    this.standbyList = []
  }

  /**
   *
   */
  isActionTrailEnabled(featureName) {
    const targetFlag = this.split[featureName]
    return Boolean(
      targetFlag !== undefined &&
        targetFlag.treatment &&
        targetFlag.treatment === 'on'
    )
  }

  /**
   * This function prepoluate the list of records from the localstorage
   * (if any) into the in-memory <actionRecords>.
   * Will leave it empty if no leftover records
   */
  prepopulateLeftOverRecords() {
    const leftOverRecords = window.localStorage.getItem(this.ACTION_TRAIL_KEY)
    window.localStorage.removeItem(this.ACTION_TRAIL_KEY)

    if (isEmpty(leftOverRecords)) {
      return []
    }
    return JSON.parse(leftOverRecords)
  }

  /**
   *
   * @param {*} splitContext
   */
  setSplit(splitContext) {
    this.split = splitContext
  }

  /**
   * Retrieve all the records tracked from the record list (in memory) and
   * push to backend for processing. Will clear the list once done.
   * Will repeat this cycles every <PERIOD>, default to 60 secs intervals
   */
  sendActionLogs() {
    const listToProcess = this.actionRecords

    /**
     * @case check for both records in main and standbyList to be send
     * to api
     */
    if (listToProcess.length > 0 || this.standbyList.length > 0) {
      /**
       * @case quickly clear the record to be process in current cycle from the main list
       */
      this.actionRecords = []

      /**
       * @standbyList is use as the temporary records holder on the time of each cycle period.
       * It holds the batch of records which id to be send for the current cycle (ONLY).
       * in case of failing, we will combine the standbyList records with the original
       * running list <actionRecords> together to be process on the next cycle / iteration
       * @case add the current batch of records (current cycle) to `standbyList`
       * in case if POST failed, the next cycle will handle it together with the next
       * record batch
       */
      const combineList = [...this.standbyList, ...listToProcess]
      this.standbyList = combineList

      if (combineList.length > 0) {
        const actionTrailAPI = new API({
          url: '/actions-trail',
        })
        const sendCustomHeader = this.isActionTrailEnabled(
          SPLIT_FEATURES.ACTION_TRAIL_CUSTOM_HEADER
        )

        // split up combineList into chunks of 10
        const chunkedActionRecords = chunk(combineList, 10)

        const apiActions = chunkedActionRecords.map((records) => {
          const transformRecords = this.transformActionTrailsPayload(records)
          const data = transformRecords.Records

          return {
            DeliveryStreamName: ACTION_TRAIL_STREAM_NAME,
            Records: data,
          }
        })

        const promises = apiActions.map((payload, index) => {
          return (
            actionTrailAPI
              .post(payload, sendCustomHeader && ACTION_TRAIL_HEADER)
              .then(() => {
                // remove all sent records from the standbyList
                const currentChunk = chunkedActionRecords[index]
                this.standbyList = this.standbyList.filter((item) => {
                  return !currentChunk.some(
                    (chunkItem) => chunkItem.id === item.id
                  )
                })
              })
              // Handle rejected promise so it won't end up as unhandled error and sent to Datadog twice
              // The same error was already sent to Datadog in the new API() call
              .catch((e) => e)
          )
        })

        Promise.all(promises)
      }
    }
  }

  /**
   * This function will first filter out any duplication records (by its id - if any), and then
   * encode all actions data payload into a base64 encoded format
   * and will add <DeliveryStreamName> properties as it is required for the backend api
   * to process successfully
   * @param {*} actionsList
   */
  transformActionTrailsPayload(actionsList) {
    try {
      const uniqueActions = []
      const idMap = new Map()

      for (const action of actionsList) {
        if (!idMap.get(action.id)) {
          idMap.set(action.id, true)
          uniqueActions.push(action)
        }
      }

      return {
        DeliveryStreamName: ACTION_TRAIL_STREAM_NAME,
        Records: uniqueActions.map((record) => {
          const sPayload = JSON.stringify(record)
          return {
            Data: Buffer.from(sPayload).toString('base64'),
          }
        }),
      }
    } catch (err) {
      console.error(err)
      return {
        DeliveryStreamName: ACTION_TRAIL_STREAM_NAME,
        Records: [],
      }
    }
  }

  /**
   *
   * @param {*} config
   */
  captureActionViaAxios(config) {
    let _isActionTrailEnabled = this.isActionTrailEnabled(
      SPLIT_FEATURES.ACTION_TRAIL
    )

    if (process.env.NODE_ENV === 'test') {
      _isActionTrailEnabled = true
    }

    const isActionTrailEndpointPost = Boolean(
      config.url.includes('/actions-trail') && config.method === 'post'
    )

    const isConfigOrExtensions = Boolean(
      config.url.includes('/config-service/meta-data') &&
        !window.location.pathname.includes('/settings/extensions')
    )

    if (isActionTrailEndpointPost || isConfigOrExtensions) {
      /**
       * Do not track this api calls, because this endpoint are use in general for the backoffice,
       * not by a specific user actions.
       * Same for action trail post call, we use this api to send action-trail data
       * into backend, thus no need to track this call too
       */
      return new Promise((resolve) => resolve())
    }

    return new Promise((resolve) => {
      try {
        let activityType = this.getActivityType(config)
        activityType = this.checkForProductHideShowAction(activityType, config)
        activityType = this.checkForCatalogueBulkUploadJobs(
          activityType,
          config
        )
        activityType = this.checkForStockOverrideGetType(activityType, config)
        activityType = this.checkForCustomFieldViewAction(activityType, config)
        activityType = this.checkForStoreRelatedPutActionType(activityType)
        activityType = this.checkForOrderSlotViewActionType(activityType)
        activityType = this.checkForHrActivity(activityType, config)
        activityType = this.checkCustomChallengeEvent(activityType, config)
        activityType = this.checkRmsApiCall(activityType, config)
        activityType = this.overwriteLogisticAction(activityType, config)
        activityType = this.overwriteMarketingAction(activityType)

        const { user } = getSession()

        const haveActionDef = Boolean(
          activityType != null && activityType !== undefined
        )
        if (haveActionDef && !isEmpty(user)) {
          const payload = {
            id: uuid(),
            systemId: 1, // <system ID> for action-trail system
            userId: user.id,
            actionType: activityType,
            endpoint: window.location.href,
            email: user.emails[0].email,
            timestamp: moment().toISOString(),
            method: config.method,
            actionPayload: this.extractActionPayload(config),
          }
          this.addAction(payload)
        }
        resolve()
      } catch (err) {
        console.error(err)
        resolve()
      }
    })
  }

  /**
   *
   * @param {*} actType
   * @returns
   */
  overwriteMarketingAction(actType) {
    // This route has too many api calls to get aditional
    // data. So we shouldn't recognize these API call as
    // main action

    // Discard all action except main action
    if (
      isRouteMatch(
        'marketing/offers/add',
        'marketing/offers/pay-via-fp-app/add'
      ) &&
      actType !== ActivityTypes.marketing.ADD_OG_OFFER
    ) {
      return null
    }

    // Discard all action except main action
    if (
      isAtRoute('marketing/offers/edit') &&
      actType !== ActivityTypes.marketing.EDIT_OFFER
    ) {
      return null
    }

    // Discard all action except main action
    if (
      isAtRoute('/marketing/evouchers/add') &&
      actType !== ActivityTypes.marketing.ADD_EVOUCHER
    ) {
      return null
    }

    // Discard all action except main action
    if (
      isAtRoute('marketing/lucky-draws/add') &&
      actType !== ActivityTypes.marketing.ADD_LUCKY_DRAW
    ) {
      return null
    }

    if (
      [
        ActivityTypes.catalogue.VIEW_TAGS,
        ActivityTypes.catalogue.VIEW_CATEGORIES,
      ].includes(actType) &&
      isRouteMatch(
        'marketing/offers',
        'marketing/search-campaigns',
        'marketing/web-banners'
      )
    ) {
      // View tag in marketing offer page is just an aditional
      // API call, not main action. So drop it
      return null
    }

    // remap to correct action
    if (
      isAtRoute('marketing/offers/pay-via-fp-app/add') &&
      actType === ActivityTypes.marketing.ADD_OG_OFFER
    ) {
      return ActivityTypes.marketing.ADD_PAY_VIA_FP_OFFER
    }

    return actType
  }

  /**
   *
   * @param {*} actType
   * @param {*} config
   * @returns
   */
  overwriteLogisticAction(actType, config) {
    const { url } = config
    if (
      actType === ActivityTypes.logistics.VIEW_VEHICLES &&
      isAtRoute('logistics/vehicle-planning')
    ) {
      return ActivityTypes.logistics.VIEW_VEHICLE_PLANNING
    }
    if (
      actType === ActivityTypes.logistics.VIEW_TRIPS &&
      /logistics-service\/trip\?id/.test(url)
    ) {
      return ActivityTypes.logistics.VIEW_TRIP_DETAIL
    }

    if (
      actType === ActivityTypes.logistics.VIEW_TRIPS &&
      isAtRoute('logistics/trips/')
    ) {
      return null
    }

    if (
      [
        ActivityTypes.logistics.VIEW_TRIPS,
        ActivityTypes.settings.VIEW_DELIVERY_AREA,
        ActivityTypes.settings.VIEW_STORES,
      ].includes(actType) &&
      isAtRoute('logistics/trip-planner')
    ) {
      // only use trip-eta because it's will spawn 3 VIEW_ACTION for VIEW_TRIP_PLANNER
      if (/logistics-service\/trip-eta/.test(url)) {
        return ActivityTypes.logistics.VIEW_TRIP_PLANNERS
      }
      return null
    }

    return actType
  }

  /**
   * @param {*} actType
   * @param {*} config
   */
  checkRmsApiCall(actType, config) {
    if (actType === ActivityTypes.rewards.ADD_REWARD_STORE) {
      const { data } = config
      // Check if bulk upload
      if (data instanceof FormData) {
        return ActivityTypes.rewards.BULK_ADD_REWARD_STORE
      }
    }

    if (
      [ActivityTypes.rewards.VIEW_REWARD_CATEGORIES].includes(actType) &&
      isRouteMatch('rewards/partners')
    ) {
      // just an aditional API call, not main action. So drop it
      return null
    }

    if (
      [
        ActivityTypes.rewards.VIEW_REWARD_PARTNERS,
        ActivityTypes.rewards.VIEW_REWARD_CATEGORIES,
        ActivityTypes.catalogue.VIEW_CATEGORIES,
      ].includes(actType) &&
      isRouteMatch(
        'rewards/catalogue',
        'rewards/stores',
        'rewards/promotions',
        'rewards/challenges'
      )
    ) {
      // just an aditional API call, not main action. So drop it
      return null
    }

    return actType
  }

  /**
   * @param {*} actType
   * @param {*} config
   * @returns
   */
  checkCustomChallengeEvent(actType, config) {
    if (actType === ActivityTypes.rewards.EDIT_CHALLENGE) {
      const { data } = config
      try {
        const payload = JSON.parse(data)
        const updatedFields = Object.keys(payload)

        // User is updating challenge detail
        if (updatedFields.length > 1) {
          return actType
        }

        return ActivityTypes.rewards.DISABLE_CHALLENGE
      } catch (err) {
        return null
      }
    }
    if (
      actType === ActivityTypes.rewards.VIEW_CHALLENGE_DETAIL &&
      isAtRoute('rewards/challenges/add')
    ) {
      return ActivityTypes.rewards.CLONE_CHALLENGE
    }
    return actType
  }

  /**
   * @param {*} actType
   * @param {*} config
   * @returns
   */
  checkForHrActivity(actType, config) {
    const result = actType
    const { method, url } = config
    if (isAtRoute('hr/employees') && /account-service\/employee/.test(url)) {
      switch (method) {
        case 'put':
          return ActivityTypes.hr.EDIT_EMPLOYEE
        case 'post':
          return ActivityTypes.hr.ADD_EMPLOYEE
        case 'get':
          return ActivityTypes.hr.VIEW_EMPLOYEE
        case 'delete':
          return ActivityTypes.hr.DELETE_EMPLOYEE
        default:
          return null
      }
    }
    if (isAtRoute('hr/designations') && method === 'get') {
      return ActivityTypes.hr.VIEW_DESIGNATION
    }
    return result
  }

  /**
   * @param {*} actType
   * @param {*} config
   * @returns
   */
  checkForCatalogueBulkUploadJobs(actType, config) {
    const result = actType
    if (
      window.location.pathname.includes('catalogue/batch-upload-jobs') &&
      config.method === 'post'
    ) {
      /**
       * Simple return Bulk_Upload if method is in post regardless
       * of jobtype bulk upload..
       * We are getting the Jobtype value in the body or data attribute from the
       * actionPayload object
       */
      return ActivityTypes.catalogue.BULK_UPLOAD
    }
    return result
  }

  /**
   *
   * @param {*} actType
   * @param {*} config
   * @returns
   */
  checkForStockOverrideGetType(actType, config) {
    let result = actType
    if (actType === ActivityTypes.catalogue.VIEW_STOCK_OVERRIDES) {
      const url = config.url

      const urlParams = new URLSearchParams(new URL(url).search)
      const { type } = Object.fromEntries(urlParams)
      if (type === 'PRODUCT') {
        result = actType + '_Product'
      }
      if (type === 'CATEGORY') {
        result = actType + '_Category'
      }
    }
    return result
  }

  /**
   * This function will check if the backend endpoint use is "/account-service/store/*" PUT method.
   * Since this endpoint is used in 2 different use case, one is for edit batch picking queues related job
   * and also editing the store entity in general, we will test out the endpoint calls plus the
   * current user location to match to the right action type.
   * @param {*} actType
   * @param {*} config
   */
  checkForStoreRelatedPutActionType(actType) {
    if (
      actType === ActivityTypes.operations.EDIT_BATCH_PICKING_QUEUES &&
      isAtRoute('/settings/stores')
    ) {
      /**
       * Check the URL router location
       */
      return ActivityTypes.settings.EDIT_STORES
    }
    return actType
  }

  checkForProductHideShowAction(actType, config) {
    if (actType === ActivityTypes.catalogue.EDIT_SINGLE_PRODUCT) {
      const { data } = config
      try {
        const payload = JSON.parse(data)

        const keys = Object.keys(payload)

        const istTogglingStore =
          keys.length === 2 && keys.includes('id') && keys.includes('status')

        if (istTogglingStore) {
          const { status } = payload
          switch (status) {
            case 'HIDDEN':
              return ActivityTypes.catalogue.HIDE_SINGLE_PRODUCT
            case 'ENABLED':
              return ActivityTypes.catalogue.SHOW_SINGLE_PRPODUCT
            default:
              throw new Error('no status key in body payload')
          }
        }
      } catch (err) {
        return actType
      }
    }
    return actType
  }

  /**
   * This function check if the default actype === 'View_Order_Process_Setting',
   * will match the router location . If location is from '/settings/slots',
   * will defined act type as View_Order_Slots instead of default "View_Order_Process_Setting".
   * This is because both action type are using the similar endpoint to render
   * the page component
   * @param {*} actType
   * @param {*} config
   */
  checkForOrderSlotViewActionType(actType) {
    if (
      actType === 'View_Order_Process_Setting' &&
      isAtRoute('/settings/slots')
    ) {
      return null
    }
    return actType
  }

  /**
   * Thsi function check if the endpoint called is 'config-service/meta-data' and
   * if it was calling from location '/settings/extensions/.*' page...
   * If both are true, user action are determined as Viewing Custom Field Page
   * @param {*} actType
   * @param {*} config
   */
  checkForCustomFieldViewAction(actType, config) {
    const url = window.location.pathname
    if (
      config.url.includes('/config-service/meta-data') &&
      url.includes('/settings/extensions')
    ) {
      if (config.method === 'get') {
        return ActivityTypes.settings.VIEW_CUSTOM_FIELDS
      }
      if (config.method === 'put') {
        return ActivityTypes.settings.EDIT_CUSTOM_FIELDS
      }
    }
    return actType
  }

  /**
   *
   * @param {*} config
   * @returns
   */
  getActivityType(config) {
    const url = config.url
    const method = config.method

    const endpointPath = new URL(url).pathname

    let result = null
    for (const [key, obj] of Object.entries(ActivityMaps)) {
      if (new RegExp(key).test(endpointPath)) {
        result = get(obj, method) ?? null
      }
    }

    /**
     * Need check for viewing product, make sure that the route location
     * is coming from /catalogue/products because some routes do used the same
     *  endpoint call to retrived some information of a product
     */
    if (
      result !== null &&
      result.includes(ActivityTypes.catalogue.VIEW_PRODUCTS) &&
      window.location.pathname !== '/catalogue/products'
    ) {
      return null
    }
    return result
  }

  /**
   * Push record into the actionRecords list to be process later
   * @param {*} payload
   */
  addAction(payload) {
    const list = this.actionRecords
    list.push(payload)
    this.actionRecords = [...list]
  }

  /**
   * @Return {
   *    body: null | object,
   *    query : null | object
   * }
   * @param {*} config
   */
  extractActionPayload(config) {
    const endpointPath = new URL(config.url).pathname

    const payload = {}

    /**
     *@Step 1 extract data for 'body', default to null if empty
     */
    let jsonBody = config.data
    try {
      jsonBody = JSON.parse(config.data)
    } catch (_) {
      /**
       * leave it as it is if teh string values cannot parse as json,
       * will pass whatever format config.data gave
       */
    }
    payload['body'] = isEmpty(jsonBody) ? null : jsonBody

    /**
     * Check if the endpoint are order detail related, extract the
     * order id from the URL param string and append to the body attribute of
     * the actionPayload
     */
    if (new RegExp('/order-service/order/.*').test(endpointPath)) {
      const a = endpointPath.split('/')
      const orderId = a[a.length - 1]
      payload['body'] = {
        order_id: orderId,
      }
    }

    /**
     * @Step2 extract data for 'query', default to null if empty
     */
    const searchQuery = new URLSearchParams(new URL(config.url).search)
    const query = Object.fromEntries(searchQuery)
    payload['query'] = isEmpty(query) ? null : query

    return JSON.stringify(payload)
  }

  /**
   *
   */
  run() {
    this.actionTrailInterval = setInterval(() => {
      this.sendActionLogs()
    }, this.PERIOD)
  }

  /**
   *
   */
  clearProcessInterval() {
    try {
      if (this.actionTrailInterval !== null) {
        this.actionTrailInterval.clearInterval()
      }
    } catch (err) {
      return
    }
  }

  /**
   * This function will invoke only when tab is close
   * or page is refresh.. to ensure no data loss, we will
   * persist the unprocessed record from the actionRecords list into
   * the localStorage.
   * The next time when the app initialized, will check if theres leftover
   * data in localstorage, put it back into the in-memory <actionRecords> list
   *
   */
  cacheRecordIntoLocalStorage(dataToPersist) {
    if (dataToPersist.length > 0) {
      window.localStorage.setItem(
        this.ACTION_TRAIL_KEY,
        JSON.stringify(dataToPersist)
      )
    }
  }
}

const actiontrailObject = new ActionTrail()

window.onbeforeunload = function () {
  actiontrailObject.cacheRecordIntoLocalStorage([
    ...actiontrailObject.actionRecords,
    ...actiontrailObject.standbyList,
  ])
}

const run = actiontrailObject.run
const setSplit = actiontrailObject.setSplit
const captureActionViaAxios = actiontrailObject.captureActionViaAxios
const sendActionLogs = actiontrailObject.sendActionLogs
const transformActionTrailsPayload =
  actiontrailObject.transformActionTrailsPayload
const isActionTrailEnabled = actiontrailObject.isActionTrailEnabled

const ACTION_TRAIL_KEY = actiontrailObject.ACTION_TRAIL_KEY

export {
  actiontrailObject,
  sendActionLogs,
  run,
  setSplit,
  captureActionViaAxios,
  ACTION_TRAIL_KEY,
  transformActionTrailsPayload,
  isActionTrailEnabled,
}
