import React, { Component } from 'react'
import partial from 'lodash/partial'
import padStart from 'lodash/padStart'
import uniq from 'lodash/uniq'
import chunk from 'lodash/chunk'

import Table, { Header, Row, Cell } from '../../../../components/Table'
import Loader from '../../../../components/Loader'
import API, { UNAUTHORISED_STATUS } from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import {
  JobConfig,
  MAX_STORE_LENGTH,
  buildProductNameMap,
  buildStoreMap,
} from '../BatchJobsConfig'
const BATCHING_SIZE = 300

const getIndex = (headers, header) => {
  return headers
    .map(h => h.replace(/ /g, '').toLowerCase())
    .indexOf(header.toLowerCase())
}

// Why not just !value? Because 0 isn't empty.
const isEmpty = value => value === null || value === '' || value === undefined
const isColumnPresent = value => value || value === ''

const getTableData = (results, jobName, storeMap, productMap) => {
  if (results.length === 0) {
    return []
  }
  const CSV_HEADERS = JobConfig[jobName].csvHeaders
  const fileHeaders = results[0]

  const getIndexPartial = partial(getIndex, fileHeaders)
  const skuIndex = getIndexPartial(CSV_HEADERS.SKU.header)
  const storeIndex = getIndexPartial(CSV_HEADERS.STORE_ID.header)
  const channelIndex = getIndexPartial(CSV_HEADERS.CHANNEL.header)
  const statusIndex = getIndexPartial(CSV_HEADERS.STATUS.header)
  const maxPurchaseQtyIndex = getIndexPartial(
    CSV_HEADERS.MAX_PURCHASE_QTY.header
  )
  const storeBulkIndex = getIndexPartial(CSV_HEADERS.STORE_BULK.header)
  const unlimitedCheckIndex = getIndexPartial(
    CSV_HEADERS.UNLIMITED_CHECK.header
  )
  const stockBufferIndex = getIndexPartial(CSV_HEADERS.STOCK_BUFFER.header)

  const rows = results.slice(1)

  try {
    const tableData = rows.map(row => {
      const storeId = isNaN(row[storeIndex])
        ? row[storeIndex]
        : padStart(row[storeIndex], MAX_STORE_LENGTH, '0')
      const storeName = isNaN(storeId) ? storeId : storeMap[storeId]
      const product = productMap[row[skuIndex]] || {}
      return {
        sku: row[skuIndex],
        productName: product.name,
        displayUnit: product.displayUnit,
        storeId: storeId,
        storeName: storeName,
        channel: row[channelIndex],
        status: row[statusIndex],
        maxPurchaseQty: row[maxPurchaseQtyIndex],
        storeBulk: row[storeBulkIndex],
        unlimitedCheck: row[unlimitedCheckIndex],
        stockBuffer: row[stockBufferIndex],
      }
    })
    return tableData
  } catch (e) {
    console.error('Error while generating review data', e)
    return []
  }
}

export default class StoreSpecificAttributeReview extends Component {
  constructor() {
    super()
    this.state = {
      productMap: {},
      storeMap: {},
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    Promise.all([this.getStores(), this.getProductsBatch(this.props.results)])
      .catch(e => {
        this.setState({
          error: e,
        })
        if (e.code === UNAUTHORISED_STATUS) {
          throw e
        }
      })
      .finally(() =>
        this.setState({
          loading: false,
        })
      )
  }

  getProductsBatch(results = []) {
    const primarySkus = results.slice(1).map(row => row[0])
    const skus = uniq(primarySkus)
    if (skus.length) {
      return Promise.all(chunk(skus, BATCHING_SIZE).map(this.getProducts)).then(
        responses => {
          const products = responses.reduce((acc, response) => {
            const prds = response.data ? response.data.product || [] : []
            return [...acc, ...prds]
          }, [])
          this.setState({
            productMap: buildProductNameMap(products) || {},
          })
        }
      )
    }
    return Promise.resolve()
  }

  getProducts(skus) {
    const productApi = new API({
      url: `/catalogue-service/product`,
    })
    if (skus.length) {
      return productApi.get({ paginate: 'false', clientId: skus.slice() })
    }
    return Promise.resolve()
  }

  getStores() {
    const productApi = new API({
      url: `/account-service/store`,
    })
    return productApi.get({ paginate: 'false' }).then(response => {
      this.setState({
        storeMap: buildStoreMap(response.data.store) || {},
      })
    })
  }

  // getRowErrors :: Array, Object -> Boolean
  getRowErrors(tableData, csvHeaders) {
    return tableData.map(row => {
      // const isSkuPresent = csvHeaders.SKU.validate(row.sku)
      const isValidSku = csvHeaders.PRODUCT_NAME.validate(row.productName)
      // const isStorePresent = csvHeaders.STORE_ID.validate(row.storeId)
      const isValidStoreId = csvHeaders.STORE_NAME.validate(
        row.storeId,
        row.storeName
      )
      const isValidStatus = csvHeaders.STATUS.validate(row.status)
      const isValidMaxPurchaseQty = csvHeaders.MAX_PURCHASE_QTY.validate(
        row.maxPurchaseQty
      )
      const isValidStoreBulk = csvHeaders.STORE_BULK.validate(row.storeBulk)
      const isValidUnlimitedCheck = csvHeaders.UNLIMITED_CHECK.validate(
        row.unlimitedCheck
      )
      const isValidStockBuffer = csvHeaders.STOCK_BUFFER.validate(
        row.stockBuffer
      )

      const isSomeOptionalFieldPresent =
        !isEmpty(row.maxPurchaseQty) ||
        isColumnPresent(row.maxPurchaseQty) ||
        !isEmpty(row.storeBulk) ||
        isColumnPresent(row.storeBulk) ||
        !isEmpty(row.unlimitedCheck) ||
        !isEmpty(row.stockBuffer) ||
        !isEmpty(row.status)

      const isGivenOptionalFieldsValid =
        (isEmpty(row.maxPurchaseQty) || isValidMaxPurchaseQty) &&
        (isEmpty(row.storeBulk) || isValidStoreBulk) &&
        (isEmpty(row.unlimitedCheck) || isValidUnlimitedCheck) &&
        (isEmpty(row.stockBuffer) || isValidStockBuffer) &&
        (isEmpty(row.status) || isValidStatus)

      return (
        !isValidSku ||
        !isValidStoreId ||
        !(isSomeOptionalFieldPresent && isGivenOptionalFieldsValid)
      )
    })
  }

  render() {
    const { results = [], jobName, actions } = this.props
    const { storeMap, productMap, error, loading } = this.state
    const tableData = getTableData(results, jobName, storeMap, productMap)
    const headers = JobConfig[jobName].getTableHeaders()
    const validationErrors = this.getRowErrors(
      tableData,
      JobConfig[jobName].csvHeaders
    )

    return loading ? (
      <Loader />
    ) : (
      <div className="review-table">
        {error && <div className="error">{getMessage('error.server')}</div>}
        {!loading && validationErrors.some(Boolean) && (
          <div className="error">
            {getMessage('category.bulk-upload.error')}
          </div>
        )}
        <div className="table-container">
          <Table tableDynamic={false}>
            <Header items={headers} />
            {tableData.map((row, index) => (
              <Row
                key={`${row.sku}-${index}`}
                className={
                  !loading && validationErrors[index] ? 'row-error' : ''
                }
              >
                <Cell>
                  <span className="sku">{row.sku}</span>
                </Cell>
                <Cell>
                  <span className="product-name">{row.productName}</span>
                  {` `}
                  <span className="display-unit muted">{row.displayUnit}</span>
                </Cell>
                <Cell>
                  <span size="md" className="store-id">
                    {row.storeId}
                  </span>
                </Cell>
                <Cell>
                  <span className="store-name">{row.storeName}</span>
                </Cell>
                <Cell>
                  <span size="md" className="channel">
                    {row.channel}
                  </span>
                </Cell>
                <Cell>
                  <span className="status">{row.status}</span>
                </Cell>
                <Cell>
                  <span className="max-purchase-qty">{row.maxPurchaseQty}</span>
                </Cell>
                <Cell>
                  <span className="store-bulk">{row.storeBulk}</span>
                </Cell>
                <Cell>
                  <span className="unlimited-check">{row.unlimitedCheck}</span>
                </Cell>
                <Cell>
                  <span className="stock-buffer">{row.stockBuffer}</span>
                </Cell>
              </Row>
            ))}
          </Table>
        </div>
        {actions(validationErrors.some(Boolean))}
      </div>
    )
  }
}
