/* eslint-disable react/forbid-prop-types */
import React from 'react'

export const PaymentsContext = React.createContext({})

const PaymentsContextProvider = ({ children }) => {
  const [contextValue, setContextValue] = React.useState({
    paymentsContext: { resultsFetched: false },
    updatePaymentsContext: (value = {}) => {
      setContextValue({
        ...contextValue,
        paymentsContext: { ...contextValue.paymentsContext, ...value },
      })
    },
  })

  return (
    <PaymentsContext.Provider value={contextValue}>
      {children}
    </PaymentsContext.Provider>
  )
}

export default PaymentsContextProvider
