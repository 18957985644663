import React from 'react'
import { Input } from 'components/Form'
import '../style.css'

function EditOrderItem({ item, updateOrderItem }) {
  return (
    <small>
      <Input
        type="number"
        name="edit-oi-quantity"
        testid="edit-oi-quantity-input"
        value={item.deliveredQuantity || 0}
        onChange={(e) => updateOrderItem(e, item.item.id)}
      />
    </small>
  )
}
export default EditOrderItem
