import React from 'react'
import PropTypes from 'prop-types'
import { BaseForm, Select } from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'
import '../../Details/style.css'
import editIcon from '../../../../../pages/catalogue/Products/Form/edit.svg'
import { getCustomPrintableTime } from '../../../../../lib/datetime'
import { toTitleCase } from '../../../../../lib/commonlyused'
import fpLogo from '../../../../../components/SmartstoreHeader/FP_logo.png'
import { RIDER_STATUSES } from '../Details.enum'
import { SuccessPopup, ReasonPopup } from '../../../../../components/Popup'
import Refresh from '../OrderSummary/OrderSummaryHeader/refresh.svg'
import PendingIcon from '../../../../../components/Popup/TablePopup/pending.svg'
import { cancelDeliveryReasons } from '../OrderSummary/Reasons'

class KeyInfo extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      masterStatusItem: this.props.masterStatus,
      masterStatusItemValue: this.props.masterStatus,
      riderStatusItemValue: RIDER_STATUSES.RIDER_ACCEPTED,
      editRiderStatus: false,
      submitting: true,
      changeReasonSubmitted: true,
      showRiderPopup: false,
      showCancelDeliveryConfirmPopup: false,
      showCancelDeliverySuccessPopup: false,
      showCancelDeliveryReasonPopup: false,
      cancelDeliveryReasonSelectValue: '',
    }
  }

  render() {
    const {
      orderId,
      placedTime,
      platform,
      fulfillmentMethod,
      originalDeliveryTime,
      originalPickupTime,
    } = this.props
    const riderStatusItems = [
      {
        currentStatus: RIDER_STATUSES.NOT_ASSIGNED,
        changedStatus: RIDER_STATUSES.REQUESTING_RIDER,
      },
      {
        currentStatus: RIDER_STATUSES.REQUESTING_RIDER,
        changedStatus: RIDER_STATUSES.RIDER_ACCEPTED,
      },
      {
        currentStatus: RIDER_STATUSES.RIDER_ACCEPTED,
        changedStatus: RIDER_STATUSES.NEARBY_MERCHANT,
      },
      {
        currentStatus: RIDER_STATUSES.NEARBY_MERCHANT,
        changedStatus: RIDER_STATUSES.ARRIVED_AT_MERCHANT,
      },
      {
        currentStatus: RIDER_STATUSES.ARRIVED_AT_MERCHANT,
        changedStatus: RIDER_STATUSES.DELIVERY_IN_PROGRESS,
      },
      {
        currentStatus: RIDER_STATUSES.DELIVERY_IN_PROGRESS,
        changedStatus: RIDER_STATUSES.NEARBY_DESTINATION,
      },
      {
        currentStatus: RIDER_STATUSES.NEARBY_DESTINATION,
        changedStatus: RIDER_STATUSES.DELIVERED,
      },
    ]
    const riderChangedStatuses = currentStatus =>
      riderStatusItems
        .filter(item => item.currentStatus === currentStatus)
        .map(item => item.changedStatus)
    const handleSaveRiderStatus = () => {
      this.setState({
        riderStatusItemValue: this.state.riderStatusItem,
        editRiderStatus: false,
        showRiderPopup: true,
      })
    }

    const handleCancelRiderStatus = () => {
      this.setState({ editRiderStatus: false })
    }

    const closeRiderPopup = () => {
      this.setState({ editRiderStatus: false, showRiderPopup: false })
      this.props.history.push(this.props.location.pathname)
    }
    const closeCancelDeliverySuccessPopup = () => {
      this.setState({
        editRiderStatus: false,
        showRiderPopup: false,
        showCancelDeliverySuccessPopup: false,
      })
      this.props.history.push(this.props.location.pathname)
    }
    const closeCancelDeliveryConfirmPopup = () => {
      this.setState({
        showCancelDeliveryConfirmPopup: false,
      })
    }
    const handleCancelDelivery = () => {
      this.setState({
        editRiderStatus: false,
        showRiderPopup: false,
        showCancelDeliveryConfirmPopup: true,
      })
    }
    const handleCancelDeliveryReasonPopupBack = () => {
      this.setState({
        showCancelDeliveryReasonPopup: false,
        showCancelDeliveryConfirmPopup: true,
      })
    }
    const handleCancelDeliveryReasonPopupSubmit = () => {
      this.setState({
        showCancelDeliverySuccessPopup: true,
        showCancelDeliveryReasonPopup: false,
      })
    }
    const handleCancelDeliveryReasonPopupChange = e => {
      this.setState({
        cancelDeliveryReasonSelectValue: e,
        changeReasonSubmitted: false,
      })
    }
    const handleConfirmSubmit = () => {
      this.setState({
        showCancelDeliveryConfirmPopup: false,
        showCancelDeliveryReasonPopup: true,
      })
    }

    return (
      <div className="key-info">
        <b>{orderId}</b>
        <div className="master-status-container">
          {getMessage('qc.orderInfo.MasterStatus')}:{' '}
          <div>
            <span className="master-status">
              {this.state.masterStatusItemValue}
            </span>
          </div>
        </div>
        <div>
          {getMessage('qc.orderInfo.PlacedTime')}:{' '}
          <b>{getCustomPrintableTime(placedTime)}</b>
        </div>
        <div className="platform-detail">
          {getMessage('qc.orderInfo.Fulfillment')}/
          {getMessage('qc.orderInfo.Platform')}:{' '}
          <b>
            {fulfillmentMethod === 'self_collect'
              ? getMessage('qc.orderInfo.takeaway')
              : toTitleCase(fulfillmentMethod.split('_').join(' '))}
          </b>
          {platform === 'FAIRPRICE' && (
            <img src={fpLogo} alt="Fairprice logo" className="fairprice-logo" />
          )}
        </div>
        {fulfillmentMethod === 'self_collect' && (
          <div>
            {getMessage('qc.orderInfo.OriginalPickupTime')}:{' '}
            <b>
              {originalPickupTime
                ? getCustomPrintableTime(originalPickupTime)
                : getMessage('qc.orderInfo.NotEstimated')}
            </b>
          </div>
        )}
        {fulfillmentMethod !== 'self_collect' && (
          <div>
            {getMessage('qc.orderInfo.OriginalDeliveryTime')}:{' '}
            <b>
              {originalDeliveryTime
                ? getCustomPrintableTime(originalDeliveryTime)
                : getMessage('qc.orderInfo.NotEstimated')}
            </b>
          </div>
        )}
        {fulfillmentMethod === 'deliveries' && (
          <div className="rider-status-container">
            {getMessage('qc.orderInfo.RiderStatus')}:{' '}
            {!this.state.editRiderStatus ? (
              <div>
                <span className="rider-status">
                  {this.state.riderStatusItemValue}
                </span>
                {this.state.riderStatusItemValue !== RIDER_STATUSES.CANCELLED &&
                  this.state.riderStatusItemValue !==
                    RIDER_STATUSES.DELIVERED &&
                  this.state.riderStatusItemValue !==
                    RIDER_STATUSES.NOT_ASSIGNED && (
                    <img
                      src={editIcon}
                      className="edit-status"
                      onClick={() => this.setState({ editRiderStatus: true })}
                      alt="edit"
                      data-testid="keyinfo-edit-icon"
                    />
                  )}
              </div>
            ) : (
              <div className="custom-select">
                <Select
                  data-testid="rider-status-select"
                  placeholder={getMessage(
                    'qc.order.filters.orderState.placeholder'
                  )}
                  options={
                    riderChangedStatuses &&
                    riderChangedStatuses(this.state.riderStatusItemValue).map(
                      elm => {
                        return {
                          text: elm,
                          value: elm,
                        }
                      }
                    )
                  }
                  value={this.state.riderStatusItem}
                  onChange={e =>
                    this.setState({
                      riderStatusItem: e,
                      submitting: false,
                    })
                  }
                />
              </div>
            )}
          </div>
        )}
        {!this.state.editRiderStatus &&
          fulfillmentMethod === 'deliveries' &&
          this.state.riderStatusItemValue !== RIDER_STATUSES.DELIVERED && (
            <div className="key-info-buttons cancel-delivery">
              <button
                type="button"
                className="primary button"
                data-testid="cancel-delivery"
                onClick={handleCancelDelivery}
              >
                {getMessage('qc.orderInfo.CancelDelivery')}
              </button>
            </div>
          )}
        {this.state.editRiderStatus && (
          <div className="key-info-buttons">
            <button
              type="button"
              className="primary button"
              onClick={handleSaveRiderStatus}
              disabled={this.state.submitting}
              data-testid="rider-save"
            >
              {getMessage('qc.orderInfo.Save')}
            </button>
            <button
              type="button"
              className="button"
              data-testid="rider-cancel"
              onClick={handleCancelRiderStatus}
            >
              {getMessage('qc.orderInfo.Cancel')}
            </button>
          </div>
        )}
        <SuccessPopup
          {...this.props}
          show={this.state.showRiderPopup}
          close={closeRiderPopup}
          icon={Refresh}
          heading={getMessage('qc.orderInfo.riderPopup.heading')}
          description={getMessage('qc.orderInfo.riderPopup.description')}
          buttonText={getMessage('qc.orderSummary.refreshpopup.button')}
        />
        <ReasonPopup
          {...this.props}
          show={this.state.showCancelDeliveryReasonPopup}
          close={handleCancelDeliveryReasonPopupBack}
          heading={getMessage(
            'qc.orderInfo.cancelDeliveryPopup.reasonPopup.heading'
          )}
          question={getMessage(
            'qc.orderInfo.cancelDeliveryPopup.reasonPopup.question'
          )}
          selectReason={getMessage('qc.orderSummary.reasonpopup.SelectReason')}
          submitButtonText={getMessage(
            'qc.orderSummary.reasonpopup.button.submit'
          )}
          handleSubmit={handleCancelDeliveryReasonPopupSubmit}
          backButtonText={getMessage('qc.orderSummary.reasonpopup.button.back')}
          handleBack={handleCancelDeliveryReasonPopupBack}
          reasons={cancelDeliveryReasons}
          state={this.state}
          value={this.state.cancelDeliveryReasonSelectValue}
          changeReason={e => handleCancelDeliveryReasonPopupChange(e)}
        />
        <SuccessPopup
          {...this.props}
          show={this.state.showCancelDeliverySuccessPopup}
          close={closeCancelDeliverySuccessPopup}
          heading={getMessage('qc.orderInfo.cancelDeliveryPopup.heading')}
          description={getMessage(
            'qc.orderInfo.cancelDeliveryPopup.description'
          )}
          buttonText={getMessage('qc.orderInfo.cancelDeliveryPopup.button')}
        />
        <SuccessPopup
          {...this.props}
          show={this.state.showCancelDeliveryConfirmPopup}
          close={closeCancelDeliveryConfirmPopup}
          heading={getMessage('qc.orderInfo.confirmDeliveryPopup.heading')}
          description={getMessage(
            'qc.orderInfo.confirmDeliveryPopup.description'
          )}
          icon={PendingIcon}
          confirmPopup={true}
          handleSubmit={handleConfirmSubmit}
          dismissButtonText={getMessage(
            'qc.orderInfo.confirmDeliveryPopup.dismiss.button'
          )}
          buttonText={getMessage('qc.orderInfo.confirmDeliveryPopup.button')}
        />
      </div>
    )
  }
}

KeyInfo.propTypes = {
  orderId: PropTypes.string,
  masterStatus: PropTypes.string,
  placedTime: PropTypes.string,
  platform: PropTypes.string,
}

export default KeyInfo
