import React, { Component, useContext } from 'react'
import moment from 'moment'
import {
  SingleDatePicker,
  Input,
  MultiSelect,
  Searchable,
  Select,
} from '../Form'
import API from '../../lib/api'
import { getMessage } from '../../lib/translator'
import { camelToReading, storeSelection } from '../../lib/commonlyused'
import DeleteIcon from '../../pages/catalogue/Categories/delete.svg'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'

import './style.css'

const Components = {
  datetime: {
    component: SingleDatePicker,
  },
  int: {
    component: Input,
    type: 'number',
  },
  float: {
    component: Input,
    type: 'number',
    step: '0.01',
  },
  string: {
    component: Input,
    type: 'text',
  },
  CSV: {
    component: MultiSelect,
  },
  CustomersMappedToTag: {
    component: Searchable,
    config: {
      label: getMessage('product.filters.tag.heading'),
      placeholder: getMessage('product.filters.tag.placeholder'),
      searchUrl: '/ef-customer-core/tags',
      valueKey: 'id',
      nameKey: 'name',
      searchKey: 'name',
      multiple: true,
      transformResponse: (response) => response.data.tags,
    },
  },
}

export function formatDataBeforeSubmit(data, isDel) {
  const values = JSON.parse(JSON.stringify(data))
  const userSet = values.userSet
  if (userSet) {
    userSet.type = 'SEGMENTS'
  }
  if (userSet && userSet.data) {
    userSet.data.forEach((segment) => {
      if (segment.id === 'CustomersMappedToTag') {
        segment.tagId = Array.isArray(segment.tags)
          ? segment.tags.map((tag) => tag.id).join(',')
          : segment.tagId
        !isDel && delete segment.tags
      }
    })
    values.userSet = userSet
  }
  return values
}

class SegmentsForm extends Component {
  constructor(props) {
    super(props)
    this.selectSegment = this.selectSegment.bind(this)
    this.getTagDetails = this.getTagDetails.bind(this)
    this.deleteSegment = this.deleteSegment.bind(this)
  }

  componentWillUnmount() {
    this.tagAPi && this.tagAPi.cancel()
    this.segmentsApi && this.segmentsApi.cancel()
    this.customerSegmentationServiceApi &&
      this.customerSegmentationServiceApi.cancel()
  }

  componentDidMount() {
    const parent = this.props._this
    this.setState({
      segmentsLoaded: false,
    })
    const values = JSON.parse(JSON.stringify(parent.state.values))
    if (values && values.userSet && values.userSet.data) {
      this.getTagDetails()
    } else {
      this.setState({
        tagsLoaded: true,
      })
    }

    // TODO: Use search by name after API starts supporting it instead of getting first 20 segments
    this.segmentsApi = new API({ url: '/promo-service/segment' })
    this.segmentsApi
      .get()
      .then((response) => {
        const segments = response.data.segment || []
        this.segments = {}
        this.segmentOptions = segments.map((segment) => {
          this.segments[segment.name] = segment.variables
          return {
            text: getMessage(camelToReading(segment.name)),
            value: segment.name,
          }
        })
        this.setState({
          segmentsLoaded: true,
        })
      })
      .catch((e) => console.log(e))

    if (values.segmentationIds?.length > 0) {
      this.customerSegmentationServiceApi = new API({ url: `/segments` })
      this.customerSegmentationServiceApi
        .get({ id: values.segmentationIds })
        .then((res) => {
          values.segmentationIds = res?.data || []
          parent.setState({
            values,
          })
        })
        .catch((e) => console.log(e))
    }
  }

  getTagDetails() {
    const parent = this.props._this
    let values = JSON.parse(JSON.stringify(parent.state.values))
    values = formatDataBeforeSubmit(values, true)

    let params = ''
    const tags =
      values &&
      values.userSet &&
      values.userSet.data &&
      values.userSet.data.filter(
        (segment) => segment.id === 'CustomersMappedToTag'
      )
    tags &&
      tags.forEach((tag) => {
        tag.tagId &&
          tag.tagId.split(',').forEach((tagId) => {
            params += `id=${Number(tagId)}&`
          })
      })
    this.tagAPi = new API({
      url: `/ef-customer-core/tags?${params}`,
    })
    if (params) {
      this.tagAPi.get({ paginate: 'false', limit: 10000 }).then((response) => {
        const resTagData = response.data.tags || []
        if (resTagData.length > 0) {
          values &&
            values.userSet &&
            values.userSet.data &&
            values.userSet.data.forEach((segment) => {
              if (segment.id === 'CustomersMappedToTag') {
                let tagDetails = []
                const tagIds = segment.tagId && segment.tagId.split(',')
                tagDetails =
                  tagIds &&
                  tagIds
                    .map((id) => {
                      return resTagData.filter(
                        (tag) => tag.id === Number(id)
                      )[0]
                    })
                    .filter(Boolean)
                segment.tags = tagDetails
                delete segment.tagId
              }
            })
          parent.setState({
            values,
          })
          this.setState({
            tagsLoaded: true,
          })
        }
      })
    } else {
      this.setState({
        tagsLoaded: true,
      })
    }
  }

  selectSegment(segment) {
    const parent = this.props._this
    const values = Object.assign({}, parent.state.values)
    const newSegment = { id: segment }
    if (values && values.userSet && values.userSet.data) {
      values.userSet.data.splice(0, 0, newSegment)
    } else {
      if (values && !values.userSet) {
        values.userSet = {}
      }
      values.userSet.data = [newSegment]
    }
    parent.setState({
      values,
    })
  }

  deleteSegment(index) {
    const parent = this.props._this
    const values = Object.assign({}, parent.state.values)
    const validations = Object.assign({}, parent.state.validations)
    validations &&
      validations.userSet &&
      validations.userSet.data &&
      validations.userSet.data.splice(index, 1)
    values && values.userSet && values.userSet.data.splice(index, 1)
    parent.setState({
      values,
      validations,
    })
  }

  render() {
    const { newCustomerSegmentSelectionFlag, _this } = this.props
    const { state } = this
    const { tagsLoaded, segmentsLoaded } = state || {}
    const userSet =
      _this.state &&
      _this.state.values &&
      Object.assign({}, _this.state.values.userSet)
    const data = userSet ? userSet.data : ''

    return (
      <div className="segments-form">
        <div className="subheading">{getMessage('Segments')}</div>
        <div className="segments-container">
          <div className="coupon-section coupon-selection">
            {this.segmentOptions && (
              <Select
                name="segment-selector"
                label={getMessage('Segment')}
                placeholder={getMessage('Select Segment')}
                options={this.segmentOptions}
                onChange={(e) => this.selectSegment(e)}
              />
            )}
            {segmentsLoaded &&
              tagsLoaded &&
              data &&
              data.map((segment, segmentIndex) => {
                const id = segment.id
                const variables = this.segments[id]
                if (variables) {
                  return (
                    <div
                      className="segment-container"
                      key={`${segmentIndex}-container`}
                    >
                      <div className="segment-type">
                        <div>{getMessage(camelToReading(id))}</div>
                        <button
                          className="delete-button"
                          type="button"
                          onClick={() => this.deleteSegment(segmentIndex)}
                        >
                          <img src={DeleteIcon} alt="delete" />
                          <span>{getMessage('deleteText')}</span>
                        </button>
                      </div>
                      <div className="coupon-segment grid">
                        {Object.entries(variables)
                          .reverse()
                          .map(([key, type]) => {
                            let extraProps = {}
                            if (key === 'endDate') {
                              extraProps = {
                                allowAllDates: true,
                              }
                            } else {
                              extraProps = {
                                isOutsideRange: (day) => moment().diff(day) < 0,
                              }
                            }
                            const obj = Components[id] || Components[type]
                            const config = obj.config || {}
                            if (obj) {
                              const Comp = obj.component
                              return (
                                <div key={`${key} ${type} ${segmentIndex} div`}>
                                  <Comp
                                    name={`${key} ${type} ${segmentIndex}`}
                                    label={getMessage(`${camelToReading(key)}`)}
                                    placeholder={getMessage(
                                      `Enter ${camelToReading(key)}`
                                    )}
                                    type={obj.type}
                                    key={`${key} ${type} ${segmentIndex}`}
                                    options={storeSelection()}
                                    min={0}
                                    required
                                    step={obj.step || 1}
                                    {...extraProps}
                                    openDirection="up"
                                    {...config}
                                    {..._this.generateStateMappers({
                                      stateKeys: [
                                        'userSet',
                                        'data',
                                        segmentIndex,
                                        key === 'tagId' ? 'tags' : key,
                                      ],
                                      loseEmphasisOnFill: true,
                                    })}
                                  />
                                </div>
                              )
                            }
                            return null
                          })
                          .filter(Boolean)}
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div
                      className="segment-container"
                      key={`${segmentIndex}-div`}
                    >
                      <div className="segment-type">
                        <div>{getMessage(camelToReading(id))}</div>
                        <button
                          className="delete-button"
                          type="button"
                          onClick={() => this.deleteSegment(segmentIndex)}
                        >
                          <img src={DeleteIcon} alt="delete" />
                          <span>{getMessage('deleteText')}</span>
                        </button>
                      </div>
                    </div>
                  )
                }
              })}
          </div>
          {newCustomerSegmentSelectionFlag && (
            <div className="audience-segment-selection">
              {
                <Searchable
                  name="segment-io-audience"
                  className="segment-io-audience"
                  label={getMessage('offer.form.segmentio.audiences.label')}
                  placeholder={getMessage(
                    'offer.form.segmentio.audiences.label'
                  )}
                  openDirection="up"
                  searchUrl="/segments"
                  valueKey="id"
                  nameKey="name"
                  searchKey="name"
                  multiple={true}
                  transformResponse={(res) => res.data}
                  {..._this.generateStateMappers({
                    stateKeys: ['segmentationIds'],
                    loseEmphasisOnFill: true,
                  })}
                />
              }
            </div>
          )}
        </div>
        <div />
      </div>
    )
  }
}

const SegmentsFormWrapper = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  const newCustomerSegmentSelectionFlag =
    splits?.[SPLIT_FEATURES.NEW_CUSTOMER_SEGMENT_SELECTION]?.treatment === 'on'
  return (
    <SegmentsForm
      newCustomerSegmentSelectionFlag={newCustomerSegmentSelectionFlag}
      {...props}
    />
  )
}

export default SegmentsFormWrapper
