import React, { useEffect, useState, useRef, useMemo } from 'react';
import PopupModal from 'components/Popup/Modal';
import StepperForm from 'components/StepperForm';
import { getMessage } from 'lib/translator';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import API from 'lib/api';
import {
  PostalCodeInputStep,
  KmlFileInputStep,
  MapInputStep,
  RuleNameInputStep,
  TimeSlotInputStep,
  ZoneNameInputStep
} from './AddRuleSteps'
import {
  getPostalCodesArrayFromString,
  verifyNoDuplicatedPostalCodes,
  verifyPostalCodesHaveSixDigit,
  transformPayload,
  getLatLongFromPolygon
} from './helpers';
import {
  AddRuleOptionsEnum,
  AddRulePopupTitle,
  AddRuleFirstStepName,
  slotRuleTypes,
  days,
  slots,
} from './constants';
import { getSelectedStore } from 'lib/auth';
import { trim } from 'lodash';
import './style.scss';

// TODO: to retrive slots details from Backend when the endpoint is ready. Now create it in Frontend first
const createInitialBlockedTimeSlots = () => {
  const map = new Map();
  days.forEach(day => {
    slots.forEach(slot => {
      const key = `${day}|${slot}`;
      map.set(key, false);
    })
  })
  return map;
}

function AddRulePopup({
  setShowAddRuleFormPopup,
  showAddRuleFormPopup,
  refetchRules,
  selectedRuleType,
  setShowToast,
  selectedRuleForEdit,
  setSelectedRuleForEdit,
  setSelectedRuleType
 }) {
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showConfirmationToClosePopup, setShowConfirmationToClosePopup] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [apiError, setApiError] = useState(null);

  const [timeSlotBlockingPayload, setTimeSlotBlockingPayload] = useState(null);

  /* postal codes input states */
  const [postalCodeInputError, setPostalCodeInputError] = useState('');
  const [postalCodesText, setPostalCodesText] = useState('');
  const [isVerifyingPostalCodes, setIsVerifyingPostalCodes] = useState(false);

  /* states for zone setup (kml file and map drawing) */
  const [zonePayload, setZonePayload] = useState(null);
  const [hasMultipleZone, setHasMultipleZone] = useState(false);
  const [clusterName, setClusterName] = useState('');
  const [drawnZone, setDrawnZone] = useState([]);

  const timeSlotBlockingPayloadRef = useRef(null);

  useEffect(() => {
    const selectedStore = getSelectedStore();
    let ruleName = '';
    if (selectedRuleType && selectedRuleType === AddRuleOptionsEnum.BY_STORE) {
      // replace white space and non-alphanumeric with underscore (while keeping the underscore count to 1 in between words)
      // then, trim any leading or trailing underscores in the name (e.g. "Hyper Changi(*FFS)" => "Hyper_Changi_FFS")
      ruleName = trim(selectedStore.name.replace(/[^a-zA-Z0-9]+/g, '_'),'_');
    }

    let payloadDataToSet = {
      slotRuleType: '',
      orderType: 'DELIVERY',
      postalCodes: [],
      name: ruleName,
      storeId: '',
      blockedSlots: createInitialBlockedTimeSlots()
    };
    if (selectedRuleForEdit) {
      if (selectedRuleForEdit.slotRuleType === slotRuleTypes.BY_POSTAL_CODE) {
        payloadDataToSet = {
          ...payloadDataToSet,
          postalCodes: selectedRuleForEdit.metadata.postalCode.map(postalCode => ({ value: postalCode, isValid: true }))
        }

      } else if (selectedRuleForEdit.slotRuleType === slotRuleTypes.IS_CLUSTER) {
        setDrawnZone([
          {
            area: {
              locations: getLatLongFromPolygon(selectedRuleForEdit.metadata.polygon)
            },
            configType: 'POLYGON',
            name: selectedRuleForEdit.zone.name,
            tempId: selectedRuleForEdit.id
          }
        ])
        setClusterName(selectedRuleForEdit.zone.name);
        setZonePayload({
          name: selectedRuleForEdit.zone.name,
          polygon: selectedRuleForEdit.metadata.polygon
        })
      }
      // set blocked timeslots and rule name from editedRule
      const currentBlockedSlots = createInitialBlockedTimeSlots();
      const blockedSlots = selectedRuleForEdit.metadata.blockedSlot;
      blockedSlots.forEach(slot => {
        currentBlockedSlots.set(slot, true);
      })
      payloadDataToSet = {
        ...payloadDataToSet,
        blockedSlots: currentBlockedSlots,
        name: selectedRuleForEdit.name
      };
    }
    setTimeSlotBlockingPayload(payloadDataToSet)
  }, [selectedRuleForEdit])

  useEffect(() => {
    // no need to check value change if popup is not shown
    if (timeSlotBlockingPayload) {
      if (timeSlotBlockingPayloadRef.current && !formEdited) {
        // Update the effect when any key value changes
        const keysToWatch = Object.keys(timeSlotBlockingPayload);
        for (const key of keysToWatch) {
          if (timeSlotBlockingPayload[key] !== timeSlotBlockingPayloadRef.current[key]) {
            setFormEdited(true);
          }
        }
      }
      timeSlotBlockingPayloadRef.current = { ...timeSlotBlockingPayload };
    }
  }, [timeSlotBlockingPayload]);

  /* HANDLE POPUPS */
  const closeAddRuleFormPopup = () => {
    if (formEdited) {
      setShowConfirmationToClosePopup(true);
    } else {
      setShowAddRuleFormPopup(false);
      clearDataAndClosePopup();
      selectedRuleForEdit && setSelectedRuleForEdit(null);
    }
  };

  const leaveOngoingFormEdit = () => {
    setShowConfirmationToClosePopup(false)
    clearDataAndClosePopup();
  }

  const clearDataAndClosePopup = () => {
    setSelectedRuleType(null);
    setSelectedRuleForEdit(null);
    setShowAddRuleFormPopup(false);
  }

  /* INPUT HANDLERS */
  const handlePostalCodeInput = (value) => setPostalCodesText(value);

  const handlePostalCodeInputKeyPress = async(event) => {
    if (event?.key === 'Enter' && postalCodesText !== '') {
      /* do validations for merged postal codes before updating the postal code chips */
      const newPostalCodesArr = await getPostalCodesAfterValidation();

      setTimeSlotBlockingPayload(data => ({
        ...data,
        postalCodes: newPostalCodesArr
      }))
      setPostalCodesText('');
    }
  }

  const handleRemovePostalCode = async(index) => {
    const newPostalCodes = timeSlotBlockingPayload.postalCodes.filter((_, i) => i !== index);
    const verifiedPostalCodes = await getPostalCodesAfterValidation(newPostalCodes);
    setTimeSlotBlockingPayload((prev) => ({...prev, postalCodes: verifiedPostalCodes}));
  }

  const handleTimeSlotCheck = (selectedSlot) => {
    const { blockedSlots } = timeSlotBlockingPayload;
    const updatedBlockedTimeSlots = new Map(blockedSlots.set(selectedSlot, !blockedSlots.get(selectedSlot)))
    setTimeSlotBlockingPayload({
      ...timeSlotBlockingPayload,
      blockedSlots: updatedBlockedTimeSlots
    })
  }

  const handleClearAllTimeSlots = () => {
    const { blockedSlots } = timeSlotBlockingPayload;
    const updatedBlockedSlots = new Map([...blockedSlots].map(([key, _]) => [key, false]));
    setTimeSlotBlockingPayload({
      ...timeSlotBlockingPayload,
      blockedSlots: updatedBlockedSlots
    })
  }

  const handleSelectAllTimeSlots = () => {
    const { blockedSlots } = timeSlotBlockingPayload;
    const updatedBlockedSlots = new Map([...blockedSlots].map(([key, _]) => [key, true]));
    setTimeSlotBlockingPayload({
      ...timeSlotBlockingPayload,
      blockedSlots: updatedBlockedSlots
    })
  }

  const handleRuleNameInput = (value) => {
    const regex = /[^a-zA-Z0-9_]/; //
    if(!regex.test(value)) {
      setTimeSlotBlockingPayload({
        ...timeSlotBlockingPayload,
        name: value
      })
    }
  }

  const [isSomeSlotsChecked, isAllSlotsUnChecked, isAllSlotsChecked] = useMemo(() => {
    if (!timeSlotBlockingPayload) {
      return [false, false, false];
    }
    const { blockedSlots } = timeSlotBlockingPayload;
    const someSlotsChecked = Array.from(blockedSlots.values()).some(x => x);
    const allSlotsChecked = Array.from(blockedSlots.values()).every(x => x);
    return [someSlotsChecked, !someSlotsChecked, allSlotsChecked];
  }, [timeSlotBlockingPayload?.blockedSlots])

  /* VALIDATIONS */
  const verifyPostalCodesWithAddressService = async(postalCodes) => {
    try {
      setIsVerifyingPostalCodes(true);
      const addressPromises = postalCodes.map(postalCode => {
        const addressAPI = new API({ url: `/address/search?type=addresses&term=${postalCode.value}`});
        return addressAPI.get();
      });
      const responses = await Promise.all(addressPromises);
      responses.forEach((res, index) => {
        if (Object.keys(res.data).length === 0) {
          postalCodes[index].isValid = false;
        }
      })
      return postalCodes;
    } catch(e) {
      console.log(e);
      // if there is error in any api call, just return postalCodes as it is
      return postalCodes;
    } finally {
      setIsVerifyingPostalCodes(false);
    }
  }

  // call standalone functions to do validation. If one validation fails, skip the rest.
  // return mergedPostalCodes after validation
  const getPostalCodesAfterValidation = async(postalCodes = timeSlotBlockingPayload.postalCodes) => {
    const postalCodesArr = postalCodesText ? getPostalCodesArrayFromString(postalCodesText) : [];
    const mergedPostalCodesArr = [...postalCodes, ...postalCodesArr]

    // validation 1: check if postal codes formats are valid
    verifyPostalCodesHaveSixDigit(mergedPostalCodesArr);
    if (mergedPostalCodesArr.some(x => !x.isValid)) {
      setPostalCodeInputError(getMessage('operations.timeSlotBlocking.error.invalidPostalCodes'));
      return mergedPostalCodesArr;
    }

    // validation 2: check if there are duplicated postal codes
    verifyNoDuplicatedPostalCodes(mergedPostalCodesArr);
    if (mergedPostalCodesArr.some(x => !x.isValid)) {
      setPostalCodeInputError(getMessage('operations.timeSlotBlocking.error.duplicaedPostalCodes'));
      return mergedPostalCodesArr;
    }

    // validation 3: check with address-service if postal codes are valid
    const verifiedPostalCodes = await verifyPostalCodesWithAddressService(mergedPostalCodesArr);
    if (verifiedPostalCodes.some(x => !x.isValid)) {
      setPostalCodeInputError(getMessage('operations.timeSlotBlocking.error.invalidPostalCodes'));
      return verifiedPostalCodes;
    }

    setPostalCodeInputError('');
    return verifiedPostalCodes;
  }

  const checkBeforeSubmit = async() => {
    // if it's cluster rule, call create / put zone API call first
    if(selectedRuleType === AddRuleOptionsEnum.BY_MAP || selectedRuleType === AddRuleOptionsEnum.BY_KML_FILE) {
      try {
        const zoneApi = selectedRuleForEdit ?
          new API({ url: `/logistics-management-service/v1/zones/${selectedRuleForEdit.zone.id}`})
          : new API({ url: '/logistics-management-service/v1/zones'})
        const res = selectedRuleForEdit ? await zoneApi.put(zonePayload) : await zoneApi.post(zonePayload);
        return res.data && res.data.id;
      } catch(err) {
        setApiError(err?.message || getMessage('operations.timeSlotBlocking.zoneCreate.error'));
        setShowErrorPopup(true)
        return false;
      }
    }
    return true;
  }

  if (!timeSlotBlockingPayload) {
    return null;
  }

  /* Construct components for each step */
  const FirstStepComponent = {
    BY_POSTAL_CODE: <PostalCodeInputStep
      handlePostalCodeInputKeyPress={handlePostalCodeInputKeyPress}
      handlePostalCodeInput={handlePostalCodeInput}
      handleRemovePostalCode={handleRemovePostalCode}
      postalCodesText={postalCodesText}
      postalCodesChips={timeSlotBlockingPayload.postalCodes}
      errorMessage={postalCodeInputError}
      isVerifyingPostalCodes={isVerifyingPostalCodes}
      />,
    BY_MAP: <MapInputStep
      drawnZone={drawnZone}
      setDrawnZone={setDrawnZone}
      zonePayload={zonePayload}
      setZonePayload={setZonePayload} />,
    BY_KML_FILE: <KmlFileInputStep
      hasMultipleZone={hasMultipleZone}
      setZonePayload={setZonePayload}
      setClusterName={setClusterName}
      setHasMultipleZone={setHasMultipleZone}
      selectedRuleForEdit={selectedRuleForEdit}
      clusterName={clusterName} />
  }

  const FirstStepDisableNext = {
    BY_POSTAL_CODE: timeSlotBlockingPayload.postalCodes.length === 0 || timeSlotBlockingPayload.postalCodes.some(x => !x.isValid),
    BY_MAP: !zonePayload?.polygon,
    BY_KML_FILE: zonePayload === null || hasMultipleZone
  }
  const componentsArray = [
    {
      title: AddRuleFirstStepName[selectedRuleType],
      component: FirstStepComponent[selectedRuleType],
      disableNext: FirstStepDisableNext[selectedRuleType]
    },
    {
      title: getMessage('operations.timeSlotBlocking.secondStep.selectTimeslot'),
      component: <TimeSlotInputStep
       days={days}
       slots={slots}
       isAllSlotsChecked={isAllSlotsChecked}
       isSomeSlotsChecked={isSomeSlotsChecked}
       isAllSlotsUnChecked={isAllSlotsUnChecked}
       handleTimeSlotCheck={handleTimeSlotCheck}
       handleClearAllTimeSlots={handleClearAllTimeSlots}
       handleSelectAllTimeSlots={handleSelectAllTimeSlots}
       blockedTimeSlots={timeSlotBlockingPayload.blockedSlots} />,
      disableNext: isAllSlotsUnChecked
    },
    {
      title: getMessage('operations.timeSlotBlocking.thirdStep.ruleName'),
      component: <RuleNameInputStep ruleName={timeSlotBlockingPayload.name} handleRuleNameInput={handleRuleNameInput} selectedRuleType={selectedRuleType} />,
      disableNext: timeSlotBlockingPayload.name === '' // last component's disableNext is to call API
    }
  ];

  const ZoneNameInputStepComponent = {
    title: getMessage('operations.timeSlotBlocking.addRule.mapInput.zoneName'),
    component: <ZoneNameInputStep setZonePayload={setZonePayload} zonePayload={zonePayload} />,
    disableNext: !zonePayload?.name
  }

  /* Construct additional buttons to display in stepper form */
  const footerButtons = [
    <button key={0} onClick={closeAddRuleFormPopup} className='cancel-button'>
      {getMessage('operations.timeSlotBlocking.addRule.cancel')}
    </button>
  ]

  // if it's draw map flow, insert one more step in the flow
  selectedRuleType === AddRuleOptionsEnum.BY_MAP && componentsArray.splice(1, 0, ZoneNameInputStepComponent)

  // if it's store rule, drop the first step
  selectedRuleType === AddRuleOptionsEnum.BY_STORE && componentsArray.shift();

  return (
    <React.Fragment>
      {/* Add Rule Popup Form */}
      <PopupModal show={showAddRuleFormPopup} close={closeAddRuleFormPopup}>
        <div className="add-rule-popup">
          {/* Header */}
          <div className='header-wrapper'>
            <div className='add-rule-title'>{AddRulePopupTitle[selectedRuleType]}</div>
            <button data-testid="close-popup" className="close" onClick={closeAddRuleFormPopup}>
              <CloseIcon height={14} width={14} />
            </button>
          </div>
          {/* Body */}
          <div className='add-rule-popup-form'>
            <StepperForm
              steppedComponents={componentsArray}
              additionalFooterElements={footerButtons}
              submitText={getMessage('operations.timeSlotBlocking.add')}
              checkBeforeSubmit={checkBeforeSubmit}
              api={{
                url: selectedRuleForEdit
                  ? `/logistics-management-service/v1/rules/${selectedRuleForEdit.id}`
                  : '/logistics-management-service/v1/rules',
                payload: timeSlotBlockingPayload,
                requestType: selectedRuleForEdit ? 'PATCH' : 'POST',
                successCallback: () => {
                  setApiError(null);
                  setShowToast({ text: getMessage('operations.timeSlotBlocking.addRule.success') });
                  clearDataAndClosePopup();
                  refetchRules();
                },
                errorCallback: (err) => {
                  setApiError(err?.message);
                  setShowErrorPopup(true)
                },
                transformPayload: (payload, response) => transformPayload(payload, response, selectedRuleType)
              }}
            />
          </div>
        </div>
      </PopupModal>

      {/* Prompt to leave the Add Rule Popup Form */}
      <PopupModal show={showConfirmationToClosePopup} close={() => setShowConfirmationToClosePopup(false)}>
        <div className='confirmation-popup'>
          <div className='body-wrapper'>
            <p className='title'>{getMessage('operations.timeSlotBlocking.confirmPopup.leavePage')}</p>
            <p className='body'>{getMessage('operations.timeSlotBlocking.confirmPopup.bodyText')}</p>
            <div className='buttons-wrapper'>
              <button className='button secondary' onClick={() => setShowConfirmationToClosePopup(false)}>Cancel</button>
              <button className='button primary' onClick={leaveOngoingFormEdit}>Leave</button>
            </div>
          </div>
        </div>
      </PopupModal>

      {/* Prompt error if any API call error */}
      <PopupModal show={showErrorPopup} close={() => setShowErrorPopup(false)}>
        <div className='confirmation-popup'>
          <div className='body-wrapper'>
            <p className='title'>{getMessage('operations.timeSlotBlocking.title.serverError')}</p>
            <p className='body'>{apiError || getMessage('operations.timeSlotBlocking.body.serverError')}</p>
            <div className='buttons-wrapper'>
              <button className='button primary-blue' onClick={() => setShowErrorPopup(false)}>Got it</button>
            </div>
          </div>
        </div>
      </PopupModal>
    </React.Fragment>
  )
}

export default AddRulePopup;
