import React from 'react'

import { BaseForm, Select } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import Tabs from '../../../../components/Tabs'
import Loader from '../../../../components/Loader'

import API from '../../../../lib/api'

import FormQuickSearch from './FormQuickSearch'
import FormAdvancedSearch from './FormAdvancedSearch'
import {
  PAYMENT_SEARCH_CATEGORIES,
  PAYMENT_SEARCH_CATEGORIES_ADV,
  PAYMENT_SEARCH_TYPES,
} from '../../../../pages/customer-support/constants'
import { PaymentsContext } from '../PaymentsContext'

import './style.css'

const { PAYMENT_REF } = PAYMENT_SEARCH_CATEGORIES
const { POS_ID } = PAYMENT_SEARCH_CATEGORIES_ADV

const tabs = ['Quick search', 'Advanced search']

class PaymentsForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      initialDataLoading: true,
      isStoresLoading: false,
      tabIndex: 0,
      stores: [],
      merchants: [],
      values: {
        paymentSearchSelect: PAYMENT_REF,
        paymentSearchInput: '',
        paymentStatus: 'ALL',
        storeIds: [],
        paymentSearchSelectMerchant: '',
        paymentSearchSelectAdvanced: POS_ID,
        paymentSearchInputAdvanced: '',
        dateRange: { startDate: null, endDate: null },
      },
    }

    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleClearForm = this.handleClearForm.bind(this)
    this.handleMerchantChange = this.handleMerchantChange.bind(this)
    this.submitBtnRef = React.createRef()
  }

  fetchStores(callback) {
    // fetch stores with merchant id
    let merchantQueryParam = ''
    if (this.state.values.paymentSearchSelectMerchant) {
      merchantQueryParam = `?groupId=${this.state.values.paymentSearchSelectMerchant}`
    }
    const apiStores = new API({
      url: `/dp-middleware-connector/stores${merchantQueryParam}`,
    }).get()

    apiStores
      .then((storesResponse) => {
        //Process stores
        const { stores } = storesResponse.data

        this.setState(
          {
            stores: stores,
            isStoresLoading: false,
            initialDataLoading: false,
            values: {
              ...this.state.values,
              storeIds: [stores[0].id],
            },
          },
          callback
        )
      })
      .catch((e) => {
        this.setState({
          error: e,
          stores: [],
          initialDataLoading: false,
        })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    //Useful only when first time merchants are fetched
    // Because stateManagement function updateStateRecursively is using Object.assign
    // and "values" in state will be directly modified So prevState and current state will always be same
    if (
      prevState.values.paymentSearchSelectMerchant !==
      this.state.values.paymentSearchSelectMerchant
    ) {
      this.fetchStores()
    }
  }

  componentDidMount() {
    this.setState({ initialDataLoading: true })

    const apiMerchants = new API({
      url: `/dp-middleware-connector/merchantgroups`,
    }).get()

    apiMerchants
      .then((merchantsResponse) => {
        //Get merchant data
        const { groups: merchants } = merchantsResponse.data
        merchants.forEach((merchant) => {
          merchant.text = merchant.displayName
          merchant.value = merchant.id
        })

        this.setState({
          merchants: merchants,
          values: {
            ...this.state.values,
            paymentSearchSelectMerchant: merchants[0].value,
          },
        })
      })
      .catch((e) => {
        this.setState({
          error: e,
          stores: [],
          merchants: [],
          initialDataLoading: false,
        })
      })
  }

  handleChangePage(tabIndex) {
    const updatedValidations = { ...this.state.validations }
    if (tabIndex === 0) {
      delete updatedValidations.paymentSearchInputAdvanced
      delete updatedValidations.storeIds
    } else {
      delete updatedValidations.paymentSearchInput
    }

    this.setState({
      // need to clear validation of other tab
      // otherwise on submit, isFormValid will be false
      // if fields in other tab are invalid
      validations: updatedValidations,
      // if user has clicked on submit button before changing tab
      // then Need to reset validation state of submit to stop showing error in another tab
      pressedSubmitWithCurrentData: false,
      tabIndex,
    })
  }

  handleClearForm(e) {
    e.preventDefault()
    let valuesToUpdate = {}
    const stateValues = this.state.values

    if (this.state.tabIndex === 0) {
      valuesToUpdate = {
        paymentSearchSelect: stateValues.paymentSearchSelect,
        paymentSearchInput: '',
        paymentStatus: 'ALL',
      }
    } else {
      valuesToUpdate = {
        storeIds: [stateValues.storeIds[0]],
        paymentSearchSelectAdvanced: stateValues.paymentSearchSelectAdvanced,
        paymentSearchInputAdvanced: '',
        dateRange: { startDate: null, endDate: null },
      }
    }

    this.setState({
      // Need to reset validation state to stop showing error
      pressedSubmitWithCurrentData: false,
      values: {
        ...stateValues,
        ...valuesToUpdate,
      },
    })
  }

  handleMerchantChange() {
    const { resultsFetched } = this.context.paymentsContext

    // using the callback function, as updating merchant state is in progress
    // and if form is submitted immediately then the form will get submitted with the old value.
    this.setState(
      {
        isStoresLoading: true,
      },
      () => {
        this.fetchStores(() => {
          resultsFetched && this.submitBtnRef.current.click()
        })
      }
    )
  }

  onSubmit(values) {
    const filterType =
      this.state.tabIndex === 0
        ? PAYMENT_SEARCH_TYPES.QUICK_SEARCH
        : PAYMENT_SEARCH_TYPES.ADVANCED_SEARCH

    // update payment ref value
    const formValues = { ...values }

    this.props.onSubmitFilters({
      filterType,
      values: formValues,
    })
  }

  render() {
    const { Form } = this.components
    const { SubmitButton, ClearButton } = this.buttons
    const { tabIndex, initialDataLoading, isStoresLoading, error, merchants } =
      this.state

    if (error) {
      return <div className="error">{getMessage('error.server')}</div>
    }

    if (initialDataLoading) {
      return <Loader />
    }

    return (
      <div className="fp-pay-search-form">
        <Form>
          <div className="payment-search-select-container">
            <Select
              className="payment-search-select-merchant"
              label={getMessage(
                'customerSupport.payments.selectMerchant.label'
              )}
              name="paymentSearchSelectMerchant"
              testid="paymentSearchSelectMerchant"
              placeholder={getMessage(
                'customerSupport.payments.selectMerchant.placeholder'
              )}
              options={merchants}
              {...this.generateStateMappers({
                stateKeys: ['paymentSearchSelectMerchant'],
                onChange: this.handleMerchantChange,
              })}
            />
            {isStoresLoading && (
              <div
                className="payment-search-merchant-loader"
                data-testid="paymentSearchMerchantLoading"
              >
                <Loader />
              </div>
            )}
          </div>

          <div className="tab-container">
            <Tabs
              items={tabs}
              default={tabIndex}
              onClick={this.handleChangePage}
            />
            <div className="tab-contents">
              {tabIndex === 0 && (
                <FormQuickSearch data={this.state} BaseForm={this} />
              )}
              {tabIndex === 1 && (
                <FormAdvancedSearch data={this.state} BaseForm={this} />
              )}

              <SubmitButton
                testid="submitPaymentSearch"
                buttonRef={this.submitBtnRef}
              >
                {getMessage('customerSupport.payments.searchText')}
              </SubmitButton>
              <ClearButton onClear={this.handleClearForm}>
                {getMessage('customerSupport.payments.clearAllText')}
              </ClearButton>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

PaymentsForm.contextType = PaymentsContext
export default PaymentsForm
