import React from 'react'
import { Radio, Input, VALIDATION_TYPES } from '../../Form'

import { getMessage } from '../../../lib/translator'
import './style.css'
import moment from 'moment'

const REWARD_OPTIONS = [
  {
    text: 'Linkpoints',
    value: 'LINKPOINT',
  },
]

const Reward1 = ({ _this, method, keys }) => (
  <div className="mb-1">
    <div className="task-popup">
      <div className="task-header reward-header">
        <div
          className="header-1-lowercase header-rewards"
          data-testid={`reward 1`}
        >
          {getMessage('omnichallenge.task.rewardHeader')} {1}
        </div>
      </div>
      <div className="row mt mb add-reward-row">
        <div className="col-6">
          <Radio
            name="reward-type"
            label={getMessage('omnichallenge.task.type')}
            options={REWARD_OPTIONS}
            {..._this.generateStateMappers({
              stateKeys: keys[0],
              loseEmphasisOnFill: true,
              defaultValue: 'LINKPOINT',
            })}
            disabled={
              method === 'edit' && moment().isAfter(_this.state.values.startAt)
            }
          />
        </div>
        <div className="col-6">
          <Input
            label={getMessage('omnichallenge.value')}
            type="number"
            step="0"
            min={0}
            max={1000000}
            {..._this.generateStateMappers({
              stateKeys: keys[1],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            readOnly={
              method === 'edit' && moment().isAfter(_this.state.values.startAt)
            }
          />
        </div>
      </div>
    </div>
  </div>
)

export default Reward1
