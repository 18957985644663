import React, { Component } from 'react'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage/index'
import Loader from '../../../../components/Loader'
import NameValueCard from '../../../../components/NameValueCard'
import { getMessage } from '../../../../lib/translator'
import moment from 'moment'
import API from '../../../../lib/api'
import Filters from '../../commonMedia/SlotCalendar/DateSelectFilter'
import SlotTable from './slotTable'
import './style.css'

const dateFormat = 'YYYY-MM-DD'

export default class Spot extends Component {
  constructor(props) {
    super(props)

    this.api = null
    this.state = {
      showLoader: false,
      apiError: '',
      summaryCardData: [],
      values: {
        filter_duration: {
          startDate: moment().add(90, 'day').format(dateFormat),
          endDate: moment().add(111, 'day').format(dateFormat),
        },
        filterStartTime: '00:00',
        filterEndTime: '00:00',
      },
      slotData: [],
      assetData: [],
    }

  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    })
    this.getSlotData()

    this.setState({
      showLoader: false,
    })
  }

  getSlotData() {
    const id = this.props.match.params.id
    let sDate = this.state?.values?.filter_duration?.startDate
    let eDate = this.state?.values?.filter_duration?.endDate
    if (!sDate || !eDate) {
      sDate = moment().add(90, 'day').format(dateFormat)
      eDate = moment().add(111, 'day').format(dateFormat)
    }

    const slotApi = new API({ url: '/martech-ad-service/reservations/inventory' })
    slotApi
      .get({
        startDate: sDate,
        endDate: eDate,
        assetId: id,
      })
      .then(response => {
        const summaryCardsData = this.mapSummaryCads(response?.data)
        const mappedSlotData = this.mapSlotData(response?.data)
        this.setState({
          summaryCardData: summaryCardsData,
          slotData: mappedSlotData,
        })
      })
      .catch(error => {
        throw error
      })
  }

  mapSummaryCads(data) {
    return [
      { name: 'Total', value: this.getValueOrDefault(data?.totalSpot, 0), color: 'blue' },
      { name: 'Booked', value: this.getValueOrDefault(data?.bookedSpot, 0), color: 'red' },
      { name: 'Free Spots', value: this.getValueOrDefault(data?.availableSpot, 0), color: 'green' },
      { name: 'Fill Rate', value: this.getValueOrDefault(data?.fillRate, 0) + ' %', color: 'gray' },
    ]
  }

  getValueOrDefault(value, defaultValue) {
    return value || defaultValue
  }

  mapSlotData(data) {
    const mappedSlotData = []

    data?.spotCalendar?.forEach(calendarItem => {
      const mappedSlot = {
        date: calendarItem.date,
        spots: this.mapSpots(calendarItem),
      }
      mappedSlotData.push(mappedSlot)
    },
    )

    return mappedSlotData
  }

  mapSpots(calendarItem) {
    const mappedSpots = []
    calendarItem?.spots?.forEach(spot => {
      const s = {
        start: spot.start.substring(0, 5),
        end: spot.end.substring(0, 5),
        totalSpots: spot.totalSpots,
        bookedSpots: spot.bookedSpots,
      }
      mappedSpots.push(s)
    })
    return mappedSpots
  }

  onChange(dates) {
    const values = JSON.parse(JSON.stringify(this.state.values))
    values.filter_duration.startDate = dates && dates.startDate
    values.filter_duration.endDate = dates && dates.endDate

    this.setState({
      values,
    })
  }

  render() {
    const { menu } = this.props
    const { showLoader, summaryCardData, slotData } = this.state

    const SummaryCards = summaryCardData.map(({ name, ...data }, index) => (
      <NameValueCard
        key={`item-${index}`}
        name={name}
        {...data}
      />
    ))

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className='media-spot-allocation'
        title={getMessage('media.spotallocation.title')}
      >
        <h1 className='title'>{getMessage('media.spotallocation.title')}
        </h1>
        {showLoader ? (
          <Loader />
        ) : (
          <div>
            <Filters
              maxDays={120}
              value={this.state.values}
              onChange={(dates) => this.onChange(dates)}
              onSubmit={() => {
                this.getSlotData()
              }}
            />
            <div className='summary-info'>{SummaryCards}</div>
            <SlotTable slotData={slotData}></SlotTable>
          </div>
        )}
      </AuthenticatedPage>
    )
  }


}
