import React from 'react'
import { getMessage } from '../../../../lib/translator'

export default function CreativeRequirementSummary (props) {
  return (
    <div className="creative-requirement-summary-wrap">
      <div className="creative-requirement-summary-header">
        <h3>{getMessage('media.creative.requirement.title')}</h3>

      </div>
      <div className="creative-requirement-summary-inner">
        <div className="creative-requirement-summary-item">
          <small className="text-muted">{getMessage('media.creative.requirement.height')}: <span
            className="black">{props?.creative_requirement?.height}</span></small>
        </div>
        <div className="creative-requirement-summary-item">
          <small className="text-muted">{getMessage('media.creative.requirement.width')}: <span
            className="black">{props?.creative_requirement?.width} </span></small>
        </div>

        <div className="creative-requirement-summary-item description">
          <small className="text-muted">{getMessage('media.creative.requirement.size')}: <span
            className="black">{props?.creative_requirement?.size}</span></small>
        </div>

        <div className="creative-requirement-summary-item">
          <small className="text-muted">{getMessage('media.creative.requirement.duration')}: <span
            className="black">{props?.creative_requirement?.duration}</span></small>
        </div>
        <div className="creative-requirement-summary-item">
          <small className="text-muted">{getMessage('media.creative.requirement.format')}: <span
            className="black">{props?.creative_requirement?.supported_formats?.join(" / ")||"-"}</span></small>
        </div>

      </div>
    </div>
  )
}
