import React, { Fragment } from 'react'
import { getMessage } from 'lib/translator'
import { Input, Checkbox } from 'components/Form'

const RankSettings = ({ _this }) => {
  const CATEGORY_SECTIONS_ARRAY = [
    ['homeDeck'],
    ['homeSwimlane'],
    ['catalogueTab'],
  ]
  return (
    <Fragment>
      <div className="row flex-wrap">
        <div className="col-12">
          <h3>{getMessage(`rewards.category.rankHeader`)}</h3>
          <Checkbox
            inlineLabel={getMessage(`rewards.category.hideIfNoActiveRewards`)}
            name="hideCategoryIfNoActiveRewards"
            {..._this.generateStateMappers({
              stateKeys: [`hideIfNoActiveRewards`],
              loseEmphasisOnFill: true,
            })}
          />
          <p className="small">
            {getMessage(`rewards.category.rankSubHeader`)}
          </p>
        </div>
      </div>
      <br />
      {CATEGORY_SECTIONS_ARRAY.map((sections, i) => (
        <div className="row mb" key={i}>
          {sections.map((section, j) => {
            const visibility = _this.state.values?.[`${section}Visibility`]
            return (
              <div className="w-50" key={j}>
                <div className="col-6">
                  <Checkbox
                    className="availability-status"
                    inlineLabel={getMessage(`rewards.category.${section}`)}
                    name={section}
                    {..._this.generateStateMappers({
                      stateKeys: [`${section}Visibility`],
                      loseEmphasisOnFill: true,
                    })}
                  />
                </div>
                <div className="col-6">
                  <Input
                    placeholder={getMessage(`rewards.category.${section}`)}
                    type="number"
                    {..._this.generateStateMappers({
                      stateKeys: ['metadata', 'placementRanking', section],
                      loseEmphasisOnFill: true,
                    })}
                    {...(visibility && {
                      required: 'required',
                      min: 0,
                    })}
                    disabled={!visibility}
                  />
                </div>
              </div>
            )
          })}
        </div>
      ))}
      <br />
    </Fragment>
  )
}

export default RankSettings
