export const STATUSES = Object.freeze({
  PENDING: 'PENDING',
  CREATED: 'CREATED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  SUBMITTED: 'SUBMITTED',
  PREPARING: 'PREPARING',
  REJECTED: 'REJECTED',
  READY_FOR_COLLECTION: 'READY FOR COLLECTION',
  COLLECTED: 'COLLECTED',
})
export const RIDER_STATUSES = Object.freeze({
  NOT_ASSIGNED: 'Not assigned',
  REQUESTING_RIDER: 'Requesting Rider',
  RIDER_ACCEPTED: 'Rider Accepted',
  NEARBY_MERCHANT: 'Nearby Merchant',
  ARRIVED_AT_MERCHANT: 'Arrived At Merchant',
  DELIVERY_IN_PROGRESS: 'Delivery In Progress',
  NEARBY_DESTINATION: 'Nearby Destination',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
})
