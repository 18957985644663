import React, { Fragment } from 'react'
import { View, Text, LockIcon, Progress } from '../ui'
import { SampleGift } from '../ui/icon'
import { PlainInput } from 'components/Form'

export const GiftLine = ({ tiers }) => {
  return tiers.map((tier, index) => {
    const spent = tier.minSpend - tier.dueSpendToReachMin
    const progress = spent / tier.minSpend
    const tierUnlocked = tier.dueSpendToReachMin === 0

    return (
      <Fragment key={index}>
        <Progress
          progress={Math.min(progress * 100, 100)}
          data-testid={`foc-progress-${tier.minSpend}-${tierUnlocked ? 'unlocked' : 'locked'}`}
        />
        <Text
          data-testid={`foc-desc-${tier.minSpend}-${tierUnlocked ? 'unlocked' : 'locked'}`}
        >
          <Text fontSize={14} fontWeight={700}>
            {tierUnlocked
              ? 'Maximum spend reached!'
              : `Spend+$${(tier.dueSpendToReachMin || tier.minSpend).toFixed(2)}`}
          </Text>
          <Text fontSize={14}> more to unlock</Text>
        </Text>
        {tier.get.map((line) => (
          <View
            key={line.id}
            padding="12px 16px"
            border="1px solid #EAEAEA"
            borderRadius={8}
            background="#F6F6F6"
            margin="8px 0 0"
            justifyContent="space-between"
            data-testid={`foc-${tier.minSpend}-${line.id}-${tierUnlocked ? 'unlocked' : 'locked'}`}
          >
            {line.product.images.length > 0 ? (
              <img width={72} height={72} src={line.product.images[0]} />
            ) : (
              <SampleGift />
            )}
            <View direction="column" alignItems="start" width="fit-content">
              <Text fontSize={16} fontWeight={900}>
                Free
              </Text>
              <Text
                fontSize={14}
                style={{
                  maxWidth: '150px',
                  overflow: 'hidden',
                  textWrap: 'nowrap',
                }}
                textAlign="left"
              >
                {line.product.name}
              </Text>
              <Text fontSize={12}>
                {line.product.metaData['DisplayUnit'] ||
                  line.product.metaData['Unit Of Weight']}
              </Text>
            </View>
            <View width={40} justifyContent="end">
              {tierUnlocked ? (
                <PlainInput
                  inputWrapperStyle={{ width: '40px' }}
                  value={1}
                  disabled
                />
              ) : (
                <LockIcon />
              )}
            </View>
          </View>
        ))}
      </Fragment>
    )
  })
}
