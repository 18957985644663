import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../../../../components/Table'
import { Popup } from '../../../../../components/Popup'
import {
  Email as CustomerEmailWidget,
  Call as CustomerCallWidget,
  Address as CustomerMapWidget,
} from '../../../../../containers/CustomerWidgets'
import { getMessage } from '../../../../../lib/translator'
import {
  getSession,
  isExtensionEnabled,
  hasPermissions,
} from '../../../../../lib/auth'
import { getPrintableTime, formatTime } from '../../../../../lib/datetime'
import { getAsapDuration, getEndpointToPrintInvoice } from '../../../../../lib/commonlyused'
import OrderDetailsColumn from './OrderDetailsColumn'
import DropDownActions from './DropDownActions'
import FileForReturn from '../../../Returns/Form/FileForReturn'
import { Consumer } from '../index'
import pickupImage from '../../../Orders/Table/Views/pickup.svg'
import deliveyImage from '../../../Orders/Table/Views/delivery.svg'
import offlineImage from '../../../Orders/Table/Views/offline.svg'
import B2BImage from '../../../Orders/Table/Views/b2b.svg'
import RbPreorderImage from 'icons/RB_Preorder_icon.svg'
import API from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'

// Mapping order status with the relevant message key
const orderStatus = {
  COMPLETED: 'order.status.completed',
  CANCELLED: 'order.status.cancelled',
  PENDING: 'order.status.pending',
  PICKED: 'order.status.picked',
  CHECKED: 'order.status.checked',
  PACKED: 'order.status.packed',
  PICKING: 'order.status.picking',
  PROCESSING: 'order.status.processing',
  'PARTIALLY-PICKED': 'order.status.partiallypicked',
  CHECKING: 'order.status.checking',
  PACKING: 'order.status.packing',
  DISPATCHED: 'order.status.dispatched',
  RETURNED: 'order.status.returned',
}

const orderTypeImages = {
  PICKUP: pickupImage,
  DELIVERY: deliveyImage,
  B2B: B2BImage,
  OFFLINE: offlineImage,
  RB_PREORDER: RbPreorderImage
}

// Logic to decide what payment status should be shown for the order
const PaymentStatusText = () => {
  return ''
}
const FillRateText = ({ fillRate = 0 }) => {
  return (
    <small className={`${fillRate < 80 ? 'fill-rate' : 'text-muted'}`}>
      {(fillRate === 0 || fillRate) &&
        `${getMessage('order.table.text.fillrate')}
          ${Math.round(fillRate * 100) / 100}%`}
    </small>
  )
}

export class TableActions extends Component {
  constructor(props) {
    super(props)
    this.returnableStatuses = ['COMPLETED', 'PACKED', 'DISPATCHED']
    this.isInStoreEnabled = isExtensionEnabled('InStoreProcessing')
    this.isLogisticsEnabled = isExtensionEnabled('LogisticsSupport')
    this.state = {
      showCompleteDialog: false,
      showLog: false,
      showPickupPending: false,
      showMovePendingToPacked: false,
    }

    this.printInvoice = this.printInvoice.bind(this)
  }
  async printInvoice() {
    const { type, referenceNumber, store, customer, status } = this.props;

    if (status === 'DELIVERY_ORDER_STATUS_COMPLETED' || status === 'COMPLETED') {
      this.setState({ loading: true })

      this.invoice = new API({ url: getEndpointToPrintInvoice({
          storeId: store.id,
          customerId: customer.id,
          type,
          referenceNumber
        })
      })
      try {
        const byteArray = await this.invoice.fileFetch()
        if (byteArray) {
          const blob = new Blob([byteArray], { type: 'application/pdf' })
          const blobURL = URL.createObjectURL(blob)
          window.open(blobURL)
        }
      } catch (err) {
        this.setState({ error: {
          status: true,
          details: 'Service is unable to generate invoice, check whether SAP invoice number is generated',
          message: 'Invoice not ready',
        }})
      }
      this.setState({ loading: false })
    } else {
      this.setState({
        error: {
          status: true,
          details: 'Wait for order to complete, before generating invoice',
          message: 'Order not completed',
        }
      })
    }
  }

  render() {
    const {
      clientId,
      referenceNumber,
      status,
      onAction,
      packedOrderEdit,
      paymentStatus,
      pendingAmount,
      type,
      url,
    } = this.props
    return (
      <div>
        {this.state.showReturn && (
          <Popup
            show={this.state.showReturn}
            referenceNumber={referenceNumber}
            close={() => this.setState({ showReturn: false })}
            className="file-for-return"
            heading={`${getMessage('return.file.heading')} #${referenceNumber}`}
          >
            <FileForReturn
              referenceNumber={referenceNumber}
              closeReturnFilePopup={() => this.setState({ showReturn: false })}
              onAction={onAction}
              status={status}
            />
          </Popup>
        )}
        {this.state.loading && <Loader size="sm" />}
        {this.state.error && this.state.error.status && (
          <Popup
            show={this.state.error.status}
            referenceNumber={referenceNumber}
            close={() => this.setState({ error: null })}
            heading={`#${referenceNumber} ${this.state.error.message}`}
          >
            {this.state.error.details}
          </Popup>
        )}
        <DropDownActions
          clientId={clientId}
          packedOrderEdit={packedOrderEdit}
          paymentStatus={paymentStatus}
          pendingAmount={pendingAmount}
          referenceNumber={referenceNumber}
          returnableStatuses={this.returnableStatuses}
          status={status}
          type={type}
          url={url}
          printInvoice={this.printInvoice}
        />
      </div>
    )
  }
}

const tableHeader = () => {
  const headers = [
    getMessage('order.table.header.referenceNumber'),
    getMessage('order.table.header.customer'),
    getMessage('order.table.header.placedTime'),
    getMessage('order.table.header.amount'),
    getMessage('order.table.header.status'),
    getMessage('order.table.header.completionTime'),
    getMessage('order.table.header.actions'),
  ]
  if (!isExtensionEnabled('DeliverySlots')) {
    return headers
  } else {
    headers.splice(3, 0, getMessage('order.table.header.expectedTime'))
    return headers
  }
}

const isConfigValid = value => {
  return value && value.communication
}
const allowCall = value => {
  return (
    isConfigValid(value) &&
    value.communication.allowCall !== false &&
    hasPermissions('communication', 'call', 'post')
  )
}
const allowEmail = value => {
  return (
    isConfigValid(value) &&
    value.communication.allowEmail !== false &&
    hasPermissions('communication', 'email', 'post')
  )
}
const showCustomerPhones = (value, customer) => {
  return (
    allowCall(value) && customer && customer.phone && !!customer.phone.length
  )
}
const showCustomerEmails = (value, customer) => {
  return (
    allowEmail(value) && customer && customer.email && !!customer.email.length
  )
}
const getAddress = (type, address, pickupLocation) => {
  return type === 'DELIVERY'
    ? `${address.streetAddress}, ${address.city}, ${address.postalCode}`
    : pickupLocation
    ? pickupLocation.address
    : ''
}
const validateSlot = (slotStartTime, slotEndTime, slotType) => {
  return slotStartTime && slotEndTime && slotType !== 'ASAP'
}
const getSlotTime = (slotStartTime, slotEndTime, slotType) => {
  return validateSlot(slotStartTime, slotEndTime, slotType)
    ? `${formatTime(slotStartTime)} - ${formatTime(slotEndTime)}`
    : getAsapDuration(slotStartTime, slotEndTime)
}
const showFillRate = (type, status) => {
  return (
    (type === 'PICKUP' || type === 'DELIVERY') &&
    (status === 'PACKED' || status === 'COMPLETED' || status === 'DISPATCHED')
  )
}
const fromOperations = url => {
  return url || '/operations'
}
const getItemCount = (items, itemCount) => {
  return itemCount || (items && items.length) || 0
}
const getProfileLink = (url, customer) => {
  return url
    ? `${url}/customers/view/${customer.id}`
    : `/operations/customers/view/${customer.id}`
}
const getOrderStatus = (status, listOrderStatus) => {
  return listOrderStatus[status] ? getMessage(listOrderStatus[status]) : null
}
const getOrderActionDate = actionAt => {
  return actionAt && getPrintableTime(actionAt).split(', ')[0]
}
const getOrderActionTime = actionAt => {
  return actionAt && getPrintableTime(actionAt).split(', ')[1]
}
const getOrderAmount = amount => {
  return (
    getSession().organization.currency &&
    getSession().organization.currency.symbol + parseFloat(amount).toFixed(2)
  )
}

const tableProperties = url => {
  return {
    headers: tableHeader(),
    row: ({
      items,
      slotType,
      clientId,
      referenceNumber,
      customer,
      completionTime,
      placedOn,
      amount,
      itemCount,
      status,
      store,
      packedOrderEdit,
      pickupLocation,
      pendingAmount,
      refundAmount,
      type,
      onAction,
      preferredDate,
      slotStartTime,
      slotEndTime,
      paymentStatus,
      fillRate,
    }) => (
      <Consumer>
        {value => {
          return (
            <Row>
              <Cell className="column-order-number">
                <OrderDetailsColumn
                  clientId={clientId}
                  pickupLocation={pickupLocation}
                  orderTypeImages={orderTypeImages}
                  referenceNumber={referenceNumber}
                  store={store}
                  type={type}
                  url={url}
                />
              </Cell>
              <Cell className="column-customer-name">
                <div className="cx-name-text">
                  {customer ? (
                    <Link
                      className="customer-name"
                      to={getProfileLink(url, customer)}
                    >
                      {customer.name}
                    </Link>
                  ) : null}
                </div>
                <div className="customer-actions">
                  {customer.address && (
                    <CustomerMapWidget
                      address={getAddress(type, customer.address, pickupLocation)}
                    />
                  )}
                  {showCustomerEmails(value, customer) && (
                    <CustomerEmailWidget
                      emails={[customer.email]}
                      showOptions={false}
                    />
                  )}
                  {showCustomerPhones(value, customer) && (
                    <CustomerCallWidget
                      phones={[customer.phone]}
                      showOptions={false}
                    />
                  )}
                </div>
              </Cell>
              <Cell className="column-creation-time">
                <div>{getOrderActionDate(placedOn)}</div>
                <small className="text-muted">
                  {getOrderActionTime(placedOn)}
                </small>
              </Cell>
              {isExtensionEnabled('DeliverySlots') ? (
                <Cell className="column-creation-time">
                  <div>{type === 'OFFLINE' ? '' : getOrderActionDate(preferredDate)}</div>
                  <small
                    className={`text-muted ${
                      !slotEndTime ? 'zero-font' : ''
                    }`.trim()}
                  >
                    {getSlotTime(slotStartTime, slotEndTime, slotType)}
                  </small>
                </Cell>
              ) : null}
              <Cell className="column-order-amount">
                <div>{getOrderAmount(amount)}</div>
                <small className="text-muted">
                  {getMessage('order.table.itemCount', {
                    count: getItemCount(items, itemCount),
                  })}
                </small>
              </Cell>
              <Cell className="column-order-status">
                <div>{getOrderStatus(status, orderStatus)}</div>
                <PaymentStatusText
                  {...{ status, pendingAmount, refundAmount }}
                />
                {showFillRate(type, status) && (
                  <FillRateText {...{ fillRate }} />
                )}
              </Cell>
              <Cell className="column-completion-time">
                <div>{getOrderActionDate(completionTime)}</div>
                <small className="text-muted">
                  {getOrderActionTime(completionTime)}
                </small>
              </Cell>
              <Cell className="column-order-actions">
                <TableActions
                  status={status}
                  clientId={clientId}
                  referenceNumber={referenceNumber}
                  onAction={onAction}
                  type={type}
                  paymentStatus={paymentStatus}
                  pendingAmount={pendingAmount}
                  url={fromOperations(url)}
                  packedOrderEdit={packedOrderEdit}
                  store={store}
                  customer={customer}
                />
              </Cell>
            </Row>
          )
        }}
      </Consumer>
    ),
  }
}

export default tableProperties
