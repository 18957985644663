import React, { Fragment } from 'react'
import { getMessage } from 'lib/translator'
import { Input, SingleDatePicker, TimePicker } from '../../Form'
import deleteIcon from '../delete.svg'

const PromotionalPrice = ({ _this }) => {
  const { linkpointPrice } = _this.state.values

  const handleRemoveClick = (e, index) => {
    const values = Object.assign({}, _this.state.values)
    const validations = Object.assign({}, _this.state.validations)
    const { priceChanges } = values.linkpointPrice
    priceChanges.splice(index, 1)
    _this.setState({
      values,
      validations,
    })
  }

  const handleAddClick = () => {
    const values = Object.assign({}, _this.state.values)
    const data = {
      price: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
    }
    const { priceChanges } = values.linkpointPrice
    priceChanges.push(data)
    _this.setState({
      values,
    })
  }

  return (
    <Fragment>
      {linkpointPrice?.priceChanges?.map((x, i) => (
        <div key={i}>
          <div className="row mt" key={i}>
            <div className="col">
              <Input
                label={getMessage('rewards.catalogue.promotionalPrice')}
                placeholder={getMessage('rewards.catalogue.promotionalPrice')}
                type="number"
                prefix="LP"
                step="0.01"
                min={0.01}
                max={999999}
                value={x.price}
                testid={`promotionalPrice-${i}`}
                {..._this.generateStateMappers({
                  stateKeys: ['linkpointPrice', 'priceChanges', i, 'price'],
                })}
              />
            </div>
            <div className="col">
              <SingleDatePicker
                enableToday
                label={getMessage(`rewards.startDate`)}
                placeholder={getMessage(`rewards.startDate`)}
                {..._this.generateStateMappers({
                  stateKeys: ['linkpointPrice', 'priceChanges', i, 'startDate'],
                })}
                {...(x.price && {
                  required: 'required',
                })}
                data-testid={`promotionalDate-${i}`}
              />
            </div>
            <div className="col time">
              <TimePicker
                label={getMessage(`rewards.startTime`)}
                placeholder={getMessage(`rewards.startTime`)}
                {..._this.generateStateMappers({
                  stateKeys: ['linkpointPrice', 'priceChanges', i, 'startTime'],
                })}
                {...(x.price && {
                  required: 'required',
                })}
                data-testid={`promotionalTime-${i}`}
              />
            </div>
            <div className="col">
              <SingleDatePicker
                enableToday
                label={getMessage(`rewards.endDate`)}
                placeholder={getMessage(`rewards.endDate`)}
                {..._this.generateStateMappers({
                  stateKeys: ['linkpointPrice', 'priceChanges', i, 'endDate'],
                })}
                {...(x.price && {
                  required: 'required',
                })}
              />
            </div>
            <div className="col time">
              <TimePicker
                label={getMessage(`rewards.endTime`)}
                placeholder={getMessage(`rewards.endTime`)}
                {..._this.generateStateMappers({
                  stateKeys: ['linkpointPrice', 'priceChanges', i, 'endTime'],
                })}
                {...(x.price && {
                  required: 'required',
                })}
              />
            </div>
            <div className="col delete">
              <button
                className="mr10"
                type="button"
                data-testid={`delete-btn-${i}`}
                onClick={(e) => handleRemoveClick(e, i)}
              >
                <img src={deleteIcon} alt="delete" />
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col-12">
          <div className="add-more">
            <button
              data-testid="add-more-btn"
              type="button"
              onClick={handleAddClick}
            />
            {getMessage('rewards.catalogue.addPromotionalPrice')}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PromotionalPrice
