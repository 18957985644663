import React, { Component } from 'react'
import _cloneDeep from 'lodash/cloneDeep'
import _remove from 'lodash/remove'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import PlacementDetails from './PlacementDetails'
import DeliveryDetails from './DeliveryDetails'
import OrderItemsView from './OrderItemsView'
import { Link } from 'react-router-dom'
import PaymentDetails from './PaymentDetails'
import RefundDetails from './RefundDetails'
import PackingDetails from './PackingDetails'
import MetaDataDetails from './MetaDataDetails'
import { DropDown, DropDownItem, ICONS } from '../../../../components/DropDown'
import Loader from '../../../../components/Loader'
import { Popup, Dialog } from 'components/Popup'
import { getReturnableStatuses } from '../../Returns'
import { tripStatus } from '../../Orders/Table/Views/tabularView'
import DeprecatedMessage from '../DeprecatedMessage'
import { getMessage } from '../../../../lib/translator'
import {
  getSession,
  isExtensionEnabled,
  getExtensionDetails,
  hasPermissions,
} from '../../../../lib/auth'
import API from '../../../../lib/api'
import './style.css'
import BPrint from '../../../../lib/zebra'
import { getEndpointToPrintInvoice } from 'lib/commonlyused';

const deliveryDetailsEditableStatus = [
  'PENDING',
  'RETURNED',
  'PACKING',
  'PACKED',
  'PROCESSING',
  'PICKING',
  'PARTIALLY-PICKED',
  'PICKED',
  'CHECKING',
  'CHECKED',
  'DISPATCHED',
]
function getMobileView() {
  return window.screen.width <= 480
}

export default class OrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: false,
      failed: false,
      orderCancellationDialog: false,
      orderCompletionDialog: false,
      orderPackedDialog: false,
      orderPrintToteDialog: false,
      moveToPendingDialog: false,
      isOrderViewItemCollapsed: true,
      isMetaDataCollapsed: true,
      isPaymentDetailsCollapsed: true,
      isRefundDetailsCollapsed: true,
      isMobileView: getMobileView(),
      error: null
    }
    this.organization = getSession().organization
    this.currency = this.organization.currency

    this.getData = this.getData.bind(this)
    this.handleItemEdit = this.handleItemEdit.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.handleMetaDataEdit = this.handleMetaDataEdit.bind(this)
    this.toggleCompletionDialog = this.toggleCompletionDialog.bind(this)
    this.toggleCancellationDialog = this.toggleCancellationDialog.bind(this)
    this.togglePackedDialog = this.togglePackedDialog.bind(this)
    this.togglePrintDialog = this.togglePrintDialog.bind(this)
    this.cancelOrder = this.cancelOrder.bind(this)
    this.packOrder = this.packOrder.bind(this)
    this.printTotes = this.printTotes.bind(this)
    this.metaDataExists = this.metaDataExists.bind(this)
    this.completeOrder = this.completeOrder.bind(this)
    this.printInvoice = this.printInvoice.bind(this)
    this.isMetaDataKeySet = this.isMetaDataKeySet.bind(this)
    this.toggleMoveToPendingDialog = this.toggleMoveToPendingDialog.bind(this)
    this.moveToPending = this.moveToPending.bind(this)
    this.mobileView = this.mobileView.bind(this)
  }
  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  async moveToPending() {
    this.modifyOrder(
      {
        status: 'PENDING',
      },
      { 'X-API-VERSION': 2 },
      'PENDING'
    ).then(() => {
      this.setState({ moveToPendingDialog: false })
    })
  }

  toggleMoveToPendingDialog() {
    this.setState(prevState => {
      return {
        moveToPendingDialog: !prevState.moveToPendingDialog,
      }
    })
  }

  mergeSubstitutionItems = order => {
    const clonedOrder = _cloneDeep(order)
    const items = clonedOrder.items
    let length = items.length
    for (let i = 0; i < length; i++) {
      const item = items[i]
      if (!item.orderDetails.substitutedItemId) {
        continue
      }

      const substitutedItemIndex = items.findIndex(
        x => x.orderDetails.orderItemId === item.orderDetails.substitutedItemId
      )
      items[substitutedItemIndex].orderDetails.substitutionItem = item
      _remove(
        items,
        x => x.orderDetails.orderItemId === item.orderDetails.orderItemId
      )
      length--
      i--
    }
    clonedOrder.items = items
    return clonedOrder
  }

  onError(error) {
    this.setState({
      error: error.message
        .split(':')
        .slice(1)
        .join(':'),
    })
    throw error
  }

  getAddressType(order) {
    let addressType = ''
    if (order && order.type && order.type.name) {
      const orderType = order.type.name
      switch (orderType) {
        case 'B2B':
          addressType = 'BIZ'
          break
        case 'OFFLINE':
          break
        default:
          addressType = 'PRS'
      }
    }
    return addressType
  }

  filterAddressByOrderType = order => {
    const clonedOrder = _cloneDeep(order)
    if (clonedOrder.customer && clonedOrder.customer.addresses) {
      const addresses = clonedOrder.customer.addresses
      const adddressType = this.getAddressType(order)
      if (addresses.length === 0 || adddressType === '') {
        return clonedOrder
      }
      const filteredAddress = addresses.filter(
        address => address.type === adddressType
      )
      clonedOrder.customer.addresses = filteredAddress
    }
    return clonedOrder
  }

  getOrderDetailsApi() {
    return this.orderDetailsApi
      .get({
        'include[0]': 'cancelledItems',
        rawData: true,
        includeSubstitution: true,
      })
      .then(
        response => {
          const order = this.filterAddressByOrderType(response.data.order)
          this.setState({
            failed: false,
            data: this.mergeSubstitutionItems(order),
          })
        },
        error => {
          this.setState({ failed: true })
          if (error.code === 401) {
            throw error
          }
        }
      )
  }

  getData() {
    const referenceNumber = this.props.router.match.params.id
    this.orderDetailsApi = new API({
      url: `/order-service/order/${referenceNumber}`,
    })
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        this.getOrderDetailsApi().then(() => {
          this.setState({ loading: false })
        })
      }
    )
  }
  modifyOrder(params, headers = {}, newOrderStatus) {
    const referenceNumber = this.props.router.match.params.id
    const api = new API({ url: `/order-service/order/${referenceNumber}` })
    return api.put(params, headers).then(response => {
      if (response && response.data && response.data.order) {
        this.setState({ data: response.data.order, error: false })
      } else {
        if (newOrderStatus && response.status === 'SUCCESS') {
          const newData = JSON.parse(JSON.stringify(this.state.data))
          newData.status = newOrderStatus
          this.setState({
            data: newData,
          })
        }
      }
    }, this.onError)
  }
  modifyAddressDeliverySlot(params, headers = {}) {
    const referenceNumber = this.props.router.match.params.id
    this.patchAddressDeliverySlotApi = new API({
      url: `/order-service/orders/${referenceNumber}`,
    })
    return this.patchAddressDeliverySlotApi
      .patch(params, headers)
      .then(() => {}, this.onError)
  }
  cancelOrderAction(params, headers = {}, newOrderStatus) {
    const referenceNumber = this.props.router.match.params.id
    const api = new API({
      url: `/order-service/orders/${referenceNumber}/reviewCancellation`,
    })
    return api.post(params, headers).then(
      response => {
        if (newOrderStatus && response.code === 200) {
          const newData = JSON.parse(JSON.stringify(this.state.data))
          newData.status = newOrderStatus
          this.setState({
            data: newData,
          })
        }
      },
      error => {
        this.setState({
          error: error.message
            .split(':')
            .slice(1)
            .join(':'),
        })
        throw error
      }
    )
  }
  packOrderAction(params, headers = {}) {
    const referenceNumber = this.props.router.match.params.id
    const api = new API({ url: `/order-service/orders/${referenceNumber}/pack` })
    return api.post(params, headers).then(
      response => {
        if (response.code === 200) {
          const newData = JSON.parse(JSON.stringify(this.state.data))
          newData.status = 'PACKED'
          this.setState({
            data: newData,
          })
        }
      },
      error => {
        this.setState({
          error: error.message
            .split(':')
            .slice(1)
            .join(':'),
        })
        throw error
      }
    )
  }
  handleItemEdit(items) {
    return this.modifyOrder({
      orderItems: items
        .filter(
          item =>
            item.orderDetails.orderItemId ||
            item.orderDetails.orderedQuantity > 0
        )
        .map(item => ({
          [item.orderDetails.orderItemId ? 'orderItemId' : 'itemId']: item
            .orderDetails.orderItemId
            ? item.orderDetails.orderItemId
            : item.id,
          quantity: !item.orderDetails.orderItemId
            ? Number(item.orderDetails.orderedQuantity) /
              item.orderDetails.factorForPutRequest
            : Number(item.orderDetails.orderedQuantity),
        })),
    })
  }
  handleAddressChange(params) {
    return this.modifyAddressDeliverySlot(params).finally(() => {
      this.getOrderDetailsApi()
    })
  }

  handleMetaDataEdit(metadata) {
    return this.modifyOrder({ metaData: metadata })
  }

  toggleCompletionDialog() {
    this.setState(prevState => {
      return {
        orderCompletionDialog: !prevState.orderCompletionDialog,
      }
    })
  }
  toggleCancellationDialog() {
    this.setState(prevState => {
      return {
        orderCancellationDialog: !prevState.orderCancellationDialog,
      }
    })
  }
  togglePackedDialog() {
    this.setState(prevState => {
      return {
        orderPackedDialog: !prevState.orderPackedDialog,
      }
    })
  }
  togglePrintDialog() {
    this.setState(prevState => {
      return {
        orderPrintToteDialog: !prevState.orderPrintToteDialog,
      }
    })
  }
  completeOrder() {
    this.modifyOrder({
      status: 'COMPLETED',
    }).then(() => {
      this.setState({ orderCompletionDialog: false })
    })
  }

  cancelOrder() {
    this.cancelOrderAction(
      {
        status: 'CANCELLED',
      },
      {},
      'CANCELLED'
    ).then(() => {
      this.setState({ orderCancellationDialog: false })
    })
  }
  packOrder() {
    this.packOrderAction({}, {}).then(() => {
      this.setState({
        orderPackedDialog: false,
      })
    })
  }
  printTotes() {
    const referenceNumber = this.props.router.match.params.id
    new BPrint().print(
      referenceNumber,
      this.state.data.slotStartTime,
      this.state.data.customer.name,
      this.state.data.preferredDate,
      this.state.data.packingDetails
    )
    this.setState({
      orderPrintToteDialog: false,
    })
  }

  async printInvoice() {
    const referenceNumber = this.props.router.match.params.id
    const { type, customer, status, store } = this.state.data

    if (status === 'COMPLETED') {
      this.setState({ loading: true })
      const apiUrl = getEndpointToPrintInvoice({
        storeId: store.id,
        customerId: customer.id,
        type: type.name,
        referenceNumber
      })

      this.invoice = new API({
        url: apiUrl,
      })
      try {
        const byteArray = await this.invoice.fileFetch()
        if (byteArray) {
          const blob = new Blob([byteArray], { type: 'application/pdf' })
          const blobURL = URL.createObjectURL(blob)
          window.open(blobURL)
        }
      } catch (err) {
        const error = {
          status: true,
          message: 'Invoice not ready',
          details:
            'Service is unable to generate invoice, check whether SAP invoice number is generated',
        }
        this.setState({ error })
      }

      this.setState({
        loading: false,
      })
    } else {
      this.setState({
        error: {
          status: true,
          message: 'Order not completed',
          details: 'Wait for order to complete, before generating invoice',
        }
      })
    }
  }

  metaDataExists() {
    const metaData = this.state.data.metaData
    if (!metaData) {
      return false
    } // for null case
    return Array.isArray(metaData)
      ? !metaData
      : !Object.values(metaData).every(val => !val) // for empty array and object case
  }
  isMetaDataKeySet() {
    if (isExtensionEnabled('EntityMetaData')) {
      const metaDataExtnId = getExtensionDetails('EntityMetaData').id
      const api = new API({
        url: `/account-service/extension/${metaDataExtnId}`,
      })
      api.get().then(response => {
        const config = response.data.extension.config.globalConfig.order
        this.setState({
          orderMeta: config,
        })
      })
    }
    if (isExtensionEnabled('EntityMetaData')) {
      this.addressSequenceApi = new API({
        url: '/config-service/config/customers',
      })
      this.addressSequenceApi.get().then(response => {
        this.setState({
          addressSequence: response.data.customers.addressSequence,
        })
      })
    }
  }
  getCommunicationConfig() {
    const communicationApi = new API({ url: `/account-service/config` })
    communicationApi
      .get()
      .then(response => {
        this.setState({
          communicationConfig: response.data.config.communication,
          orderConfig: response.data.config.order,
        })
      })
      .catch(error => {
        throw error
      })
  }
  async componentDidMount() {
    if (isExtensionEnabled('InStoreProcessing')) {
      this.packedOrderEditApi = new API({
        url: `/config-service/config/inStoreProcessing.packedOrderEditAllowed`,
      })
      await this.packedOrderEditApi
        .get()
        .then(res => {
          if (res.code === 200 && res.status === 'SUCCESS') {
            this.setState({
              packedOrderEdit:
                res &&
                res.data &&
                res.data.inStoreProcessing &&
                res.data.inStoreProcessing.packedOrderEditAllowed,
            })
          }
        })
        .catch(err => {
          if (err.code === 401 || err.code === 403) {
            throw err
          }
        })
    }
    this.getData()
    this.isMetaDataKeySet()
    this.getCommunicationConfig()
    window.addEventListener('resize', this.mobileView, false)
  }
  componentWillUnmount() {
    this.orderDetailsApi && this.orderDetailsApi.cancel()
    this.addressSequenceApi && this.addressSequenceApi.cancel()
    window.removeEventListener('resize', this.mobileView, false)
  }
  render() {
    const {
      isMobileView,
      isOrderViewItemCollapsed,
      isMetaDataCollapsed,
      isPaymentDetailsCollapsed,
      isRefundDetailsCollapsed,
    } = this.state
    const offers = (this.state.data && this.state.data.offers) || []
    const vouchers = (this.state.data && this.state.data.vouchers) || []
    const hasOrderEditPermission = hasPermissions('order', 'order', 'put')
    const url = this.props.url
    const referenceNumber = this.props.router.match.params.id
    const contentLoaded =
      !this.state.loading && !this.state.failed && this.state.data
    const registerPaymentsLink =
      contentLoaded &&
      `orders/register-payment/${referenceNumber}?pendingAmount=${this.state.data.pendingAmount}&clientId=${this.state.data.clientId}`
    let canBeCompleted = contentLoaded
      ? isExtensionEnabled('InStoreProcessing')
        ? isExtensionEnabled('LogisticsSupport')
          ? this.state.data.status === 'DISPATCHED'
          : this.state.data.status === 'PACKED'
        : isExtensionEnabled('LogisticsSupport')
        ? this.state.data.status === 'DISPATCHED'
        : this.state.data.status === 'PENDING'
      : false // Good candidate for using FSM?
    canBeCompleted = canBeCompleted && hasOrderEditPermission
    if (
      contentLoaded &&
      this.state.data.status === 'PACKED' &&
      this.state.data.type &&
      this.state.data.type.name === 'PICKUP'
    ) {
      canBeCompleted = true && hasOrderEditPermission
    }
    const registerPayments =
      contentLoaded &&
      (!isExtensionEnabled('LogisticsSupport') ||
        (this.state.data.type && this.state.data.type.name === 'PICKUP')) &&
      this.state.data.paymentStatus === 'PENDING'
    const canBeCancelled =
      contentLoaded &&
      (this.state.data.status === 'PENDING' ||
        this.state.data.status === 'PACKING' ||
        this.state.data.status === 'PACKED') &&
      hasOrderEditPermission
    const canbePacked =
      contentLoaded &&
      this.state.data.status !== 'COMPLETED' &&
      this.state.data.status !== 'PACKED' &&
      hasOrderEditPermission
    const canShowTripDetails =
      contentLoaded &&
      isExtensionEnabled('LogisticsSupport') &&
      tripStatus.indexOf(this.state.data.status) > -1
    const canbeReturned =
      contentLoaded &&
      isExtensionEnabled('OrderReturns') &&
      getReturnableStatuses().indexOf(this.state.data.status) > -1 &&
      hasPermissions('order', 'order', 'post')
    const canBeDisputed =
      contentLoaded &&
      isExtensionEnabled('OrderDisputes') &&
      this.state.data.status === 'COMPLETED'
    const canBePending = contentLoaded && this.state.data.status === 'PICKING'
    const showDropdown =
      canBeCompleted ||
      canBeCancelled ||
      canbePacked ||
      canbeReturned ||
      canShowTripDetails ||
      canBeDisputed ||
      canBePending

    const isStatusPacked =
      (this.state.data && this.state.data.status === 'PACKED') || false
    const packedOrderEdit = this.state.packedOrderEdit || false
    const fromOperations = this.props.url === '/operations'

    return (
      <AuthenticatedPage
        storeDependent
        className="order-details"
        menu={this.props.menu}
      >
        <Dialog
          show={this.state.orderCancellationDialog}
          title={getMessage('order.details.cancellation.title')}
          information={getMessage('order.details.cancellation.message')}
          onOk={this.cancelOrder}
          close={this.toggleCancellationDialog}
          okText={getMessage('order.details.cancellation.confirmText')}
          closeText={getMessage('order.details.cancellation.cancelText')}
        />
        <Dialog
          show={this.state.orderPackedDialog}
          title={getMessage('order.details.packed.title')}
          information={getMessage('order.details.packed.message')}
          onOk={this.packOrder}
          close={this.togglePackedDialog}
          okText={getMessage('order.details.packed.confirmText')}
          closeText={getMessage('order.details.packed.cancelText')}
        />
        <Dialog
          show={this.state.orderPrintToteDialog}
          title={getMessage('order.details.print.title')}
          information={getMessage('order.details.print.message')}
          onOk={this.printTotes}
          close={this.togglePrintDialog}
          okText={getMessage('order.details.print.confirmText')}
          closeText={getMessage('order.details.print.cancelText')}
        />
        <Dialog
          show={this.state.orderCompletionDialog}
          title={getMessage('order.details.completion.title')}
          information={getMessage('order.details.completion.message')}
          onOk={this.completeOrder}
          close={this.toggleCompletionDialog}
          okText={getMessage('order.details.completion.confirmText')}
          closeText={getMessage('order.details.completion.cancelText')}
        />
        <Dialog
          show={this.state.moveToPendingDialog}
          title={getMessage('order.details.cancellation.title')}
          information={getMessage('order.details.pending.message')}
          onOk={this.moveToPending}
          close={this.toggleMoveToPendingDialog}
          okText={getMessage('order.details.cancellation.confirmText')}
          closeText={getMessage('order.details.cancellation.cancelText')}
        />
        <div className="flex-around">
          <h1 className="title heading">
            {getMessage('order.details.heading')}{' '}
            {this.state.data &&
              (this.state.data.clientId
                ? getMessage('order.table.clientId.prefix')
                : getMessage('order.table.referenceNumber.prefix'))}
            <strong className="reference-number">
              {this.state.data && (this.state.data.clientId || referenceNumber)}
            </strong>
          </h1>
          <BPrint />
          {contentLoaded ? (
            <div className="order-actions">
              <div className="order-actions-mobile-view">
                <DropDown
                  className="order-actions-dropdown"
                  icon={<img src={ICONS.VELLIP} alt="⋮" />}
                >
                  <DropDownItem>
                    {' '}
                    <button
                      className="invoice-button print-icon"
                      aria-label="Invoice button"
                      onClick={this.printInvoice}
                    >
                      {' '}
                      {getMessage('order.details.invoice.printButtonText')}
                    </button>
                  </DropDownItem>
                  <DropDownItem>
                    <button className="invoice-button">
                      <Link to={`${url}/orders/order-log/${referenceNumber}`}>
                        {getMessage('order.actions.logs')}
                      </Link>
                    </button>
                  </DropDownItem>
                </DropDown>
              </div>
              <div className="order-actions-web-view">
                <button
                  className="invoice-button print-icon"
                  onClick={this.printInvoice}
                >
                  {getMessage('order.details.invoice.printButtonText')}
                </button>
                <button className="invoice-button">
                  <Link to={`${url}/orders/order-log/${referenceNumber}`}>
                    {getMessage('order.actions.logs')}
                  </Link>
                </button>
              </div>
              {showDropdown && (
                <DropDown
                  className="order-actions-dropdown"
                  icon={<img src={ICONS.VELLIP} alt="⋮" />}
                >
                  {canBeCompleted &&
                    (!registerPayments ? (
                      <DropDownItem onClick={this.toggleCompletionDialog}>
                        {getMessage('order.details.complete.optionText')}
                      </DropDownItem>
                    ) : (
                      <DropDownItem>
                        <Link
                          to={
                            url
                              ? `${url}/${registerPaymentsLink}`
                              : `/operations/${registerPaymentsLink}`
                          }
                        >
                          {getMessage('order.details.complete.optionText')}
                        </Link>
                      </DropDownItem>
                    ))}
                  {canbeReturned && (
                    <DropDownItem>
                      <Link
                        to={`${url}/orders/file-for-return/${referenceNumber}`}
                      >
                        {getMessage('order.details.returm.optionText')}
                      </Link>
                    </DropDownItem>
                  )}
                  {canBeDisputed && (
                    <DropDownItem>
                      <Link
                        to={`${url}/orders/dispute/${this.state.data.referenceNumber}`}
                      >
                        {getMessage('order.details.dispute.optionText')}
                      </Link>
                    </DropDownItem>
                  )}
                  {canBeCancelled && (
                    <DropDownItem onClick={this.toggleCancellationDialog}>
                      {getMessage('order.details.cancel.optionText')}
                    </DropDownItem>
                  )}
                  {canbePacked && (
                    <DropDownItem onClick={this.togglePackedDialog}>
                      {getMessage('order.details.packed.optionText')}
                    </DropDownItem>
                  )}
                  {this.state.data.packingDetails.length > 0 && (
                    <DropDownItem onClick={this.togglePrintDialog}>
                      {getMessage('order.details.print.optionText')}
                    </DropDownItem>
                  )}
                  {canShowTripDetails && (
                    <DropDownItem>
                      <Link
                        to={`/logistics/trips?orderNumber=${referenceNumber}`}
                      >
                        {getMessage('order.actions.trips')}
                      </Link>
                    </DropDownItem>
                  )}
                  {canBePending && (
                    <DropDownItem onClick={this.toggleMoveToPendingDialog}>
                      {getMessage('order.action.moveToPending')}
                    </DropDownItem>
                  )}
                  {isStatusPacked && packedOrderEdit && fromOperations && (
                    <DropDownItem>
                      <Link to={`edit-packed/${referenceNumber}`}>
                        {getMessage('order.action.editPackedOrder')}
                      </Link>
                    </DropDownItem>
                  )}
                </DropDown>
              )}
            </div>
          ) : null}
        </div>
        {this.state.loading ? <Loader /> : null}
        {this.state.failed ? (
          <div className="retry-message text-muted" onClick={this.getData}>
            {getMessage('order.details.errors.onLoad')}
          </div>
        ) : null}
        {this.state.error && this.state.error.status && (
          <Popup
            show={this.state.error.status}
            referenceNumber={referenceNumber}
            close={() => this.setState({ error: null })}
            heading={`#${referenceNumber} ${this.state.error.message}`}
          >
            {this.state.error.details}
          </Popup>
        )}
        {contentLoaded ? (
          <div>
            <DeprecatedMessage url={url} referenceNumber={referenceNumber} />
            <div className="order-summary-wrapper">
              <div className="user-details">
                <PlacementDetails
                  data={{
                    preorder: this.state.data.preorder,
                    status: this.state.data.status,
                    verificationStatus: this.state.data.verificationStatus,
                    creationTime: this.state.data.createdAt,
                    completionTime: this.state.data.completedAt,
                    device: this.state.data.placedFrom,
                    items: this.state.data.items,
                    metaData: this.state.data.metaData,
                    storeId: this.state.data.store.id,
                    batchIds: this.state.data.batchIDs,
                  }}
                />
                <DeliveryDetails
                  data={{
                    customer: this.state.data.customer,
                    address: this.state.data.address,
                    pickupLocation: this.state.data.pickupLocation,
                    status: this.state.data.status,
                    preferredSlot: {
                      startTime: this.state.data.slotStartTime,
                      endTime: this.state.data.slotEndTime,
                      type: this.state.data.slotType,
                    },
                    preferredDate: this.state.data.preferredDate,
                    storeId: this.state.data.store && this.state.data.store.id,
                    slotType: this.state.data.slotType,
                    url: url,
                    metaData: this.state.data.metaData,
                    addressSequence: this.state.addressSequence,
                    type: this.state.data.type && this.state.data.type.name,
                  }}
                  editable={
                    deliveryDetailsEditableStatus.indexOf(
                      this.state.data.status
                    ) > -1 && hasOrderEditPermission
                  }
                  onChange={this.handleAddressChange}
                  error={this.state.error}
                  communicationConfig={this.state.communicationConfig}
                  orderConfig={this.state.orderConfig}
                />
              </div>
              {isMobileView ? (
                <div
                  className={`collapse-container-order ${isOrderViewItemCollapsed}`}
                >
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        isOrderViewItemCollapsed: !this.state
                          .isOrderViewItemCollapsed,
                      })
                    }
                    className="component-collapse"
                  >
                    {isOrderViewItemCollapsed ? '+' : '-'}
                  </button>{' '}
                  {isOrderViewItemCollapsed && (
                    <h3>
                      {getMessage('order.details.itemsTable.title')}
                      <span className="text-muted">
                        {' '}
                        ({(this.state.data.items || []).length})
                      </span>
                    </h3>
                  )}
                </div>
              ) : (
                ''
              )}
              {(!isMobileView ||
                (isMobileView && !isOrderViewItemCollapsed)) && (
                <OrderItemsView
                  data={{
                    items: this.state.data.items || [],
                    payment: this.state.data.payment || [],
                    shipping: this.state.data.shipping,
                    clickAndCollectCharges: this.state.data
                      .clickAndCollectCharges,
                    type: this.state.data.type.name,
                    amount: this.state.data.amount,
                    discount: this.state.data.discount,
                    couponDiscount: this.state.data.couponDiscount,
                    refundAmount: this.state.data.refundAmount,
                    pendingAmount: this.state.data.pendingAmount,
                    invoiceAmount: this.state.data.invoiceAmount,
                    serviceFee: this.state.data.currentServiceFee,
                  }}
                  vouchers={vouchers}
                  offers={offers}
                  editable={
                    this.state.data.status === 'PENDING' &&
                    hasOrderEditPermission
                  }
                  currency={this.currency}
                  onChange={this.handleItemEdit}
                  status={this.state.data.status}
                  storeId={this.state.data.store && this.state.data.store.id}
                />
              )}
              {isMobileView ? (
                <div
                  className={`collapse-container-metadata ${isMetaDataCollapsed}`}
                >
                  {' '}
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        isMetaDataCollapsed: !this.state.isMetaDataCollapsed,
                      })
                    }
                    className="component-collapse"
                  >
                    {isMetaDataCollapsed ? '+' : '-'}
                  </button>{' '}
                  {isMetaDataCollapsed && (
                    <h3>{getMessage('Custom Fields Details')}</h3>
                  )}
                </div>
              ) : (
                ''
              )}
              {(!isMobileView || (isMobileView && !isMetaDataCollapsed)) &&
                isExtensionEnabled('EntityMetaData') &&
                (this.state.data.status === 'COMPLETED' ||
                this.state.data.status === 'CANCELLED'
                  ? this.metaDataExists()
                  : true) &&
                !Array.isArray(this.state.orderMeta) && (
                  <MetaDataDetails
                    data={this.state.data.metaData}
                    editable={
                      this.state.data.status !== 'COMPLETED' &&
                      this.state.data.status !== 'CANCELLED' &&
                      hasOrderEditPermission
                    }
                    onChange={this.handleMetaDataEdit}
                  />
                )}
              {isMobileView ? (
                <div
                  className={`collapse-container-payment ${isPaymentDetailsCollapsed}`}
                >
                  {' '}
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        isPaymentDetailsCollapsed: !this.state
                          .isPaymentDetailsCollapsed,
                      })
                    }
                    className="component-collapse"
                  >
                    {isPaymentDetailsCollapsed ? '+' : '-'}
                  </button>
                  {isPaymentDetailsCollapsed && (
                    <h3>{getMessage('order.details.payment.heading')}</h3>
                  )}
                </div>
              ) : (
                ''
              )}
              {(!isMobileView ||
                (isMobileView && !isPaymentDetailsCollapsed)) &&
                this.state.data.payment &&
                this.state.data.payment.length > 0 && (
                  <PaymentDetails
                    payment={this.state.data.payment}
                    currency={this.currency}
                  />
                )}
              {isMobileView ? (
                <div
                  className={`collapse-container-refund ${isRefundDetailsCollapsed}`}
                >
                  {' '}
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        isRefundDetailsCollapsed: !this.state
                          .isRefundDetailsCollapsed,
                      })
                    }
                    className="component-collapse"
                  >
                    {isRefundDetailsCollapsed ? '+' : '-'}
                  </button>
                  {isRefundDetailsCollapsed && (
                    <h3>{getMessage('Refund Details')}</h3>
                  )}
                </div>
              ) : (
                ''
              )}
              {(!isMobileView || (isMobileView && !isRefundDetailsCollapsed)) &&
                this.state.data.refund &&
                this.state.data.refund.length > 0 && (
                  <RefundDetails
                    refund={this.state.data.refund}
                    currency={this.currency}
                  />
                )}
              {this.state.data.packingDetails &&
                this.state.data.packingDetails.length > 0 && (
                  <PackingDetails
                    packingDetails={this.state.data.packingDetails[0].details}
                  />
                )}
            </div>
          </div>
        ) : null}
      </AuthenticatedPage>
    )
  }
}
