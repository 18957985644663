import React from 'react'
import { BaseForm, Select, SingleDatePicker } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import { getDisplaySlot, sortSlots } from '../../../../lib/commonlyused'
import { isEqual } from 'lodash'

class CapacityFilters extends BaseForm {
  constructor(props) {
    super(props)
    this.getSlotsAndZones = this.getSlotsAndZones.bind(this)
  }
  componentDidMount() {
    this.getSlotsAndZones()
  }

  getSlotsAndZones() {
    const { props } = this
    const deliverySlots =
      props.options.deliverySlots && sortSlots(props.options.deliverySlots)
    this.deliverySlots =
      deliverySlots &&
      deliverySlots.map(slot => {
        return {
          text: getDisplaySlot(slot),
          value: slot.id,
        }
      })
    const pickupSlots =
      props.options.pickupSlots && sortSlots(props.options.pickupSlots)
    this.pickupSlots =
      pickupSlots &&
      pickupSlots.map(slot => {
        return {
          text: getDisplaySlot(slot),
          value: slot.id,
        }
      })
    this.zones =
      props.options.zones &&
      props.options.zones.map(zone => {
        return {
          text: zone.name || zone.id,
          value: zone.id,
        }
      })
  }
  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        prevProps.options.deliverySlots,
        this.props.options.deliverySlots
      )
    ) {
      this.getSlotsAndZones()
      this.setState({
        updated: true,
      })
    }
  }
  render() {
    const { Form } = this.components
    const { SubmitButton, ClearButton } = this.buttons
    const { type, daysOfWeek } = this.props.options
    const { orderType } = this.state.values || {}
    const isPickup = orderType === 'PICKUP'

    return (
      <Form className="capacity-filters">
        <div className="form-fields">
          <Select
            name="type"
            label={getMessage('type')}
            placeholder={getMessage('type.placeholder')}
            options={type}
            {...this.generateStateMappers({
              stateKeys: ['orderType'],
              loseEmphasisOnFill: true,
              defaultValue: 'DELIVERY',
            })}
          />
          <SingleDatePicker
            name="date"
            label={getMessage('Date')}
            placeholder={getMessage('Date')}
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ['date'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            name="day"
            options={daysOfWeek}
            label={getMessage('Day')}
            placeholder={getMessage('day.placeholder')}
            {...this.generateStateMappers({
              stateKeys: ['day'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            name="zones"
            type="number"
            label={getMessage('zone')}
            placeholder={getMessage('zones.placeholder')}
            options={this.zones} // TODO: handle pickup zones different
            {...this.generateStateMappers({
              stateKeys: ['zoneId'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            name="slots"
            type="number"
            label={getMessage('slot')}
            placeholder={getMessage('slots.placeholder')}
            options={isPickup ? this.pickupSlots : this.deliverySlots}
            {...this.generateStateMappers({
              stateKeys: ['slotId'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div>
          <SubmitButton>{getMessage('submit')}</SubmitButton>
          <ClearButton>{getMessage('clear')}</ClearButton>
        </div>
      </Form>
    )
  }
}

export default CapacityFilters
