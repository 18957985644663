import React, { useState, useContext } from 'react'
import {
  Select,
  SingleDatePicker,
  Checkbox
} from 'components/Form'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Dialog, Popup } from 'components/Popup';
import API from 'lib/api';
import { getMessage } from 'lib/translator';
import Loader from 'components/Loader';
import { getNewDisplayAddress, updatedDeliveryOrderStatuses } from 'lib/commonlyused'
import { getSelectedStore } from 'lib/auth';
import { formatTime } from 'lib/datetime';
import exportFromJSON from 'export-from-json';
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import B2BDeliveryNotesPDF from './B2BDeliveryNotesPDF'
import _ from 'lodash';
import moment from 'moment';

function BulkOperationFilters({ slotOptions }) {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  const zonePrefix = splits?.[SPLIT_FEATURES.ZONE_PREFIX_LABEL]
    ? JSON.parse(splits[SPLIT_FEATURES.ZONE_PREFIX_LABEL].config)
    : {}

  const [deliveryDate, setDeliveryDate] = useState(null);
  const [orderSlot, setOrderSlot] = useState(null);
  const [errorDialog, setErrorDialog] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [b2bDeliveryOrders, setB2bDeliveryOrders] = useState([])
  const [showB2BDeliveryNotePopup, setShowB2BDeliveryNotePopup] = useState(false);
  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState(() => {
    return updatedDeliveryOrderStatuses.map(status => ({
      text: _.capitalize(status.split('_').pop()),
      value: status.split('_').pop(),
      isChecked: status === 'DELIVERY_ORDER_STATUS_PACKED' ||
        status === 'DELIVERY_ORDER_STATUS_DISPATCHED' ||
        status === 'DELIVERY_ORDER_STATUS_COMPLETED'
    }))
  })

  const getSelectedOrderStatuses = () => {
    return selectedOrderStatuses.filter(x => x.isChecked).map(status => status.value)
  }

  const downloadCSV = async() => {
    try {
      setIsLoading(true);
      const selectedStore = getSelectedStore();
      const queryAPI = new API({ url: `/order-service/v3/deliveryOrders`});
      const { startTime, endTime } = JSON.parse(orderSlot);
      const result = await queryAPI.get({
        storeId: selectedStore.id,
        preferredDate: deliveryDate,
        status: getSelectedOrderStatuses(),
        slotFrom: startTime,
        slotTo: endTime,
        listAction: 'LIST_ACTION_FFS_DISPATCH'
      });
      if (!result || result?.deliveryOrders.length === 0) {
        setErrorDialog({
          title: 'Info',
          message: getMessage('order.filters.noOrderFound')
        })
      } else {
        // converts 08:00:00 to 0800 and 10:00:00 to 1000
        const slotStartTime = startTime.replace(/:/g, '').slice(0,-2);
        const slotEndTime = endTime.replace(/:/g, '').slice(0,-2);
        const timestamp = moment().format('YYYY-MM-DDTHH_mm_ss');
        const fileName = `${deliveryDate}_${slotStartTime}_${slotEndTime}_${timestamp}`;
        const data = result.deliveryOrders.map(order => {
          const packageCounts = {};
          for(const key in order.packageCounts) {
            packageCounts[key] = order.packageCounts[key];
          }
          const address = getNewDisplayAddress(order.deliveryAddress, false, false);

          // remove white spaces infront of the address lines
          const formattedAddress = address.split('\n').map(x => x.trim()).join('\n');
          return {
            reference_number: order.referenceNumber,
            delivery_address: formattedAddress,
            postal_code: order.deliveryAddress.postalCode,
            time_slot: `${formatTime(startTime)} - ${formatTime(endTime)}`,
            ...packageCounts,
            early_deli: order.deliveryNotes?.deliveryPreference.earlyDelivery || '-',
            leave_at_door: order.deliveryNotes?.deliveryPreference.leaveAtDoor || '-',
            delivery_notes: order.deliveryNotes?.customerInstruction || '-',
          }
        })
        const sortedData = _.orderBy(data, ['postal_code'], ['asc']);
        exportFromJSON({ data: sortedData, fileName, exportType: exportFromJSON.types.csv });
      }
      setIsLoading(false);
    } catch(e) {
      console.log(e);
      setIsLoading(false);
      setErrorDialog({
        title: 'Error',
        message: getMessage('order.filters.unableDownloadCSV')
      })
    }
  }

  const downloadB2BDeliveryNotes = async() => {
    try {
      setIsLoading(true);
      const selectedStore = getSelectedStore();
      const queryAPI = new API({ url: `/order-service/v3/deliveryOrders`});
      const { startTime, endTime } = JSON.parse(orderSlot);
      const response = await queryAPI.get({
        storeId: selectedStore.id,
        preferredDate: deliveryDate,
        status: getSelectedOrderStatuses(),
        slotFrom: startTime,
        slotTo: endTime,
        listAction: 'LIST_ACTION_B2B'
      });
      if (response?.deliveryOrders) {
        setShowB2BDeliveryNotePopup(true)
        setB2bDeliveryOrders(response?.deliveryOrders || [])
      }
    } catch(e) {
      console.log(e);
      setErrorDialog({
        title: 'Error',
        message: getMessage('order.filters.unableDownloadPDF')
      })
    } finally {
      setIsLoading(false)
    }
  }

  const reset = () => {
    setDeliveryDate(null);
    setOrderSlot(null);
    setSelectedOrderStatuses(data => data.map(status => ({
      ...status,
      isChecked: false
    })))
  }

  // ensure all filter fields are applied. !! cast the value to boolean instead of null/undefined/empty string
  const checkAllFiltersApplied = !!deliveryDate && !!orderSlot && selectedOrderStatuses.some(x => x.isChecked)

  const getDeliverySlotName = () => {
    const { startTime } = JSON.parse(orderSlot);
    return `T${startTime.split(':')[0]}`
  }

  const handleUpdateItemChecked = (index) => {
    setSelectedOrderStatuses(data => data.map((item, i) =>
      i === index ? {
        ...item,
        isChecked: !item.isChecked
      } : item
    ))
  }

  return (
    <>
      <div className="form-fields">
        <SingleDatePicker
            label={getMessage('order.filters.deliveryDate.label')}
            placeholder={getMessage('order.filter.placedOn.placeholder')}
            name="bulk-operation-delivery-date"
            allowAllDates
            onChange={(date) => setDeliveryDate(date)}
            value={deliveryDate}
          />

          <Select
            label={getMessage('order.filters.slot')}
            placeholder={getMessage('order.filters.slot.placeholder')}
            name="bulk-operation-order-slot"
            testid="order-slot-select"
            options={slotOptions}
            onChange={(slot) => setOrderSlot(slot)}
            value={orderSlot}
          />

          <div className="order-status-checkboxes-container">
            <p>Select delivery order status</p>
            <div className="order-status-checkboxes-wrapper">
              {
                selectedOrderStatuses.map((status, index) =>
                  <div key={status.value}>
                    <Checkbox
                      name={`status-${status.value}`}
                      inlineLabel={status.text}
                      value={status.isChecked}
                      testid={`test-${status.value}`}
                      controlled
                      onChange={() => handleUpdateItemChecked(index)}
                    />
                  </div>
                )
              }
            </div>
          </div>

      </div>
      <button className={`button primary ${checkAllFiltersApplied ? '' : 'disabled'}`} onClick={downloadCSV}>
        {getMessage('order.filters.download.summary.text')}
      </button>
      <button className={`button primary ${checkAllFiltersApplied ? '' : 'disabled'}`} onClick={downloadB2BDeliveryNotes}>
        {getMessage('order.filters.download.b2b.delivery.notes')}
      </button>
      <button className='button secondary' onClick={reset}>
        {getMessage('deliveryOrder.bulkOperations.reset')}
      </button>
      { isLoading && <Loader /> }
      <Popup
          heading={getMessage('order.filters.download.b2b.delivery.notes')}
          className="b2b-delivery-order-notes-popup"
          show={showB2BDeliveryNotePopup}
          close={() => setShowB2BDeliveryNotePopup(false)}
      >
        <div>
          {b2bDeliveryOrders.length === 0 ? getMessage('order.filters.download.b2b.noOrder') : `${b2bDeliveryOrders.length} order(s) found`}
          {
            b2bDeliveryOrders.length > 0 && (
              <div className='download-button-wrapper'>
                <PDFDownloadLink
                  document={
                    <B2BDeliveryNotesPDF
                      deliveryOrders={b2bDeliveryOrders}
                      zonePrefix={zonePrefix}
                      deliverySlotName={getDeliverySlotName()} />}
                  fileName={`${deliveryDate}_${getDeliverySlotName()}_${selectedOrderStatuses.map(x => x.text).join('_')}.pdf`}>
                  {({ _blob, _url, loading, _error }) =>
                    loading ? 'Generating pdf ...' : getMessage('order.filters.download.pdf')
                  }
                </PDFDownloadLink>
              </div>
            )
          }
        </div>
      </Popup>
      {errorDialog && (
          <Dialog
            show={!!errorDialog}
            title={errorDialog.title}
            information={errorDialog.message}
            close={() => setErrorDialog(null)}
            closeText={getMessage('dialog.okText')}
          />
      )}
    </>
  )
}

export default BulkOperationFilters;
