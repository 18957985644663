import React, { Component } from 'react'
import { getMessage } from '../../../../../lib/translator'

import './style.css'

class InvoiceSummary extends Component {
  render() {
    const {
      shipping = 0.0,
      paid: paidProp = 0.0,
      refundAmount,
      loadedFromInvoice,
      editing = false,
      pendingAmount: pendingAmountProp = 0.0,
      serviceFee = 0.0,
      amount = 0.0,
    } = this.props.data
    const offers = this.props.offers || []
    const vouchers = this.props.vouchers || []
    const offersAmount = offers.reduce(function (offersTotal, offer) {
      return offersTotal + Number(offer.discount)
    }, 0)
    const vouchersAmount = vouchers.reduce(function (vouchersTotal, voucher) {
      return vouchersTotal + Number(voucher.voucherDiscount)
    }, 0)
    const total =
      Number(amount) +
      Number(serviceFee) +
      Number(shipping) -
      vouchersAmount -
      offersAmount
    let paid = paidProp
    let pendingAmount = pendingAmountProp
    paid = Number(paid)
    let paymentAmountText = null
    pendingAmount = Number(pendingAmount)
    if (!Number(!!refundAmount && Number(refundAmount))) {
      if (total > paid) {
        let shownPendingAmount = (total - paid).toFixed(2)
        if (!editing && pendingAmount >= 0) {
          shownPendingAmount = pendingAmount
        }
        paymentAmountText = (
          <div className="flex-around payment-amount">
            <div className="text-muted">
              {getMessage('order.details.summary.pendingAmount')}
            </div>
            <div className="payment-pending-amount">
              <span>{this.props.currency.symbol} </span>
              <span className="section-value">{shownPendingAmount}</span>
            </div>
          </div>
        )
      }
    } else {
      paymentAmountText = (
        <div className="flex-around payment-amount">
          <div className="text-muted">
            {getMessage('order.details.summary.refundAmount')}
          </div>
          <div className="payment-refund-amount">
            <span>{this.props.currency.symbol} </span>
            <span className="section-value">
              {Number(refundAmount).toFixed(2)}
            </span>
          </div>
        </div>
      )
    }
    return (
      <div className="invoice-summary">
        <div className="flex-around shipping-charge">
          <div className="text-muted">
            {this.props.data.type === 'DELIVERY'
              ? getMessage('order.details.summary.shippingCharge')
              : getMessage('order.details.summary.collectionCharge')}
          </div>
          <div>
            <span>{this.props.currency.symbol} </span>
            <span className="section-value">
              {this.props.data.type === 'DELIVERY'
                ? Number(shipping).toFixed(2)
                : Number(this.props.data.clickAndCollectCharges).toFixed(2)}
            </span>
          </div>
        </div>
        <div className="flex-around service-fee">
          <div className="text-muted">
            {getMessage('order.details.summary.servicefee')}
          </div>
          <div>
            <span>{this.props.currency.symbol} </span>
            <span className="section-value">
              {Number(serviceFee).toFixed(2)}
            </span>
          </div>
        </div>
        {vouchers.length > 0 && (
          <VoucherGroup
            vouchers={vouchers}
            currSym={this.props.currency.symbol}
          />
        )}
        {offers.length > 0 && (
          <OfferGroup offers={offers} currSym={this.props.currency.symbol} />
        )}
        <div className="flex-around total-amount">
          <div className="text-muted ">
            {getMessage('order.details.summary.totalAmount')}:
          </div>
          <div>
            <span>{this.props.currency.symbol} </span>
            {<span className="section-value">{total.toFixed(2)}</span>}
          </div>
        </div>
        {paymentAmountText}
        {loadedFromInvoice && (
          <div className="tax-disclaimer">
            {getMessage('order.details.invoice.taxDisclaimer')}
          </div>
        )}
      </div>
    )
  }
}

const VoucherGroup = ({ vouchers, currSym }) => (
  <div>
    <div className="flex-around discount-amount text-muted">
      {getMessage('order.details.summary.coupondiscount')}:
    </div>
    {vouchers.map((voucher, i) => (
      <div className="flex-around discount-amount" key={'voucher-' + i}>
        <div title={voucher.voucherCode} className="text-muted text-overflow">
          {voucher.voucherCode}
        </div>
        <div className="nowrap-amount">
          <span>-{currSym} </span>
          <span className="section-value">
            {Number(voucher.voucherDiscount).toFixed(2)}
          </span>
        </div>
      </div>
    ))}
  </div>
)

const OfferGroup = ({ offers, currSym }) => (
  <div>
    <div className="flex-around discount-amount text-muted">
      {getMessage('order.details.summary.offers')}:
    </div>
    {offers.map((item, i) => (
      <div className="flex-around discount-amount" key={'offer-' + i}>
        <div title={item.description} className="text-muted text-overflow">
          {item.description}
        </div>
        <div className="nowrap-amount">
          <span>-{currSym} </span>
          <span className="section-value">
            {Number(item.discount).toFixed(2)}
          </span>
        </div>
      </div>
    ))}
  </div>
)

export default InvoiceSummary
