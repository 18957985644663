import React from 'react'

const FlexBox = ({ className, id, children, ...props }) => {
  return (
    <div
      className={className}
      id={id}
      data-testId={props['data-testid']}
      style={{
        display: 'flex',
        ...props,
      }}
    >
      {children}
    </div>
  )
}

export default FlexBox
