import React, { Component } from 'react'

import './style.css'

function getValidationObj(DomNode) {
  if (!DomNode) {
    return {}
  }
  const validationObj = DomNode.validity
  const errors = {}
  for (const key in validationObj) {
    errors[key] = validationObj[key]
  }
  return errors
}

class Radio extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  runValidation(DomNode) {
    this.props.onValidation &&
      this.props.onValidation(getValidationObj(DomNode))
  }
  handleChange(e) {
    e.preventDefault()
    this.props.onChange && this.props.onChange(e.target.value)
    this.runValidation(e.target)
  }
  componentDidMount() {
    this.runValidation(this.inputRef)
  }
  render() {
    const { props } = this
    return (
      <div className={`Radio ${props.skin}`}>
        {(props.options || []).map((option, index) => (
          <label
            disabled={props.disabled}
            className={`radio-label ${
              String(props.value) === String(option.value) ? ' selected' : ''
            } ${props.labelClass ? props.labelClass : ''}
            `}
            key={option.value}
          >
            <input
              type="radio"
              name={props.name}
              value={option.value}
              required={props.required}
              onClick={this.handleChange}
              ref={(node) => {
                if (index === 0) {
                  this.inputRef = node
                }
              }}
              disabled={props.readOnly || option.disabled}
            />
            <span className="radio-label-text" title={option.titleText}>
              {option.text}
              {option.alertText && (
                <span style={{ color: option.alertText?.color || '#FB424B' }}>
                  {' '}
                  ({option.alertText.text})
                </span>
              )}
            </span>
          </label>
        ))}
      </div>
    )
  }
}

const SKINS = {
  DEFAULT: '',
  BORDERLESS: 'borderless-skin',
  ONE_PER_LINE: 'block-skin',
}

Radio.defaultProps = {
  value: '',
  options: [],
  skin: SKINS.DEFAULT,
}

export default Radio
export { SKINS }
