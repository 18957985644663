import React from 'react'
import { getMessage } from '../../../lib/translator'
import Upload from '../../../components/FileUpload'

import uploadIcon from './upload-products.svg'
import csvIcon from './csv-icon.svg'

import './style.css'

class UploadWidget extends React.Component{


  render(){

    return (
      <div className="product-upload">
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          <Upload
            name="orderCleanupUpload"
            accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
            placeholder={getMessage('category.bulk-upload')}
            uploadButtonText={"Upload"}
            cancelText={getMessage('category.form.cancelText')}
            onUpload={this.props.onUpload}
            validationStrings={{
              invalidFileType: getMessage('input.invalidFileType'),
            }}
            uploadedFile={this.props.uploadedFile}
            strings={{
              defaultMessage: getMessage('fileUpload.importCsv.heading'),
              progressMessage: getMessage('fileUpload.importedCsv.heading'),
              completionMessage: 'Uploaded',
            }}
            icon={csvIcon}
          />
        </div>
        <div className="text-center download-sample-text">
          <a className="download-link"  target="_blank" download="sample.csv" onClick={this.props.onDownloadCsv}>
            {getMessage('productUpload.csvDownloadText')}
          </a>
        </div>
      </div>
    )
  }

}

export default UploadWidget
