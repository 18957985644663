import React from 'react'
import { Row, Cell } from '../../../components/Table'
import { RolesPopup } from './RolesPopup'

export const TableRow = (props) => {
  const { name, roles } = props
  return (
    <Row>
      <Cell>{name}</Cell>
      <Cell>
        <RolesPopup roles={roles} designation={name} />
      </Cell>
    </Row>
  )
}
export const tableProperties = () => {
  return {
    headers: ['Designation', 'Roles'],
    row: (props) => <TableRow {...props} />,
  }
}
