import { getMessage } from '../../../../lib/translator'
import React, { Fragment } from 'react'
import { Checkbox } from '../../../Form'

const ManualDraw = ({ _this, method }) => {
  return (
    <Fragment>
      <div className="mobile-full-width">
        <Checkbox
          name="manual-draw"
          testid="manual-draw-checkbox"
          inlineLabel={getMessage('luckydraw.isManual')}
          {..._this.generateStateMappers({
            stateKeys: ['isManual'],
            loseEmphasisOnFill: true,
          })}
          disabled={method === 'edit' && _this.state.campaignStarted}
        />
      </div>
    </Fragment>
  )
}

export default ManualDraw
