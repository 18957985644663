import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import {
  tableProperties,
  deviceDataCsvExample,
} from '../../../components/Rewards/Devices/utils'
import CSVUpload from '../../../components/Rewards/CSVUpload'
import { LinkButtons, emptyState } from '../../../components/Rewards/utils'
import '../style.css'
import './devices.css'
import DeviceForm from './Form'
import { getMessage } from 'lib/translator'
import Filters from 'components/Rewards/Filters'

const Devices = (props) => {
  return (
    <ListingPageWithRoutes
      testid="devices-listing"
      title={getMessage('rewards.device.title')}
      addHeading={getMessage('rewards.device.add')}
      editHeading={getMessage('rewards.device.edit')}
      menu={props.menu}
      className="rewards-page devices"
      api={{
        url: '/admin/devices',
        transform: (response) => response && response.data.list,
      }}
      headerActions={({ onAction }) => (
        <LinkButtons type="device" onAction={onAction} />
      )}
      form={{
        component: DeviceForm,
      }}
      filters={{
        component: Filters,
        forceShow: true,
        options: {
          type: 'device',
        },
      }}
      importDialog={{
        component: () => (
          <CSVUpload
            uploadUrl="/admin/bulk/devices"
            csv={deviceDataCsvExample()}
            fileName="devicesData"
          />
        ),
      }}
      modalClassName="csvUpload"
      importHeading={getMessage('rewards.device.import')}
      tableProperties={tableProperties}
      emptyState={emptyState('device')}
      tableDynamic
    />
  )
}

export default Devices
