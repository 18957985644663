import React, { useCallback, useEffect } from 'react'
import { FixedButton, Text, View } from '../ui'
import { useFormikContext, Field } from 'formik'
import { FormikInput } from './FormikInput'

const COMMON_ERROR = 'Either Order ID or Phone number is required'
export const SearchOrder = ({ toOrderSummary, toViewOrder }) => {
  const { values, setFieldValue, setFieldError } = useFormikContext()

  useEffect(() => {
    setFieldValue('scanOrderId', '')
    setFieldValue('orderPhoneNumber', '')
  }, [])

  const handleSearch = useCallback(() => {
    if (!values.scanOrderId && !values.orderPhoneNumber) {
      setFieldError('scanOrderId', COMMON_ERROR)
    } else {
      if (values.scanOrderId && values.scanOrderId !== '') {
        toViewOrder()
      } else if (values.orderPhoneNumber !== '') {
        toOrderSummary()
      }
    }
  }, [values])

  return (
    <>
      <View direction="column">
        <Text margin="0 0 16px" fontSize={14}>
          Enter order ID or mobile number to search for orders
        </Text>

        <Field component={FormikInput} label="ORDER ID" name="scanOrderId" />
        <Field
          component={FormikInput}
          label="MOBILE NUMBER"
          name="orderPhoneNumber"
        />
      </View>
      <FixedButton onClick={handleSearch}>Search</FixedButton>
    </>
  )
}
