import { Popup } from '../../Popup'
import './style.css'
import SuccessIcon from './success-icon.svg'

import React from 'react'

const SuccessPopup = ({
  show,
  close,
  heading,
  description,
  buttonText,
  icon,
  confirmPopup,
  handleSubmit,
  dismissButtonText,
}) => {
  return (
    <Popup className="successPopup" show={show} close={close} nohide={true}>
      <img src={icon ? icon : SuccessIcon} alt="success icon" />
      <h3>{heading}</h3>
      <div>{description}</div>
      {confirmPopup ? (
        <div className="button-container">
          <button
            onClick={handleSubmit}
            className="primary button"
            data-testid="success-submit"
          >
            {buttonText}
          </button>
          <button
            onClick={close}
            className="button"
            data-testid="success-dismiss"
          >
            {dismissButtonText}
          </button>
        </div>
      ) : (
        <button
          onClick={close}
          className="primary button"
          data-testid="success-close"
        >
          {buttonText}
        </button>
      )}
    </Popup>
  )
}

export default SuccessPopup
