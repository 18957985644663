import React, { Component } from 'react'
import {
  SearchBox,
  Marker,
  Polygon,
  GoogleMaps
} from '../../../../../components/GoogleMaps'

import { getMessage } from '../../../../../lib/translator'
import { getStores } from '../../../../../lib/auth'
import { get } from '../../../../../lib/storage'
import MarkerIcon from '../icon-marker.svg'
import SearchIcon from './icon-search.svg'

function drawCircle(point, radius, dir) {
  const d2r = Math.PI / 180 // degrees to radians
  const r2d = 180 / Math.PI // radians to degrees
  const earthsradius = 6371000 // 6371000 is the radius of the earth in meters

  const points = 40 // we will get 40 points that lies on circle's circumference

  // find the radius in lat/lng
  const rlat = (radius / earthsradius) * r2d
  const rlng = rlat / Math.cos(point.lat * d2r)

  const extp = []
  let start = points + 1
  let end = 0
  if (dir === 1) {
    start = 0
    end = points + 1
  }
  for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
    const theta = Math.PI * (i / (points / 2))
    const ey = point.lng + rlng * Math.cos(theta) // center a + radius x * cos(theta)
    const ex = point.lat + rlat * Math.sin(theta) // center b + radius y * sin(theta)
    extp.push({ lat: ex, lng: ey })
  }
  return extp
}

class RadialMapComponent extends Component {
  constructor(props) {
    super(props)
    ;['onMapMounted', 'onSearchBoxMounted', 'onPlacesChanged'].forEach(fn => {
      this[fn] = this[fn].bind(this)
    })
    this.searchBox = null
    this.map = null
    this.state = {
      mapMounted: !!window.google,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      startRadius,
      endRadius,
      markerLocation,
      containerClassName,
    } = this.props
    const stateChanged = this.state.mapMounted !== nextState.mapMounted
    const radiusChanged =
      startRadius !== nextProps.startRadius || endRadius !== nextProps.endRadius
    let locationChanged = true
    if (
      nextProps.markerLocation === markerLocation ||
      (nextProps.markerLocation &&
        markerLocation &&
        nextProps.markerLocation.lat === markerLocation.lat &&
        nextProps.markerLocation.lng === markerLocation.lng)
    ) {
      locationChanged = false
    }
    const classChanged = containerClassName !== nextProps.containerClassName
    return stateChanged || radiusChanged || locationChanged || classChanged
  }

  onSearchBoxMounted(ref) {
    this.searchBox = ref
  }

  onMapMounted(ref) {
    this.map = ref
    if (this.props.searchBox) {
      this.setState({
        mapMounted: true,
      })
    }
  }

  onPlacesChanged() {
    const places = this.searchBox.getPlaces()
    const bounds = new window.google.maps.LatLngBounds()

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport)
      } else {
        bounds.extend(place.geometry.location)
      }
    })
    const nextMarkers = places.map(place => ({
      position: place.geometry.location,
    }))
    if (nextMarkers[0] && nextMarkers[0]['position']) {
      this.props.setNewLocation(
        nextMarkers[0].position.lat(),
        nextMarkers[0].position.lng()
      )
    }
  }

  render() {
    const {
      markerLocation,
      searchBox,
      bounds,
      endRadius,
      startRadius,
      setNewLocation,
      draggable,
    } = this.props
    let center = markerLocation
    if (!center || !center.lat || !center.lng) {
      const stores = getStores()
      if (stores && get('store')) {
        const store = stores.filter(
          s => Number(s.id) === Number(get('store'))
        )[0]
        if (store && store.latitude && store.longitude) {
          center = { lat: Number(store.latitude), lng: Number(store.longitude) }
        }
      }
    }
    return (
      <GoogleMaps
        center={center}
        onMapMounted={!window.google && this.onMapMounted}
        {...this.props}
      >
        {searchBox && this.state.mapMounted && (
          <SearchBox
            onLoad={this.onSearchBoxMounted}
            bounds={bounds}
            controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
            onPlacesChanged={this.onPlacesChanged}
          >
            <div className="places-search">
              <input
                type="text"
                placeholder={getMessage(
                  'radial.form.searchLocation.placeholder'
                )}
                className="places-search-input"
              />
              <img className="search-icon" src={SearchIcon} alt="" />
            </div>
          </SearchBox>
        )}
        <Polygon
          paths={[
            drawCircle(center, endRadius, 1),
            drawCircle(center, startRadius, -1),
          ]}
          options={{
            strokeColor: '#7ac8ed',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#7ac8ed',
            fillOpacity: 0.35,
          }}
        />
        {center && (
          <Marker
            icon={MarkerIcon}
            draggable={draggable}
            onDragEnd={e => {
              setNewLocation(e.latLng.lat(), e.latLng.lng())
            }}
            position={center}
          />
        )}
      </GoogleMaps>
    )
  }
}

export { drawCircle }

export default RadialMapComponent
