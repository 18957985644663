import React, { Component } from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { Dialog } from '../../../components/Popup'
import DetailsCard from '../../../components/DetailsCard'

import LeaveForm from './Form'
import LeavesFiltersForm from './Filters'
import './style.css'
import EmptyIcon from './my-leaves-empty.svg'

import { getMessage } from '../../../lib/translator'
import { getSession } from '../../../lib/auth'
import { formatDate, getPrintableTime } from '../../../lib/datetime'

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage('leaves.emptyMessage'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      + {getMessage('leaves.apply.new')}
    </button>
  ),
}

class LeaveActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
    }
    this.showDialog = this.showDialog.bind(this)
    this.hideDialog = this.hideDialog.bind(this)
    this.cancelLeave = this.cancelLeave.bind(this)
  }

  showDialog() {
    this.setState({
      showDialog: true,
    })
  }

  hideDialog() {
    this.setState({
      showDialog: false,
    })
  }

  cancelLeave(id) {
    const { onAction } = this.props
    onAction(TABLE_ACTIONS.UPDATE, { id }, { status: 'CANCELLED' })
  }

  render() {
    const { id } = this.props
    return (
      <div>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem onClick={this.showDialog}>
            {getMessage('leaves.cancel')}
          </DropDownItem>
        </DropDown>
        <Dialog
          show={this.state.showDialog}
          title={getMessage('leaves.dialog.title')}
          information={getMessage('leaves.dialog.information')}
          onOk={() => this.cancelLeave(id)}
          close={this.hideDialog}
          closeText={getMessage('leaves.dialog.cancel')}
          okText={getMessage('leaves.dialog.okText')}
        />
      </div>
    )
  }
}

const tableProperties = {
  headers: [
    getMessage('leaves.header.startsOn'),
    getMessage('leaves.header.endsOn'),
    getMessage('leaves.headers.appliedOn'),
    getMessage('leaves.header.reason'),
    getMessage('leaves.header.type'),
    getMessage('leaves.header.status'),
    getMessage('leaves.header.actions'),
  ],
  row: ({
    id,
    fromDate,
    toDate,
    reason,
    type,
    status,
    appliedOn,
    onAction,
  }) => (
    <Row>
      <Cell>{formatDate(fromDate)}</Cell>
      <Cell>{formatDate(toDate)}</Cell>
      <Cell>{getPrintableTime(appliedOn)}</Cell>
      <Cell>{reason}</Cell>
      <Cell>{type}</Cell>
      <Cell>{status}</Cell>
      <Cell>
        {status === 'APPROVED' || status === 'PENDING' ? (
          <LeaveActions id={id} onAction={onAction} />
        ) : (
          ''
        )}
      </Cell>
    </Row>
  ),
}

const gridView = {
  row: ({ id, fromDate, toDate, reason, type, status, onAction }) => (
    <div className="spacer">
      <DetailsCard
        leftTitle={type}
        leftValue={reason}
        rightTitle={getMessage('leaves.header.status')}
        rightValue={status}
        bottomValue={`${formatDate(fromDate)} - ${formatDate(toDate)}`}
      >
        {status === 'APPROVED' || status === 'PENDING' ? (
          <LeaveActions id={id} onAction={onAction} />
        ) : (
          ''
        )}
      </DetailsCard>
    </div>
  ),
}

export default class MyLeaves extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowSize: window.innerWidth,
    }
    this.registerResize = this.registerResize.bind(this)
  }
  registerResize(e) {
    this.setState({
      windowSize: e.target.innerWidth,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.registerResize, true)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.registerResize, true)
  }

  render() {
    const userId = getSession().user.id
    const { props } = this
    let tableProps = tableProperties
    if (this.state.windowSize <= 768) {
      tableProps = gridView
    }
    return (
      <ListingPage
        menu={props.menu}
        className="my-leaves"
        title={getMessage('my.leaves.title')}
        api={{
          params: { userId: userId },
          url: `/account-service/user-leave`,
          transform: (response) => response.data.userleave,
        }}
        headerActions={({ onAction }) => (
          <button
            className="primary button"
            onClick={() => onAction(TABLE_ACTIONS.ADD)}
          >
            {'+ ' + getMessage('leaves.apply')}
          </button>
        )}
        addHeading={getMessage('leaves.apply.new')}
        emptyState={EmptyState}
        tableProperties={tableProps}
        form={{
          component: LeaveForm,
          transformSubmit: (formData) => {
            const data = Object.assign({}, formData)
            if (formData.dateRange) {
              data.fromDate = formData.dateRange.startDate
              data.toDate = formData.dateRange.endDate
              delete data.dateRange
            }
            return data
          },
        }}
        filters={{
          component: LeavesFiltersForm,
          transformSubmit: (formData) => {
            const filters = Object.assign({}, formData)
            if (formData.type && formData.type === 'ALL') {
              delete filters.type
            }
            if (formData.status && formData.status === 'ALL') {
              delete filters.status
            }
            return filters
          },
        }}
      />
    )
  }
}
