import React, { Component } from 'react'
import API from '../../../lib/api'

import { ConfigForm, domains } from './configform'
import { defaultContract } from './config'
import './style.css'

export default class Recommender extends Component {
  constructor(props) {
    super(props)

    this.recoContractApi = new API({
      url: '/recommender-service/orchid/v1/contract?cache=0',
    })
    this.recoConfigApi = new API({
      url: '/recommender-service/orchid/v2/config?cache=0',
    })
    this.recoapi = new API({
      url: '/recommender-service/orchid/v1/recommender',
    })
    this.state = {
      items: [],
      contract: JSON.parse(defaultContract),
      count: 1,
      status: '',
      msg: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAddAttributes = this.handleAddAttributes.bind(this)
    this.handleDelAttributes = this.handleDelAttributes.bind(this)
    this.handleLoadConfig = this.handleLoadConfig.bind(this)
  }

  componentDidMount() {
    this.recoContractApi
      .get()
      .then(response => {
        if (response.status === 'SUCCESS') {
          this.setState({ contract: response.data, status: '' })
        }
      })
      .catch(error => {
        this.setState({
          msg: 'Load Contract Error:' + error.message,
          status: 'ERROR',
        })
      })
  }

  handleAddAttributes() {
    this.setState({ count: this.state.count + 1 })
  }

  handleDelAttributes() {
    if (this.state.count > 1) {
      this.setState({ count: this.state.count - 1 })
    }
  }

  handleLoadConfig(configID) {
    return new API({
      url: `/recommender-service/orchid/v2/config/${configID}?cache=0`,
    })
      .get({ config_id: configID })
      .then(response => {
        if (response.status === 'SUCCESS') {
          // count attributes
          const domainCount = domains.reduce((acc, domain) => {
            if (response.data[`${domain}_domain`]) {
              return acc + response.data[`${domain}_domain`].length
            } else {
              return acc
            }
          }, 0)
          this.setState({
            status: response.status,
            msg: 'config returned:' + JSON.stringify(response.data),
            config: response.data,
            count: domainCount,
          })
          return response.data
        }
        return response
      })
      .catch(error => {
        this.setState({
          msg: 'Load Config Error:' + error.message,
          status: 'ERROR',
        })
      })
  }

  handleSubmit(form) {
    const payload = {
      user_domain: [],
      model_domain: [],
      filter_domain: [],
      rank_domain: [],
      business_domain: [],
      catalog: 1,
      store_id: form.store_id || '4',
      split: form.split || false,
      config_id: form.config_id
    }
    const attributes = [...Array(this.state.count).keys()]

    attributes.forEach(attributeNo => {
      const domain = form['domain:' + attributeNo]
      const attr = form['attr:' + attributeNo]
      const key = form['key:' + attributeNo]
      const score = form['score:' + attributeNo] || '1'
      const type = form['type:' + attributeNo]
      if (domain && attr && score) {
        const attributeKey = { key: attr, score: parseInt(score, 10) }
        if (type) {
          attributeKey.key_value = {}
          attributeKey.key_value[type] = key
        }
        payload[domain + '_domain'].push(attributeKey)
      }
    })

    // Recommend Items
    if (form['submit_type'] === 'DISPLAY') {
      payload['enterprise'] = 1
      this.recoapi
        .post(payload)
        .then(response => {
          if (response.status === 'SUCCESS') {
            this.setState({
              items: response.data.items,
              status: response.status,
              msg: `result returned: ${response.data.items.length} query: ${JSON.stringify(payload)}`,
            })
          }
        })
        .catch(error => {
          this.setState({
            msg: 'Display Config Error:' + error.message,
            status: 'ERROR',
          })
        })
    }
    // Save Config
    if (form['submit_type'] === 'SAVE_CONFIG') {
      new API({
        url: `/recommender-service/orchid/v2/config/${form['config_id']}`,
      })
        .put(payload)
        .then(response => {
          this.setState({
            status: response.status,
            msg: 'config saved:' + JSON.stringify(payload),
          })
        })
    }
  }
  render() {
    const { items, contract, config } = this.state
    const errorStatus = () => {
      if (this.state.msg !== '' && this.state.msg.length !== 0) {
        if (this.state.status === 'SUCCESS') {
          return <div className="success">{this.state.msg}</div>
        } else {
          return <div className="error">{this.state.msg}</div>
        }
      }
      return <div></div>
    }
    const listItems = items.map((item, index) => {
      const img = (item.images && item.images[0]) || ''
      return (
        <tr key={`${index}-${item.pos}`}>
          <th>{item.pos}:{item.item_id}</th>
          <th>{item.name}</th>
          <th>{item.score}</th>
          <th>
            <img src={img} alt={item.name} width="200" height="200" />
          </th>
          <th>{decodeURIComponent(item.track)}</th>
        </tr>
      )
    })
    return (
      <div className="recommender-config">
        <div>
          <h1> Recommender </h1>
          <ConfigForm
            onSubmit={this.handleSubmit}
            orchidContract={contract}
            orchidConfig={config}
            count={this.state.count}
            onHandleAddAttributes={this.handleAddAttributes}
            onHandleDelAttributes={this.handleDelAttributes}
            onHandleLoadAttributes={this.handleLoadConfig}
          />
        </div>
        {errorStatus()}
        <div className="results">
          <h1> Results </h1>
          <table id="table-results">
            <tbody>
              <tr>
                <th>Item ID</th>
                <th>Name</th>
                <th>Score</th>
                <th>Image</th>
                <th>Track</th>
              </tr>
              {listItems}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
