import React from 'react'
import { View, Text, Badge } from '../ui'
import { PREORDER_STATUSES } from '../constant'

const TESTID_PAYMENT_STATUS = 'payment-status'

export const OrderDetailCard = ({ order, onClick }) => {
  return (
    <View
      border="1px solid rgba(234, 234, 234, 1)"
      borderRadius={4}
      direction="column"
      alignItems="start"
      padding={16}
      margin="16px 0 0"
      onClick={onClick}
      data-testid="order-summary-card"
    >
      <View justifyContent="space-between" margin="0 0 12px">
        <View direction="column" width="fit-content" alignItems="start">
          <Text data-testid="order-no" fontSize={14}>
            {order.id}
          </Text>
          <Text data-testid="amount-total" fontSize={20} fontWeight={900}>
            ${order.total}
          </Text>
        </View>
        {order.status === PREORDER_STATUSES.PENDING_PAYMENT && (
          <Badge data-testid={TESTID_PAYMENT_STATUS} variant="warning">
            Pending Payment
          </Badge>
        )}
        {order.status === PREORDER_STATUSES.COLLECTED && (
          <Badge data-testid={TESTID_PAYMENT_STATUS} variant="secondary">
            Collected
          </Badge>
        )}
        {order.status === PREORDER_STATUSES.CANCELLED && (
          <Badge data-testid={TESTID_PAYMENT_STATUS} variant="danger">
            Cancelled
          </Badge>
        )}
        {order.status === PREORDER_STATUSES.READY_FOR_COLLECTION && (
          <Badge data-testid={TESTID_PAYMENT_STATUS} variant="success">
            Ready to collect
          </Badge>
        )}
        {order.status === PREORDER_STATUSES.PENDING_DISPATCH && (
          <Badge data-testid={TESTID_PAYMENT_STATUS} variant="info">
            In process (Paid)
          </Badge>
        )}
      </View>

      <Text data-testid="store-name" fontWeight={700}>
        {order.storeName}
      </Text>
      <Text data-testid="timeslot" fontWeight={700}>
        {order.pickupTime}
      </Text>
      <Text data-testid="cust-name" margin="12px 0 0" textAlign="left">
        {order.customerName}
      </Text>
      <Text data-testid="cust-mobile">{order.customerPhone}</Text>
    </View>
  )
}
