function getCreativeTypes() {
  return [
    {
      name: 'Play List',
      code: 'PLAYLIST',
    },
    {
      name: 'Single Image Ad',
      code: 'SINGLE_IMAGE_AD',
    },
    {
      name: 'HTML 5',
      code: 'HTML5',
    }]
}

function formatCreativeOptions(creativeTypeList) {
  const _creativeTypeList = []
  if (creativeTypeList) {
    creativeTypeList.forEach(creativeType => {
      _creativeTypeList.push({
        text: creativeType.name === 'all' ? 'All creative types' : creativeType.name,
        value: creativeType.name === 'all' ? ' ' : creativeType.code,
      })
    })
  }
  return _creativeTypeList
}

var creativeStatusList = [
  {
    value: 'Draft',
    text: 'Draft'
  },
  {
    value: 'Complete',
    text: 'Complete'
  }
]

export {
  getCreativeTypes,
  formatCreativeOptions,
  creativeStatusList
}
