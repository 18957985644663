import React, { Component } from 'react'
import {
  ProductSearch,
  Input,
  Select,
  Checkbox,
  BrandSearch,
  CategorySearch,
} from '../../../../components/Form'
import ItemTable from './ItemTable'
import { BANYGLED } from '../'
import { getMessage } from '../../../../lib/translator'
import { handleFocus } from './FormTabs/utils'

class BANYATP extends Component {
  constructor(props) {
    super(props)
    this.submitHandler = this.submitHandler.bind(this)
    this.validationHandler = this.validationHandler.bind(this)
    this.handleUnityOfferCheck = this.handleUnityOfferCheck.bind(this)
  }

  validationHandler(parent) {
    const validations = JSON.parse(JSON.stringify(parent.state.validations))
    parent.setState({
      validations,
    })
  }

  handleUnityOfferCheck(value) {
    const { _this, discountTypes } = this.props
    const { onChange } = _this.generateStateMappers({
      stateKeys: ['rule', 'isUnityOffer'],
    })
    onChange(value)

    // reset discount type if already selected
    const { rule } = _this.state.values
    if (rule && rule.total && rule.total.t) {
      const [unityDiscountType = {}] = discountTypes.filter(
        (type) => type.isUnityOffer
      )
      _this.handleDiscountChange(unityDiscountType.value)
    }
  }

  submitHandler(formData) {
    const data = JSON.parse(JSON.stringify(formData))
    const entityType = data.entityType.toLowerCase()
    const rule = data.rule || {}
    if (rule && rule.total) {
      rule.total.v = Number(rule.total.v)
    }
    if (rule.minAmount) {
      rule.minAmount = Number(rule.minAmount)
    }
    if (rule.minQuantity) {
      rule.minQuantity = Number(rule.minQuantity)
      delete rule.minQuantity
    }
    rule['entity'] = {
      type: data.entityType === 'PRODUCT' ? 'VARIANT' : data.entityType,
      id: data.entityType === 'PRODUCT' ? null : data[entityType].id, // TODO: change this for brands and category
    }
    rule.limit = Number(rule.limit)

    if (entityType === 'product') {
      rule.variants = data.product.map((pdt) => pdt.id).filter(Boolean)
    }

    data.rule = rule
    // data.entityId = 1
    data.offerType = data.type
    data.type = data.rule && data.rule.isUnityOffer ? BANYGLED : data.type
    delete data.pwpTagId
    delete data.quantity
    delete data.minQuantity
    delete data.product
    delete data.category
    delete data.brand
    delete data.entity
    delete data.includes
    delete data.itemDiscountType
    delete data.rule.isUnityOffer
    return data
  }
  render() {
    const {
      entityType: entityTypeProp,
      discountTypes,
      _this,
      type,
      hasOfferStarted,
    } = this.props
    const { values } = _this.state
    const { itemDiscountType, rule } = _this.state.values
    let entityType = entityTypeProp
    entityType = this.props.entityTypeMap[entityType]
    const comboDiscount = itemDiscountType === 'COMBO_DISCOUNT'
    const items = values[entityType]
    const itemsLength = items ? items.length : 0
    const { isUnityOffer } = rule || {}
    const unityDiscountTypes = isUnityOffer
      ? discountTypes.filter((discountType) => discountType.isUnityOffer)
      : discountTypes

    return (
      <div className={`offers-form ${type}-offer`}>
        <Checkbox
          id="unity-check"
          name="unity-check"
          disabled={hasOfferStarted}
          inlineLabel="Discount applicable on lowest-priced item"
          {..._this.generateStateMappers({
            stateKeys: ['rule', 'isUnityOffer'],
            loseEmphasisOnFill: true,
          })}
          onChange={this.handleUnityOfferCheck}
        />
        <div className="offer-fields">
          {entityType === 'product' && (
            <ProductSearch
              required
              label={getMessage(`offer.${entityType}`)}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              name={`entityType-${entityType}-pdt`}
              key={`entityType-${entityType}-pdt`}
              className="product-searchable"
              onChange={_this.addItem}
              readOnly={hasOfferStarted}
            />
          )}
          {entityType === 'category' && (
            <CategorySearch
              name="category-search"
              readOnly={hasOfferStarted}
              required
              label={`CATEGORY`}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              className="category-search"
              {..._this.generateStateMappers({
                stateKeys: [entityType],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {entityType === 'brand' && (
            <BrandSearch
              name="brand-search"
              required
              readOnly={hasOfferStarted}
              label={`BRAND`}
              placeholder={getMessage(`offer.${entityType}.placeholder`)}
              className="category-search"
              {..._this.generateStateMappers({
                stateKeys: [entityType],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Input
            type="number"
            name="quantity"
            onFocus={handleFocus}
            min={1}
            required
            readOnly={hasOfferStarted}
            label={getMessage('offer.quantity')}
            placeholder={getMessage('offer.quantity.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'quantity'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            name="discount-type"
            label={getMessage('offer.discounttype')}
            required
            disabled={hasOfferStarted}
            placeholder={getMessage('offer.discounttype.placeholder')}
            options={unityDiscountTypes}
            className="discount-type"
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'total', 't'],
              loseEmphasisOnFill: true,
            })}
            onChange={_this.handleDiscountChange}
            readOnly={hasOfferStarted}
          />
          <Input
            name="discount-value"
            type="number"
            onFocus={handleFocus}
            id="discount-value"
            step={0.01}
            min={0}
            max={
              values &&
              values.rule &&
              values.rule.total &&
              values.rule.total.t === 'PERCENT_OFF'
                ? 100
                : Number.MAX_SAFE_INTEGER
            }
            required
            readOnly={hasOfferStarted}
            label={getMessage('offer.discountvalue')}
            className="discount-value"
            placeholder={getMessage('offer.discountvalue.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'total', 'v'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div>
          {itemsLength ? (
            <ItemTable
              comboDiscount={comboDiscount}
              entityType={entityType}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
              buyAny
              hasOfferStarted={hasOfferStarted}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

export default BANYATP
