import React from 'react'
import { compose, lifecycle } from 'recompose'
import { SearchBox } from '../GoogleMaps'

const PlacesWithStandaloneSearchBox = compose(
  lifecycle({
    UNSAFE_componentWillMount() {
      const refs = {}

      this.setState({
        places: [],
        onSearchBoxMounted: ref => {
          refs.searchBox = ref
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces()
          this.setState(
            {
              places,
            },
            () => this.props.onPlacesSearched(this.state.places)
          )
        },
      })
    },
  }),
)(props => (
  <div data-standalone-searchbox="">
    <SearchBox
      onLoad={props.onSearchBoxMounted}
      bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
    >
      <div className="field">
        {props.element ? (
          props.element
        ) : (
          <div className="field-wrapper">
            <label htmlFor={props.fieldname}>{props.label}</label>
            <input
              name={props.fieldname}
              value={props.value}
              type="text"
              placeholder={props.placeholder ? props.placeholder : ''}
              onFocus={props.onFocusFunction ? props.onFocusFunction : null}
              onChange={
                props.onChange &&
                (e => {
                  props.onChange(e)
                })
              }
              onBlur={props.onBlurFunction ? props.onBlurFunction : null}
              required={props.required ? props.required : false}
            />
          </div>
        )}
      </div>
    </SearchBox>
  </div>
))

export default PlacesWithStandaloneSearchBox
