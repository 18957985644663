import React, { Component } from 'react'
import { getMessage } from '../../../../../lib/translator'
import deleteIcon from '../icon-dustbin.svg'
import './style.css'
// import { getCurrency } from '../../../../../lib/userDetails'

export default class PolygonForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      polygonName: '',
    }
    this.clearTempData = this.clearTempData.bind(this)
    this.setPolygonName = this.setPolygonName.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    // this.onKeyPress = this.onKeyPress.bind(this)
  }

  onKeyPress(id, e) {
    const { polygonName } = this.state
    if (e.key === 'Enter') {
      this.props.setPolygonName(id, polygonName)
      this.clearTempData()
    }
  }
  handleOnBlur(id) {
    const { polygonName } = this.state
    this.props.setPolygonName(id, polygonName)
    this.clearTempData()
  }
  clearTempData() {
    this.setState({ polygonName: '' })
  }
  setPolygonName(value) {
    this.setState({ polygonName: value })
  }

  render() {
    const {
      active,
      polygonalArea,
      editPolygonId,
      distinctColors,
      editing,
      handleZoneDelete,
      setEditPolygonId,
    } = this.props
    return (
      <div
        className={`polygon-form zones-subsection ${
          active ? '' : 'hidden-section'
        }`}
      >
        <span
          onClick={() => this.props.handleOpenClose('polygon')}
          className="section-title"
        >
          {getMessage('extensions.deliveryArea.polygon.title')}
        </span>
        <span
          onClick={() => this.props.handleOpenClose('polygon')}
          className="open-close-icon"
        >
          {active ? '\u2013' : '+'}
        </span>
        <div className="zones-content">
          {/* <div className='text-muted map-description'><small>{getMessage('polygon.form.map.description')}</small></div> */}
          {/* <Input
            placeholder={`${getCurrency().symbol}0`}
            className='delivery-fee'
            name='deliveryFeePolygon'
            type='number'
          /> */}
          <div className="polygon-locations-list">
            {polygonalArea &&
              polygonalArea.map((zone, index) => {
                return (
                  <div
                    key={`polygonal-zone-item-${index}`}
                    className="list-item"
                  >
                    <span
                      style={{
                        backgroundColor:
                          distinctColors[
                            distinctColors.length -
                              1 -
                              (index % distinctColors.length)
                          ].value,
                      }}
                    />
                    {editPolygonId === zone.id ||
                    editPolygonId === zone.tempId ? (
                      <input
                        value={this.state.polygonName}
                        onChange={e =>
                          this.setState({ polygonName: e.target.value })
                        }
                        onKeyPress={this.onKeyPress.bind(
                          this,
                          zone.id || zone.tempId
                        )}
                        onBlur={() => this.handleOnBlur(zone.id || zone.tempId)}
                        type="text"
                        autoFocus
                      />
                    ) : (
                      <div
                        className="polygon-names"
                        title={editing ? 'click to edit name' : ''}
                        onClick={
                          editing
                            ? () =>
                                this.setState({ polygonName: zone.name }, () =>
                                  setEditPolygonId(zone.id || zone.tempId)
                                )
                            : () => null
                        }
                      >
                        {zone.name ||
                          getMessage('extensions.deliveryArea.polygon.unnamed')}
                      </div>
                    )}
                    {editing && (
                      <span className="delete-polygon">
                        <img
                          src={deleteIcon}
                          onClick={() => {
                            handleZoneDelete(zone.id || zone.tempId)
                          }}
                          alt="delete"
                        />
                      </span>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}
