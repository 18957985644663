import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListingPage from '../../../containers/ListingPage'
import { Cell, Row } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { Popup } from '../../../components/Popup'
import { getMessage } from '../../../lib/translator'
import packageFilters from './filters.js'
import PackagePreview from './PackagePreview'
import SelectedPackagePopup from './PackageInventory/SelectedPackagePopup'
import {
  toggleDeleteDialog,
  DeleteAndErrorDialog,
  throwError
} from '../commonMedia'
import './style.css'

const packageMaxAmount = 20

function getMobileView() {
  return window.screen.width <= 480
}

function AddButton() {
  return (
    <Link to="/media-monetization/media-packagemanagement/media-package-detail" className="primary button">
      + <span className="text">{getMessage('media.add.text')}</span>
    </Link>
  )
}

const InventoryButton = (props) => {
  const packageIds = JSON.stringify([...props.inventoryData.selectedPackages.keys()])
  let searchParams = `?packageIds=${packageIds}`
  const inventoryDuration = JSON.parse(window.sessionStorage.getItem('mediaPackageInventoryDuration'))
  if(inventoryDuration){
    searchParams += inventoryDuration.startDate ? `&startDate=${inventoryDuration.startDate}` : ''
    searchParams += inventoryDuration.endDate ? `&endDate=${inventoryDuration.endDate}` : ''
  }
  return (
    <div className='inventory-btn-box'>
      {!props.inventoryData.status ? (
        <button
          type="button"
          className='button start-btn'
          onClick={() => {
            props.toggleInventoryStatus(1)
          }}
        >{getMessage('media.packageManagement.inventory.title')}</button>
      ) : (
        <div className='action-btns'>
          <button
            type="button"
            className='button left-btn'
            onClick={() => {
              props.toggleInventoryStatus(2)
            }}
          >{getMessage('media.action.exit')}</button>
          <button
            type="button"
            className='button mid-btn'
            onClick={() => {
              props.toggleInventoryPopup(1)
            }}
          >{getMessage('media.packageManagement.inventory.btn.selected')}</button>
          <Link className='button right-btn' to={`/media-monetization/media-packagemanagement/media-package-inventory${searchParams}`}>
            {getMessage('media.packageManagement.inventory.btn.search')}
          </Link>
        </div>
      )}
    </div>
  )
}

const emptyState = {
  message: 'No results found'
}

const tableProperties = (isMobileView, togglePackageBox, inventoryData, _this) => {
  const SelectAll = (
    <div>
      <div>{getMessage('media.action.selectall')}</div>
      <button
        type="button"
        data-testid="package-select-all"
        title={getMessage('media.action.selectall')}
        className={`button package-select-box ${_this.checkPagePackagesAllSelected() ? 'button-checked' : 'button-unchecked'}`}
        onClick={() => {
          _this.handlePackageSelectAll()
        }}
      />
    </div>
  )
  return {
    headers: [
      getMessage('media.packageManagement.header.name'),
      getMessage('media.packageManagement.header.ownerBu'),
      getMessage('media.packageManagement.header.duration'),
      getMessage('media.packageManagement.header.expire'),
      getMessage('media.packageManagement.header.price'),
      `${getMessage('media.packageManagement.header.availableSpots')} / ${getMessage('media.packageManagement.header.maxSlots')}`,
      getMessage('media.packageManagement.header.campaigns'),
      !inventoryData.status ? getMessage('media.packageManagement.header.actions') : SelectAll
    ],
    row: props => (
      <Row>
        <Cell className='website-page-url'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.packageManagement.header.name')}
            </div>
          )}
          <small className='link-btn' onClick={() => {
            togglePackageBox(1, { ...props },1,0)
          }}>{props.packageName}</small>
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.packageManagement.header.ownerBu')}
            </div>
          )}
          {props.assetList.length>0 && props.assetList[0].ownerBu}
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.packageManagement.header.duration')}
            </div>
          )}
          {props.packageDuration}
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.packageManagement.header.expire')}
            </div>
          )}
          {props.expireDate}
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.packageManagement.header.price')}
            </div>
          )}
          {props.packagePrice}
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView && (
            <div className="text-muted">
              {`${getMessage('media.packageManagement.header.availableSpots')} / ${getMessage('media.packageManagement.header.maxSlots')}`}
            </div>
          )}
          {props?.availableSlots ? props?.availableSlots : ' - '}/{props.maxSlots}
        </Cell>
        <Cell className='website-page-url'>
          {isMobileView && (
            <div className="text-muted">
              {getMessage('media.packageManagement.header.campaigns')}
            </div>
          )}
          {props?.reservedCampaigns && props.reservedCampaigns.length > 0 ? (
            <div className="campaign-view-icon" alt="View Campaigns" onClick={() => {
              togglePackageBox(1, { ...props },0,1)
            }}/>
          ) : ' - '}
        </Cell>
        <Cell className='website-page-actions'>
          {!inventoryData.status ? (
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() => {
                togglePackageBox(1, { ...props },1,0)
              }}
            >
              {getMessage('media.action.view')}
            </DropDownItem>
            <DropDownItem>
              <Link to={`/media-monetization/media-packagemanagement/media-package-detail/${props.id}`}>
                {getMessage('media.action.edit')}
              </Link>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                toggleDeleteDialog(_this, 1, props.id, props.onAction)
              }}
            >
              {getMessage('media.action.delete')}
            </DropDownItem>
          </DropDown>
          ) : (
            <button
              type="button"
              data-testid="package-select"
              title='Select'
              className={`button package-select-box ${!inventoryData.selectedPackages.has(props.id) ? 'button-unchecked' : 'button-checked'}`}
              onClick={() => {
                _this.handlePackageSelect([props])
              }}
            />
          )}
        </Cell>
      </Row>
    ),
  }
}

export default class MediaPackageList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileView: getMobileView(),
      isOpenPackageBox: false,
      showCampaigns: false,
      showAssets: false,
      selectedPackageData: {},
      deleteDialog: {
        shown: false,
        id: null,
      },
      errorDialog: {
        shown: false,
        title: '',
        message: ''
      },
      inventoryData: {
        status: false,
        selectedPackages: new Map(),
        inventoryDialog: false
      },
      pageData: [],
    }
    this.mobileView = this.mobileView.bind(this)
    this.togglePackageBox = this.togglePackageBox.bind(this)
    this.toggleInventoryStatus = this.toggleInventoryStatus.bind(this)
    this.toggleInventoryPopup = this.toggleInventoryPopup.bind(this)
    this.handlePackageSelect = this.handlePackageSelect.bind(this)
    this.handlePackageSelectAll = this.handlePackageSelectAll.bind(this)
    this.checkPagePackagesAllSelected = this.checkPagePackagesAllSelected.bind(this)
  }

  togglePackageBox(status, packageData, showAssetsFlag, showCampaignsFlag) {
    if (status === 1) {
      this.setState({
        isOpenPackageBox: true,
        showCampaigns: showCampaignsFlag === 1,
        showAssets: showAssetsFlag === 1,
        selectedPackageData: packageData,
      })
    } else {
      this.setState({
        isOpenPackageBox: false,
        showCampaigns: false,
        showAssets: false,
        selectedPackageData: {},
      })
    }
  }

  toggleInventoryStatus(_status) {
    this.setState({
      inventoryData: {
        ...this.state.inventoryData,
        status: Number(_status) === 1
      }
    })
    window.sessionStorage.setItem('mediaPackageInventoryMode', _status)
  }
  toggleInventoryPopup(_status) {
    this.setState({
      inventoryData: {
        ...this.state.inventoryData,
        inventoryDialog: _status === 1
      }
    })
  }

  handlePackageSelect(packageList){
    let selectedPackages
    let inventoryDialog = this.state.inventoryData.inventoryDialog

    if(!packageList){
      // clear all selected packages
      selectedPackages = new Map()
      inventoryDialog = false
    }
    else{
      selectedPackages = this.state.inventoryData.selectedPackages
      const _packageList = JSON.parse(JSON.stringify(packageList))

      _packageList.forEach(_packageItem => {
        const _id = _packageItem.id
        _packageItem.BU = _packageItem.assetList?.length > 0 ? _packageItem.assetList[0].ownerBu : ""
        delete _packageItem.assetList

        if(!selectedPackages.has(_id)){
          if(selectedPackages.size >= packageMaxAmount){
            throwError(this, { code: 0, message: getMessage('media.packageManagement.inventory.error') }, 'Alert')
            return
          }else{
            selectedPackages.set(_id, _packageItem)
          }
        }else{
          selectedPackages.delete(_id)
        }
      })
    }

    this.setState({
      inventoryData: {
        ...this.state.inventoryData,
        inventoryDialog,
        selectedPackages,
      }
    })
    window.sessionStorage.setItem('mediaPackageInventory', JSON.stringify(Array.from(selectedPackages.entries())))
  }

  handlePackageSelectAll(){
    let packagesTobeAdded = []
    this.state.pageData?.forEach(rowItem => {
      if(!this.state.inventoryData.selectedPackages.has(rowItem.id)){
        packagesTobeAdded.push(rowItem)
      }
    })

    if(packagesTobeAdded.length === 0){
      // unselect all
      packagesTobeAdded = this.state.pageData
      this.handlePackageSelect(packagesTobeAdded)
    }
    else{
      if(this.state.inventoryData.selectedPackages.size + packagesTobeAdded.length > packageMaxAmount){
        throwError(this, { code: 0, message: getMessage('media.packageManagement.inventory.error') }, 'Alert')
      }else{
        this.handlePackageSelect(packagesTobeAdded)
      }
    }
  }

  checkPagePackagesAllSelected(){
    let count = 0
    this.state.pageData?.forEach(rowItem => {
      if(this.state.inventoryData.selectedPackages.has(rowItem.id)){
        count++
      }
    })
    return count >= this.state.pageData.length
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)

    const status = window.sessionStorage.getItem('mediaPackageInventoryMode')
    const selectedPackages = new Map(JSON.parse(window.sessionStorage.getItem('mediaPackageInventory')))
    this.setState({
      inventoryData: {
        ...this.state.inventoryData,
        status: Number(status) === 1,
        selectedPackages,
      }
    })
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
    window.removeEventListener('resize', this.mobileView, false)
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }
  render() {
    return (
      <React.Fragment
      >
        <ListingPage
          className="package-list website-pages"
          title={getMessage('media.packageManagement.title')}
          menu={this.props.menu}
          showLanguageDropDown
          api={{
            url: '/martech-ad-service/search/packages',
            transform: response => {
              this.setState({
                pageData: response.data.content
              })
              return response.data.content
            },
          }}
          emptyState={emptyState}
          headerActions={()=> (
            <React.Fragment>
              <InventoryButton
                inventoryData={this.state.inventoryData}
                toggleInventoryStatus={this.toggleInventoryStatus}
                toggleInventoryPopup={this.toggleInventoryPopup}
              ></InventoryButton>
              <AddButton></AddButton>
            </React.Fragment>
          )}
          tableProperties={
            tableProperties(this.state.isMobileView, this.togglePackageBox, this.state.inventoryData, this)
          }
          filters={{
            component: packageFilters,
            forceShow: true,
            transformSubmit: formData => {
              return Object.assign(
                formData.duration ? {
                  'startDate': formData.duration.startDate,
                  'endDate': formData.duration.endDate
                } : {}, {
                  'bu': formData.bu,
                  'packageName': formData.packageName,
                })
            },
          }}
        />

        <Popup
          show={this.state.isOpenPackageBox}
          className="packagePreview-box"
          heading={getMessage('media.packageDetail.title')}
          close={() => { this.togglePackageBox(2) }}
        >
          <PackagePreview packageData={this.state.selectedPackageData}
                          showCampaigns={this.state.showCampaigns}
                          showAssets={this.state.showAssets}>
          </PackagePreview>
        </Popup>

        <SelectedPackagePopup
          inventoryData={this.state.inventoryData}
          toggleInventoryPopup={this.toggleInventoryPopup}
          handlePackageSelect={this.handlePackageSelect}
        ></SelectedPackagePopup>

        <DeleteAndErrorDialog
          _this={this}
          deleteDialog={this.state.deleteDialog}
          errorDialog={this.state.errorDialog}
          deleteApi='/martech-ad-service/packages/'
          deleteClass='package-list website-pages'
          refreshApi='/martech-ad-service/search/packages'
        ></DeleteAndErrorDialog>

      </React.Fragment>
    )
  }
}
