import React, { Component } from 'react'
import partial from 'lodash/partial'
import uniq from 'lodash/uniq'
import chunk from 'lodash/chunk'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import Loader from '../../../../components/Loader'
import { getMessage } from '../../../../lib/translator'
import { buildProductNameMap, snakeToCamel } from '../BatchJobsConfig'
import API, { UNAUTHORISED_STATUS } from 'lib/api'

const BATCHING_SIZE = 300

const getIndex = (headers, header) => {
  return headers
    .map((h) => h.replace(/ /g, '').toLowerCase())
    .indexOf(header.toLowerCase())
}

const getTableData = (results, jobName, JobConfig, productMap) => {
  const CSV_HEADERS = JobConfig[jobName].csvHeaders
  const fileHeaders = results[0]
  const getIndexPartial = partial(getIndex, fileHeaders)

  const indexPartials = {}

  for (const key in CSV_HEADERS) {
    indexPartials[CSV_HEADERS[key].header] = getIndexPartial(
      CSV_HEADERS[key].header
    )
  }

  const rows = results.slice(1)

  try {
    return rows.map((row) => {
      const properties = {}
      for (const key in CSV_HEADERS) {
        const product = productMap[row[indexPartials['sku']]] || {}
        const propertyName = snakeToCamel(CSV_HEADERS[key].header)
        properties[propertyName] = row[indexPartials[CSV_HEADERS[key].header]]
        properties['sku'] = {
          id: row[indexPartials['sku']],
          name: product.name,
        }
        properties['productName'] = {
          name: product.name,
          unit: product.displayUnit,
        }
      }

      return properties
    })
  } catch (e) {
    /* istanbul ignore next */
    console.error('Error while generating review data', e)
  }
  /* istanbul ignore next */
  return []
}

export default class BulkGlobalProductComplexAttributesReview extends Component {
  constructor() {
    super()
    this.state = {
      products: {},
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    Promise.all([this.getProductsBatch(this.props.results)])
      .catch((e) => {
        this.setState({
          error: e,
        })
        if (e.code === UNAUTHORISED_STATUS) {
          throw e
        }
      })
      .finally(() =>
        this.setState({
          loading: false,
        })
      )
  }

  getProductsBatch(results = []) {
    const primarySkus = results.slice(1).map((row) => row[0])
    const skus = uniq(primarySkus)
    if (skus.length) {
      return Promise.all(chunk(skus, BATCHING_SIZE).map(this.getProducts)).then(
        (responses) => {
          const products = responses.reduce((acc, response) => {
            const prds = response.data ? response.data.product || [] : []
            return [...acc, ...prds]
          }, [])
          this.setState({
            products: buildProductNameMap(products) || {},
          })
        }
      )
    }
    /* istanbul ignore next */
    return Promise.resolve()
  }

  getProducts(skus) {
    const productApi = new API({
      url: `/catalogue-service/product`,
    })
    if (skus.length) {
      return productApi.get({ paginate: 'false', clientId: skus.slice() })
    }
    /* istanbul ignore next */
    return Promise.resolve()
  }

  // getRowErrors :: Array, Object -> Boolean
  getRowErrors(tableData, csvHeaders, fileHeaders) {
    return tableData.map((row) => {
      return !fileHeaders.reduce((isValid, h) => {
        const key = (h || '').toUpperCase()
        const { validate = () => true, header } = key ? csvHeaders[key] : {}
        const field = snakeToCamel(header)
        return (
          isValid && validate(row[field], row[field.replace(/Action$/, '')])
        )
      }, true)
    })
  }

  isValidData(csvHeaders, column, row) {
    const key = (column || '').toUpperCase()
    const { validate = () => true, header } = key ? csvHeaders[key] : {}
    const field = snakeToCamel(header)
    return validate(row[field], row[field.replace(/Action$/, '')])
  }

  getSelectedHeaders(csvHeaders, fileHeaders) {
    return fileHeaders.map((h) => {
      const key = (h || '').toUpperCase()
      return key ? csvHeaders[key] : {}
    })
  }

  getTableHeaders(csvHeaders, fileHeaders) {
    return this.getSelectedHeaders(csvHeaders, fileHeaders).map((obj) => {
      return obj.displayHeader
    })
  }

  getColumnFields(csvHeaders, fileHeaders) {
    return this.getSelectedHeaders(csvHeaders, fileHeaders).map((obj) => {
      const errors = obj.message()
      return {
        header: obj.header,
        field: snakeToCamel(obj.header),
        render: obj.render,
        errorMessage: errors?.length ? errors[0] : '',
      }
    })
  }

  render() {
    const { results = [], jobName, actions, JobConfig } = this.props
    const { error, loading, products } = this.state
    const tableData = getTableData(results, jobName, JobConfig, products)
    const csvHeaders = JobConfig[jobName].csvHeaders
    const isValidDataPartial = partial(this.isValidData, csvHeaders)
    const uploadedHeaders = results[0] || []
    const givenHeaders = uploadedHeaders.reduce((hdr, h) => {
      return [...hdr, h]
    }, [])
    const fileHeaders = [
      givenHeaders[0],
      csvHeaders.PRODUCT_NAME.header,
      ...givenHeaders.slice(1),
    ]
    const headers = this.getTableHeaders(csvHeaders, fileHeaders)
    const validationErrors = this.getRowErrors(
      tableData,
      csvHeaders,
      fileHeaders
    )
    const columnFields = this.getColumnFields(csvHeaders, fileHeaders)
    return loading ? (
      <Loader />
    ) : (
      <div className="review-table">
        {error && <div className="error">{getMessage('error.server')}</div>}
        {!loading && validationErrors.some(Boolean) && (
          <div className="error">
            <span className="circle-error"></span>
            {getMessage('category.bulk-upload.error')}
          </div>
        )}
        <div className="table-container complex">
          <Table tableDynamic={false}>
            <Header items={headers} />
            {tableData.map((row, index) => (
              <>
                <Row
                  key={`${row[index]}-${index}`}
                  className={
                    !loading && validationErrors[index]
                      ? 'row-error-complex'
                      : ''
                  }
                >
                  {columnFields.map((col) => {
                    const { header, field, render, errorMessage } = col
                    return (
                      <Cell
                        key={field}
                        className={
                          !loading && !isValidDataPartial(header, row)
                            ? `${field} cell-error-complex`
                            : field
                        }
                      >
                        {!loading && !isValidDataPartial(header, row) && (
                          <div className="error-tooltip">
                            <span></span>
                            <div className="complex-error-message">
                              {errorMessage}
                            </div>
                          </div>
                        )}
                        {render ? render(row[field]) : row[field]}
                      </Cell>
                    )
                  })}
                </Row>
              </>
            ))}
          </Table>
        </div>
        {actions(validationErrors.some(Boolean))}
      </div>
    )
  }
}
