import React from 'react'
import './style.css'

const Card = ({ src, texts, category }) => {
  return (
    <div className={category ? `${category} card` : 'card'}>
      <div className="card-image-wrapper">
        <img className="card-image" src={src || ''} alt="Card Image" />
      </div>
      <div className="texts">
        {Object.values(texts).map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
    </div>
  )
}

export default Card
