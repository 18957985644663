const data = {
  'omni-journey.error-msg.incomplete-form': 'Form is incomplete.',
  'omni-journey.error-msg.encounter-issue':
    'Oops, we encountered a little issue...',
  'omni-journey.success-msg.upload-success': 'Upload successful!',
  'omni-journey.try-again-button': 'Try again?',
  'omni-journey.close-button': 'Close',
  'omni-journey.cancel-button': 'Cancel',
  'omni-journey.upload-button': 'Upload',

  'omni-journey.start-date-field.label': 'Start date',
  'omni-journey.start-time-field.label': 'Start time',
  'omni-journey.date-field.placeholder': 'Pick date',
  'omni-journey.time-field.placeholder': 'Select time',
  'omni-journey.category-field.label': 'Category',
  'omni-journey.category-field.placeholder': 'Select Category',
  'omni-journey.storeId-field.label': 'Store ID',
  'omni-journey.storeId-field.placeholder': 'Enter store ID',
  'omni-journey.storeType-field.label': 'Store Type',
  'omni-journey.storeType-field.placeholder': 'Enter store type',
  'omni-journey.fileUpload-field.placeholder': 'Click here to upload file',

  'omni-journey.voucher.header': 'Manage Vouchers',
  'omni-journey.voucher.upload-sequence': 'Upload Voucher Sequence',
}

export default data
