import React, { Component, useContext } from 'react'
import { Row, Cell } from '../../../components/Table'
import { getMessage } from '../../../lib/translator'
import NewListingPage, {
  TABLE_ACTIONS,
} from '../../../containers/NewListingPage'
import campaignsEmpty from './images/campaigns_empty.svg'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import imagePlaceholder from './images/image-placeholder.svg'
import SearchCampaignsForm from './Forms'
import SearchCampaignsFilter from './Filters'
import { getStores, isExtensionEnabled } from '../../../lib/auth'
import { getStoreName } from '../../../containers/StoreSelector'
import { searchCampaignsList } from './mock'
import './style.css'
import moment from 'moment'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { SplitContext } from 'containers/SplitContext'

const timeFormat = 'YYYY-MM-DD HH:mm'
const dateFormat = 'YYYY-MM-DD'
const emptyState = {
  icon: campaignsEmpty,
  message: getMessage('search-campaigns.empty.campaigns'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      + {getMessage('search-campaigns.add.text')}
    </button>
  ),
}
const tableProperties = () => {
  return {
    headers: [
      getMessage('search-campaigns.image'),
      getMessage('search-campaigns.id'),
      getMessage('search-campaigns.name'),
      getMessage('search-campaigns.type'),
      getMessage('search-campaigns.status'),
      getMessage('search-campaigns.validFrom'),
      getMessage('search-campaigns.validTill'),
      getMessage('search-campaigns.action'),
    ],
    row: ({
      logo,
      id,
      name,
      type,
      startDate,
      endDate,
      status,
      onAction,
      index,
    }) => (
      <Row>
        <Cell className="campaign-image">
          <img
            className="image-size"
            src={logo || imagePlaceholder}
            alt="campaign_image"
            width="50px"
          />
        </Cell>
        <Cell className="search-campaigns-id">{id}</Cell>
        <Cell className="campaign-name">
          <a
            data-key={index}
            onClick={() => onAction(TABLE_ACTIONS.VIEW, { id })}
          >
            {name || 'campaign name'}
          </a>
        </Cell>
        <Cell className="search-campaigns-type">{type || 'campaign type'}</Cell>
        <Cell className="search-campaigns-status">{status || 'status'}</Cell>
        <Cell className="search-campaigns-campaignValidFrom">
          {moment(startDate).format(timeFormat) || 'valid from'}
        </Cell>
        <Cell className="search-campaigns-campaignValidTill">
          {endDate ? moment(endDate).format(timeFormat) || 'valid till' : 'N/A'}
        </Cell>
        <Cell className="search-campaigns-actions">
          <SearchCampaignActions
            onAction={onAction}
            id={id}
            status={status}
            validFrom={startDate}
            index={index}
          />
        </Cell>
      </Row>
    ),
  }
}

class SearchCampaignActions extends Component {
  render() {
    const { id, onAction, index } = this.props
    return (
      <React.Fragment>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem
            datakey={'view' + index}
            onClick={() => onAction(TABLE_ACTIONS.VIEW, { id })}
          >
            {getMessage('search-campaigns.view')}
          </DropDownItem>
          <DropDownItem
            datakey={'edit' + index}
            onClick={() => {
              onAction(TABLE_ACTIONS.EDIT, { id })
            }}
          >
            {getMessage('search-campaigns.edit')}
          </DropDownItem>
          <DropDownItem
            datakey={'delete' + index}
            onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}
          >
            {getMessage('search-campaigns.delete')}
          </DropDownItem>
        </DropDown>
      </React.Fragment>
    )
  }
}

class SearchCampaignsClass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      campaignTypes: [
        { text: 'Default', value: 'default' },
        { text: 'Keyword', value: 'keyword' },
      ],
      pages: [
        {
          text: getMessage('search-campaigns.pageType.category'),
          value: 'category',
        },
      ],
      pageTypes: {
        category: 'category',
        brand: 'brand',
        productTag: 'tag',
      },
      scopes: {
        global: 'GLOBAL',
        storeSpecific: 'STORE',
      },
    }
  }
  componentDidMount() {
    this.setState({
      campaignList: searchCampaignsList.data.campaigns,
    })
    const productTagEnabled = isExtensionEnabled('ProductTagSupport')
    const brandsEnabled = isExtensionEnabled('MultiBrandSupport')
    const multiStoreEnabled = isExtensionEnabled('MultiStoreSupport')
    this.setState({ productTagEnabled, brandsEnabled, multiStoreEnabled })
    const { pages } = this.state
    if (productTagEnabled) {
      pages.push({
        text: getMessage('search-campaigns.pageType.productTag'),
        value: 'tag',
      })
      this.setState({ pages })
    }
    if (brandsEnabled) {
      pages.push({
        text: getMessage('search-campaigns.pageType.brand'),
        value: 'brand',
      })
      this.setState({ pages })
    }
    if (multiStoreEnabled) {
      const stores = (getStores() || []).map(store => ({
        ...store,
        name: getStoreName(store),
      }))
      this.setState({
        stores,
      })
    }
  }
  componentWillUnmount() {
    this.api && this.api.cancel()
  }
  render() {
    const { props } = this
    const isSearchServiceEnabled = props.splits?.[SPLIT_FEATURES.SEARCH_SERVICE_FLAG]?.treatment === 'on';
    const {
      stores,
      scopes,
      campaignTypes,
      pages,
      pageTypes,
      brandsEnabled,
      productTagEnabled,
      multiStoreEnabled,
    } = this.state
    const defaultStatus = 'ENABLED'
    return (
      <NewListingPage
        testid="search-campaigns-table"
        className="search-campaigns-page"
        menu={props.menu}
        title={getMessage('search-campaigns.title')}
        api={{
          url: isSearchServiceEnabled ? `/search-service/campaigns` : `/fp-search-service/campaigns`,
          defaultQuery: { status: defaultStatus },
          transform: response => {
            let campaignData = response.data.campaigns
            campaignData = campaignData.map(campaign => {
              campaign.keywords =
                campaign.keywords && typeof campaign.keywords === 'string'
                  ? campaign.keywords.split(',')
                  : campaign.keywords
              campaign.type = campaign.keywords.length ? 'keyword' : 'default'
              //converting storeId from object to string, convert to array of numbers and filter non numbers out
              campaign.storeId = campaign.storeId
                .toString()
                .split(',')
                .map(el => {
                  return Number(el)
                })
                .filter(el => !isNaN(el) && el)
              campaign.scope = campaign.storeId.length ? 'STORE' : 'GLOBAL'
              campaign.pageType = campaign.pageType.toLowerCase()
              return campaign
            })
            return campaignData
          },
        }}
        modalClassName={'search-campaigns-popup'}
        emptyState={emptyState}
        addHeading={getMessage('search-campaigns.add.heading')}
        editHeading={getMessage('search-campaigns.edit.heading')}
        viewHeading={getMessage('search-campaigns.view.heading')}
        tableProperties={tableProperties()}
        headerActions={({ onAction }) => (
          <button
            className="primary button vendor-btn"
            onClick={() => {
              onAction(TABLE_ACTIONS.ADD)
            }}
          >
            +{' '}
            <span className="text" data-testid="add-campaign">
              {getMessage('search-campaigns.add.text')}
            </span>
          </button>
        )}
        filters={{
          component: SearchCampaignsFilter,
          forceShow: true,
          options: {
            campaignTypes,
            defaultStatus,
            stores,
            pageTypes,
            pages,
          },
          transformSubmit: formData => {
            const data = Object.assign({}, formData)
            data.startDate = data.startDate && moment(data.startDate).format()
            data.endDate = data.endDate && moment(data.endDate).format()
            if (data.pageType === 'category') {
              data.slug =
                data.categoryUrl &&
                data.categoryUrl.map(each => each.slug).join()
            } else if (data.pageType === 'tag') {
              data.slug =
                data.productTagUrl &&
                data.productTagUrl.map(each => each.slug).join()
            } else if (data.pageType === 'brand') {
              data.slug =
                data.brandUrl && data.brandUrl.map(each => each.slug).join()
            }
            delete data.categoryUrl
            delete data.productTagUrl
            delete data.brandUrl
            return data
          },
        }}
        form={{
          component: SearchCampaignsForm,
          options: {
            defaultStatus,
            campaignTypes,
            pages,
            pageTypes,
            brandsEnabled,
            productTagEnabled,
            stores,
            scopes,
            multiStoreEnabled,
          },
          transformSubmit: formData => {
            const data = Object.assign({}, formData)
            data.keywords =
              data.type === 'keyword' && data.keywords
                ? data.keywords.join()
                : ''
            data.description = data.type === 'default' ? data.description : ''
            data.storeId =
              data.scope === 'STORE' && data.storeId ? data.storeId.join() : ''
            if (data.pageType === 'category') {
              data.slug = data.categoryUrl.slug
            } else if (data.pageType === 'tag') {
              data.slug = data.productTagUrl.slug
            } else if (data.pageType === 'brand') {
              data.slug = data.brandUrl.slug
            }
            data.status = data.status ? data.status : 'ENABLED'
            data.startDate = data.campaignStartTime
              ? moment(
                  moment(data.startDate).format(dateFormat) +
                    ` ${data.campaignStartTime}`
                ).format()
              : moment(data.startDate).format()
            if (data.endDate) {
              if (data.campaignEndTime) {
                data.endDate = moment(
                  moment(data.endDate).format(dateFormat) +
                    ` ${data.campaignEndTime}`
                ).format()
              } else {
                data.endDate = moment(
                  moment(data.endDate).format(dateFormat) + ` 00:00`
                ).format()
              }
            }
            return data
          },
        }}
      />
    )
  }
}

const SearchCampaigns = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig

  return (
    <SearchCampaignsClass {...props} splits={splits} />
  )
}

export default SearchCampaigns
