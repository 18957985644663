import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Popup } from '../../../components/Popup'
import './style.css'
import Table, { Cell, Header, Row } from 'components/Table'

export const RolesPopup = (props) => {
  const { roles, designation } = props
  const [showPopup, setShow] = useState(false)
  if (showPopup) {
    return (
      <Popup
        show={showPopup}
        close={() => setShow(false)}
        heading={`${designation}: Roles`}
        className="roles-table-popup"
      >
        <Table tableDynamic={false}>
          <Header items={['Role', 'Route']} />
          {roles.map((role) => (
            <Row key={role.id} className="row">
              <Cell className="wrap-cell">{role.name}</Cell>
              <Cell className="route">
                {role.backofficeUrl !== null ? (
                  <Link to={'/' + role.backofficeUrl}>
                    <button className="">{role.backofficeUrl}</button>
                  </Link>
                ) : (
                  <>-</>
                )}
              </Cell>
            </Row>
          ))}
        </Table>
      </Popup>
    )
  }
  return (
    <button className="view-role-btn" onClick={() => setShow(true)}>
      View Roles
    </button>
  )
}
