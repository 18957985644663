import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../Table'
import { DropDown, DropDownItem, ICONS } from '../../DropDown'
import { getMessage } from 'lib/translator'

export const tableProperties = {
  headers: [
    getMessage(`rewards.id`),
    getMessage(`rewards.device.serialNumber`),
    getMessage(`rewards.clsMerchantId`),
    getMessage(`rewards.device.clsBranchNo`),
    getMessage(`rewards.device.clsTerminalId`),
    getMessage(`rewards.device.clsOperatorId`),
    getMessage(`rewards.device.storeName`),
    getMessage(`rewards.status`),
    getMessage(`rewards.actions`),
  ],
  row: ({
    id,
    serialNumber,
    status,
    clsBranchNo,
    clsTerminalId,
    clsOperatorId,
    clsMerchantId,
    storeName,
  }) => (
    <Row>
      <Cell className="rewards-page-actions column-id">
        <Link to={`/rewards/devices/edit/${id}`}>{id}</Link>
      </Cell>
      <Cell className="rewards-page-actions serial-number">
        <Link to={`/rewards/devices/edit/${id}`}>{serialNumber}</Link>
      </Cell>
      <Cell className="rewards-page-actions cls-merchant-id">
        {clsMerchantId}
      </Cell>
      <Cell className="rewards-page-actions cls-branch-no">{clsBranchNo}</Cell>
      <Cell className="rewards-page-actions cls-terminal-id">
        {clsTerminalId}
      </Cell>
      <Cell className="rewards-page-actions cls-operator-id">
        {clsOperatorId}
      </Cell>
      <Cell className="rewards-page-actions store-name">{storeName}</Cell>
      <Cell className="rewards-page-actions column-status">{status}</Cell>
      <Cell className="rewards-page-actions column-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <Link to={`/rewards/devices/edit/${id}`}>
            <DropDownItem>{getMessage('rewards.device.edit')}</DropDownItem>
          </Link>
        </DropDown>
      </Cell>
    </Row>
  ),
}

export const createMapData = (values) => ({
  serialNumber: values.serialNumber,
  status: Number(values.status || 1) ? 'ACTIVE' : 'INACTIVE',
  clsTerminalId: values.clsTerminalId,
  clsOperatorId: values.clsOperatorId,
  clsMerchantId: values.clsMerchantId,
  clsBranchNo: values.clsBranchNo,
  storeName: values.storeName,
  storeAddress: values.storeAddress,
})

export const deviceDataCsvExample = () => {
  const CSV_HEADERS = [
    'cls_corporate_id',
    'merchant_name',
    'is_award_and_redeem_enabled',
    'is_vouchers_enabled',
    'store_name',
    'store_address',
    'branch_no',
    'terminal_id',
    'operator_id',
    'serial_number',
  ]

  function generateCSVRow(data) {
    const rowValues = CSV_HEADERS.map((header) => data[header] || '')
    return rowValues.join(',')
  }

  const dataObjects = [
    {
      cls_corporate_id: '1658265',
      merchant_name: 'MerchantName',
      is_award_and_redeem_enabled: 'true',
      is_vouchers_enabled: 'false',
      store_name: 'Cheers',
      store_address: 'Texas Chicken Redhill',
      branch_no: 'Branch1',
      terminal_id: '743779',
      operator_id: 'Operator1',
      serial_number: 'PB0AP2C220715',
    },
  ]

  let csv = ''
  csv += CSV_HEADERS.join(',') + '\n'

  dataObjects.forEach((data) => {
    csv += generateCSVRow(data) + '\n'
  })

  // Return the generated CSV as a string
  return csv
}
