import React, { Component } from 'react'
import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'
import API from '../../../../lib/api'
import Loader from '../../../../components/Loader'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { JobConfig } from '../BatchJobsConfig'

const BATCH_SIZE = 1000

const getRows = results => {
  const customerIdIdx = results[0].indexOf('customer_id')
  const promoCodeIdx = results[0].indexOf('promo_code')
  return results.slice(1).map(result => ({
    customerId: result[customerIdIdx] ? Number([result[customerIdIdx]]) : '',
    promoCode: result[promoCodeIdx],
  }))
}

export default class BulkVoucherDeposit extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const { results = [] } = this.props
    const rows = getRows(results)
    this.setState({
      loading: true,
    })

    this.createVoucherDeposits(rows)
  }

  createVoucherDeposits(rows) {
    const offerWalletAPI = new API({
      url: this.props.endpoint,
    })

    const vouchersByPromoCode = rows.reduce((acc, row) => {
      if (acc[row.promoCode]) {
        acc[row.promoCode] = [...acc[row.promoCode], row.customerId]
      } else {
        acc[row.promoCode] = [row.customerId]
      }

      return acc
    }, {})

    const payload = Object.keys(vouchersByPromoCode).map(promoCode => ({
      organizationId: '2',
      promoCode,
      customerIds: vouchersByPromoCode[promoCode],
    }))

    const chunkedPayload = chunk(payload, BATCH_SIZE)

    chunkedPayload.forEach(chunkPayload => {
      this.setState({
        loading: true,
      })
      offerWalletAPI
        .post({ vouchers: chunkPayload })
        .then(response => {
          this.setState({
            updates: response.data.success,
            failedUpdates: response.data.failed,
          })
        })
        .finally(() =>
          this.setState({
            loading: false,
          })
        )
    })
  }

  render() {
    const { jobName, onBack } = this.props
    const { loading, failedUpdates = [], updates = [] } = this.state
    const headers = JobConfig[jobName].getTableHeaders()
    let failedRows = failedUpdates.map(item => {
      return (item.customerIds || []).map(id => ({
        promoCode: item.promoCode,
        customerId: id,
        error: item.error,
      }))
    })

    failedRows = flatten(failedRows)
    const successCount = updates.reduce(
      (acc, item) => acc + (item.customerIds || []).length,
      0
    )

    return (
      <div>
        {loading && <Loader />}
        <div className="message-box">
          <div className="update-message">Vouchers have been deposited!</div>
          <div className="update-message">
            Number of successful updates:{' '}
            <span className="count">{successCount}</span>
          </div>
          <div className="update-message">
            Number of failed updates:{' '}
            <span className="count">{failedRows.length}</span>
          </div>
        </div>
        {failedRows.length > 0 && (
          <div className="invalid-message">
            Below is the list of failed updates
          </div>
        )}
        <div className="table-container">
          {failedRows.length > 0 && (
            <Table tableDynamic={false}>
              <Header items={headers} />
              {failedRows.filter(Boolean).map((row, rowIndex) => (
                <Row className={`row`} key={rowIndex}>
                  <Cell>
                    <span>{row.customerId}</span>
                  </Cell>
                  <Cell>
                    <span>{row.promoCode}</span>
                  </Cell>
                  <Cell>
                    <span>{row.error}</span>
                  </Cell>
                </Row>
              ))}
            </Table>
          )}
          <div className="actions">
            <button className="button" onClick={onBack}>
              Ok
            </button>
          </div>
        </div>
      </div>
    )
  }
}
