import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import Tabs from '../../../../components/Tabs'
import API from '../../../../lib/api'
import { BaseForm } from '../../../../components/Form'
import { Dialog } from 'components/Popup'
import Details from '../../../../components/OmniChallenge/DetailsTab/Details'
import Mechanics from '../../../../components/OmniChallenge/MechanicsTab/Mechanics'
import { getMessage } from 'lib/translator'
import { camelToReading } from '../../../../lib/commonlyused'
import {
  formatCampaignDate,
  fetchStoresOption,
  formatTaskList,
  transformAPIData,
  formatConfig,
  DEFAULT_CONFIG,
  validateAwardList,
  validateSpendRelatedTaskChannels,
  calculatePtrModification,
} from '../../../../components/OmniChallenge/utils'
import './style.css'

class OmniChallengesForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: null,
      showErrorDialog: false,
      segmentOptions: [],
      storesOptions: [],
      formReady: false,
      submitting: false,
      activeIndex: 0,
      challengeStarted: false,
      loading: false,
      values: {
        taskList: [
          {
            userAction: '',
            type: 'TRANSACTION',
            awardList: [],
          },
        ],
        isEnabled: true,
        isNotificationEnabled: false,
        config: DEFAULT_CONFIG,
        type: 'FPG',
        customerTags: [],
      },
      ptrModification: { error: false, message: '' },
    }

    this.changeTab = this.changeTab.bind(this)
    this.nextTab = this.nextTab.bind(this)
    this.verifyData = this.verifyData.bind(this)
    this.formatData = this.formatData.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.fetchClonedData = this.fetchClonedData.bind(this)
    this.handlePatch = this.handlePatch.bind(this)
    this.requestHandler = this.requestHandler.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.fetchSegmentOption = this.fetchSegmentOption.bind(this)
  }

  changeTab(activeIndex) {
    this.setState({ activeIndex: this.state.loading ? 0 : activeIndex })
  }

  nextTab() {
    const activeIndex = this.state.activeIndex
    this.changeTab(activeIndex + 1)
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    await this.verifyData()
    this.requestHandler('POST')
  }

  verifyData() {
    const validations = Object.assign({}, this.state.validations)
    return new Promise((resolve) => {
      if (validations.taskList && !this.isFormValid(validations.taskList)) {
        this.changeTab(1)
      } else if (!this.isFormValid()) {
        this.changeTab(0)
      } else {
        resolve(this.setState({ formReady: true }))
      }
    })
  }

  formatData() {
    const {
      startDate,
      startTime,
      endDate,
      endTime,
      name,
      config,
      participationGuide,
      taskList,
      deletedTaskList,
      isEnabled,
      isNotificationEnabled,
      imgUrls,
      tncUrl,
      type,
      awardSummary = '',
      description = '',
    } = this.state.values

    validateSpendRelatedTaskChannels(taskList)
    validateAwardList(taskList)

    const { startAt, endAt } = formatCampaignDate(
      startDate,
      startTime,
      endDate,
      endTime
    )

    const tasks = formatTaskList(taskList)
    const deletedTasks = deletedTaskList ? formatTaskList(deletedTaskList) : []

    return {
      name,
      type,
      awardSummary,
      description,
      imgUrls,
      tncUrl,
      isEnabled,
      isNotificationEnabled,
      startAt,
      endAt,
      participationGuide,
      tasks,
      deletedTasks,
      config: config.segment.type === '' ? config : formatConfig(config),
    }
  }

  async requestHandler(req) {
    try {
      switch (req) {
        case 'POST': {
          const data = this.formatData()
          const api = new API({ url: '/chendol/challenges' })
          const response = await api.post(data)
          response && this.props.onCancel()
          break
        }
        case 'PATCH': {
          const { id } = this.props.value
          const data = this.formatData()
          const api = new API({ url: `/chendol/challenges/${id}` })
          const response = await api.patch(data)
          response && this.props.onCancel()
          break
        }
      }
    } catch (error) {
      console.error('Error submitting challenges:', error)
      this.setState({
        showErrorDialog: true,
        errorMessage: error.message,
      })
    }
  }

  componentDidMount() {
    const { search } = this.props.location
    const cloneId = search.split('=')[1]

    this.fetchSegmentOption()
    fetchStoresOption(this)

    if (this.props.method === 'edit') {
      const { id } = this.props.value
      this.setState({ loading: true })
      this.fetchData(id)
    }

    if (this.props.method === 'add' && cloneId) {
      this.setState({ loading: true })
      this.fetchClonedData(cloneId)
    }
  }

  fetchSegmentOption() {
    const segmentsApi = new API({ url: '/promo-service/segment' })
    segmentsApi
      .get()
      .then((response) => {
        const segmentData = response.data.segment || []
        const options = segmentData.filter((segment) => {
          return segment.name === 'CustomersMappedToTag'
        })
        this.setState({
          segmentOptions: [
            {
              text: getMessage(camelToReading(options[0].name)),
              value: 'CUSTOMERS_MAPPED_TO_TAG',
            },
            {
              text: getMessage('rewards.customersMappedToSegment'),
              value: 'CUSTOMERS_MAPPED_TO_SEGMENT',
            },
          ],
        })
      })
      .catch((error) => {
        this.setState({
          showErrorDialog: true,
          errorMessage: error.message,
        })
      })
  }

  async fetchData(id) {
    this.setState({ loading: true })
    try {
      const api = new API({ url: `/chendol/challenges/${id}` })
      const values = await api.get()
      const ptrModification = calculatePtrModification(
        values,
        this?.state?.ptrModification
      )

      this.setState({
        values: await transformAPIData(values),
        challengeStarted: moment().isAfter(values.startAt),
        showErrorDialog: ptrModification?.error,
        errorMessage: ptrModification?.message,
        ptrModification,
      })
    } catch (error) {
      this.setState({
        showErrorDialog: true,
        errorMessage: error.message,
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  async handlePatch() {
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    await this.verifyData()
    this.requestHandler('PATCH')
  }

  async fetchClonedData(id) {
    try {
      const api = new API({ url: `/chendol/challenges/${id}` })
      /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
      const { name, ...response } = await api.get()
      const values = await transformAPIData({
        ...response,
        isEnabled: true,
        isNotificationEnabled: false,
      })
      this.setState({ values, loading: false })
    } catch (error) {
      this.setState({
        showErrorDialog: true,
        errorMessage: error.message,
      })
    }
  }

  closePopup() {
    this.setState({
      showErrorDialog: false,
      errorMessage: null,
    })
  }

  render() {
    const { Form } = this.components
    const { CancelButton, SubmitButton } = this.buttons
    const activeIndex = this.state.activeIndex || 0
    const detailsTab = activeIndex === 0
    const mechanicsTab = activeIndex === 1
    const { method } = this.props
    const tabsList = [
      {
        text: 'Details',
      },
      {
        text: 'Mechanics',
      },
    ]
    const { loading, submitting } = this.state

    return (
      <div className="omnichallenge container">
        {this.state.showErrorDialog && (
          <Dialog
            show={this.state.showErrorDialog}
            information={this.state.errorMessage}
            close={this.closePopup}
            closeText={getMessage('rewardPage.dialogs.disable.okText')}
          />
        )}
        {method === 'edit' && (
          <a
            href={`/rewards/challenges/add?clone=${this.props.value.id}`}
            className="clone-action"
          >
            <button
              type="button"
              className="primary"
              data-testid="clone-button"
            >
              {getMessage('omnichallenge.createsimilar')}
            </button>
          </a>
        )}
        <div className="tab-list">
          <Tabs
            items={tabsList.map((tab) => `${tab.text}`)}
            default={0}
            onClick={this.changeTab}
            active={this.state.activeIndex}
          />
        </div>
        <Form className="omnichallenge-form">
          {detailsTab && (
            <Details _this={this} method={method} loading={loading} />
          )}
          {mechanicsTab && <Mechanics _this={this} method={method} />}
          <div className="form-actions">
            <CancelButton>{getMessage('omnichallenge.cancel')}</CancelButton>
            {!mechanicsTab && method !== 'edit' && (
              <button
                type="button"
                className="primary"
                data-testid="next-button"
                onClick={this.nextTab}
              >
                {getMessage('omnichallenge.next')}
              </button>
            )}
            {mechanicsTab && method !== 'edit' && (
              <SubmitButton testid="confirm-submit" disabled={submitting}>
                {getMessage('omnichallenge.submit')}
              </SubmitButton>
            )}
            {method === 'edit' && (
              <button
                type="button"
                className="primary"
                data-testid="save-button"
                onClick={this.handlePatch}
                disabled={this?.state?.ptrModification?.error}
              >
                {getMessage('omnichallenge.save')}
              </button>
            )}
          </div>
        </Form>
      </div>
    )
  }
}

export default withRouter(OmniChallengesForm)
