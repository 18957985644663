import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import Table, { Row, Cell } from '../../../components/Table'
import Header from '../../../components/Table/Header'
import { SingleDatePicker } from '../../../components/Form'
import Tabs from '../../../components/Tabs'
import { getMessage } from '../../../lib/translator'
import API from '../../../lib/api'
import { getDisplaySlot, sortSlots } from '../../../lib/commonlyused'
import { get } from '../../../lib/storage'
import Loader from '../../../components/Loader'
import { Dialog } from '../../../components/Popup'
import EditIcon from '../Orders/Details/edit-icon.svg'
import moment from 'moment'

import './style.css'

const chargeTypes = [
  {
    text: 'Surcharge',
    key: 'surcharge',
  },
  {
    text: 'Order Discount',
    key: 'orderDiscount',
  },
  {
    text: 'Discount',
    key: 'discount',
  },
]

const dateFormat = 'YYYY-MM-DD'

class SlotCharges extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deliverySlots: [],
      pickupSlots: [],
      slotCharge: [],
      allSortedSlots: [],
      slotChargeMapping: {},
      activeIndex: 0,
      selectedDate: moment().format(dateFormat),
      editingCell: {},
      errorMessage: null,
      showErrorDialog: false,
      loading: false,
    }
    this.getSlots = this.getSlots.bind(this)
    this.getSLotCharges = this.getSLotCharges.bind(this)
    this.getSlotChargesMapping = this.getSlotChargesMapping.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleCellEditClick = this.handleCellEditClick.bind(this)
    this.onStoreChange = this.onStoreChange.bind(this)
    this.fetchTableData = this.fetchTableData.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.showError = this.showError.bind(this)
  }
  componentDidMount() {
    this.fetchTableData()
  }
  fetchTableData() {
    this.setState({
      loading: true,
      editingCell: {},
    })
    this.getSlots()
    this.getSLotCharges()
  }
  onKeyPress(scope, type, dataArr, slotId, e) {
    const editAPI = new API({
      url: `/order-service/slot-charge/${
        dataArr && dataArr[0] && dataArr[0].id
      }`,
    })
    const addAPI = new API({ url: '/order-service/slot-charge/X' })
    if (e.key === 'Enter') {
      if (e.target.value === '') {
        this.setState({ editingCell: {} })
      } else if (dataArr.length) {
        const params = {}
        if (scope === 'date') {
          params.date = this.state.selectedDate || moment().format(dateFormat)
          if (params.date && !params.date.includes('T')) {
            params.date = `${params.date}T00:00:00+00:00`
          }
        }
        params.id = dataArr[0].id
        params[chargeTypes[this.state.activeIndex]['key']] = Number(
          e.target.value
        )
        params.storeId = Number(get('store'))
        params.slotId = slotId
        editAPI
          .put(params)
          .then(() => {
            this.fetchTableData()
          })
          .catch((error) => {
            if (error.code === 401 || error.code === 403) {
              throw error
            }
            this.showError(error)
          })
      } else {
        const params = {}
        if (scope === 'date') {
          params.date = this.state.selectedDate || moment().format(dateFormat)
          if (params.date && !params.date.includes('T')) {
            params.date = `${params.date}T00:00:00+00:00`
          }
        }
        params.orderType = type
        params.slotId = slotId
        params[chargeTypes[this.state.activeIndex]['key']] = Number(
          e.target.value
        )
        params.storeId = Number(get('store'))
        addAPI
          .put(params)
          .then(() => {
            this.fetchTableData()
          })
          .catch((error) => {
            if (error.code === 401 || error.code === 403) {
              throw error
            }
            this.showError(error)
          })
      }
    }
  }
  showError(error) {
    this.setState({
      errorMessage: error.message,
      showErrorDialog: true,
    })
  }
  handleDateChange(selectedDate) {
    this.setState({ selectedDate }, () => this.fetchTableData())
  }
  handleCellEditClick(index, scope, type) {
    this.setState({ editingCell: { index, scope, type } })
  }
  closePopup() {
    this.setState({
      showErrorDialog: false,
      errorMessage: null,
    })
  }
  changeTab(activeIndex) {
    this.setState({ activeIndex })
  }
  getSLotCharges() {
    const chargesApi = new API({ url: '/order-service/slot-charge' })
    const dateChargesApi = new API({ url: `/order-service/slot-charge` })
    const dateChargesParams = {
      date: this.state.selectedDate,
      storeId: get('store'),
      paginated: 'false',
    }
    Promise.all([
      chargesApi.get({ storeId: get('store'), paginated: 'false' }),
      dateChargesApi.get(dateChargesParams),
    ]).then(([chargesResponse, dateChargesResponse]) => {
      const charges = chargesResponse.data.slotCharge.filter((el) => !el.date)
      const dateCharges = dateChargesResponse.data.slotCharge.filter(
        (el) => el.date
      )
      const slotCharge = charges.concat(dateCharges)
      this.setState({ slotCharge }, () => this.getSlotChargesMapping())
    })
  }
  getSlotChargesMapping() {
    const currentState = JSON.parse(JSON.stringify(this.state))
    const { slotCharge, allSortedSlots } = currentState
    const slotChargeMapping = {}
    allSortedSlots.map((slot) => {
      if (slotChargeMapping[getDisplaySlot(slot)]) {
        slotChargeMapping[getDisplaySlot(slot)][slot.orderType] = {
          slotId: slot.id,
          data: slotCharge.filter((s) => s.slotId === slot.id),
        }
      } else {
        slotChargeMapping[getDisplaySlot(slot)] = {
          [slot.orderType]: {
            slotId: slot.id,
            data: slotCharge.filter((s) => s.slotId === slot.id),
          },
        }
      }
      return null
    })
    this.setState({ slotChargeMapping })
  }
  getSlots(_storeId = get('store')) {
    this.deliverySlots = new API({ url: '/order-service/slot' })
    const deliverySlotsParams = { orderType: 'DELIVERY' }
    // Uncomment storeId part when we need store specific slots
    // deliverySlotsParams.storeId = Number(storeId)
    this.pickupSlots = new API({ url: '/order-service/slot' })
    const pickupSlotsParams = { orderType: 'PICKUP' }
    // pickupSlotsParams.storeId = Number(storeId)
    Promise.all([
      this.deliverySlots.get(deliverySlotsParams),
      this.pickupSlots.get(pickupSlotsParams),
    ]).then(([deliverySlots, pickupSlots]) => {
      this.setState({
        deliverySlots: deliverySlots.data.slot,
        pickupSlots: pickupSlots.data.slot,
        allSortedSlots: sortSlots(
          deliverySlots.data.slot.concat(pickupSlots.data.slot)
        ),
        loading: false,
      })
    })
  }

  onStoreChange() {
    this.fetchTableData()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.allSortedSlots.length !== this.state.allSortedSlots.length) {
      this.getSlotChargesMapping()
    }
  }

  render() {
    const scopes = ['default', 'date']
    const types = ['DELIVERY', 'PICKUP']
    let content = null
    const dateSelect = (
      <SingleDatePicker
        allowAllDates
        displayFormat={dateFormat}
        value={this.state.selectedDate}
        onChange={this.handleDateChange}
      />
    )
    if (this.state.loading) {
      content = <Loader />
    } else {
      if (this.state.allSortedSlots && this.state.allSortedSlots.length > 0) {
        content = (
          <div>
            <Tabs
              items={chargeTypes.map((tab) => `${tab.text}`)}
              default={this.state.activeIndex}
              onClick={(index) => {
                this.changeTab(index)
              }}
            />

            <div className="table-wrapper">
              <div className="fixed-table">
                <Table>
                  <Header items={['Slot']} />
                  {Object.keys(this.state.slotChargeMapping).map(
                    (key, index) => {
                      return (
                        <Row key={`slotrow${index}`}>
                          <Cell key={`slotcell${index}`} className="slot-name">
                            <span className="slot-duration">{key}</span>
                          </Cell>
                        </Row>
                      )
                    }
                  )}
                </Table>
              </div>
              <div className="scrollable-table">
                <Table
                  tableRef={(el) => {
                    this.tableRef = el
                  }}
                >
                  <Header items={['Default', dateSelect]} />
                  {Object.entries(this.state.slotChargeMapping).map(
                    ([_slot, details], index) => {
                      return (
                        <Row key={index}>
                          {scopes.map((scope, i) => {
                            return (
                              <Cell key={`${index}${i + 1}`}>
                                <div className={`${scope}-data`}>
                                  {types.map((type, ti) => {
                                    const dataArr =
                                      scope === 'default'
                                        ? details[type] &&
                                          details[type]['data'] &&
                                          details[type]['data'].filter(
                                            (el) => !el.date
                                          )
                                        : details[type] &&
                                          details[type]['data'] &&
                                          details[type]['data'].filter(
                                            (el) =>
                                              el.date &&
                                              el.date.split('T')[0] ===
                                                this.state.selectedDate
                                          )
                                    const {
                                      index: savedIndex,
                                      scope: savedScope,
                                      type: savedType,
                                    } = this.state.editingCell
                                    const isCellSelected =
                                      savedIndex === index &&
                                      savedScope === scope &&
                                      savedType === type
                                    return (
                                      <div
                                        key={`${index}${i + 1}${ti}`}
                                        className={type.toLowerCase()}
                                      >
                                        {index === 0 && (
                                          <div className="subcolumn-header">
                                            {type}
                                          </div>
                                        )}
                                        <div
                                          className={`${type.toLowerCase()}-count charge-value`}
                                        >
                                          {!isCellSelected &&
                                            dataArr &&
                                            dataArr.length > 0 &&
                                            dataArr[0][
                                              chargeTypes[
                                                this.state.activeIndex
                                              ]['key']
                                            ]}
                                          {!isCellSelected && !dataArr && '-'}
                                          {isCellSelected && dataArr && (
                                            <input
                                              onBlur={() =>
                                                this.setState({
                                                  editingCell: {},
                                                })
                                              }
                                              type="number"
                                              defaultValue={
                                                (dataArr.length > 0 &&
                                                  dataArr[0][
                                                    chargeTypes[
                                                      this.state.activeIndex
                                                    ]['key']
                                                  ]) ||
                                                ''
                                              }
                                              onKeyPress={this.onKeyPress.bind(
                                                this,
                                                scope,
                                                type,
                                                dataArr,
                                                details[type]['slotId']
                                              )}
                                              autoFocus
                                            />
                                          )}
                                        </div>
                                        {dataArr ? (
                                          dataArr.length > 0 ? (
                                            <span
                                              className="cell-action"
                                              onClick={() =>
                                                this.handleCellEditClick(
                                                  index,
                                                  scope,
                                                  type
                                                )
                                              }
                                            >
                                              <img src={EditIcon} alt="edit" />
                                            </span>
                                          ) : (
                                            <span
                                              className="cell-action add"
                                              onClick={() =>
                                                this.handleCellEditClick(
                                                  index,
                                                  scope,
                                                  type
                                                )
                                              }
                                            >
                                              +
                                            </span>
                                          )
                                        ) : null}
                                      </div>
                                    )
                                  })}
                                </div>
                              </Cell>
                            )
                          })}
                        </Row>
                      )
                    }
                  )}
                </Table>
              </div>
            </div>
          </div>
        )
      } else {
        content = <p>No Data Available</p>
      }
    }
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="slot-charges"
        storeDependent
        onChange={this.onStoreChange}
      >
        <h1>Slot Charges</h1>
        {content}
        {
          <Dialog
            show={this.state.showErrorDialog}
            information={this.state.errorMessage}
            close={this.closePopup}
            closeText={getMessage('vehiclePlanner.error.dialog.okay')}
          />
        }
      </AuthenticatedPage>
    )
  }
}

export default SlotCharges
