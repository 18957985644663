import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../../../../components/Table'
import { Dialog, Popup } from '../../../../../components/Popup'
import {
  DropDown,
  DropDownItem,
  ICONS,
} from '../../../../../components/DropDown'
import {
  Email as CustomerEmailWidget,
  Call as CustomerCallWidget,
  Address as CustomerMapWidget,
} from '../../../../../containers/CustomerWidgets'
import { getMessage } from '../../../../../lib/translator'
import {
  getSession,
  isExtensionEnabled,
  hasPermissions,
} from '../../../../../lib/auth'
import { getPrintableTime, formatTime } from '../../../../../lib/datetime'
import { getAsapDuration, getEndpointToPrintInvoice } from '../../../../../lib/commonlyused'
import { TABLE_ACTIONS } from '../../../../../containers/ListingPage'
import FileForReturn from '../../../Returns/Form/FileForReturn'
import CompletePickupPendingOrder from '../../../Returns/Form/CompletePickupPending'
import { Consumer } from '../index'
import pickupImage from './pickup.svg'
import deliveyImage from './delivery.svg'
import offlineImage from './offline.svg'
import B2BImage from './b2b.svg'
import API from '../../../../../lib/api'

import Image from '../../../../../components/Image'
import { getStoreName } from '../../../../../containers/StoreSelector'
import Loader from '../../../../../components/Loader'

// Mapping order status with the relevant message key
const orderStatus = {
  COMPLETED: 'order.status.completed',
  CANCELLED: 'order.status.cancelled',
  PENDING: 'order.status.pending',
  PICKED: 'order.status.picked',
  CHECKED: 'order.status.checked',
  PACKED: 'order.status.packed',
  PICKING: 'order.status.picking',
  'PARTIALLY-PICKED': 'order.status.partiallypicked',
  CHECKING: 'order.status.checking',
  PACKING: 'order.status.packing',
  DISPATCHED: 'order.status.dispatched',
  RETURNED: 'order.status.returned',
}

const orderTypeImages = {
  PICKUP: pickupImage,
  DELIVERY: deliveyImage,
  B2B: B2BImage,
  OFFLINE: offlineImage,
}

// Logic to decide what payment status should be shown for the order
const PaymentStatusText = ({ status, pendingAmount }) => {
  let text = ''
  switch (status) {
    case 'PAID':
      text = (
        <small className="text-muted">{getMessage('order.payment.paid')}</small>
      )
      break
    case 'PENDING':
      text = (
        <small className="pending-amount">
          {getMessage('order.table.pendingAmount', {
            currency: getSession().organization.currency.symbol,
            amount: Number(pendingAmount).toFixed(2),
          })}
        </small>
      )
      break
    case 'REFUND':
      text = (
        <small className="refund-amount">
          {getMessage('order.table.refundAmount', {
            currency: getSession().organization.currency.symbol,
            amount: Number(pendingAmount).toFixed(2),
          })}
        </small>
      )
      break
    default:
      break
  }
  return text
}
const FillRateText = ({ fillRate = 0 }) => {
  return (
    <small className={`${fillRate < 80 ? 'fill-rate' : 'text-muted'}`}>
      {(fillRate === 0 || fillRate) &&
        getMessage('order.table.text.fillrate') +
          Math.round(fillRate * 100) / 100 +
          '%'}
    </small>
  )
}

const tripStatus = ['DISPATCHED', 'COMPLETED', 'RETURNED']

export class TableActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCompleteDialog: false,
      showLog: false,
      showPickupPending: false,
      showMovePendingToPacked: false,
    }
    this.returnableStatuses = ['COMPLETED']
    this.isInStoreEnabled = isExtensionEnabled('InStoreProcessing')
    this.isLogisticsEnabled = isExtensionEnabled('LogisticsSupport')
    this.returnableStatuses.push('PACKED')
    this.returnableStatuses.push('DISPATCHED')

    this.printInvoice = this.printInvoice.bind(this)
  }

  async printInvoice() {
    const { type, referenceNumber, store, customer, status } = this.props;

    if (status === 'COMPLETED') {
      this.setState({ loading: true })
      const apiUrl = getEndpointToPrintInvoice({
        storeId: store.id,
        customerId: customer.id,
        type: type.name,
        referenceNumber
      })

      const invoiceAPI = new API({ url: apiUrl })
      try {
        const byteArray = await invoiceAPI.fileFetch()
        if (byteArray) {
          const blob = new Blob([byteArray], { type: 'application/pdf' })
          const blobURL = URL.createObjectURL(blob)
          window.open(blobURL)
        }
      } catch (err) {
        this.setState({
          error: {
            status: true,
            message: 'Invoice not ready',
            details: 'Service is unable to generate invoice, check whether SAP invoice number is generated',
          }
        })
      }
      this.setState({ loading: false })
    } else {
      this.setState({
        error: {
          status: true,
          message: 'Order not completed',
          details: 'Wait for order to complete, before generating invoice',
        }
      })
    }
  }

  render() {
    const {
      referenceNumber,
      status,
      onAction,
      type,
      paymentStatus,
      pendingAmount,
      clientId,
      url,
    } = this.props
    const hasOrderEditPermissions = hasPermissions('order', 'order', 'PUT')
    const hasOrderPostPermissions = hasPermissions('order', 'order', 'post')
    let canBeCompleted =
      hasOrderEditPermissions && isExtensionEnabled('InStoreProcessing')
        ? isExtensionEnabled('LogisticsSupport')
          ? status === 'DISPATCHED'
          : status === 'PACKED'
        : isExtensionEnabled('LogisticsSupport')
        ? status === 'DISPATCHED'
        : status === 'PENDING'
    const canBePendingToPacked =
      hasOrderEditPermissions &&
      isExtensionEnabled('LogisticsSupport') &&
      !isExtensionEnabled('InStoreProcessing') &&
      status === 'PENDING'
    const canBeReturned =
      hasOrderPostPermissions &&
      isExtensionEnabled('OrderReturns') &&
      this.returnableStatuses.indexOf(status) > -1
    const canBeDisputed =
      isExtensionEnabled('OrderDisputes') && status === 'COMPLETED'
    const canShowTrip =
      isExtensionEnabled('LogisticsSupport') &&
      tripStatus.indexOf(status) > -1 &&
      hasPermissions('logistics', 'trip', 'get') &&
      type.name === 'DELIVERY'
    if (type && type.name === 'PICKUP' && status === 'PACKED') {
      canBeCompleted = true
    }
    const registerPayments =
      paymentStatus === 'PENDING' &&
      (!isExtensionEnabled('LogisticsSupport') ||
        (type && type.name === 'PICKUP'))
    const isStatusPacked = status === 'PACKED' || false
    const packedOrderEdit = this.props.packedOrderEdit || false
    const fromOperations = this.props.url === '/operations'
    return (
      <div>
        <Dialog
          show={this.state.showMovePendingToPacked}
          title={getMessage('order.details.packed.title')}
          information={getMessage('order.details.packed.message')}
          onOk={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { referenceNumber },
              { status: 'PACKED' }
            )
            this.setState({ showMovePendingToPacked: false })
          }}
          close={() => this.setState({ showMovePendingToPacked: false })}
          okText={getMessage('order.details.packed.confirmText')}
          closeText={getMessage('order.details.packed.cancelText')}
        />
        <Dialog
          show={this.state.showCompleteDialog}
          title={getMessage('order.details.completion.title')}
          information={getMessage('order.details.completion.message')}
          onOk={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { referenceNumber },
              { status: 'COMPLETED' }
            )
            this.setState({ showCompleteDialog: false })
          }}
          close={() => this.setState({ showCompleteDialog: false })}
          okText={getMessage('order.details.completion.confirmText')}
          closeText={getMessage('order.details.completion.cancelText')}
        />
        {this.state.showMoveToPendingDialog && (
          <Dialog
            show={this.state.showMoveToPendingDialog}
            title={getMessage('order.details.pending.title')}
            information={getMessage('order.details.pending.message')}
            onOk={() => {
              onAction(
                TABLE_ACTIONS.UPDATE,
                { referenceNumber },
                { status: 'PENDING' }
              )
            }}
            close={() => this.setState({ moveCancelledToPending: false })}
            okText={getMessage('order.details.cancellation.confirmText')}
            closeText={getMessage('order.details.cancellation.cancelText')}
          />
        )}
        {this.state.loading && <Loader size="sm" />}
        {this.state.error && this.state.error.status && (
          <Popup
            show={this.state.error.status}
            referenceNumber={referenceNumber}
            close={() => this.setState({ error: null })}
            heading={`#${referenceNumber} ${this.state.error.message}`}
          >
            {this.state.error.details}
          </Popup>
        )}
        {this.state.showReturn && (
          <Popup
            show={this.state.showReturn}
            referenceNumber={referenceNumber}
            close={() => this.setState({ showReturn: false })}
            className="file-for-return"
            heading={`${getMessage('return.file.heading')} #${referenceNumber}`}
          >
            <FileForReturn
              referenceNumber={referenceNumber}
              closeReturnFilePopup={() => this.setState({ showReturn: false })}
              onAction={onAction}
              status={status}
            />
          </Popup>
        )}
        {this.state.showPickupPending && (
          <Popup
            show={this.state.showPickupPending}
            close={() => this.setState({ showPickupPending: false })}
            className="pickup-pending-form"
          >
            <CompletePickupPendingOrder
              referenceNumber={this.props.referenceNumber}
              close={() => this.setState({ showPickupPending: false })}
              onComplete={() => {
                onAction(
                  TABLE_ACTIONS.UPDATE,
                  { referenceNumber },
                  { status: 'COMPLETED' }
                )
                this.setState({
                  showPickupPending: false,
                })
              }}
              onPickupPendingComplete={params => {
                onAction(TABLE_ACTIONS.UPDATE, { referenceNumber }, params)
              }}
            />
          </Popup>
        )}
        {!this.state.loading && (
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem>
              <Link to={`${url}/orders/${referenceNumber}`}>
                {getMessage('order.action.viewDetails')}
              </Link>
            </DropDownItem>
            {canBeDisputed && (
              <DropDownItem>
                <Link to={`${url}/orders/dispute/${referenceNumber}`}>
                  {getMessage('order.details.dispute.optionText')}
                </Link>
              </DropDownItem>
            )}
            {canShowTrip && (
              <DropDownItem>
                <Link to={`/logistics/trips?orderNumber=${referenceNumber}`}>
                  {getMessage('order.actions.trips')}
                </Link>
              </DropDownItem>
            )}
            <DropDownItem onClick={this.printInvoice}>
              {getMessage('order.action.printInvoice')}
            </DropDownItem>
            {canBePendingToPacked && (
              <DropDownItem
                onClick={() => {
                  this.setState({
                    showMovePendingToPacked: true,
                  })
                }}
              >
                {getMessage('order.action.packed')}
              </DropDownItem>
            )}
            {canBeCompleted &&
              (!registerPayments ? (
                <DropDownItem
                  onClick={() => {
                    if (!isExtensionEnabled('OrderReturns')) {
                      this.setState({ showCompleteDialog: true })
                    } else {
                      this.setState({ showPickupPending: true })
                    }
                  }}
                >
                  {getMessage('order.action.complete')}
                </DropDownItem>
              ) : (
                <DropDownItem>
                  <Link
                    to={`${url}/orders/register-payment/${referenceNumber}?pendingAmount=${pendingAmount}&clientId=${clientId}`}
                  >
                    {getMessage('order.action.complete')}
                  </Link>
                </DropDownItem>
              ))}
            {canBeReturned && (
              <DropDownItem>
                <Link to={`${url}/orders/file-for-return/${referenceNumber}`}>
                  {getMessage('order.action.return')}
                </Link>
              </DropDownItem>
            )}
            <DropDownItem>
              <Link to={`${url}/orders/order-log/${referenceNumber}`}>
                {getMessage('order.actions.logs')}
              </Link>
            </DropDownItem>
            {isStatusPacked && packedOrderEdit && fromOperations && (
              <DropDownItem>
                <Link to={`orders/edit-packed/${referenceNumber}`}>
                  {getMessage('order.action.editPackedOrder')}
                </Link>
              </DropDownItem>
            )}
          </DropDown>
        )}
      </div>
    )
  }
}

const tableHeader = () => {
  const headers = [
    getMessage('order.table.header.referenceNumber'),
    getMessage('order.table.header.customer'),
    getMessage('order.table.header.placedTime'),
    getMessage('order.table.header.amount'),
    getMessage('order.table.header.status'),
    getMessage('order.table.header.completionTime'),
    getMessage('order.table.header.actions'),
  ]
  if (!isExtensionEnabled('DeliverySlots')) {
    return headers
  } else {
    headers.splice(3, 0, getMessage('order.table.header.expectedTime'))
    return headers
  }
}
const tableProperties = (url, packedOrderEdit) => {
  return {
    headers: tableHeader(),
    row: ({
      items,
      slotType,
      clientId,
      referenceNumber,
      customer,
      address,
      completedAt,
      createdAt,
      invoiceAmount,
      itemCount,
      status,
      store,
      pickupLocation,
      pendingAmount,
      refundAmount,
      type,
      onAction,
      preferredDate,
      slotStartTime,
      slotEndTime,
      paymentStatus,
      fillRate,
    }) => (
      <Consumer>
        {value => {
          const allowCall =
            value &&
            value.communication &&
            value.communication.allowCall !== false &&
            hasPermissions('communication', 'call', 'post')
          const allowEmail =
            value &&
            value.communication &&
            value.communication.allowEmail !== false &&
            hasPermissions('communication', 'email', 'post')
          return (
            <Row>
              <Cell className="column-order-number">
                <div className="container-order-number">
                  <div>
                    {type.name && (
                      <div className="order-type-icons">
                        <Image src={orderTypeImages[type.name]} />
                      </div>
                    )}
                  </div>
                  <div>
                    <div>
                      <Link
                        className="order-number"
                        to={
                          url
                            ? `${url}/orders/${referenceNumber}`
                            : `/operations/orders/${referenceNumber}`
                        }
                      >
                        {clientId ? (
                          <span>
                            <span className="text-muted prefix">
                              {' '}
                              {getMessage('order.table.clientId.prefix')}{' '}
                            </span>{' '}
                            {clientId}
                          </span>
                        ) : (
                          <span>
                            <span className="text-muted prefix">
                              {getMessage(
                                'order.table.referenceNumber.prefix'
                              )}
                            </span>&nbsp;
                            {referenceNumber}
                          </span>
                        )}
                      </Link>
                    </div>
                    {type.name === 'PICKUP' ? (
                      <small className="text-muted store-name">
                        {pickupLocation && pickupLocation.name}
                      </small>
                    ) : (
                      store && (
                        <small className="text-muted store-name">
                          {getStoreName(store)}
                        </small>
                      )
                    )}
                  </div>
                </div>
              </Cell>
              <Cell className="column-customer-name">
                <div className="cx-name-text">
                  {customer ? (
                    <Link
                      className="customer-name"
                      to={
                        url
                          ? `${url}/customers/view/${customer.id}`
                          : `/operations/customers/view/${customer.id}`
                      }
                    >
                      {customer.name}
                    </Link>
                  ) : null}
                </div>
                <div className="customer-actions">
                  {address && (
                    <CustomerMapWidget
                      address={
                        type.name === 'DELIVERY'
                          ? address.address +
                            ', ' +
                            address.city +
                            ', ' +
                            address.pincode
                          : pickupLocation
                          ? pickupLocation.address
                          : ''
                      }
                    />
                  )}
                  {allowEmail &&
                    customer &&
                    customer.emails &&
                    !!customer.emails.length && (
                      <CustomerEmailWidget
                        emails={customer.emails.slice(0, 1)}
                        showOptions={false}
                      />
                    )}
                  {allowCall &&
                    customer &&
                    customer.phones &&
                    !!customer.phones.length && (
                      <CustomerCallWidget
                        phones={customer.phones.slice(0, 1)}
                        showOptions={false}
                      />
                    )}
                </div>
              </Cell>
              <Cell className="column-creation-time">
                <div>
                  {createdAt
                    ? getPrintableTime(createdAt).split(', ')[0]
                    : null}
                </div>
                <small className="text-muted">
                  {createdAt
                    ? getPrintableTime(createdAt).split(', ')[1]
                    : null}
                </small>
              </Cell>
              {isExtensionEnabled('DeliverySlots') ? (
                <Cell className="column-creation-time">
                  <div>
                    {preferredDate
                      ? getPrintableTime(preferredDate).split(', ')[0]
                      : null}
                  </div>
                  <small
                    className={`text-muted ${
                      !slotEndTime ? 'zero-font' : ''
                    }`.trim()}
                  >
                    {slotStartTime && slotEndTime && slotType !== 'ASAP'
                      ? formatTime(slotStartTime) +
                        ' - ' +
                        formatTime(slotEndTime)
                      : getAsapDuration(slotStartTime, slotEndTime)}
                  </small>
                </Cell>
              ) : null}
              <Cell className="column-order-amount">
                <div>
                  {getSession().organization.currency &&
                    getSession().organization.currency.symbol + invoiceAmount}
                </div>
                <small className="text-muted">
                  {getMessage('order.table.itemCount', {
                    count: itemCount || (items && items.length) || 0,
                  })}
                </small>
              </Cell>
              <Cell className="column-order-status">
                <div>
                  {orderStatus[status] ? getMessage(orderStatus[status]) : null}
                </div>
                <PaymentStatusText
                  {...{ status, pendingAmount, refundAmount }}
                />
                {(type.name === 'PICKUP' || type.name === 'DELIVERY') &&
                  (status === 'PACKED' ||
                    status === 'COMPLETED' ||
                    status === 'DISPATCHED') && (
                    <FillRateText {...{ fillRate }} />
                  )}
              </Cell>
              <Cell className="column-completion-time">
                <div>
                  {completedAt
                    ? getPrintableTime(completedAt).split(', ')[0]
                    : null}
                </div>
                <small className="text-muted">
                  {completedAt
                    ? getPrintableTime(completedAt).split(', ')[1]
                    : null}
                </small>
              </Cell>
              <Cell className="column-order-actions">
                <TableActions
                  status={status}
                  clientId={clientId}
                  referenceNumber={referenceNumber}
                  onAction={onAction}
                  type={type}
                  paymentStatus={paymentStatus}
                  pendingAmount={pendingAmount}
                  url={url || '/operations'}
                  packedOrderEdit={packedOrderEdit}
                  store={store}
                  customer={customer}
                />
              </Cell>
            </Row>
          )
        }}
      </Consumer>
    ),
  }
}

export default tableProperties

export { tripStatus }
