import React from 'react'
import { Popup } from '../../../../components/Popup'
import { getMessage } from '../../../../lib/translator'
import Upload from '../../../../components/FileUpload'
import uploadIcon from '../images/upload-products.svg'
import csvIcon from '../images/csv-icon.svg'
import './CampaignUploadPopup.css'
import { downloadTemplateFromApi } from '../../DownloadUtil'

const UploadFileCsvCommon = (props) => {

  const getSampleCsv = (e) => {
    e.preventDefault()
    e.stopPropagation()
    downloadTemplateFromApi('/martech-ad-service/campaigns/download_template_file' ,'campaign-bulk-operation-template.csv')
  }

  return (
    <div className='Media-CSV-upload'>
      <div className='bordered-box'>
        <div className='text-center'>
          <img src={uploadIcon} width='140' alt='' />
        </div>
        <Upload
          name='MediaSKUUpload'
          accept='.csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel'
          placeholder={getMessage('media.adset.upload.fileinput')}
          uploadButtonText='Upload'
          cancelText='Cancel'
          uploadUrl={'/martech-ad-service/campaigns/upload_data'}
          uploadKey='file'
          validationStrings={{
            invalidFileType: getMessage('input.invalidFileType'),
          }}
          strings={{
            defaultMessage: getMessage('fileUpload.importCsv.heading'),
            progressMessage: getMessage('fileUpload.importedCsv.heading'),
            completionMessage: getMessage('fileUpload.uploadedCsv.success'),
          }}
          icon={csvIcon}
          successCallback={props.refreshCampaignDetail}
        />
      </div>
      <div className='text-center download-sample-text'>
        <a className='download-link' onClick={(event)=> getSampleCsv(event)}>
          {getMessage('productUpload.csvDownloadText')}
        </a>
      </div>
    </div>
  )
}

function CampaingUploadPopup(props) {
  return (
    <Popup
      show={props.isOpenUploadBox}
      className='fileUpload-box'
      heading={getMessage('media.allcampaigns.upload.title')}
      close={() => {
        props.toggleUploadBox()
      }}
    >
      <div className='fileUpload-wrapper'>

        <div className='tip'>* {getMessage('media.adset.upload.tip')}</div>
        <UploadFileCsvCommon
          supplierReservationId={props.supplierReservationId}
          refreshCampaignDetail={props.refreshCampaignDetail}
        />
      </div>
    </Popup>
  )
}

export default CampaingUploadPopup
