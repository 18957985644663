import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Table, { Header, Cell } from '../../../../../components/Table'
import PacklistItemsView from './PacklistItemsView'
import {
  getNestedState,
  updateStateRecursively,
} from '../../../../../lib/stateManagement'
import { getMessage } from '../../../../../lib/translator'
import './style.scss'

const orderPacklistStatus = {
  PACKLIST_STATUS_UNSPECIFIED: "Unspecified",
  PACKLIST_STATUS_PENDING: "Pending",
  PACKLIST_STATUS_RECEIVED: "Received",
  PACKLIST_STATUS_NOT_RECEIVED: "Not Received",
  PACKLIST_STATUS_PICKING: "Picking",
  PACKLIST_STATUS_PACKING: "Packing",
  PACKLIST_STATUS_PACKED: "Packed",
  PACKLIST_STATUS_CANCELLED: "Cancelled"
}

export const tags = {
  all: "Single Order",
  bulk: "Bulky Items",
  general: "Normal Items",
  freshfrozen: "FreshFrozen Items",
  others: "Others",
  z2all: "Single Order"
}

const PacklistRow = ({
  status,
  tag,
  picklistId,
  ownerName,
  ownerType,
  isDfOrder,
  isOperationsPage,
  packlistId
}) => {
  const getPacklistTitle = () => {
    if (tag==="all" && ownerType && ownerName){
      return ownerType==="PACKLIST_OWNER_TYPE_VENDOR" ? ownerName : tags[tag];
    }
    return tags[tag];
  }
 return (
     <div className="sub-heading">
       <div>
         <strong> {getPacklistTitle()}</strong>
         {isDfOrder && <span style={{ color: '#80959d' }}>&nbsp;(Direct Fulfilment)</span>}
         {(isOperationsPage && picklistId) && (
         <Link to={`/operations/delivery-orders?picklistId=${picklistId}&offset=0&page=1`}><u>Batch #{picklistId}</u></Link>
         )}
         {
          packlistId && <span className='packlist-id'>&nbsp; (Packlist id: {packlistId})</span>
         }
         <span style={{ float: "right", color: "#80959d" }}> {getMessage('order.log.status')} :  <span style={{ color: "#3FC078" }} >{orderPacklistStatus[status]}</span></span>
       </div>
     </div>
   )
}

const validatePacklist = (packlist) => {
  return packlist.items && packlist.items.length > 0
}

class PacklistView extends Component {
  constructor(props) {
    super(props)
    this.state = this.generateStateFromProps(props, {
      packlistData: [],
    })
    this.getNestedState = getNestedState.bind(this)
    this.updateStateRecursively = updateStateRecursively.bind(this)
    this.onEditItemQuantity = this.onEditItemQuantity.bind(this)
    this.isOrderPendingOrCancelled = this.isOrderPendingOrCancelled.bind(this)
    this.isPacklistPendingOrCancelled = this.isPacklistPendingOrCancelled.bind(this)
    this.displayInvoiceSummary = this.displayInvoiceSummary.bind(this)
  }

  generateStateFromProps(props, state) {
    const newState = Object.assign({}, state, {
      packlistData: props.data.packlistData ? [...props.data.packlistData] : [],
    })
    return JSON.parse(JSON.stringify(newState))
  }

  isOrderPendingOrCancelled(status) {
    return status === 'DELIVERY_ORDER_STATUS_PENDING' || status === 'DELIVERY_ORDER_STATUS_CANCELLED'
  }

  isPacklistPendingOrCancelled(packlistStatus) {
    return packlistStatus === 'PACKLIST_STATUS_PENDING' || packlistStatus === 'PACKLIST_STATUS_CANCELLED'
  }

  displayInvoiceSummary(itemEditMode) {
    return this.state.packlistData.length > 0 && !itemEditMode
  }

  itemOverPicked(item, editQuantity, isSoldByWeight) {
    // if it is weighted item, check with overpickingThreshold
    if(isSoldByWeight) {
      if(item.overPickingThreshold) {
        const overPickingThreshold = (item.orderedQuantity * item.overPickingThreshold).toFixed(2)
        return editQuantity > overPickingThreshold;
      }
    }
    return editQuantity > item.orderedQuantity;
  }

  onEditItemQuantity(packlistIndex, itemIndex, editQuantity, isSoldByWeight) {
    let updatedQuantity = isSoldByWeight ? parseFloat(editQuantity  || 0) : parseInt(editQuantity || 0, 10)
    const parts = updatedQuantity.toString().split('.');
    if (parts[1] && parts[1].length > 3) {
      parts[1] = parts[1].slice(0, 3);
      updatedQuantity = parts.join('.');
    }

    if(updatedQuantity >= 0) {
      const item = this.state.packlistData[packlistIndex].items[itemIndex]
      const overPicked = this.itemOverPicked(item, updatedQuantity, isSoldByWeight)

      this.setState(prevState => {
        const packlistData = [...prevState.packlistData];
        if (!overPicked) {
          packlistData[packlistIndex].items[itemIndex].packedQuantity = updatedQuantity;
        }
        return { packlistData }
      })
      this.props.updateItemQuantity(this.state.packlistData[packlistIndex].id, item, updatedQuantity, overPicked)
    }
  }

  renderPacklists(isPendingOrCancelled, itemEditMode, status) {
    return this.state.packlistData.map((packlistObj, index) => {
      const isPacklistPacked = packlistObj.status === 'PACKLIST_STATUS_PACKED' || packlistObj.status === 'PACKLIST_STATUS_RECEIVED'
      return (
        <React.Fragment key={index}>
        {validatePacklist(packlistObj) && (
          <PacklistRow
            className="packlist-row"
            key={packlistObj.id}
            packlistId={packlistObj.id}
            status={packlistObj.status}
            tag={packlistObj.tag}
            picklistId={packlistObj.picklistId}
            ownerName={packlistObj.ownerName}
            ownerType={packlistObj.ownerType}
            isDfOrder={this.props.isDfOrder}
            isOperationsPage={this.props.isOperationsPage}
          />
          )}
          {packlistObj.items.map((itemObj, itemIndex) =>
            (
              <React.Fragment key={`fragment-${itemObj.id}`}>
                <PacklistItemsView
                  key={itemObj.itemId}
                  item={itemObj}
                  index={itemIndex}
                  orderStatus={status}
                  currency={this.props.currency}
                  packlistStatus={packlistObj.status}
                  isSubstitutedItem={!!itemObj.originalItem}
                  isPendingOrCancelled={isPendingOrCancelled}
                  canEditPacklistItems={itemEditMode && isPacklistPacked}
                  packlistIndex={index}
                  onEditItemQuantity={this.onEditItemQuantity}
                  />
                {
                  (itemObj && itemObj.originalItem) &&
                  <PacklistItemsView
                    key={itemObj.originalItem.itemId}
                    item={itemObj.originalItem}
                    index={itemIndex}
                    orderStatus={status}
                    currency={this.props.currency}
                    itemHasSubstitution={true}
                    isPendingOrCancelled={isPendingOrCancelled}
                    />
                }
              </React.Fragment>
              )
            )}
        </React.Fragment>
      )
    })
  }

  render() {
    const productsInOrder = this.state.packlistData.flatMap(x => x.items).length;
    const { status, itemEditMode } = this.props

    const isPendingOrCancelled = this.isOrderPendingOrCancelled(status)

    return (
      <div className="packlist-items-view">
        <div className="flex-around section-title">
          <h3>
            {getMessage('order.details.itemsTable.title')}
            <span className="text-muted"> ({productsInOrder})</span>
          </h3>
        </div>
        <Table>
          <Header>
            <Cell className="item-image">
              {getMessage('order.details.itemsTable.header.image')}
            </Cell>
            <Cell className="item-name">
              {getMessage('order.details.itemsTable.header.name')}
            </Cell>
            <Cell>
              {getMessage('order.details.itemsTable.header.clientItemId')}
            </Cell>
            <Cell
              className="item-original text-right"
              abbreviation={getMessage(
                'order.details.itemsTable.header.quantity.abbreviated'
              )}
            >
              {getMessage('order.details.itemsTable.header.originalQuantity')}
            </Cell>
            <Cell className="item-final text-right">
              {' '}
              {getMessage('order.details.itemsTable.header.finalQuantity')}
            </Cell>
          </Header>
          {this.renderPacklists(isPendingOrCancelled, itemEditMode, status)}
        </Table>
        {productsInOrder <= 0 &&
          (
            <div className="empty-table-message">
              {getMessage('order.details.itemsTable.emptyMessage')}
            </div>
          )}
      </div>
    )
  }
}

PacklistView.defaultProps = {
  data: {
    items: [],
  },
  includeInvoice: true,
}

export default PacklistView
