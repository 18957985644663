import React from 'react';
import './style.scss';

function calcPercent(total, curr) {
  if (total === 0) {
    return 0
  }
  return (curr / total) * 100
}

function ProgressBar({ total, curr }) {
  const width = calcPercent(total, curr)
  return (
    <div className="zone-progress-bar">
      <div className="progress-line">
        <div
          className="completed-progress-line"
          data-testid='progress-bar-line'
          style={{ width: `${width}%` }}
        />
      </div>
    </div>
  )
}

export default ProgressBar;
