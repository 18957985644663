import React from 'react'
import { Input, VALIDATION_TYPES } from 'components/Form'
import { getMessage } from 'lib/translator'

const Permalink = ({ _this }) => {
  const values = Object.assign({}, _this.state.values)

  const slugifyName = e => {
    e && e.preventDefault()
    const slugify = require('slugify')
    values.slug = slugify(values.name, { lower: true })
    _this.setState({
      values,
    })
  }

  return (
    <div className="col-6 flex">
      <Input
        label={getMessage('rewards.slug')}
        placeholder={getMessage('rewards.slug')}
        className="flex-grow"
        type="text"
        {..._this.generateStateMappers({
          stateKeys: ['slug'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
        required
      />
      <button
        className="button mt-05 slugify-button"
        data-testid="slugify-button"
        onClick={e => slugifyName(e)}
        disabled={!values.name}
      >
        {getMessage('rewards.generateSlug')}
      </button>
    </div>
  )
}

export default Permalink
