import React from 'react'
import { BaseForm, Toggle } from '../../../components/Form'
import { getMessage } from '../../../lib/translator'
import API from '../../../lib/api'
import Loader from '../../../components/Loader'
import { DropDown, DropDownItem } from '../../../components/DropDown'
import Send from '../../../components/Send'
import Popup from '../../../components/Popup/Popup'
import { withRouter } from 'react-router-dom'
import AddEmailForm from './Email'
import AddPhoneForm from './Phone'
import { parse, format, isValidNumber } from 'libphonenumber-js'
import { Dialog } from '../../../components/Popup'

import SmsIcon from './sms.svg'
import MailIcon from './mail.svg'
import AddIcon from './plus.svg'

const MSG_NOTIFICATION_MIDTEXT = getMessage('notifications.midtext')
const MSG_DELETE_PHONE = getMessage('notification.phone.delete.title')

class NotificationsForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      showLoader: true,
      formError: '',
      popupFormError: '',
      showOrderEmailPopup: false,
      showOrderPhonePopup: false,
      showCustomerEmailPopup: false,
      showCustomerPhonePopup: false,
      showOrderDeleteEmailDialog: false,
      showOrderDeletePhoneDialog: false,
      showCustomerDeleteEmailDialog: false,
      showCustomerDeletePhoneDialog: false,
      showSuccessDialog: false,
      values: {
        orderNotificationEmails: [],
        orderNotificationPhones: [],
        customerNotificationEmails: [],
        customerNotificationPhones: [],
      },
    }
    this.handleOrderEmailDelete = this.handleOrderEmailDelete.bind(this)
    this.handleOrderPhoneDelete = this.handleOrderPhoneDelete.bind(this)

    this.handleOrderEmailAdd = this.handleOrderEmailAdd.bind(this)
    this.handleOrderPhoneAdd = this.handleOrderPhoneAdd.bind(this)
    this.handleCustomerEmailAdd = this.handleCustomerEmailAdd.bind(this)
    this.handleCustomerPhoneAdd = this.handleCustomerPhoneAdd.bind(this)
    this.handleCustomerEmailDelete = this.handleCustomerEmailDelete.bind(this)
    this.handleCustomerPhoneDelete = this.handleCustomerPhoneDelete.bind(this)

    this.showOrderEmailPopup = this.showOrderEmailPopup.bind(this)
    this.showOrderPhonePopup = this.showOrderPhonePopup.bind(this)
    this.closeOrderEmailPopup = this.closeOrderEmailPopup.bind(this)
    this.closeOrderPhonePopup = this.closeOrderPhonePopup.bind(this)
    this.showCustomerEmailPopup = this.showCustomerEmailPopup.bind(this)
    this.closeCustomerEmailPopup = this.closeCustomerEmailPopup.bind(this)
    this.showCustomerPhonePopup = this.showCustomerPhonePopup.bind(this)
    this.closeCustomerPhonePopup = this.closeCustomerPhonePopup.bind(this)
    this.closeSuccess = this.closeSuccess.bind(this)

    this.handleClose = this.handleClose.bind(this)
    this.hideEmailDeleteConfirmation =
      this.hideEmailDeleteConfirmation.bind(this)
    this.hidePhoneDeleteConfirmation =
      this.hidePhoneDeleteConfirmation.bind(this)
    this.hideCustomerDeleteEmailDialog =
      this.hideCustomerDeleteEmailDialog.bind(this)
    this.hideCustomerDeletePhoneDialog =
      this.hideCustomerDeletePhoneDialog.bind(this)
    this.showOrderDeleteEmailDialog = this.showOrderDeleteEmailDialog.bind(this)
    this.showOrderDeletePhoneDialog = this.showOrderDeletePhoneDialog.bind(this)
    this.showCustomerDeleteEmailDialog =
      this.showCustomerDeleteEmailDialog.bind(this)
    this.showCustomerDeletePhoneDialog =
      this.showCustomerDeletePhoneDialog.bind(this)
    this.getData = this.getData.bind(this)
  }

  componentDidMount() {
    this.getData()
    this.api = new API({ url: '/account-service/country' })
    this.api.get().then(
      (response) => {
        this.countryIsoCode = response.data.country.isoCode
      },
      (error) => {
        if (error.message === 'cancelled') {
          return
        }
        this.countryIsoCode = 'IN'
        this.setState({
          countryError: error,
        })
      }
    )
  }

  getData() {
    this.setState({
      showLoader: true,
    })
    this.configApi = new API({ url: '/account-service/config/notification' })
    this.configApi.get().then(
      (response) => {
        const notification = response.data.config.notification
        const values = {
          orderNotificationEmails: notification.orderPlaceEmailRecipients || [],
          orderNotificationPhones: notification.orderPlaceSmsRecipients || [],
          customerNotificationPhones:
            notification.customerSignUpSmsRecipients || [],
          customerNotificationEmails:
            notification.customerSignUpEmailRecipients || [],
          notifyOnCustomerSignUp: notification.notifyOnCustomerSignUp || false,
          notifyOnCustomerWalletRecharge:
            notification.notifyOnCustomerWalletRecharge || false,
          notifyOnCustomerAddNewAddress:
            notification.notifyOnCustomerAddNewAddress || false,
          notifyOnOrderCancel: notification.notifyOnOrderCancel || false,
          notifyOnOrderPlace: notification.notifyOnOrderPlace || false,
          notifyOnOrderRefund: notification.notifyOnOrderRefund || false,
        }
        this.setState({ values, showLoader: false })
      },
      (error) => {
        if (error.message === 'cancelled') {
          return
        }
        this.setState({ formError: error.message, showLoader: false })
      }
    )
  }

  handleClose() {
    this.props.history.goBack()
  }

  showOrderEmailPopup() {
    this.setState({
      showOrderEmailPopup: true,
    })
  }

  closeOrderEmailPopup() {
    this.setState({
      showOrderEmailPopup: false,
      popupFormError: '',
    })
  }

  showOrderPhonePopup() {
    this.setState({
      showOrderPhonePopup: true,
    })
  }

  closeOrderPhonePopup() {
    this.setState({
      showOrderPhonePopup: false,
      popupFormError: '',
    })
  }

  showCustomerEmailPopup() {
    this.setState({
      showCustomerEmailPopup: true,
    })
  }

  closeCustomerEmailPopup() {
    this.setState({
      showCustomerEmailPopup: false,
      popupFormError: '',
    })
  }

  showCustomerPhonePopup() {
    this.setState({
      showCustomerPhonePopup: true,
    })
  }

  closeCustomerPhonePopup() {
    this.setState({
      showCustomerPhonePopup: false,
      popupFormError: '',
    })
  }

  handleOrderEmailAdd({ email }) {
    const newState = Object.assign({}, this.state.values)
    if (!newState.orderNotificationEmails.includes(email)) {
      newState.orderNotificationEmails.push(email)
    } else {
      this.setState({
        popupFormError: getMessage('notification.email.error'),
      })
    }

    this.setState({
      values: newState,
      showOrderEmailPopup: false,
    })
  }

  handleCustomerEmailAdd({ email }) {
    const newState = Object.assign({}, this.state.values)
    if (!newState.customerNotificationEmails.includes(email)) {
      newState.customerNotificationEmails.push(email)
    } else {
      this.setState({
        popupFormError: getMessage('notification.email.error'),
      })
    }

    this.setState({
      values: newState,
      showCustomerEmailPopup: false,
    })
  }

  handleOrderEmailDelete() {
    const index = this.state.index
    const newState = Object.assign({}, this.state.values)
    const newEmails = this.state.values.orderNotificationEmails.filter(
      (email, i) => i !== index
    )
    newState.orderNotificationEmails = newEmails
    this.setState({
      values: newState,
      showOrderDeleteEmailDialog: false,
    })
  }

  handleCustomerEmailDelete() {
    const index = this.state.index
    const newState = Object.assign({}, this.state.values)
    const newEmails = this.state.values.customerNotificationEmails.filter(
      (email, i) => i !== index
    )
    newState.customerNotificationEmails = newEmails
    this.setState({
      values: newState,
      showCustomerDeleteEmailDialog: false,
    })
  }

  hideEmailDeleteConfirmation() {
    this.setState({
      showOrderDeleteEmailDialog: false,
    })
  }

  handleOrderPhoneAdd({ phone }) {
    const newState = Object.assign({}, this.state.values)
    if (isValidNumber(phone)) {
      if (!newState.orderNotificationPhones.includes(phone)) {
        newState.orderNotificationPhones.push(phone)
      } else {
        this.setState({
          popupFormError: getMessage('notification.phone.error'),
        })
      }
    } else {
      this.setState({
        popupFormError: getMessage('input.invalidPhoneFormat'),
      })
    }
    this.setState({
      newState,
      showOrderPhonePopup: false,
    })
  }

  handleOrderPhoneDelete() {
    const index = this.state.index
    const newState = Object.assign({}, this.state.values)
    const newPhones = this.state.values.orderNotificationPhones.filter(
      (phone, i) => i !== index
    )
    newState.orderNotificationPhones = newPhones
    this.setState({
      values: newState,
      showOrderDeletePhoneDialog: false,
    })
  }

  handleCustomerPhoneAdd({ phone }) {
    const newState = Object.assign({}, this.state.values)
    if (isValidNumber(phone)) {
      if (!newState.customerNotificationPhones.includes(phone)) {
        newState.customerNotificationPhones.push(phone)
      } else {
        this.setState({
          popupFormError: getMessage('notification.phone.error'),
        })
      }
    } else {
      this.setState({
        popupFormError: getMessage('input.invalidPhoneFormat'),
      })
    }
    this.setState({
      newState,
      showCustomerPhonePopup: false,
    })
  }

  handleCustomerPhoneDelete() {
    const index = this.state.index
    const newState = Object.assign({}, this.state.values)
    const newPhones = this.state.values.customerNotificationPhones.filter(
      (phone, i) => i !== index
    )
    newState.customerNotificationPhones = newPhones
    this.setState({
      values: newState,
      showCustomerDeletePhoneDialog: false,
    })
  }

  hidePhoneDeleteConfirmation() {
    this.setState({
      showOrderDeletePhoneDialog: false,
    })
  }

  hideCustomerDeletePhoneDialog() {
    this.setState({
      showCustomerDeletePhoneDialog: false,
    })
  }

  showEmails(emailsToShow) {
    const _this = this
    return emailsToShow.map((email, i) => {
      return (
        <Send
          key={i}
          icon={MailIcon}
          name={getMessage('notifications.name.email')}
          midText={MSG_NOTIFICATION_MIDTEXT}
          value={email}
          onDelete={() => {
            _this.showOrderDeleteEmailDialog(i)
          }}
        />
      )
    })
  }

  showCustomerEmails(emailsToShow) {
    const _this = this
    return emailsToShow.map((email, i) => {
      return (
        <Send
          key={i}
          icon={MailIcon}
          name={getMessage('notifications.name.email')}
          midText={MSG_NOTIFICATION_MIDTEXT}
          value={email}
          onDelete={() => {
            _this.showCustomerDeleteEmailDialog(i)
          }}
        />
      )
    })
  }

  showOrderDeleteEmailDialog(index) {
    this.setState({
      index: index,
      showOrderDeleteEmailDialog: true,
    })
  }

  showCustomerDeleteEmailDialog(index) {
    this.setState({
      index: index,
      showCustomerDeleteEmailDialog: true,
    })
  }

  hideCustomerDeleteEmailDialog() {
    this.setState({
      showCustomerDeleteEmailDialog: false,
    })
  }

  showPhones(phonesToShow) {
    const _this = this
    return phonesToShow.map((phone, i) => {
      return (
        <Send
          key={i}
          icon={SmsIcon}
          name={getMessage('notifications.name.phone')}
          midText={MSG_NOTIFICATION_MIDTEXT}
          value={format(parse(phone), 'International')}
          onDelete={() => {
            _this.showOrderDeletePhoneDialog(i)
          }}
        />
      )
    })
  }

  showCustomerPhones(phonesToShow) {
    const _this = this
    return phonesToShow.map((phone, i) => {
      return (
        <Send
          key={i}
          icon={SmsIcon}
          name={getMessage('notifications.name.phone')}
          midText={MSG_NOTIFICATION_MIDTEXT}
          value={format(parse(phone), 'International')}
          onDelete={() => {
            _this.showCustomerDeletePhoneDialog(i)
          }}
        />
      )
    })
  }

  showOrderDeletePhoneDialog(index) {
    this.setState({
      index: index,
      showOrderDeletePhoneDialog: true,
    })
  }

  showCustomerDeletePhoneDialog(index) {
    this.setState({
      index: index,
      showCustomerDeletePhoneDialog: true,
    })
  }

  hideOrderDeletePhoneDialog() {
    this.setState({
      showDeletePhoneDialog: false,
    })
  }

  closeSuccess() {
    this.setState({
      showSuccessDialog: false,
    })
  }

  onSubmit({ ...data }) {
    this.setState({
      submitting: true,
    })
    const api = new API({ url: '/account-service/config/notification' })
    const params = {
      notification: data,
    }
    params['notification']['orderPlaceEmailRecipients'] =
      data.orderNotificationEmails
    params['notification']['orderPlaceSmsRecipients'] =
      data.orderNotificationPhones
    params['notification']['customerAddNewAddressEmailRecipients'] =
      data.customerNotificationEmails
    params['notification']['customerAddNewAddressSmsRecipients'] =
      data.customerNotificationPhones
    params['notification']['customerSignUpEmailRecipients'] =
      data.customerNotificationEmails
    params['notification']['customerSignUpSmsRecipients'] =
      data.customerNotificationPhones
    params['notification']['customerWalletRechargeEmailRecipients'] =
      data.customerNotificationEmails
    params['notification']['customerWalletRechargeSmsRecipients'] =
      data.customerNotificationPhones
    params['notification']['orderCancelEmailRecipients'] =
      data.orderNotificationEmails
    params['notification']['orderCancelSmsRecipients'] =
      data.orderNotificationPhones
    params['notification']['orderRefundEmailRecipients'] =
      data.orderNotificationEmails
    params['notification']['orderRefundSmsRecipients'] =
      data.orderNotificationPhones
    delete data.customerNotificationEmails
    delete data.customerNotificationPhones
    delete data.orderNotificationEmails
    delete data.orderNotificationPhones
    api.put(params).then(
      () => {
        this.setState({
          submitting: false,
          showSuccessDialog: true,
        })
      },
      (error) => {
        this.setState({
          formError: error.message.split(':').slice(1),
          submitting: false,
        })
      }
    )
  }
  componentWillUnmount() {
    this.api && this.api.cancel()
    this.configApi && this.configApi.cancel()
  }

  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    return this.state.showLoader ? (
      <Loader />
    ) : (
      <div className="notifications-form">
        <Popup
          show={this.state.showOrderEmailPopup}
          heading={getMessage('notifications.add.email')}
          close={this.closeOrderEmailPopup}
        >
          <span className="form-error">{this.state.popupFormError}</span>
          <AddEmailForm onSubmit={this.handleOrderEmailAdd} />
        </Popup>
        <Popup
          show={this.state.showOrderPhonePopup}
          heading={getMessage('notifications.add.phone')}
          close={this.closeOrderPhonePopup}
        >
          <span className="form-error">{this.state.popupFormError}</span>
          <AddPhoneForm
            onSubmit={this.handleOrderPhoneAdd}
            isoCode={this.countryIsoCode}
          />
        </Popup>
        <Popup
          show={this.state.showCustomerEmailPopup}
          heading={getMessage('notifications.add.email')}
          close={this.closeCustomerEmailPopup}
        >
          <span className="form-error">{this.state.popupFormError}</span>
          <AddEmailForm onSubmit={this.handleCustomerEmailAdd} />
        </Popup>
        <Popup
          show={this.state.showCustomerPhonePopup}
          heading={getMessage('notifications.add.phone')}
          close={this.closeCustomerPhonePopup}
        >
          <span className="form-error">{this.state.popupFormError}</span>
          <AddPhoneForm
            onSubmit={this.handleCustomerPhoneAdd}
            isoCode={this.countryIsoCode}
          />
        </Popup>
        <Dialog
          show={this.state.showOrderDeleteEmailDialog}
          title={MSG_DELETE_PHONE}
          information={getMessage('notification.email.delete.message')}
          onOk={this.handleOrderEmailDelete}
          close={this.hideEmailDeleteConfirmation}
          closeText={getMessage('notification.email.delete.cancelText')}
          okText={getMessage('notification.email.delete.confirmText')}
        />
        <Dialog
          show={this.state.showOrderDeletePhoneDialog}
          title={MSG_DELETE_PHONE}
          information={getMessage('notification.phone.delete.message')}
          onOk={this.handleOrderPhoneDelete}
          close={this.hidePhoneDeleteConfirmation}
          closeText={getMessage('notification.phone.delete.cancelText')}
          okText={getMessage('notification.phone.delete.confirmText')}
        />
        <Dialog
          show={this.state.showCustomerDeleteEmailDialog}
          title={getMessage('notification.email.delete.title')}
          information={getMessage('notification.email.delete.message')}
          onOk={this.handleCustomerEmailDelete}
          close={this.hideCustomerDeleteEmailDialog}
          closeText={getMessage('notification.email.delete.cancelText')}
          okText={getMessage('notification.email.delete.confirmText')}
        />
        <Dialog
          show={this.state.showCustomerDeletePhoneDialog}
          title={MSG_DELETE_PHONE}
          information={getMessage('notification.phone.delete.message')}
          onOk={this.handleCustomerPhoneDelete}
          close={this.hideCustomerDeletePhoneDialog}
          closeText={getMessage('notification.phone.delete.cancelText')}
          okText={getMessage('notification.phone.delete.confirmText')}
        />
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          title={getMessage('notification.success')}
          information={getMessage('notification.success.save')}
          close={this.closeSuccess}
          closeText={getMessage('notification.okay.text')}
        />

        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <h3>{getMessage('notifications.order.title')}</h3>
          <div className="notifications-group">
            <div className="form-fields">
              {this.showEmails(this.state.values.orderNotificationEmails)}
              {this.showPhones(this.state.values.orderNotificationPhones)}
              <DropDown
                icon={
                  <div className="heading">
                    <img src={AddIcon} alt="+" />
                    {getMessage('notifications.add')}
                  </div>
                }
              >
                <DropDownItem onClick={this.showOrderEmailPopup}>
                  <div className="name-part">
                    <div className="icon">
                      <img src={MailIcon} alt="" />
                    </div>
                    <div className="name">
                      {getMessage('notifications.email')}
                    </div>
                  </div>
                </DropDownItem>
                <DropDownItem onClick={this.showOrderPhonePopup}>
                  <div className="name-part">
                    <div className="icon">
                      <img src={SmsIcon} alt="" />
                    </div>
                    <div className="name">
                      {getMessage('notifications.phone')}
                    </div>
                  </div>
                </DropDownItem>
              </DropDown>
              <Toggle
                name="order-place"
                {...this.generateStateMappers({
                  stateKeys: ['notifyOnOrderPlace'],
                })}
                icons={false}
                togglelabel={getMessage('notifications.new.order')}
              />
              <Toggle
                name="order-cancel"
                {...this.generateStateMappers({
                  stateKeys: ['notifyOnOrderCancel'],
                })}
                icons={false}
                togglelabel={getMessage('notifications.order.cancel')}
              />
              <Toggle
                name="order-refund"
                {...this.generateStateMappers({
                  stateKeys: ['notifyOnOrderRefund'],
                })}
                icons={false}
                togglelabel={getMessage('notifications.order.refund')}
              />
            </div>
            <div className="explanation">
              Some explanation text starts from here which will make the user
              understand how important this part for them to get notified.
            </div>
          </div>
          <h3>{getMessage('notifications.customer.title')}</h3>
          <div className="notifications-group">
            <div className="form-fields">
              {this.showCustomerEmails(
                this.state.values.customerNotificationEmails
              )}
              {this.showCustomerPhones(
                this.state.values.customerNotificationPhones
              )}
              <DropDown
                icon={
                  <div className="heading">
                    <img src={AddIcon} alt="+" />
                    {getMessage('notifications.add')}
                  </div>
                }
              >
                <DropDownItem onClick={this.showCustomerEmailPopup}>
                  <div className="name-part">
                    <div className="icon">
                      <img src={MailIcon} alt="" />
                    </div>
                    <div className="name">
                      {getMessage('notifications.email')}
                    </div>
                  </div>
                </DropDownItem>
                <DropDownItem onClick={this.showCustomerPhonePopup}>
                  <div className="name-part">
                    <div className="icon">
                      <img src={SmsIcon} alt="" />
                    </div>
                    <div className="name">
                      {getMessage('notifications.phone')}
                    </div>
                  </div>
                </DropDownItem>
              </DropDown>
              <Toggle
                name="customer-joins"
                {...this.generateStateMappers({
                  stateKeys: ['notifyOnCustomerSignUp'],
                })}
                icons={false}
                togglelabel={getMessage('notification.new.customer')}
              />
              {/* <Toggle
                name='customer-add-money'
                {...this.generateStateMappers({
                  stateKeys: ['notifyOnCustomerWalletRecharge']
                })}
                icons={false}
                togglelabel={getMessage('notifications.customer.add.money')}
              /> */}
              <Toggle
                name="customer-add-address"
                {...this.generateStateMappers({
                  stateKeys: ['notifyOnCustomerAddNewAddress'],
                })}
                icons={false}
                togglelabel={getMessage('notifications.customer.add.address')}
              />
              {/* <Toggle
                name='customer-complain'
                {...this.generateStateMappers({
                  stateKeys: ['notifyOnCustomerComplains']
                })}
                icons={false}
                togglelabel={getMessage('notifications.customer.complain')}
              /> */}
            </div>
            <div className="explanation">
              Some explanation text starts from here which will make the user
              understand how important this part for them to get notified.
            </div>
          </div>
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage('notifications.save')}
            </SubmitButton>
            <button
              className="button"
              type="button"
              onClick={this.handleClose}
              disabled={this.state.submitting}
            >
              {getMessage('notifications.cancel')}
            </button>
          </div>
        </Form>
      </div>
    )
  }
}
export default withRouter(NotificationsForm)
