import React from 'react'
import {
  BaseForm,
  VALIDATION_TYPES,
  MultiSelect,
} from '../../../../../components/Form'
import { Dialog } from '../../../../../components/Popup'
import API from '../../../../../lib/api'
import { getLanguages } from '../../../../../lib/commonlyused'
import { getMessage } from '../../../../../lib/translator'

export default class LanguageSelect extends BaseForm {
  constructor(props) {
    super(props)
    this.state.showSuccessDialog = false
    this.state.showErrorDialog = false
    this.state.formError = ''
    this.closeDialogs = this.closeDialogs.bind(this)
  }

  handleSubmit(data) {
    const id = this.props.data.id
    const api = new API({ url: `/account-service/extension/${id}` })

    const params = { status: this.props.data.status }
    params['config'] = {}
    for (const field in data) {
      params['config'][field] = data[field]
    }

    return api.put(params).then(
      () => {
        this.setState({ formError: '', showSuccessDialog: true })
      },
      error => {
        this.setState({ formError: error.message, showErrorDialog: true })
      }
    )
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    })
    this.handleSubmit(data).then(() => {
      this.setState({ submitting: false })
    })
  }

  closeDialogs() {
    this.setState({
      showSuccessDialog: false,
      showErrorDialog: false,
    })
  }
  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons
    return (
      <div>
        <h1 className="title">
          {getMessage('extensions.multiLingual.heading')}
        </h1>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage('extensions.analytics.save.success.title')}
          close={this.closeDialogs}
          closeText={getMessage('extensions.analytics.save.success.closeText')}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={getMessage('extensions.analytics.save.error.title')}
          close={this.closeDialogs}
          closeText={getMessage('extensions.analytics.save.error.closeText')}
        />
        <Form>
          <div className="coupon-section valid-stores">
            <MultiSelect
              //   label={getMessage('coupon.form.stores.heading')}
              label=" "
              name="Language"
              placeholder={getMessage('extensions.multiLingual.placeholder')}
              required
              options={getLanguages()
                .sort((a, b) => {
                  const x = a.name.toLowerCase()
                  const y = b.name.toLowerCase()
                  return x < y ? -1 : x > y ? 1 : 0
                })
                .map((lng, i) => {
                  lng.key = `language${i}`
                  lng.text = lng.name
                  lng.value = lng.code
                  return lng
                })}
              {...this.generateStateMappers({
                stateKeys: ['globalConfig', 'languages'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <SubmitButton>Submit</SubmitButton>
          <CancelButton>Cancel</CancelButton>
        </Form>
      </div>
    )
  }
}
