import React, { useState } from 'react'
import TickIcon from 'icons/tick.svg'
import PropTypes from 'prop-types'
import './style.scss'
import { getMessage } from 'lib/translator'
import API from 'lib/api'

function StepperForm({
  steppedComponents,
  additionalFooterElements,
  submitText,
  api,
  checkBeforeSubmit,
}) {
  const [stepIndex, setStepIndex] = useState(0)

  const isCompletedStep = (index) => stepIndex > index
  const isCurrentStep = (index) => stepIndex === index

  const goBack = () => setStepIndex(stepIndex - 1)
  const goNext = () => setStepIndex(stepIndex + 1)

  const goToIndex = (index) => {
    if (stepIndex > index) {
      setStepIndex(index)
    }
  }

  const onSubmit = async () => {
    const apiRequestTypes = {
      POST: 'post',
      PUT: 'put',
      PATCH: 'patch',
    }

    // when an object is passed as a prop, it will replace the default object specified in defaultProps.
    // So, merge two objects to ensure defaultProps is taken if some values are missing
    const mergedApiProp = { ...StepperForm.defaultProps.api, ...api }
    const {
      url,
      requestType,
      transformPayload,
      errorCallback,
      successCallback,
    } = mergedApiProp
    const { payload: payloadProp } = mergedApiProp
    let res = null
    if (typeof checkBeforeSubmit === 'function') {
      res = await checkBeforeSubmit()
      if (!res) {
        // stop the submit call if any check fails. any error should be handled in parent component
        return
      }
    }
    let payload = payloadProp
    if (typeof transformPayload === 'function') {
      payload = transformPayload(payload, res)
    }

    try {
      const apiCall = new API({ url })
      const response = await apiCall[apiRequestTypes[requestType]](payload)
      successCallback(response)
    } catch (e) {
      console.log(e)
      errorCallback(e)
    }
  }

  return (
    <div className="stepper-form">
      <div className="steps-wrapper">
        {steppedComponents.map((step, index) => (
          <div
            key={step.title.split(' ').join('-')}
            className={`single-step-wrapper ${isCurrentStep(index) ? 'active' : isCompletedStep(index) ? 'completed' : 'inactive'}`}
          >
            <div
              className={`step-number ${isCompletedStep(index) ? 'clickable' : ''}`}
              onClick={() => goToIndex(index)}
            >
              {isCompletedStep(index) ? (
                <img src={TickIcon} alt="tick-icon" />
              ) : (
                index + 1
              )}
            </div>
            <span
              className={`step-text ${isCompletedStep(index) ? 'clickable' : ''}`}
              onClick={() => goToIndex(index)}
            >
              {step.title}
            </span>
          </div>
        ))}
      </div>
      {steppedComponents[stepIndex].component}
      <div className="footer-wrapper">
        {additionalFooterElements}
        {stepIndex !== 0 && (
          <button className="button secondary" onClick={goBack}>
            {getMessage('common.stepperForm.back')}
          </button>
        )}
        {stepIndex === steppedComponents.length - 1 ? (
          <button
            className="button primary"
            onClick={onSubmit}
            disabled={steppedComponents[stepIndex].disableNext}
          >
            {submitText}
          </button>
        ) : (
          <button
            className="button primary"
            disabled={steppedComponents[stepIndex].disableNext}
            onClick={goNext}
          >
            {getMessage('common.stepperForm.next')}
          </button>
        )}
      </div>
    </div>
  )
}

StepperForm.defaultProps = {
  submitText: getMessage('common.stepperForm.submit'),
  api: {
    errorCallback: () => {},
    successCallback: () => {},
    transformPayload: (value) => value,
    requestType: 'POST',
  },
}

StepperForm.propTypes = {
  submitText: PropTypes.string.isRequired,
  additionalFooterElements: PropTypes.arrayOf(PropTypes.element),
  checkBeforeSubmit: PropTypes.func,
  steppedComponents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      component: PropTypes.element.isRequired,
      disableNext: PropTypes.bool,
    })
  ),
  api: PropTypes.shape({
    url: PropTypes.string.isRequired,
    payload: PropTypes.object,
    requestType: PropTypes.oneOf(['POST', 'PUT', 'PATCH']),
    transformPayload: PropTypes.func,
    successCallback: PropTypes.func,
    errorCallback: PropTypes.func,
  }),
}

export default StepperForm
