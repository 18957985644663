import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from 'components/Table'
import Image from 'components/Image'
import { getMessage } from 'lib/translator'
import {
  getItemData,
  getCalculatedItemData,
} from 'lib/commonlyused/orderCalculations'
import { getQuantity, isBeforePackedOrCancelled } from '../helpers.utils'
import SubstitutionIcon from 'icons/substitution-icon.svg'
import { FinalAmount } from './index'
import EditOrderItem from '../../EditPackedDeliveryOrder/EditOrderItem'
import './style.scss'

const isMarketplaceSku = (clientItemId) => clientItemId >= 90000000

function SingleOrderItemRow({
  rowClassName,
  item,
  currency,
  isSubstituted,
  isOriginalItem,
  orderStatus,
  itemEditMode,
  updateOrderItem,
}) {
  const {
    orderedQuantity,
    deliveredQuantity,
    mrp,
    discount,
    soldByWeight,
    clientItemId,
    brand,
    name,
    itemId,
    image,
    amendmentItemIndex,
  } = getItemData(item, 'deliveryOrder')

  const { fixedDecimalPlace, originalItemAmount, finalItemAmount } =
    getCalculatedItemData({ ...item }, 'deliveryOrder')

  const getAmendmentText = (index) => {
    switch (index) {
      case 0:
        return getMessage('saleOrder.details.firstAmendment')
      case 1:
        return getMessage('saleOrder.details.secondAmendment')
      default:
        return ''
    }
  }

  return (
    <Row className={`${rowClassName} ${isOriginalItem ? 'child-row' : ''}`}>
      <Cell className="item-image">
        {!isOriginalItem && (
          <>
            <Image bordered size="sm" src={image} />
            {isSubstituted && (
              <img
                className="substitution-icon-img"
                src={SubstitutionIcon}
                alt="Substitution Icon"
              />
            )}
          </>
        )}
      </Cell>
      <Cell className={`${isOriginalItem ? 'item-name-org' : 'item-name'}`}>
        {isOriginalItem && <Image bordered size="sm" src={image} />}
        <Link to={`/catalogue/products/edit/${itemId}`} className="item-link">
          <small className="text-muted">
            <div className="product-name">
              <span className="brand-name">{brand?.name}</span> {name}
            </div>
            {amendmentItemIndex !== -1 && (
              <div className="amendment-item-text-order">
                {getAmendmentText(amendmentItemIndex)}
              </div>
            )}
          </small>
        </Link>
      </Cell>
      <Cell className="client-item-id">
        <Link to={`/catalogue/products/edit/${itemId}`}>
          {clientItemId}
          {isMarketplaceSku(clientItemId) && (
            <span style={{ fontWeight: 'bold' }}>
              &nbsp;({getMessage('saleOrder.details.mkpLabel')})
            </span>
          )}
        </Link>
      </Cell>
      <Cell className="item-original text-right">
        <small className="text-muted">
          {getQuantity(orderedQuantity, fixedDecimalPlace)}
          {soldByWeight && 'kg'}
        </small>
      </Cell>
      {itemEditMode ? (
        <Cell>
          <EditOrderItem
            item={item}
            updateOrderItem={updateOrderItem}
          ></EditOrderItem>
        </Cell>
      ) : (
        <>
          {!isBeforePackedOrCancelled(orderStatus) && (
            <Cell className="item-final text-right">
              <small className="text-muted">
                {getQuantity(deliveredQuantity, fixedDecimalPlace)}
                {deliveredQuantity && soldByWeight ? 'kg' : ''}
              </small>
            </Cell>
          )}
          <Cell className="item-mrp text-right">
            <small className="text-muted">
              {currency.symbol}
              {Number(mrp).toFixed(2)}
            </small>
          </Cell>
          <Cell className="item-discount text-right">
            <small className="text-muted">
              {currency.symbol}
              {Number(discount).toFixed(2)}
            </small>
          </Cell>
          <Cell className="item-amount text-right">
            <small>
              <FinalAmount
                orderStatus={orderStatus}
                originalAmount={originalItemAmount}
                finalAmount={finalItemAmount}
                currency={currency}
              />
            </small>
          </Cell>
        </>
      )}
    </Row>
  )
}

export default SingleOrderItemRow
