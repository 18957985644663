import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../Table'
import { DropDown, DropDownItem, ICONS } from '../../DropDown'
import { getMessage } from 'lib/translator'
import { getStatusText } from '../utils'

export const tableProperties = {
  headers: [
    'Store ID',
    'Store Name',
    'Partner Name',
    'Street',
    'Postal Code',
    'Lat',
    'Lng',
    'Pin Code',
    'Status',
    'Actions',
  ],
  row: ({ id, name, partnerName, address, lat, lng, pinCode, status }) => {
    const { street, postalCode } = address
    return (
      <Row>
        <Cell className="rewards-page-actions column-id">
          <Link to={`/rewards/stores/edit/${id}`}>{id}</Link>
        </Cell>
        <Cell className="rewards-page-actions column-name">
          <Link to={`/rewards/stores/edit/${id}`}>{name}</Link>
        </Cell>
        <Cell className="rewards-page-actions column-partner-name">
          {partnerName}
        </Cell>
        <Cell className="rewards-page-actions column-street">{street}</Cell>
        <Cell className="rewards-page-actions column-postalcode">
          {postalCode}
        </Cell>
        <Cell className="rewards-page-actions column-lat">{lat}</Cell>
        <Cell className="rewards-page-actions column-lng">{lng}</Cell>
        <Cell className="rewards-page-actions column-lng">{pinCode}</Cell>
        <Cell className="rewards-page-actions column-status">
          {getStatusText(status)}
        </Cell>
        <Cell className="rewards-page-actions column-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <Link to={`/rewards/stores/edit/${id}`}>
              <DropDownItem>{getMessage('rewards.stores.edit')}</DropDownItem>
            </Link>
          </DropDown>
        </Cell>
      </Row>
    )
  },
}

export const postalCodeRegex = '^[0-9]{6}$'

export const postalCodeErrorMsg = {
  patternMismatch: 'Incorrect value. Please enter 6 digits.',
}

export const storeDataCsvExample = () => {
  const CSV_HEADERS = [
    'Partner ID',
    'Name',
    'Status',
    'Lat',
    'Lng',
    'Country Code',
    'Phone Number',
    'Street',
    'Floor',
    'Unit',
    'Postal Code',
    'Opening Hour',
  ]
  let csv = ''
  csv += CSV_HEADERS.join(',')
  csv += '\n'
  return csv
}

export const clientIDsCsvSample = () => {
  const CSV_HEADERS = ['Client ID']

  function generateCSVRow(data) {
    const rowValues = CSV_HEADERS.map((header) => data[header] || '')
    return rowValues.join(',')
  }

  const dataObjects = [
    {
      'Client ID': '13096356',
    },
    {
      'Client ID': '13151990',
    },
  ]

  let csv = ''
  csv += CSV_HEADERS.join(',') + '\n'

  dataObjects.forEach((data) => {
    csv += generateCSVRow(data) + '\n'
  })

  // Return the generated CSV as a string
  return csv
}
