import React, { Component, useContext } from 'react'
import { CommonComponent } from '../../operations/Customers'
import CsDashboard from '../Dashboard'
import { SplitContext } from '../../../containers/SplitContext'
import SPLIT_FEATURES from '../../../containers/SplitContext/features'

class CustomersListingClass extends Component {
  render() {
    const { props } = this
    return <CommonComponent page="customer-support" {...props} />
  }
}

const Customers = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  const isLinkpassFeatOn =
    splits?.[SPLIT_FEATURES.LOYALTY_LINKPASS_FEATURES]?.treatment === 'on'

  return isLinkpassFeatOn ? (
    <CsDashboard {...props} splits={splits} />
  ) : (
    <CustomersListingClass {...props} />
  )
}

export default Customers
