import React, { Component } from 'react'

import Upload from '../../../../components/FileUpload'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import csvIcon from './csv-icon.svg'
import uploadIcon from './import-customers.svg'
import infoIcon from './info-icon.svg'

import './style.css'

function downloadCsv(data) {
  let csv = ''
  csv += data.join(',')
  csv += '\n'
  var hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  hiddenElement.target = '_blank'
  hiddenElement.download = 'customers.csv'
  hiddenElement.click()
}

export default class CustomerImport extends Component {
  constructor(props) {
    super(props)
    this.getSampleCsv = this.getSampleCsv.bind(this)
  }

  getSampleCsv(e) {
    e.preventDefault()
    const api = new API({ url: '/customer-service/customer-upload' })
    api.get().then(
      response => {
        downloadCsv(response.data.customerUpload || [])
      },
      error => {
        this.setState({ uploadError: error.message })
      }
    )
  }

  render() {
    return (
      <div className="product-upload">
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          <Upload
            name="customerImport"
            accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
            placeholder={getMessage('productUpload.uploadFileText')}
            uploadButtonText="Upload"
            cancelText="Cancel"
            uploadUrl="/customer-service/customer-upload"
            validationStrings={{
              invalidFileType: getMessage('input.invalidFileType'),
            }}
            strings={{
              defaultMessage: getMessage('fileUpload.importCsv.heading'),
              progressMessage: getMessage('fileUpload.importedCsv.heading'),
              completionMessage: getMessage('customer.import.successText'),
            }}
            icon={csvIcon}
          />
          <div className="cx-import-tooltip">
            <img src={infoIcon} alt="note" />
            NOTE
            <div className="tooltiptext">
              <div className="tooltip-title">Note:</div>
              <div className="instruction">
                * The phone numbers must be prepended with (+) followed by
                country code.
              </div>
              <div className="instruction text-muted">
                {' '}
                Eg: Phone number with country code 91 must be +911234567890
              </div>
              <div className="instruction">
                * For entries with the same client id or information, the
                corresponding records will be merged.
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <a className="download-link" onClick={this.getSampleCsv}>
            {getMessage('productUpload.csvDownloadText')}
          </a>
        </div>
      </div>
    )
  }
}
