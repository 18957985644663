import React from 'react'
import { Input, Textarea } from '../../Form'
import { getMessage } from '../../../lib/translator'

const fields = [
  {
    key: 'promoCodeDescription',
    label: 'omnichallenge.description',
    component: Textarea,
  },
  {
    key: 'limitation',
    label: 'rewards.catalogue.redemptionLimit',
    component: Input,
  },
  { key: 'startAt', label: 'rewards.startAt', component: Input },
  { key: 'endAt', label: 'rewards.endAt', component: Input },
]

const PtrOfferAndPtrRcFields = ({ _this, taskIndex, awardIndex }) => {
  return (
    <div className="row">
      {fields.map(({ key, label, component: Component }) => (
        <div className="col-6" key={key}>
          <Component
            label={getMessage(label)}
            placeholder={getMessage(label)}
            type="text"
            readOnly
            {..._this.generateStateMappers({
              stateKeys: ['taskList', taskIndex, 'awardList', awardIndex, key],
            })}
          />
        </div>
      ))}
    </div>
  )
}

export default PtrOfferAndPtrRcFields
