import React from 'react';
import Image from 'components/Image';
import Table, { Header, Row, Cell } from 'components/Table';
import { getMessage } from 'lib/translator';
import { formatCardNumber, getPaymentLogo } from 'lib/payment';
import './style.scss';

export default function PaymentDetails({ payments, currency }) {
  return (
    <div className='payment-details'>
      <div className='section-title'>
        <h3>{getMessage('saleOrder.details.payment.heading')}</h3>
        <Table>
          <Header>
            <Cell>{getMessage('saleOrder.details.payment.amount')}</Cell>
            <Cell>{getMessage('saleOrder.details.payment.mode')}</Cell>
            <Cell>{getMessage('saleOrder.details.payment.card')}</Cell>
            <Cell>{getMessage('saleOrder.details.payment.status')}</Cell>
            <Cell>{getMessage('saleOrder.details.payment.details')}</Cell>
          </Header>
          {
            payments.map((paymentItem) => (
              <Row key={paymentItem.id}>
                <Cell>
                  {currency.symbol} {Number(paymentItem.amount).toFixed(2)}
                </Cell>

                <Cell>
                  <div>{paymentItem.mode}</div>
                </Cell>

                <Cell testId="payment-card-number">
                  { (paymentItem.metaData.cardNumber || paymentItem.metaData.cardType) ?
                    <React.Fragment>
                      <Image
                        className="payment-card"
                        size="sm"
                        src={getPaymentLogo(paymentItem.metaData.cardType)}
                      />
                      <span className='formatted-card-number'>
                        {formatCardNumber(paymentItem.metaData.cardNumber, paymentItem.metaData.type)}
                      </span>
                    </React.Fragment>
                  :'-'}
                </Cell>

                <Cell className="payment-status">
                  {paymentItem.status.split('PAYMENT_STATUS_').pop()}
                </Cell>

                <Cell className="payment-transaction-id">
                  {getMessage('order.details.payment.transactionId')}-
                  <span className="transaction-id">
                    {paymentItem.transactionId}
                  </span>
                </Cell>
              </Row>
            ))
          }
        </Table>
      </div>
    </div>
  )
}
