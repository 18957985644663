import React from 'react'
import Tabs from '../../../../components/Tabs'
import API from '../../../../lib/api'
import {
  getEditTabs,
  handleRuleList,
  handleBaseChanceRule,
  handleConditionList,
  handleAdmissionRules,
  transformAPIData,
  readOnly,
  formatCampaignDate,
  isCutOffDateWithinCampaign,
} from '../../../../components/LuckyDraw/utils'
import { Checkbox, BaseForm } from '../../../../components/Form'
import Details from '../../../../components/LuckyDraw/Create/DetailsTab/Details'
import Qualifiers from '../../../../components/LuckyDraw/Create/Qualifiers/Qualifiers'
import Mechanics from '../../../../components/LuckyDraw/Create/MechanicsTab/Mechanics'
import Participants from '../../../../components/LuckyDraw/ParticipantsTab/Participants'
import './style.css'
import moment from 'moment'
import { getMessage } from '../../../../lib/translator'

class LuckydrawForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      verifyingData: false,
      checkedDetailS: false,
      formReady: false,
      submitting: false,
      activeIndex: 0,
      startAt: '',
      endAt: '',
      costRules: '',
      chanceRules: '',
      data: '',
      campaignStarted: false,
      loading: false,
      errorMessage: '',
    }

    this.changeTab = this.changeTab.bind(this)
    this.nextTab = this.nextTab.bind(this)
    this.verifyData = this.verifyData.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.formatData = this.formatData.bind(this)
    this.handlePatch = this.handlePatch.bind(this)
    this.requestHandler = this.requestHandler.bind(this)
  }

  changeTab(activeIndex) {
    this.setState({ activeIndex })
  }

  nextTab() {
    const activeIndex = this.state.activeIndex
    this.changeTab(activeIndex + 1)
  }

  verifyData() {
    return new Promise((resolve) => {
      this.setState({ verifyingData: true })
      const validations = Object.assign({}, this.state.validations)
      const values = Object.assign({}, this.state.values)
      if (
        !('channels' in values) ||
        !Object.values(values.channels).includes(true) ||
        ('channelInfoMap' in validations &&
          !this.isFormValid(validations.channelInfoMap))
      ) {
        this.changeTab(1)
        this.setState({ checkedDetails: false })
      } else if (!this.isFormValid()) {
        this.changeTab(0)
        this.setState({ checkedDetails: false })
      } else {
        resolve(this.setState({ formReady: true }))
      }
    })
  }

  async formatData() {
    const {
      startDate,
      startTime,
      endDate,
      endTime,
      campaignName,
      inputList,
      prizes,
      participate,
      campaignURL,
      campaignWinnersURL,
      campaignTermsURL,
      imageUrl,
      bannerUrl,
      channels,
      channelInfoMap,
      rulesList,
      conditionsList,
      userSegment,
      cardSegment,
      isManual,
    } = this.state.values

    const { startAt, endAt } = formatCampaignDate(
      startDate,
      startTime,
      endDate,
      endTime
    )

    const draw = inputList.map((list, i) => {
      const cutOffAt = moment(list.cutOffDate).format()
      if (!isCutOffDateWithinCampaign(cutOffAt, startAt, endAt)) {
        throw new Error(
          `Draw ${i + 1}: CutOff date is not within the campaign period.`
        )
      }

      return {
        cutOffAt,
        winnersCount: list.numberOfWinners,
        reservedWinnersCount: list.numberOfReserveWinners,
      }
    })
    const baseChanceRule = handleBaseChanceRule(channels, channelInfoMap)

    const chanceRules = handleRuleList(rulesList, startAt, endAt)

    const costRules = handleConditionList(conditionsList)

    const admissionRules = handleAdmissionRules(userSegment, cardSegment)

    return {
      name: campaignName,
      startAt: startDate && startAt,
      endAt: endDate && endAt,
      tncUrl: campaignTermsURL || '',
      winnerListUrl: campaignWinnersURL || '',
      campaignPageUrl: campaignURL,
      participationGuide: participate,
      prizes,
      imgUrls: {
        logo: imageUrl,
        banner: bannerUrl,
      },
      draws: draw ? [...draw] : [],
      baseChanceRule: baseChanceRule,
      admissionRules: admissionRules,
      costRules: [...costRules],
      chanceRules: [...chanceRules],
      isManual: isManual,
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    await this.verifyData()

    this.requestHandler('POST')
  }

  async handlePatch() {
    this.setState({
      pressedSubmitWithCurrentData: true,
    })

    await this.verifyData()

    this.requestHandler('PATCH')
  }

  async requestHandler(req) {
    const { formReady } = this.state

    if (formReady) {
      try {
        switch (req) {
          case 'PATCH': {
            const { id } = this.props.value
            const data = await this.formatData()
            const api = new API({ url: `/duck/campaigns/${id}` })
            const response = await api.patch(data)
            response && this.props.onCancel()
            break
          }
          case 'POST': {
            const data = await this.formatData()
            const { checkedDetails } = this.state
            if (checkedDetails) {
              this.setState({
                submitting: true,
              })
              const api = new API({ url: '/duck/campaigns' })
              const response = await api.post(data)
              response && this.props.onCancel()
            }
            break
          }
        }
      } catch (error) {
        if (error.code === 401) {
          throw error
        }

        this.setState({
          submitting: false,
          errorMessage: error.message,
        })
      }
    }
  }

  async fetchData() {
    this.setState({ loading: true })
    try {
      const { id } = this.props.value
      const api = new API({ url: `/duck/campaigns/${id}?idType=id` })
      const data = await api.get()
      this.setState({
        values: await transformAPIData(data),
        campaignStarted: moment().isAfter(data.startAt),
      })
    } catch (error) {
      this.setState({
        submitting: false,
        errorMessage: error.message,
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    if (this.props.method === 'edit') {
      this.fetchData()
    }
  }

  render() {
    const { Form } = this.components
    const activeIndex = this.state.activeIndex || 0
    const detailsTab = activeIndex === 0
    const qualifiersTab = activeIndex === 1
    const mechanicsTab = activeIndex === 2
    const participantsTab = activeIndex === 3
    const { method } = this.props
    let tabsList = [
      {
        text: 'Details',
      },
      {
        text: 'Qualifiers',
      },
      {
        text: 'Mechanics',
      },
    ]
    if (method && method === 'edit') {
      tabsList = getEditTabs(tabsList)
    }
    const { SubmitButton, CancelButton } = this.buttons
    const { formReady, checkedDetails, submitting, loading } = this.state
    const luckyDrawCancel = 'luckydraw.cancel'

    return (
      <div className="luckydraw container">
        <div className="tab-list">
          <Tabs
            items={tabsList.map((tab) => `${tab.text}`)}
            default={0}
            onClick={this.changeTab}
            active={this.state.activeIndex}
          />
        </div>
        <Form className="luckydraw-form">
          <div className="form-fields">
            {detailsTab && (
              <Details _this={this} method={method} loading={loading} />
            )}
            {qualifiersTab && <Qualifiers _this={this} method={method} />}
            {mechanicsTab && <Mechanics _this={this} method={method} />}
            {mechanicsTab && method !== 'edit' && (
              <div className="single-column-field">
                <div className="submit-form-actions mt-1">
                  <div className="verify-box">
                    <Checkbox
                      testid="verify-checkbox"
                      inlineLabel={getMessage('luckydraw.verify-form')}
                      name="checkedDetails"
                      onChange={(x) => this.setState({ checkedDetails: x })}
                    />
                    {formReady && !checkedDetails && (
                      <div className="verify-box-error">
                        <div className="input-error-message">
                          {getMessage('luckydraw.missingField')}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="submit-draw-buttons">
                    <CancelButton>{getMessage(luckyDrawCancel)}</CancelButton>
                    <SubmitButton testid="confirm-submit" disabled={submitting}>
                      {getMessage('luckydraw.submit')}
                    </SubmitButton>
                  </div>
                </div>
              </div>
            )}
            {!mechanicsTab && !participantsTab && method !== 'edit' && (
              <div className="single-column-field">
                <div className="form-actions mt-1">
                  <div className="submit-draw-buttons">
                    <CancelButton>{getMessage(luckyDrawCancel)}</CancelButton>
                    <button
                      type="button"
                      className="primary"
                      data-testid="next-button"
                      onClick={this.nextTab}
                    >
                      {getMessage('luckydraw.next')}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {!participantsTab && method === 'edit' && (
              <div className="single-column-field">
                <div className="form-actions mt-1">
                  <div className="submit-draw-buttons">
                    <CancelButton>{getMessage(luckyDrawCancel)}</CancelButton>
                    {!readOnly() && (
                      <button
                        type="button"
                        className="primary"
                        data-testid="next-button"
                        onClick={this.handlePatch}
                      >
                        {getMessage('luckydraw.save')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {participantsTab && method === 'edit' && (
            <Participants
              draws={this.state?.values?.draws}
              isManual={this.state?.values?.isManual}
            />
          )}
          {this.state.errorMessage && (
            <div className="error">{this.state.errorMessage}</div>
          )}
        </Form>
      </div>
    )
  }
}
export default LuckydrawForm
