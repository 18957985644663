import React, { useEffect, useState } from 'react'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import { LINK_HOST } from 'config/app'
import '../../customer-support/EGiftingOrders/Details/style.css'
import Table, { Header, Cell, Row } from '../../../components/Table'
import { getPrintableTime } from '../../../lib/datetime'
import Loader from 'components/Loader'
import Pagination from 'components/Pagination'
import { Link } from 'react-router-dom'

const TABLE_HEADER = [
  'Code',
  'Initial Balance',
  'Curent Balance',
  'Date Sent',
  'Status',
  'LastTransactionID',
]

const BatchDetails = (props) => {
  const [details, setDetails] = useState(null)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [voucherDetails, setVoucherDetails] = useState([])

  const fetchVoucherDetails = async (id, offset = 0) => {
    try {
      setIsLoading(true)
      const voucherApi = new API({
        url: `${LINK_HOST}/vouchers?batchId=${id}&limit=20&offset=${offset}`,
      })
      const voucherResponse = await voucherApi.get()
      setVoucherDetails(voucherResponse)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const { id } = props.match.params
    const fetchBatchDetails = async () => {
      try {
        const api = new API({ url: `/batches/${id}` })
        const response = await api.get()
        setDetails(response)
      } catch (error) {
        console.log(error)
      }
    }

    fetchBatchDetails()
    if (voucherDetails?.length === 0) {
      fetchVoucherDetails(id)
    }
  }, [])

  const changePage = (e) => {
    const { id } = props.match.params
    setPage(e)
    fetchVoucherDetails(id, (e - 1) * 20)
  }

  const totalPages = Math.ceil(details?.totalVoucherCount / 20)

  return (
    <AuthenticatedPage
      menu={props.menu}
      from={props.location && props.location.pathname}
      className="evoucher-details"
    >
      <h1 className="title">{getMessage('menu.item.evouchers')}</h1>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="evoucher-summary-wrapper">
          <div className="section-pre-evoucher">
            <h3 className="section-title">Status: {details?.status}</h3>
          </div>
          <div className="user-details">
            <div className="col-6 text-muted">
              <small className="margin-top">
                {`${getMessage('order.filters.batch.heading')}  `}
                <span className="creation-time">{details?.id}</span>
              </small>
            </div>
            <div className="col-6 text-muted">
              <small className="">
                {`${getMessage('eVoucher.filter.entityName')} `}
                <span className="creation-time">{details?.entityName}</span>
              </small>
            </div>
            <div className="col-6 text-muted">
              <small className="">
                {`${getMessage('eVoucher.details.denominations')} `}
                <span className="creation-time">
                  {details?.denomination?.map(
                    (item) => `$${item.amount} (${item.count}) `
                  )}
                </span>
              </small>
            </div>
            <div className="col-6 text-muted">
              <small className="">
                {`${getMessage('eVoucher.details.totalVouchers')} `}
                <span className="creation-time">
                  {details?.totalVoucherCount}
                </span>
              </small>
            </div>
            <div className="col-6 text-muted">
              <small className="">
                {`${getMessage('eVoucher.details.grandTotal')} `}
                <span className="creation-time">{details?.totalAmount}</span>
              </small>
            </div>

            <div className="col-6 margin-top">
              <h3 className="section-title">
                {getMessage('eVoucher.details.batch')}
              </h3>
            </div>
            <div className="row">
              <Table>
                <Header>
                  <Row>
                    {TABLE_HEADER.map((item, i) => (
                      <Cell className="align-center" key={i}>
                        {item}
                      </Cell>
                    ))}
                  </Row>
                  {voucherDetails?.map((item, i) => {
                    return (
                      <Row key={i}>
                        <Link
                          className="cursor"
                          data-testid={`batch-details-${i}`}
                          to={{
                            pathname: `/marketing/search-evoucher`,
                            param: item.code,
                          }}
                        >
                          <Cell className="align-center">
                            {item.code?.replace(/.(?=.{4})/g, '*')}
                          </Cell>
                        </Link>
                        <Cell className="align-center">
                          {item.initialBalance}
                        </Cell>
                        <Cell className="align-center">{item.balance}</Cell>
                        <Cell className="align-center">
                          <small className="text-muted">
                            {item.createdAt &&
                              getPrintableTime(item.createdAt).split(', ')[0]}
                          </small>
                        </Cell>
                        <Cell className="align-center">{item.status}</Cell>
                        <Cell className="align-center">
                          {item.lastTransaction?.id}
                        </Cell>
                      </Row>
                    )
                  })}
                </Header>
              </Table>
            </div>
            <div className="margin-top">
              {totalPages > 1 ? (
                <Pagination
                  total={Math.ceil(details?.totalVoucherCount / 20)}
                  current={Number(page)}
                  onSelect={(e) => changePage(e)}
                />
              ) : null}
            </div>
            <div className="col-6 margin-top">
              <h3 className="section-title">Personalization</h3>
            </div>
            <div className="col-6 text-muted">
              <small className="margin-top">
                {`${getMessage('offer.name')} `}
                <span className="creation-time">
                  {voucherDetails[0]?.personalizatioon?.name}
                </span>
              </small>
            </div>
            <div className="col-6 text-muted">
              <small className="margin-top">
                {`${getMessage('marketing.table.heading.title')} `}
                <span className="creation-time">
                  {voucherDetails[0]?.personalizatioon?.title}
                </span>
              </small>
            </div>
            <div className="col-6 text-muted">
              <small className="margin-top">
                {`${getMessage('rewards.maintenance.message')} `}
                <span className="creation-time">
                  {voucherDetails[0]?.personalizatioon?.message}
                </span>
              </small>
            </div>
            <div className="col-6 text-muted">
              <small className="margin-top">
                {`${getMessage('eVoucher.create.invoice')} `}
                <span className="creation-time">
                  {voucherDetails[0]?.invoiceNumber}
                </span>
              </small>
            </div>
            <div className="col-6 text-muted">
              <small className="margin-top">
                {`${getMessage('eVoucher.details.remarks')} `}
                <span className="creation-time">
                  {voucherDetails[0]?.metadata?.remarks}
                </span>
              </small>
            </div>
          </div>
        </div>
      )}
    </AuthenticatedPage>
  )
}

export default BatchDetails
