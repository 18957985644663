import React from 'react'
import { BaseForm, Input } from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

class ReturnFilters extends BaseForm {
  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    return (
      <Form>
        <div className="form-fields">
          <Input
            type="text"
            label={getMessage('return.filter.referenceNumber')}
            placeholder={getMessage(
              'return.filter.referenceNumber.placeholder'
            )}
            {...this.generateStateMappers({
              stateKeys: ['referenceNumber'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div className="form-actions">
          <SubmitButton>{getMessage('return.filter.submit')}</SubmitButton>
        </div>
      </Form>
    )
  }
}

export default ReturnFilters
