import React from 'react'
import OmniChallengesForm from './Form'
import {
  linkButtons,
  transformFilter,
} from '../../../components/OmniChallenge/utils'
import { tableProperties } from '../../../components/Rewards/Campaigns/utils'
import Filters from '../../../components/Rewards/Filters'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'

const OmniChallenges = (props) => {
  return (
    <ListingPageWithRoutes
      testid="omnichallenges-listing"
      title="Omni Challenges"
      addHeading="Add Challenge"
      editHeading="Edit Challenge"
      menu={props.menu}
      className="rewards-page"
      api={{
        url: '/chendol/challenges',
        transform: (response) => {
          if (response && response.data.list.length > 0) {
            return response.data.list
          }
          return null
        },
      }}
      headerActions={linkButtons}
      form={{
        component: OmniChallengesForm,
      }}
      filters={{
        component: Filters,
        transformFilter: (formData) => transformFilter(formData),
        options: {
          type: 'challenges',
        },
      }}
      tableProperties={tableProperties('OmniChallenges')}
      viewHeading="Challenge Details"
      modalClassName="resend-popup"
      tableDynamic
    />
  )
}

export default OmniChallenges
