import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListingPage, { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import Image from '../../../components/Image'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import BrandForm from './Form'
import BrandFilters from './Filters'
import { getMessage } from '../../../lib/translator'
import './style.css'

import emptyIcon from './brands-empty.svg'
import Toggle from '../../../components/Form/Inputs/Toggle'

const emptyState = {
  icon: emptyIcon,
  message: getMessage('brand.helpItems.message'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      + {getMessage('brand.add.text')}
    </button>
  ),
}

const tableProperties = () => {
  return {
    headers: [
      getMessage('brand.header.logo'),
      getMessage('brand.header.brand'),
      getMessage('brand.header.productCount'),
      getMessage('brand.header.status'),
      getMessage('brand.header.actions'),
    ],
    row: ({ id, name, image, productsCount, status, onAction, slug }) => (
      <Row>
        <Cell>
          <Image size="sm" src={image} bordered />
        </Cell>
        <Cell className="brand-name">
          <span
            className="brand-name-heading"
            onClick={() => {
              onAction(TABLE_ACTIONS.EDIT, { id })
            }}
          >
            {name}
          </span>
        </Cell>
        <Cell className="product-count">
          <span className="text-muted">
            <span className="brand-header-mobileview">{`${getMessage(
              'brand.header.productCount'
            )}: `}</span>
            <small>{`${productsCount}`}</small>
          </span>
        </Cell>
        <Cell className="brand-status">
          <small className="text-muted brand-enable">
            {['ENABLED', 'HIDDEN'].indexOf(status) > -1
              ? getMessage(`brand.status.${status.toLowerCase()}`)
              : status}
          </small>
          <Toggle
            className={`${status} brand-enable-mobileview`}
            name={status}
            value={status === 'ENABLED'}
            icons={false}
            onChange={() => {
              onAction(
                TABLE_ACTIONS.UPDATE,
                { id },
                { status: status === 'ENABLED' ? 'HIDDEN' : 'ENABLED' }
              )
            }}
          />
        </Cell>
        <Cell className="brand-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT, { id })
              }}
            >
              {getMessage('brand.action.edit')}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.DELETE, { id })
              }}
            >
              {getMessage('brand.action.delete')}
            </DropDownItem>
            <DropDownItem>
              <Link to={`/catalogue/products?slugBrand=${slug}`}>
                {getMessage('brand.action.seeProducts')}
              </Link>
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    ),
  }
}

export default class Brands extends Component {
  render() {
    const props = this.props
    return (
      <ListingPage
        menu={props.menu}
        className="brands-page"
        addHeading={getMessage('brand.add.text')}
        editHeading={getMessage('brand.edit.text')}
        title={getMessage('brand.heading')}
        api={{
          url: '/catalogue-service/brand',
          transform: response => response.data.brand,
        }}
        showLanguageDropDown
        emptyState={emptyState}
        tableProperties={tableProperties()}
        headerActions={({ onAction }) => (
          <button
            className="primary button"
            onClick={() => {
              onAction(TABLE_ACTIONS.ADD)
            }}
          >
            + <span className="text">{getMessage('brand.add.text')}</span>
          </button>
        )}
        form={{
          component: BrandForm,
          allowDelete: true,
        }}
        filters={{
          component: BrandFilters,
          transformSubmit: formData => {
            const data = Object.assign({}, formData)
            if (data.brand) {
              data.name = data.brand.name
              delete data.brand
            }
            return data
          },
        }}
      />
    )
  }
}
