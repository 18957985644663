import React from 'react'
import rightArrow from '../Pagination/icon-right-arrow.svg'
import leftArrow from '../Pagination/icon-left-arrow.svg'

const AppHomePagination = ({ data, page, onSelect }) => {
  return (
    <>
      <div className="pagination-button">
        {page > 1 && (
          <a onClick={() => onSelect(Number(page) - 1)}>
            <img className="navigation-icon" src={leftArrow} alt="Previous" />
          </a>
        )}
        <span className="pageNo">{Number(page)}</span>
        {data.pagination.lastEvaluatedSK && (
          <a onClick={() => onSelect(Number(page) + 1)}>
            <img className="navigation-icon" src={rightArrow} alt="Next" />
          </a>
        )}
      </div>
    </>
  )
}
export default AppHomePagination
