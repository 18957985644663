import React, { Component } from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../../containers/ListingPage'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import Tabs from '../../../../components/Tabs'
import { Row, Cell } from '../../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../../components/DropDown'
import SlotForm from './Form'
import Slots from '../../../settings/Extensions/ExtensionDetailsForms/Slots'
// import StoreFilter from './StoreFilter'

import { formatTime } from '../../../../lib/datetime'
import { getMessage } from '../../../../lib/translator'
import { sortSlots } from '../../../../lib/commonlyused'

import EmptyIcon from './empty.svg' // TODO: Change icon

// Converts 01:00 PM to 13:00:00. Military format
const convertToMilitaryTime = (time, meridian) => {
  if (!time || !meridian) {
    return null
  }
  const parts = time.split(':')
  let militaryTime
  if (meridian === 'PM') {
    militaryTime = `${Number(parts[0]) + 12}:${String(parts[1])}:00`
  } else {
    if (Number(parts[0]) === 12) {
      militaryTime = `00:${parts[1]}:00`
    } else {
      militaryTime = `${parts[0]}:${parts[1]}:00`
    }
  }
  return militaryTime
}

const addSecondsToTimeSlots = time => {
  return String(time) + ':00'
}

const emptyState = {
  icon: EmptyIcon,
  message: getMessage('slots.emptymessage'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      + {getMessage('slot.add.text')}
    </button>
  ),
}

const tableProperties = {
  row: ({ id, startTime, endTime, onAction }) => (
    <Row>
      <Cell className="slot-time">
        {startTime
          ? `${formatTime(startTime)}-${formatTime(endTime)}`
          : `${getMessage('slots.asap')} ${getMinutes(endTime)} ${getMessage('slots.asap.minute')}`
        }
      </Cell>
      <Cell>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem
            onClick={() => {
              onAction(TABLE_ACTIONS.DELETE, { id })
            }}
          >
            {getMessage('slots.delete')}
          </DropDownItem>
        </DropDown>
      </Cell>
    </Row>
  ),
}

function getMinutes(his) {
  var a = his.split(':')
  return +a[0] * 60 + +a[1]
}

class SlotConfiguration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      storeId: props.storeId,
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const storeId = newProps.storeId
    if (this.state.storeId !== storeId) {
      this.setState({
        storeId,
      })
    }
  }

  render() {
    const props = this.props
    const params = {
      orderType: props.entity && props.entity.toUpperCase()
    }
    if (this.state.storeId) {
      params.storeId = Number(this.state.storeId)
    }
    return (
      <ListingPage
        addHeading={getMessage('slots.addHeading')}
        api={{
          url: '/order-service/slot',
          transform: response => sortSlots(response.data.slot),
          params: params,
          updateApiParams: (prevParams, newParams) => {
            if (
              prevParams.orderType !== newParams.orderType ||
              prevParams.storeId !== newParams.storeId
            ) {
              let storeId = newParams.storeId
              if (!Number(newParams.storeId)) {
                storeId = null
              }
              return {
                shouldUpdate: true,
                params: {
                  orderType: newParams.orderType,
                  storeId: storeId,
                },
              }
            }
            return null
          },
        }}
        modalClassName="slot-rules-page"
        className="slot-rules-page"
        emptyState={emptyState}
        tableProperties={tableProperties}
        form={{
          component: SlotForm,
          transformSubmit: formData => {
            const slots = Object.assign({}, formData)
            slots.startTime = addSecondsToTimeSlots(slots.startTime)
            slots.endTime = addSecondsToTimeSlots(slots.endTime)
            if (slots.type === 'ASAP') {
              delete slots.startTime
              let h = Math.floor(slots.asap / 60)
              let m = slots.asap % 60
              h = h < 10 ? '0' + h : h
              m = m < 10 ? '0' + m : m
              slots.endTime = `${h}:${m}:00`
            }
            delete slots.asap
            return slots
          },
        }}
        additionalViewsBottom={[Slots]}
        addToTable={({ onAction }) => (
          <button
            className="primary button"
            onClick={() => {
              onAction(TABLE_ACTIONS.ADD)
            }}
          >
            + <span className="text">{getMessage('slot.add.text')}</span>
          </button>
        )}
        addNewItemToLast
      />
    )
  }
}

const delivery = [
  {
    name: 'Delivery',
    component: SlotConfiguration,
  },
]

const pickup = [
  {
    name: 'Pickup',
    component: SlotConfiguration,
  },
]

const entities = delivery.concat(pickup)

class InterfaceContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      storeId: null,
      entity: 'delivery',
    }
    this.mapping = ['delivery', 'pickup']
    this.changeTab = this.changeTab.bind(this)
    this.updateStoreId = this.updateStoreId.bind(this)
  }

  changeTab(index) {
    this.setState({
      index,
      entity: this.mapping[index],
    })
  }

  updateStoreId(storeId) {
    this.setState({
      storeId,
    })
  }

  render() {
    const { index, entity, storeId } = this.state
    const ListingComponent = entities[index].component
    return (
      <div className="slot-page">
        <h1>{getMessage('slots.heading')}</h1>
        <div className="tabs-container">
          <Tabs
            items={entities.map(e => `${e.name}`)}
            default={index}
            onClick={i => {
              this.changeTab(i)
            }}
          />
          <div className="tab-contents">
            <ListingComponent entity={entity} storeId={storeId} />
          </div>
        </div>
      </div>
    )
  }
}

class SlotsContainer extends Component {
  render() {
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <InterfaceContainer />
      </AuthenticatedPage>
    )
  }
}

export default SlotsContainer
export { convertToMilitaryTime, getMinutes }
