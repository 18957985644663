import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getMessage } from '../../../../../lib/translator'
import { getPrintableTime } from '../../../../../lib/datetime'
import preoder from './preorder.svg'
import { get } from '../../../../../lib/storage'
import API from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import { STORE_TECH_BASE_URL } from '../../../../customer-support/constants'
import './style.css'

class PlacementDetails extends Component {
  constructor() {
    super()
    this.state = {
      errorMessage: '',
      loading: false,
    }
    this.handleVerify = this.handleVerify.bind(this)
  }

  handleVerify(sessionID = '') {
    const api = new API({
      url: `${STORE_TECH_BASE_URL}/staff-feedback/v0.3/feedbacks`,
    })
    this.setState({
      loading: true,
    })
    const data = {
      params: {
        sessionID,
        good: true,
        optionIDs: [],
      },
    }
    api
      .post(data)
      .then(
        () => {
          window.location.reload()
          this.setState({
            errorMessage: '',
          })
        },
        error => {
          if (error.code === 401) {
            throw error
          }
          this.setState({
            errorMessage: 'Unable to verify',
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    let orderStatusText = null
    const status = this.props.data.status
    const { metaData } = this.props.data
    const defaultStore = JSON.parse(get('organization') || '{}').defaultStoreId
    const showCancellationMessageFpOn =
      !(metaData && metaData['zs-sap-create-action-done']) &&
      defaultStore &&
      defaultStore !== this.props.storeId

    const sngSessionID = (metaData || {}).sngSessionID
    const { errorMessage } = this.state

    switch (this.props.data.status) {
      case 'PENDING':
      case 'COMPLETED':
        orderStatusText = (
          <span className={`order-status-${status.toLowerCase()}`}>
            {getMessage(`order.status.${status.toLowerCase()}`)}
          </span>
        )
        break
      default:
        if (status) {
          orderStatusText = (
            <span>
              {status.slice(0, 1).toUpperCase() + status.slice(1).toLowerCase()}
            </span>
          )
        }
        break
    }
    const deviceParameters =
      (this.props.data.device && this.props.data.device.split('_')) || []
    let device =
      deviceParameters[0] === 'DESKTOP'
        ? deviceParameters[0]
        : deviceParameters[1]
    switch (device) {
      case 'DESKTOP':
      case 'ANDROID':
      case 'IOS':
        device = getMessage(`order.details.device.${device.toLowerCase()}`)
        break
      default:
        device = getMessage(`order.details.device.${'DESKTOP'.toLowerCase()}`)
        break
    }

    const batchInfo = this.props.data.batchIds === null ? null : this.props.data.batchIds.map((batchId, index) => {
      let separator = ""
      if (index !== this.props.data.batchIds.length - 1) {
        separator = ", "
      }
      if (index !== 0) {
        return <span className="batch-info-reassigned" key="{batchId}"><s><Link to={`../orders?batchId=${batchId}`}>#{batchId}</Link></s>(reassigned){separator}</span>
      } else {
        return <span className="batch-info" key="{batchId}"><Link to={`../orders?batchId=${batchId}`}>#{batchId}</Link>{separator}</span>
      }
    })

    const verificationStatus = this.props.data.verificationStatus
      ? getMessage(
          `order.verificationStatus.${this.props.data.verificationStatus.toLowerCase()}`
        )
      : ''
    return (
      <div className="order-placement-details">
        <div className="section-pre-order">
          <h3 className="section-title">Status: {orderStatusText}</h3>
          {this.props.data.preorder && (
            <div className="pre-order">
              <img src={preoder} alt="pre-order-icon" />
              <span>{getMessage('order.details.preorder')}</span>
            </div>
          )}
        </div>
        {this.props.data.verificationStatus ? (
          <div className="order-placement-info-item flex">
            <span className="order-placement-info-icon icon-shield" />
            <small className="text-muted">
              Verification Status:{' '}
              <span className="creation-time">{verificationStatus}</span>
            </small>
            {![
              getMessage('order.verificationStatus.passed'),
              'Verified',
            ].includes(verificationStatus) && (
              <div className="button-container">
                {this.state.loading ? (
                  <Loader size="sm" />
                ) : (
                  <button
                    className="button"
                    onClick={() => this.handleVerify(sngSessionID)}
                  >
                    Verify
                  </button>
                )}
                <span className="error">{errorMessage}</span>
              </div>
            )}
          </div>
        ) : null}
        {this.props.data.batchIds && (
          <div className="order-placement-info-item">
            <span className="order-placement-info-icon batch-icon" />
            <small className="text-muted">
            Batch ID:{' '}
              {batchInfo}
            </small>
          </div>
        )}
        <div className="order-placement-info-item">
          <span className="order-placement-info-icon icon-clock" />
          <small className="text-muted">
            Placed Time:{' '}
            <span className="creation-time">
              {getPrintableTime(this.props.data.creationTime)}
            </span>
          </small>
        </div>
        {this.props.data.completionTime ? (
          <div className="order-placement-info-item">
            <span className="order-placement-info-icon icon-clock" />
            <small className="text-muted">
              Completion Time:{' '}
              <span className="completion-time">
                {getPrintableTime(this.props.data.completionTime)}
              </span>
            </small>
          </div>
        ) : null}
        <div className="order-placement-info-item">
          <span className="order-placement-info-icon icon-mobile" />
          <small className="text-muted">
            Placed From: <span className="device-name">{device}</span>
          </small>
        </div>

        <div className="order-placement-info-item">
          <div className={`text-muted ${showCancellationMessageFpOn ? '' : 'red-note'}`}>
            Note:
            {showCancellationMessageFpOn ? (
              <b> {getMessage(`order.log.fponNote`)}</b>
            ) : (
              <b> {getMessage(`order.log.sapNote`)}</b>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default PlacementDetails
