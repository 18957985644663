import React, { Component } from 'react'
import './style.css'
import KeyInfo from './KeyInfo'
import OrderSummary from './OrderSummary'
import OrderLog from './OrderLog'
import CustomerDetails from '../../../../components/CustomerDetails'
import { getMessage } from '../../../../lib/translator'
import API from '../../../../lib/api'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import Loader from '../../../../components/Loader'
import PaymentDetails from './PaymentDetails'
import LinkPointDetails from './LinkPointDetails'
import DeliveryDetails from './DeliveryDetails'
import { QC_BE_GATEWAY } from '../../../../config/app'
class OrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      failed: false,
      orderDetails: {},
      customerDetails: {},
      refundDetails: {},
    }
    this.getData = this.getData.bind(this)
  }
  getError(error) {
    this.setState({ failed: true })
    if (error.code === 401) {
      throw error
    }
  }
  getCustomerData(customerID) {
    this.customerDetailsApi = new API({
      url: `/ef-customer-core/profile/${customerID}?fullresponse=true`,
    })
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        this.getCustomerDetailsApi().then(() => {
          this.setState({ loading: false })
        })
      }
    )
  }
  getRefundActionData(orderId) {
    this.refundActionApi = new API({
      url: `${QC_BE_GATEWAY}/orders/${orderId}/actions`,
    })
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        this.getRefundActionDetailsApi().then(() => {
          this.setState({ loading: false })
        })
      }
    )
  }
  getCustomerDetailsApi() {
    return this.customerDetailsApi.get().then(
      response => {
        const customer = response
        this.setState({
          failed: false,
          customerDetails: customer.data,
        })
      },
      error => {
        this.getError(error)
      }
    )
  }
  getRefundActionDetailsApi() {
    return this.refundActionApi.get().then(
      response => {
        const refunds = response
        this.setState({
          failed: false,
          refundDetails: refunds.data,
        })
      },
      error => {
        this.getError(error)
      }
    )
  }
  getOrderDetailsApi() {
    return this.orderDetailsApi.get().then(
      response => {
        const order = response.data.items[0] || response.data.items
        this.setState(() => ({
          failed: false,
          orderDetails: order,
        }))
        this.getRefundActionData(order.id)
        this.getCustomerData(this.props.router.match.params.customerId)
      },
      error => {
        this.getError(error)
      }
    )
  }
  getData() {
    const referenceNumber = this.props.router.match.params.reference
    const customerId = this.props.router.match.params.customerId
    this.orderDetailsApi = new API({
      url: `${QC_BE_GATEWAY}/orders?customerId=${customerId}&orderReference=${referenceNumber}`,
    })
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        this.getOrderDetailsApi().then(() => {
          this.setState({ loading: false })
        })
      }
    )
  }

  UNSAFE_componentWillMount() {
    this.getData()
  }
  render() {
    return (
      <AuthenticatedPage
        storeDependent
        className="order-details-qc"
        menu={this.props.menu}
        title="order"
      >
        {this.state.loading ? (
          <Loader />
        ) : this.state.failed ? (
          <div className="retry-message text-muted" onClick={this.getData}>
            {getMessage('order.details.errors.onLoad')}
          </div>
        ) : (
          <React.Fragment>
            <h1>
              {getMessage('qc.orderInfo.Order')}
              <span className="order-id">
                {this.state.orderDetails.reference}
              </span>
            </h1>
            <div className="odp-container">
              <div className="delivery-info">
                <KeyInfo
                  {...this.props}
                  orderId={this.state.orderDetails.reference + ''}
                  masterStatus={this.state.orderDetails.state}
                  placedTime={this.state.orderDetails.createdAt}
                  platform={this.state.orderDetails.platform}
                  fulfillmentMethod={this.state.orderDetails.fulfillmentMethod}
                  originalDeliveryTime={this.state.orderDetails.deliveryAt}
                  originalPickupTime={this.state.orderDetails.pickupAt}
                />
                {this.state.customerDetails && (
                  <CustomerDetails
                    id={this.state.customerDetails.id}
                    name={this.state.customerDetails.name}
                    mobile={this.state.customerDetails.phone}
                    email={this.state.customerDetails.email}
                    address={
                      this.state.customerDetails.addresses
                        ? this.state.customerDetails.addresses[0]
                        : null
                    }
                  />
                )}
              </div>
              <OrderSummary
                data={this.state.orderDetails}
                refundDetails={this.state.refundDetails}
                {...this.props}
              />
              {this.state.orderDetails.payments && (
                <PaymentDetails
                  data={this.state.orderDetails}
                  {...this.props}
                />
              )}
              {this.state.orderDetails.meta &&
                this.state.orderDetails.customer && (
                  <LinkPointDetails
                    data={this.state.orderDetails}
                    {...this.props}
                  />
                )}
              {this.state.orderDetails.fulfillmentMethod === 'deliveries' && (
                <DeliveryDetails
                  data={this.state.orderDetails}
                  {...this.props}
                />
              )}
              <OrderLog {...this.props} id={this.state.orderDetails.id} />
            </div>
          </React.Fragment>
        )}
      </AuthenticatedPage>
    )
  }
}

export default OrderDetails
