import React from 'react'
import { withRouter } from 'react-router-dom'
import BatchUploadsJobDetails from './BatchJobDetails/index'
import BatchUploadsStatusList from './BatchUploadsStatusList/index'

const BatchUploadStatus = props => {
  const { router } = props
  if(router.match.params.id) {
    return<BatchUploadsJobDetails router={router} {...props}/>
  } else {
    return <BatchUploadsStatusList router={router} {...props}/>
  }
}

export default withRouter(({ match, ...props }) => (
  <BatchUploadStatus router={{ match }} {...props} />
))
