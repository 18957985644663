import React from 'react'
import { Checkbox, Input, VALIDATION_TYPES } from '../../../Form'
import { getMessage } from '../../../../lib/translator'
import { CHANNEL_OPTIONS } from '../../../Rewards/Campaigns/utils'
import './style.css'

const Channels = (props) => {
  const { _this } = props
  const { campaignStarted } = _this.state
  const values = Object.assign({}, _this.state.values)
  const channels = Object.assign({}, values.channels)

  return (
    <div className="single-column-field normal-height-field">
      <div className="tab-header">{getMessage('luckydraw.channel-header')}</div>
      <div className="normal-text mb-1">{getMessage('luckydraw.channel')}</div>
      <div className="channel-field-container">
        {(!('channels' in values) || !Object.values(channels).includes(true)) &&
          _this.state.verifyingData && (
            <div className="error">
              {getMessage('luckydraw.channel-validate-error')}
            </div>
          )}
        {CHANNEL_OPTIONS.map((channel, i) => (
          <div className="channel-field" key={i}>
            <div className="channel-checkbox">
              <Checkbox
                inlineLabel={channel.text}
                name={channel.text}
                disabled={campaignStarted}
                {..._this.generateStateMappers({
                  stateKeys: ['channels', channel.value],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <Input
              disabled={!channels[channel.value] || campaignStarted}
              required={channels[channel.value]}
              name={channel.text}
              type="number"
              prefix="$"
              step="0.01"
              min={0.01}
              max={1000000}
              {..._this.generateStateMappers({
                stateKeys: ['channelInfoMap', channel.value, 'amountPerChance'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Channels
