import React from 'react'
import Searchable from '../Searchable'

export default function StoreSearch(props) {
  const newProps = Object.assign({}, props, {
    searchUrl: '/martech-ad-service/search/stores',
    valueKey: 'storeLabel',
    nameKey: 'storeDescription', //the value in droplist
    searchKey: 'storeLabel', // for store API param
    expandMore: true,
    displaySelectedValue: (store) => {
      return store ? store.storeLabel : ''
    },
    transformRequest: (searchText, paramsDefault) => {
      return Object.assign({}, paramsDefault)
    },
    transformResponse: (response) => {
      const _rep = response.data.content
      for (let i = 0; i < _rep.length; i++) {
        _rep[i].name = _rep[i].storeLabel
      }
      return (props.appendElement ? props.appendElement : []).concat(_rep) || []
    },
  })
  return <Searchable {...newProps} />
}
