import React from 'react'
import moment from 'moment'
import { BaseForm, Checkbox } from '../../../../../../components/Form'
import Loader from '../../../../../../components/Loader'
import { getMessage } from '../../../../../../lib/translator'
import API from '../../../../../../lib/api'

export default class CompleteOrderForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: {
        notifyCustomer: true,
      },
    }
  }

  onSubmit(formData) {
    this.setState({
      loading: true,
    })
    const params = {
      ...formData,
      completeTime: moment().toISOString(),
    }
    const referenceNumber = this.props.data.referenceNumber
    const api = new API({
      url: `/order-service/v3/deliveryOrders/${referenceNumber}/complete`,
    })
    return api.post(params, {}).then(() => {
      this.props.updateCompleted()
    })
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    return this.state.loading || !this.props.data ? (
      <Loader />
    ) : (
      <Form className="complete-order-form">
        <div>
          <Checkbox
            name="notify-customer"
            label={getMessage(
              'deliveryOrder.details.action.notifyCustomer.title'
            )}
            inlineLabel={getMessage(
              'deliveryOrder.details.action.notifyCustomer.text'
            )}
            {...this.generateStateMappers({
              stateKeys: ['notifyCustomer'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div className={`form-actions`}>
          <CancelButton>
            {getMessage('deliveryOrder.details.action.cancel')}
          </CancelButton>
          <SubmitButton testid="complete-order-submit">
            {getMessage('deliveryOrder.details.action.save')}
          </SubmitButton>
        </div>
      </Form>
    )
  }
}
