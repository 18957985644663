import React from 'react'
import { ActionTrailContextProvider } from './ActionTrailContext'
import { ActionTrailTable } from './Table'
import './style.css'

const ActionTrail = (props) => {
  return (
    <ActionTrailContextProvider>
      <ActionTrailTable {...props} />
    </ActionTrailContextProvider>
  )
}

export default ActionTrail
