import React from 'react'
import { SelectSearch, MultiSelect, VALIDATION_TYPES } from '../Form'
import { getMessage } from '../../lib/translator'
import { get } from '../../lib/storage'

export default function SelectStoresSearch(props) {
  const {
    _this,
    stores,
    stateKey,
    label,
    placeholder,
    secondaryLabel,
    readOnly,
    dontDisplaySelected,
    required,
    groupFilter,
    multiple = true,
    className,
    searchById = false,
  } = props
  const formattedStores = stores.map((store) => {
    return {
      text: store.name,
      value: store.id,
    }
  })

  if (stores.length < 5) {
    return (
      <MultiSelect
        label={label || getMessage('shifts.stores')}
        name="belongsToStores"
        placeholder={placeholder || getMessage('shifts.stores.placeholder')}
        options={formattedStores}
        {..._this.generateStateMappers({
          stateKeys: [stateKey],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
        required={required}
        readOnly={readOnly}
        dontDisplaySelected={dontDisplaySelected}
        secondaryLabel={secondaryLabel}
        searchByName
        className={className}
      />
    )
  } else if (stores.length >= 5) {
    const storeTypes = groupStoresByType(stores)
    return (
      <SelectSearch
        name="search-store"
        required={required}
        readOnly={readOnly}
        dontDisplaySelected={dontDisplaySelected}
        label={label || getMessage('shifts.stores')}
        placeholder={placeholder || getMessage('shifts.stores.placeholder')}
        multiple={multiple}
        options={formattedStores}
        nameKey="text"
        valueKey="value"
        searchByName
        searchById={searchById}
        className={className}
        secondaryLabel={secondaryLabel}
        {..._this.generateStateMappers({
          stateKeys: [stateKey],
          loseEmphasisOnFill: true,
        })}
        onChange={(selectedValue) => {
          selectedValue =
            selectedValue instanceof Array ? selectedValue : [selectedValue]
          let values = JSON.parse(JSON.stringify(_this.state.values))
          values = {
            ...values,
            [stateKey]: selectedValue.map((el) => el.value),
          }
          _this.setState({ values })
        }}
        value={(_this.state.values[stateKey] || [])
          .map((id) => formattedStores.find((opt) => opt.value === id))
          .filter(Boolean)}
        filter={() =>
          groupFilter ? (
            <div className="store-types">
              {Object.keys(storeTypes)
                .filter((key) => storeTypes[key])
                .map((key) => {
                  return (
                    <span
                      key={key}
                      onClick={() => {
                        addByGroup(props, storeTypes[key])
                      }}
                    >
                      {`+ ${key.toUpperCase()}`}
                    </span>
                  )
                })}
            </div>
          ) : null
        }
      />
    )
  }
  return null
}

function addByGroup(props, newValue) {
  const { _this, stateKey } = props
  const currentValues = JSON.parse(JSON.stringify(_this.state.values))
  const values = {
    ...currentValues,
    [stateKey]: Array.from(
      new Set((currentValues.storeId || []).concat(newValue))
    ),
  }
  _this.setState({ values })
}

function groupStoresByType(stores) {
  const defaultStore = JSON.parse(get('organization') || '{}').defaultStoreId

  const data = stores.reduce((acc, item) => {
    // S&G
    if (item.hasSelfCheckout && item.metaData['Is SnG Store']) {
      return { ...acc, 's&g': (acc['s&g'] || []).concat(item.id) }
    }

    // OCCL
    if (item.hasPicking && item.name.includes('(DS)')) {
      return { ...acc, occl: (acc.occl || []).concat(item.id) }
    }

    // C&C
    if (item.hasPicking && item.name.includes('(CNC)')) {
      return { ...acc, 'c&c': (acc['c&c'] || []).concat(item.id) }
    }

    // FFS
    if (item.hasPicking && item.name.includes('(FFS)')) {
      return { ...acc, ffs: (acc.ffs || []).concat(item.id) }
    }

    // PFC
    if (item.id === defaultStore) {
      return { ...acc, pfc: (acc.pfc || []).concat(item.id) }
    }

    return acc
  }, {})

  return {
    pfc: data.pfc,
    ffs: data.ffs,
    occl: data.occl,
    'c&c': data['c&c'],
    's&g': data['s&g'],
  }
}
