import React from 'react'
import { Textarea, Radio, Select, VALIDATION_TYPES } from '../../Form'
import { getMessage } from '../../../lib/translator'
import TaskDetail2 from './TaskDetail2'
import moment from 'moment'

const typeOptions = [
  {
    text: 'Transaction',
    value: 'TRANSACTION',
  },
]

const userActions = [
  {
    text: 'CDG Monthly Spend',
    value: 'CDG_MONTHLY_SPEND',
  },
  {
    text: 'Single order',
    value: 'SINGLE_ORDER',
  },
  {
    text: 'Multiple order',
    value: 'MULTIPLE_ORDER',
  },
  {
    text: 'Accumulated spend',
    value: 'CUMULATIVE_SPEND',
  },
  {
    text: 'Challenge completed',
    value: 'COMPLETE_CHALLENGE',
  },
]

const TaskDetail = ({ _this, method, task, taskIndex }) => {
  const handleChange = (e, target) => {
    target.userAction = e
    const validations = Object.assign({}, _this.state.validations)
    target.rule && delete target.rule
    delete validations.taskList[taskIndex].rule
  }

  return (
    <div className="task-details">
      <Textarea
        required
        testid={`task-title-${taskIndex + 1}`}
        label={getMessage('omnichallenge.task.title')}
        placeholder={getMessage('omnichallenge.task.placeholder')}
        type="text"
        {..._this.generateStateMappers({
          stateKeys: ['taskList', taskIndex, 'title'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
      />
      <div className="task-detail-sec2">
        <Radio
          label={getMessage('omnichallenge.task.type')}
          name="task-type"
          className="task-type mr-2 mobile-768"
          options={typeOptions}
          {..._this.generateStateMappers({
            stateKeys: ['taskList', taskIndex, 'type'],
            loseEmphasisOnFill: true,
            defaultValue: 'TRANSACTION',
          })}
          disabled={
            method === 'edit' && moment().isAfter(_this.state.values.startAt)
          }
        />
        <Select
          required
          name="task-useraction"
          data-testid={`useraction ${taskIndex + 1}`}
          className="task-useraction mobile-768"
          label={getMessage('omnichallenge.task.useraction')}
          placeholder={getMessage('omnichallenge.task.useraction.placeholder')}
          options={userActions}
          {..._this.generateStateMappers({
            stateKeys: ['taskList', taskIndex, 'userAction'],
            loseEmphasisOnFill: true,
          })}
          disabled={
            method === 'edit' && moment().isAfter(_this.state.values.startAt)
          }
          onChange={(e) => handleChange(e, task)}
        />
      </div>
      {task.userAction && (
        <TaskDetail2
          _this={_this}
          task={task}
          taskIndex={taskIndex}
          method={method}
        />
      )}
    </div>
  )
}

export default TaskDetail
