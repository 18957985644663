import Button from 'components.new/button/Button'
import { Popup } from 'components/Popup'
import React from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import './style.scss'

class LeavePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { initState: {} }
  }
  leavePage = (isActive, onCancel, onConfirm) => {
    const message1 = (
      <text>
        <b>Do you want to switch to another page?</b>
      </text>
    )
    const message = 'By doing so your current changes will get discarded.'
     return <ConfirmLeavePageModal
        isOpen={isActive}
        okHandler={onConfirm}
        cancelHandler={onCancel}
        message={message}
        message1={message1}
      />
  }
  render() {

    return (
      <div>
        <div>
          <NavigationPrompt
            when={(crntLocation, nextLocation, _action) =>
              !nextLocation ||
              !nextLocation.pathname.startsWith(crntLocation.pathname)
            }
          >
            {({ isActive, onCancel, onConfirm }) => {
              return this.leavePage(isActive, onCancel, onConfirm)
            }}
          </NavigationPrompt>
        </div>
      </div>
    )
  }
}

export default LeavePage

export class ConfirmLeavePageModal extends React.Component {
  render() {
    const {isOpen,okHandler,cancelHandler}=this.props
    return (
      <>
        <Popup
          className="publishPopup dfc"
          heading={"Save Campaign"}
          show={isOpen}
          close={cancelHandler}
        >
          You have made some changes on the campaign, do you want to leave this page?
          <div className="mt-32" >
            <button
              className="button"
              onClick={okHandler}
            >
              Leave without saving changes
            </button>
            <Button
              className="primary button mr-8"
              onClick={cancelHandler}
            >
              Save Changes
            </Button>
            <br />
          </div>
        </Popup>
      </>
    )
  }
}
