import React, { Component, useContext } from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { Row, Cell } from '../../../components/Table'
import { getMessage } from '../../../lib/translator'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import CampaignFiltersForm from './Filters'
import DefineFestiveCampaign from '../DefineFestiveCampaign'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'

export const emptyState = {
  actions: () => (
    <Link to={`/preorders/define-festive-campaigns/add`}>
      <button className="primary button">
        <span className="text festiveCampaign">
          + {getMessage('preorder.definefestivecampaign.heading')}
        </span>
      </button>
    </Link>
  ),
}

export const tableProperties = () => {
  return {
    headers: [
      getMessage('preorder.header.id'),
      getMessage('preorder.header.campaign'),
      getMessage('preorder.header.startdate'),
      getMessage('preorder.header.enddate'),
      getMessage('preorder.header.offerid'),
      getMessage('preorder.header.status'),
    ],
    row: function CategoryRow({
      id,
      campaign_title,
      order_start_date,
      order_end_date,
      offer_id,
      campaign_status_code,
    }) {
      const startDate = order_start_date
        ? moment(new Date(order_start_date.substr(0, 16)))
        : null
      const orderStartDate =
        startDate != null ? startDate.format('YYYY-MM-DD') : '-'
      const endDate = order_end_date
        ? moment(new Date(order_end_date.substr(0, 16)))
        : null
      const orderEndDate = endDate != null ? endDate.format('YYYY-MM-DD') : '-'
      const status =
        campaign_status_code === 1
          ? 'Inactive'
          : campaign_status_code === 2
          ? 'Active'
          : 'Scheduled'

      return (
        <Row>
          <Cell>
            <span size="sm" className="category-id">
              {id}
            </span>
          </Cell>
          <Cell className="campaignDeli">
            <Link to={`/preorders/define-festive-campaigns/edit/${id}`}>
              {campaign_title ? campaign_title : "Untitled"}
            </Link>
          </Cell>
          <Cell className="parent-category">
            <span className="text-muted">
              <span className="text-category-name">{orderStartDate}</span>
            </span>
          </Cell>
          <Cell className="product-count">
            <span className="text-muted">
              <span className="category-productcount-mobileview">
                {orderEndDate}
              </span>
            </span>
          </Cell>
          <Cell className="category-status">
            <small className="text-muted status">
              {!offer_id ? '-' : offer_id}
            </small>
          </Cell>
          <Cell className="category-status">
            <small className={status === 'Inactive' ? 'text-muted' : 'green'}>
              {status}
            </small>
          </Cell>
        </Row>
      )
    },
  }
}

export class Campaign extends Component {
  render() {
    const isPreOrderEnabled =
      this.props.splits?.[SPLIT_FEATURES.PRE_ORDER]?.treatment === 'on'
    const props = this.props
    return (
      <React.Fragment>
        <ListingPageWithRoutes
          menu={props.menu}
          className="preorder-page"
          testid="campaign-listing"
          addHeading={getMessage('category.form.add.heading')}
          editHeading={getMessage('category.form.edit.heading')}
          title={
            isPreOrderEnabled
              ? getMessage('preorder.heading')
              : 'Feature Not Enabled'
          }
          api={isPreOrderEnabled &&{
            url: '/campaigns/',
            params: {
              sort: `created_at desc`,
            },
            transform: response => response.data.campaigns,
          }}
          emptyState={emptyState}
          headerActions={() =>
            isPreOrderEnabled && (
              <Link to={`/preorders/define-festive-campaigns/add`}>
                <button className="primary button">
                  <span className="text festiveCampaign">
                    + {getMessage('preorder.definefestivecampaign.heading')}
                  </span>
                </button>
              </Link>
            )
          }
          form={{
            component: DefineFestiveCampaign,
          }}
          filters={{
            component: isPreOrderEnabled ? CampaignFiltersForm : null,
            forceShow: true,
            transformSubmit: formData => {
               const { campaigns, ...data } = { ...formData }
               campaigns&& (data.name = campaigns.name)
               return data
            },
          }}
          noSearch
          tableProperties={isPreOrderEnabled ? tableProperties() : {}}
        />
      </React.Fragment>
    )
  }
}

const CampaignWrapper = props => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return <Campaign splits={splits} {...props} />
}

export default withRouter(({ location, history, match, ...props }) => (
  <CampaignWrapper router={{ location, history, match }} {...props} />
))
