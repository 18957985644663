import React from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
} from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'

class AddEmailForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      submitting: false,
      formError: '',
    }
    this.response = ''
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(_data) {}

  onSubmit(data) {
    this.setState(
      {
        submitting: true,
      },
      () => {
        this.handleSubmit(data).then(() => {
          this.setState({
            submitting: false,
          })
          if (this.state.formError === '') {
            this.props.onSuccess('addedEmail', this.response)
          }
        })
      }
    )
  }
  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    return (
      <div className="addEmailForm">
        <Form>
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <Input
            label={getMessage('customer.details.email.email')}
            type="email"
            name="email"
            required
            {...this.generateStateMappers({
              stateKeys: ['email'],
              validationType: VALIDATION_TYPES.ONCHANGE,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
              typeMismatch: getMessage('input.invalidEmailFormat'),
            }}
          />
          <div className="form-buttons-container">
            <SubmitButton disabled={this.state.submitting}>
              {getMessage('customer.details.submitText.submit')}
            </SubmitButton>
          </div>
        </Form>
      </div>
    )
  }
}
export default AddEmailForm
