import React from 'react'

import { getMessage } from '../../../../lib/translator'

import { Link } from 'react-router-dom'

export default function EmptyCreative() {
  return (
    <div className="center">
      <h3>{getMessage('media.creative.add.empty')}</h3>
      <Link to={`/media-monetization/media-creativemanagement/media-creative-detail`}>
        {getMessage('media.creative.add.empty.submessage')}
      </Link>
    </div>
  )
}
