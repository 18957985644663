import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { Row, Cell } from '../../Table'
import { DropDown, DropDownItem, ICONS } from '../../DropDown'
import { getMessage } from '../../../lib/translator'
import moment from 'moment'
import { Dialog } from '../../Popup'
import API from '../../../lib/api'
import { DATE_TIME_FORMAT } from '../utils'

export const TableAction = (props) => {
  const { startAt, id, onAction, isEnabled, type } = props
  const [statusToggleConfirmation, setStatusToggleConfirmation] =
    useState(false)
  const toggleStatus = () => {
    const api = new API({
      url:
        type === 'OmniChallenges'
          ? `/chendol/challenges/${id}`
          : `/duck/campaigns/${id}`,
    })

    return api
      .patch({ isEnabled: false })
      .then(() => {
        onAction(TABLE_ACTIONS.EDIT, { id }, { isEnabled: false })
      })
      .then(() => {
        setStatusToggleConfirmation(false)
      })
      .catch((error) => {
        if (error.code === 401) {
          throw error
        }
        return null
      })
  }
  return (
    <div>
      <Dialog
        show={statusToggleConfirmation}
        title={getMessage('rewardPage.dialogs.disable.title')}
        information={getMessage('rewardPage.dialogs.disable.message')}
        onOk={toggleStatus}
        close={() => setStatusToggleConfirmation(false)}
        closeText={getMessage('rewardPage.dialogs.disable.cancelText')}
        okText={getMessage('rewardPage.dialogs.disable.okText')}
      />
      <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
        <DropDownItem>
          <Link
            to={
              type === 'OmniChallenges'
                ? `/rewards/challenges/edit/${id}`
                : `/marketing/lucky-draws/edit/${id}`
            }
          >
            {getMessage('rewardPage.edit')}
          </Link>
        </DropDownItem>
        {moment().isBefore(startAt) && isEnabled && (
          <DropDownItem
            onClick={() => {
              setStatusToggleConfirmation(true)
            }}
          >
            {getMessage('rewardPage.disable')}
          </DropDownItem>
        )}
      </DropDown>
    </div>
  )
}

export const tableProperties = (type) => {
  return {
    headers: [
      'ID',
      'Campaign Name',
      'Type',
      'Start Date',
      'End Date',
      'Status',
      'Actions',
    ],
    row: ({
      id,
      name,
      type: challengeType,
      startAt,
      endAt,
      isEnabled,
      onAction,
    }) => (
      <Row>
        <Cell className="rewards-page-actions column-id">
          <Link
            to={
              type === 'OmniChallenges'
                ? `/rewards/challenges/edit/${id}`
                : `/marketing/lucky-draws/edit/${id}`
            }
          >
            {id}
          </Link>
        </Cell>
        <Cell className="rewards-page-actions column-name">
          <Link
            to={
              type === 'OmniChallenges'
                ? `/rewards/challenges/edit/${id}`
                : `/marketing/lucky-draws/edit/${id}`
            }
          >
            {name}
          </Link>
        </Cell>
        <Cell className="rewards-page-actions column-type">
          {challengeType}
        </Cell>
        <Cell className="rewards-page-actions column-start-date">
          {moment(startAt).format(DATE_TIME_FORMAT)}
        </Cell>
        <Cell className="rewards-page-actions column-end-date">
          {moment(endAt).format(DATE_TIME_FORMAT)}
        </Cell>
        <Cell className="rewards-page-actions column-campaign-status">
          {isEnabled
            ? getMessage('rewardPage.enabled')
            : getMessage('rewardPage.disabled')}
        </Cell>
        <Cell className="rewards-page-actions column-actions">
          <TableAction
            startAt={startAt}
            endAt={endAt}
            id={id}
            onAction={onAction}
            isEnabled={isEnabled}
            type={type}
          />
        </Cell>
      </Row>
    ),
  }
}

export const CHANNEL_OPTIONS = [
  {
    text: getMessage('rewards.channel.preOrderOnline'),
    value: 'RB_PREORDER_ONLINE',
  },
  {
    text: getMessage('rewards.channel.Cheers.linkcard'),
    value: 'LINK_CARD_CHEERS',
  },
  {
    text: getMessage('rewards.channel.Cheers.autonomous'),
    value: 'LINKPAY_PAY_CHEERS_AUTONOMOUS',
  },
  {
    text: getMessage('rewards.channel.Cheers.linkpay'),
    value: 'LINKPAY_PAY_CHEERS',
  },
  {
    text: getMessage('rewards.channel.Cheers.linkpayLoyalty'),
    value: 'LINKPAY_LOYALTY_CHEERS',
  },
  {
    text: getMessage('rewards.channel.fairPrice.linkCard'),
    value: 'LINK_CARD_FAIRPRICE',
  },
  {
    text: getMessage('rewards.channel.fairPrice.linkPay'),
    value: 'LINKPAY_PAY_FAIRPRICE',
  },
  {
    text: getMessage('rewards.channel.fairPrice.linkPayLoyalty'),
    value: 'LINKPAY_LOYALTY_FAIRPRICE',
  },
  {
    text: getMessage('rewards.channel.fairPrice.online'),
    value: 'FPON',
  },
  {
    text: getMessage('rewards.channel.fairPrice.ScanNGo'),
    value: 'SCANGO',
  },
  {
    text: getMessage('rewards.channel.kopitiam.ClickAndCollect'),
    value: 'O2O_FOOD',
  },
  {
    text: getMessage('rewards.channel.kopitiam.linkpay'),
    value: 'LINKPAY_PAY_KOPITIAM',
  },
  {
    text: getMessage('rewards.channel.unity.linkcard'),
    value: 'LINK_CARD_UNITY',
  },
  {
    text: getMessage('rewards.channel.unity.linkpay'),
    value: 'LINKPAY_PAY_UNITY',
  },
  {
    text: getMessage('rewards.channel.unity.linkpayLoyalty'),
    value: 'LINKPAY_LOYALTY_UNITY',
  },
  {
    text: getMessage('rewards.channel.others.linkpayLoyalty'),
    value: 'LINKPAY_LOYALTY_OTHERS',
  },
]
