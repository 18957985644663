import React, { useReducer } from 'react'
import './style.scss'

import CustomerDetailsCard from 'components/CustomerDetailsCard'
import { useGetUserObject } from './GetUserObjectHook'
import { prettier } from '../../json-prettifier'

const BasicInfo = () => {
  return (
    <div className="tab-display-info">
      <p>This user does not have a fairprice account.</p>
      <p>
        This user signed up through another application instead of fairprice
        group
      </p>
    </div>
  )
}

const linkpassProfileSectionIdentifier = 'linkpass-profile'

export const Developer = ({ infoProps }) => {
  const [section, setSection] = React.useState(linkpassProfileSectionIdentifier)
  const handleSwitchSection = (sectionType) => {
    setSection(sectionType)
    const elem = document.getElementById('tab-display-info')
    elem.innerHTML = prettier.prettyPrint(getSectionInfo(sectionType))
  }
  const getSectionInfo = (sectionType) => {
    switch (sectionType) {
      case linkpassProfileSectionIdentifier:
      case 'auth0-profile':
      case 'link-profile':
        return infoProps[sectionType]
      default:
        return ''
    }
  }

  React.useEffect(() => {
    handleSwitchSection(section)
  }, [infoProps])

  return (
    <div className="developer-tab-wrapper">
      <div className="buttonRow">
        <button
          className="button"
          onClick={() => handleSwitchSection(linkpassProfileSectionIdentifier)}
        >
          Linkpass Profile
        </button>
        <button
          className="button"
          onClick={() => handleSwitchSection('auth0-profile')}
        >
          Auth0 Profile
        </button>
        <button
          className="button"
          onClick={() => handleSwitchSection('link-profile')}
        >
          Link Profile
        </button>
      </div>
      <pre className="tab-display-info" id="tab-display-info"></pre>
    </div>
  )
}

const detailTabReducer = (state, action) => {
  switch (action.type) {
    case 'basic_info':
      return {
        tabType: 'basic_info',
        Tab: BasicInfo,
      }
    case 'developer':
      return {
        tabType: 'developer',
        Tab: Developer,
      }
    default:
      return {}
  }
}

const LinkpassOnlyUser = (props) => {
  const [state, dispatch] = useReducer(detailTabReducer, {
    tabType: 'basic_info',
    Tab: BasicInfo,
  })

  const userObject = useGetUserObject(props.uid)

  const TabDisplay = state.Tab
  const handleToggleTab = (type) => {
    dispatch({ type })
  }

  return (
    <div>
      <h1 className="title">Customer name</h1>
      <div className="parentTab">
        <div className="detailsTab">
          <div className="buttonRow">
            <button
              className="button"
              onClick={() => handleToggleTab('basic_info')}
            >
              Basic info
            </button>
            {props.hasLinkpassAdminRole && (
              <button
                className="button"
                onClick={() => handleToggleTab('developer')}
              >
                developer
              </button>
            )}
          </div>
          <TabDisplay
            infoProps={state.tabType === 'basic_info' ? '' : userObject}
          />
        </div>
        <div>
          <CustomerDetailsCard
            image={''}
            email={userObject[linkpassProfileSectionIdentifier]['email']}
            phone={userObject[linkpassProfileSectionIdentifier]['phone_number']}
            uid={props.uid}
            name={userObject[linkpassProfileSectionIdentifier]['name']}
            id={''}
            showDeleteLinkpassButton={props.showDeleteLinkpassButton}
          />
        </div>
      </div>
    </div>
  )
}

export default LinkpassOnlyUser
