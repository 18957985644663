import React, { Component } from 'react'
import NewListingPage, {
  TABLE_ACTIONS,
} from '../../../../containers/NewListingPage'
import StoreSelector from '../../../../containers/StoreSelector'
import { getMessage } from '../../../../lib/translator'
import OrderFilters, { multiSelectFilters } from './Filters'
import OrderSearchForm from './Search'
import { qcTabularView } from './Views'
import { Link } from 'react-router-dom'
import API from '../../../../lib/api'
import { isExtensionEnabled } from '../../../../lib/auth'
import emptyIcon from '../../../../icons/no-orders.svg'
import Loader from '../../../../components/Loader'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage/index'

import './style.css'

function CreateNewOrder() {
  return (
    <div className="text-center">
      <Link to="/operations/orders/add" className="primary button">
        + <span className="text">{getMessage('order.add.text')}</span>
      </Link>
    </div>
  )
}

const emptyState = props => {
  return {
    icon: emptyIcon,
    message: getMessage('order.emptyList.message'),
    actions: ({ onAction, apiParams }) => (
      <React.Fragment>
        <CreateNewOrder />
        {isExtensionEnabled('MultiStoreSupport') && (
          <StoreSelector
            value={apiParams.storeId}
            stores={props.stores}
            onChange={storeId => {
              onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId })
            }}
          />
        )}
      </React.Fragment>
    ),
  }
}

const { Provider, Consumer } = React.createContext('commConfig')

const OrdersContainer = class Orders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterApiData: {},
    }
  }
  componentDidMount() {
    const brandsLimit = {
      limit: -1,
    }
    const locationsLimit = {
      limit: -1,
    }
    const brandsApi = new API({ url: `/brands` })
    const locationsApi = new API({ url: `/locations` })
    const foodsuppliersApi = new API({ url: `/foodsuppliers/names` })

    Promise.all([
      brandsApi.get(brandsLimit),
      locationsApi.get(locationsLimit),
      foodsuppliersApi.get(),
    ]).then(([brandRes, locationsRes, foodsuppliersRes]) => {
      const filterIdToNameDict = new Map()
      const brands = brandRes.data.items.map(brand => {
        filterIdToNameDict.set(`brands-${brand.id}`, brand.name)
        return {
          text: brand.name,
          value: brand.name,
        }
      })
      const locations = locationsRes.data.items.map(location => {
        filterIdToNameDict.set(`locations-${location.id}`, location.name)
        return {
          text: location.name,
          value: location.name,
        }
      })
      const foodsuppliers = foodsuppliersRes.data.items.map(foodsupplier => {
        filterIdToNameDict.set(
          `foodsuppliers-${foodsupplier.id}`,
          foodsupplier.name
        )
        return {
          text: foodsupplier.name,
          value: foodsupplier.name,
        }
      })

      const filterApiData = {
        brands,
        locations,
        foodsuppliers,
      }

      this.setState({ filterApiData, filterIdToNameDict })
    })
  }
  componentWillUnmount() {
    this.communicationApi && this.communicationApi.cancel()
  }
  render() {
    const { props } = this
    const { filterApiData, filterIdToNameDict } = this.state
    // If url is present in the props, then we need to show customer support related interfaces
    return (
      <AuthenticatedPage
        menu={props.menu}
        from={props.location && props.location.pathname}
      >
        <h1>{getMessage('operations.dashboard.orders')}</h1>
        <Provider value={this.state && this.state.communicationConfig}>
          {Object.keys(filterApiData).length === 0 ? (
            <Loader size="sm" />
          ) : (
            <NewListingPage
              className={`qc-orders-page ${this.props.className || 'ops'}`}
              api={{
                url: '/orders',
                params: props.params,
                updateApiParams: props.updateApiParams
                  ? props.updateApiParams
                  : null,
                transform: response => response.data.items,
                isPageDisabled: true,
                ignoreKeysInFilterCount: ['orderReference'],
              }}
              primaryKey="id"
              emptyState={
                props.emptyState ? props.emptyState() : emptyState(props)
              }
              skipArrows={true}
              jumpToPage={true}
              filters={{
                searchComponent: OrderSearchForm,
                isSearchEnabled: true,
                forceShow: true,
                component: OrderFilters,
                multiSelectFilters: multiSelectFilters,
                filterApiData,
                filterIdToNameDict,
                transformFilter: formData => {
                  let result = Object.assign({}, formData)
                  if (result.orderDuration) {
                    result.dateFrom = result.orderDuration.startDate

                    result.dateTo = result.orderDuration.endDate

                    delete result.orderDuration
                  }
                  if (
                    result.filters === 'all' &&
                    Object.keys(result).length === 1
                  ) {
                    result = {}
                  } else {
                    delete result.filters
                  }
                  const allFilterAvoidList = [
                    'paymentState',
                    'orderState',
                    'fulfillmentMethod',
                    'platform',
                  ]
                  allFilterAvoidList.forEach(filter => {
                    if (result[filter] === 'all') {
                      delete result[filter]
                    }
                  })
                  multiSelectFilters.forEach(filter => {
                    if (
                      Array.isArray(result[filter]) &&
                      (result[filter][0] === '' || result[filter].length === 0)
                    ) {
                      delete result[filter]
                    }
                  })
                  return result
                },
                transformSubmit: formData => {
                  let searchParams = ''
                  Object.keys(formData)
                    .filter(
                      key =>
                        key !== 'filters' &&
                        (formData[key] === 0 || formData[key])
                    )
                    .forEach((key, index) => {
                      if (index === 0) {
                        searchParams += `?${key}=${formData[key]}`
                      } else {
                        searchParams += `&${key}=${formData[key]}`
                      }
                    })
                  if (searchParams !== '') {
                    this.props.history.push({ search: searchParams })
                  }
                  const result = Object.assign({}, formData)
                  if (result.orderDuration) {
                    result.dateFrom = result.orderDuration.startDate

                    result.dateTo = result.orderDuration.endDate

                    delete result.orderDuration
                  }
                  if (result.customer) {
                    result.customerId = result.customer.id
                    delete result.customer
                  }
                  if (result.slot) {
                    const slotDetails = JSON.parse(result.slot)
                    if (slotDetails.startTime) {
                      result.slotStartTime = slotDetails.startTime
                    }
                    result.slotEndTime = slotDetails.endTime
                    result.type = slotDetails.type
                    delete result.slot
                  }
                  multiSelectFilters.forEach(filter => {
                    if (
                      Array.isArray(result[filter]) &&
                      (result[filter].length === 0 || result[filter][0] === '')
                    ) {
                      delete result[filter]
                    }
                  })
                  return result
                },
              }}
              tableProperties={qcTabularView(this.props.url)}
              tableDynamic
              storeDependent={this.props.url === '/operations'}
            />
          )}
        </Provider>
      </AuthenticatedPage>
    )
  }
}

export default OrdersContainer

export { Provider, Consumer }
