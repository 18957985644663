import React from 'react'
import { getMessage } from 'lib/translator'
import { ANALYTICS_PLACEHOLDER } from './utils'
import { Input, VALIDATION_TYPES } from 'components/Form'
import Analytic from '../Analytic'

const ClickToDismiss = ({
  errorFields,
  dismissErrors,
  generateStateMappers,
}) => {
  return (
    <>
      <h3 className="two-col">{getMessage('app.popup.ctd.button')}</h3>
      <Input
        type="text"
        label={getMessage('app.popup.cta.text')}
        placeholder={getMessage('app.popup.cta.text.placeholder')}
        {...generateStateMappers({
          stateKeys: ['closeBtn', 'cta', 'text'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
          loseEmphasisOnFill: true,
        })}
      />
      <Analytic
        type="closeBtn"
        errorFields={errorFields}
        dismissErrors={dismissErrors}
        placeholder={ANALYTICS_PLACEHOLDER}
        generateStateMappers={generateStateMappers}
        stateKeys={['closeBtn', 'analytic']}
      />
    </>
  )
}

export default ClickToDismiss
