import React from 'react'
import { CONFIG_API } from 'config/app'
import API from 'lib/api'
import BulkGlobalProductComplexAttributesReview from './ReviewTables/BulkGlobalProductComplexAttributesReview'
import SPLIT_FEATURES from '../../../containers/SplitContext/features'
import { get } from 'lib/storage'
import { isPositiveNumber } from './BatchJobsConfig'

export const VALIDATORS = {
  // value: SINGLE VALUE TO CHECK
  // param: COMING FROM API CONFIG
  bool: (value) => {
    return value === true || value === false || value === 0 || value === 1
  },
  required: (value) => {
    return !(value === undefined || value === null || value === '')
  },
  in: (value, param, type) => {
    if (!value) {
      return true
    }
    const values = value.split(',')
    return type === 'enum' && values.length === 1
      ? param.includes(value)
      : type === 'array' && values.every((val) => param.includes(val))
  },
  // this mehtod not in use right now its for future use
  max_length: (value, param) => {
    return value.length <= param
  },
  // this mehtod not in use right now its for future use
  min_length: (value, param) => {
    return value.length >= param
  },
  // this mehtod not in use right now its for future use
  max: (value, param) => {
    return value <= param
  },
  // this mehtod not in use right now its for future use
  min: (value, param) => {
    return Number(value) >= param
  },
  // this mehtod not in use right now its for future use
  pattern: () => true,
}

export const getComplexAttribute = async () => {
  const flags = JSON.parse(get('SPLIT_FEATURE_FLAGS'))
  const isBulkProductComplexEnabled =
    flags[SPLIT_FEATURES.BULK_GLOBAL_PRODUCT_COMPLEX_ATTRIBUTE]?.treatment ===
    'on'

  if (isBulkProductComplexEnabled) {
    try {
      const response = await new API({
        url: `${CONFIG_API}/v1/batch-jobs/types/SKU-PRODUCT-METADATA-UPDATE-JOB/fields`,
      }).get()
      if (response?.data && response.code === 200) {
        return {
          COMPLEX_ATTRIBUTE: { ...complexAttributeJobConfig(response?.data) },
        }
      }
    } catch (error) {
      /* istanbul ignore next */
      console.error('Error fetching complex attribute:', error)
    }
  }

  return {}
}

export const constructHeaders = (val) => {
  const result = val?.reduce((acc, value) => {
    const newObj = {
      message: () => {
        const validators = value?.validations || []
        const errors = []
        for (const { errorMessage } of validators) {
          errors.push(errorMessage)
        }
        return errors
      },
      header: value.csvColumn,
      validate: (data) => {
        const validators = value?.validations || []

        for (const { validator, param } of validators) {
          const isValid = VALIDATORS[validator](data, param, value.type)
          if (!isValid) {
            return false // Return false if validation fails
          }
        }
        return true
      },
      displayHeader: value.name,
      type: value.type,
    }

    // Handle value.action if present
    const actionObj =
      value.action && value.action?.required
        ? {
            [`${value.csvColumn.toUpperCase()}_ACTION`]: {
              message: () => {
                const validators = value?.action?.allowedValues || []
                const errors = []
                if (validators.length > 0) {
                  errors.push(`Allowed values ${validators}`)
                }
                return errors
              },
              header: `${value.csvColumn}_action`,
              validate: (g) => {
                if (!g) {
                  return true
                }
                const allowedValues = value?.action?.allowedValues
                return allowedValues.includes(g)
              },
              displayHeader: `${value.name} Action`,
              type: value.type,
              render: function AttributeAction(v) {
                return (
                  <span
                    className={`attribute-action ${(v || '').toUpperCase()}`}
                  >
                    {v}
                  </span>
                )
              },
            },
          }
        : {}

    // Return the accumulated result
    return {
      ...acc,
      [value.csvColumn.toUpperCase()]: newObj,
      ...actionObj, // Include the action object if available
    }
  }, {})

  return {
    SKU: {
      header: 'sku',
      validate: (obj) => {
        return (
          isPositiveNumber(obj.id) &&
          obj.name &&
          VALIDATORS['required'](obj.id.trim())
        )
      },
      displayHeader: 'Sku',
      render: (obj) => <span className="sku">{obj.id}</span>,
      mandatory: true,
      message: () => {
        return ['This field is mandatory']
      },
      type: 'numeric',
    },
    PRODUCT_NAME: {
      header: 'product_name',
      displayHeader: 'Product Name',
      skipInTemplate: true,
      message: () => {
        return ['SKU / Product not found']
      },
      validate: (o) => {
        return !o.name ? false : true
      },
      // eslint-disable-next-line react/display-name
      render: (obj) => (
        <React.Fragment>
          <span className="product-name">{obj.name || 'Not found'}</span>
          {` `}
          <span className="display-unit muted">{obj.unit}</span>
        </React.Fragment>
      ),
    },
    ...result,
  }
}

export const complexAttributeJobConfig = (data) => {
  const csvHeaders = constructHeaders(data)
  return {
    name: 'Bulk Global Product Complex Attributes',
    id: 'bulk-global-product-complex-attributes',
    sampleCsv: 'sample-bulk-global-product-complex-attributes.csv',
    columnSelectable: true,
    csvHeaders: {
      ...csvHeaders,
    },
    endpoint: `${CONFIG_API}/v1/batch-jobs`,
    ReviewComponent: BulkGlobalProductComplexAttributesReview,
  }
}
