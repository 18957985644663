import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { tableProperties } from '../../../components/Rewards/Merchants/utils'
import { LinkButtons, emptyState } from '../../../components/Rewards/utils'
import '../style.css'
import './merchants.css'
import MerchantForm from './Form'
import { getMessage } from 'lib/translator'
import Filters from 'components/Rewards/Filters'

const Merchants = (props) => {
  return (
    <ListingPageWithRoutes
      testid="merchants-listing"
      title={getMessage('rewards.merchant.title')}
      addHeading={getMessage('rewards.merchant.add')}
      editHeading={getMessage('rewards.merchant.edit')}
      menu={props.menu}
      className="rewards-page merchants"
      api={{
        url: '/admin/merchants',
        transform: (response) => response && response.data.list,
      }}
      headerActions={() => <LinkButtons type="merchant" />}
      form={{
        component: MerchantForm,
      }}
      filters={{
        component: Filters,
        forceShow: true,
        options: {
          type: 'merchant',
        },
      }}
      tableProperties={tableProperties}
      emptyState={emptyState('merchant')}
      tableDynamic
    />
  )
}

export default Merchants
