import React from 'react'
import { BaseForm, MultiSelect } from '../../../components/Form'
import { getMessage } from '../../../lib/translator'

export default class ColumnsDropdown extends BaseForm {
  render() {
    const { options, onChange, value } = this.props
    return (
      <div>
        <MultiSelect
          name="columns-select"
          key="columns-select"
          isExpanded
          label=" "
          {...this.generateStateMappers({
            stateKeys: ['columns-select'],
            loseEmphasisOnFill: true,
          })}
          placeholder={getMessage('product.global.dropdown.placeholder')}
          options={options}
          value={value}
          onChange={onChange}
        />
      </div>
    )
  }
}
