import React from 'react'
import {
  Input,
  Select,
  BaseForm,
  Phone,
  MultiSelect,
  VALIDATION_TYPES,
  Checkbox,
} from '../../../../components/Form'
import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import deleteIcon from './delete.svg'
import SelectStoresSearch from '../../../../components/SelectStoresSearch'

import './style.css'

const getTimes = () => {
  const times = []
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      times.push(`0${i}:00`)
      times.push(`0${i}:30`)
    } else {
      times.push(`${i}:00`)
      times.push(`${i}:30`)
    }
  }
  return times
}

const daysOfWeek = [
  {
    text: 'Mon',
    value: 'Monday',
  },
  {
    text: 'Tue',
    value: 'Tuesday',
  },
  {
    text: 'Wed',
    value: 'Wednesday',
  },
  {
    text: 'Thur',
    value: 'Thursday',
  },
  {
    text: 'Fri',
    value: 'Friday',
  },
  {
    text: 'Sat',
    value: 'Saturday',
  },
  {
    text: 'Sun',
    value: 'Sunday',
  },
]

export default class UserShiftForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state.allSelected = false
    this.state.storeZones = []
    this.delete = this.delete.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.getPickerZones = this.getPickerZones.bind(this)
    this.getStoreZones = this.getStoreZones.bind(this)
    this.resetZones = this.resetZones.bind(this)
    this.onDesignationChange = this.onDesignationChange.bind(this)
    this.pickerZonesApi = new API({
      url: `/picking-service/v3/pickerZones`,
    })
  }

  handleSelectAll(value) {
    const currState = JSON.parse(JSON.stringify(this.state))
    const { values } = currState
    if (value) {
      values.storeIds = (this.props.options.stores || []).map(
        (store) => store.id
      )
      this.props.options.stores && this.setState({ values, allSelected: true })
    } else {
      values.storeIds = []
      this.setState({ values, allSelected: false })
    }
  }

  onDesignationChange(designationId) {
    const isZonePicker =
      designationId === this.state.values.zonePickerDesignationId
    const storeIds = this.state.values.storeIds
    if (isZonePicker && storeIds && storeIds.length) {
      this.getStoreZones(storeIds[0])
      this.getPickerZones(storeIds[0], this.state.values.id)
    } else {
      this.resetZones()
    }
  }

  getPickerZones(storeId, pickerId) {
    this.pickerZonesApi = new API({
      url: `/picking-service/v3/pickerZones`,
    })
    const params = {
      storeId: storeId,
      pickerId: pickerId,
    }
    this.pickerZonesApi
      .get(params)
      .then((response) => {
        if (response && response.zones && pickerId) {
          const pickerZones = response.zones.map((zone) => {
            return zone.id
          })
          const { values } = this.state
          this.setState({
            values: {
              ...values,
              pickerZones: pickerZones,
            },
          })
        }
      })
      .catch((error) => {
        this.handleError(error)
      })
  }

  getStoreZones(storeId) {
    const storeZonesApi = new API({
      url: `/picking-service/v3/storeZones/` + storeId,
    })
    storeZonesApi
      .get()
      .then((response) => {
        if (response && response.storeZones) {
          const storeZones = response.storeZones.map((zone) => ({
            text: zone.displayName,
            value: parseInt(zone.id, 10),
          }))
          this.setState({
            storeZones: storeZones,
          })
        }
      })
      .catch((error) => {
        this.handleError(error)
      })
  }

  handleError(error) {
    if (error.code === 401) {
      throw error
    }
    this.setState({
      error: error.message,
    })
  }

  resetZones() {
    const { values } = this.state
    this.setState({
      storeZones: [],
      values: {
        ...values,
        pickerZones: [],
      },
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.values.storeIds !== prevState.values.storeIds) {
      const storeIds = this.state.values.storeIds
        ? this.state.values.storeIds
        : []
      if (storeIds.length) {
        const designationId = this.state.values.designationId
        const isZonePicker =
          designationId &&
          designationId.toString() ===
            this.state.values.zonePickerDesignationId.toString()
        if (isZonePicker) {
          this.getStoreZones(storeIds[0])
          this.getPickerZones(storeIds[0], this.state.values.id)
        } else {
          this.resetZones()
        }
      } else {
        this.resetZones()
      }
    }
  }

  componentDidMount() {
    const { state, props } = this
    let shiftStart, shiftEnd
    const { values } = state
    const newState = Object.assign({}, this.state.values)
    if (this.props && this.props.value) {
      shiftStart = this.props.value.shiftStart
      shiftEnd = this.props.value.shiftEnd
    }
    if (shiftStart && shiftEnd) {
      shiftStart = shiftStart.split(':').splice(0, 2).join(':')
      shiftEnd = shiftEnd.split(':').splice(0, 2).join(':')
      newState.shiftStart = shiftStart
      newState.shiftEnd = shiftEnd
    }
    const designationOpts = this.props.options.designations
    newState.zonePickerDesignationId = ''
    if (designationOpts) {
      for (var i = 0; i < designationOpts.length; i++) {
        if (designationOpts[i].name === 'Zone Picker') {
          newState.zonePickerDesignationId = designationOpts[i].id.toString()
        }
      }
    }
    if (values.designation) {
      newState.designationId = values.designation.id
    }
    if (values.stores && values.stores.length > 0) {
      const stores = values.stores
      const storeIds = stores.map((store) => {
        return store.id
      })
      if (
        Number(newState.designationId) ===
        Number(newState.zonePickerDesignationId)
      ) {
        this.getStoreZones(storeIds[0])
        this.getPickerZones(storeIds[0], values.id)
      }
      newState.storeIds = storeIds
    }
    this.setState(
      {
        values: newState,
      },
      () => {
        if (props.method === 'edit') {
          const currState = JSON.parse(JSON.stringify(this.state))
          const { values: currValues } = currState
          const { storeIds } = currValues
          if (
            props.options.stores &&
            props.options.stores.every((store) =>
              (storeIds || []).find((id) => id === store.id)
            )
          ) {
            this.setState({ allSelected: true })
          }
        }
      }
    )
    this.stores =
      this.props.options.multiStoreEnabled && (this.props.options.stores || [])

    this.designationOptions =
      this.props.options.designations &&
      this.props.options.designations
        .map((designation) => ({
          text: designation.name,
          value: designation.id,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
  }

  delete() {
    this.props.onDelete()
  }
  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const designationId = this.state.values.designationId
    const zonePickerDesignationId = this.state.values.zonePickerDesignationId
    const { Form } = this.components
    const { isoCode, stores, designations } = this.props.options
    const selectedDes =
      designations &&
      designations.filter((des) => Number(des.id) === Number(designationId))[0]
    const timingType =
      designationId &&
      designations &&
      selectedDes &&
      selectedDes.timingType === 'FIXED'
    const isZonePicker =
      designationId &&
      designationId.toString() === zonePickerDesignationId.toString()
    return (
      <div className={'user-shifts-form'}>
        <Form>
          <div className="flex">
            <Input
              label={getMessage('shifts.form.name')}
              placeholder={getMessage('shifts.form.name.placeholder')}
              name="name"
              required
              {...this.generateStateMappers({
                stateKeys: ['name'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Phone
              label={getMessage('shifts.form.phone')}
              placeholder={getMessage('shifts.phone.placeholder')}
              country={isoCode}
              name="phone"
              // Hack. Modify the Phone component for solving this issue
              required={!this.state.values.id}
              {...this.generateStateMappers({
                stateKeys: ['phones', 0, 'phone'],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="flex">
            <Input
              label={getMessage('shifts.form.email')}
              placeholder={getMessage('shifts.form.email.placeholder')}
              required
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['emails', 0, 'email'],
                loseEmphasisOnFill: true,
              })}
            />
            <Select
              label={getMessage('shifts.designation')}
              placeholder={getMessage('shifts.designation.placeholder')}
              name="designation"
              options={this.designationOptions}
              {...this.generateStateMappers({
                stateKeys: ['designationId'],
                loseEmphasisOnFill: true,
                onChange: this.onDesignationChange,
              })}
            />
          </div>
          {
            <React.Fragment>
              {timingType ? (
                <div className="shift-details">
                  <Select
                    label={getMessage('shifts.form.startTime')}
                    name="shiftStart"
                    type="text"
                    options={getTimes()}
                    {...this.generateStateMappers({
                      stateKeys: ['shiftStart'],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                  />
                  <Select
                    label={getMessage('shifts.form.endTime')}
                    name="shiftStart"
                    type="text"
                    options={getTimes()}
                    {...this.generateStateMappers({
                      stateKeys: ['shiftEnd'],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                  />
                </div>
              ) : null}
            </React.Fragment>
          }
          <MultiSelect
            name="weeklyOff"
            label={getMessage('shifts.form.weekly.off')}
            placeholder={getMessage('shifts.form.weekly.off.placeholder')}
            options={daysOfWeek}
            {...this.generateStateMappers({
              stateKeys: ['weeklyOff'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          {this.stores && (
            <SelectStoresSearch
              label={getMessage('shifts.stores')}
              placeholder={getMessage('shifts.stores.placeholder')}
              _this={this}
              stores={stores}
              required
              stateKey="storeIds"
              readOnly={this.state.allSelected}
              dontDisplaySelected={this.state.allSelected}
              secondaryLabel={() => (
                <Checkbox
                  inlineLabel={getMessage('select all')}
                  name="selectAllStores"
                  value={this.state.allSelected}
                  controlled
                  onChange={(value) => {
                    this.handleSelectAll(value)
                  }}
                  className="checkLabel"
                />
              )}
            />
          )}
          {isZonePicker ? (
            <MultiSelect
              name="pickerZones"
              label={getMessage('shifts.form.pickers.zones')}
              placeholder={getMessage('shifts.form.pickers.zones.placeholder')}
              required={true}
              options={this.state.storeZones}
              {...this.generateStateMappers({
                stateKeys: ['pickerZones'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
          ) : null}
          <div className={`form-actions ${this.props.method}`}>
            {this.props.method && this.props.method === 'edit' && (
              <button
                className="delete-button"
                type="button"
                onClick={this.delete}
              >
                <img src={deleteIcon} alt="delete" />
                <span>{getMessage('brand.form.deleteText')}</span>
              </button>
            )}
            <CancelButton>{getMessage('shifts.form.cancel')}</CancelButton>
            <SubmitButton>{getMessage('shifts.form.submitText')}</SubmitButton>
          </div>
        </Form>
      </div>
    )
  }
}
