import React, { useEffect, useState } from 'react'
import API from '../../../../lib/api'
import { tierSetupSelection } from '../../../../lib/commonlyused'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import Loader from '../../../../components/Loader'
import { StoreConfigurationForm } from './Form'

export const useConfigMetaData = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const metadataApi = new API({ url: `/config-service/meta-data` })

  useEffect(() => {
    metadataApi.get().then((response) => {
      const storeMetaDataWithType =
        (response.data &&
          response.data.config &&
          response.data.config.entityMetaData &&
          response.data.config.entityMetaData.store) ||
        {}
      setData(storeMetaDataWithType)
      setLoading(false)
    })
  }, [])

  return { loading, data }
}

export const useTierSetup = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const tierSetupApi = new API({ url: `/account-service/shipping-tier` })

  useEffect(() => {
    tierSetupApi.get().then((response) => {
      const sortedTierSetupData = response.data.sort(
        (a, b) => b.isDefault - a.isDefault
      )
      const tierSetups = tierSetupSelection(sortedTierSetupData)
      setData(tierSetups)
      setLoading(false)
    })
  }, [])

  return { loading, data }
}

export const useStore = (storeId, action) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  const storeApi = new API({
    url: `/account-service/store/${storeId}`,
  })

  useEffect(() => {
    if (action === 'edit') {
      storeApi.get({ bypasscache: new Date().getTime() }).then((response) => {
        const store = response.data.store
        setData(store ?? null)
        setLoading(false)
      })
    }
  }, [storeId, action])

  return { loading, data }
}
export const StoreConfigContainer = ({
  storeId,
  menu,
  action,
  onCancel,
  onSubmitDone,
}) => {
  const { loading: storeLoading, data: storeData } = useStore(storeId, action)
  const { loading: tierLoading, data: tierData } = useTierSetup()
  const { loading: metaDataLoading, data: metaData } = useConfigMetaData()

  const showLoader = Boolean(
    action === 'edit'
      ? storeLoading || tierLoading || metaDataLoading
      : tierLoading || metaDataLoading
  )

  return (
    <AuthenticatedPage menu={menu}>
      <h1>Store Configuration</h1>
      {showLoader ? (
        <Loader />
      ) : (
        <StoreConfigurationForm
          store={storeData}
          tier={tierData}
          metaData={metaData}
          action={action}
          onCancel={onCancel}
          onSubmitDone={onSubmitDone}
        />
      )}
    </AuthenticatedPage>
  )
}
