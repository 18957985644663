import React, { Fragment } from 'react'
import Table, { Row, Cell } from '../../../../../../components/Table'
import { Checkbox } from '../../../../../../components/Form'
import TickIcon from '../../../../../../components/Form/Inputs/CheckboxGroup/tick-icon.svg'
import CustomisationOptions from './CustomisationOptions'
import {
  checkedItemSubOrders,
  handleDisabledItemLevel,
} from '../OrderSummaryHelpers'
import './style.css'
import { STATUSES } from '../../Details.enum'
import { getMessage } from '../../../../../../lib/translator'
import { notEmptyArray } from '../../../../../../lib/commonlyused'
const handleItemSingleSelected = (idx, suborderId, handleState, state, e) => {
  handleState({
    ...state,
    allSelected: checkedItemSubOrders(state, suborderId, idx, e).some(
      item => item.value
    ),
    refundItems: checkedItemSubOrders(state, suborderId, idx, e),
  })
}
const increaseQuantity = (idx, suborderId, handleState, state) => {
  ++state.refundItems.find(a => a.id === suborderId).items[idx].finalQuantity
  handleState({
    ...state,
    refundItems: state.refundItems,
  })
}
const decreaseQuantity = (idx, suborderId, handleState, state) => {
  --state.refundItems.find(a => a.id === suborderId).items[idx].finalQuantity
  handleState({
    ...state,
    refundItems: state.refundItems,
  })
}
const formattedPrice = order => {
  return <Cell>{order.formattedPrice}</Cell>
}
const formattedItemTotal = (order, refundSuborder) => {
  return (
    <Cell>
      {refundSuborder
        ? order.formattedRefundedItemTotal
        : order.formattedItemDiscountedTotal}
    </Cell>
  )
}
const quantityButton = str => (
  <button className={`primary button quantity hide-quantity`}>{str}</button>
)
const checkboxWithName = (props, order, idx) => {
  return (
    <Cell>
      <div
        className={`select-single-subOrder-item ${
          props.state.refundOrder &&
          !order.customisationOptions &&
          order.specialInstructions
            ? 'special-instructions'
            : ''
        }`}
      >
        {props.state.refundOrder && !props.state.showHideTablePopup && (
          <Checkbox
            testid={`selectItemSingleSelected${idx}${props.suborderId}${props.suborderidx}`}
            name={`selectItemSingleSelected${idx}${props.suborderId}${props.suborderidx}`}
            value={props.state.refundItems[props.suborderidx].items[idx].value}
            onChange={handleItemSingleSelected.bind(
              this,
              idx,
              props.suborderId,
              props.handleState,
              props.state
            )}
            disabled={handleDisabledItemLevel(props)}
          />
        )}
        <div>{order.name} </div>
      </div>
    </Cell>
  )
}
const specialInstructions = (props, order) => {
  return (
    <div
      className={`suborder-special-instructions qc-order-summary-grey ${props
        .state.refundOrder &&
        !props.refundSuborder &&
        !props.state.showHideTablePopup &&
        'special-instructions-with-checkbox'}`}
    >
      {order.specialInstructions ? (
        <i>&quot;{order.specialInstructions}&quot;</i>
      ) : (
        <b>
          <i className="no-special-instructions">
            {getMessage('qc.orderSummary.no.special.instruction')}
          </i>
        </b>
      )}
    </div>
  )
}
const specialDiscounts = (props, order) => {
  return (
    <div
      className={`suborder-special-discounts qc-order-summary-grey ${props.state
        .refundOrder &&
        !props.refundSuborder &&
        !props.state.showHideTablePopup &&
        'special-discounts-with-checkbox'}`}
    >
      <div className="special-discounts">
        <img src={TickIcon} alt="tick icon" />
        {order.discountDetails.length > 0 &&
          order.discountDetails[0].displayName}
        :{' '}
        {order.discountDetails.length > 0 &&
          order.discountDetails[0].formattedUnitAmount}
      </div>
    </div>
  )
}

const customisationItems = order =>
  order.finalQuantity > 0 &&
  order.customisationOptions.some(a => a.finalQuantity > 0)
const customisationRefundedItems = order =>
  !!order.refundedQuantity &&
  order.refundedQuantity > 0 &&
  order.customisationOptions.some(a => a.refundedQuantity > 0)

const refundedItems = (order, props, idx) => {
  return (
    <Row
      className={`suborder-refunded-items customisation-items ${notEmptyArray(
        order.customisationOptions
      ) &&
        customisationRefundedItems(order) &&
        'customisation-items'}`}
      key={`qc-item-row-${props.id}-${idx}`}
    >
      <Cell>
        <div className={`select-single-subOrder-item`}>
          <div>{order.name}</div>
        </div>
      </Cell>
      <Cell>
        <div>{order.formattedUnitFees}</div>
      </Cell>
      {formattedPrice(order)}
      <Cell>
        <div>{order.formattedUnitDiscounts}</div>
      </Cell>
      <Cell>
        <div>
          {' '}
          {props.state.refundOrder &&
            !props.state.showHideTablePopup &&
            quantityButton('-')}
          {order.refundedQuantity}
          {props.state.refundOrder &&
            !props.state.showHideTablePopup &&
            quantityButton('+')}
        </div>
      </Cell>

      {formattedItemTotal(order, props.refundSuborder)}
    </Row>
  )
}
const nonRefundedItems = (order, props, idx) => {
  return (
    <Row
      className={`suborder-items customisation-items ${notEmptyArray(
        order.customisationOptions
      ) &&
        customisationItems(order) &&
        'customisation-items'}`}
      key={`qc-item-row-${props.id}-${idx}`}
    >
      {checkboxWithName(props, order, idx)}
      <Cell>
        <div>
          <div>{order.formattedUnitFees}</div>
        </div>
      </Cell>
      {formattedPrice(order)}
      <Cell>
        <div>{order.formattedUnitDiscounts}</div>
      </Cell>
      <Cell>
        <div>
          {' '}
          {props.state.refundOrder && !props.state.showHideTablePopup && (
            <button
              data-testid={`decreaseQuantity${idx}${props.suborderId}${props.suborderidx}`}
              onClick={decreaseQuantity.bind(
                this,
                idx,
                props.suborderId,
                props.handleState,
                props.state
              )}
              className={`primary button quantity ${props.suborder.state !==
                STATUSES.COLLECTED && 'hide-quantity'}`}
              disabled={
                !(
                  props.state.refundItems[props.suborderidx].items[idx].value &&
                  props.state.refundItems[props.suborderidx].items[idx]
                    .finalQuantity > 1
                )
              }
            >
              -
            </button>
          )}
          {props.state.refundOrder &&
          props.suborder.state === STATUSES.COLLECTED &&
          !props.state.showHideTablePopup
            ? props.state.refundItems.find(a => a.id === props.suborderId)
                .items[idx].finalQuantity
            : order.finalQuantity}
          {props.state.refundOrder && !props.state.showHideTablePopup && (
            <button
              data-testid={`increaseQuantity${idx}${props.suborderId}${props.suborderidx}`}
              onClick={increaseQuantity.bind(
                this,
                idx,
                props.suborderId,
                props.handleState,
                props.state
              )}
              className={`primary button quantity ${props.suborder.state !==
                STATUSES.COLLECTED && 'hide-quantity'}`}
              disabled={
                !(
                  props.state.refundItems[props.suborderidx].items[idx].value &&
                  props.state.refundItems[props.suborderidx].items[idx]
                    .finalQuantity !== order.finalQuantity
                )
              }
            >
              +
            </button>
          )}
        </div>
      </Cell>

      {formattedItemTotal(order, props.refundSuborder)}
    </Row>
  )
}
const customizationComponent = (order, props, idx) => {
  return (
    <CustomisationOptions
      order={order}
      state={props.state}
      handleState={props.handleState}
      idx={idx}
      suborderidx={props.suborderidx}
      suborderId={props.suborderId}
      suborder={props.suborder}
      refundSuborder={props.refundSuborder}
    />
  )
}
const suborderItemContent = (idx, order, props) => {
  return (
    <Fragment key={`qc-subordr-main-row-${idx}-${order.id}`}>
      {!props.refundSuborder &&
        order.finalQuantity !== 0 &&
        nonRefundedItems(order, props, idx)}
      {!!order.refundedQuantity &&
        order.refundedQuantity !== 0 &&
        props.refundSuborder &&
        refundedItems(order, props, idx)}
      {order.customisationOptions && customizationComponent(order, props, idx)}
      {specialInstructionContent(order, props)}
      {specialDiscountContent(order, props)}
    </Fragment>
  )
}
const specialDiscountContent = (order, props) => {
  return (
    ((order.finalQuantity > 0 && !props.refundSuborder) ||
      (props.refundSuborder &&
        order.refundedQuantity &&
        order.refundedQuantity > 0) ||
      (order.customisationOptions.some(item => item.finalQuantity > 0) &&
        !props.refundSuborder) ||
      (props.refundSuborder &&
        order.customisationOptions &&
        order.customisationOptions.some(
          item => item.refundedQuantity && item.refundedQuantity > 0
        ))) &&
    order.discountDetails &&
    order.discountDetails.length > 0 &&
    specialDiscounts(props, order)
  )
}

const specialInstructionContent = (order, props) => {
  return (
    ((order.finalQuantity > 0 && !props.refundSuborder) ||
      (props.refundSuborder &&
        order.refundedQuantity &&
        order.refundedQuantity > 0) ||
      (order.customisationOptions.some(item => item.finalQuantity > 0) &&
        !props.refundSuborder) ||
      (props.refundSuborder &&
        order.customisationOptions &&
        order.customisationOptions.some(
          item => item.refundedQuantity && item.refundedQuantity > 0
        ))) &&
    specialInstructions(props, order)
  )
}

const SubOrderItems = props => {
  return (
    <Table
      className={`${
        props.refundSuborder
          ? 'suborder-refunded-items-table'
          : 'suborder-items-table'
      } table-dynamic`}
    >
      {props.items.map((order, idx) => {
        return suborderItemContent(idx, order, props)
      })}
    </Table>
  )
}
export { quantityButton }
export default SubOrderItems
