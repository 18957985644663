import React from 'react'
import {
  BaseForm,
  Input, Select,
  SelectSearch,
} from '../../../components/Form'
import { getMessage } from '../../../lib/translator'

import {
  formattedMediaPackageList_toString,
  getPackages,
} from '../MediaCampaigns/advertiserList'
import { BUList } from '../MediaAssetManagement/businessUnitList'
import { creativeStatusList } from './creativeTypeList'


export default class Filters extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      packageList: [],
    }
  }

  componentDidMount () {
    getPackages(this)
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components

    return (
      <Form className='store-filter-form'>
        <div className='form-fields'>
          <Input
            label={getMessage('media.creativeManagement.filter.name')}
            placeholder={getMessage('media.creativeManagement.filters.name.placeholder')}
            name='creativeName'
            className='section-row-input'
            type='text'
            {...this.generateStateMappers({
              stateKeys: ['creativeName'],
              loseEmphasisOnFill: true,
            })}
          />
          <SelectSearch
            className='section-row-input'
            name="packageId"
            label={getMessage('media.adsetdetail.info.package')}
            placeholder={getMessage('media.adsetdetail.info.package.placeholder')}
            multiple={false}
            options={formattedMediaPackageList_toString(this.state.packageList)}
            nameKey="text"
            valueKey="value"
            searchByName
            {...this.generateStateMappers({
              stateKeys: ['packageId'],
            })}
          />
          <Select
            className="section-row-input"
            label={getMessage('media.campaigndetail.business.ownerBu')}
            placeholder={getMessage('media.campaigndetail.business.ownerBu.placeholder')}
            options={BUList}
            {...this.generateStateMappers({
              stateKeys: ['bu'],
              loseEmphasisOnFill: true
            })}
          /><Select
            className="section-row-input"
            label={getMessage('media.adsetdetail.info.status')}
            placeholder={getMessage('media.adsetdetail.info.status.placeholder')}
            options={creativeStatusList}
            {...this.generateStateMappers({
              stateKeys: ['status'],
              loseEmphasisOnFill: true
            })}
          />
        </div>
        <SubmitButton>{getMessage('brand.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('brand.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
