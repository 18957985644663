import React, { Component, useContext } from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { Link } from 'react-router-dom'

import CapacityPlanningForm from './Form'
import CapacityPlanningFilter from './Filters'
import CapacityModal from './CapacityModal'

import { getMessage } from '../../../lib/translator'
import API from '../../../lib/api'
import { get } from '../../../lib/storage'
import { getDisplaySlot } from '../../../lib/commonlyused'
import { formatDate, getDaysOfWeek } from '../../../lib/datetime'
import { SingleDatePicker } from '../../../components/Form'
import { SplitContext } from 'containers/SplitContext';
import './style.css'
import EmptyIcon from './capacityplanner.svg'

const orderTypeOptions = [
  {
    text: getMessage('delivery'),
    value: 'DELIVERY'
  },
  {
    text: getMessage('pickup'),
    value: 'PICKUP'
  }
]

const getOrderTypeText = (orderType) => {
  let orderTypeText = orderType.split('')[0] + orderType.toLowerCase().split('').splice(1, orderType.length).join('');
  if (orderTypeText.includes('_')) {
    orderTypeText = orderTypeText.replace('_',' ');
  }
  if (orderTypeText.startsWith('Rb')) {
    orderTypeText = orderTypeText.replace('Rb','RB');
  }
  return orderTypeText;
}

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage('cp.empty'),
  actions: ({ onAction }) => (
    <React.Fragment>
      <button
        className='primary button import-button'
        onClick={() => {
          onAction(TABLE_ACTIONS.IMPORT)
        }}>
        <span className='text'>Import Capacity</span>
      </button>

      <button
        className='primary button'
        onClick={() => {
          onAction(TABLE_ACTIONS.ADD)
        }}
      >
        <span className='text'>{`+ ${getMessage('cp.add')}`}</span>
      </button>
    </React.Fragment>
  )
}

const tableProperties = options => {
  return {
    headers: [
      getMessage('cp.type'),
      getMessage('cp.area'),
      getMessage('Slot'),
      getMessage('cutoff.time'),
      getMessage('Date'),
      getMessage('Capacity'),
      getMessage('Actions')
    ],
    row: ({
      id,
      orderType,
      cutOffTime,
      day,
      date,
      capacity,
      zone,
      slot,
      slotId,
      zoneId,
      onAction
    }) => {
      const derivedZone =
        !zone &&
        options.zones &&
        options.zones.filter(z => z.id === zoneId)[0]
      const slots =
        orderType === 'PICKUP' ? options.pickupSlots : options.deliverySlots
      const derivedSlot =
        !slot && slots && slots.filter(s => s.id === slotId)[0]
      const split = cutOffTime && cutOffTime.split(':')
      let displayCutOffTime = split && split[0] && Number(split[0])
      if (split.length > 1 && split[1].trim() === '30' && displayCutOffTime) {
        displayCutOffTime = Number(displayCutOffTime) + Number(0.5)
      } else if (
        split.length > 1 &&
        split[1].trim() === '30' &&
        displayCutOffTime === 0
      ) {
        displayCutOffTime = Number(displayCutOffTime) + Number(0.5)
      } else {
        displayCutOffTime = Number(displayCutOffTime) + '.0'
      }
      return (
        <Row>
          <Cell className='capacity-order-type'>
            <span onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}>
              {orderType && getOrderTypeText(orderType)}
            </span>
          </Cell>
          <Cell className='capacity-zone-name'>
            <span className='text-muted'>
              {(zone && zone.name) || (derivedZone && derivedZone.name)}
            </span>
          </Cell>
          <Cell className='capacity-slot'>
            <div>
              <span className='text-muted mobile-header-slot'>
                {getMessage('Slot')}
              </span>
              <p>
                {(slot && getDisplaySlot(slot)) ||
                  (derivedSlot && getDisplaySlot(derivedSlot))}
              </p>
            </div>
          </Cell>
          <Cell className='capacity-cutoff-time'>
            <div>
              <span className='text-muted mobile-header-cutoff'>
                {getMessage('cutoff.time')}
              </span>
              <p>{`${displayCutOffTime} h`}</p>
            </div>
          </Cell>
          <Cell className='capitalize capacity-day'>
            {(date && formatDate(date.split('T')[0])) ||
              (day && day === 'ALLDAYS' ? 'All Days' : day.toLowerCase())}
          </Cell>
          <Cell className='text-right capacity'>
            <div>
              <span className='text-muted mobile-header-capacity'>
                {getMessage('Capacity')}
              </span>
              <p>{capacity}</p>
            </div>
          </Cell>
          <Cell className='capacity-actions'>
            <DropDown icon={<img src={ICONS.VELLIP} alt='⋮' />}>
              <DropDownItem
                onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}
              >
                Edit
              </DropDownItem>
              <DropDownItem
                onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}
              >
                Delete
              </DropDownItem>
            </DropDown>
          </Cell>
        </Row>
      )
    }
  }
}

export default function CapacityPlanningWrapper(props) {
  // this is a temporary split flag checking.
  // it will be removed once the feature is stable on production
  const splitConfig = useContext(SplitContext);
  const { splits } = splitConfig;

  return <CapacityPlanning {...props} splits={splits} />
}

class CapacityPlanning extends Component {
  constructor (props) {
    super(props)
    this.update = this.update.bind(this)
    this.getZonesAndSlots = this.getZonesAndSlots.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.handleImportClick = this.handleImportClick.bind(this)
    this.state = {
      zones: [],
      deliverySlots: [],
      pickupSlots: [],
      preorderSlots: [],
      date: null,
      showImportModal: false,
    }
  }

  componentDidMount () {
    this.getZonesAndSlots()
    const daysOfWeek = getDaysOfWeek({ full: true })
    daysOfWeek.unshift({ text: 'All Days', value: 'ALLDAYS' })
    this.setState({ daysOfWeek })
  }
  onDateChange (date) {
    this.setState({
      date
    })
    this.props.history.push({ pathname: `capacity-planning/today`, date: date })
  }

  getZonesAndSlots (currentStoreId) {
    const storeId = currentStoreId || get('store');
    this.zonesApi = new API({ url: `/logistics-service/delivery-area` })
    const zonesParams = {
      paginated: 'false',
      storeId
    }
    this.slotsApiPickup = new API({ url: `/order-service/slot` })
    const slotParams = {
      paginated: 'false',
      storeId,
      orderType: 'PICKUP'
    }

    this.slotsApiDelivery = new API({ url: '/order-service/slot' })
    const slotParamsDelivery = {
      paginated: 'false',
      storeId,
      orderType: 'DELIVERY'
    }

    this.slotsApiPreorder = new API({ url: '/order-service/slot' })
    const slotParamsPreorder = {
      paginated: 'false',
      storeId,
      orderType: 'RB_PREORDER'
    }

    // TODO: need to see abt pickup zones. right now using stores but need to be converted to zones
    Promise.all([
      this.zonesApi.get(zonesParams),
      this.slotsApiPickup.get(slotParams),
      this.slotsApiDelivery.get(slotParamsDelivery),
      this.slotsApiPreorder.get(slotParamsPreorder)
    ]).then(([zonesResponse, slotsPickupResponse, slotsDeliveryResponse, slotsPreorderResponse]) => {
      this.setState({
        zones: zonesResponse.data.deliveryarea || [],
        pickupSlots: slotsPickupResponse.data.slot || [],
        deliverySlots: slotsDeliveryResponse.data.slot || [],
        preorderSlots: slotsPreorderResponse.data.slot || []
      })
    })
  }

  componentWillUnmount () {
    this.zonesApi && this.zonesApi.cancel()
    this.slotsApi && this.slotsApi.cancel()
    window.removeEventListener('resize', this.mobileView, false)
  }

  update (storeId) {
    this.getZonesAndSlots(storeId)
  }
  handleImportClick () {
    this.setState({
      showImportModal: true
    })
    console.log(this.state)
  }

  render () {
    const options = {
      pickupSlots: this.state.pickupSlots,
      deliverySlots: this.state.deliverySlots,
      preorderSlots: this.state.preorderSlots,
      zones: this.state.zones,
      type: [...orderTypeOptions, {text: getMessage('cp.rb_preorder'), value: 'RB_PREORDER'}],
      daysOfWeek: this.state.daysOfWeek,
      date: this.state.date,
      onDateChange: this.onDateChange,
      showImportModal: this.state.showImportModal
    }
    return (
      <ListingPage
        className='capacity-planning'
        api={{
          url: '/logistics-service/zone-capacity',
          transform: response => response.data.capacityPlanning,
          updateApiParams: (
            prevParams,
            newParams,
            prevStateParams,
            newStateParams
          ) => {
            if (
              prevStateParams &&
              newStateParams &&
              prevStateParams.storeId !== newStateParams.storeId
            ) {
              this.update(newStateParams.storeId)
              return {
                shouldUpdate: true,
                params: {
                  storeId: newStateParams.storeId
                }
              }
            }
            return null
          }
        }}
        emptyState={EmptyState}
        headerActions={({ onAction }) => (
          <React.Fragment>
            <div className='datepick-wrapper'>
              <SingleDatePicker
                allowAllDates
                displayFormat='YYYY-MM-DD'
                onChange={this.onDateChange}
                value={this.state.date}
              />
            </div>
            <span className='today-heading'>
              <Link to='/operations/capacity-planning/today'>Today</Link>
            </span>
            <button
              className='primary button import-button'
              onClick={() => {
                onAction(TABLE_ACTIONS.IMPORT)
              }}>
              <span className='text'>Import Capacity</span>
            </button>
            <button
              className='primary button margin-left'
              onClick={() => {
                onAction(TABLE_ACTIONS.ADD)
              }}
            >
              {' '}
              <span className='text'>{`+ ${getMessage('cp.add')}`}</span>
            </button>
          </React.Fragment>
        )}
        editHeading={getMessage('cp.edit')}
        importHeading='Batch Import Capacity Planning'
        addHeading={getMessage('cp.add')}
        tableProperties={tableProperties(options)}
        menu={this.props.menu}
        title={getMessage('cp.title')}
        storeDependent
        modalClassName='capacity-planning-form'
        form={{
          component: CapacityPlanningForm,
          options: options,
          allowDelete: true,
          transformSubmit: formData => {
            const data = Object.assign({}, formData)
            if (data.capacityType === 'DAY') {
              delete data.date
            } else {
              if (data.date && !data.date.includes('T')) {
                data.date = data.date + 'T00:00:00Z'
              }
              delete data.day
            }
            if (data.orderType === 'PICKUP') {
              delete data.zoneId
            }
            delete data.capacityType
            delete data.zone
            delete data.slot
            delete data.id
            return data
          }
        }}
        importDialog={{
          component: CapacityModal
        }}
        filters={{
          component: CapacityPlanningFilter,
          options: options,
          transformSubmit: formData => {
            return formData
          }
        }}
      />

    )
  }
}
