import React, { Component } from 'react'
import { getMessage } from '../../../../../../lib/translator'
import { Row, Cell } from '../../../../../../components/Table'
import Image from '../../../../../../components/Image'
import substitutionIcon from './substitution-icon.svg'
import EditPacklistItem from '../../../EditPackedDeliveryOrder/EditPacklistItem'
import { Link } from 'react-router-dom'
import {
  getItemData,
  getCalculatedItemData,
} from 'lib/commonlyused/orderCalculations'
import '../style.scss'

const isMarketplaceSku = (clientItemId) => clientItemId >= 90000000

class PacklistItemsView extends Component {
  getAmendmentText(index) {
    switch (index) {
      case 0:
        return getMessage('saleOrder.details.firstAmendment')
      case 1:
        return getMessage('saleOrder.details.secondAmendment')
      default:
        return ''
    }
  }

  getThresholdValues(
    { shortPickingThreshold, overPickingThreshold },
    orderedQuantity
  ) {
    const shortPickingThresholdValue = (
      shortPickingThreshold * orderedQuantity
    ).toFixed(3)
    const overPickingThresholdValue = (
      overPickingThreshold * orderedQuantity
    ).toFixed(3)
    return `(${shortPickingThresholdValue}kg - ${overPickingThresholdValue}kg)`
  }

  render() {
    const {
      item,
      index,
      itemHasSubstitution,
      isSubstitutedItem,
      isPendingOrCancelled,
      canEditPacklistItems,
    } = this.props

    const {
      brandName,
      packedQuantity,
      orderedQuantity,
      soldByWeight,
      amendmentItemIndex,
      name,
      image,
      clientItemId,
      itemId,
    } = getItemData(item, 'packlist')

    const { fixedDecimalPlace } = getCalculatedItemData(
      { ...item, isPendingOrCancelled },
      'packlist'
    )

    const rowClasses = []
    if (index === 0) {
      rowClasses.push('offset-top')
    }
    if (itemHasSubstitution) {
      rowClasses.pop()
      rowClasses.push('child-row')
    }

    return (
      <Row key={`${itemId}_${index}`} className={rowClasses.join(' ')}>
        <Cell className="item-image">
          {!itemHasSubstitution && (
            <React.Fragment>
              <Image bordered size="sm" src={image} />
              {isSubstitutedItem && (
                <img
                  className="substitution-icon-img"
                  src={substitutionIcon}
                  alt={getMessage('deliveryOrder.details.substitutedIndicator')}
                />
              )}
            </React.Fragment>
          )}
        </Cell>
        <Cell className="item-name">
          <Link to={`/catalogue/products/edit/${itemId}`} className="item-link">
            {itemHasSubstitution && <Image bordered size="sm" src={image} />}
            <small className="text-muted">
              <div className="product-name">
                &nbsp;
                <span className="brand-name">{brandName}</span>
                &nbsp;
                {name}
              </div>
              {amendmentItemIndex !== -1 && (
                <div className="amendment-item-text-packlist">
                  {this.getAmendmentText(amendmentItemIndex)}
                </div>
              )}
            </small>
          </Link>
        </Cell>
        <Cell className="client-item-id" style={{ textAlign: 'left' }}>
          <Link to={`/catalogue/products/edit/${itemId}`}>
            <div className="item-table-mobile-view">
              {getMessage('order.details.itemsTable.header.clientItemId')}
            </div>
            {clientItemId}
            {isMarketplaceSku(clientItemId) ? (
              <span className="mkp-label">(MKP)</span>
            ) : (
              <span />
            )}
          </Link>
        </Cell>

        <Cell className="item-original text-right">
          <span className="mobile-view-header">
            {getMessage('order.details.itemsTable.header.quantity')}
          </span>
          <small className="text-muted">
            {soldByWeight
              ? `${Number(orderedQuantity).toFixed(fixedDecimalPlace)}kg`
              : Number(orderedQuantity).toFixed(fixedDecimalPlace)}
            <br />
            {soldByWeight && this.getThresholdValues(item, orderedQuantity)}
          </small>
        </Cell>
        <Cell className="item-final text-right">
          <span className="mobile-view-header">
            {getMessage('order.details.itemsTable.header.picked')}
          </span>
          {canEditPacklistItems ? (
            <EditPacklistItem
              item={item}
              itemIndex={index}
              packlistIndex={this.props.packlistIndex}
              isSoldByWeight={soldByWeight}
              onEditItemQuantity={this.props.onEditItemQuantity}
            />
          ) : isPendingOrCancelled ? (
            <small className="text-muted">-</small>
          ) : (
            <small className="text-muted">
              {soldByWeight
                ? `${Number(packedQuantity).toFixed(fixedDecimalPlace)}kg`
                : Number(packedQuantity).toFixed(fixedDecimalPlace)}
            </small>
          )}
        </Cell>
      </Row>
    )
  }
}

export default PacklistItemsView
