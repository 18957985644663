import React from 'react'
import { getMessage } from 'lib/translator'
import { Input } from 'components/Form'
import { ANALYTICS_PLACEHOLDER } from './utils'
import Analytic from '../Analytic'

const Frequency = ({
  errorFields,
  dismissErrors,
  frequencyPerDay,
  generateStateMappers,
}) => {
  return (
    <>
      <Input
        type="number"
        name="frequencyPerDay"
        label={getMessage('app.popup.frequency')}
        placeholder={getMessage('app.popup.frequency.placeholder')}
        {...generateStateMappers({
          stateKeys: ['view', 'frequencyPerDay'],
          loseEmphasisOnFill: true,
        })}
      />
      <Input
        type="number"
        name="interval"
        prefix="minute"
        className="interval"
        readOnly={frequencyPerDay === 1}
        label={getMessage('app.popup.interval')}
        placeholder={getMessage('app.popup.interval.placeholder')}
        {...generateStateMappers({
          stateKeys: ['view', 'intervalInMinutes'],
          loseEmphasisOnFill: true,
        })}
      />
      <Analytic
        type="view"
        errorFields={errorFields}
        dismissErrors={dismissErrors}
        placeholder={ANALYTICS_PLACEHOLDER}
        generateStateMappers={generateStateMappers}
        stateKeys={['view', 'analytic']}
      />
    </>
  )
}

export default Frequency
