import React, { Component } from 'react'
import Tabs from '../../../components/Tabs'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import { getMessage } from '../../../lib/translator'
import ListingComponent from './ListingComponent'

import './style.css'
import { isExtensionEnabled } from '../../../lib/auth'
import DateTimeSearchFilter from './Search'

export const buildObj = data => {
  const startTime = data.startTime ? data.startTime : '00:00:00'
  const endTime = data.endTime ? data.endTime : '00:00:00'
  const obj = {
    ...(data.startDate && { startTime: `${data.startDate} ${startTime}` }),
    ...(data.endDate && { endTime: `${data.endDate} ${endTime}` }),
    ...(data.name && { name: data.name }),
  }
  return obj
}

class SearchConfiguration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      currentSearch: {},
    }
    this.tabs = [
      { name: 'Search Terms', pageType: 'SEARCH' },
      { name: 'Category', pageType: 'CATEGORY' },
    ]

    if (isExtensionEnabled('MultiBrandSupport')) {
      this.tabs.push({
        name: 'Brand',
        pageType: 'BRAND',
      })
    }
    if (isExtensionEnabled('ProductTagSupport')) {
      this.tabs.push({
        name: 'Tag',
        pageType: 'TAG',
      })
    }
    this.changeTab = this.changeTab.bind(this)
    this.handleSearchTerm = this.handleSearchTerm.bind(this)
  }

  changeTab(activeIndex) {
    this.setState({ activeIndex })
  }

  handleSearchTerm(data) {
    this.setState({ currentSearch: buildObj(data) })
  }
  render() {
    const { tabs, state } = this
    const { activeIndex } = state

    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
        className="search-config"
      >
        <h1>{getMessage('search-configuration.header')}</h1>
        <code className="search-notify">This page will be removed by 29 Sep. You are not be able to add or edit configurations in Backoffice anymore. Please transfer your configuration to Algolia by 29 Sep.</code>
        <DateTimeSearchFilter handleSearchTerm={this.handleSearchTerm} />
        <Tabs
          items={tabs.map(tab => `${tab.name}`)}
          default={this.state.activeIndex}
          onClick={index => {
            this.changeTab(index)
          }}
        />
        <ListingComponent
          pageType={this.tabs[activeIndex]['pageType']}
          dates={this.state.currentSearch}
        />
      </AuthenticatedPage>
    )
  }
}

export default SearchConfiguration
