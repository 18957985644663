import React, { Component } from 'react'
import {
  Input,
  Radio,
  Select,
  Checkbox,
  ProductSearch,
  BrandSearch,
  CategorySearch,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import ItemTable from './ItemTable'
import { isAllRulesValid } from '../../../../lib/commonlyused'
import deleteIcon from './delete.svg'
import { handleFocus } from './FormTabs/utils'

class BMIN extends Component {
  constructor(props) {
    super(props)

    this.state = {
      slotOptions: [],
    }
    this.validationHandler = this.validationHandler.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
    this.initData = this.initData.bind(this)
    this.handleAddRule = this.handleAddRule.bind(this)
    this.handleDeleteRule = this.handleDeleteRule.bind(this)
  }

  initData() {
    const { method, _this, onUpdateValues } = this.props
    if (method === 'add') {
      const { values } = _this && _this.state
      if (!(values.rule && values.rule.elementGroups)) {
        onUpdateValues({
          ...values,
          rule: { ...values.rule, elementGroups: [{ total: {} }] },
        })
      }
    }
  }

  validationHandler(parent) {
    const validations = JSON.parse(JSON.stringify(parent.state.validations))
    parent.setState({
      validations,
    })
  }

  submitHandler(formData) {
    const data = JSON.parse(JSON.stringify(formData))
    const comboDiscount = data.rule.itemDiscountType === 'COMBO_DISCOUNT'
    let nonEntity = false
    if (!data.includes) {
      nonEntity = true
    }
    const entityType = data.entityType.toLowerCase()
    const entityIds = Array.isArray(data[entityType])
      ? data[entityType].map((item) => item.id)
      : [data[entityType].id]
    const newRule = {
      elementGroups: data.rule.elementGroups.map((eg) => ({
        ...eg,
        maxDiscount: eg.maxDiscount !== '' ? eg.maxDiscount : undefined,
      })),
      entity: {
        type: data.entityType === 'PRODUCT' ? 'VARIANT' : data.entityType,
      },
      entityIds: entityIds,
    }

    if (nonEntity) {
      newRule['non-entity'] = JSON.parse(JSON.stringify(newRule.entity))
      delete newRule.entity
    }
    if (comboDiscount) {
      newRule.isCombo = true
    }
    data.rule = newRule
    delete data.includes
    delete data.ruleDetail
    delete data.product
    delete data.category
    delete data.brand
    delete data.total
    delete data.entity
    return data
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.initData()
    }
  }

  componentDidMount() {
    this.initData()
  }

  handleAddRule(e) {
    e && e.preventDefault()
    const { _this } = this.props
    const { values } = _this.state
    const { rule: ruleProp } = values || {}
    const { elementGroups } = ruleProp || {}

    let rule = ruleProp
    if (!elementGroups) {
      rule = { ...rule, elementGroups: [{ total: {} }] }
    }

    rule = { ...rule, elementGroups: [...rule.elementGroups, { total: {} }] }

    _this.setState({ pressedSubmitWithCurrentData: false })

    this.props.onUpdateValues({ ...values, rule })
  }

  handleDeleteRule(index) {
    const { _this } = this.props
    const { values } = _this.state
    const { rule: ruleProp } = values || {}

    let rule = ruleProp
    if (rule && rule.elementGroups) {
      const elementGroups = rule.elementGroups.filter((_, idx) => idx !== index)
      rule = { ...rule, elementGroups }
    }

    this.props.onUpdateValues({ ...values, rule })
  }

  render() {
    const {
      discountTypes,
      itemDiscountTypes,
      _this,
      hasOfferStarted,
      type,
      method,
    } = this.props
    const { values } = _this.state
    const entityTypeLowerCase = values.entityType.toLowerCase()
    const includes = values.includes
    let entityText = ''
    if (entityTypeLowerCase === 'product') {
      entityText = 'Products'
    } else if (entityTypeLowerCase === 'category') {
      entityText = 'Categories'
    } else {
      entityText = 'Brands'
    }

    const items = values[entityTypeLowerCase]
    const itemsLength = items ? items.length : 0

    const { rule } = values || {}

    const { elementGroups } = rule || {}

    return (
      <div className="offers-form BMIN">
        <div className="offer-fields">
          {entityTypeLowerCase === 'product' && (
            <ProductSearch
              className="product-searchable-max"
              key={`item-searchable-pdt`}
              name={`item-searchable-pdt`}
              label={getMessage(`offer.${entityTypeLowerCase}`)}
              placeholder={getMessage(
                `offer.${entityTypeLowerCase}.placeholder`
              )}
              onChange={_this.addItem}
              readOnly={hasOfferStarted}
            />
          )}
          {entityTypeLowerCase === 'brand' && (
            <BrandSearch
              name="item-serchable-brand"
              className="product-searchable-max"
              label={getMessage(`offer.${entityTypeLowerCase}`)}
              placeholder={getMessage(`offer.brands.placeholder`)}
              {..._this.generateStateMappers({
                stateKeys: ['brand'],
                loseEmphasisOnFill: true,
              })}
              readOnly={hasOfferStarted}
              multiple
            />
          )}
          {entityTypeLowerCase === 'category' && (
            <CategorySearch
              name="category"
              className="product-searchable-max"
              transformResponse={(response) => response.data.category}
              label={getMessage(`offer.${entityTypeLowerCase}`)}
              placeholder={getMessage(`offer.categories.placeholder`)}
              {..._this.generateStateMappers({
                stateKeys: ['category'],
                loseEmphasisOnFill: true,
              })}
              multiple
              readOnly={hasOfferStarted}
            />
          )}
          <Radio
            name="discount-type-main"
            label={getMessage('offer.main.discount.type')}
            placeholder={getMessage('offer.main.discouont.type.placeholder')}
            options={itemDiscountTypes}
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'itemDiscountType'],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
          {elementGroups
            ? elementGroups.map((_, index) => (
                <div className="row rule-block" key={`rule-block-${index}`}>
                  <div className="offer-rule-header">
                    {Boolean(index) && (
                      <img
                        className="rule-delete-icon"
                        onClick={() => this.handleDeleteRule(index)}
                        src={deleteIcon}
                        alt="delete"
                      />
                    )}
                  </div>
                  <Input
                    name="min-amount"
                    className="shipping-cart"
                    type="number"
                    onFocus={handleFocus}
                    step="0.01"
                    required
                    readOnly={hasOfferStarted}
                    label={getMessage('offer.min.amount.label')}
                    placeholder={getMessage('offer.min.amount.placeholder')}
                    {..._this.generateStateMappers({
                      stateKeys: ['rule', 'elementGroups', index, 'minAmount'],
                      loseEmphasisOnFill: true,
                    })}
                  />
                  <Select
                    name="discount-type"
                    className="row-element"
                    required
                    label={getMessage('offer.discounttype')}
                    placeholder={getMessage('offer.select.placeholder')}
                    options={discountTypes}
                    {..._this.generateStateMappers({
                      stateKeys: ['rule', 'elementGroups', index, 'total', 't'],
                      loseEmphasisOnFill: true,
                    })}
                    onChange={(selectedType) =>
                      _this.handleDiscountChange(selectedType, [
                        'elementGroups',
                        index,
                      ])
                    }
                    disabled={hasOfferStarted}
                  />
                  <Input
                    name="discount-value"
                    className="row-element"
                    type="number"
                    onFocus={handleFocus}
                    step={0.01}
                    min={0}
                    required
                    label={getMessage('offer.discountvalue')}
                    placeholder={getMessage('offer.discountvalue.placeholder')}
                    {..._this.generateStateMappers({
                      stateKeys: ['rule', 'elementGroups', index, 'total', 'v'],
                      loseEmphasisOnFill: true,
                    })}
                    readOnly={
                      hasOfferStarted ||
                      (values &&
                        values.rule &&
                        values.rule.elementGroups[index].total &&
                        values.rule.elementGroups[index].total.t === `FREE`)
                    }
                  />
                  {values &&
                    values.rule &&
                    values.rule.elementGroups &&
                    values.rule.elementGroups[index] &&
                    values.rule.elementGroups[index].total &&
                    values.rule.elementGroups[index].total.t ===
                      `PERCENT_OFF` && (
                      <Input
                        name="max-discount-value"
                        type="number"
                        onFocus={handleFocus}
                        className="row-element"
                        min={0}
                        step={0.01}
                        label={getMessage('offer.maxdiscountvalue')}
                        placeholder={getMessage(
                          'offer.discountvalue.placeholder'
                        )}
                        {..._this.generateStateMappers({
                          stateKeys: [
                            'rule',
                            'elementGroups',
                            index,
                            'maxDiscount',
                          ],
                          loseEmphasisOnFill: true,
                        })}
                        readOnly={
                          hasOfferStarted ||
                          (values &&
                            values.rule &&
                            values.rule.elementGroups &&
                            values.rule.elementGroups[index].total &&
                            values.rule.elementGroups[index].total.t === `FREE`)
                        }
                      />
                    )}
                </div>
              ))
            : null}
          {method === 'add' && type === 'BMIN' && (
            <button
              className="primary add-rule-btn"
              onClick={this.handleAddRule}
              disabled={
                !isAllRulesValid(rule.elementGroups, ['minAmount', 'total'])
              }
            >
              +
            </button>
          )}
        </div>
        <Checkbox
          className="entity-checkbox"
          label={`Includes`}
          name={`entity`}
          {..._this.generateStateMappers({
            stateKeys: ['includes'],
            loseEmphasisOnFill: true,
          })}
        />
        {itemsLength ? (
          <div>
            <p>
              {includes
                ? `${entityText} ${getMessage('offer.includeText')} :`
                : `${entityText} ${getMessage('offer.excludeText')} :`}
            </p>
            <ItemTable
              buyAny
              entityType={entityTypeLowerCase}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
              hasOfferStarted={hasOfferStarted}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

export default BMIN
