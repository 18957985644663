import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { NewOrderTabs } from '../constant'
import { Button, Text, View, FullScreen } from '../ui'
import { ProductLine } from './ProductLine'
import { useFormikContext } from 'formik'
import { getSlimCartProductData, groupCart, xUserHeaders } from '../helper'
import { GiftLine } from './GiftLine'
import Loader from 'components/Loader'
import { FE_GATEWAY_API_URL } from 'config/app'
import API from 'lib/api'

const LoadingScreen = () => {
  return (
    <FullScreen>
      <Loader />
    </FullScreen>
  )
}

export const ReviewOrderTab = ({
  setActiveIndex,
  goBack,
  setCartResponse,
  cart: cartResFromCheckout,
  selectedCampaign,
  onInputProductQuantity,
}) => {
  const { values, setFieldValue } = useFormikContext()

  const [loading, setLoading] = useState(true)
  const [checkoutError, setCheckoutError] = useState(false)
  const [productsTimestamp, setProductsTimestamp] = useState({})

  // values.products should have same object keys as values.cart
  // values.products: [ SKU: quantity ]
  // values.cart: [ SKU: boolean ]
  const allAvailableProducts = values.allAvailableProducts

  // helper function to create payload for checkout API
  const checkoutPayload = (productQuantityArr, productIsCheckedArr) =>
    Object.entries(productQuantityArr).map(([sku, quantity]) => ({
      id: allAvailableProducts.find((p) => p.sku === sku).id.toString(), // must use DBP product ID
      isChecked: productIsCheckedArr[sku] === true,
      q: String(quantity),
      t: Number(new Date()),
    }))

  // Helper function to update local cart data based on checkout response
  // Compare syncedCartData with values.cart and values.products
  // if response is different, update values.cart and values.products
  // if not, do not update.
  // This is to prevent infinite loop of useEffect
  const updateLocalCart = (syncedCartData) => {
    const chargableItems = syncedCartData.filter(
      (item) => item.isFree === false
    )
    const newProductsWithQuantity = {}
    const newProductsWithChecked = {}
    const productsAddedTime = {}
    const newAvailableProducts = []
    chargableItems.forEach((item) => {
      newProductsWithQuantity[item.product.clientItemId] = parseInt(item.q)
      newProductsWithChecked[item.product.clientItemId] = item.isChecked
      productsAddedTime[item.product.clientItemId] = item.t
      newAvailableProducts.push(getSlimCartProductData(item))
    })

    const isProductQuantityDifferent = !isEqual(
      newProductsWithQuantity,
      values.products
    )
    const isProductCheckedDifferent = !isEqual(
      newProductsWithChecked,
      values.cart
    )

    setProductsTimestamp(productsAddedTime) // save timestamp for sorting in UI

    if (isProductQuantityDifferent || isProductCheckedDifferent) {
      setFieldValue('products', newProductsWithQuantity)
      setFieldValue('cart', newProductsWithChecked)
      setFieldValue('allAvailableProducts', [
        ...(values.allAvailableProducts ?? []),
        ...newAvailableProducts,
      ])
    }
  }

  // checkout will also create cart, if we haven't called /cart before
  const checkout = () => {
    setLoading(true)
    setCheckoutError(false)

    new API({ url: `${FE_GATEWAY_API_URL}/checkout` })
      .post(
        {
          campaignId: Number(selectedCampaign), // must be number, not string
          cart: {
            isCartMerge: false,
            items: checkoutPayload(values.products, values.cart),
          },
          couponCodes: [],
          isCheckerEnabled: true,
          isDirectFulfilmentEnabled: true,
          isSellerCheckerEnabled: true,
          isSellerTimeSlotEnabled: true,
          orderType: 'RB_PREORDER',
          storeId: values.storeId,
        },
        xUserHeaders({
          userId: values.userData.userId,
          userUid: values.userData.userUid,
        })
      )
      .then((response) => {
        const groupedCartItems = groupCart(response.data?.cart?.items)
        setCartResponse({
          ...response.data,
          cart: { ...response.data?.cart, items: groupedCartItems },
        })
        updateLocalCart(groupedCartItems)
      })
      .catch((err) => {
        setCheckoutError(
          err.message // default value: 'An error has occured'
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    // change in values.cart means user checks/unchecks, or the quantity becomes zero
    // change in values.products means user changes the quantity (non-zero)
    checkout()
  }, [JSON.stringify(values.cart), JSON.stringify(values.products)])

  const subTotal = cartResFromCheckout?.youPay || 0
  const gifts = cartResFromCheckout?.giftOffers || []

  return (
    <>
      {loading && <LoadingScreen />}
      <View direction="column" alignItems="start" margin="0 0 100px">
        {checkoutError && (
          <>
            <Text color="red">Unable to checkout:</Text>
            <Text color="red">{checkoutError}</Text>
          </>
        )}
        <Text variant="h4" fontWeight={700} fontSize={20}>
          Order details
        </Text>
        <View height={1} width="100%" />
        {Object.keys(values.products)
          .sort((a, b) => productsTimestamp[a] - productsTimestamp[b])
          .map((sku) => (
            <ProductLine
              item={allAvailableProducts.find((p) => p.sku === sku)}
              key={sku}
              onChange={onInputProductQuantity(sku)}
              quantity={values.products[sku]}
            />
          ))}
        <View
          justifyContent="space-between"
          padding="16px 0 0"
          style={{
            borderTop: '1px solid #DADEE0',
          }}
        >
          <View direction="column" alignItems="start">
            <Text fontWeight={700}>Subtotal</Text>
            <Text fontSize={14}>Final price calculated at cashier.</Text>
          </View>

          {loading ? (
            <Text>Recalculating...</Text>
          ) : (
            <Text fontWeight={900} fontSize={20}>
              ${subTotal.toFixed(2)}
            </Text>
          )}
        </View>
        {gifts.length > 0 && (
          <View
            justifyContent="start"
            margin="24px 0 0"
            padding="16px 0 0"
            style={{
              borderTop: '1px solid #DADEE0',
              fontWeight: 700,
            }}
          >
            Spend more to receive free gift
          </View>
        )}
        {gifts.length > 0 && <GiftLine tiers={gifts[0].tiers} />}
      </View>
      <View
        direction="column"
        position="fixed"
        data-name="fixedButton"
        background="white"
        style={{ bottom: 0, left: 0 }}
      >
        <View direction="column" padding="0 0 12px" gap={10}>
          <Button
            disabled={Object.values(values.cart).every((v) => !v)}
            onClick={() => setActiveIndex(NewOrderTabs.PICKUP)}
          >
            Select pick-up options
          </Button>
          <Button variant="secondary" onClick={() => goBack()}>
            Add more items
          </Button>
        </View>
      </View>
    </>
  )
}
