import React, { Component } from 'react'
import { Input } from '../../../../components/Form'
import { Actions } from '../form'
import { getMessage } from '../../../../lib/translator'
import MgageIcon from './mGage.png'

class Mgage extends Component {
  render() {
    const { parent, type, provider, allowKey } = this.props
    const _this = parent
    const readOnly = _this.getState([allowKey]) === false
    return (
      <React.Fragment>
        <Actions parent={_this} icon={MgageIcon} allowKey={allowKey} />
        <div className="form-fields">
          <Input
            name="signature"
            label={getMessage('communication.signature')}
            placeholder={getMessage('communication.signature.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'SIGNATURE'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <h5>{getMessage('communication.transactional')}</h5>
          <Input
            name="transactional-aid"
            label={getMessage('communication.aid')}
            placeholder={getMessage('communication.aid.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'TRANSACTIONAL', 'AID'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            type="password"
            label={getMessage('communication.pin')}
            placeholder={getMessage('communication.pin.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'TRANSACTIONAL', 'PIN'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <h5>{getMessage('communication.promotional')}</h5>
          <Input
            name="promotional-aid"
            label={getMessage('communication.aid')}
            placeholder={getMessage('communication.aid.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'PROMOTIONAL', 'AID'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            name="promotional-pin"
            label={getMessage('communication.pin')}
            placeholder={getMessage('communication.pin.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'PROMOTIONAL', 'PIN'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default Mgage
