import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'components/Loader'
import { getMessage } from 'lib/translator'
import { getSession } from 'lib/auth'
import API from 'lib/api'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import ShowSaleOrderDetails from './ShowSaleOrderDetails'
import './style.scss'

export default class SaleOrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saleOrderData: null,
      loading: false,
      failed: false,
      communicationConfig: null,
      orderConfig: null,
      error: null
    }
    this.organization = getSession().organization;
    this.getSaleOrder = this.getSaleOrder.bind(this);
  }

  onError(error) {
    if (error && error.message) {
      this.setState({
        error: error.message
          .split(':')
          .slice(1)
          .join(':'),
      })
    }
    throw error
  }

  async getSaleOrder() {
    this.setState({ loading: true, failed: false });
    const referenceNumber = this.props.router.match.params.id;
    this.getSaleOrderApi = new API({ url: `/order-service/v3/salesOrders/${referenceNumber}`})
    this.getSaleOrderApi.get()
    .then(
      response => {
        this.setState({ saleOrderData: response.salesOrder });
      },
      error => {
        this.setState({ failed: true })
        if (error.code === 401) {
          throw error
        }
      }
    )
    .finally(() => this.setState({ loading: false }))
  }

  getCommunicationConfig() {
    this.communicationApi = new API({ url: `/account-service/config` })
    this.communicationApi.get()
      .then(response => {
        this.setState({
          communicationConfig: response.data.config.communication,
          orderConfig: response.data.config.order,
        })
      })
      .catch(error => {
        throw error
      })
  }

  async componentDidMount() {
    this.getSaleOrder();
    this.getCommunicationConfig();
  }

  componentWillUnmount() {
    this.communicationApi && this.communicationApi.cancel()
    this.getSaleOrderApi && this.getSaleOrderApi.cancel()
  }

  render() {
    const referenceNumber = this.props.router.match.params.id;
    const contentLoaded = !this.state.loading && !this.state.failed && this.state.saleOrderData;

    return (
      <AuthenticatedPage
        className="sale-order-details"
        menu={this.props.menu}
      >
        <div className="flex-around">
          <h1 className="title heading">
            {getMessage('saleOrder.details.heading')}&nbsp;
            <Link to={`/customer-support/sale-orders/${referenceNumber}`}>
              <strong className="reference-number">
                {referenceNumber}
              </strong>
            </Link>
          </h1>
        </div>

        {this.state.loading ? <Loader /> : null}
        {this.state.failed && (
          <div className="retry-message text-muted">
            {getMessage('order.details.errors.onLoad')}
          </div>
        )}

        {contentLoaded && (
          <ShowSaleOrderDetails
            saleOrderData = {this.state.saleOrderData}
            url={this.props.url}
            currency = {this.organization.currency}/>
        )}
      </AuthenticatedPage>
    )
  }
}
