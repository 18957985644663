export const STORE_TECH_BASE_URL =
  process.env.REACT_APP_STORE_TECH_API_URL ||
  'https://storetech-uat.fairprice.com.sg'

export const PAYMENT_SEARCH_CATEGORIES = {
  PAYMENT_REF: 'payment-ref',
  CUSTOMER_EMAIL: 'customer-email',
  CUSTOMER_PHONE: 'customer-phone',
}

export const PAYMENT_SEARCH_CATEGORIES_ADV = {
  POS_ID: 'pos-id',
  CASHIER_ID: 'cashier-id',
  STORE_TXN: 'store-txn',
  TRANSACTION_DATE: 'transaction-date',
}

export const PAYMENT_SEARCH_TYPES = {
  QUICK_SEARCH: 'quick-search',
  ADVANCED_SEARCH: 'advanced-search',
}

export const PAYMENT_REF_PREFIX = 'A-'

export const PAYMENT_REF_OTHER_PREFIX = 'B-'

export const PAYMENT_PAGINATION_SIZE = 25

export const GIFTING_PAGINATION_SIZE = 20

export const CURRENCY = '$'
