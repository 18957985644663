import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import ReactBigCalendar from 'react-big-calendar'
import moment from 'moment'
ReactBigCalendar.setLocalizer(ReactBigCalendar.momentLocalizer(moment))

export default class BigCalendar extends Component {
  render() {
    const {
      date,
      events,
      onSelectEvent,
      eventPropGetter,
      defaultView,
      accessors,
      monthEvent,
    } = this.props
    return (
      <ReactBigCalendar
        date={date ? new Date(date) : new Date()}
        onNavigate={() => {}}
        defaultView={defaultView}
        events={events}
        components={{
          event: monthEvent,
        }}
        startAccessor={accessors.startAccessor}
        endAccessor={accessors.endAccessor}
        titleAccessor={accessors.titleAccessor}
        style={{ minHeight: '500px' }}
        onSelectEvent={onSelectEvent}
        views={['month']}
        toolbar={false}
        eventPropGetter={eventPropGetter}
      />
    )
  }
}

BigCalendar.defaultProps = {
  date: new Date(),
  defaultView: 'month',
  onSelectEvent: false,
  eventPropGetter: () => true,
}

BigCalendar.propTypes = {
  events: PropTypes.array.isRequired,
}
