import React, { Component } from 'react'
import { Row, Cell } from '../../../components/Table'
import { getMessage } from '../../../lib/translator'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import API from '../../../lib/api'
import {
  isExtensionEnabled,
  getSession,
  getExtensionDetails,
} from '../../../lib/auth'
import BannerEditForm from './Forms'
import BannerFilters from './Filters'
import bannersEmpty from './images/banners_empty.svg'
import imagePlaceholder from './images/image-placeholder.svg'
import moment from 'moment'

import './style.css'
import DraggableListingPage from '../DraggableListingPage'
import { formatDate, formatTime } from '../../../lib/datetime'
import { getLanguages } from '../../../lib/commonlyused'
import { get } from '../../../lib/storage'
import { IS_STAGING } from '../../../config/app'
import { createTransformSubmit } from './createTransformSubmit'
const eng = { name: 'English', code: 'en' }

const O2O_STORE_SELECTOR = 'O2O_STORE_SELECTOR'

export const transformBannerFilter = (formData) => {
  const data = formData
  if (moment(data.startTime).isSame(Date.now(), 'day')) {
    if (data.startTime) {
      const startDate = data.startTime.split(' ') || data.startTime
      if (startDate[1]) {
        data.startTime = startDate[0] + ' '
        data.startTime += data.bannerStartTime
          ? data.bannerStartTime + ':00'
          : startDate[1]
      } else {
        data.startTime = data.startTime + ' '
        data.startTime += data.bannerStartTime
          ? data.bannerStartTime + ':00'
          : '00:00:00'
      }
    }
    const isBefore = new Date() > new Date(data.startTime)
    if (isBefore) {
      data.startTime = data.startTime || moment().format('YYYY-MM-DD HH:mm:ss')
    }
  } else {
    if (data.startTime) {
      const startDate = data.startTime.split(' ') || data.startTime
      if (startDate[1]) {
        data.startTime = startDate[0] + ' '
        data.startTime += data.bannerStartTime
          ? data.bannerStartTime + ':00'
          : startDate[1]
      } else {
        data.startTime = data.startTime + ' '
        data.startTime += data.bannerStartTime
          ? data.bannerStartTime + ':00'
          : '00:00:00'
      }
    }
  }

  if (data.endTime) {
    const endDate = data.endTime.split(' ') || data.endTime
    if (endDate[1]) {
      data.endTime = endDate[0] + ' '
      data.endTime += data.bannerEndTime
        ? data.bannerEndTime + ':00'
        : endDate[1]
    } else {
      data.endTime = data.endTime + ' '
      data.endTime += data.bannerEndTime
        ? data.bannerEndTime + ':00'
        : '00:00:00'
    }
  }

  if (data.bannerStartTime) {
    delete data.bannerStartTime
  }
  if (data.bannerEndTime) {
    delete data.bannerEndTime
  }
  if (data.startTime === null) {
    delete data.startTime
  }
  if (data.endTime === null) {
    delete data.endTime
  }

  return data
}

const emptyState = {
  icon: bannersEmpty,
  message: getMessage('banners.empty.banner'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      + {getMessage('banners.view.add.button')}
    </button>
  ),
}

const ValidityField = ({ startTime, endTime }) => {
  return (
    <div>
      <span>{getMessage('banners.table.validity')}</span>
      {startTime && endTime ? (
        <small>
          {' '}
          {`${formatDate(startTime.split(' ')[0])}, ${formatTime(
            startTime.split(' ')[1]
          )} - ${formatDate(endTime.split(' ')[0])}, ${formatTime(
            endTime.split(' ')[1]
          )}`}
        </small>
      ) : (
        getMessage('banners.table.na')
      )}
    </div>
  )
}

const tableProperties = {
  headers: [
    getMessage('banners.header.image'),
    getMessage('banners.header.actions'),
  ],
  row: (props) => {
    const {
      imageUrl,
      id,
      onAction,
      startTime,
      endTime,
      scope,
      linkUrl,
      storeId,
      bannerType,
      pageType,
      sequence,
      pageTypeFilter,
    } = props
    const isPageTypeHome =
      !pageTypeFilter || pageTypeFilter.toLowerCase() === 'home'
    return (
      <div>
        <Row>
          <Cell className="banner-image">
            <img
              className="image-size"
              src={imageUrl || imagePlaceholder}
              alt="banner_image"
            />
            {pageType !== O2O_STORE_SELECTOR && linkUrl && (
              <small className="view-on-web">
                <a
                  href={`${IS_STAGING ? 'http://' : 'https://'}${
                    getSession().organization.domain
                  }${linkUrl}`}
                  target={'_blank'}
                >
                  {`${getMessage('banners.table.view.on.web.url')}`}
                </a>
              </small>
            )}
            <div className="banner-sequence">{sequence}</div>
            <div className="banner-image-fields">
              <ValidityField startTime={startTime} endTime={endTime} />
              {scope && (
                <div className="scope inline-block">
                  {scope && <span>{getMessage('banners.table.scope')}</span>}
                  {scope === 'STORE'
                    ? get('stores') &&
                      JSON.parse(get('stores'))
                        .map((store) => {
                          if (storeId.includes(store.id)) {
                            return store.name
                          }
                          return null
                        })
                        .filter((name) => Boolean(name))
                        .join(', ')
                    : 'Global'}
                </div>
              )}
              <div className="inline-block lg-padding-left">
                <span className="scope"> Banner type:</span>
                <strong>{bannerType} Banner</strong>
              </div>
              {id && (
                <div className="inline-block lg-padding-left">
                  <span className="scope">
                    {getMessage('banners.table.bannerId')}
                  </span>
                  <strong>{id}</strong>
                </div>
              )}
              <div>
                <div className="inline-block">
                  <span className="scope"> Page type:</span>
                  <strong>{pageType} </strong>
                </div>
              </div>
            </div>
          </Cell>
          <Cell className="table-action">
            <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
              <DropDownItem
                onClick={() => {
                  onAction(TABLE_ACTIONS.EDIT, { id })
                }}
              >
                {getMessage('banners.action.edit')}
              </DropDownItem>
              <DropDownItem
                onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}
              >
                {getMessage('banners.action.delete')}
              </DropDownItem>
            </DropDown>
          </Cell>
        </Row>
        {isPageTypeHome && (
          <div className="grid-banner-info">
            <ValidityField startTime={startTime} endTime={endTime} />
            <div>
              <span> Banner type: </span>
              <span>{bannerType}</span>
            </div>
          </div>
        )}
      </div>
    )
  },
}

export const pageTypes = {
  home: 'HOME',
  category: 'CATEGORY',
  brand: 'BRAND',
  productTag: 'PRODUCT_TAG',
  search: 'SEARCH',
  o2oStoreSelector: O2O_STORE_SELECTOR,
  linkpayInvoice: 'LINKPAY_INVOICE',
  promotions_all: 'PROMO_ALL',
  promotions_pricedropbuynow: 'PROMO_PDBN',
  promotions_elp: 'PROMO_ELP',
  omni_home: 'OMNI_HOMEPAGE',
}

export const scopes = {
  global: 'GLOBAL',
  storeSpecific: 'STORE',
}

class ManageBanners extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: [
        { text: 'All', value: 'All' },
        { text: getMessage('banners.pageType.home'), value: 'HOME' },
        { text: getMessage('banners.pageType.category'), value: 'CATEGORY' },
        {
          text: getMessage('banners.pageType.promotions_all'),
          value: 'PROMO_ALL',
        },
        {
          text: getMessage('banners.pageType.promotions_pricedropbuynow'),
          value: 'PROMO_PDBN',
        },
        {
          text: getMessage('banners.pageType.promotions_elp'),
          value: 'PROMO_ELP',
        },
        {
          text: getMessage('banners.pageType.omni_home'),
          value: 'OMNI_HOMEPAGE',
        },
      ],
      pageTypes,
      bannerType: [
        { text: 'All', value: 'All' },
        { text: 'BIG', value: 'BIG' },
        { text: 'SUB1', value: 'SUB1' },
        { text: 'SUB2', value: 'SUB2' },
      ],
      scopes,
    }
  }

  componentDidMount() {
    const productTagEnabled = isExtensionEnabled('ProductTagSupport')
    const brandsEnabled = isExtensionEnabled('MultiBrandSupport')
    const multiStoreEnabled = isExtensionEnabled('MultiStoreSupport')

    this.setState({ productTagEnabled, brandsEnabled, multiStoreEnabled })

    if (multiStoreEnabled) {
      this.storesApi = new API({ url: '/account-service/store' })
      this.setState({
        stores: JSON.parse(get('stores')),
      })
    }
    if (productTagEnabled) {
      const { pages: pagesState } = this.state
      pagesState.push({
        text: getMessage('banners.pageType.productTag'),
        value: 'PRODUCT_TAG',
      })
      this.setState({ pages: pagesState })
    }
    if (brandsEnabled) {
      const { pages: pagesState } = this.state
      pagesState.push({
        text: getMessage('banners.pageType.brand'),
        value: 'BRAND',
      })
      this.setState({ pages: pagesState })
    }
    const { pages } = this.state
    pages.push({ text: getMessage('banners.pageType.search'), value: 'SEARCH' })
    pages.push({
      text: getMessage('banners.pageType.o2o_store_selector'),
      value: 'O2O_STORE_SELECTOR',
    })
    pages.push({
      text: getMessage('banners.pageType.linkpay_invoice'),
      value: 'LINKPAY_INVOICE',
    })
    this.setState({ pages })
    const extension = getExtensionDetails('MultiLingualSupport')
    const id = extension && extension.id
    const availableLangs = [eng]
    if (id) {
      const api = new API({ url: `/account-service/extension/${id}` })
      api.get().then((response) => {
        const savedLangs = response.data.extension.config.globalConfig.languages
        savedLangs &&
          savedLangs.map((lng) => {
            const language = getLanguages().find((lang) => lang.code === lng)
            if (language && language.name !== 'english') {
              availableLangs.push({ ...language })
            }
            return null
          })
        this.setState({ availableLangs })
      })
    }
  }

  render() {
    const { menu } = this.props
    const {
      stores,
      pages,
      bannerType,
      multiStoreEnabled,
      productTagEnabled,
      brandsEnabled,
      pageTypes,
      scopes,
      availableLangs,
    } = this.state
    return (
      <div>
        <DraggableListingPage
          menu={menu}
          addNewItemToLast={true}
          className="manage-banners draggable-listing-page"
          title={getMessage('banners.header')}
          api={{
            url: '/promo-service/banner',
            params: {},
            transform: (response) => {
              let bannerData = response.data.banner
              if (Array.isArray(bannerData)) {
                bannerData = bannerData.map((banner) => {
                  const url = [...banner.url]
                  switch (banner.pageType) {
                    case pageTypes.category:
                      banner.categoryUrl = [...url]
                      break
                    case pageTypes.brand:
                      banner.brandUrl = [...url]
                      break
                    case pageTypes.productTag:
                      banner.productTagUrl = [...url]
                      break
                    case pageTypes.search:
                      banner.searchUrl = [...url]
                      break
                    default:
                      break
                  }
                  return banner
                })
              }
              return bannerData
            },
          }}
          editHeading={getMessage('banners.editForm.header')}
          addHeading={getMessage('banners.addForm.header')}
          tableProperties={tableProperties}
          modalClassName={'manage-banners-popup'}
          form={{
            component: BannerEditForm,
            overwriteWithApiParams: false,
            allowDelete: true,
            options: {
              multiStoreEnabled: multiStoreEnabled,
              stores: stores,
              pages: pages,
              bannerType: bannerType,
              pageTypes: pageTypes,
              scopes: scopes,
              productTagEnabled: productTagEnabled,
              brandsEnabled: brandsEnabled,
              availableLangs: availableLangs,
            },
            filterBeforeAdding: (response, _this) => {
              if (
                _this &&
                _this.state &&
                _this.state.data &&
                Object.keys(_this.state.data.filters).length === 0
              ) {
                return response
              }
              if (
                _this &&
                _this.state &&
                _this.state.data &&
                _this.state.data.filters &&
                _this.state.data.filters.pageType === response.pageType
              ) {
                return response
              }
              if (
                _this &&
                _this.state &&
                _this.state.data &&
                _this.state.data.filters.storeId
              ) {
                return response
              }
              return null
            },
            transformSubmit: createTransformSubmit({ pageTypes, scopes }),
          }}
          emptyState={emptyState}
          filters={{
            component: BannerFilters,
            forceShow: true,
            options: {
              multiStoreEnabled: multiStoreEnabled,
              stores: stores,
              pages: pages,
              bannerType,
              availableLangs: availableLangs,
            },
            transformSubmit: (formData) => {
              let data = Object.assign({}, formData)
              const { pageType } = data
              if (data.bannerType === 'All') {
                delete data.bannerType
              }
              if (pageType === 'All') {
                delete data.pageType
              }
              /* istanbul ignore next */
              data = transformBannerFilter(data)
              data.url = data[pageType] && data[pageType].slug
              if (pageType === 'SEARCH') data.url = data['SEARCH_TERM']
              delete data['CATEGORY']
              delete data['BRAND']
              delete data['PRODUCT_TAG']
              delete data['SEARCH_TERM']
              return data
            },
          }}
          headerActions={({ onAction }) => (
            <button
              data-testid="add_button"
              className="primary button"
              onClick={() => {
                onAction(TABLE_ACTIONS.ADD)
              }}
            >
              + <span>{getMessage('banners.view.add.button')}</span>
            </button>
          )}
        />
      </div>
    )
  }
}

export default ManageBanners
