import React, { useMemo, useState, useEffect } from 'react'
import API from '../../../../../lib/api'
import { DiscountTypes } from '../../settings'
import { get } from 'lib/storage'
import { getMessage } from 'lib/translator'

const cartLevelOffers = ['SFXGSD', 'SFXGSFD', 'SFXGCD']

const getMinDiscountRecord = (records) => {
  return records.reduce((prev, curr) =>
    prev.discountValue < curr.discountValue ? prev : curr
  )
}

const getMaxDiscountRecord = (records) => {
  return records.reduce((prev, curr) =>
    prev.discountValue > curr.discountValue ? prev : curr
  )
}

const calculateDiscountValue = (
  type,
  value,
  discount,
  maxDiscount,
  itemDiscountType,
  productQty,
  addDiscountValuePercents
) => {
  const quantityBase =
    itemDiscountType === 'INDIVIDUAL_DISCOUNT' ? productQty : 1

  let discountValue = discount * quantityBase
  let discountPercent = ((discount * quantityBase) / value) * 100
  if (type === 'PERCENT_OFF') {
    discountPercent = discount
    discountValue = value * (discount / 100)
    if (maxDiscount && maxDiscount < discountValue) {
      discountValue = maxDiscount
    }
  }
  if (type === 'FIXED') {
    discountValue = value - discount * quantityBase
    discountPercent = (discountValue / value) * 100
  }
  if (type === 'FREE') {
    discountValue = value
  }

  if (discountPercent >= 50 && value) {
    addDiscountValuePercents(discountPercent)
  }
  return discountValue.toFixed(2)
}

const getMaxPercentValue = (rule) => {
  const graterPercentValue = rule.find(
    (item) => item.total.t === 'PERCENT_OFF' && item.total.v >= 50
  )
  return graterPercentValue?.total?.v || null
}

const getRuleForMinQuantityType = (
  rule,
  type,
  currentProduct,
  productPrice,
  includes,
  itemDiscountType,
  addDiscountValuePercents
) => {
  const { elementGroups = [], itemDiscountType: ruleItemDiscountType } = rule

  // get max percent value  if discount type is PERCENT_OFF
  // and value is > 50
  const maxPercentValue = getMaxPercentValue(elementGroups)
  if (maxPercentValue) {
    addDiscountValuePercents(maxPercentValue)
  }
  if ((type === 'BMIN' || type === 'BMINXGFG') && !includes) {
    return []
  }
  if (
    elementGroups.length > 0 &&
    (elementGroups[0]?.product?.length || 0) > 1
  ) {
    return []
  }
  if (type === 'BMINXGFG' && elementGroups.length > 1) {
    return []
  }
  return elementGroups.map((item) => {
    const productQty = currentProduct?.q || item?.minQuantity || 1
    const isIndividualDiscount = itemDiscountType === 'INDIVIDUAL_DISCOUNT'
    const discountType = isIndividualDiscount
      ? currentProduct?.t
      : item?.total?.t
    const discountTotalValue = isIndividualDiscount
      ? currentProduct?.v
      : item?.total?.v
    const priceValue = productPrice * productQty
    return {
      value: priceValue,
      discountValue: calculateDiscountValue(
        discountType,
        priceValue,
        discountTotalValue,
        item?.maxDiscount,
        ruleItemDiscountType || itemDiscountType,
        productQty,
        addDiscountValuePercents
      ),
      type: discountType,
    }
  })
}

const DiscountTabulation = ({
  rule,
  type,
  totalRedemption,
  product,
  includes,
  setIsShowDiscountWarning,
  itemDiscountType,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [productPrice, setProductPrice] = useState(0)
  const [discountValuePercents, setDiscountValuePercents] = useState([])

  const addDiscountValuePercents = (percent) => {
    setDiscountValuePercents([...discountValuePercents, percent])
  }

  const currentProduct = useMemo(() => {
    if (type === 'PWP' && rule?.total?.variantId) {
      return rule?.total?.variantId
    }
    if (type === 'BMINXGFG' && rule?.elementGroups.length === 1) {
      return rule?.elementGroups[0]?.product.length > 0
        ? rule?.elementGroups[0]?.product[0]
        : null
    }
    return product?.length > 0 ? product[0] : null
  }, [])
  const rulesRecords = useMemo(() => {
    if (
      ['BANYATP', 'BANYGYD', 'BXATP', 'BXGYD', 'BFXATP', 'PWP'].indexOf(type) >
      -1
    ) {
      const isIndividualDiscount = itemDiscountType === 'INDIVIDUAL_DISCOUNT'
      const discountType = isIndividualDiscount
        ? currentProduct?.t
        : rule?.total?.t
      const discountTotalValue = isIndividualDiscount
        ? currentProduct?.v
        : rule?.total?.v
      const productQty = currentProduct?.q || rule?.quantity || 1

      const priceValue = productPrice * productQty
      return [
        {
          value: priceValue,
          discountValue: calculateDiscountValue(
            discountType,
            priceValue,
            discountTotalValue,
            rule?.maxDiscount,
            itemDiscountType,
            productQty,
            addDiscountValuePercents
          ),
          type: discountType,
        },
      ]
    }
    if (['BMIN', 'BMINXGFG', 'BMINXATP'].indexOf(type) > -1) {
      return getRuleForMinQuantityType(
        rule,
        type,
        currentProduct,
        productPrice,
        includes,
        itemDiscountType,
        addDiscountValuePercents
      )
    }
    if (cartLevelOffers.indexOf(type) > -1) {
      const ruleElement = rule || []
      // get max percent value  if discount type is PERCENT_OFF
      // and value is > 50
      const maxPercentValue = getMaxPercentValue(ruleElement)
      if (maxPercentValue) {
        addDiscountValuePercents(maxPercentValue)
      }
      if (ruleElement.some((item) => item.total.t !== 'ABSOLUTE_OFF')) {
        return []
      }
      return ruleElement.map((item) => ({
        value: item.cartPrice,
        discountValue: calculateDiscountValue(
          item.total.t,
          item.cartPrice,
          item.total.v,
          item.maxDiscount,
          itemDiscountType,
          1,
          addDiscountValuePercents
        ),
        type: item.total.t,
      }))
    } else {
      return rule
    }
  }, [type, rule, productPrice, currentProduct])
  const getProductPrice = () => {
    const { defaultStoreId, id } = JSON.parse(get('organization') || '{}')
    if (currentProduct) {
      setIsLoading(true)
      const productAPI = new API({
        url: `/inventory-service/item?checkHandlingDays=true&productId=${currentProduct.id}&storeId=${defaultStoreId}&organizationId=${id}`,
      })
      productAPI
        .get()
        .then((response) => {
          if (response.code === 200 && response.status === 'SUCCESS') {
            const responseProduct = response.data[0]
            if (responseProduct) {
              setProductPrice(responseProduct.mrp)
            }
            setIsLoading(false)
          }
        })
        .catch((err) => err)
    }
  }

  useEffect(() => {
    getProductPrice()
  }, [])

  useEffect(() => {
    const maxDiscountPercent = Math.max(...discountValuePercents)
    if (maxDiscountPercent >= 50) {
      setIsShowDiscountWarning(true)
    }
  }, [discountValuePercents, setIsShowDiscountWarning])

  if (isLoading) {
    return <>Loading...</>
  }

  if (!totalRedemption) {
    return (
      <span data-testid="confirmation-modal-tabulation">
        {getMessage('offer.confirmation.unlimited')}
      </span>
    )
  }
  if (!rulesRecords.length) {
    return (
      <span data-testid="confirmation-modal-tabulation">
        {getMessage('offer.confirmation.na')}
      </span>
    )
  }
  if (rulesRecords.length > 1) {
    const minDiscountRecord = getMinDiscountRecord(rulesRecords)
    const maxDiscountRecord = getMaxDiscountRecord(rulesRecords)
    return (
      <span data-testid="confirmation-modal-tabulation">
        ${minDiscountRecord.discountValue} X {totalRedemption} = $
        {(minDiscountRecord.discountValue * totalRedemption).toFixed(2)} (
        {DiscountTypes.find((dtype) => dtype.value === minDiscountRecord.type)
          ?.text || ''}
        ) - ${maxDiscountRecord.discountValue} X {totalRedemption} = $
        {(maxDiscountRecord.discountValue * totalRedemption).toFixed(2)} (
        {DiscountTypes.find((dtype) => dtype.value === maxDiscountRecord.type)
          ?.text || ''}
        )
      </span>
    )
  } else {
    const discount = getMinDiscountRecord(rulesRecords)
    return (
      <span data-testid="confirmation-modal-tabulation">
        ${discount.discountValue} X {totalRedemption} = $
        {(discount.discountValue * totalRedemption).toFixed(2)} (
        {DiscountTypes.find((dtype) => dtype.value === discount.type)?.text ||
          ''}
        )
      </span>
    )
  }
}

export default DiscountTabulation
