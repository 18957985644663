import React, { useContext, useMemo } from 'react'
import { useActionTrailContext } from '../ActionTrailContext'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import './style.css'
import isEmpty from 'lodash.isempty'
import { CSVButton } from './CSVButton'

export const CSVExport = () => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig

  const isCsvExportEnabled = useMemo(() => {
    return splits?.[SPLIT_FEATURES.ACTION_TRAIL_CSV_EXPORT]?.treatment === 'on'
  }, [splits])

  const actiontrailContext = useActionTrailContext()

  const shouldShowButton = useMemo(() => {
    return (
      !isEmpty(actiontrailContext) &&
      actiontrailContext.totalRecord > 0 &&
      !isEmpty(actiontrailContext.filterForm)
    )
  }, [actiontrailContext])

  if (isCsvExportEnabled && shouldShowButton) {
    return <CSVButton />
  }
  return null
}
