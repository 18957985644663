import { getMessage } from 'lib/translator';

// this value is for slotRuleType in API payload
const slotRuleTypes = {
  BY_POSTAL_CODE: 'POSTAL_CODE',
  BY_MAP: 'CLUSTER',
  BY_KML_FILE: 'CLUSTER',
  IS_CLUSTER: 'CLUSTER', // to check if a rule is cluster rule (regarldess of map or kml)
  BY_STORE: 'STORE'
}

const AddRuleOptionsEnum = {
  BY_POSTAL_CODE: 'BY_POSTAL_CODE',
  BY_MAP: 'BY_MAP',
  BY_KML_FILE: 'BY_KML_FILE',
  BY_STORE: 'BY_STORE'
}

const AddRuleOptionsName = {
  BY_POSTAL_CODE: getMessage('operations.timeSlotBlocking.byPostalCode'),
  BY_MAP: getMessage('operations.timeSlotBlocking.byMap'),
  BY_KML_FILE: getMessage('operations.timeSlotBlocking.byKmlFile'),
  BY_STORE: getMessage('operations.timeSlotBlocking.byStore')
}
const AddRulePopupTitle = {
  BY_POSTAL_CODE: getMessage('operations.timeSlotBlocking.addRule.title.postalCode'),
  BY_MAP: getMessage('operations.timeSlotBlocking.addRule.title.map'),
  BY_KML_FILE: getMessage('operations.timeSlotBlocking.addRule.title.kmlFile'),
  BY_STORE: getMessage('operations.timeSlotBlocking.addRule.title.store')
}

const AddRuleFirstStepName = {
  BY_POSTAL_CODE: getMessage('operations.timeSlotBlocking.firstStep.postalCode'),
  BY_MAP: getMessage('operations.timeSlotBlocking.firstStep.map'),
  BY_KML_FILE: getMessage('operations.timeSlotBlocking.firstStep.kml')
}

const distinctColors = [
  { color: 'red', value: '#D50000' },
  { color: 'darkPurple', value: '#742092' },
  { color: 'purple', value: '#AA00FF' },
  { color: 'teal', value: '#006BB9' },
  { color: 'blue', value: '#0042EA' },
  { color: 'green', value: '#0FA063' },
  { color: 'oliveGreen', value: '#819D14' },
  { color: 'yellow', value: '#FFC700' },
  { color: 'orange', value: '#FF6D00' },
  { color: 'pink', value: '#F82C85' }
]

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const slots = ['T08', 'T10', 'T12', 'T14', 'T16', 'T18', 'T20']; // ToDo: retrieve this from API

export {
  AddRuleOptionsEnum,
  AddRuleOptionsName,
  AddRulePopupTitle,
  AddRuleFirstStepName,
  slotRuleTypes,
  distinctColors,
  days,
  slots
}
