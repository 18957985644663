export const contentHeight = '100vh - 50px - 27px'
export const contentHeightWithBackBtn = '100vh - 50px - 27px - 24px'
export const newOrderPageHeight = `${contentHeightWithBackBtn} - 40px - 12px - 16px`
export const browseProductHeight = `${contentHeightWithBackBtn} - 52px - 100px`

export const NewOrderTabs = {
  CUSTOMER_DETAIL: 0,
  BROWSE: 1,
  REVIEW_ORDER: 2,
  PICKUP: 3,
}
export const PREORDER_STATUSES = {
  PENDING_PAYMENT: 'PAYMENT_STATUS_PENDING',
  READY_FOR_COLLECTION: 'DELIVERY_ORDER_STATUS_DISPATCHED',
  COLLECTED: 'DELIVERY_ORDER_STATUS_COMPLETED',
  CANCELLED: 'DELIVERY_ORDER_STATUS_CANCELLED',
  PENDING_DISPATCH: 'DELIVERY_ORDER_STATUS_PENDING_DISPATCHED',
}

export const PAYMENT_STATUSES = {
  PAYMENT_STATUS_UNSPECIFIED: 'PAYMENT_STATUS_UNSPECIFIED',
  PAYMENT_STATUS_STARTED: 'PAYMENT_STATUS_STARTED',
  PAYMENT_STATUS_PENDING: 'PAYMENT_STATUS_PENDING',
  PAYMENT_STATUS_COMPLETED: 'PAYMENT_STATUS_COMPLETED',
  PAYMENT_STATUS_CANCELLED: 'PAYMENT_STATUS_CANCELLED',
  PAYMENT_STATUS_FAILED: 'PAYMENT_STATUS_FAILED',
  PAYMENT_STATUS_RECONCILED: 'PAYMENT_STATUS_RECONCILED',
  PAYMENT_STATUS_POA_AUTHORISATION_FAILED:
    'PAYMENT_STATUS_POA_AUTHORISATION_FAILED',
}
export const DELIVERY_ORDER_STATUSES = {
  DELIVERY_ORDER_STATUS_UNSPECIFIED: 'DELIVERY_ORDER_STATUS_UNSPECIFIED',
  DELIVERY_ORDER_STATUS_PENDING: 'DELIVERY_ORDER_STATUS_PENDING',
  DELIVERY_ORDER_STATUS_PROCESSING: 'DELIVERY_ORDER_STATUS_PROCESSING',
  DELIVERY_ORDER_STATUS_PACKING: 'DELIVERY_ORDER_STATUS_PACKING',
  DELIVERY_ORDER_STATUS_PACKED: 'DELIVERY_ORDER_STATUS_PACKED',
  DELIVERY_ORDER_STATUS_DISPATCHED: 'DELIVERY_ORDER_STATUS_DISPATCHED',
  DELIVERY_ORDER_STATUS_COMPLETED: 'DELIVERY_ORDER_STATUS_COMPLETED',
  DELIVERY_ORDER_STATUS_CANCELLED: 'DELIVERY_ORDER_STATUS_CANCELLED',
  DELIVERY_ORDER_STATUS_PICKING: 'DELIVERY_ORDER_STATUS_PICKING',
  DELIVERY_ORDER_STATUS_PARTIALLY_PICKED:
    'DELIVERY_ORDER_STATUS_PARTIALLY_PICKED',
  DELIVERY_ORDER_STATUS_PICKED: 'DELIVERY_ORDER_STATUS_PICKED',
  DELIVERY_ORDER_STATUS_CHECKING: 'DELIVERY_ORDER_STATUS_CHECKING',
  DELIVERY_ORDER_STATUS_CHECKED: 'DELIVERY_ORDER_STATUS_CHECKED',
  DELIVERY_ORDER_STATUS_RETURNED: 'DELIVERY_ORDER_STATUS_RETURNED',
}

export const orders = [
  {
    id: 'ORD1234567891',
    total: '114.6',
    storeName: 'FairPrice VivoCity',
    pickupTime: 'Pick up: 12pm–2pm, 15 Aug 2024',
    customerName: 'Monique Regalado',
    customerPhone: '83948392',
    status: 'paid',
  },
  {
    id: 'ORD123456',
    total: '90',
    storeName: 'FairPrice',
    pickupTime: 'Pick up: 2pm-4pm, 15 Aug 2024',
    customerName: 'Monique Regalado super long name that cant fit on 1 line',
    customerPhone: '99998888',
    status: 'pending',
  },
  {
    id: 'ORD123456sdf7891',
    total: '114.6',
    storeName: 'FairPrice VivoCity',
    pickupTime: 'Pick up: 12pm–2pm, 15 Aug 2024',
    customerName: 'Monique Regalado',
    customerPhone: '83948392',
    status: 'paid',
  },
  {
    id: 'ORD123sad456',
    total: '90',
    storeName: 'FairPrice',
    pickupTime: 'Pick up: 2pm-4pm, 15 Aug 2024',
    customerName: 'Monique Regalado super long name that cant fit on 1 line',
    customerPhone: '99998888',
    status: 'pending',
  },
]
