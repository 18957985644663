import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  Email as CustomerEmailWidget,
  Call as CustomerCallWidget,
} from '../../../../../containers/CustomerWidgets'
import { Radio, Select } from '../../../../../components/Form'
import { SKINS as RADIO_SKINS } from '../../../../../components/Form/Inputs/Radio'
import { getMessage } from '../../../../../lib/translator'
import {
  isExtensionEnabled,
  getExtensionDetails,
  getStores,
} from '../../../../../lib/auth'
import API from '../../../../../lib/api'
import { formatDate, formatTime } from '../../../../../lib/datetime'

import editIcon from '../edit-icon.svg'
import {
  getDisplayAddress,
  getAsapDuration,
  sortSlotsAvailability,
  generateDateOptions
} from '../../../../../lib/commonlyused'

const dateFormat = 'YYYY-MM-DD';

class DeliveryDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.orderTypes = [
      {
        text: getMessage('order.details.location.option.delivery'),
        value: 'DELIVERY',
      },
    ]
    if (isExtensionEnabled('DeliverySlots')) {
      this.state.addressSlotMap = {
        DELIVERY: {},
        PICKUP: {},
      }
    }
    this.startEditing = this.startEditing.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.submitChanges = this.submitChanges.bind(this)
    this.getAddressParams = this.getAddressParams.bind(this)
    this.formatSlotEndTime = this.formatSlotEndTime.bind(this)
    this.getPickupParams = this.getPickupParams.bind(this)
    this.promisifySetState = this.promisifySetState.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(id) {
    const { visibleDaysForSlot, orderType } = this.state

    if (isExtensionEnabled('DeliverySlots')) {
      const addressApi = new API({ url: '/order-service/slot-availability' })
      if (!this.state.addressSlotMap[orderType][id]) {
        let params = {}
        if (orderType === 'DELIVERY') {
          const addressIndex = this.state.customer.addresses.findIndex(
            address => Number(address.id) === Number(id)
          )
          params = this.getAddressParams(id, visibleDaysForSlot, addressIndex)
        } else if (orderType === 'PICKUP') {
          params = this.getPickupParams(id, visibleDaysForSlot)
        }

        addressApi
          .get(params)
          .then(response => {
            const addressSlots = sortSlotsAvailability(response.data.slots)
            const slots = {}
            addressSlots &&
              addressSlots.map(slot => {
                if (slots.hasOwnProperty(slot.date)) {
                  slots[slot.date].push(slot)
                } else {
                  slots[slot.date] = []
                  slots[slot.date].push(slot)
                }

                return null
              })
            const addressSlotMap = JSON.parse(
              JSON.stringify(this.state.addressSlotMap)
            )
            addressSlotMap[orderType][id] = slots
            this.dateOptions[orderType][id] = generateDateOptions(slots)

            this.setState({
              addressSlotMap,
            })
          })
          .catch(error => {
            if (error.code === 401) {
              throw error
            } else {
              this.setState({
                error: error.message,
              })
            }
          })
      }
    }

    if (this.state.orderType === 'DELIVERY') {
      const addressIndex = this.state.customer.addresses.findIndex(
        address => Number(address.id) === Number(id)
      )
      this.setState({
        addressIndex,
        addressId: id,
        orderType: 'DELIVERY',
        preferredSlotId: undefined,
      })
    } else if (this.state.orderType === 'PICKUP') {
      const pickupLocationIndex =
        this.state.pickupLocations &&
        this.state.pickupLocations.findIndex(
          pickupAddress => Number(pickupAddress.id) === Number(id)
        )
      this.setState({
        pickupLocationIndex,
        pickupLocationId: id,
        orderType: 'PICKUP',
        preferredSlotId: undefined,
      })
    }
  }

  promisifySetState(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  getAddressParams(addressId, visibleDaysForSlot, addressIndex) {
    const params = {}
    const { data } = this.props
    const { customer } = this.state
    const address =
      (customer && customer.addresses && customer.addresses[addressIndex]) || {}
    params['startDate'] = moment().toISOString()
    params['endDate'] = moment()
      .add(visibleDaysForSlot, 'days')
      .toISOString()
    params['storeId'] = data.storeId
    params['orderType'] = 'DELIVERY'
    params['searchInterval'] = params['startDate'] + '/' + params['endDate']
    // Optional Parameters
    params['address[address]'] = address.address
    params['address[landmark]'] = address.landmark
    params['address[city]'] = address.city
    params['address[pincode]'] = address.pincode
    params['address[latitude]'] = address.latitude
    params['address[longitude]'] = address.longitude
    delete params['startDate']
    delete params['endDate']
    return params
  }

  getPickupParams(pickupId, visibleDaysForSlot) {
    const params = {}
    const { pickupLocations } = this.state
    if (!pickupLocations) {
      return params
    }
    params['startDate'] = moment().toISOString()
    params['endDate'] = moment()
      .add(visibleDaysForSlot, 'days')
      .toISOString()
    params['storeId'] = pickupId
    params['orderType'] = 'PICKUP'
    params['searchInterval'] = params['startDate'] + '/' + params['endDate']
    delete params['startDate']
    delete params['endDate']
    return params
  }

  async componentDidMount() {
    const { data } = this.props
    const dateOptions = { DELIVERY: {}, PICKUP: {} }

    const addressSlotMap = {
      DELIVERY: {},
      PICKUP: {},
    }

    const newStates = this.generateStateFromProps(this.props, {
      editing: false,
      saving: false,
    })

    await this.promisifySetState(newStates)

    if (isExtensionEnabled('DeliverySlots')) {
      const extnId = getExtensionDetails('DeliverySlots').id
      this.extnApi = new API({ url: `/account-service/extension/${extnId}` })
      await this.extnApi
        .get()
        .then(response => {
          const visibleDaysForSlot =
            (response.data.extension.config.globalConfig &&
              response.data.extension.config.globalConfig.visibleDaysForSlot) ||
            null
          // visibleDaysForSlot = 5 // TODO: Remove this
          this.setState({
            visibleDaysForSlot,
          })
        })
        .catch(err => err)

      if (this.state.visibleDaysForSlot) {
        const pickupLocations = getStores()
        if (
          pickupLocations &&
          pickupLocations.length > 0 &&
          this.props.orderConfig &&
          this.props.orderConfig.orderTypes &&
          this.props.orderConfig.orderTypes.includes('PICKUP')
        ) {
          this.orderTypes.push({
            text: getMessage('order.details.location.option.pickup'),
            value: 'PICKUP',
          })
          const pickupLocationId = data.pickupLocation && data.pickupLocation.id
          let pickupLocationIndex
          if (pickupLocationId) {
            pickupLocationIndex =
              pickupLocations &&
              pickupLocations.findIndex(
                loc => loc && loc.id === pickupLocationId
              )
          }

          await this.promisifySetState({
            pickupLocations: pickupLocations,
            pickupLocationIndex,
            pickupLocationId,
          })
          if (this.props.data.pickupLocation) {
            this.orderSlotApi = new API({
              url: '/order-service/slot-availability',
            })
            const params = this.getPickupParams(
              this.props.data.pickupLocation.id,
              this.state.visibleDaysForSlot
            ) // TODO: remove asap param
            this.orderSlotApi
              .get(params)
              .then(response => {
                const addressSlots = sortSlotsAvailability(response.data.slots)
                const slots = {}
                addressSlots &&
                  addressSlots.map(slot => {
                    if (slots.hasOwnProperty(slot.date)) {
                      slots[slot.date].push(slot)
                    } else {
                      slots[slot.date] = []
                      slots[slot.date].push(slot)
                    }
                    return null
                  })
                const today = new Date()
                let preferredDate =
                  this.state.preferredDate &&
                  moment(this.state.preferredDate).format(dateFormat)
                if (preferredDate < today) {
                  preferredDate = moment().format(dateFormat)
                }
                addressSlotMap.PICKUP[data.pickupLocation.id] = slots
                dateOptions['PICKUP'][
                  data.pickupLocation.id
                ] = generateDateOptions(slots)

                this.setState({
                  addressSlotMap,
                  preferredDate,
                })
              })
              .catch(err => err)
          }
        }

        if (data.address && (this.state.addressId || this.state.addressIndex)) {
          this.orderSlotApi = new API({
            url: '/order-service/slot-availability',
          })
          const params = this.getAddressParams(
            data.address.id,
            this.state.visibleDaysForSlot,
            0
          )
          this.orderSlotApi
            .get(params)
            .then(response => {
              const addressSlots = sortSlotsAvailability(response.data.slots)
              const today = new Date()
              let preferredDate =
                this.state.preferredDate &&
                moment(this.state.preferredDate).format(dateFormat)
              if (preferredDate < today) {
                preferredDate = moment().format('YYYY-MM-DD')
              }

              const slots = {}
              addressSlots &&
                addressSlots.map(slot => {
                  if (slots.hasOwnProperty(slot.date)) {
                    slots[slot.date].push(slot)
                  } else {
                    slots[slot.date] = []
                    slots[slot.date].push(slot)
                  }

                  return null
                })
              addressSlotMap.DELIVERY[data.address.id] = slots
              dateOptions['DELIVERY'][data.address.id] = generateDateOptions(
                slots
              )
              this.setState({
                addressSlotMap,
                preferredDate,
                error: null,
              })
            })
            .catch(error => {
              if (error.code === 401) {
                throw error
              }
              this.setState({
                error: error.message,
              })
            })
        }
      }
    }
    this.dateOptions = dateOptions
  }

  startEditing() {
    this.setState({ editing: true, preferredSlotId: null })
  }
  cancelEditing() {
    this.setState({
      editing: false,
    })
  }
  formatSlotEndTime(time) {
    return (
      parseInt(time.split(':')[1], 10) + '' + parseInt(time.split(':')[2], 10)
    )
  }
  submitChanges() {
    const { orderType } = this.state
    if (this.props.onChange) {
      // onChange method should return a promise
      this.setState(
        {
          // editing: false,
          saving: true,
        },
        () => {
          const requestParams = {}
          if (
            orderType === 'DELIVERY' &&
            Number.isFinite(this.state.addressIndex)
          ) {
            requestParams.addressId =
              this.state.customer.addresses &&
              this.state.customer.addresses[this.state.addressIndex].id
            requestParams.type = 'DELIVERY'
            if (isExtensionEnabled('DeliverySlots')) {
              requestParams.preferredDate = this.state.preferredDate
              const slotArray = this.state.addressSlotMap[orderType][
                requestParams.addressId
              ][this.state.preferredDate].filter(
                slot => slot.id === Number(this.state.preferredSlotId)
              )
              if (slotArray.length > 0) {
                const slot = slotArray[0]
                slot.startTime = slot.interval
                  .split('/')[0]
                  .split('T')[1]
                  .substring(0, 8)
                slot.endTime = slot.interval
                  .split('/')[1]
                  .split('T')[1]
                  .substring(0, 8)
                requestParams.preferredSlot = slot
                requestParams.slotStartTime = slot.startTime
                requestParams.slotEndTime = slot.endTime
              }
            }
          } else if (
            orderType === 'PICKUP' &&
            Number.isFinite(Number(this.state.pickupLocationId))
          ) {
            // TODO: Add logic to select a pickup location
            requestParams.pickupLocationId = this.state.pickupLocations[
              this.state.pickupLocationIndex
            ].id
            requestParams.type = 'PICKUP'
            if (isExtensionEnabled('DeliverySlots')) {
              requestParams.preferredSlotId = Number(this.state.preferredSlotId)
              requestParams.preferredDate = this.state.preferredDate
            }
          }
          if (this.props.data.status !== 'PENDING') {
            delete requestParams.addressId
          }
          this.props
            .onChange(requestParams)
            .then(
              () => {
                this.setState({ error: false, saving: false })
              },
              error => {
                this.setState({ error: true, saving: false })
                if (error.code === 401) {
                  throw error
                }
              }
            )
            .then(() => {
              this.setState({ editing: false, saving: false })
            })
        }
      )
    }
  }
  generateStateFromProps(props, state) {
    const addressId = props.data.address ? props.data.address.id : null
    const pickupId = props.data.pickupLocation
      ? props.data.pickupLocation.id
      : null
    let addressIndex
    let pickupLocationIndex
    if (addressId) {
      addressIndex =
        props.data.customer &&
        props.data.customer.addresses &&
        props.data.customer.addresses.findIndex(
          address => address.id === props.data.address.id
        )
      if (addressIndex === -1) {
        addressIndex = undefined
      }
    }
    if (pickupId && state.pickupLocations) {
      pickupLocationIndex =
        state.pickupLocations &&
        state.pickupLocations.findIndex(
          loc => loc.id === props.data.pickupLocation.id
        )
      if (pickupLocationIndex === -1) {
        pickupLocationIndex = null
      }
    }
    let newState = JSON.parse(JSON.stringify(state))
    newState = {
      ...newState,
      addressIndex,
      addressId,
      pickupLocationIndex,
      pickupLocationId: pickupId,
      pickupLocation: props.data.pickupLocation,
      customer: props.data.customer,
      orderType: props.data.type,
    }

    if (isExtensionEnabled('DeliverySlots') && !state.preferredDate) {
      newState.preferredDate =
        props.data.preferredDate < moment().format(dateFormat)
          ? moment().format(dateFormat)
          : props.data.preferredDate
      newState.preferredSlotId =
        props.data.preferredSlot && props.data.preferredSlot.id
    }
    return newState
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.data.address || newProps.data.pickupLocation) {
      this.setState(prevState =>
        this.generateStateFromProps(newProps, prevState)
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { customer, visibleDaysForSlot, orderType } = this.state
    if (prevState.orderType !== this.state.orderType) {
      if (orderType === 'DELIVERY') {
        if (
          visibleDaysForSlot &&
          !this.state.addressSlotMap[orderType][this.state.addressId]
        ) {
          this.slotApi = new API({ url: '/order-service/slot-availability' })
          const addressId =
            customer &&
            customer.addresses &&
            customer.addresses[0] &&
            customer.addresses[0].id
          if (addressId && this.props.data.storeId) {
            const params = this.getAddressParams(addressId, visibleDaysForSlot, 0)
            this.slotApi.get(params).then(response => {
              const addressSlots = sortSlotsAvailability(response.data.slots)
              const slots = {}
              addressSlots &&
                addressSlots.map(slot => {
                  if (slot.available) {
                    if (slots.hasOwnProperty(slot.date)) {
                      slots[slot.date].push(slot)
                    } else {
                      slots[slot.date] = []
                      slots[slot.date].push(slot)
                    }
                  }
                  return null
                })
              const addressSlotMap = JSON.parse(
                JSON.stringify(this.state.addressSlotMap)
              )
              const today = new Date()
              let preferredDate =
                this.state.preferredDate &&
                moment(this.state.preferredDate).format(dateFormat)
              if (!preferredDate || preferredDate < today) {
                preferredDate = moment().format(dateFormat)
              }
              addressSlotMap.DELIVERY[addressId] = slots
              this.dateOptions['DELIVERY'][addressId] = generateDateOptions(
                slots
              )
              const addressNotServed = addressSlots.length === 0
              this.setState({
                addressSlotMap,
                preferredDate,
                addressId,
                addressNotServed,
                error: null,
                preferredSlotId: undefined,
              })
            })
          }
        }
      } else if (orderType === 'PICKUP') {
        if (
          visibleDaysForSlot &&
          this.state.addressSlotMap[orderType] &&
          !this.state.addressSlotMap[orderType][this.state.pickupLocationId]
        ) {
          this.slotApi = new API({ url: '/order-service/slot-availability' })
          const pickupId =
            this.state.pickupLocations &&
            this.state.pickupLocations[0] &&
            this.state.pickupLocations[0].id
          if (pickupId) {
            const params = this.getPickupParams(pickupId, visibleDaysForSlot)
            this.slotApi.get(params).then(response => {
              const addressSlotMap = JSON.parse(
                JSON.stringify(this.state.addressSlotMap)
              )
              const addressSlots = sortSlotsAvailability(response.data.slots)
              const slots = {}
              addressSlots &&
                addressSlots.map(slot => {
                  if (slot.available) {
                    if (slots.hasOwnProperty(slot.date)) {
                      slots[slot.date].push(slot)
                    } else {
                      slots[slot.date] = []
                      slots[slot.date].push(slot)
                    }
                  }
                  return null
                })

              const today = new Date()
              let preferredDate =
                this.state.preferredDate &&
                moment(this.state.preferredDate).format(dateFormat)
              if (preferredDate < today || !preferredDate) {
                preferredDate = moment().format(dateFormat)
              }
              addressSlotMap.PICKUP[pickupId] = slots
              this.dateOptions['PICKUP'][pickupId] = generateDateOptions(slots)
              this.setState({
                addressSlotMap,
                preferredDate,
                pickupLocationId: pickupId,
                pickupLocationIndex: 0,
                addressNotServed: false,
                preferredSlotId: undefined,
              })
            })
          }
        }
      }
    }
  }
  componentWillUnmount() {
    this.extnApi && this.extnApi.cancel()
    this.slotApi && this.slotApi.cancel()
  }
  render() {
    const {
      visibleDaysForSlot,
      addressSlotMap,
      preferredDate,
      addressId,
      orderType,
      pickupLocationId,
    } = this.state
    const isPickupOrder = orderType === 'PICKUP'
    const { communicationConfig } = this.props
    const allowCall =
      communicationConfig &&
      communicationConfig.communication &&
      communicationConfig.communication.allowCall !== false
    const allowEmail =
      communicationConfig &&
      communicationConfig.communication &&
      communicationConfig.communication.allowEmail !== false
    var title
    if (this.props.data.status === 'COMPLETED') {
      if (this.props.data.type === 'DELIVERY') {
        title = getMessage('order.details.location.delivery.completed.title')
      } else if (this.props.data.type === 'PICKUP') {
        title = getMessage('order.details.location.pickup.completed.title')
      } else if (this.props.data.type === 'B2B') {
        title = getMessage('order.details.location.b2b.completed.title')
      } else if (this.props.data.type === 'OFFLINE') {
        title = getMessage('order.details.location.offline.completed.title')
      }
    } else {
      if (this.props.data.type === 'DELIVERY') {
        title = getMessage('order.details.location.delivery.pending.title')
      } else if (this.props.data.type === 'PICKUP') {
        title = getMessage('order.details.location.pickup.pending.title')
      } else if (this.props.data.type === 'B2B') {
        title = getMessage('order.details.location.b2b.pending.title')
      } else if (this.props.data.type === 'OFFLINE') {
        title = getMessage('order.details.location.offline.pending.title')
      }
    }

    const isAddressSelected = Number.isFinite(this.state.addressIndex)
    const isPickupLocationSelected = Number.isFinite(
      this.state.pickupLocationIndex
    )

    let minutesToDeliver = this.props.data.preferredSlot
      ? this.props.data.preferredSlot.startTime
        ? formatTime(this.props.data.preferredSlot.startTime) +
          ' - ' +
          formatTime(this.props.data.preferredSlot.endTime)
        : null
      : null
    if (
      this.props.data.slotType === 'ASAP' &&
      this.props.data.preferredSlot &&
      this.props.data.preferredSlot.startTime &&
      this.props.data.preferredSlot.endTime
    ) {
      minutesToDeliver = getAsapDuration(
        this.props.data.preferredSlot.startTime,
        this.props.data.preferredSlot.endTime
      )
    }

    const metaData = this.props.data.address && this.props.data.address.metaData
    const metaDataPhones =
      metaData &&
      [{ phone: metaData.Phone }, { phone: metaData.AlternatePhone }].filter(
        Boolean
      )
    const myoptions =
      this.dateOptions &&
      this.dateOptions[orderType] &&
      this.dateOptions[orderType][
        orderType === 'PICKUP' ? pickupLocationId : addressId
      ]
    const filteredOption =
      myoptions && myoptions.filter(item => item.value === preferredDate)
    let selectedDate = null
    if (filteredOption && filteredOption.length > 0) {
      selectedDate = filteredOption[0].value
    }
    return (
      <div className="address-details">
        {!this.state.editing ? (
          <React.Fragment>
            <h3 className="customer-heading">{getMessage('Customer')}</h3>
            {this.state.customer && (
              <React.Fragment>
                <Link
                  className="customer-name"
                  to={`${this.props.data.url}/customers/view/${this.state.customer.id}`}
                >
                  {this.state.customer.name}
                </Link>
                <div className="customer-actions">
                  {allowEmail &&
                    this.state.customer &&
                    this.state.customer.emails &&
                    this.state.customer.emails.length > 0 && (
                      <CustomerEmailWidget
                        emails={this.state.customer.emails}
                      />
                    )}
                  {allowCall &&
                    this.state.customer &&
                    this.state.customer.phones &&
                    this.state.customer.phones.length > 0 && (
                      <CustomerCallWidget phones={this.state.customer.phones} />
                    )}
                </div>
              </React.Fragment>
            )}
            <div className="flex-around section-title title">
              <h3>{title}</h3>
              {this.props.editable ? (
                <img
                  src={editIcon}
                  alt="Edit"
                  className="edit-icon"
                  onClick={this.startEditing}
                />
              ) : null}
            </div>
          </React.Fragment>
        ) : (
          <div className="section-title">
            <div className="location-options">
              <Radio
                name="delivery-option"
                skin={RADIO_SKINS.BORDERLESS}
                value={orderType}
                readOnly={this.props.data.status !== 'PENDING'}
                onChange={value => {
                  value === 'DELIVERY'
                    ? this.setState({
                        orderType: 'DELIVERY',
                        preferredSlotId: null,
                      })
                    : this.setState({
                        orderType: 'PICKUP',
                        preferredSlotId: null,
                      })
                }}
                options={this.orderTypes}
              />
            </div>
          </div>
        )}
        {this.props.data.address && (
          <div className="customer-address customer-meta">
            {(({ metaData }) => {
              const name = [
                metaData && metaData.FirstName,
                metaData && metaData.LastName,
              ]
                .filter(Boolean)
                .join(' ')
              if (name) {
                return name
              }
              return this.state.customer && this.state.customer.name
            })(this.props.data.address)}
          </div>
        )}
        {this.props.data.metaData &&
          this.props.data.pickupLocation &&
          this.props.data.metaData.pickupPersonName && (
            <React.Fragment>
              <div className="customer-address customer-meta">
                {(({ pickupPersonName }) => {
                  return pickupPersonName
                })(this.props.data.metaData)}
              </div>
              {this.props.data.metaData.pickupPersonContact && (
                <div className="customer-address customer-meta">
                  {(({ pickupPersonContact }) => {
                    return pickupPersonContact
                  })(this.props.data.metaData)}
                </div>
              )}
            </React.Fragment>
          )}
        <div className="customer-actions">
          {allowCall && metaDataPhones && metaDataPhones.length > 0 && (
            <CustomerCallWidget phones={metaDataPhones} />
          )}
        </div>
        {(orderType === 'DELIVERY' ||
          orderType === 'PICKUP' ||
          orderType === 'OFFLINE' ||
          orderType === 'B2B') &&
        (isAddressSelected || isPickupLocationSelected) ? (
          !this.state.editing ? (
            !isPickupOrder ? (
              <React.Fragment>
                <div className="customer-address">
                  {this.props.data.address &&
                    (() => {
                      const addString =
                        this.props.data.address &&
                        getDisplayAddress(
                          this.props.data.address,
                          this.props.data.addressSequence
                        )
                      return addString
                    })()}
                </div>
              </React.Fragment>
            ) : (
              this.state.pickupLocation && (
                <div className="customer-address">
                  {this.state.pickupLocation.address}
                </div>
              )
            )
          ) : (
            <React.Fragment>
              <div className="customer-addresses">
                {!isPickupOrder ? (
                  <Radio
                    name="delivery-address"
                    skin={RADIO_SKINS.ONE_PER_LINE}
                    readOnly={this.props.data.status !== 'PENDING'}
                    value={
                      this.state.customer.addresses[this.state.addressIndex] &&
                      this.state.customer.addresses[this.state.addressIndex].id
                    }
                    onChange={this.onChange}
                    options={this.state.customer.addresses.map(address => ({
                      value: address.id,
                      text: getDisplayAddress(
                        address,
                        this.props.data.addressSequence
                      ),
                    }))}
                  />
                ) : (
                  <Radio
                    name="pickup-addresses"
                    skin={RADIO_SKINS.ONE_PER_LINE}
                    readOnly={this.props.data.status !== 'PENDING'}
                    value={
                      this.state.pickupLocations &&
                      this.state.pickupLocations[
                        this.state.pickupLocationIndex
                      ] &&
                      this.state.pickupLocations[this.state.pickupLocationIndex]
                        .id
                    } // TODO: confirm
                    onChange={this.onChange}
                    options={
                      this.state.pickupLocations &&
                      this.state.pickupLocations
                        .filter(store => store.hasClickCollect)
                        .map(pickupLocation => ({
                          value: pickupLocation.id,
                          text: pickupLocation.name,
                        }))
                    }
                  />
                )}
              </div>
              {isExtensionEnabled('DeliverySlots') &&
                orderType === 'DELIVERY' &&
                visibleDaysForSlot &&
                addressSlotMap &&
                addressSlotMap[orderType] &&
                addressSlotMap[orderType][addressId] && (
                  <React.Fragment>
                    <Select
                      name="preferredDate"
                      label={getMessage('order.details.preferredDate')}
                      value={selectedDate}
                      options={
                        this.dateOptions &&
                        this.dateOptions[orderType] &&
                        this.dateOptions[orderType][addressId]
                      }
                      onChange={e =>
                        this.setState({
                          preferredSlotId: undefined,
                          preferredDate: e,
                        })
                      }
                    />
                    {preferredDate &&
                      Array.isArray(
                        addressSlotMap[orderType][addressId][preferredDate]
                      ) && (
                        <Select
                          name="preferredSlot"
                          label={getMessage('order.details.prefferdSlot')}
                          placeholder={getMessage('Select slot')}
                          value={this.state.preferredSlotId}
                          onChange={e => this.setState({ preferredSlotId: e })}
                          options={addressSlotMap[orderType][addressId][
                            preferredDate
                          ].map(slot => {
                            return {
                              text: slot.text,
                              value: slot.id,
                            }
                          })}
                        />
                      )}
                  </React.Fragment>
                )}
              {isExtensionEnabled('DeliverySlots') &&
                isPickupOrder &&
                addressSlotMap &&
                addressSlotMap[orderType] &&
                addressSlotMap[orderType][pickupLocationId] && (
                  <React.Fragment>
                    <Select
                      name="preferredDatePickup"
                      label={getMessage('order.details.preferredDate')}
                      value={selectedDate}
                      options={
                        this.dateOptions &&
                        this.dateOptions[orderType] &&
                        this.dateOptions[orderType][pickupLocationId]
                      }
                      onChange={e =>
                        this.setState({
                          preferredDate: e,
                          preferredSlotId: undefined,
                        })
                      }
                    />
                    {preferredDate &&
                      Array.isArray(
                        addressSlotMap[orderType][pickupLocationId][
                          preferredDate
                        ]
                      ) && (
                        <Select
                          name="preferredSlot"
                          label={getMessage('order.details.prefferdSlot')}
                          value={this.state.preferredSlotId}
                          onChange={e => this.setState({ preferredSlotId: e })}
                          options={addressSlotMap[orderType][pickupLocationId][
                            preferredDate
                          ].map(slot => {
                            return {
                              text: slot.text,
                              value: slot.id,
                            }
                          })}
                        />
                      )}
                  </React.Fragment>
                )}
            </React.Fragment>
          )
        ) : (
          <div className="store-address">
            {this.state.pickupLocation ? (
              <React.Fragment>
                <span className="customer-address customer-meta">
                  {getMessage('order.details.pickup.address')}
                </span>{' '}
                {this.state.pickupLocation.address}
              </React.Fragment>
            ) : null}
          </div>
        )}
        {!this.state.editing &&
          isExtensionEnabled('DeliverySlots') &&
          (this.props.data.preferredDate || minutesToDeliver) && (
            <div className="slot-details">
              <div className="section-title slot-title">
                <h5>{getMessage('order.details.slot.heading')}</h5>
              </div>
              {this.props.data.preferredDate && (
                <div className="customer-address">{`${this.props.data
                  .preferredDate &&
                  formatDate(this.props.data.preferredDate)}`}</div>
              )}
              {minutesToDeliver && (
                <div className="customer-address">{minutesToDeliver}</div>
              )}
            </div>
          )}
        <div className="delivery-option-buttons">
          {this.state.editing ? (
            <button
              className="button primary"
              disabled={
                (isExtensionEnabled('DeliverySlots') &&
                  !this.state.preferredSlotId) ||
                this.state.saving
              }
              onClick={this.submitChanges}
            >
              {getMessage('order.details.location.actions.confirmText')}
            </button>
          ) : null}
          {this.state.editing ? (
            <button
              className="button button-white"
              onClick={this.cancelEditing}
            >
              {getMessage('order.details.location.actions.cancelText')}
            </button>
          ) : null}
        </div>
      </div>
    )
  }
}

export default DeliveryDetails
