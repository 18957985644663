import React from 'react'
import FPLogo from 'icons/fp_logo_pdf.png'
import './style.css'
import InvoiceBody from './InvoiceBody'

const Invoice = ({ data }) => {
  return (
    <div className="main-container-invoice">
      <div className="row">
        <div className="brand-icons">
          <img alt="" className="img-invoice" src={FPLogo} />
        </div>

        <div className="header-section-right">
          <div className="header-right-text">
            NTUC FairPrice Co-operative Limited
          </div>
          <div className="header-right-text">
            No. 1 Joo Koon Circle, #13-01, FairPrice Hub Singapore 629117
          </div>
          <div className="header-right-text">
            Telephone: 6888 1888 Facsimile: 6397 4001
          </div>
          <div className="header-right-text-link">
            GST Registration No. M4-0004578-0 UEN No: S83CS0191L
          </div>
        </div>
      </div>
      <InvoiceBody invoiceDetails={data} />
    </div>
  )
}

export default Invoice
