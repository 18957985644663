import { set, get } from 'lib/storage';
import _ from 'lodash';

function checkAndSetFeatureFlags(latestFlags) {
  const localStorageFlags = JSON.parse(get('SPLIT_FEATURE_FLAGS'));
  if (latestFlags && _.isEqual(latestFlags, localStorageFlags) === false) {
    set('SPLIT_FEATURE_FLAGS', JSON.stringify(latestFlags));
  }
}

export {
  checkAndSetFeatureFlags
}
