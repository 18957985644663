import React, { useState, useEffect } from 'react'

import placeholder from './image-placeholder.svg'
import Loader from '../Loader'
import mediaService from '../../lib/mediaService'
import classNames from '../../lib/utils/classNames'
import './style.css'

export default function Image(props) {
  const { isPrivate, src: propSrc } = props
  const [isLoading, setIsLoading] = useState(isPrivate)
  const [error, setError] = useState(null)
  const [urlSign, setUrlSign] = useState(null)

  useEffect(() => {
    if (isPrivate) {
      setIsLoading(true)
      mediaService
        .getUrlSign()
        .then((response) => {
          const { query } = response
          setIsLoading(false)
          setUrlSign(query)
          setError(null)
        })
        .catch((e) => {
          setIsLoading(false)
          setUrlSign(null)
          setError(e)
        })
    }
  }, [isPrivate])

  if (isLoading) {
    return <Loader size="sm" />
  }

  let imageSrc
  if (isPrivate) {
    if (!error && urlSign) {
      const separator = propSrc.indexOf('?') !== -1 ? '&' : '?'
      imageSrc = propSrc + separator + urlSign
    }
  } else {
    imageSrc = propSrc || placeholder
  }

  return (
    <div
      className={classNames([
        'image',
        props.size ? `image-${props.size}` : '',
        props.bordered ? 'bordered' : '',
        props.className ? props.className : '',
      ])}
    >
      {error && error.message && (
        <div className="error-message">{error.message}</div>
      )}
      <img src={imageSrc} alt={props.alt} />
    </div>
  )
}
