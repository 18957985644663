import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { tableProperties } from '../../../components/Rewards/Maintenance/utils'
import { LinkButtons, emptyState } from '../../../components/Rewards/utils'
import '../style.css'
import './maintenance.css'
import MaintenaceForm from './Form'
import { getMessage } from 'lib/translator'

const Maintenance = props => {
  return (
    <ListingPageWithRoutes
      testid="maintenace-listing"
      title={getMessage('rewards.maintenance.title')}
      addHeading={getMessage('rewards.maintenance.add')}
      editHeading={getMessage('rewards.maintenance.edit')}
      menu={props.menu}
      className="rewards-page maintenance"
      api={{
        url: '/rms/maintenance',
        transform: response => response && response.data.list,
      }}
      headerActions={() => <LinkButtons type="maintenance" />}
      form={{
        component: MaintenaceForm,
      }}
      tableProperties={tableProperties}
      emptyState={emptyState('maintenance')}
      tableDynamic
    />
  )
}

export default Maintenance
