import React from 'react'
import {
  BaseForm,
  Select,
  VALIDATION_TYPES,
  Searchable,
  BrandSearch,
  CategorySearch,
  Input,
} from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'
import { isExtensionEnabled } from '../../../../lib/auth'
import DateTimeValidityFilter from './DateTimeValidityFilter'

export const compare = (val1, val2) => {
  const a = val1.name.toLowerCase()
  const b = val2.name.toLowerCase()

  if (a < b) {
    return -1
  }

  if (a > b) {
    return 1
  }
  return 0
}
export default class BannerFilters extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    const {
      stores,
      multiStoreEnabled,
      pages,
      bannerType,
      availableLangs,
    } = this.props.options
    const { pageType } = this.state.values

    return (
      <Form>
        <div className="form-fields">
          {pages && (
            <Select
              label={getMessage('banners.select.page.type')}
              placeholder={getMessage('banners.select.page.type.placeholder')}
              name="pageType"
              testid="banner-page-type"
              options={pages}
              {...this.generateStateMappers({
                stateKeys: ['pageType'],
                defaultValue: 'HOME',
                formatValue: () => pageType?.toUpperCase() || 'HOME',
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {multiStoreEnabled && stores && pageType !== 'LINKPAY_INVOICE' && (
            <Select
              label={getMessage('banners.stores')}
              placeholder={getMessage('banners.stores.placeholder')}
              name="store"
              options={stores
                .sort(compare)
                .filter(store => store.hasPicking || store.hasSelfCheckout)
                .map(store => {
                  return {
                    text: store.name,
                    value: store.id,
                  }
                })}
              {...this.generateStateMappers({
                stateKeys: ['storeId'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {multiStoreEnabled && stores && pageType === 'LINKPAY_INVOICE' && (
            <Input
              label={getMessage('banners.stores')}
              placeholder={getMessage('banners.client-stores.placeholder')}
              value={this.state.values['clientStoreId'] || []}
              onChange={value => {
                const newValues = {
                  ...this.state.values,
                  clientStoreId: value.split(',').map(v => v.trim()),
                }
                this.setState({ values: newValues })
              }}
            />
          )}
          {bannerType && (
            <Select
              label={getMessage('banners.select.bannerImage.type')}
              placeholder={getMessage('banners.select.bannerImage.placeholder')}
              name="bannerType"
              testid="banner-type"
              options={bannerType}
              {...this.generateStateMappers({
                stateKeys: ['bannerType'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
                defaultValue: 'All',
              })}
            />
          )}
          {isExtensionEnabled('MultiLingualSupport') && (
            <Select
              label={getMessage('banners.languages')}
              name="language"
              placeholder={getMessage('banners.languages.placeholder')}
              className="language"
              options={(availableLangs || []).map(language => {
                return {
                  text: language.name,
                  value: language.code,
                }
              })}
              {...this.generateStateMappers({
                stateKeys: ['language'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          {pageType === 'CATEGORY' && (
            <CategorySearch
              label={getMessage('banners.select.category.heading')}
              placeholder={getMessage('banners.select.category.placeholder')}
              name="category"
              {...this.generateStateMappers({
                stateKeys: ['CATEGORY'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage(
                  'product.form.category.requiredMessage'
                ),
              }}
            />
          )}
          {pageType === 'BRAND' && isExtensionEnabled('MultiBrandSupport') && (
            <BrandSearch
              label={getMessage('banners.select.brand.heading')}
              placeholder={getMessage('banners.select.brand.placeholder')}
              name="brand"
              {...this.generateStateMappers({
                stateKeys: ['BRAND'],
              })}
            />
          )}
          {pageType === 'PRODUCT_TAG' &&
            isExtensionEnabled('ProductTagSupport') && (
              <Searchable
                label={getMessage('banners.select.tag.heading')}
                placeholder={getMessage('banners.select.tag.placeholder')}
                name="tag"
                searchUrl="/catalogue-service/tag"
                valueKey="id"
                responseKey="tag"
                nameKey="name"
                searchKey="name"
                expandMore={true}
                transformResponse={response => response.data.tag}
                createButton={getMessage('product.form.tag.addButton')}
                {...this.generateStateMappers({
                  stateKeys: ['PRODUCT_TAG'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            )}
          {pageType === 'SEARCH' && (
            <Input
              label={getMessage('banners.select.search.term')}
              placeholder={getMessage('banners.select.search.term.placeholder')}
              name="name"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['SEARCH_TERM'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
            />
          )}

          {/* New Section for Date Time Filter */}
          <DateTimeValidityFilter mapper={this.generateStateMappers} />
          {/* End Section */}
        </div>

        <SubmitButton testid="submit-filter">
          {getMessage('shifts.filters.submitText')}
        </SubmitButton>
        <ClearButton testid="clear-filter">
          {getMessage('shifts.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
