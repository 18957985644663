import React, { useEffect, useRef, useState } from 'react'
import { Html5Qrcode } from 'html5-qrcode'

const qrcodeRegionId = 'html5qr-code-full-region'

const ScannerStatus = {
  NOT_STARTED: 1,
  PAUSED: 3,
  SCANNING: 2,
}
// Creates the configuration object for Html5QrcodeScanner.
export const createConfig = (props) => {
  const config = {}
  if (props.fps) {
    config.fps = props.fps
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip
  }
  return config
}
export const CustomScanner = ({ qrCodeSuccessCallback, ...config }) => {
  const [cameraList, setCameraList] = useState(null)
  const [error, setError] = useState('')
  const cameraRef = useRef()

  const loadCameraList = async () => {
    await Html5Qrcode.getCameras()
      .then((devices) => setCameraList(devices))
      .catch(() => setError('Request camera permission failed'))
  }

  useEffect(() => {
    loadCameraList()
    return () => {
      setCameraList(null)
      setError('')
    }
  }, [])

  useEffect(() => {
    if (cameraList && cameraList.length && cameraRef.current) {
      // PREORDER-TODO: camera remains open after scanning
      cameraRef.current
        .start(
          { facingMode: 'environment' },
          createConfig(config),
          qrCodeSuccessCallback
        )
        .catch(setError)
    }
    return async () => {
      if (
        cameraRef.current &&
        cameraRef.current.getState() === ScannerStatus.SCANNING
      ) {
        await cameraRef.current.stop().catch(console.log)
        await cameraRef.current.clear()
      }
    }
  }, [cameraList, cameraRef.current])

  useEffect(() => {
    if (!cameraRef.current) {
      cameraRef.current = new Html5Qrcode(qrcodeRegionId)
    }
  }, [])

  return error ? (
    <div style={{ width: '100%', height: '60vh' }} id={qrcodeRegionId}>
      {error}
    </div>
  ) : (
    <div style={{ width: '100%', height: '60vh' }} id={qrcodeRegionId} />
  )
}
