export const EVoucherStatus = {
  FOR_CANCELLATION: 'MARKED FOR CANCELLATION',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  CREATED: 'VOUCHER CREATED',
  PAID: 'PAID',
  SENT: 'SENT',
  RECEIVED: 'RECEIVED',
  CANCELLED: 'CANCELLED',
  CHARGED: 'CHARGED',
  REFUNDED: 'REFUNDED',
  COMPLETED: 'COMPLETED',
  ORDER_INITIATED: 'ORDER INITIATED',
  DEPOSITED: 'DEPOSITED',
}
