import React from 'react'
import {
  BaseForm,
  Input,
  Searchable,
  VALIDATION_TYPES,
} from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'

export default class AddForm extends BaseForm {
  render() {
    const { SubmitButton } = this.buttons
    const { val } = this.props
    const { Form } = this.components
    return (
      <Form>
        {val === 'Link' ? (
          <React.Fragment>
            <Input
              required
              label="text"
              placeholder={getMessage('product.filters.link.placeholder')}
              {...this.generateStateMappers({
                stateKeys: ['linkText'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Input
              required
              label="href"
              placeholder="url"
              {...this.generateStateMappers({
                stateKeys: ['href'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Searchable
              label={getMessage('product.filters.tag.heading')}
              placeholder={getMessage('product.filters.tag.placeholder')}
              name="brand"
              searchUrl="/catalogue-service/tag"
              valueKey="id"
              nameKey="name"
              searchKey="name"
              expandMore={true}
              required
              transformResponse={response => response.data.tag}
              {...this.generateStateMappers({
                stateKeys: ['tag'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </React.Fragment>
        )}
        <SubmitButton>{getMessage('category.form.submitText')}</SubmitButton>
      </Form>
    )
  }
}
