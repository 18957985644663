import React from 'react'
import { getMessage } from '../../../../lib/translator'
import {
  BaseForm,
  Input,
  SelectSearch,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import {
  formattedMediaPackageList,
  getPackages,
} from '../../MediaCampaigns/advertiserList'


function getMobileView () {
  return window.screen.width <= 480
}

export default class CreativeForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: this.props.value,
      isMobileView: getMobileView(),
    }
    this.mobileView = this.mobileView.bind(this)
  }
  mobileView () {
    this.setState({ isMobileView: getMobileView() })
  }

  componentDidMount () {
    window.addEventListener('resize', this.mobileView, false)
    getPackages(this)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.mobileView, false)
    this.api && this.api.cancel()
  }

  componentWillReceiveProps(newProps) {
    this.setState(prevState => {
      const newState = Object.assign({}, prevState)
      newState.values = newProps.value
      return newState
    })
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    return (
      <div className='website-page-edit'>
        <Form className='website-page-form'>

          <div className='section-wrap'>
            <div className='section-row'>
              <Input
                className='section-row-input'
                label={getMessage('media.creativedetail.creative.name')}
                placeholder={getMessage('media.creativedetail.creative.name.placeholder')}
                name='creativeName'
                type='text'
                required
                {...this.generateStateMappers({
                  stateKeys: ['creative_name'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    'media.creativedetail.creative.name.requiredMessage',
                  ),
                }}
              />
              <SelectSearch
                className='section-row-input'
                name="package_id"
                required
                readOnly={this.props?.pageAction === 'edit'}
                label={getMessage('media.adsetdetail.info.package')}
                placeholder={getMessage('media.adsetdetail.info.package.placeholder')}
                multiple={false}
                options={formattedMediaPackageList(this.state.packageList)}
                nameKey="text"
                valueKey="value"
                searchByName
                {...this.generateStateMappers({
                  stateKeys: ['package_id'],
                  validationType: VALIDATION_TYPES.ONSUBMIT
                })}
                validationStrings={{
                  valueMissing: getMessage('media.adsetdetail.info.package.requiredMessage'),
                }}
              />
            </div>
          </div>

          <div className='form-buttons'>
            <SubmitButton>
              {getMessage('websitePage.form.submitText')}
            </SubmitButton>
            <CancelButton>
              {getMessage('websitePage.form.cancelText')}
            </CancelButton>
          </div>
        </Form>
      </div>
    )
  }
}
