import React from 'react'
import { BaseForm, Input } from '../../../../components/Form'
import Dialog from '../../../../components/Popup/Dialog'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import Loader from '../../../../components/Loader'
import Table, { Row, Cell, Header } from '../../../../components/Table'

import API from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
import { getSession } from '../../../../lib/auth'
import './style.css'

class RegisterOrderPayment extends BaseForm {
  componentDidMount() {
    this.setState({
      loading: true,
      showErrorDialog: false,
    })
    const searchParams = new URL(window.location).searchParams
    const pendingAmount = searchParams.get('pendingAmount')
    const clientId = searchParams.get('clientId')
    this.setState({
      pendingAmount,
      clientId,
    })
    const configApi = new API({ url: '/config-service/config/order' })
    configApi.get().then(response => {
      let disabledModes = response.data.order.finCloseDisabledPaymentModes
      disabledModes = disabledModes || []
      const api = new API({ url: '/account-service/config/order' })
      api.get().then(response => {
        const paymentModes = response.data.config.order.paymentMethods.filter(
          mode => disabledModes.indexOf(mode) === -1
        )
        if (paymentModes && !paymentModes.length) {
          const api = new API({
            url: `/order-service/order/${this.props.match.params.id}`,
          })
          api
            .put({ status: 'COMPLETED' })
            .then(() => {
              this.setState(
                {
                  submitting: false,
                },
                () => this.props.history.goBack()
              )
            })
            .catch(error => {
              if (error.code === 401) {
                throw error
              } else {
                this.setState({
                  submitting: false,
                  loading: false,
                  errorMessage:
                    (error && error.message) || 'Please try again later',
                  showErrorDialog: true,
                })
              }
            })
        } else {
          this.setState({
            loading: false,
            paymentModes,
          })
        }
      })
    })
    if (!pendingAmount) {
      const orderApi = new API({
        url: `/order-service/order/${this.props.match.params.id}`,
      })
      orderApi.get().then(response => {
        const order = response.data.order
        this.setState({
          pendingAmount: order.pendingAmount,
        })
      })
    }
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
      loading: true,
    })
    const data = Object.assign({}, formData)
    const newData = Object.keys(data)
      .map(mode => {
        if (data[mode].amount) {
          return {
            mode: mode,
            amount: data[mode].amount,
            transactionId: data[mode].transactionId,
          }
        }
        return null
      })
      .filter(Boolean)

    const api = new API({ url: '/order-service/order-payment' })
    Promise.all(
      newData.map(data => {
        const params = {}
        params.amount = data.amount
        params.mode = data.mode
        params.orderReferenceNumber = this.props.match.params.id
        if (data.transactionId) {
          params.metaData = { transaction_id: data.transactionId }
        }
        params.status = 'COMPLETED'
        return api.post(params)
      })
    )
      .then(() => {
        const api = new API({
          url: `/order-service/order/${this.props.match.params.id}`,
        })
        api.put({ status: 'COMPLETED' }).then(() => {
          this.setState(
            {
              submitting: false,
              loading: false,
            },
            () => this.props.history.goBack()
          )
        })
      })
      .catch(error => {
        if (error.code === 401) {
          throw error
        } else {
          this.setState({
            submitting: false,
            loading: false,
            errorMessage: (error && error.message) || 'Please try again later',
            showErrorDialog: true,
          })
        }
      })
  }
  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const referenceNumber = this.props.match.params.id
    const {
      paymentModes,
      order,
      values,
      pendingAmount,
      submitting,
      errorMessage,
    } = this.state
    const clientId =
      (this.state && this.state.clientId && this.state.clientId !== 'null') ||
      (order && order.clientId)
    const remainingAmount = (
      pendingAmount -
      (values &&
        Object.keys(values) &&
        Object.keys(values).reduce(
          (acc, val) => Number(values[val].amount) + acc,
          0
        ))
    ).toFixed(2)
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1>
          {getMessage('order.register.payments.for')}{' '}
          <span className="text-muted">{clientId ? '#' : 'Z'}</span>
          {clientId || referenceNumber}{' '}
        </h1>
        {this.state.showErrorDialog && (
          <Dialog
            show={this.state.showErrorDialog}
            title={'Error'}
            information={errorMessage}
            close={() => this.props.history.goBack()}
            closeText={getMessage('dialog.okText')}
          />
        )}
        {this.state.loading ? (
          <Loader />
        ) : (
          <Form className="order-payments-form">
            <Table>
              <Header>
                <Cell>{getMessage('order.payment.paymentmode')}</Cell>
                <Cell className="align-right">
                  {getMessage('order.payment.amount')} (
                  {getSession() &&
                    getSession().organization &&
                    getSession().organization.currency.symbol}
                  )
                </Cell>
                <Cell>{getMessage('order.payment.transactionid')}</Cell>
              </Header>
              {paymentModes &&
                paymentModes.map((mode, i) => (
                  <Row key={`${mode}-${i}-row`}>
                    <Cell key={`${mode}-${i}`}>{mode}</Cell>
                    <Cell className="amount">
                      <Input
                        name={`${mode}-${i}-amount`}
                        type="number"
                        min={0}
                        step={0.01}
                        {...this.generateStateMappers({
                          stateKeys: [mode, 'amount'],
                          loseEmphasisOnFill: true,
                        })}
                      />
                    </Cell>
                    <Cell className="transactionid">
                      {mode !== 'COD' && (
                        <Input
                          name={`${mode}-${i}-transactionId`}
                          type="text"
                          {...this.generateStateMappers({
                            stateKeys: [mode, 'transactionId'],
                            loseEmphasisOnFill: true,
                          })}
                          required={this.getState(mode, 'amount')}
                        />
                      )}
                    </Cell>
                  </Row>
                ))}
              <Row>
                <Cell>{getMessage('order.payment.pendingamount')}</Cell>
                <Cell className="align-right">{remainingAmount}</Cell>
                <Cell />
              </Row>
            </Table>
            <div className="form-action">
              <SubmitButton
                disabled={
                  paymentModes &&
                  paymentModes.length > 0 &&
                  (submitting ||
                    !!Number(!!remainingAmount && Number(remainingAmount)))
                }
              >
                {getMessage('order.payment.submit')}
              </SubmitButton>
            </div>
          </Form>
        )}
      </AuthenticatedPage>
    )
  }
}

export default RegisterOrderPayment
