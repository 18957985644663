import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { tableProperties } from '../../../components/Rewards/Partners/utils'
import { LinkButtons, emptyState } from '../../../components/Rewards/utils'
import '../style.css'
import './partners.css'
import PartnersForm from './Form'
import Filters from '../../../components/Rewards/Filters'
import { getMessage } from 'lib/translator'

const Partners = props => {
  return (
    <ListingPageWithRoutes
      testid="partners-listing"
      title={getMessage('rewards.partners.heading')}
      addHeading={getMessage('rewards.partner.add')}
      editHeading={getMessage('rewards.partner.edit')}
      menu={props.menu}
      className="rewards-page partners"
      api={{
        url: '/rms/partners',
        transform: response => response && response.data.list,
      }}
      headerActions={() => <LinkButtons type="partner" />}
      form={{
        component: PartnersForm,
      }}
      filters={{
        component: Filters,
        forceShow: true,
        options: {
          type: 'partner',
        },
      }}
      emptyState={emptyState('partner')}
      tableProperties={tableProperties}
      tableDynamic
    />
  )
}

export default Partners
