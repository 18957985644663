import './style.css'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import storesFilters from './Filters'
import { getMessage } from '../../../lib/translator'
import API from '../../../lib/api'
import { SplitContext } from 'containers/SplitContext'
import EmptyIcon from './store-empty.svg'
import { ManageStoreCore } from './ManageStore'
import { STORE_TECH_BASE_URL } from 'pages/customer-support/constants'
import SPLIT_FEATURES from '../../../containers/SplitContext/features'
import NewListingPage from '../../../containers/NewListingPage'
import { StoreConfigContainer } from './StoreConfiguration'
import { tableProperties } from './tableProperties'

export const clearStoreCache = (isClearStoreCache = false) => {
  if (isClearStoreCache) {
    const clearCacheAPI = new API({
      url: `${STORE_TECH_BASE_URL}/store/v0.2/clear-store-configs`,
    })
    clearCacheAPI.get().catch(console.log)
  }
}

export function updateStoreDeliveryFee(tierSetupData, store) {
  if (tierSetupData) {
    const tierSetup = tierSetupData.find((ts) => ts.id === store.tierSetupId)
    if (tierSetup) {
      store.deliveryFee = tierSetup.name
    }
  }
}

/**
 * New Version to display all actions component at the top of the
 * page
 */
function StorePageActions() {
  return (
    <div>
      <div className="text-center actions-box">
        <ManageStoreCore />{' '}
        {/* Entry point to the Manage Store - S&G Bulk Enablinge */}
        <Link to="/settings/stores/new" className="primary button">
          <span />+{' '}
          <span className="text">
            {getMessage('operations.store.add.text')}
          </span>
        </Link>
      </div>
    </div>
  )
}

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage('operations.store.empty.message'),
  additionalViews: [StorePageActions],
}

export function retrieveStoreIdFromParam(routerProps) {
  let id = null
  if (routerProps.match && routerProps.match.params) {
    const targetParam = routerProps.match.params
    if (targetParam.id) {
      id = targetParam.id
    }
  }
  return id
}

class Store extends React.Component {
  static contextType = SplitContext
  constructor() {
    super()
    this.api = new API({
      url: '/account-service/shipping-tier',
    })
    this.state = {}
    this.goBack = this.goBack.bind(this)
  }

  goBack() {
    const { router } = this.props
    router.history.push(
      router.location.pathname.replace(/(\/new)|(\/configuration\/.*)$/, '')
    )
  }

  render() {
    const { props } = this

    const { router } = props
    const storeId = retrieveStoreIdFromParam(router)

    const PAGE_ACTIONS = router.match.params.action

    const storeKindColEnabled =
      this.context.splits?.[SPLIT_FEATURES.STORE_KIND_FIELD]?.treatment === 'on'

    if (PAGE_ACTIONS === 'configuration' || PAGE_ACTIONS === 'new') {
      return (
        <StoreConfigContainer
          menu={props.menu}
          storeId={PAGE_ACTIONS === 'configuration' ? storeId : undefined}
          action={PAGE_ACTIONS === 'configuration' ? 'edit' : 'new'}
          onSubmitDone={this.goBack}
          onCancel={this.goBack}
        />
      )
    }

    return (
      <NewListingPage
        menu={props.menu}
        className="stores-page"
        title={getMessage('operations.store.heading')}
        headerActions={StorePageActions}
        emptyState={EmptyState}
        api={{
          url: `/account-service/store`,
          transform: (response) => {
            const result =
              response.data && response.data.store
                ? [...response.data.store]
                : []

            result.forEach((store) => {
              //mapping tier setups in stores with tier setup data to get names
              const { tierSetupData } = this.state
              updateStoreDeliveryFee(tierSetupData, store)
            })
            clearStoreCache(this.state.isClearStoreCache)
            this.setState({ isClearStoreCache: false })
            return result
          },
        }}
        filters={{
          component: storesFilters,
          forceShow: true,
          showFiltersOnLoad: false,
        }}
        tableProperties={tableProperties(storeKindColEnabled)}
      />
    )
  }
}

export default withRouter(({ location, history, match, ...props }) => (
  <Store router={{ location, history, match }} {...props} />
))
