import React, { Component } from 'react'
import Image from '../../../../../components/Image'
import {
  BaseForm,
  Input,
  CheckboxGroup,
  Checkbox,
  VALIDATION_TYPES,
} from '../../../../../components/Form'
import { SKINS as CHECKBOX_SKINS } from '../../../../../components/Form/Inputs/CheckboxGroup'
import { getMessage } from '../../../../../lib/translator'
import API from '../.././../../../lib/api'
import { Dialog } from '../../../../../components/Popup'

class PaymentGatewayConfigurationForm extends BaseForm {
  constructor(props) {
    super(props)
    this.showConfigs = this.showConfigs.bind(this)

    const selected = props.selected
    const selectedPaymentModes = selected.map(pm => {
      return pm.id
    })
    this.state = {
      showSuccessDialog: false,
    }
    this.state.values = {
      paymentModes: {
        paymentModes: selectedPaymentModes,
      },
      accountConfiguration: {},
    }
    this.handleDialogClose = this.handleDialogClose.bind(this)
  }

  handleDialogClose() {
    this.setState({
      showSuccessDialog: false,
    })
  }

  componentDidMount() {
    const configs = this.props.configs
    const values = this.state.values
    const transformedConfigsArray = []

    // Find all configs for the payment mode
    if (Array.isArray(configs)) {
      // Configs have not been set yet
      configs.forEach(config => {
        let transformedConfig = {}
        if (config.typeMeta) {
          config.typeMeta.forEach(meta => {
            transformedConfig = {}
            values['accountConfiguration'][meta] = ''
            transformedConfig.label = `${config.value} ${meta}`
            transformedConfig.stateKey = meta
            transformedConfig.parent = config.value
            transformedConfigsArray.push(transformedConfig)
          })
        } else {
          values['accountConfiguration'][config.value] = ''
          transformedConfig.label = config.value
          transformedConfig.stateKey = config.value
          transformedConfig.parent = config.value
          transformedConfigsArray.push(transformedConfig)
        }
      })
    } else {
      const parsedConfigs = JSON.parse(configs) // Configs got from server
      const keys = Object.keys(parsedConfigs)
      keys.forEach(key => {
        let transformedConfig = {}
        if (Array.isArray(parsedConfigs[key])) {
          transformedConfig = {}
          const details = parsedConfigs[key]
          return details.map(obj => {
            const objectKeys = Object.keys(obj)
            return objectKeys.forEach(innerKey => {
              transformedConfig = {}
              values['accountConfiguration'][innerKey] = obj[innerKey]
              transformedConfig.label = `${key} ${innerKey}`
              transformedConfig.stateKey = innerKey
              transformedConfig.parent = key
              transformedConfigsArray.push(transformedConfig)
            })
          })
        } else {
          values['accountConfiguration'][key] = parsedConfigs[key]
          transformedConfig.label = key
          transformedConfig.stateKey = key
          transformedConfigsArray.push(transformedConfig)
        }
      })
    }
    this.transformedConfigsArray = transformedConfigsArray

    // To get all the supported payment modes of the gateway
    const supportedPaymentModes = this.props.supportedPaymentModes.slice()
    const toRemove = supportedPaymentModes.filter(pm => pm.paymentModeId === null)
    const pmKeys = Object.keys(supportedPaymentModes)
    if (toRemove.length > 0) {
      const newPmodes = toRemove[0].paymentModes.filter(
        pm => pmKeys.indexOf(pm.id) === -1
      )
      toRemove[0].paymentModes = newPmodes
    }

    const modes = supportedPaymentModes.map(pm => {
      if (!pm.paymentModeName) {
        const paymentModes = pm.paymentModes.map(paymentMode => {
          const options = [
            {
              value: paymentMode.id,
              icon:
                paymentMode.imageUrl ||
                'https://png.icons8.com/ios/1600/ios-application-placeholder.png',
            },
          ]
          const key = `${paymentMode.paymentMode}-checkbox`
          return {
            key: key,
            label: paymentMode.paymentMode,
            name: `${this.props.id}-${paymentMode.paymentMode}`,
            skin: CHECKBOX_SKINS.WITH_ICONS,
            options: options,
          }
        })
        return paymentModes
      } else {
        const name = pm.paymentModeName.paymentMode
        const options = pm.paymentModes.map(paymentMode => {
          return {
            text: paymentMode.paymentMode,
            value: paymentMode.id,
            icon:
              paymentMode.imageUrl ||
              'https://png.icons8.com/ios/1600/ios-application-placeholder.png',
          }
        })
        return {
          key: `${this.props.id}${name}-checkbox`,
          label: name,
          skin: CHECKBOX_SKINS.WITH_ICONS,
          options: options,
        }
      }
    })

    this.setState({
      values: values,
      modes: modes,
    })
  }

  showConfigs() {
    const configs = this.transformedConfigsArray
    return (
      configs &&
      configs.map(config => {
        return (
          <Input
            key={`${config.label}-input`}
            label={config.label}
            name={config.name}
            required
            {...this.generateStateMappers({
              stateKeys: ['accountConfiguration', config.stateKey],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        )
      })
    )
  }

  onSubmit(data) {
    const api = new API({ url: '/account-service/payment-account' })
    const gatewayId = this.props.gatewayId
    const params = {}
    const thisGw = {}
    const gatewayPaymentModes = data.paymentModes.paymentModes.map(pm => {
      return {
        id: pm,
      }
    })
    thisGw.id = gatewayId
    thisGw.paymentModes = gatewayPaymentModes

    const { accountConfiguration } = data
    if (Array.isArray(this.props.configs)) {
      const configs = this.props.configs
      const conf = {}

      configs.forEach(config => {
        if (config.typeMeta) {
          conf[config.value] = []
          const obj = {}
          config.typeMeta.forEach(key => {
            obj[key] = accountConfiguration[key]
          })
          conf[config.value].push(obj)
        } else {
          conf[config.value] = accountConfiguration[config.value]
        }
        return true
      })
      thisGw.accountConfiguration = JSON.stringify(conf)
    } else {
      const parsedConfigs = JSON.parse(this.props.configs)
      const keys = Object.keys(parsedConfigs)
      const conf = {}
      keys.forEach(key => {
        if (Array.isArray(parsedConfigs[key])) {
          const keyArray = []
          parsedConfigs[key].forEach(obj => {
            const sub = {}
            Object.keys(obj).forEach(innerKey => {
              sub[innerKey] = data['accountConfiguration'][innerKey]
            })
            keyArray.push(sub)
          })
          conf[key] = keyArray
        } else {
          conf[key] = data['accountConfiguration'][key]
        }
      })
      thisGw.accountConfiguration = JSON.stringify(conf)
    }
    const configuredGateways = this.props.configuredGateways
    const othergateways = configuredGateways.filter(gw => gw.id !== gatewayId)
    if (!data.disable) {
      othergateways.push(thisGw)
    }
    params['type'] = 'CUSTOM'
    params['id'] = this.props.paymentAccountId
    params['paymentGateways'] = othergateways

    api.put(params).then(
      response => {
        this.props.onSuccess(response.data.paymentaccount.paymentGateways)
        this.setState({
          formError: '',
          showSuccessDialog: true,
        })
      },
      error => {
        this.setState({
          formError: error.message,
        })
      }
    )
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    return (
      <div key={`${this.props.id}-payment-gateway-configuration-form`}>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          message={getMessage('settings.online.payment.success')}
          information={getMessage('settings.online.payment.gateway.saved')}
          close={this.handleDialogClose}
          closeText={getMessage('settings.online.payment.ok')}
        />
        <Form
          key={`${this.props.id}-gateway-configuration-form`}
          className="gateway-configuration-form"
        >
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <div className="form-fields">
            {
              <div className="basic-section">
                {this.transformedConfigsArray &&
                  this.transformedConfigsArray.length > 0 &&
                  this.showConfigs()}
                <div className="show-option">
                  <Checkbox
                    inlineLabel={'Disable'}
                    name={`${this.props.id}-show`}
                    {...this.generateStateMappers({
                      stateKeys: ['disable'],
                    })}
                  />
                </div>
              </div>
            }
            <div className="portal-selection-section">
              {this.state.modes &&
                this.state.modes.map(pm => {
                  if (Array.isArray(pm)) {
                    const cpm = pm.map(loners => (
                      <CheckboxGroup
                        key={loners.key}
                        label={loners.label}
                        name={loners.name}
                        options={loners.options}
                        skin={loners.skin}
                        {...this.generateStateMappers({
                          stateKeys: ['paymentModes', 'paymentModes'],
                        })}
                      />
                    ))
                    return (
                      <div
                        className="flex"
                        key="custom-payment-mode-configuration"
                      >
                        {cpm}
                      </div>
                    )
                  } else {
                    return (
                      <CheckboxGroup
                        key={pm.key}
                        label={pm.label}
                        name={pm.name}
                        options={pm.options}
                        skin={pm.skin}
                        {...this.generateStateMappers({
                          stateKeys: ['paymentModes', 'paymentModes'],
                        })}
                      />
                    )
                  }
                })}
            </div>
          </div>
          <div className="form-actions">
            <SubmitButton>
              {getMessage('settings.online.payments.save')}
            </SubmitButton>
          </div>
        </Form>
      </div>
    )
  }
}

export default class PaymentGatewayConfiguration extends Component {
  render() {
    const containerStyle =
      !this.props.expanded || this.contentRef
        ? {
            maxHeight: this.props.expanded
              ? this.contentRef.offsetHeight + 'px'
              : 0,
          }
        : {}
    return (
      <div
        key={`${this.props.id}-payment-configuration`}
        className={`gateway-configuration ${
          this.props.expanded ? 'expanded' : ''
        }`.trim()}
      >
        <div className="header">
          <Image size="sm" bordered src={this.props.imageUrl} />
          <span>{this.props.name}</span>
          <button type="button" onClick={this.props.onToggle}>
            Edit
          </button>
        </div>
        <div className="content-container" style={containerStyle}>
          <div
            className="content"
            key={this.props.id}
            ref={node => {
              this.contentRef = node
            }}
          >
            <PaymentGatewayConfigurationForm
              id={this.props.id}
              gatewayId={this.props.gatewayId}
              supportedPaymentModes={this.props.supportedPaymentModes}
              selected={this.props.selected}
              configs={this.props.config}
              configuredGateways={this.props.configuredGateways}
              onSuccess={this.props.onSuccess}
              paymentAccountId={this.props.paymentAccountId}
            />
          </div>
        </div>
      </div>
    )
  }
}
