import React from 'react'

import {
  BaseForm,
  Select,
  Input,
  Checkbox,
  VALIDATION_TYPES,
} from '../../../components/Form'

import './style.css'

export const domains = ['user', 'model', 'filter', 'rank', 'business']

export class ConfigForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = { values: {config_id: 'config_id', split: false}, description: ''}
    this.onChangeDomain = this.onChangeDomain.bind(this)
    this.onChangeAttribute = this.onChangeAttribute.bind(this)
    this.setSubmitType = this.setSubmitType.bind(this)
    this.onLoad = this.onLoad.bind(this)
    this.canSubmit = this.canSubmit.bind(this)

    this.buttons = {
      SubmitButton: _props => (
        <input
          className="primary"
          type="submit"
          value={this.state.submitting ? '...' : _props.children}
          disabled={_props.disabled || this.state.submitting }
          data-testid={_props.testid}
          ref={_props.buttonRef}
          onClick={_props.onClick}
        />
      ),
      AttrButton: _props => (
        <button
          id="addattr-button"
          className={_props.className || "primary"}
          type="button"
          disabled={_props.disabled}
          data-testid={_props.testid}
          onClick={_props.onClick}
        >
          {_props.children}
        </button>
      ),
    }
  }

  async onLoad() {
    const result = await this.props.onHandleLoadAttributes(this.state.values['config_id'])
    if (result) {
      // TODO: load the result back
      const newState = {config_id: this.state.values['config_id']}

      newState['store_id'] = result['store_id']
      newState['split'] = result['split']
      let attributeNo = 0
      domains.forEach(domain => {
        if (result[`${domain}_domain`]) {
          result[`${domain}_domain`].map(userAttr => {
            newState[`domain:${attributeNo}`] = domain
            newState[`attr:${attributeNo}`] = userAttr['key']
            newState[`score:${attributeNo}`] = userAttr['score']
            for (var key in userAttr['key_value']) {
              newState[`type:${attributeNo}`] = key
              newState[`key:${attributeNo}`] = userAttr['key_value'][key]
            }
            attributeNo++
          })
        }

      }
      )
      this.setState({values: newState})

    }
  }

  setSubmitType(submitType) {
    this.setState({ values: { ...this.state.values, submit_type: submitType } })
  }

  onChangeDomain(attributeNo) {
    const oldState = this.state.values
    delete oldState['type:' + attributeNo]
    delete oldState['attr:' + attributeNo]
    this.setState({ values: oldState })
  }

  onChangeAttribute(attributeNo) {
    const oldState = this.state.values
    delete oldState['type:' + attributeNo]
    this.setState({ values: oldState })
  }

  canSubmit() {
    return !isNaN(this.state.values.store_id)
  }

  canSave() {
    return this.state.values.config_id !== 'config_id'
  }

  render() {
    const configs = [...Array(this.props.count).keys()].map(attributeNo => {
      const domainKey = this.state.values['domain:' + attributeNo]
      let attributesKey = []
      let typeKey = []
      let description = ''
      var storeID
      if (attributeNo === 0) {
        storeID = (
          <div className="domain-select">
            <Input
              name="score"
              value="1"
              type="text"
              testid="store-input"
              {...this.generateStateMappers({
                stateKeys: ['store_id'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        )
      }
      if (domainKey) {
        attributesKey = [
          ...new Set(this.props.orchidContract[domainKey].map(x => x.key)),
        ]
        if (this.state.values['attr:' + attributeNo]) {
          const key = this.props.orchidContract[domainKey].find(
            x => x.key === this.state.values['attr:' + attributeNo]
          )
          if (key) {
            description = key.description
            typeKey = key.key_type
          }
        }
      }
      return (
        <div className="config-select" key={attributeNo}>
          <div className="domain-select">
            <Select
              name="domain-select"
              id="domain-select"
              testid="domain-select"
              placeholder="Domain"
              options={domains}
              {...this.generateStateMappers({
                stateKeys: ['domain:' + attributeNo],
                onChange: () => this.onChangeDomain(attributeNo),
              })}
            />
          </div>
          <div className="domain-select">
            <Select
              name="attribute-select"
              id="attribute-select"
              testid="attribute-select"
              placeholder="Attributes"
              options={attributesKey}
              value={attributesKey}
              {...this.generateStateMappers({
                stateKeys: ['attr:' + attributeNo],
                onChange: () => this.onChangeAttribute(attributeNo),
              })}
            />
          </div>
          <div className="domain-select">
            <Input
              name="score"
              value="1"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['score:' + attributeNo],
                defaultValue: 1,
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="domain-select">
            <Input
              name="key"
              value="1"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['key:' + attributeNo],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className="domain-select">
            <Select
              name="attribute-select"
              placeholder="Type"
              options={typeKey}
              value={typeKey}
              {...this.generateStateMappers({
                stateKeys: ['type:' + attributeNo],
              })}
            />
          </div>
          {storeID}
          <div className="description">
            <p>{description}</p>
          </div>
        </div>
      )
    })

    const { Form } = this.components
    const {
      SubmitButton,
      AttrButton,
    } = this.buttons
    return (
      <div>
        <Form testid="submit">
          <div>
            <AttrButton onClick={this.props.onHandleAddAttributes}>+</AttrButton>
            <AttrButton className="secondary" onClick={this.props.onHandleDelAttributes}>-</AttrButton>
          </div>
          <div className="config-select">
            <div className="domain-select">
              <h3>Domain</h3>
            </div>
            <div className="domain-select">
              <h3>Attributes</h3>
            </div>
            <div className="domain-select">
              <h3>Score</h3>
            </div>
            <div className="domain-select">
              <h3>Key</h3>
            </div>
            <div className="domain-select">
              <h3>Key Type</h3>
            </div>
            <div className="domain-select">
              <h3>StoreID</h3>
            </div>
          </div>
          {configs}
          <SubmitButton onClick={() => this.setSubmitType('DISPLAY')} disabled={!this.canSubmit()} testid="display-items"> Display </SubmitButton>
          <div className="config-save">
          <SubmitButton onClick={() => this.setSubmitType('SAVE_CONFIG')} disabled={!this.canSave()} testid="save-config"> Save Config</SubmitButton>
            <AttrButton onClick={this.onLoad} testid="load-config">Load Config</AttrButton>
          </div>
          <div className="config-save">
            <Input
              name="config_id"
              testid="set-config-id"
              value="1"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['config_id'],
                defaultValue: 'config_id',
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div>
            <Checkbox
              testid="split-id"
              inlineLabel="enable split"
              name="split"
              label={this.state.values.split}
              {...this.generateStateMappers({
                stateKeys: ['split'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        </Form>
      </div>
    )
  }
}
