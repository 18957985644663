import React, { useContext } from 'react'
import { Input, BaseForm, VALIDATION_TYPES, Select, Checkbox } from 'components/Form'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { SplitContext } from 'containers/SplitContext'
import API from 'lib/api'
import { hasPermissions } from 'lib/auth'
import { getMessage } from 'lib/translator'
import { cloneMutables } from 'lib/stateManagement'

import editIcon from '../edit-icon.svg'
import './style.css'

const freeTextValue  = getMessage('deliveryOrder.deliveryNote.freeText.value');

/* Delivery Note on delivery order details page is now renamed as Delivery Preference  */
class DeliveryNote extends BaseForm {
  constructor(props) {
    super(props)
    const { deliveryNoteData } = this.props;
    const isCustomerInstructionInFlags = this.isCustomerInstructionInFlags();
    this.state = {
      editing: false,
      isSaveEnabled: false,
      canBeEdited: false,
      customerInstructionsOptions: this.getCustomerInstructionOptions(),
      isCustomerInstructionFreeText: !isCustomerInstructionInFlags,
      deliveryNote: {
        referenceNumber: deliveryNoteData.referenceNumber,
        dispatcherNote: deliveryNoteData.dispatcherNote,
        customerInstruction: deliveryNoteData.deliveryNotes?.customerInstruction,
        customerInstructionFreeText: deliveryNoteData.deliveryNotes?.customerInstruction,
        earlyDelivery: deliveryNoteData.deliveryNotes?.deliveryPreference?.earlyDelivery,
        leaveAtDoor: deliveryNoteData.deliveryNotes?.deliveryPreference?.leaveAtDoor,
      },
      values: {
        referenceNumber: deliveryNoteData.referenceNumber,
        dispatcherNote: deliveryNoteData.dispatcherNote,
        customerInstruction: isCustomerInstructionInFlags ? deliveryNoteData.deliveryNotes?.customerInstruction : freeTextValue,
        customerInstructionFreeText: deliveryNoteData.deliveryNotes?.customerInstruction,
        earlyDelivery: !!deliveryNoteData.deliveryNotes?.deliveryPreference?.earlyDelivery,
        leaveAtDoor: !!deliveryNoteData.deliveryNotes?.deliveryPreference?.leaveAtDoor,
      }
    }
    this.handleCustomerInstructionChange = this.handleCustomerInstructionChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isSaveEnabled &&
        (prevState.values.earlyDelivery !== prevState.deliveryNote.earlyDelivery ||
        prevState.values.leaveAtDoor !== prevState.deliveryNote.leaveAtDoor ||
        prevState.values.dispatcherNote !== prevState.deliveryNote.dispatcherNote ||
        prevState.values.customerInstruction !== prevState.deliveryNote.customerInstruction ||
        prevState.values.customerInstructionFreeText !== prevState.deliveryNote.customerInstructionFreeText)) {
          this.setState({ isSaveEnabled: true })
    }
  }

  componentDidMount() {
    this.setState({ canBeEdited: true })
  }

  generateStateFromProps(state) {
    const newState = Object.assign({}, state)
    const values = {
      referenceNumber: this.state.deliveryNote.referenceNumber,
      dispatcherNote: this.state.deliveryNote.dispatcherNote,
      customerInstruction: this.state.deliveryNote.customerInstruction,
      customerInstructionFreeText: this.state.deliveryNote.customerInstructionFreeText
    }
    newState.values = values
    return cloneMutables(newState)
  }

  onSubmit(formData) {
    const { isSaveEnabled } = this.state
    const { isCustomerInstructionFreeText } = this.state;
    if (isSaveEnabled) {
      const data = Object.assign({}, formData)
      const { referenceNumber } = this.state.values;
      this.deliveryNoteApi = new API({
        url: `/order-service/v3/deliveryOrdersNotes/${referenceNumber}`,
      })
      const params = {
        dispatcherInstruction: data.dispatcherNote,
        deliveryPreference: {
          leaveAtDoor: data.leaveAtDoor,
          earlyDelivery: data.earlyDelivery,
        },
        customerInstruction: isCustomerInstructionFreeText ? data.customerInstructionFreeText : data.customerInstruction
      }
      this.deliveryNoteApi.post(params).then(response => {
        if(response) {
          this.setState(prevState => ({
            deliveryNote: {
              ...prevState.deliveryNote,
              dispatcherNote: params.dispatcherInstruction,
              customerInstruction: params.customerInstruction,
              customerInstructionFreeText: this.getCustomerInstructionFreeText(isCustomerInstructionFreeText, data),
              leaveAtDoor: params.deliveryPreference.leaveAtDoor,
              earlyDelivery: params.deliveryPreference.earlyDelivery
            },
            values: {
              ...prevState.values,
              referenceNumber,
              dispatcherNote: params.dispatcherInstruction,
              customerInstruction: isCustomerInstructionFreeText ? freeTextValue : params.customerInstruction,
              customerInstructionFreeText: this.getCustomerInstructionFreeText(isCustomerInstructionFreeText, data),
              leaveAtDoor: params.deliveryPreference.leaveAtDoor,
              earlyDelivery: params.deliveryPreference.earlyDelivery
            },
            editing: false,
            isSaveEnabled: false,
          }))
        }
      }).catch(error => {
        this.handleError(error)
      })
    }
  }

  getCustomerInstructionFreeText(isFreeText, data) {
    return isFreeText ? data.customerInstructionFreeText : '';
  }

  handleError(error) {
    if (error.code === 401) {
      throw error
    }
    this.setState({
      error: error.message,
      editing: false,
      isSaveEnabled: false,
    })
  }

  componentWillUnmount() {
    this.deliveryNoteApi && this.deliveryNoteApi.cancel()
  }

  getYesNoOptions(value) {
    if (value === true) {
      return "Yes";
    } else if (value === false) {
      return "No";
    }
    return "-";
  }

  getCustomerInstructionOptions() {
    const { deliveryPreference } = this.props;
    if (deliveryPreference) {
      return deliveryPreference?.instructions.map(instruction => (
        { text: instruction, value: instruction }
      )).concat({ text: freeTextValue, value: freeTextValue });
    }
    return [];
  }

  isCustomerInstructionInFlags() {
    const { deliveryPreference, deliveryNoteData } = this.props;

    // if there is no deliveryNotes or customerInstruction empty, return true.
    // because if it is false, customer instruciton dropdown will display Others by default, because there is no Others field in split.
    // If it is true, customer instruciton dropdown will display default placeholder.
    if (deliveryNoteData.deliveryNotes === null || !deliveryNoteData.deliveryNotes?.customerInstruction) {
      return true;
    }
    return deliveryPreference?.instructions.includes(deliveryNoteData.deliveryNotes?.customerInstruction)
  }

  handleCustomerInstructionChange(value) {
    // if selection is Others, show Others field.
    // else hide Others field and clear Others field
    if (value === freeTextValue) {
      this.setState({ isCustomerInstructionFreeText: true });
    } else {
      this.setState(prevState => {
        const newState = Object.assign({}, prevState)
        newState.deliveryNote.customerInstructionFreeText = "";
        newState.values.customerInstructionFreeText = "";
        newState.isCustomerInstructionFreeText = false;
        return newState
      })
    }
  }

  render() {
    const { editing, isSaveEnabled, deliveryNote, isCustomerInstructionFreeText } = this.state;
    const { deliveryPreference } = this.props;
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const canBeEdited = hasPermissions('order', 'order', 'put')

    return (
      <div className={'delivery-note-form'}>
        {editing ? (
          <Form>
            <div className="flex-around section-title">
              <h3>{getMessage('deliveryOrder.deliveryPreference.heading')}</h3>
            </div>
            <div className="delivery-note">
              <Input
                className="string"
                type="text"
                label={getMessage('deliveryOrder.deliveryNote.form.dispatcherNote')}
                placeholder={getMessage('deliveryOrder.deliveryNote.form.dispatcherNote.placeholder')}
                name="dispatcherNote"
                {...this.generateStateMappers({
                  stateKeys: ['dispatcherNote'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            {
              deliveryPreference?.instructions &&
              <div className="delivery-note">
                <Select
                  testid="customer-instruction-select"
                  options={this.state.customerInstructionsOptions}
                  label={getMessage('deliveryOrder.deliveryNote.form.customerInstruction')}
                  placeholder={getMessage('deliveryOrder.deliveryNote.form.customerInstruction.placeholder')}
                  {...this.generateStateMappers({
                    stateKeys: ['customerInstruction'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                    onChange: (value) => this.handleCustomerInstructionChange(value)
                  })}
                />
              </div>
            }
            {
              deliveryPreference?.instructions && isCustomerInstructionFreeText &&
              <div className='delivery-note'>
                <Input
                  label={getMessage('deliveryOrder.deliveryNote.form.customerInstruction.freeText')}
                  placeholder={getMessage('deliveryOrder.deliveryNote.form.customerInstruction.freeText.placeholder')}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['customerInstructionFreeText'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>
            }
            <div className="delivery-note">
              <Checkbox
                inlineLabel={getMessage('deliveryOrder.deliveryNote.earlyDelivery')}
                name='early-delivery'
                className='early-delivery'
                testid='early-delivery-checkbox'
                controlled
                {...this.generateStateMappers({
                  stateKeys: ['earlyDelivery'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <div className="delivery-note">
              <Checkbox
                inlineLabel={getMessage('deliveryOrder.deliveryNote.leaveAtDoor')}
                name='leave-at-door'
                className='leave-at-door'
                testid='leave-at-door-checkbox'
                controlled
                {...this.generateStateMappers({
                  stateKeys: ['leaveAtDoor'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <br />
            {canBeEdited && (
              <div className="form-action">
                <SubmitButton disabled={!isSaveEnabled}>
                  {getMessage('deliveryOrder.deliveryNote.form.submit')}
                </SubmitButton>
                <button
                  type="button"
                  className="button"
                  onClick={() =>
                    this.setState({ editing: false })
                  }
                >
                  {getMessage('deliveryOrder.deliveryNote.form.cancel')}
                </button>
              </div>
            )}
          </Form>
        ) : (
          <div className="delivery-note-form">
            <div className="flex-around section-title">
              <h3>{getMessage('deliveryOrder.deliveryPreference.heading')}</h3>
              {this.props.editable ? (
                <img
                  src={editIcon}
                  alt="Edit"
                  className="edit-icon"
                  onClick={() => this.setState({ editing: true })}
                />
              ) : null}
            </div>
            <div>
              <small className="text-muted">
                {getMessage('deliveryOrder.deliveryNote.form.dispatcherNote')}
                <span className="creation-time">
                {deliveryNote.dispatcherNote ? " "+deliveryNote.dispatcherNote : " none"}
                </span>
              </small>
            </div>

            <div>
              <small className="text-muted">
                {getMessage('deliveryOrder.deliveryPreference.customerInstruction')}
                &nbsp;
                <span className="delivery-preference">
                {
                  deliveryNote?.customerInstruction ? deliveryNote.customerInstruction : "none"
                }
                </span>
              </small>
            </div>

            <div>
              <small className="text-muted">
                {getMessage('deliveryOrder.deliveryPreference.earlyDelivery')}
                &nbsp;
                <span className="delivery-preference">
                  {this.getYesNoOptions(deliveryNote?.earlyDelivery)}
                </span>
              </small>
            </div>

            <div>
              <small className="text-muted">
              {getMessage('deliveryOrder.deliveryPreference.leaveAtDoor')}
                &nbsp;
                <span className="delivery-preference">
                  {this.getYesNoOptions(deliveryNote?.leaveAtDoor)}
                </span>
              </small>
            </div>
          </div>
        )}
      </div>
    )
  }
}

function DeliveryNoteWrapper(props) {
  const splitConfig = useContext(SplitContext);
  const { splits } = splitConfig;
  const deliveryPreferenceFFS = splits?.[SPLIT_FEATURES.DELIVERY_PREFERENCE_FFS]?.treatment === "TreatmentGroup" ?
    JSON.parse(JSON.parse(splits?.[SPLIT_FEATURES.DELIVERY_PREFERENCE_FFS]?.config).config) : null;

  const deliveryPreferencePFC = splits?.[SPLIT_FEATURES.DELIVERY_PREFERENCE_PFC]?.treatment === "TreatmentGroup" ?
    JSON.parse(JSON.parse(splits?.[SPLIT_FEATURES.DELIVERY_PREFERENCE_PFC]?.config).config) : null;

  const deliveryPreference =  props.storeName && props.storeName.endsWith('PFC') ? deliveryPreferencePFC : deliveryPreferenceFFS;
  return <DeliveryNote {...props} deliveryPreferenceFFS={deliveryPreferenceFFS} deliveryPreference={deliveryPreference} />
}

export default DeliveryNoteWrapper
