import React, { Component } from 'react'
import { MultiTextInput, Input } from '../../../../../components/Form'
// import { getCurrency } from '../../../../../lib/userDetails'
import { getMessage } from '../../../../../lib/translator'
import crossIcon from './cross-icon.svg'
import deleteIcon from '../icon-dustbin.svg'
import './style.css'

class PinCodesDisplay extends Component {
  render() {
    const {
      pincodeGroups,
      selectedArea,
      selectedPincode,
      editing,
      handlePincodeDelete,
      handleZoneDelete,
    } = this.props
    return (
      <div className={`pincode-group-list ${editing ? 'edit' : 'saved'}`}>
        {pincodeGroups.map((group, index) => {
          return (
            <React.Fragment key={`pincode-group-${index}`}>
              <div className="pincode-group-name">
                <span>
                  {group.name
                    ? group.name
                    : getMessage('extensions.deliveryArea.zone.unnamed')}
                </span>
              </div>
              <div
                className={`pincode-group ${
                  selectedArea === group.id ? 'active' : ''
                }`}
              >
                {group.area.pincodes.map((pin, i) => {
                  return (
                    <div
                      className={`pincode ${
                        selectedPincode !== undefined &&
                        selectedPincode === pin.id
                          ? 'selected'
                          : ''
                      }`}
                      key={`pincode${index}${i}`}
                    >
                      {pin.pincode.toString().length < 6 ? (
                        <span>{'0' + pin.pincode.toString()}</span>
                      ) : (
                        <span>{pin.pincode}</span>
                      )}
                      {editing && (
                        <img
                          src={crossIcon}
                          onClick={() =>
                            handlePincodeDelete(
                              group.id || group.tempId,
                              pin.pincode
                            )
                          }
                          alt="cross icon"
                        />
                      )}
                    </div>
                  )
                })}
                {editing && (
                  <span className="delete-group">
                    <img
                      onClick={() => {
                        handleZoneDelete(group.id || group.tempId)
                      }}
                      src={deleteIcon}
                      alt="delete"
                    />
                  </span>
                )}
              </div>
              <div key={`hr${index}`} className="horizontal-line">
                <hr />
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}
export default class PincodeFromComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pincodeArea: props.pincodeArea,
      pincodeInput: '',
      name: '',
      mode: 'add',
      pincodeGroupEditId: null,
    }
    this.handlePincodeAdd = this.handlePincodeAdd.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.handleAddPincodeGroup = this.handleAddPincodeGroup.bind(this)
    this.clearTempData = this.clearTempData.bind(this)
    this.setPincodeInput = this.setPincodeInput.bind(this)
    this.changeMode = this.changeMode.bind(this)
    this.changePincodeGroupEditId = this.changePincodeGroupEditId.bind(this)
    this.handleZoneDelete = this.handleZoneDelete.bind(this)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.pincodeArea &&
      nextProps.pincodeArea.length &&
      this.setState({ pincodeArea: nextProps.pincodeArea })
  }
  handlePincodeAdd(value) {
    this.setState({ pincodeInput: value })
  }
  clearTempData() {
    this.setState({
      pincodeInput: '',
      name: '',
      mode: 'add',
      pincodeGroupEditId: null,
    })
  }
  changeMode(mode) {
    this.setState({ mode })
  }
  changePincodeGroupEditId(id) {
    this.setState({ pincodeGroupEditId: id })
  }
  setPincodeInput(valueArr, name) {
    this.setState({ pincodeInput: valueArr, name })
  }
  handleZoneDelete(id) {
    if (id === this.state.pincodeGroupEditId) {
      this.clearTempData()
    }
    this.props.handleZoneDelete(id)
  }
  handleAddPincodeGroup() {
    const { parent } = this.props
    const randomId = new Date().getTime()
    const currentState = JSON.parse(JSON.stringify(parent.state))
    const { tempDeliveryArea: tempDeliveryAreaState, deliveryarea } =
      currentState
    let tempDeliveryArea = tempDeliveryAreaState
    const mode = this.state.mode
    const editId = this.state.pincodeGroupEditId
    if (mode === 'add') {
      tempDeliveryArea.push({
        configType: 'PINCODE',
        tempId: randomId,
        name: this.state.name,
        area: {
          pincodes: this.state.pincodeInput.map((pincode) => {
            return { pincode: Number(pincode) }
          }),
        },
      })
    } else if (mode === 'edit' && editId) {
      const pincodeGroupFromState = tempDeliveryArea.find(
        (zone) => zone.id === editId || zone.tempId === editId
      )
      if (pincodeGroupFromState) {
        const newTempDeliveryArea = tempDeliveryArea.filter(
          (zone) => zone.id !== editId && zone.tempId !== editId
        )
        pincodeGroupFromState.area.pincodes = this.state.pincodeInput.map(
          (pincode) => {
            return {
              pincode: Number(pincode),
              id:
                pincodeGroupFromState.area.pincodes.find(
                  (pincodeObj) => pincodeObj.pincode === Number(pincode)
                ) &&
                pincodeGroupFromState.area.pincodes.find(
                  (pincodeObj) => pincodeObj.pincode === Number(pincode)
                ).id,
            }
          }
        )
        pincodeGroupFromState.name = this.state.name || ''
        newTempDeliveryArea.push(pincodeGroupFromState)
        tempDeliveryArea = newTempDeliveryArea
      } else {
        const pincodeGroup = deliveryarea.find((zone) => zone.id === editId)
        pincodeGroup.area.pincodes = this.state.pincodeInput.map((pincode) => {
          return {
            pincode: Number(pincode),
            id:
              pincodeGroup.area.pincodes.find(
                (pincodeObj) => pincodeObj.pincode === Number(pincode)
              ) &&
              pincodeGroup.area.pincodes.find(
                (pincodeObj) => pincodeObj.pincode === Number(pincode)
              ).id,
          }
        })
        pincodeGroup.name = this.state.name || ''
        tempDeliveryArea.push(pincodeGroup)
      }
    }
    parent.setState({ tempDeliveryArea })
    this.clearTempData()
  }
  onKeyDown(e) {
    if (e.keyCode === 13) {
      // whenever enter is pressed then create a tag with text input
      e.preventDefault() // prevent submission of form on Enter
      this.addValue(e.target.value)
      e.target.value = ''
    }
  }
  render() {
    const {
      active,
      pincodeArea = [],
      editing,
      handlePincodeDelete,
    } = this.props
    return (
      <div
        className={`pincode-form zones-subsection ${
          active ? '' : 'hidden-section'
        }`}
      >
        <span
          onClick={() => this.props.handleOpenClose('pincode')}
          className="section-title"
        >
          {getMessage('extensions.deliveryArea.pincodes.heading')}
        </span>
        <span
          onClick={() => this.props.handleOpenClose('pincode')}
          className="open-close-icon"
        >
          {active ? '\u2013' : '+'}
        </span>
        <div className="zones-content">
          {/* <label htmlFor='zipcodes'>zip codes</label> */}
          {editing && (
            <Input
              placeholder="Enter zone name"
              name="zone-name"
              type="text"
              value={this.state.name}
              onChange={(name) => this.setState({ name })}
            />
          )}
          {editing && (
            <MultiTextInput
              placeholder="Enter zip code"
              name="zipcodes"
              className="zipcode-input edit-section"
              value={this.state.pincodeInput}
              onChange={(value) => this.handlePincodeAdd(value)}
            />
          )}
          <div className="flex">
            {this.state &&
              this.state.pincodeInput &&
              Array.isArray(this.state.pincodeInput) &&
              this.state.pincodeInput.length > 0 && (
                <button
                  onClick={this.handleAddPincodeGroup}
                  className="button primary update-group"
                >
                  {this.state.mode === 'add' ? 'Add' : 'Update'}
                </button>
              )}
            {this.state &&
              this.state.pincodeInput &&
              Array.isArray(this.state.pincodeInput) &&
              this.state.pincodeInput.length > 0 && (
                <button
                  onClick={this.clearTempData}
                  className="button cancel-update"
                >
                  {this.state.mode === 'add' ? 'Clear' : 'Cancel'}
                </button>
              )}
          </div>
          {pincodeArea && pincodeArea.length > 0 && (
            <PinCodesDisplay
              pincodeGroups={pincodeArea}
              editing={editing}
              handlePincodeDelete={handlePincodeDelete}
              handleZoneDelete={this.handleZoneDelete}
            />
          )}
        </div>
      </div>
    )
  }
}
