import React from 'react'
import Select from '../../../components/Form/Inputs/Select'

const JobConfig = {
  UpdatePackInfo: {
    name: 'Update pack info',
    description:
      'Updates pack info. This replaces all old pack info for a product and update with new values from CSV',
  },
  UpdatePLUNumber: {
    name: 'Update PLU number or Display Unit',
    description: 'Updates PLU number or Display Unit for give SKUs',
  },
  UpdateDailyPreset: {
    name: 'Update daily preset or variance',
    description:
      'Updates daily preset and variance value for give SKUs and store id',
  },
  UpdatePickingThreshold: {
    name: 'Update picking threshold',
    description: 'Updates Picking threshold value for given SKUs',
  },
  UpdateSKULocation: {
    name: 'Update SKU location',
    description: 'Updates Aisle value for given SKUs',
  },
  UpdateBulkRoutingThreshold: {
    name: 'Update Store Bulk Threshold',
    description: 'Updates store bulk thresholds for given SKUs',
  },
  UpdateBulkProductLocation: {
    name: 'Update Bulk Product Location',
    description: 'Updates bulk product location for given store and SKUs',
  },
}

const JobsDropdown = (props) => {
  const { onChange, value, isJobError, disabled } = props

  const jobs = Object.keys(JobConfig).map((key) => ({
    text: JobConfig[key].name,
    value: key,
  }))

  return (
    <div className="batch-upload-jobs">
      <div className={`dropdown-wrapper ${isJobError && 'hasError'}`}>
        <span className="labelWrap">
          <label htmlFor="BatchUploadDropdown">Jobs</label>
        </span>
        <Select
          id="batch-upload-dropdown"
          name="BatchUploadDropdown"
          testid="batch-upload-actions-select"
          placeholder="Select a job"
          value={value}
          options={jobs}
          onChange={onChange}
          disabled={disabled}
        />
        {isJobError && <span className="form-error">Please select a job</span>}
        <p className="panel"> {value && JobConfig[value].description}</p>
      </div>
    </div>
  )
}

export default JobsDropdown
