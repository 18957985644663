import React, { Component } from 'react'
import DateRangePickerWrapper from '../../../DateRangePickerWrapper'
import './style.css'

function getValidationObj(props, dateRange) {
  const valueMissing =
    props.required && (!dateRange || !dateRange.startDate || !dateRange.endDate)
  return {
    valueMissing,
    valid: !valueMissing,
  }
}

export default class DateRangePicker extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.runValidation = this.runValidation.bind(this)
  }

  runValidation(dateRange) {
    this.props.onValidation &&
      this.props.onValidation(getValidationObj(this.props, dateRange))
  }

  handleChange(startDate, endDate) {
    const dateRange = { startDate: startDate, endDate: endDate }
    this.props.onChange(dateRange)
    this.runValidation(dateRange)
  }

  componentDidMount() {
    this.runValidation(this.props.value)
  }

  render() {
    const {
      value,
      displayFormat,
      required,
      startDatePlaceholder,
      endDatePlaceholder,
      minimumNights,
      isOutsideRange,
      isDayBlocked,
      openDirection,
      name,
      key,
    } = this.props
    return (
      <DateRangePickerWrapper
        startDate={value && value.startDate}
        endDate={value && value.endDate}
        displayFormat={displayFormat}
        required={required}
        onDatesChange={this.handleChange}
        startDatePlaceholder={startDatePlaceholder}
        endDatePlaceholder={endDatePlaceholder}
        minimumNights={minimumNights}
        isOutsideRange={isOutsideRange}
        isDayBlocked={isDayBlocked}
        openDirection={openDirection}
        name={name}
        key={key}
      />
    )
  }
}
