import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import InvoiceSummary from '../InvoiceSummary'
import Table, { Header, Row, Cell } from '../../../../../components/Table'
import Image from '../../../../../components/Image'
import { ProductSearch } from '../../../../../components/Form'
import {
  getNestedState,
  updateStateRecursively,
} from '../../../../../lib/stateManagement'
import { getMessage } from '../../../../../lib/translator'
import { Dialog } from '../../../../../components/Popup'
import Loader from '../../../../../components/Loader'

import './style.css'
import editIcon from '../edit-icon.svg'
import substitutionIcon from './substitution-icon.svg'

const isMarketplaceSku = clientItemId => clientItemId >= 90000000

const ItemRow = ({
  item,
  index,
  loadedFromInvoice,
  editing,
  status,
  currency,
}) => {
  if (
    loadedFromInvoice &&
    status === 'COMPLETED' &&
    !Number(item.orderDetails.deliveredQuantity)
  ) {
    return null
  }
  const image = item.images && item.images[0] ? item.images[0] : null
  const mrp = Number(item.orderDetails.mrp)
  const discount = Number(item.orderDetails.discount) || 0
  const orderedQuantity = item.orderDetails.orderedQuantity
  const pickedQuantity = Number(item.orderDetails.deliveredQuantity)
  const chargeableQuantity =
    item.orderDetails.status === 'PENDING' ||
    (item.orderDetails['deliveredQuantity'] === undefined &&
      item.orderDetails.status === undefined) ||
    pickedQuantity > orderedQuantity
      ? orderedQuantity
      : pickedQuantity
  const isSoldByWeight = item.soldByWeight || false
  const name = item.fullName || item.name
  const clientItemId = item.clientItemId
  const comment =
    item &&
    item.orderDetails &&
    item.orderDetails.metaData &&
    item.orderDetails.metaData.comment
  let unit =
    (name &&
      name
        .split(' ')
        .slice(-1)
        .pop()) ||
    ''
  unit = unit.toUpperCase()
  const weight = name ? name.split(' ').slice(-2)[0] : 1
  let divider = 1
  let multiplier = 1
  let smallUnit = 'kg'
  if (unit === 'KG' || unit === 'L') {
    smallUnit = unit === 'KG' ? ' g' : ' ml'
    multiplier = weight * 1000
    divider = weight * 1000
  }
  if (unit === 'G' || unit === 'GM' || unit === 'ML') {
    multiplier = weight
    divider = weight
  }
  if (orderedQuantity === 0) {
    return null
  }
  return (
    <Row
      key={item.id || `_${index}`}
      className={!!item.orderDetails.substitutedItemId && 'child-row'}
    >
      <Cell className="item-image">
        {!item.orderDetails.substitutedItemId && (
          <React.Fragment>
            <Image bordered size="sm" src={image} />
            {item.orderDetails.substitutionItem && (
              <img
                className="substitution-icon-img"
                src={substitutionIcon}
                alt="Substitution Icon"
              />
            )}
          </React.Fragment>
        )}
      </Cell>
      <Cell className="item-name">
        <Link to={`/catalogue/products/edit/${item.id}`} className="item-link">
          {!!item.orderDetails.substitutedItemId && (
            <Image bordered size="sm" src={image} />
          )}
          <small className="text-muted">
            {item.brand ? (
              <div className="product-name">
                {' '}
                <span className="brand-name">{item.brand.name}</span> {name}
              </div>
            ) : (
              name
            )}
          </small>
          {comment && <div className="text-muted item-comment">{comment}</div>}
        </Link>
      </Cell>
      {!loadedFromInvoice && (
        <Cell className="client-item-id text-muted">
          <Link to={`/catalogue/products/edit/${item.id}`}>
            <div className="item-table-mobile-view">
              {getMessage('order.details.itemsTable.header.clientItemId')}
            </div>
            {clientItemId}
            {isMarketplaceSku(clientItemId) ? (
              <span className="mkp-label">(MKP)</span>
            ) : (
              <span />
            )}
          </Link>
        </Cell>
      )}
      {!loadedFromInvoice && (
        <Cell className="item-original text-right">
          <span className="mobile-view-header">
            {getMessage('order.details.itemsTable.header.quantity')}
          </span>
          <small className="text-muted">
            {editing ? (
              <input
                type="number"
                placeholder="0"
                min={1}
                step={1}
                value={
                  isSoldByWeight
                    ? this.getNestedState([
                        'items',
                        index,
                        'orderDetails',
                        'orderedQuantity',
                      ]) === ''
                      ? this.getNestedState([
                          'items',
                          index,
                          'orderDetails',
                          'orderedQuantity',
                        ])
                      : parseFloat(
                          Number(
                            this.getNestedState([
                              'items',
                              index,
                              'orderDetails',
                              'orderedQuantity',
                            ])
                          )
                        )
                    : !this.getNestedState([
                        'items',
                        index,
                        'orderDetails',
                        'orderedQuantity',
                      ])
                    ? this.getNestedState([
                        'items',
                        index,
                        'orderDetails',
                        'orderedQuantity',
                      ])
                    : Number(
                        this.getNestedState([
                          'items',
                          index,
                          'orderDetails',
                          'orderedQuantity',
                        ])
                      ).toFixed(0)
                }
                onChange={e => {
                  if (e) {
                    const updatedValue =
                      e.target.value === ''
                        ? ''
                        : Math.floor(Math.abs(e.target.value))
                    this.updateStateRecursively(
                      ['items', index, 'orderDetails', 'orderedQuantity'],
                      updatedValue
                    )
                  }
                }}
                onBlur={() => {
                  const keys = [
                    'items',
                    index,
                    'orderDetails',
                    'orderedQuantity',
                  ]
                  this.updateStateRecursively(
                    keys,
                    Number(this.getNestedState(keys))
                  )
                }}
              />
            ) : isSoldByWeight &&
              item.metaData['Unit Of Measurement'] &&
              item.metaData['Unit Of Measurement'].trim() === 'KGM' ? (
              (Number(orderedQuantity) / divider).toFixed(3) + smallUnit
            ) : (
              Number(orderedQuantity).toFixed(0)
            )}
          </small>
        </Cell>
      )}
      {loadedFromInvoice && (
        <Cell className="item-quantity text-right">
          <span className="mobile-view-header">
            {getMessage('order.details.itemsTable.header.quantity')}
          </span>
          <small className="text-muted">
            {pickedQuantity
              ? isSoldByWeight
                ? (pickedQuantity / divider).toFixed(3)
                : pickedQuantity.toFixed(0)
              : isSoldByWeight
              ? (Number(orderedQuantity) / divider).toFixed(3)
              : Number(orderedQuantity).toFixed(0)}
          </small>
        </Cell>
      )}
      {!loadedFromInvoice && status !== 'PENDING' && status !== 'CANCELLED' && (
        <Cell className="item-final text-right">
          <span className="mobile-view-header">
            {getMessage('order.details.itemsTable.header.picked')}
          </span>

          <small className="text-muted">
            {pickedQuantity
              ? isSoldByWeight &&
                item.metaData['Unit Of Measurement'] &&
                item.metaData['Unit Of Measurement'].trim() === 'KGM'
                ? (pickedQuantity / divider).toFixed(3) + smallUnit
                : pickedQuantity.toFixed(0)
              : 0}
          </small>
        </Cell>
      )}
      <Cell className="item-mrp text-right">
        <div className="mobile-view-header">
          {getMessage('order.details.itemsTable.header.mrp')}
        </div>
        <small className="text-muted">
          {currency.symbol}{' '}
          {isSoldByWeight ? (mrp * multiplier).toFixed(2) : mrp.toFixed(2)}
        </small>
      </Cell>
      <Cell className="item-discount text-right">
        <div className="mobile-view-header">
          {getMessage('order.details.itemsTable.header.discount')}
        </div>
        <small className="text-muted">
          {currency.symbol}{' '}
          {isSoldByWeight
            ? (discount * multiplier).toFixed(2)
            : discount.toFixed(2)}
        </small>
      </Cell>
      <Cell className="item-amount text-right">
        <div className="mobile-view-header">
          {getMessage('order.details.itemsTable.header.amount')}
        </div>
        <div className="remove-button-container">
          {currency.symbol} {((mrp - discount) * chargeableQuantity).toFixed(2)}
          {editing
            ? !loadedFromInvoice && (
                <div
                  className="remove-button"
                  onClick={() => {
                    this.setState({ itemToRemove: index })
                  }}
                >
                  &times;
                </div>
              )
            : null}
        </div>
      </Cell>
    </Row>
  )
}
class OrderItemsView extends Component {
  constructor(props) {
    super(props)
    this.state = this.generateStateFromProps(props, {
      items: [],
      editing: false,
      saving: false,
      error: false,
      productLookup: {},
      // The index of the item in the row that is to be removed
      // Setting this state will show confirmation popup
      itemToRemove: null,
    })
    this.getNestedState = getNestedState.bind(this)
    this.updateStateRecursively = updateStateRecursively.bind(this)
    this.startEditing = this.startEditing.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.submitChanges = this.submitChanges.bind(this)
    this.addProductToOrder = this.addProductToOrder.bind(this)
  }
  startEditing() {
    this.setState({ editing: true })
  }
  cancelEditing() {
    this.setState(() =>
      this.generateStateFromProps(this.props, {
        editing: false,
      })
    )
  }
  submitChanges() {
    if (this.props.onChange) {
      // onChange method should return a promise
      this.setState(
        {
          editing: false,
          saving: true,
        },
        () => {
          this.props
            .onChange(this.state.items)
            .then(
              () => {
                this.setState({ error: false })
              },
              () => {
                this.setState({ error: true })
              }
            )
            .then(() => {
              this.setState({ saving: false })
            })
        }
      )
    }
  }
  addProductToOrder(newProduct) {
    if (!newProduct) {
      return
    }
    // TODO: Find a better way to cause Searchable component to re-render
    this.setState({ productLookup: newProduct }, () => {
      this.setState(prevState => {
        const newState = Object.assign({}, prevState, {
          productLookup: {},
        })
        // Check if new product exists in the order already
        const index = prevState.items.findIndex(
          product => product.id === newProduct.id
        )
        if (index > -1) {
          // Product exists, just increase quantity
          const item = prevState.items[index]
          if (item.isSoldByWeight) {
            let unit = item.variant
              ? item.variant.fullName &&
                item.variant.fullName
                  .split(' ')
                  .slice(-1)
                  .pop()
              : item.product
              ? item.product.name.split(' ').slice(-1)[0]
              : ''
            unit = unit.toUpperCase()
            const multiplier = unit === 'KG' || unit === 'L' ? 1000 : 1
            let weight = item.variant
              ? item.variant.fullName &&
                item.variant.fullName.split(' ').slice(-2)[0]
              : item.product
              ? item.product.name.split(' ').slice(-2)[0]
              : 1
            weight = Number(weight)
            newState.items[index].orderDetails.orderedQuantity = (
              Number(item.orderDetails.orderedQuantity) +
              multiplier * weight
            ).toFixed(2)
          } else {
            newState.items[index].orderDetails.orderedQuantity = (
              Number(prevState.items[index].orderDetails.orderedQuantity) + 1
            ).toFixed(2)
          }
        } else {
          // Product doesn't exist, create a new entry
          let orderedQuantity = '1.00'
          let unit = null
          let multiplier = 1
          let weight = 1
          if (newProduct.soldByWeight) {
            unit = newProduct.name.split(' ').slice(-1)[0]
            unit = unit.toUpperCase()
            multiplier = unit === 'KG' || unit === 'L' ? 1000 : 1
            weight = newProduct.name.split(' ').slice(-2)[0]
            weight = Number(weight)
            orderedQuantity = (multiplier * weight).toFixed(2)
          } else {
            orderedQuantity = '1.00'
          }
          newState.items = [
            ...prevState.items,
            Object.assign(
              { id: newProduct.id, ...newProduct },
              {
                orderDetails: {
                  factorForPutRequest: newProduct.soldByWeight
                    ? multiplier * weight
                    : 1,
                  orderedQuantity,
                  discount: (
                    Number(newProduct.storeSpecificData.discount) /
                    Number(orderedQuantity)
                  ).toFixed(2),
                  mrp: (
                    Number(newProduct.storeSpecificData.mrp) /
                    Number(orderedQuantity)
                  ).toFixed(2),
                },
              }
            ),
          ]
        }
        return newState
      })
    })
  }
  removeProductFromOrder(index) {
    this.updateStateRecursively(
      ['items', index, 'orderDetails', 'orderedQuantity'],
      0
    )
    this.setState({ itemToRemove: null })
  }

  generateStateFromProps(props, state) {
    const newState = Object.assign({}, state, {
      items: [...props.data.items],
    })
    // TODO: Use immutable data structures instead
    return JSON.parse(JSON.stringify(newState))
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.data.items) {
      this.setState(prevState =>
        this.generateStateFromProps(newProps, prevState)
      )
    }
  }
  render() {
    const offers = this.props.offers || []
    const vouchers = this.props.vouchers || []
    const loadedFromInvoice = this.props.loadedFromInvoice
    const productsInOrder = this.state.items
    const isPendingOrCancelled =
      this.props.status === 'PENDING' || this.props.status === 'CANCELLED'
    const invoiceQuantity = isPendingOrCancelled
      ? 'orderedQuantity'
      : 'deliveredQuantity'
    const totalMrp = this.state.items.reduce(
      (result, item) =>
        result +
        Number(item.orderDetails.mrp) *
          Number(item.orderDetails[invoiceQuantity]),
      0
    )

    const totalDiscount = this.state.items.reduce(
      (result, item) =>
        result +
        Number(item.orderDetails.discount) *
          Number(item.orderDetails[invoiceQuantity]),
      0
    )

    const totalAmount = this.state.items.reduce(
      (result, item) =>
        result +
        Number(item.orderDetails.mrp) *
          Number(item.orderDetails[invoiceQuantity]),
      0
    )

    let totalAmountCalculated = 0
    this.state.items.map(item => {
      if (
        item.orderDetails.status === 'PENDING' ||
        (item.orderDetails['deliveredQuantity'] === undefined &&
          item.orderDetails.status === undefined)
      ) {
        totalAmountCalculated +=
          Number(item.orderDetails.mrp) *
            Number(item.orderDetails['orderedQuantity']) -
          Number(item.orderDetails.discount) *
            Number(item.orderDetails['orderedQuantity'])
      } else {
        let quantity = Number(item.orderDetails['deliveredQuantity'])
        if (quantity > Number(item.orderDetails['orderedQuantity'])) {
          quantity = Number(item.orderDetails['orderedQuantity'])
        }
        totalAmountCalculated +=
          Number(item.orderDetails.mrp) * quantity -
          Number(item.orderDetails.discount) * quantity
      }
      return null
    })

    const paidAmount = this.props.data.payment.reduce(
      (result, payment) =>
        result + (payment.status === 'COMPLETED' ? Number(payment.amount) : 0),
      0
    )

    let overlayClass = 'hidden'
    if (this.state.saving) {
      overlayClass = ''
    } else if (this.state.error) {
      overlayClass = 'error-message'
    }
    return (
      <div className="order-items-view">
        <Dialog
          show={Number.isFinite(this.state.itemToRemove)}
          title={getMessage('order.details.removeItem.title')}
          information={getMessage('order.details.removeItem.message')}
          onOk={() => {
            this.removeProductFromOrder(this.state.itemToRemove)
          }}
          close={() => {
            this.setState({ itemToRemove: null })
          }}
          closeText={getMessage('order.details.removeItem.cancelText')}
          okText={getMessage('order.details.removeItem.confirmText')}
        />
        <div className="flex-around section-title">
          <h3>
            {getMessage('order.details.itemsTable.title')}
            <span className="text-muted"> ({productsInOrder.length})</span>
          </h3>
          {this.state.editing ? (
            <div className="product-search">
              <ProductSearch
                label="Product"
                includeInventory
                name="product"
                onChange={this.addProductToOrder}
                storeId={this.props.storeId}
              />
            </div>
          ) : this.props.editable && !this.state.saving && !this.state.error ? (
            <img
              src={editIcon}
              alt="Edit"
              className="edit-icon"
              onClick={this.startEditing}
            />
          ) : null}
        </div>
        <Table>
          {this.state.error || this.state.saving ? (
            <div className={`message-overlay ${overlayClass}`}>
              {this.state.saving ? (
                <Loader />
              ) : this.state.error ? (
                <div className="retry-message" onClick={this.submitChanges}>
                  {getMessage('order.details.errors.onSave')}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : null}
          <Header>
            <Cell className="item-image">
              {getMessage('order.details.itemsTable.header.image')}
            </Cell>
            <Cell className="item-name">
              {getMessage('order.details.itemsTable.header.name')}
            </Cell>
            {!loadedFromInvoice && (
              <Cell>
                {getMessage('order.details.itemsTable.header.clientItemId')}
              </Cell>
            )}
            {!loadedFromInvoice && (
              <Cell
                className="item-original text-right"
                abbreviation={getMessage(
                  'order.details.itemsTable.header.quantity.abbreviated'
                )}
              >
                {getMessage('order.details.itemsTable.header.quantity')}
              </Cell>
            )}
            {loadedFromInvoice && (
              <Cell className="item-quantity text-right">Quantity</Cell>
            )}
            {!loadedFromInvoice &&
              this.props.status !== 'PENDING' &&
              this.props.status !== 'CANCELLED' && (
                <Cell className="item-final text-right">
                  {' '}
                  {getMessage('order.details.itemsTable.header.picked')}
                </Cell>
              )}
            <Cell className="item-mrp text-right">
              {getMessage('order.details.itemsTable.header.mrp')}
            </Cell>
            <Cell className="item-discount text-right">
              {getMessage('order.details.itemsTable.header.discount')}
            </Cell>
            <Cell className="item-amount text-right">
              {getMessage('order.details.itemsTable.header.amount')}
            </Cell>
          </Header>
          {this.state.items &&
            this.state.items.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ItemRow
                    key={item.id}
                    item={item}
                    index={index}
                    loadedFromInvoice={loadedFromInvoice}
                    editing={this.state.editing}
                    status={this.props.status}
                    currency={this.props.currency}
                  ></ItemRow>
                  {item.orderDetails.substitutionItem && (
                    <ItemRow
                      key={item.orderDetails.substitutionItem.id}
                      item={item.orderDetails.substitutionItem}
                      status={this.props.status}
                      currency={this.props.currency}
                    ></ItemRow>
                  )}
                </React.Fragment>
              )
            })}
          {productsInOrder.length > 0 && (
            <Row>
              <Cell />
              <Cell />
              {!loadedFromInvoice && <Cell />}
              {!loadedFromInvoice &&
                this.props.status !== 'PENDING' &&
                this.props.status !== 'CANCELLED' && <Cell />}
              <Cell className="total-amount text-right">Total</Cell>
              <Cell className="total-amount text-right">
                <small>
                  {this.props.currency.symbol} {totalMrp.toFixed(2)}
                </small>
              </Cell>
              <Cell className="total-amount text-right">
                <small>
                  {this.props.currency.symbol} {totalDiscount.toFixed(2)}
                </small>
              </Cell>
              <Cell className="total-amount text-right">
                {this.props.currency.symbol} {totalAmountCalculated.toFixed(2)}
              </Cell>
            </Row>
          )}
        </Table>
        {productsInOrder.length <= 0 ? (
          this.state.editing ? (
            <div className="empty-table-message">
              {getMessage('order.details.itemsTable.clearingMessage')}
            </div>
          ) : (
            <div className="empty-table-message">
              {getMessage('order.details.itemsTable.emptyMessage')}
            </div>
          )
        ) : null}
        <div className="flex-around table-footer">
          <div className="order-edit-actions">
            {this.state.editing ? (
              <button className="button primary" onClick={this.submitChanges}>
                {getMessage('order.details.itemsTable.actions.confirmText')}
              </button>
            ) : null}
            {this.state.editing ? (
              <button
                className="button button-white"
                onClick={this.cancelEditing}
              >
                {getMessage('order.details.itemsTable.actions.cancelText')}
              </button>
            ) : null}
          </div>
          {
            <InvoiceSummary
              data={{
                amount: totalAmountCalculated,
                shipping: Number(this.props.data.shipping),
                paid: paidAmount,
                couponDiscount: Number(this.props.data.couponDiscount),
                refundAmount: this.props.data.refundAmount,
                loadedFromInvoice: loadedFromInvoice,
                editing: this.state.editing,
                clickAndCollectCharges:
                  totalAmount > 0
                    ? Number(this.props.data.clickAndCollectCharges)
                    : 0,
                type: this.props.data.type,
                pendingAmount: this.props.data.pendingAmount,
                serviceFee: this.props.data.serviceFee,
              }}
              vouchers={vouchers}
              offers={offers}
              currency={this.props.currency}
            />
          }
        </div>
      </div>
    )
  }
}

OrderItemsView.defaultProps = {
  data: {
    items: [],
    payment: [],
  },
  includeInvoice: true,
}

export default OrderItemsView
