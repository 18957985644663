import React from 'react'
import { getMessage } from 'lib/translator'

const ActionButton = ({ isErrorField, type, previewButtonHandler }) => {
  return (
    <div className="actionButton-section">
      <button
        className={`button ${isErrorField ? 'errorButton' : ''} `}
        type="button"
        onClick={() => previewButtonHandler(type)}
      >
        {getMessage(`app.splash.${type}.button`)}
      </button>
      {isErrorField && (
        <div className="input-error-message">
          {getMessage('apphome.requiredField')}
        </div>
      )}
    </div>
  )
}

export default ActionButton
