import React from 'react'
import { Link } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import { Row, Cell } from '../../Table'
import ImagePlaceHolder from '../../Image/image-placeholder.svg'
import {
  ColumnDate,
  ColumnName,
  ColumnStatus,
  getStatus,
  TableAction,
} from '../utils'

const DATE_FORMAT = 'D MMM YYYY'

const REQUIRED_INPUTS = {
  DEEPLINK: ['deepLink', 'image', 'startDate', 'endDate', 'priority'],
  LOGIN: ['image', 'startDate', 'endDate', 'priority'],
}

const PRIORITY_OPTIONS = [
  {
    value: '1',
    text: 1,
  },
  {
    value: '2',
    text: 2,
  },
  {
    value: '3',
    text: 3,
  },
  {
    value: '4',
    text: 4,
  },
  {
    value: '5',
    text: 5,
  },
]

const BANNER_OPTIONS = [
  {
    value: 'DEEPLINK',
    text: getMessage(`appbanner.bannerType.DEEPLINK`),
  },
  {
    value: 'LOGIN',
    text: getMessage(`appbanner.bannerType.LOGIN`),
  },
]

const linkButtons = () => {
  return (
    <div className="header-wrap">
      <Link to="/app-home/banner-upload/add">
        <button className="primary button">
          <span className="text">{getMessage('appbanner.addBanner')}</span>
        </button>
      </Link>
    </div>
  )
}

const tableProperties = () => {
  return {
    headers: [
      'Banner Image',
      'Campaign Name',
      'Banner Type',
      'Valid From',
      'Valid To',
      'Status',
      'Priority',
      'Actions',
    ],
    row: ({
      name,
      bannerType,
      priority,
      startTime,
      endTime,
      image,
      onAction,
      isEnable,
      id,
    }) => {
      const status = isEnable
        ? getStatus(startTime, endTime)
        : getMessage('apphome.disabled')
      return (
        <Row>
          <Cell className="column-img-url">
            <img
              src={image ? image : ImagePlaceHolder}
              alt="Icon"
              height={50}
            />
          </Cell>
          <ColumnName type="banners" id={id} name={name} />
          <Cell className="column-bannerType">
            {getMessage(`appbanner.bannerType.${bannerType}`)}
          </Cell>
          <ColumnDate type="start" time={startTime} />
          <ColumnDate type="end" time={endTime} />
          <ColumnStatus status={status} />
          <Cell className="column-priority">{priority}</Cell>
          <Cell className="column-actions">
            <TableAction
              id={id}
              onAction={onAction}
              isEnable={isEnable}
              type="banners"
            />
          </Cell>
        </Row>
      )
    },
  }
}

export {
  PRIORITY_OPTIONS,
  BANNER_OPTIONS,
  DATE_FORMAT,
  REQUIRED_INPUTS,
  linkButtons,
  TableAction,
  tableProperties,
}
