import React from 'react'

import { BaseForm } from 'components/Form'
import Loader from 'components/Loader'
import {
  FormButtons,
  ErrorScreen,
  convertToISOString,
  getCategoryDetails,
  getPartnerDetails,
  getFilteredList,
  validatePartnerOnlineCodes,
  validateUseByDate,
  getFormattedPartnerOnlineCodes,
  getFormattedDate,
  getFormattedTime,
  getDateValues,
} from 'components/Rewards/utils'
import {
  formatPromotionalPrice,
  getFormattedPromotionalPrice,
  formatUsuageValidityData,
  formatMetaData,
} from 'components/Rewards/Catalogue/CatalogueUtils'
import { handleApis, handleError } from 'components/Rewards/services'
import API from 'lib/api'
import TextInfo from 'components/Rewards/Catalogue/TextInfo'
import CategoryInfo from 'components/Rewards/CategoryInfo'
import ImageUrl from 'components/Rewards/Catalogue/ImageUrl'
import Details from 'components/Rewards/Catalogue/Details'
import RedemptionValidity from 'components/Rewards/Catalogue/RedemptionValidity'
import UsageValidity from 'components/Rewards/Catalogue/UsageValidity'
import LinkpointPrice from 'components/Rewards/Catalogue/LinkpointPrice'
import RedemptionLimit from 'components/Rewards/Catalogue/RedemptionLimit'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'

class CatalogueForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: {
        linkpointPrice: {
          regular: '',
          priceChanges: [],
        },
        status: 1,
        usageType: 'Fixed',
        redemptionCap: {
          isCapTotal: false,
          isCapPerUser: false,
        },
        memberships: [],
      },
    }
  }

  async componentDidMount() {
    const values = Object.assign({}, this.state.values)
    values.loading = true
    this.setState({
      values,
    })
    let getRewardsDetails = ''
    const { id } = { ...this.props.value }
    if (id) {
      const api = new API({ url: `/rms/v2/rewards/${id}` })
      getRewardsDetails = api.get()
    }

    try {
      const catDetailsApi = new API({
        url: `/rms/categories?status=active&offset=0&limit=1000`,
      })
      const partnerDetailsApi = new API({
        url: `/rms/partners?offset=0&limit=1000`,
      })
      const [catDetailsRes, partnerDetailsRes, rewardsDetailsRes] =
        await Promise.allSettled([
          catDetailsApi.get(),
          partnerDetailsApi.get(),
          getRewardsDetails,
        ])
      getCategoryDetails(catDetailsRes, values)
      getPartnerDetails(partnerDetailsRes, values)

      let formattedLinkPointPrice = ''
      if (rewardsDetailsRes.status === 'fulfilled' && rewardsDetailsRes.value) {
        const rewardsResValue = rewardsDetailsRes.value
        const startDateTime = rewardsResValue.startAt
        const endDateTime = rewardsResValue.endAt
        const usedByDateTime = rewardsResValue.useByDate
        const dateValues = getDateValues(startDateTime, endDateTime)
        values.date = { ...dateValues }
        values.usedByDate = getFormattedDate(usedByDateTime)
        values.usedByTime = getFormattedTime(usedByDateTime)
        values.usageType = rewardsResValue.useWithinDays ? 'Relative' : 'Fixed'
        const {
          linkpointPrice: { regular = '', priceChanges = [] },
        } = rewardsResValue
        formattedLinkPointPrice = {
          linkpointPrice: {
            regular,
            priceChanges: getFormattedPromotionalPrice(priceChanges),
          },
        }
      }

      if (rewardsDetailsRes.status === 'rejected') {
        throw new Error(rewardsDetailsRes.reason.message)
      }

      values.loading = false
      this.setState({
        values: {
          ...values,
          ...rewardsDetailsRes.value,
          ...formattedLinkPointPrice,
          ...rewardsDetailsRes.value.redemptionCap,
          ...rewardsDetailsRes.value.imgUrls,
        },
      })
    } catch (err) {
      handleError(err, this)
    }
  }

  async _submitHandler(e) {
    e.preventDefault()
    const { values } = this.state
    this.beforeSubmit(values)

    this.setState({ pressedSubmitWithCurrentData: true })

    const {
      name,
      slug,
      type,
      status,
      banner,
      date,
      usageType,
      tnc,
      description,
      categoryIds,
      partnerId,
      storeIds,
      linkpointPrice,
      howToRedeem,
      isCapTotal,
      isCapPerUser,
      capTotal,
      capPerUser,
      title,
      subtitle,
      metadata,
      partnerOnlineCodes,
      memberships,
    } = values

    const formattedPromotionalPrice = formatPromotionalPrice(
      linkpointPrice?.priceChanges
    )
    const startAt = convertToISOString(date?.startingDate, date?.startingTime)
    const endAt = convertToISOString(date?.endingDate, date?.endingTime)

    let isValid = true
    let isValidTypePartnerOnline = true
    let isValidUseByDate = true

    const isTypePartnerOnline =
      this.props.method === 'add' &&
      type === 'PARTNER_ONLINE' &&
      partnerOnlineCodes
    let formattedPartnerOnlineCodes = ''

    if (isTypePartnerOnline) {
      const filteredList = getFilteredList({ partnerOnlineCodes })
      isValidTypePartnerOnline = validatePartnerOnlineCodes(
        filteredList,
        values
      )
      formattedPartnerOnlineCodes = getFormattedPartnerOnlineCodes(filteredList)
    }

    if (usageType === 'Fixed') {
      isValidUseByDate = validateUseByDate(values)
    }

    this.setState({ values })

    isValid = isValidTypePartnerOnline && isValidUseByDate && this.isFormValid()

    if (isValid) {
      const mapData = {
        name,
        slug,
        type,
        tnc,
        description,
        title,
        subtitle,
        categoryIds,
        partnerId: Number(partnerId),
        storeIds,
        startAt,
        endAt,
        status: Number(status),
        imgUrls: { banner },
        redemptionCap: {
          isCapTotal,
          capTotal: isCapTotal ? capTotal : 0,
          isCapPerUser,
          capPerUser: isCapPerUser ? capPerUser : 0,
        },
        howToRedeem,
        linkpointPrice: {
          regular: linkpointPrice.regular,
          priceChanges: formattedPromotionalPrice,
        },
        memberships,
        ...(isTypePartnerOnline && {
          partnerOnlineCodes: formattedPartnerOnlineCodes,
        }),
      }

      formatUsuageValidityData(mapData, values)
      formatMetaData(mapData, metadata, type)
      const { id } = { ...this.props.value }
      handleApis(mapData, `/rms/v2/rewards`, this, id)
    }
  }

  render() {
    const {
      categoryList,
      fetchCategoryError,
      partnerList,
      fetchPartnerError,
      error,
      loading,
      isCapTotal,
      isCapPerUser,
      usageType,
      isUseByDateNull,
      isInvalidPartnerOnlineReward,
    } = {
      ...this.state.values,
    }
    const { Form } = this.components

    return (
      <div className="rewards container catelogue-form">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <Details
              _this={this}
              partnerList={partnerList}
              fetchPartnerError={fetchPartnerError}
              isInvalidPartnerOnlineReward={isInvalidPartnerOnlineReward}
            />
            {(categoryList || fetchCategoryError) && (
              <CategoryInfo
                _this={this}
                categoryList={categoryList}
                fetchCategoryError={fetchCategoryError}
                type="catalogue"
                isRewardsCatalogue
              />
            )}
            <br />
            <TextInfo _this={this} />
            <ImageUrl _this={this} />
            <RedemptionValidity _this={this} />
            <UsageValidity
              _this={this}
              usageType={usageType}
              isUseByDateNull={isUseByDateNull}
            />
            <LinkpointPrice _this={this} />
            <RedemptionLimit
              _this={this}
              isCapTotal={isCapTotal}
              isCapPerUser={isCapPerUser}
            />
            {this.props.method === 'edit' && (
              <DisplayCreatorEditorInfo {...this.state.values} />
            )}
            <FormButtons buttons={this.buttons} type="catalogue" />
            {error && <ErrorScreen error={error} />}
          </Form>
        )}
      </div>
    )
  }
}
export default CatalogueForm
