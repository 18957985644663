import React from 'react'
import { Popup } from '../../../../components/Popup'
import { getMessage } from '../../../../lib/translator'
import Upload from '../../../../components/FileUpload'
import uploadIcon from '../images/upload-products.svg'
import csvIcon from '../images/csv-icon.svg'
import './SKUpopup.css'

const sampleCSV = {
  'sample-sku.csv':
    'data:text/csv;charset=utf-8,Participating_skus\n' +
    '13172090',
}

const UploadFile = (props) => {

  const getSampleCsv = (e) => {
    e.preventDefault()
    var hiddenElement = document.createElement('a')
    hiddenElement.href = sampleCSV["sample-sku.csv"]
    hiddenElement.target = '_blank'
    hiddenElement.download = 'sample-sku.csv'
    hiddenElement.click()
  }

  return (
    <div className="Media-SKU-upload">
      <div className="bordered-box">
        <div className="text-center">
          <img src={uploadIcon} width="140" alt="" />
        </div>
        <Upload
          name="MediaSKUUpload"
          accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
          placeholder={getMessage('media.adset.upload.fileinput')}
          uploadButtonText="Upload"
          cancelText="Cancel"
          uploadUrl={"/martech-ad-service/data/reservation/upload_sku/"+ props.supplierReservationId}
          uploadKey="file"
          validationStrings={{
            invalidFileType: getMessage('input.invalidFileType'),
          }}
          strings={{
            defaultMessage: getMessage('fileUpload.importCsv.heading'),
            progressMessage: getMessage('fileUpload.importedCsv.heading'),
            completionMessage: getMessage('fileUpload.uploadedCsv.success'),
          }}
          icon={csvIcon}
          successCallback={props.refreshCampaignDetail}
        />
      </div>
      <div className="text-center download-sample-text">
        <a className="download-link" onClick={getSampleCsv}>
          {getMessage('productUpload.csvDownloadText')}
        </a>
      </div>
    </div>
  )
}

function UploadPopup (props) {
  return (
    <Popup
      show={props.isOpenUploadBox}
      className="fileUpload-box"
      heading={getMessage('media.adset.upload.title')}
      close={() => { props.toggleUploadBox(2) }}
    >
      <div className='fileUpload-wrapper'>
        <div className='tip'>* {getMessage('media.adset.upload.tip')}</div>
        <UploadFile
          supplierReservationId={props.supplierReservationId}
          refreshCampaignDetail={props.refreshCampaignDetail}
        />
      </div>
    </Popup>
  )
}
export default UploadPopup
