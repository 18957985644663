import React, { Component } from 'react'
import Table, { Header, Cell, Row } from '../../../../../components/Table'
import Image from '../../../../../../src/components/Image/index'
import { getPaymentLogo, formatCardNumber } from '../../../../../lib/payment'
import { getMessage } from 'lib/translator'

class PaymentDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      items: props.data,
    }
  }

  render() {
    const isBankTransfer = this.state.items?.paymentType === 'DEPOSIT'
    const amount = this.state.items.amount
    const cardScheme = this.state.items.methods.card?.scheme
    const status =
      this.state.items.methods.card?.status || this.state.items.status
    const refund = this.state.items.refund
    const details = this.state.items.methods.card?.transactionId
    const cardNumber = this.state.items.methods.card?.number
    const cardType = isBankTransfer
      ? getMessage('customerSupport.egifting.bank.transfer')
      : this.state.items.methods.card?.type

    return (
      <div className="order-items-view" data-testid="PaymentDetails">
        <div className="flex-around section-title">
          <h3>{'Payment Details'}</h3>
        </div>

        <Table>
          <Header>
            <Cell>{'Amount'}</Cell>
            <Cell>{'Mode'}</Cell>
            <Cell>
              {isBankTransfer
                ? getMessage('customerSupport.egifting.approved.by')
                : getMessage('qc.paymentDetails.Card')}
            </Cell>
            <Cell>{'Status'}</Cell>
            <Cell>
              {isBankTransfer
                ? getMessage('customerSupport.egifting.header.assignment')
                : 'Details'}
            </Cell>
          </Header>

          <Row>
            <Cell>${Number(amount).toFixed(2)}</Cell>
            <Cell>{cardType}</Cell>
            <Cell>
              <React.Fragment>
                {isBankTransfer ? (
                  <>
                    {this.state.items?.methods?.deposit?.metadata['user.email']}
                  </>
                ) : (
                  <>
                    {' '}
                    <Image
                      className="payment-card"
                      size="sm"
                      src={getPaymentLogo(cardScheme)}
                    />
                    <span>{formatCardNumber(cardNumber, cardType)}</span>
                  </>
                )}
              </React.Fragment>
            </Cell>
            <Cell>
              {status} {status === 'REFUNDED' && `- ${refund}`}
            </Cell>
            <Cell>
              {isBankTransfer ? (
                <>{this.state.items?.methods?.deposit?.transactionId}</>
              ) : (
                <>Transaction Id - {details}</>
              )}
            </Cell>
          </Row>
        </Table>
      </div>
    )
  }
}

PaymentDetails.defaultProps = {
  data: {
    items: [],
  },
}

export default PaymentDetails
