import React, { Component } from 'react'
import Table from '../Table'

export default class PacklistLog extends Component {

    render() {
        const {
            item,
            index
        } = this.props

        return (
            <React.Fragment>
                {item.packlistTag && <div className="tag-label"><span className="curve-box">{item.packlistTag}</span></div>}
                {item.packlistId && item.itemChanges && (
                    <Table
                        data={{
                            header: 'Item Changes',
                            tableData: item.itemChanges,
                            properties: [
                                'itemName',
                                'oldQuantity',
                                'newQuantity',
                                'itemId',
                                'reason',
                            ],
                            index: index,
                            itemChanges: true,
                        }}
                    />
                )}
            </React.Fragment>
        )
    }
}
