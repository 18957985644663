import React from 'react'
import deleteIcon from '../delete.svg'

const HeaderWithDeleteButton = ({
  type,
  index,
  handleRemoveClick,
  campaignStarted,
  getMessage,
}) => (
  <div className={`${type}-header`}>
    <div className="header-1" data-testid={`${type} ${index + 1}`}>
      {getMessage(`luckydraw.${type}`)} {index + 1}
    </div>
    <div>
      <button
        className="mr10-luckydraw"
        type="button"
        data-testid={`delete-${type}-btn-${index + 1}`}
        onClick={(e) => handleRemoveClick(e, index)}
        disabled={campaignStarted}
      >
        <img src={deleteIcon} alt="delete" />
      </button>
    </div>
  </div>
)

export default HeaderWithDeleteButton
