import React, { useEffect, useState, useMemo, useContext } from 'react';
import { DropDown, DropDownItem } from 'components/DropDown';
import AuthenticatedPage from 'containers/AuthenticatedPage';
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import Loader from 'components/Loader'
import Toast from 'components/Toast';
import { getMessage } from 'lib/translator';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import API from 'lib/api';
import { uniq } from 'lodash';
import AddRulePopup from './AddRulePopup';
import {
  AddRuleOptionsEnum,
  AddRuleOptionsName,
  distinctColors,
  slotRuleTypes
} from './constants';
import { getMapDataFromClusterRules, fetchCoordinatesFromAddressService } from './helpers';
import MapAndRulesList from './MapAndRulesList';
import useRules from './useRules';
import { hasPermissions, getSession } from 'lib/auth';
import './style.scss';

function TimeSlotBlocking({ menu }) {
  const splitConfig = useContext(SplitContext);
  const { splits } = splitConfig;

  const [showAddRuleFormPopup, setShowAddRuleFormPopup] = useState(false);
  const [selectedRuleType, setSelectedRuleType] = useState(null);
  const [showToast, setShowToast] = useState(null);
  const [deliveryAreaData, setDeliveryAreaData] = useState(null);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [disableEditStoreRule, setDisableEditStoreRule ] = useState(false);

  const [apiError, setApiError] = useState(false);
  const [postalCodeCoordinates, setPostalCodeCoordinates] = useState(null);

  // Editing states
  const [selectedRuleForEdit, setSelectedRuleForEdit] = useState(null);

  const [pageNum, setPageNum] = useState(1);
  const {
    isLoading,
    isError,
    rules,
    hasNextPage,
    totalRuleCount,
    setRules
  } = useRules(pageNum);

  useEffect(() => {
    if (splits?.[SPLIT_FEATURES.BLOCK_STORE_RULE_CUD]?.treatment === 'on') {
      const { user } = getSession();
      const flagConfig = JSON.parse(splits[SPLIT_FEATURES.BLOCK_STORE_RULE_CUD].config);
      const userDesignationsFromFlag = 'userDesignations' in flagConfig ? JSON.parse(flagConfig?.userDesignations) : [];
      setDisableEditStoreRule(userDesignationsFromFlag.includes(user?.designation.name));
    }
  }, [])

  useEffect(() => {
    async function fetchRulesWithMapData() {
      const clusterRulesDataForMap = getMapDataFromClusterRules(rules);
      setDeliveryAreaData(clusterRulesDataForMap);

      const currentPostalCodes = postalCodeCoordinates ? Object.keys(postalCodeCoordinates) : [];
      const postalCodes = uniq(rules
        .filter(x => x.slotRuleType === slotRuleTypes.BY_POSTAL_CODE)
        .flatMap(rule => rule.metadata.postalCode))
        .filter(postalCode => !currentPostalCodes.includes(postalCode)); // to remove postals that were fetched already

      if (postalCodes.length > 0 && postalCodes[0]) {
        try {
          const coordinates = await fetchCoordinatesFromAddressService(postalCodes);
          setPostalCodeCoordinates(prev => ({...prev, ...coordinates}));
        } catch(e) {
          console.log(e);
        } finally {
          setAllDataLoaded(true);
        }
      } else {
        setAllDataLoaded(true);
      }
    }

    if (rules.length > 0) {
      fetchRulesWithMapData();
    } else if(!isLoading) {
      // if isLoading is false and rules is empty, there is confirmed no rules
      setPostalCodeCoordinates(null);
      setDeliveryAreaData([]);
      setAllDataLoaded(true);
    }
  }, [rules])

  const refetchRules = () => {
    // ensure selectedPin code in DeliveryAreaData is cleared before fetching rules
    // because rule id to color mapping will give error if there is new rule id after rule is updated
    const mapIndexWithPinCode = deliveryAreaData.findIndex(x => x.configType === 'PINCODE')
    if (mapIndexWithPinCode > -1) {
      setDeliveryAreaData(prev => prev.filter((_, index) => index !== mapIndexWithPinCode));
    }

    setPageNum(prev => Math.abs(prev) === 1 ? -prev : 1);
  }

  const colorsMappedToRules = useMemo(() => {
    if (rules) {
      const mapping = {}
      rules.forEach((rule, index) => {
        mapping[rule.id] = distinctColors[index % distinctColors.length]
      })
      return mapping;
    }
    return null;
  }, [rules])

  /* HANDLE POPUPS */
  const openAddRulePopupForm = (ruleType) => {
    setSelectedRuleType(ruleType);
    setShowAddRuleFormPopup(true);
  }

  const deleteRule = async(ruleId, name) => {
    try {
      const deleteRuleApi = new API({ url: `/logistics-management-service/v1/rules/${ruleId}` });
      await deleteRuleApi.delete();
      setShowToast({ text: `The rule "${name}" has been deleted.` });
      setApiError(false);
      setDeliveryAreaData([]);
      refetchRules();
    } catch(e) {
      setShowToast({ text: getMessage('error.server') });
    }
  }

  const editRule = (selectedRule, editRuleOption) => {
    setSelectedRuleType(editRuleOption);
    setSelectedRuleForEdit(selectedRule);
    setShowAddRuleFormPopup(true);
  }

  // !! is used to cast the value to boolean as the method will return undefined if permission not found.
  const hasEditPermission = !!hasPermissions('logistics-management', 'v1/rules', 'patch');
  const hasStoreRule = rules && rules.find(x => x.slotRuleType === slotRuleTypes.BY_STORE);

  return (
    <AuthenticatedPage
      className="timeslot-blocking"
      menu={menu}
      storeDependent
      onChange={refetchRules}>
      {
        showToast && <Toast
          content={showToast.text}
          timer={3000}
          toastColor="#333333"
          style={{
            position: 'absolute',
            fontSize: '14px',
            top: '1.5rem',
            width: '30rem',
            left: '45%'
          }}
          onClose={() => setShowToast(null)}
        />
      }
      <div className="header-container-time-slot-blocking">
        <h2>{getMessage('operations.timeSlotBlocking.title')}</h2>
        {
          hasEditPermission &&
          <div className="add-rule-button-wrapper">
            <DropDown
              className='add-rule-dropdown'
              icon={
                <button className="primary button">
                  <PlusIcon />
                  {getMessage('operations.timeSlotBlocking.addRule')}
                </button>
              }>
              <DropDownItem onClick={() => openAddRulePopupForm(AddRuleOptionsEnum.BY_POSTAL_CODE)}>
                {AddRuleOptionsName.BY_POSTAL_CODE}
              </DropDownItem>
              <DropDownItem onClick={() => openAddRulePopupForm(AddRuleOptionsEnum.BY_MAP)}>
                {AddRuleOptionsName.BY_MAP}
              </DropDownItem>
              <DropDownItem onClick={() => openAddRulePopupForm(AddRuleOptionsEnum.BY_KML_FILE)}>
                {AddRuleOptionsName.BY_KML_FILE}
              </DropDownItem>
              <DropDownItem className={(hasStoreRule || disableEditStoreRule) ? 'disabled' : ''} onClick={() => {
                openAddRulePopupForm(AddRuleOptionsEnum.BY_STORE)
              }}>
                {AddRuleOptionsName.BY_STORE}
              </DropDownItem>
            </DropDown>
          </div>
        }
      </div>
      { (apiError || isError) && <div className='server-error-message'>{getMessage('error.server')}</div> }
      { allDataLoaded ?
        <MapAndRulesList
          deliveryAreaData={deliveryAreaData}
          rulesData={rules}
          editRule={editRule}
          isLoading={isLoading}
          refetchRules={refetchRules}
          setPageNum={setPageNum}
          hasNextPage={hasNextPage}
          setRules={setRules}
          deleteRule={deleteRule}
          colorsMappedToRules={colorsMappedToRules}
          postalCodeCoordinates={postalCodeCoordinates}
          hasEditPermission={hasEditPermission}
          totalRuleCount={totalRuleCount}
          disableEditStoreRule={disableEditStoreRule}
          setDeliveryAreaData={setDeliveryAreaData} />
        : <Loader />
      }

      {
        showAddRuleFormPopup &&
        <AddRulePopup
          setShowAddRuleFormPopup={setShowAddRuleFormPopup}
          showAddRuleFormPopup={showAddRuleFormPopup}
          refetchRules={refetchRules}
          selectedRuleType={selectedRuleType}
          setShowToast={setShowToast}
          selectedRuleForEdit={selectedRuleForEdit}
          setSelectedRuleForEdit={setSelectedRuleForEdit}
          setSelectedRuleType={setSelectedRuleType}
        />
      }
    </AuthenticatedPage>
  )
}

export default TimeSlotBlocking;
