import React, { Component } from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../../containers/ListingPage'
import StoreSelector from '../../../../containers/StoreSelector'
import { getMessage } from '../../../../lib/translator'
import OrderFilters from './Filters'
import { tabularView } from './Views'
import { Link } from 'react-router-dom'
import API from '../../../../lib/api'
import { isExtensionEnabled } from '../../../../lib/auth'
import emptyIcon from './no-orders.svg'
import rcmIcon from './rcm.svg'
import seoIcon from './seo.svg'
import newCustomerIcon from './new-customer.svg'
import DeprecatedMessage from '../DeprecatedMessage';
import './style.css'

function CreateNewOrder() {
  return (
    <div className="text-center">
      <Link to="/operations/orders/add" className="primary button">
        + <span className="text">{getMessage('order.add.text')}</span>
      </Link>
    </div>
  )
}

const emptyState = props => {
  return {
    icon: emptyIcon,
    message: getMessage('order.emptyList.message'),
    actions: ({ onAction, apiParams }) => (
      <React.Fragment>
        <CreateNewOrder />
        {isExtensionEnabled('MultiStoreSupport') && (
          <StoreSelector
            value={apiParams.storeId}
            stores={props.stores}
            onChange={storeId => {
              onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId })
            }}
          />
        )}
      </React.Fragment>
    ),
  }
}

const helpItems = {
  title: getMessage('order.helpItems.title'),
  instructions: [
    {
      icon: seoIcon,
      title: getMessage('order.helpItems.seo.title'),
      description: getMessage('order.helpItems.seo.description'),
    },
    {
      icon: rcmIcon,
      title: getMessage('order.helpItems.rcm.title'),
      description: getMessage('order.helpItems.rcm.description'),
    },
    {
      icon: newCustomerIcon,
      title: getMessage('order.helpItems.nca.title'),
      description: getMessage('order.helpItems.nca.description'),
    },
  ],
}

const { Provider, Consumer } = React.createContext('commConfig')

const OrdersContainer = class Orders extends Component {
  componentDidMount() {
    this.communicationApi = new API({
      url: `/account-service/config/communication`,
    })
    this.communicationApi
      .get()
      .then(response => {
        this.setState({ communicationConfig: response.data.config })
      })
      .catch(error => {
        throw error
      })

    if (isExtensionEnabled('InStoreProcessing')) {
      this.api = new API({
        url: `/config-service/config/inStoreProcessing.packedOrderEditAllowed`,
      })
      this.api
        .get()
        .then(res => {
          if (res.code === 200 && res.status === 'SUCCESS') {
            this.setState({
              packedOrderEdit:
                res &&
                res.data &&
                res.data.inStoreProcessing &&
                res.data.inStoreProcessing.packedOrderEditAllowed,
            })
          }
        })
        .catch(err => {
          if (err.code === 401 || err.code === 403) {
            throw err
          }
        })
    }
  }
  componentWillUnmount() {
    this.communicationApi && this.communicationApi.cancel()
  }
  render() {
    const { props } = this
    // If url is present in the props, then we need to show customer support related interfaces
    return (
      <Provider value={this.state && this.state.communicationConfig}>
        <ListingPage
          menu={props.menu}
          className={`orders-page ${this.props.className || 'ops'}`}
          title={getMessage('operations.dashboard.orders')}
          notificationMessage={<DeprecatedMessage url={props.url}/>}
          api={{
            url: '/order-service/order',
            params: props.params,
            updateApiParams: props.updateApiParams
              ? props.updateApiParams
              : null,
            transform: response => response.data.order,
          }}
          primaryKey="referenceNumber"
          emptyState={props.emptyState ? props.emptyState() : emptyState(props)}
          helpItems={helpItems}
          filters={{
            component: OrderFilters,
            transformFilter: formData => {
              const result = Object.assign({}, formData)
              if (result.customer) {
                result.customerId = result.customer.id
                delete result.customer
              }
              if (result.slot) {
                const slotDetails = JSON.parse(result.slot)
                if (slotDetails.startTime) {
                  result.slotStartTime = slotDetails.startTime
                }
                result.slotEndTime = slotDetails.endTime
                result.type = slotDetails.type
                delete result.slot
              }
              if (result.productType === 'all') {
                delete result.productType
              }
              return result
            },
            transformSubmit: formData => {
              let searchParams = ''
              Object.keys(formData)
                .filter(
                  key =>
                    key !== 'filters' && (formData[key] === 0 || formData[key])
                )
                .forEach((key, index) => {
                  if (index === 0) {
                    searchParams += `?${key}=${formData[key]}`
                  } else {
                    searchParams += `&${key}=${formData[key]}`
                  }
                })
              if (searchParams !== '') {
                this.props.history.push({ search: searchParams })
              }
              const result = Object.assign({}, formData)
              if (result.customer) {
                result.customerId = result.customer.id
                delete result.customer
              }
              if (result.slot) {
                const slotDetails = JSON.parse(result.slot)
                if (slotDetails.startTime) {
                  result.slotStartTime = slotDetails.startTime
                }
                result.slotEndTime = slotDetails.endTime
                result.type = slotDetails.type
                delete result.slot
              }
              return result
            },
          }}
          onClear={() => this.props.history.push({ search: 'filters=all' })}
          headerActions={() =>
            this.props.url.includes('operations') ? (
              <React.Fragment>
                <CreateNewOrder />
              </React.Fragment>
            ) : null
          }
          tableProperties={tabularView(
            this.props.url,
            this.state && this.state.packedOrderEdit
          )}
          tableDynamic
          storeDependent={this.props.url === '/operations'}
        />
      </Provider>
    )
  }
}

export default OrdersContainer

export { Provider, Consumer }
