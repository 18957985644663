import React from 'react';
import {View, StyleSheet, Text } from '@react-pdf/renderer';
import OrderItemsTableHeader from './OrderItemsTableHeader'
import OrderItemsTableRow from './OrderItemsTableRow'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
        borderWidth: 1,
        borderColor: '#2b3238',
        // basic formats
        fontFamily: 'Helvetica',
        lineHeight: 1.5,
    },
});

const OrderItemsTable = ({ order }) => {
  const fulfilledItems = order.items.filter(item => item.deliveredQuantity > 0)
  const dropoffItems = order.items.filter(item => item.orderedQuantity !== item.deliveredQuantity)

  return (
    <View>
      {
        fulfilledItems.length > 0 &&
        <View style={styles.tableContainer}>
          <OrderItemsTableHeader />
          <OrderItemsTableRow
            items={fulfilledItems}
            isDropoffTable={false} />
        </View>
      }
      {
        dropoffItems.length > 0 &&
        <View style={{marginTop: 8}}>
          <Text>Items Not Fulfilled and Not Billed</Text>
          <View style={{...styles.tableContainer, marginTop: 4}}>
            <OrderItemsTableHeader />
            <OrderItemsTableRow
              items={dropoffItems}
              isDropoffTable={true} />
          </View>
        </View>
      }
    </View>
  )
};

export default OrderItemsTable
