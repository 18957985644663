import React from 'react'
import { useIsBulkSngActionEnabled } from './utils'
import { ManageStore } from './ManageStore'
import './style.css'

export const ManageStoreCore = () => {
  const _isFeatureEnabled = useIsBulkSngActionEnabled()

  if (_isFeatureEnabled) {
    return <ManageStore />
  }
  return <div data-testid="s&g-bulk-action-disabled" hidden />
}
