import React, { Component } from 'react'
import ScriptTag from 'react-script-tag'

export class BPrint extends Component {
  print(orderID, startTimeSlot, custName, deliveryDate, packageDetails) {
    const timeSlot = this.getTimeSlot(startTimeSlot)
    const printName = this.getPrintName(custName)
    const date = this.formatDate(deliveryDate)

    window.BrowserPrint?.getDefaultDevice(
      'printer',
      function (device) {
        if (device != null) {
          if (packageDetails && packageDetails.length > 0) {
            const toteCount = packageDetails.length;
            packageDetails.map((pack, index) => {
              const { zoneLabel, packageCode } = pack;
              const dataToWrite = `^XA
              ^FO36,60^A0N,100,0^FD${orderID} ^FS
              ^FO650,40^A0N,100,0^FD${zoneLabel}^FS
              ^FO40,170^A0N,160,0^FD${timeSlot}^FS
              ^FO40,335^A0N,60,0^FD${printName}^FS
              ^FO40,430^A0N,60,0^FD${date}^FS
              ^FO40,530^A0N,50,0^FD(${index + 1}/${toteCount})^FS
              ^FO175,530^A0N,50,0^FD ${packageCode.toUpperCase()}^FS
              ^FO450,170^ADN,50,50^BQ,2,10^FDHA,${orderID},${packageCode.toUpperCase()}^FS
              ^XZ`

              device.send(dataToWrite, undefined, function (errorMessage) {
                alert('print error: ' + errorMessage)
              })
            })
          }
        } else {
          alert('Printer device return null')
        }
      },
      function (error) {
        alert('BrowserPrint getDefaultDevice : ' + error)
      }
    )
  }

  getPrintName(custName) {
    const index = custName.indexOf('@')
    if (index !== -1) {
      custName = custName.substring(0, index)
    }

    if (custName.length > 12) {
      custName = custName.split('_').join(' ')

      const names = custName.split(' ')
      custName = ''
      for (let i = 0; i < names.length; i++) {
        if (i === names.length - 1) {
          custName += ' ' + names[i]
          custName = custName.substring(0, 12) + '.'
        } else {
          custName += names[i].charAt(0).toUpperCase()
        }
      }
    }

    return custName
  }

  getTimeSlot(start) {
    return 'T' + start.substring(0, 2)
  }

  formatDate(inputDate) {
    const date = new Date(inputDate)
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    })
    const [{ value: month }, , { value: day }, , { value: year }] =
      dateTimeFormat.formatToParts(date)

    return `${day} ${month} ${year}`
  }

  render() {
    return (
      <ScriptTag
        type="text/javascript"
        src="/BrowserPrint-3.0.216.min.js"
      ></ScriptTag>
    )
  }
}

export default BPrint
