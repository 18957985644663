import React from 'react'
import PublicPage from '../../../containers/PublicPage/index'
import { withRouter } from 'react-router-dom'
import { getMessage as t } from '../../../lib/translator'

import './style.css'

const ErrorPage = function(props) {
  return (
    <PublicPage className="error-page" showHeader={props.showHeader}>
      {props.title && <h1>{props.title}</h1>}
      <div className="box">
        <h2 className="heading">{t('unexpected.error.heading')}</h2>
        <p className="subheading" style={{ lineHeight: '1.5' }}>
          {t('unexpected.error.description')}
        </p>
        <p>
          <button
            className="primary button"
            onClick={() => {
              props.history.goBack()
            }}
          >
            {t('unexpected.error.back')}
          </button>
        </p>
      </div>
    </PublicPage>
  )
}

export default withRouter(ErrorPage)
