import React, { Component }  from 'react';
import Select from '../../../components/Form/Inputs/Select';

class ZonesDropdown extends Component {
  render() {
    const { onChange, storeZoneConfig, value, isZoneError, disabled } = this.props;

    const storeZones = Object.keys(storeZoneConfig).map(key => ({
      text: storeZoneConfig[key].displayName,
      value: storeZoneConfig[key].tag,
    }))

    return (
      <div className="dropdown-wrapper">
        <label htmlFor="ZonesDropdown">Zones</label>
        <Select
        id="zones-dropdown"
        name="ZonesDropdown"
        placeholder="Select a zone"
        value={value}
        options={storeZones}
        onChange={onChange}
        disabled={disabled}
        />
        {isZoneError && <span className="error">Please select a zone</span>}
      </div>
    );
  }
}

export default ZonesDropdown;