import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import Tabs from 'components/Tabs'
import LayoutForm from 'components/AppHome/AppLayout/LayoutForm'
import { getMessage } from 'lib/translator'

const ManageLayout = (props) => {
  const [activeTab, setActiveTab] = useState(0)

  const changeTab = (tab) => {
    setActiveTab(tab)
  }

  const tabsList = [
    {
      text: getMessage('app.layout.tab.profile'),
    },
    {
      text: getMessage('app.layout.tab.preview'),
    },
  ]

  return (
    <AuthenticatedPage menu={props.menu}>
      <div className="applayout-container">
        <h1>{getMessage('app.layout.header')}</h1>
        <div className="tab-list">
          <Tabs
            items={tabsList.map((tab) => `${tab.text}`)}
            default={0}
            onClick={changeTab}
            active={activeTab}
          />
        </div>
        <div className="applayout-form">
          {activeTab === 0 && <LayoutForm />}
        </div>
      </div>
    </AuthenticatedPage>
  )
}

export default withRouter(({ location, history, match, ...props }) => (
  <ManageLayout router={{ location, history, match }} {...props} />
))
