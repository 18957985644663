import { getMessage } from '../../../../../lib/translator'

export const cancelReasons = [
  {
    value: 'ITEM_UNAVAILABLE',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.ItemUnavailable'),
  },
  {
    value: 'MERCHANT_BUSY',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.TooBusy'),
  },
  {
    value: 'CUSTOMER_REQUEST',
    text: getMessage(
      'qc.orderSummary.reasonpopup.reasons.ExceptionalCustomerRequest'
    ),
  },
  {
    value: 'NO_SHOW',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.NoShow'),
  },
  {
    value: 'MERCHANT_CLOSED',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.StallClosed'),
  },
  {
    value: 'INTERNAL_TESTING',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.InternalTesting'),
  },
  {
    value: 'OTHERS',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.Others'),
  },
]
export const rejectReasons = [
  {
    value: 'ITEM_UNAVAILABLE',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.ItemUnavailable'),
  },
  {
    value: 'MERCHANT_BUSY',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.TooBusy'),
  },
  {
    value: 'MERCHANT_CLOSED',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.StallClosed'),
  },
]

export const otherReasons = [
  {
    value: 'KCARD_DISCOUNT',
    text: getMessage(
      'qc.orderSummary.reasonpopup.reasons.KDiscountNotReflected'
    ),
  },
]
export const refundReasons = [
  {
    value: 'NO_SHOW',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.NoShow'),
  },
  {
    value: 'APP_PAYMENT_DISCOUNT',
    text: getMessage(
      'qc.orderSummary.reasonpopup.reasons.AppDiscountNotReflected'
    ),
  },
  {
    value: 'QUALITY_OF_FOOD',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.QualityOfFood'),
  },
  {
    value: 'FOOD_POISONING',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.FoodPoisoning'),
  },
  {
    value: 'MISSING_ITEMS',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.MissingItems'),
  },
  {
    value: 'DAMANGED_ITEMS',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.DamagedItem'),
  },
  {
    value: 'WRONG_ITEMS',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.WrongItems'),
  },
  {
    value: 'LATE_DELIVERY',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.LateDelivery'),
  },
  {
    value: 'INTERNAL_TESTING',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.InternalTesting'),
  },
  {
    value: 'OTHERS',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.Others'),
  },
]
export const cancelDeliveryReasons = [
  {
    value: 'ITEM_UNAVAILABLE',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.ItemUnavailable'),
  },
  {
    value: 'MERCHANT_BUSY',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.TooBusy'),
  },
  {
    value: 'MERCHANT_CLOSED',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.StallClosed'),
  },
  {
    value: 'Delivery no show',
    text: getMessage('qc.orderSummary.reasonpopup.reasons.DeliveryNoShow'),
  },
  {
    value: 'CUSTOMER_REQUEST',
    text: getMessage(
      'qc.orderSummary.reasonpopup.reasons.ExceptionalCustomerRequest'
    ),
  },
]
