import { ENVIRONMENT } from 'config/app'

/* istanbul ignore next */
const getOktaState = () => {
  const domainURL = window.location.hostname || ''

  /**
   * If not on uat, avoid further checking on session
   * and return as normal
   */
  if (ENVIRONMENT === 'UAT') {
    if (_getAutomationSession()) {
      window.sessionStorage.setItem('automation', true)
      /**
       * Catered for automation test, having automation-test params and check if theres automation session
       * in cookies, will allow to
       * bypass the OKTA Auth flow for carrying out E2E test (ONLY)
       */
      return false
    }
  }
  return !(domainURL.includes('zs-uat') || domainURL.includes('omni'))
}

/* istanbul ignore next */
const handleDBPRedirect = () => {
  const domainURL = window.location.hostname || ''
  if (domainURL.includes('zs-uat')) {
    window.location.assign('https://admin-uat.fairprice.com.sg/')
  } else if (domainURL.includes('omni')) {
    window.location.assign('https://admin.fairprice.com.sg/')
  }
}

/**
 * This local function will be use to extract the automationSess from the cookie
   decode and verify the content.
 * @return boolean -  True if app is on automation session, otherwise False
 * @Cookie content format
 * {
 *   automationId: string,
 *   session: boolean
 * }
 * @automationId is not nessessary but may be use for future automation session tracking
 * and logging
 * @session is the flag. True means you are telling the app its on automation session.
 * */
const _getAutomationSession = () => {
  try {
    const target = '_automation-sess'

    const value = `; ${document.cookie}`
    const parts = value.split(`; ${target}=`)

    if (parts.length === 2) {
      const n = parts.pop().split(';').shift()
      const decoded = window.atob(n)
      /**
       * Remove trailing characters such as \n and some spaces to ensure JS can parse this
       * string into valid Js Object and attributes are accessible.
       * The decoded base64 (UTF8 based) value should be a stringify JSON format
       * without the wrapping of double quotes.
       *
       * @Reference {@link https://codebeautify.org/json-stringify-online}
       * @Reference {@link https://www.base64encode.org/}
       */
      const formattedStr = decoded.replace(/\\n/g, '').replace(/\\/g, '')

      const b = JSON.parse(formattedStr)
      return b.session
    }
    return false
  } catch (e) {
    /**
     * In case of any error occurs when extracting the cookie
     * or decoding the cookie values to get the actual attribute,
     * return false.. App should fallback to original OKTA login flow
     */
    return false
  }
}
export { getOktaState, handleDBPRedirect, _getAutomationSession }
