
import React, { Component } from 'react'
import { getMessage } from '../../../../lib/translator'
import './style.css'

export default class PackagePreview extends Component {
  render() {
    const { showAssets, showCampaigns } = this.props
    let packageData = {
      "id": "",
      "packageName": "",
      "packageDescription": "",
      "packageDuration": 0,
      "creationDate": "",
      "expireDate": "",
      "packageUnits": null,
      "packagePrice": "",
      "packageValue": "",
      "ownerBu": "-",
      "description": "",
      "assetList": [],
      "reservedCampaigns":[]
    }
    packageData = Object.assign(packageData, this.props.packageData ? this.props.packageData : {})

    return (
      <div className='package-summary-wrap'>
        <div className='package-summary-inner'>
          <div className='package-summary-item description'>
            <div className='text-muted'>{getMessage('media.packageManagement.header.name')}: <span className='black'>{packageData.packageName}</span></div>
          </div>
          <div className='package-summary-item'>
            <div className='text-muted'>{getMessage('media.packageManagement.header.id')}: <span className='black'>{packageData.id}</span></div>
          </div>
          <div className='package-summary-item'>
            <div className='text-muted'>{getMessage('media.packageManagement.header.packagetype')}: <span className='black'>{packageData.packageType}</span></div>
          </div>
          <div className='package-summary-item'>
            <div className='text-muted'>{getMessage('media.packageManagement.header.duration')}: <span className='black'>{packageData.packageDuration}</span></div>
          </div>
          <div className='package-summary-item'>
            <div className='text-muted'>{getMessage('media.packageManagement.header.expire')}: <span className='black'>{packageData.expireDate}</span></div>
          </div>
          <div className='package-summary-item'>
            <div className='text-muted'>{getMessage('media.packageManagement.header.price')}: <span className='black'>{packageData.packagePrice}</span></div>
          </div>
          <div className='package-summary-item'>
            <div className='text-muted'>{getMessage('media.packageManagement.header.value')}: <span className='black'>{packageData.packageValue}</span></div>
          </div>
          <div className='package-summary-item description'>
            <div className='text-muted'>{getMessage('media.packageManagement.header.description')}: <span className='black'>{packageData.packageDescription}</span></div>
          </div>
          {showAssets && (
            <div className='package-summary-item description'>
            <div className='text-muted'>{getMessage('media.packageManagement.header.assets')}:
            <div className='assetTable'>
              {packageData?.assetList?.map((item, index) => (
                <div className='assetRow' key={index}>
                  <div className='id'>{item.buAssetId}</div>
                  <div className='name'>{item.assetName}</div>
                </div>
              ))}
            </div>
            </div>
            </div>
          )}
          {showCampaigns && (
            <div className='package-summary-item description'>
              <div className='text-muted'>{getMessage('media.packageManagement.header.campaigns')}:
                <div className='assetTable'>
                  {packageData?.reservedCampaigns?.map((item, index) => (
                    <div className='assetRow' key={index}>
                      <div className='id'>{item.id}</div>
                      <div className='name'>{item.campaignName}</div>
                      <div className='name'>{item.campaignStartDate} - {item.campaignEndDate}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    )
  }
}
