import React from 'react';
import { Input } from 'components/Form';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { getMessage } from 'lib/translator';
import Loader from 'components/Loader'
import './style.scss';

export function PostalCodeInputStep({
  handlePostalCodeInput,
  handlePostalCodeInputKeyPress,
  handleRemovePostalCode,
  postalCodesText,
  postalCodesChips,
  errorMessage,
  isVerifyingPostalCodes
}) {
  return (
    <div className='postal-code-input-step'>
      <Input
        type="text"
        placeholder="e.g. 629117,068996"
        name="postalCode"
        label={getMessage('operations.timeSlotBlocking.label.postalCodeInput')}
        value={postalCodesText}
        onChange={handlePostalCodeInput}
        onKeyDown={handlePostalCodeInputKeyPress}
      />
      <div className='label-container'>
        { isVerifyingPostalCodes ?
          <div className='loader-wrapper'><Loader size='sm' /></div> :
            errorMessage ? <div className='input-error-message'>{errorMessage}</div>
            : <div className='input-label-message'>{getMessage('operations.timeSlotBlocking.tip.postalCodeInput')}</div>
        }
      </div>
      {
        postalCodesChips.length > 0 &&
        <div className='postal-code-chips-wrapper'>
          {
            postalCodesChips.map((postalCode, index) =>
              <div key={index} className={`chip ${postalCode.isValid ? '' : 'error-chip'}`}>
                {postalCode.value}
                <button className="remove-postal-icon" data-testid={`remove-postal-code-${index}`} onClick={() => handleRemovePostalCode(index)}>
                  <CloseIcon height={11} width={11} />
                </button>
              </div>)
          }
        </div>
      }
    </div>
  )
}
