import React, { Component } from 'react'
import './style.scss'
import classNames from '../../lib/utils/classNames'

export default class Tabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: props.default || props.active || 0,
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      Number.isInteger(newProps.active) &&
      newProps.active !== this.state.activeTab
    ) {
      this.setState({
        activeTab: newProps.active,
      })
    }
  }

  render() {
    const props = this.props
    const { nameKey, valueKey } = props
    const state = this.state
    const align = props.align ? props.align : ''
    return nameKey && valueKey ? (
      <div className={classNames(['tabs', align])}>
        {props.items.map((item) => {
          return (
            <a
              key={item[valueKey]}
              className={classNames([
                state.activeTab === item[valueKey] ? 'active' : '',
                state.activeTab - 1 === item[valueKey] ? 'previous' : '',
                'tab',
              ])}
              onClick={() => {
                if (this.state.activeTab !== item[valueKey]) {
                  this.setState({
                    activeTab: item[valueKey],
                  })
                  props.onClick && props.onClick(item[valueKey])
                }
              }}
            >
              {item[nameKey]}
            </a>
          )
        })}
      </div>
    ) : (
      <div className={classNames(['tabs', align])}>
        {props.items.map((item, index) => {
          return (
            <a
              key={index}
              className={classNames([
                state.activeTab === index ? 'active' : '',
                state.activeTab - 1 === index ? 'previous' : '',
                'tab',
              ])}
              onClick={() => {
                if (this.state.activeTab !== index) {
                  this.setState({
                    activeTab: index,
                  })
                  props.onClick && props.onClick(index)
                }
              }}
            >
              {item}
            </a>
          )
        })}
      </div>
    )
  }
}
