import React from 'react'
import { BaseForm } from 'components/Form'
import { getMessage } from 'lib/translator'
import FilterBanner from '../AppBanner/FilterBanner'
import FilterSplashscreen from '../AppSplash/FilterSplashscreen'
import FilterCard from '../AppCard/FilterCard'
import './style.css'

class Filters extends BaseForm {
  constructor(props) {
    super(props)
  }
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    const { type } = this.props.options

    return (
      <Form>
        <div className="apphome-filters">
          {type === 'banners' && (
            <FilterBanner generateStateMappers={this.generateStateMappers} />
          )}
          {type === 'splashscreen' && (
            <FilterSplashscreen
              generateStateMappers={this.generateStateMappers}
            />
          )}
          {type === 'cards' && (
            <FilterCard generateStateMappers={this.generateStateMappers} />
          )}
        </div>
        <SubmitButton testid="submit-filter">
          {getMessage('apphome.submit')}
        </SubmitButton>
        <ClearButton testid="clear-filter">
          {getMessage('apphome.clear')}
        </ClearButton>
      </Form>
    )
  }
}

export default Filters
