import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../Loader'
import MapWithPath from '../MapWithPath'
import { getPrintableTime, formatDate, formatTime } from '../../lib/datetime'
import EmptyState from '../../components/EmptyState'
import API from '../../lib/api'
import { getSession, hasPermissions, isExtensionEnabled } from '../../lib/auth'
import { getMessage } from '../../lib/translator'
import './style.css'
import AuthenticatedPage from '../../containers/AuthenticatedPage'
import emptyIcon from './order-log-empty.svg'
import { getAsapDuration, getDisplayAddress } from '../../lib/commonlyused'
import { get } from 'lib/storage'

const msgOrderLogBy = getMessage('order.log.user')
const MSG_ORDER_LOG_FROM = getMessage('order.log.from')
const MSG_ORDER_LOG_TO = getMessage('order.log.to')
export default class OrderLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      orderLog: [],
    }
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    })

    this.api = new API({ url: '/order-service/order-log' })
    const params = {
      orderReferenceNumber: this.props.match.params.id,
    }
    this.api.get(params).then((response) => {
      const orderLog = response.data.orderlog
      const log = orderLog.filter(
        (orderlog) => orderlog.action === 'ORDER_DISPATCHED'
      )[0]
      let tripId = false
      if (log && orderLog[0].action === 'ORDER_DISPATCHED') {
        tripId = log.details && log.details.tripId
      }
      this.clientId = response.data.order && response.data.order.clientId
      if (tripId) {
        this.getTripDetails(tripId)
      }
      if (isExtensionEnabled('EntityMetaData')) {
        this.addressApi = new API({ url: '/config-service/config/customers' })
        this.addressApi.get().then((res) => {
          this.setState({
            addressSequence: res.data.customers.addressSequence,
          })
        })
      }
      this.setState({
        orderLog: response.data.orderlog,
        showLoader: false,
        tripId,
      })
    })
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
    this.addressApi && this.addressApi.cancel()
  }

  getTripDetails(id) {
    const api = new API({ url: `/logistics-service/tripLocations` })
    const tripDetailsApi = new API({ url: `/logistics-service/trip/${id}` })
    Promise.all([tripDetailsApi.get(), api.get({ tripId: id })])
      .then(([tripDetailsRes, tripLocationResponse]) => {
        const locations = tripLocationResponse.data
        const length = locations.length
        const vehicleCoordinates =
          length > 0
            ? locations.sort(
                (a, b) =>
                  new Date(getPrintableTime(a.time)) -
                  new Date(getPrintableTime(b.time))
              )
            : []

        const tripDetails = tripDetailsRes.data.trip
        const orders = tripDetails.orders
        const orderLocations = orders.map(({ address }) => ({
          latitude: address.latitude,
          longitude: address.longitude,
        }))
        const storeLocation =
          orders.length > 0
            ? {
                latitude: orders[0].store.latitude,
                longitude: orders[0].store.longitude,
              }
            : null
        this.setState({
          vehicleCoordinates,
          orderLocations,
          showMap: true,
          storeLocation,
        })
      })
      .catch((error) => {
        if (error.code === 401) {
          throw error
        }
      })
  }

  render() {
    const {
      orderLog,
      showLoader,
      vehicleCoordinates,
      showMap,
      orderLocations,
      storeLocation,
    } = this.state
    const count = orderLog && orderLog.length
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <h1>{getMessage('order.logs.heading')}</h1>
        <div className="order-reference">
          <h4>
            {`${getMessage('order.logs.orderRef')} `}
            <span className="text-muted prefix">
              {!showLoader && (this.clientId ? '#' : 'Z')}
            </span>
            {!showLoader &&
              (hasPermissions('order', 'order', 'get') ? (
                <Link to={`/operations/orders/${this.props.match.params.id}`}>
                  {this.clientId || this.props.match.params.id}
                </Link>
              ) : (
                this.clientId || this.props.match.params.id
              ))}
          </h4>
          {orderLog &&
            orderLog[0] &&
            orderLog[0].details &&
            orderLog[0].details.status &&
            orderLog[0].details.status.newValue && (
              <small className="text-muted">{`${getMessage(
                'order.logs.status'
              )}: ${
                orderLog[0].details.status.newValue &&
                orderLog[0].details.status.newValue.charAt(0) +
                  orderLog[0].details.status.newValue.slice(1).toLowerCase()
              }`}</small>
            )}
        </div>
        <div className="order-log">
          {showLoader ? (
            <Loader />
          ) : count > 0 ? (
            orderLog.map((entry, index) => (
              <LogItem
                data={entry}
                index={index}
                key={index}
                vehicleCoordinates={vehicleCoordinates}
                orderLocations={orderLocations}
                storeLocation={storeLocation}
                showMap={showMap}
                addressSequence={this.state.addressSequence}
              />
            ))
          ) : (
            <div>
              <EmptyState
                icon={emptyIcon}
                message={getMessage('order.logs.emptyMeassage')}
              />
            </div>
          )}
        </div>
      </AuthenticatedPage>
    )
  }
}

const SlotTime = ({ item }) => {
  let slotTime = null
  if (
    item.slotStartTime &&
    item.slotEndTime &&
    item.slotStartTime.oldValue &&
    item.slotEndTime.oldValue
  ) {
    slotTime = `${
      item.slotStartTime &&
      item.slotStartTime.oldValue &&
      formatTime(item.slotStartTime.oldValue)
    } - ${item.slotEndTime.oldValue && formatTime(item.slotEndTime.oldValue)}`
  }
  if (
    slotTime == null &&
    item.slotStartTime &&
    item.slotEndTime &&
    item.slotStartTime.newValue &&
    item.slotEndTime.newValue
  ) {
    slotTime = `${
      item.slotStartTime &&
      item.slotStartTime.newValue &&
      formatTime(item.slotStartTime.newValue)
    } - ${item.slotEndTime.newValue && formatTime(item.slotEndTime.newValue)}`
  }

  if (
    item.slotEndTime &&
    item.slotStartTime &&
    item.slotEndTime.oldValue &&
    item.slotStartTime.oldValue &&
    item.slotEndTime.newValue &&
    item.slotStartTime.newValue
  ) {
    return (
      <div className="order-slot">
        {getMessage('order.log.slot.changed')}{' '}
        {item.slotStartTime.oldValue && MSG_ORDER_LOG_FROM}
        <span className="bold">
          {item.slotType && item.slotType.oldValue === 'ASAP'
            ? getAsapDuration(
                item.slotStartTime.oldValue,
                item.slotEndTime.oldValue
              )
            : ' ' + slotTime}{' '}
        </span>
        {MSG_ORDER_LOG_TO}{' '}
        <span className="bold">
          {item.slotType && item.slotType.newValue === 'ASAP'
            ? getAsapDuration(
                item.slotStartTime.newValue,
                item.slotEndTime.newValue
              )
            : `${formatTime(item.slotStartTime.newValue)} - ${formatTime(
                item.slotEndTime.newValue
              )}`}
        </span>
      </div>
    )
  }
  return null
}

const PaymentLog = ({ data, item }) => {
  if (data.action === 'PAYMENT_INITIATION') {
    return (
      <div className="placed-from">
        <span className="bold">
          {getMessage('order.log.payment.initiated')}
        </span>
        {' for '}
        {`${getSession().organization.currency.symbol}${item.amount}`}
        {item.paymentService ? ' via ' + item.paymentService : ''}
        {item.paymentMode && (
          <span className="user-name">
            <span className="by"> Mode:</span> {item.paymentMode}
          </span>
        )}
      </div>
    )
  } else if (data.action === 'PAYMENT_CANCELLATION') {
    return (
      <div className="placed-from">
        <span className="bold">
          {getMessage('order.log.payment.cancelled')}
        </span>
        {' for '}
        {`${getSession().organization.currency.symbol}${item.amount}`}
        {item.paymentMode && (
          <span className="user-name">
            <span className="by"> Mode:</span> {item.paymentMode}
          </span>
        )}
      </div>
    )
  } else if (data.action === 'PAYMENT_COMPLETION') {
    return (
      <div className="placed-from">
        <span className="bold">
          {getMessage('order.log.payment.completed')}
        </span>
        {' for '}
        {`${getSession().organization.currency.symbol}${item.amount}`}
        {item.paymentMode && (
          <span className="user-name">
            <span className="by"> Mode:</span> {item.paymentMode}
          </span>
        )}
      </div>
    )
  } else if (data.action === 'REFUND_INITIATION') {
    return (
      <div className="placed-from">
        <span className="bold">
          {getMessage('order.log.refund.initiated')}
        </span>
        {' for '}
        {`${getSession().organization.currency.symbol}${item.amount}`}
        {item.paymentMethod && (
          <span className="user-name">
            <span className="by"> Mode:</span> {item.paymentMethod}
          </span>
        )}
      </div>
    )
  }
  return null;
}

const pickerStatuses = ['PICKING', 'PICKED', 'PACKING', 'PACKED']
const LogItem = function (props) {
  const item = props.data.details || {}
  const packlistAction =
    props.data.action && props.data.action.includes('PACKLIST')
  const {
    vehicleCoordinates,
    showMap,
    orderLocations,
    storeLocation: storeLocationProp,
  } = props
  let storeLocation = storeLocationProp
  if (!storeLocation || !(storeLocation.latitude || storeLocation.longitude)) {
    const storeId = Number(get('store'))
    storeLocation = JSON.parse(get('stores')).filter(
      (store) => store.id === storeId
    )[0]
    delete storeLocation.address
    delete storeLocation.clientStoreId
    delete storeLocation.id
    delete storeLocation.name
  }

  const getUserName = function (data) {
    return (
      <div className="user-name">
        <span className="by"> {msgOrderLogBy}</span>
        {data.user && data.user.name
          ? data.user.isDeleted
            ? ` ${data.user.name} (Deleted)`
            : ` ${data.user.name}`
          : ' - (Deleted)'}
      </div>
    )
  }

  return (
    Object.keys(props.data.details).length !== 0 &&
    (props.data.action !== 'RECALCULATE_PAYMENT_STATUS' ||
      (props.data.action === 'RECALCULATE_PAYMENT_STATUS' &&
        !!Object.keys(item).length)) && (
      <div className="log-box" key={`log-${props.index}`}>
        <div className="log-date-time">
          <div className="log-date">
            {getPrintableTime(props.data.createdAt).split(',')[0]}
          </div>
          <small className="text-muted">
            {getPrintableTime(props.data.createdAt).split(',')[1]}
          </small>
        </div>
        <div className="log-details">
          <div className="log-details-wrapper">
            {props.data.action === 'ORDER_RETURN' && (
              <div className="bold order-return">
                {getMessage('order.log.return')}
              </div>
            )}

            {props.data.action === 'STATUS_CHANGE' &&
              props.data.details.status &&
              props.data.details.status.newValue === 'COMPLETED' &&
              props.data.details.leftAtDoorStep && (
                <div className="bold">
                  {getMessage('order.log.leftAtDoorStep')}
                </div>
              )}

            {props.data.action === 'CUSTOMER_NOT_AVAILABLE' && (
              <div className="bold">{getMessage('order.log.customerNA')}</div>
            )}

            {!packlistAction && item.status ? (
              props.data.user ? (
                <div className={`bold ${item.status.newValue}`}>
                  {item.status.newValue &&
                    item.status.newValue
                      .toLowerCase()
                      .split('_')
                      .map((t) => t[0].toUpperCase() + t.substr(1, t.length))
                      .join(' ')
                      .toString()}
                  {getUserName(props.data)}
                </div>
              ) : (
                item.status.newValue &&
                item.status.oldValue &&
                (pickerStatuses.includes(item.status.newValue) ? (
                  <div className={`bold ${item.status.newValue}`}>
                    {item.status.newValue
                      .toLowerCase()
                      .split('_')
                      .map((t) => t[0].toUpperCase() + t.substr(1, t.length))
                      .join(' ')}
                    {getUserName(props.data)}
                  </div>
                ) : (
                  <div className={`status-changed ${item.status.newValue}`}>
                    {getMessage('order.log.status.changed')}{' '}
                    {MSG_ORDER_LOG_FROM}{' '}
                    <span className="bold">{` ${
                      item.status.oldValue &&
                      item.status.oldValue
                        .toLowerCase()
                        .split('_')
                        .map((t) => t[0].toUpperCase() + t.substr(1, t.length))
                        .join(' ')
                        .toString()
                    }`}</span>{' '}
                    {MSG_ORDER_LOG_TO}{' '}
                    <span className="bold">{`${
                      item.status.newValue &&
                      item.status.newValue
                        .toLowerCase()
                        .split('_')
                        .map((t) => t[0].toUpperCase() + t.substr(1, t.length))
                        .join(' ')
                        .toString()
                    }`}</span>
                  </div>
                ))
              )
            ) : (
              props.data.user && (
                <div className="bold updated-by">
                  {getMessage('order.log.editedBy')}
                  {getUserName(props.data)}
                </div>
              )
            )}

            {item.placedFrom ? (
              <div className="placed-from">
                <span className="bold">
                  {getMessage('order.log.placedfrom')}
                </span>
                : {item.placedFrom}
              </div>
            ) : null}

            {item.paymentStatus && (
              <div className="payment-changed">
                {getMessage('order.log.payment.changed')}: {MSG_ORDER_LOG_FROM}{' '}
                <span className="bold">
                  {item.paymentStatus.oldValue &&
                    item.paymentStatus.oldValue
                      .toLowerCase()
                      .split('_')
                      .map((t) => t[0].toUpperCase() + t.substr(1, t.length))
                      .join(' ')
                      .toString()}
                </span>{' '}
                {MSG_ORDER_LOG_TO}{' '}
                <span className="bold">
                  {item.paymentStatus.newValue
                    .toLowerCase()
                    .split('_')
                    .map((t) => t[0].toUpperCase() + t.substr(1, t.length))
                    .join(' ')
                    .toString()}
                </span>
              </div>
            )}

            {item.address && item.address.newValue && (
              <div>
                {getMessage('order.log.address.changed')}:{' '}
                <span className="bold">{`${getDisplayAddress(
                  item.address.newValue,
                  props.addressSequence
                )}`}</span>
              </div>
            )}

            {item.preferredDate && (
              <div className="order-date">
                {getMessage('order.log.date.changed')}{' '}
                {item.preferredDate.oldValue && MSG_ORDER_LOG_FROM}{' '}
                <span className="bold">
                  {item.preferredDate.oldValue &&
                    formatDate(item.preferredDate.oldValue)}
                </span>{' '}
                {MSG_ORDER_LOG_TO}
                <span className="bold">
                  {' '}
                  {formatDate(item.preferredDate.newValue)}
                </span>
              </div>
            )}

            <SlotTime item={item} />

            {item.shipping && (
              <div>
                {getMessage('order.log.shipping.charges')}:{' '}
                <span className="bold">{item.shipping.newValue}</span>
              </div>
            )}

            {item.type && (
              <div style={{ textTransform: 'capitalize' }}>
                {getMessage('order.log.type.changed')}: {MSG_ORDER_LOG_FROM}{' '}
                <span className="bold">
                  {item.type.oldValue.name.toLowerCase()}
                </span>{' '}
                {MSG_ORDER_LOG_TO}{' '}
                <span className="bold">
                  {item.type.newValue.name.toLowerCase()}
                </span>
              </div>
            )}

            {item.metaData &&
              Object.keys(item.metaData.newValue).map((metadata, i) => (
                <div
                  key={`${props.index}-metadata-${i}`}
                >{`${metadata}: ${item.metaData.newValue[metadata]}`}</div>
              ))}

            {props.data.action === 'ORDER_DISPATCHED' && showMap && (
              <MapWithPath
                coordinates={orderLocations}
                vehiclePath={vehicleCoordinates}
                callMaps={false}
                showStore={storeLocation}
              />
            )}
            {props.data.action === 'ORDER_DISPATCHED' && (
              <React.Fragment>
                <div className="bold">
                  {getMessage('order.log.tripId')}
                  {hasPermissions('logistics', 'trips', 'get') ? (
                    <Link to={`/logistics/trips/${item.tripId}`}>
                      #{item.tripId}
                    </Link>
                  ) : (
                    item.tripId
                  )}
                </div>
              </React.Fragment>
            )}

            {item.itemChanges && (
              <div>
                <Table
                  data={{
                    header: 'Item Changes',
                    tableData: item.itemChanges,
                    properties: [
                      'itemName',
                      'oldQuantity',
                      'newQuantity',
                      'orderItemId',
                      'reason',
                    ],
                    index: props.index,
                    itemChanges: true,
                  }}
                />
              </div>
            )}

            {item.amount && item.amount.oldValue && item.amount.newValue && (
              <div className="amount-changed">
                {getMessage('order.log.amount.changed')}: {MSG_ORDER_LOG_FROM}{' '}
                <span className="bold">{` ${
                  getSession().organization.currency.symbol
                }${item.amount.oldValue}`}</span>{' '}
                {MSG_ORDER_LOG_TO}{' '}
                <span className="bold">{`${
                  getSession().organization.currency.symbol
                }${item.amount.newValue}`}</span>
              </div>
            )}

            {item.payment && (
              <Table
                data={{
                  header: 'Payment',
                  tableData: item.payment.newValue,
                  properties: ['mode', 'amount', 'status', 'transactionId'],
                  index: props.index,
                }}
              />
            )}
            {/* payment log */}
            <PaymentLog data={props.data} item={item} />
          </div>
        </div>
      </div>
    )
  )
}

const Table = function (props) {
  const theadData = props.data.properties
  const tbodyData = props.data.tableData
  const { itemChanges } = props.data
  return (
    <div className="tabular-data">
      {props.data.header && <h4>{props.data.header}</h4>}
      <table key={`table-${props.data.index}`}>
        <thead className="table-header">
          <tr>
            {theadData.map((cell, index) => (
              <th key={`th-${props.data.index}-${index}`}>
                {getMessage(`order.log.${cell}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-body">
          {tbodyData.map((row, index) => (
            <tr key={`tr-${props.data.index}-${index}`}>
              {theadData.map((cell, i) => {
                if (cell === 'reason' && row.metaData && row.metaData.reason) {
                  return (
                    <td key={`td-${props.data.index}-${index}-${i}`}>
                      {row.metaData[cell]}
                    </td>
                  )
                }
                return (
                  <td key={`td-${props.data.index}-${index}-${i}`}>
                    {isNaN(row[cell])
                      ? cell === 'status'
                        ? row[cell] &&
                          row[cell].charAt(0) + row[cell].slice(1).toLowerCase()
                        : row[cell]
                      : row[cell] % 1 !== 0 ||
                          (itemChanges && (i === 1 || i === 2))
                        ? Number(row[cell]).toFixed(2)
                        : row[cell]}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
