import React, { Fragment } from 'react'
import { Input, Select, DateRangePicker } from '../Form'
import { getMessage } from 'lib/translator'
import { CHALLENGE_TYPE } from './utils'

const SearchChallenges = ({ generateStateMappers }) => (
  <Fragment>
    <Input
      id="name"
      label={getMessage('search-campaigns.name')}
      placeholder={getMessage('rewards.searchName.placeholder')}
      name="name"
      type="text"
      {...generateStateMappers({
        stateKeys: ['name'],
        loseEmphasisOnFill: true,
      })}
    />
    <Select
      name="type"
      testid="type"
      label={getMessage(`search-campaigns.type`)}
      placeholder={getMessage(`rewards.selectType`)}
      options={CHALLENGE_TYPE}
      {...generateStateMappers({
        stateKeys: [`type`],
        loseEmphasisOnFill: true,
      })}
    />
    <DateRangePicker
      className="section-row-input"
      label={getMessage('omnichallenge.campaignDuration')}
      {...generateStateMappers({
        stateKeys: ['duration'],
        loseEmphasisOnFill: true,
      })}
      isOutsideRange={() => false}
      displayFormat="YYYY-MM-DD"
      startDatePlaceholder={getMessage('campaign.startDate')}
      endDatePlaceholder={getMessage('campaign.endDate')}
    />
  </Fragment>
)

export default SearchChallenges
