import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../../../components/Loader'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { getMessage } from '../../../../lib/translator'
import { OfferTypes } from '../../../marketing/Offers/settings'
import useUploadOffer from './hooks/useUploadOffer'
import '../style.scss';

export default function ReviewTableOffer({ results, jobName, onBack }) {
  const { tableData, isLoading, onUpload, isUploading, uploadResult, invalidSegmentIoData } =
    useUploadOffer(results, jobName)

  const headers = results[0]

  const showUploadStatus = Boolean(uploadResult?.successful?.length) || Boolean(uploadResult?.errors?.length)

  // show all offers fetched from CSV
  // once its uploded show failed and invalid offers
  const offersToShow =
    useMemo(
      () =>
        showUploadStatus
          ? tableData.filter(
              (row, index) =>
                (uploadResult?.errors || []).some((err) => err.id === index) ||
                row.some((col) => !col.isValid)
            )
          : tableData,
      [showUploadStatus, tableData, uploadResult]
    ) || []

  const showErrors = showUploadStatus && Boolean(offersToShow?.length)
  const showSuccessFullOffers =
    showUploadStatus && Boolean(uploadResult?.successful?.length)
  if (isLoading || isUploading) {
    return <Loader />
  }
  return (
    <>
      {
        invalidSegmentIoData.length > 0 &&
        <div className="bulk-upload-error-message-box-wrapper">
          <div className="message-box-error">
            Failed to create some offers because of these invalid SegmentIO tag names:
            <br /> {invalidSegmentIoData.map(x => x.name).join(", ")}
          </div>
        </div>
      }
      {showUploadStatus && (
        <div className="message-box">
          <div className="update-message">Offers created successfully</div>
          <div className="update-message">
            Number of successful offers:{' '}
            <span className="count">{uploadResult?.successful?.length}</span>
          </div>
          <div className="update-message">
            Number of invalid offers:{' '}
            <span className="count">{offersToShow.length}</span>
          </div>
        </div>
      )}
      {showSuccessFullOffers && (
        <>
          <div
            className="invalid-message"
            data-testid="successfull-uploded-offers-message"
          >
            Below is the list of successfully created offers
          </div>
          <Table tableDynamic={false}>
            <Header items={['ID', 'Name', 'Description', 'Type', 'Status']} />
            {uploadResult.successful?.map((row) => {
              const redirectUrl = `/marketing/offers/edit/${row.id}`
              return (
                <Row key={row.id} testId="successfull-uploded-offers">
                  <Cell>
                    <Link to={redirectUrl}>{row.id}</Link>
                  </Cell>
                  <Cell>{row?.metaData?.sapReference || ''}</Cell>
                  <Cell>{row.description || ''}</Cell>
                  <Cell>{OfferTypes[row.offerType]}</Cell>
                  <Cell>{row.status || ''}</Cell>
                </Row>
              )
            })}
          </Table>
        </>
      )}
      {showErrors && (
        <div className="invalid-message">
          Below is the list of invalid/falied offers
        </div>
      )}
      {Boolean(offersToShow?.length) && (
        <div className="table-container table-responsive">
          <Table tableDynamic={false}>
            <Header items={headers} />
            {offersToShow.map((row, index) => {
              const isEdit = row.some(item => item.key === 'offerId')
              if (isEdit) {
                return (
                  <Row
                    key={`${row[index]}-${index}`}
                    testId={
                      showErrors ? 'failed-uploded-offers' : 'offers-to-upload'
                    }
                  >
                    {row.map((col, ind) => {
                      return (
                        <Cell
                          key={`${row[ind]}-${ind}`}
                        >
                          {col.value}
                        </Cell>
                      )
                    })}
                  </Row>
                )
              } else {
                const hasError =
                row.some((col) => !col.isValid) || showUploadStatus
                return (
                  <Row
                    key={`${row[index]}-${index}`}
                    className={hasError ? 'row-error' : ''}
                    testId={
                      showErrors ? 'failed-uploded-offers' : 'offers-to-upload'
                    }
                  >
                    {row.map((col, ind) => {
                      return (
                        <Cell
                          className={col.isValid ? '' : 'column-error'}
                          key={`${row[ind]}-${ind}`}
                        >
                          {col.value}
                        </Cell>
                      )
                    })}
                  </Row>
                )
              }
            })}
          </Table>
        </div>
      )}
      <div className="actions">
        <button className="button" onClick={onBack}>
          {showUploadStatus
            ? getMessage('category.form.goBackText')
            : getMessage('category.form.cancelText')}
        </button>
        {!showUploadStatus && (
          <button
            className="primary button"
            onClick={onUpload}
            data-testid="cart-offer-upload-action"
          >
            {getMessage('category.form.upload')}
          </button>
        )}
      </div>
    </>
  )
}
