import React, { Component } from 'react'
import { formatIsoDate } from '../../lib/datetime'
import calendarIcon from './calender-icon.svg'
import API from 'lib/api'

export default class ResendDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expiresAt: this.props.values.expiresAt,
    }
  }

  async getBatchDetails() {
    try {
      const api = new API({
        url: `/batches/${this.props.values.id}`,
      })

      const response = await api.get()
      this.setState({
        expiresAt: response.expiresAt,
      })
    } catch (error) {
      console.log(error)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.values.expiresAt !== prevState.expiresAt) {
      this.setState({
        expiresAt: prevProps.values.expiresAt,
      })
    }
  }

  componentDidMount() {
    if (this.props.method === 'update_expiry' && !this.props.values.expiresAt) {
      this.getBatchDetails()
    }
  }

  render() {
    return (
      <div
        className="resend-details-wrapper mb container"
        data-testid="resend-details"
      >
        <div className="row status">
          <div className="col-6">
            <h3>Status:</h3>
          </div>
          <div className="col-6">
            <h3>{this.props.values.status}</h3>
          </div>
        </div>
        <div className="row mt">
          <div className="col-6">
            <label>Batch ID:</label>
          </div>
          <div className="col-6">
            <label>{this.props.values.id}</label>
          </div>
        </div>
        <div className="row mt">
          <div className="col-6">
            <label>Entity Name: </label>
          </div>
          <div className="col-6">
            <label>{this.props.values.entityName}</label>
          </div>
        </div>
        <div className="row mt">
          <div className="col-6">
            <label>
              <img src={calendarIcon} alt="calendar" /> Created At:
            </label>
          </div>
          <div className="col-6">
            <label>{formatIsoDate(this.props.values.createdAt)}</label>
          </div>
        </div>

        {this.props.method === 'update_expiry' && (
          <div className="row mt mb">
            <div className="col-6">
              <label>
                <img src={calendarIcon} alt="calendar" /> Current Expiry Date:
              </label>
            </div>
            <div className="col-6">
              <label>{formatIsoDate(this.state.expiresAt)}</label>
            </div>
          </div>
        )}

        {this.props.method === 'view' && (
          <>
            <div className="row mt">
              <div className="col-6">
                <label>Total Amount: </label>
              </div>
              <div className="col-6">
                <label>${this.props.values.totalAmount.toLocaleString()}</label>
              </div>
            </div>
            <div className="row mt mb">
              <div className="col-6">
                <label>Total Voucher Count: </label>
              </div>
              <div className="col-6">
                <label>{this.props.values.totalVoucherCount}</label>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

ResendDetails.DefaultProps = {
  method: 'view',
}
