import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../Table'
import { DropDown, DropDownItem, ICONS } from '../../DropDown'
import moment from 'moment'
import { getMessage } from '../../../lib/translator'
import { DATE_TIME_FORMAT, getStatusText } from '../utils'

const tableProperties = {
  headers: [
    'ID',
    'Name',
    'Device Type',
    'Publish Date',
    'Issuance Rate Linkpoint',
    'Issuance Rate Dollar Value',
    'Status',
    'Actions',
  ],
  row: ({
    id,
    name,
    displayChannel,
    publishAt,
    linkpointGain,
    linkpointDollarSpend,
    status,
  }) => (
    <Row>
      <Cell className="rewards-page-actions column-id">
        <Link to={`/rewards/partners/edit/${id}`}>{id}</Link>
      </Cell>
      <Cell className="rewards-page-actions column-name">
        <Link to={`/rewards/partners/edit/${id}`}>{name}</Link>
      </Cell>
      <Cell className="rewards-page-actions column-type">{displayChannel}</Cell>
      <Cell className="rewards-page-actions column-publish-at">
        {moment(publishAt).format(DATE_TIME_FORMAT)}
      </Cell>
      <Cell className="rewards-page-actions column-linkpointGain">
        {linkpointGain}
      </Cell>
      <Cell className="rewards-page-actions column-linkpointDollarSpend">
        {linkpointDollarSpend}
      </Cell>
      <Cell className="rewards-page-actions column-status">
        {getStatusText(status)}
      </Cell>
      <Cell className="rewards-page-actions column-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <Link to={`/rewards/partners/edit/${id}`}>
            <DropDownItem>{getMessage('rewards.partner.edit')}</DropDownItem>
          </Link>
        </DropDown>
      </Cell>
    </Row>
  ),
}

export { tableProperties }
