import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../Table'
import { DropDown, DropDownItem, ICONS } from '../../DropDown'
import moment from 'moment'
import { getMessage } from '../../../lib/translator'
import { DATE_TIME_FORMAT, getStatusText } from '../utils'
import ImagePlaceHolder from '../../Image/image-placeholder.svg'

const TYPE_OPTIONS = [
  {
    value: 'partner',
    text: getMessage('rewards.partner'),
  },
  {
    value: 'promotion',
    text: getMessage('rewards.carousel.promotion'),
  },
  {
    value: 'link',
    text: getMessage('rewards.carousel.link'),
  },
  {
    value: 'login',
    text: getMessage('rewards.carousel.login'),
  },
]

const tableProperties = {
  headers: [
    'ID',
    'Web Img',
    'App Img',
    'Type',
    'Device Type',
    'Rank',
    'Start Date',
    'End Date',
    'Page Url',
    'Status',
    'Actions',
  ],
  row: ({
    id,
    imgUrls,
    startDate,
    endDate,
    link,
    status,
    type,
    displayChannel,
    rank,
  }) => {
    const { carouselBanner, appBanner } = { ...imgUrls }
    return (
      <Row>
        <Cell className="rewards-page-actions column-id">
          <Link to={`/rewards/carousels/edit/${id}`}>{id}</Link>
        </Cell>
        <Cell className="rewards-page-actions column-web-img">
          <Link to={`/rewards/carousels/edit/${id}`}>
            <img
              src={carouselBanner ? carouselBanner : ImagePlaceHolder}
              alt="Icon"
              height={50}
            />
          </Link>
        </Cell>
        <Cell className="rewards-page-actions column-app-img">
          <Link to={`/rewards/carousels/edit/${id}`}>
            <img
              src={appBanner ? appBanner : ImagePlaceHolder}
              alt="Icon"
              height={50}
            />
          </Link>
        </Cell>
        <Cell className="rewards-page-actions column-type">{type}</Cell>
        <Cell className="rewards-page-actions column-channel">
          {displayChannel}
        </Cell>
        <Cell className="rewards-page-actions column-rank">{rank}</Cell>
        <Cell className="rewards-page-actions column-startdate">
          {moment(startDate).format(DATE_TIME_FORMAT)}
        </Cell>
        <Cell className="rewards-page-actions column-enddate">
          {moment(endDate).format(DATE_TIME_FORMAT)}
        </Cell>
        <Cell className="rewards-page-actions column-link">{link}</Cell>
        <Cell className="rewards-page-actions column-status">
          {getStatusText(status)}
        </Cell>
        <Cell className="rewards-page-actions column-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <Link to={`/rewards/carousels/edit/${id}`}>
              <DropDownItem>{getMessage('rewards.carousel.edit')}</DropDownItem>
            </Link>
          </DropDown>
        </Cell>
      </Row>
    )
  },
}

export { TYPE_OPTIONS, tableProperties }
