import React from 'react'
import { getMessage } from 'lib/translator'
import TextEditor from '../../RichEditor/TextEditor'

const WebDescription = ({ _this, webDescription }) => {
  const formattedStory =
    typeof webDescription === 'string' ? webDescription.trim() : ''

  const onEditorStateChange = editorStateValue => {
    const values = Object.assign({}, _this.state.values)
    values.webDescription = editorStateValue
    _this.setState({
      values,
    })
  }

  return (
    <TextEditor
      label={getMessage(`rewards.promotion.webDescription`)}
      placeholder={getMessage(`rewards.promotion.webDescription`)}
      html={formattedStory}
      onChange={onEditorStateChange}
    />
  )
}

export default WebDescription
