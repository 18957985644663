import React, { Fragment } from 'react'
import {
  BaseForm,
  Upload,
  VALIDATION_TYPES,
  Select,
  Input,
  Searchable,
  SingleDatePicker,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import moment from 'moment'

import './style.css'

const timeOptions = (interval = 60) => {
  const times = []
  const x = {
    slotInterval: interval,
    openTime: '00:00',
    closeTime: '24:00',
  }
  let startDate = moment(x.openTime, 'HH:mm')
  const endDate = moment(x.closeTime, 'HH:mm')
  while (startDate < endDate) {
    times.push(startDate.format('HH:mm'))
    startDate = startDate.add(x.slotInterval, 'm')
  }
  return times
}
class CampaignLabelsForm extends BaseForm {
  constructor(props) {
    super(props)
    if (!this.props.value) {
      const value = this.state.values
      if (this.props.method === 'add') {
        value.startDate = moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
        value.campaignStartTime = '05:00'
      }
    }
  }

  componentDidMount() {
    if (this.props.method === 'add') {
      this.setState({ loader: true })
    }
    if (this.state.values && this.props.method !== 'add') {
      const values = Object.assign({}, this.state.values)
      this.setState({
        values,
        loader: false,
      })
    }
  }

  picker() {
    const { endDate } = this.state.values
    const endDatePlaceholder = endDate && moment(endDate).format('HH:mm')

    const datePicker = {
      label: getMessage('campaign-labels.form.validTo'),
      placeholder: getMessage('campaign-labels.endDate'),
      displayFormat: 'YYYY-MM-DD',
      openDirection: 'up',
      name: 'endDate',
      className: 'date-section',
    }

    const select = {
      label: getMessage('campaign-labels.form.endTime'),
      name: 'campaignendDate',
      type: 'text',
      className: 'time-section',
      placeholder: endDatePlaceholder || '00:00',
      options: timeOptions(30),
    }

    return (
      <div className="form-section date-time-section">
        <SingleDatePicker
          {...datePicker}
          required
          {...this.generateStateMappers({
            stateKeys: ['endDate'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <Select
          {...select}
          required
          {...this.generateStateMappers({
            stateKeys: ['campaignEndTime'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }

  campaignLabelDatePicker() {
    const { startDate } = this.state.values
    const startDatePlaceholder = moment(startDate).format('HH:mm')
    return (
      <Fragment>
        <div className="form-section date-time-section">
          <SingleDatePicker
            label={getMessage('campaign-labels.form.validFrom')}
            placeholder={getMessage('campaign-labels.startDate')}
            displayFormat="YYYY-MM-DD"
            name="startDate"
            className="date-section"
            allowToday
            enableToday
            required
            openDirection={'up'}
            {...this.generateStateMappers({
              stateKeys: ['startDate'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('campaign-labels.form.startTime')}
            name="campaignstartDate"
            type="text"
            className="time-section"
            placeholder={startDatePlaceholder || '00:00'}
            options={timeOptions(30)}
            {...this.generateStateMappers({
              stateKeys: ['campaignStartTime'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        {this.picker()}
      </Fragment>
    )
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  campaignLabelName() {
    return (
      <div className="form-section block-section">
        <Input
          label={getMessage('campaign-labels.form.campaignName.label')}
          tooltip={
            <Fragment>
              <div>
                <b>Note:</b>
              </div>
              <div>Character limit might apply</div>
            </Fragment>
          }
          placeholder={getMessage(
            'search-campaigns.form.campaignName.placeholder'
          )}
          name="name"
          type="text"
          required
          {...this.generateStateMappers({
            stateKeys: ['name'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }

  renderProductSearch() {
    return (
      <Searchable
        label={getMessage('campaign-labels.form.tag.heading')}
        placeholder={getMessage('search-campaigns.form.tag.placeholder')}
        name="tag"
        searchUrl="/catalogue-service/tag"
        valueKey="id"
        responseKey="tag"
        nameKey="name"
        searchKey="name"
        expandMore={true}
        transformResponse={(response) => response.data.tag}
        required
        {...this.generateStateMappers({
          stateKeys: ['productTagUrl'],
          loseEmphasisOnFill: true,
        })}
      />
    )
  }
  pageTypeField() {
    return this.renderProductSearch()
  }

  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons
    return (
      <Form className="search-campaigns-form" testid="search-campaigns-form">
        <fieldset>
          <div className="form-sections">
            <div className="form-section cl-logo-upload">
              <Upload
                label={getMessage('campaign-labels.form.image.label')}
                tooltip={
                  <Fragment>
                    <div>
                      <b>Note:</b>
                    </div>
                    <div>
                      Image dimension should be minimum 16px x 60px and maximum
                      34px x 125px can be .jpg or .png
                    </div>
                  </Fragment>
                }
                required
                name="logo"
                {...this.generateStateMappers({
                  stateKeys: ['logo'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            <div className="form-section seachable-component">
              {this.pageTypeField()}
            </div>
            {this.campaignLabelName()}
            {this.campaignLabelDatePicker()}
          </div>
          <div className="form-actions">
            <CancelButton>Cancel</CancelButton>
            <SubmitButton>Save</SubmitButton>
          </div>
        </fieldset>
      </Form>
    )
  }
}

export default CampaignLabelsForm
