import React, { Component } from 'react'
import Table, { Header, Row, Cell } from '../../../../../components/Table'
import Image from '../../../../../components/Image'
import {
  getNestedState,
  updateStateRecursively,
} from '../../../../../lib/stateManagement'
import { getMessage } from '../../../../../lib/translator'

import '../../Details/OrderItemsView/style.css'
function getMobileView() {
  return window.screen.width <= 480
}

class ItemsView extends Component {
  constructor(props) {
    super(props)
    this.state = this.generateStateFromProps(props, {
      items: [],
      isMobileView: getMobileView(),
    })

    this.getNestedState = getNestedState.bind(this)
    this.updateStateRecursively = updateStateRecursively.bind(this)
    this.mobileView = this.mobileView.bind(this)
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() })
  }

  generateStateFromProps(props, state) {
    const newState = Object.assign({}, state, {
      items: [...props.data.items],
    })
    // TODO: Use immutable data structures instead
    return JSON.parse(JSON.stringify(newState))
  }

  componentDidMount() {
    window.addEventListener('resize', this.mobileView, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.mobileView, false)
  }
  render() {
    const productsInOrder = this.state.items
    const notPendingOrCancelled =
      this.props.status !== 'PENDING' || this.props.status !== 'CANCELLED'
    const invoiceQuantity = !notPendingOrCancelled
      ? 'deliveredQuantity'
      : 'orderedQuantity'
    const totalMrp = this.state.items.reduce(
      (result, item) =>
        result +
        Number(item.orderDetails.mrp) *
          Number(item.orderDetails[invoiceQuantity]),
      0
    )

    const totalDiscount = this.state.items.reduce(
      (result, item) =>
        result +
        Number(item.orderDetails.discount) *
          Number(item.orderDetails[invoiceQuantity]),
      0
    )

    let totalAmountCalculated = 0
    this.state.items.map(item => {
      if (
        item.orderDetails.status === 'PENDING' ||
        (item.orderDetails['deliveredQuantity'] === undefined &&
          item.orderDetails.status === undefined)
      ) {
        totalAmountCalculated +=
          Number(item.orderDetails.mrp) *
            Number(item.orderDetails['orderedQuantity']) -
          Number(item.orderDetails.discount) *
            Number(item.orderDetails['orderedQuantity'])
      } else {
        let quantity = Number(item.orderDetails['deliveredQuantity'])
        if (quantity > Number(item.orderDetails['orderedQuantity'])) {
          quantity = Number(item.orderDetails['orderedQuantity'])
        }
        totalAmountCalculated +=
          Number(item.orderDetails.mrp) * quantity -
          Number(item.orderDetails.discount) * quantity
      }
      return null
    })

    const isMobileView = this.state.isMobileView
    return (
      <div className="order-items-view items-view">
        <div className="flex-around section-title">
          <h3>
            {getMessage('order.details.itemsTable.title')}
            <span className="text-muted"> ({productsInOrder.length})</span>
          </h3>
        </div>
        <Table>
          <Header>
            <Cell className="item-image">
              {getMessage('order.details.itemsTable.header.image')}
            </Cell>
            <Cell className="item-name">
              {getMessage('order.details.itemsTable.header.name')}
            </Cell>
            <Cell
              className="item-original text-right"
              abbreviation={getMessage(
                'order.details.itemsTable.header.quantity.abbreviated'
              )}
            >
              {getMessage('order.details.itemsTable.header.quantity')}
            </Cell>
            {this.props.status !== 'PENDING' &&
              this.props.status !== 'CANCELLED' && (
                <Cell className="item-final text-right">
                  {' '}
                  {getMessage('order.details.itemsTable.header.picked')}
                </Cell>
              )}
            <Cell className="item-mrp text-right">
              {getMessage('order.details.itemsTable.header.mrp')}
            </Cell>
            <Cell className="item-discount text-right">
              {getMessage('order.details.itemsTable.header.discount')}
            </Cell>
            <Cell className="item-amount text-right">
              {getMessage('order.details.itemsTable.header.amount')}
            </Cell>
          </Header>
          {this.state.items &&
            this.state.items.map((item, index) => {
              const image = item.images && item.images[0] ? item.images[0] : null
              const mrp = Number(item.orderDetails.mrp)
              const discount = Number(item.orderDetails.discount) || 0
              const orderedQuantity = item.orderDetails.orderedQuantity
              const pickedQuantity = Number(item.orderDetails.deliveredQuantity)
              const chargeableQuantity =
                item.orderDetails.status === 'PENDING' ||
                (item.orderDetails['deliveredQuantity'] === undefined &&
                  item.orderDetails.status === undefined) ||
                pickedQuantity > orderedQuantity
                  ? orderedQuantity
                  : pickedQuantity
              const isSoldByWeight = item.soldByWeight || false
              const name = item.fullName || item.name
              const comment =
                item &&
                item.orderDetails &&
                item.orderDetails.metaData &&
                item.orderDetails.metaData.comment
              let unit =
                (name &&
                  name
                    .split(' ')
                    .slice(-1)
                    .pop()) ||
                ''
              unit = unit.toUpperCase()
              const weight = name ? name.split(' ').slice(-2)[0] : 1
              let divider = 1
              let multiplier = 1
              let smallUnit = 'g'
              if (unit === 'KG' || unit === 'L') {
                smallUnit = unit === 'KG' ? ' g' : ' ml'
                multiplier = weight * 1000
                divider = weight * 1000
              }
              if (unit === 'G' || unit === 'GM' || unit === 'ML') {
                multiplier = weight
                divider = weight
              }
              if (orderedQuantity === 0) {
                return null
              }

              let deliveredQuantity = this.getNestedState([
                'items',
                index,
                'orderDetails',
                'deliveredQuantity',
              ])
              if (deliveredQuantity !== '') {
                deliveredQuantity = isSoldByWeight
                  ? Number(deliveredQuantity) + ''
                  : Math.floor(Number(deliveredQuantity))
              }

              return (
                <Row key={item.id || `_${index}`}>
                  <Cell className="item-image">
                    <Image bordered size="sm" src={image} />
                  </Cell>
                  <Cell className="item-name">
                    <small className="text-muted">
                      {item.brand ? (
                        <div className="product-name">
                          {' '}
                          <span className="brand-name">
                            {item.brand.name}
                          </span>{' '}
                          {name}
                        </div>
                      ) : (
                        name
                      )}
                    </small>
                    {comment && (
                      <div className="text-muted item-comment">{comment}</div>
                    )}
                  </Cell>
                  <Cell className="item-original text-right">
                    {isMobileView ? (
                      <span className="mobile-view-header">
                        {getMessage('order.details.itemsTable.header.quantity')}
                      </span>
                    ) : (
                      ''
                    )}
                    <small className="text-muted">
                      {isSoldByWeight
                        ? this.props.status !== 'PENDING'
                          ? (Number(orderedQuantity) / divider).toFixed(3)
                          : Number(orderedQuantity).toFixed() + smallUnit
                        : Number(orderedQuantity).toFixed(0)}
                    </small>
                  </Cell>
                  {this.props.status !== 'PENDING' &&
                    this.props.status !== 'CANCELLED' && (
                      <Cell className="item-final text-right">
                        {isMobileView ? (
                          <span className="mobile-view-header">
                            {getMessage(
                              'order.details.itemsTable.header.picked'
                            )}
                          </span>
                        ) : (
                          ''
                        )}

                        <small className="text-muted">
                          <input
                            type="number"
                            placeholder={isSoldByWeight ? '0.000' : '0'}
                            step={isSoldByWeight ? '0.001' : '1'}
                            min={isSoldByWeight ? 0.0 : 0}
                            value={deliveredQuantity}
                            onChange={e => {
                              if (e) {
                                const items = this.state.items
                                items[index]['orderDetails'][
                                  'deliveredQuantity'
                                ] = e.target.value
                                this.setState(
                                  {
                                    items: items,
                                  },
                                  () => this.props.onChange(items)
                                )
                              }
                            }}
                            onBlur={e => {
                              const items = this.state.items
                              let updatedValue = e.target.value
                              if (updatedValue !== '') {
                                updatedValue = isSoldByWeight
                                  ? e.target.value
                                  : Math.floor(
                                      Math.min(
                                        Math.abs(Number(e.target.value)),
                                        Number(orderedQuantity)
                                      )
                                    )
                              }
                              items[index]['orderDetails'][
                                'deliveredQuantity'
                              ] = updatedValue
                              this.setState(
                                {
                                  items: items,
                                },
                                () => this.props.onChange(items)
                              )
                              const keys = [
                                'items',
                                index,
                                'orderDetails',
                                'deliveredQuantity',
                              ]
                              this.updateStateRecursively(
                                keys,
                                Number(this.getNestedState(keys))
                              )
                            }}
                          />
                        </small>
                      </Cell>
                    )}
                  <Cell className="item-mrp text-right">
                    {isMobileView ? (
                      <div className="mobile-view-header">
                        {getMessage('order.details.itemsTable.header.mrp')}
                      </div>
                    ) : (
                      ''
                    )}
                    <small className="text-muted">
                      {this.props.currency.symbol}{' '}
                      {isSoldByWeight
                        ? (mrp * multiplier).toFixed(2)
                        : mrp.toFixed(2)}
                    </small>
                  </Cell>
                  <Cell className="item-discount text-right">
                    {isMobileView ? (
                      <div className="mobile-view-header">
                        {getMessage('order.details.itemsTable.header.discount')}
                      </div>
                    ) : (
                      ''
                    )}
                    <small className="text-muted">
                      {this.props.currency.symbol}{' '}
                      {isSoldByWeight
                        ? (discount * multiplier).toFixed(2)
                        : discount.toFixed(2)}
                    </small>
                  </Cell>
                  <Cell className="item-amount text-right">
                    {isMobileView ? (
                      <div className="mobile-view-header">
                        {getMessage('order.details.itemsTable.header.amount')}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="remove-button-container">
                      {this.props.currency.symbol}{' '}
                      {((mrp - discount) * chargeableQuantity).toFixed(2)}
                    </div>
                  </Cell>
                </Row>
              )
            })}
          {productsInOrder.length > 0 && (
            <Row>
              <Cell />
              <Cell />
              {this.props.status !== 'PENDING' &&
                this.props.status !== 'CANCELLED' && <Cell />}
              <Cell className="total-amount text-right">Total</Cell>
              <Cell className="total-amount text-right">
                <small>
                  {this.props.currency.symbol} {totalMrp.toFixed(2)}
                </small>
              </Cell>
              <Cell className="total-amount text-right">
                <small>
                  {this.props.currency.symbol} {totalDiscount.toFixed(2)}
                </small>
              </Cell>
              <Cell className="total-amount text-right">
                {this.props.currency.symbol} {totalAmountCalculated.toFixed(2)}
              </Cell>
            </Row>
          )}
        </Table>
        {productsInOrder.length <= 0 ? (
          <div className="empty-table-message">
            {getMessage('order.details.itemsTable.clearingMessage')}
          </div>
        ) : null}
      </div>
    )
  }
}

ItemsView.defaultProps = {
  data: {
    items: [],
  },
}

export default ItemsView
