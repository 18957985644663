import React, { Component } from 'react'
import Image from '../../../../components/Image'
import ExtensionForm from '../Form'
import { TABLE_ACTIONS } from '../../../../containers/ListingPage'
import { Popup } from '../../../../components/Popup'
import { getMessage } from '../../../../lib/translator'
import { Link } from 'react-router-dom'
import { extensionRoutes } from '../../../../lib/auth'

class ExtensionTableRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      detailsPopup: false,
      quickActionPopup: false,
      refreshPrompt: false,
    }
    this.extensionRoutes = extensionRoutes
    this.hideDetailsPopup = this.hideDetailsPopup.bind(this)
    this.showDetailsPopup = this.showDetailsPopup.bind(this)
    this.hideQuickActionPopup = this.hideQuickActionPopup.bind(this)
    this.showQuickActionPopup = this.showQuickActionPopup.bind(this)
    this.showRefreshPrompt = this.showRefreshPrompt.bind(this)
    this.hideRefreshPrompt = this.hideRefreshPrompt.bind(this)
  }
  showDetailsPopup() {
    this.setState({
      detailsPopup: true,
    })
  }
  hideDetailsPopup() {
    this.setState({
      detailsPopup: false,
    })
  }
  hideQuickActionPopup() {
    this.setState({
      quickActionPopup: false,
    })
  }
  showQuickActionPopup() {
    this.setState({
      quickActionPopup: true,
    })
  }
  showRefreshPrompt() {
    this.setState({ refreshPrompt: true })
  }
  hideRefreshPrompt() {
    this.setState({ refreshPrompt: false })
  }
  render() {
    const _targetStatus = this.props.status === 'ENABLED' ? 'DISABLED' : 'ENABLED'
    const {
      id,
      icon,
      enableHelperText,
      disableHelperText,
      description,
      name,
      addedOn,
      pricing,
      status,
      version,
      lastUpdated,
      activeInstallations,
      slug,
      onAction,
      isConfigurable,
    } = this.props
    const configureable = status === 'ENABLED' && this.extensionRoutes[slug]
    return (
      <div className="extension-box">
        <div className="extension-details">
          <div className="extension-table-name">
            <div className="extension-table-image">
              <Image src={icon} className={!icon ? 'bordered' : ''} />
            </div>
            <div className="description">
              <div className="extension-action">
                {this.props.status === 'ENABLED' && (
                  <div className="extensions-page-with-popup">
                    <Popup
                      show={this.state.quickActionPopup}
                      close={this.hideQuickActionPopup}
                      className="editPopup extension-popup"
                    >
                      <ExtensionForm
                        value={{
                          id,
                          name,
                          pricing,
                          status,
                          icon,
                          version,
                          addedOn,
                          description,
                          lastUpdated,
                          activeInstallations,
                          slug,
                          onAction,
                        }}
                        details={disableHelperText}
                        className="editPopup extension-popup"
                        showExtra={false}
                        onCancel={this.hideQuickActionPopup}
                        onSubmit={() => {
                          this.hideQuickActionPopup()
                          this.props.onAction(
                            TABLE_ACTIONS.UPDATE,
                            { id },
                            { status: _targetStatus }
                          )
                        }}
                      />
                    </Popup>
                  </div>
                )}
                {this.props.status === 'DISABLED' && (
                  <div className="extensions-page-with-popup">
                    <Popup
                      show={this.state.quickActionPopup}
                      close={this.hideQuickActionPopup}
                      className="editPopup extension-popup"
                    >
                      <ExtensionForm
                        value={{
                          id,
                          name,
                          pricing,
                          status,
                          icon,
                          version,
                          addedOn,
                          description,
                          lastUpdated,
                          activeInstallations,
                          slug,
                          onAction,
                        }}
                        showExtra={false}
                        details={enableHelperText}
                        onCancel={this.hideQuickActionPopup}
                        onSubmit={() => {
                          this.hideQuickActionPopup()
                          this.props.onAction(
                            TABLE_ACTIONS.UPDATE,
                            { id },
                            { status: _targetStatus }
                          )
                        }}
                      />
                    </Popup>
                  </div>
                )}
              </div>
            </div>
            <div className="name" onClick={this.showQuickActionPopup}>
              <span>{name}</span>
              <div className="explanation">{description.split('. ', 1)[0]}</div>
            </div>
          </div>
          {pricing && (
            <div className="pricing">
              <div className="pricing-heading">
                {getMessage('extensions.price')}
              </div>
              <div className="pricing-description">
                <span>{pricing.split(' ')[0] + ' '}</span>
                {pricing.split(' ').length > 1 &&
                  pricing
                    .split(' ')
                    .slice(1)
                    .join(' ')}
              </div>
            </div>
          )}
        </div>
        <div className="extension-actions">
          <div className="extension-action">
            {this.props.status === 'ENABLED' && (
              <div className="extensions-page-with-popup">
                <Popup
                  show={this.state.detailsPopup}
                  close={this.hideDetailsPopup}
                  className="editPopup extension-popup"
                >
                  <ExtensionForm
                    value={{
                      id,
                      name,
                      pricing,
                      status,
                      icon,
                      version,
                      addedOn,
                      description,
                      lastUpdated,
                      activeInstallations,
                      slug,
                      onAction,
                    }}
                    details={disableHelperText}
                    showExtra
                    className="editPopup extension-popup"
                    onCancel={this.hideDetailsPopup}
                    onSubmit={() => {
                      this.hideDetailsPopup()
                      this.props
                        .onAction(
                          TABLE_ACTIONS.UPDATE,
                          { id },
                          { status: _targetStatus }
                        )
                        .then(this.showRefreshPrompt)
                    }}
                  />
                </Popup>
              </div>
            )}
            {this.props.status === 'DISABLED' && (
              <div className="extensions-page-with-popup">
                <Popup
                  show={this.state.detailsPopup}
                  close={this.hideDetailsPopup}
                  className="editPopup extension-popup"
                >
                  <ExtensionForm
                    value={{
                      id,
                      name,
                      pricing,
                      status,
                      icon,
                      version,
                      addedOn,
                      description,
                      lastUpdated,
                      activeInstallations,
                      slug,
                      onAction,
                    }}
                    details={enableHelperText}
                    onCancel={this.hideDetailsPopup}
                    showExtra
                    onSubmit={() => {
                      this.hideDetailsPopup()
                      this.props
                        .onAction(
                          TABLE_ACTIONS.UPDATE,
                          { id },
                          { status: _targetStatus }
                        )
                        .then(this.showRefreshPrompt)
                    }}
                  />
                </Popup>
              </div>
            )}
          </div>
          {status === 'ENABLED' ? (
            <div className="extension-buttons">
              <button
                type="button"
                id={id}
                className={status === 'DISABLED' ? 'primary' : ''}
                onClick={this.showDetailsPopup}
              >
                {' '}
                {status === 'ENABLED'
                  ? getMessage('extensions.uninstall')
                  : getMessage('extensions.install')}
              </button>
              {isConfigurable && slug !== 'DeliverySlots' ? (
                <Link className="extension-configure" to={`extensions/${id}`}>
                  {getMessage('extensions.configure')}
                </Link>
              ) : (
                ''
              )}
              {configureable ? (
                <Link className="extension-configure" to={configureable}>
                  {getMessage('extensions.configure')}
                </Link>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className="extension-buttons">
              {version ? (
                <span>
                  {getMessage('extensions.version')}: {version}
                </span>
              ) : (
                ''
              )}
              <button
                type="button"
                id={id}
                className={status === 'DISABLED' ? 'primary' : ''}
                onClick={this.showDetailsPopup}
              >
                {' '}
                {status === 'ENABLED'
                  ? getMessage('extensions.uninstall')
                  : getMessage('extensions.install')}
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const desktopView = {
  row: ExtensionTableRow,
}
export default desktopView
