import React, { Fragment } from 'react'
import { getMessage } from '../../lib/translator'

const Table = function (props) {
  const theadData = props.data.properties
  const tbodyData = props.data.tableData

  return (
    <div className="tabular-data">
      {props.data.header && <h4>{props.data.header}</h4>}
      <table key={`table-${props.data.index}`}>
        <thead className="table-header">
          <tr>
            {theadData.map((cell, index) => (
              <th key={`th-${props.data.index}-${index}`}>
                {getMessage(`qc.order.log.${cell}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-body">
          {tbodyData.map((stalls, index) => {
            const items = stalls.items.map((item, itemIdx) => {
              let stallRowsWithCust = []
              let stallRows = []
              if (item.type === 'ALL') {
                stallRowsWithCust = item.customisations.map(
                  (custumItem, csIdx) => {
                    const custStallRows = theadData.map((colName, idx) => {
                      return (
                        <td
                          key={`td-${props.data.index}-${index}-${idx}-${csIdx}`}
                          style={{
                            color: colName === 'name' ? '#8B9FA6' : '#333333;',
                          }}
                        >
                          {custumItem[colName]}
                        </td>
                      )
                    })
                    return (
                      <tr key={`tr-${index}-${itemIdx}-${csIdx}`}>
                        {custStallRows}
                      </tr>
                    )
                  }
                )
                const rows = theadData.map((colName, idx) => {
                  return (
                    <td key={`td-${props.data.index}-${index}-${idx}`}>
                      {item[colName]}
                    </td>
                  )
                })
                stallRows = <tr key={`tr-${index}-${itemIdx}`}>{rows}</tr>
              } else if (item.type === 'CUSTOMISATION_ONLY') {
                stallRowsWithCust = item.customisations.map(
                  (custumItem, csIdx) => {
                    const rows = theadData.map((colName, idx) => {
                      return (
                        <td
                          key={`td-${props.data.index}-${index}-${idx}-${csIdx}`}
                          style={{
                            color: colName === 'name' ? '#8B9FA6' : '#333333;',
                          }}
                        >
                          {custumItem[colName]}
                        </td>
                      )
                    })
                    return (
                      <tr key={`tr-${index}-${itemIdx}-${csIdx}`}>{rows}</tr>
                    )
                  }
                )
              } else if (item.type === 'MENU_ITEM_ONLY') {
                const rows = theadData.map((colName, idx) => {
                  return (
                    <td key={`td-${props.data.index}-${index}-${idx}`}>
                      {item[colName]}
                    </td>
                  )
                })
                stallRows = <tr key={`tr-${index}-${itemIdx}`}>{rows}</tr>
              }

              return (
                <Fragment key={`tr-${index}-${itemIdx}`}>
                  {stallRows}
                  {stallRowsWithCust}
                </Fragment>
              )
            })

            return (
              <Fragment key={`stalls-${index}`}>
                <tr>
                  <td className="table-stall-name" colSpan="3">
                    {stalls.name}
                  </td>
                </tr>
                {items}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table
