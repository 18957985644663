import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import {
  linkButtons,
  tableProperties,
} from '../../../components/Evoucher/utils'
import EvoucherForm from './Form'
import EvoucherFilters from './EvoucherFilters'
import './style.css'

const Evouchers = props => {
  return (
    <ListingPageWithRoutes
      {...props}
      testid="evoucher-listing"
      title="E-Vouchers"
      addHeading="Generate and Issue E-Voucher"
      editHeading="E-Vouchers"
      menu={props.menu}
      className="rewards-page"
      api={{
        url: '/batches',
        params: { ...props.params },
        transform: response => response && response.data.list,
      }}
      headerActions={linkButtons}
      form={{
        component: EvoucherForm,
      }}
      tableProperties={tableProperties()}
      viewHeading="E-Voucher Details"
      modalClassName="resend-popup"
      tableDynamic
      filters={{
        component: EvoucherFilters,
        forceShow: true,
      }}
    />
  )
}

export default Evouchers
