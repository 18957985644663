import React, { Component } from 'react'
import Image from 'components/Image'

import { getMessage } from '../../../../../lib/translator'
import { getReadableDateFromISO8601 } from '../../../../../lib/datetime'
import './style.css'
import { EVoucherStatus } from 'lib/status'

class PlacementDetails extends Component {
  constructor() {
    super()
    this.state = {
      errorMessage: '',
      loading: false,
    }
  }

  render() {
    let orderStatusText = null
    const status = this.props.data.status
    switch (this.props.data.status) {
      case EVoucherStatus.PENDING:
      case EVoucherStatus.COMPLETED:
        orderStatusText = (
          <span className={`evoucher-status-${status.toLowerCase()}`}>
            {getMessage(`order.status.${status.toLowerCase()}`)}
          </span>
        )
        break
      default:
        if (status) {
          orderStatusText = <span>{EVoucherStatus[status]}</span>
        }
        break
    }

    return this.props.isBankToBank ? (
      <div className="container" data-testid="PlacementDetails">
        <div className="row">
          <div className="col-6">
            <div className="section-pre-evoucher">
              <h3 className="section-title">Status: {orderStatusText}</h3>
            </div>

            <div className="evoucher-placement-info-item">
              <small className="text-muted">
                {getMessage('order.payment.paymentmode')}:{' '}
                <span className="creation-time">
                  {this.props.data.paymentMethod}
                </span>
              </small>
            </div>
            {this.props.data.completionTime && (
              <div className="evoucher-placement-info-item">
                <span className="evoucher-placement-info-icon icon-clock" />
                <small className="text-muted">
                  {getMessage(
                    'customerSupport.egifting.header.payment.submitted'
                  )}
                  :{' '}
                  <span className="completion-time">
                    {getReadableDateFromISO8601(this.props.data.completionTime)}
                  </span>
                </small>
              </div>
            )}
          </div>
          <div className="col-6">
            <div
              className="flex-end pointer"
              onClick={() => window.open(this.props.data.url, '_blank')}
            >
              <Image className="pending-image" src={this.props.data.url} />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        className="evoucher-placement-details"
        data-testid="PlacementDetails"
      >
        <div className="section-pre-evoucher">
          <h3 className="section-title">Status: {orderStatusText}</h3>
        </div>

        <h3 className="section-title no-margin-top">
          {getMessage('order.type')} :{' '}
          {this.props.data.isBusiness
            ? getMessage('order.business')
            : getMessage('order.personal')}
        </h3>

        <div className="evoucher-placement-info-item">
          <span className="evoucher-placement-info-icon icon-clock" />
          <small className="text-muted">
            Order Place On:{' '}
            <span className="creation-time">
              {getReadableDateFromISO8601(this.props.data.creationTime)}
            </span>
          </small>
        </div>
        {this.props.data.completionTime && (
          <div className="evoucher-placement-info-item">
            <span className="evoucher-placement-info-icon icon-clock" />
            <small className="text-muted">
              Completion Time On:{' '}
              <span className="completion-time">
                {getReadableDateFromISO8601(this.props.data.completionTime)}
              </span>
            </small>
          </div>
        )}
      </div>
    )
  }
}

export default PlacementDetails
