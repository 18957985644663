import React from 'react'
import { LINK_HOST } from 'config/app'
import API from 'lib/api'
import { formatIsoDate } from 'lib/datetime'

import { PaymentError } from '../JwcTab'
import { paymentErrorMsg } from '../service'
import '../style.css'

const InfoRow = ({title, info, showRow}) => {
  return (
    showRow && (
    <div className='info-row'>
      <div><b>{title}</b></div>
      <div className='info-details'>{info}</div>
    </div>)
  )
}

const getFreeTrialStatus = latestOrder => latestOrder.amount === 0;

const getAutoRenewalStatus = (membershipObj) => {
  return membershipObj.membership.isAutoRenewal ? 'ON' : 'OFF'
}

const getIsActive = (endDate, startDate, isFreeTrial, autoRenewal) => {
  const membershipState = new Date(endDate).getTime() >= new Date().getTime() &&
    new Date().getTime() >= new Date(startDate).getTime();
  if (isFreeTrial) {
    return membershipState
  }

  if (!isFreeTrial && !autoRenewal) {
    return false;
  }
  else {
    return membershipState
  }
}

const parseDcMembership = (membershipObj, latestOrder, paymentErrMsg, setShowButton) => {
  const endDate = membershipObj.membership.endDate;
  const startDate = membershipObj.membership.startDate;

  const getRefundStatus = latestOrder.status === 'REFUNDED' && latestOrder.type === 'REFUND' ?
    'Refunded' : 'N/A';
  const isFreeTrial = getFreeTrialStatus(latestOrder)
  const isActive = getIsActive(endDate, startDate, isFreeTrial, membershipObj.membership.isAutoRenewal)
  setShowButton(membershipObj.membership.isAutoRenewal)
  return {
    status: isActive ? 'Active' : 'Inactive',
    packageName: membershipObj.package.title,
    cancelledBy: latestOrder.cancelledBy ? latestOrder.cancelledBy : 'N/A',
    refundStatus: getRefundStatus,
    isFreeTrial: isActive ? isFreeTrial.toString() : null,
    autoRenewal: isActive ? getAutoRenewalStatus(membershipObj) : null,
    paymentError: paymentErrMsg,
    endDate: formatIsoDate(endDate),
    startDate: isActive ? formatIsoDate(membershipObj.membership.startDate) : null,
    id: membershipObj.membership.id,
    activeCancelledDc: isFreeTrial && !membershipObj.membership.isAutoRenewal ?
      `Cancelled free trial will end on ${formatIsoDate(endDate)}` : null
  }
}

const deleteDcMembership = async (membershipId, getDcFunc) => {
  const deleteMembershipApi = new API({
    url: `${LINK_HOST}/laksa/internal/memberships/${membershipId}/cancel`,
  })

  try {
    const deleteMembership = await deleteMembershipApi.put();
    if (deleteMembership.id) {
      // fetch new dc object
      getDcFunc();
    }
  } catch (e) {
    alert(e.message)
  }
}


const DigitalClub = ({ uid }) => {
  const [membership, setMembership] = React.useState({
    status: null,
    packageName: null,
    cancelledBy: null,
    refundStatus: null,
    isFreeTrial: null,
    autoRenewal: null,
    paymentError: null,
    endDate: null,
    startDate: null,
    activeCancelledDc: null
  })
  const [showButton, setShowButton] = React.useState(false)
  const getCustomerDcApi = new API({
    url: `${LINK_HOST}/laksa/internal/memberships?uid=${uid}&programmeId=2`,
  })

  const getDc = async () => {
    try {
      const dcObj = await getCustomerDcApi.get();
      const latestOrder = dcObj.orders[0];

      const getPaymentErrMsg = paymentErrorMsg(latestOrder.status, latestOrder.failureReason)
      const parsedMembership = parseDcMembership(dcObj, latestOrder, getPaymentErrMsg, setShowButton)
      setMembership(parsedMembership)
    } catch (e) {
      if (e.code === 404 && e.message === 'Requested resource is not found') {
        setMembership({...membership, status: 'Not a member'})
      }
    }
  }

  React.useEffect(() => {
    getDc();
  }, [])

  return (
    <React.Fragment>
      <div className="tab-wrapper">
        <div className='membership-information'>
          <InfoRow title={'Status'} info={membership.status} showRow={membership.status} />
          <InfoRow title={'Package Name'} info={membership.packageName} showRow={membership.packageName} />
          <InfoRow title={'Cancelled by'} info={membership.cancelledBy} showRow={membership.cancelledBy} />
          <InfoRow title={'Refund status'} info={membership.refundStatus} showRow={membership.refundStatus} />
          <InfoRow title={'Is free trial'} info={membership.isFreeTrial} showRow={membership.isFreeTrial} />
          <InfoRow title={'Auto renewal'} info={membership.autoRenewal} showRow={membership.autoRenewal} />
        </div>
        <div className='date-information'>
          <InfoRow title={'End Date'} info={membership.endDate} showRow={membership.endDate} />
          <InfoRow title={'Start Date'} info={membership.startDate} showRow={membership.startDate} />
        </div>
      </div>
      <PaymentError paymentError={membership.paymentError} />
      {membership.activeCancelledDc &&
      <>
        <hr className='cancel-membership-button-divider' />
        <div className="payment-wrapper">
          {membership.activeCancelledDc}
        </div>
      </>}
      <hr className='cancel-membership-button-divider' />
      {showButton && <button className='cancel-membership-button' onClick={() => deleteDcMembership(membership.id, getDc)}>
        Cancel membership
      </button>}
    </React.Fragment>
  )
}
export default DigitalClub
