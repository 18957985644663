const data = {
  'designations.empty.message': 'There are no designations',
  'designations.header.name': 'Name',
  'designations.header.timingType': 'Timing Type',
  'designations.header.timing': 'Timing',
  'designations.header.manager': 'Manager',
  'designations.header.actions': 'Actions',
  'designations.edit': 'Edit',
  'designations.view.employees': 'View employees',
  'designations.title': 'Designations',
  'designations.add': '+ Add Designation',
  'designations.clone': 'Clone designation',
  'designations.clone.placeholder.newName': 'Enter new designation name',
  'designations.form.name': 'Name',
  'designations.form.name.placeholder': 'Enter name',
  'designations.form.manager': 'Manager',
  'designations.form.manager.placeholder': 'Enter manager designation',
  'designations.form.timingtype': 'Timing type',
  'designations.form.timingtype.fixed': 'Fixed',
  'designations.form.timingtype.flexible': 'Flexible',
  'designations.form.save': 'Save',
  'designations.form.cancel': 'Cancel',
  'designations.delete': 'Delete',
  'designations.addheader': 'Add designation',
  'designations.editheader': 'Edit designation',
  'designations.form.submit': 'Submit',
  'designations.form.clearFiltersText': 'Clear All',
  'designations.form.roles': 'Mapped to roles',
  'designations.form.roles.placeholder': 'Enter roles',
  'designations.form.designationlevel': 'Designation level',
  'designations.form.designationlevel.placeholder': 'Select designation level',

  'attendance.label.notCheckedIn': 'Not Checked In',
  'attendance.label.noDesignation': 'No Designation',
  'attendance.summary.title': 'Attendance Summary',
  'attendance.summary.empty.message': 'No attendance detail found',
  'attendance.summary.table.employee.name': 'Employee Name',
  'attendance.summary.table.to': 'To',
  'attendance.summary.table.na': 'N/A',
  'attendance.summary.table.PRESENT': 'Present',
  'attendance.summary.table.WEEKLY_OFF': 'Weekly Off',
  'attendance.summary.table.ABSENT': 'Absent',
  'attendance.summary.table.HALFDAY': 'Halfday',
  'attendance.summary.filter.form.employee.name': 'Employee',
  'attendance.summary.filter.form.employee.placeholder':
    'Enter an employee name',

  'shifts.edit.heading': 'Edit Employee',
  'shifts.heading': 'Employees',
  'shifts.edit': 'Edit',
  'shifts.header.name': 'Name',
  'shifts.header.offdays': 'Weekly off',
  'shifts.header.offdaysAndStores': 'Weekly off / Stores',
  'shifts.header.timingsAndAttendance': 'Timings / Attendance',
  'shifts.header.lastLoggedIn': 'Last logged in',
  'shifts.header.blankHeading': ' ',
  'shifts.header.timings': 'Timings',
  'shifts.header.attendance': 'Attendance',
  'shifts.header.actions': 'Actions',
  'shifts.empty.message': 'There are currently no employees',
  'shifts.empty.submessage': 'Users can be seen once added',
  'shifts.designation': 'Designation',
  'shifts.select.designation': 'Select designation',
  'shifts.form.name': 'Name',
  'shifts.form.phone': 'Phone',
  'shifts.form.weekly.off': 'Weekly off',
  'shifts.form.startTime': 'Shift Start time',
  'shifts.form.endTime': 'Shift end time',
  'shifts.form.submitText': 'Save',
  'shifts.form.cancel': 'Cancel',
  'shifts.checkout': 'Check-out',
  'shifts.checkin': 'Check-in',
  'shifts.designation.placeholder': 'Enter designation',
  'shifts.filters.submitText': 'Submit',
  'shifts.filters.clearFiltersText': 'Clear All',
  'employee.add': 'Add Employee',
  'shifts.form.name.placeholder': 'Enter name',
  'shifts.phone.placeholder': 'Enter phone number',
  'shifts.form.email': 'Email',
  'shifts.form.email.placeholder': 'Enter email ID',
  'shifts.form.toolAccess': 'Allow employee to access the dashboard portal',
  'shifts.employee': 'Employee',
  'shifts.employee.placeholder': 'Enter name',
  'shifts.employee.enterPhone': 'Phone',
  'shifts.employee.searchByPhone': 'Search Phone',
  'shifts.stores': 'Store',
  'shifts.stores.placeholder': 'Select Store',
  'shifts.employee.enterEmail': 'Email',
  'shifts.employee.searchByEmail': 'Search Email ',
  'shifts.form.weekly.off.placeholder': 'Select weekly offs',
  'shifts.header.stores': 'Stores',
  'shifts.resetpassword': 'Reset password',
  'shifts.newPassword': 'New Password',
  'shifts.newPassword.placeholder': 'Enter new password',
  'shifts.reset.title': 'Reset password for',
  'shifts.reset.success.title': 'Success',
  'shifts.reset.success.information': 'Password has been successfully reset',
  'shifts.confirmpassword': 'COnfirm Password',
  'shifts.confirmpassword.placeholder': 'Enter the password again',
  'shifts.passwords.dont.match': 'Passwords do not match',
  'shifts.reset': 'Okay',
  'shifts.delete': 'Delete',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
  'Monday.all': ' All Mondays',
  'Tuesday.all': 'All Tuesdays',
  'Wednesday.all': 'All Wednesdays',
  'Thursday.all': 'All Thursdays',
  'Friday.all': 'All Fridays',
  'Saturday.all': 'All Saturdays',
  'Sunday.all': 'All Sundays',

  'role.add.heading': 'Add Role',
  'role.edit.heading': 'Edit Role',
  'role.heading': 'Roles',
  'role.add': 'Add Role',
  'role.edit': 'Edit',
  'role.name': 'Name',
  'role.action': 'Action',
  'role.submit': 'Submit',
  'role.cancel': 'Cancel',
  'role.name.placeholder': 'Enter name',
  'role.delete': 'Delete',
}

export default data
