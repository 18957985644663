import React, { Fragment } from 'react'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import { Popup, Dialog } from 'components/Popup'
import { BaseForm, VALIDATION_TYPES, Select, Checkbox } from 'components/Form'
import { SingleDatePicker } from 'components/Form/Inputs'
import { formatIsoDate } from '../../lib/datetime'
import calendarIcon from './calender-icon.svg'

const VOUCHER_STATUS = [
  {
    text: 'Disable',
    value: 'DISABLED',
  },
]

class SearchResults extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
      modalData: {},
      values: {},
    }
  }

  async updateFormHandler() {
    const values = Object.assign({}, this.state.values)
    values.response = ''
    values.error = ''
    this.setState({
      values: values,
    })
    const { newExpiryDate, newStatus } = this.state.values
    const data = {
      expiresAt: newExpiryDate,
      status: newStatus,
    }

    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    try {
      const api = new API({ url: `/vouchers/${this.props.response.code}` })
      const response = await api.patch(data)
      values.response = response
      values.updatePopup = false
      values.success = true
      this.setState({
        values: values,
      })
    } catch (error) {
      values.error = error.message || 'No results found.'
      this.setState({
        values: values,
      })
    }
  }

  handleCloseSuccessMessage() {
    const values = Object.assign({}, this.state.values)
    values.success = false
    this.setState({
      values: values,
    })
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    const values = Object.assign({}, this.state.values)
    values.updatePopup = true
    this.setState({
      values: values,
    })
  }

  renderSuccess() {
    return this.state.values.success ? (
      <div className="col-4 search-success" id="succcess-dialog">
        {getMessage('banners.success.dialog.message')}
        <strong
          data-testid="ok-strng-btn"
          onClick={() => this.handleCloseSuccessMessage()}
          className="strong-btn"
        >
          {getMessage('rewardPage.dialogs.disable.okText')}
        </strong>
      </div>
    ) : (
      <></>
    )
  }

  renderResponse() {
    const { SubmitButton, CancelButton } = this.buttons

    return (
      <Fragment>
        <div className="row status">
          <h3>
            Status:
            <span className={`${this.props.status} ml`}>
              {this.props.status}
            </span>
          </h3>
        </div>
        <div className="row mt">
          <div className="col-4">
            <label>Voucher Code:</label>
          </div>
          <div className="col-4">
            <label className="blue ">{this.props.response.code}</label>
          </div>
        </div>
        <div className="row mt">
          <div className="col-4">
            <label>Batch ID:</label>
          </div>
          <div className="col-4">
            <label className="blue ">{this.props.response.batchId}</label>
          </div>
        </div>
        <div className="row mt">
          <div className="col-4">
            <label>Entity Name:</label>
          </div>
          <div className="col-4">
            <label className="blue ">{this.props.response.entityName}</label>
          </div>
        </div>
        <div className="row mt">
          <div className="col-4">
            <label>Validity {<img src={calendarIcon} alt="calendar" />}:</label>
          </div>
          <div className="col-4">
            <label className="blue">
              {`${formatIsoDate(
                this.props.response.createdAt
              )} - ${formatIsoDate(this.props.response.expiresAt)}`}
            </label>
          </div>
        </div>
        {!this.props.response.cannotExtendPast && (
          <div className="row mt">
            <div className="col-4">
              <label>
                {getMessage('eVoucher.update.newexpiry')}{' '}
                {<img src={calendarIcon} alt="calendar" />}:
              </label>
            </div>
            <div className="col-4">
              <SingleDatePicker
                openDirection="up"
                enableToday={false}
                id="expiryPicker"
                name="expiryPicker"
                {...this.generateStateMappers({
                  stateKeys: ['newExpiryDate'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
            </div>
          </div>
        )}
        <div className="row mt">
          <div className="col-4">
            <label>Initial balance:</label>
          </div>
          <div className="col-4">
            <label>${this.props.response.initialBalance.toFixed(2)}</label>
          </div>
        </div>
        <div className="row mt">
          <div className="col-4">
            <label>Current balance: </label>
          </div>
          <div className="col-4">
            <label>${this.props.response.balance.toFixed(2)}</label>
          </div>
        </div>
        <div className="row mt">
          <div className="col-4">
            <label>{getMessage('eVoucher.update.status')} </label>
          </div>
          <div className="col-4">
            <Select
              name="status-selector"
              options={[
                this.props.response.status !== 'ENABLED' && {
                  text: 'Enable',
                  value: 'ENABLED',
                },
                ...VOUCHER_STATUS,
              ]}
              {...this.generateStateMappers({
                stateKeys: ['newStatus'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        </div>
        <div className="row mt">
          <div className="col-4">
            <label>{getMessage('eVoucher.create.allow')} </label>
          </div>
          <div className="col-4">
            <Checkbox
              name="cannotExtendPast"
              {...this.generateStateMappers({
                stateKeys: ['cannotExtendPast'],
                loseEmphasisOnFill: true,
              })}
              value={!this.props.response.cannotExtendPast}
              disabled
            />
          </div>
        </div>
        <div className="transaction-item">
          <div className="row">
            <div className="form-actions">
              <SubmitButton testid="confirm-submit">Submit</SubmitButton>
              <CancelButton testid="cancel-submit">Cancel</CancelButton>
            </div>
          </div>
        </div>
        <h3>Transaction history</h3>
        <div className="col-12 mb">
          {this.props.response.transactions.length === 0 && (
            <label className="empty-transactions">No transactions found</label>
          )}
          {this.props.response.transactions.map((res, i) => {
            const isRefund = res.type === 'REFUND'
            return (
              <div
                data-testid={`refund-btn-${i}`}
                key={i}
                className={`transaction-item ${
                  isRefund ? 'refund-pointer' : ''
                }`}
                onClick={() => {
                  this.setState({ modalData: res })
                  this.setState({ isModalOpen: true })
                }}
              >
                <div className="date">{formatIsoDate(res.createdAt)}</div>
                <div className="row">
                  <div className="store-name">{`${res.storeName} ${
                    isRefund ? '(Refund)' : ''
                  }`}</div>
                  <div className="amount">{`${
                    isRefund ? '+' : '-'
                  }${res.amount.toFixed(2)}`}</div>
                </div>
              </div>
            )
          })}
        </div>
      </Fragment>
    )
  }

  renderDialog = () => {
    return (
      <Dialog
        show={this.state.values.updatePopup}
        title={getMessage('deleteDialog.title')}
        information={getMessage('eVoucher.update.resultDetails')}
        onOk={() => this.updateFormHandler()}
        close={() => {
          const values = Object.assign({}, this.state.values)
          values.updatePopup = false
          this.setState({
            values: values,
          })
        }}
        closeText={getMessage('dialog.cancelText')}
        okText={getMessage('dialog.okText')}
      />
    )
  }

  render() {
    const { Form } = this.components

    return (
      <Form>
        {this.renderSuccess()}
        {this.renderDialog()}
        <div className="search-container contain mt">
          <div className="search-result-wrapper" data-testid="search-results">
            <Popup
              show={this.state.isModalOpen}
              heading={getMessage('eVoucher.refund.title')}
              close={() => this.setState({ isModalOpen: false })}
            >
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <h1>ID:</h1>
                  </div>
                  <div className="col-6">
                    <h1>{this.state.modalData.id}</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <h1>Merchant ID:</h1>
                  </div>
                  <div className="col-6">
                    <h1>{this.state.modalData.merchantId}</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <h1>Store Code:</h1>
                  </div>
                  <div className="col-6">
                    <h1>{this.state.modalData.storeCode}</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <h1>Order ID:</h1>
                  </div>
                  <div className="col-6">
                    <h1>{this.state.modalData.orderId}</h1>
                  </div>
                </div>
              </div>
            </Popup>
            {this.props.error && (
              <p className="text-center">{this.props.error}</p>
            )}
            {this.props.response && this.renderResponse()}
          </div>
        </div>
      </Form>
    )
  }
}

export default SearchResults
