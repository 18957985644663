import React from 'react'
import {
  BaseForm,
  Input,
  Phone,
  VALIDATION_TYPES,
} from '../../../components/Form'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import { withRouter } from 'react-router-dom'
import Loader from '../../../components/Loader'

class Signup extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values = {
      // states are name of form fields
      email: '',
      phone: '',
      name: '',
    }
    this.state.formError = ''
    this.state.loading = false
    this.handleSignup = this.handleSignup.bind(this)
  }

  handleSignup(formData) {
    this.api = new API({ url: '/account-service/me' })
    this.api
      .post(formData)
      .then(() => {
        this.props.history.push('/account-created')
      })
      .catch(e => {
        this.setState({
          submitting: false,
          formError: e.message && e.message.split(':')[1],
        })
      })
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    })
    this.handleSignup(data)
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    const api = new API({ url: '/account-service/country' })
    api.get().then(
      response => {
        this.isoCode = response.data.country.isoCode
        this.setState({
          loading: false,
        })
      },
      error => {
        this.setState({
          countryError: error,
        })
      }
    )
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    return this.state.loading ? (
      <Loader />
    ) : (
      <Form>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        <Input
          label={getMessage('signup.name.heading')}
          placeholder={getMessage('signup.name.placeholder')}
          name="name"
          type="text"
          required
          value={this.getState(['name'])}
          {...this.generateStateMappers({
            stateKeys: ['name'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
          validationStrings={{
            valueMissing: getMessage('input.requiredMessage'),
            typeMismatch: getMessage('input.invalidEmailFormat'),
          }}
        />

        <Input
          label={getMessage('signup.email.heading')}
          placeholder={getMessage('signup.email.placeholder')}
          name="email"
          type="email"
          required
          value={this.getState(['email'])}
          {...this.generateStateMappers({
            stateKeys: ['email'],
            validationType: VALIDATION_TYPES.ONCHANGE,
          })}
          validationStrings={{
            valueMissing: getMessage('input.requiredMessage'),
            typeMismatch: getMessage('input.invalidEmailFormat'),
          }}
        />

        <Phone
          country={this.isoCode}
          label={getMessage('signup.phone.heading')}
          placeholder={getMessage('signup.phone.placeholder')}
          name="phone"
          type="text"
          required
          value={this.getState(['phone'])}
          onChange={value => {
            this.updateState(['phone'], value)
          }}
        />

        <div className="form-buttons-container">
          <SubmitButton
            disabled={
              this.state.submitting ||
              !this.state.values.email ||
              !this.state.values.phone ||
              !this.state.values.name
            }
          >
            {getMessage('signup.submitText')}
          </SubmitButton>
        </div>
      </Form>
    )
  }
}

export default withRouter(Signup)
