import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { Row, Cell } from '../../../components/Table'
import EditForm from './Form'
import './style.css'
import { WORKING_DAYS, ROTATED_WORKING_DAYS } from './constants'
import { getStores } from '../../../lib/auth'
import API from '../../../lib/api'
import { getStoreName } from '../../../containers/StoreSelector'

import emptyIcon from './empty.svg'

const emptyState = {
  icon: emptyIcon,
  message: 'There are no sellers',
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      {`+ Add Sellers`}
    </button>
  ),
}

const sortBlockedDates = (BlockedDates = []) =>
  BlockedDates.filter(Boolean).sort()

/**
 * Convert working days to blocked days of work
 * Input: days: [{ [DAY]: boolean }]
 * Output: blockedDow: [1, 2, ... 7] // Sunday 1, Monday 2, ... Saturday 7
 **/
const convertWorkingDaysToBlockedDow = (days = {}) =>
  WORKING_DAYS.map((key, index) => (days[key] ? null : index + 1))
    .filter(Boolean)
    .map(num => (num + 1 > 7 ? (num + 1) % 7 : num + 1))

/**
 * Convert blocked days of work to working days
 * Input: blockedDow: [1, 2, ... 7] // Sunday 1, Monday 2, ... Saturday 7
 * Output: days: [{ [DAY]: boolean }]
 **/
var convertBlockedDowToWorkingDays = blockedDow =>
  ROTATED_WORKING_DAYS.reduce((obj, day, index) => {
    return {
      ...obj,
      [day]: !blockedDow.includes(index + 1 > 7 ? (index + 1) % 7 : index + 1),
    }
  }, {})

var parseSellerStores = sellerStores =>
  Object.keys(sellerStores).reduce((obj, key) => {
    obj.storeId = obj.storeId || []
    obj.status = obj.status || []
    obj.transporters = obj.transporters || []
    obj.transporterOffDays = obj.transporterOffDays || []
    obj.reportSignature = obj.reportSignature || []
    const timings = sellerStores[key].timing.reduce((acc, timingObj) => {
      acc[`cutOffTimes.${key}`] = acc[`cutOffTimes.${key}`] || []
      acc[`handlingHours.${key}`] = acc[`handlingHours.${key}`] || []
      acc[`pickupLeadTime.${key}`] = acc[`pickupLeadTime.${key}`] || []

      acc[`cutOffTimes.${key}`] = acc[`cutOffTimes.${key}`].concat(
        `${timingObj.order_cutoff}:00:01`
      )
      acc[`handlingHours.${key}`] = acc[`handlingHours.${key}`].concat(
        timingObj.handling_hours
      )
      acc[`pickupLeadTime.${key}`] = acc[`pickupLeadTime.${key}`].concat(
        timingObj.pickup_lead_time
      )
      return acc
    }, {})
    const splitReports =
      sellerStores[key].timing && sellerStores[key].timing.length === 1
        ? sellerStores[key].timing[0].split_reports || []
        : []
    return {
      ...obj,
      storeId: obj.storeId.concat(sellerStores[key].storeID),
      status: obj.status.concat(sellerStores[key].status === 'ENABLED'),
      [`cutOffTimesCount.${key}`]: sellerStores[key].timing
        ? sellerStores[key].timing.length
        : 0,
      ...timings,
      reportSignature: obj.reportSignature.concat(
        !!(sellerStores[key].config || {}).reportSignature
      ),
      [`splitReportFlag.${key}`]: splitReports.length >= 1,
      [`stockDistribution.${key}`]: sellerStores[key].config
        ? sellerStores[key].config.stockDistribution
        : void 0,
      transporters: obj.transporters.concat(sellerStores[key].transporterID),
      transporterOffDays: obj.transporterOffDays.concat([
        sellerStores[key].transporterOffDays,
      ]),
      [`packingTypePrimary.${key}`]: sellerStores[key].packingType.primary,
      [`packingTypeSKU.${key}`]: sellerStores[key].packingType.others.includes('SKU'),
      [`packingTypeOrderNoLabel.${key}`]: sellerStores[key].packingType.others.includes('ORDER-NO-LABEL'),
    }
  }, {})

function getNearestCutOff(timing = []) {
  if (timing.length === 0) {
    return ''
  }

  let cutOffIndex = timing.length
  for (let i = 0; i < timing.length; i++) {
    if (moment().isSameOrBefore(moment({ hour: timing[i].order_cutoff }))) {
      cutOffIndex = i
      break
    }
  }

  if (cutOffIndex < timing.length) {
    return timing[cutOffIndex].order_cutoff + timing[cutOffIndex].handling_hours
  } else {
    return 24 + timing[0].order_cutoff + timing[0].handling_hours
  }
}

function getNearestOrderSlot(nearestCutOff) {
  const days = Math.floor(nearestCutOff / 24)
  const hours = nearestCutOff % 24
  const nearestOrderDate = moment()
    .add(days, 'days')
    .format('YYYY-MM-DD')
  const time = moment({ hour: hours }).format('HH:mm:ss')
  return `${nearestOrderDate} ${time}`
}

/*
 * Given cut-off time (z), get [z-8, z-4, z] at where split reports to be generated.
 * Ex: cutoff = 22 => [14, 18, 22]
 * may flow into previous day
 */
function generateSplitReports(splitZ) {
  const splitY = splitZ - 4
  const y = splitY > 0 ? splitY : (splitY + 24) % 24
  const splitX = splitY - 4
  const x = splitX > 0 ? splitX : (splitX + 24) % 24

  return [x, y, splitZ]
}

export function populateSellerStores({
  storeId,
  status,
  VendorCode,
  reportSignature,
  transporters,
  transporterOffDays,
  ...rest
}) {
  const modifiedSellerStores = (storeId || []).reduce((acc, strId, index) => {
    const sellerStore = rest.sellerStores ? rest.sellerStores[strId] || {} : {}
    const splitReportFlag = !!rest[`splitReportFlag.${strId}`]
    const timing = rest[`cutOffTimes.${strId}`].map((cutoff, index) => ({
      order_cutoff: Number(cutoff.split(':')[0]),
      handling_hours: Number(rest[`handlingHours.${strId}`][index]),
      pickup_lead_time: rest[`pickupLeadTime.${strId}`]
        ? rest[`pickupLeadTime.${strId}`][index]
        : 0,
    }))

    if (splitReportFlag && timing.length === 1) {
      if (timing[0].handling_hours >= 8) {
        timing[0].split_reports = generateSplitReports(timing[0].order_cutoff)
      }
    }

    sellerStore.packingType = {
      primary: rest[`packingTypePrimary.${strId}`],
      others: [
        rest[`packingTypeSKU.${strId}`] ? 'SKU' : '',
        rest[`packingTypeOrderNoLabel.${strId}`] ? 'ORDER-NO-LABEL' : '',
      ].filter((s) => Boolean(s)),
    }

    const nearestCutOff = getNearestCutOff(timing)
    const nearestOrderSlot =
      sellerStore['orderSlot'] || getNearestOrderSlot(nearestCutOff)
    let config = sellerStore.config

    if (reportSignature && reportSignature[index]) {
      config = config
        ? {
            ...config,
            reportSignature: true,
          }
        : {
            reportSignature: true,
          }
    }

    if (rest[`stockDistribution.${strId}`]) {
      config = config
        ? {
            ...config,
            stockDistribution: rest[`stockDistribution.${strId}`],
          }
        : {
            stockDistribution: rest[`stockDistribution.${strId}`],
          }
    }

    acc[strId] = {
      ...sellerStore,
      storeID: strId,
      status: status && status[index] ? 'ENABLED' : 'DISABLED',
      vendorCode: VendorCode,
      timing: timing,
      orderSlot: nearestOrderSlot,
      isDeleted: false,
      config,
    }
    if (transporters && transporters.length) {
      acc[strId].transporterID = transporters[index]
    }
    if (transporterOffDays && transporterOffDays.length) {
      acc[strId].transporterOffDays = transporterOffDays[index]
    }
    return acc
  }, {})
  const deletedSellerStores = Object.keys(rest.sellerStores || {}).reduce(
    (acc, strId) => {
      if (!storeId.includes(Number(strId))) {
        const sellerStore = rest.sellerStores[strId] || {}
        acc[strId] = {
          ...sellerStore,
          isDeleted: true,
        }
        return acc
      }
      return acc
    },
    {}
  )

  return {
    ...modifiedSellerStores,
    ...deletedSellerStores,
  }
}

const tableProperties = () => {
  return {
    headers: [
      'Vendor Code',
      'Seller Name',
      'Handling Time',
      'Inventory Report',
      'Logo',
      'Recipient List',
    ],
    row: ({
      VendorCode,
      Name,
      HandlingDays,
      SendInventoryReport,
      logo,
      Emails,
    }) => (
      <Row>
        <Cell className="seller-name">
          <div className="mobile-view-header">Vendor Code</div>
          <span className="">
            <Link
              to={`/marketplace/sellers/edit/${VendorCode}`}
              className="seller-name"
            >
              {VendorCode}
            </Link>
          </span>
        </Cell>
        <Cell className="seller-name">
          <div className="mobile-view-header">Seller Name</div>
          <span className="">
            <Link
              to={`/marketplace/sellers/edit/${VendorCode}`}
              className="seller-name"
            >
              {Name}
            </Link>
          </span>
        </Cell>
        <Cell className="seller-handlingdays">
          <div className="mobile-view-header">Handling Time</div>
          <span>{HandlingDays}</span>
        </Cell>
        <Cell className="seller-inventory">
        <div className="mobile-view-header">Inventory Report</div>
        <span>
          {SendInventoryReport ? 'Y' : 'N'}
          </span>
        </Cell>
        <Cell className="seller-logo">
          <div className="mobile-view-header">Logo</div>
          <span>{logo && logo !== '' ? 'Y' : 'N'}</span>
        </Cell>
        <Cell className="seller-emails">
          <div className="mobile-view-header">Recipient List</div>
          <span>{Emails && Array.isArray(Emails) && Emails.join(', ')}</span>
        </Cell>
      </Row>
    ),
  }
}

export default class Sellers extends Component {
  componentDidMount() {
    const stores = (getStores() || []).map(store => ({
      ...store,
      name: getStoreName(store),
    }))
    this.setState({ stores })

    const api = new API({
      url: '/seller-service/transporters',
    })

    api.get().then((response = {}) => {
      this.setState({
        transporters: response.data ? response.data.transporters : [],
      })
    })
  }

  render() {
    const props = this.props
    return (
      <ListingPageWithRoutes
        menu={props.menu}
        className="seller-page"
        addHeading="Add Sellers"
        editHeading="Edit Sellers"
        title="Sellers"
        api={{
          url: '/seller-service/sellers',
          transform: response =>
            response.data && response.data.sellers
              ? response.data.sellers
              : response,
        }}
        showLanguageDropDown
        emptyState={emptyState}
        tableProperties={tableProperties()}
        headerActions={({ onAction }) => (
          <Link to="/marketplace/sellers/add">
            <button
              className="primary button"
              onClick={() => {
                onAction(TABLE_ACTIONS.ADD)
              }}
            >
              + Add
            </button>
          </Link>
        )}
        primaryKey="VendorCode"
        form={{
          component: EditForm,
          options: {
            stores: this.state && this.state.stores,
            transporters: this.state && this.state.transporters,
          },
          allowDelete: false,
          overwriteWithApiParams: true,
          transformSubmit: data => {
            return {
              ...data,
              Category: data.Category
                ? data.Category
                : {
                    name: data.CategoryName,
                  },
              Emails:
                typeof data.Emails === 'string'
                  ? data.Emails.split(',')
                      .map(e => e.trim())
                      .filter(Boolean)
                  : data.Emails,
              BlockedDOW: convertWorkingDaysToBlockedDow(data.WorkingDays),
              BlockedDates: sortBlockedDates(data.BlockedDates || []),
              sellerStores: populateSellerStores(data),
            }
          },
          transformResponse: response => {
            if (response && response.data && response.data.sellers) {
              const { BlockedDOW } = response.data.sellers[0]
              let WorkingDays = {}
              if (Array.isArray(BlockedDOW) && BlockedDOW.length) {
                WorkingDays = convertBlockedDowToWorkingDays(BlockedDOW)
              } else {
                WorkingDays = WORKING_DAYS.reduce((obj, day) => {
                  return { ...obj, [day]: true }
                }, {})
              }
              const { BlockedDates = [] } = response.data.sellers[0]
              const { sellerStores } = response.data.sellers[0]

              return {
                ...response.data.sellers[0],
                WorkingDays,
                BlockedDates: BlockedDates,
                ...parseSellerStores(sellerStores || {}),
              }
            }
          },
        }}
      />
    )
  }
}
