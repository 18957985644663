import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { tableProperties } from '../../../components/Rewards/Carousels/utils'
import { LinkButtons, emptyState } from '../../../components/Rewards/utils'
import '../style.css'
import './carousels.css'
import CarouselForm from './Form'
import { getMessage } from 'lib/translator'

const Carousels = props => {
  return (
    <ListingPageWithRoutes
      testid="carousels-listing"
      title={getMessage('rewards.carousels.title')}
      addHeading={getMessage('rewards.carousel.add')}
      editHeading={getMessage('rewards.carousel.edit')}
      menu={props.menu}
      className="rewards-page carousels"
      api={{
        url: '/rms/carousels',
        transform: response => response && response.data.list,
      }}
      headerActions={() => <LinkButtons type="carousel" />}
      form={{
        component: CarouselForm,
      }}
      emptyState={emptyState('carousel')}
      tableProperties={tableProperties}
      tableDynamic
    />
  )
}

export default Carousels
