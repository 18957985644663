import React, { Fragment, useEffect, useState } from 'react'
import API from '../../../../../../lib/api'
import { Checkbox, Input } from '../../../../../../components/Form'
import '../style.css'
import Loader from '../../../../../../components/Loader'
import moment from 'moment'
import { getMessage } from '../../../../../../lib/translator'
import { Dialog } from '../../../../../../components/Popup'

const OptIn = ({ uid }) => {
  const [optIn, setOptIn] = useState(true)
  const [optOutDate, setOptOutDate] = useState([])
  const [loading, setLoading] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [showSuccessDialog, setShowSuccessDialog] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    async function fetchData() {
      try {
        const api = new API({
          url: `/duck/opt-out-customers/${uid}`,
        })
        await api.get().then(response => {
          const formatCreatedAt = moment(response.createdAt).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          setOptOutDate([formatCreatedAt])
          setOptIn(false)
          setLoading(false)
        })
      } catch (e) {
        setLoading(false)
      }
    }
    fetchData()
  }, [submit])

  const handleOptOut = () => {
    try {
      const api = new API({
        url: `/duck/opt-out-customers`,
      })
      const data = { uid: uid }
      api
        .post(data)
        .then(() => setShowSuccessDialog(true))
        .then(() => setSubmit(prev => !prev))
    } catch (e) {
      setError(e.message || getMessage('customer.details.rewards.serverError'))
    }
  }

  const handleOptIn = () => {
    try {
      const api = new API({
        url: `/duck/opt-out-customers/${uid}`,
      })
      api
        .delete()
        .then(() => setShowSuccessDialog(true))
        .then(() => setOptOutDate([]))
        .then(() => setSubmit(prev => !prev))
    } catch (e) {
      setError(e.message || getMessage('customer.details.rewards.serverError'))
    }
  }

  const changeOptions = () => {
    setOptIn(prevOpt => !prevOpt)
  }

  return (
    <Fragment>
      {loading && <Loader size="sm" />}
      {error && (
        <div className="error" data-testid="error">
          {error}
        </div>
      )}
      <Dialog
        show={showSuccessDialog}
        className="success"
        title={getMessage('customer.entityMetaData.successTitle')}
        information={getMessage('customer.entityMetaData.successInformation')}
        close={() => setShowSuccessDialog(false)}
        closeText={getMessage('customer.entityMetaData.ok')}
      />
      <div className="col-6 mt">
        {!loading && (
          <Checkbox
            inlineLabel={getMessage('customer.details.luckyDraws.optIn')}
            name="LuckyDrawOptIn"
            testid="luckydraw-option"
            onChange={changeOptions}
            value={optIn}
          />
        )}
        {optOutDate.length > 0 && (
          <Input
            label={getMessage('customer.details.luckyDraws.optOut')}
            className="optOut mt-1"
            testid="luckydraw-optout-date"
            readOnly={true}
            value={optOutDate[0]}
          />
        )}
        <button
          type="button"
          className="btn-primary mt"
          data-testid="submit"
          onClick={optIn ? handleOptIn : handleOptOut}
        >
          {getMessage('customer.entityMetaData.submit')}
        </button>
      </div>
    </Fragment>
  )
}

export default OptIn
