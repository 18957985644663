import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import BlogForm from './Form'
import { Row, Cell } from '../../../components/Table'
import { importText, exportText } from '../../../components/RichEditor'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { Dialog } from '../../../components/Popup'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'

import './style.css'
import emptyIcon from './blogs-empty.svg'

class BlogActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statusAction: null,
    }
    this.showStatusActionDialog = this.showStatusActionDialog.bind(this)
    this.hideStatusActionDialog = this.hideStatusActionDialog.bind(this)
  }
  showStatusActionDialog(statusAction) {
    this.setState({ statusAction })
  }
  hideStatusActionDialog() {
    this.setState({ statusAction: null })
  }
  UNSAFE_componentWillReceiveProps() {
    this.setState({ statusAction: false })
  }
  setPublishStatus(isDraft) {
    const id = this.props.id
    const api = new API({ url: `/blog-service/blog/${id}` })
    const now = new Date()
    const publishTime = isDraft
      ? null
      : `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
          2,
          '0'
        )}-${String(now.getDate()).padStart(2, '0')} ${
          now.toTimeString().split(' ')[0]
        }`
    return api.put({ isDraft, publishTime }).then(() => {
      this.props.onAction(
        TABLE_ACTIONS.REFRESH,
        { id },
        { isDraft, publishTime }
      )
    }, console.error)
  }
  render() {
    const { id, isDraft } = this.props
    return (
      <div>
        <Dialog
          show={this.state.statusAction}
          title={
            isDraft
              ? getMessage('marketing.blog.dialogs.publish.title')
              : getMessage('marketing.blog.dialogs.unpublish.title')
          }
          information={
            isDraft
              ? getMessage('marketing.blog.dialogs.publish.message')
              : getMessage('marketing.blog.dialogs.unpublish.message')
          }
          onOk={() => {
            this.props.isDraft
              ? this.setPublishStatus(false)
              : this.setPublishStatus(true)
          }}
          close={this.hideStatusActionDialog}
          closeText={getMessage('marketing.blog.dialogs.publish.cancelText')}
          okText={
            isDraft
              ? getMessage('marketing.blog.dialogs.publish.okText')
              : getMessage('marketing.blog.dialogs.unpublish.okText')
          }
        />
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/marketing/blog/edit/${id}`}>
              {getMessage('marketing.blog.table.action.edit')}
            </Link>
          </DropDownItem>
          <DropDownItem>
            <div
              onClick={() => {
                this.showStatusActionDialog(id)
              }}
            >
              {isDraft
                ? getMessage('marketing.blog.table.action.publish')
                : getMessage('marketing.blog.table.action.unpublish')}
            </div>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              this.props.onAction(TABLE_ACTIONS.DELETE, { id })
            }}
          >
            {getMessage('marketing.blog.table.action.delete')}
          </DropDownItem>
        </DropDown>
      </div>
    )
  }
}

function AddBlog() {
  return (
    <div className="text-center">
      <Link to="/marketing/blog/add" className="primary button">
        <span />+{' '}
        <span className="text">{getMessage('marketing.blog.add.text')}</span>
      </Link>
    </div>
  )
}

const emptyState = {
  icon: emptyIcon,
  message: getMessage('marketing.blog.helpItems.message'),
  additionalViews: [AddBlog],
}

const tableProperties = {
  headers: [
    getMessage('marketing.table.heading.title'),
    getMessage('marketing.table.heading.publishTime'),
    getMessage('marketing.table.heading.author'),
    // getMessage('marketing.table.heading.interaction'),
    getMessage('marketing.table.heading.actions'),
  ],
  row: ({ id, title, publishTime, author, isDraft, onAction }) => (
    <Row>
      <Cell>
        <Link to={`/marketing/blog/edit/${id}`} className="blog-title-link">
          {title}
        </Link>
      </Cell>
      <Cell>
        {isDraft ? (
          <small className="status-draft">
            {getMessage('marketing.blog.table.status.draft')}
          </small>
        ) : (
          <div>
            <div className="text-muted">
              <small className="publish-date">
                {publishTime ? publishTime.split(' ')[0] : null}
              </small>
            </div>
            <div className="text-muted">
              <small className="publish-time">
                {publishTime ? publishTime.split(' ')[1] : null}
              </small>
            </div>
          </div>
        )}
      </Cell>
      <Cell>{author ? author.name : null}</Cell>
      <Cell>
        <BlogActions onAction={onAction} id={id} isDraft={isDraft} />
      </Cell>
    </Row>
  ),
}

export default function Blog(props) {
  return (
    <ListingPageWithRoutes
      menu={props.menu}
      className="blogs-page"
      title={getMessage('marketing.blog.heading')}
      addHeading={getMessage('marketing.form.add.heading')}
      editHeading={getMessage('marketing.form.edit.heading')}
      emptyState={emptyState}
      headerActions={AddBlog}
      api={{
        url: '/blog-service/blog',
        transform: response => response.data.blog,
      }}
      form={{
        component: BlogForm,
        transformSubmit: formData =>
          Object.assign({}, formData, {
            content: exportText(formData.content),
          }),
        transformResponse: response =>
          Object.assign({}, response.data.blog, {
            content: importText(response.data.blog.content),
          }),
      }}
      tableProperties={tableProperties}
    />
  )
}
