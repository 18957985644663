import React from 'react'
import './CategorizedStoreSelector.css'
import PropTypes from 'prop-types'
import tagIcon from '../../icons/tag.svg'
import deleteIcon from '../../icons/delete.svg'
import AutocompleInput from '../autocomplete/AutocompleteInput'

export default function CategorizedStoreSelector({
  onUpdate,
  stores = [],
  values = [],
  validateEmpty,
  allowedCategories,
}) {
  const categorizedStores = React.useMemo(
    () =>
      stores
        .reduce((categories, store) => {
          const isOfflineStore = store.hasSelfCheckout

          const allOfflineStores = categories.find(
            category => category.category === ALL_OFFLINE_STORES
          )

          if (isOfflineStore && allOfflineStores) {
            allOfflineStores.stores.push(store.id)
          }

          const isSngStores =
            isOfflineStore &&
            store.metaData &&
            (store.metaData['Is SnG Store'] ||
              store.metaData[IS_SNG_STORE_CODE])

          const sngStores = categories.find(
            category => category.category === SNG_STORES
          )

          if (isSngStores && sngStores) {
            sngStores.stores.push(store.id)
          }

          const isNonSngStores =
            isOfflineStore &&
            store.metaData &&
            !store.metaData['Is SnG Store'] &&
            !store.metaData[IS_SNG_STORE_CODE]

          const nonSngStores = categories.find(
            category => category.category === NON_SNG_STORES
          )

          if (isNonSngStores && nonSngStores) {
            nonSngStores.stores.push(store.id)
          }

          const isFFSStores = store.name.toLowerCase().includes('(ffs)')
          const isDS = store.name.toLowerCase().includes('(ds)')
          const isPFC = store.name.toLowerCase().includes(' pfc')
          const isPreOrderStores = store.hasSelfCheckout && store.status === "ENABLED" && store.metaData?.["Search And Browse Enabled"]

          const preOrderStores = categories.find(
            category => category.category === ALL_PREORDER_STORES
          )

          if(isPreOrderStores && preOrderStores) {
            preOrderStores.stores.push(store.id)
          }

          const onlineStores = categories.find(
            category => category.category === FAIRPRICE_ONLINE
          )

          if ((isFFSStores || isDS || isPFC) && onlineStores) {
            onlineStores.stores.push(store.id)
          }

          const ffsStores = categories.find(
            category => category.category === FAIRPRICE_FFS
          )

          if (isFFSStores && ffsStores) {
            ffsStores.stores.push(store.id)
          }

          const isStoreHasStoreFormat = isOfflineStore && store.metaData

          if (isStoreHasStoreFormat) {
            let storeFormat

            if (store.metaData['Store Format']) {
              storeFormat = categories.find(
                category => category.category === store.metaData['Store Format']
              )
            } else if (store.metaData[STORE_FORMAT_CODE]) {
              storeFormat = categories.find(
                category =>
                  category.category === store.metaData[STORE_FORMAT_CODE]
              )
            }

            if (storeFormat) {
              storeFormat.stores.push(store.id)
            }
          }

          return categories
        }, defaultCategorizedStores(allowedCategories))
        .filter(category => category.stores.length),
    [stores]
  )

  const selectedStores = stores
    .filter(store => values.some(value => value === store.id))
    .map(store => {
      const tags = categorizedStores.reduce((acc, curr) => {
        if (curr.stores.some(id => id === store.id)) {
          if (curr.tag) {
            acc.push(curr.tag)
          }
        }
        return acc
      }, [])

      return {
        ...store,
        tags,
      }
    })

  const addStoresFromCategory = (e, category) => {
    e.preventDefault()
    const newStoresToAdd = category.stores.filter(
      store => !values.some(existing => existing === store)
    )

    onUpdate([...values, ...newStoresToAdd])
  }

  const onAutoCompleteSelect = option => {
    if (!values.some(value => value === option.value)) {
      onUpdate([...values, option.value])
    }
  }

  const hasValidationEmptyError = values.length === 0 && validateEmpty

  return (
    <div className="store-category-container">
      <div className="store-category-list">
        {categorizedStores.map(category => {
          return (
            <button
              className="store-category-btn"
              key={category.category}
              onClick={e => addStoresFromCategory(e, category)}
            >
              + {category.label}
            </button>
          )
        })}
      </div>
      <AutocompleInput
        options={stores.map(opt => ({
          label: opt.name,
          value: opt.id,
        }))}
        placeholder="Select the store"
        onSelect={onAutoCompleteSelect}
        hasError={hasValidationEmptyError}
      />
      {hasValidationEmptyError ? (
        <small style={{ color: 'red', paddingTop: '0.5rem' }}>
          This field is required
        </small>
      ) : null}
      {selectedStores.length ? (
        <SelectedList
          items={selectedStores}
          onDelete={id => {
            onUpdate(values.filter(value => value !== id))
          }}
        />
      ) : null}
      {values.length > 0 ? (
        <div
          role="button"
          className="remove-all-btn"
          onClick={() => onUpdate([])}
        >
          Remove All ({selectedStores.length}) stores
        </div>
      ) : null}
    </div>
  )
}

function SelectedList({ items, onDelete }) {
  return (
    <ul
      style={{ display: 'flex', flexDirection: 'column' }}
      className="selected-list"
    >
      {items.map(store => {
        return (
          <li key={store.id} className="selected-list__item">
            <div className="selected-list__label">
              <img src={tagIcon} alt="Tag icon" className="tag-icon" />
              <small>{store.name}</small>
              {store.tags.map((tag, i) => (
                <span key={tag + i} className="store-category-tag">
                  {tag}
                </span>
              ))}
            </div>
            <div className="delete-icon__container">
              <img
                src={deleteIcon}
                alt={`Delete ${store.name} icon`}
                className="delete-icon"
                onClick={() => onDelete(store.id)}
              />
            </div>
          </li>
        )
      })}
    </ul>
  )
}

SelectedList.propTypes = {
  items: PropTypes.array,
  onDelete: PropTypes.func,
}

export const STORE_FORMAT_CODE = '37'
export const IS_SNG_STORE_CODE = '126'

CategorizedStoreSelector.propTypes = {
  onUpdate: PropTypes.func,
  values: PropTypes.array,
  stores: PropTypes.array,
  validateEmpty: PropTypes.bool,
  allowedCategories: PropTypes.array,
}

export const ALL_OFFLINE_STORES = 'All Offline Stores'
export const SNG_STORES = 'S&G Stores'
export const NON_SNG_STORES = 'Non S&G Stores'
export const FAIRPRICE = 'FairPrice'
export const FAIRPRICE_EXTRA = 'FairPrice Xtra'
export const FAIRPRICE_FINEST = 'FairPrice Finest'
export const FAIRPRICE_HYPER = 'FairPrice Hyper'
export const FAIRPRICE_ONLINE = 'Online Stores'
export const FAIRPRICE_FFS = 'FFS Stores'
export const ALL_PREORDER_STORES = 'All Pre-Order Stores'

export const defaultCategorizedStores = allowedCategories => {
  const defaultCategoriesList = [
    {
      category: ALL_OFFLINE_STORES,
      tag: null,
      label: ALL_OFFLINE_STORES,
      stores: [],
    },
    {
      category: SNG_STORES,
      tag: 'S&G',
      label: 'S&G',
      stores: [],
    },
    {
      category: NON_SNG_STORES,
      tag: null,
      label: 'Non-S&G',
      stores: [],
    },
    {
      category: FAIRPRICE,
      tag: 'Super',
      label: 'Super',
      stores: [],
    },
    {
      category: FAIRPRICE_EXTRA,
      tag: 'Xtra',
      label: 'Xtra',
      stores: [],
    },
    {
      category: FAIRPRICE_FINEST,
      tag: 'Finest',
      label: 'Finest',
      stores: [],
    },
    {
      category: FAIRPRICE_HYPER,
      tag: 'Hyper',
      label: 'Hyper',
      stores: [],
    },
    {
      category: FAIRPRICE_ONLINE,
      tag: 'OG Stores',
      label: 'OG Stores',
      stores: [],
    },
    {
      category: FAIRPRICE_FFS,
      tag: 'All FFS',
      label: 'All FFS',
      stores: [],
    },
    {
      category: ALL_PREORDER_STORES,
      tag: 'All Pre-Order Stores',
      label: 'All Pre-Order Stores',
      stores: [],
    },
  ]

  if (allowedCategories && allowedCategories.length) {
    return defaultCategoriesList.filter(category =>
      allowedCategories.some(
        allowedCategory => allowedCategory === category.category
      )
    )
  }

  return defaultCategoriesList
}
