import React from 'react';
import { VALIDATION_TYPES, SingleDatePicker, TimePicker, Input } from 'components/Form';
import { getMessage } from 'lib/translator';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as BinIcon } from 'icons/delete.svg';

function OfferSchedulesInput({ formValues, generateStateMappers, addOfferSlot, removeOfferSlotByIndex }) {
  return(
    <>
      <div className="offer-slots-container">
      <p className='offer-slots-title'>{getMessage('offer.campaigns.form.offerSchedules.title')}</p>
      {
        formValues?.schedules?.length > 0 && formValues.schedules.map((offerSlot, index) => (
          <div className="single-offer-slot" key={`offer-slot-${offerSlot.id}`}>
            <SingleDatePicker
              name="scheduleStartDate"
              required
              label={getMessage('offer.campaigns.form.offerSchedules.startDate')}
              allowAllDates
              {...generateStateMappers({
                stateKeys: ['schedules', index, 'startDate'],
                loseEmphasisOnFill: true,
              })}
            />
            <SingleDatePicker
              name="scheduleEndDate"
              required
              label={getMessage('offer.campaigns.form.offerSchedules.endDate')}
              allowAllDates
              {...generateStateMappers({
                stateKeys: ['schedules', index, 'endDate'],
                loseEmphasisOnFill: true,
              })}
            />
            <TimePicker
              name="scheduleStartTime"
              label={getMessage('offer.campaigns.form.offerSchedules.startTime')}
              required
              placeholder="time"
              {...generateStateMappers({
                stateKeys: ['schedules', index, 'startTime'],
                loseEmphasisOnFill: true,
              })}
            />
            <TimePicker
              name="scheduleEndTime"
              label={getMessage('offer.campaigns.form.offerSchedules.endTime')}
              required
              placeholder="time"
              {...generateStateMappers({
                stateKeys: ['schedules', index, 'endTime'],
                loseEmphasisOnFill: true,
              })}
            />
            <Input
              type="text"
              placeholder={getMessage("offer.campaigns.form.offerSchedules.offerIds.placeholder")}
              name="scheduleOfferIds"
              label={getMessage("offer.campaigns.form.offerSchedules.offerIds")}
              required
              {...generateStateMappers({
                stateKeys: ['schedules', index, 'offers'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
            <div className="delete-icon">
              {
                formValues.schedules.length !==1 &&
                <BinIcon width={16} height={16} onClick={() => removeOfferSlotByIndex(index)} />
              }
            </div>
          </div>
        ))
      }
      <button
        className="button primary add-offer-slot-btn"
        onClick={addOfferSlot}>
          <PlusIcon />
          {getMessage('offer.campaigns.form.addSchedule')}
      </button>
      </div>
    </>
  )
}

export default OfferSchedulesInput;
