import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../Table'
import { DropDown, DropDownItem, ICONS } from '../../DropDown'
import { getMessage } from 'lib/translator'

export const tableProperties = {
  headers: ['CLS Merchant ID', 'Name', 'Actions'],
  row: ({ clsMerchantID, name }) => (
    <Row>
      <Cell className="rewards-page-actions column-id">
        <Link to={`/rewards/merchants/edit/${clsMerchantID}`}>
          {clsMerchantID}
        </Link>
      </Cell>
      <Cell className="rewards-page-actions column-name">
        <Link to={`/rewards/merchants/edit/${clsMerchantID}`}>{name}</Link>
      </Cell>
      <Cell className="rewards-page-actions column-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <Link to={`/rewards/merchants/edit/${clsMerchantID}`}>
            <DropDownItem>{getMessage('rewards.merchant.edit')}</DropDownItem>
          </Link>
        </DropDown>
      </Cell>
    </Row>
  ),
}
