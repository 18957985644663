import React, { Component } from 'react'
import fpLogo from './FP_logo.png'
import './style.css'

export default class EazyHeader extends Component {
  render() {
    return (
      <header className="eazy-header">
        <div>
          <div className="wrap">
            <div>
              <a className="logo-link">
                <img
                  className="logo"
                  style={{ height: '40px' }}
                  src={fpLogo}
                  alt="FPGP Logo"
                />
              </a>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
