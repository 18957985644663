/**
 *
 * @param {*} formData
 * @returns
 */
export const transformFilter = formData => {
  const data = Object.assign({}, formData)
  const dates = data.dates
  if (dates) {
    data.from = dates.startDate ?? undefined
    data.to = dates.endDate ?? undefined
  }
  delete data.dates
  return data
}
