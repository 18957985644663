import './style.css'
import React from 'react'
import Module from './Module'
import { PAGE } from './utils'
import { DndProvider } from 'react-dnd'
import update from 'immutability-helper'
import { Motion, spring } from 'react-motion'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Draggable from '../Draggable'

const Container = ({
  view,
  items,
  setView,
  setPopUp,
  moduleValues,
  setModuleValues,
  setActiveModule,
  toggleModuleStatus,
}) => {
  const moveCard = (dragIndex, hoverIndex) => {
    setModuleValues((prev) =>
      update(Object.values(prev), {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, Object.values(prev)[dragIndex]],
        ],
      }).reduce((accumulator, item, index) => {
        accumulator[index] = {
          ...item,
          mPosition: moduleValues[index].mPosition,
        }
        return accumulator
      }, {})
    )
    setView(PAGE[2])
  }

  return (
    <div className="draggable-modules">
      {items.map((item, index) => (
        <Motion
          key={moduleValues[item].id}
          style={{
            y: spring(index, { stiffness: 500, damping: 10 }),
          }}
        >
          {({ y }) => (
            <Draggable
              index={index}
              axis="vertical"
              moveCard={moveCard}
              style={{
                transform: `translate3d(0, ${y}px, 0)`,
              }}
            >
              <Module
                view={view}
                index={index}
                setPopUp={setPopUp}
                module={moduleValues[item]}
                setActiveModule={setActiveModule}
                toggleModuleStatus={toggleModuleStatus}
              />
            </Draggable>
          )}
        </Motion>
      ))}
    </div>
  )
}

const ModuleList = ({
  view,
  setView,
  setPopUp,
  moduleValues,
  setActiveModule,
  setModuleValues,
  toggleModuleStatus,
}) => {
  return (
    <>
      {Object.keys(moduleValues).length > 0 && (
        <DndProvider backend={HTML5Backend}>
          <Container
            view={view}
            setView={setView}
            setPopUp={setPopUp}
            moduleValues={moduleValues}
            items={Object.keys(moduleValues)}
            setActiveModule={setActiveModule}
            setModuleValues={setModuleValues}
            toggleModuleStatus={toggleModuleStatus}
          />
        </DndProvider>
      )}
    </>
  )
}

export default ModuleList
