import React, {useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from 'icons/search.svg';
import { Input } from 'components/Form';
import { DropDown, DropDownItem, ICONS } from 'components/DropDown';
import CommonMapComponent from 'pages/settings/DeliveryArea/CommonMapComponent';
import { getMessage } from 'lib/translator';
import { slotRuleTypes, AddRuleOptionsEnum, distinctColors } from './constants';
import Loader from 'components/Loader';
import { get } from 'lib/storage';
import API from 'lib/api';
import { ReactComponent as CloseIcon } from 'icons/close-icon-grey.svg';
import { useDebounce } from 'lib/hooks';

// memo is used to optimize perfomance of map component. To avoid unnecessary re-renders.
const MemorizedMapComponent = React.memo(CommonMapComponent)

function MapAndRulesList ({
  deliveryAreaData,
  rulesData,
  deleteRule,
  editRule,
  isLoading,
  setRules,
  refetchRules,
  colorsMappedToRules,
  setDeliveryAreaData,
  postalCodeCoordinates,
  hasEditPermission,
  totalRuleCount,
  setPageNum,
  hasNextPage,
  disableEditStoreRule
}) {
  const [searchRuleValue, setSearchRuleValue] = useState('');
  const [mapInfoBoxData, setMapInfoBoxData] = useState(null);
  const [selectedRuleId, setSelectedRuleId] = useState(null);

  const debouncedSearchValue = useDebounce(searchRuleValue, 500);

  const searchRuleValueRef = useRef(null);

  useEffect(() => {
    if (debouncedSearchValue === '' && searchRuleValueRef.current) {
      refetchRules();
    }
    searchRuleValueRef.current = debouncedSearchValue;
  }, [debouncedSearchValue])

  const showPinsOnMap = (metadata) => {
    // if new selected rule is currently selected rule, do not add the pins
    const ruleFound = deliveryAreaData.find(x => x.id === metadata.ruleId);
    if (!ruleFound) {
      const filteredAreas = deliveryAreaData.filter(x => x.configType !== 'PINCODE')
      const withSelectedPostalCodeRule = [
        ...filteredAreas,
        {
          id: metadata.ruleId,
          tempId: metadata.ruleId,
          configType: 'PINCODE',
          name: null,
          metadata: {
            ruleName: metadata.ruleName,
            blockedSlots: metadata.blockedSlot,
            slotRuleType: metadata.slotRuleType,
            postalCodes: metadata.postalCode || []
          },
          area: {
            pincodes: metadata.postalCode ? metadata.postalCode.map(postalCode => (
              { pincode: postalCode, coordinate: postalCodeCoordinates[postalCode] }
            )) : []
          },
        }
      ]
      setDeliveryAreaData(withSelectedPostalCodeRule);
    }
  }

  const handleMapInfoBoxData = (metadata) => {
    if (mapInfoBoxData) {
      setSelectedRuleId(null);
    }

    if (metadata) {
      const blockedSlotsData = new Map();
      metadata.blockedSlot.forEach(dayTimeSlot => {
        const [day, timeSlot] = dayTimeSlot.split('|');
        const currentSlots = blockedSlotsData.get(day);
        currentSlots ? blockedSlotsData.set(day, currentSlots.concat(timeSlot)) : blockedSlotsData.set(day, [timeSlot])
      })

      const blockedSlotsArr = [];
      for (const [key, value] of blockedSlotsData) {
        blockedSlotsArr.push({
          day: key,
          slots: value.join(', ')
        })
      }

      setMapInfoBoxData({
        ruleId: metadata.ruleId,
        name: metadata.ruleName,
        blockedSlots: blockedSlotsArr,
        slotRuleType: metadata.slotRuleType,
        postalCodes: metadata?.postalCode || [],
        zoneName: metadata?.zoneName,
      });
      setSelectedRuleId(metadata.ruleId);
      if (metadata.slotRuleType === slotRuleTypes.BY_POSTAL_CODE) {
        // if it is postal code rule, show associated pins on the map
        showPinsOnMap(metadata);
      } else {
        // clear pins on the map
        setDeliveryAreaData(deliveryAreaData.filter(x => x.configType !== 'PINCODE'));
      }
    } else {
      // clear pins on the map
      setDeliveryAreaData(deliveryAreaData.filter(x => x.configType !== 'PINCODE'));
    }
  }

  const relayClickRuleAction = (ruleId) => {
    const selectedRule = rulesData.find(x => x.id === ruleId)
    handleMapInfoBoxData({
      ...selectedRule.metadata,
      slotRuleType: selectedRule.slotRuleType,
      ruleName: selectedRule.name,
      zoneName: selectedRule?.zone?.name || '',
      ruleId
    })
  }


  useEffect(() => {
    function performSearch(searchedValue) {
      const params = {
        page: 1,
        pageSize: 20,
        orderType: 'DELIVERY',
        storeId: get('store'),
        name: searchedValue
      }
      const searchApi = new API({ url: `/logistics-management-service/v1/rules` });
      searchApi.get(params)
        .then(res => {
          setRules(res.data.items);
        })
        .catch(e => {
          console.log(e)
        })
    }

    if (debouncedSearchValue) {
      performSearch(debouncedSearchValue);
    }
  }, [debouncedSearchValue])

  const handleRuleSearchInput = (value) => {
    setSearchRuleValue(value);
  }

  const handleClearSearch = () => {
    setSearchRuleValue('');
  }

  const disableEditButton = (rule) => rule.slotRuleType === slotRuleTypes.BY_STORE && disableEditStoreRule;

  return (
    <div className='delivery-area-content'>
      <div className='map-area'>
        <MemorizedMapComponent
          deliveryArea={deliveryAreaData || []}
          mapInfoBoxData={mapInfoBoxData}
          distinctColors={distinctColors}
          handleMapInfoBoxData={handleMapInfoBoxData}
          setMapInfoBoxData={setMapInfoBoxData}
          selectedRuleId={selectedRuleId}
          colorsMappedToRules={colorsMappedToRules} />
      </div>
      <div className='rules-container'>
        <Input
          type="text"
          placeholder="Search rules"
          name="searchRuleName"
          value={searchRuleValue}
          onChange={handleRuleSearchInput}
          onIconSuffixClick={handleClearSearch}
          iconSuffix={searchRuleValue ? CloseIcon : SearchIcon}
        />
        <div className='title'>
          {getMessage('operations.timeSlotBlocking.allRules')}
          <span className='rules-count'> (Showing {rulesData.length} of {totalRuleCount} Rules)</span>
        </div>
        <ul className='rules-list'>
        {
          rulesData.length > 0 ?
          rulesData.map(rule => (
            <li className='single-rule' key={rule.id} onClick={() => relayClickRuleAction(rule.id)}>
              <div className={`rule-name ${rule.id === selectedRuleId ? 'selected' : ''}`}>
                <span style={{backgroundColor: colorsMappedToRules[rule.id].value}} />
                {rule.name}
              </div>
              <div>
                {
                  hasEditPermission &&
                  <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
                    {
                      rule.slotRuleType === slotRuleTypes.IS_CLUSTER ?
                      <>
                        <DropDownItem onClick={() => editRule(rule, AddRuleOptionsEnum.BY_MAP)}>
                          {getMessage('operations.timeSlotBlocking.editRule.byMap')}
                        </DropDownItem>
                        <DropDownItem onClick={() => editRule(rule, AddRuleOptionsEnum.BY_KML_FILE)}>
                          {getMessage('operations.timeSlotBlocking.editRule.byKmlFile')}
                        </DropDownItem>
                        <div className='divider'></div>
                      </>
                      : <DropDownItem className={disableEditButton(rule) ? 'disabled' : ''}
                        onClick={() => rule.slotRuleType === slotRuleTypes.BY_POSTAL_CODE ? editRule(rule, AddRuleOptionsEnum.BY_POSTAL_CODE) : editRule(rule, AddRuleOptionsEnum.BY_STORE)}>
                        {getMessage('operations.timeSlotBlocking.editRule.edit')}
                      </DropDownItem>
                    }
                    <DropDownItem className={disableEditButton(rule) ? 'disabled' : ''} onClick={() => deleteRule(rule.id, rule.name)}>
                      {getMessage('operations.timeSlotBlocking.deleteRule.delete')}
                    </DropDownItem>
                  </DropDown>
                }
              </div>
            </li>
          )) :
          debouncedSearchValue ?
          <div className='no-rules'>{getMessage('operations.timeSlotBlocking.noRules.found')}</div> :
          <div className='no-rules'>{getMessage('operations.timeSlotBlocking.noRules')}</div>
        }
        {
          (hasNextPage && !isLoading && !debouncedSearchValue) &&
          <li className='load-more'>
            <a onClick={() => setPageNum(prev => Math.abs(prev) + 1)}>{getMessage('operations.timeSlotBlocking.rulesList.loadMore')}</a>
          </li>
        }
        { isLoading && <Loader />}
        </ul>

      </div>
    </div>
  )
}

export default MapAndRulesList;
