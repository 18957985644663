import React from 'react'

export default function StoreBlock(props) {

  const { storeInfo } = props;

  return(
    <div className='asset-summary'>
      <h3>Store Information</h3>
      <div className='store-info'>
        <div className='store-info-item'>
          <small className='text-muted'>ID: <span className='black'>{storeInfo?.id}</span></small>
        </div>
        <div className='store-info-item'>
          <small className='text-muted'>Name: <span className='black'>{storeInfo?.storeLabel}</span></small>
        </div>
        <div className='store-info-item'>
          <small className='text-muted'>Business Unit: <span className='black'>{storeInfo?.businessUnit}</span></small>
        </div>
        <div className='store-info-item'>
          <small className='text-muted'>Description: <span className='black'>{storeInfo?.storeDescription}</span></small>
        </div>
        <div className='store-info-item'>
          <small className='text-muted'>Operation Hour: <span className='black'>{storeInfo?.storeOpen}~{storeInfo?.storeClosed}</span></small>
        </div>
        <div className='store-info-item'>
          <small className='text-muted'>Location: <span className='black'>{storeInfo?.storeLocation}</span></small>
        </div>
      </div>
    </div>
  )
}
