import React, { Component } from 'react'
import { getMessage } from '../../../../lib/translator'

export default class Table extends Component {

    getCellData(row, cell, itemChanges, i) {
        return isNaN(row[cell])
            ? cell === 'status'
                ? row[cell] &&
                row[cell].charAt(0) + row[cell].slice(1).toLowerCase()
                : row[cell]
            : row[cell] % 1 !== 0 ||
                (itemChanges && (i === 1 || i === 2))
                ? Number(row[cell]).toFixed(2)
                : row[cell]
    }

    render() {
        const {
            data
        } = this.props
        const theadData = data.properties
        const tbodyData = data.tableData
        const { itemChanges } = data
        return (
            <div className="tabular-data">
                {data.header && <h4>{data.header}</h4>}
                <table key={`table-${data.index}`}>
                    <thead className="table-header">
                        <tr>
                            {theadData.map((cell, index) => (
                                <th key={`th-${data.index}-${index}`}>
                                    {getMessage(`order.log.${cell}`)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {tbodyData.map((row, index) => (
                            <tr key={`tr-${data.index}-${index}`}>
                                {theadData.map((cell, i) => {
                                    if (cell === 'reason' && row.metaData && row.metaData.reason) {
                                        return (
                                            <td key={`td-${data.index}-${index}-${i}`}>
                                                {row.metaData[cell]}
                                            </td>
                                        )
                                    }
                                    return (
                                        <td key={`td-${data.index}-${index}-${i}`}>
                                            {this.getCellData(row, cell, itemChanges, i)}
                                        </td>
                                    )
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}
