import React, { Fragment } from 'react'
import Table, { Row, Cell } from '../../../../../../components/Table'
import { getMessage } from '../../../../../../lib/translator'

const StallDiscount = props => {
  return (
    <Fragment>
      <Table className="qc-stall-discount-table table-dynamic">
        <Row className="qc-stall-discount-row" key={`qc-stall-discount-row`}>
          <Cell className="">
            <b>{getMessage('qc.orderSummary.table.StallDiscount')}</b>
          </Cell>
          <Cell>
            <b>-{props.formattedDiscounts}</b>
          </Cell>
        </Row>
      </Table>
      <Table className="qc-stall-discount-table-items table-dynamic">
        {props.discountDetails.map(discount => (
          <Row
            className="qc-stall-discount-row-item"
            key={`qc-stall-discount-row`}
          >
            <Cell className="qc-order-summary-grey">
              {discount.displayName}
            </Cell>
            <Cell>{discount.formattedAmount}</Cell>
          </Row>
        ))}
      </Table>
    </Fragment>
  )
}

export default StallDiscount
