import React, { useEffect, useMemo, useState } from 'react'
import { Text, View, GiftIcon, Button } from '../ui'
import { OrderDetailCard } from '../components/OrderDetailCard'
import { MarkAsPaid } from '../components/MarkAsPaid'
import { MarkAsCollected } from '../components/MarkAsCollected'
import { truncate } from 'lodash'
import { useField, useFormikContext } from 'formik'
import API from 'lib/api'
import {
  errorToast,
  generateQRFromPLU,
  getOrderSummary,
  getPreOrderStatus,
  htmlToPreorderPdf,
  isGracePeriodOver,
  parseStoreMetaData,
  groupCart,
  isOfflinePreorder,
} from '../helper'
import { PREORDER_STATUSES } from '../constant'
import { PREORDER_API } from '../../../../config/app'
import { PreorderInvoice } from '../components/PreorderInvoice'
import moment from 'moment'
import { useConfigMetaData } from 'pages/settings/Store/StoreConfiguration'

const SERVER_FORMAT_DATE = 'YYYY-MM-DD HH:mm:ss'
export const ViewSingleOrder = ({ toHome }) => {
  const { values, setFieldValue } = useFormikContext()
  const [field] = useField('scanOrderId')
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState(false)
  const [disallowMarkAsPaid, setDisallowMarkAsPaid] = useState(true)
  const [qrImages, setQRImages] = useState([])
  const { loading: isLoadingMetaData, data: metaData } = useConfigMetaData()

  const parsedMetaData = parseStoreMetaData(values.storeId, metaData)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    function fetchLeadTime(collectionEndTime, campaignId) {
      return new Promise((resolve, reject) => {
        new API({
          url: `${PREORDER_API}/campaigns/${campaignId}`,
        })
          .get()
          .then((resp) => {
            if (resp.code === 200 && resp.data) {
              const graceDays = resp.data.lead_time
              setDisallowMarkAsPaid(
                isGracePeriodOver(graceDays, collectionEndTime, new Date())
              )
            }
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    async function fetchOrder(orderId) {
      setIsFetching(true)
      setFieldValue('orderDetailData', undefined)
      try {
        const orderResponse = await new API({
          url: `/order-service/v3/deliveryOrders/${orderId}`,
        }).get()

        const orderData = orderResponse.deliveryOrder

        const slotEndTime = orderData.slotEndTime
        await fetchLeadTime(slotEndTime, orderData.campaignId)

        setFieldValue('orderDetailData', {
          ...orderData,
          items: groupCart(
            orderData.items,
            'item.clientItemId',
            'orderedQuantity'
          ),
        })
        setIsFetching(false)
      } catch (err) {
        errorToast('ORDER_FETCH_ERROR', 'Something went wrong...')
        toHome()
        setError(err.message)
      }
    }

    fetchOrder(field.value)
  }, [field.value])

  const { orderDetailData } = values

  const totalOrderedSkus = useMemo(() => {
    if (orderDetailData) {
      let count = 0
      const items = orderDetailData.items
      if (items && items.length > 0) {
        items.forEach(({ orderedQuantity }) => {
          count += Number(orderedQuantity)
        })
      }
      return count
    }
    return 0
  }, [orderDetailData])

  const showMarkAsPaid = Boolean(
    isOfflinePreorder(orderDetailData) &&
      getPreOrderStatus(orderDetailData) === PREORDER_STATUSES.PENDING_PAYMENT
  )
  const isCompleted = Boolean(
    orderDetailData &&
      getPreOrderStatus(orderDetailData) === PREORDER_STATUSES.COLLECTED
  )

  const showReadyForCollection = Boolean(
    orderDetailData &&
      getPreOrderStatus(orderDetailData) ===
        PREORDER_STATUSES.READY_FOR_COLLECTION
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const pickupData = useMemo(() => {
    if (!orderDetailData) {
      return {}
    }
    const pickupDateObj = moment(
      orderDetailData.slotStartTime,
      SERVER_FORMAT_DATE
    )

    const pickupDate = pickupDateObj.format('YYYY-MM-DD').toString()

    const startPickupTime = pickupDateObj.format('ha')

    const endPickupTime = moment(
      orderDetailData.slotEndTime,
      SERVER_FORMAT_DATE
    ).format('ha')

    return {
      pickupDate,
      startPickupTime,
      endPickupTime,
    }
  }, [orderDetailData])

  const generateQR = async (orderId) => {
    const allPLU = orderDetailData.items.map(
      ({ item, orderedQuantity }) => `${orderedQuantity}x${item.clientItemId}`
    )
    const result = await generateQRFromPLU(orderId, allPLU)
    setQRImages(result)
  }
  const preparePrint = () => {
    setIsFetching(true)
    const input = document.getElementById('preorderInvoice')
    const totalItems = orderDetailData.items.concat(orderDetailData.freeItems)
    const totalItemHeight = totalItems.reduce((total, item) => {
      const lineSpan = Math.ceil(item.item.name.length / 16) // 1 line can have 16 characters
      return total + lineSpan * 18 // 1 line is 18px tall
    }, 0)

    htmlToPreorderPdf({
      orderId: orderDetailData.referenceNumber,
      input,
      height: 1340 + totalItemHeight + qrImages.length * 300,
    }).finally(() => setIsFetching(false))
  }

  useEffect(() => {
    if (orderDetailData) {
      generateQR(orderDetailData.referenceNumber)
    }
  }, [orderDetailData])

  if (isFetching || isLoadingMetaData) {
    return (
      <View direction="column" alignItems="center" justifyContent="center">
        Loading...
      </View>
    )
  } else if (error) {
    return (
      <View direction="column" alignItems="center" justifyContent="center">
        <Text color="red">Unable to fetch order detail:</Text>
        <Text color="red">{error}</Text>
      </View>
    )
  } else if (!error && orderDetailData) {
    return (
      <View direction="column" alignItems="start">
        <Text fontWeight={700} fontSize={20}>
          Order details
        </Text>
        <View gap={32} justifyContent="start" margin="16px 0 0">
          <Text data-testid="total-qty" fontWeight={700}>
            {Number(totalOrderedSkus)} items
          </Text>
          <Text data-testid="foc-qty" fontWeight={700}>
            <GiftIcon />
            {orderDetailData.freeItems.length ?? 0} free gift
          </Text>
        </View>
        <OrderDetailCard order={getOrderSummary(orderDetailData)} />
        <Text fontWeight={700} fontSize={20} margin="16px 0 8px">
          Items ordered
        </Text>
        <View
          display="flex"
          direction="column"
          padding="0 0px 100px 0px"
          minHeight="0"
        >
          {orderDetailData.items
            .concat(orderDetailData.freeItems)
            .map((product, i) => {
              const { item } = product

              return (
                <View
                  data-testid={`item-${item.clientItemId}-card`}
                  key={item.itemId}
                  padding="8px 0"
                  display="flex"
                  direction="flex-row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  style={{ borderTop: '1px solid rgba(234, 234, 234, 1)' }}
                  height={80}
                >
                  <View
                    width={200}
                    height={'100%'}
                    display="flex"
                    direction="flex-row"
                    alignItems="flex-start"
                  >
                    <Text width={20} fontWeight={700} textAlign="left">
                      {i + 1}
                    </Text>
                    <View direction="column" alignItems="start" width={200}>
                      <Text data-testid="item-name" fontWeight={700}>
                        {truncate(item.name, { length: 20 })}
                      </Text>
                      <Text data-testid="sku" fontSize={12}>
                        SKU {item.clientItemId}
                      </Text>
                      <Text data-testid="qty" fontSize={12}>
                        {product.orderedQuantity} items
                      </Text>
                    </View>
                  </View>
                  {product.mrp > 0 && (
                    <Text data-testid="amount">
                      $
                      {(
                        (product.mrp - product.discount) *
                        product.orderedQuantity
                      ).toFixed(2)}
                    </Text>
                  )}
                </View>
              )
            })}
        </View>
        <View
          direction="column"
          position="fixed"
          data-name="fixedButton"
          background="white"
          style={{
            bottom: '12px',
            left: 0,
            background: 'white',
          }}
        >
          <View direction="column" padding="0 0 12px" gap={10}>
            {(showMarkAsPaid || showReadyForCollection) && (
              <Button
                disabled={showMarkAsPaid && disallowMarkAsPaid}
                onClick={() => setIsModalOpen(true)}
              >
                {showMarkAsPaid && 'Mark as paid'}
                {showReadyForCollection && 'Mark as collected'}
              </Button>
            )}
            {!isCompleted && (
              <>
                <PreorderInvoice
                  order={orderDetailData}
                  cartItems={orderDetailData.items.concat(
                    orderDetailData.freeItems
                  )}
                  qrSources={qrImages}
                  otherCartDetails={{
                    customerName: orderDetailData.customer.name,
                    customerPhone: orderDetailData.customer.phone.replace(
                      // to match with 1st print
                      '+65',
                      ''
                    ),
                    pickupDate: pickupData.pickupDate,
                    pickupTime: `${pickupData.startPickupTime}-${pickupData.endPickupTime}`,
                    staffId: values.staffId,
                    storePhone: parsedMetaData.Phone,
                    storeName: orderDetailData.store.name,
                    storeAddress: orderDetailData.store.address,
                  }}
                />
                <Button variant="secondary" onClick={preparePrint}>
                  Print receipt QR
                </Button>
              </>
            )}
          </View>
        </View>
        {isModalOpen && (
          <>
            {showMarkAsPaid && (
              <MarkAsPaid
                isOpen={isModalOpen}
                toggleModal={(opts) => {
                  setIsModalOpen(false)
                  if (opts && opts.toHome) {
                    toHome()
                  }
                }}
              />
            )}
            {showReadyForCollection && (
              <MarkAsCollected
                isOpen={isModalOpen}
                toggleModal={(opts) => {
                  setIsModalOpen(false)
                  if (opts && opts.toHome) {
                    toHome()
                  }
                }}
              />
            )}
          </>
        )}
      </View>
    )
  } else {
    return null
  }
}
