import React from 'react'
import { getMessage } from '../../../../../lib/translator'
import Table, { Row, Cell, Header } from '../../../../../components/Table'
const parameterCheck = linkPoint => {
  return linkPoint ? linkPoint : '___'
}
const LinkPointDetails = props => {
  const headers = [
    getMessage('qc.paymentDetails.table.header.Amount'),
    getMessage('qc.linkPointDetails.table.header.VirtualCard'),
    getMessage('qc.linkPointDetails.table.header.AwardedLinkPoints'),
    getMessage('qc.linkPointDetails.table.header.LinkPointsRedeemed'),
  ]
  return (
    <div
      className="qc-container linkpoint-details-container"
      data-testid="linkpoint-details-container"
    >
      <div className="qc-header border-bottom">
        <h2>{getMessage('qc.linkPointDetails.Heading')}</h2>
      </div>

      <Table tableDynamic={true}>
        <Header items={headers} />
        {[props.data.meta].map(linkPoint => {
          return (
            <Row className="suborder-items" key={`qc-item-link-points`}>
              <Cell>
                <div>
                  {parameterCheck(linkPoint.formattedEarnedLinkPointsAmount)}
                </div>
              </Cell>
              <Cell>
                <div>{parameterCheck(props.data.customer.vc)}</div>
              </Cell>
              <Cell>
                <div>{parameterCheck(linkPoint.earnedLinkPoints)}</div>
              </Cell>
              <Cell>
                <div>{parameterCheck(linkPoint.redeemedLinkPoints)}</div>
              </Cell>
            </Row>
          )
        })}
      </Table>
    </div>
  )
}

export { parameterCheck }

export default LinkPointDetails
