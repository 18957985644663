import React, { Component } from 'react'
import { Input } from 'components/Form';
import '../style.css'

class EditPacklistItem extends Component {
    render() {
        const {
            item,
            itemIndex,
            packlistIndex,
            isSoldByWeight,
        } = this.props

        return (
            <small>
              <Input
                type="number"
                name="edit-packed-quantity"
                testid="edit-packed-quantity-input"
                value={item.packedQuantity}
                min={0}
                onChange={(value) => this.props.onEditItemQuantity(packlistIndex, itemIndex, value, isSoldByWeight)}
              />
            </small>
        )
    }
}

export default EditPacklistItem
