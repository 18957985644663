import React, { Component } from 'react'
import ErrorPage from '../../pages/others/ErrorPage'
import { withRouter } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'

function notifyBugsnag(_error, _info = null) {
  return
  // TODO: Add datadog here
}

class DefaultErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    const { history } = props
    history.listen((_location, _action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        })
      }
    })
  }

  componentDidCatch(error, info) {
    // Using `.addError` so it can be processed by Error Tracking.
    // see: https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
    datadogRum.addError(error)
    this.setState({ hasError: true, error, info })
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          title={this.props.title}
          showHeader={this.props.showHeader}
        />
      )
    }
    // This is to resolve SonarCloud issue:
    // Functions should always return the same type javascript:S3800
    return <>{this.props.children}</>
  }
}

const ErrorBoundary = withRouter(DefaultErrorBoundary)
const errorBoundaryWrapper = (props) => {
  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <ErrorPage title={props.title} showHeader={props.showHeader} />
      )}
      {...props}
    />
  )
}
export default errorBoundaryWrapper
export { notifyBugsnag }
