import React, { Component } from 'react'
import './style.css'

class Image extends Component {
  render() {
    const props = this.props
    return (
      <img
        className="zc-image"
        src={(this.props.data && this.props.data.imageUrl) || props.src}
        alt={props.alt || ''}
      />
    )
  }
}

export default Image
