import React, { Fragment } from 'react'
import { getMessage } from 'lib/translator'
import { Input } from 'components/Form'

const DisplayCreatorEditorInfo = (props) => {
  const ARRAY_DETAILS = [
    ['createdBy', 'createdAt'],
    ['updatedBy', 'updatedAt'],
  ]
  return (
    <Fragment>
      {ARRAY_DETAILS.map((details, i) => {
        return (
          <div className="row" key={i}>
            {details.map((info, j) => {
              return (
                <div className="col-6" key={j}>
                  <Input
                    label={getMessage(`rewards.${info}`)}
                    placeholder={getMessage(`rewards.${info}`)}
                    type="text"
                    value={props[info]}
                    readonly
                    disabled
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </Fragment>
  )
}

export default DisplayCreatorEditorInfo
