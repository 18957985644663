import _ from 'lodash';

function relocateSubOrderInOrderItems(items) {
  const [ subItems, originalItems ] = _.partition(items, (x) => x.orderDetails.substitutedItemId);
  subItems.forEach(subItem => {
    const itemIndex = originalItems.findIndex(x => x.orderDetails.orderItemId === subItem.orderDetails.substitutedItemId);
    // insert the subItem right after the originalItem index
    if (itemIndex !== -1) {
      // from originalItem perspective, it is impossible to know it is item for sub. So, use isItemToSub
      originalItems[itemIndex].isItemToSub = true;
      originalItems.splice(itemIndex + 1, 0, subItem);
    }
  })
  return originalItems;
}

function getAmendmentFromOrder(order) {
  // details is not under amendment at all if there is no amendment
  if(!order.amendment?.details) {
    return order;
  }
  // sort orderAmendments by id, so we know which is the first amendment and which is the second amendment
  const sortedAmendmentIds = _.sortBy(order.amendment.details, ['id']).map(x => x.id);
  const itemsWithAmendmentIds = order.items.map(item => {
    return {
      ...item,
      amendmentItemIndex: sortedAmendmentIds.findIndex(x => x === item.orderDetails.orderAmendmentId)
    }
  })
  return {
    ...order,
    items: itemsWithAmendmentIds
  };
}

const getItemsWithSubstitutionAndAmendments = (order) => {
  const orderWithAmendmendedItems = getAmendmentFromOrder(order);
  return relocateSubOrderInOrderItems(orderWithAmendmendedItems.items);
}

export {
  getAmendmentFromOrder,
  relocateSubOrderInOrderItems,
  getItemsWithSubstitutionAndAmendments
}
