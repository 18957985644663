import React, { Fragment, Component } from 'react'
import { Input } from '../../Form'

export default class GrandTotal extends Component {
  render() {
    return (
      this.props.method && (
        <Fragment>
          <hr />
          <div className="row">
            <h3 className="mt-0">Grand Total</h3>
          </div>
          <div className="row">
            <div className="col-4">
              <Input
                label="Total Amount"
                placeholder="Total Amount"
                name="totalAmount"
                testid="totalAmount"
                value={this.props.totalAmount.toFixed(2)}
                type="text"
                readOnly
              />
            </div>
            <div className="col-4 ml">
              <Input
                label="Total Number of E-Vouchers"
                placeholder="Total Number of E-Vouchers"
                value={this.props.totalNoOfVouchers}
                type="text"
                readOnly
                testid="totalNoOfVouchers"
              />
            </div>
          </div>
        </Fragment>
      )
    )
  }
}
