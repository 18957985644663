import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getMessage } from '../../../lib/translator'
import API from '../../../lib/api'
import Loader from '../../../components/Loader'
import { Dialog, Popup } from '../../../components/Popup'
import EmptyState from '../../../components/EmptyState'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import Table, { Header, Row, Cell } from '../../../components/Table'
import LineItemPreview from './LineItemPreview'
import UploadPopup from './SKUupload/UploadPopup'
import './SKUupload/SKUpopup.css'
import emptyIcon from '../commonMedia/images/pages-empty.svg'
import editIcon from '../commonMedia/images/edit-icon.svg'

const emptyState = {
  icon: emptyIcon,
  message: getMessage('media.adset.emptyMessage'),
  submessage: getMessage('media.adset.emptySubmessage'),
}
const lineItemNameText = 'media.adset.rowheaders.name'
const lineItemSKUText = 'media.adset.rowheaders.sku'

const DownloadImage = () => {
  const [fill, setFill] = React.useState('none')
  const handleOnMouseOver = () => {
    setFill('#008000')
  }
  const handleOnMouseOut = () => {
    setFill('none')
  }

  return (
    <div onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 20"
      >
        <g fill={fill} fillRule="evenodd">
          <path d="M-3-6h30v30H-3z" opacity=".05" />
          <g stroke="#80959D">
            <path d="M1 15v4h22v-4M8 8l4.495 4L17 8M12.5 11.5V0" />
          </g>
        </g>
      </svg>
    </div>
  )
}

const AdvertiserTable = props => {
  return (
    <div className="Advertiser-wrapper">
      <Table>
        <Header>
          <Cell>{getMessage(lineItemNameText)}</Cell>
          <Cell>{getMessage('media.adset.advertiserpopup.sku')}</Cell>
        </Header>
        {props.AdvertiserDialog.advertiserList.map((item, index) => (
          <Row key={index}>
            <Cell>{item.supplierName}</Cell>
            <Cell>
              {item.supplierName}
            </Cell>
            <Cell>
              <a className="upload-button"
                onClick={() => {
                  props.toggleUploadBox(1, item.id)
                }}
              >
                &nbsp;
              </a>
              {item.lineItemSupplierSkusList &&
                item.lineItemSupplierSkusList.length > 0 && (
                  <a
                    className="download-button"
                    onClick={e => {
                      props.downloadSKUFile(e, item.id, item.supplierName)
                    }}
                  >
                    <DownloadImage />
                  </a>
                )}
            </Cell>
          </Row>
        ))}
      </Table>
    </div>
  )
}

const LineItemRow = props => {
  const { campaignId, item, isMobileView } = props
  item.duration =
    moment
      .duration(moment(item.endDate).diff(moment(item.startDate)))
      .asDays() + 1

  return (
    <Row>
      <Cell>
        {isMobileView && (
          <div className="text-muted">{getMessage(lineItemNameText)}</div>
        )}
        <span
          className="link-btn"
          onClick={() => {
            props.toggleLineitemPopup(1, item)
          }}
        >
          {item.lineItemName}
        </span>
      </Cell>
      <Cell>
        {isMobileView && (
          <div className="text-muted">
            {getMessage('media.adset.rowheaders.time')}
          </div>
        )}
        <div>
          {item.startDate} ~ {item.endDate || '?'}
        </div>
        {item.endDate && (
          <small>
            {item.duration} {item.duration === 1 ? 'day' : 'days'}
          </small>
        )}
      </Cell>
      <Cell>
        {isMobileView && (
          <div className="text-muted">
            {getMessage('media.adset.rowheaders.package')}
          </div>
        )}
        <div>
          ({item.packageId}) {item.packageName}
        </div>
      </Cell>
      <Cell>
        {isMobileView && (
          <div className="text-muted">
            {getMessage('media.adset.rowheaders.offsite')}
          </div>
        )}
        {item.requireOffsiteForm && (
          <Link
            to={`/media-monetization/media-allcampaigns/media-adset-offsite/${campaignId}/${item.id}`}
          >
            <img alt="edit" src={editIcon} />
          </Link>
        )}
      </Cell>
      <Cell>
        {isMobileView && (
          <div className="text-muted">{getMessage(lineItemSKUText)}</div>
        )}
        <a
          className="advertisers-botton"
          onClick={() => {
            props.toggleAdvertiserBox(
              1,
              item.id,
              item.lineItemName,
              item.lineItemSupplierReservationList
            )
          }}
        >
          <img alt="edit" src={editIcon} />
        </a>
      </Cell>
      <Cell>
        {isMobileView && (
          <div className="text-muted">
            {getMessage('media.adset.rowheaders.editCreative')}
          </div>
        )}
        <Link
          to={`/media-monetization/media-creativemanagement/media-add-creative/${item.id}/${item.packageId}`}
        >
          <img alt="edit" src={editIcon} />
        </Link>
      </Cell>
      <Cell>
        {isMobileView && (
          <div className="text-muted">
            {getMessage('media.adset.rowheaders.status')}
          </div>
        )}
        <div>
          {item.status}
        </div>
      </Cell>
      <Cell className="website-page-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem
            onClick={() => {
              props.toggleLineitemPopup(1, item)
            }}
          >
            {getMessage('media.action.view')}
          </DropDownItem>
          <DropDownItem>
            <Link
              to={`/media-monetization/media-allcampaigns/media-adset-detail/edit/${campaignId}/${item.id}`}
            >
              {getMessage('media.action.edit')}
            </Link>
          </DropDownItem>
          <DropDownItem>
            <Link
              to={`/media-monetization/media-allcampaigns/media-adset-detail/add/${campaignId}/${item.id}`}
            >
              {getMessage('media.action.duplicate')}
            </Link>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              props.toggleDeleteDialog(1, item.id)
            }}
          >
            {getMessage('media.action.delete')}
          </DropDownItem>
        </DropDown>
      </Cell>
    </Row>
  )
}

export default class LineItemTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showLoader: false,
      deleteDialog: {
        shown: false,
        id: null,
      },
      AdvertiserDialog: {
        shown: false,
        lineItemId: null,
        lineItemName: null,
        advertiserList: [],
      },
      UploadDialog: {
        shown: false,
        id: null,
      },
      LineitemPopup: {
        shown: false,
        data: null,
      },
    }
    this.toggleLineitemPopup = this.toggleLineitemPopup.bind(this)
    this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.refreshCampaignDetail = this.refreshCampaignDetail.bind(this)
    this.toggleAdvertiserBox = this.toggleAdvertiserBox.bind(this)
    this.toggleUploadBox = this.toggleUploadBox.bind(this)
    this.downloadSKUFile = this.downloadSKUFile.bind(this)
  }

  toggleLineitemPopup(status, data) {
    this.setState({
      LineitemPopup: {
        shown: status === 1,
        data,
      },
    })
  }

  toggleDeleteDialog(status, id) {
    this.setState({
      showLoader: false,
      deleteDialog: {
        shown: status === 1,
        id,
      },
    })
  }

  confirmDelete() {
    const _id = this.state.deleteDialog.id
    this.setState({
      showLoader: true,
      deleteDialog: {
        shown: false,
        id: null,
      },
    })
    this.api = new API({
      url: '/martech-ad-service/reservations/deleteLineItem/' + _id,
    })
    this.api.delete().then(
      () => {
        this.setState({
          showLoader: false,
        })
        this.props.getCampaignDetail()
      },
      error => {
        this.toggleDeleteDialog()
        this.props.throwError(
          error,
          getMessage('errorDialog.delete.error.title')
        )
      }
    )
  }

  refreshCampaignDetail() {
    this.props.getCampaignDetail()
    this.toggleAdvertiserBox()
  }

  toggleAdvertiserBox(status, lineItemId, lineItemName, advertiserList) {
    this.setState({
      AdvertiserDialog: {
        shown: status === 1,
        lineItemId,
        lineItemName,
        advertiserList,
      },
    })
  }

  toggleUploadBox(status, id) {
    this.setState({
      UploadDialog: {
        shown: status === 1,
        id,
      },
    })
  }

  downloadSKUFile(e, supplierReservationId, supplierName) {
    e.preventDefault()
    const api = new API({
      url:
        '/martech-ad-service/data/download_supplier/' + supplierReservationId,
    })
    api.get().then(
      response => {
        downloadCsv(this.state.AdvertiserDialog.lineItemName, response || [])
      },
      error => {
        error.message = 'Cannot download the file'
        this.props.throwError(error, getMessage('errorDialog.title'))
      }
    )

    function downloadCsv(lineItemName, data) {
      var hiddenElement = document.createElement('a')
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
      hiddenElement.target = '_blank'
      hiddenElement.download = `${lineItemName}_${supplierName}.csv`
      hiddenElement.click()
    }
  }

  render() {
    const { isMobileView, lineItemData } = this.props
    const { showLoader } = this.state

    return (
      <React.Fragment>
        {showLoader ? (
          <Loader />
        ) : (
          <div className="lineitem-list">
            <div className="lineitem-table-wrap">
              {!lineItemData || lineItemData?.length === 0 ? (
                <EmptyState {...emptyState} />
              ) : (
                <Table>
                  {!isMobileView && (
                    <Header>
                      <Cell>{getMessage(lineItemNameText)}</Cell>
                      <Cell>{getMessage('media.adset.rowheaders.time')}</Cell>
                      <Cell>
                        {getMessage('media.adset.rowheaders.package')}
                      </Cell>
                      <Cell>
                        {getMessage('media.adset.rowheaders.offsite')}
                      </Cell>
                      <Cell>{getMessage(lineItemSKUText)}</Cell>
                      <Cell>
                        {getMessage('media.adset.rowheaders.editCreative')}
                      </Cell>
                      <Cell>
                        {getMessage('media.adset.rowheaders.status')}
                      </Cell>
                      <Cell>
                        {getMessage('media.storeList.header.actions')}
                      </Cell>
                    </Header>
                  )}
                  {lineItemData?.map((item, index) => (
                    <LineItemRow
                      key={index}
                      campaignId={this.props.campaignId}
                      item={item}
                      isMobileView={isMobileView}
                      toggleLineitemPopup={this.toggleLineitemPopup}
                      toggleAdvertiserBox={this.toggleAdvertiserBox}
                      toggleDeleteDialog={this.toggleDeleteDialog}
                    ></LineItemRow>
                  ))}
                </Table>
              )}
            </div>
          </div>
        )}

        {this.state.deleteDialog.shown && (
          <Dialog
            show={this.state.deleteDialog.shown}
            title={getMessage('deleteDialog.title')}
            information={getMessage('deleteDialog.information')}
            onOk={this.confirmDelete}
            close={this.toggleDeleteDialog}
            closeText={getMessage('dialog.cancelText')}
            okText={getMessage('dialog.okText')}
          />
        )}
        {this.state.AdvertiserDialog.shown && (
          <Popup
            show={this.state.AdvertiserDialog.shown}
            className="Advertiser-box"
            heading={getMessage('media.campaigndetail.advertiser.h3')}
            close={() => {
              this.toggleAdvertiserBox(2)
            }}
          >
            <AdvertiserTable
              AdvertiserDialog={this.state.AdvertiserDialog}
              toggleUploadBox={this.toggleUploadBox}
              downloadSKUFile={this.downloadSKUFile}
            ></AdvertiserTable>
          </Popup>
        )}
        {this.state.UploadDialog.shown && (
          <UploadPopup
            isOpenUploadBox={this.state.UploadDialog.shown}
            toggleUploadBox={this.toggleUploadBox}
            supplierReservationId={this.state.UploadDialog.id}
            refreshCampaignDetail={this.refreshCampaignDetail}
          ></UploadPopup>
        )}
        {this.state.LineitemPopup.shown && (
          <LineItemPreview
            show={this.state.LineitemPopup.shown}
            toggleLineitemPopup={this.toggleLineitemPopup}
            data={this.state.LineitemPopup.data}
            campaignId={this.props.campaignId}
          ></LineItemPreview>
        )}
      </React.Fragment>
    )
  }
}
