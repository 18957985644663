import React from 'react'

import {
  BaseForm,
  VALIDATION_TYPES,
  Textarea,
} from '../../../../components/Form'
import { getMessage } from 'lib/translator'
import Loader from '../../../../components/Loader'
import {
  DatePickerField,
  TimePickerField,
  FormButtons,
  ErrorScreen,
  convertToISOString,
} from '../../../../components/Rewards/utils'
import {
  getApiDetails,
  handleApis,
} from '../../../../components/Rewards/services'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'

class MaintenaceForm extends BaseForm {
  async componentDidMount() {
    if (this.props.method === 'edit') {
      getApiDetails(this, 'maintenance')
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    const { id, message, date } = this.state.values
    const startAt = convertToISOString(date?.startingDate, date?.startingTime)
    const endAt = convertToISOString(date?.endingDate, date?.endingTime)

    if (isValid) {
      const mapData = {
        message,
        startAt,
        endAt,
      }
      handleApis(mapData, `/rms/maintenance`, this, id)
    }
  }

  render() {
    const { Form } = this.components
    const { loading, error } = { ...this.state.values }

    return (
      <div className="rewards container">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="row">
              <DatePickerField label="startDate" _this={this} />
              <TimePickerField label="startTime" _this={this} />
            </div>
            <div className="row">
              <DatePickerField label="endDate" _this={this} />
              <TimePickerField label="endTime" _this={this} />
            </div>
            <div className="row">
              <div className="col-12">
                <Textarea
                  label={getMessage(`rewards.maintenance.message`)}
                  placeholder={getMessage(`rewards.maintenance.message`)}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: ['message'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  required
                />
              </div>
            </div>
            {this.props.method === 'edit' && (
              <DisplayCreatorEditorInfo {...this.state.values} />
            )}
            <FormButtons buttons={this.buttons} type="maintenance" />
            {error && <ErrorScreen error={error} />}
          </Form>
        )}
      </div>
    )
  }
}
export default MaintenaceForm
