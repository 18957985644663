import { get } from 'lib/storage';
import { slotRuleTypes } from './constants';
import API from 'lib/api';

/* E.g. Return array [321,232,454] from "321 , 2 32, 454" */
function getPostalCodesArrayFromString(postalCodes) {
  return postalCodes
            .replace(/\s/g, "") // remove any white space
            .split(',')         // split by comma
            .filter(x => x!=='')// ignore last white space array item if string ends with comma
            .map(x => ({ value: x, isValid: true }) ) // create object
}

/* check if postal code has non-digit char */
function verifyPostalCodesHaveSixDigit(postalCodesArr) {
  postalCodesArr.forEach(postalCode => {
    postalCode.isValid = /^\d{6}$/.test(postalCode.value) // check if it contains only 6 digits
  })
}

/* check for duplicates in array of objects */
function findDuplicates(arr, property) {
  const counts = arr.reduce((acc, obj) => {
    const value = obj[property];
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});

  return Object.keys(counts).filter(key => counts[key] > 1);
}

/* return indexes of duplicate postal codes */
function verifyNoDuplicatedPostalCodes(postalCodesArr) {
  const result = findDuplicates(postalCodesArr, 'value');
  const indices = []
  result.forEach(postalCode => {
    postalCodesArr.forEach((x, index) => {
      if (postalCode === x.value) {
        indices.push(index);
      }
    })
  })
  indices.forEach(x => postalCodesArr[x].isValid = false);
}

/* Transform timeSlotBlocking payload for add rule API call */
function transformPayload(timeSlotBlocking, clusterId, selectedRuleType) {
  const checkedBlockedSlots = [];
  for (const [key, value] of timeSlotBlocking.blockedSlots) {
    if (value) {
      checkedBlockedSlots.push(key);
    }
  }

  return {
    ...timeSlotBlocking,
    ...(slotRuleTypes[selectedRuleType] === slotRuleTypes.IS_CLUSTER ? { clusterId } : {}),
    blockedSlots: checkedBlockedSlots,
    storeId: parseInt(get('store')),
    slotRuleType: slotRuleTypes[selectedRuleType],
    postalCodes: timeSlotBlocking.postalCodes?.length > 0 ?
      timeSlotBlocking.postalCodes.map(postalCode => postalCode.value) : []
  };
}

function getLatLongFromPolygon(polygon) {
  return polygon.split(',').map(x => {
    return {
      longitude: Number(x.trim().split(" ")[0]),
      latitude: Number(x.trim().split(" ")[1])
    }
  })
}

function getCoordindatesFromPolygon(polygon) {
  return polygon.split(',').map(x => {
    return {
      lng: Number(x.trim().split(" ")[0]),
      lat: Number(x.trim().split(" ")[1])
    }
  })
}

function getMapDataFromClusterRules(rules) {
  return rules.filter(x => x.slotRuleType === slotRuleTypes.IS_CLUSTER)
    .map(rule => (
      {
        id: rule.id,
        tempId: rule.id,
        configType: 'POLYGON',
        name: rule.zone.name,
        metadata: {
          ruleName: rule.name,
          blockedSlot: rule.metadata.blockedSlot,
          slotRuleType: rule.slotRuleType,
          polygon: rule.metadata.polygon,
          zoneName: rule.zone.name,
        },
        area: {
          locations: getLatLongFromPolygon(rule.metadata.polygon)
        }
      }
    ));
}

// To filter only specific slot rule types (to remove RDC and non-RDC rules from array)
const filterRules = (rules) => {
  const filteredRules = rules.filter(rule => Object.values(slotRuleTypes).includes(rule.slotRuleType));
  const numberOfFilteredOutRules = rules.length - filteredRules.length;
  return { filteredRules, numberOfFilteredOutRules }
}

const fetchCoordinatesFromAddressService = async(postalCodes) => {
  const coordinates = {};
  const addressPromises = postalCodes.map(postalCode => {
    const addressAPI = new API({ url: `/address/search?type=addresses&term=${postalCode}`});
    return addressAPI.get();
  });
  const responses = await Promise.all(addressPromises)
  responses.forEach(res => {
    if (res.data.addresses && res.data.addresses.length > 0) {
      coordinates[res.data.addresses[0].postcode] = {
        lat: res.data.addresses[0].lat,
        lng: res.data.addresses[0].long
      }
    }
  })
  return coordinates;
}

export {
  getPostalCodesArrayFromString,
  verifyPostalCodesHaveSixDigit,
  verifyNoDuplicatedPostalCodes,
  transformPayload,
  getLatLongFromPolygon,
  getCoordindatesFromPolygon,
  getMapDataFromClusterRules,
  filterRules,
  fetchCoordinatesFromAddressService
}
