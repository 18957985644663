import React from 'react';
import { getMessage } from 'lib/translator';
import AuthenticatedPage from 'containers/AuthenticatedPage';

const OfferCampaignsTable = ({ menu, history }) => {

	return (
		<AuthenticatedPage className="offer-campaign-form-page" menu={menu}>
			<div className="flex-around">
				<h1 className="title heading">
					<strong>
						{getMessage('offer.campaigns.listing.heading')}
					</strong>
				</h1>
				<button className="button primary" onClick={() => history.push("/marketing/offer-campaigns/new")}>
					{getMessage('offer.campaigns.listing.add.offerCampaign')}
				</button>
			</div>
		</AuthenticatedPage>
	)
}

export default OfferCampaignsTable;
