import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getMessage } from '../../../../lib/translator'
import API from '../../../../lib/api'
import Loader from '../../../../components/Loader'
import { Dialog } from '../../../../components/Popup'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../../components/DropDown'
import EmptyState from '../../../../components/EmptyState'
import emptyIcon from '../../commonMedia/images/pages-empty.svg'
import { toggleDeleteDialog } from '../../commonMedia'

const emptyState = {
  icon: emptyIcon,
  message: getMessage('media.creativedetail.creative.group.emptyMessage'),
  submessage: getMessage('media.creativedetail.creative.group.emptySubmessage'),
}

const creativeGroupHeader = 'media.creativedetail.creative.group.header.name'

export default class CreativeGroupTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showLoader: false,
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
      deleteDialog: {
        shown: false,
        id: null,
      }
    }
    this.confirmDelete = this.confirmDelete.bind(this)
  }

  confirmDelete() {
    const _id = this.state.deleteDialog.id
    this.setState({
      showLoader: true,
      deleteDialog: {
        shown: false,
        id: null,
      },
    })
    this.api = new API({ url: '/martech-creative-service/creative-group/'+ _id })
    this.api.delete().then(
      () => {
        this.setState({
          showLoader: false
        })
        this.props.getCreativeDetail()
      },
      error => {
        this.props.throwError(error, getMessage('errorDialog.delete.error.title'))
      },
    )
  }

  render() {
    const { isMobileView, creativeGroupsData, creativeId } = this.props
    const { showLoader } = this.state

    return (
      <React.Fragment>

        {showLoader ? <Loader /> : (
          <div>
            {!creativeGroupsData || creativeGroupsData?.length === 0 ? (<EmptyState {...emptyState} />) : (
              <Table>
                {!isMobileView && (
                  <Header>
                    <Cell>{getMessage(creativeGroupHeader)}</Cell>
                    <Cell>{getMessage('media.creativedetail.creative.group.header.asset')}</Cell>
                    <Cell>{getMessage('media.creativedetail.creative.group.header.adlist')}</Cell>
                    <Cell>{getMessage('media.action')}</Cell>
                  </Header>
                )}
                {creativeGroupsData?.map((item, index) => (
                  <GroupRow
                    _this={this}
                    key={index}
                    item={item}
                    index={index}
                    isMobileView={isMobileView}
                    creativeId={creativeId}
                    creativeGroupId={item.id}
                    requirementId={item.creative_requirement?.id || 0}
                    toggleGroupDialog={this.props.toggleGroupDialog}
                    isNotEditable={this.props.isNotEditable}
                    toggleEitDisabledDialog={()=> this.props.toggleEitDisabledDialog()}
                  ></GroupRow>
                ))}
              </Table>
            )}
          </div>
        )}

        {this.state.deleteDialog.shown && (
          <Dialog
            show={this.state.deleteDialog.shown}
            title={getMessage('deleteDialog.title')}
            information={getMessage('deleteDialog.information')}
            onOk={this.confirmDelete}
            close={()=>{toggleDeleteDialog(this)}}
            closeText={getMessage('dialog.cancelText')}
            okText={getMessage('dialog.okText')}
          />
        )}

      </React.Fragment>
    )
  }
}

const GroupRow = (props) => {

  const { _this, item, isMobileView, index, creativeId, creativeGroupId, requirementId, isNotEditable } = props

  return (
    <Row>
      <Cell>
        {isMobileView && (
          <div className='text-muted'>
            {getMessage(creativeGroupHeader)}
          </div>
        )}
        <span className='link-btn'>{`${getMessage(creativeGroupHeader)} ${index + 1}`}</span>
      </Cell>
      <Cell>
        {isMobileView && (
          <div className='text-muted'>
            {getMessage('media.creativedetail.creative.group.header.asset')}
          </div>
        )}
        <small className='link-btn group-assets'>
          {item.creative_group_asset?.map((assetItem, assetIndex) => (
            <span className='group-asset-item' key={assetIndex}>{assetItem.asset.asset_name}</span>
          ))}
        </small>
      </Cell>
      <Cell>
        {isMobileView && (
          <div className='text-muted'>
            {getMessage('media.creativedetail.creative.group.header.adlist')}
          </div>
        )}
        <span className="link-btn">
          {isNotEditable ? (
            <Link
              to={`/media-monetization/media-creativemanagement/media-creative-group-ad-list/${creativeId}/${creativeGroupId}/disabled`}
              className="upload-button">&nbsp;</Link>
          )
          : (
            <Link
              to={`/media-monetization/media-creativemanagement/media-creative-group-ad-list/${creativeId}/${creativeGroupId}`}
              className="upload-button">&nbsp;</Link>
            )
          }
        </span>
      </Cell>
      <Cell className="website-page-actions">
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮"/>}>
          <DropDownItem onClick={() => {
            props.isNotEditable ? props.toggleEitDisabledDialog() :
              props.toggleGroupDialog(1, 2, requirementId, creativeGroupId)
          }}>
            {getMessage('media.action.edit')}
          </DropDownItem>
          <DropDownItem onClick={() => {
            props.isNotEditable ? props.toggleEitDisabledDialog() :
              toggleDeleteDialog(_this, 1, item.id)
          }}
          >
            {getMessage('media.action.delete')}
          </DropDownItem>
        </DropDown>
      </Cell>
    </Row>
  )
}
