import React, { useEffect } from 'react'
import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api'

const CENTER_OF_SINGAPORE = {
  lat: 1.3679034017868088,
  lng: 103.8448850430738,
}

export const parseGoogleMapData = (
  places,
  bounds,
  map,
  setLocation,
  onPlaceChanged
) => {
  let loc, address
  if (places.length) {
    const place = places[0]
    loc = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    }
    setLocation(loc)
    address = place.formatted_address
    onPlaceChanged({ location: loc, address })
  }
  if (map !== null) {
    map.fitBounds(bounds)
  }
  places.forEach((place) => {
    if (place.geometry.viewport) {
      bounds.union(place.geometry.viewport)
    } else {
      bounds.extend(place.geometry.location)
    }
  })
  map.fitBounds(bounds)
}

export const StoreMapLocator = (props) => {
  const [map, setMap] = React.useState(null)
  const [searchBox, setSearchBox] = React.useState(null)
  const [location, setLocation] = React.useState()

  const onLoad = React.useCallback(function callback(mapInstance) {
    setMap(mapInstance)
  }, [])

  const handleLocationChange = () => {
    const places = searchBox.getPlaces()
    const bounds = new window.google.maps.LatLngBounds()
    parseGoogleMapData(places, bounds, map, setLocation, props.onPlaceChanged)
  }

  useEffect(() => {
    setLocation(props.initialLocation)
  }, [props])

  return (
    <div className="store-map-locator">
      <GoogleMap
        mapContainerClassName="map-element"
        onLoad={onLoad}
        zoom={15}
        onClick={() => {}}
        center={location ?? CENTER_OF_SINGAPORE}
      >
        <>
          {map !== null && (
            <StandaloneSearchBox
              onLoad={(node) => {
                setSearchBox(node)
              }}
              controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
              onPlacesChanged={handleLocationChange}
            >
              <input
                type="text"
                placeholder={'Search location'}
                className="location-search-box"
              />
            </StandaloneSearchBox>
          )}
          <Marker
            position={location ?? CENTER_OF_SINGAPORE}
            draggable
            onDragEnd={(e) => {
              console.log(e)
            }}
          />
        </>
      </GoogleMap>
    </div>
  )
}
