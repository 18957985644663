import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import {
  LinkButtons,
  emptyState,
  tableProperties,
} from '../../../components/Rewards/utils'
import '../style.css'
import './promotion.css'
import PromotionForm from './Form'
import Filters from '../../../components/Rewards/Filters'
import { getMessage } from 'lib/translator'

const Promotions = props => {
  return (
    <ListingPageWithRoutes
      testid="promotions-listing"
      title={getMessage('rewards.promotions.heading')}
      addHeading={getMessage('rewards.promotion.add')}
      editHeading={getMessage('rewards.promotions.edit')}
      menu={props.menu}
      className="rewards-page promotions"
      api={{
        url: '/rms/promotions',
        transform: response => response && response.data.list,
      }}
      headerActions={() => <LinkButtons type="promotion" />}
      form={{
        component: PromotionForm,
      }}
      filters={{
        component: Filters,
        forceShow: true,
        options: {
          type: 'promotion',
        },
      }}
      emptyState={emptyState('promotion')}
      tableProperties={tableProperties('promotions')}
      tableDynamic
    />
  )
}

export default Promotions
