import React, { Fragment } from 'react'
import {
  Input,
  BaseForm,
  Select,
  Radio,
  SingleDatePicker,
  Searchable,
  BrandSearch,
  CategorySearch,
  MultiTextInput,
} from '../../../../components/Form'

import { getMessage } from '../../../../lib/translator'

class SearchCampaignsFilter extends BaseForm {
  renderCategorySearch() {
    return (
      <div className="searched-items">
        <CategorySearch
          label={getMessage('search-campaigns.filter.category.heading')}
          placeholder={getMessage(
            'search-campaigns.filter.category.placeholder'
          )}
          name="category"
          multiple
          {...this.generateStateMappers({
            stateKeys: ['categoryUrl'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }
  renderBrandSearch() {
    return (
      <div className="searched-items">
        <BrandSearch
          label={getMessage('search-campaigns.filter.brand.heading')}
          placeholder={getMessage('search-campaigns.filter.brand.placeholder')}
          name="brand"
          multiple
          {...this.generateStateMappers({
            stateKeys: ['brandUrl'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }
  renderProductSearch() {
    return (
      <div className="searched-items">
        <Searchable
          label={getMessage('search-campaigns.filter.tag.heading')}
          placeholder={getMessage('search-campaigns.filter.tag.placeholder')}
          name="tag"
          searchUrl="/catalogue-service/tag"
          valueKey="id"
          responseKey="tag"
          nameKey="name"
          searchKey="name"
          expandMore={true}
          transformResponse={response => response.data.tag || []}
          multiple
          {...this.generateStateMappers({
            stateKeys: ['productTagUrl'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
    )
  }
  pageTypeField() {
    const { values } = this.state
    const { pageTypes } = this.props.options || {}
    const pageType = values && values.pageType
    switch (pageType) {
      case pageTypes.category:
        return this.renderCategorySearch()
      case pageTypes.brand:
        return this.renderBrandSearch()
      case pageTypes.productTag:
        return this.renderProductSearch()
      default:
        return null
    }
  }
  renderDefault() {
    return (
      <Input
        label={getMessage('search-campaigns.filter.defaultText.label')}
        placeholder={getMessage(
          'search-campaigns.filter.defaultText.placeholder'
        )}
        name="default"
        type="text"
        {...this.generateStateMappers({
          stateKeys: ['description'],
          loseEmphasisOnFill: true,
        })}
      />
    )
  }
  renderKeyword() {
    return (
      <MultiTextInput
        label={getMessage('search-campaigns.filter.keyword.label')}
        placeholder={getMessage('search-campaigns.filter.keyword.placeholder')}
        name="keywords"
        type="text"
        {...this.generateStateMappers({
          stateKeys: ['keywords'],
          loseEmphasisOnFill: true,
        })}
      />
    )
  }
  campaignTypeField() {
    const { values } = this.state
    const campaignType = values && values.campaignType
    return (
      <Fragment>
        {!campaignType && <div className="placeholder field"></div>}
        {campaignType && campaignType === 'default' && this.renderDefault()}
        {campaignType && campaignType === 'keyword' && this.renderKeyword()}
      </Fragment>
    )
  }
  render() {
    const { values } = this.state
    const { Form } = this.components
    const { SubmitButton, ClearButton } = this.buttons
    const { stores, campaignTypes, defaultStatus, pages } =
      this.props.options || {}
    const pageType = values && values.pageType
    return (
      <Form
        className="search-campaigns-filters"
        testid="search-campaigns-filter"
      >
        <div className="form-fields">
          <Select
            testid="filter-campaignType"
            name="campaignType"
            label={getMessage('search-campaigns.filter.type')}
            placeholder={getMessage('search-campaigns.filter.type.placeholder')}
            options={campaignTypes}
            {...this.generateStateMappers({
              stateKeys: ['campaignType'],
              loseEmphasisOnFill: true,
            })}
          />
          {this.campaignTypeField()}
          <div className="form-field">
            <Select
              testid="filter-pageType"
              label={getMessage('search-campaigns.filter.pageType.label')}
              placeholder={getMessage(
                'search-campaigns.filter.pageType.placeholder'
              )}
              name="pageType"
              options={pages}
              {...this.generateStateMappers({
                stateKeys: ['pageType'],
                loseEmphasisOnFill: true,
              })}
            />
            {pageType && this.pageTypeField()}
          </div>
          <Input
            label={getMessage('search-campaigns.filter.name')}
            placeholder={getMessage('search-campaigns.filter.name.placeholder')}
            name="campaignName"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage('search-campaigns.filter.id')}
            placeholder={getMessage('search-campaigns.filter.id.placeholder')}
            name="cId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['cId'],
              loseEmphasisOnFill: true,
            })}
          />
          {stores && (
            <Select
              label={getMessage('search-campaigns.filter.store')}
              placeholder={getMessage(
                'search-campaigns.filter.store.placeholder'
              )}
              name="store"
              options={stores
                .filter(store => store.hasPicking || store.hasSelfCheckout)
                .map(store => {
                  return {
                    text: store.name,
                    value: store.id,
                  }
                })}
              {...this.generateStateMappers({
                stateKeys: ['storeId'],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <SingleDatePicker
            label={getMessage('search-campaigns.filter.validFrom')}
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ['startDate'],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage('search-campaigns.filter.validTill')}
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ['endDate'],
              loseEmphasisOnFill: true,
            })}
          />
          <Radio
            name="status"
            label={getMessage('search-campaigns.filter.status')}
            options={[
              {
                text: getMessage('search-campaigns.filter.all'),
                value: 'ALL',
              },
              {
                text: getMessage('search-campaigns.filter.enabled'),
                value: 'ENABLED',
              },
              {
                text: getMessage('search-campaigns.filter.disabled'),
                value: 'DISABLED',
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ['status'],
              loseEmphasisOnFill: true,
              defaultValue: defaultStatus,
            })}
          />
        </div>
        <SubmitButton>
          {getMessage('search-campaigns.filter.submit')}
        </SubmitButton>
        <ClearButton>{getMessage('search-campaigns.filter.clear')}</ClearButton>
      </Form>
    )
  }
}

export default SearchCampaignsFilter
