import React, { useEffect, useMemo, useState } from 'react'
import { Popup } from 'components/Popup'
import { OfferTypes } from '../../settings'
import DiscountTabulation from './DiscountTabulation'
import SegmentsList from './segmentsList'
import {
  getCartLevelOfferSummary,
  getOffersSummary,
  getOffersSummaryForCategoryBrandOrTags,
} from './offerSummary'
import { getMessage } from 'lib/translator'
import './style.scss';

const NAEntityTypes = ['CATEGORY', 'BRAND']

const ConfirmationModal = ({ show, onEdit, onConfirm, values, stores }) => {

  const [isShowDiscountWarning, setIsShowDiscountWarning] = useState(false)

  const {
    type,
    validFrom,
    validTill,
    stackable,
    hasUniquePromocode,
    addToWallet,
    paymentType = [],
    userSet,
    rule,
    product,
    totalRedemption,
    entityType,
    includes,
    isPayViaFpApp,
    promoCode,
    itemDiscountType,
    segmentationIds,
  } = values

  const isCategoryBrandOrTags =
    NAEntityTypes.indexOf(entityType) > -1 || type === 'BFXATP'
  const isTabulationApplicable =
    !isCategoryBrandOrTags && (product?.length || 0) <= 1

  const offerSummary = useMemo(() => {
    if (isCategoryBrandOrTags) {
      return getOffersSummaryForCategoryBrandOrTags(values,setIsShowDiscountWarning)
    }
    if (['SFXGSD', 'SFXGSFD', 'SFXGCD'].indexOf(values?.type) > -1) {
      return getCartLevelOfferSummary(values.rule, values.type)
    }
    return getOffersSummary(values)
  }, [show, values, isCategoryBrandOrTags])

  const isPromoCodeApplicable = hasUniquePromocode || promoCode?.length > 0
  useEffect(()=>{
    if(!show){
      setIsShowDiscountWarning(false)
    }
  },[show])

  return (
    <Popup
      show={show}
      className="offer-form-confirmation-modal"
      heading="Confirmation"
      close={onEdit}
      nohide
    >
      <div data-testid="confirmation-modal-offer">
      {isShowDiscountWarning && <div className='confirmation-modal-warning'><b>{getMessage('offer.confirmation.warning')}</b></div>}
        <h3>{getMessage('offer.confirmation.title')}</h3>
        <ul>
          <li>
            <span>{getMessage('offer.confirmation.tabulation')}:</span>
            {isTabulationApplicable ? (
              <DiscountTabulation
                rule={rule}
                type={type}
                product={product}
                totalRedemption={totalRedemption}
                includes={includes}
                setIsShowDiscountWarning={setIsShowDiscountWarning}
                itemDiscountType={itemDiscountType}
              />
            ) : (
              getMessage('offer.confirmation.na')
            )}
          </li>
          <li>
            <span>{getMessage('offer.confirmation.summary')}:</span>
            <span data-testid="confirmation-modal-offer-summary">
              {offerSummary}
            </span>
          </li>
          <li>
            <span>{getMessage('offer.confirmation.type')}:</span>
            <span data-testid="confirmation-modal-offer-type">
              {OfferTypes[type] || ''}
            </span>
          </li>
          <li>
            <span>{getMessage('offer.validFrom')}:</span>
            <span data-testid="confirmation-modal-valid-from">
              {validFrom} to {validTill || '-'}
            </span>
          </li>
          <li>
            <span>{getMessage('offer.confirmation.segments')}</span>
            <span data-testid="confirmation-modal-segments">
              <SegmentsList segments={userSet?.data} stores={stores} />
            </span>
          </li>
          {
            segmentationIds?.length > 0 &&
            <li>
              <span>{getMessage('offer.confirmation.segmentIoAudiences')}</span>
              <span className='segment-io-audience-container' data-testid="confirmation-modal-segment-io-audience">
                {
                  segmentationIds.map(segmentationId =>
                    <div key={segmentationId.id} className='segment-io-audience'>{segmentationId.name}</div>
                  )
                }
              </span>
            </li>
          }
          <li>
            <span>{getMessage('offer.promocode.label')}</span>
            {isPromoCodeApplicable ? (
              <span data-testid="confirmation-modal-unique-promo">
                {hasUniquePromocode ? 'Unique' : 'Generic'}
              </span>
            ) : (
              <span data-testid="confirmation-modal-unique-promo">
                {getMessage('offer.confirmation.na')}
              </span>
            )}
          </li>
          <li>
            <span>{getMessage('offer.field.stackable.label')}</span>
            <span data-testid="confirmation-modal-stackable">
              {stackable || isPayViaFpApp ? 'Yes' : 'No'}
            </span>
          </li>
          <li>
            <span>{getMessage('offer.field.addToWallet.label')}</span>
            <span data-testid="confirmation-modal-add-to-wallet">
              {addToWallet ? 'Yes' : 'No'}
            </span>
          </li>
          <li>
            <span>{getMessage('offer.payment.type')}</span>
            <span data-testid="confirmation-modal-payment-type">
              {paymentType?.join(', ') || '-'}
            </span>
          </li>
        </ul>
      </div>
      <div className="button-container">
        <button
          onClick={onConfirm}
          className="primary button"
          data-testid="confirmation-modal-submit"
        >
          {getMessage('offer.confirmation.confirm')}
        </button>
        <button
          onClick={onEdit}
          className="button"
          data-testid="confirmation-modal-edit"
        >
          {getMessage('offer.confirmation.edit')}
        </button>
      </div>
    </Popup>
  )
}

export default ConfirmationModal
