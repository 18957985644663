import React from 'react'
import CsvParser from 'papaparse'

import { getMessage } from '../../../lib/translator'
import Upload from '../../../components/FileUpload'
import uploadIcon from './../../catalogue/BatchUploadJobs/upload-products.svg'
import csvIcon from './../../catalogue/BatchUploadJobs/csv-icon.svg'

import './style.css'

const SIZE_IN_KB = 1024

const UploadFile = props => {
  const {
    onUpload,
    setError,
    isInvalidCsv,
    isValidName,
    onDownloadCsv,
    validateJob,
  } = props
  const handleUpload = file => {
    return new Promise((resolve, reject) => {
      if (file.size > 40 * SIZE_IN_KB) {
        validateJob && validateJob()
        resolve()
        setTimeout(
          () =>
            onUpload({
              file,
              results: [],
            }),
          100
        )
      } else {
        CsvParser.parse(file, {
          skipEmptyLines: true,
          delimiter: ',',
          complete: results => {
            const nonBlankRows = results.data.filter(
              row => !row.every(el => !el.trim())
            )
            if (isInvalidCsv(nonBlankRows)) {
              reject(new Error('Invalid CSV! Columns/rows mismatch.'))
            } else if (!isValidName(file.name)) {
              reject(new Error('Filename is not valid.'))
            } else {
              resolve()
              setTimeout(
                () =>
                  onUpload({
                    file,
                    results: nonBlankRows,
                  }),
                100
              )
            }
          },
          error: e => {
            reject(e)
          },
        })
      }
    })
  }
  const handleSampleDownload = e => {
    e.preventDefault()
    onDownloadCsv()
  }
  return (
    <div className="product-upload">
      <div className="bordered-box">
        <div className="text-center">
          <img src={uploadIcon} width="140" alt="" />
        </div>
        <Upload
          name="bulkProductUpload"
          accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
          placeholder={getMessage('category.bulk-upload')}
          uploadButtonText={getMessage('category.form.review')}
          cancelText={getMessage('category.form.cancelText')}
          onUpload={handleUpload}
          onchangeCallback={()=> setError(null)}
          validationStrings={{
            invalidFileType: getMessage('input.invalidFileType'),
          }}
          strings={{
            defaultMessage: getMessage('fileUpload.importCsv.heading'),
            progressMessage: getMessage('fileUpload.importedCsv.heading'),
            completionMessage: getMessage('fileUpload.uploadedCsv.success'),
          }}
          icon={csvIcon}
        />
      </div>
      <div className="text-center download-sample-text">
        <a className="download-link" onClick={handleSampleDownload}>
          {getMessage('productUpload.csvDownloadText')}
        </a>
      </div>
    </div>
  )
}

export default UploadFile
