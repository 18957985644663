import React, { Component } from 'react'
import './style.css'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import BasicInformationForm from './form'
import { getMessage } from '../../../lib/translator'

export default class BasicInformation extends Component {
  render() {
    return (
      <AuthenticatedPage
        className="BasicInformation"
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
        title={getMessage('basicInfo.heading')}
      >
        <h1 className="title">{getMessage('basicInfo.heading')}</h1>
        <BasicInformationForm />
      </AuthenticatedPage>
    )
  }
}
