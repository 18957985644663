import React, { Fragment } from 'react'
import Loader from '../../../components/Loader'
import PublicPage from '../../../containers/PublicPage/index'
import FPG_LOGO from './FPG.png'

export default class RedirectPage extends React.Component {
  render() {
    const { loading } = this.props

    return (
      <PublicPage showHeader={false} className="login">
        <Fragment>
          <header className="imgHeader" data-testid="Redirect-fpg-logo">
            <img src={FPG_LOGO} alt="FPG logo" />
          </header>
          {loading && (
            <div className="loaderWrapper" data-testid="Redirect-Loader">
              <Loader />
            </div>
          )}
        </Fragment>
      </PublicPage>
    )
  }
}
