import React from 'react'

export const Text = ({
  variant = 'span',
  color = '#000000',
  display = 'inline',
  fontWeight = 400,
  fontSize = 16,
  fontFamily,
  style = {},
  textAlign = 'center',
  margin = '0',
  width,
  minWidth,
  children,
  ...rest
}) => {
  const TextComponent = variant
  return (
    <TextComponent
      style={{
        color,
        display,
        fontWeight,
        fontSize,
        fontFamily,
        textAlign,
        margin,
        width,
        minWidth,
        ...style,
      }}
      {...rest}
    >
      {children}
    </TextComponent>
  )
}
