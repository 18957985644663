import React from 'react'
import { getMessage } from 'lib/translator'
import { Dialog } from 'components/Popup'
import API from 'lib/api'

const DeleteModule = ({
  setPopUp,
  activeModule,
  moduleValues,
  setIsLoading,
  setCacheValues,
  setActiveModule,
}) => {
  const deleteModule = () => {
    setPopUp(null)
    setIsLoading(true)
    new API({
      url: `/genie/lms/module?module_pk=${moduleValues[activeModule].id}`,
    })
      .delete()
      .then(() => {
        setCacheValues(null)
      })
  }

  return (
    <Dialog
      show
      information={getMessage('apphome.delete.confirmation')}
      title={getMessage('apphome.confirmation')}
      close={() => {
        setPopUp(null)
        setActiveModule(null)
      }}
      closeText={getMessage('apphome.cancel')}
      okText={getMessage('apphome.yes')}
      onOk={deleteModule}
    />
  )
}

export default DeleteModule
