import React from 'react'

const SubHeader = props => {
  return (
    <div>
      {props.foodSupplier.brand}, {props.location.name}
      <br />
      <ul className="food-supplier-names">
        <li>
          {props.foodSupplier.name} ({props.suborder.id})
        </li>
      </ul>
    </div>
  )
}

export default SubHeader
