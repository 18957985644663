import { getMessage } from 'lib/translator'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'

export const daysOfWeak = {
  Monday: { o: '', c: '' },
  Tuesday: { o: '', c: '' },
  Wednesday: { o: '', c: '' },
  Thursday: { o: '', c: '' },
  Friday: { o: '', c: '' },
  Saturday: { o: '', c: '' },
  Sunday: { o: '', c: '' },
}

const metaDataFields = [
  "Amendment Order Cutoff Time",
  "Amendment Order Enabled",
  "B2B Delivery Days",
  "B2B Enabled",
  "Batch Order Enabled",
  "Batch Order Percentage",
  "CLS Merchant ID",
  "CLS Operator ID",
  "CLS Terminal ID",
  "Delivery Days",
  "Delivery type",
  "Email",
  "FFS Exclusive",
  "Fax",
  "Fresh Report Picking",
  "Is SnG Store",
  "Link ID",
  "POA M2 Enabled",
  "Past Stock Reservation Days",
  "Phone",
  "PreOrder Email",
  "SAP Code",
  "SAP Inventory Download",
  "SAP Posting Disabled",
  "SAP Zone Code",
  "Search And Browse Enabled",
  "SnG Check-in Blocked",
  "SnG Geo Checkin Radius",
  "SnG Operational Hours",
  "Store Code",
  "Store Format",
  "WCS Id",
  "Zone Picking Enabled"
]

export const flatDiffObject = (obj, str, prevResult) => {
  const result = prevResult ? prevResult : {}
  Object.keys(obj).forEach((key) => {
    const queryKey = str ? `${str}.${key}` : key
    if (Object.keys(obj[key]).includes('__old')) {
      result[queryKey] = _get(obj, key)
    } else if (key.includes('__added')) {
      const newQueryKey = queryKey.replace('__added', '')
      result[newQueryKey] = { __new: _get(obj, key), __old: '' }
    } else {
      flatDiffObject(obj[key], queryKey, result)
    }
  })
  return result
}

export const fieldNameMapping = {
  name: getMessage('operations.store.form.name.heading'),
  address: getMessage('operations.store.form.address.heading'),
  hasPicking: getMessage('operations.store.form.hasPicking.label'),
  hasDeliveryHub: getMessage('operations.store.form.hasDeliveryHub.label'),
  hasClickCollect: getMessage('operations.store.form.hasClickCollect.label'),
  hasSelfCheckout: getMessage('operations.store.form.hasSelfCheckout.label'),
  tierSetupId: getMessage('operations.store.form.deliveryFee.label'),
  b2bTierSetupId: getMessage('operations.store.form.b2bDeliveryFee.label'),
  storeImgUrl: 'Image',
  pickingStoreId: 'Picking store',
  ...Object.values(metaDataFields).reduce((t, v) => {
    t[`metaData.${v}`] = v
    return t
  }, {}),
  ...Object.keys(daysOfWeak).reduce((t, v) => {
    t[`businessHours.${v}.o`] = `${v} Opening Time`
    t[`businessHours.${v}.c`] = `${v} Closing Time`
    return t
  }, {}),
}

export const parseSngHours = (obj) => {
  const metaData = obj.metaData
  const sngHours = obj.metaData?.['SnG Operational Hours']
  let parsedData = {}
  try {
    parsedData = {
      'SnG Operational Hours': sngHours
        ? JSON.parse(sngHours)
        : _cloneDeep(daysOfWeak),
    }
  } catch (error) {
    parsedData = {}
  }
  return {
    ...obj,
    metaData: {
      ...metaData,
      ...parsedData,
    },
  }
}

export const parseEmptyValue = (str) => {
  return str === undefined || str === null || str === ''
    ? 'empty'
    : str.toString()
}
