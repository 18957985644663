import React, { useContext } from 'react'
import { Input, ProductSearch } from '../../../../../components/Form'
import Image from '../../../../../components/Image'
import Table, { Cell, Header, Row } from '../../../../../components/Table'
import { SplitContext } from '../../../../../containers/SplitContext'
import SPLIT_FEATURES from '../../../../../containers/SplitContext/features'
import { getMessage } from '../../../../../lib/translator'

export default function EligibleItems({
  addEligibleItems,
  removeEligibleItems,
  items,
  generateStateMappers,
}) {
  const splitConfig = useContext(SplitContext)
  if (splitConfig.loading) {
    return <></>
  }
  const { splits } = splitConfig
  const isEnabled =
    splits?.[SPLIT_FEATURES.PRICING_VOUCHER_PRODUCT_DETAILS]?.treatment === 'on'
  if (!isEnabled) {
    return <></>
  }

  return (
    <div className="customer-level-restrictions">
      <h3 data-testid="offer-eligible-item">
        {getMessage('offer.eligible.items')}
      </h3>
      <div className="form-fields">
        <div>
          <ProductSearch
            label={getMessage(`offer.product`)}
            name={`eligible-item-searchable-pdt`}
            placeholder={getMessage(`offer.product.placeholder`)}
            onChange={addEligibleItems}
            readOnly={items.length >= 20}
          />
          {!!items.length && (
            <Table>
              <Header>
                <Cell>Image</Cell>
                <Cell>Name</Cell>
                <Cell></Cell>
              </Header>
              {items.map((item, i) => (
                <Row key={item.sku}>
                  <Cell>
                    <span data-testid={`eligible-item-image-${item.sku}`}>
                      <Image size="sm" src={item.image} />{' '}
                    </span>
                  </Cell>
                  <Cell align="align-left">
                    <span data-testid={`eligible-item-name-${item.sku}`}>
                      {item.name}
                    </span>
                  </Cell>
                  <Cell>
                    <button
                      data-testid={`eligible-item-delete-${item.sku}`}
                      className="delete-button button"
                      type="button"
                      onClick={() => removeEligibleItems(i)}
                    />
                  </Cell>
                </Row>
              ))}
            </Table>
          )}
        </div>
        <div>
          <Input
            type="text"
            name="web-redirect-link"
            label={getMessage('offer.eligible.items.website')}
            placeholder={getMessage('offer.eligible.items.website.placeholder')}
            {...generateStateMappers({
              stateKeys: ['eligibleItemsLinks', 'web'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            type="text"
            name="web-redirect-link"
            label={getMessage('offer.eligible.items.mobile')}
            placeholder={getMessage('offer.eligible.items.mobile.placeholder')}
            {...generateStateMappers({
              stateKeys: ['eligibleItemsLinks', 'mobile'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
      </div>
    </div>
  )
}
