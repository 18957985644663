import React, { Component } from 'react'
import {
  Input,
  Radio,
  ProductSearch,
  BrandSearch,
  CategorySearch,
} from '../../../../components/Form'
import ItemTable from './ItemTable'
import { getMessage } from '../../../../lib/translator'
import { handleFocus } from './FormTabs/utils'
import CommonForm from './CommonForm'

class BANYGYD extends Component {
  constructor(props) {
    super(props)
    this.validationHandler = this.validationHandler.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
  }

  validationHandler(parent) {
    const formData = JSON.parse(JSON.stringify(parent.state.values))
    const validations = JSON.parse(JSON.stringify(parent.state.validations))
    const comboDiscount = formData.itemDiscountType === 'COMBO_DISCOUNT'
    const entityType = 'product'
    if (comboDiscount && validations[entityType]) {
      validations[entityType].forEach((validation) => {
        delete validation.t
        delete validation.v
      })
    } else {
      if (validations.rule) {
        delete validations.rule.total
      }
    }
    parent.setState({
      validations,
    })
  }

  submitHandler(formData, buyKey) {
    const data = Object.assign({}, formData)
    const comboDiscount = formData.itemDiscountType === 'COMBO_DISCOUNT'
    const entityType = formData.entityType.toLowerCase()
    data.offerType = data.type
    const get = {}
    if (!comboDiscount) {
      delete data.rule.total
    }
    const rule = {
      limit: data.rule ? Number(data.rule.limit) : null,
      quantity: data.rule.quantity ? Number(data.rule.quantity) : null,
      total: comboDiscount
        ? { ...data.rule.total, v: Number(data.rule.total.v) }
        : undefined,
      entity: {
        type: data.entityType === 'PRODUCT' ? 'VARIANT' : formData.entityType,
        id:
          data.entityType === 'PRODUCT'
            ? null
            : data[entityType] && data[entityType].id,
      },
      get: {},
    }
    rule.limit = data.rule ? Number(data.rule.limit) : null
    if (data.rule.quantity) {
      rule.quantity = Number(data.rule.quantity)
    }
    if (comboDiscount) {
      rule.total = data.rule.total
      rule.total.v = Number(rule.total.v)
    }
    rule.entity = {
      type: data.entityType === 'PRODUCT' ? 'VARIANT' : formData.entityType,
      id:
        data.entityType === 'PRODUCT'
          ? null
          : data[entityType] && data[entityType].id,
    }
    const getItems = data.product || []
    getItems.forEach((item) => {
      const res = { q: Number(item.q) }
      if (!comboDiscount) {
        res.t = item.t
        res.v = Number(item.v)
      }
      get[item.id] = res
    })
    rule.get = get
    data.rule = rule
    // data[entityType] = data[`${buyKey}${entityType}`].map(item => item.id)
    if (entityType === 'product') {
      rule.variants = (data[`${buyKey}${entityType}`] || [])
        .map((pdt) => pdt.id)
        .filter(Boolean)
    }
    delete data.product
    delete data.category
    delete data.brand
    delete data[`${buyKey}product`]
    delete data[`${buyKey}category`]
    delete data[`${buyKey}brand`]
    return data
  }

  render() {
    const {
      entityType: entityTypeProp,
      discountTypes,
      itemDiscountTypes,
      _this,
      buyKey,
      hasOfferStarted,
      isPayViaFpApp,
    } = this.props
    const { values } = _this.state
    const { itemDiscountType } = _this.state.values
    let entityType = entityTypeProp
    entityType = this.props.entityTypeMap[entityType]
    const comboDiscount = itemDiscountType === 'COMBO_DISCOUNT'
    const buyItems = values[`buy${entityType}`]
    const buyItemsLength = buyItems ? buyItems.length : 0
    const items = values.product
    const itemsLength = items ? items.length : 0
    const availableItemDiscountTypes = isPayViaFpApp
      ? itemDiscountTypes.filter((item) => item.linkPay)
      : itemDiscountTypes
    return (
      <div className="offers-form">
        <div className="offer-fields">
          <Radio
            name="discount-type-main"
            label={getMessage('offer.main.discount.type')}
            placeholder={getMessage('offer.main.discouont.type.placeholder')}
            options={availableItemDiscountTypes}
            {..._this.generateStateMappers({
              stateKeys: ['itemDiscountType'],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
          <Input
            type="number"
            onFocus={handleFocus}
            name="quantity"
            min={1}
            required
            label={getMessage('offer.quantity')}
            placeholder={getMessage('offer.quantity.placeholder')}
            {..._this.generateStateMappers({
              stateKeys: ['rule', 'quantity'],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
          {comboDiscount && (
            <CommonForm
              discountTypes={discountTypes}
              _this={_this}
              hasOfferStarted={hasOfferStarted}
              values={values}
            />
          )}
        </div>
        <h3 className="top-o">{`Buy`}</h3>
        {entityType === 'product' && (
          <ProductSearch
            className="product-searchable-max"
            key={`item-searchable-pdt`}
            name={`item-searchable-pdt`}
            label={getMessage(`offer.${entityType}`)}
            placeholder={getMessage(`offer.${entityType}.placeholder`)}
            onChange={_this.addItemBuy}
            readOnly={hasOfferStarted}
          />
        )}
        {entityType === 'brand' && (
          <BrandSearch
            name="item-serchable-brand"
            className="product-searchable-max"
            label={getMessage(`offer.${entityType}`)}
            placeholder={getMessage(`offer.${entityType}.placeholder`)}
            {..._this.generateStateMappers({
              stateKeys: ['brand'],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
        )}
        {entityType === 'category' && (
          <CategorySearch
            name="category"
            className="product-searchable-max"
            transformResponse={(response) => response.data.category}
            label={getMessage(`offer.${entityType}`)}
            placeholder={getMessage(`offer.${entityType}.placeholder`)}
            {..._this.generateStateMappers({
              stateKeys: ['category'],
              loseEmphasisOnFill: true,
            })}
            readOnly={hasOfferStarted}
          />
        )}
        <div>
          {buyItemsLength ? (
            <ItemTable
              comboDiscount
              entityType={entityType}
              items={buyItems}
              discountTypes={discountTypes}
              _this={_this}
              buy
            />
          ) : null}
        </div>
        <h3>Get</h3>
        {
          <ProductSearch
            className="product-searchable-max"
            key={`item-searchable-pdt-get`}
            name={`item-searchable-pdt-get`}
            label={getMessage(`offer.product`)}
            onChange={_this.addItem}
            readOnly={hasOfferStarted}
          />
        }
        <div>
          {itemsLength ? (
            <ItemTable
              comboDiscount={comboDiscount}
              entityType={entityType}
              items={items}
              discountTypes={discountTypes}
              _this={_this}
              buyKey={buyKey}
              hasOfferStarted={hasOfferStarted}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

export default BANYGYD
