/**
 *
 * @param {*} formData
 * @param {*} props
 * @param {*} isFeatureEnabled
 */
export const injectAutomationFlag = (formData, props) => {
  // feature flag wrapper
  const ACTION_TARGET = 'add'
  let actionType
  try {
    actionType = props.match.params.action
  } catch (err) {
    /** At this point no , simply default actiontype = null */
    actionType = undefined
  }
  if (actionType === ACTION_TARGET) {
    formData['isAutomation'] = true
  } else {
    delete formData['isAutomation']
  }
  return formData
}

