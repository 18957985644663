import React, { useMemo } from 'react'
import { getMessage } from 'lib/translator'
import Table, { Header, Row, Cell } from 'components/Table'
import SingleOrderItemRow from './SingleOrderItemRow'
import {
  getCalculatedSubtotalOrderValues,
  getCalculatedSummaryValues,
} from 'lib/commonlyused/orderCalculations'
import { isBeforePackedOrCancelled } from '../helpers.utils'

import './style.scss'

function OrderItemsView({
  deliveryOrderData,
  currency,
  isDfOrder,
  isSplitOrder,
  itemEditMode,
  updateOrderItem,
}) {
  const { refundAmount } = deliveryOrderData
  const totalItemsCount = deliveryOrderData.items.length
  const vouchers = deliveryOrderData.discounts.filter(
    (item) => item.type === 'DISCOUNT_TYPE_VOUCHER'
  )
  const offers = deliveryOrderData.discounts.filter(
    (item) => item.type === 'DISCOUNT_TYPE_OFFER'
  )

  // without useMemo, the summary values will be calculated on every item row render
  const {
    subTotalPrice,
    subTotalDiscount,
    originalSubTotalAmount,
    finalSubTotalAmount,
  } = useMemo(() => {
    return getCalculatedSubtotalOrderValues(
      {
        items: deliveryOrderData.items,
        subTotal: deliveryOrderData.subTotal,
      },
      'deliveryOrder'
    )
  }, [deliveryOrderData])

  const {
    originalTotalAmount,
    finalTotalAmount,
    pendingAmount,
    pendingAmountText,
  } = getCalculatedSummaryValues(
    {
      discounts: deliveryOrderData.discounts,
      subTotal:
        finalSubTotalAmount !== undefined
          ? finalSubTotalAmount
          : deliveryOrderData.subTotal, // take subtotalAmount from calculation because deliveryOrderData.subTotal is wrong
      finalSubTotalAmount: finalSubTotalAmount,
      originalSubTotalAmount: originalSubTotalAmount,
      deliveryFee: deliveryOrderData.currentDeliveryFee,
      serviceFee: deliveryOrderData.currentServiceFee,
      payments: deliveryOrderData.payments,
    },
    'deliveryOrder'
  )
  const freeItems = deliveryOrderData.freeItems
    ? deliveryOrderData.freeItems
    : []
  const hasRefundAmount = Boolean(refundAmount) // it return false for null, undefined, 0 and "" if value is checked with Boolean(value)

  return (
    <div className="delivery-order-list-items-container">
      <div className="flex-around section-title">
        <h3>
          {getMessage('order.details.itemsTable.title')}
          <span className="text-muted" data-testid="total-items-count">
            {' '}
            ({totalItemsCount})
          </span>
        </h3>
      </div>
      <Table>
        <Header>
          <Cell className="item-image">
            {getMessage('order.details.itemsTable.header.image')}
          </Cell>
          <Cell className="item-name">
            {getMessage('order.details.itemsTable.header.name')}
          </Cell>
          <Cell className="client-item-id">
            {getMessage('order.details.itemsTable.header.clientItemId')}
          </Cell>
          <Cell className="item-original text-right">
            {getMessage('order.details.itemsTable.header.originalQuantity')}
          </Cell>
          {!isBeforePackedOrCancelled(deliveryOrderData.status) && (
            <Cell className="item-final text-right">
              {getMessage('order.details.itemsTable.header.finalQuantity')}
            </Cell>
          )}
          {!itemEditMode && (
            <>
              <Cell className="item-mrp text-right">
                {getMessage('order.details.itemsTable.header.mrp')}
              </Cell>
              <Cell className="item-discount text-right">
                {getMessage('order.details.itemsTable.header.discount')}
              </Cell>
              <Cell className="item-amount text-right">
                {getMessage('order.details.itemsTable.header.amount')}
              </Cell>
            </>
          )}
        </Header>
        {isDfOrder && (
          <div className="sub-heading">
            <div>
              <strong>{deliveryOrderData.seller.name}</strong>
              {isDfOrder && (
                <span style={{ color: '#80959d' }}>
                  &nbsp;(Direct Fulfilment)
                </span>
              )}
            </div>
          </div>
        )}
        {[...deliveryOrderData.items, ...freeItems].map((item, index) => {
          const rowClassName = index === 0 && isDfOrder ? 'offset-top' : ''
          if (item.originalItem) {
            // Remark: this condition will not be true till backend fix is done to include originalItem in deliveryOrder response
            return (
              <React.Fragment key={index}>
                <SingleOrderItemRow
                  item={item}
                  currency={currency}
                  isSubstituted={!!item.originalItem}
                  orderStatus={deliveryOrderData.status}
                  rowClassName={rowClassName}
                  itemEditMode={itemEditMode}
                  updateOrderItem={updateOrderItem}
                />
                <SingleOrderItemRow
                  item={{
                    ...item.originalItem,
                    mrp: 0, // TODO: to replace with actual mrp of original item if delivery order response has originalItem
                    discount: 0, // no need to display actual discount value
                    amount: 0, // no need to display amount as it is already substituted
                    orderedQuantity: item.orderedQuantity,
                    deliveredQuantity: 0,
                    item: item.originalItem,
                  }}
                  isOriginalItem={!!item.originalItem}
                  currency={currency}
                  orderStatus={deliveryOrderData.status}
                  rowClassName={rowClassName}
                  itemEditMode={itemEditMode}
                  updateOrderItem={updateOrderItem}
                />
              </React.Fragment>
            )
          }
          return (
            <SingleOrderItemRow
              key={item.item.itemId + index}
              item={item}
              currency={currency}
              orderStatus={deliveryOrderData.status}
              rowClassName={rowClassName}
              itemEditMode={itemEditMode}
              updateOrderItem={updateOrderItem}
            />
          )
        })}
        {/* subtotal amounts */}
        {!itemEditMode && (
          <Row className="black-text">
            <Cell />
            <Cell />
            <Cell />
            {!isBeforePackedOrCancelled(deliveryOrderData.status) && <Cell />}
            <Cell className="text-right">
              <small>{getMessage('operations.dashboard.subTotal')}</small>
            </Cell>
            <Cell className="text-right">
              <small>
                {currency.symbol} {subTotalPrice.toFixed(2)}
              </small>
            </Cell>
            <Cell className="text-right">
              <small>
                {currency.symbol} {subTotalDiscount.toFixed(2)}
              </small>
            </Cell>
            <Cell className="text-right">
              <small>
                <FinalAmount
                  orderStatus={deliveryOrderData.status}
                  originalAmount={originalSubTotalAmount.toFixed(2)}
                  finalAmount={finalSubTotalAmount.toFixed(2)}
                  currency={currency}
                />
              </small>
            </Cell>
          </Row>
        )}
      </Table>
      {/* summary amounts */}
      {!itemEditMode && (
        <div className="invoice-summary-wrapper">
          <div className="invoice-summary">
            {vouchers.length > 0 && (
              <GroupedDiscounts
                items={vouchers}
                text="saleOrder.details.summary.vouchers"
                currency={currency}
              />
            )}
            {offers.length > 0 && (
              <GroupedDiscounts
                items={offers}
                text="saleOrder.details.summary.offers"
                currency={currency}
              />
            )}
            <ShowAmounts
              clName="small-size"
              text="order.details.summary.shippingCharge"
              testId="delivery-order-shipping-charge"
              value={deliveryOrderData.currentDeliveryFee.toFixed(2)}
              currency={currency}
            />
            <ShowAmounts
              clName="small-size"
              text="saleOrder.details.summary.serviceFee"
              testId="delivery-order-service-fee"
              value={deliveryOrderData.currentServiceFee.toFixed(2)}
              currency={currency}
            />
            <div className="flex-around">
              <div className="text-muted">
                {getMessage('order.details.summary.totalAmount')}:
              </div>
              <div>
                <span
                  className="section-value"
                  data-testid="delivery-order-total-amount"
                >
                  <FinalAmount
                    orderStatus={deliveryOrderData.status}
                    originalAmount={originalTotalAmount.toFixed(2)}
                    finalAmount={finalTotalAmount.toFixed(2)}
                    currency={currency}
                  />
                </span>
              </div>
            </div>
            {!isSplitOrder &&
              !hasRefundAmount &&
              !isBeforePackedOrCancelled(deliveryOrderData.status) && (
                <>
                  <ShowAmounts
                    clName="pending-amount"
                    text="order.details.summary.pendingAmount"
                    testId="delivery-order-pending-amount"
                    value={Number(pendingAmount).toFixed(2)}
                    currency={currency}
                  />
                  {deliveryOrderData.status ===
                    'DELIVERY_ORDER_STATUS_COMPLETED' &&
                    pendingAmountText && (
                      <div className="pending-amount-text text-muted">
                        ({pendingAmountText})
                      </div>
                    )}
                </>
              )}
            {hasRefundAmount && (
              <div className="flex-around">
                <div className="text-muted ">
                  {getMessage('order.details.summary.refundAmount')}:
                </div>
                <div>
                  <span
                    className="section-value"
                    data-testid="delivery-order-refund-amount"
                  >
                    {isSplitOrder ? (
                      'Not Available'
                    ) : (
                      <>
                        {currency.symbol} {Number(refundAmount).toFixed(2)}
                      </>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

/*
if order is before packed or cancelled,
  display originalAmount
else,
  if originalItemAmount <= finalItemAmount,
    display originalAmount
  else,
    if it's weighted item, show min of originalAmount and finalAmount
    display finalAmount, then display originalAmount with strikethrough
*/
export function FinalAmount({
  orderStatus,
  originalAmount,
  finalAmount,
  currency,
}) {
  if (
    isBeforePackedOrCancelled(orderStatus) ||
    Number(originalAmount) <= Number(finalAmount)
  ) {
    return (
      <>
        {currency.symbol}
        {originalAmount}
      </>
    )
  }
  return (
    <>
      {currency.symbol}
      {finalAmount}&nbsp;
      <s data-testid="strike-through-text">
        {currency.symbol}
        {originalAmount}
      </s>
    </>
  )
}

export const ShowAmounts = ({ clName, text, value, testId, currency }) => (
  <div className={`flex-around ${clName || ''}`}>
    <div className="text-muted ">{getMessage(text)}:</div>
    <div>
      <span className="section-value" data-testid={testId}>
        {currency.symbol} {value}
      </span>
    </div>
  </div>
)

export const GroupedDiscounts = ({ items, text, currency }) => (
  <div className="grouped-discounts">
    <div className="flex-around discount-amount text-muted">
      {getMessage(text)}:
    </div>
    {items.map((item, i) => (
      <div className="flex-around discount-amount" key={'discount-' + i}>
        <div className="text-muted code">
          {item.description !== '' ? item.description : item.code}
        </div>
        <div className="discount-amount-nowrap">
          <span>-{currency.symbol}</span>
          <span className="section-value">
            {Number(item.discount).toFixed(2)}
          </span>
        </div>
      </div>
    ))}
  </div>
)

export default OrderItemsView
