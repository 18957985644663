import React, { Component } from 'react'
import { getMessage } from '../../../../lib/translator'
import { DropDown, DropDownItem } from '../../../../components/DropDown'

import './style.css'

export default class AddDeliveryArea extends Component {
  render() {
    const {
      handleEditValuesOpen,
      editing,
      changeEditMode,
      handleKmlUpload,
    } = this.props
    return (
      <div className="add-delivery-area">
        <DropDown
          icon={
            <div className="button primary">
              {' '}
              + <span>Add Delivery Area </span>
            </div>
          }
        >
          <div>
            <DropDownItem
              onClick={() => {
                !editing && changeEditMode()
                handleEditValuesOpen('radial')
              }}
            >
              {getMessage('deliveryArea.dropdown.radial.heading')}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                !editing && changeEditMode()
                handleEditValuesOpen('pincode')
              }}
            >
              {getMessage('deliveryArea.dropdown.pincode.heading')}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                !editing && changeEditMode()
              }}
            >
              {getMessage('deliveryArea.dropdown.polygon.heading')}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                handleKmlUpload()
              }}
            >
              {getMessage('Upload KML file')}
            </DropDownItem>
          </div>
        </DropDown>
      </div>
    )
  }
}
