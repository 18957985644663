var BUList = [
    { value : 'RB', text: 'RB'},
    { value : 'DB', text: 'DB'},
    { value : 'FS', text: 'FS'},
]

var PackageTypeList = [
    { value : 'FIXED', text: 'Fixed'},
    { value : 'FLEXIBLE', text: 'Flexible'},
]

var offsiteTypeList = [
    { value: 'EDM Feature', text: 'EDM Feature'},
    { value: 'Dedicated EDM', text: 'Dedicated EDM'},
    { value: 'PNS', text: 'PNS'},
    { value: 'Geofence PNS', text: 'Geofence PNS'},
]

export {
    BUList,
    PackageTypeList,
    offsiteTypeList
}
