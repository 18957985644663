import React, { Fragment } from 'react'
import {
  BaseForm,
  SingleDatePicker,
  VALIDATION_TYPES,
  Input,
} from '../../../Form'
import deleteIcon from '../delete.svg'
import { validationMsgs } from '../../utils'
import { getMessage } from '../../../../lib/translator'

export default class AddDraw extends BaseForm {
  constructor(props) {
    super(props)

    this.state.values = {
      inputList: [
        { cutOffDate: '', numberOfWinners: '', numberOfReserveWinners: '' },
      ],
    }

    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
  }

  // handle click event of the Add button
  handleAddClick() {
    const values = Object.assign({}, this.state.values)
    const data = {
      cutOffDate: '',
      numberOfWinners: '',
      numberOfReserveWinners: '',
    }
    const inputList = values.inputList
    inputList.push(data)
    this.setState({
      values: values,
    })
  }

  // handle click event of the delete button
  handleRemoveClick(e, index) {
    const values = Object.assign({}, this.state.values)
    const validations = Object.assign({}, this.state.validations)
    const inputList = values.inputList
    inputList.splice(index, 1)
    this.setState({
      values,
      validations,
    })
  }

  render() {
    const { _this } = this.props
    const { inputList } = this.state.values
    Object.assign(this.state.values, _this.state.values)

    return inputList.map((x, i) => (
      <div className="single-column-field" key={i}>
        <div className="draw-popup">
          <div className="draw-header">
            <div className="header-1" data-testid={`draw ${i + 1}`}>
              {getMessage('luckydraw.addDraw')} {i + 1}
            </div>
            <div>
              {inputList.length !== 1 && (
                <button
                  className="mr10-luckydraw"
                  type="button"
                  data-testid={`delete-btn-${i + 1}`}
                  onClick={e => this.handleRemoveClick(e, i)}
                >
                  <img src={deleteIcon} alt="delete" />
                </button>
              )}
            </div>
          </div>
          <div className="draw-details">
            <SingleDatePicker
              enableToday
              name="cut-off-date"
              testid={`cutOffDate-${i + 1}`}
              className="full-width-mobile"
              required
              disabled={_this.state.campaignStarted}
              label={getMessage('luckydraw.cutOffDate.label')}
              tooltip={
                <Fragment>
                  <div>
                    <b>{getMessage('luckydraw.tooltip-note')}</b>
                  </div>
                  <div>
                    {getMessage('luckydraw.draw')}
                    <br />
                    <br />
                    {getMessage('luckydraw.draw-1')}
                  </div>
                </Fragment>
              }
              {..._this.generateStateMappers({
                stateKeys: ['inputList', i, 'cutOffDate'],
                loseEmphasisOnFill: true,
              })}
            />
            <div className="grid details full-width-mobile">
              <Input
                label={getMessage('luckydraw.noOfwinners.label')}
                value={x.numberOfWinners}
                testid={`numberOfWinners-${i + 1}`}
                type="number"
                step="1"
                min={0}
                max={1000000}
                {..._this.generateStateMappers({
                  stateKeys: ['inputList', i, 'numberOfWinners'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                required
                validationStrings={validationMsgs}
              />
              <Input
                label={getMessage('luckydraw.noOfreserveWinners.label')}
                value={x.numberOfReserveWinners}
                testid={`numberOfReserveWinners-${i + 1}`}
                type="number"
                step="1"
                min={0}
                max={1000000}
                {..._this.generateStateMappers({
                  stateKeys: ['inputList', i, 'numberOfReserveWinners'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                required
                validationStrings={validationMsgs}
              />
            </div>
          </div>
        </div>
        {inputList.length - 1 === i && (
          <button
            data-testid="add-draw-btn"
            className="button add-content-block-lucky"
            type="button"
            onClick={this.handleAddClick}
            disabled={_this.state.campaignStarted}
          >
            {getMessage('luckydraw.addDraw.button')}
          </button>
        )}
      </div>
    ))
  }
}
