import { getMessage } from '../../lib/translator'
import API from '../../lib/api'
import { getDateValues } from './utils'

const getApiDetails = async (_this, type) => {
  const values = Object.assign({}, _this.state.values)
  values.loading = true
  _this.setState({
    values,
  })
  const { id } = _this.props.value
  try {
    const api = new API({ url: `/rms/${type}/${id}` })
    const response = await api.get()
    let startDateTime = response.startAt
    let endDateTime = response.endAt
    if (type === 'carousels') {
      startDateTime = response.startDate
      endDateTime = response.endDate
      values.title = response.title
      values.subliner = response.subliner
    }
    const dateValues = getDateValues(startDateTime, endDateTime)
    values.date = { ...dateValues }
    values.loading = false
    _this.setState({
      values,
    })
  } catch (err) {
    handleError(err, _this)
  }
}

export const fetchDetailsById = (id, type) => {
  const api = new API({ url: `/admin/${type}/${id}` })
  return api.get()
}

export const fetchAllMerchants = () => {
  const api = new API({ url: `/admin/merchants?limit=1000` })
  return api.get()
}

const handleApis = async (data, url, _this, id = '') => {
  try {
    const values = Object.assign({}, _this.state.values)
    values.loading = true
    _this.setState({
      values,
    })
    let response = ''
    if (_this.props.method === 'add') {
      const api = new API({ url })
      response = await api.post(data)
    } else {
      const api = new API({ url: `${url}/${id}` })
      response = await api.patch(data)
    }
    response && _this.props.onCancel()
  } catch (e) {
    handleError(e, _this)
  }
}

const handleError = (e, _this) => {
  const { values } = _this.state
  _this.setState({
    values: {
      ...values,
      loading: false,
      error: e.message || getMessage('rewards.defaultError'),
    },
  })
}

export { getApiDetails, handleApis, handleError }
