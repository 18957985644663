import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Popup } from 'components/Popup'
import { Input } from 'components/Form'
import { format, parse } from 'libphonenumber-js'
import { STORE_TECH_BASE_URL } from '../../pages/customer-support/constants'
import Loader from '../Loader'
import './style.scss'
import API from '../../lib/api'
import { getMessage } from 'lib/translator'
import { LINKPASS_ADMIN_URL, LINK_HOST } from 'config/app'
import { hasPermissions } from 'lib/auth'

class CustomerDetailsCard extends Component {
  constructor() {
    super()
    this.state = {
      errorMessage: '',
      loading: false,
      success: false,
      showDeleteLinkpassPopup: false,
      deleteReason: '',
      vc: '',
      hasGetLinkAccPermissions: hasPermissions(
        'loyalty',
        'link/search-users',
        'post',
        true
      ),
    }
    this.handleUnblockCustomer = this.handleUnblockCustomer.bind(this)
    this.handleDeleteLinkpassUser = this.handleDeleteLinkpassUser.bind(this)
    this.handleChangeReason = this.handleChangeReason.bind(this)
  }
  handleUnblockCustomer(customerID) {
    const api = new API({
      url: `${STORE_TECH_BASE_URL}/store/v0.2/customers/unblock/${customerID}`,
    })
    this.setState({
      loading: true,
      sucess: false,
    })
    api
      .put()
      .then(
        () => {
          // window.location.reload()
          this.setState({
            errorMessage: '',
            success: true,
          })
        },
        (error) => {
          if (error.code === 401) {
            throw error
          }
          this.setState({
            errorMessage: 'Unable to verify',
            success: false,
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  handleDeleteLinkpassUser() {
    this.setState({ loading: true })
    const deleteLinkpassUserApi = new API({
      url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/user/uid/${this.props.uid}/permanent?reason=${this.state.deleteReason}`,
    })
    deleteLinkpassUserApi
      .delete()
      .then(() => {
        this.setState({
          showDeleteLinkpassPopup: false,
          loading: false,
          errorMessage: '',
        })
        this.props.router.history.push('/customer-support/customers')
      })
      .catch((err) => {
        this.setState({ loading: false, errorMessage: err.message })
      })
  }

  handleChangeReason(e) {
    this.setState({ deleteReason: e })
  }

  componentDidMount() {
    const linkAccByUidApi = new API({
      url: `${LINK_HOST}/users/search`,
    })
    this.state.hasGetLinkAccPermissions &&
      linkAccByUidApi
        .post({ uid: [this.props.uid] })
        .then((res) => {
          this.setState({ vc: res[0]?.vc })
        })
        .catch(() => {
          // show default backoffice error
        })
  }

  render() {
    const { name, phone, email, uid, id, editHandler } = this.props
    return (
      <div className="CustomerDetailsCard">
        <div className="customer-details">
          <div className="detail">
            <div>{name}</div>
            {this.props.isEntity ? (
              <>
                <div style={{ textAlign: 'left' }}>
                  UEN: <small>{this.props.uen}</small>
                </div>
                <div className="button-container">
                  <button
                    data-testid="button-edit"
                    className="button edit-btn"
                    onClick={() => editHandler()}
                  >
                    Edit
                  </button>
                  {this.props.isSaved && (
                    <div className="changes-saved">
                      {getMessage('eVoucher.entities.saved')}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div>{email}</div>
                <div>
                  {phone && phone.length > 0
                    ? format(parse(phone), 'International')
                    : phone}
                </div>
                <br />
                <div
                  style={{
                    color: 'rgb(128, 149, 157)',
                    fontWeight: 400,
                    fontSize: 11,
                  }}
                >
                  UID: {uid}
                </div>
                {this.state.hasGetLinkAccPermissions ? (
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: 11,
                    }}
                  >
                    {' '}
                    VC (Link):&nbsp;
                    {this.state.vc ? (
                      <a href={`/customer-support/link/vc/${this.state.vc}`}>
                        {this.state.vc}
                      </a>
                    ) : (
                      'none'
                    )}
                  </div>
                ) : null}
                {this.state.success ? (
                  <span className="success">Unblocked successfully!</span>
                ) : (
                  <div className="button-container">
                    {this.state.loading ? (
                      <Loader size="sm" />
                    ) : (
                      <button
                        className="button"
                        onClick={() => this.handleUnblockCustomer(id)}
                      >
                        Unblock Scan & Go
                      </button>
                    )}
                    {this.state.errorMessage && (
                      <span className="error">{this.state.errorMessage}</span>
                    )}
                  </div>
                )}
                {this.props.showDeleteLinkpassButton && (
                  <button
                    className="delete-linkpass-button"
                    onClick={() =>
                      this.setState({ showDeleteLinkpassPopup: true })
                    }
                  >
                    <b>Delete Linkpass Profile</b>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        <Popup
          show={this.state.showDeleteLinkpassPopup}
          heading={'Delete Linkpass'}
          close={() =>
            this.setState({ showDeleteLinkpassPopup: false, errorMessage: '' })
          }
        >
          <div className="delete-linkpass-popup">
            <p>Are you sure you want to delete linkpass profile?</p>
            {this.state.errorMessage.length > 0 ? (
              <p>Error: {this.state.errorMessage}</p>
            ) : (
              <>
                <Input
                  type="text"
                  label={getMessage(
                    'customerSupport.customer.deleteLinkpass.label'
                  )}
                  placeholder={getMessage(
                    'customerSupport.customer.deleteLinkpass.placeholder'
                  )}
                  name="deleteReason"
                  value={this.state.deleteReason}
                  onChange={this.handleChangeReason}
                />
                <button
                  onClick={this.handleDeleteLinkpassUser}
                  disabled={this.state.deleteReason.length === 0}
                >
                  {this.state.loading ? <Loader size="sm" /> : <b>Delete</b>}
                </button>
              </>
            )}
          </div>
        </Popup>
      </div>
    )
  }
}

export default withRouter(({ location, history, match, ...props }) => (
  <CustomerDetailsCard router={{ location, history, match }} {...props} />
))
