import React from 'react'
import classNames from '../../lib/utils/classNames'
import './style.css'
import leftArrow from './icon-left-arrow.svg'
import rightArrow from './icon-right-arrow.svg'
import leftSkipArrow from './icon-skip-left-arrow.svg'
import rightSkipArrow from './icon-skip-right-arrow.svg'

/* TODO: Logic to selectively show page numbers if there are too many pages */

const noop = () => {}
const paginationBtnDisabled = ' pagination-button-disabled'

const skipRightArrowFn = (rightDisabled, clickHandler, total) => {
  return (
    <a
      className={
        'pagination-button' + (rightDisabled ? paginationBtnDisabled : '')
      }
      onClick={
        !rightDisabled
          ? () => {
              clickHandler(total)
            }
          : noop
      }
    >
      <img
        className="navigation-icon skip-right-arrow"
        src={rightSkipArrow}
        alt="Next"
      />
    </a>
  )
}

const skipLeftArrowFn = (leftDisabled, clickHandler) => {
  return (
    <a
      className={
        'pagination-button' + (leftDisabled ? paginationBtnDisabled : '')
      }
      onClick={
        !leftDisabled
          ? () => {
              clickHandler(1)
            }
          : noop
      }
    >
      <img
        className="navigation-icon skip-left-arrow"
        src={leftSkipArrow}
        alt="Previous"
      />
    </a>
  )
}

export default function Pagination(props) {
  const { total, current, skipArrows, offset, nextPrevButtonsEnabled } = props
  if (!Number.isInteger(total) || !Number.isInteger(current)) {
    return null
  }
  const pageNumbers = Array(total)
    .fill(0)
    .map((_, index) => index + 1)
  let spliced = pageNumbers.splice(1, current - 3)
  if (spliced.length) {
    pageNumbers.splice(1, 0, '...')
  }
  spliced = pageNumbers.splice(5, pageNumbers.length - 6)
  if (spliced.length) {
    pageNumbers.splice(5, 0, '...')
  }
  const leftDisabled = (current === 1 && !nextPrevButtonsEnabled) || offset === 0
  const rightDisabled = current === total && !nextPrevButtonsEnabled
  const clickHandler = props.onSelect || noop
  const ellipsis = <span>&#8230;</span>

  const pageNumberNotEditable = Boolean(props.pageNumberNotEditable) // Props for unwanted page number edit

  const visitPageDirectly = (e) => {
    if (e.which === 13) {
      if (e.target.value >= 1 && e.target.value <= total) {
        clickHandler(e.target.value)
      }
    }
  }

  return (
    <div className="pagination">
      {skipArrows && skipLeftArrowFn(leftDisabled, clickHandler)}
      <a
        className={
          'pagination-button' + (leftDisabled ? paginationBtnDisabled : '')
        }
        onClick={
          !leftDisabled
            ? () => {
                clickHandler(current - 1)
              }
            : noop
        }
      >
        {
          props.nextPrevButtonsEnabled ?
           <span>Prev</span>
           : <img className="navigation-icon" src={leftArrow} alt="Previous" />
        }
      </a>
      {!props.nextPrevButtonsEnabled && pageNumbers.map((number, index) => (
        <a
          key={Number.isFinite(number) ? number : 'hellip' + String(index)}
          className={classNames([
            'pagination-button',
            current === number ? 'pagination-button-highlighted' : '',
            Number.isFinite(number) ? '' : paginationBtnDisabled,
          ])}
          onClick={
            pageNumberNotEditable
              ? Number.isFinite(number)
                ? () => {
                    clickHandler(number)
                  }
                : null
              : Number.isFinite(number) && current !== number
                ? () => {
                    clickHandler(number)
                  }
                : null
          }
        >
          {Number.isFinite(number) ? (
            number === current && !pageNumberNotEditable ? (
              <input
                className="current-page-number"
                type="number"
                defaultValue={number}
                min={1}
                max={total}
                onKeyDown={visitPageDirectly}
              />
            ) : (
              number
            )
          ) : (
            ellipsis
          )}
        </a>
      ))}
      <a
        className={
          'pagination-button' + (rightDisabled ? paginationBtnDisabled : '')
        }
        onClick={
          !rightDisabled
            ? () => {
                clickHandler(current + 1)
              }
            : noop
        }
      >
        {
          props.nextPrevButtonsEnabled ?
          <span>Next</span>
          : <img className="navigation-icon" src={rightArrow} alt="Next" />
        }
      </a>
      {skipArrows && skipRightArrowFn(rightDisabled, clickHandler, total)}
    </div>
  )
}
