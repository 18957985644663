import React from 'react'
import {
  BaseForm,
  Input,
  Phone,
  VALIDATION_TYPES,
} from '../../../../../components/Form'
import { getMessage } from '../../../../../lib/translator'

export default class InviteForm extends BaseForm {
  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons
    return (
      <Form>
        <Input
          label={getMessage('settings.users.inviteForm.name.heading')}
          placeholder={getMessage('settings.users.inviteForm.name.placeholder')}
          name="name"
          required
          {...this.generateStateMappers({
            stateKeys: ['name'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <Input
          label={getMessage('settings.users.inviteForm.email.heading')}
          placeholder={getMessage(
            'settings.users.inviteForm.email.placeholder'
          )}
          name="email"
          type="email"
          required
          {...this.generateStateMappers({
            stateKeys: ['email'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <Phone
          label={getMessage('settings.users.inviteForm.phone.heading')}
          placeholder={getMessage(
            'settings.users.inviteForm.phone.placeholder'
          )}
          name="phone"
          country={this.props.isoCode}
          required
          {...this.generateStateMappers({
            stateKeys: ['phone'],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <div className="form-actions">
          <SubmitButton>
            {getMessage('settings.users.inviteForm.invite.buttonText')}
          </SubmitButton>
          <CancelButton>
            {getMessage('settings.users.inviteForm.cancelText')}
          </CancelButton>
        </div>
      </Form>
    )
  }
}
