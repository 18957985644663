import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Radio, Select } from 'components/Form'
import { SKINS as RADIO_SKINS } from 'components/Form/Inputs/Radio'
import { getMessage } from 'lib/translator'
import { isExtensionEnabled, getExtensionDetails, getSession } from 'lib/auth'
import API from 'lib/api'
import { formatDate, formatTime } from 'lib/datetime'
import {
  getTitleBasedOnOrderStatusAndType,
  getStartTimeAndEndTimeFromInterval,
} from '../helpers.utils'
import editIcon from '../edit-icon.svg'
import {
  getNewDisplayAddress,
  sortSlotsAvailability,
  generateDateOptions,
} from 'lib/commonlyused'
import './style.scss'

/* DeliveryDetails component was initially written with DELIVERY, B2B and OFFLINE orderTypes. So, this mapping helps to convert the orderType */
const orderTypeMapping = {
  SALES_ORDER_TYPE_ONLINE_GROCERY: 'DELIVERY',
  SALES_ORDER_TYPE_BUSINESS_TO_BUSINESS: 'B2B',
  SALES_ORDER_TYPE_SCAN_AND_GO: 'OFFLINE',
  SALES_ORDER_TYPE_RB_PREORDER: 'RB_PREORDER',
}

const findAddressIndex = (customerAddresses, deliveryAddress) => {
  return deliveryAddress?.id
    ? customerAddresses.findIndex(
        (value) => Number(value.id) === Number(deliveryAddress.id)
      )
    : -1
}

function CustomerSection({
  customer,
  url,
  isCustomerHyperLinkDisabled,
  isRbPreorder,
}) {
  return (
    <React.Fragment>
      <h3 className="customer-heading">{getMessage('Customer')}</h3>
      <Link
        style={{ pointerEvents: isCustomerHyperLinkDisabled ? 'none' : '' }}
        className="customer-name"
        to={`${url}/customers/view/${customer.id}`}
      >
        {customer.name}
      </Link>
      {isRbPreorder && (
        <div className="customer-contact-details">
          <p>{customer?.phone}</p>
          <p>{customer?.email}</p>
        </div>
      )}
    </React.Fragment>
  )
}

export default function AddresssAndTimeSlot({
  data,
  editable,
  getOrderDetailsApi,
  referenceNumber,
  onError,
  isScanAndGoOrder,
}) {
  /* PROPS */
  const {
    status,
    orderType,
    customer,
    address,
    url,
    preferredDate,
    preferredSlot,
    storeId,
  } = data

  /* STATES */
  const [isCustomerHyperLinkDisabled, setIsCustomerHyperLinkDisabled] =
    useState(false)
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = moment()
    const preferredDateMoment = preferredDate && moment(preferredDate)
    return today > preferredDateMoment
      ? today.format('YYYY-MM-DD')
      : preferredDateMoment.format('YYYY-MM-DD')
  })
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(null)

  const customerAddresses = useMemo(() => {
    const index = findAddressIndex(customer.addresses, address)
    if (index === -1) {
      return customer.addresses.concat({ ...address, isDeleted: true })
    }
    return customer.addresses
  }, [customer, address])

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(() => {
    return findAddressIndex(customerAddresses, address)
  })

  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [visibleDaysForSlot, setVisibleDaysForSlot] = useState(null)
  const [dateTimeSlotMap, setDateTimeSlotMap] = useState(null)
  const [dateOptions, setDateOptions] = useState(null)
  const [hasError, setHasError] = useState(false)

  /* DERIVED VALUES */
  const formattedDeliveryAddress = useMemo(() => {
    if (address) {
      return getNewDisplayAddress(address)
    }
    return ''
  }, [address])

  const timeRange = preferredSlot?.startTime
    ? `${formatTime(preferredSlot.startTime)} - ${formatTime(preferredSlot.endTime)}`
    : null

  useEffect(() => {
    async function fetchVisibleDaysAndSlotAvailability() {
      try {
        let visibleDays = null
        if (!visibleDaysForSlot) {
          const extnAPI = new API({
            url: `/account-service/extension/${getExtensionDetails('DeliverySlots').id}`,
          })
          const extnRes = await extnAPI.get()
          visibleDays =
            extnRes.data.extension.config.globalConfig?.visibleDaysForSlot
          setVisibleDaysForSlot(visibleDays)
        }

        const params = getAddressParams(visibleDays || visibleDaysForSlot)
        if (params) {
          const slotAvailabilityAPI = new API({
            url: '/order-service/slot-availability',
          })
          const slotAvailabilityRes = await slotAvailabilityAPI.get(params)
          sortAndSetSlotsAvailability(slotAvailabilityRes)
          setHasError(false)
        }
      } catch (e) {
        if (e.code === 401) {
          throw e
        }
        setHasError(true)
      }
    }

    fetchVisibleDaysAndSlotAvailability()
  }, [selectedAddressIndex])

  useEffect(() => {
    const { user } = getSession()
    // disable customer hyperlink for RB staffs
    if (
      user?.designation?.name === 'Digital Pre-order' ||
      user?.designation?.name === 'AC_Onsite Admin' ||
      user?.designation?.name === 'FP - Store Manager (New)'
    ) {
      setIsCustomerHyperLinkDisabled(true)
    }
  }, [])

  const sortAndSetSlotsAvailability = (slotsResponse) => {
    const sortedSlots = sortSlotsAvailability(slotsResponse.data.slots)
    const slots = {}
    if (Array.isArray(sortedSlots)) {
      sortedSlots.forEach((slot) => {
        if (slot.date in slots) {
          slots[slot.date].push(slot)
        } else {
          slots[slot.date] = []
          slots[slot.date].push(slot)
        }
      })
    }
    setDateTimeSlotMap(slots)
    setDateOptions(generateDateOptions(slots))
  }

  const getAddressParams = (visibleDays) => {
    const params = {}
    const customerAddress =
      (customerAddresses && customerAddresses[selectedAddressIndex]) || address
    if (!customerAddress) {
      return null
    }
    const startDate = moment().toISOString()
    const endDate = moment().add(visibleDays, 'days').toISOString()
    params['storeId'] = storeId
    params['orderType'] = orderTypeMapping[orderType]
    params['searchInterval'] = `${startDate}/${endDate}`
    // Optional Parameters
    params['address[address]'] = customerAddress.streetAddress
    params['address[city]'] = customerAddress.city
    params['address[pincode]'] = Number(customerAddress.postalCode)
    params['address[latitude]'] = customerAddress.latitude
    params['address[longitude]'] = customerAddress.longitude
    return params
  }

  const submitChanges = async () => {
    setIsSaving(true)
    const selectedSlot =
      dateTimeSlotMap &&
      dateTimeSlotMap[selectedDate] &&
      dateTimeSlotMap[selectedDate].find(
        (slot) => slot.id === Number(selectedTimeSlotId)
      )
    const timeSlot = getStartTimeAndEndTimeFromInterval(selectedSlot?.interval)

    if (timeSlot) {
      try {
        const payload = {
          addressId: customerAddresses[selectedAddressIndex].id,
          deliverySlotStart: moment(
            `${selectedDate} ${timeSlot.startTime}`
          ).toISOString(),
          deliverySlotEnd: moment(
            `${selectedDate} ${timeSlot.endTime}`
          ).toISOString(),
        }
        modifyAddressDeliverySlot(payload).finally(() => {
          getOrderDetailsApi()
        })
        setHasError(false)
      } catch (err) {
        setHasError(true)
        if (err.code === 401) {
          throw err
        }
      } finally {
        setIsSaving(false)
        setIsEditing(false)
      }
    }
  }

  const modifyAddressDeliverySlot = (params, headers = {}) => {
    const patchAddressDeliverySlotApi = new API({
      url: `/order-service/v3/deliveryOrders/${referenceNumber}`,
    })
    return patchAddressDeliverySlotApi
      .put(params, headers)
      .then(() => {}, onError)
  }

  const addressOnChange = async (id) => {
    setSelectedAddressIndex(() =>
      customerAddresses.findIndex((x) => Number(x.id) === Number(id))
    )
    setSelectedTimeSlotId(null)
    setSelectedDate(null)
  }

  const handleCloseEditMode = () => {
    setIsEditing(false)
    setSelectedAddressIndex(() =>
      customerAddresses.findIndex((x) => Number(x.id) === Number(address.id))
    )
    setSelectedTimeSlotId(null)
    setSelectedDate(null)
  }

  /* Edit Mode */
  if (isEditing) {
    return (
      <div className="address-details">
        <h3 className="edit-address-and-timeslot-text">
          {getMessage('order.details.location.editing.text')}
        </h3>
        <div className="customer-addresses">
          <Radio
            name="delivery-address"
            className="deleted-address"
            labelClass="deleted-address-label"
            skin={RADIO_SKINS.ONE_PER_LINE}
            readOnly={!editable}
            value={
              customerAddresses[selectedAddressIndex] &&
              customerAddresses[selectedAddressIndex].id
            }
            onChange={addressOnChange}
            options={customerAddresses.map((customerAddress) => ({
              value: customerAddress.id,
              text: getNewDisplayAddress(customerAddress, false),
              alertText: customerAddress.isDeleted ? { text: 'deleted' } : null,
            }))}
          />
        </div>
        {dateOptions && (
          <Select
            name="preferredDate"
            label={getMessage('order.details.preferredDate')}
            placeholder={getMessage('Select date')}
            value={selectedDate}
            options={dateOptions}
            onChange={(value) => {
              setSelectedDate(value)
              setSelectedTimeSlotId(null)
            }}
          />
        )}
        {dateTimeSlotMap && selectedDate && dateTimeSlotMap[selectedDate] && (
          <Select
            name="preferredSlot"
            label={getMessage('order.details.prefferdSlot')}
            placeholder={getMessage('Select slot')}
            value={selectedTimeSlotId}
            onChange={(e) => setSelectedTimeSlotId(e)}
            options={dateTimeSlotMap[selectedDate].map((slot) => {
              return { text: slot.text, value: slot.id.toString() }
            })}
          />
        )}
        <div className="delivery-option-buttons">
          <button
            className="button primary"
            disabled={
              (isExtensionEnabled('DeliverySlots') && !selectedTimeSlotId) ||
              isSaving
            }
            onClick={submitChanges}
          >
            {getMessage('order.details.location.actions.confirmText')}
          </button>
          <button className="button button-white" onClick={handleCloseEditMode}>
            {getMessage('order.details.location.actions.cancelText')}
          </button>
        </div>
        {hasError && (
          <div className="address-details-error">
            {getMessage('order.details.location.save.error')}
          </div>
        )}
      </div>
    )
  }

  /* Display Mode */
  return (
    <div className="address-details">
      {customer && (
        <CustomerSection
          url={url}
          customer={customer}
          isCustomerHyperLinkDisabled={isCustomerHyperLinkDisabled}
          isRbPreorder={orderType === 'SALES_ORDER_TYPE_RB_PREORDER'}
        />
      )}
      <div className="flex-around section-title title">
        <h3>{getTitleBasedOnOrderStatusAndType(status, orderType)}</h3>
        {editable && (
          <img
            src={editIcon}
            alt="Edit"
            className="edit-icon"
            onClick={() => setIsEditing(true)}
          />
        )}
      </div>
      <div className="customer-address">
        {formattedDeliveryAddress.split('\n').map((value, index) => {
          const styleObj =
            index === 0 ? { fontWeight: 'bold', marginBottom: '0.5rem' } : null
          return (
            <div key={value} style={styleObj}>
              {value}
            </div>
          )
        })}
      </div>
      {selectedAddressIndex === -1 && (
        <div className="address-details-error">
          {getMessage('order.details.location.address.notFound')}
        </div>
      )}
      {preferredDate && !isScanAndGoOrder && (
        <div className="slot-details">
          <div className="section-title slot-title">
            <h5>
              {orderType === 'SALES_ORDER_TYPE_RB_PREORDER'
                ? getMessage('order.details.slot.collection')
                : getMessage('order.details.slot.heading')}
            </h5>
          </div>
          <div className="order-delivery-date">{formatDate(preferredDate)}</div>
          {timeRange && <div className="order-delivery-slot">{timeRange}</div>}
        </div>
      )}
      {hasError && (
        <div className="address-details-error">
          {getMessage('order.details.location.save.error')}
        </div>
      )}
    </div>
  )
}
