import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { getMessage } from '../../lib/translator'
import infoIcon from '../../components/Form/info-icon.svg'
import Close from './close.svg'
import ArrowUpIcon from './arrow-up.svg'
import ArroDownIcon from './arrow-down.svg'
import './style.css'
import { usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import { Checkbox } from '../Form'
import { STATUSES } from 'pages/qc/Orders/Details/Details.enum'

const tooltipContent = (setControlledVisible, controlledVisible) => {
  return (
    <div className="custom-tooltip-container">
      <div className="custom-tooltop-desc">
        <b>{getMessage('qc.orderSummary.AppPaymentDiscountTooltipLabel')}</b>
        <button onClick={() => setControlledVisible(!controlledVisible)}>
          <img src={Close} alt="Close" />
        </button>
      </div>
      {getMessage('qc.orderSummary.AppPaymentDiscountTooltipDescription')}
    </div>
  )
}

const finalAmountContent = finalAmount => {
  return (
    <div className="summary-calculation final-summary-calculation no-bottom-border">
      <label>{getMessage('qc.orderSummary.FinalTotalAmount')}</label>
      <div>{finalAmount}</div>
    </div>
  )
}

const appDiscountVoidedContent = appDiscountVoided => {
  return (
    <div className="summary-calculation sub-item">
      <label>{getMessage('qc.orderSummary.AppPaymentDiscountVoided')}</label>
      <div>-{appDiscountVoided.formattedAppPaymentDiscount}</div>
    </div>
  )
}

const containerFeeReturnedContent = containerFee => {
  return (
    <div className="summary-calculation sub-item no-bottom-border">
      <label>{getMessage('qc.orderSummary.containerFee.return')}</label>
      <div>{containerFee.formattedContainerFee}</div>
    </div>
  )
}

const amountRefundedContent = amountRefunded => {
  return (
    <div
      className="summary-calculation sub-item no-bottom-border"
      data-testid="AmountRefunded"
    >
      <label>{getMessage('qc.orderSummary.AmountRefunded')}</label>
      <div>{amountRefunded.formattedItemTotal}</div>
    </div>
  )
}

const amountCancelledContent = amountCancelled => {
  return (
    <div
      className="summary-calculation sub-item no-bottom-border"
      data-testid="AmountCancelled"
    >
      <label>{getMessage('qc.orderSummary.AmountCancelled')}</label>
      <div>{amountCancelled.formattedItemTotal}</div>
    </div>
  )
}

const totalAmountCancelledContent = (
  amountCancelled,
  setShowHideTotalAmountCancelled,
  showHideTotalAmountCancelled
) => {
  return (
    <div
      className="summary-calculation no-bottom-border"
      data-testid="TotalAmountCancelled"
    >
      <label
        className="with-arrow"
        data-testid="totalAmountCancelledId"
        onClick={() =>
          handleTotalAmountCancelled(
            setShowHideTotalAmountCancelled,
            showHideTotalAmountCancelled
          )
        }
      >
        {getMessage('qc.orderSummary.TotalAmountCancelledRejected')}{' '}
        {showHideTotalAmountCancelled ? (
          <img src={ArrowUpIcon} alt="ArrowUpIcon" />
        ) : (
          <img src={ArroDownIcon} alt="ArroDownIcon" />
        )}
      </label>
      <div>-{amountCancelled.formattedTotal}</div>
    </div>
  )
}
const totalAmountRefundedContent = (
  amountRefunded,
  setShowHideTotalAmountRefunded,
  showHideTotalAmountRefunded
) => {
  return (
    <div
      className="summary-calculation no-bottom-border"
      data-testid="TotalAmountRefunded"
    >
      <label
        className="with-arrow"
        data-testid="totalAmountRefundedId"
        onClick={() =>
          handleTotalAmountRefunded(
            setShowHideTotalAmountRefunded,
            showHideTotalAmountRefunded
          )
        }
      >
        {getMessage('qc.orderSummary.TotalAmountRefunded')}{' '}
        {showHideTotalAmountRefunded ? (
          <img src={ArrowUpIcon} alt="ArrowUpIcon" />
        ) : (
          <img src={ArroDownIcon} alt="ArroDownIcon" />
        )}
      </label>
      <div>-{amountRefunded.formattedTotal}</div>
    </div>
  )
}
const originalTotalAmountContent = originalTotalAmount => {
  return (
    <div
      className="summary-calculation no-bottom-border"
      data-testid="OriginalTotalAmount"
    >
      <label>{getMessage('qc.orderSummary.OriginalTotalAmount')}</label>
      <div>{originalTotalAmount}</div>
    </div>
  )
}

const cardSurchargeContent = cardSurcharge => {
  return (
    <div className="summary-calculation">
      <label>
        {getMessage('qc.orderSummary.CardSurcharge')}{' '}
        {cardSurcharge.isWaived && (
          <Fragment>
            ({getMessage('qc.orderSummary.CardSurcharge.Waived')})
          </Fragment>
        )}
      </label>
      <div
        className={`${(cardSurcharge.formattedPayable === '$0.00' ||
          cardSurcharge.isWaived) &&
          'line-through'}`}
      >
        +{cardSurcharge.formattedPrice}
      </div>
    </div>
  )
}

const appDiscountContent = (setTriggerRef, appDiscount) => {
  return (
    <div className="summary-calculation">
      <label>
        {getMessage('qc.orderSummary.AppPaymentDiscountTooltipLabel')} (
        {appDiscount.percentage ? appDiscount.percentage : 10}%)
        <button type="button" ref={setTriggerRef}>
          <img src={infoIcon} alt="infoIcon" />
        </button>
      </label>
      <div>-{appDiscount.formattedAmount}</div>
    </div>
  )
}
const handleCheckboxContainerFee = (
  handleState,
  state,
  subOrders,
  itemIndex,
  e
) => {
  const containerCheckboxItems = state.containerCheckboxItems
  containerCheckboxItems[itemIndex].value = e
  handleState({
    ...state,
    containerCheckboxItems,
  })
}

const handleDisabledContainerFeeCheckbox = (state, item, suborders) => {
  return (
    state.refundItems.find(obj => obj.id === item.subOrderId).value ||
    item.formattedPrice === '$0.00' ||
    item.isRefunded === true ||
    suborders.find(suborder => suborder.id === item.subOrderId).state !==
      STATUSES.COLLECTED
  )
}

const serviceFeeContent = serviceFee => {
  return (
    <div className="summary-calculation">
      <label>{getMessage('qc.orderSummary.service.fee')}</label>
      <div>
        {serviceFee.isWaived && (
          <Fragment>
            {getMessage('qc.orderSummary.CardSurcharge.Waived')}
          </Fragment>
        )}{' '}
        <span
          className={`${(serviceFee.formattedPayable === '$0.00' ||
            serviceFee.isWaived) &&
            'line-through'}`}
        >
          {serviceFee.formattedPrice}
        </span>
      </div>
    </div>
  )
}

const containerFeeBreakdownContent = (
  itemIndex,
  containerFee,
  item,
  handleState,
  state,
  subOrders
) => {
  const disabledGreyClass = handleDisabledContainerFeeCheckbox(
    state,
    item,
    subOrders
  )

  return (
    <div
      key={`containerFee${itemIndex}`}
      className={`summary-calculation sub-item 
       
        ${containerFee.breakdown.length === itemIndex + 1 && 'last-item'}`}
    >
      <div className="container-fee-checkbox-container">
        {state.refundOrder && (
          <Checkbox
            testid="containerFeeTestID"
            name={`containerFeeCheckbox${itemIndex}`}
            value={!!state.containerCheckboxItems[itemIndex].value}
            onChange={handleCheckboxContainerFee.bind(
              this,
              handleState,
              state,
              subOrders,
              itemIndex
            )}
            disabled={disabledGreyClass}
          />
        )}
        <label className={`${disabledGreyClass && 'qc-order-summary-grey'}`}>
          {item.displayName}
        </label>
      </div>
      <div className={`${disabledGreyClass && 'qc-order-summary-grey'}`}>
        {item.formattedPrice}
      </div>
    </div>
  )
}

const containerFeeContent = (
  containerFee,
  setShowHideContainerFee,
  showHideContainerFee
) => {
  return (
    <div
      className={`summary-calculation ${showHideContainerFee && 'cont-fee'}`}
    >
      <label
        className="with-arrow"
        data-testid="containerFeeId"
        onClick={() =>
          handleContainerFee(setShowHideContainerFee, showHideContainerFee)
        }
      >
        {getMessage('qc.orderSummary.containerFee')}{' '}
        {showHideContainerFee ? (
          <img src={ArrowUpIcon} alt="ArrowUpIcon" />
        ) : (
          <img src={ArroDownIcon} alt="ArroDownIcon" />
        )}
      </label>
      <div>{containerFee.formattedPrice}</div>
    </div>
  )
}
const tooltipMainContent = (
  setTooltipRef,
  getTooltipProps,
  setControlledVisible,
  controlledVisible,
  getArrowProps
) => {
  return (
    <div
      ref={setTooltipRef}
      data-popper-interactive="true"
      {...getTooltipProps({ className: 'tooltip-container' })}
    >
      {tooltipContent(setControlledVisible, controlledVisible)}
      <div {...getArrowProps({ className: 'tooltip-arrow' })} />
    </div>
  )
}

const subtotalContent = subtotal => {
  return (
    <div className="summary-calculation">
      <label>{getMessage('qc.orderSummary.Subtotal')}</label>
      <div>{subtotal}</div>
    </div>
  )
}
const handleContainerFee = (setShowHideContainerFee, showHideContainerFee) => {
  setShowHideContainerFee(!showHideContainerFee)
}
const handleTotalAmountCancelled = (
  setShowHideTotalAmountCancelled,
  showHideTotalAmountCancelled
) => {
  setShowHideTotalAmountCancelled(!showHideTotalAmountCancelled)
}
const handleTotalAmountRefunded = (
  setShowHideTotalAmountRefunded,
  showHideTotalAmountRefunded
) => {
  setShowHideTotalAmountRefunded(!showHideTotalAmountRefunded)
}

const FinalTotalAmount = props => {
  const {
    subtotal,
    originalTotalAmount,
    finalAmount,
    appDiscount,
    cardSurcharge,
    containerFee,
    serviceFee,
    hideTemp,
    cancellationBreakdown,
    refundBreakdown,
    handleState,
    state,
    subOrders,
  } = props
  const [controlledVisible, setControlledVisible] = React.useState(false)
  const [showHideContainerFee, setShowHideContainerFee] = React.useState(false)
  const [
    showHideTotalAmountCancelled,
    setShowHideTotalAmountCancelled,
  ] = React.useState(false)
  const [
    showHideTotalAmountRefunded,
    setShowHideTotalAmountRefunded,
  ] = React.useState(false)
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'click',
    closeOnOutsideClick: false,
    delayHide: 5000,
    interactive: true,
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
  })

  return (
    <div className="final-calculation">
      {subtotal && subtotalContent(subtotal)}
      {containerFee && (
        <>
          {containerFeeContent(
            containerFee,
            setShowHideContainerFee,
            showHideContainerFee
          )}
          {showHideContainerFee &&
            containerFee.breakdown.map((item, itemIndex) =>
              containerFeeBreakdownContent(
                itemIndex,
                containerFee,
                item,
                handleState,
                state,
                subOrders
              )
            )}
        </>
      )}
      {serviceFee && serviceFeeContent(serviceFee)}

      {appDiscount && appDiscountContent(setTriggerRef, appDiscount)}
      {cardSurcharge && hideTemp && cardSurchargeContent(cardSurcharge)}
      {originalTotalAmount && originalTotalAmountContent(originalTotalAmount)}

      {cancellationBreakdown &&
        cancellationBreakdown.formattedTotal !== '$0.00' &&
        totalAmountCancelledContent(
          cancellationBreakdown,
          setShowHideTotalAmountCancelled,
          showHideTotalAmountCancelled
        )}
      {cancellationBreakdown &&
        cancellationBreakdown.formattedTotal !== '$0.00' &&
        showHideTotalAmountCancelled && (
          <Fragment>
            {amountCancelledContent(cancellationBreakdown)}
            {containerFeeReturnedContent(cancellationBreakdown)}
            {appDiscountVoidedContent(cancellationBreakdown)}
          </Fragment>
        )}

      {refundBreakdown &&
        refundBreakdown.formattedTotal !== '$0.00' &&
        totalAmountRefundedContent(
          refundBreakdown,
          setShowHideTotalAmountRefunded,
          showHideTotalAmountRefunded
        )}

      {refundBreakdown &&
        refundBreakdown.formattedTotal !== '$0.00' &&
        showHideTotalAmountRefunded && (
          <Fragment>
            {amountRefundedContent(refundBreakdown)}
            {containerFeeReturnedContent(refundBreakdown)}
            {appDiscountVoidedContent(refundBreakdown)}
          </Fragment>
        )}

      {((cancellationBreakdown &&
        cancellationBreakdown.formattedTotal !== '$0.00') ||
        (refundBreakdown &&
          (refundBreakdown.formattedTotal !== '$0.00' ||
            containerFee.formattedRefundedAmount !== '$0.00'))) &&
        finalAmount &&
        finalAmountContent(finalAmount)}
      {visible &&
        tooltipMainContent(
          setTooltipRef,
          getTooltipProps,
          setControlledVisible,
          controlledVisible,
          getArrowProps
        )}
    </div>
  )
}

FinalTotalAmount.propTypes = {
  subtotal: PropTypes.string,
  finalAmount: PropTypes.string,
  fees: PropTypes.string,
  originalTotalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export { handleDisabledContainerFeeCheckbox }

export default FinalTotalAmount
