import React, { useContext } from 'react'
import Tabs from '../../../components/Tabs'
import { BaseForm, VALIDATION_TYPES } from '../../../components/Form'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import { getMessage } from '../../../lib/translator'
import './style.scss'
import MobileAppSetup from './MobileAppSetup'
import CampaignDetails from './CampaignDetails'
import { Toggle } from 'components/Form/Inputs'
import { isExtensionEnabled } from '../../../lib/auth'
import { getStoreName } from '../../../containers/StoreSelector'
import {
  EntityIds,
  OfferMapping,
  OfferTypes,
} from 'pages/marketing/Offers/settings'
import Button from 'components.new/button/Button'
import API from 'lib/api'
import moment from 'moment'
import { Popup } from '../../../components/Popup'
import SuccessfullTick from './SuccessfullTick.svg'
import { Link, withRouter } from 'react-router-dom'
import LeavePage from './leavePage'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'

class DefineFestiveCampaign extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: {},
      stores: [],
      status: false,
      method: '',
      id: null,
      showPublishDialog: false,
      showPublishInactive: false,
      publishedScreen: false,
      errors: {},
      statusChecker: false,
      vOrderDate: false,
      vCollectionDate: false,
      validationOnEdit: false,
      isPublish: false,
      showPublishCmpaign: false,
      showFormFill: false,
      saveChangesDialogue: false,
      disableLeavePage: true,
      saveCampaignScreen: false,
      currentTabIndex: 0,
      redirectToPreorder: '/preorders/preorder-campaigns',
      loading: true,
      createSimilarDialogue: false,
      isCopy: false,
    }
    this.campaignTabHeaders = ['Campaign Details', 'Mobile App Set Up']
    this.handleOnTabClick = this.handleOnTabClick.bind(this)
    this.onUpdateStores = this.onUpdateStores.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
      location: { pathname },
    } = this.props.router

    const { method } = this.props
    if (isExtensionEnabled('MultiStoreSupport')) {
      const api = new API({ url: '/account-service/store' })
      api.get({ paginate: 'false' }).then((response) => {
        const stores = (response.data.store || [])
          .map((store) => ({
            ...store,
            name: getStoreName(store),
          }))
          .filter((store) => store.hasPicking || store.hasSelfCheckout)

        this.setState({ stores, method: method ?? pathname.split('/')[3], id })
      })
    }

    const isPreOrderEnabled =
      this.props.splits?.[SPLIT_FEATURES.PRE_ORDER]?.treatment === 'on'

    if (id && isPreOrderEnabled) {
      const api = new API({
        url: `/campaigns/${id}`,
      })
      const response = await api.get()
      const storeIds =
        response.data.campaign_stores?.map((cs) => cs.store_id) || []
      const newObject = {
        campaign_products: response.data.campaign_products,
        id: response.data.id,
        campaign_status_code: response.data.campaign_status_code,
        storeId: storeIds,
        campaignTitle: response.data.campaign_title,
        mbSetupTitle: response.data.listing_title,
        description: response.data.listing_description,
        listingLogo: response.data.listing_image_path,
        logo: response.data.onboard_image_path,
        landing_image_path: response.data.landing_image_path,
        mbOnboardingdescription: response.data.onboarding_description,
        campaignType: response.data.campaign_type,
        validCollectionDateTo: response.data.collection_end_date,
        validCollectionDateFrom: response.data.collection_start_date,
        onboard_image_path: response.data.onboard_image_path,
        onboarding_description: response.data.onboarding_description,
        onboarding_title: response.data.onboarding_title,
        validOrderDateTo: response.data.order_end_date,
        validOrderDateFrom: response.data.order_start_date,
        swimlane_description: response.data.swimlane_description,
        swimlane_item_count: response.data.swimlane_item_count,
        mbOnboardingtitle: response.data.onboarding_title,
        swimlane_title: response.data.swimlane_title,
        has_sku_scanner: response.data.has_sku_scanner,
        leadTime:
          response.data.lead_time && response.data.lead_time > 0
            ? response.data.lead_time
            : '',
        offerId:
          response.data.offer_id && response.data.offer_id > 0
            ? response.data.offer_id
            : '',
        tag_id:
          response.data.tag_id && response.data.tag_id > 0
            ? response.data.tag_id
            : '',
        category_id:
          response.data.category_id && response.data.category_id > 0
            ? response.data.category_id
            : '',
        flag: response.data.is_campaign_published,
      }
      this.setState({
        values: newObject,
        status: response.data.campaign_status_code === 1 ? false : true,
        isPublish: newObject.flag,
        loading: false,
      })
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  onUpdateStores(storeIds) {
    this.setState((prevState) => ({
      values: { ...prevState.values, storeId: storeIds },

      pressedSubmitWithCurrentData: false,
    }))
  }

  handleOnTabClick(index) {
    this.setState({ currentTabIndex: index })
  }
  handleStatusCheck() {
    const {
      campaignTitle,
      campaignType,
      description,
      logo,
      landing_image_path,
      listingLogo,
      mbOnboardingdescription,
      mbOnboardingtitle,
      mbSetupTitle,
      storeId,
      validOrderDateFrom,
      validOrderDateTo,
      validCollectionDateFrom,
      validCollectionDateTo,
      tag_id,
    } = this.state.values
    return (
      campaignTitle &&
      campaignType &&
      description &&
      logo &&
      mbOnboardingdescription &&
      mbOnboardingtitle &&
      mbSetupTitle &&
      storeId.length !== 0 &&
      validOrderDateFrom &&
      validOrderDateTo &&
      validCollectionDateFrom &&
      validCollectionDateTo &&
      landing_image_path &&
      listingLogo &&
      tag_id
    )
  }

  handleLeavePageCheck() {
    const {
      campaignTitle,
      campaignType,
      description,
      logo,
      landing_image_path,
      listingLogo,
      mbOnboardingdescription,
      mbOnboardingtitle,
      mbSetupTitle,
      storeId,
      validOrderDateFrom,
      validOrderDateTo,
      validCollectionDateFrom,
      validCollectionDateTo,
      tag_id,
    } = this.state.values
    return (
      campaignTitle ||
      campaignType ||
      description ||
      logo ||
      mbOnboardingdescription ||
      mbOnboardingtitle ||
      mbSetupTitle ||
      storeId ||
      validOrderDateFrom ||
      validOrderDateTo ||
      validCollectionDateFrom ||
      validCollectionDateTo ||
      landing_image_path ||
      listingLogo ||
      tag_id
    )
  }

  handleCloneCampaign() {
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        campaignTitle: `Copy of ${prevState.values.campaignTitle}`,
        flag: false,
        id: null,
      },
      isPublish: false,
      status: false,
      campaign_status_code: 1,
    }))
    this.setState({ currentTabIndex: 0 })
  }

  async handleSubmit(action) {
    const { values } = this.state
    const {
      campaignTitle,
      campaignType,
      description,
      logo,
      mbOnboardingdescription,
      mbOnboardingtitle,
      mbSetupTitle,
      storeId,
      validOrderDateFrom,
      validOrderDateTo,
      validCollectionDateFrom,
      validCollectionDateTo,
      tag_id,
      category_id,
      offerId,
      landing_image_path,
      leadTime,
      listingLogo,
      has_sku_scanner,
    } = values
    const data1 = {}
    const dateFormat = 'YYYY-MM-DD 00:00:00'
    const dateFormatWithoutTime = 'YYYY-MM-DD'
    const campaignStatusCode = this.state.status
      ? moment(validOrderDateFrom).isSame(moment())
        ? 2
        : 3
      : 1

    if (validOrderDateFrom) {
      data1.order_start_date = moment(validOrderDateFrom).format(dateFormat)

      data1.order_end_date_val = moment(validOrderDateFrom)
        .add(3, 'day')
        .format(dateFormatWithoutTime)
    }

    if (validOrderDateTo) {
      data1.order_end_date = moment(validOrderDateTo).format(dateFormat)
    }

    if (validCollectionDateFrom) {
      data1.collection_start_date = moment(validCollectionDateFrom).format(
        dateFormat
      )

      data1.collection_end_date_val = moment(validCollectionDateFrom)
        .add(3, 'day')
        .format(dateFormatWithoutTime)
    }

    if (validCollectionDateTo) {
      data1.collection_end_date = moment(validCollectionDateTo).format(
        dateFormat
      )
    }
    /**title and statusCode is mandatory */
    data1.title = campaignTitle
    const currentDate = moment(Date()).format(dateFormatWithoutTime)
    if (
      this.state.isPublish &&
      this.state.status &&
      moment(validOrderDateFrom).format(dateFormatWithoutTime) <= currentDate &&
      moment(validOrderDateTo).format(dateFormatWithoutTime) >= currentDate
    ) {
      data1.campaign_status_code = 2
    } else {
      data1.campaign_status_code = campaignStatusCode
    }
    campaignType && (data1.campaign_type = campaignType.toLowerCase())
    data1.onboarding_title = mbOnboardingtitle
    data1.onboarding_description = mbOnboardingdescription
    data1.onboarding_image_path = logo
    data1.stores = storeId
    tag_id && (data1.tag_id = tag_id)
    category_id && (data1.category_id = category_id)
    data1.landing_image_path = landing_image_path
    leadTime && (data1.lead_time = leadTime)
    data1.listing_description = description
    data1.listing_image_path = listingLogo
    data1.listing_title = mbSetupTitle
    offerId && (data1.offer_id = offerId)
    data1.is_campaign_published = this.state.isPublish
    data1.has_sku_scanner = has_sku_scanner

    if (!this.state.id || this.state.isCopy) {
      const api = new API({ url: '/campaigns' })
      await api.post(data1).then(
        (res) => {
          if (action === 'Publish') {
            this.setState({
              showPublishDialog: false,
              publishedScreen: true,
              id: res.id,
            })
          } else {
            this.setState({
              saveCampaignScreen: true,
              id: res.id,
              saveChangesDialogue: false,
            })
            this.props.router.history.push(this.state.redirectToPreorder)
          }
        },
        (_error) =>
          action === 'Publish' &&
          this.setState({
            showPublishCmpaign: true,
            isPublish: false,
          })
      )
    } else {
      const api = new API({ url: `/campaigns/${this.state.id}` })
      await api.put(data1).then((res) => {
        if (res.code === 200) {
          if (action === 'Publish') {
            this.setState({
              showPublishDialog: false,
              publishedScreen: true,
            })
          } else {
            this.setState({
              saveCampaignScreen: true,
              saveChangesDialogue: false,
            })
          }
        }
        this.props.router.history.push(this.state.redirectToPreorder)
      })
    }
  }

  render() {
    const isPreOrderEnabled =
      this.props.splits?.[SPLIT_FEATURES.PRE_ORDER]?.treatment === 'on'
    if (!isPreOrderEnabled) {
      return (
        <AuthenticatedPage menu={this.props.menu}>
          <h1>Feature Not Enabled</h1>
        </AuthenticatedPage>
      )
    }
    const name = this.campaignTabHeaders[this.state.currentTabIndex]
    const { Form } = this.components
    const {
      validOrderDateFrom,
      validOrderDateTo,
      validCollectionDateFrom,
      validCollectionDateTo,
      storeId,
      leadTime,
      flag,
    } = this.state.values
    const options = {
      stores: this.state.stores,
      offerTypes: OfferTypes,
      offerMapping: OfferMapping,
      entityIds: isExtensionEnabled('MultiBrandSupport')
        ? EntityIds.concat({
            text: getMessage('offer.brand'),
            value: 'BRAND',
          })
        : EntityIds,
      buyKey: this.buyKey,
      validOrderDateFrom,
      validOrderDateTo,
      validCollectionDateFrom,
      validCollectionDateTo,
      storeId,
      leadTime,
      flag,
    }
    const { status, saveCampaignScreen, publishedScreen } = this.state
    const fiveLines = (
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    )

    if (this.state.loading) {
      return 'Loading ...'
    }
    return (
      <AuthenticatedPage menu={this.props.menu} className="dfc">
        {publishedScreen || saveCampaignScreen ? (
          <div>
            {fiveLines}
            <div className="mt-3 publishScreenVisible AlignCampaign">
              <div className="center">
                <div>
                  <img src={SuccessfullTick} />
                </div>
                <div>
                  Campaign successfully {publishedScreen && <>published</>}
                  {saveCampaignScreen && <>saved</>}!
                </div>
              </div>
            </div>
            <div>
              <div className={`AlignCampaignSaved `}>
                <div className="flexProperty">
                  <div className="marginSet">
                    <Link
                      to={`/preorders/define-festive-campaigns/edit/${this.state.id}`}
                      className="RedirectToPreorder"
                      testid="RedirectToPreorder"
                    >
                      <button
                        className="button RedirectToPreorderBtn"
                        testid="RedirectToPreorderBtn"
                        onClick={() => {
                          this.setState({
                            publishedScreen: false,
                          })
                        }}
                      >
                        View Campaign Details
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link to={this.state.redirectToPreorder}>
                      <Button className="primary" data-testid="Publish">
                        View Campaign Listing
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Form noValidate testid="form">
            <Popup
              className="publishPopup dfc"
              heading={'Publish'}
              show={this.state.showPublishDialog}
              close={() => {
                this.setState({
                  showPublishDialog: false,
                })
              }}
            >
              <div className="publish-preorder">
                Press Publish if you are ready to publish your campaign,
                <br />
                this will change your campaign status from Inactive into
                <br />
                an Active campaign.
              </div>
              <div className="publishBtn">
                <br />
                <Button
                  data-testid="publishCampBtn"
                  onClick={() => {
                    this.setState({
                      isPublish: true,
                      status: !status,
                    })
                    setTimeout(() => {
                      this.handleSubmit('Publish')
                    }, 100)
                  }}
                  className="primary button publishCampaign"
                >
                  Publish
                </Button>
                <br />
              </div>
            </Popup>
            <Popup
              className="publishPopup"
              heading={'Campaign Title'}
              show={this.state.showPublishCmpaign}
              close={() => {
                this.setState({
                  showPublishCmpaign: false,
                })
              }}
            >
              Campaign Title should be unique.
              <div className="">
                <br />
                <Button
                  onClick={() => {
                    this.setState({
                      showPublishCmpaign: false,
                    })
                  }}
                  className="primary button"
                >
                  Ok
                </Button>
                <br />
              </div>
            </Popup>
            <Popup
              className="savePopup dfc"
              heading={
                this.state.saveChangesDialogue
                  ? 'Save Campaign'
                  : 'Create Similar Campaign'
              }
              data-testid="save_popup"
              show={
                this.state.saveChangesDialogue
                  ? this.state.saveChangesDialogue
                  : this.state.createSimilarDialogue
              }
              close={() => {
                this.state.saveChangesDialogue
                  ? this.setState({
                      saveChangesDialogue: false,
                    })
                  : this.setState({ createSimilarDialogue: false })
              }}
            >
              {this.state.saveChangesDialogue
                ? 'Do you want to save changes to campaign ?'
                : 'Create another campaign with the same campaign details ?'}
              {this.state.saveChangesDialogue ? (
                <div className="campaignSave">
                  <div className="mr-8">
                    <Button
                      onClick={() => {
                        this.setState({
                          saveChangesDialogue: false,
                        })
                      }}
                      className="secondary button"
                    >
                      No
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        this.setState({
                          disableLeavePage: true,
                        })
                        this.handleSubmit()
                      }}
                      testid={'save_yes'}
                      className="primary button ml-1 savePopupClass"
                    >
                      Yes
                    </Button>
                  </div>
                  <br />
                </div>
              ) : (
                <div className="campaignSave">
                  <div className="mr-8">
                    <Button
                      onClick={() => {
                        this.setState({
                          createSimilarDialogue: false,
                          disableLeavePage: true,
                          isCopy: true,
                        })
                        this.handleCloneCampaign()
                      }}
                      testid={'save_yes'}
                      className="primary button ml-1 savePopupClass"
                    >
                      Create Campaign
                    </Button>
                  </div>
                  <br />
                </div>
              )}
            </Popup>
            <Popup
              className="publishPopupInactive dfc"
              heading={'Campaign Status'}
              show={this.state.showPublishInactive}
              close={() => {
                this.setState({
                  showPublishInactive: false,
                })
              }}
            >
              You are about to change the status of the campaign to Inactive.
              This will reflected on live build
              <div className="campaignSave">
                <br />
                <div className="mr-8">
                  <button
                    onClick={() => {
                      this.setState({
                        disableLeavePage: false,
                        showPublishInactive: false,
                      })
                      this.handleSubmit()
                    }}
                    className="secondary button mr-1 T-none"
                  >
                    Yes, Change to Inactive
                  </button>
                  <Button
                    onClick={() => {
                      this.setState({
                        showPublishInactive: false,
                      })
                    }}
                    className="primary button ml-1"
                  >
                    Back
                  </Button>
                </div>
                <br />
              </div>
            </Popup>
            <div className="">
              <div className="define-campaign">
                <h1 testid="campaign-header-testid">
                  {getMessage('preorder.definefestivecampaign.heading')}
                </h1>
              </div>
              <React.Fragment>
                <div className="tab-container dfc">
                  <div className="tab-row tab-align">
                    <div>
                      <Tabs
                        items={this.campaignTabHeaders}
                        onClick={this.handleOnTabClick}
                        data-testid="testBtn"
                        className="tabsClick p-0"
                        active={this.state.currentTabIndex}
                      />
                    </div>
                    <div className="toggleButtonAlign">
                      <div className="tab-text">
                        {getMessage(
                          'preorder.definefestivecampaign.campaignstatus'
                        )}
                      </div>
                      <button type="button" className="vector-button"></button>
                      <Toggle
                        name="status"
                        icons={false}
                        {...this.generateStateMappers({
                          stateKeys: ['status'],
                          validationType: VALIDATION_TYPES.ONSUBMIT,
                          loseEmphasisOnFill: true,
                        })}
                        className="togglebtn"
                        onChange={() => {
                          this.setState({
                            status:
                              this.state.isPublish && this.handleStatusCheck()
                                ? !status
                                : false,
                          })
                        }}
                        value={status}
                      />
                    </div>
                  </div>
                  <div className="preorderCampaign">
                    {name === 'Campaign Details' ? (
                      <CampaignDetails
                        options={options}
                        testid="onClick"
                        _this={this}
                        Form={Form}
                        vOrderDate={this.state.vOrderDate}
                        vCollectionDate={this.state.vCollectionDate}
                        generateStateMappers={this.generateStateMappers}
                        onUpdateStores={this.onUpdateStores}
                        state={this.state}
                        values={this.state.values}
                        saveChangesDialogue={this.state.saveChangesDialogue}
                        handleLeavePageCheck={this.handleLeavePageCheck()}
                      />
                    ) : name === 'Mobile App Set Up' ? (
                      <MobileAppSetup
                        _this={this}
                        state={this.state}
                        testid="onClick"
                        generateStateMappers={this.generateStateMappers}
                        saveChangesDialogue={this.state.saveChangesDialogue}
                        className="mbSetup"
                        handleLeavePageCheck={this.handleLeavePageCheck()}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={`form-actions edit positionBottom`}>
                    {!this.state.isPublish ? (
                      <button
                        className="button"
                        data-testid="save-campaign"
                        onClick={() => {
                          if (this.handleLeavePageCheck()) {
                            this.setState({
                              disableLeavePage: false,
                              saveChangesDialogue: true,
                            })
                          }
                        }}
                        filled
                      >
                        Save Campaign
                      </button>
                    ) : (
                      <button
                        className="button"
                        data-testid="save-campaign"
                        onClick={() => {
                          this.setState({
                            disableLeavePage: false,
                            createSimilarDialogue: true,
                          })
                        }}
                        filled
                      >
                        Create Similar
                      </button>
                    )}
                    <div className="publishMsg">
                      {!this.state.isPublish && (
                        <div>
                          Please fill in all of the information from{' '}
                          <b>Campaign Details</b>,<br />
                          <b>Onboarding Mobile app</b> to publish this campaign
                        </div>
                      )}
                    </div>
                    {!this.state.isPublish ? (
                      <button
                        className="primary button"
                        data-testid="publish"
                        disabled={this.handleStatusCheck() ? false : true}
                        onClick={() => {
                          if (this.isFormValid() && this.handleStatusCheck()) {
                            this.setState({ showPublishDialog: true })
                          }
                        }}
                      >
                        Publish
                      </button>
                    ) : (
                      <button
                        className="primary button"
                        data-testid="Publish"
                        onClick={() => {
                          if (!status && this.isFormValid()) {
                            this.setState({ showPublishInactive: true })
                          } else if (this.isFormValid()) {
                            this.setState({
                              saveChangesDialogue: true,
                            })
                          }
                        }}
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </React.Fragment>
            </div>
            {this.state.disableLeavePage && !this.handleLeavePageCheck() ? (
              ''
            ) : (
              <LeavePage />
            )}
          </Form>
        )}
      </AuthenticatedPage>
    )
  }
}

const DefineFestiveCampaignWrapper = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return <DefineFestiveCampaign splits={splits} {...props} />
}
export default withRouter(({ location, history, match, ...props }) => (
  <DefineFestiveCampaignWrapper
    router={{ location, history, match }}
    {...props}
  />
))
