import React, { Component } from 'react'
import partial from 'lodash/partial'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { JobConfig } from '../BatchJobsConfig'

const getIndex = (headers, header) => {
  return headers
    .map(h => h.replace(/ /g, '').toLowerCase())
    .indexOf(header.toLowerCase())
}

const getTableData = (results, jobName,) => {
  if (results.length === 0) {
    return []
  }
  const CSV_HEADERS = JobConfig[jobName].csvHeaders
  const fileHeaders = results[0]
  const getIndexPartial = partial(getIndex, fileHeaders)
  const dateFromIndex = getIndexPartial(CSV_HEADERS.DATE_FROM.header)
  const dateToIndex = getIndexPartial(CSV_HEADERS.DATE_TO.header)
  const isinIndex = getIndexPartial(CSV_HEADERS.ISIN.header)
  const actionIndex = getIndexPartial(CSV_HEADERS.ACTION.header)
  const labelIndex = getIndexPartial(CSV_HEADERS.LABEL.header)
  const idIndex = getIndexPartial(CSV_HEADERS.ID.header)
  const rows = results.slice(1)
  try {
    
    return rows.map(row => {
      return {
        dateFrom: row[dateFromIndex],
        dateTo: row[dateToIndex],
        isin: row[isinIndex],
        action: row[actionIndex],
        label: row[labelIndex],
        id: row[idIndex],
      }
   })
  } catch (e) {
    console.error('Error while generating review data', e)
    return []
  }
}

export default class BulkScheduleFeatureRecipe extends Component {
  constructor() {
    super()
    this.state = {
      productMap: {},
      storeMap: {},
      error: null,
      loading: false,
    }
  }

  render() {
    const { results = [], jobName,actions } = this.props
    const tableData = getTableData(results, jobName)
    const headers = JobConfig[jobName].getTableHeaders()
    return (
      <div className="review-table">
        <div className="table-container">
          <Table tableDynamic={false}>
            <Header items={headers} />
            {tableData.map((row, index) => (
              <Row
                key={`${row.isin}-${index}`}
               
              >
                <Cell>
                  <span
                  >
                    {row.dateFrom}
                  </span>
                </Cell>
                <Cell>
                  <span className="product-name">{row.dateTo}</span>
                </Cell>
                <Cell>
                  <span className="product-name">{row.isin}</span>
                </Cell>
                <Cell>
                  <span className="product-name">{row.action}</span>
                </Cell>
                <Cell>
                  <span className="product-name">{row.label}</span>
                </Cell>
                <Cell>
                  <span className="product-name">{row.id}</span>
                </Cell>
              </Row>
            ))}
          </Table>
        </div>
        {actions(
         false
        )}
      </div>
    )
  }
}
