import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import {
  LinkButtons,
  emptyState,
  tableProperties,
} from '../../../components/Rewards/utils'
import '../style.css'
import './catalogue.css'
import CatalogueForm from './Form'
import Filters from '../../../components/Rewards/Filters'
import { getMessage } from 'lib/translator'

const Catalogue = props => {
  return (
    <ListingPageWithRoutes
      testid="catalogue-listing"
      title={getMessage('rewards.catalogue.heading')}
      addHeading={getMessage('rewards.catalogue.add')}
      editHeading={getMessage('rewards.catalogue.edit')}
      menu={props.menu}
      className="rewards-page catalogue"
      api={{
        url: '/rms/v2/rewards',
        transform: response => response && response.data.list,
      }}
      headerActions={() => <LinkButtons type="catalogue" />}
      form={{
        component: CatalogueForm,
      }}
      filters={{
        component: Filters,
        forceShow: true,
        options: {
          type: 'catalogue',
        },
      }}
      emptyState={emptyState('catalogue')}
      tableProperties={tableProperties('catalogue')}
      tableDynamic
    />
  )
}

export default Catalogue
