import React, { Fragment } from 'react'
import Tasks from './Tasks'
import UserSegment from './UserSegment'

const Mechanics = (props) => {
  const { _this, method } = props

  return (
    <Fragment>
      <UserSegment _this={_this} method={method} />
      <Tasks _this={_this} method={method} />
    </Fragment>
  )
}

export default Mechanics
