/* eslint-disable react/jsx-handler-names */
import React, { Fragment } from 'react'
import Table, { Header } from '../../components/Table'

const SummaryTable = props => {
  const { data, className, tableProperties, testid, primaryKey } = props
  const Row = tableProperties.row
  return (
    <div
      className={`table-container summary-listing-page ${
        className ? className : null
      }`}
      data-testid={testid}
    >
      <Fragment>
        {tableProperties.headers && (
          <Table tableDynamic={props.tableDynamic || false}>
            <Header items={props.tableProperties.headers} />
          </Table>
        )}
        {data.map((row, index) => (
          <Row
            {...row}
            key={`summary-table-${row[primaryKey]}`}
            index={index}
          />
        ))}
      </Fragment>
    </div>
  )
}

export default SummaryTable
