import React from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  DateRangePicker,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'

export default class Filters extends BaseForm {
  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components

    return (
      <Form className='assetSearch'>
        <div className="form-fields">
          <div className="section-row-flex">
            <div className="section-row">
              <DateRangePicker
                className="section-row-input-auto"
                label={getMessage('media.spotallocation.searchform.time')}
                {...this.generateStateMappers({
                  stateKeys: ['filter_duration'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                displayFormat="YYYY-MM-DD"
                isOutsideRange={()=>{return false}}
                required
                onChange={(dates) => this.props.onChange(dates)}
                startDatePlaceholder={getMessage(
                  'media.spotallocation.searchform.time.startDate.placeholder'
                )}
                endDatePlaceholder={getMessage(
                  'media.spotallocation.searchform.time.endDate.placeholder'
                )}
              />
              <Input
                label={getMessage('media.spotallocation.searchform.name')}
                placeholder={getMessage('media.spotallocation.searchform.name.placeholder')}
                name="filterName"
                className="section-row-input"
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ['assetName'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONBLUR,
                })}
                onChange={(value)=> this.props.assetNameChanged(value)}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                }}
              />
            </div>
            <div className='form-buttons'>
              <SubmitButton>{getMessage('brand.filters.submitText')}</SubmitButton>
            </div>
          </div>
        </div>
      </Form>
    )
  }
}
