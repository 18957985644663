import React from 'react'
import moment from 'moment'
import toUpper from 'lodash/toUpper'
import flatten from 'lodash/flatten'

import AuthenticatedPage from '../../../containers/AuthenticatedPage/index'
import API from '../../../lib/api'
import { Popup, Dialog } from '../../../components/Popup'
import Loader from '../../../components/Loader'
import { SingleDatePicker, TimePicker } from '../../../components/Form'
import { getSession } from '../../../lib/auth'
import { getMessage } from '../../../lib/translator'
import UploadFile from './UploadFile'
import JobsDropdown from './JobsDropdown'
import { JobConfig } from './BatchJobsConfig'
import ColumnsDropdown from '../../catalogue/BatchUploadJobs/ColumnsDropdown'

import './style.css'

function handleSampleDownload(data, filename) {
  const universalBOM = '\uFEFF'
  const csv = `${data.join(',')}\n`
  var hiddenElement = document.createElement('a')
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
    universalBOM + csv
  )}`
  hiddenElement.target = '_blank'
  hiddenElement.download = filename
  hiddenElement.click()
}

const ACTION_SUFFIX = '_ACTION'

const RowCount = ({ count }) => {
  return (
    <div className="row-count">
      <span className="label">No. of Rows:</span>
      {` `}
      <span className="count">{count}</span>
    </div>
  )
}

const BatchUploadJobs = (props) => {
  const [selectedJob, setSelectedJob] = React.useState()
  const [isFileParsed, setFileParsed] = React.useState()
  const [results, setResults] = React.useState([])
  const [file, setFile] = React.useState('')
  const [isJobError, setJobError] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [isFileUploaded, setFileUploaded] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isModalShown, setModalShown] = React.useState(false)
  const [selectedColumns, setSelectedColumns] = React.useState([])
  const [scheduleDate, setScheduledDate] = React.useState()
  const [scheduleTime, setScheduledTime] = React.useState()
  const [isScheduling, setScheduling] = React.useState(false)
  const handleDropdownChange = React.useCallback(
    (value) => {
      setSelectedJob(value)
      setJobError(false)
    },
    [selectedJob]
  )
  const toggleScheduling = React.useCallback(() => {
    setScheduling(!isScheduling)
  }, [isScheduling])

  let ReviewComponent = <span />
  let JOB_CSV_HEADERS = []
  let endpoint = ''
  let filename = ''
  let columnSelectable = false
  let columns = []
  let csvHeaders = {}
  let isValidName = () => true

  if (selectedJob) {
    csvHeaders = JobConfig[selectedJob].csvHeaders
    ReviewComponent = JobConfig[selectedJob].ReviewComponent
    JOB_CSV_HEADERS = Object.values(csvHeaders)
      .filter((obj) => !obj.skipInTemplate)
      .map((obj) => obj.header)
    endpoint = JobConfig[selectedJob].endpoint
    filename = JobConfig[selectedJob].sampleCsv
    isValidName = JobConfig[selectedJob].isValidFilename || isValidName
    columnSelectable = JobConfig[selectedJob].columnSelectable
    columns = Object.keys(csvHeaders)
      .filter(
        (key) =>
          !(
            csvHeaders[key].skipInTemplate ||
            key.includes(ACTION_SUFFIX) ||
            csvHeaders[key].mandatory
          )
      )
      .map((key) => ({
        text: csvHeaders[key].displayHeader,
        value: csvHeaders[key].header,
      }))
  }

  const onDownloadCsv = React.useCallback(() => {
    if (!selectedJob) {
      setJobError(true)
      return
    }
    handleSampleDownload(JOB_CSV_HEADERS, filename)
  }, [JOB_CSV_HEADERS, filename])

  const handlePartialDownload = (e) => {
    e.preventDefault()
    const concatenatedActionColumns = [csvHeaders.SKU.header].concat(
      selectedColumns.map((col) => {
        const actionColumn = csvHeaders[toUpper(col) + ACTION_SUFFIX] || {}
        return [col, actionColumn.header].filter(Boolean)
      })
    )
    handleSampleDownload(flatten(concatenatedActionColumns), filename)
  }

  const isInvalidCsv = (results) => {
    if (!selectedJob) {
      setJobError(true)
      return
    }
    const csvHeaders = results[0]
    const isStructureInvalid = results
      .slice(1)
      .some((row) => row.length !== csvHeaders.length)
    return !isCsvHeadersValid(csvHeaders) || isStructureInvalid
  }

  const isCsvHeadersValid = (csvHeaders) => {
    const trimmedCsvHeaders = csvHeaders.map((h) => h.replace(/ /g, ''))
    const validHeaders = trimmedCsvHeaders.map(
      (h) => !h || JOB_CSV_HEADERS.includes(h.toLowerCase())
    )
    return validHeaders.every(Boolean)
  }

  const validateJob = () => {
    setJobError(!selectedJob)
    return !!selectedJob
  }

  const handleResults = ({ file, results }) => {
    setResults(results)
    setFile(file)
    setFileParsed(!!selectedJob) // eslint-disable-line
  }

  const handleBack = React.useCallback(() => {
    setScheduledDate()
    setScheduledTime()
    setFileParsed(false)
    setFileUploaded(false)
    setError(false)
  }, [isFileParsed])

  const transformSubmit = (data) => {
    const userId = getSession().user.id
    if (scheduleDate && scheduleTime) {
      let dateTime = moment().add(5, 'minutes')
      const selectedDateTime = moment(scheduleDate + ' ' + scheduleTime)
      dateTime = selectedDateTime > dateTime ? selectedDateTime : dateTime
      data.append('scheduledAt', dateTime.format('YYYY-MM-DD HH:mm:ss'))
    }
    data.append('name', 'RecipeScheduleUpload')
    data.append('userId', userId)
    return data
  }

  const handleSubmit = () => {
    setLoading(true)
    let data = new window.FormData()
    data.append('uploadfile', file)
    data = transformSubmit(data)
    const api = new API({ url: endpoint })
    return api
      .post(data)
      .then(
        (response) => {
          if (response.data && response.data.success) {
            setError(null)
            setFileUploaded(true)
          } else {
            setFile('')
            setFileParsed(false)
            setError(response.data.errors)
            setFileUploaded(false)
          }
        },
        (error) => {
          setFile('')
          setError(error)
          setFileUploaded(false)
        }
      )
      .finally(() => setLoading(false))
  }

  const handleScheduleConfirm = () => {
    toggleScheduling()
    handleSubmit()
  }
  const actions = (count, isValidationErrors) => (
    <div className="actions">
      {!!count && <RowCount count={count} />}
      {loading && <Loader size="sm" />}
      <button className="button" onClick={handleBack}>
        {getMessage('category.form.cancelText')}
      </button>
      <button
        disabled={isFileUploaded || isValidationErrors}
        className="primary button"
        onClick={handleSubmit}
      >
        {getMessage('category.form.upload')}
      </button>
    </div>
  )

  const showModal = () => setModalShown(true)
  const hideModal = () => {
    setModalShown(false)
    setSelectedColumns([])
  }
  const handleDownloadCsv = () => {
    columnSelectable ? showModal() : onDownloadCsv()
  }
  const handleColumnSelection = (values) => {
    setSelectedColumns(values)
  }
  return (
    <AuthenticatedPage
      menu={props.menu}
      from={props.location && props.location.pathname}
    >
      <div className="batch-upload">
        {isModalShown && (
          <Popup
            heading={getMessage('product.global.modal.header')}
            show={isModalShown}
            close={hideModal}
            className="columnsPopup"
          >
            <div className="download-all">
              <button
                className="primary button"
                type="button"
                onClick={onDownloadCsv}
              >
                Download Full Template
              </button>
              <div className="text">Or</div>
            </div>
            <ColumnsDropdown
              options={columns}
              value={selectedColumns}
              onChange={handleColumnSelection}
            />
            <div className="button-container">
              <button
                className="primary button"
                type="button"
                onClick={handlePartialDownload}
              >
                Download
              </button>
            </div>
          </Popup>
        )}
        {isScheduling && (
          <Popup
            heading={getMessage('catalogue.batch-jobs.upload.schedule')}
            show={isScheduling}
            close={toggleScheduling}
            className="columnsPopup"
          >
            <div>
              <div className="dateTimeSelect">
                <SingleDatePicker
                  enableToday
                  name="scheduleDate"
                  key="scheduleDate"
                  label={getMessage('Select Date')}
                  value={scheduleDate}
                  onChange={(date) => setScheduledDate(date)}
                />
                <TimePicker
                  name="scheduleTime"
                  key="scheduleTime"
                  label={getMessage('Select Time')}
                  placeholder={getMessage('offer.time.placeholder')}
                  value={scheduleTime}
                  onChange={(time) => setScheduledTime(time)}
                  showSecond={false}
                  minuteStep={15}
                />
              </div>
              <div className="schedule-buttons">
                <button className="button" onClick={toggleScheduling}>
                  {getMessage('category.form.cancelText')}
                </button>
                <button
                  className="primary button"
                  onClick={handleScheduleConfirm}
                >
                  {getMessage('product.form.delete.confirmText')}
                </button>
              </div>
            </div>
          </Popup>
        )}

        <JobsDropdown
          onChange={handleDropdownChange}
          isJobError={isJobError}
          value={selectedJob}
          disabled={isFileParsed}
        />
        {!isFileParsed ? (
          <UploadFile
            setError={setError}
            onUpload={handleResults}
            isInvalidCsv={isInvalidCsv}
            isValidName={isValidName}
            onDownloadCsv={handleDownloadCsv}
            validateJob={validateJob}
          />
        ) : results.length ? (
          <div className="review-container">
            <ReviewComponent
              results={results}
              jobName={selectedJob}
              disabled={isFileUploaded}
              actions={(error) => actions(results.length - 1, error)}
            />
          </div>
        ) : (
          <div className="no-review">
            <div>
              We are unable to show a review of your changes as the file is too
              big.
            </div>
            <div>
              Please click <b>Upload</b> to complete the submission.
            </div>
            {actions(0, false)}
          </div>
        )}
        {error && error.length && (
          <div className="apierrorWrap">
            <p>
              Upload failed, please fix the failed rows and re-upload the file
            </p>
            {error.map((errorInfo, index) => (
              <p className="apierror" key={index}>
                {errorInfo}
              </p>
            ))}
          </div>
        )}
        <div className="upload-alert">
          <Dialog
            className="success"
            show={isFileUploaded}
            title="Success"
            message={getMessage('fileUpload.uploadedCsv.success')}
            information=""
            close={handleBack}
            closeText={getMessage('dialog.okText')}
            btnClassName="primary"
          />
        </div>
      </div>
    </AuthenticatedPage>
  )
}

export default BatchUploadJobs
