import API from '../../../lib/api'

function getAssetTypes(_this, isHideAll) {
  let assetTypeList = JSON.parse(window.sessionStorage.getItem('mediaAssetTypes'))
  if (assetTypeList && assetTypeList.length > 0) {
    if(isHideAll){
      assetTypeList.shift()
    }
    _this.setState({
      assetTypeList,
    })
  } else {
    _this.api = new API({ url: '/martech-ad-service/assetType' })
    _this.api.get().then(
      response => {
        assetTypeList = response.data
        assetTypeList.unshift({
          id: 0,
          name: 'all'
        })
        window.sessionStorage.setItem('mediaAssetTypes', JSON.stringify(assetTypeList))
        _this.setState({
          assetTypeList,
        })
      }
    )
  }
}

function addAssetType(_this, name, bu) {
  _this.api = new API({ url: '/martech-ad-service/assetType' })
  _this.api.post({
    "name": name,
    "businessUnit": bu
  }).then(
    response => {
      const assetTypeList = JSON.parse(window.sessionStorage.getItem('mediaAssetTypes'))
      assetTypeList.push(response.data)
      window.sessionStorage.setItem('mediaAssetTypes', JSON.stringify(assetTypeList))
      _this.setState({
        assetTypeList,
      })
      _this.toggleAssetTypeBox(2)
    }
  )
}

function formatAssetOptions(assetTypeList, bu) {
  const _assetTypeList = []
  if(assetTypeList){
    assetTypeList.forEach(assetType => {
      if (!bu || (bu && assetType.businessUnit === bu)){
        _assetTypeList.push({
          text: assetType.name === 'all' ? 'All asset types' : assetType.name,
          value: assetType.name === 'all' ? ' ' : assetType.name,
        })
      }
    })
  }
  return _assetTypeList
}

export {
  getAssetTypes,
  addAssetType,
  formatAssetOptions
} 