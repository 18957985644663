import React from 'react'
import { Link } from 'react-router-dom'
import { Cell } from '../../../../components/Table'
import Row from '../../../../components/Table/Row'
import { getOnlyTime, getPrintableTime } from '../../../../lib/datetime'
import { getMessage } from '../../../../lib/translator'
import { Actions } from '../../EGiftingOrders/List/tableProperties'

export default function tablePropertie() {
  return {
    headers: [
      getMessage('customerSupport.egifting.header.orderno'),
      getMessage('eVoucher.filter.entityName'),
      getMessage('customerSupport.egifting.header.email'),
      getMessage('customerSupport.egifting.header.submitTime'),
      getMessage('customerSupport.egifting.header.amountPaid'),
    ],
    row: ({
      reference,
      senderEmail,
      amount,
      status,
      id,
      onAction,
      notificationStatus,
      entityId,
      entity,
      methods,
    }) => {
      return (
        <Row>
          <Cell>
            <Link
              className="order-number"
              to={`/customer-support/egifting-orders-pending-bank-approval/${id}`}
            >
              {reference}
            </Link>
          </Cell>
          <Cell>
            <Link
              className="order-number"
              to={`/customer-support/gifting-entities/view/${entityId}`}
            >
              {entity?.name}
            </Link>
          </Cell>
          <Cell>{senderEmail}</Cell>
          <Cell className="column-creation-time">
            <div>
              {methods.deposit?.depositTime
                ? getPrintableTime(methods.deposit?.depositTime).split(', ')[0]
                : null}
            </div>
            <small className="text-muted">
              {methods.deposit?.depositTime
                ? getOnlyTime(methods.deposit?.depositTime)
                : null}
            </small>
          </Cell>
          <Cell className="text-align-center">{amount}</Cell>
          <Cell>
            <Actions
              onAction={onAction}
              id={id}
              notificationStatus={notificationStatus}
              status={status}
            />
          </Cell>
        </Row>
      )
    },
  }
}
