import React from 'react'
import { Input, Select } from '../../../../components/Form'
import Tabs from '../../../../components/Tabs'
import { VideoCardCarousel } from '../../../../components/migrated-from-admin-ui-builder'
import { getMessage } from '../../../../lib/translator'
import { fontSizes } from '../constants.js'

const VideoCarouselLayout = () => {
  return {
    preview: VideoCardCarousel,
    fields: ({ getState, updateState, parent }) => {
      const activeIndex = Number(getState(['activeIndex'])) || 0
      const emptySlide = {
        src: '',
        details: '',
      }
      const slides = getState(['videoCards']) || [emptySlide]
      const currentSlide = slides[activeIndex] || emptySlide

      const newState = (key, value) => {
        parent.setState((prevState) => {
          const newState = Object.assign({}, prevState)
          if (!newState.values.videoCards) {
            newState.values.videoCards = []
            newState.values.videoCards.push(slides[activeIndex])
          }
          newState.values.videoCards[activeIndex][key] = value
          return prevState
        })
      }
      const showDelete = slides && slides.length > 1
      return (
        <div>
          <div className="flex-around">
            <Tabs
              items={slides.map((slide, index) => (
                <span key={index}>
                  slide {index + 1}
                  {showDelete && (
                    <span
                      className="delete-tab"
                      onClick={() => {
                        slides.splice(index, 1)
                        updateState(
                          ['activeIndex'],
                          index !== 0 && index === slides.length
                            ? index - 1
                            : index
                        )
                      }}
                    >
                      &times;
                    </span>
                  )}
                </span>
              ))}
              default={activeIndex}
              active={activeIndex}
              onClick={(value) => {
                updateState(['activeIndex'], value)
              }}
            />
            <a
              className="add-tab"
              onClick={() => {
                const slidesCount = slides.length
                updateState(['videoCards'], slides.concat(emptySlide))
                updateState(['activeIndex'], slidesCount)
              }}
            >
              + {getMessage('videoCarousel.addSlide')}
            </a>
          </div>
          <div className="form-sections">
            <div className="form-section">
              <Input
                label={getMessage('videoCarousel.videoUrl.title')}
                placeholder={getMessage('videoCarousel.videoUrl.placeholder')}
                value={currentSlide['src']}
                onChange={(value) => {
                  newState('src', value)
                }}
              />
              {activeIndex === 0 && (
                <Select
                  label={getMessage('videoCarousel.fontSize.label')}
                  placeholder={getMessage('videoCarousel.fontSize.placeholder')}
                  options={fontSizes}
                  value={getState(['fontSizeForDescription']) || ''}
                  onChange={(value) => {
                    updateState(['fontSizeForDescription'], value)
                  }}
                />
              )}
              <Input
                label={getMessage('videoCarousel.description.title')}
                placeholder={getMessage(
                  'videoCarousel.description.placeholder'
                )}
                value={currentSlide['details']}
                onChange={(value) => {
                  newState('details', value)
                }}
              />
            </div>
            <div className="form-section">
              <Input
                label="subtitle"
                placeholder="Enter subtitle here"
                value={getState(['subtitle']) || ''}
                onChange={(value) => {
                  updateState(['subtitle'], value)
                }}
              />
              {activeIndex === 0 && (
                <Input
                  label={getMessage('videoCarousel.textColor.label')}
                  placeholder={getMessage(
                    'videoCarousel.textColor.placeholder'
                  )}
                  type="color"
                  value={getState(['textColorForDescription']) || ''}
                  onChange={(value) => {
                    updateState(['textColorForDescription'], value)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )
    },
  }
}
export default VideoCarouselLayout
