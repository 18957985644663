import React from 'react'
import { getMessage } from '../../../../lib/translator'
import { getSession } from '../../../../lib/auth'
import SelectAdvertiserSearch from '../../../../components/SelectAdvertiserSearch'
import {
  BaseForm,
  Input,
  Select,
  Textarea,
  VALIDATION_TYPES,
  DateRangePicker,
} from '../../../../components/Form'
import {
  formattedresellAgentList,
} from '../advertiserList'

export default class CampaignForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: this.props.value
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState(prevState => {
      const newState = Object.assign({}, prevState)
      newState.values = newProps.value
      return newState
    })
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    return (
      <div className="website-page-edit">
        <Form className="website-page-form">

          <h3 className="section-title">{getMessage('media.campaigndetail.campaign.h3')}</h3>
          <div className="section-wrap">
            <div className="section-row">
              <Input
                className="section-row-input"
                label={getMessage('media.campaigndetail.campaign.name')}
                placeholder={getMessage('media.campaigndetail.campaign.name.placeholder')}
                name="campaignName"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ['campaignName'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    'media.campaigndetail.campaign.name.requiredMessage'
                  ),
                }}
              />
              <Input
                className="section-row-input"
                label={getMessage('media.campaigndetail.campaign.budget')}
                placeholder={getMessage('media.campaigndetail.campaign.budget.placeholder')}
                name="campaignBudget"
                type="number"
                min={0}
                step="0.01"
                prefix={getSession().organization?.currency?.symbol}
                required
                {...this.generateStateMappers({
                  stateKeys: ['campaignBudget'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    'media.campaigndetail.campaign.budget.requiredMessage'
                  ),
                }}
              />
            </div>
            <div className="section-row">
              <Input
                className="section-row-input"
                label={getMessage('media.campaigndetail.campaign.objective')}
                placeholder={getMessage('media.campaigndetail.campaign.objective.placeholder')}
                name="campaignObjective"
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ['campaignObjective'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
              <DateRangePicker
                className="section-row-input"
                label={getMessage('media.campaigndetail.campaign.time')}
                {...this.generateStateMappers({
                  stateKeys: ['campaign_duration'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                displayFormat="YYYY-MM-DD"
                required
                isOutsideRange={()=>false}
                startDatePlaceholder={getMessage(
                  'media.campaigndetail.campaign.time.startDate.placeholder'
                )}
                endDatePlaceholder={getMessage(
                  'media.campaigndetail.campaign.time.endDate.placeholder'
                )}
                validationStrings={{
                  valueMissing: getMessage('media.campaigndetail.campaign.time.requiredMessage')
                }}
              />
            </div>
            <div className='section-row'>
              <Textarea
                label={getMessage('media.campaigndetail.campaign.description')}
                placeholder={getMessage('media.campaigndetail.campaign.description.placeholder')}
                name="description"
                maxLength={100}
                {...this.generateStateMappers({
                  stateKeys: ['description'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>

          <h3 className="section-title">{getMessage('media.campaigndetail.buyer.h3')}</h3>
          <div className="section-wrap">
            <div className="section-row">
              <SelectAdvertiserSearch
                className="section-row-input"
                label={getMessage('media.campaigndetail.buyer.advertiser')}
                placeholder={getMessage('media.campaigndetail.buyer.advertiser.placeholder')}
                _this={this}
                required
                stateKey="advertiserClientIdList"
                advertisers={this.props.advertiserList}
              ></SelectAdvertiserSearch>
              <Select
                className="section-row-input"
                label={getMessage('media.campaigndetail.buyer.resell')}
                placeholder={getMessage('media.campaigndetail.buyer.resell.placeholder')}
                options={formattedresellAgentList(this.props.resellAgentList)}
                name="agentId"
                {...this.generateStateMappers({
                  stateKeys: ['agentId'],
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    'media.campaigndetail.buyer.resell.requiredMessage'
                  ),
                }}
              />
            </div>
          </div>

          <div className="form-buttons">
            <SubmitButton>
              {getMessage('websitePage.form.submitText')}
            </SubmitButton>
            <CancelButton>
              {getMessage('websitePage.form.cancelText')}
            </CancelButton>
          </div>
        </Form>
      </div>
    )
  }
}
