import React from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  DateRangePicker,
  Select,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import { formatAssetOptions } from '../../MediaAssetManagement/AssetTypeList'
import './style.css'


export default class Filters extends BaseForm {
  constructor (props) {
    super(props)
    this.state.values.status = ''
    this.state = {}
  }

  render () {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    const { assetTypeList, defaultStartDate, defaultEndDate } = this.props.options

    return (
      <Form className='store-filter-form'>
        <div className='form-fields'>
          <Input
            label={getMessage('media.storeList.filters.name')}
            placeholder={getMessage('media.storeList.filters.name.placeholder')}
            name='campaign'
            className='section-row-input'
            type='text'
            {...this.generateStateMappers({
              stateKeys: ['storeName'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONBLUR,
            })}
            validationStrings={{
              valueMissing: getMessage('input.requiredMessage'),
            }}
          />
          <Select
            label={getMessage('media.storeList.filters.assettype')}
            placeholder={getMessage('media.storeList.filters.assettype.placeholder')}
            name='assetType'
            options={formatAssetOptions(assetTypeList)}
            {...this.generateStateMappers({
              stateKeys: ['assetType'],
              loseEmphasisOnFill: true,
            })}
          />
          <div className='section-row'>
            <DateRangePicker
              className='section-row-input-auto'
              label={getMessage('media.storeList.filters.time')}
              {...this.generateStateMappers({
                stateKeys: ['filter_duration'],
                loseEmphasisOnFill: true,
              })}
              displayFormat='YYYY-MM-DD'
              startDatePlaceholder={defaultStartDate}
              endDatePlaceholder={defaultEndDate}
              isOutsideRange={()=>{return false}}
            />

          </div>
        </div>
        <SubmitButton>{getMessage('brand.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('brand.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
