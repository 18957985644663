import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import { getMessage } from '../../../../lib/translator'

import AddCreativeForm from './AddCreativeForm'
import './style.css'


const APIurl = '/martech-creative-service/creative/'

export default class MediaAddCreative extends Component {
  constructor(props) {
    super(props)

    this.api = null
    this.state = {
      showLoader: true,
      data: {},
      apiUrl: APIurl,
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
    }
  }

  render() {

    return (
        <AuthenticatedPage
            menu={this.props.menu}
            className='creative-add-creative-page'
            title={getMessage('media.creative.add.title')}
        >

        <div className="header-container">
            <h1 className="title">{getMessage('media.creative.add.title')}</h1>
            <div className='timestamp'>
                <Link to="/media-monetization/media-creativemanagement/media-creative-detail" className="primary button">
                + <span className="text">{getMessage('media.add.text')}</span>
                </Link>
            </div>
         </div>

        <AddCreativeForm
            lineItemId={this.props.match.params.lineItemId}
            packageId={this.props.match.params.packageId}
        >
        </AddCreativeForm>

      </AuthenticatedPage>
    )
  }
}
