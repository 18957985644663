import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Cell } from 'components/Table';
import { formatDate, formatTime } from 'lib/datetime';
import { getMessage } from 'lib/translator';
import FpIcon from 'icons/fp-store-icon.svg';
import SingleOrderItemRow from './SingleOrderItemRow';
import { getItemsWithSubstitutionAndAmendments } from './helpers';
import { getCalculatedSubtotalOrderValues } from 'lib/commonlyused/orderCalculations';
import _ from 'lodash';
import './style.scss';

function SingleOrderView({ order, currency, isPendingOrCancelled }) {
  const { referenceNumber, status, seller, preferredDate, slotStartTime, slotEndTime } = order;
  const orderItems = useMemo(() => getItemsWithSubstitutionAndAmendments(order), [order]);

  if (order.items.length === 0) {
    return <div>{getMessage('order.details.itemsTable.emptyMessage')}</div>
  }

  const timeRange = (slotStartTime && slotEndTime) ? `${formatTime(slotStartTime)} - ${formatTime(slotEndTime)}` : null;

  const {
    subTotalPrice,
    subTotalDiscount,
    subTotalAmount
  } = getCalculatedSubtotalOrderValues({
    items: orderItems,
    status: order.status,
    shipping: order.shipping,
    isPendingOrCancelled: isPendingOrCancelled(order.status)
  }, 'saleOrder')

  return (
    <React.Fragment>
      <div className="sub-heading-table">
          <div>
            <img src={seller?.logo || FpIcon} alt="Store logo" className="store-logo" />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div>
              <strong>{seller?.name || 'FairPrice'}</strong>
              { seller?.vendorCode && <span style={{ color: '#80959d' }}>&nbsp;(Direct Fulfilment)</span> }
            </div>
            <div style={{display: 'flex'}}>
              {referenceNumber && (
                <Link to={`/customer-support/delivery-orders/${referenceNumber}`}>X{referenceNumber}</Link>
              )}
              <div className='delivery-slot'><span>{getMessage('deliveryOrder.details.deliverySlot')}:&nbsp;
                </span>{formatDate(preferredDate)}, {timeRange}</div>
            </div>
          </div>
          <div className='status-text'>
            {getMessage('order.log.status')}:  <span className={status.toLowerCase()}>{_.capitalize(status)}</span>
          </div>
      </div>
      {
        orderItems.map((item, index) => {
          const tableRowClassNames = [];
          tableRowClassNames.push(index === 0 ? 'offset-top' : 'child-row');
          tableRowClassNames.push(item.orderDetails.substitutedItemId ? 'sub-item-gray-out' : '');
          return (
            <SingleOrderItemRow
              key={item.orderDetails.orderItemId}
              rowClassName={tableRowClassNames.join(' ')}
              item={item}
              currency={currency}
              isPendingOrCancelled={isPendingOrCancelled(order.status)}
              dataSource="saleOrder"
            />
          )
        })
      }
      <Row style={{backgroundColor: 'transparent', lineHeight: '0.5rem'}}>
        <Cell /><Cell /><Cell /><Cell />
        <Cell className='text-right'>
          <small className='text-muted'>
            {getMessage('order.details.summary.shippingCharge')}
          </small>
        </Cell>
        <Cell /><Cell />
        <Cell className='text-right'>
          <small className='text-muted'>
            {currency.symbol} {Number(order.shipping).toFixed(2)}
          </small>
        </Cell>
      </Row>
      <Row className='without-border' style={{backgroundColor: 'transparent', lineHeight: '0.5rem'}}>
        <Cell /><Cell /><Cell /><Cell />
        <Cell className='text-right'>
          <small className='text-muted' style={{fontWeight: 'bold'}}>
            {getMessage('saleOrder.details.subtotal')}
          </small>
        </Cell>
        <Cell className='text-right' testId='order-total-price'>
          <small className='text-muted' style={{fontWeight: 'bold'}}>
            {currency.symbol} {subTotalPrice.toFixed(2)}
          </small>
        </Cell>
        <Cell className='text-right' testId='order-total-discount'>
          <small className='text-muted' style={{fontWeight: 'bold'}}>
            {currency.symbol} {subTotalDiscount.toFixed(2)}
          </small>
        </Cell>
        <Cell className='text-right' testId='order-total-amount'>
          <small className='text-muted' style={{fontWeight: 'bold'}}>
            {currency.symbol} {subTotalAmount.toFixed(2)}
          </small>
        </Cell>
      </Row>
    </React.Fragment>
  )
}

export default SingleOrderView;
