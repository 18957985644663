import React from 'react'
import API from '../../../lib/api'
import { TABLE_ACTIONS }  from '../../../containers/ListingPage'
import { getMessage } from '../../../lib/translator'
import { Dialog } from '../../../components/Popup'

function toggleLoader(_this, value){
  if(_this.state.showLoader){
    _this.setState({
      showLoader: value
    })
  }
}

function toggleDeleteDialog(_this, status, id, actionFun) {
  _this.setState({
    deleteDialog: {
      shown: status === 1,
      id,
    }
  })
  toggleLoader(_this, false)
  _this.actionFun = actionFun
}

function confirmDelete(_this, _url, _className, _listUrl) {
  const _id = _this.state.deleteDialog.id
  _this.setState({
    deleteDialog: {
      shown: false,
      id: null,
    }
  })
  toggleLoader(_this, true)
  _this.api = new API({ url: _url + _id })
  _this.api.delete().then(
    () => {
      if(_className){
        let _page = Number(window.localStorage.getItem(_className))
        _this.refreshApi = new API({ url: `${_listUrl}?page=${_page}` })
        _this.refreshApi.get().then(
          response => {
            if(response.data.content?.length === 0){
              _page -= 1
            }
            window.localStorage.setItem(_className, _page)
            _this.actionFun(TABLE_ACTIONS.SET_API_PARAMS, {page: _page})
            toggleLoader(_this, false)
          }
        )
      }else{
        toggleLoader(_this, false)
      }
    },
    error => {
      toggleDeleteDialog(_this)
      throwError(_this, error, getMessage('errorDialog.delete.error.title'))
    }
  )
}

function throwError(_this, error, title) {
  if (error.code === 401) {
    throw error
  }else{
    _this.setState({
      showLoader: false,
      errorDialog: {
        shown: true,
        title: title || 'Request Error',
        message: error.message || `${error.method}, ${error.code}`
      }
    })
  }
}

function hideErrorDialog(_this){
  _this.setState({
    errorDialog: {
      shown: false,
      title: '',
      message: '',
    }
  })
}

function DeleteAndErrorDialog(props){
  const { _this, deleteDialog, errorDialog, deleteApi, deleteClass, refreshApi } = props
  return (
    <div>
      {deleteDialog?.shown && (
        <Dialog
          show={deleteDialog.shown}
          title={getMessage('deleteDialog.title')}
          information={getMessage('deleteDialog.information')}
          closeText={getMessage('dialog.cancelText')}
          okText={getMessage('dialog.okText')}
          onOk={()=>{confirmDelete(_this, deleteApi, deleteClass, refreshApi)}}
          close={()=>{toggleDeleteDialog(_this)}}
        />
      )}
      {errorDialog?.shown && (
        <Dialog
          show={errorDialog.shown}
          title={errorDialog.title}
          information={errorDialog.message}
          closeText={getMessage('dialog.okText')}
          close={()=>{hideErrorDialog(_this)}}
        />
      )}
    </div>
  )
}

export {
  toggleDeleteDialog,
  confirmDelete,
  throwError,
  hideErrorDialog,
  DeleteAndErrorDialog
}
