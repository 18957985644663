import React, { Fragment } from 'react'
import infoIcon from 'components/Form/info-icon.svg'
import { getMessage } from 'lib/translator'

const ConditionHeader = () => (
  <Fragment>
    <div className="tab-header mt-1">
      {getMessage('luckydraw.condition-header')}
    </div>
    <div className="normal-text mb-1">
      {getMessage('luckydraw.inclusion-mechanics')}
    </div>
    <div className="label-tooltip">
      <div className="ml-0 mr-1 mt-2">
        <div className="optional-header">
          <b className="tab-header">
            {getMessage('luckydraw.condition-header-2')}
          </b>
          <img className="ml-1 mb-0 icon-3" src={infoIcon} alt="note" />
        </div>
        <div className="label-tooltiptext tooltip-three">
          <div>
            <b>{getMessage('luckydraw.tooltip-note')} </b>
            {getMessage('luckydraw.condition-note')}
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default ConditionHeader
