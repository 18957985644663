import React from 'react'

export const View = ({
  display = 'flex',
  direction = 'row',
  alignItems = 'center',
  justifyContent = 'center',
  overflow = 'auto',
  background,
  gap = 0,
  margin = '0',
  borderRadius,
  padding,
  children,
  position = 'relative',
  minHeight,
  height,
  width,
  border,
  style,
  ...rest
}) => {
  const actualStyle = {
    width: width || '100%',
    flexDirection: direction,
    justifyContent,
    alignItems,
    gap,
    margin,
    display,
    overflow,
    position,
    height,
    padding,
    background,
    border,
    borderRadius,
    minHeight: minHeight ?? height,
    ...(style ? style : {}),
  }

  return (
    <div style={actualStyle} {...rest}>
      {children}
    </div>
  )
}
