import React from 'react'
import { LINK_HOST } from 'config/app'
import API from 'lib/api'
import { formatIsoDate } from 'lib/datetime'
import { paymentErrorMsg } from '../service'
import { Input } from 'components/Form'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'

import '../style.css'

const InfoRow = ({ title, info, showRow }) => {
  return (
    showRow && (
      <div className="info-row">
        <div>
          <b>{title}</b>
        </div>
        <div>{info}</div>
      </div>
    )
  )
}

const parseJwcMembership = (membershipObj, paymentErrMsg) => {
  const endDate = membershipObj.membership.endDate
  const startDate = membershipObj.membership.startDate
  const isActive =
    new Date(endDate).getTime() >= new Date().getTime() &&
    new Date().getTime() >= new Date(startDate).getTime()
  return {
    status: isActive ? 'Active' : 'Inactive',
    packageName: membershipObj.package.title,
    cardNumber: isActive ? membershipObj.membership.cardNumber : null,
    endDate: formatIsoDate(endDate),
    startDate: isActive
      ? formatIsoDate(membershipObj.membership.startDate)
      : null,
    renewal: isActive
      ? membershipObj.membership.isAutoRenewal
        ? 'ON'
        : 'OFF'
      : null,
    paymentError: isActive ? paymentErrMsg : null,
  }
}

export const PaymentError = ({ paymentError }) => {
  return (
    <div className="payment-wrapper">
      <div>
        <b>Payment error</b>
      </div>
      <div>{paymentError}</div>
    </div>
  )
}

const JwcOperations = ({
  uid,
  membershipStatus,
  refreshJwcMembership,
  cardNumber,
}) => {
  const [toUid, setToUid] = React.useState('')
  const [showPopup, setShowPopup] = React.useState(false)
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const handleMoveJwc = () => {
    setLoading(true)
    const shiftjwc = new API({
      url: `${LINK_HOST}/laksa/internal/memberships/cards/${cardNumber}/move`,
    })
    shiftjwc
      .post({ to: toUid, from: uid })
      .then(() => {
        setShowPopup(true)
        refreshJwcMembership()
      })
      .catch((err) => {
        setError(err.message)
        setShowPopup(true)
      })
      .finally(() => {
        setLoading(false)
        setToUid('')
      })
  }

  return (
    <div className="jwc-operations-wrapper">
      <hr />
      <h3>Operations</h3>

      <div>
        <div>
          <b>Shift Jwc membership</b>
        </div>
        <Input
          type="text"
          label={getMessage(
            'customer.details.jwc.jwcoperations.fromuid.inputlabel'
          )}
          placeholder={getMessage(
            'customer.details.jwc.jwcoperations.fromuid.placeholder'
          )}
          name="fromUid"
          value={uid}
          disabled={true}
        />
        <Input
          type="text"
          label={getMessage(
            'customer.details.jwc.jwcoperations.touid.inputlabel'
          )}
          placeholder={getMessage(
            'customer.details.jwc.jwcoperations.touid.placeholder'
          )}
          name="toUid"
          value={toUid}
          onChange={(e) => setToUid(e)}
        />
      </div>
      <button
        className="operations-button"
        disabled={membershipStatus !== 'Active' || toUid.length === 0}
        onClick={handleMoveJwc}
      >
        {loading
          ? '...'
          : getMessage('customer.details.jwc.jwcoperations.shiftButton')}
      </button>
      <Popup
        show={showPopup}
        heading={error ? 'Error' : 'Successfully shifted membership'}
        close={() => {
          setShowPopup(false)
          setError('')
        }}
      >
        <div>
          {error ? (
            <div style={{ color: 'red' }}>{error}</div>
          ) : (
            <span className="successCheckmark">Success &#9989;</span>
          )}
        </div>
      </Popup>
    </div>
  )
}

const initialMembership = {
  status: null,
  packageName: null,
  cardNumber: null,
  endDate: null,
  startDate: null,
  renewal: null,
  paymentError: null,
}

const Jwc = ({ uid }) => {
  const [membership, setMembership] = React.useState(initialMembership)

  const getCustomerJwcApi = new API({
    url: `${LINK_HOST}/laksa/internal/memberships?uid=${uid}&programmeId=1`,
  })

  const getJwc = async () => {
    try {
      const jwcObc = await getCustomerJwcApi.get()
      const latestOrder = jwcObc.orders[0]

      const getPaymentErrMsg = paymentErrorMsg(
        latestOrder.status,
        latestOrder.failureReason
      )
      const parsedMembership = parseJwcMembership(jwcObc, getPaymentErrMsg)
      setMembership(parsedMembership)
    } catch (e) {
      if (e.code === 404 && e.message === 'Requested resource is not found') {
        setMembership({ ...initialMembership, status: 'Not a member' })
      }
    }
  }

  React.useEffect(() => {
    getJwc()
  }, [])

  return (
    membership && (
      <>
        <div className="tab-wrapper">
          <div className="membership-information">
            <InfoRow
              title={'Status'}
              info={membership.status}
              showRow={membership.status}
            />
            <InfoRow
              title={'Pkg Name'}
              info={membership.packageName}
              showRow={membership.packageName}
            />
            <InfoRow
              title={'Card Number'}
              info={membership.cardNumber}
              showRow={membership.cardNumber}
            />
            <InfoRow
              title={'Renewal'}
              info={membership.renewal}
              showRow={membership.renewal}
            />
          </div>
          <div className="date-information">
            <InfoRow
              title={'End Date'}
              info={membership.endDate}
              showRow={membership.endDate}
            />
            <InfoRow
              title={'Start Date'}
              info={membership.startDate}
              showRow={membership.startDate}
            />
          </div>
        </div>
        <PaymentError paymentError={membership.paymentError} />
        <JwcOperations
          uid={uid}
          membershipStatus={membership.status}
          refreshJwcMembership={getJwc}
          cardNumber={membership.cardNumber}
        />
      </>
    )
  )
}
export default Jwc
