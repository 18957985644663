import React from 'react'
import { getMessage } from 'lib/translator'
import './style.scss'

function SegmentIoTags({ segmentIoTags }) {
  return (
    <div className="segment-io-tags-section">
      <h4>{getMessage('customer.entityMetaData.segmentIo.header')}</h4>
      <div className='segment-tags-wrapper'>
        {
          segmentIoTags.map(tag => (
            <span className='segment-tag' key={tag.id}>
              {tag.name}
            </span>
          ))
        }
      </div>
    </div>
  )
}

export default SegmentIoTags;
