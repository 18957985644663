import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getMessage } from '../../../lib/translator'
import editIcon from '../commonMedia/images/edit-icon.svg'

export default function CampaignView (props) {
  return (
    <div className='adset-summary-wrap'>
      <div className='adset-summary-header'>
        <h3>Campaign Information</h3>
        <div className='editIcon'>
          <Link to={`/media-monetization/media-allcampaigns/media-campaign-detail/${props.data?.id}`}>
            <img alt='edit' src={editIcon} />
          </Link>
        </div>
      </div>
      <div className='adset-summary-inner'>
        <div className='adset-summary-item description'>
          <small className='text-muted'>{getMessage('media.campaigndetail.campaign.name')}: <span
            className='black'>{props.data?.campaignName}</span></small>
        </div>
        <div className='adset-summary-item'>
          <small className='text-muted'>{getMessage('media.campaigndetail.campaign.budget')}: <span
            className='black'> $ {props.data?.campaignBudget}</span></small>
        </div>
        <div className='adset-summary-item'>
          <small className='text-muted'>{getMessage('media.campaigndetail.campaign.objective')}: <span
            className='black'>{props.data?.campaignObjective}</span></small>
        </div>
        <div className='adset-summary-item'>
          <small className='text-muted'>{getMessage('media.campaigndetail.campaign.time')}: <span
            className='black'>{props.data?.campaignStartDate} ~ {props.data?.campaignEndDate}</span></small>
        </div>
        <div className='adset-summary-item'>
          <small className='text-muted'>{getMessage('media.campaigndetail.buyer.resellname')}:
            <span className='black advertiser-name'>{props.data?.agent?.agentName ? props.data?.agent?.agentName : "-"}</span>
          </small>
        </div>
        <div className='adset-summary-item description'>
          <small className='text-muted'>{getMessage('media.campaigndetail.buyer.adname')}:
            <span className='black'>
                {props.data?.advertiserClientList?.map((ad, index) =>
                  <span key={index} className='advertiser-name'>{ad.vendorCode ? `${ad.advertiserName} - ${ad.vendorCode}` : ad.advertiserName}</span>,
                )}
              </span>
          </small>
        </div>
        <div className='adset-summary-item description'>
          <small className='text-muted'>{getMessage('media.campaigndetail.campaign.description')}: <span
            className='black'>{props.data?.description}</span></small>
        </div>
        <div className='adset-summary-item'>
          <small className='text-muted'>{getMessage('media.campaigndetail.campaign.offersverified')}: <span
            className='black'>{props.data?.offersVerified}</span></small>
        </div>
        <div className='adset-summary-item'>
          <small className='text-muted'>{getMessage('media.campaigndetail.campaign.status')}: <span
            className='black'>{props.data?.status}</span></small>
        </div>
        <div className='adset-summary-item'>
          <small className='text-muted'>{getMessage('media.public.createdby')}: <span
            className='black'>{props.data?.createdBy} | {moment(props.data?.createdOn).format('ddd, DD MMM YYYY, h:mm A')}</span></small>
        </div>
        <div className='adset-summary-item'>
          <small className='text-muted'>{getMessage('media.public.updatedby')}: <span
            className='black'>{props.data?.updatedBy} | {moment(props.data?.updatedOn).format('ddd, DD MMM YYYY, h:mm A')}</span></small>
        </div>
      </div>
    </div>
  )
}
