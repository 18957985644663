import React, { useEffect, useState } from 'react'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import API from '../../../../lib/api'
import Loader from 'components/Loader'
import { ManageStorePopupForm } from './Form'
import { inMemoryStore } from '../../../../store'

export const ManageStore = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [stores, setStores] = useState(null)
  const [organisationId, setOrganisationId] = useState()

  useEffect(() => {
    const storeApi = new API({ url: '/account-service/store' })

    storeApi.get({ paginate: 'false' }).then((response) => {
      setStores(response.data.store)
    })
  }, [])

  useEffect(() => {
    try {
      const targetObj = JSON.parse(inMemoryStore.getItem('organization'))
      if (targetObj && targetObj.id) {
        setOrganisationId(targetObj.id)
      } else {
        return
      }
    } catch (err) {
      console.log(err)
      return
    }
  }, [])

  return (
    <>
      <button
        className="base button"
        data-testid="s&g-bulk-action-enabled"
        onClick={() => setModalOpen(true)}
      >
        <span className="text">
          {getMessage('operations.store.button.manage-store.text')}
        </span>
      </button>
      <Popup
        show={modalOpen}
        size="lg"
        className="manage-store-popup"
        close={() => setModalOpen(false)}
        heading="Manage Store - S&G"
      >
        {stores === null ? (
          <div data-testid="spinner-loader">
            <Loader />
          </div>
        ) : (
          <ManageStorePopupForm
            stores={stores}
            orgId={organisationId}
            onSubmit={() => setModalOpen(false)}
          />
        )}
      </Popup>
    </>
  )
}
