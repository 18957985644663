import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import { formatIsoDate } from '../../../lib/datetime'
import { getMessage } from '../../../lib/translator'
import { Select, Textarea, Input } from '../../Form'
import { Popup } from '../../Popup'
import DrawTabs from './DrawTabs'
import DrawWinnersPopUpDetails from './DrawWinnersPopUpDetails'
import { formatWinnerPositions, verifyInput } from './participantsUtils'
import { emailRegex } from '../../Rewards/utils'
import API from '../../../lib/api'
import Loader from '../../Loader'
import './style.css'

const dateTimeFormat = 'D MMM YYYY, HH:mm a'
const DISABLED_STATUS = ['COMPLETED', 'PENDING']

const Participants = ({ draws = '', isManual = false }) => {
  const [error, setError] = useState('')
  const [drawsList, setDrawsList] = useState([])
  const [inputError, setInputError] = useState({})
  const [selectedValue, setSelectedValue] = useState('')
  const [winnerPositions, setWinnerPositions] = useState('')
  const [drawWinnerModal, setDrawWinnerModal] = useState(false)
  const [email, setEmail] = useState('')
  const [previewModal, setPreviewModal] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const drawsListArr =
      draws.length > 0 &&
      draws.map((item, i) => {
        const cutOffAt = formatIsoDate(item.cutOffAt)
        return {
          index: i + 1,
          value: item.id,
          text: `Draw ${i + 1} (Cut-off: ${cutOffAt})`,
          cutOffAt,
          drawAt: item.drawAt && moment(item.drawAt).format(dateTimeFormat),
          disabled: DISABLED_STATUS.includes(item.status),
          winnersCount: item.winnersCount,
          reservedWinnersCount: item.reservedWinnersCount,
        }
      })
    setDrawsList(drawsListArr || [])
  }, [draws])

  const handleSelect = (e) => {
    setSelectedValue(e)
  }

  const handlePopup = () => {
    setInputError({})
    setDrawWinnerModal(false)
    setPreviewModal(false)
  }

  const handleAutoDraw = () => {
    setDrawWinnerModal(true)
  }

  const handleManualDraw = () => {
    const validInput = verifyInput(winnerPositions)
    if (validInput) {
      setInputError({ winnerInput: false })
      const data = formatWinnerPositions(winnerPositions)
      const api = new API({
        url: `/duck/draws/${selectedValue}/winners?manual=true`,
      })
      api
        .post({ winnerPositions: data })
        .then(setLoading(true))
        .then(
          setTimeout(function () {
            window.location.reload()
          }, 3000)
        )
        .catch((e) => {
          setLoading(false)
          setError(e.message || getMessage('luckydraw.server.error'))
        })
    } else {
      setLoading(false)
      setInputError({ winnerInput: true })
    }
  }

  return (
    <div className="participants-tab">
      {error && (
        <div className="error" data-testid="error">
          {error}
        </div>
      )}
      {!error && (
        <Fragment>
          <div className="col-6 pr">
            <Select
              required
              testid="draw-list"
              placeholder={getMessage('luckydraw.selectDraw.placeholder')}
              options={drawsList}
              value={selectedValue}
              onChange={handleSelect}
            />
          </div>
          {isManual && (
            <div className="col-6 pr normal-height-field mt">
              <Textarea
                required
                name="winnerInput"
                testid="winnerInput"
                value={winnerPositions}
                onChange={setWinnerPositions}
                placeholder={getMessage('luckydraw.inputWinners.text')}
                disabled={!selectedValue}
              />
              {inputError.winnerInput && (
                <div className="error">
                  {getMessage('luckydraw.inputField.error')}
                </div>
              )}
            </div>
          )}
          <div className="row mb-1">
            <div className="col-6 email mt">
              <Input
                label={getMessage('luckydraw.previewDraw')}
                placeholder={getMessage('luckydraw.previewDraw.placeholder')}
                type="text"
                value={email}
                onChange={setEmail}
              />
              {inputError.email && (
                <div className="error">
                  {getMessage('luckydraw.participantsTab.email.error')}
                </div>
              )}
            </div>
            <div className="col-6">
              <button
                className="button primary preview-draw-btn"
                type="button"
                data-testid="preview-draw-btn"
                disabled={!selectedValue || !email}
                onClick={() => {
                  if (!emailRegex.test(email)) {
                    setInputError({ email: true })
                  } else {
                    setInputError({ email: false })
                    setPreviewModal(true)
                  }
                }}
              >
                {getMessage('luckydraw.previewDraw')}
              </button>
            </div>
          </div>
          {loading ? (
            <div className="loading-msg-2">
              <Loader size="sm" />
              Looking up winners! Page reloading in 3 seconds...
            </div>
          ) : (
            <div className="row mb-1 actual-draw-section">
              <div className="col-6">
                <label> {getMessage('luckydraw.actualDraw')}</label>
              </div>
              <button
                className="button primary col-12"
                type="button"
                onClick={!isManual ? handleAutoDraw : handleManualDraw}
                data-testid="draw-winner-btn"
                disabled={!selectedValue}
              >
                {!isManual
                  ? getMessage('luckydraw.drawWinners.text')
                  : getMessage('luckydraw.lookupWinners.text')}
              </button>
            </div>
          )}
          {!loading && drawsList.length > 0 && (
            <DrawTabs
              drawsList={drawsList}
              selectedValue={selectedValue}
              drawWinnerModal={drawWinnerModal}
            />
          )}
          {(drawWinnerModal || (!!email && previewModal)) && (
            <Popup
              show
              heading={getMessage('luckydraw.Confirmation')}
              className="drawWinners-popup"
              close={handlePopup}
            >
              <DrawWinnersPopUpDetails
                drawsList={drawsList}
                selectedValue={selectedValue}
                previewEmail={previewModal && email}
                hidePopup={handlePopup}
              />
            </Popup>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default Participants
