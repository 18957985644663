import React from 'react'
import Image from '../Image'
import phoneIcon from './cx-phone-icon.svg'
import emailIcon from './cx-email-icon.svg'
import verifiedIcon from './verified.svg'
import './style.css'
import { getMessage } from '../../lib/translator'

export default function PhoneAndEmailDisplay(props) {
  const isDefault = props.defaultId === props.id
  return (
    <div className="cx-phone-email-container">
      <div className={`cx-phone-email-card cx-${props.type}-card`}>
        <span className={`${props.type}-icon`}>
          <img
            src={props.type === 'phone' ? phoneIcon : emailIcon}
            alt="icon"
          />
        </span>
        <div className={`customer-${props.type}`}>{props.value}</div>
        {props.status === 'VERIFIED' && (
          <Image src={verifiedIcon} alt="verified" />
        )}
      </div>
      <div className="cx-phone-email-actions">
        {isDefault && (
          <span className="default">
            {getMessage('customer.contact.details.default')}
          </span>
        )}
      </div>
    </div>
  )
}
