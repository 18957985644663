import React, { Fragment } from 'react'
import { SingleDatePicker, TimePicker } from '../../../Form'
import './style.css'
import { getMessage } from '../../../../lib/translator'

const SetRuleDuration = ({ _this, i }) => {
  const DURATIONS = ['start', 'end']
  return (
    <div className="row" data-testid="duration-date">
      <div className="flex rule-date">
        {DURATIONS.map((duration, key) => {
          return (
            <Fragment key={key}>
              <div className="col">
                <SingleDatePicker
                  enableToday
                  label={getMessage(`rewards.${duration}Date`)}
                  placeholder={getMessage(`rewards.${duration}Date`)}
                  {..._this.generateStateMappers({
                    stateKeys: ['rulesList', i, 'date', `${duration}Date`],
                  })}
                />
              </div>
              <div className="col">
                <TimePicker
                  label={getMessage(`rewards.${duration}Time`)}
                  placeholder={getMessage(`rewards.${duration}Time`)}
                  {..._this.generateStateMappers({
                    stateKeys: ['rulesList', i, 'date', `${duration}Time`],
                  })}
                />
              </div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default SetRuleDuration
