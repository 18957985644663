import React from 'react'
import { View } from '.'

export const FullScreen = ({ children, ...rest }) => {
  return (
    <View
      direction="column"
      justifyContent="space-around"
      position="fixed"
      style={{
        inset: 0,
        width: '100dvw',
        height: '100dvh',
        zIndex: 100,
      }}
      {...rest}
    >
      {children}
    </View>
  )
}
