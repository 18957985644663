import React from 'react'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
} from '../../../../../components/Form'
import Dialog from '../../../../../components/Popup/Dialog'
import { getMessage } from '../../../../../lib/translator'

export default class BankForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      formError: props.error,
      showConfirmDelete: false,
    }

    if (props.bankDetails) {
      this.state.values = props.bankDetails
    }
    this.closeDialog = this.closeDialog.bind(this)
    this.showDialog = this.showDialog.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.error) {
      this.setState({
        formError: newProps.error,
        showConfirmDelete: false,
      })
    }
  }

  showDialog() {
    this.setState({
      showConfirmDelete: true,
    })
  }

  closeDialog() {
    this.setState({
      showConfirmDelete: false,
    })
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const { bankDetails, onDelete } = this.props
    return (
      <div>
        <Dialog
          show={this.state.showConfirmDelete}
          close={this.closeDialog}
          okText={getMessage('settings.online.payment.delete')}
          closeText={getMessage('settings.online.payment.cancel')}
          onOk={() => {
            onDelete(this.state.values)
          }}
          title={getMessage('settings.online.payment.are.you.sure')}
          information={getMessage('settings.online.payment.account.delete')}
        />
        <Form className="bank-form">
          {this.state.formError && (
            <div className="form-error">{this.state.formError}</div>
          )}
          <div className="form-fields">
            <Input
              label={getMessage('settings.online.payment.bank.name')}
              placeholder={getMessage('settings.online.payment.bank.name')}
              name="bank-name"
              type="text"
              required
              {...this.generateStateMappers({
                stateKeys: ['bankName'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
            />
            <Input
              label={getMessage('settings.online.payment.accountnumber')}
              placeholder={getMessage('settings.online.payment.accountnumber')}
              name="account-number"
              type="text"
              required
              {...this.generateStateMappers({
                stateKeys: ['accountNumber'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
            />
            <Input
              label={getMessage('settings.online.payment.beneficiaryname')}
              placeholder={getMessage(
                'settings.online.payment.accountholdername'
              )}
              name="account-number"
              type="text"
              required
              {...this.generateStateMappers({
                stateKeys: ['beneficiaryName'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
            />
            <Input
              label={getMessage('settings.online.payment.branchifsccode')}
              placeholder={getMessage(
                'settings.online.payment.ifsccodeofbankbranch'
              )}
              name="ifsc"
              type="text"
              required
              {...this.generateStateMappers({
                stateKeys: ['ifscCode'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
            />
            <Input
              label={getMessage('settings.online.payment.city')}
              placeholder={getMessage('settings.online.payment.entercity')}
              name="city"
              type="text"
              required
              {...this.generateStateMappers({
                stateKeys: ['city'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Input
              label={getMessage('settings.online.payment.state')}
              placeholder={getMessage('settings.online.payment.enterstate')}
              name="state"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['state'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <Input
              label={getMessage('settings.online.payment.pincode')}
              placeholder={getMessage('settings.online.payment.enterpincode')}
              name="pincode"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['pincode'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
          <div className={`form-actions ${bankDetails ? 'details' : ''}`}>
            <SubmitButton>
              {getMessage('settings.online.payments.save')}
            </SubmitButton>{' '}
            {bankDetails && (
              <button
                type="button"
                className="button"
                onClick={this.showDialog}
              >
                {getMessage('settings.online.payment.removebankaccount')}
              </button>
            )}
          </div>
        </Form>
      </div>
    )
  }
}
