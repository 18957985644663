import React from 'react'
import moment from 'moment'
import crossIcon from '../../../../components/Form/cross_gray.svg'
import './style.css'

const dateStr = "YYYY-MM-DD"

function formatDuration(startDate, endDate){
  if(moment(startDate).isSame(moment(endDate), 'day')){
    return moment(startDate).format(dateStr)
  }else{
    return `${moment(startDate).format(dateStr)} ~ ${moment(endDate).format(dateStr)}`
  }
}

function DateMultiSelect(props){
  return (
    <div className='datepick_wrap'>
      { props.timeList?.map((item,index) => (
        <div className={`select-value index_${index}`} key={index}>
          <span className='select-value-label'>
            <small>{formatDuration(item.startDate, item.endDate)}</small>
          </span>
          <button type="button" className="select-value-icon" onClick={()=>{props.handleSelect(index)}} >
            <img src={crossIcon} alt="delete" />
          </button>
        </div>
      )) }
    </div>
  )
}

export {
  DateMultiSelect
}
