import React, { Component } from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { Row, Cell } from '../../../components/Table'
import Tooltip from '../../../components/Tooltip'
import { Dialog } from '../../../components/Popup'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import './style.css'

import emptyIcon from './empty.svg'

const DownloadImage = () => {
  const [fill, setFill] = React.useState('none')
  const handleOnMouseOver = () => {
    setFill('#008000')
  }
  const handleOnMouseOut = () => {
    setFill('none')
  }

  return (
    <div onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="20"
        viewBox="0 0 24 20"
      >
        <g fill={fill} fillRule="evenodd">
          <path d="M-3-6h30v30H-3z" opacity=".05" />
          <g stroke="#80959D">
            <path d="M1 15v4h22v-4M8 8l4.495 4L17 8M12.5 11.5V0" />
          </g>
        </g>
      </svg>
    </div>
  )
}

const emptyState = {
  icon: emptyIcon,
  message: 'There are no scheduled upload jobs',
}

const handleFileDownload = (e, fileID, onError) => {
  e.preventDefault()
  const downloadUrlApi = new API({
    url: `/catalogue-service/batch-jobs/${fileID}`,
  })
  if (fileID) {
    return downloadUrlApi
      .get()
      .then(response => {
        const { data: { url } = {} } = response || {}
        window.open(url)
      })
      .catch(e => {
        const { message } = e || {}
        if (message === 'file not found') {
          onError()
        } else {
          throw e
        }
      })
  }
}

const tableProperties = onError => {
  return {
    headers: [
      getMessage('scheduled.batch.jobs.column.jobName'),
      getMessage('scheduled.batch.jobs.column.fileName'),
      getMessage('scheduled.batch.jobs.column.userID'),
      getMessage('scheduled.batch.jobs.column.uploadedAt'),
      getMessage('scheduled.batch.jobs.column.scheduledAt'),
      'Download',
    ],
    row: ({ jobName, fileID, fileName, userID, scheduledAt, uploadedAt }) => (
      <Row>
        <Cell className="jobname">
          <span>{jobName}</span>
        </Cell>
        <Cell className="filename">
          <span>{fileName}</span>
          <Tooltip>{fileName}</Tooltip>
        </Cell>
        <Cell className="userID">{userID}</Cell>
        <Cell className="uploadedAt">{uploadedAt}</Cell>
        <Cell className="scheduledAt">{scheduledAt}</Cell>
        <Cell className="scheduledAt">
          <a
            className="download-button"
            onClick={e => {
              handleFileDownload(e, fileID, onError)
            }}
          >
            <DownloadImage />
          </a>
        </Cell>
      </Row>
    ),
  }
}

export default class ScheduledBatchUploads extends Component {
  constructor() {
    super()
    this.state = {
      showError: false,
    }
    this.toggleError = this.toggleError.bind(this)
  }

  toggleError() {
    this.setState({
      showError: !this.state.showError,
    })
  }

  render() {
    const props = this.props
    const { showError } = this.state
    return (
      <div>
        <ListingPageWithRoutes
          menu={props.menu}
          className="scheduled-jobs-page"
          addHeading="Scheduled Upload Jobs"
          title="Scheduled Upload Jobs"
          api={{
            url: '/catalogue-service/batch-jobs',
            transform: response =>
              response && response.data ? response.data : [],
          }}
          showLanguageDropDown
          emptyState={emptyState}
          tableProperties={tableProperties(this.toggleError)}
          primaryKey="fileID"
          form={{}}
        />
        <Dialog
          show={showError}
          title="Error"
          information={'File not found!'}
          close={this.toggleError}
          closeText={getMessage('dialog.okText')}
          btnClassName="primary"
        />
      </div>
    )
  }
}
