import React, { Component } from 'react'
import ListingPage from '../../../containers/ListingPage'
import RechargeWidget from './rechargeWidget'
import { Row, Cell } from '../../../components/Table'
import { getMessage } from '../../../lib/translator'
import { getSession } from '../../../lib/auth'
import { getPrintableTime } from '../../../lib/datetime'
import emptyIcon from './transactionEmptyState.svg'
import './style.css'

const emptyState = {
  additionalViews: [
    () => (
      <div className="empty-state">
        <img src={emptyIcon} alt="no-data" />
        <span>{getMessage('user.transactions.emptyList.text')}</span>
      </div>
    ),
  ],
}

const tableProperties = {
  headers: [
    getMessage('user.transactions.header.billingInfo'),
    getMessage('user.transactions.header.status'),
    getMessage('user.transactions.header.type'),
    getMessage('user.transactions.header.payment'),
  ],
  row: ({ service, startTime, status, amount, type, createdAt }) => (
    <Row>
      <Cell className="account-billing-info">
        <div className="account-mobile-view">
          {getMessage('user.transactions.header.billingInfo')}
        </div>
        <div className="transaction-name">{service.replace(/-/g, ' ')}</div>
        <div className="transaction-time">
          <small className="text-muted">
            {startTime
              ? getPrintableTime(startTime)
              : createdAt
              ? getPrintableTime(createdAt)
              : null}
          </small>
        </div>
      </Cell>
      <Cell className="account-payment-status">
        <div className="account-mobile-view">
          {getMessage('user.transactions.header.status')}
        </div>
        {['PENDING', 'COMPLETED', 'FAILED'].indexOf(status) > -1 ? (
          <div className={`payment-status-${status.toLowerCase()}`}>
            {getMessage(`user.transactions.status.${status.toLowerCase()}`)}
          </div>
        ) : (
          status
        )}
      </Cell>
      <Cell className="account-card-type">
        <div className="account-mobile-view">
          {getMessage('user.transactions.header.type')}
        </div>
        <div className="payment-type">{type.toLowerCase()}</div>
      </Cell>
      <Cell className="account-payment-amount">
        <div className="account-mobile-view">
          {getMessage('user.transactions.header.payment')}
        </div>
        <div className="payment-amount">{`${type === 'CREDIT' ? '+ ' : '- '}${
          getSession().organization.currency.symbol
        } ${amount}`}</div>
      </Cell>
    </Row>
  ),
}

export default class AccountBalance extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <ListingPage
        menu={this.props.menu}
        className="account-recharge-page"
        title={getMessage('user.accountBalance.heading')}
        api={{
          url: '/billing-service/transaction',
          transform: response => response.data.transaction,
        }}
        emptyState={emptyState}
        additionalViews={[RechargeWidget]}
        tableProperties={tableProperties}
      />
    )
  }
}
