import React from 'react'
import Pagination from '../../../../components/Pagination'
import { getMessage } from '../../../../lib/translator'

export default function MediaPagination(props){
  const { count, offset, limit } = props.paging
  const totalPages = Math.ceil(count / limit)

  return (
    <div className="pagination-container">
        <div className="pagination-count">{`${getMessage('pagination.text')} ${offset + 1} - ${limit > 0 && offset >= 0 ? Math.min(offset + limit, count) : count
        } ${getMessage('pagination.helperText')} ${count}`}</div>

        {totalPages > 1 && (
          <Pagination
            total={totalPages}
            current={Math.floor(offset / limit + 1)}
            onSelect={props.changePage}
          />
        )}
      </div>
  )
}
