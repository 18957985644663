import React from 'react'
import {
  BaseForm,
  Checkbox,
} from '../../../../../../components/Form'
import Loader from '../../../../../../components/Loader'
import { getMessage } from '../../../../../../lib/translator'
import BPrint from '../../../../../../lib/zebra'
import _ from 'lodash'
import moment from 'moment';
import './style.css'

const tags = {
  all: "Single Order",
  bulk: "Bulky Items",
  general: "Normal Items",
  freshfrozen: "FreshFrozen Items",
  others: "Others",
}
export default class PrintOrderForm extends BaseForm {
  constructor(props) {
    super(props);
    // add isChecked boolean to items in packages in packlists
    const packlistsWithChecked = (this.props.packlistData && this.props.packlistData.length > 0) ?
     this.props.packlistData.map(packlist => (
        {...packlist, packages: packlist.packages.map(item => ({...item, isChecked: false }))})
      ) : [];

    this.state = {
      data: this.props.data,
      packlists: this.groupPacklistsWithSameTag(packlistsWithChecked),
      loading: false
    }
    this.handleUpdateItemChecked = this.handleUpdateItemChecked.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  getZoneLabel(packageCode) {
    return packageCode.startsWith("Z2") ? "Z2" : "Z1";
  }

  onPrint(event) {
    event.preventDefault();
    const packingDetails = this.state.packlists
      .map(packlist => {
        return packlist.packages.filter(p => p.isChecked).map(pack => {
          return {
            ...pack,
            zoneLabel: packlist.ownerName === "Hyper Changi (FFS)" ? this.getZoneLabel(pack.packageCode) : ""
          }
        })
      }).flat()

    new BPrint().print(
      this.props.orderReferenceNumber,
      moment(this.state.data.slotStartTime).format('HH:mm:ss'),
      this.state.data.deliveryAddress.addresseeName,
      this.state.data.slotEndTime,
      packingDetails
    );
    this.props.onCancel();
  }

  groupPacklistsWithSameTag(packlists) {
    const res = [];
    for(let i = 0; i < packlists.length; i++){
       const ind = res.findIndex(x => x.tag === packlists[i].tag);
       if(ind !== -1){
          res[ind].packages = [...res[ind].packages, ...packlists[i].packages]
       } else {
          res.push(packlists[i]);
       }
    }
    return res;
  }

  selectAll(event) {
    event.preventDefault();
    this.setState((prevState) => {
      const newPacklists = _.cloneDeep(prevState.packlists);
      newPacklists.forEach(packlist => packlist.packages.forEach(p => p.isChecked = true ));
      return { ...prevState, packlists: newPacklists };
    })
  }

  clearAll(event) {
    event.preventDefault();
    this.setState((prevState) => {
      const newPacklists = _.cloneDeep(prevState.packlists);
      newPacklists.forEach(packlist => packlist.packages.forEach(p => p.isChecked = false ));
      return { ...prevState, packlists: newPacklists };
    })
  }

  handleUpdateItemChecked(packlistIndex, itemIndex, isChecked) {
    this.setState((prevState) => {
      const newPacklists = _.cloneDeep(prevState.packlists);
      newPacklists[packlistIndex].packages[itemIndex].isChecked = !isChecked
      return {...prevState, packlists: newPacklists};
    })
  }

  isAllChecked() {
    return this.state.packlists.flatMap(x => x.packages).every(p => p.isChecked);
  }

  isAllUnchecked() {
    return this.state.packlists.flatMap(x => x.packages).every(p => !p.isChecked);
  }

  checkedItemsCount() {
    return this.state.packlists.flatMap(x => x.packages).filter(p => p.isChecked).length;
  }

  renderPacklistsPackages() {
    return this.state.packlists.map((packlist, packlistIndex) => {
        return (
            <div key={packlist.id}>
              <p className="tag-label">{tags[packlist.tag]}
                {
                  packlist.packages.length === 0 && <span><br/>{getMessage('deliveryOrder.packages.print.noLabel')}</span>
                }
              </p>
              <div className={`checkboxes-wrapper ${(packlistIndex === this.state.packlists.length-1) && "last-item"}`}>
              {
                packlist.packages.map((item, itemIndex) => {
                  return (
                    <Checkbox
                        name={`packlist-item-${packlistIndex}-${itemIndex}`}
                        key={item.id}
                        inlineLabel={item.packageCode}
                        value={item.isChecked}
                        testid={`test-${item.packageCode.replace(/ /g,'')}`}
                        controlled
                        onChange={() => this.handleUpdateItemChecked(packlistIndex, itemIndex, item.isChecked)}
                    />
                    );
                  })
                }
              </div>
            </div>
        );
    })
  }

  render() {
    const { Form } = this.components

    return this.state.loading || !this.props.data ? (
      <Loader />
    ) : (
      <Form className="print-order-form" onCancel={this.props.onCancel}>
        <div className="top-labels-container">
            <p>{getMessage('deliveryOrder.packages.print.selectLabelsToPrint')}</p>
            {
              this.isAllChecked() ?
                <a href="#" onClick={this.clearAll}>{getMessage('deliveryOrder.packages.print.clearAll')}</a>
                : <a href="#" onClick={this.selectAll}>{getMessage('deliveryOrder.packages.print.selectAll')}</a>
            }
        </div>
        <div className="labels-display-container">
            { this.renderPacklistsPackages() }
        </div>
        <BPrint />

        <div className="actions" style={{justifyContent: 'flex-end'}}>
          <div className="number-of-selected-labels">
            <b data-testid="checked-items-count">{this.checkedItemsCount()}</b> label(s) selected
          </div>
          <button
            className="button"
            onClick={this.props.onCancel}
          >
            {getMessage('deliveryOrder.details.action.cancel')}
          </button>
          <button
            className="primary button"
            disabled={this.isAllUnchecked()}
            onClick={e => this.onPrint(e)}
          >
            {getMessage('order.details.print.text')}
          </button>
        </div>
      </Form>
    )
  }
}
