import React, { Component } from 'react'
import { Input } from '../../../../components/Form'
import { Actions } from '../form'
import { getMessage } from '../../../../lib/translator'
import EmailIcon from './email.png'

class Email extends Component {
  render() {
    const { parent, type, provider, allowKey } = this.props
    const _this = parent
    const readOnly = _this.getState([allowKey]) === false
    return (
      <React.Fragment>
        <Actions parent={_this} icon={EmailIcon} allowKey={allowKey} />
        <div className="form-fields">
          <Input
            name="smtpHost"
            label={getMessage('Smtp host')}
            placeholder={getMessage('Enter smtp Host')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'smtpHost'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            name="smtpUsername"
            label={getMessage('Smtp username')}
            placeholder={getMessage('Enter smtp Username')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'smtpUserName'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            name="smtpPassword"
            type="password"
            label={getMessage('Smtp password')}
            placeholder={getMessage('Enter smtp Password')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'smtpPassword'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            name="smtpPort"
            type="number"
            label={getMessage('Smtp port')}
            placeholder={getMessage('Enter smtp Port')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'smtpPort'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
          <Input
            name="defaultEmail"
            type="email"
            label={getMessage('Default email')}
            placeholder={getMessage('Enter default Email')}
            {..._this.generateStateMappers({
              stateKeys: [type, provider, 'defaultEmail'],
              loseEmphasisOnFill: true,
            })}
            readOnly={readOnly}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default Email
