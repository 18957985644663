import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Details from './Details'
import List from './List'

import './style.css';

class EGiftOrdersRouter extends Component {
  render() {
    const { router, ...props } = this.props
    const details = { ...props }
    details.url = '/customer-support'
    details.className = 'cx-listing'
    if (router.match.params.id) {
      const detailsOrder = { ...this.props }
      detailsOrder.url = '/customer-support'
      return <Details {...detailsOrder} />
    }
    return <List {...this.props} />
  }
}

export default withRouter(({ match, ...props }) => (
  <EGiftOrdersRouter router={{ match }} {...props} />
))
