import React from 'react'
import './style.css'
import { Checkbox } from '../../../Form'
import { getMessage } from 'lib/translator'

const CardSegment = ({ _this }) => {
  const CARD_TYPES = ['VISA', 'MasterCard', 'AMEX']

  return (
    <div className="cardtype-section single-column-field mb-1">
      <div className="field-label">
        <strong>{getMessage('omnichallenge.task.cardnetwork.label')}</strong>
      </div>
      <div className="normal-text mb-1">
        {getMessage('omnichallenge.task.cardnetwork.placeholder')}
      </div>
      {CARD_TYPES.map((card, cardIndex) => (
        <Checkbox
          key={cardIndex}
          inlineLabel={getMessage(`omnichallenge.task.cardnetwork.${card}`)}
          {..._this.generateStateMappers({
            stateKeys: ['cardSegment', card],
            loseEmphasisOnFill: true,
          })}
          name={card}
          disabled={_this.state.campaignStarted}
        />
      ))}
    </div>
  )
}

export default CardSegment
