import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SplitFactory } from '@splitsoftware/splitio-browserjs'
import SPLIT_FEATURES from './features'
import { SPLIT_KEY } from '../../config/app'
import { checkAndSetFeatureFlags } from './helpers'
import { setSplit as setActionTrailSplit } from '../../lib/actiontrail/actiontrail'

export const SplitContext = createContext({})

/* eslint-disable react/forbid-prop-types */

const SplitContextProvider = ({ userKey, children }) => {
  const [contextValue, setContextValue] = useState({
    splits: {},
    loading: true,
  })

  useEffect(() => {
    if (userKey === -1) {
      return
    }
    const factory = SplitFactory({
      core: {
        authorizationKey: SPLIT_KEY,
        key: userKey,
      },
    })
    const client = factory.client()
    const featureList = Object.values(SPLIT_FEATURES)

    client.on(client.Event.SDK_READY, function() {
      const treatments = client.getTreatmentsWithConfig(featureList)
      checkAndSetFeatureFlags(treatments)

      /**
       * @actiontrail
       */
      setActionTrailSplit(treatments)

      setContextValue({
        splits: treatments,
        loading: false,
        // To retrieve targeting rules based on custom attributes
        // Read more: https://help.split.io/hc/en-us/articles/360058730852-Browser-SDK
        getCustomAttributeTreatment: (flag, attributes) => client.getTreatmentWithConfig(flag, attributes),
      })
    })
  }, [userKey])

  return (
    <SplitContext.Provider value={contextValue}>
      {children}
    </SplitContext.Provider>
  )
}
SplitContextProvider.propTypes = {
  userKey: PropTypes.string,
}

export default SplitContextProvider

export const SplitConsumer = SplitContext.Consumer
