import React from 'react'
import { getMessage } from 'lib/translator'
import Upload from 'components/Form/Inputs/Upload'
import Checkbox from 'components/Form/Inputs/Checkbox'
import ColourLogo from '../../../pages/app-home/ManageSplashscreen/ColourLogo.png'
import WhiteLogo from '../../../pages/app-home/ManageSplashscreen/WhiteLogo.png'

const UploadLogo = ({ uploadType, selectedLogo, checkboxChangeHandler }) => {
  return (
    <div className="uploadlogo-section">
      <div className="defaultlogo-section">
        <div className="defaultlogo">
          <img src={ColourLogo} alt="colour Logo" />
        </div>
        <Checkbox
          inlineLabel="Colour Logo"
          name="Colour Logo"
          value={selectedLogo === 'colour'}
          controlled
          onChange={() => checkboxChangeHandler('colour')}
        />
      </div>
      <div className="defaultlogo-section">
        <div className="defaultlogo">
          <img src={WhiteLogo} alt="white logo" />
        </div>
        <Checkbox
          inlineLabel="White Logo"
          name="White Logo"
          value={selectedLogo === 'white'}
          controlled
          onChange={() => checkboxChangeHandler('white')}
        />
      </div>
      <Upload
        accept={'image/jpeg,image/jpg,image/png,application/json'}
        placeholder={getMessage(`app.splash.upload.${uploadType}.placeholder`)}
        onChange={(e) => checkboxChangeHandler('custom', e)}
      />
    </div>
  )
}

export default UploadLogo
