import React, { Fragment } from 'react'
import { getMessage } from '../../../../../../lib/translator'
import { Checkbox } from '../../../../../../components/Form'
import { STATUSES } from '../../Details.enum'
import { SuccessPopup } from '../../../../../../components/Popup'
import Refresh from './refresh.svg'

import {
  handleCancelOrder,
  openTablePopup,
  handleBack,
  handleSelectAll,
  handleDisabledSelectAll,
  handleRefundOrder,
  handleRejectOrder,
  handleSaveSubOrderStatus,
  handleCancelSubOrderStatus,
  handleLabelClassSelectAll,
  closeRefreshPopup,
  closeAutoRejectedPopup,
  refundButtonVisibilityCheck,
} from './HeaderHelpers'

const OrderSummaryHeader = props => {
  return (
    <div className="qc-header border-bottom">
      <h2>{getMessage('qc.orderSummary.Heading')}</h2>
      {(props.state.allSubOrdersStatus.indexOf(STATUSES.PREPARING) !== -1 ||
        props.state.allSubOrdersStatus.indexOf(STATUSES.SUBMITTED) !== -1 ||
        refundButtonVisibilityCheck(props) ||
        (!props.state.showHideElements &&
          props.state.editSubOrderStatus &&
          !Object.values(props.state.editSubOrderStatus).every(
            item => item === false
          ))) && (
        <div
          className={`summary-input-container ${!props.state.showHideElements &&
            'select-all-container'}`}
        >
          {props.state.showHideElements && props.state.refundOrder && (
            <Checkbox
              inlineLabel={getMessage('qc.orderSummary.SelectAll')}
              name="selectAllSubOrders"
              value={props.state.allSelected}
              controlled
              onChange={e => handleSelectAll(props, e)}
              labelClass={handleLabelClassSelectAll(props)}
              disabled={handleDisabledSelectAll(props)}
            />
          )}

          <div className="bulk-actions">
            {!props.state.showHideElements &&
              props.state.editSubOrderStatus &&
              Object.values(props.state.editSubOrderStatus).every(
                item => item === false
              ) && (
                <Fragment>
                  <b>{getMessage('qc.orderSummary.BulkActions')}:</b>
                  {(props.state.allSubOrdersStatus.indexOf(
                    STATUSES.PREPARING
                  ) !== -1 ||
                    props.state.allSubOrdersStatus.indexOf(
                      STATUSES.SUBMITTED
                    ) !== -1) && (
                    <button
                      type="button"
                      className="primary button"
                      onClick={() => handleCancelOrder(props)}
                    >
                      {getMessage('qc.orderSummary.CancelOrder')}
                    </button>
                  )}
                  {refundButtonVisibilityCheck(props) && (
                    <button
                      type="button"
                      className="primary button"
                      onClick={() => handleRefundOrder(props)}
                    >
                      {getMessage('qc.orderSummary.RefundOrder')}
                    </button>
                  )}
                  {props.state.allSubOrdersStatus.indexOf(
                    STATUSES.SUBMITTED
                  ) !== -1 &&
                    props.state.hideTemp && (
                      <button
                        type="button"
                        className="primary button"
                        onClick={() => handleRejectOrder(props)}
                      >
                        {getMessage('qc.orderSummary.RejectOrder')}
                      </button>
                    )}
                </Fragment>
              )}
            {!props.state.showHideElements &&
              props.state.editSubOrderStatus &&
              !Object.values(props.state.editSubOrderStatus).every(
                item => item === false
              ) && (
                <Fragment>
                  <button
                    type="button"
                    className="primary button"
                    onClick={e => handleSaveSubOrderStatus(props, e)}
                    disabled={props.state.submitting}
                    data-testid="summary-save"
                  >
                    {getMessage('qc.orderSummary.Save')}
                  </button>
                  <button
                    type="button"
                    className="button"
                    onClick={e => handleCancelSubOrderStatus(props, e)}
                    data-testid="summary-cancel"
                  >
                    {getMessage('qc.orderSummary.Cancel')}
                  </button>
                </Fragment>
              )}
            {props.state.cancelOrder && (
              <button
                type="button"
                className="primary button"
                onClick={() => openTablePopup(props)}
                disabled={!props.state.refundItems.some(item => item.value)}
              >
                {getMessage('qc.orderSummary.CancelSelected')}
              </button>
            )}
            {props.state.rejectOrder && (
              <button
                type="button"
                className="primary button"
                onClick={() => openTablePopup(props)}
                disabled={!props.state.radioSubOrder}
              >
                {getMessage('qc.orderSummary.RejectSelected')}
              </button>
            )}
            {props.state.refundOrder && (
              <button
                type="button"
                className="primary button"
                onClick={() => openTablePopup(props)}
                disabled={
                  !(
                    props.state.refundItems.some(a => a.value) ||
                    props.state.containerCheckboxItems.some(a => a.value)
                  )
                }
              >
                {getMessage('qc.orderSummary.RefundSelected')}
              </button>
            )}
            {props.state.showHideElements && (
              <button
                type="button"
                className="button"
                onClick={() => handleBack(props)}
              >
                {getMessage('qc.orderSummary.Back')}
              </button>
            )}
          </div>
        </div>
      )}
      <SuccessPopup
        {...props}
        show={props.state.showHideRefreshPopup}
        close={() =>
          closeRefreshPopup(
            props.handleState,
            props.state,
            props.history,
            props.location
          )
        }
        icon={Refresh}
        heading={getMessage('qc.orderSummary.refreshpopup.heading')}
        description={getMessage('qc.orderSummary.refreshpopup.description')}
        buttonText={getMessage('qc.orderSummary.refreshpopup.button')}
      />
      <SuccessPopup
        {...props}
        show={props.state.showHideAutoRejectedPopup}
        close={() =>
          closeAutoRejectedPopup(
            props.handleState,
            props.state,
            props.history,
            props.location
          )
        }
        icon={Refresh}
        heading={getMessage(
          'qc.orderSummary.refreshpopup.autoRejected.heading'
        )}
        description={getMessage('qc.orderSummary.refreshpopup.description')}
        buttonText={getMessage('qc.orderSummary.refreshpopup.button')}
      />
    </div>
  )
}

export default OrderSummaryHeader
