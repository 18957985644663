import React from 'react'
import API from 'lib/api'
import { LINKPASS_ADMIN_URL, LINK_HOST } from 'config/app'

const resolvePromises = async (apiCallArray) => {
  return Promise.all(apiCallArray)
}

export const useGetUserObject = (uid) => {
  const [userObj, setUserObj] = React.useState({
    'linkpass-profile': {},
    'auth0-profile': {},
    'link-profile': {},
  })

  const linkpassAdminApi = new API({
    url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/user/uid/${uid}`,
  })
  const linkAccByUidApi = new API({
    url: `${LINK_HOST}/users/search`,
  })
  const auth0ProfileApi = new API({
    url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/internal/user/uid/${uid}/auth0`,
  })

  React.useEffect(() => {
    const apiCalls = [
      linkpassAdminApi
        .get()
        .then((res) => res)
        .catch((err) => err),
      auth0ProfileApi
        .get()
        .then((res) => res)
        .catch((err) => err),
      linkAccByUidApi.post({ uid: [uid] }).then((res) => res),
    ]

    resolvePromises(apiCalls).then((res) => {
      // handle empty link profile
      const linkProfile = res[2][0] ? res[2][0] : 'No Vc'
      setUserObj({
        'linkpass-profile': res[0],
        'auth0-profile': res[1],
        'link-profile': linkProfile,
      })
    })
  }, [])

  return userObj
}
