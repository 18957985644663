import React from 'react'
import { PDFDownloadLink, _PDFViewer } from '@react-pdf/renderer'
import QRCode from 'qrcode'
import uniqBy from 'lodash/uniqBy'
import flatten from 'lodash/flatten'
import moment from 'moment'
import { Select } from '../../../../components/Form'
import Button from '../../../../components.new/button/Button'
import Dialog from '../../../../components/Popup/Dialog'
import Loader from '../../../../components/Loader'
import API from '../../../../lib/api'
import OrderLabelReport from './order-label-report'
import './style.css'

const PicklistConstants = {
  SKU_CSV_HEADERS: [
    'Seller SKU',
    'SKU',
    'Barcode',
    'Product Name',
    'Weight/Pack Size',
    'Quantity',
    'Priority',
  ],
  ORDER_CSV_HEADERS: [
    'No',
    'Order no',
    'SKU',
    'Seller SKU',
    'Barcode',
    'Product Name',
    'Display Unit',
    'Quantity',
  ],
  TIMESTAMP_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  TIMESTAMP_FORMAT_YYYYMMDDHHMMSS: 'YYYYMMDDHHmmss',
  TIMESTAMP_FORMAT_MMMDDYYYY: 'MMM DD, YYYY',
  TIMESTAMP_FORMAT_HHMM: 'HH:mm',
  STATUS_CANCELLED: 'CANCELLED',
  PICKLIST_COUNT: 7,
  PICKLISTS_ENDPOINT: '/seller-order-service/picklists',
  ORDERS_ENDPOINT: '/seller-order-service/orders',
  QRCODE_LABELS: {
    AMBIENT: 'MKP_A01',
    FROZEN: 'MKP_F01',
    CHILLED: 'MKP_C01',
  },
  PFC_CLIENT_ID: 4,
  PACKING_TYPE: {
    SKU: 'SKU',
    ORDER: 'ORDER',
    ORDER_NO_LABEL: 'ORDER-NO-LABEL',
  },
}

const getBarcodesString = barcodes => {
  return barcodes.replaceAll && barcodes.replaceAll(/[\]["]/g, '')
}

class ValidationError extends React.Component {
  render() {
    return (
      !this.props.value && (
        <div className="input-error-message">This cannot be blank</div>
      )
    )
  }
}

function downloadCSV(data, filename) {
  const universalBOM = '\uFEFF'
  const csv = data.map(row => `${row.join(',')}`).join('\n')
  if (window && window.document) {
    var hiddenElement = document.createElement('a')
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
      universalBOM + csv
    )}`
    hiddenElement.target = '_blank'
    hiddenElement.download = filename
    hiddenElement.click()
  }
}

export default class PicklistDownload extends React.Component {
  constructor() {
    super()

    this.state = {
      store: '',
      cutOff: '',
      showDialog: false,
      isPdfDataReady: false,
    }

    this.handleSellerStoreChange = this.handleSellerStoreChange.bind(this)
    this.handleCutOffDateChange = this.handleCutOffDateChange.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.handleDialogStatus = this.handleDialogStatus.bind(this)
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    this.getPicklists()
  }

  getPicklists() {
    const { vendorCode } = this.props
    const picklistsAPI = new API({
      url: PicklistConstants.PICKLISTS_ENDPOINT,
    })

    const currentTimeStamp = moment().format(PicklistConstants.TIMESTAMP_FORMAT)
    const lastWeekTimeStamp = moment()
      .subtract(PicklistConstants.PICKLIST_COUNT, 'days')
      .format(PicklistConstants.TIMESTAMP_FORMAT)

    picklistsAPI
      .get({
        vendorCode,
        cutoffAt: `${lastWeekTimeStamp}..${currentTimeStamp}`,
      })
      .then((response = {}) => {
        const picklists = (response.data || []).filter(
          picklist => picklist.status !== PicklistConstants.STATUS_CANCELLED
        )
        const stores = picklists.map(picklist => ({
          value: picklist.storeID,
          text: picklist.storeName,
        }))
        const cutOffDates = picklists.map(picklist => ({
          value: picklist.cutoffAt,
          text: moment(picklist.cutoffAt, 'YYYY-MM-DDTHH:mm:ss[Z]').format(
            PicklistConstants.TIMESTAMP_FORMAT
          ),
          storeID: picklist.storeID,
        }))

        this.setState({
          picklists: response.data || [],
          stores: uniqBy(stores, store => store.value),
          cutOffDates: cutOffDates,
        })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleSellerStoreChange(store) {
    this.setState({
      store,
      cutOff: '',
    })
  }

  handleCutOffDateChange(cutOff) {
    this.setState({
      cutOff,
    })
  }

  handleDialogStatus() {
    this.setState({
      showDialog: !this.state.showDialog,
    })
  }

  handleDownload() {
    this.setState({
      isSubmitting: true,
    })
    const { store, cutOff, picklists } = this.state
    if (!store || !cutOff) {
      return
    }

    this.setState({
      loading: true,
    })
    const picklist = picklists.find(
      picklist =>
        Number(picklist.storeID) === Number(store) &&
        picklist.cutoffAt === cutOff
    )

    this.getOrders(store, cutOff, picklist)
  }

  getOrders(store, cutOff, picklist) {
    const { sellerName } = this.props
    const ordersAPI = new API({
      url: PicklistConstants.ORDERS_ENDPOINT,
    })

    ordersAPI
      .get({
        picklistID: picklist.id,
        vendorCode: picklist.vendorCode,
        withOrderItems: true,
      })
      .then((response = {}) => {
        const orders = (response.data || []).filter(
          order => order.status !== PicklistConstants.STATUS_CANCELLED
        )

        if (orders.length === 0) {
          this.handleDialogStatus()

          return
        }

        this.downloadPicklists(orders, sellerName, cutOff, picklist)
      })
      .finally(() => {
        this.setState({
          isSubmitting: false,
          loading: false,
        })
      })
  }

  downloadPicklists(orders, sellerName, cutOff, picklist) {
    const { sellerStores = {} } = this.props
    const { packingType = { others: [] } } = sellerStores[picklist.storeID]
    const orderItemsByQuantity = this.groupByQuantity(orders)
    if (
      packingType.primary === PicklistConstants.PACKING_TYPE.SKU ||
      packingType.others.includes(PicklistConstants.PACKING_TYPE.SKU)
    ) {
      this.downloadSkuCsvReport(
        Object.values(orderItemsByQuantity || {}),
        `${sellerName}_${moment(cutOff, 'YYYY-MM-DDTHH:mm:ss[Z]').format(
          PicklistConstants.TIMESTAMP_FORMAT_YYYYMMDDHHMMSS
        )}_SKU.csv`
      )
    }

    picklist.sellerName = sellerName

    const groupedOrders = orders.map(order =>
      Object.values(this.groupByQuantity([order], picklist))
    )
    if (
      packingType.primary === PicklistConstants.PACKING_TYPE.ORDER_NO_LABEL ||
      packingType.others.includes(PicklistConstants.PACKING_TYPE.ORDER_NO_LABEL)
    ) {
      this.downloadOrderCsvReport(
        flatten(groupedOrders),
        `${sellerName}_${moment(cutOff, 'YYYY-MM-DDTHH:mm:ss[Z]').format(
          PicklistConstants.TIMESTAMP_FORMAT_YYYYMMDDHHMMSS
        )}_ORDER-NO-LABEL.csv`
      )
    }

    if (packingType.primary === PicklistConstants.PACKING_TYPE.ORDER) {
      orders.forEach(order => {
        order.orderItems = Object.values(this.groupByQuantity([order]))
      })
      this.downloadOrderPdfReport(
        orders,
        picklist,
        `${sellerName}_${moment(cutOff, 'YYYY-MM-DDTHH:mm:ss[Z]').format(
          PicklistConstants.TIMESTAMP_FORMAT_YYYYMMDDHHMMSS
        )}_ORDER.pdf`
      )
    }
  }

  groupByQuantity(orders) {
    return orders.reduce((groupedByQuantity, order) => {
      return (order.orderItems || []).reduce((acc, orderItem) => {
        if (acc[orderItem.sku]) {
          acc[orderItem.sku].orderedQuantity =
            acc[orderItem.sku].orderedQuantity + orderItem.orderedQuantity
        } else {
          acc[orderItem.sku] = {
            ...orderItem,
            referenceNumber: order.referenceNumber,
          }
        }

        return acc
      }, groupedByQuantity)
    }, {})
  }

  downloadSkuCsvReport(orderItems, fileName) {
    const rows = orderItems.map(orderItem => [
      orderItem.sellerSku,
      orderItem.sku,
      getBarcodesString(orderItem.barcodes || ''),
      orderItem.productName,
      orderItem.displayUnit,
      orderItem.orderedQuantity,
      '',
    ])

    downloadCSV([PicklistConstants.SKU_CSV_HEADERS, ...rows], fileName)
  }

  downloadOrderCsvReport(orderItems, fileName) {
    const rows = orderItems.map((orderItem, index) => [
      index + 1,
      orderItem.referenceNumber,
      orderItem.sku,
      orderItem.sellerSku,
      getBarcodesString(orderItem.barcodes || ''),
      orderItem.productName,
      orderItem.displayUnit,
      orderItem.orderedQuantity,
    ])

    downloadCSV([PicklistConstants.ORDER_CSV_HEADERS, ...rows], fileName)
  }

  downloadOrderPdfReport(orders, picklist, fileName) {
    this.setState({
      isPdfDataReady: false,
      loading: true,
    })
    const pdfOrders = []
    orders.forEach((order, index) => {
      const qrCodePromises = Object.keys(PicklistConstants.QRCODE_LABELS).map(
        label => {
          return QRCode.toDataURL(
            `${order.referenceNumber},${picklist.vendorCode},${picklist.sellerName},${PicklistConstants.QRCODE_LABELS[label]}`
          ).then(url => {
            order[label] = url
            return url
          })
        }
      )

      const pickupLeadTime =
        picklist.storeID === PicklistConstants.PFC_CLIENT_ID ? 1 : 0 // pickupLeadTime isn't coming from repsonse so make-do

      Promise.all(qrCodePromises).then(() => {
        order = {
          ...order,
          storeName: picklist.storeName,
          vendorCode: picklist.vendorCode,
          sellerName: picklist.sellerName,
          pickupDate: moment(picklist.cutoffAt, 'YYYY-MM-DDTHH:mm:ss[Z]')
            .add(pickupLeadTime * 24, 'hours')
            .format(PicklistConstants.TIMESTAMP_FORMAT_MMMDDYYYY),
          deliveryDate: moment(
            order.deliverFrom,
            'YYYY-MM-DDTHH:mm:ss[Z]'
          ).format(PicklistConstants.TIMESTAMP_FORMAT_MMMDDYYYY),
          deliveryTime:
            moment(order.deliverFrom, 'YYYY-MM-DDTHH:mm:ss[Z]').format(
              PicklistConstants.TIMESTAMP_FORMAT_HHMM
            ) +
            ' - ' +
            moment(order.deliverTill, 'YYYY-MM-DDTHH:mm:ss[Z]').format(
              PicklistConstants.TIMESTAMP_FORMAT_HHMM
            ),
          deliveryHour: moment(
            order.deliverFrom,
            'YYYY-MM-DDTHH:mm:ss[Z]'
          ).format('HH'),
        }

        pdfOrders.push(order)
        if (index === orders.length - 1) {
          this.setState({
            isPdfDataReady: true,
            pdfOrders,
            pdfFileName: fileName,
            loading: false,
          })
        }
      })
    })
  }

  render() {
    const {
      stores = [],
      cutOffDates = [],
      store,
      cutOff,
      isSubmitting,
    } = this.state
    const cutOffDatesForSelectedStore = cutOffDates.filter(
      cutoff => Number(cutoff.storeID) === Number(store)
    )

    return (
      <React.Fragment>
        <div className="dropdown-container">
          <div
            className={[
              'field',
              isSubmitting && !store ? 'input-error' : '',
            ].join(' ')}
          >
            <span className="labelWrap">
              <label htmlFor="seller-stores-dropdown">Stores</label>
            </span>
            <Select
              className="input-error"
              id="seller-stores-dropdown"
              name="seller-stores-dropdown"
              for="seller-stores-dropdown"
              placeholder="Stores"
              options={stores}
              value={this.state.store}
              onChange={this.handleSellerStoreChange}
              required
            />
            {isSubmitting && <ValidationError value={store} />}
          </div>
          <div
            className={[
              'field',
              isSubmitting && !cutOff ? 'input-error' : '',
            ].join(' ')}
          >
            <span className="labelWrap">
              <label htmlFor="cut-off-dates-dropdown">Cutoff</label>
            </span>
            <Select
              id="cut-off-dates-dropdown"
              name="cut-off-dates-dropdown"
              for="cut-off-dates-dropdown"
              placeholder="Dates"
              options={uniqBy(
                cutOffDatesForSelectedStore,
                cutoff => cutoff.value
              )}
              value={this.state.cutOff}
              onChange={this.handleCutOffDateChange}
              disabled={!this.state.store}
              required
            />
            {isSubmitting && <ValidationError value={cutOff} />}
          </div>
        </div>
        <div className="download-container">
          <Button
            type="button"
            style={{ backgroundColor: '#1557bf', color: 'white' }}
            filled
            onClick={this.handleDownload}
          >
            Download
          </Button>
        </div>
        {this.state.isPdfDataReady && (
          <div>
            <PDFDownloadLink
              document={<OrderLabelReport orders={this.state.pdfOrders} />}
              fileName={this.state.pdfFileName}
            >
              {({ _blob, _url, loading, _error }) =>
                loading ? 'Generating pdf ...' : 'Download Order label pdf'
              }
            </PDFDownloadLink>
          </div>
        )}
        {/*
				{this.state.isPdfDataReady && (
					<PDFViewer width="1000" height="2000">
					<OrderLabelReport orders={this.state.pdfOrders} />
					</PDFViewer>
				)}
			*/}
        {this.state.loading && <Loader />}
        <Dialog
          className="info"
          information={'There are no picklists for the selected cutoff.'}
          close={this.handleDialogStatus}
          show={this.state.showDialog}
          closeText="OK"
        />
      </React.Fragment>
    )
  }
}
