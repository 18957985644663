import React, { Component } from 'react'
import VideoCard from '../VideoCard'
import AliceCarousel from 'react-alice-carousel'
import Image from '../Image'
import videoCarouselImage from '../images/video_card_2.svg'

import './style.css'
import 'react-alice-carousel/lib/alice-carousel.css'

class VideoCardCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: props.data.activeIndex || 0,
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      activeSlide: newProps.data.activeIndex,
    })
  }

  render() {
    if (!Object.keys(this.props.data).length) {
      return <Image src={videoCarouselImage} alt="default-video-carousel" />
    }

    return (
      <div className="video-card-carousel">
        <AliceCarousel
          dotsDisabled={true}
          slideToIndex={this.state.activeSlide}
          startIndex={this.state.activeSlide}
          fadeOutAnimation={true}
          mouseDragEnabled={true}
        >
          {this.props.data.videoCards &&
            this.props.data.videoCards.map((videoCard, index) => (
              <VideoCard
                key={`vc-${index}`}
                data={{
                  src: videoCard.src,
                  details: videoCard.details,
                  autoplay: 1,
                  textColorForDescription:
                    this.props.data.textColorForDescription,
                  fontSizeforDescription:
                    this.props.data.fontSizeforDescription,
                }}
              />
            ))}
        </AliceCarousel>
      </div>
    )
  }
}

export default VideoCardCarousel
