const data = {
  'apphome.analytics': 'Analytics',
  'apphome.back': 'Back',
  'apphome.okay': 'Okay',
  'apphome.done': 'Done',
  'apphome.next': 'Next',
  'apphome.cancel': 'Cancel',
  'apphome.cancel.draft': 'Cancel Draft',
  'apphome.edit': 'Edit',
  'apphome.enable': 'Publish',
  'apphome.enabled': 'Published',
  'apphome.disable': 'Unpublish',
  'apphome.disabled': 'Unpublished',
  'apphome.delete': 'Delete',
  'apphome.saveasdraft': 'Save as Draft',
  'apphome.metadata': 'Metadata',
  'apphome.day': 'Day',
  'apphome.day.mon': 'Monday',
  'apphome.day.tue': 'Tuesday',
  'apphome.day.wed': 'Wednesday',
  'apphome.day.thu': 'Thursday',
  'apphome.day.fri': 'Friday',
  'apphome.day.sat': 'Saturday',
  'apphome.day.sun': 'Sunday',
  'apphome.validity': 'Validity',
  'apphome.validFrom': 'Valid From',
  'apphome.validTo': 'Valid To',
  'apphome.startDate': 'Start Date',
  'apphome.endDate': 'End Date',
  'apphome.startTime': 'Start Time',
  'apphome.endTime': 'End Time',
  'apphome.time.placeholder': 'Select time',
  'apphome.expired': 'Expired',
  'apphome.scheduled': 'Scheduled',
  'apphome.live': 'Live',
  'apphome.requiredField': 'This field is required',
  'apphome.missingInfo.1': 'Please note that the following field is empty:',
  'apphome.missingInfo.2': 'Please note that the following fields are empty:',
  'apphome.missingInfo.3':
    'Campaign will automatically be unpublished upon saving.',
  'apphome.missingInfo.name': 'Campaign Name',
  'apphome.missingInfo.deepLink': 'Link URL',
  'apphome.missingInfo.image': 'Image',
  'apphome.missingInfo.priority': 'Priority',
  'apphome.missingInfo.startDate': 'Valid From',
  'apphome.missingInfo.endDate': 'Valid To',
  'apphome.missingInfo.background': 'Background',
  'apphome.missingInfo.logo': 'Logo',
  'apphome.missingInfo.topAnimation': 'Top Animation',
  'apphome.missingInfo.bottomAnimation': 'Bottom Animation',
  'apphome.errorMessage.contact-team':
    'Unable to process request. Please check with Loyalty - Engagement team.',
  'apphome.errorMessage.json-format': 'Incorrect JSON format',
  'apphome.confirmation': 'Are you sure?',
  'apphome.delete.confirmation': 'This action cannot be reverted.',
  'apphome.yes': 'Yes',
  'apphome.segment': 'Segment',
  'apphome.segment.placeholder': 'Enter segment',
  'apphome.filter.searchByName': 'Search by name',
  'apphome.submit': 'Submit',
  'apphome.clear': 'Clear All',

  'appbanner.save': 'Save',
  'appbanner.status': 'Status',
  'appbanner.addBanner': '+ Add Banner',
  'appbanner.title': 'Title',
  'appbanner.title.placeholder': 'Enter title',
  'appbanner.image': 'Image',
  'appbanner.image.placeholder': 'Click here to upload banner (app 328x120)',
  'appbanner.campaign': 'Campaign Name',
  'appbanner.campaign.placeholder': 'Enter campaign name',
  'appbanner.setDuration': 'Set Campaign Validity',
  'appbanner.deepLink': 'Link URL',
  'appbanner.deepLink.placeholder': 'Enter URL',
  'appbanner.priority': 'Priority',
  'appbanner.priority.placeholder': 'Select Priority',
  'appbanner.bannerType': 'Banner Type',
  'appbanner.bannerType.DEEPLINK': 'Deep Link',
  'appbanner.bannerType.DEFAULT': 'Default',
  'appbanner.bannerType.LOGIN': 'Login',
  'appbanner.errorMessage02':
    'Campaign name has been taken. Please set a new name.',
  'appbanner.errorMessage03': 'Please fill in Campaign Name before saving.',

  'app.campaign.name.placeholder': 'Enter Campaign Name',
  'app.campaign.name': 'Campaign Name',
  'app.tile.title.label': 'Title',
  'app.tile.service.label': 'Service',
  'app.tile.deeplink.label': 'Deeplink',
  'app.tile.image.placeholder': 'Click here to upload tile',
  'app.tile.name.placeholder': 'Enter tile name',
  'app.tile.service.placeholder': 'Enter service',
  'app.tile.deeplink.placeholder': 'Enter tile deeplink',
  'app.tile.startDate.label': 'Start Date',
  'app.tile.endDate.label': 'End Date',
  'app.tile1.label': 'Tile 1',
  'app.tile2.label': 'Tile 2',
  'app.tile3.label': 'Tile 3',
  'app.tile4.label': 'Tile 4',
  'app.tile5.label': 'Tile 5',
  'app.tile.missing': 'There should be at least 1 upper tile',
  'app.tile.addTile': '+ Add Tile',

  'app.splash.upload.background': 'Upload Background',
  'app.splash.upload.topAnimation': 'Upload Top Animation',
  'app.splash.upload.logo': 'Upload Logo',
  'app.splash.upload.bottomAnimation': 'Upload Bottom Animation',
  'app.splash.upload.background.placeholder': 'Click here to upload background',
  'app.splash.upload.logo.placeholder': 'Click here to upload logo',
  'app.splash.upload.topAnimation.placeholder':
    'Click here to upload top animation',
  'app.splash.upload.bottomAnimation.placeholder':
    'Click here to upload bottom animation',
  'app.splash.background.button': 'Background',
  'app.splash.logo.button': 'Logo',
  'app.splash.reset.button': 'Reset',
  'app.splash.topAnimation.button': 'Top Animation',
  'app.splash.bottomAnimation.button': 'Bottom Animation',
  'app.splash.uploadAnimation.error':
    'Error: The uploaded animation may have exceed 1080 x 609 dimension.',
  'app.splash.uploadBackground.error':
    'Error: The uploaded animation may have exceed 1080 x 1920 dimension.',

  'app.layout': 'Layout',
  'app.layout.placeholder': 'Select layout',
  'app.layout.header': 'Dynamic Layout',
  'app.layout.image.placeholder': 'Click here to upload content',
  'app.layout.popup.heading.edit-module': 'Edit Module',
  'app.layout.popup.heading.create-module': 'Create Module',
  'app.layout.popup.heading.create-profile': 'Create New Profile',
  'app.layout.popup.heading.module-type': 'Select Module Type',
  'app.layout.enable': 'Enable',
  'app.layout.enabled': 'Enabled',
  'app.layout.disable': 'Disable',
  'app.layout.disabled': 'Disabled',
  'app.layout.create.profile': 'Create Profile',
  'app.layout.tab.profile': 'Profile',
  'app.layout.tab.preview': 'Preview',
  'app.layout.module.id': 'ID',
  'app.layout.module.name': 'Name',
  'app.layout.module.action': 'Actions',
  'app.layout.module.name.placeholder': 'Enter name',
  'app.layout.module.title': 'Title',
  'app.layout.module.title.placeholder': 'Enter title',
  'app.layout.module.text': 'Text',
  'app.layout.module.text.placeholder': 'Enter text',
  'app.layout.module.cta.text.placeholder': 'Enter CTA text',
  'app.layout.module.selectLayout.placeholder': 'Select layout',
  'app.layout.module.profile.placeholder': 'Enter profile name',
  'app.layout.module.status': 'Status',
  'app.layout.module.deeplink': 'Deeplink',
  'app.layout.module.deeplink.placeholder': 'Enter CTA deeplink',
  'app.layout.module.datasource.banner': 'Banner',
  'app.layout.module.datasource.personalization_banner': 'Personalized Banner',
  'app.layout.module.datasource.pastpurchase': 'Past Purchase',
  'app.layout.module.datasource.r4u': 'Recommender Service',
  'app.layout.module.datasource.config.button.add': 'Add Params',
  'app.layout.module.datasource.config.key.input.label': 'Key',
  'app.layout.module.datasource.config.key.input.placeholder': 'Enter key',
  'app.layout.module.datasource.config.value.input.label': 'Value',
  'app.layout.module.datasource.config.value.input.placeholder': 'Enter value',
  'app.layout.module.datasource.webservice': 'Website Service',
  'app.layout.module.contentConfig': 'Content Config',
  'app.layout.module.contentConfig.placeholder': 'Select datasource',
  'app.layout.module.type.productSwimlane': 'Product Swimlane',
  'app.layout.module.type.cardSwimlane': 'Card Swimlane',
  'app.layout.module.type.entryGroup': 'Entry Group',
  'app.layout.module.type.placeholder': 'Placeholder',
  'app.layout.module.type.banner': 'Banner',
  'app.layout.module.add': 'Add Module',
  'app.layout.missingField.errorMsg.cta-deeplink':
    'This field is required if CTA text is provided.',
  'app.layout.missingField.errorMsg.cta-text':
    'This field is required if CTA deeplink is provided.',

  'app.card.type.swa': 'SWA',
  'app.card.type.contenthub': 'Content Hub',
  'app.card.title': 'Enter card title',
  'app.card.create': 'Create Card',
  'app.card.preview': 'Preview card',
  'app.card.preview.all': 'Preview Cards',
  'app.card.image.placeholder': 'Click here to upload card image',
  'app.card.id': 'ID',
  'app.card.name': 'Name',
  'app.card.deeplink': 'Deeplink',
  'app.card.category': 'Category',
  'app.card.category.placeholder': 'Enter category',
  'app.card.category.validation': 'Category cannot contain any spaces',
  'app.card.filter.category': 'Filter by category',
  'app.card.filter.category.placeholder': 'Select category',
  'app.card.content': 'Content',
  'app.card.name.placeholder': 'Enter name',
  'app.card.deeplink.placeholder': 'Enter deeplink',
  'app.card.change.position': 'Drag and drop cards to change its position',
  'app.card.empty': 'No cards available',

  'app.popup.add': '+ Add Popup',
  'app.popup.ctd.button': 'Close Button',
  'app.popup.cta.button': 'CTA Button',
  'app.popup.cta.text': 'Text',
  'app.popup.cta.text.placeholder': 'Enter button text',
  'app.popup.cta.deeplink': 'Deeplink',
  'app.popup.cta.deeplink.placeholder': 'Enter deeplink',
  'app.popup.dailySchedule': 'Daily Schedule',
  'app.popup.frequency': 'Frequency per day',
  'app.popup.frequency.placeholder': 'Enter frequency of popup per day',
  'app.popup.title': 'Title',
  'app.popup.title.placeholder': 'Enter popup title',
  'app.popup.description': 'Description',
  'app.popup.description.placeholder': 'Enter popup description',
  'app.popup.image': 'Image',
  'app.popup.image.placeholder': 'Click here to upload image',
  'app.popup.interval': 'Interval',
  'app.popup.interval.placeholder': 'Enter interval',
}

export default data
