import React from 'react'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import MediaPagination from '../../commonMedia/pagination'
import { getMessage } from '../../../../lib/translator'
import Button from 'components.new/button/Button'
import { Link } from 'react-router-dom'

export default function CreativeTable (props) {

  const { creativeList, paging, isMobileView, changePage, _this } = props

  return (
    <div>
      <Table>
        {!isMobileView && (
          <Header>
            <Cell>{getMessage('media.creative.add.emptyheader')}</Cell>
            <Cell>{getMessage('media.creativeManagement.header.name')}</Cell>
            <Cell>{getMessage('media.creative.add.package')}</Cell>
          </Header>
        )}
        {creativeList.map((creative, creativeIndex) => (
          <Row key={creativeIndex}>
          <Cell>
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.creative.add.emptyheader')}
              </div>
            )}

            <Button
              data-testid = {'button_' + creativeIndex}
              id = {'button_' + creativeIndex}
              name = {'button_' + creativeIndex}
              onClick = {() => {
                _this.toggleCheckbox(creative)
              }}
              className= {_this.state.optionSelected === creative.id ? "button-checked" : "button-unchecked"}
            />
          </Cell>
          <Cell>
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.creativeManagement.header.name')}
              </div>
            )}
            <Link
              to={`/media-monetization/media-creativemanagement/media-creative-group-list/${creative.id}`}
            >
              {creative.creative_name}
            </Link>
          </Cell>
          <Cell>
            {isMobileView && (
              <div className="text-muted">
                {getMessage('media.creative.add.package')}
              </div>
            )}
            <small>{creative?.media_package?.package_name}</small>
          </Cell>
        </Row>
        ))}
      </Table>

      <MediaPagination paging={paging} changePage={changePage}></MediaPagination>
    </div>
  )
}
