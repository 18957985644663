import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ListingPage from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import Image from '../../../components/Image'
import { IS_STAGING } from '../../../config/app'
import BrandFilters from './Filters'
import emptyIcon from '../../catalogue/Brands/brands-empty.svg'
import downloadIcon from '../../catalogue/Products/download.svg'
class HeaderActions extends Component {
  render() {
    return (
      <div className="flex">
        <a
          className="download-button"
          onClick={() => {
            const fields = [
              'date_from',
              'date_to',
              'image_url',
              'slug',
              'title',
              'description',
              'isin',
              'label',
              'id',
            ]
            const replacer = function (key, value) {
              return value === null ? '' : value
            }
            const csv = [
              fields.join(','), // header row first
              ...this.props.data.items.map((row) =>
                fields
                  .map((fieldName) => {
                    let info = ''
                    if (
                      fieldName === 'slug' ||
                      fieldName === 'description' ||
                      fieldName === 'title'
                    ) {
                      info = row['recipe'][fieldName]
                    } else if (fieldName === 'image_url') {
                      info = row['recipe']['image']['url']
                    } else {
                      info = row[fieldName]
                    }
                    return JSON.stringify(info, replacer)
                  })
                  .join(',')
              ),
            ].join('\r\n')
            const universalBOM = '\uFEFF'
            var hiddenElement = document.createElement('a')
            hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
              universalBOM + csv
            )}`
            hiddenElement.target = '_blank'
            hiddenElement.download = `feature_recipes.csv`
            hiddenElement.click()
          }}
        >
          <img src={downloadIcon} />
        </a>
      </div>
    )
  }
}

const emptyState = {
  icon: emptyIcon,
  message: 'There are no schedules',
  actions: () => (
    <Link to="/recipe/batch-upload">
      <button className="primary button">Schedule Featured Recipes</button>
    </Link>
  ),
}

const tableProperties = () => {
  const url = IS_STAGING
    ? 'https://zs-uat.fairprice.com.sg'
    : 'https://www.fairprice.com.sg'
  return {
    headers: [
      'Date From',
      'Date to',
      'Image',
      'Recipe Title',
      'Recipe Description',
      'ISIN',
      'Label',
      'Id',
    ],
    row: ({ id, isin, label, recipe, date_from, date_to }) => (
      <Row>
        <Cell className="product-count">
          <small>{moment.utc(date_from).format('MMMM Do YYYY')}</small>
        </Cell>
        <Cell className="product-count">
          <small>{moment.utc(date_to).format('MMMM Do YYYY')}</small>
        </Cell>
        <Cell>
          <Image size="sm" src={recipe.image.url} bordered />
        </Cell>
        <Cell className="product-count">
          <a
            href={`${url}/recipes/detail/${recipe.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            <small>{recipe.title}</small>
          </a>
        </Cell>
        <Cell className="product-count">
          <small>{recipe.description}</small>
        </Cell>
        <Cell className="product-count">
          <small>{isin}</small>
        </Cell>
        <Cell className="product-count">
          <small>{label}</small>
        </Cell>
        <Cell className="product-count">
          <small>{id}</small>
        </Cell>
      </Row>
    ),
  }
}

export default class FeaturedRecipes extends Component {
  render() {
    const props = this.props
    return (
      <ListingPage
        menu={props.menu}
        className="featured-recipes-page"
        title="Featured Recipes"
        api={{
          url: '/recipe-service/recipe/schedule',
          transform: (response) => response.data.schedules,
        }}
        headerActions={HeaderActions}
        emptyState={emptyState}
        tableProperties={tableProperties()}
        filters={{
          component: BrandFilters,
        }}
      />
    )
  }
}
