import React, { Component } from 'react'
import API from '../../../../lib/api'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage/index'
import { getMessage } from '../../../../lib/translator'
import { Popup, Dialog } from '../../../../components/Popup'
import {
  BaseForm,
  Input,
  StoreSearch,
  Select,
  Radio,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import Table, { Header, Cell, Row } from '../../../../components/Table'
import {
  getAssetTypes,
  addAssetType,
  formatAssetOptions,
} from '../AssetTypeList'
import AssetTimeForm, {
  DateMap,
  checkOperatingCalendar,
  initOperatingCalendarList,
} from './assetOperationHour'
import AssetTypeForm from './AssetTypeForm'
import {
  BUList,
  offsiteTypeList,
} from '../../MediaAssetManagement/businessUnitList'
import MediaCreativeReqList from '../AssetCreativeReq'
import { formatTime } from '../../../../lib/datetime'
import { throwError, hideErrorDialog } from '../../commonMedia'
import './style.css'
import editIcon from './edit-icon.svg'

const _stryes = 'media.assetDetail.yes'
const _strno = 'media.assetDetail.no'
const APIurl = '/martech-ad-service/assets/'

const CreativeReqSummary = (props) => {
  const data = props.data
  return (
    <Table className="creative-req-summary">
      <Header>
        <Cell>{getMessage('media.creativeReqManagement.header.id')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.name')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.type')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.height')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.width')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.size')}</Cell>
        <Cell>{getMessage('media.creativeReqManagement.header.format')}</Cell>
      </Header>
      <Row>
        <Cell>
          <small>{data?.id || '-'}</small>
        </Cell>
        <Cell>
          <small>{data?.name || '-'}</small>
        </Cell>
        <Cell>
          <small>{data?.required_creative_type || '-'}</small>
        </Cell>
        <Cell>
          <small>{data?.height || '-'}</small>
        </Cell>
        <Cell>
          <small>{data?.width || '-'}</small>
        </Cell>
        <Cell>
          <small>{data?.size || '-'}</small>
        </Cell>
        <Cell>
          <small>{data?.supported_formats?.join(' / ') || '-'}</small>
        </Cell>
      </Row>
    </Table>
  )
}
const CreativeReqBlock = (props) => {
  const toggleCreativeReqBox = props.toggleCreativeReqBox
  const data = props.data
  return (
    <div>
      <div className="section-title-wrap creative-requirement">
        <h3 className="section-title">
          {getMessage('media.creativeReqManagement.name')}
        </h3>
        <button
          type="button"
          className="action"
          onClick={() => {
            toggleCreativeReqBox(1)
          }}
        >
          <img src={editIcon} alt="edit-icon" />
        </button>
      </div>
      <div className="section-wrap">
        <CreativeReqSummary data={data}></CreativeReqSummary>
      </div>
    </div>
  )
}
const OperatingHourBlock = (props) => {
  const toggleOperatinBox = props.toggleOperatinBox
  const assetOperatingCalendarList = props.assetOperatingCalendarList
  return (
    <div>
      <div className="section-title-wrap operating-hour">
        <h3 className="section-title">
          {getMessage('media.assetDetail.operatingHour.h3')}
        </h3>
        <button
          type="button"
          className="action"
          onClick={() => {
            toggleOperatinBox(1)
          }}
        >
          <img src={editIcon} alt="edit-icon" />
        </button>
      </div>
      <div className="section-wrap operationHour-wrap">
        <div className="operationHour-slot-wrap">
          {assetOperatingCalendarList &&
            assetOperatingCalendarList.map((timeItem, timeIndex) => (
              <div className="operationHour-slot" key={timeIndex}>
                <div className="slot-1">{timeItem.eventDay}</div>
                <div
                  className={`slot-2 ${timeItem.status === 'INACTIVE' ? 'inactive' : ''}`}
                >
                  {timeItem.status}
                </div>
                <div className="slot-3">
                  {timeItem.status === 'ACTIVE' ? (
                    <span>
                      {formatTime(timeItem.startTime)}~
                      {formatTime(timeItem.endTime)}
                    </span>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

class MediaAssetForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      ownerBu: '',
      isOpenAssetTypeBox: false,
      isOffiste: false,
    }
    this.handleOwnerBuChange = this.handleOwnerBuChange.bind(this)
    this.toggleAssetTypeBox = this.toggleAssetTypeBox.bind(this)
    this.addNewAssetType = this.addNewAssetType.bind(this)
    this.handleAssetTypeChange = this.handleAssetTypeChange.bind(this)
  }

  handleOwnerBuChange(ownerBu) {
    this.setState({
      ownerBu,
      isOffiste: false,
    })
  }

  toggleAssetTypeBox(status) {
    this.setState({
      isOpenAssetTypeBox: status === 1,
    })
  }

  addNewAssetType(data) {
    addAssetType(this, data.assetType_name, data.assetType_bu)
  }

  handleAssetTypeChange(value) {
    this.setState({
      isOffiste: value.includes('Offsite'),
    })
  }

  componentDidMount() {
    getAssetTypes(this, true)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.values = newProps.value
      if (newProps.value.ownerBu) {
        newState.ownerBu = String(newProps.value.ownerBu)
      }
      return newState
    })
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const {
      assetOperatingCalendarList,
      editOperationTime,
      toggleOperatinBox,
      isOpenOperationBox,
      toggleCreativeReqBox,
      creativeReqListDialog,
      selectCreativeReq,
    } = this.props

    return (
      <div className="website-page-edit">
        <Form className="website-page-form">
          <div className="section-wrap">
            <h3 className="section-title">
              {getMessage('media.adsetdetail.info.h3')}
            </h3>
            <div className="section-row">
              <Input
                className="section-row-input"
                label={getMessage('media.assetDetail.name')}
                placeholder={getMessage('media.assetDetail.name.placeholder')}
                name="asset_name"
                type="text"
                required
                {...this.generateStateMappers({
                  stateKeys: ['assetName'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    'media.assetDetail.name.requiredMessage'
                  ),
                }}
              />
            </div>
            <div className="section-row">
              <Select
                className="section-row-input"
                label={getMessage('media.assetDetail.ownerBu')}
                placeholder={getMessage(
                  'media.assetDetail.ownerBu.placeholder'
                )}
                options={BUList}
                name="ownerBu"
                {...this.generateStateMappers({
                  stateKeys: ['ownerBu'],
                  loseEmphasisOnFill: true,
                  onChange: (value) => {
                    this.handleOwnerBuChange(value)
                  },
                })}
                required
                validationStrings={{
                  valueMissing: getMessage(
                    'media.assetDetail.ownerBu.requiredMessage'
                  ),
                }}
              />
              {this.state.ownerBu && (
                <div>
                  <Select
                    className="section-row-input"
                    label={getMessage('media.storeList.filters.assettype')}
                    placeholder={getMessage(
                      'media.storeList.filters.assettype.placeholder'
                    )}
                    name="assetTypeName"
                    required
                    options={formatAssetOptions(
                      this.state.assetTypeList,
                      this.state.ownerBu
                    )}
                    {...this.generateStateMappers({
                      stateKeys: ['assetTypeName'],
                      loseEmphasisOnFill: true,
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                      onChange: (value) => {
                        this.handleAssetTypeChange(value)
                      },
                    })}
                    validationStrings={{
                      valueMissing: getMessage(
                        'media.assetDetail.assettype.requiredMessage'
                      ),
                    }}
                  />
                  <div
                    className="addAssetTypeBtn"
                    onClick={() => {
                      this.toggleAssetTypeBox(1)
                    }}
                  >
                    {getMessage('media.assetDetail.assettype.btn')}
                  </div>
                </div>
              )}
              <Select
                className="section-row-input"
                label={getMessage('media.assetDetail.crmAssetFlag')}
                placeholder={getMessage(
                  'media.assetDetail.crmAssetFlag.placeholder'
                )}
                options={offsiteTypeList}
                name="crmAssetFlag"
                {...this.generateStateMappers({
                  stateKeys: ['crmAssetFlag'],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
            {!this.state.isOffiste && (
              <React.Fragment>
                <div className="section-row">
                  <Input
                    className="section-row-input"
                    label={getMessage('media.assetDetail.quantity')}
                    placeholder={getMessage(
                      'media.assetDetail.quantity.placeholder'
                    )}
                    name="quantity"
                    type="number"
                    min={1}
                    {...this.generateStateMappers({
                      stateKeys: ['quantity'],
                      loseEmphasisOnFill: true,
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                    validationStrings={{
                      valueMissing: getMessage(
                        'media.assetDetail.quantity.requiredMessage'
                      ),
                    }}
                    tooltip={
                      <div>
                        {getMessage('media.assetDetail.quantity.tooltip')}
                      </div>
                    }
                  />
                  <StoreSearch
                    className="section-row-input"
                    label={getMessage('media.storeList.filters.name')}
                    placeholder={getMessage(
                      'media.storeList.filters.store.placeholder'
                    )}
                    name="storeLabel"
                    {...this.generateStateMappers({
                      stateKeys: ['storeLabel'],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                    validationStrings={{
                      valueMissing: getMessage(
                        'media.storeList.filters.store.requiredMessage'
                      ),
                    }}
                  />
                </div>
                <div className="section-row">
                  <Input
                    className="section-row-input"
                    label={getMessage('media.assetDetail.slotDuration')}
                    placeholder={getMessage(
                      'media.assetDetail.slotDuration.placeholder'
                    )}
                    name="slotDuration"
                    type="number"
                    min={1}
                    {...this.generateStateMappers({
                      stateKeys: ['slotDuration'],
                      loseEmphasisOnFill: true,
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                    validationStrings={{
                      valueMissing: getMessage(
                        'media.assetDetail.slotDuration.requiredMessage'
                      ),
                    }}
                  />
                  <Input
                    className="section-row-input"
                    label={getMessage('media.assetDetail.maxSlots')}
                    placeholder={getMessage('media.assetDetail.maxSlots')}
                    name="slotPerSpot"
                    type="number"
                    min={1}
                    {...this.generateStateMappers({
                      stateKeys: ['numberOfSlotsPerPlaylist'],
                      loseEmphasisOnFill: true,
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                    })}
                    validationStrings={{
                      valueMissing: getMessage(
                        'media.assetDetail.maxSlots.requiredMessage'
                      ),
                    }}
                    tooltip={
                      <div>
                        {getMessage('media.assetDetail.maxSlots.tooltip')}
                      </div>
                    }
                  />
                </div>
              </React.Fragment>
            )}

            <h3
              className={`section-title ${this.state.ownerBu === 'RB' && !this.state.isOffiste ? 'show' : 'hide'}`}
            >
              {getMessage('media.assetDetail.rb.h3')}
            </h3>
            {this.state.ownerBu === 'RB' && !this.state.isOffiste && (
              <div className="section-wrap">
                <div className="section-row">
                  <Radio
                    className="section-row-input"
                    label={getMessage('media.assetDetail.rb.PWP')}
                    name="RB_isPWP"
                    options={[
                      {
                        text: getMessage(_stryes),
                        value: 'Y',
                      },
                      {
                        text: getMessage(_strno),
                        value: 'N',
                      },
                    ]}
                    {...this.generateStateMappers({
                      stateKeys: ['RB_isPWP'],
                      loseEmphasisOnFill: true,
                    })}
                  />
                  <Radio
                    className="section-row-input"
                    label={getMessage('media.assetDetail.rb.MB')}
                    name="RB_isMB"
                    options={[
                      {
                        text: getMessage(_stryes),
                        value: 'Y',
                      },
                      {
                        text: getMessage(_strno),
                        value: 'N',
                      },
                    ]}
                    {...this.generateStateMappers({
                      stateKeys: ['RB_isMB'],
                      loseEmphasisOnFill: true,
                    })}
                  />
                </div>
                <div className="section-row">
                  <Radio
                    className="section-row-input"
                    label={getMessage('media.assetDetail.rb.LF')}
                    name="RB_isLF"
                    options={[
                      {
                        text: getMessage(_stryes),
                        value: 'Y',
                      },
                      {
                        text: getMessage(_strno),
                        value: 'N',
                      },
                    ]}
                    {...this.generateStateMappers({
                      stateKeys: ['RB_isLF'],
                      loseEmphasisOnFill: true,
                    })}
                  />
                </div>
              </div>
            )}
          </div>

          <CreativeReqBlock
            toggleCreativeReqBox={toggleCreativeReqBox}
            data={this.props.creativeReqListDialog?.data}
          ></CreativeReqBlock>

          {!this.state.isOffiste && (
            <OperatingHourBlock
              toggleOperatinBox={toggleOperatinBox}
              assetOperatingCalendarList={assetOperatingCalendarList}
            ></OperatingHourBlock>
          )}

          <div className="form-buttons">
            <SubmitButton>
              {getMessage('websitePage.form.submitText')}
            </SubmitButton>
            <CancelButton>
              {getMessage('websitePage.form.cancelText')}
            </CancelButton>
          </div>
        </Form>

        <Popup
          show={isOpenOperationBox}
          className="operationHour-box"
          heading={getMessage('media.assetDetail.operatingHour.popupTitle')}
          close={() => {
            toggleOperatinBox(2)
          }}
        >
          <AssetTimeForm
            assetOperatingCalendarList={assetOperatingCalendarList}
            onSubmit={editOperationTime}
            onCancel={() => {
              toggleOperatinBox(2)
            }}
          />
        </Popup>

        <Popup
          show={this.state.isOpenAssetTypeBox}
          className="assettype-box"
          heading={getMessage('media.assetDetail.assettype.popupTitle')}
          close={() => {
            this.toggleAssetTypeBox(2)
          }}
        >
          <AssetTypeForm
            onSubmit={this.addNewAssetType}
            onCancel={() => {
              this.toggleAssetTypeBox(2)
            }}
          />
        </Popup>

        <Popup
          show={creativeReqListDialog?.shown}
          className="creativeReqList-box"
          heading={getMessage('media.creativeReqManagement.title')}
          close={() => {
            toggleCreativeReqBox(2)
          }}
        >
          <MediaCreativeReqList
            selectCreativeReq={selectCreativeReq}
            creativeReqListDialog={creativeReqListDialog}
          ></MediaCreativeReqList>
        </Popup>
      </div>
    )
  }
}

export default class MediaAssetDetail extends Component {
  constructor(props) {
    super(props)

    this.api = null
    this.state = {
      pageAction: 'add',
      data: {},
      isOpenOperationBox: false,
      assetOperatingCalendarList: [],
      creativeReqListDialog: {
        shown: false,
        id: null,
        data: null,
      },
      errorDialog: {
        shown: false,
        title: '',
        message: '',
      },
    }
    this.getAssetDetail = this.getAssetDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleOperatinBox = this.toggleOperatinBox.bind(this)
    this.editOperationTime = this.editOperationTime.bind(this)
    this.toggleCreativeReqBox = this.toggleCreativeReqBox.bind(this)
    this.selectCreativeReq = this.selectCreativeReq.bind(this)
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({
        pageAction: 'edit',
      })
      this.getAssetDetail()
    } else {
      this.setState({
        data: {
          RB_isPWP: 'N',
          RB_isMB: 'N',
          RB_isLF: 'N',
          ...this.state.data,
        },
      })
      initOperatingCalendarList(this)
    }
  }

  getAssetDetail() {
    this.api = new API({ url: APIurl + this.props.match.params.id })
    this.api
      .get()
      .then((response) => {
        const data = {
          RB_isPWP: 'N',
          RB_isMB: 'N',
          RB_isLF: 'N',
          storeLabel: {
            storeLabel: response.data.buStoreLabel,
            name: response.data.buStoreLabel,
          },
          ...response.data,
        }
        this.setState({
          data,
          assetOperatingCalendarList: response.data.assetOperatingCalendarList,
        })

        if (response.data.creativeRequirementId) {
          const crID = response.data.creativeRequirementId
          this.api = new API({
            url: '/martech-creative-service/creative-requirement/' + crID,
          })
          this.api.get().then((_response) => {
            this.setState({
              creativeReqListDialog: {
                shown: false,
                id: crID,
                data: _response.data,
              },
            })
          })
        }
      })
      .catch((error) => {
        throwError(this, error)
      })
  }

  // Operating time start
  toggleOperatinBox(status) {
    this.setState({
      isOpenOperationBox: status === 1,
    })
  }
  editOperationTime(formData) {
    const _assetOperatingCalendarList = [
      ...this.state.assetOperatingCalendarList,
    ]
    _assetOperatingCalendarList.forEach((item) => {
      item['rank'] = DateMap[item.eventDay].rank
      if (formData.operatingList.includes(item.eventDay)) {
        item['status'] = formData.status
        item['startTime'] = formData.startTime
        item['endTime'] = formData.endTime
      }
    })

    function compare(property) {
      return function (a, b) {
        var value1 = a[property]
        var value2 = b[property]
        return value1 - value2
      }
    }
    _assetOperatingCalendarList.sort(compare('rank'))
    this.setState({
      isOpenOperationBox: false,
      assetOperatingCalendarList: _assetOperatingCalendarList,
    })
  }
  // Operating time end

  // creative requirement start
  toggleCreativeReqBox(status) {
    this.setState({
      creativeReqListDialog: {
        ...this.state.creativeReqListDialog,
        shown: status === 1,
      },
    })
  }
  selectCreativeReq(creativeReqItem) {
    const id = creativeReqItem?.id
    this.setState({
      creativeReqListDialog: {
        ...this.state.creativeReqListDialog,
        id: id === this.state.creativeReqListDialog.id ? null : id,
        data:
          id === this.state.creativeReqListDialog.id ? null : creativeReqItem,
      },
    })
  }

  handleSubmit(formData) {
    formData.buStoreLabel = formData.storeLabel
      ? formData.storeLabel.storeLabel
      : formData.ownerBu + '-DEFAULT'
    formData.assetOperatingCalendarList = checkOperatingCalendar(
      this.state.assetOperatingCalendarList
    )
    formData.creativeRequirementId = this.state.creativeReqListDialog?.id

    if (this.state.pageAction === 'edit') {
      this.api = new API({ url: APIurl + this.props.match.params.id })
      this.api
        .put(formData)
        .then(() => {
          this.props.history.goBack()
        })
        .catch((error) => {
          throwError(this, error)
        })
    } else {
      this.api = new API({ url: APIurl })
      this.api
        .post(formData)
        .then(() => {
          this.props.history.goBack()
        })
        .catch((error) => {
          throwError(this, error)
        })
    }
  }

  render() {
    const { menu } = this.props

    return (
      <AuthenticatedPage
        menu={menu}
        showLanguageDropDown
        className="asset-detail-page"
        title={getMessage('media.assetDetail.title.' + this.state.pageAction)}
      >
        <h1 className="title">
          {getMessage('media.assetDetail.title.' + this.state.pageAction)}
        </h1>
        <MediaAssetForm
          value={this.state.data}
          isOpenOperationBox={this.state.isOpenOperationBox}
          toggleOperatinBox={this.toggleOperatinBox}
          assetOperatingCalendarList={this.state.assetOperatingCalendarList}
          editOperationTime={this.editOperationTime}
          creativeReqListDialog={this.state.creativeReqListDialog}
          toggleCreativeReqBox={this.toggleCreativeReqBox}
          selectCreativeReq={this.selectCreativeReq}
          onSubmit={this.handleSubmit}
          onCancel={() => {
            this.props.history.goBack()
          }}
        />
        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={this.state.errorDialog.message}
            close={() => hideErrorDialog(this)}
            closeText={getMessage('dialog.okText')}
          />
        )}
      </AuthenticatedPage>
    )
  }
}
