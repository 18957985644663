import React from 'react'
import Table, { Row, Cell } from '../../../../../../components/Table'
import { getMessage } from '../../../../../../lib/translator'

const CardSurcharge = ({ cardSurcharge }) => {
  return (
    <Table className="card-surcharge-table table-dynamic">
      <Row
        className={`card-surcharge ${cardSurcharge.formattedPayable ===
          '$0.00' && 'line-through'}`}
        key={`qc-card-surcharge-row`}
      >
        <Cell>
          <div>{getMessage('qc.orderSummary.CardSurcharge')}</div>
        </Cell>
        <Cell>
          <div>{cardSurcharge.formattedPrice}</div>
        </Cell>
      </Row>
    </Table>
  )
}

export default CardSurcharge
