import React from 'react';
import { Input } from 'components/Form';
import './style.scss'

export function ZoneNameInputStep({ setZonePayload, zonePayload }) {
  return (
    <div className='rule-name-input-step'>
      <Input
        type="text"
        placeholder="e.g.East Coast"
        name="zoneName"
        label="Enter a name for the zone"
        value={zonePayload.name}
        onChange={(value) => {
          setZonePayload(payload => ({
            ...payload,
            name: value
          }))
        }}
      />
    </div>
  )
}
