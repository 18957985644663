import React, { Component } from 'react'
import moment from 'moment'
import Table, { Header, Row, Cell } from '../../../../components/Table'
import { getMessage } from '../../../../lib/translator'
import {
  Input,
  DateRangePicker
} from '../../../../components/Form'
import { DateMultiSelect } from '../../commonMedia/DateMultiSelect'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { SplitContext } from 'containers/SplitContext'
import './style.css'


const AssetsBoxHeader = () => {
  const splitConfig = React.useContext(SplitContext)
  const isShowTip = splitConfig?.splits?.[SPLIT_FEATURES.MEDIA_LINEITEM_MULTIDATES_MESSAGE]?.treatment === 'on'

  return (
    <Header>
      <Cell>{getMessage('media.adsetdetail.flexible.asset.rowheaders.name')}</Cell>
      <Cell className="time">
        {getMessage('media.adsetdetail.flexible.asset.rowheaders.time')}
        {isShowTip && (
          <div>
            <i className='tip'>{getMessage('media.adsetdetail.flexible.defaulttime.tipmessage')}</i>
          </div>
        )}
      </Cell>
    </Header>
  )
}

export default class AssetsBox extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { assetReservationList, generateStateMappers } = this.props
    return (
      <div className='assetsBox'>
        <div className="title">{getMessage('media.adsetdetail.flexible.asset.h3')}</div>
        { assetReservationList?.length > 5 &&
          <div className='assetsBox_filter section-row'>
            <Input
              label={getMessage('media.assetManagement.filters.name')}
              placeholder={getMessage('media.assetManagement.filters.name.placeholder')}
              name="filterAssetName"
              className="section-row-input"
              type="text"
              {...generateStateMappers({
                stateKeys: ['filterAssetName'],
              })}
            />
          </div>
        }
        <div className={`assetsBox_list ${assetReservationList?.length > 5 ? 'scroll' : ''}`}>
          <Table>
            <AssetsBoxHeader></AssetsBoxHeader>
            {assetReservationList.map((assetItem, assetIndex) => (
              <Row
                key={assetIndex}
                className={!(!this.props.values?.filterAssetName || assetItem.assetName.toLowerCase().includes(this.props.values?.filterAssetName.toLowerCase())) && 'hide'}
              >
                <Cell className="name">
                  <small>{assetItem.assetName}</small>
                </Cell>
                <Cell>
                  <div className={`multi-dateRangePicker-wrap special_duration_${assetIndex}`}>
                    <div className='multi-dateRangePicker'>
                      <DateRangePicker
                        className='section-row-input'
                        {...generateStateMappers({
                          stateKeys: ['special_duration', assetIndex],
                          onChange: ({ startDate, endDate }) => {
                            this.props.flexibleTimeAdd(startDate, endDate, 'special', assetIndex)
                          }
                        })}
                        displayFormat="YYYY-MM-DD"
                        isOutsideRange={date => {
                          return assetItem.operatingHour ? !assetItem.operatingHour.includes(moment(date).day()) : false
                        }}
                        startDatePlaceholder={getMessage(
                          'media.campaigndetail.campaign.time.startDate.placeholder'
                        )}
                        endDatePlaceholder={getMessage(
                          'media.campaigndetail.campaign.time.endDate.placeholder'
                        )}
                        minimumNights={0}
                      />
                    </div>
                    <DateMultiSelect
                      timeList={this.props.assetReservationList[assetIndex]?.reserveDates}
                      handleSelect={(index)=> {this.props.flexibleTimeDelete('special', index, assetIndex)}}
                    ></DateMultiSelect>
                  </div>
                </Cell>
              </Row>
            ))}
          </Table>
        </div>
      </div>
    )
  }
}
