import React, { Fragment } from 'react'
import { SingleDatePicker, TimePicker, Textarea } from '../../../Form'
import AddDraw from './AddDraw'
import ImageDetail from './ImageDetail'
import ManualDraw from './ManualDraw'
import DrawTerms from './DrawTerms'
import { getMessage } from '../../../../lib/translator'
import Loader from '../../../Loader'
import DisplayCreatorEditorInfo from 'components/Rewards/DisplayCreatorEditorInfo'

const Details = (props) => {
  const { _this, method, loading } = props
  return !loading ? (
    <Fragment>
      <div className="single-column-field fiftypercent-field normal-height-field">
        <Textarea
          type="text"
          required
          name="campaign-name"
          key="campaign-name"
          testid="campaign-name"
          label={getMessage('luckydraw.campaignName.label')}
          placeholder={getMessage('luckydraw.campaignName.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['campaignName'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
      <div className="grid mobile-full-width" key="start-date-div">
        <SingleDatePicker
          enableToday
          name="start-date"
          key="start-date"
          required
          label={getMessage('campaign.startDate')}
          {..._this.generateStateMappers({
            stateKeys: ['startDate'],
            loseEmphasisOnFill: true,
          })}
          disabled={_this.state.campaignStarted}
        />
        <TimePicker
          name="start-time"
          key="start-time"
          label={getMessage('campaign.startTime')}
          required
          placeholder={getMessage('lucky-draws.time.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['startTime'],
            loseEmphasisOnFill: true,
          })}
          disabled={_this.state.campaignStarted}
        />
      </div>
      <div className="grid mobile-full-width" key="end-date-div">
        <SingleDatePicker
          enableToday
          name="end-date"
          key="end-date"
          required
          label={getMessage('campaign.endDate')}
          {..._this.generateStateMappers({
            stateKeys: ['endDate'],
            loseEmphasisOnFill: true,
          })}
          disabled={_this.state.campaignStarted}
        />
        <TimePicker
          name="end-time"
          key="end-time"
          label={getMessage('campaign.endTime')}
          required
          placeholder={getMessage('lucky-draws.time.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['endTime'],
            loseEmphasisOnFill: true,
          })}
          disabled={_this.state.campaignStarted}
        />
      </div>
      <div className="single-column-field">
        <div className="header-1-lowercase">
          {getMessage('luckydraw.date.header')}
        </div>
        <div className="normal-text">
          {getMessage('luckydraw.date.note1')}
          <br />
          {getMessage('luckydraw.date.note2')}
        </div>
      </div>
      <ManualDraw _this={_this} method={method} />
      <AddDraw _this={_this} method={method} />
      <div className="campaign-details-1">
        <Textarea
          name="prizes"
          key="prizes"
          testid="prizes"
          required
          label={getMessage('luckydraw.prizes.label')}
          placeholder={getMessage('luckydraw.prizes.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['prizes'],
            loseEmphasisOnFill: true,
          })}
        />
        <Textarea
          name="participate"
          key="participate"
          testid="participate"
          required
          label={getMessage('luckydraw.participation.label')}
          placeholder={getMessage('luckydraw.participation.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['participate'],
            loseEmphasisOnFill: true,
          })}
        />
        <Textarea
          name="campaign-url"
          key="campaign-url"
          testid="campaign-url"
          required
          label={getMessage('luckydraw.CampaignPageUrl.label')}
          placeholder={getMessage('luckydraw.CampaignPageUrl.placeholder')}
          {..._this.generateStateMappers({
            stateKeys: ['campaignURL'],
            loseEmphasisOnFill: true,
          })}
        />
      </div>
      <ImageDetail _this={_this} />
      <DrawTerms _this={_this} />
      {_this.props.method === 'edit' && (
        <DisplayCreatorEditorInfo {..._this.state.values} />
      )}
    </Fragment>
  ) : (
    <Loader size="sm" />
  )
}

export default Details
