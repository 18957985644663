/* eslint-disable react/jsx-handler-names */
import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import isObject from 'lodash/isObject'
import AuthenticatedPage from '../AuthenticatedPage'
import WithoutMenuPage from '../WithoutMenuPage'
import EmptyState from '../../components/EmptyState'
import Table, { Header } from '../../components/Table'
import Loader from '../../components/Loader'
import Pagination from '../../components/Pagination'
import { Popup as Modal, Dialog } from '../../components/Popup'
import API from '../../lib/api'
import { getMessage } from '../../lib/translator'
import { get } from '../../lib/storage'
import searchImg from './search.png'
import { Select } from '../../components/Form'
import './style.css'

export const TABLE_ACTIONS = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  DELETE: 'DELETE',
  FILTER: 'FILTER',
  REFRESH: 'REFRESH',
  UPDATE: 'UPDATE',
  UPDATE_CUSTOM: 'UPDATE_CUSTOM',
  SET_API_PARAMS: 'SET_API_PARAMS',
  IMPORT: 'IMPORT',
}

class NewListingPage extends React.Component {
  constructor(props) {
    super(props)
    const searchParams = Object.assign(
      {},
      ...this.props.router.location.search
        .slice(1)
        .split('&')
        .filter(Boolean)
        .map((keystr) => {
          keystr = keystr.split('=')
          return {
            [keystr[0]]: decodeURIComponent(keystr[1]),
          }
        })
    )

    const multiFilters =
      (props.filters && props.filters.multiSelectFilters) || []
    if (Array.isArray(multiFilters) && multiFilters.length > 0) {
      Object.keys(searchParams).forEach((item) => {
        if (multiFilters.includes(item)) {
          const itemIdList = searchParams[item].split(',')
          const idToNameList = []
          if (props.filters && props.filters.filterIdToNameDict) {
            const filterDict = props.filters.filterIdToNameDict
            itemIdList.forEach((itemId) => {
              const itemKey = `${item}-${itemId}`
              if (filterDict.has(itemKey)) {
                idToNameList.push(filterDict.get(itemKey))
              } else {
                idToNameList.push(itemId)
              }
            })
            searchParams[item] = idToNameList
          }
        }
      })
    }
    if (Object.keys(searchParams).length > 0) {
      const item = window.localStorage.getItem(this.props.className)
      if (!item) {
        window.localStorage.setItem(this.props.className, 1)
      }
    }
    let apiParams = {}
    if (props.api) {
      if (props.api.params) {
        apiParams = props.api.params
      }
    }
    const { location } = props.router || {}
    const { state = {} } = location

    const { ignoreKeysInFilterCount } = props.api
    const validParams = JSON.parse(JSON.stringify(searchParams))
    if (Object.keys(searchParams).length > 0) {
      delete validParams.page
      delete validParams.offset
      delete validParams.filters

      if (ignoreKeysInFilterCount && ignoreKeysInFilterCount.length > 0) {
        ignoreKeysInFilterCount.forEach((key) => {
          delete validParams[key]
        })
      }
    }
    this.state = {
      data: {
        items: null,
        paging: null,
        filters: { ...state, ...searchParams } || {}, // Corresponds to the filters applied to the API
        viewItems: null,
      },
      filters: {
        // Corresponds to the state of the 'filters' view
        shown:
          Object.keys(validParams).length > 0 ||
          (this.props.filters && this.props.filters.showFiltersOnLoad),
      },
      apiParams: apiParams,
      loaders: {
        data: false,
        updatingApiParams: false,
      },
      form: {
        shown: false,
        rowIndex: -1, // The row being edited
        data: null,
      },
      deleteDialog: {
        shown: false,
        data: {},
      },
      errorDialog: {
        shown: false,
        message: '',
        title: '',
      },
      firstLoadDone: false, // Indicate that we have gotten results from API at least once
    }

    this.primaryKey = this.props.primaryKey || 'id'

    this.setApiUrl()
    this.setApiCustomHeaders()
    this.hooksAndOverrides()

    this._showDataLoader = this._showDataLoader.bind(this)
    this._hideDataLoader = this._hideDataLoader.bind(this)
    this.changePage = this.changePage.bind(this)
    this.performAction = this.performAction.bind(this)
    this._showForm = this._showForm.bind(this)
    this._hideForm = this._hideForm.bind(this)
    this._toggleFilters = this._toggleFilters.bind(this)
    this.createResource = this.createResource.bind(this)
    this.modifyResource = this.modifyResource.bind(this)
    this.applyFilters = this.applyFilters.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.throwError = this.throwError.bind(this)
    this._showDeleteDialog = this._showDeleteDialog.bind(this)
    this._hideDeleteDialog = this._hideDeleteDialog.bind(this)
    this._showErrorDialog = this._showErrorDialog.bind(this)
    this._hideErrorDialog = this._hideErrorDialog.bind(this)
  }
  setApiUrl() {
    const { props } = this
    if (props.api && props.api.url) {
      const { api } = props
      let queryString = ''
      if (api.defaultQuery && Object.keys(api.defaultQuery).length > 0) {
        queryString = Object.keys(api.defaultQuery)
          .map((key) => `${key}=${encodeURIComponent(api.defaultQuery[key])}`)
          .join('&')
      }
      const url = queryString ? `${api.url}?${queryString}` : api.url
      this.api = new API({ url })
    }
  }
  /**
   * this function is to setup the custom headers (use it only if required).
   * custom headers can be injected in each method call, doesn't need to be in all
   * api calls.
   * Set the method : `methodToInjectHeader` : ['get', 'put'], and only this method call will
   * be having the custom headers injected.
   * This to prevent from api having
   */
  setApiCustomHeaders() {
    const { props } = this
    if (props.api && props.api.customHeaders) {
      const { headers, methodToInjectHeader } = props.api.customHeaders
      this.customHeaders = headers
      this.methodToInjectHeader = methodToInjectHeader
    }
  }
  _getCustomHeader(method) {
    const apiCustomHeaders = this.customHeaders
    if (this.methodToInjectHeader && this.methodToInjectHeader.length > 0) {
      const toBeInject = this.methodToInjectHeader.includes(
        method.toUpperCase()
      )
      return toBeInject && apiCustomHeaders
    }
    return undefined
  }
  hooksAndOverrides() {
    const { props } = this
    /* Default hooks */
    // Method to extract data from the API call made
    this._transformResponse = (response) => response
    this._transformSubmit = (form) => form

    /* Hook overrides */
    if (props.api && props.api.transform) {
      this._transformResponse = props.api.transform
    }
    if (props.form && props.form.transformSubmit) {
      this._transformSubmit = props.form.transformSubmit
    }
    if (props.updateView) {
      this.updateView = this.updateView.bind(this)
    }
  }
  // Some data lookup methods
  _getRow(data) {
    const result = {
      index: data ? data : -1,
    }
    if (!data && this.props.noId) {
      return result
    }
    const { items } = this.state.data
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === data.id) {
        result.data = items[i]
        result.index = i
      }
    }
    return result
  }
  // Some utility methods to manage states
  _showDataLoader() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.loaders.data = true
      return newState
    })
  }
  _hideDataLoader() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.loaders.data = false
      newState.loaders.updatingApiParams = false
      return newState
    })
  }
  _showForm(rowIndex, data) {
    // If rowIndex is specified, then a particular row is being edited
    if (this.props.form && this.props.form.component) {
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        newState.form.shown = true
        newState.form.rowIndex = isFinite(rowIndex) ? rowIndex : -1
        if (data) {
          newState.form.data = data
        }
        return newState
      })
    }
  }
  _hideForm() {
    // Clear the form and hide it
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.form.shown = false
      newState.form.rowIndex = -1
      newState.form.data = null
      newState.form.method = null
      return newState
    })
  }
  _toggleFilters() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.filters.shown = !prevState.filters.shown
      return newState
    })
  }
  _hideDeleteDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.deleteDialog.shown = false
      return newState
    })
  }
  _showDeleteDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.deleteDialog.shown = true
      return newState
    })
  }
  _hideErrorDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.errorDialog.shown = false
      newState.errorDialog.message = ''
      newState.errorDialog.title = ''
      return newState
    })
  }
  _showErrorDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.errorDialog.shown = true
      return newState
    })
  }
  handleResponse(code) {
    if (code === 200) {
      this.clearFilters()
      // temp fix to close the modal after succesfully POST data
      /* istanbul ignore next */
      if (this.props.api.url === '/v1/campaign-labels') {
        this._hideForm()
      }
    }
  }
  // Methods to manage data via API
  /**
   * Makes API call to create the resource
   * @param {object} data - Data to be created in DB
   * @return {Oject} newState - updated state [Immutated]
   */
  createResource(data) {
    const api = new API({
      url: this.props.api.url,
    })
    const params =
      this.props.form && this.props.form.overwriteWithApiParams === false
        ? Object.assign({}, this.state.apiParams, this._transformSubmit(data))
        : Object.assign({}, this._transformSubmit(data), this.state.apiParams)

    return api
      .post(params, this._getCustomHeader('post'))
      .then((response) => {
        this.handleResponse(response.code)
      })
      .catch((err) => this.throwError(err))
  }
  modifyResource(data, ignoreDefault = false) {
    // Make an API call to update the resource
    const { id } = data
    const api = new API({
      url: `${this.props.api.url}/${id}`,
    })
    let params =
      this.props.form && this.props.form.overwriteWithApiParams === false
        ? Object.assign({}, this.state.apiParams, this._transformSubmit(data))
        : Object.assign({}, this._transformSubmit(data), this.state.apiParams)

    if (ignoreDefault) {
      params = data
    }

    api
      .put(params, this._getCustomHeader('put'))
      .then((response) => {
        this.handleResponse(response.code)
      })
      .catch((err) => this.throwError(err))
  }

  /**
   * Makes API call to delete the resource
   * @param {object} data - Data to be deleted from DB
   * @return {Oject} newState - updated state [Immutated]
   */
  deleteResource(data) {
    const { id } = data
    const api = new API({
      url: `${this.props.api.url}/${id}`,
    })
    const params =
      this.props.api.overWriteDeleteParams === false
        ? Object.assign({}, this.state.apiParams, data)
        : Object.assign({}, data, this.state.apiParams)

    api
      .delete(params, this._getCustomHeader('delete'))
      .then((response) => {
        if (response.code === 200) {
          this.fetchTableData()
        }
      })
      .catch((err) => {
        this.throwError(err, getMessage('errorDialog.delete.error.title'))
      })
  }

  fetchTableData(params = {}) {
    if (this.api) {
      // {page: null} is not deleted, the null value will overwrite to apiParams

      if (params?.page === null) {
        delete params.page
      }
      if (
        this.props.api.isPageDisabled &&
        // eslint-disable-next-line no-prototype-builtins
        params.hasOwnProperty('page') &&
        params.page > 1
      ) {
        if (this.state.data.paging) {
          const { data } = this.state
          const { paging } = data
          params['offset'] = paging.limit * (Number(params.page) - 1)
        } else {
          // consider default value as 20
          params['offset'] = 20 * (Number(params.page) - 1)
        }
      }
      let filters = Object.keys(params).reduce((acc, key) => {
        if (isObject(params[key]) && !Array.isArray(params[key])) {
          return acc
        }
        return { ...acc, [key]: params[key] }
      }, {})
      this._showDataLoader()
      if (Object.keys(filters).length > 0) {
        filters = Object.assign({}, this.state.apiParams, filters)
      } else {
        filters = Object.assign({}, filters, this.state.apiParams)
      }

      // add storeId in params if fetching Data is dependent on store
      if (this.props.storeDependent) {
        filters = Object.assign({}, filters, { storeId: get('store') })
      }

      return this.api
        .get(filters, this._getCustomHeader('get'))
        .then((response) => {
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            newState.data.items = this._transformResponse(response)
            if (this.props.updateView) {
              newState.data.viewItems = this._transformResponse(response)
            }

            // some API response doesn't have same naminmgs: count, limit, offset. So, rename the values
            let data = null
            if ('data' in response) {
              data = response.data
            } else if ('paging' in response) {
              data = response.paging
            } else {
              // response.page is by page 1,2,3,4. Offset needs to be 0,20,40,60
              data = {
                ...response,
                limit: response.pageSize,
                offset: (response.page - 1) * 20,
              }
            }

            newState.data.paging = (({ count, limit, offset }) => ({
              count,
              limit,
              offset,
            }))(data)
            newState.firstLoadDone = true
            return newState
          })
        })
        .then(this._hideDataLoader)
        .catch((error) => {
          this._hideDataLoader()
          throw error
        })
    }
    return null
  }
  changePage(page) {
    window.localStorage.setItem(this.props.className, page) // necessary for pagination
    if (Object.keys(this.state.data.filters).length) {
      this.applyFilters(this.state.data.filters, page)
    } else {
      this.fetchTableData({ page })
    }
  }
  applyFilters(filters, page = 1) {
    const { location, history } = this.props.router
    const { filters: propFilters } = this.props
    const { data } = this.state
    window.localStorage.setItem(this.props.className, page) // necessary for pagination
    let transformedFilters = filters
    if (this.props.filters && this.props.filters.transformSubmit) {
      transformedFilters = this.props.filters.transformSubmit(filters)
    }
    const params = Object.assign({}, transformedFilters, {
      offset: 0, // Reset page to 1 if applying filters
      page: page,
    })
    let querystring = ''
    for (const key in params) {
      const value = params[key]
      if (value !== undefined && value !== null) {
        querystring += `${
          querystring.length ? '&' : ''
        }${key}=${encodeURIComponent(value)}`
      }
    }
    history.push(`${location.pathname}?${querystring}`, {
      ...filters,
    })

    this.setState({
      data: { ...data, filters: { ...filters } },
    })

    if (propFilters?.transformFilter) {
      this.fetchTableData({
        ...propFilters.transformFilter(filters),
        ...params,
      })
    } else {
      this.fetchTableData({ ...filters, ...params })
    }
  }
  clearFilters() {
    const { location, history } = this.props.router
    window.localStorage.setItem(this.props.className, 1)
    const { onClear } = this.props
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.data.filters = {}
      newState.filters = { shown: true }
      return newState
    })

    if (onClear) {
      onClear()
      return
    }

    history.push(location.pathname)
    this.fetchTableData()
  }
  confirmDelete() {
    this.deleteResource(this.state.deleteDialog.data)
    this._hideDeleteDialog()
  }

  throwError(error, title) {
    if (error.code >= 400 && error.code < 500) {
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        newState.errorDialog.message =
          error.message.split(':').slice(1).join(':') || error.message // if string does not have a colon
        newState.errorDialog.title = title || ''
        return newState
      }, this._showErrorDialog())
    } else {
      throw error
    }
  }
  performAction(action, data, _updates) {
    // action: (string) Action to perform
    // data: The data needed to search a specific a row
    // only actions for searchcampaigns added here, refer to ListingPage for others
    if (action in TABLE_ACTIONS) {
      switch (action) {
        case TABLE_ACTIONS.VIEW:
          if (this.props.form) {
            const getRow = this._getRow(data)
            this.setState({ form: { method: 'view' } })
            this._showForm(getRow.index)
          }
          break
        case TABLE_ACTIONS.ADD:
          if (this.props.form) {
            this._showForm(-1)
          }
          break
        case TABLE_ACTIONS.EDIT:
          if (this.props.form) {
            // Lookup row number for given data and send that row number
            const getRow = this._getRow(data)
            this._showForm(getRow.index)
          }
          break
        case TABLE_ACTIONS.DELETE:
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            newState.deleteDialog.data = data
            return newState
          })
          this._showDeleteDialog()
          break
        case TABLE_ACTIONS.SET_API_PARAMS:
          this.setState((prevState) => {
            return {
              apiParams: Object.assign({}, prevState.apiParams, data),
            }
          }, this.fetchTableData)
          break
        default:
          break
      }
    } else if (
      this.props.tableProperties.actions &&
      action in this.props.tableProperties.actions
    ) {
      // Perform custom action
    }
  }
  componentDidMount() {
    this.props.dontSavePagination &&
      window.localStorage.setItem(this.props.className, 1)
    const configs = { page: window.localStorage.getItem(this.props.className) }
    if (
      this.props.filters &&
      this.props.filters.transformFilter &&
      this.state.data.filters
    ) {
      const transformedFilters = this.props.filters.transformFilter(
        this.state.data.filters
      )
      this.fetchTableData({ ...transformedFilters, ...configs })
    } else {
      this.fetchTableData({ ...this.state.data.filters, ...configs })
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }
  formPart() {
    const { props, state } = this
    const Form = props.form && props.form.component
    return (
      <Form
        value={
          state.form.rowIndex > -1
            ? state.data.items[this.state.form.rowIndex]
            : state.form.data
        }
        onSubmit={
          this.state.form.rowIndex > -1
            ? this.modifyResource
            : this.createResource
        }
        onCancel={this._hideForm}
        method={
          state.form.method === 'view'
            ? 'view'
            : state.form.rowIndex > -1
              ? 'edit'
              : 'add'
        }
        options={props.form && props.form.options}
      />
    )
  }
  renderSearch() {
    const { props, state } = this
    if (props.filters && props.filters.searchComponent) {
      const SearchComponent = props.filters.searchComponent
      return (
        <div className={'filters-wrapper'}>
          <SearchComponent
            value={state.data.filters}
            filters={state.data.filters}
            isFilterVisible={state.filters.shown}
            filterToggle={this._toggleFilters}
            onClear={this.clearFilters}
            onSubmit={this.applyFilters}
            options={props.filters ? props.filters.options : null}
          />
        </div>
      )
    }
    return null
  }
  renderForm() {
    const { props, state } = this
    if (state.form.shown) {
      return (
        <Modal
          heading={
            state.form.method === 'view'
              ? props.viewHeading
              : state.form.rowIndex > -1
                ? props.editHeading
                : props.addHeading
          }
          className={props.modalClassName || ''}
          show={state.form.shown}
          close={this._hideForm}
        >
          {this.formPart()}
        </Modal>
      )
    }
    return null
  }
  renderTable() {
    const { props, state } = this
    const { data } = this.state
    const Row = props.tableProperties.row
    const {
      count: countProp,
      offset: offsetProp,
      limit: limitProp,
    } = data.paging

    let count = countProp
    let offset = offsetProp
    let limit = limitProp
    count = Number(count) || Number(data.items.length)
    offset = Number(offset)
    limit = Number(limit)

    const totalPages = Math.ceil(count / limit)

    const jumpToPageOptions = [...Array(totalPages).keys()].map((i) => {
      return { text: i + 1, value: i + 1 }
    })
    const page = window.localStorage.getItem(props.className)
    const viewData = props.updateView
      ? data.viewItems && data.viewItems.length > 0
        ? data.viewItems
        : data.items
      : data.items
    return (
      <div className="table-container" data-testid={props.testid}>
        <Table tableDynamic={props.tableDynamic || false}>
          {props.tableProperties.headers && (
            <Header items={props.tableProperties.headers} />
          )}
          {viewData.map((row, index) => (
            <Row
              {...row}
              key={`New-listing-${row[this.primaryKey]}`}
              apiParams={state.apiParams}
              onAction={this.performAction}
              index={index}
            />
          ))}
          {props.addToTable && (
            <props.addToTable onAction={this.performAction} />
          )}
        </Table>
        <div className="pagination-container">
          <div className="pagination-count">{`${getMessage(
            'pagination.text'
          )} ${offset + 1} - ${
            limit > 0 && offset >= 0 ? Math.min(offset + limit, count) : count
          } ${getMessage('pagination.helperText')} ${count}`}</div>
          {totalPages > 1 && (
            <Pagination
              total={totalPages}
              current={Number(page) || Math.floor(offset / limit + 1)}
              onSelect={this.changePage}
              skipArrows={props.skipArrows}
            />
          )}
          {props.jumpToPage && (
            <div className="jump-to-page-container">
              {getMessage('pagination.jumpToPage')} :
              <Select
                name="jumpToPagePagination"
                placeholder={'Select'}
                value={Number(page) || Math.floor(offset / limit + 1)}
                options={jumpToPageOptions}
                onChange={this.changePage}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
  renderFilter() {
    const { props, state } = this
    const Filters = props.filters && props.filters.component
    const filterApiData = (props.filters && props.filters.filterApiData) || {}
    if (Filters) {
      return (
        <div
          className={'filters-wrapper' + (state.filters.shown ? '' : ' hidden')}
        >
          <Filters
            value={state.data.filters}
            filterApiData={filterApiData}
            onClear={this.clearFilters}
            onSubmit={this.applyFilters}
            options={props.filters && props.filters.options}
          />
        </div>
      )
    }
    return null
  }
  renderHeader() {
    const { props, state } = this
    const HeaderActions = props.headerActions
    return (
      <div className="header-container">
        {props.title && <h1 className="title">{props.title}</h1>}
        {props.filters && props.filters.forceShow && (
          <div className="header-actions-wrapper">
            {props.filters && !props.filters.isSearchEnabled && (
              <div className="search-button-wrapper">
                <button
                  className={
                    'search-button' + (state.filters.shown ? ' active' : '')
                  }
                  onClick={this._toggleFilters}
                />
              </div>
            )}
            {HeaderActions && (
              <HeaderActions
                apiParams={state.apiParams}
                onAction={this.performAction}
                data={state.data}
              />
            )}
          </div>
        )}
      </div>
    )
  }
  renderBody(filtersApplied) {
    const { props, state } = this
    const { data } = this.state
    const emptyStateProps = Object.assign({}, props.emptyState)
    const emptyState = <EmptyState {...emptyStateProps} />
    if (
      !this.api ||
      (data.paging && data.paging.count === 0) ||
      (data.items && data.items.length === 0)
    ) {
      if (filtersApplied) {
        return (
          <div className="not-found">
            <img src={searchImg} className="not-found-img" alt="not-found" />
            <div className="not-found-text">No results found</div>
            <div className="not-found-filter-text">
              Please try adjusting your search or filter to find what you’re
              looking for.
            </div>
          </div>
        )
      } else {
        return <div>{emptyState}</div>
      }
    } else if (
      data.items &&
      props.tableProperties.row &&
      !state.loaders.updatingApiParams
    ) {
      return this.renderTable()
    }
    return null
  }
  render() {
    // TODO: Add support for replacing default messages with localized strings
    const { props, state } = this
    const { router = {} } = props
    const { location = {} } = router
    const { data } = this.state
    const filters = JSON.parse(JSON.stringify(data.filters))
    delete filters.offset
    delete filters.page
    const filtersCount = Object.keys(filters).filter((key) =>
      Boolean(data.filters[key])
    ).length
    const filtersApplied = filtersCount > 0
    const view = this.renderBody(filtersApplied)

    const WrapperComponent = props.menu ? AuthenticatedPage : WithoutMenuPage
    return (
      <WrapperComponent
        setApiParam={this.setApiParam}
        menu={props.menu}
        showLanguageDropDown={props.showLanguageDropDown}
        className={`listing-page ${props.className}`}
        storeDependent={props.storeDependent}
        onChange={() => {
          this.performAction(TABLE_ACTIONS.SET_API_PARAMS, {
            storeId: Number(get('store')),
          })
          props.api && props.api.onUpdateStore && props.api.onUpdateStore()
        }}
        from={location.pathname}
      >
        {(props.title || props.filters) && this.renderHeader()}
        {this.renderSearch()}
        {this.renderFilter()}
        {this.renderForm()}
        {state.deleteDialog.shown && (
          <Dialog
            show={state.deleteDialog.shown}
            title={getMessage('deleteDialog.title')}
            information={getMessage('deleteDialog.information')}
            onOk={this.confirmDelete}
            close={this._hideDeleteDialog}
            closeText={getMessage('dialog.cancelText')}
            okText={getMessage('dialog.okText')}
          />
        )}
        {state.errorDialog.shown && (
          <Dialog
            show={state.errorDialog.shown}
            title={state.errorDialog.title}
            information={getMessage(state.errorDialog.message)}
            close={this._hideErrorDialog}
            closeText={getMessage('dialog.okText')}
          />
        )}
        {state.loaders.data ? <Loader /> : view}
      </WrapperComponent>
    )
  }
}

NewListingPage.propTypes = {
  title: PropTypes.string,
  api: PropTypes.shape({
    url: PropTypes.string.isRequired,
    defaultQuery: PropTypes.object,
    transform: PropTypes.func,
  }),
  filters: PropTypes.shape({
    transformFilter: PropTypes.func,
    showFiltersOnLoad: PropTypes.bool,
    multiSelectFilters: PropTypes.object,
    filterIdToNameDict: PropTypes.array,
    filterApiData: PropTypes.object,
    options: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
    isSearchEnabled: PropTypes.bool,
    component: PropTypes.elementType,
    transformSubmit: PropTypes.func,
  }),
  form: PropTypes.shape({
    component: PropTypes.elementType,
    overwriteWithApiParams: PropTypes.bool,
    transformSubmit: PropTypes.func,
  }),
  tableProperties: PropTypes.shape({
    headers: PropTypes.array,
    row: PropTypes.func,
    actions: PropTypes.object,
  }),
  menu: PropTypes.object,
  primaryKey: PropTypes.string,
  className: PropTypes.string,
  testid: PropTypes.string,
  emptyState: PropTypes.object,
  tableDynamic: PropTypes.bool,
  addToTable: PropTypes.elementType,
  jumpToPage: PropTypes.bool,
  showLanguageDropDown: PropTypes.bool,
  updateView: PropTypes.func,
  storeDependent: PropTypes.bool,
  dontSavePagination: PropTypes.bool,
}

export default withRouter(({ location, history, match, ...props }) => (
  <NewListingPage router={{ location, history, match }} {...props} />
))
