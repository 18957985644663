import React from 'react'
import { Link } from 'react-router-dom'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'

import { getMessage } from '../../../../lib/translator'
import { GIFTING_PAGINATION_SIZE } from '../../constants'
import tableProperties, { transform } from './tableProperties'
import EnitityFilter from './Filters'
import EntityForm from '../Form'

const AddButton = () => (
  <Link to="/customer-support/gifting-entities/add" className="primary button" data-testid='crate-new-entity'>
    <span className="text">{getMessage('eVoucher.entities.create')}</span>
  </Link>
)

const EmptyState = {
  message: getMessage('customerSupport.egifting.empty'),
  actions: () => <AddButton />,
}

export default function List(props) {
  return (
    <ListingPageWithRoutes
      testid="e-gifting-entities"
      addHeading={getMessage('eVoucher.entities.create')}
      menu={props.menu}
      className="egifting-entities-page"
      title={getMessage('customerSupport.egifting.entities')}
      api={{
        url: '/gifting-admin-service/entities',
        params: { ...props.params, page_size: GIFTING_PAGINATION_SIZE },
        transform,
      }}
      headerActions={AddButton}
      primaryKey="id"
      filters={{
        component: EnitityFilter,
        forceShow: true,
      }}
      form={{
        component: EntityForm,
      }}
      tableProperties={tableProperties()}
      emptyState={EmptyState}
      statusUpdateOnDelete
    />
  )
}
