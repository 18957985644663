import React from 'react'
import {
  BaseForm,
  Input,
  DateRangePicker,
  VALIDATION_TYPES,
  Select
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import '../style.css'

const offerStatusOptions = [
    { value: 'Verified', text: 'Verified' },
    { value: 'Unverified', text: 'Unverified' },
  ];

export default class MediaCampaignFiltersForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      values: {}
    }
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('media.filters.name.heading')}
            placeholder={getMessage('media.filters.name.placeholder')}
            name="name"
            className="campaign-filter"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
            })}
          />
          <DateRangePicker
            className="section-row-input"
            label={getMessage('media.campaigndetail.campaign.time')}
            {...this.generateStateMappers({
              stateKeys: ['duration'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            displayFormat="YYYY-MM-DD"
            minimumNights={0}
            isOutsideRange={() => false}
            startDatePlaceholder={getMessage(
              'media.campaigndetail.campaign.time.startDate.placeholder'
            )}
            endDatePlaceholder={getMessage(
              'media.campaigndetail.campaign.time.endDate.placeholder'
            )}
          />
          <Input
           label={getMessage('media.filters.createdby.heading')}
           placeholder={getMessage('media.filters.createdby.placeholder')}
            name="createdBy"
            type="text"
            {...this.generateStateMappers({
             stateKeys: ['createdBy'],
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Select
            label={getMessage('media.filters.offerstatus.heading')}
            placeholder={getMessage('media.filters.offerstatus.placeholder')}
            name="offersVerified"
            options={offerStatusOptions}
            {...this.generateStateMappers({
              stateKeys: ['offersVerified'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('media.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('media.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
