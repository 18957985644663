import React, { useState, Fragment } from 'react'
import { getMessage } from '../../../lib/translator'
import { Input } from '../../Form'
import { SuccessPopup } from '../../Popup'
import API from '../../../lib/api'
import RefreshIcon from '../../Rewards/rewards.svg'

const DownloadWinnersList = ({ drawId }) => {
  const [error, setError] = useState('')
  const [emailAddresses, setEmailAddresses] = useState('')
  const [isEmailSuccessModalVisible, setEmailSuccessModalVisibility] =
    useState(false)

  const DescriptionList = () => (
    <ul className="draw-winners-success-popup">
      {getMessage('luckydraw.drawWinnersList.successModal.description')}
      <li>
        {getMessage('luckydraw.drawWinnersList.successModal.description.1')}
      </li>
      <li>
        {getMessage('luckydraw.drawWinnersList.successModal.description.2')}
      </li>
    </ul>
  )

  const handlePopupClose = () => {
    setError('')
    setEmailAddresses('')
    setEmailSuccessModalVisibility(false)
  }

  const EMAIL_REGEX =
    /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}(,[\s]*[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})*$/

  const handleSendResults = async () => {
    if (!EMAIL_REGEX.test(emailAddresses)) {
      setError(getMessage('luckydraw.participantsTab.email.error'))
      return
    }

    try {
      const emailList = emailAddresses.split(',').map((email) => email.trim())

      await new API({ url: `/duck/draws/${drawId}/results` }).post({
        sendWinnersTo: emailList,
        sendPasswordTo: emailList,
      })

      setEmailSuccessModalVisibility(true)
      setError('')
    } catch (apiError) {
      setError(apiError.message || getMessage('luckydraw.server.error'))
    }
  }

  return (
    <Fragment>
      <div className="row download-winner-list">
        <div className="col-6 email mt">
          <Input
            label={getMessage('luckydraw.drawWinnersList.sendEmail')}
            placeholder={getMessage(
              'luckydraw.drawWinnersList.inputEmail.placeholder'
            )}
            type="text"
            value={emailAddresses}
            onChange={setEmailAddresses}
          />
          {error && <div className="error">{error}</div>}
        </div>
        <div className="col-6">
          <button
            className="button primary"
            type="button"
            data-testid="send-results-btn"
            disabled={!emailAddresses || isEmailSuccessModalVisible}
            onClick={handleSendResults}
          >
            {getMessage('luckydraw.drawWinnersList.sendResults')}
          </button>
        </div>
      </div>

      {isEmailSuccessModalVisible && (
        <SuccessPopup
          show
          close={handlePopupClose}
          icon={RefreshIcon}
          heading={getMessage(
            'luckydraw.drawWinnersList.successModal.headerMessage'
          )}
          description={<DescriptionList />}
          buttonText={getMessage('luckydraw.drawWinnersList.successModal.okay')}
        />
      )}
    </Fragment>
  )
}

export default DownloadWinnersList
