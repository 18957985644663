import React from 'react'
import { getMessage } from '../../../../../lib/translator'
import Table, { Row, Cell, Header } from '../../../../../components/Table'
import { getPaymentLogo, formatCardNumber } from '../../../../../lib/payment'
import { toSentenceCase } from '../../../../../lib/commonlyused'
import Image from '../../../../../components/Image'
const isDebit = isDebitCard => {
  return isDebitCard
    ? getMessage('qc.paymentDetails.table.Debit')
    : getMessage('qc.paymentDetails.table.Credit')
}
const refundItemRows = refundItem => {
  return (
    <Row className="suborder-items" key={`qc-item-row-${refundItem.id}`}>
      <Cell>
        <div>{refundItem.formattedTotalRefunded}</div>
      </Cell>
      <Cell>
        <div>
          {refundItem.method === 'LINKPOINTS'
            ? getMessage('qc.paymentDetails.LinkPoints')
            : toSentenceCase(refundItem.method)}
        </div>
      </Cell>
      <Cell>
        {refundItem.lastFour ? (
          <div className="order-payment-card">
            <Image
              className="payment-card"
              size="sm"
              src={getPaymentLogo(refundItem.scheme)}
            />
            <span>
              {formatCardNumber(
                refundItem.lastFour,
                isDebit(refundItem.isDebit)
              )}
            </span>
          </div>
        ) : (
          '___'
        )}
      </Cell>
      <Cell className="refund-item-state">
        {toSentenceCase(refundItem.state.split('_').join(' '))}
      </Cell>
    </Row>
  )
}
const PaymentDetails = props => {
  const headers = [
    getMessage('qc.paymentDetails.table.header.Amount'),
    getMessage('qc.paymentDetails.table.header.PaymentMode'),
    getMessage('qc.paymentDetails.table.header.Card'),
    getMessage('qc.paymentDetails.table.header.Status'),
  ]
  return (
    <div
      className="qc-container payment-details-container"
      data-testid="payment-details-container"
    >
      <div className="qc-header border-bottom">
        <h2>{getMessage('qc.paymentDetails.Heading')}</h2>
        <div className="qc-payment-subheading">
          {getMessage('qc.paymentDetails.AmountPaid')}: <b>{'$0.00'}</b>
          {getMessage('qc.paymentDetails.Card')}: <b>{'$0.00'}</b>
          {getMessage('qc.paymentDetails.LinkPoints')}: <b>{'$0.00'}</b>
        </div>
      </div>
      <Table tableDynamic={true}>
        <Header items={headers} />
        {props.data.payments &&
          props.data.payments.map(payment => {
            return (
              <Row
                className="suborder-items"
                key={`qc-item-row-${payment.paymentId}`}
              >
                <Cell>
                  <div>
                    {payment.state === 'CAPTURED'
                      ? payment.formattedTotalCaptured
                      : payment.formattedTotal}
                  </div>
                </Cell>
                <Cell>
                  <div>
                    {payment.method === 'LINKPOINTS'
                      ? getMessage('qc.paymentDetails.LinkPoints')
                      : toSentenceCase(payment.method)}
                  </div>
                </Cell>
                <Cell testid="lastFour">
                  {payment.lastFour ? (
                    <div className="order-payment-card">
                      <Image
                        className="payment-card"
                        size="sm"
                        src={getPaymentLogo(payment.scheme)}
                      />
                      <span>
                        {formatCardNumber(
                          payment.lastFour,
                          isDebit(payment.isDebit)
                        )}
                      </span>
                    </div>
                  ) : (
                    '___'
                  )}
                </Cell>
                <Cell>
                  {toSentenceCase(payment.state.split('_').join(' '))}
                </Cell>
              </Row>
            )
          })}
        {props.data.refunds &&
          props.data.refunds.map(refundItem => {
            return refundItemRows(refundItem)
          })}
      </Table>
    </div>
  )
}
export { isDebit }
export default PaymentDetails
