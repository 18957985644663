import React, { Fragment } from 'react'
import PublicPage from '../../../containers/PublicPage/index'
import { getMessage } from '../../../lib/translator'
import SignUpForm from './form'
import { Link, Redirect } from 'react-router-dom'
import { Consumer } from '../../../containers/Context'

export default function SignUp() {
  return (
    <PublicPage className="signup">
      <Consumer>
        {isEnterprise =>
          isEnterprise ? (
            <Redirect to="/" />
          ) : (
            <Fragment>
              <header className="header">
                <h1 className="heading">{getMessage('signup.heading')}</h1>
                <h2 className="subheading">
                  {getMessage('signup.subheading')}
                </h2>
              </header>
              <div className="box">
                <SignUpForm />
                <p className="alt-action">
                  {getMessage('signup.oldUser.message')}{' '}
                  <Link to="/login">
                    {getMessage('signup.oldUser.signinText')}
                  </Link>
                </p>
              </div>
            </Fragment>
          )
        }
      </Consumer>
    </PublicPage>
  )
}
