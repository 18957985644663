import React from 'react'
import {
  BaseForm,
  Select,
  SingleDatePicker,
  Textarea,
} from '../../../../components/Form'
import { getMessage } from '../../../../lib/translator'
import './style.css'

export const getTimes = () => {
  const times = []
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      times.push(`0${i}:00`)
      times.push(`0${i}:30`)
    } else {
      times.push(`${i}:00`)
      times.push(`${i}:30`)
    }
  }
  return times
}

export const exportCSV = (data, id) => {
  if (!data.length) return

  const csvRow = []
  const csvHeader = [
    ['TagId', 'SKU', 'StartDate', 'StartTime', 'EndDate', 'EndTime'],
  ]
  const csvData = data

  // Converting All Data into Array
  for (let item = 0; item < csvData.length; item++) {
    csvHeader.push([
      id,
      csvData[item].SKU,
      csvData[item].startDate,
      csvData[item].startTime,
      csvData[item].endDate,
      csvData[item].endTime,
    ])
  }

  // Converting each data into single string
  for (let i = 0; i < csvHeader.length; i++) {
    csvRow.push(csvHeader[i].join(','))
  }

  // CSV Creation
  const csvString = csvRow.join('%0A')
  const anchorElement = document.createElement('a')
  anchorElement.href = 'data:attachment/csv,' + csvString
  anchorElement.target = '_blank'
  anchorElement.download = 'Tags_Upload.csv'
  document.body.appendChild(anchorElement)
  anchorElement.click()
}
export default class TagsCSV extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values = {
      tagValues: '',
      tagStartDate: '',
      tagStartTime: '',
      tagEndDate: '',
      tagEndTime: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleSubmit = () => {
    const data = this.state.values.tagValues
    /* istanbul ignore next */
    if (!data) return

    const allValues = data.toString().split(',')
    const valuesCollections = allValues.map(item => {
      return {
        SKU: item,
        startDate: this.state.values.tagStartDate,
        startTime: this.state.values.tagStartTime,
        endDate: this.state.values.tagEndDate,
        endTime: this.state.values.tagEndTime,
      }
    })
    exportCSV(valuesCollections, this.props.data.items[0].id)
    this.props.hideForm()
    this.props.router.history.push('/catalogue/batch-upload-jobs')
  }

  handleCancel = () => {
    this.props.hideForm()
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components

    return (
      <div className="TagsCSV">
        <Form>
          <h1 className="heading">{getMessage('tag.generateCSV.text')}</h1>
          <div className="date-time-filter">
            <SingleDatePicker
              label={getMessage('tag.startDate.text')}
              placeholder={getMessage('tag.startTime.placeholder')}
              displayFormat="YYYY-MM-DD"
              name="tagStartDate"
              className="date-section"
              allowAllDates
              openDirection={'down'}
              {...this.generateStateMappers({
                stateKeys: ['tagStartDate'],
                loseEmphasisOnFill: true,
              })}
            />
            <Select
              label={getMessage('tag.startTime.text')}
              name="tagStartTime"
              type="text"
              className="time-section"
              placeholder={'05:00'}
              options={getTimes()}
              {...this.generateStateMappers({
                stateKeys: ['tagStartTime'],
                defaultValue: '00:00',
                loseEmphasisOnFill: true,
              })}
            />
          </div>

          <SingleDatePicker
            label={getMessage('tag.endDate.text')}
            placeholder={getMessage('tag.endTime.placeholder')}
            displayFormat="YYYY-MM-DD"
            openDirection={'up'}
            allowToday
            enableToday
            name="tagEndDate"
            className="date-section"
            {...this.generateStateMappers({
              stateKeys: ['tagEndDate'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage('tag.endTime.text')}
            name="tagEndTime"
            type="text"
            className="time-section"
            placeholder={'00:00'}
            options={getTimes()}
            {...this.generateStateMappers({
              stateKeys: ['tagEndTime'],
              loseEmphasisOnFill: true,
            })}
          />

          <Textarea
            testid="tagsValue"
            label={getMessage('tag.productSku.text')}
            name="tagValues"
            placeholder={getMessage('tag.productSku.placeholder')}
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['tagValues'],
              loseEmphasisOnFill: true,
            })}
          />
          <div className="form-actions">
            <div onClick={this.handleCancel} data-testid="cancelCsvTags">
              <CancelButton>{getMessage('brand.form.cancelText')}</CancelButton>
            </div>
            <div onClick={this.handleSubmit} data-testid="submitCsvTags">
              <SubmitButton>
                {getMessage('tag.action.submit')}
              </SubmitButton>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}
