import React from 'react'
import { getMessage } from '../../../../lib/translator'
import {
  BaseForm,
  DateRangePicker,
  Input,
  MultiSelect,
  SelectSearch,
  SingleDatePicker,
  VALIDATION_TYPES,
} from '../../../../components/Form'
import {
  formattedadvertiserList,
  getClassGLCodeList,
  formattedMediaPackageList,
  getAdvertiserClient,
  getPackages
} from '../../MediaCampaigns/advertiserList'
import AdvertisersTable from './advertisers'
import { BusinessMechanic, SetupDetails } from './BuFields'
import { DateMultiSelect } from '../../commonMedia/DateMultiSelect'
import AssetsBox from './AssetsBox'


function Buyer(props){
  return (
    <div>
      <Input
      className='section-row-input'
      label={getMessage('media.adsetdetail.info.buyer')}
      placeholder={getMessage('media.adsetdetail.info.buyer.placeholder')}
      name='buyer'
      type='text'
      {...props.generateStateMappers({
        stateKeys: ['buyer'],
      })}
      />
    </div>
  )
}

export default class AdsetForm extends BaseForm {
  constructor (props) {
    super(props)
    if (this.props.packageType === 'FLEXIBLE') {
      delete this.props.value.startDate
    }

    const isShowAssets = this.props.assetReservationList?.some(item => {
      return item.reserveDates && item.reserveDates.length > 0
    })

    this.state = {
      selectedAssetItemList: [],
      ownerBu: 'DB',
      advertiserList: [],
      selectedAdvertisers: [],
      selectedPackage: '',
      isShowAssets,
      values: this.props.value
    }
  }

  componentDidMount () {
    getPackages(this)
    getAdvertiserClient(this, this.props.campaignId)
    getClassGLCodeList(this)

    if(this.state.values?.businessMechanic?.classGLCode){
      this.state.values.businessMechanic.classGLCode = {
        "text": this.state.values.businessMechanic.classGLCode.name,
        "value": this.state.values.businessMechanic.classGLCode.id
      };
    } else {
      this.setState({
        "classGLCode": null,
      })
    }
    this.setState({
      selectedAdvertisers: this.props.value.advertiser,
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.packageType === 'FLEXIBLE') {
      this.deleteKeys(["startDate"])
    }
    this.setState(prevState => {
      const newState = Object.assign({}, prevState)
      newState.values = newProps.value
      return newState
    })
  }

  render () {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    const { isMobileView, duration} = this.props

    if (this.state.values?.classGLCode?.id) {
      this.state.values.classGLCode = this.state.values.classGLCode.id;
    }

    return (
      <div className='website-page-edit'>
        <Form className='website-page-form'>
          <React.Fragment>
            <h3 className='section-title'>{getMessage('media.adsetdetail.info.h3')}</h3>
            <div className='section-wrap'>
              <div className='section-row'>
                <Input
                  className='section-row-input'
                  label={getMessage('media.adsetdetail.info.name')}
                  placeholder={getMessage('media.adsetdetail.info.name.placeholder')}
                  name='lineItemName'
                  type='text'
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['lineItemName'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                  validationStrings={{
                    valueMissing: getMessage('media.adsetdetail.info.name.requiredMessage'),
                  }}
                />
                <SelectSearch
                  className='section-row-input'
                  name="selectedPackage"
                  required
                  label={getMessage('media.adsetdetail.info.package')}
                  placeholder={getMessage('media.adsetdetail.info.package.placeholder')}
                  multiple={false}
                  options={formattedMediaPackageList(this.state.packageList)}
                  nameKey="text"
                  valueKey="value"
                  searchByName
                  {...this.generateStateMappers({
                    stateKeys: ['selectedPackage'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    onChange: value => {
                      this.props.handlePackageChange(value)
                    }
                  })}
                  validationStrings={{
                    valueMissing: getMessage('media.adsetdetail.info.package.requiredMessage'),
                  }}
                />
              </div>

              {this.props.packageType !== 'FLEXIBLE' ? (
                <div className='section-row'>
                  <SingleDatePicker
                    className="section-row-input"
                    label={getMessage('media.adsetdetail.info.time.startDate')}
                    displayFormat="YYYY-MM-DD"
                    placeholder={getMessage('media.adsetdetail.info.time.startDate.placeholder')}
                    name='startDate'
                    required
                    allowAllDates
                    {...this.generateStateMappers({
                      stateKeys: ['startDate'],
                      validationType: VALIDATION_TYPES.ONSUBMIT,
                      onChange: value => {
                        this.props.handleDateChange(value)
                      }
                    })}
                    validationStrings={{
                      valueMissing: getMessage(
                        'media.adsetdetail.info.time.startDate.requiredMessage'
                      ),
                    }}
                  />
                  <Input
                    className='section-row-input'
                    label={getMessage('media.adsetdetail.info.time.endDate')}
                    placeholder={getMessage('media.adsetdetail.info.time.endDate.placeholder')}
                    name='endDate'
                    type='text'
                    value={duration.endDate}
                    readOnly
                  />
                </div>
              ) : (
                <div className='section-row'>
                  <div className='multi-dateRangePicker-wrap'>
                    <div className='multi-dateRangePicker'>
                      <DateRangePicker
                        className="section-row-input w-secondary-label"
                        label={getMessage('media.adsetdetail.flexible.defaulttime')}
                        secondaryLabel={() => (
                          <i className="text-muted secondary-label">{getMessage('media.adsetdetail.flexible.defaulttime.tipmessage')}</i>
                        )}
                        tooltip={
                          <div>{getMessage('media.adsetdetail.flexible.defaulttime.tooltip')}</div>
                        }
                        {...this.generateStateMappers({
                          stateKeys: ['flexible_duration'],
                          validationType: VALIDATION_TYPES.ONSUBMIT,
                          onChange: ({ startDate, endDate }) => {
                            this.props.flexibleTimeAdd(startDate, endDate, 'default')
                          }
                        })}
                        displayFormat="YYYY-MM-DD"
                        isOutsideRange={()=>false}
                        startDatePlaceholder={getMessage(
                          'media.campaigndetail.campaign.time.startDate.placeholder'
                        )}
                        endDatePlaceholder={getMessage(
                          'media.campaigndetail.campaign.time.endDate.placeholder'
                        )}
                        minimumNights={0}
                        isDayBlocked={ day => {
                          // moment().day() //get day of week
                          const disableDates = ["2022-09-01"]
                          return disableDates.some(date => day.isSame(date, 'day'));
                        }}
                      />
                      { !this.state.isShowAssets ? (
                        <button type="button" className='button button-white addSpecial'
                          onClick={()=>{ this.setState({ isShowAssets: true }) }}
                        >+ Add Special Activation</button>
                      ) : (
                        <button type="button" className='button button-white removeSpecial'
                          onClick={()=>{ this.setState({ isShowAssets: false })
                            this.props.flexibleTimeDelete('special')
                          }}
                        >Reset All Special Activation</button>
                      )}
                    </div>
                    <DateMultiSelect
                      timeList={this.props.flexibleDefaultTimeList}
                      handleSelect={(index)=>{this.props.flexibleTimeDelete('default', index)}}
                    ></DateMultiSelect>
                  </div>
                  { this.state.isShowAssets && (
                    <AssetsBox
                      values={this.state.values}
                      assetReservationList={this.props.assetReservationList}
                      flexibleTimeAdd={this.props.flexibleTimeAdd}
                      flexibleTimeDelete={this.props.flexibleTimeDelete}
                      generateStateMappers={this.generateStateMappers}
                    ></AssetsBox>
                  )}
                </div>
              )}

              <div className='section-row'>
                <MultiSelect
                  name='advertiser'
                  className='section-row-input'
                  label={getMessage('media.campaigndetail.buyer.advertiser')}
                  placeholder={getMessage('media.campaigndetail.buyer.advertiser.placeholder')}
                  options={formattedadvertiserList(this.state.advertiserList)}
                  searchByName
                  required
                  {...this.generateStateMappers({
                    stateKeys: ['advertiser'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    onChange: values => {
                      this.state.advertiserList.forEach((item, index) => {
                        if(!values.includes(item.id)){
                          this.deleteKeys([`budget_${index}`])
                        }
                      })
                      this.setState({ selectedAdvertisers: values })
                    },
                  })}
                  validationStrings={{
                    valueMissing: getMessage('media.campaigndetail.buyer.advertiser.requiredMessage'),
                  }}
                />
                <Buyer generateStateMappers={this.generateStateMappers}></Buyer>
              </div>
            </div>

            {(this.state.selectedAdvertisers && this.state.selectedAdvertisers.length > 0) && (
              <AdvertisersTable
                isMobileView={isMobileView}
                generateStateMappers={this.generateStateMappers}
                advertiserList={this.state.advertiserList}
                selectedAdvertisers={this.state.selectedAdvertisers}
                values={this.state.values}
              ></AdvertisersTable>
            )}

            {/* Setup Details & Business Mechanic */}
            {this.state.ownerBu === 'DB' && (
              <React.Fragment>
                <SetupDetails
                  isMobileView={isMobileView}
                  generateStateMappers={this.generateStateMappers}
                  values={this.state.values}
                  handleCampaignUrl={this.props.handleCampaignUrl}
                ></SetupDetails>
                <BusinessMechanic generateStateMappers={this.generateStateMappers} classGLCodeList={this.state.formattedClassGLCodeList} classGLCode={this.state.values?.classGLCode}></BusinessMechanic>
              </React.Fragment>
            )}
          </React.Fragment>

          <SubmitButton>{getMessage('websitePage.form.submitText')}</SubmitButton>
          <CancelButton>{getMessage('websitePage.form.cancelText')}</CancelButton>
        </Form>
      </div>
    )
  }
}
